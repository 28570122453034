(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.fW.$7 === region.gO.$7)
	{
		return 'on line ' + region.fW.$7;
	}
	return 'on lines ' + region.fW.$7 + ' through ' + region.gO.$7;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.jd,
		impl.j7,
		impl.j2,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		jl: func(record.jl),
		ib: record.ib,
		hB: record.hB
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.jl;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.ib;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.hB) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.jd,
		impl.j7,
		impl.j2,
		function(sendToApp, initialModel) {
			var view = impl.ka;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.jd,
		impl.j7,
		impl.j2,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.fV && impl.fV(sendToApp)
			var view = impl.ka;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.e5);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.f1) && (_VirtualDom_doc.title = title = doc.f1);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.jF;
	var onUrlRequest = impl.jG;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		fV: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.hF === next.hF
							&& curr.fw === next.fw
							&& curr.hz.a === next.hz.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		jd: function(flags)
		{
			return A3(impl.jd, flags, _Browser_getUrl(), key);
		},
		ka: impl.ka,
		j7: impl.j7,
		j2: impl.j2
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { i8: 'hidden', iO: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { i8: 'mozHidden', iO: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { i8: 'msHidden', iO: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { i8: 'webkitHidden', iO: 'webkitvisibilitychange' }
		: { i8: 'hidden', iO: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		hW: _Browser_getScene(),
		iC: {
			kc: _Browser_window.pageXOffset,
			kd: _Browser_window.pageYOffset,
			iE: _Browser_doc.documentElement.clientWidth,
			g$: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		iE: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		g$: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			hW: {
				iE: node.scrollWidth,
				g$: node.scrollHeight
			},
			iC: {
				kc: node.scrollLeft,
				kd: node.scrollTop,
				iE: node.clientWidth,
				g$: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			hW: _Browser_getScene(),
			iC: {
				kc: x,
				kd: y,
				iE: _Browser_doc.documentElement.clientWidth,
				g$: _Browser_doc.documentElement.clientHeight
			},
			gN: {
				kc: x + rect.left,
				kd: y + rect.top,
				iE: rect.width,
				g$: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.jo) { flags += 'm'; }
	if (options.iN) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.a1.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.a1.b, xhr)); });
		$elm$core$Maybe$isJust(request.ir) && _Http_track(router, xhr, request.ir.a);

		try {
			xhr.open(request.z, request.f3, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.f3));
		}

		_Http_configureRequest(xhr, request);

		request.e5.a && xhr.setRequestHeader('Content-Type', request.e5.a);
		xhr.send(request.e5.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.g_; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.im.a || 0;
	xhr.responseType = request.a1.d;
	xhr.withCredentials = request.iI;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		f3: xhr.responseURL,
		j$: xhr.status,
		j0: xhr.statusText,
		g_: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			jY: event.loaded,
			h4: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			jP: event.loaded,
			h4: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}

var $author$project$App$Main$LinkClicked = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Main$UrlChanged = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.A) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.C),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.C);
		} else {
			var treeLen = builder.A * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.E) : builder.E;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.A);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.C) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.C);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{E: nodeList, A: (len / $elm$core$Array$branchFactor) | 0, C: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {gU: fragment, fw: host, hu: path, hz: port_, hF: protocol, R: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $author$project$App$Main$AdjustTimeZone = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Main$Client = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Main$ClientMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Main$GetTime = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Main$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$Loading = {$: 0};
var $author$project$App$Main$LoadingMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Main$Proponent = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Main$ProponentMsg = function (a) {
	return {$: 16, a: a};
};
var $author$project$App$Route$Public = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Main$SharedStateMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Main$UserRecoverPassword = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Main$UserRecoverPasswordMsg = function (a) {
	return {$: 14, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $author$project$App$SharedState$APIMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$ClientLoginTemplateRetrieved = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$ClientMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$SharedState$LoadTemplateFromUrl = F3(
	function (a, b, c) {
		return {$: 111, a: a, b: b, c: c};
	});
var $author$project$App$Page$Client$PageClient$LoginForm = 0;
var $author$project$App$Page$Client$PageClient$None = {$: 0};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $author$project$App$Data$Contact$Email = 5;
var $author$project$App$Data$Contact$Mobile = 1;
var $author$project$App$Data$Id$uuidNil = '00000000-0000-0000-0000-000000000000';
var $author$project$App$Data$Contact$emptyModel = function (kind) {
	return {as: $author$project$App$Data$Id$uuidNil, hb: kind, jJ: $author$project$App$Data$Id$uuidNil, T: ''};
};
var $author$project$App$Data$Contact$emptyEmail = $author$project$App$Data$Contact$emptyModel(5);
var $author$project$App$Data$Contact$emptyMobile = $author$project$App$Data$Contact$emptyModel(1);
var $author$project$App$Data$Person$CivilStatusNotInformed = 0;
var $author$project$App$Data$Person$SexNotInformed = 0;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $author$project$App$Data$Person$emptyModel = {
	al: $elm$time$Time$millisToPosix(0),
	D: 0,
	i1: '',
	as: $author$project$App$Data$Id$uuidNil,
	cp: '',
	hl: '',
	dy: $elm$core$Maybe$Nothing,
	cD: false,
	dJ: 0,
	B: '',
	ii: '',
	f2: $elm$time$Time$millisToPosix(0)
};
var $author$project$App$Data$Statement$ShouldInterview = 1;
var $author$project$App$Data$StatementStatus$StatusNew = 0;
var $author$project$App$Data$Statement$emptyModel = {
	bo: 0,
	iS: 0,
	gx: $author$project$App$Data$Id$uuidNil,
	br: 0,
	iT: 0,
	iW: $elm$time$Time$millisToPosix(0),
	iX: $elm$core$Maybe$Nothing,
	b8: false,
	ao: 0,
	fl: $elm$core$Maybe$Nothing,
	bv: 0,
	ap: 0,
	gL: $elm$time$Time$millisToPosix(0),
	as: $author$project$App$Data$Id$uuidNil,
	jx: '',
	jK: $elm$core$Maybe$Nothing,
	be: $elm$core$Maybe$Nothing,
	jL: 0,
	Y: $author$project$App$Data$Id$uuidNil,
	bG: 0,
	jN: 0,
	bI: $elm$core$Maybe$Nothing,
	jU: $elm$time$Time$millisToPosix(0),
	j_: 1,
	aw: 0,
	eY: $author$project$App$Data$Id$uuidNil,
	f2: $elm$time$Time$millisToPosix(0)
};
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $author$project$App$Data$Contact$kindToString = function (kind) {
	switch (kind) {
		case 0:
			return '';
		case 1:
			return 'mobile';
		case 2:
			return 'whatsapp';
		case 3:
			return 'residential';
		case 4:
			return 'commercial';
		default:
			return 'email';
	}
};
var $author$project$App$Data$FullStatement$emptyEditStatement = {
	gc: $elm$core$Dict$empty,
	gd: $elm$core$Dict$empty,
	ge: $elm$core$Dict$empty,
	gf: $elm$core$Dict$empty,
	aZ: $elm$core$Dict$empty,
	gw: $elm$core$Dict$empty,
	ea: A3(
		$elm$core$Dict$insert,
		$author$project$App$Data$Contact$kindToString(5),
		$author$project$App$Data$Contact$emptyEmail,
		A3(
			$elm$core$Dict$insert,
			$author$project$App$Data$Contact$kindToString(1),
			$author$project$App$Data$Contact$emptyMobile,
			$elm$core$Dict$empty)),
	gE: $elm$core$Dict$empty,
	fx: $elm$core$Dict$empty,
	e: $author$project$App$Data$Statement$emptyModel,
	dw: $author$project$App$Data$Person$emptyModel
};
var $author$project$App$Data$Company$emptyModel = {iR: '', i1: '', as: $author$project$App$Data$Id$uuidNil, hs: ''};
var $author$project$App$Data$StatementTemplate$Unknown = 0;
var $author$project$App$Data$StatementTemplate$emptyModel = {gr: $elm$core$Maybe$Nothing, gx: $author$project$App$Data$Id$uuidNil, gz: $elm$core$Maybe$Nothing, eg: _List_Nil, gF: 0, gG: _List_Nil, el: _List_Nil, gX: false, as: $author$project$App$Data$Id$uuidNil, fy: true, hl: '', ex: _List_Nil, ht: false, eB: _List_Nil, hw: $elm$core$Maybe$Nothing, eJ: _List_Nil, eM: _List_Nil, hR: false, $9: '', io: '', iw: '', ix: $elm$core$Maybe$Nothing, iy: '', iz: '', e0: _List_Nil};
var $author$project$App$Data$FullStatement$emptyFullStatement = {g: $author$project$App$Data$FullStatement$emptyEditStatement, b4: $author$project$App$Data$Company$emptyModel, bs: _List_Nil, fh: $elm$core$Dict$empty, an: $elm$core$Dict$empty, gH: _List_Nil, he: $elm$core$Maybe$Nothing, jM: _List_Nil, fO: _List_Nil, cJ: $author$project$App$Data$StatementTemplate$emptyModel};
var $author$project$App$Page$Client$PageClient$emptyModel = {al: '', gx: $author$project$App$Data$Id$uuidNil, i1: '', c7: '', P: $author$project$App$Data$FullStatement$emptyFullStatement, db: '', g$: '1,70 m', cp: '', hl: '', dx: '', cF: $elm$core$Maybe$Nothing, aw: 0, cH: $author$project$App$Page$Client$PageClient$None, cI: '', eY: '', iD: '70 kg'};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$App$Page$Client$PageClient$init = _Utils_Tuple3(
	$author$project$App$Page$Client$PageClient$emptyModel,
	$elm$core$Platform$Cmd$none,
	A2(
		$elm$core$List$map,
		$author$project$App$SharedState$APIMsg,
		_List_fromArray(
			[
				A3(
				$author$project$App$SharedState$LoadTemplateFromUrl,
				'hcufpr.medsyn.com.br',
				'/funcionario',
				_List_fromArray(
					[
						$author$project$App$Page$ResponseMsgs$ClientMsg(
						A2($author$project$App$Page$ResponseMsgs$ClientLoginTemplateRetrieved, 'hcufpr.medsyn.com.br', '/funcionario'))
					]))
			])));
var $author$project$App$Page$PageLoading$NoData = 0;
var $author$project$App$Page$PageLoading$init = function (st) {
	return _Utils_Tuple3(0, $elm$core$Platform$Cmd$none, _List_Nil);
};
var $author$project$App$Page$PageProponent$GotTime = function (a) {
	return {$: 18, a: a};
};
var $author$project$App$Page$PageProponent$InformOnline = {$: 17};
var $author$project$App$Page$PageProponent$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$PageProponent$LoadingMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$Proponent = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$App$Route$ProponentLoading = 0;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$App$SharedState$asyncDelayedMsg = F2(
	function (_float, msg) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$always(msg),
			$elm$core$Process$sleep(_float));
	});
var $author$project$App$Constants$informOnlineInterval = 30000.0;
var $author$project$App$SharedState$LoadTemplateByStatementId = F2(
	function (a, b) {
		return {$: 87, a: a, b: b};
	});
var $author$project$App$SharedState$LoginProponent = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$PropLoadingTemplateLoaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$ResponseMsgs$ProponentLoadingMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$ResponseMsgs$ProponentLoginResponse = {$: 0};
var $author$project$App$Page$ResponseMsgs$ProponentMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$SharedMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$ResponseMsgs$SharedTemplateByStatementIdLoaded = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$SharedState$StopConference = {$: 1};
var $author$project$App$SharedState$VideoMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Proponent$PageLoading$init = function (fullStmt) {
	return _Utils_Tuple3(
		{fg: false},
		$elm$core$Platform$Cmd$none,
		_List_fromArray(
			[
				$author$project$App$SharedState$VideoMsg($author$project$App$SharedState$StopConference),
				$author$project$App$SharedState$APIMsg(
				A2(
					$author$project$App$SharedState$LoadTemplateByStatementId,
					fullStmt.g.e.as,
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$ProponentMsg(
							$author$project$App$Page$ResponseMsgs$ProponentLoadingMsg(
								$author$project$App$Page$ResponseMsgs$PropLoadingTemplateLoaded(fullStmt.g.e.as))),
							$author$project$App$Page$ResponseMsgs$SharedMsg(
							$author$project$App$Page$ResponseMsgs$SharedTemplateByStatementIdLoaded(fullStmt.g.e.as))
						]))),
				$author$project$App$SharedState$APIMsg(
				A2(
					$author$project$App$SharedState$LoginProponent,
					fullStmt.g.e.as,
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$ProponentMsg($author$project$App$Page$ResponseMsgs$ProponentLoginResponse)
						])))
			]));
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$App$Components$Filter$encode = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'sd',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$andThen,
						function (sd) {
							return $elm$core$Maybe$Just(
								$elm$json$Json$Encode$string(sd));
						},
						model.bN))),
				_Utils_Tuple2(
				'ed',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$andThen,
						function (ed) {
							return $elm$core$Maybe$Just(
								$elm$json$Json$Encode$string(ed));
						},
						model.bw))),
				_Utils_Tuple2(
				't',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$andThen,
						function (t) {
							return $elm$core$Maybe$Just(
								$elm$json$Json$Encode$string(t));
						},
						model.bP))),
				_Utils_Tuple2(
				'p',
				$elm$json$Json$Encode$int(model.aQ)),
				_Utils_Tuple2(
				'o',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$andThen,
						function (o) {
							return $elm$core$Maybe$Just(
								$elm$json$Json$Encode$string(o));
						},
						model.bE))),
				_Utils_Tuple2(
				'r',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, model.aH)),
				_Utils_Tuple2(
				'mpp',
				$elm$json$Json$Encode$int(model.cm)),
				_Utils_Tuple2(
				's',
				$elm$json$Json$Encode$bool(model.bK)),
				_Utils_Tuple2(
				'q',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$andThen,
						function (q) {
							return $elm$core$Maybe$Just(
								$elm$json$Json$Encode$string(q));
						},
						model.aX)))
			]));
};
var $elm$core$Bitwise$or = _Bitwise_or;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Bitwise$and = _Bitwise_and;
var $truqu$elm_base64$Base64$Encode$intToBase64 = function (i) {
	switch (i) {
		case 0:
			return 'A';
		case 1:
			return 'B';
		case 2:
			return 'C';
		case 3:
			return 'D';
		case 4:
			return 'E';
		case 5:
			return 'F';
		case 6:
			return 'G';
		case 7:
			return 'H';
		case 8:
			return 'I';
		case 9:
			return 'J';
		case 10:
			return 'K';
		case 11:
			return 'L';
		case 12:
			return 'M';
		case 13:
			return 'N';
		case 14:
			return 'O';
		case 15:
			return 'P';
		case 16:
			return 'Q';
		case 17:
			return 'R';
		case 18:
			return 'S';
		case 19:
			return 'T';
		case 20:
			return 'U';
		case 21:
			return 'V';
		case 22:
			return 'W';
		case 23:
			return 'X';
		case 24:
			return 'Y';
		case 25:
			return 'Z';
		case 26:
			return 'a';
		case 27:
			return 'b';
		case 28:
			return 'c';
		case 29:
			return 'd';
		case 30:
			return 'e';
		case 31:
			return 'f';
		case 32:
			return 'g';
		case 33:
			return 'h';
		case 34:
			return 'i';
		case 35:
			return 'j';
		case 36:
			return 'k';
		case 37:
			return 'l';
		case 38:
			return 'm';
		case 39:
			return 'n';
		case 40:
			return 'o';
		case 41:
			return 'p';
		case 42:
			return 'q';
		case 43:
			return 'r';
		case 44:
			return 's';
		case 45:
			return 't';
		case 46:
			return 'u';
		case 47:
			return 'v';
		case 48:
			return 'w';
		case 49:
			return 'x';
		case 50:
			return 'y';
		case 51:
			return 'z';
		case 52:
			return '0';
		case 53:
			return '1';
		case 54:
			return '2';
		case 55:
			return '3';
		case 56:
			return '4';
		case 57:
			return '5';
		case 58:
			return '6';
		case 59:
			return '7';
		case 60:
			return '8';
		case 61:
			return '9';
		case 62:
			return '+';
		default:
			return '/';
	}
};
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $truqu$elm_base64$Base64$Encode$toBase64 = function (_int) {
	return _Utils_ap(
		$truqu$elm_base64$Base64$Encode$intToBase64(63 & (_int >>> 18)),
		_Utils_ap(
			$truqu$elm_base64$Base64$Encode$intToBase64(63 & (_int >>> 12)),
			_Utils_ap(
				$truqu$elm_base64$Base64$Encode$intToBase64(63 & (_int >>> 6)),
				$truqu$elm_base64$Base64$Encode$intToBase64(63 & (_int >>> 0)))));
};
var $truqu$elm_base64$Base64$Encode$add = F2(
	function (_char, _v0) {
		var res = _v0.a;
		var count = _v0.b;
		var acc = _v0.c;
		var current = (acc << 8) | _char;
		if (count === 2) {
			return _Utils_Tuple3(
				_Utils_ap(
					res,
					$truqu$elm_base64$Base64$Encode$toBase64(current)),
				0,
				0);
		} else {
			return _Utils_Tuple3(res, count + 1, current);
		}
	});
var $elm$core$Basics$ge = _Utils_ge;
var $truqu$elm_base64$Base64$Encode$chomp = F2(
	function (char_, acc) {
		var _char = $elm$core$Char$toCode(char_);
		return (_char < 128) ? A2($truqu$elm_base64$Base64$Encode$add, _char, acc) : ((_char < 2048) ? A2(
			$truqu$elm_base64$Base64$Encode$add,
			128 | (63 & _char),
			A2($truqu$elm_base64$Base64$Encode$add, 192 | (_char >>> 6), acc)) : (((_char < 55296) || ((_char >= 57344) && (_char <= 65535))) ? A2(
			$truqu$elm_base64$Base64$Encode$add,
			128 | (63 & _char),
			A2(
				$truqu$elm_base64$Base64$Encode$add,
				128 | (63 & (_char >>> 6)),
				A2($truqu$elm_base64$Base64$Encode$add, 224 | (_char >>> 12), acc))) : A2(
			$truqu$elm_base64$Base64$Encode$add,
			128 | (63 & _char),
			A2(
				$truqu$elm_base64$Base64$Encode$add,
				128 | (63 & (_char >>> 6)),
				A2(
					$truqu$elm_base64$Base64$Encode$add,
					128 | (63 & (_char >>> 12)),
					A2($truqu$elm_base64$Base64$Encode$add, 240 | (_char >>> 18), acc))))));
	});
var $elm$core$String$foldl = _String_foldl;
var $truqu$elm_base64$Base64$Encode$initial = _Utils_Tuple3('', 0, 0);
var $truqu$elm_base64$Base64$Encode$wrapUp = function (_v0) {
	var res = _v0.a;
	var cnt = _v0.b;
	var acc = _v0.c;
	switch (cnt) {
		case 1:
			return res + ($truqu$elm_base64$Base64$Encode$intToBase64(63 & (acc >>> 2)) + ($truqu$elm_base64$Base64$Encode$intToBase64(63 & (acc << 4)) + '=='));
		case 2:
			return res + ($truqu$elm_base64$Base64$Encode$intToBase64(63 & (acc >>> 10)) + ($truqu$elm_base64$Base64$Encode$intToBase64(63 & (acc >>> 4)) + ($truqu$elm_base64$Base64$Encode$intToBase64(63 & (acc << 2)) + '=')));
		default:
			return res;
	}
};
var $truqu$elm_base64$Base64$Encode$encode = function (input) {
	return $truqu$elm_base64$Base64$Encode$wrapUp(
		A3($elm$core$String$foldl, $truqu$elm_base64$Base64$Encode$chomp, $truqu$elm_base64$Base64$Encode$initial, input));
};
var $truqu$elm_base64$Base64$encode = $truqu$elm_base64$Base64$Encode$encode;
var $author$project$App$Components$Filter$toURLString = function (filter) {
	return $truqu$elm_base64$Base64$encode(
		A2(
			$elm$json$Json$Encode$encode,
			0,
			$author$project$App$Components$Filter$encode(filter)));
};
var $author$project$App$Route$routeToString = function (page) {
	var pieces = function () {
		switch (page.$) {
			case 3:
				var _v5 = page.a;
				return _List_fromArray(
					['funcionario']);
			case 2:
				switch (page.b) {
					case 0:
						var stmtId = page.a;
						var _v6 = page.b;
						return _List_fromArray(
							['atendimento', 'carregando', stmtId]);
					case 1:
						var stmtId = page.a;
						var _v7 = page.b;
						return _List_fromArray(
							['atendimento', 'redirecionando', stmtId]);
					case 2:
						var stmtId = page.a;
						var _v8 = page.b;
						return _List_fromArray(
							['atendimento', 'inicio', stmtId]);
					case 9:
						var stmtId = page.a;
						var _v9 = page.b;
						return _List_fromArray(
							['atendimento', 'pagamento', stmtId]);
					case 3:
						var stmtId = page.a;
						var _v10 = page.b;
						return _List_fromArray(
							['atendimento', 'questionario', stmtId]);
					case 4:
						var stmtId = page.a;
						var _v11 = page.b;
						return _List_fromArray(
							['atendimento', 'confirmando', stmtId]);
					case 5:
						var stmtId = page.a;
						var _v12 = page.b;
						return _List_fromArray(
							['atendimento', 'aguardando', stmtId]);
					case 6:
						var stmtId = page.a;
						var _v13 = page.b;
						return _List_fromArray(
							['atendimento', 'revisando', stmtId]);
					case 7:
						var stmtId = page.a;
						var _v14 = page.b;
						return _List_fromArray(
							['atendimento', 'video', stmtId]);
					default:
						var stmtId = page.a;
						var _v15 = page.b;
						return _List_fromArray(
							['atendimento', 'finalizando', stmtId]);
				}
			case 1:
				switch (page.a.$) {
					case 0:
						var _v1 = page.a;
						return _List_fromArray(
							['carregando']);
					case 1:
						var _v2 = page.a;
						return _List_fromArray(
							['entrar']);
					case 7:
						var filter = page.a.a;
						return _List_fromArray(
							[
								'resumo_atendimentos',
								$author$project$App$Components$Filter$toURLString(filter)
							]);
					case 8:
						var _v3 = page.a;
						return _List_fromArray(
							['faturamento']);
					case 2:
						var _v16 = page.a;
						return _List_fromArray(
							['medico', 'cadastrar']);
					case 3:
						var _v17 = page.a;
						return _List_fromArray(
							['usuario', 'cadastrar']);
					case 4:
						var _v18 = page.a;
						return _List_fromArray(
							['usuario', 'cadastro', 'completo']);
					case 5:
						var _v19 = page.a;
						return _List_fromArray(
							['usuario', 'recuperar']);
					default:
						var token = page.a.a;
						return _List_fromArray(
							['recuperar-senha', token]);
				}
			default:
				switch (page.a.$) {
					case 0:
						var _v4 = page.a;
						return _List_fromArray(
							['restrito']);
					case 1:
						switch (page.a.a.$) {
							case 0:
								var _v20 = page.a.a;
								return _List_fromArray(
									['medico', 'carregando']);
							case 1:
								var _v21 = page.a.a;
								return _List_fromArray(
									['medico', 'atendimento', 'fila']);
							case 2:
								var _v22 = page.a.a;
								return _List_fromArray(
									['medico', 'atendimento', 'revisando']);
							case 3:
								if (!page.a.a.a) {
									var _v23 = page.a.a.a;
									return _List_fromArray(
										['medico', 'atendimento', 'carregando']);
								} else {
									var _v24 = page.a.a.a;
									return _List_fromArray(
										['medico', 'atendimento', 'online']);
								}
							case 4:
								var stmtId = page.a.a.a;
								return _List_fromArray(
									['medico', 'finalizando', stmtId]);
							case 5:
								var stmtId = page.a.a.a;
								return _List_fromArray(
									['medico', 'completo', stmtId]);
							case 6:
								var filter = page.a.a.a;
								return _List_fromArray(
									[
										'medico',
										'pendentes',
										$author$project$App$Components$Filter$toURLString(filter)
									]);
							case 7:
								if (!page.a.a.a.$) {
									var filter = page.a.a.a.a;
									return _List_fromArray(
										[
											'medico',
											'realizados',
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								} else {
									var _v25 = page.a.a.a;
									var id = _v25.a;
									var filter = _v25.b;
									return _List_fromArray(
										[
											'medico',
											'realizados',
											'detalhes',
											id,
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								}
							case 8:
								var _v26 = page.a.a;
								return _List_fromArray(
									['medico', 'reeval']);
							case 9:
								var id = page.a.a.a;
								return _List_fromArray(
									['medico', 'reeval', 'detalhes', id]);
							default:
								var _v27 = page.a.a.a;
								return _List_fromArray(
									['medico', 'relatorio']);
						}
					case 2:
						switch (page.a.a.$) {
							case 1:
								if (!page.a.a.a.$) {
									var filter = page.a.a.a.a;
									return _List_fromArray(
										[
											'empresa',
											'pendentes',
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								} else {
									var _v28 = page.a.a.a;
									var stmtId = _v28.a;
									var filter = _v28.b;
									return _List_fromArray(
										[
											'empresa',
											'pendentes',
											'detalhes',
											stmtId,
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								}
							case 2:
								if (!page.a.a.a.$) {
									var filter = page.a.a.a.a;
									return _List_fromArray(
										[
											'empresa',
											'cancelados',
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								} else {
									var _v29 = page.a.a.a;
									var stmtId = _v29.a;
									var filter = _v29.b;
									return _List_fromArray(
										[
											'empresa',
											'cancelados',
											'detalhes',
											stmtId,
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								}
							case 3:
								if (!page.a.a.a.$) {
									var filter = page.a.a.a.a;
									return _List_fromArray(
										[
											'empresa',
											'concluidos',
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								} else {
									var _v30 = page.a.a.a;
									var stmtId = _v30.a;
									var filter = _v30.b;
									return _List_fromArray(
										[
											'empresa',
											'concluidos',
											'detalhes',
											stmtId,
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								}
							case 0:
								if (!page.a.a.a.$) {
									var _v31 = page.a.a.a;
									return _List_fromArray(
										['atendimento', 'requisitar', 'nova']);
								} else {
									var id = page.a.a.a.a;
									return _List_fromArray(
										['atendimento', 'requisitar', 'editar', id]);
								}
							case 4:
								var stmtId = page.a.a.a;
								return _List_fromArray(
									['empresa', 'atendimento', 'completo', stmtId]);
							case 5:
								var _v32 = page.a.a;
								return _List_fromArray(
									['empresa', 'reeval']);
							default:
								var id = page.a.a.a;
								return _List_fromArray(
									['empresa', 'reeval', 'detalhes', id]);
						}
					case 5:
						switch (page.a.a.$) {
							case 0:
								return _List_fromArray(
									['administrativo', 'listar', 'usuarios']);
							case 1:
								return _List_fromArray(
									['administrativo', 'listar', 'medicos']);
							case 2:
								return _List_fromArray(
									['administrativo', 'relatorio']);
							case 3:
								var stmtId = page.a.a.a;
								return _List_fromArray(
									['administrativo', 'atendimento', 'completo', stmtId]);
							case 4:
								if (!page.a.a.a.$) {
									var filter = page.a.a.a.a;
									return _List_fromArray(
										[
											'administrativo',
											'realizados',
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								} else {
									var _v33 = page.a.a.a;
									var id = _v33.a;
									var filter = _v33.b;
									return _List_fromArray(
										[
											'administrativo',
											'realizados',
											'detalhes',
											id,
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								}
							case 5:
								var _v34 = page.a.a;
								return _List_fromArray(
									['administrativo', 'reeval']);
							default:
								var id = page.a.a.a;
								return _List_fromArray(
									['administrativo', 'reeval', 'detalhes', id]);
						}
					case 3:
						switch (page.a.a.$) {
							case 0:
								if (!page.a.a.a.$) {
									var filter = page.a.a.a.a;
									return _List_fromArray(
										[
											'revisao',
											'pendentes',
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								} else {
									var _v35 = page.a.a.a;
									var stmtId = _v35.a;
									var filter = _v35.b;
									return _List_fromArray(
										[
											'revisao',
											'pendentes',
											'detalhes',
											stmtId,
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								}
							case 1:
								if (!page.a.a.a.$) {
									var filter = page.a.a.a.a;
									return _List_fromArray(
										[
											'revisao',
											'incompletos',
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								} else {
									var _v36 = page.a.a.a;
									var stmtId = _v36.a;
									var filter = _v36.b;
									return _List_fromArray(
										[
											'revisao',
											'incompletos',
											'detalhes',
											stmtId,
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								}
							case 2:
								if (!page.a.a.a.$) {
									var filter = page.a.a.a.a;
									return _List_fromArray(
										[
											'revisao',
											'aprovados',
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								} else {
									var _v37 = page.a.a.a;
									var stmtId = _v37.a;
									var filter = _v37.b;
									return _List_fromArray(
										[
											'revisao',
											'aprovados',
											'detalhes',
											stmtId,
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								}
							case 3:
								var id = page.a.a.a;
								return _List_fromArray(
									['revisao', 'pendentes', 'revisar', id]);
							case 4:
								if (!page.a.a.a.$) {
									var _v38 = page.a.a.a;
									var id = _v38.a;
									var filter = _v38.b;
									return _List_fromArray(
										[
											'revisao',
											'incompletos',
											'editar',
											id,
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								} else {
									var _v39 = page.a.a.a;
									var id = _v39.a;
									var filter = _v39.b;
									return _List_fromArray(
										[
											'revisao',
											'aprovados',
											'editar',
											id,
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								}
							case 5:
								var _v40 = page.a.a;
								return _List_fromArray(
									['revisao', 'reeval']);
							case 6:
								var id = page.a.a.a;
								return _List_fromArray(
									['revisao', 'reeval', 'detalhes', id]);
							case 7:
								var _v41 = page.a.a;
								return _List_fromArray(
									['revisao', 'atendimento', 'detalhes']);
							default:
								var id = page.a.a.a;
								return _List_fromArray(
									['revisao', 'atendimento', 'detalhes', id]);
						}
					default:
						switch (page.a.a.$) {
							case 0:
								if (!page.a.a.a.$) {
									var filter = page.a.a.a.a;
									return _List_fromArray(
										[
											'suporte',
											'pendentes',
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								} else {
									var _v42 = page.a.a.a;
									var stmtId = _v42.a;
									var filter = _v42.b;
									return _List_fromArray(
										[
											'suporte',
											'pendentes',
											'detalhes',
											stmtId,
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								}
							case 1:
								if (!page.a.a.a.$) {
									var filter = page.a.a.a.a;
									return _List_fromArray(
										[
											'suporte',
											'incompletos',
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								} else {
									var _v43 = page.a.a.a;
									var stmtId = _v43.a;
									var filter = _v43.b;
									return _List_fromArray(
										[
											'suporte',
											'incompletos',
											'detalhes',
											stmtId,
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								}
							case 2:
								if (!page.a.a.a.$) {
									var filter = page.a.a.a.a;
									return _List_fromArray(
										[
											'suporte',
											'aprovados',
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								} else {
									var _v44 = page.a.a.a;
									var stmtId = _v44.a;
									var filter = _v44.b;
									return _List_fromArray(
										[
											'suporte',
											'aprovados',
											'detalhes',
											stmtId,
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								}
							case 3:
								var id = page.a.a.a;
								return _List_fromArray(
									['suporte', 'pendentes', 'revisar', id]);
							case 4:
								if (!page.a.a.a.$) {
									var _v45 = page.a.a.a;
									var id = _v45.a;
									var filter = _v45.b;
									return _List_fromArray(
										[
											'suporte',
											'incompletos',
											'editar',
											id,
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								} else {
									var _v46 = page.a.a.a;
									var id = _v46.a;
									var filter = _v46.b;
									return _List_fromArray(
										[
											'suporte',
											'aprovados',
											'editar',
											id,
											$author$project$App$Components$Filter$toURLString(filter)
										]);
								}
							case 5:
								var _v47 = page.a.a;
								return _List_fromArray(
									['suporte', 'reeval']);
							default:
								var id = page.a.a.a;
								return _List_fromArray(
									['suporte', 'reeval', 'detalhes', id]);
						}
				}
		}
	}();
	return '/' + A2($elm$core$String$join, '/', pieces);
};
var $author$project$App$Route$pushUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$pushUrl,
			key,
			$author$project$App$Route$routeToString(route));
	});
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {bB: index, jk: match, bb: number, j1: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{iN: false, jo: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $author$project$App$Data$Id$uuidRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('[\\da-f]{8}[-][\\da-f]{4}[-][\\da-f]{4}[-][\\da-f]{4}[-][\\da-f]{12}'));
var $author$project$App$Data$Id$uuidIsValid = function (id) {
	return _Utils_eq(id, $author$project$App$Data$Id$uuidNil) ? false : A2($elm$regex$Regex$contains, $author$project$App$Data$Id$uuidRegex, id);
};
var $author$project$App$Page$PageProponent$init = F2(
	function (stmtId, st) {
		var validStmtId = $author$project$App$Data$Id$uuidIsValid(st.ia.h8.g.e.as) ? st.ia.h8.g.e.as : stmtId;
		var fullStmt = st.ia.h8;
		var bearer = $author$project$App$Data$FullStatement$emptyFullStatement.g;
		var stmt = bearer.e;
		var newFullStmt = _Utils_update(
			fullStmt,
			{
				g: _Utils_update(
					bearer,
					{
						e: _Utils_update(
							stmt,
							{as: stmtId})
					})
			});
		var _v0 = $author$project$App$Page$Proponent$PageLoading$init(newFullStmt);
		var model = _v0.a;
		var cmd = _v0.b;
		var uStMsg = _v0.c;
		return _Utils_Tuple3(
			{
				ey: $elm$time$Time$millisToPosix(0),
				aQ: $author$project$App$Page$PageProponent$Loading(model)
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$LoadingMsg, cmd),
						A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						A2($author$project$App$Route$Proponent, validStmtId, 0)),
						A2($author$project$App$SharedState$asyncDelayedMsg, $author$project$App$Constants$informOnlineInterval, $author$project$App$Page$PageProponent$InformOnline),
						A2($elm$core$Task$perform, $author$project$App$Page$PageProponent$GotTime, $elm$time$Time$now)
					])),
			uStMsg);
	});
var $author$project$App$Page$PageUserRecoverPassword$Filling = {$: 0};
var $author$project$App$Data$User$emptyModel = {e3: $elm$core$Maybe$Nothing, gx: $elm$core$Maybe$Nothing, iW: $elm$core$Maybe$Nothing, c7: '', as: $author$project$App$Data$Id$uuidNil, bf: '', dv: '', eE: '', Y: $author$project$App$Data$Id$uuidNil};
var $author$project$App$Page$PageUserRecoverPassword$empty = {dI: '', aw: $author$project$App$Page$PageUserRecoverPassword$Filling, bS: $author$project$App$Data$User$emptyModel};
var $author$project$App$Page$PageUserRecoverPassword$init = function (s) {
	return _Utils_Tuple3(
		_Utils_update(
			$author$project$App$Page$PageUserRecoverPassword$empty,
			{dI: s}),
		$elm$core$Platform$Cmd$none,
		_List_Nil);
};
var $author$project$App$SharedState$APICmd = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$ResponseMsgs$SharedTokenRenewResponse = {$: 16};
var $author$project$App$Constants$appNameInternal = 'medsyn2';
var $author$project$App$Service$Shared$Auth = 1;
var $author$project$App$Service$Shared$Method = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$Service$Shared$methodFromPath = $author$project$App$Service$Shared$Method;
var $author$project$App$Service$Shared$Path = $elm$core$Basics$identity;
var $author$project$App$Service$Shared$pathFromRID = $elm$core$Basics$identity;
var $author$project$App$Service$Shared$RID = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Service$Shared$ridFromString = function (str) {
	var queryList = A2($elm$core$String$split, '?', str);
	var ridStr = A2(
		$elm$core$Maybe$withDefault,
		'invalidService.invalidEndpoint',
		$elm$core$List$head(queryList));
	var query = $elm$core$List$head(
		A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$tail(queryList)));
	var list = A2($elm$core$String$split, '.', ridStr);
	var service = A2(
		$elm$core$Maybe$withDefault,
		'invalidService',
		$elm$core$List$head(list));
	var endpoint = A2(
		$elm$core$String$join,
		'.',
		A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$tail(list)));
	return A3($author$project$App$Service$Shared$RID, service, endpoint, query);
};
var $author$project$App$Constants$appRenewMethod = function () {
	var rid = $author$project$App$Service$Shared$ridFromString('userService.sign');
	var path = $author$project$App$Service$Shared$pathFromRID(rid);
	return A3($author$project$App$Service$Shared$methodFromPath, 1, path, 'renew');
}();
var $author$project$App$SharedState$Idle = 0;
var $author$project$App$SharedState$SimpleLoad = {$: 0};
var $author$project$App$SharedState$emptyFullLoadingStatus = {gw: false, ea: 0, gE: false, e: false, dw: false, cv: false};
var $author$project$App$SharedState$emptyFullStatementCache = {
	iP: 0,
	aq: $author$project$App$SharedState$SimpleLoad,
	cl: _List_Nil,
	i: {g: $author$project$App$SharedState$emptyFullLoadingStatus, d6: false, fh: $elm$core$Dict$empty, an: false, eh: false, dd: $elm$core$Maybe$Nothing, eG: false, aG: false, cJ: false},
	h8: $author$project$App$Data$FullStatement$emptyFullStatement
};
var $author$project$App$Components$Video$NotInitialized = {$: 0};
var $author$project$App$Components$Video$emptyDevice = {go: '', a9: _List_Nil, fY: false, dO: '', iB: 0};
var $author$project$App$Data$Conference$emptyModel = {
	iW: $elm$time$Time$millisToPosix(0),
	as: $author$project$App$Data$Id$uuidNil,
	bf: '',
	jQ: false,
	jR: false,
	dM: '',
	dN: '',
	ie: '',
	$8: '',
	dT: ''
};
var $author$project$App$Components$Video$emptyModel = {
	d7: $author$project$App$Data$Conference$emptyModel,
	bt: $author$project$App$Components$Video$emptyDevice,
	fo: '',
	dp: $elm$core$Dict$empty,
	dq: $elm$core$Dict$empty,
	jl: '',
	fM: $elm$core$Maybe$Nothing,
	bJ: false,
	dA: $elm$core$Maybe$Nothing,
	cy: $elm$core$Dict$empty,
	cC: false,
	bj: '',
	aT: false,
	dH: _List_Nil,
	eT: {eO: '', dM: '', dN: _List_Nil, dT: _List_Nil},
	bM: false,
	p: $author$project$App$Components$Video$NotInitialized,
	aw: $elm$core$Dict$empty
};
var $author$project$App$Service$ApiWebSocket$Resgate = F8(
	function (a, b, c, d, e, f, g, h) {
		return {$: 0, a: a, b: b, c: c, d: d, e: e, f: f, g: g, h: h};
	});
var $author$project$App$Constants$baseApiUrl = 'wss://api.medsyn.com.br/ws';
var $author$project$App$Service$Websocket$Cache = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $author$project$App$Service$Websocket$StateDisconnected = 0;
var $author$project$App$Service$Websocket$init = function (host) {
	return A4($author$project$App$Service$Websocket$Cache, host, 0, _List_Nil, _List_Nil);
};
var $author$project$App$Service$ApiWebSocket$InternalToken = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$Service$ApiWebSocket$NotAuthenticated = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Service$ApiWebSocket$ResponseAlreadyAvailable = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Service$ApiWebSocket$ValidatingSession = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Service$ApiWebSocket$asyncMsg = function (msg) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$identity,
		$elm$core$Task$succeed(msg));
};
var $author$project$App$Service$ApiWebSocket$sessionLoadToken = _Platform_outgoingPort('sessionLoadToken', $elm$json$Json$Encode$string);
var $author$project$App$Service$ApiWebSocket$initSession = F3(
	function (recoverSession, tokenName, renewResponse) {
		return recoverSession ? _Utils_Tuple2(
			A2(
				$author$project$App$Service$ApiWebSocket$ValidatingSession,
				renewResponse,
				A3($author$project$App$Service$ApiWebSocket$InternalToken, tokenName, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)),
			$author$project$App$Service$ApiWebSocket$sessionLoadToken(tokenName)) : _Utils_Tuple2(
			$author$project$App$Service$ApiWebSocket$NotAuthenticated(
				A3($author$project$App$Service$ApiWebSocket$InternalToken, tokenName, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)),
			$author$project$App$Service$ApiWebSocket$asyncMsg(
				$author$project$App$Service$ApiWebSocket$ResponseAlreadyAvailable(renewResponse)));
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$App$Service$ApiWebSocket$init = F4(
	function (recoverSession, appName, renewMethod, renewResponse) {
		var websocket = $author$project$App$Service$Websocket$init($author$project$App$Constants$baseApiUrl);
		var now = $elm$time$Time$millisToPosix(0);
		var _v0 = A3($author$project$App$Service$ApiWebSocket$initSession, recoverSession, appName, renewResponse);
		var sModel = _v0.a;
		var sCmd = _v0.b;
		var m = A8(
			$author$project$App$Service$ApiWebSocket$Resgate,
			$elm$time$Time$posixToMillis(now),
			now,
			sModel,
			renewMethod,
			websocket,
			$elm$core$Dict$empty,
			$elm$core$Dict$empty,
			$elm$core$Dict$empty);
		return _Utils_Tuple2(m, sCmd);
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$App$SharedState$init = F3(
	function (key, route, recoverSession) {
		var _v0 = A4(
			$author$project$App$Service$ApiWebSocket$init,
			recoverSession,
			$author$project$App$Constants$appNameInternal,
			$author$project$App$Constants$appRenewMethod,
			_List_fromArray(
				[
					$author$project$App$Page$ResponseMsgs$SharedMsg($author$project$App$Page$ResponseMsgs$SharedTokenRenewResponse)
				]));
		var apiModel = _v0.a;
		var apiCmd = _v0.b;
		return _Utils_Tuple2(
			{_: apiModel, cY: false, b6: $elm$core$Maybe$Nothing, fC: key, ba: $elm$core$Maybe$Nothing, eP: route, ia: $author$project$App$SharedState$emptyFullStatementCache, bS: $author$project$App$Data$User$emptyModel, f4: $author$project$App$Components$Video$emptyModel, f6: $elm$time$Time$utc},
			A2($elm$core$Platform$Cmd$map, $author$project$App$SharedState$APICmd, apiCmd));
	});
var $author$project$App$MedsynCss$loadCss = _Platform_outgoingPort('loadCss', $elm$json$Json$Encode$string);
var $author$project$App$MedsynCss$load = function (str) {
	return $author$project$App$MedsynCss$loadCss(str);
};
var $author$project$App$Route$Login = {$: 1};
var $author$project$App$Route$AccessRestricted = {$: 0};
var $author$project$App$Route$Admin = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Route$AdminFinanceSales = 0;
var $author$project$App$Route$AdminFinanceSalesRoute = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Route$AdminInterviewDetails = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Route$AdminListDoctors = 0;
var $author$project$App$Route$AdminListDoctorsRoute = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Route$AdminListFinished = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$AdminListFinishedRoute = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Route$AdminListReevaluation = {$: 5};
var $author$project$App$Route$AdminListUsers = 0;
var $author$project$App$Route$AdminListUsersRoute = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$AdminReevaluationDetails = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Route$AdminViewEvaluationRoute = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Route$Client = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Route$ClientLogin = 0;
var $author$project$App$Route$Company = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Route$CompanyListCanceled = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$CompanyListCanceledRoute = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Route$CompanyListCanceledViewDetails = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Route$CompanyListConcluded = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$CompanyListConcludedRoute = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Route$CompanyListConcludedViewDetails = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Route$CompanyListPending = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$CompanyListPendingRoute = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Route$CompanyListPendingViewDetails = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Route$CompanyListReevaluation = {$: 5};
var $author$project$App$Route$CompanyReevaluationDetails = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Route$CompanyRequestInterview = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$CompanyRequestInterviewEdit = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Route$CompanyRequestInterviewNew = {$: 0};
var $author$project$App$Route$CompanyViewEvaluationRoute = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Route$Doctor = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Route$DoctorFinalEvaluation = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Route$DoctorInterview = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Route$DoctorInterviewDetails = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Route$DoctorInterviewLoading = 0;
var $author$project$App$Route$DoctorInterviewOnline = 1;
var $author$project$App$Route$DoctorListFinished = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$DoctorListFinishedRoute = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Route$DoctorListPending = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Route$DoctorListReevaluation = {$: 8};
var $author$project$App$Route$DoctorLoading = {$: 0};
var $author$project$App$Route$DoctorReevaluationDetails = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Route$DoctorResume = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Route$DoctorReviewStatement = {$: 2};
var $author$project$App$Route$DoctorSales = 0;
var $author$project$App$Route$DoctorSalesRoute = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Route$DoctorSignUp = {$: 2};
var $author$project$App$Route$DoctorViewEvaluation = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Route$DoctorWaitingLine = {$: 1};
var $author$project$App$Route$FinanceSales = {$: 8};
var $author$project$App$Route$Private = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$ProponentConfirmInterview = 4;
var $author$project$App$Route$ProponentDoctorReview = 6;
var $author$project$App$Route$ProponentInterview = 7;
var $author$project$App$Route$ProponentIntro = 2;
var $author$project$App$Route$ProponentPayment = 9;
var $author$project$App$Route$ProponentQuestions = 3;
var $author$project$App$Route$ProponentRedirect = 1;
var $author$project$App$Route$ProponentWaitingInLine = 5;
var $author$project$App$Route$ProponentWaitingResults = 8;
var $author$project$App$Route$Review = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Route$ReviewEditApprovedStatement = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Route$ReviewEditStatement = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$App$Route$ReviewEditStatementRoute = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Route$ReviewInterviewRoute = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Route$ReviewInterviewRouteEdit = $elm$core$Basics$identity;
var $author$project$App$Route$ReviewListApproved = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$ReviewListApprovedRoute = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Route$ReviewListApprovedViewDetails = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Route$ReviewListForReview = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$ReviewListForReviewRoute = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$ReviewListForReviewViewDetails = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Route$ReviewListIncomplete = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$ReviewListIncompleteRoute = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Route$ReviewListIncompleteViewDetails = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Route$ReviewListReevaluation = {$: 5};
var $author$project$App$Route$ReviewReevaluationDetails = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Route$ReviewWaitingLine = {$: 7};
var $author$project$App$Route$ReviewWaitingProponentDetails = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Route$Support = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Route$SupportEditApprovedStatement = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Route$SupportEditStatement = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$App$Route$SupportEditStatementRoute = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Route$SupportInterviewRoute = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Route$SupportInterviewRouteEdit = $elm$core$Basics$identity;
var $author$project$App$Route$SupportListApproved = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$SupportListApprovedRoute = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Route$SupportListApprovedViewDetails = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Route$SupportListForReview = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$SupportListForReviewRoute = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$SupportListForReviewViewDetails = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Route$SupportListIncomplete = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Route$SupportListIncompleteRoute = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Route$SupportListIncompleteViewDetails = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Route$SupportListReevaluation = {$: 5};
var $author$project$App$Route$SupportReevaluationDetails = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Route$UserRecoverPassword = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Route$UserRequestRecoverPassword = {$: 5};
var $author$project$App$Route$UserSignUp = {$: 3};
var $author$project$App$Route$UserSignUpComplete = {$: 4};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $author$project$App$Components$Filter$empty = {aX: $elm$core$Maybe$Nothing, bw: $elm$core$Maybe$Nothing, cm: 7, bE: $elm$core$Maybe$Nothing, aQ: 1, aH: _List_Nil, bK: false, bN: $elm$core$Maybe$Nothing, bP: $elm$core$Maybe$Nothing};
var $author$project$App$Components$Filter$Model = F9(
	function (startDate, endDate, text, page, order, rules, maxPerPage, show, actualQuery) {
		return {aX: actualQuery, bw: endDate, cm: maxPerPage, bE: order, aQ: page, aH: rules, bK: show, bN: startDate, bP: text};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$App$Components$Filter$decode = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'q',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		's',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'mpp',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'r',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'o',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
					$elm$core$Maybe$Nothing,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'p',
						$elm$json$Json$Decode$int,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							't',
							$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'ed',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
								$elm$core$Maybe$Nothing,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'sd',
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
									$elm$core$Maybe$Nothing,
									$elm$json$Json$Decode$succeed($author$project$App$Components$Filter$Model))))))))));
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $truqu$elm_base64$Base64$Decode$pad = function (input) {
	var _v0 = $elm$core$String$length(input) % 4;
	switch (_v0) {
		case 3:
			return input + '=';
		case 2:
			return input + '==';
		default:
			return input;
	}
};
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $truqu$elm_base64$Base64$Decode$charToInt = function (_char) {
	switch (_char) {
		case 'A':
			return 0;
		case 'B':
			return 1;
		case 'C':
			return 2;
		case 'D':
			return 3;
		case 'E':
			return 4;
		case 'F':
			return 5;
		case 'G':
			return 6;
		case 'H':
			return 7;
		case 'I':
			return 8;
		case 'J':
			return 9;
		case 'K':
			return 10;
		case 'L':
			return 11;
		case 'M':
			return 12;
		case 'N':
			return 13;
		case 'O':
			return 14;
		case 'P':
			return 15;
		case 'Q':
			return 16;
		case 'R':
			return 17;
		case 'S':
			return 18;
		case 'T':
			return 19;
		case 'U':
			return 20;
		case 'V':
			return 21;
		case 'W':
			return 22;
		case 'X':
			return 23;
		case 'Y':
			return 24;
		case 'Z':
			return 25;
		case 'a':
			return 26;
		case 'b':
			return 27;
		case 'c':
			return 28;
		case 'd':
			return 29;
		case 'e':
			return 30;
		case 'f':
			return 31;
		case 'g':
			return 32;
		case 'h':
			return 33;
		case 'i':
			return 34;
		case 'j':
			return 35;
		case 'k':
			return 36;
		case 'l':
			return 37;
		case 'm':
			return 38;
		case 'n':
			return 39;
		case 'o':
			return 40;
		case 'p':
			return 41;
		case 'q':
			return 42;
		case 'r':
			return 43;
		case 's':
			return 44;
		case 't':
			return 45;
		case 'u':
			return 46;
		case 'v':
			return 47;
		case 'w':
			return 48;
		case 'x':
			return 49;
		case 'y':
			return 50;
		case 'z':
			return 51;
		case '0':
			return 52;
		case '1':
			return 53;
		case '2':
			return 54;
		case '3':
			return 55;
		case '4':
			return 56;
		case '5':
			return 57;
		case '6':
			return 58;
		case '7':
			return 59;
		case '8':
			return 60;
		case '9':
			return 61;
		case '+':
			return 62;
		case '/':
			return 63;
		default:
			return 0;
	}
};
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Char$fromCode = _Char_fromCode;
var $truqu$elm_base64$Base64$Decode$intToString = A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar);
var $truqu$elm_base64$Base64$Decode$add = F2(
	function (_char, _v0) {
		var curr = _v0.a;
		var need = _v0.b;
		var res = _v0.c;
		var shiftAndAdd = function (_int) {
			return (63 & _int) | (curr << 6);
		};
		return (!need) ? ((!(128 & _char)) ? _Utils_Tuple3(
			0,
			0,
			_Utils_ap(
				res,
				$truqu$elm_base64$Base64$Decode$intToString(_char))) : (((224 & _char) === 192) ? _Utils_Tuple3(31 & _char, 1, res) : (((240 & _char) === 224) ? _Utils_Tuple3(15 & _char, 2, res) : _Utils_Tuple3(7 & _char, 3, res)))) : ((need === 1) ? _Utils_Tuple3(
			0,
			0,
			_Utils_ap(
				res,
				$truqu$elm_base64$Base64$Decode$intToString(
					shiftAndAdd(_char)))) : _Utils_Tuple3(
			shiftAndAdd(_char),
			need - 1,
			res));
	});
var $truqu$elm_base64$Base64$Decode$toUTF16 = F2(
	function (_char, acc) {
		return _Utils_Tuple3(
			0,
			0,
			A2(
				$truqu$elm_base64$Base64$Decode$add,
				255 & (_char >>> 0),
				A2(
					$truqu$elm_base64$Base64$Decode$add,
					255 & (_char >>> 8),
					A2($truqu$elm_base64$Base64$Decode$add, 255 & (_char >>> 16), acc))));
	});
var $truqu$elm_base64$Base64$Decode$chomp = F2(
	function (char_, _v0) {
		var curr = _v0.a;
		var cnt = _v0.b;
		var utf8ToUtf16 = _v0.c;
		var _char = $truqu$elm_base64$Base64$Decode$charToInt(char_);
		if (cnt === 3) {
			return A2($truqu$elm_base64$Base64$Decode$toUTF16, curr | _char, utf8ToUtf16);
		} else {
			return _Utils_Tuple3((_char << ((3 - cnt) * 6)) | curr, cnt + 1, utf8ToUtf16);
		}
	});
var $truqu$elm_base64$Base64$Decode$initial = _Utils_Tuple3(
	0,
	0,
	_Utils_Tuple3(0, 0, ''));
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$String$endsWith = _String_endsWith;
var $truqu$elm_base64$Base64$Decode$stripNulls = F2(
	function (input, output) {
		return A2($elm$core$String$endsWith, '==', input) ? A2($elm$core$String$dropRight, 2, output) : (A2($elm$core$String$endsWith, '=', input) ? A2($elm$core$String$dropRight, 1, output) : output);
	});
var $truqu$elm_base64$Base64$Decode$validBase64Regex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([A-Za-z0-9\\/+]{4})*([A-Za-z0-9\\/+]{2}[A-Za-z0-9\\/+=]{2})?$'));
var $truqu$elm_base64$Base64$Decode$validate = function (input) {
	return A2($elm$regex$Regex$contains, $truqu$elm_base64$Base64$Decode$validBase64Regex, input) ? $elm$core$Result$Ok(input) : $elm$core$Result$Err('Invalid base64');
};
var $truqu$elm_base64$Base64$Decode$wrapUp = function (_v0) {
	var _v1 = _v0.c;
	var need = _v1.b;
	var res = _v1.c;
	return (need > 0) ? $elm$core$Result$Err('Invalid UTF-16') : $elm$core$Result$Ok(res);
};
var $truqu$elm_base64$Base64$Decode$validateAndDecode = function (input) {
	return A2(
		$elm$core$Result$map,
		$truqu$elm_base64$Base64$Decode$stripNulls(input),
		A2(
			$elm$core$Result$andThen,
			A2(
				$elm$core$Basics$composeR,
				A2($elm$core$String$foldl, $truqu$elm_base64$Base64$Decode$chomp, $truqu$elm_base64$Base64$Decode$initial),
				$truqu$elm_base64$Base64$Decode$wrapUp),
			$truqu$elm_base64$Base64$Decode$validate(input)));
};
var $truqu$elm_base64$Base64$Decode$decode = A2($elm$core$Basics$composeR, $truqu$elm_base64$Base64$Decode$pad, $truqu$elm_base64$Base64$Decode$validateAndDecode);
var $truqu$elm_base64$Base64$decode = $truqu$elm_base64$Base64$Decode$decode;
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $author$project$App$Components$Filter$fromURLString = F2(
	function (defaultFilter, str) {
		return function (result) {
			if (!result.$) {
				var filter = result.a;
				return filter;
			} else {
				return defaultFilter;
			}
		}(
			A2(
				$elm$json$Json$Decode$decodeString,
				$author$project$App$Components$Filter$decode,
				function (result) {
					if (!result.$) {
						var d = result.a;
						var data = _Utils_eq(
							d,
							A2(
								$elm$json$Json$Encode$encode,
								0,
								$author$project$App$Components$Filter$encode($author$project$App$Components$Filter$empty))) ? A2(
							$elm$json$Json$Encode$encode,
							0,
							$author$project$App$Components$Filter$encode(defaultFilter)) : d;
						return data;
					} else {
						return A2(
							$elm$json$Json$Encode$encode,
							0,
							$author$project$App$Components$Filter$encode(defaultFilter));
					}
				}(
					$truqu$elm_base64$Base64$decode(str))));
	});
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {by: frag, du: params, bl: unvisited, T: value, bV: visited};
	});
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.bV;
		var unvisited = _v0.bl;
		var params = _v0.du;
		var frag = _v0.by;
		var value = _v0.T;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.bV;
			var unvisited = _v1.bl;
			var params = _v1.du;
			var frag = _v1.by;
			var value = _v1.T;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.bV;
		var unvisited = _v0.bl;
		var params = _v0.du;
		var frag = _v0.by;
		var value = _v0.T;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $author$project$App$Data$Token$sessionFromURLString = function (str) {
	return str;
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.bV;
			var unvisited = _v0.bl;
			var params = _v0.du;
			var frag = _v0.by;
			var value = _v0.T;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $author$project$App$Route$matchers = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Public($author$project$App$Route$Loading),
			$elm$url$Url$Parser$s('carregando')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Public($author$project$App$Route$Login),
			$elm$url$Url$Parser$s('entrar')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Private($author$project$App$Route$AccessRestricted),
			$elm$url$Url$Parser$s('restrito')),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $author$project$App$Route$Public, $author$project$App$Route$DoctorResume),
				$author$project$App$Components$Filter$fromURLString($author$project$App$Components$Filter$empty)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('resumo_atendimentos'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Public($author$project$App$Route$FinanceSales),
			$elm$url$Url$Parser$s('faturamento')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Client(0),
			$elm$url$Url$Parser$s('funcionario')),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return A2($author$project$App$Route$Proponent, stmtId, 0);
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('atendimento'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('carregando'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return A2($author$project$App$Route$Proponent, stmtId, 1);
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('atendimento'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('redirecionando'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return A2($author$project$App$Route$Proponent, stmtId, 2);
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('atendimento'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('inicio'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return A2($author$project$App$Route$Proponent, stmtId, 9);
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('atendimento'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('pagamento'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return A2($author$project$App$Route$Proponent, stmtId, 3);
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('atendimento'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('questionario'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return A2($author$project$App$Route$Proponent, stmtId, 4);
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('atendimento'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('confirmando'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return A2($author$project$App$Route$Proponent, stmtId, 5);
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('atendimento'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('aguardando'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return A2($author$project$App$Route$Proponent, stmtId, 6);
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('atendimento'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('revisando'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return A2($author$project$App$Route$Proponent, stmtId, 7);
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('atendimento'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('video'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return A2($author$project$App$Route$Proponent, stmtId, 8);
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('atendimento'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('finalizando'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Public($author$project$App$Route$DoctorSignUp),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('medico'),
				$elm$url$Url$Parser$s('cadastrar'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Public($author$project$App$Route$UserSignUp),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('usuario'),
				$elm$url$Url$Parser$s('cadastrar'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Public($author$project$App$Route$UserSignUpComplete),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('usuario'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('cadastro'),
					$elm$url$Url$Parser$s('completo')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Public($author$project$App$Route$UserRequestRecoverPassword),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('usuario'),
				$elm$url$Url$Parser$s('recuperar'))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $author$project$App$Route$Public, $author$project$App$Route$UserRecoverPassword),
				$author$project$App$Data$Token$sessionFromURLString),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('recuperar-senha'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Private(
				$author$project$App$Route$Doctor($author$project$App$Route$DoctorLoading)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('medico'),
				$elm$url$Url$Parser$s('carregando'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Private(
				$author$project$App$Route$Doctor($author$project$App$Route$DoctorWaitingLine)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('medico'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('atendimento'),
					$elm$url$Url$Parser$s('fila')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Private(
				$author$project$App$Route$Doctor($author$project$App$Route$DoctorReviewStatement)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('medico'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('atendimento'),
					$elm$url$Url$Parser$s('revisando')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Private(
				$author$project$App$Route$Doctor(
					$author$project$App$Route$DoctorInterview(0))),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('medico'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('atendimento'),
					$elm$url$Url$Parser$s('carregando')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Private(
				$author$project$App$Route$Doctor(
					$author$project$App$Route$DoctorInterview(1))),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('medico'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('atendimento'),
					$elm$url$Url$Parser$s('online')))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Doctor),
				$author$project$App$Route$DoctorFinalEvaluation),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('medico'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('finalizando'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Doctor),
				$author$project$App$Route$DoctorViewEvaluation),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('medico'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('completo'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Doctor),
					$author$project$App$Route$DoctorListPending),
				$author$project$App$Components$Filter$fromURLString($author$project$App$Components$Filter$empty)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('medico'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('pendentes'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Doctor),
						$author$project$App$Route$DoctorListFinishedRoute),
					$author$project$App$Route$DoctorListFinished),
				$author$project$App$Components$Filter$fromURLString($author$project$App$Components$Filter$empty)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('medico'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('realizados'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			F2(
				function (stmtId, filterStr) {
					return $author$project$App$Route$Private(
						$author$project$App$Route$Doctor(
							$author$project$App$Route$DoctorListFinishedRoute(
								A2(
									$author$project$App$Route$DoctorInterviewDetails,
									stmtId,
									A2($author$project$App$Components$Filter$fromURLString, $author$project$App$Components$Filter$empty, filterStr)))));
				}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('medico'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('realizados'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Private(
				$author$project$App$Route$Doctor(
					$author$project$App$Route$DoctorSalesRoute(0))),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('medico'),
				$elm$url$Url$Parser$s('relatorio'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Private(
				$author$project$App$Route$Doctor($author$project$App$Route$DoctorListReevaluation)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('medico'),
				$elm$url$Url$Parser$s('reeval'))),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return $author$project$App$Route$Private(
					$author$project$App$Route$Doctor(
						$author$project$App$Route$DoctorReevaluationDetails(stmtId)));
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('medico'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('reeval'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						$elm$url$Url$Parser$string)))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Company),
						$author$project$App$Route$CompanyListPendingRoute),
					$author$project$App$Route$CompanyListPending),
				$author$project$App$Components$Filter$fromURLString($author$project$App$Components$Filter$empty)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('empresa'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('pendentes'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			F2(
				function (stmtId, filterStr) {
					return $author$project$App$Route$Private(
						$author$project$App$Route$Company(
							$author$project$App$Route$CompanyListPendingRoute(
								A2(
									$author$project$App$Route$CompanyListPendingViewDetails,
									stmtId,
									A2($author$project$App$Components$Filter$fromURLString, $author$project$App$Components$Filter$empty, filterStr)))));
				}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('empresa'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('pendentes'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Company),
						$author$project$App$Route$CompanyListCanceledRoute),
					$author$project$App$Route$CompanyListCanceled),
				$author$project$App$Components$Filter$fromURLString($author$project$App$Components$Filter$empty)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('empresa'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('cancelados'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			F2(
				function (stmtId, filterStr) {
					return $author$project$App$Route$Private(
						$author$project$App$Route$Company(
							$author$project$App$Route$CompanyListCanceledRoute(
								A2(
									$author$project$App$Route$CompanyListCanceledViewDetails,
									stmtId,
									A2($author$project$App$Components$Filter$fromURLString, $author$project$App$Components$Filter$empty, filterStr)))));
				}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('empresa'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('cancelados'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Company),
						$author$project$App$Route$CompanyListConcludedRoute),
					$author$project$App$Route$CompanyListConcluded),
				$author$project$App$Components$Filter$fromURLString($author$project$App$Components$Filter$empty)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('empresa'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('concluidos'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			F2(
				function (stmtId, filterStr) {
					return $author$project$App$Route$Private(
						$author$project$App$Route$Company(
							$author$project$App$Route$CompanyListConcludedRoute(
								A2(
									$author$project$App$Route$CompanyListConcludedViewDetails,
									stmtId,
									A2($author$project$App$Components$Filter$fromURLString, $author$project$App$Components$Filter$empty, filterStr)))));
				}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('empresa'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('concluidos'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Private(
				$author$project$App$Route$Company(
					$author$project$App$Route$CompanyRequestInterview($author$project$App$Route$CompanyRequestInterviewNew))),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('atendimento'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('requisitar'),
					$elm$url$Url$Parser$s('nova')))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Company),
					$author$project$App$Route$CompanyRequestInterview),
				$author$project$App$Route$CompanyRequestInterviewEdit),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('atendimento'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('requisitar'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('editar'),
						$elm$url$Url$Parser$string)))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Company),
				$author$project$App$Route$CompanyViewEvaluationRoute),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('empresa'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('atendimento'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('completo'),
						$elm$url$Url$Parser$string)))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Private(
				$author$project$App$Route$Company($author$project$App$Route$CompanyListReevaluation)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('empresa'),
				$elm$url$Url$Parser$s('reeval'))),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return $author$project$App$Route$Private(
					$author$project$App$Route$Company(
						$author$project$App$Route$CompanyReevaluationDetails(stmtId)));
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('empresa'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('reeval'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						$elm$url$Url$Parser$string)))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Review),
						$author$project$App$Route$ReviewListForReviewRoute),
					$author$project$App$Route$ReviewListForReview),
				$author$project$App$Components$Filter$fromURLString($author$project$App$Components$Filter$empty)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('revisao'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('pendentes'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			F2(
				function (stmtId, filterStr) {
					return $author$project$App$Route$Private(
						$author$project$App$Route$Review(
							$author$project$App$Route$ReviewListForReviewRoute(
								A2(
									$author$project$App$Route$ReviewListForReviewViewDetails,
									stmtId,
									A2($author$project$App$Components$Filter$fromURLString, $author$project$App$Components$Filter$empty, filterStr)))));
				}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('revisao'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('pendentes'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Review),
						$author$project$App$Route$ReviewListIncompleteRoute),
					$author$project$App$Route$ReviewListIncomplete),
				$author$project$App$Components$Filter$fromURLString($author$project$App$Components$Filter$empty)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('revisao'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('incompletos'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			F2(
				function (stmtId, filterStr) {
					return $author$project$App$Route$Private(
						$author$project$App$Route$Review(
							$author$project$App$Route$ReviewListIncompleteRoute(
								A2(
									$author$project$App$Route$ReviewListIncompleteViewDetails,
									stmtId,
									A2($author$project$App$Components$Filter$fromURLString, $author$project$App$Components$Filter$empty, filterStr)))));
				}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('revisao'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('incompletos'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Review),
						$author$project$App$Route$ReviewListApprovedRoute),
					$author$project$App$Route$ReviewListApproved),
				$author$project$App$Components$Filter$fromURLString($author$project$App$Components$Filter$empty)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('revisao'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('aprovados'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			F2(
				function (stmtId, filterStr) {
					return $author$project$App$Route$Private(
						$author$project$App$Route$Review(
							$author$project$App$Route$ReviewListApprovedRoute(
								A2(
									$author$project$App$Route$ReviewListApprovedViewDetails,
									stmtId,
									A2($author$project$App$Components$Filter$fromURLString, $author$project$App$Components$Filter$empty, filterStr)))));
				}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('revisao'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('aprovados'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Review),
					$author$project$App$Route$ReviewInterviewRoute),
				$elm$core$Basics$identity),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('revisao'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('pendentes'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('revisar'),
						$elm$url$Url$Parser$string)))),
			A2(
			$elm$url$Url$Parser$map,
			F2(
				function (stmtId, filterStr) {
					return $author$project$App$Route$Private(
						$author$project$App$Route$Review(
							$author$project$App$Route$ReviewEditStatementRoute(
								A2(
									$author$project$App$Route$ReviewEditStatement,
									stmtId,
									A2($author$project$App$Components$Filter$fromURLString, $author$project$App$Components$Filter$empty, filterStr)))));
				}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('revisao'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('incompletos'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('editar'),
						A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))))),
			A2(
			$elm$url$Url$Parser$map,
			F2(
				function (stmtId, filterStr) {
					return $author$project$App$Route$Private(
						$author$project$App$Route$Review(
							$author$project$App$Route$ReviewEditStatementRoute(
								A2(
									$author$project$App$Route$ReviewEditApprovedStatement,
									stmtId,
									A2($author$project$App$Components$Filter$fromURLString, $author$project$App$Components$Filter$empty, filterStr)))));
				}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('revisao'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('aprovados'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('editar'),
						A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Private(
				$author$project$App$Route$Review($author$project$App$Route$ReviewListReevaluation)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('revisao'),
				$elm$url$Url$Parser$s('reeval'))),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return $author$project$App$Route$Private(
					$author$project$App$Route$Review(
						$author$project$App$Route$ReviewReevaluationDetails(stmtId)));
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('revisao'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('reeval'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						$elm$url$Url$Parser$string)))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Private(
				$author$project$App$Route$Review($author$project$App$Route$ReviewWaitingLine)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('revisao'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('atendimento'),
					$elm$url$Url$Parser$s('fila')))),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return $author$project$App$Route$Private(
					$author$project$App$Route$Review(
						$author$project$App$Route$ReviewWaitingProponentDetails(stmtId)));
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('revisao'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('atendimento'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						$elm$url$Url$Parser$string)))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Support),
						$author$project$App$Route$SupportListForReviewRoute),
					$author$project$App$Route$SupportListForReview),
				$author$project$App$Components$Filter$fromURLString($author$project$App$Components$Filter$empty)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('suporte'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('pendentes'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			F2(
				function (stmtId, filterStr) {
					return $author$project$App$Route$Private(
						$author$project$App$Route$Support(
							$author$project$App$Route$SupportListForReviewRoute(
								A2(
									$author$project$App$Route$SupportListForReviewViewDetails,
									stmtId,
									A2($author$project$App$Components$Filter$fromURLString, $author$project$App$Components$Filter$empty, filterStr)))));
				}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('suporte'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('pendentes'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Support),
						$author$project$App$Route$SupportListIncompleteRoute),
					$author$project$App$Route$SupportListIncomplete),
				$author$project$App$Components$Filter$fromURLString($author$project$App$Components$Filter$empty)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('suporte'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('incompletos'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			F2(
				function (stmtId, filterStr) {
					return $author$project$App$Route$Private(
						$author$project$App$Route$Support(
							$author$project$App$Route$SupportListIncompleteRoute(
								A2(
									$author$project$App$Route$SupportListIncompleteViewDetails,
									stmtId,
									A2($author$project$App$Components$Filter$fromURLString, $author$project$App$Components$Filter$empty, filterStr)))));
				}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('suporte'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('incompletos'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Support),
						$author$project$App$Route$SupportListApprovedRoute),
					$author$project$App$Route$SupportListApproved),
				$author$project$App$Components$Filter$fromURLString($author$project$App$Components$Filter$empty)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('suporte'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('aprovados'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			F2(
				function (stmtId, filterStr) {
					return $author$project$App$Route$Private(
						$author$project$App$Route$Support(
							$author$project$App$Route$SupportListApprovedRoute(
								A2(
									$author$project$App$Route$SupportListApprovedViewDetails,
									stmtId,
									A2($author$project$App$Components$Filter$fromURLString, $author$project$App$Components$Filter$empty, filterStr)))));
				}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('suporte'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('aprovados'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Support),
					$author$project$App$Route$SupportInterviewRoute),
				$elm$core$Basics$identity),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('suporte'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('pendentes'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('revisar'),
						$elm$url$Url$Parser$string)))),
			A2(
			$elm$url$Url$Parser$map,
			F2(
				function (stmtId, filterStr) {
					return $author$project$App$Route$Private(
						$author$project$App$Route$Support(
							$author$project$App$Route$SupportEditStatementRoute(
								A2(
									$author$project$App$Route$SupportEditStatement,
									stmtId,
									A2($author$project$App$Components$Filter$fromURLString, $author$project$App$Components$Filter$empty, filterStr)))));
				}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('suporte'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('incompletos'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('editar'),
						A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))))),
			A2(
			$elm$url$Url$Parser$map,
			F2(
				function (stmtId, filterStr) {
					return $author$project$App$Route$Private(
						$author$project$App$Route$Support(
							$author$project$App$Route$SupportEditStatementRoute(
								A2(
									$author$project$App$Route$SupportEditApprovedStatement,
									stmtId,
									A2($author$project$App$Components$Filter$fromURLString, $author$project$App$Components$Filter$empty, filterStr)))));
				}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('suporte'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('aprovados'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('editar'),
						A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Private(
				$author$project$App$Route$Support($author$project$App$Route$SupportListReevaluation)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('revisao'),
				$elm$url$Url$Parser$s('reeval'))),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return $author$project$App$Route$Private(
					$author$project$App$Route$Support(
						$author$project$App$Route$SupportReevaluationDetails(stmtId)));
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('suporte'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('reeval'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						$elm$url$Url$Parser$string)))),
			A2(
			$elm$url$Url$Parser$map,
			A3(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Admin),
				$author$project$App$Route$AdminListUsersRoute,
				0),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('administrativo'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('listar'),
					$elm$url$Url$Parser$s('usuarios')))),
			A2(
			$elm$url$Url$Parser$map,
			A3(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Admin),
				$author$project$App$Route$AdminListDoctorsRoute,
				0),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('administrativo'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('listar'),
					$elm$url$Url$Parser$s('medicos')))),
			A2(
			$elm$url$Url$Parser$map,
			A3(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Admin),
				$author$project$App$Route$AdminFinanceSalesRoute,
				0),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('administrativo'),
				$elm$url$Url$Parser$s('relatorio'))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Admin),
				$author$project$App$Route$AdminViewEvaluationRoute),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('administrativo'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('atendimento'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('completo'),
						$elm$url$Url$Parser$string)))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $author$project$App$Route$Private, $author$project$App$Route$Admin),
						$author$project$App$Route$AdminListFinishedRoute),
					$author$project$App$Route$AdminListFinished),
				$author$project$App$Components$Filter$fromURLString($author$project$App$Components$Filter$empty)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('administrativo'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('realizados'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			F2(
				function (stmtId, filterStr) {
					return $author$project$App$Route$Private(
						$author$project$App$Route$Admin(
							$author$project$App$Route$AdminListFinishedRoute(
								A2(
									$author$project$App$Route$AdminInterviewDetails,
									stmtId,
									A2($author$project$App$Components$Filter$fromURLString, $author$project$App$Components$Filter$empty, filterStr)))));
				}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('administrativo'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('realizados'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$App$Route$Private(
				$author$project$App$Route$Admin($author$project$App$Route$AdminListReevaluation)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('administrativo'),
				$elm$url$Url$Parser$s('reeval'))),
			A2(
			$elm$url$Url$Parser$map,
			function (stmtId) {
				return $author$project$App$Route$Private(
					$author$project$App$Route$Admin(
						$author$project$App$Route$AdminReevaluationDetails(stmtId)));
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('administrativo'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('reeval'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('detalhes'),
						$elm$url$Url$Parser$string))))
		]));
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.bl;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.T);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.T);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.hu),
					$elm$url$Url$Parser$prepareQuery(url.R),
					url.gU,
					$elm$core$Basics$identity)));
	});
var $author$project$App$Route$parseUrl = function (url) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$App$Route$Public($author$project$App$Route$Login),
		A2($elm$url$Url$Parser$parse, $author$project$App$Route$matchers, url));
};
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $author$project$App$SharedState$VideoCmd = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$SharedState$Advancing = 1;
var $author$project$App$SharedState$FullLoad = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$SharedState$FullLoadForReview = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$SharedState$FullLoadWithMinors = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$SharedState$Reverting = 2;
var $author$project$App$Page$ResponseMsgs$SharedStatementLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Service$SpecificResources$TypeToken = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Data$Id$timeEncoder = function (t) {
	return $elm$json$Json$Encode$int(
		$elm$time$Time$posixToMillis(t));
};
var $author$project$App$Data$Id$uuidEncoder = function (id) {
	return (($elm$core$String$length(id) > 0) && $author$project$App$Data$Id$uuidIsValid(id)) ? $elm$json$Json$Encode$string(id) : $elm$json$Json$Encode$null;
};
var $author$project$App$Data$Id$uuidMaybeEncoder = function (mId) {
	if (!mId.$) {
		var id = mId.a;
		return $author$project$App$Data$Id$uuidEncoder(id);
	} else {
		return $elm$json$Json$Encode$null;
	}
};
var $author$project$App$Data$User$encoder = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$author$project$App$Data$Id$uuidEncoder(model.as)),
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(model.c7)),
				_Utils_Tuple2(
				'password',
				$elm$json$Json$Encode$string(model.bf)),
				_Utils_Tuple2(
				'passwordCheck',
				$elm$json$Json$Encode$string(model.dv)),
				_Utils_Tuple2(
				'passwordOld',
				$elm$json$Json$Encode$string(model.eE)),
				_Utils_Tuple2(
				'personId',
				$author$project$App$Data$Id$uuidEncoder(model.Y)),
				_Utils_Tuple2(
				'companyId',
				$author$project$App$Data$Id$uuidMaybeEncoder(model.gx)),
				_Utils_Tuple2(
				'activatedAt',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$App$Data$Id$timeEncoder, model.e3))),
				_Utils_Tuple2(
				'createdAt',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$App$Data$Id$timeEncoder, model.iW)))
			]));
};
var $author$project$App$Service$ApiWebSocket$SigningIn = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$App$Service$Shared$pathToRID = function (_v0) {
	var rid = _v0;
	return rid;
};
var $author$project$App$Service$Shared$ridToString = function (_v0) {
	var service = _v0.a;
	var endpoint = _v0.b;
	var query = _v0.c;
	return $elm$core$String$isEmpty(endpoint) ? _Utils_ap(
		service,
		_Utils_ap(
			endpoint,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function (v) {
						return '?' + v;
					},
					query)))) : (service + ('.' + (endpoint + A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			function (v) {
				return '?' + v;
			},
			query)))));
};
var $author$project$App$Service$Shared$pathToString = A2($elm$core$Basics$composeR, $author$project$App$Service$Shared$pathToRID, $author$project$App$Service$Shared$ridToString);
var $author$project$App$Service$Shared$methodToString = function (m) {
	switch (m.a) {
		case 0:
			var _v1 = m.a;
			return 'version';
		case 1:
			var _v2 = m.a;
			var path = m.b;
			var name = m.c;
			return 'auth.' + ($author$project$App$Service$Shared$pathToString(path) + ('.' + name));
		case 2:
			var _v3 = m.a;
			var path = m.b;
			var name = m.c;
			return 'call.' + ($author$project$App$Service$Shared$pathToString(path) + ('.' + name));
		default:
			var _v4 = m.a;
			var path = m.b;
			var name = m.c;
			return 'new.' + ($author$project$App$Service$Shared$pathToString(path) + ('.' + name));
	}
};
var $author$project$App$Service$ApiWebSocket$clearMethod = F2(
	function (me, _v0) {
		var id = _v0.a;
		var now = _v0.b;
		var smo = _v0.c;
		var sme = _v0.d;
		var ws = _v0.e;
		var sc = _v0.f;
		var cm = _v0.g;
		var c = _v0.h;
		return A8(
			$author$project$App$Service$ApiWebSocket$Resgate,
			id,
			now,
			smo,
			sme,
			ws,
			sc,
			cm,
			A2(
				$elm$core$Dict$remove,
				$author$project$App$Service$Shared$methodToString(me),
				c));
	});
var $author$project$App$Service$ApiWebSocket$ResourceAvailable = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$App$Service$ApiWebSocket$ResourceError = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$App$Service$ApiWebSocket$ResourceRequested = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $author$project$App$Service$ApiWebSocket$concatResponses = F2(
	function (list1, list2) {
		var list1Cleared = A2(
			$elm$core$List$filter,
			function (i) {
				return $elm$core$List$isEmpty(
					A2(
						$elm$core$List$filter,
						function (j) {
							return _Utils_eq(j, i);
						},
						list2));
			},
			list1);
		var list2Cleared = A2(
			$elm$core$List$filter,
			function (i) {
				return $elm$core$List$isEmpty(
					A2(
						$elm$core$List$filter,
						function (j) {
							return _Utils_eq(j, i);
						},
						list1Cleared));
			},
			list2);
		return _Utils_ap(list1Cleared, list2Cleared);
	});
var $author$project$App$Service$ApiWebSocket$getMethodResponse = F2(
	function (me, _v0) {
		var cache = _v0.h;
		return A2(
			A2($elm$core$Basics$composeL, $elm$core$Dict$get, $author$project$App$Service$Shared$methodToString),
			me,
			cache);
	});
var $author$project$App$Service$ApiWebSocket$ResourceWaitingRequest = F6(
	function (a, b, c, d, e, f) {
		return {$: 0, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $author$project$App$Service$ApiWebSocket$WebsocketMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Service$Websocket$hostFromModel = function (m) {
	var host = m.a;
	return host;
};
var $author$project$App$Service$Websocket$websocketConnect = _Platform_outgoingPort(
	'websocketConnect',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'cid',
					$elm$json$Json$Encode$int($.e9)),
					_Utils_Tuple2(
					'host',
					$elm$json$Json$Encode$string($.fw))
				]));
	});
var $author$project$App$Service$Websocket$connect = F2(
	function (connId, m) {
		switch (m.b) {
			case 2:
				var _v1 = m.b;
				return _Utils_Tuple2(m, $elm$core$Platform$Cmd$none);
			case 1:
				var _v2 = m.b;
				return _Utils_Tuple2(m, $elm$core$Platform$Cmd$none);
			default:
				var _v3 = m.b;
				return _Utils_Tuple2(
					m,
					$author$project$App$Service$Websocket$websocketConnect(
						{
							e9: connId,
							fw: $author$project$App$Service$Websocket$hostFromModel(m)
						}));
		}
	});
var $author$project$App$Service$Websocket$isConnected = function (m) {
	if (m.b === 2) {
		var _v1 = m.b;
		return true;
	} else {
		return false;
	}
};
var $elm$core$Basics$neq = _Utils_notEqual;
var $author$project$App$Service$ApiWebSocket$resourceRequestEncode = function (rr) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(rr.as)),
				_Utils_Tuple2(
				'method',
				$elm$json$Json$Encode$string(rr.z)),
				function () {
				var _v0 = rr.du;
				if (!_v0.$) {
					var params = _v0.a;
					return _Utils_Tuple2('params', params);
				} else {
					return _Utils_Tuple2('params', $elm$json$Json$Encode$null);
				}
			}()
			]));
};
var $author$project$App$Service$Websocket$websocketSend = _Platform_outgoingPort('websocketSend', $elm$json$Json$Encode$string);
var $author$project$App$Service$Websocket$send = F2(
	function (v, m) {
		if (m.b === 2) {
			var _v1 = m.b;
			return _Utils_Tuple2(
				m,
				$author$project$App$Service$Websocket$websocketSend(
					A2($elm$json$Json$Encode$encode, 0, v)));
		} else {
			return _Utils_Tuple2(m, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Service$ApiWebSocket$requestOnWebsocket = F6(
	function (rid, request, auth, t, responseMsgs, _v0) {
		var id = _v0.a;
		var now = _v0.b;
		var smo = _v0.c;
		var sme = _v0.d;
		var ws = _v0.e;
		var sc = _v0.f;
		var cm = _v0.g;
		var c = _v0.h;
		if ($author$project$App$Service$Websocket$isConnected(ws) && (!(!$elm$time$Time$posixToMillis(now)))) {
			var nCm = A3(
				$elm$core$Dict$insert,
				id,
				_Utils_Tuple2(rid, t),
				cm);
			var nC = A3(
				$elm$core$Dict$insert,
				rid,
				A2($author$project$App$Service$ApiWebSocket$ResourceRequested, now, responseMsgs),
				c);
			var _v1 = A2(
				$author$project$App$Service$Websocket$send,
				$author$project$App$Service$ApiWebSocket$resourceRequestEncode(request),
				ws);
			var wsModel = _v1.a;
			var wsCmd = _v1.b;
			return _Utils_Tuple2(
				A8($author$project$App$Service$ApiWebSocket$Resgate, id + 1, now, smo, sme, wsModel, sc, nCm, nC),
				A2($elm$core$Platform$Cmd$map, $author$project$App$Service$ApiWebSocket$WebsocketMsg, wsCmd));
		} else {
			var nCm = A3(
				$elm$core$Dict$insert,
				id,
				_Utils_Tuple2(rid, t),
				cm);
			var nC = A3(
				$elm$core$Dict$insert,
				rid,
				A6(
					$author$project$App$Service$ApiWebSocket$ResourceWaitingRequest,
					now,
					auth,
					id,
					_Utils_Tuple2(rid, t),
					responseMsgs,
					$author$project$App$Service$ApiWebSocket$resourceRequestEncode(request)),
				c);
			var _v2 = A2(
				$author$project$App$Service$Websocket$connect,
				$elm$time$Time$posixToMillis(now),
				ws);
			var wsModel = _v2.a;
			var wsCmd = _v2.b;
			return _Utils_Tuple2(
				A8($author$project$App$Service$ApiWebSocket$Resgate, id + 1, now, smo, sme, wsModel, sc, nCm, nC),
				A2($elm$core$Platform$Cmd$map, $author$project$App$Service$ApiWebSocket$WebsocketMsg, wsCmd));
		}
	});
var $author$project$App$Service$ApiWebSocket$executeMethod = F6(
	function (reqAuth, t, method, params, respMsgs, _v0) {
		var id = _v0.a;
		var now = _v0.b;
		var smo = _v0.c;
		var sme = _v0.d;
		var ws = _v0.e;
		var sc = _v0.f;
		var cm = _v0.g;
		var c = _v0.h;
		var _v1 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			method,
			A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, c));
		if (_v1.$ === 1) {
			var rid = $author$project$App$Service$Shared$methodToString(method);
			var request = {
				as: id,
				z: rid,
				du: $elm$core$Maybe$Just(params)
			};
			return A6(
				$author$project$App$Service$ApiWebSocket$requestOnWebsocket,
				rid,
				request,
				reqAuth,
				t,
				respMsgs,
				A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, c));
		} else {
			switch (_v1.a.$) {
				case 1:
					var _v2 = _v1.a;
					var when = _v2.a;
					var currentResponses = _v2.b;
					var nResps = A2($author$project$App$Service$ApiWebSocket$concatResponses, currentResponses, respMsgs);
					var uC = A3(
						$elm$core$Dict$insert,
						$author$project$App$Service$Shared$methodToString(method),
						A2($author$project$App$Service$ApiWebSocket$ResourceRequested, when, nResps),
						c);
					return _Utils_Tuple2(
						A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, uC),
						$elm$core$Platform$Cmd$none);
				case 2:
					var _v3 = _v1.a;
					var currentResponses = _v3.a;
					var error = _v3.b;
					var nResps = A2($author$project$App$Service$ApiWebSocket$concatResponses, currentResponses, respMsgs);
					var uC = A3(
						$elm$core$Dict$insert,
						$author$project$App$Service$Shared$methodToString(method),
						A2($author$project$App$Service$ApiWebSocket$ResourceError, nResps, error),
						c);
					return _Utils_Tuple2(
						A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, uC),
						$author$project$App$Service$ApiWebSocket$asyncMsg(
							$author$project$App$Service$ApiWebSocket$ResponseAlreadyAvailable(nResps)));
				case 3:
					var _v4 = _v1.a;
					var currentResponses = _v4.b;
					var value = _v4.c;
					var nResps = A2($author$project$App$Service$ApiWebSocket$concatResponses, currentResponses, respMsgs);
					var uC = A3(
						$elm$core$Dict$insert,
						$author$project$App$Service$Shared$methodToString(method),
						A3($author$project$App$Service$ApiWebSocket$ResourceAvailable, t, nResps, value),
						c);
					return _Utils_Tuple2(
						A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, uC),
						$author$project$App$Service$ApiWebSocket$asyncMsg(
							$author$project$App$Service$ApiWebSocket$ResponseAlreadyAvailable(nResps)));
				default:
					return _Utils_Tuple2(
						A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, c),
						$elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$App$Service$ApiWebSocket$login = F5(
	function (lType, cred, mRespMsg, name, _v0) {
		var id = _v0.a;
		var now = _v0.b;
		var smo = _v0.c;
		var rMethod = _v0.d;
		var ws = _v0.e;
		var sc = _v0.f;
		var cm = _v0.g;
		var c = _v0.h;
		var _v1 = _Utils_Tuple2(lType, smo);
		_v1$2:
		while (true) {
			if (_v1.a.$ === 2) {
				switch (_v1.b.$) {
					case 0:
						var lMethod = _v1.a.a;
						var _v2 = _v1.b.a;
						return A6(
							$author$project$App$Service$ApiWebSocket$executeMethod,
							false,
							lType,
							lMethod,
							cred,
							mRespMsg,
							A2(
								$author$project$App$Service$ApiWebSocket$clearMethod,
								lMethod,
								A8(
									$author$project$App$Service$ApiWebSocket$Resgate,
									id,
									now,
									A2(
										$author$project$App$Service$ApiWebSocket$SigningIn,
										lMethod,
										A3(
											$author$project$App$Service$ApiWebSocket$InternalToken,
											name,
											$elm$core$Maybe$Just(now),
											$elm$core$Maybe$Just(cred))),
									rMethod,
									ws,
									sc,
									cm,
									c)));
					case 1:
						var lMethod = _v1.a.a;
						var _v3 = _v1.b;
						var _v4 = _v3.b;
						return A6(
							$author$project$App$Service$ApiWebSocket$executeMethod,
							false,
							lType,
							lMethod,
							cred,
							mRespMsg,
							A2(
								$author$project$App$Service$ApiWebSocket$clearMethod,
								lMethod,
								A8(
									$author$project$App$Service$ApiWebSocket$Resgate,
									id,
									now,
									A2(
										$author$project$App$Service$ApiWebSocket$SigningIn,
										lMethod,
										A3(
											$author$project$App$Service$ApiWebSocket$InternalToken,
											name,
											$elm$core$Maybe$Just(now),
											$elm$core$Maybe$Just(cred))),
									rMethod,
									ws,
									sc,
									cm,
									c)));
					default:
						break _v1$2;
				}
			} else {
				break _v1$2;
			}
		}
		return _Utils_Tuple2(
			A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, rMethod, ws, sc, cm, c),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$App$Service$ApplicationResources$serviceUser = 'userService';
var $author$project$App$Service$ApplicationResources$ridUserSign = $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceUser + '.sign');
var $author$project$App$Service$ApplicationResources$pathUserSign = $author$project$App$Service$Shared$pathFromRID($author$project$App$Service$ApplicationResources$ridUserSign);
var $author$project$App$Service$ApplicationResources$methodUserSignIn = A3($author$project$App$Service$Shared$methodFromPath, 1, $author$project$App$Service$ApplicationResources$pathUserSign, 'in');
var $author$project$App$Service$ApplicationResources$authUserSignIn = F3(
	function (user, respMsg, api) {
		return A5(
			$author$project$App$Service$ApiWebSocket$login,
			$author$project$App$Service$SpecificResources$TypeToken($author$project$App$Service$ApplicationResources$methodUserSignIn),
			$author$project$App$Data$User$encoder(user),
			respMsg,
			$author$project$App$Constants$appNameInternal,
			api);
	});
var $author$project$App$Constants$appNameInterview = 'medsyn-interview';
var $author$project$App$Constants$appSignInterviewMethod = function () {
	var rid = $author$project$App$Service$Shared$ridFromString('userService.sign');
	var path = $author$project$App$Service$Shared$pathFromRID(rid);
	return A3($author$project$App$Service$Shared$methodFromPath, 1, path, 'notification');
}();
var $author$project$App$Service$ApplicationResources$authUserSignNotification = F3(
	function (stmtId, respMsgs, api) {
		return A5(
			$author$project$App$Service$ApiWebSocket$login,
			$author$project$App$Service$SpecificResources$TypeToken($author$project$App$Constants$appSignInterviewMethod),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'statementId',
						$elm$json$Json$Encode$string(stmtId))
					])),
			respMsgs,
			$author$project$App$Constants$appNameInterview,
			api);
	});
var $author$project$App$Service$SpecificResources$TypeStatementResult = function (a) {
	return {$: 29, a: a};
};
var $author$project$App$Data$Statement$relationToString = function (relation) {
	switch (relation) {
		case 0:
			return '';
		case 1:
			return 'spouse';
		case 2:
			return 'child';
		default:
			return 'grandchild';
	}
};
var $author$project$App$Data$Statement$relationEncoder = function (relation) {
	return $elm$json$Json$Encode$string(
		$author$project$App$Data$Statement$relationToString(relation));
};
var $author$project$App$Data$Statement$shouldInterviewToInt = function (shouldInterview) {
	switch (shouldInterview) {
		case 0:
			return 0;
		case 1:
			return 1;
		default:
			return 2;
	}
};
var $author$project$App$Data$Statement$shouldInterviewEncoder = function (shouldInterview) {
	return $elm$json$Json$Encode$int(
		$author$project$App$Data$Statement$shouldInterviewToInt(shouldInterview));
};
var $author$project$App$Data$StatementStatus$statusToInt = function (status) {
	switch (status) {
		case 0:
			return 0;
		case 1:
			return 3;
		case 2:
			return 5;
		case 3:
			return 10;
		case 4:
			return 15;
		case 5:
			return 16;
		case 6:
			return 20;
		case 7:
			return 23;
		case 10:
			return 24;
		case 8:
			return 25;
		case 9:
			return 26;
		case 11:
			return 27;
		case 12:
			return 28;
		case 13:
			return 30;
		case 14:
			return 31;
		case 15:
			return 32;
		case 16:
			return 33;
		case 17:
			return 34;
		case 18:
			return 35;
		case 19:
			return 37;
		case 20:
			return 38;
		case 26:
			return 50;
		case 21:
			return 39;
		case 22:
			return 40;
		case 23:
			return 41;
		case 24:
			return 42;
		case 25:
			return 43;
		case 27:
			return 44;
		case 28:
			return 45;
		case 30:
			return 46;
		case 31:
			return 47;
		case 29:
			return 60;
		case 32:
			return 63;
		case 33:
			return 65;
		case 34:
			return 70;
		case 35:
			return 75;
		default:
			return 80;
	}
};
var $author$project$App$Data$StatementStatus$statusEncoder = function (status) {
	return $elm$json$Json$Encode$int(
		$author$project$App$Data$StatementStatus$statusToInt(status));
};
var $author$project$App$Data$Statement$encoder = function (stmt) {
	return $elm$json$Json$Encode$object(
		_Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$author$project$App$Data$Id$uuidEncoder(stmt.as)),
					_Utils_Tuple2(
					'parentId',
					$author$project$App$Data$Id$uuidMaybeEncoder(stmt.jK)),
					_Utils_Tuple2(
					'personId',
					$author$project$App$Data$Id$uuidEncoder(stmt.Y)),
					_Utils_Tuple2(
					'companyId',
					$author$project$App$Data$Id$uuidEncoder(stmt.gx)),
					_Utils_Tuple2(
					'templateId',
					$author$project$App$Data$Id$uuidEncoder(stmt.eY)),
					_Utils_Tuple2(
					'companyWeight',
					$elm$json$Json$Encode$int(stmt.iT)),
					_Utils_Tuple2(
					'companyHeight',
					$elm$json$Json$Encode$int(stmt.iS)),
					_Utils_Tuple2(
					'companySectionIndex',
					$elm$json$Json$Encode$int(stmt.br)),
					_Utils_Tuple2(
					'personWeight',
					$elm$json$Json$Encode$int(stmt.jN)),
					_Utils_Tuple2(
					'personHeight',
					$elm$json$Json$Encode$int(stmt.jL)),
					_Utils_Tuple2(
					'personSectionIndex',
					$elm$json$Json$Encode$int(stmt.bG)),
					_Utils_Tuple2(
					'doctorWeight',
					$elm$json$Json$Encode$int(stmt.ap)),
					_Utils_Tuple2(
					'doctorHeight',
					$elm$json$Json$Encode$int(stmt.ao)),
					_Utils_Tuple2(
					'doctorSectionIndex',
					$elm$json$Json$Encode$int(stmt.bv)),
					_Utils_Tuple2(
					'status',
					$author$project$App$Data$StatementStatus$statusEncoder(stmt.aw)),
					_Utils_Tuple2(
					'observation',
					$elm$json$Json$Encode$string(stmt.jx)),
					_Utils_Tuple2(
					'childrenCount',
					$elm$json$Json$Encode$int(stmt.bo)),
					_Utils_Tuple2(
					'shouldInterview',
					$author$project$App$Data$Statement$shouldInterviewEncoder(stmt.j_)),
					_Utils_Tuple2(
					'dueDate',
					$author$project$App$Data$Id$timeEncoder(stmt.gL)),
					_Utils_Tuple2(
					'createdAt',
					$author$project$App$Data$Id$timeEncoder(stmt.iW)),
					_Utils_Tuple2(
					'createdBy',
					$author$project$App$Data$Id$uuidMaybeEncoder(stmt.iX)),
					_Utils_Tuple2(
					'updatedAt',
					$author$project$App$Data$Id$timeEncoder(stmt.f2)),
					_Utils_Tuple2(
					'scheduleDateTime',
					$author$project$App$Data$Id$timeEncoder(stmt.jU))
				]),
			_Utils_ap(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2(
						$elm$core$Maybe$map,
						function (relation) {
							return _List_fromArray(
								[
									_Utils_Tuple2(
									'parentRelation',
									$author$project$App$Data$Statement$relationEncoder(relation))
								]);
						},
						stmt.be)),
				_Utils_ap(
					A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						A2(
							$elm$core$Maybe$map,
							function (dId) {
								return _List_fromArray(
									[
										_Utils_Tuple2(
										'doctorId',
										$author$project$App$Data$Id$uuidEncoder(dId))
									]);
							},
							stmt.fl)),
					A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						A2(
							$elm$core$Maybe$map,
							function (dId) {
								return _List_fromArray(
									[
										_Utils_Tuple2(
										'recordedDoctorId',
										$author$project$App$Data$Id$uuidEncoder(dId))
									]);
							},
							stmt.bI))))));
};
var $author$project$App$Service$ApplicationResources$ifUUIDIsValid = F3(
	function (id, api, f) {
		return $author$project$App$Data$Id$uuidIsValid(id) ? f(api) : _Utils_Tuple2(api, $elm$core$Platform$Cmd$none);
	});
var $author$project$App$Service$Shared$Call = 2;
var $author$project$App$Service$ApplicationResources$serviceStatement = 'statementService';
var $author$project$App$Service$ApplicationResources$ridStatementById = function (stmtId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatement + ('.byId.' + stmtId));
};
var $author$project$App$Service$ApplicationResources$pathStatementById = function (stmtId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridStatementById(stmtId));
};
var $author$project$App$Service$ApplicationResources$methodAdvanceDoctorStatementById = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
		'advanceScript');
};
var $author$project$App$Service$ApplicationResources$callAdvanceDoctorStatement = F3(
	function (stmt, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmt.as,
			api,
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementResult(
					$author$project$App$Service$ApplicationResources$methodAdvanceDoctorStatementById(stmt.as)),
				$author$project$App$Service$ApplicationResources$methodAdvanceDoctorStatementById(stmt.as),
				$author$project$App$Data$Statement$encoder(stmt),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$ridProponentStatementById = function (stmtId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatement + ('.proponent.' + stmtId));
};
var $author$project$App$Service$ApplicationResources$pathProponentStatementById = function (stmtId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridProponentStatementById(stmtId));
};
var $author$project$App$Service$ApplicationResources$methodAdvanceProponentStatementById = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathProponentStatementById(stmtId),
		'advance');
};
var $author$project$App$Service$ApplicationResources$callAdvanceProponentStatement = F3(
	function (stmt, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmt.as,
			api,
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementResult(
					$author$project$App$Service$ApplicationResources$methodAdvanceProponentStatementById(stmt.as)),
				$author$project$App$Service$ApplicationResources$methodAdvanceProponentStatementById(stmt.as),
				$author$project$App$Data$Statement$encoder(stmt),
				respMsgs));
	});
var $author$project$App$Service$SpecificResources$TypeUnknown = {$: 0};
var $author$project$App$Service$ApplicationResources$callApproveStatement = F3(
	function (stmtId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeUnknown,
			A3(
				$author$project$App$Service$Shared$methodFromPath,
				2,
				$author$project$App$Service$Shared$pathFromRID(
					$author$project$App$Service$Shared$ridFromString('statementService.byId.' + stmtId)),
				'accept'),
			$elm$json$Json$Encode$null,
			respMsgs,
			api);
	});
var $author$project$App$Service$SpecificResources$TypeQuestionSectionFull = function (a) {
	return {$: 26, a: a};
};
var $author$project$App$Data$QuestionSection$categoryToString = function (cat) {
	switch (cat) {
		case 1:
			return 'company';
		case 2:
			return 'person';
		case 3:
			return 'doctor';
		default:
			return 'unknown';
	}
};
var $author$project$App$Service$ApplicationResources$serviceQuestionSection = 'questionSectionService';
var $author$project$App$Service$ApplicationResources$ridSectionFromTemplateAndCategory = F2(
	function (templateId, category) {
		return $author$project$App$Service$Shared$ridFromString(
			$author$project$App$Service$ApplicationResources$serviceQuestionSection + ('.fromTemplate.' + (templateId + ('.category.' + $author$project$App$Data$QuestionSection$categoryToString(category)))));
	});
var $author$project$App$Service$ApplicationResources$pathSectionFromTemplateAndCategory = F2(
	function (templateId, category) {
		return $author$project$App$Service$Shared$pathFromRID(
			A2($author$project$App$Service$ApplicationResources$ridSectionFromTemplateAndCategory, templateId, category));
	});
var $author$project$App$Service$ApplicationResources$methodCompleteSectionFromTemplateAndCategory = F2(
	function (templateId, category) {
		return A3(
			$author$project$App$Service$Shared$methodFromPath,
			2,
			A2($author$project$App$Service$ApplicationResources$pathSectionFromTemplateAndCategory, templateId, category),
			'complete');
	});
var $author$project$App$Service$ApplicationResources$callCompleteSectionFromTemplateAndCategory = F4(
	function (templateId, category, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			templateId,
			api,
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeQuestionSectionFull(
					A2($author$project$App$Service$ApplicationResources$methodCompleteSectionFromTemplateAndCategory, templateId, category)),
				A2($author$project$App$Service$ApplicationResources$methodCompleteSectionFromTemplateAndCategory, templateId, category),
				$elm$json$Json$Encode$null,
				respMsgs));
	});
var $author$project$App$Service$SpecificResources$TypeUserResult = function (a) {
	return {$: 35, a: a};
};
var $author$project$App$Data$Contact$kindEncoder = function (kind) {
	return $elm$json$Json$Encode$string(
		$author$project$App$Data$Contact$kindToString(kind));
};
var $author$project$App$Data$Contact$encoder = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$author$project$App$Data$Id$uuidEncoder(model.as)),
				_Utils_Tuple2(
				'ownerId',
				$author$project$App$Data$Id$uuidEncoder(model.jJ)),
				_Utils_Tuple2(
				'kind',
				$author$project$App$Data$Contact$kindEncoder(model.hb)),
				_Utils_Tuple2(
				'value',
				$elm$json$Json$Encode$string(model.T))
			]));
};
var $author$project$App$Data$DigitalAccount$bankModelEncoder = function (bankModel) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'bankNumber',
				$elm$json$Json$Encode$string(bankModel.d4)),
				_Utils_Tuple2(
				'agencyNumber',
				$elm$json$Json$Encode$string(bankModel.d1)),
				_Utils_Tuple2(
				'accountNumber',
				$elm$json$Json$Encode$string(bankModel.d_)),
				_Utils_Tuple2(
				'accountType',
				$elm$json$Json$Encode$string(bankModel.d$))
			]));
};
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {gv: col, iV: contextStack, hC: problem, hT: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.hT, s.gv, x, s.l));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.d),
			s.f) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.d);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.f, offset) < 0,
					0,
					{gv: col, l: s0.l, n: s0.n, f: offset, hT: row, d: s0.d});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.f, s.hT, s.gv, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.f, s1.f, s0.d),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	return A2(
		$elm$parser$Parser$andThen,
		function (str) {
			if (_Utils_eq(
				$elm$core$String$length(str),
				quantity)) {
				var _v0 = $elm$core$String$toInt(str);
				if (!_v0.$) {
					var intVal = _v0.a;
					return $elm$parser$Parser$succeed(intVal);
				} else {
					return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
				}
			} else {
				return $elm$parser$Parser$problem(
					'Expected ' + ($elm$core$String$fromInt(quantity) + (' digits, but got ' + $elm$core$String$fromInt(
						$elm$core$String$length(str)))));
			}
		},
		$elm$parser$Parser$getChompedString(
			$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.f, s.hT, s.gv, s.d);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{gv: newCol, l: s.l, n: s.n, f: newOffset, hT: newRow, d: s.d});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4),
					$elm$parser$Parser$symbol('-'))),
			A2(
				$elm$parser$Parser$ignorer,
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
				$elm$parser$Parser$symbol('-'))),
		$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)));
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetMinutesFromParts = F3(
	function (multiplier, hours, minutes) {
		return multiplier * ((hours * 60) + minutes);
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									A2(
										$elm$parser$Parser$ignorer,
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
										$elm$parser$Parser$symbol(':'))),
								A2(
									$elm$parser$Parser$ignorer,
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
									$elm$parser$Parser$symbol(':'))),
							$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$map,
								function (_v0) {
									return 0;
								},
								$elm$parser$Parser$symbol('Z')),
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$keeper,
										$elm$parser$Parser$succeed($rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetMinutesFromParts),
										$elm$parser$Parser$oneOf(
											_List_fromArray(
												[
													A2(
													$elm$parser$Parser$map,
													function (_v1) {
														return 1;
													},
													$elm$parser$Parser$symbol('+')),
													A2(
													$elm$parser$Parser$map,
													function (_v2) {
														return -1;
													},
													$elm$parser$Parser$symbol('-'))
												]))),
									A2(
										$elm$parser$Parser$ignorer,
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
										$elm$parser$Parser$symbol(':'))),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2))
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {gv: col, hC: problem, hT: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.hT, p.gv, p.hC);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{gv: 1, l: _List_Nil, n: 1, f: 0, hT: 1, d: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$App$Data$DigitalAccount$companyEncoder = function (company) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'officialName',
				$elm$json$Json$Encode$string(company.hs)),
				_Utils_Tuple2(
				'commercialName',
				$elm$json$Json$Encode$string(company.iR)),
				_Utils_Tuple2(
				'document',
				$elm$json$Json$Encode$string(company.i1)),
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(company.c7)),
				_Utils_Tuple2(
				'birthDate',
				$author$project$App$Data$Id$timeEncoder(
					A2(
						$elm$core$Result$withDefault,
						$elm$time$Time$millisToPosix(0),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(company.al)))),
				_Utils_Tuple2(
				'phone',
				$elm$json$Json$Encode$string(company.dx)),
				_Utils_Tuple2(
				'companyType',
				$elm$json$Json$Encode$string(company.fd)),
				_Utils_Tuple2(
				'cnae',
				$elm$json$Json$Encode$string(company.b2)),
				_Utils_Tuple2(
				'address',
				$elm$json$Json$Encode$string(company.cS)),
				_Utils_Tuple2(
				'number',
				$elm$json$Json$Encode$string(company.bb)),
				_Utils_Tuple2(
				'district',
				$elm$json$Json$Encode$string(company.ef)),
				_Utils_Tuple2(
				'city',
				$elm$json$Json$Encode$string(company.bp)),
				_Utils_Tuple2(
				'state',
				$elm$json$Json$Encode$string(company.p)),
				_Utils_Tuple2(
				'country',
				$elm$json$Json$Encode$string(company.ff)),
				_Utils_Tuple2(
				'postalCode',
				$elm$json$Json$Encode$string(company.cw))
			]));
};
var $author$project$App$Data$DigitalAccount$encoder = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'company',
				$author$project$App$Data$DigitalAccount$companyEncoder(model.b4)),
				_Utils_Tuple2(
				'bank',
				$author$project$App$Data$DigitalAccount$bankModelEncoder(model.ak)),
				_Utils_Tuple2(
				'license',
				$elm$json$Json$Encode$string(model.dm)),
				_Utils_Tuple2(
				'licenseState',
				$elm$json$Json$Encode$string(model.dn)),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(model.dw.hl)),
				_Utils_Tuple2(
				'surname',
				$elm$json$Json$Encode$string(model.dw.ii)),
				_Utils_Tuple2(
				'document',
				$elm$json$Json$Encode$string(model.dw.i1)),
				_Utils_Tuple2(
				'birthDate',
				$author$project$App$Data$Id$timeEncoder(
					A2(
						$elm$core$Result$withDefault,
						$elm$time$Time$millisToPosix(0),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(model.dw.al)))),
				_Utils_Tuple2(
				'sex',
				$elm$json$Json$Encode$string(model.dw.dJ)),
				_Utils_Tuple2(
				'motherName',
				$elm$json$Json$Encode$string(model.dw.cp)),
				_Utils_Tuple2(
				'memedCityID',
				$elm$json$Json$Encode$int(model.et)),
				_Utils_Tuple2(
				'memedSpecialtyID',
				$elm$json$Json$Encode$int(model.ev))
			]));
};
var $author$project$App$Service$ApplicationResources$ridUsers = $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceUser + '.users');
var $author$project$App$Service$ApplicationResources$pathUsers = $author$project$App$Service$Shared$pathFromRID($author$project$App$Service$ApplicationResources$ridUsers);
var $author$project$App$Service$ApplicationResources$methodCreateDoctorUser = A3($author$project$App$Service$Shared$methodFromPath, 2, $author$project$App$Service$ApplicationResources$pathUsers, 'createDoctor');
var $author$project$App$Service$ApplicationResources$callCreateDoctorUser = F5(
	function (user, phone, juno, responseMsg, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			false,
			$author$project$App$Service$SpecificResources$TypeUserResult($author$project$App$Service$ApplicationResources$methodCreateDoctorUser),
			$author$project$App$Service$ApplicationResources$methodCreateDoctorUser,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'doctor',
						$author$project$App$Data$DigitalAccount$encoder(juno)),
						_Utils_Tuple2(
						'user',
						$author$project$App$Data$User$encoder(user)),
						_Utils_Tuple2(
						'phone',
						$author$project$App$Data$Contact$encoder(phone))
					])),
			responseMsg,
			A2($author$project$App$Service$ApiWebSocket$clearMethod, $author$project$App$Service$ApplicationResources$methodCreateDoctorUser, api));
	});
var $author$project$App$Data$Document$typeToInt = function (t) {
	switch (t) {
		case 0:
			return 0;
		case 1:
			return 4;
		case 2:
			return 5;
		case 3:
			return 7;
		case 5:
			return 8;
		default:
			return 11;
	}
};
var $author$project$App$Data$Document$typeEncoder = function (t) {
	return $elm$json$Json$Encode$int(
		$author$project$App$Data$Document$typeToInt(t));
};
var $author$project$App$Data$Document$encoder = function (doc) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$author$project$App$Data$Id$uuidEncoder(doc.as)),
				_Utils_Tuple2(
				'mime',
				$elm$json$Json$Encode$string(doc.dr)),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(doc.hl)),
				_Utils_Tuple2(
				'ownerId',
				$author$project$App$Data$Id$uuidEncoder(doc.jJ)),
				_Utils_Tuple2(
				'ownerKind',
				$elm$json$Json$Encode$string(doc.X)),
				_Utils_Tuple2(
				'personId',
				$author$project$App$Data$Id$uuidEncoder(doc.Y)),
				_Utils_Tuple2(
				'content',
				$elm$json$Json$Encode$string(doc.iU)),
				_Utils_Tuple2(
				'docType',
				$author$project$App$Data$Document$typeEncoder(doc.b7))
			]));
};
var $author$project$App$Service$ApplicationResources$serviceDocument = 'documentService';
var $author$project$App$Service$ApplicationResources$ridDocumentFromStatementAndIndex = function (stmtId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceDocument + ('.' + ('fromStatement.' + (stmtId + '.0'))));
};
var $author$project$App$Service$ApplicationResources$pathDocumentFromStatementAndIndex = function (stmtId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridDocumentFromStatementAndIndex(stmtId));
};
var $author$project$App$Service$ApplicationResources$methodCreateDocumentFromStatement = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathDocumentFromStatementAndIndex(stmtId),
		'new');
};
var $author$project$App$Service$ApplicationResources$callCreateDocumentFromStatement = F4(
	function (stmtId, doc, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmtId,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodCreateDocumentFromStatement(stmtId),
				api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeUnknown,
				$author$project$App$Service$ApplicationResources$methodCreateDocumentFromStatement(stmtId),
				$author$project$App$Data$Document$encoder(doc),
				respMsgs));
	});
var $author$project$App$Data$DigitalAccount$creditCardHashEncoder = function (hash) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'creditCardHash',
				$elm$json$Json$Encode$string(hash)),
				_Utils_Tuple2(
				'creditCardId',
				$elm$json$Json$Encode$string(''))
			]));
};
var $author$project$App$Service$ApplicationResources$methodCreateNewPayment = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
		'chargeCreate');
};
var $author$project$App$Data$DigitalAccount$addressEncoder = function (address) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'street',
				$elm$json$Json$Encode$string(address.ic)),
				_Utils_Tuple2(
				'number',
				$elm$json$Json$Encode$string(address.bb)),
				_Utils_Tuple2(
				'neighborhood',
				$elm$json$Json$Encode$string(address.hn)),
				_Utils_Tuple2(
				'complement',
				$elm$json$Json$Encode$string(address.gy)),
				_Utils_Tuple2(
				'city',
				$elm$json$Json$Encode$string(address.bp)),
				_Utils_Tuple2(
				'state',
				$elm$json$Json$Encode$string(address.p)),
				_Utils_Tuple2(
				'postCode',
				$elm$json$Json$Encode$string(address.hA))
			]));
};
var $author$project$App$Data$DigitalAccount$newPaymentEncoder = F2(
	function (email, address) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'email',
					$elm$json$Json$Encode$string(email)),
					_Utils_Tuple2(
					'address',
					$author$project$App$Data$DigitalAccount$addressEncoder(address)),
					_Utils_Tuple2(
					'delayed',
					$elm$json$Json$Encode$bool(false))
				]));
	});
var $author$project$App$Service$ApplicationResources$callCreateNewPayment = F6(
	function (stmtId, email, address, hash, responseMsg, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			false,
			$author$project$App$Service$SpecificResources$TypeUnknown,
			$author$project$App$Service$ApplicationResources$methodCreateNewPayment(stmtId),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2('charge', $elm$json$Json$Encode$null),
						_Utils_Tuple2(
						'billing',
						A2($author$project$App$Data$DigitalAccount$newPaymentEncoder, email, address)),
						_Utils_Tuple2(
						'creditCard',
						$author$project$App$Data$DigitalAccount$creditCardHashEncoder(hash))
					])),
			responseMsg,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodCreateNewPayment(stmtId),
				api));
	});
var $author$project$App$Service$ApplicationResources$ridDocumentFromId = function (stmtId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceDocument + ('.' + ('byId.' + stmtId)));
};
var $author$project$App$Service$ApplicationResources$pathDocumentFromId = function (documentId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridDocumentFromId(documentId));
};
var $author$project$App$Service$ApplicationResources$methodDeleteDocumentFromId = function (documentId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathDocumentFromId(documentId),
		'deleteVideo');
};
var $author$project$App$Service$ApplicationResources$callDeleteDocumentFromId = F3(
	function (documentId, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			documentId,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodDeleteDocumentFromId(documentId),
				api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeUnknown,
				$author$project$App$Service$ApplicationResources$methodDeleteDocumentFromId(documentId),
				$elm$json$Json$Encode$null,
				respMsgs));
	});
var $author$project$App$Service$SpecificResources$TypeICD = {$: 12};
var $author$project$App$Data$ICD$ridById = function (id) {
	return 'icdService.byId.' + id;
};
var $author$project$App$Service$ApplicationResources$callDeleteICD = F3(
	function (icdId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeICD,
			A3(
				$author$project$App$Service$Shared$methodFromPath,
				2,
				$author$project$App$Service$Shared$pathFromRID(
					$author$project$App$Service$Shared$ridFromString(
						$author$project$App$Data$ICD$ridById(icdId))),
				'delete'),
			$elm$json$Json$Encode$null,
			respMsgs,
			api);
	});
var $author$project$App$Service$ApplicationResources$methodChangeDoctorStatementById = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
		'informChanged');
};
var $author$project$App$Service$ApplicationResources$callDoctorChange = F3(
	function (stmtId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeStatementResult(
				$author$project$App$Service$ApplicationResources$methodChangeDoctorStatementById(stmtId)),
			$author$project$App$Service$ApplicationResources$methodChangeDoctorStatementById(stmtId),
			$elm$json$Json$Encode$null,
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodChangeDoctorStatementById(stmtId),
				api));
	});
var $author$project$App$Service$ApplicationResources$serviceDoctor = 'doctorService';
var $author$project$App$Service$ApplicationResources$ridDoctorById = function (dId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceDoctor + ('.doctor.' + dId));
};
var $author$project$App$Service$ApplicationResources$pathDoctorById = function (dId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridDoctorById(dId));
};
var $author$project$App$Service$ApplicationResources$methodDoctorInformOnline = function (dId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathDoctorById(dId),
		'informOnline');
};
var $author$project$App$Service$ApplicationResources$callDoctorInformOnline = F4(
	function (dId, v, respMsgs, api) {
		return $author$project$App$Data$Id$uuidIsValid(dId) ? A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeUnknown,
			$author$project$App$Service$ApplicationResources$methodDoctorInformOnline(dId),
			$elm$json$Json$Encode$string(v),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodDoctorInformOnline(dId),
				api)) : _Utils_Tuple2(api, $elm$core$Platform$Cmd$none);
	});
var $author$project$App$Service$ApplicationResources$methodFinishDoctorStatementById = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
		'finishInterview');
};
var $author$project$App$Service$ApplicationResources$callFinishDoctorStatement = F3(
	function (stmt, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmt.as,
			api,
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementResult(
					$author$project$App$Service$ApplicationResources$methodFinishDoctorStatementById(stmt.as)),
				$author$project$App$Service$ApplicationResources$methodFinishDoctorStatementById(stmt.as),
				$author$project$App$Data$Statement$encoder(stmt),
				respMsgs));
	});
var $author$project$App$Service$SpecificResources$TypeICDCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$App$Data$ICD$ridCategories = 'icdService.categories';
var $author$project$App$Service$ApplicationResources$methodGetICDCategories = A3(
	$author$project$App$Service$Shared$methodFromPath,
	2,
	$author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$Shared$ridFromString($author$project$App$Data$ICD$ridCategories)),
	'all');
var $author$project$App$Service$ApplicationResources$callGetICDCategories = F2(
	function (respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeICDCategory($author$project$App$Service$ApplicationResources$methodGetICDCategories),
			$author$project$App$Service$ApplicationResources$methodGetICDCategories,
			$elm$json$Json$Encode$null,
			respMsgs,
			api);
	});
var $author$project$App$Service$SpecificResources$TypeMemedCities = function (a) {
	return {$: 36, a: a};
};
var $author$project$App$Data$MemedRegister$cityEncoder = function (state) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'query',
				$elm$json$Json$Encode$string(state))
			]));
};
var $author$project$App$Service$ApplicationResources$ridGetMemedToken = 'memedService.integration';
var $author$project$App$Service$ApplicationResources$methodGetMemedCities = A3(
	$author$project$App$Service$Shared$methodFromPath,
	2,
	$author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$ridGetMemedToken)),
	'filterCities');
var $author$project$App$Service$ApplicationResources$callGetMemedCities = F3(
	function (state, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeMemedCities($author$project$App$Service$ApplicationResources$methodGetMemedCities),
			$author$project$App$Service$ApplicationResources$methodGetMemedCities,
			$author$project$App$Data$MemedRegister$cityEncoder(state),
			respMsgs,
			A2($author$project$App$Service$ApiWebSocket$clearMethod, $author$project$App$Service$ApplicationResources$methodGetMemedCities, api));
	});
var $author$project$App$Service$SpecificResources$TypeMemedSpecialties = function (a) {
	return {$: 37, a: a};
};
var $author$project$App$Service$ApplicationResources$methodGetMemedSpecialties = A3(
	$author$project$App$Service$Shared$methodFromPath,
	2,
	$author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$ridGetMemedToken)),
	'filterSpecialties');
var $author$project$App$Service$ApplicationResources$callGetMemedSpecialties = F2(
	function (respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeMemedSpecialties($author$project$App$Service$ApplicationResources$methodGetMemedSpecialties),
			$author$project$App$Service$ApplicationResources$methodGetMemedSpecialties,
			$elm$json$Json$Encode$null,
			respMsgs,
			A2($author$project$App$Service$ApiWebSocket$clearMethod, $author$project$App$Service$ApplicationResources$methodGetMemedSpecialties, api));
	});
var $author$project$App$Service$SpecificResources$TypeMemedToken = function (a) {
	return {$: 15, a: a};
};
var $author$project$App$Data$Memed$encoder = function (id) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'externalID',
				$author$project$App$Data$Id$uuidEncoder(id))
			]));
};
var $author$project$App$Service$ApplicationResources$methodGetMemedToken = A3(
	$author$project$App$Service$Shared$methodFromPath,
	2,
	$author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$ridGetMemedToken)),
	'doctorToken');
var $author$project$App$Service$ApplicationResources$callGetMemedToken = F3(
	function (id, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeMemedToken($author$project$App$Service$ApplicationResources$methodGetMemedToken),
			$author$project$App$Service$ApplicationResources$methodGetMemedToken,
			$author$project$App$Data$Memed$encoder(id),
			respMsgs,
			api);
	});
var $author$project$App$Service$SpecificResources$TypeNexodataToken = function (a) {
	return {$: 16, a: a};
};
var $author$project$App$Service$ApplicationResources$ridGetNexodataToken = 'nexodataService.integration';
var $author$project$App$Service$ApplicationResources$methodGetNexodataToken = A3(
	$author$project$App$Service$Shared$methodFromPath,
	2,
	$author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$ridGetNexodataToken)),
	'getToken');
var $author$project$App$Service$ApplicationResources$callGetNexodataToken = F3(
	function (stmt, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeNexodataToken($author$project$App$Service$ApplicationResources$methodGetNexodataToken),
			$author$project$App$Service$ApplicationResources$methodGetNexodataToken,
			$author$project$App$Data$Statement$encoder(stmt),
			respMsgs,
			api);
	});
var $author$project$App$Service$SpecificResources$TypeSimpleString = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Service$ApplicationResources$ridDocumentFromStatementAndIndexAndDocType = function (stmtId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceDocument + ('.' + ('fromStatement.' + (stmtId + ('.byDocType.' + '5')))));
};
var $author$project$App$Service$ApplicationResources$pathDocumentFromStatementAndIndexAndDocType = function (stmtId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridDocumentFromStatementAndIndexAndDocType(stmtId));
};
var $author$project$App$Service$ApplicationResources$methodGetPresignedUrl = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathDocumentFromStatementAndIndexAndDocType(stmtId),
		'getPresignedUrl');
};
var $author$project$App$Service$ApplicationResources$callGetPresignedUrl = F4(
	function (stmtId, doc, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmtId,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodGetPresignedUrl(stmtId),
				api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeSimpleString(
					$author$project$App$Service$ApplicationResources$methodGetPresignedUrl(stmtId)),
				$author$project$App$Service$ApplicationResources$methodGetPresignedUrl(stmtId),
				$author$project$App$Data$Document$encoder(doc),
				respMsgs));
	});
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Data$Statement$brDateToPosix = function (dateString) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$time$Time$millisToPosix(0),
		$elm$core$Result$toMaybe(
			$rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(
				A2(
					$elm$core$String$join,
					'-',
					$elm$core$List$reverse(
						A2($elm$core$String$split, '/', dateString))))));
};
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$App$Data$Statement$encodeDocumentAndBirthDate = F2(
	function (document, birthdate) {
		var newDocument = A3(
			$elm$core$String$replace,
			'.',
			'',
			A3($elm$core$String$replace, '-', '', document));
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'document',
					$elm$json$Json$Encode$string(newDocument)),
					_Utils_Tuple2(
					'birthDate',
					$author$project$App$Data$Id$timeEncoder(
						$author$project$App$Data$Statement$brDateToPosix(birthdate)))
				]));
	});
var $author$project$App$Service$ApplicationResources$ridStatementFromTemplateId = function (tId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatement + ('.fromTemplate.' + tId));
};
var $author$project$App$Service$ApplicationResources$pathStatementFromTemplateId = function (tId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridStatementFromTemplateId(tId));
};
var $author$project$App$Service$ApplicationResources$methodStatementFromTemplateIdNewFromExisting = function (tId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementFromTemplateId(tId),
		'newFromExisting');
};
var $author$project$App$Service$ApplicationResources$callGetStatementFromDocumentAndBirthDate = F5(
	function (document, birthdate, tId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeStatementResult(
				$author$project$App$Service$ApplicationResources$methodStatementFromTemplateIdNewFromExisting(tId)),
			$author$project$App$Service$ApplicationResources$methodStatementFromTemplateIdNewFromExisting(tId),
			A2($author$project$App$Data$Statement$encodeDocumentAndBirthDate, document, birthdate),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodStatementFromTemplateIdNewFromExisting(tId),
				api));
	});
var $author$project$App$Service$SpecificResources$TypeWaitingPerson = {$: 25};
var $author$project$App$Service$ApplicationResources$servicePeople = 'peopleService';
var $author$project$App$Service$ApplicationResources$ridPersonWaitingById = function (pId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$servicePeople + ('.waiting.' + pId));
};
var $author$project$App$Service$ApplicationResources$pathPersonWaitingById = A2($elm$core$Basics$composeL, $author$project$App$Service$Shared$pathFromRID, $author$project$App$Service$ApplicationResources$ridPersonWaitingById);
var $author$project$App$Service$ApplicationResources$methodHoldWaitingById = function (pId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathPersonWaitingById(pId),
		'hold');
};
var $author$project$App$Service$ApplicationResources$callHoldPersonById = F3(
	function (pId, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			pId,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodHoldWaitingById(pId),
				api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeWaitingPerson,
				$author$project$App$Service$ApplicationResources$methodHoldWaitingById(pId),
				$elm$json$Json$Encode$null,
				respMsgs));
	});
var $author$project$App$Service$SpecificResources$TypeHoliday = {$: 21};
var $author$project$App$Service$ApplicationResources$serviceHoliday = 'holidayService';
var $author$project$App$Service$ApplicationResources$ridHolidayTextForTemplate = function (templateId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceHoliday + ('.proponent.fromOwner.' + (templateId + ('.type.' + 'statement_template'))));
};
var $author$project$App$Service$ApplicationResources$pathHolidayTextForTemplate = function (templateId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridHolidayTextForTemplate(templateId));
};
var $author$project$App$Service$ApiWebSocket$getResource = F2(
	function (p, _v0) {
		var cache = _v0.h;
		var pathStr = $author$project$App$Service$Shared$pathToString(p);
		return A2($elm$core$Dict$get, pathStr, cache);
	});
var $author$project$App$Service$ApiWebSocket$Subscribed = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $author$project$App$Service$ApiWebSocket$subscribeResourceInternal = F5(
	function (reqAuth, t, path, response, _v0) {
		var id = _v0.a;
		var now = _v0.b;
		var smo = _v0.c;
		var sme = _v0.d;
		var ws = _v0.e;
		var sc = _v0.f;
		var cm = _v0.g;
		var c = _v0.h;
		var rid = $author$project$App$Service$Shared$pathToString(path);
		var uSc = A3(
			$elm$core$Dict$insert,
			rid,
			A4($author$project$App$Service$ApiWebSocket$Subscribed, reqAuth, t, path, response),
			sc);
		var request = {as: id, z: 'subscribe.' + rid, du: $elm$core$Maybe$Nothing};
		return A6(
			$author$project$App$Service$ApiWebSocket$requestOnWebsocket,
			rid,
			request,
			reqAuth,
			t,
			response,
			A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, uSc, cm, c));
	});
var $author$project$App$Service$ApiWebSocket$subscribeResource = F5(
	function (reqAuth, t, path, response, _v0) {
		var id = _v0.a;
		var now = _v0.b;
		var smo = _v0.c;
		var sme = _v0.d;
		var ws = _v0.e;
		var sc = _v0.f;
		var cm = _v0.g;
		var c = _v0.h;
		var _v1 = A2(
			$author$project$App$Service$ApiWebSocket$getResource,
			path,
			A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, c));
		if (_v1.$ === 1) {
			return A5(
				$author$project$App$Service$ApiWebSocket$subscribeResourceInternal,
				reqAuth,
				t,
				path,
				response,
				A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, c));
		} else {
			switch (_v1.a.$) {
				case 1:
					var _v2 = _v1.a;
					var when = _v2.a;
					var currentResponses = _v2.b;
					var nResps = A2($author$project$App$Service$ApiWebSocket$concatResponses, currentResponses, response);
					var uC = A3(
						$elm$core$Dict$insert,
						$author$project$App$Service$Shared$pathToString(path),
						A2($author$project$App$Service$ApiWebSocket$ResourceRequested, when, nResps),
						c);
					return _Utils_Tuple2(
						A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, uC),
						$elm$core$Platform$Cmd$none);
				case 2:
					var _v3 = _v1.a;
					var currentResponses = _v3.a;
					var error = _v3.b;
					var nResps = A2($author$project$App$Service$ApiWebSocket$concatResponses, currentResponses, response);
					var uC = A3(
						$elm$core$Dict$insert,
						$author$project$App$Service$Shared$pathToString(path),
						A2($author$project$App$Service$ApiWebSocket$ResourceError, nResps, error),
						c);
					return _Utils_Tuple2(
						A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, uC),
						$author$project$App$Service$ApiWebSocket$asyncMsg(
							$author$project$App$Service$ApiWebSocket$ResponseAlreadyAvailable(nResps)));
				case 3:
					var _v4 = _v1.a;
					var currentResponses = _v4.b;
					var value = _v4.c;
					var nResps = A2($author$project$App$Service$ApiWebSocket$concatResponses, currentResponses, response);
					var uC = A3(
						$elm$core$Dict$insert,
						$author$project$App$Service$Shared$pathToString(path),
						A3($author$project$App$Service$ApiWebSocket$ResourceAvailable, t, nResps, value),
						c);
					return _Utils_Tuple2(
						A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, uC),
						$author$project$App$Service$ApiWebSocket$asyncMsg(
							$author$project$App$Service$ApiWebSocket$ResponseAlreadyAvailable(nResps)));
				default:
					return _Utils_Tuple2(
						A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, c),
						$elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$App$Service$ApplicationResources$callHolidayTextForTemplate = F3(
	function (templateId, respMsgs, api) {
		return A5(
			$author$project$App$Service$ApiWebSocket$subscribeResource,
			true,
			$author$project$App$Service$SpecificResources$TypeHoliday,
			$author$project$App$Service$ApplicationResources$pathHolidayTextForTemplate(templateId),
			respMsgs,
			api);
	});
var $author$project$App$Service$SpecificResources$TypeStatementTemplate = {$: 32};
var $author$project$App$Service$ApplicationResources$serviceStatementTemplate = 'stmtTemplateService';
var $author$project$App$Service$ApplicationResources$ridLoadTemplateFromUrl = F2(
	function (url, path) {
		return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatementTemplate + ('.byURL.' + (url + ('.path.' + path))));
	});
var $author$project$App$Service$ApplicationResources$pathLoadTemplateFromUrl = F2(
	function (url, path) {
		var encodedUrl = $truqu$elm_base64$Base64$encode(url);
		return $author$project$App$Service$Shared$pathFromRID(
			A2($author$project$App$Service$ApplicationResources$ridLoadTemplateFromUrl, encodedUrl, path));
	});
var $author$project$App$Service$ApplicationResources$callLoadTemplateFromUrl = F4(
	function (url, path, respMsgs, api) {
		return A5(
			$author$project$App$Service$ApiWebSocket$subscribeResource,
			false,
			$author$project$App$Service$SpecificResources$TypeStatementTemplate,
			A2($author$project$App$Service$ApplicationResources$pathLoadTemplateFromUrl, url, path),
			respMsgs,
			api);
	});
var $author$project$App$Service$SpecificResources$TypeSingleDocument = function (a) {
	return {$: 19, a: a};
};
var $author$project$App$Service$ApplicationResources$ridDocumentByStatementId = function (stmtId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceDocument + ('.fromStatement.' + stmtId));
};
var $author$project$App$Service$ApplicationResources$pathTermsDocumentFromStatement = function (stmtId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridDocumentByStatementId(stmtId));
};
var $author$project$App$Service$ApplicationResources$methodTermsDocumentFromStatement = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathTermsDocumentFromStatement(stmtId),
		'getStatementTerms');
};
var $author$project$App$Service$ApplicationResources$callLoadTermsByStatementId = F3(
	function (stmtId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeSingleDocument(
				$author$project$App$Service$ApplicationResources$methodTermsDocumentFromStatement(stmtId)),
			$author$project$App$Service$ApplicationResources$methodTermsDocumentFromStatement(stmtId),
			$elm$json$Json$Encode$null,
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodTermsDocumentFromStatement(stmtId),
				api));
	});
var $author$project$App$Service$SpecificResources$TypeStatementFull = function (a) {
	return {$: 30, a: a};
};
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$json$Json$Encode$dict = F3(
	function (toKey, toValue, dictionary) {
		return _Json_wrap(
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (key, value, obj) {
						return A3(
							_Json_addField,
							toKey(key),
							toValue(value),
							obj);
					}),
				_Json_emptyObject(0),
				dictionary));
	});
var $author$project$App$Data$Person$civilStatusToString = function (status) {
	switch (status) {
		case 1:
			return 'solteiro';
		case 2:
			return 'casado';
		case 3:
			return 'divorciado';
		case 4:
			return 'viuvo';
		case 5:
			return 'estavel';
		default:
			return '';
	}
};
var $author$project$App$Data$Person$civilStatusEncoder = function (status) {
	return $elm$json$Json$Encode$string(
		$author$project$App$Data$Person$civilStatusToString(status));
};
var $author$project$App$Data$Person$sexToString = function (sex) {
	switch (sex) {
		case 1:
			return 'M';
		case 2:
			return 'F';
		default:
			return '';
	}
};
var $author$project$App$Data$Person$sexEncoder = function (sex) {
	return $elm$json$Json$Encode$string(
		$author$project$App$Data$Person$sexToString(sex));
};
var $author$project$App$Data$Person$encoder = function (p) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$author$project$App$Data$Id$uuidEncoder(p.as)),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(p.hl)),
				_Utils_Tuple2(
				'surname',
				$elm$json$Json$Encode$string(p.ii)),
				_Utils_Tuple2(
				'socialName',
				$elm$json$Json$Encode$string(p.B)),
				_Utils_Tuple2(
				'document',
				$elm$json$Json$Encode$string(p.i1)),
				_Utils_Tuple2(
				'sex',
				$author$project$App$Data$Person$sexEncoder(p.dJ)),
				_Utils_Tuple2(
				'civilState',
				$author$project$App$Data$Person$civilStatusEncoder(p.D)),
				_Utils_Tuple2(
				'birthDate',
				$author$project$App$Data$Id$timeEncoder(p.al)),
				_Utils_Tuple2(
				'motherName',
				$elm$json$Json$Encode$string(p.cp)),
				_Utils_Tuple2(
				'photo',
				function () {
					var _v0 = p.dy;
					if (!_v0.$) {
						var photo = _v0.a;
						return $elm$json$Json$Encode$string(photo);
					} else {
						return $elm$json$Json$Encode$null;
					}
				}()),
				_Utils_Tuple2(
				'updatedAt',
				$author$project$App$Data$Id$timeEncoder(p.f2))
			]));
};
var $author$project$App$Data$FullStatement$editStatementEncoder = function (editStmt) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'model',
				$author$project$App$Data$Statement$encoder(editStmt.e)),
				_Utils_Tuple2(
				'person',
				$author$project$App$Data$Person$encoder(editStmt.dw)),
				_Utils_Tuple2(
				'contacts',
				A3($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $author$project$App$Data$Contact$encoder, editStmt.ea))
			]));
};
var $author$project$App$Data$Document$encoderNoContent = function (doc) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$author$project$App$Data$Id$uuidEncoder(doc.as)),
				_Utils_Tuple2(
				'mime',
				$elm$json$Json$Encode$string(doc.dr)),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(doc.hl)),
				_Utils_Tuple2(
				'ownerId',
				$author$project$App$Data$Id$uuidEncoder(doc.jJ)),
				_Utils_Tuple2(
				'ownerKind',
				$elm$json$Json$Encode$string(doc.X)),
				_Utils_Tuple2(
				'personId',
				$author$project$App$Data$Id$uuidEncoder(doc.Y)),
				_Utils_Tuple2(
				'docType',
				$author$project$App$Data$Document$typeEncoder(doc.b7))
			]));
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$App$Data$FullStatement$getServerFullStmtFromLocal = function (fullStmt) {
	return {
		g: fullStmt.g,
		fh: $elm$core$Dict$values(fullStmt.fh),
		fm: $elm$core$Dict$values(fullStmt.an),
		fP: fullStmt.fO
	};
};
var $author$project$App$Data$FullStatement$fullStatementEncoder = function (fullStmt) {
	var srvFullStmt = $author$project$App$Data$FullStatement$getServerFullStmtFromLocal(fullStmt);
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'bearer',
				$author$project$App$Data$FullStatement$editStatementEncoder(srvFullStmt.g)),
				_Utils_Tuple2(
				'deps',
				A2($elm$json$Json$Encode$list, $author$project$App$Data$FullStatement$editStatementEncoder, srvFullStmt.fh)),
				_Utils_Tuple2(
				'documents',
				A2($elm$json$Json$Encode$list, $author$project$App$Data$Document$encoder, srvFullStmt.fm)),
				_Utils_Tuple2(
				'removedDocuments',
				A2($elm$json$Json$Encode$list, $author$project$App$Data$Document$encoderNoContent, srvFullStmt.fP))
			]));
};
var $author$project$App$Service$ApplicationResources$ridStatementsFromCompany = function (cId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatement + ('.fromCompany.' + cId));
};
var $author$project$App$Service$ApplicationResources$pathStatementsFromCompany = function (cId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridStatementsFromCompany(cId));
};
var $author$project$App$Service$ApplicationResources$methodNewFullStatementForCompany = function (cId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementsFromCompany(cId),
		'newFullStatement');
};
var $author$project$App$Service$ApplicationResources$callNewFullStatementForCompany = F4(
	function (cId, fullStmt, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeStatementFull(
				$author$project$App$Service$ApplicationResources$methodNewFullStatementForCompany(cId)),
			$author$project$App$Service$ApplicationResources$methodNewFullStatementForCompany(cId),
			$author$project$App$Data$FullStatement$fullStatementEncoder(fullStmt),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodNewFullStatementForCompany(cId),
				api));
	});
var $author$project$App$Service$ApplicationResources$methodStatementFromTemplateIdNewFromProponent = function (tId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementFromTemplateId(tId),
		'newFullStatementFromProponent');
};
var $author$project$App$Service$ApplicationResources$callNewFullStatementFromProponent = F4(
	function (tId, fullStmt, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeStatementResult(
				$author$project$App$Service$ApplicationResources$methodStatementFromTemplateIdNewFromProponent(tId)),
			$author$project$App$Service$ApplicationResources$methodStatementFromTemplateIdNewFromProponent(tId),
			$author$project$App$Data$FullStatement$fullStatementEncoder(fullStmt),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodStatementFromTemplateIdNewFromProponent(tId),
				api));
	});
var $author$project$App$Service$ApplicationResources$ridIncomplete = $author$project$App$Service$Shared$ridFromString('statementService.incomplete');
var $author$project$App$Service$ApplicationResources$pathPostponeIncomplete = $author$project$App$Service$Shared$pathFromRID($author$project$App$Service$ApplicationResources$ridIncomplete);
var $author$project$App$Service$ApplicationResources$methodPostponeStatement = A3($author$project$App$Service$Shared$methodFromPath, 2, $author$project$App$Service$ApplicationResources$pathPostponeIncomplete, 'postponeStatement');
var $author$project$App$Service$ApplicationResources$callPostponeStatement = F3(
	function (stmt, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmt.as,
			A2($author$project$App$Service$ApiWebSocket$clearMethod, $author$project$App$Service$ApplicationResources$methodPostponeStatement, api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementResult($author$project$App$Service$ApplicationResources$methodPostponeStatement),
				$author$project$App$Service$ApplicationResources$methodPostponeStatement,
				$author$project$App$Data$Statement$encoder(stmt),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$ridPersonWaitingLine = $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$servicePeople + '.waiting.line');
var $author$project$App$Service$ApplicationResources$pathPersonWaitingLine = $author$project$App$Service$Shared$pathFromRID($author$project$App$Service$ApplicationResources$ridPersonWaitingLine);
var $author$project$App$Service$ApplicationResources$methodInformPersonIsOnline = A3($author$project$App$Service$Shared$methodFromPath, 2, $author$project$App$Service$ApplicationResources$pathPersonWaitingLine, 'informOnline');
var $author$project$App$Service$ApplicationResources$callProponentInformOnline = F3(
	function (respMsgs, now, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeUnknown,
			$author$project$App$Service$ApplicationResources$methodInformPersonIsOnline,
			$elm$json$Json$Encode$string(now),
			respMsgs,
			A2($author$project$App$Service$ApiWebSocket$clearMethod, $author$project$App$Service$ApplicationResources$methodInformPersonIsOnline, api));
	});
var $author$project$App$Service$SpecificResources$TypeDoctorTemplate = function (a) {
	return {$: 38, a: a};
};
var $author$project$App$Data$DoctorTemplate$encoder = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$author$project$App$Data$Id$uuidEncoder(model.as)),
				_Utils_Tuple2(
				'doctorId',
				$author$project$App$Data$Id$uuidEncoder(model.fl)),
				_Utils_Tuple2(
				'templateId',
				$author$project$App$Data$Id$uuidEncoder(model.eY)),
				_Utils_Tuple2(
				'doctorAmount',
				$elm$json$Json$Encode$int(model.c2)),
				_Utils_Tuple2(
				'doctorCharge',
				$elm$json$Json$Encode$bool(model.c3)),
				_Utils_Tuple2(
				'createdAt',
				$author$project$App$Data$Id$timeEncoder(model.iW)),
				_Utils_Tuple2(
				'updatedAt',
				$author$project$App$Data$Id$timeEncoder(model.f2))
			]));
};
var $author$project$App$Service$ApplicationResources$serviceDoctorTemplate = 'doctorTemplateService';
var $author$project$App$Service$ApplicationResources$ridDoctorTemplateFromDoctor = function (pId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceDoctorTemplate + ('.doctor.' + pId));
};
var $author$project$App$Service$ApplicationResources$pathDoctorTemplateFromDoctor = function (pId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridDoctorTemplateFromDoctor(pId));
};
var $author$project$App$Service$ApplicationResources$methodRegisterDoctorById = function (pId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathDoctorTemplateFromDoctor(pId),
		'register');
};
var $author$project$App$Service$ApplicationResources$callRegisterDoctorByDoctorId = F4(
	function (pId, doctorTemplate, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeDoctorTemplate(
				$author$project$App$Service$ApplicationResources$methodRegisterDoctorById(pId)),
			$author$project$App$Service$ApplicationResources$methodRegisterDoctorById(pId),
			$author$project$App$Data$DoctorTemplate$encoder(doctorTemplate),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodRegisterDoctorById(pId),
				api));
	});
var $author$project$App$Service$ApplicationResources$methodRemoveDoctorById = function (pId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathDoctorTemplateFromDoctor(pId),
		'remove');
};
var $author$project$App$Service$ApplicationResources$callRemoveDoctorByDoctorId = F4(
	function (pId, doctorTemplate, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeDoctorTemplate(
				$author$project$App$Service$ApplicationResources$methodRemoveDoctorById(pId)),
			$author$project$App$Service$ApplicationResources$methodRemoveDoctorById(pId),
			$author$project$App$Data$DoctorTemplate$encoder(doctorTemplate),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodRemoveDoctorById(pId),
				api));
	});
var $author$project$App$Service$SpecificResources$TypeStatement = {$: 27};
var $author$project$App$Service$ApplicationResources$methodRequestStatementReview = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
		'requestReview');
};
var $author$project$App$Service$ApplicationResources$callRequestStatementReview = F3(
	function (stmtId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeStatement,
			$author$project$App$Service$ApplicationResources$methodRequestStatementReview(stmtId),
			$elm$json$Json$Encode$null,
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodRequestStatementReview(stmtId),
				api));
	});
var $author$project$App$Service$ApplicationResources$methodRequestStatementUnschedule = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
		'requestUnschedule');
};
var $author$project$App$Service$ApplicationResources$callRequestStatementUnschedule = F3(
	function (stmtId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeStatement,
			$author$project$App$Service$ApplicationResources$methodRequestStatementUnschedule(stmtId),
			$elm$json$Json$Encode$null,
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodRequestStatementUnschedule(stmtId),
				api));
	});
var $author$project$App$Service$SpecificResources$TypeUser = {$: 33};
var $author$project$App$Service$ApplicationResources$serviceAdmin = 'adminService';
var $author$project$App$Service$ApplicationResources$ridAdminFromPersonId = function (uId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceAdmin + ('.activation.' + uId));
};
var $author$project$App$Service$ApplicationResources$pathAdminFromPersonId = function (uId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridAdminFromPersonId(uId));
};
var $author$project$App$Service$ApplicationResources$methodRequestUserActivation = function (personId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathAdminFromPersonId(personId),
		'activate');
};
var $author$project$App$Service$ApplicationResources$callRequestUserActivation = F3(
	function (personId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeUser,
			$author$project$App$Service$ApplicationResources$methodRequestUserActivation(personId),
			$elm$json$Json$Encode$null,
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodRequestUserActivation(personId),
				api));
	});
var $author$project$App$Service$ApplicationResources$methodRequestUserDeletion = function (personId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathAdminFromPersonId(personId),
		'delete');
};
var $author$project$App$Service$ApplicationResources$callRequestUserDeletion = F3(
	function (personId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeUser,
			$author$project$App$Service$ApplicationResources$methodRequestUserDeletion(personId),
			$elm$json$Json$Encode$null,
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodRequestUserDeletion(personId),
				api));
	});
var $author$project$App$Service$ApplicationResources$ridPassword = function (data) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceUser + ('.password.' + data));
};
var $author$project$App$Service$ApplicationResources$pathPassword = function (data) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridPassword(data));
};
var $author$project$App$Service$ApplicationResources$methodResetPasswordWithPass = function (data) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathPassword(data),
		'resetWithPass');
};
var $author$project$App$Service$ApplicationResources$callResetPasswordWithPassword = F4(
	function (data, user, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeUnknown,
			$author$project$App$Service$ApplicationResources$methodResetPasswordWithPass(data),
			$author$project$App$Data$User$encoder(user),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodResetPasswordWithPass(data),
				api));
	});
var $author$project$App$Service$ApplicationResources$methodReturnToLineById = function (pId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathPersonWaitingById(pId),
		'backToLine');
};
var $author$project$App$Service$ApplicationResources$callReturnPersonToLineById = F3(
	function (pId, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			pId,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodReturnToLineById(pId),
				api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeWaitingPerson,
				$author$project$App$Service$ApplicationResources$methodReturnToLineById(pId),
				$elm$json$Json$Encode$null,
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$pathReturnToCompany = $author$project$App$Service$Shared$pathFromRID($author$project$App$Service$ApplicationResources$ridIncomplete);
var $author$project$App$Service$ApplicationResources$methodReturnToCompany = A3($author$project$App$Service$Shared$methodFromPath, 2, $author$project$App$Service$ApplicationResources$pathReturnToCompany, 'returnToCompany');
var $author$project$App$Service$ApplicationResources$callReturnToCompany = F3(
	function (stmt, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmt.as,
			api,
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementResult($author$project$App$Service$ApplicationResources$methodReturnToCompany),
				$author$project$App$Service$ApplicationResources$methodReturnToCompany,
				$author$project$App$Data$Statement$encoder(stmt),
				respMsgs));
	});
var $author$project$App$Data$StatementMessage$encoder = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$author$project$App$Data$Id$uuidEncoder(model.as)),
				_Utils_Tuple2(
				'senderId',
				$author$project$App$Data$Id$uuidEncoder(model.eS)),
				_Utils_Tuple2(
				'statementId',
				$author$project$App$Data$Id$uuidEncoder(model.aV)),
				_Utils_Tuple2(
				'senderName',
				$elm$json$Json$Encode$string(model.jX)),
				_Utils_Tuple2(
				'message',
				$elm$json$Json$Encode$string(model.jl)),
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string(model.fF)),
				_Utils_Tuple2(
				'status',
				$elm$json$Json$Encode$string(model.aw)),
				_Utils_Tuple2(
				'createdAt',
				$author$project$App$Data$Id$timeEncoder(model.iW))
			]));
};
var $author$project$App$Service$ApplicationResources$methodReturnToDoctorEvaluation = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
		'returnToDoctorEvaluation');
};
var $author$project$App$Service$ApplicationResources$callReturnToDoctorEvaluation = F3(
	function (stmtMsg, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmtMsg.aV,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodReturnToDoctorEvaluation(stmtMsg.aV),
				api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementResult(
					$author$project$App$Service$ApplicationResources$methodReturnToDoctorEvaluation(stmtMsg.aV)),
				$author$project$App$Service$ApplicationResources$methodReturnToDoctorEvaluation(stmtMsg.aV),
				$author$project$App$Data$StatementMessage$encoder(stmtMsg),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$methodReturnToInterview = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
		'returnToConfirmInterview');
};
var $author$project$App$Service$ApplicationResources$callReturnToInterview = F3(
	function (stmt, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmt.as,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodReturnToInterview(stmt.as),
				api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementResult(
					$author$project$App$Service$ApplicationResources$methodReturnToInterview(stmt.as)),
				$author$project$App$Service$ApplicationResources$methodReturnToInterview(stmt.as),
				$author$project$App$Data$Statement$encoder(stmt),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$methodReturnToWaitingForRoom = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
		'returnToWaitingForRoom');
};
var $author$project$App$Service$ApplicationResources$callReturnToWaitingForRoom = F3(
	function (stmt, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmt.as,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodReturnToWaitingForRoom(stmt.as),
				api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementResult(
					$author$project$App$Service$ApplicationResources$methodReturnToWaitingForRoom(stmt.as)),
				$author$project$App$Service$ApplicationResources$methodReturnToWaitingForRoom(stmt.as),
				$author$project$App$Data$Statement$encoder(stmt),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$methodRevertDoctorStatementById = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
		'revertScript');
};
var $author$project$App$Service$ApplicationResources$callRevertDoctorStatement = F3(
	function (stmt, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmt.as,
			api,
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementResult(
					$author$project$App$Service$ApplicationResources$methodRevertDoctorStatementById(stmt.as)),
				$author$project$App$Service$ApplicationResources$methodRevertDoctorStatementById(stmt.as),
				$author$project$App$Data$Statement$encoder(stmt),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$methodRevertProponentStatementById = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathProponentStatementById(stmtId),
		'revert');
};
var $author$project$App$Service$ApplicationResources$callRevertProponentStatement = F3(
	function (stmt, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmt.as,
			api,
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementResult(
					$author$project$App$Service$ApplicationResources$methodRevertProponentStatementById(stmt.as)),
				$author$project$App$Service$ApplicationResources$methodRevertProponentStatementById(stmt.as),
				$author$project$App$Data$Statement$encoder(stmt),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$methodSetStatementById = function (sId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementById(sId),
		'set');
};
var $author$project$App$Service$ApplicationResources$callSaveStatementById = F3(
	function (stmt, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmt.as,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodSetStatementById(stmt.as),
				api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementResult(
					$author$project$App$Service$ApplicationResources$methodSetStatementById(stmt.as)),
				$author$project$App$Service$ApplicationResources$methodSetStatementById(stmt.as),
				$author$project$App$Data$Statement$encoder(stmt),
				respMsgs));
	});
var $author$project$App$Service$SpecificResources$TypeStatementMessage = {$: 40};
var $author$project$App$Service$ApplicationResources$serviceStatementMessage = 'statementMessageService';
var $author$project$App$Service$ApplicationResources$ridStatementMessageFromPerson = function (personId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatementMessage + ('.byPersonId.' + personId));
};
var $author$project$App$Service$ApplicationResources$pathStatementMessageFromPerson = function (personId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridStatementMessageFromPerson(personId));
};
var $author$project$App$Service$ApplicationResources$methodSendStatementMessageFromPerson = function (personId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementMessageFromPerson(personId),
		'create');
};
var $author$project$App$Service$ApplicationResources$callSendStatementMessageFromStatementId = F3(
	function (stmtMessage, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeStatementMessage,
			$author$project$App$Service$ApplicationResources$methodSendStatementMessageFromPerson(stmtMessage.eS),
			$author$project$App$Data$StatementMessage$encoder(stmtMessage),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodSendStatementMessageFromPerson(stmtMessage.eS),
				api));
	});
var $author$project$App$Service$SpecificResources$TypeAnswer = {$: 5};
var $author$project$App$Data$QuestionSection$categoryEncoder = A2($elm$core$Basics$composeR, $author$project$App$Data$QuestionSection$categoryToString, $elm$json$Json$Encode$string);
var $author$project$App$Data$Answer$encoder = function (answer) {
	var _v0 = answer.G;
	if (!_v0.$) {
		var tableDict = _v0.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$author$project$App$Data$Id$uuidEncoder(answer.as)),
					_Utils_Tuple2(
					'questionSectionId',
					$author$project$App$Data$Id$uuidEncoder(answer.aS)),
					_Utils_Tuple2(
					'questionId',
					$author$project$App$Data$Id$uuidEncoder(answer.bh)),
					_Utils_Tuple2(
					'statementId',
					$author$project$App$Data$Id$uuidEncoder(answer.aV)),
					_Utils_Tuple2(
					'answerTemplateId',
					$author$project$App$Data$Id$uuidEncoder(answer.aY)),
					_Utils_Tuple2(
					'answered',
					$elm$json$Json$Encode$bool(answer.d3)),
					_Utils_Tuple2(
					'value',
					$elm$json$Json$Encode$string(
						A2(
							$elm$json$Json$Encode$encode,
							0,
							A3(
								$elm$json$Json$Encode$dict,
								$elm$core$String$fromInt,
								A2($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $elm$json$Json$Encode$string),
								tableDict)))),
					_Utils_Tuple2(
					'category',
					$author$project$App$Data$QuestionSection$categoryEncoder(answer.a0)),
					_Utils_Tuple2(
					'selected',
					$elm$json$Json$Encode$bool(answer.dF)),
					_Utils_Tuple2(
					'description',
					$elm$json$Json$Encode$string(answer.am)),
					_Utils_Tuple2(
					'observation',
					$elm$json$Json$Encode$string(answer.jx)),
					_Utils_Tuple2(
					'businessValue',
					$elm$json$Json$Encode$int(answer.ay))
				]));
	} else {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$author$project$App$Data$Id$uuidEncoder(answer.as)),
					_Utils_Tuple2(
					'questionSectionId',
					$author$project$App$Data$Id$uuidEncoder(answer.aS)),
					_Utils_Tuple2(
					'questionId',
					$author$project$App$Data$Id$uuidEncoder(answer.bh)),
					_Utils_Tuple2(
					'statementId',
					$author$project$App$Data$Id$uuidEncoder(answer.aV)),
					_Utils_Tuple2(
					'answerTemplateId',
					$author$project$App$Data$Id$uuidEncoder(answer.aY)),
					_Utils_Tuple2(
					'answered',
					$elm$json$Json$Encode$bool(answer.d3)),
					_Utils_Tuple2(
					'value',
					$elm$json$Json$Encode$string(answer.T)),
					_Utils_Tuple2(
					'category',
					$author$project$App$Data$QuestionSection$categoryEncoder(answer.a0)),
					_Utils_Tuple2(
					'selected',
					$elm$json$Json$Encode$bool(answer.dF)),
					_Utils_Tuple2(
					'description',
					$elm$json$Json$Encode$string(answer.am)),
					_Utils_Tuple2(
					'observation',
					$elm$json$Json$Encode$string(answer.jx)),
					_Utils_Tuple2(
					'businessValue',
					$elm$json$Json$Encode$int(answer.ay)),
					_Utils_Tuple2(
					'index',
					$elm$json$Json$Encode$int(answer.bB))
				]));
	}
};
var $author$project$App$Service$Answer$serviceAnswer = 'answerService';
var $author$project$App$Service$Answer$ridAnswerById = function (answerId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$Answer$serviceAnswer + ('.byId.' + answerId));
};
var $author$project$App$Service$Answer$pathAnswerById = function (answerId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$Answer$ridAnswerById(answerId));
};
var $author$project$App$Service$Answer$methodSetAnswerById = function (answerId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$Answer$pathAnswerById(answerId),
		'set');
};
var $author$project$App$Service$Answer$callSetAnswerById = F3(
	function (answer, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			answer.as,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$Answer$methodSetAnswerById(answer.as),
				api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeAnswer,
				$author$project$App$Service$Answer$methodSetAnswerById(answer.as),
				$author$project$App$Data$Answer$encoder(answer),
				respMsgs));
	});
var $author$project$App$Service$SpecificResources$TypeConference = {$: 8};
var $author$project$App$Data$Conference$encoder = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$author$project$App$Data$Id$uuidEncoder(model.as)),
				_Utils_Tuple2(
				'stun',
				$elm$json$Json$Encode$string(model.dN)),
				_Utils_Tuple2(
				'turn',
				$elm$json$Json$Encode$string(model.dT)),
				_Utils_Tuple2(
				'signaler',
				$elm$json$Json$Encode$string(model.dM)),
				_Utils_Tuple2(
				'password',
				$elm$json$Json$Encode$string(model.bf)),
				_Utils_Tuple2(
				'stunUser',
				$elm$json$Json$Encode$string(model.$8)),
				_Utils_Tuple2(
				'stunPassword',
				$elm$json$Json$Encode$string(model.ie)),
				_Utils_Tuple2(
				'recordingDoctor',
				$elm$json$Json$Encode$bool(model.jQ)),
				_Utils_Tuple2(
				'recordingProponent',
				$elm$json$Json$Encode$bool(model.jR))
			]));
};
var $author$project$App$Data$Conference$profileToString = function (profile) {
	if (!profile) {
		return 'proponent';
	} else {
		return 'doctor';
	}
};
var $author$project$App$Service$ApplicationResources$ridConferenceById = F2(
	function (stmtId, prof) {
		return $author$project$App$Service$Shared$ridFromString(
			'conferenceService' + ('.byId.' + (stmtId + ('.profile.' + $author$project$App$Data$Conference$profileToString(prof)))));
	});
var $author$project$App$Service$ApplicationResources$pathConferenceById = F2(
	function (stmtId, prof) {
		return $author$project$App$Service$Shared$pathFromRID(
			A2($author$project$App$Service$ApplicationResources$ridConferenceById, stmtId, prof));
	});
var $author$project$App$Service$ApplicationResources$methodSetConferenceById = F2(
	function (stmtId, prof) {
		return A3(
			$author$project$App$Service$Shared$methodFromPath,
			2,
			A2($author$project$App$Service$ApplicationResources$pathConferenceById, stmtId, prof),
			'set');
	});
var $author$project$App$Service$ApplicationResources$callSetConferenceById = F5(
	function (conf, stmtId, prof, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeConference,
			A2($author$project$App$Service$ApplicationResources$methodSetConferenceById, stmtId, prof),
			$author$project$App$Data$Conference$encoder(conf),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				A2($author$project$App$Service$ApplicationResources$methodSetConferenceById, stmtId, prof),
				api));
	});
var $author$project$App$Service$Shared$New = 3;
var $author$project$App$Data$ICD$encoder = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$author$project$App$Data$Id$uuidEncoder(model.as)),
				_Utils_Tuple2(
				'answerTemplateId',
				$author$project$App$Data$Id$uuidEncoder(model.aY)),
				_Utils_Tuple2(
				'statementId',
				$author$project$App$Data$Id$uuidEncoder(model.aV)),
				_Utils_Tuple2(
				'category',
				$elm$json$Json$Encode$string(model.a0)),
				_Utils_Tuple2(
				'sectionCategory',
				$elm$json$Json$Encode$string(
					$author$project$App$Data$QuestionSection$categoryToString(model.dE))),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(model.am))
			]));
};
var $author$project$App$Data$ICD$ridCreate = function (model) {
	return 'icdService.fromStatement.' + (model.aV + ('.answerTemplate.' + (model.aY + '.category')));
};
var $author$project$App$Service$ApplicationResources$callSetICD = F3(
	function (icd, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeICD,
			A3(
				$author$project$App$Service$Shared$methodFromPath,
				3,
				$author$project$App$Service$Shared$pathFromRID(
					$author$project$App$Service$Shared$ridFromString(
						$author$project$App$Data$ICD$ridCreate(icd))),
				icd.a0),
			$author$project$App$Data$ICD$encoder(icd),
			respMsgs,
			api);
	});
var $author$project$App$Service$SpecificResources$TypePerson = {$: 23};
var $author$project$App$Service$ApplicationResources$ridPersonById = function (pId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$servicePeople + ('.person.' + pId));
};
var $author$project$App$Service$ApplicationResources$pathPersonById = A2($elm$core$Basics$composeL, $author$project$App$Service$Shared$pathFromRID, $author$project$App$Service$ApplicationResources$ridPersonById);
var $author$project$App$Service$ApplicationResources$methodSetPersonById = function (pId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathPersonById(pId),
		'set');
};
var $author$project$App$Service$ApplicationResources$callSetPersonById = F3(
	function (person, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			person.as,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodSetPersonById(person.as),
				api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypePerson,
				$author$project$App$Service$ApplicationResources$methodSetPersonById(person.as),
				$author$project$App$Data$Person$encoder(person),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$methodSignDocument = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$Shared$pathFromRID(
			$author$project$App$Service$Shared$ridFromString('statementService.byId.' + stmtId)),
		'signMedicalReport');
};
var $author$project$App$Service$ApplicationResources$callSignDocument = F4(
	function (token, stmtId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeToken(
				$author$project$App$Service$ApplicationResources$methodSignDocument(stmtId)),
			A3(
				$author$project$App$Service$Shared$methodFromPath,
				2,
				$author$project$App$Service$Shared$pathFromRID(
					$author$project$App$Service$Shared$ridFromString('statementService.byId.' + stmtId)),
				'signMedicalReport'),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'otp',
						$elm$json$Json$Encode$string(token))
					])),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				A3(
					$author$project$App$Service$Shared$methodFromPath,
					2,
					$author$project$App$Service$Shared$pathFromRID(
						$author$project$App$Service$Shared$ridFromString('statementService.byId.' + stmtId)),
					'signMedicalReport'),
				api));
	});
var $author$project$App$Service$ApplicationResources$callSignMedicalDocument = F4(
	function (token, stmtId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeToken(
				$author$project$App$Service$ApplicationResources$methodSignDocument(stmtId)),
			A3(
				$author$project$App$Service$Shared$methodFromPath,
				2,
				$author$project$App$Service$Shared$pathFromRID(
					$author$project$App$Service$Shared$ridFromString('statementService.byId.' + stmtId)),
				'signMedicalDocument'),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'otp',
						$elm$json$Json$Encode$string(token))
					])),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				A3(
					$author$project$App$Service$Shared$methodFromPath,
					2,
					$author$project$App$Service$Shared$pathFromRID(
						$author$project$App$Service$Shared$ridFromString('statementService.byId.' + stmtId)),
					'signMedicalDocument'),
				api));
	});
var $author$project$App$Service$ApplicationResources$callStartRecording = F3(
	function (stmtId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeUnknown,
			A3(
				$author$project$App$Service$Shared$methodFromPath,
				2,
				$author$project$App$Service$Shared$pathFromRID(
					$author$project$App$Service$Shared$ridFromString('recordingService.fromStatement.' + stmtId)),
				'connectFromWeb'),
			$elm$json$Json$Encode$null,
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				A3(
					$author$project$App$Service$Shared$methodFromPath,
					2,
					$author$project$App$Service$Shared$pathFromRID(
						$author$project$App$Service$Shared$ridFromString('recordingService.fromStatement.' + stmtId)),
					'connectFromWeb'),
				api));
	});
var $author$project$App$Service$ApplicationResources$methodFinishReevaluation = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
		'finishReevaluation');
};
var $author$project$App$Service$ApplicationResources$callStatementFinishReevaluation = F3(
	function (stmt, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmt.as,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodFinishReevaluation(stmt.as),
				api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementResult(
					$author$project$App$Service$ApplicationResources$methodFinishReevaluation(stmt.as)),
				$author$project$App$Service$ApplicationResources$methodFinishReevaluation(stmt.as),
				$author$project$App$Data$Statement$encoder(stmt),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$methodStatementSaveDocumentsById = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
		'saveDocuments');
};
var $author$project$App$Service$ApplicationResources$callStatementSaveDocuments = F3(
	function (fullStmt, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			fullStmt.g.e.as,
			api,
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementResult(
					$author$project$App$Service$ApplicationResources$methodStatementSaveDocumentsById(fullStmt.g.e.as)),
				$author$project$App$Service$ApplicationResources$methodStatementSaveDocumentsById(fullStmt.g.e.as),
				$author$project$App$Data$FullStatement$fullStatementEncoder(fullStmt),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$methodStatementSetById = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
		'set');
};
var $author$project$App$Service$ApplicationResources$callStatementSetById = F3(
	function (stmt, respMsgs, api) {
		var m = $author$project$App$Service$ApplicationResources$methodStatementSetById(stmt.as);
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmt.as,
			A2($author$project$App$Service$ApiWebSocket$clearMethod, m, api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementResult(m),
				m,
				$author$project$App$Data$Statement$encoder(stmt),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$methodStatementStartInterviewById = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
		'startInterview');
};
var $author$project$App$Service$ApplicationResources$callStatementStartInterviewById = F3(
	function (stmtId, respMsgs, api) {
		var m = $author$project$App$Service$ApplicationResources$methodStatementStartInterviewById(stmtId);
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmtId,
			A2($author$project$App$Service$ApiWebSocket$clearMethod, m, api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementResult(m),
				m,
				$elm$json$Json$Encode$null,
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$callStopRecording = F3(
	function (stmtId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeUnknown,
			A3(
				$author$project$App$Service$Shared$methodFromPath,
				2,
				$author$project$App$Service$Shared$pathFromRID(
					$author$project$App$Service$Shared$ridFromString('recordingService.fromStatement.' + stmtId)),
				'disconnectFromWeb'),
			$elm$json$Json$Encode$null,
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				A3(
					$author$project$App$Service$Shared$methodFromPath,
					2,
					$author$project$App$Service$Shared$pathFromRID(
						$author$project$App$Service$Shared$ridFromString('recordingService.fromStatement.' + stmtId)),
					'disconnectFromWeb'),
				api));
	});
var $author$project$App$Service$ApplicationResources$methodUpdateDocumentFromId = function (documentId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathDocumentFromId(documentId),
		'set');
};
var $author$project$App$Service$ApplicationResources$callUpdateDocumentFromId = F3(
	function (document, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			document.as,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodUpdateDocumentFromId(document.as),
				api),
			A5(
				$author$project$App$Service$ApiWebSocket$executeMethod,
				true,
				$author$project$App$Service$SpecificResources$TypeUnknown,
				$author$project$App$Service$ApplicationResources$methodUpdateDocumentFromId(document.as),
				$author$project$App$Data$Document$encoder(document),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$ridGetNexodataTokenByStatementId = 'nexodataService.byStatementId';
var $author$project$App$Service$ApplicationResources$methodUploadDocumentFromStatementId = function (stmtId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$Shared$pathFromRID(
			$author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$ridGetNexodataTokenByStatementId + ('.' + stmtId))),
		'uploadDocument');
};
var $author$project$App$Service$ApplicationResources$callUploadDocumentFromStatementId = F4(
	function (url, stmtId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeUnknown,
			$author$project$App$Service$ApplicationResources$methodUploadDocumentFromStatementId(stmtId),
			$elm$json$Json$Encode$string(url),
			respMsgs,
			api);
	});
var $author$project$App$Service$ApplicationResources$clearAdvanceDoctorStatement = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodAdvanceDoctorStatementById(stmtId),
			api);
		_v0$2:
		while (true) {
			if (!_v0.$) {
				switch (_v0.a.$) {
					case 3:
						var _v1 = _v0.a;
						return _Utils_Tuple2(
							A2(
								$author$project$App$Service$ApiWebSocket$clearMethod,
								$author$project$App$Service$ApplicationResources$methodAdvanceDoctorStatementById(stmtId),
								api),
							$elm$core$Platform$Cmd$none);
					case 2:
						var _v2 = _v0.a;
						return _Utils_Tuple2(
							A2(
								$author$project$App$Service$ApiWebSocket$clearMethod,
								$author$project$App$Service$ApplicationResources$methodAdvanceDoctorStatementById(stmtId),
								api),
							$elm$core$Platform$Cmd$none);
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return _Utils_Tuple2(api, $elm$core$Platform$Cmd$none);
	});
var $author$project$App$Service$ApplicationResources$clearAdvanceProponentStatement = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodAdvanceProponentStatementById(stmtId),
			api);
		_v0$2:
		while (true) {
			if (!_v0.$) {
				switch (_v0.a.$) {
					case 3:
						var _v1 = _v0.a;
						return _Utils_Tuple2(
							A2(
								$author$project$App$Service$ApiWebSocket$clearMethod,
								$author$project$App$Service$ApplicationResources$methodAdvanceProponentStatementById(stmtId),
								api),
							$elm$core$Platform$Cmd$none);
					case 2:
						var _v2 = _v0.a;
						return _Utils_Tuple2(
							A2(
								$author$project$App$Service$ApiWebSocket$clearMethod,
								$author$project$App$Service$ApplicationResources$methodAdvanceProponentStatementById(stmtId),
								api),
							$elm$core$Platform$Cmd$none);
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return _Utils_Tuple2(api, $elm$core$Platform$Cmd$none);
	});
var $author$project$App$Service$ApiWebSocket$asyncDelayedMsg = F2(
	function (_float, msg) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$always(msg),
			$elm$core$Process$sleep(_float));
	});
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$App$Service$ApiWebSocket$unsubscribeResource = F3(
	function (t, path, _v0) {
		var id = _v0.a;
		var now = _v0.b;
		var smo = _v0.c;
		var sme = _v0.d;
		var ws = _v0.e;
		var sc = _v0.f;
		var cm = _v0.g;
		var c = _v0.h;
		var rid = $author$project$App$Service$Shared$pathToString(path);
		var uC = A2(
			$elm$core$Dict$filter,
			F2(
				function (k, _v3) {
					return _Utils_eq(rid, k) ? false : true;
				}),
			c);
		var uSc = A2(
			$elm$core$Dict$filter,
			F2(
				function (k, _v2) {
					return _Utils_eq(rid, k) ? false : true;
				}),
			sc);
		var request = {as: id, z: 'unsubscribe.' + rid, du: $elm$core$Maybe$Nothing};
		var _v1 = A2(
			$author$project$App$Service$Websocket$send,
			$author$project$App$Service$ApiWebSocket$resourceRequestEncode(request),
			ws);
		var uWs = _v1.a;
		var wsCmds = _v1.b;
		return $author$project$App$Service$Websocket$isConnected(ws) ? _Utils_Tuple2(
			A8($author$project$App$Service$ApiWebSocket$Resgate, id + 1, now, smo, sme, uWs, uSc, cm, uC),
			A2($elm$core$Platform$Cmd$map, $author$project$App$Service$ApiWebSocket$WebsocketMsg, wsCmds)) : _Utils_Tuple2(
			A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, c),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$App$Service$ApiWebSocket$clearCache = F2(
	function (_v0, respMsgs) {
		var id = _v0.a;
		var now = _v0.b;
		var smo = _v0.c;
		var rMethod = _v0.d;
		var ws = _v0.e;
		var sc = _v0.f;
		var cm = _v0.g;
		var c = _v0.h;
		var _v1 = A3(
			$elm$core$Dict$foldl,
			F3(
				function (_v3, r, _v4) {
					var m = _v4.a;
					var aC = _v4.b;
					var _v5 = function () {
						var t = r.b;
						var path = r.c;
						return A3($author$project$App$Service$ApiWebSocket$unsubscribeResource, t, path, m);
					}();
					var nM = _v5.a;
					var nC = _v5.b;
					return _Utils_Tuple2(
						nM,
						A2($elm$core$List$cons, nC, aC));
				}),
			_Utils_Tuple2(
				A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, rMethod, ws, sc, cm, c),
				_List_Nil),
			sc);
		var _v2 = _v1.a;
		var nId = _v2.a;
		var nWs = _v2.e;
		var unsubCmds = _v1.b;
		return _Utils_Tuple2(
			A8(
				$author$project$App$Service$ApiWebSocket$Resgate,
				nId,
				now,
				smo,
				rMethod,
				nWs,
				$elm$core$Dict$empty,
				$elm$core$Dict$empty,
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v7, r) {
							if ((r.$ === 3) && (r.a.$ === 2)) {
								return true;
							} else {
								return false;
							}
						}),
					c)),
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$cons,
					A2(
						$author$project$App$Service$ApiWebSocket$asyncDelayedMsg,
						50.0,
						$author$project$App$Service$ApiWebSocket$ResponseAlreadyAvailable(respMsgs)),
					unsubCmds)));
	});
var $author$project$App$Service$ApplicationResources$clearFinishDoctorStatement = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodFinishDoctorStatementById(stmtId),
			api);
		_v0$2:
		while (true) {
			if (!_v0.$) {
				switch (_v0.a.$) {
					case 3:
						var _v1 = _v0.a;
						return _Utils_Tuple2(
							A2(
								$author$project$App$Service$ApiWebSocket$clearMethod,
								$author$project$App$Service$ApplicationResources$methodFinishDoctorStatementById(stmtId),
								api),
							$elm$core$Platform$Cmd$none);
					case 2:
						var _v2 = _v0.a;
						return _Utils_Tuple2(
							A2(
								$author$project$App$Service$ApiWebSocket$clearMethod,
								$author$project$App$Service$ApplicationResources$methodFinishDoctorStatementById(stmtId),
								api),
							$elm$core$Platform$Cmd$none);
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return _Utils_Tuple2(api, $elm$core$Platform$Cmd$none);
	});
var $author$project$App$Service$ApplicationResources$clearRevertDoctorStatement = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodRevertDoctorStatementById(stmtId),
			api);
		_v0$2:
		while (true) {
			if (!_v0.$) {
				switch (_v0.a.$) {
					case 3:
						var _v1 = _v0.a;
						return _Utils_Tuple2(
							A2(
								$author$project$App$Service$ApiWebSocket$clearMethod,
								$author$project$App$Service$ApplicationResources$methodRevertDoctorStatementById(stmtId),
								api),
							$elm$core$Platform$Cmd$none);
					case 2:
						var _v2 = _v0.a;
						return _Utils_Tuple2(
							A2(
								$author$project$App$Service$ApiWebSocket$clearMethod,
								$author$project$App$Service$ApplicationResources$methodRevertDoctorStatementById(stmtId),
								api),
							$elm$core$Platform$Cmd$none);
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return _Utils_Tuple2(api, $elm$core$Platform$Cmd$none);
	});
var $author$project$App$Service$ApplicationResources$clearRevertProponentStatement = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodRevertProponentStatementById(stmtId),
			api);
		_v0$2:
		while (true) {
			if (!_v0.$) {
				switch (_v0.a.$) {
					case 3:
						var _v1 = _v0.a;
						return _Utils_Tuple2(
							A2(
								$author$project$App$Service$ApiWebSocket$clearMethod,
								$author$project$App$Service$ApplicationResources$methodRevertProponentStatementById(stmtId),
								api),
							$elm$core$Platform$Cmd$none);
					case 2:
						var _v2 = _v0.a;
						return _Utils_Tuple2(
							A2(
								$author$project$App$Service$ApiWebSocket$clearMethod,
								$author$project$App$Service$ApplicationResources$methodRevertProponentStatementById(stmtId),
								api),
							$elm$core$Platform$Cmd$none);
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return _Utils_Tuple2(api, $elm$core$Platform$Cmd$none);
	});
var $author$project$App$Data$StatementMessage$emptyModel = {
	iW: $elm$time$Time$millisToPosix(0),
	as: $author$project$App$Data$Id$uuidNil,
	jl: '',
	fF: '',
	eS: $author$project$App$Data$Id$uuidNil,
	jX: '',
	aV: $author$project$App$Data$Id$uuidNil,
	aw: ''
};
var $author$project$App$Data$Profile$codeToString = function (code) {
	switch (code) {
		case 0:
			return 'doctor';
		case 1:
			return 'company';
		case 2:
			return 'reviewer';
		case 3:
			return 'support';
		case 4:
			return 'proponent';
		case 5:
			return 'admin';
		default:
			return 'other';
	}
};
var $author$project$App$Data$Profile$encode = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$author$project$App$Data$Id$uuidEncoder(model.as)),
				_Utils_Tuple2(
				'code',
				$elm$json$Json$Encode$string(
					$author$project$App$Data$Profile$codeToString(model.gu))),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(model.am))
			]));
};
var $author$project$App$Data$Token$encode = function (mo) {
	return $elm$json$Json$Encode$object(
		function (list) {
			return A2(
				$elm$core$Maybe$withDefault,
				list,
				A2(
					$elm$core$Maybe$map,
					function (sId) {
						return A2(
							$elm$core$List$append,
							_List_fromArray(
								[
									_Utils_Tuple2(
									'statementId',
									$author$project$App$Data$Id$uuidEncoder(sId))
								]),
							list);
					},
					mo.aV));
		}(
			function (list) {
				return A2(
					$elm$core$Maybe$withDefault,
					list,
					A2(
						$elm$core$Maybe$map,
						function (cId) {
							return A2(
								$elm$core$List$append,
								_List_fromArray(
									[
										_Utils_Tuple2(
										'companyId',
										$author$project$App$Data$Id$uuidEncoder(cId))
									]),
								list);
						},
						mo.gx));
			}(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'email',
						$elm$json$Json$Encode$string(mo.c7)),
						_Utils_Tuple2(
						'extra',
						$elm$json$Json$Encode$string(mo.fq)),
						_Utils_Tuple2(
						'person',
						$author$project$App$Data$Person$encoder(mo.dw)),
						_Utils_Tuple2(
						'personId',
						$author$project$App$Data$Id$uuidEncoder(mo.Y)),
						_Utils_Tuple2(
						'emittedOn',
						$author$project$App$Data$Id$timeEncoder(mo.fn)),
						_Utils_Tuple2(
						'profiles',
						A2($elm$json$Json$Encode$list, $author$project$App$Data$Profile$encode, mo.fN)),
						_Utils_Tuple2(
						'session',
						$elm$json$Json$Encode$string(mo.dI))
					]))));
};
var $author$project$App$Service$Loadable$Failed = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Service$Loadable$Got = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Service$Loadable$Loading = {$: 1};
var $author$project$App$Service$Loadable$NotLoaded = {$: 0};
var $author$project$App$Service$SpecificResources$DecodeError = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Service$ApiWebSocket$Error = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$App$Service$ApiWebSocket$unknownError = function (msg) {
	return A2(
		$author$project$App$Service$ApiWebSocket$Error,
		$author$project$App$Service$SpecificResources$DecodeError(msg),
		$elm$json$Json$Encode$null);
};
var $author$project$App$Service$ApplicationResources$getAdvanceDoctorStatementResponse = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodAdvanceDoctorStatementById(stmtId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 27) && (_v0.a.c.$ === 16)) {
						var _v1 = _v0.a;
						var _v2 = _v1.a;
						var model = _v1.c.a;
						return $author$project$App$Service$Loadable$Got(model);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Service$ApplicationResources$getAdvanceProponentStatementResponse = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodAdvanceProponentStatementById(stmtId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 27) && (_v0.a.c.$ === 16)) {
						var _v1 = _v0.a;
						var _v2 = _v1.a;
						var model = _v1.c.a;
						return $author$project$App$Service$Loadable$Got(model);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Data$Token$Model = F9(
	function (email, extra, person, personId, companyId, statementId, emittedOn, profiles, session) {
		return {gx: companyId, c7: email, fn: emittedOn, fq: extra, dw: person, Y: personId, fN: profiles, dI: session, aV: statementId};
	});
var $author$project$App$Data$Person$Model = function (id) {
	return function (name) {
		return function (surname) {
			return function (socialName) {
				return function (document) {
					return function (sex) {
						return function (civilState) {
							return function (birthDate) {
								return function (motherName) {
									return function (photo) {
										return function (updatedAt) {
											return function (selfieChanged) {
												return {al: birthDate, D: civilState, i1: document, as: id, cp: motherName, hl: name, dy: photo, cD: selfieChanged, dJ: sex, B: socialName, ii: surname, f2: updatedAt};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$Person$Divorced = 3;
var $author$project$App$Data$Person$Married = 2;
var $author$project$App$Data$Person$Single = 1;
var $author$project$App$Data$Person$StableRelation = 5;
var $author$project$App$Data$Person$Widowed = 4;
var $elm$core$String$toLower = _String_toLower;
var $author$project$App$Data$Person$civilStatusFromString = function (status) {
	var _v0 = $elm$core$String$toLower(status);
	switch (_v0) {
		case 'solteiro':
			return 1;
		case 'casado':
			return 2;
		case 'divorciado':
			return 3;
		case 'viuvo':
			return 4;
		case 'estavel':
			return 5;
		default:
			return 0;
	}
};
var $author$project$App$Data$Person$civilStatusDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		return $elm$json$Json$Decode$succeed(
			$author$project$App$Data$Person$civilStatusFromString(str));
	},
	$elm$json$Json$Decode$string);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded = A2($elm$core$Basics$composeR, $elm$json$Json$Decode$succeed, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom);
var $author$project$App$Data$Person$Female = 2;
var $author$project$App$Data$Person$Male = 1;
var $author$project$App$Data$Person$sexFromString = function (str) {
	var _v0 = $elm$core$String$toLower(str);
	switch (_v0) {
		case 'm':
			return 1;
		case 'f':
			return 2;
		default:
			return 0;
	}
};
var $author$project$App$Data$Person$sexDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		return $elm$json$Json$Decode$succeed(
			$author$project$App$Data$Person$sexFromString(str));
	},
	$elm$json$Json$Decode$string);
var $author$project$App$Data$Id$timeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (i) {
		return $elm$json$Json$Decode$succeed(
			$elm$time$Time$millisToPosix(i));
	},
	$elm$json$Json$Decode$int);
var $author$project$App$Data$Id$uuidDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (id) {
		return (($elm$core$String$length(id) > 0) && $author$project$App$Data$Id$uuidIsValid(id)) ? $elm$json$Json$Decode$succeed(id) : $elm$json$Json$Decode$succeed($author$project$App$Data$Id$uuidNil);
	},
	$elm$json$Json$Decode$string);
var $author$project$App$Data$Person$decoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
	false,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'updatedAt',
		$author$project$App$Data$Id$timeDecoder,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'photo',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'motherName',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'birthDate',
					$author$project$App$Data$Id$timeDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'civilState',
						$author$project$App$Data$Person$civilStatusDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'sex',
							$author$project$App$Data$Person$sexDecoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'document',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'socialName',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'surname',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'name',
											$elm$json$Json$Decode$string,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'id',
												$author$project$App$Data$Id$uuidDecoder,
												$elm$json$Json$Decode$succeed($author$project$App$Data$Person$Model)))))))))))));
var $author$project$App$Data$Profile$Model = F3(
	function (id, code, description) {
		return {gu: code, am: description, as: id};
	});
var $author$project$App$Data$Profile$Admin = 5;
var $author$project$App$Data$Profile$Company = 1;
var $author$project$App$Data$Profile$Doctor = 0;
var $author$project$App$Data$Profile$Other = 6;
var $author$project$App$Data$Profile$Proponent = 4;
var $author$project$App$Data$Profile$Reviewer = 2;
var $author$project$App$Data$Profile$Support = 3;
var $author$project$App$Data$Profile$codeFromString = function (str) {
	switch (str) {
		case 'doctor':
			return 0;
		case 'company':
			return 1;
		case 'reviewer':
			return 2;
		case 'support':
			return 3;
		case 'proponent':
			return 4;
		case 'admin':
			return 5;
		default:
			return 6;
	}
};
var $author$project$App$Data$Profile$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'description',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'code',
		A2($elm$json$Json$Decode$map, $author$project$App$Data$Profile$codeFromString, $elm$json$Json$Decode$string),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$author$project$App$Data$Id$uuidDecoder,
			$elm$json$Json$Decode$succeed($author$project$App$Data$Profile$Model))));
var $author$project$App$Data$Token$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'session',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'profiles',
		$elm$json$Json$Decode$list($author$project$App$Data$Profile$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'emittedOn',
			$author$project$App$Data$Id$timeDecoder,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'statementId',
				$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'companyId',
					$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
					$elm$core$Maybe$Nothing,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'personId',
						$author$project$App$Data$Id$uuidDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'person',
							$author$project$App$Data$Person$decoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'extra',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'email',
									$elm$json$Json$Decode$string,
									$elm$json$Json$Decode$succeed($author$project$App$Data$Token$Model))))))))));
var $author$project$App$Service$ApiWebSocket$getAuthToken = function (_v0) {
	var smo = _v0.c;
	if (smo.$ === 3) {
		var _v2 = smo.b;
		var token = _v2.c;
		return token;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Service$ApplicationResources$getAuthToken = function (api) {
	var _v0 = $author$project$App$Service$ApiWebSocket$getAuthToken(api);
	if (!_v0.$) {
		var tokenValue = _v0.a;
		var _v1 = A2($elm$json$Json$Decode$decodeValue, $author$project$App$Data$Token$decoder, tokenValue);
		if (!_v1.$) {
			var token = _v1.a;
			return $elm$core$Maybe$Just(token);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Service$ApplicationResources$getFinishDoctorStatementResponse = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodFinishDoctorStatementById(stmtId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 27) && (_v0.a.c.$ === 16)) {
						var _v1 = _v0.a;
						var _v2 = _v1.a;
						var model = _v1.c.a;
						return $author$project$App$Service$Loadable$Got(model);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Service$ApplicationResources$getRevertDoctorStatementResponse = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodRevertDoctorStatementById(stmtId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 27) && (_v0.a.c.$ === 16)) {
						var _v1 = _v0.a;
						var _v2 = _v1.a;
						var model = _v1.c.a;
						return $author$project$App$Service$Loadable$Got(model);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Service$ApplicationResources$getRevertProponentStatementResponse = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodRevertProponentStatementById(stmtId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 27) && (_v0.a.c.$ === 16)) {
						var _v1 = _v0.a;
						var _v2 = _v1.a;
						var model = _v1.c.a;
						return $author$project$App$Service$Loadable$Got(model);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Service$ApplicationResources$ridStatementTemplateById = function (templateId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatementTemplate + ('.byId.' + templateId));
};
var $author$project$App$Service$ApplicationResources$pathStatementTemplateById = function (templateId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridStatementTemplateById(templateId));
};
var $author$project$App$Service$ApplicationResources$getStatementTemplateById = F2(
	function (templateId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getResource,
			$author$project$App$Service$ApplicationResources$pathStatementTemplateById(templateId),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 32)) && (_v0.a.c.$ === 19)) {
			var _v1 = _v0.a;
			var _v2 = _v1.a;
			var model = _v1.c.a;
			return $elm$core$Maybe$Just(model);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$Websocket$websocketDisconnect = _Platform_outgoingPort('websocketDisconnect', $elm$json$Json$Encode$string);
var $author$project$App$Service$Websocket$disconnect = function (_v0) {
	return $author$project$App$Service$Websocket$websocketDisconnect('');
};
var $author$project$App$Service$ApiWebSocket$encodeInternalToken = function (t) {
	if (t.b.$ === 1) {
		var name = t.a;
		var _v1 = t.b;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string(name))
				]));
	} else {
		if (!t.c.$) {
			var name = t.a;
			var time = t.b.a;
			var token = t.c.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'name',
						$elm$json$Json$Encode$string(name)),
						_Utils_Tuple2(
						'time',
						$elm$json$Json$Encode$int(
							$elm$time$Time$posixToMillis(time))),
						_Utils_Tuple2('value', token)
					]));
		} else {
			var name = t.a;
			var time = t.b.a;
			var _v2 = t.c;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'name',
						$elm$json$Json$Encode$string(name)),
						_Utils_Tuple2(
						'time',
						$elm$json$Json$Encode$int(
							$elm$time$Time$posixToMillis(time)))
					]));
		}
	}
};
var $author$project$App$Service$ApiWebSocket$sessionSaveToken = _Platform_outgoingPort('sessionSaveToken', $elm$core$Basics$identity);
var $author$project$App$Service$ApiWebSocket$saveInternalToken = function (token) {
	return $author$project$App$Service$ApiWebSocket$sessionSaveToken(
		$author$project$App$Service$ApiWebSocket$encodeInternalToken(token));
};
var $author$project$App$Service$ApiWebSocket$logout = function (_v0) {
	var id = _v0.a;
	var now = _v0.b;
	var smo = _v0.c;
	var rMethod = _v0.d;
	var ws = _v0.e;
	var sc = _v0.f;
	var cm = _v0.g;
	var c = _v0.h;
	var _v1 = A3(
		$elm$core$Dict$foldl,
		F3(
			function (k, r, _v3) {
				var m = _v3.a;
				var aC = _v3.b;
				var _v4 = function () {
					var t = r.b;
					var path = r.c;
					return A3($author$project$App$Service$ApiWebSocket$unsubscribeResource, t, path, m);
				}();
				var nM = _v4.a;
				var nC = _v4.b;
				return _Utils_Tuple2(
					nM,
					A2($elm$core$List$cons, nC, aC));
			}),
		_Utils_Tuple2(
			A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, rMethod, ws, sc, cm, c),
			_List_Nil),
		sc);
	var _v2 = _v1.a;
	var nWs = _v2.e;
	var unsubCmds = _v1.b;
	var logoutTuple = function (tokenName) {
		return _Utils_Tuple2(
			A8(
				$author$project$App$Service$ApiWebSocket$Resgate,
				id,
				now,
				$author$project$App$Service$ApiWebSocket$NotAuthenticated(
					A3(
						$author$project$App$Service$ApiWebSocket$InternalToken,
						tokenName,
						$elm$core$Maybe$Just(now),
						$elm$core$Maybe$Nothing)),
				rMethod,
				nWs,
				$elm$core$Dict$empty,
				$elm$core$Dict$empty,
				$elm$core$Dict$empty),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2(
						$elm$core$Platform$Cmd$map,
						$author$project$App$Service$ApiWebSocket$WebsocketMsg,
						$author$project$App$Service$Websocket$disconnect(ws)),
						$elm$core$Platform$Cmd$batch(unsubCmds),
						$author$project$App$Service$ApiWebSocket$saveInternalToken(
						A3(
							$author$project$App$Service$ApiWebSocket$InternalToken,
							tokenName,
							$elm$core$Maybe$Just(now),
							$elm$core$Maybe$Nothing))
					])));
	};
	switch (smo.$) {
		case 0:
			return _Utils_Tuple2(
				A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, rMethod, ws, sc, cm, c),
				$elm$core$Platform$Cmd$none);
		case 1:
			var _v7 = smo.b;
			var tokenName = _v7.a;
			return logoutTuple(tokenName);
		case 2:
			var _v8 = smo.a;
			var tokenName = _v8.a;
			return logoutTuple(tokenName);
		case 3:
			var _v9 = smo.b;
			var tokenName = _v9.a;
			return logoutTuple(tokenName);
		default:
			var _v10 = smo.b;
			var tokenName = _v10.a;
			return logoutTuple(tokenName);
	}
};
var $author$project$App$Service$ApiWebSocket$pipe = F2(
	function (f, _v0) {
		var api = _v0.a;
		var cmd = _v0.b;
		var _v1 = f(api);
		var nApi = _v1.a;
		var nCmd = _v1.b;
		return _Utils_Tuple2(
			nApi,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, nCmd])));
	});
var $author$project$App$Service$SpecificResources$TypeReportMonthDetails = function (a) {
	return {$: 43, a: a};
};
var $author$project$App$Service$ApplicationResources$serviceReport = 'reportService';
var $author$project$App$Service$ApplicationResources$ridReportByCompanyAndDate = F3(
	function (cId, startDate, endDate) {
		return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceReport + ('.byCompany.' + (cId + ('.startingDate.' + (startDate + ('.endingDate.' + endDate))))));
	});
var $author$project$App$Service$ApplicationResources$pathReportByCompanyAndDate = F3(
	function (cId, startDate, endDate) {
		return $author$project$App$Service$Shared$pathFromRID(
			A3($author$project$App$Service$ApplicationResources$ridReportByCompanyAndDate, cId, startDate, endDate));
	});
var $author$project$App$Service$ApplicationResources$methodReportFromCompanyAndDate = F3(
	function (cId, startDate, endDate) {
		return A3(
			$author$project$App$Service$Shared$methodFromPath,
			2,
			A3($author$project$App$Service$ApplicationResources$pathReportByCompanyAndDate, cId, startDate, endDate),
			'getReport');
	});
var $author$project$App$Service$ApplicationResources$requestCompanyReport = F7(
	function (cId, startDate, endDate, doctor, template, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeReportMonthDetails(
				A3($author$project$App$Service$ApplicationResources$methodReportFromCompanyAndDate, cId, startDate, endDate)),
			A3($author$project$App$Service$ApplicationResources$methodReportFromCompanyAndDate, cId, startDate, endDate),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'doctor',
						$elm$json$Json$Encode$string(doctor)),
						_Utils_Tuple2(
						'template',
						$elm$json$Json$Encode$string(template))
					])),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				A3($author$project$App$Service$ApplicationResources$methodReportFromCompanyAndDate, cId, startDate, endDate),
				api));
	});
var $author$project$App$Service$ApplicationResources$ridDocumentFromCompany = function (companyId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceDocument + ('.' + ('fromCompany.' + companyId)));
};
var $author$project$App$Service$ApplicationResources$pathDocumentFromCompany = function (companyId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridDocumentFromCompany(companyId));
};
var $author$project$App$Service$ApplicationResources$methodCreateCompanyReportCsv = function (companyId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathDocumentFromCompany(companyId),
		'createReportCsv');
};
var $author$project$App$Service$ApplicationResources$requestCreateCompanyReportCsv = F7(
	function (cId, startDate, endDate, doctor, template, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeSingleDocument(
				$author$project$App$Service$ApplicationResources$methodCreateCompanyReportCsv(cId)),
			$author$project$App$Service$ApplicationResources$methodCreateCompanyReportCsv(cId),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'startDate',
						$elm$json$Json$Encode$string(startDate)),
						_Utils_Tuple2(
						'endDate',
						$elm$json$Json$Encode$string(endDate)),
						_Utils_Tuple2(
						'doctor',
						$elm$json$Json$Encode$string(doctor)),
						_Utils_Tuple2(
						'template',
						$elm$json$Json$Encode$string(template))
					])),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodCreateCompanyReportCsv(cId),
				api));
	});
var $author$project$App$Service$ApplicationResources$methodCreateCompanyReportPdf = function (companyId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathDocumentFromCompany(companyId),
		'createReportPdf');
};
var $author$project$App$Service$ApplicationResources$requestCreateCompanyReportPdf = F7(
	function (cId, startDate, endDate, doctor, template, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeSingleDocument(
				$author$project$App$Service$ApplicationResources$methodCreateCompanyReportPdf(cId)),
			$author$project$App$Service$ApplicationResources$methodCreateCompanyReportPdf(cId),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'startDate',
						$elm$json$Json$Encode$string(startDate)),
						_Utils_Tuple2(
						'endDate',
						$elm$json$Json$Encode$string(endDate)),
						_Utils_Tuple2(
						'doctor',
						$elm$json$Json$Encode$string(doctor)),
						_Utils_Tuple2(
						'template',
						$elm$json$Json$Encode$string(template))
					])),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodCreateCompanyReportPdf(cId),
				api));
	});
var $author$project$App$Service$ApplicationResources$ridDocumentFromDoctor = function (dId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceDocument + ('.' + ('fromDoctor.' + dId)));
};
var $author$project$App$Service$ApplicationResources$pathDocumentFromDoctor = function (dId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridDocumentFromDoctor(dId));
};
var $author$project$App$Service$ApplicationResources$methodCreateDoctorReportCsv = function (dId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathDocumentFromDoctor(dId),
		'createReportCsv');
};
var $author$project$App$Service$ApplicationResources$requestCreateDoctorReportCsv = F7(
	function (cId, startDate, endDate, doctor, template, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeSingleDocument(
				$author$project$App$Service$ApplicationResources$methodCreateDoctorReportCsv(cId)),
			$author$project$App$Service$ApplicationResources$methodCreateDoctorReportCsv(cId),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'startDate',
						$elm$json$Json$Encode$string(startDate)),
						_Utils_Tuple2(
						'endDate',
						$elm$json$Json$Encode$string(endDate)),
						_Utils_Tuple2(
						'doctor',
						$elm$json$Json$Encode$string(doctor)),
						_Utils_Tuple2(
						'template',
						$elm$json$Json$Encode$string(template))
					])),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodCreateDoctorReportCsv(cId),
				api));
	});
var $author$project$App$Service$ApplicationResources$methodCreateDoctorReportPdf = function (dId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathDocumentFromDoctor(dId),
		'createReportPdf');
};
var $author$project$App$Service$ApplicationResources$requestCreateDoctorReportPdf = F7(
	function (dId, startDate, endDate, doctor, template, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeSingleDocument(
				$author$project$App$Service$ApplicationResources$methodCreateDoctorReportPdf(dId)),
			$author$project$App$Service$ApplicationResources$methodCreateDoctorReportPdf(dId),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'startDate',
						$elm$json$Json$Encode$string(startDate)),
						_Utils_Tuple2(
						'endDate',
						$elm$json$Json$Encode$string(endDate)),
						_Utils_Tuple2(
						'doctor',
						$elm$json$Json$Encode$string(doctor)),
						_Utils_Tuple2(
						'template',
						$elm$json$Json$Encode$string(template))
					])),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				$author$project$App$Service$ApplicationResources$methodCreateDoctorReportPdf(dId),
				api));
	});
var $author$project$App$Service$ApplicationResources$ridMedsynReportByCompanyAndDate = F4(
	function (mId, cId, startDate, endDate) {
		return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceReport + ('.medsyn.' + (mId + ('.byCompany.' + (cId + ('.startingDate.' + (startDate + ('.endingDate.' + endDate))))))));
	});
var $author$project$App$Service$ApplicationResources$pathMedsynReportByCompanyAndDate = F4(
	function (mId, cId, startDate, endDate) {
		return $author$project$App$Service$Shared$pathFromRID(
			A4($author$project$App$Service$ApplicationResources$ridMedsynReportByCompanyAndDate, mId, cId, startDate, endDate));
	});
var $author$project$App$Service$ApplicationResources$methodMedsynReportFromCompanyAndDate = F4(
	function (mId, cId, startDate, endDate) {
		return A3(
			$author$project$App$Service$Shared$methodFromPath,
			2,
			A4($author$project$App$Service$ApplicationResources$pathMedsynReportByCompanyAndDate, mId, cId, startDate, endDate),
			'getReport');
	});
var $author$project$App$Service$ApplicationResources$requestMedsynCompanyReport = F8(
	function (mId, cId, startDate, endDate, doctor, template, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeReportMonthDetails(
				A4($author$project$App$Service$ApplicationResources$methodMedsynReportFromCompanyAndDate, mId, cId, startDate, endDate)),
			A4($author$project$App$Service$ApplicationResources$methodMedsynReportFromCompanyAndDate, mId, cId, startDate, endDate),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'doctor',
						$elm$json$Json$Encode$string(doctor)),
						_Utils_Tuple2(
						'template',
						$elm$json$Json$Encode$string(template))
					])),
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				A4($author$project$App$Service$ApplicationResources$methodMedsynReportFromCompanyAndDate, mId, cId, startDate, endDate),
				api));
	});
var $author$project$App$Service$ApplicationResources$methodMedsynMonthDetailsFromCompanyAndDate = F4(
	function (mId, cId, startDate, endDate) {
		return A3(
			$author$project$App$Service$Shared$methodFromPath,
			2,
			A4($author$project$App$Service$ApplicationResources$pathMedsynReportByCompanyAndDate, mId, cId, startDate, endDate),
			'monthDetails');
	});
var $author$project$App$Service$ApplicationResources$requestMedsynMonthDetailsByCompany = F6(
	function (mId, cId, startDate, endDate, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeReportMonthDetails(
				A4($author$project$App$Service$ApplicationResources$methodMedsynMonthDetailsFromCompanyAndDate, mId, cId, startDate, endDate)),
			A4($author$project$App$Service$ApplicationResources$methodMedsynMonthDetailsFromCompanyAndDate, mId, cId, startDate, endDate),
			$elm$json$Json$Encode$null,
			respMsgs,
			api);
	});
var $author$project$App$Service$ApplicationResources$methodMonthDetailsFromCompanyAndDate = F3(
	function (cId, startDate, endDate) {
		return A3(
			$author$project$App$Service$Shared$methodFromPath,
			2,
			A3($author$project$App$Service$ApplicationResources$pathReportByCompanyAndDate, cId, startDate, endDate),
			'monthDetails');
	});
var $author$project$App$Service$ApplicationResources$requestMonthDetailsByCompany = F5(
	function (cId, startDate, endDate, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeReportMonthDetails(
				A3($author$project$App$Service$ApplicationResources$methodMonthDetailsFromCompanyAndDate, cId, startDate, endDate)),
			A3($author$project$App$Service$ApplicationResources$methodMonthDetailsFromCompanyAndDate, cId, startDate, endDate),
			$elm$json$Json$Encode$null,
			respMsgs,
			api);
	});
var $author$project$App$Service$ApplicationResources$ridReportByDoctorAndDate = F3(
	function (dId, startDate, endDate) {
		return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceReport + ('.byDoctor.' + (dId + ('.startingDate.' + (startDate + ('.endingDate.' + endDate))))));
	});
var $author$project$App$Service$ApplicationResources$pathReportByDoctorAndDate = F3(
	function (dId, startDate, endDate) {
		return $author$project$App$Service$Shared$pathFromRID(
			A3($author$project$App$Service$ApplicationResources$ridReportByDoctorAndDate, dId, startDate, endDate));
	});
var $author$project$App$Service$ApplicationResources$methodMonthDetailsFromDoctorAndDate = F3(
	function (dId, startDate, endDate) {
		return A3(
			$author$project$App$Service$Shared$methodFromPath,
			2,
			A3($author$project$App$Service$ApplicationResources$pathReportByDoctorAndDate, dId, startDate, endDate),
			'monthDetails');
	});
var $author$project$App$Service$ApplicationResources$requestMonthDetailsByDoctor = F5(
	function (dId, startDate, endDate, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeReportMonthDetails(
				A3($author$project$App$Service$ApplicationResources$methodMonthDetailsFromDoctorAndDate, dId, startDate, endDate)),
			A3($author$project$App$Service$ApplicationResources$methodMonthDetailsFromDoctorAndDate, dId, startDate, endDate),
			$elm$json$Json$Encode$null,
			respMsgs,
			api);
	});
var $author$project$App$Service$SpecificResources$TypeReportSummaries = function (a) {
	return {$: 41, a: a};
};
var $author$project$App$Service$ApplicationResources$ridReportByCompany = function (cId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceReport + ('.byCompany.' + cId));
};
var $author$project$App$Service$ApplicationResources$pathReportByCompany = function (cId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridReportByCompany(cId));
};
var $author$project$App$Service$ApplicationResources$methodMonthSummariesFromCompany = function (cId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathReportByCompany(cId),
		'monthSummaries');
};
var $author$project$App$Service$ApplicationResources$requestMonthSummaryByCompany = F3(
	function (cId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeReportSummaries(
				$author$project$App$Service$ApplicationResources$methodMonthSummariesFromCompany(cId)),
			$author$project$App$Service$ApplicationResources$methodMonthSummariesFromCompany(cId),
			$elm$json$Json$Encode$null,
			respMsgs,
			api);
	});
var $author$project$App$Service$ApplicationResources$ridReportByDoctor = function (pId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceReport + ('.byDoctor.' + pId));
};
var $author$project$App$Service$ApplicationResources$pathReportByDoctor = function (pId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridReportByDoctor(pId));
};
var $author$project$App$Service$ApplicationResources$methodMonthSummariesFromDoctor = function (pId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathReportByDoctor(pId),
		'monthSummaries');
};
var $author$project$App$Service$ApplicationResources$requestMonthSummaryByDoctor = F3(
	function (pId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeReportSummaries(
				$author$project$App$Service$ApplicationResources$methodMonthSummariesFromDoctor(pId)),
			$author$project$App$Service$ApplicationResources$methodMonthSummariesFromDoctor(pId),
			$elm$json$Json$Encode$null,
			respMsgs,
			api);
	});
var $author$project$App$Service$SpecificResources$TypeTaxes = function (a) {
	return {$: 42, a: a};
};
var $author$project$App$Service$ApplicationResources$methodTaxesFromCompany = function (cId) {
	return A3(
		$author$project$App$Service$Shared$methodFromPath,
		2,
		$author$project$App$Service$ApplicationResources$pathReportByCompany(cId),
		'taxes');
};
var $author$project$App$Service$ApplicationResources$requestTaxesByCompany = F3(
	function (cId, respMsgs, api) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			true,
			$author$project$App$Service$SpecificResources$TypeTaxes(
				$author$project$App$Service$ApplicationResources$methodTaxesFromCompany(cId)),
			$author$project$App$Service$ApplicationResources$methodTaxesFromCompany(cId),
			$elm$json$Json$Encode$null,
			respMsgs,
			api);
	});
var $author$project$App$Service$SpecificResources$TypeAnswersCollection = {$: 4};
var $author$project$App$Service$Answer$ridAnswersFromStatementAndCategory = F2(
	function (stmtId, cat) {
		return $author$project$App$Service$Shared$ridFromString(
			$author$project$App$Service$Answer$serviceAnswer + ('.fromStatement.' + (stmtId + ('.category.' + $author$project$App$Data$QuestionSection$categoryToString(cat)))));
	});
var $author$project$App$Service$Answer$pathAnswersFromStatementAndCategory = F2(
	function (stmtId, cat) {
		return $author$project$App$Service$Shared$pathFromRID(
			A2($author$project$App$Service$Answer$ridAnswersFromStatementAndCategory, stmtId, cat));
	});
var $author$project$App$Service$Answer$subscribeAnswersFromStatementAndCategory = F4(
	function (stmtId, cat, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmtId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeAnswersCollection,
				A2($author$project$App$Service$Answer$pathAnswersFromStatementAndCategory, stmtId, cat),
				respMsgs));
	});
var $author$project$App$Service$SpecificResources$TypeStatementCollection = {$: 28};
var $author$project$App$Components$Filter$FilterFieldApply = {$: 8};
var $author$project$App$Components$Filter$offset = function (filter) {
	return (filter.aQ - 1) * filter.cm;
};
var $author$project$App$Components$Filter$fixedQuery = function (filter) {
	return function (stmtRule) {
		return A2(
			$elm$core$Maybe$withDefault,
			stmtRule,
			A2(
				$elm$core$Maybe$map,
				function (order) {
					return 'order(' + (order + ('&' + (stmtRule + ')')));
				},
				filter.bE));
	}(
		function (stmtRule) {
			var limit = 'limit(' + ($elm$core$String$fromInt(filter.cm) + (',' + $elm$core$String$fromInt(
				$author$project$App$Components$Filter$offset(filter))));
			return (!$elm$core$String$length(stmtRule)) ? (limit + ')') : (limit + ('&' + (stmtRule + ')')));
		}(
			A2(
				$elm$core$String$join,
				'',
				$elm$core$List$reverse(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, v) {
								return (!i) ? (v + ')') : v;
							}),
						$elm$core$List$reverse(
							A2(
								$elm$core$List$map,
								function (_v0) {
									var v = _v0.b;
									return v;
								},
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, v) {
											return (!i) ? _Utils_Tuple2(i, 'and(' + v) : _Utils_Tuple2(i, '&' + v);
										}),
									filter.aH))))))));
};
var $author$project$App$Components$Filter$generateQuery = function (filter) {
	var peopleFilter = A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$andThen,
			function (t) {
				return $elm$core$Maybe$Just('fullname,like,%' + (t + '%'));
			},
			filter.bP));
	var convertDate = function (time) {
		return A2(
			$elm$core$Maybe$andThen,
			function (t) {
				return function (str) {
					return ($elm$core$String$length(str) > 0) ? $elm$core$Maybe$Just(str) : $elm$core$Maybe$Nothing;
				}(
					A2(
						$elm$core$String$join,
						'',
						$elm$core$List$reverse(
							function (_v2) {
								var ns = _v2.a;
								var r = _v2.b;
								return r ? ns : _List_Nil;
							}(
								A3(
									$elm$core$List$foldl,
									F2(
										function (_v0, _v1) {
											var i = _v0.a;
											var n = _v0.b;
											var ns = _v1.a;
											return _Utils_Tuple2(
												A2(
													$elm$core$List$append,
													ns,
													_List_fromArray(
														[n])),
												(i === 2) && ($elm$core$String$length(n) === 4));
										}),
									_Utils_Tuple2(_List_Nil, false),
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (i, v) {
												return _Utils_Tuple2(i, v);
											}),
										A2($elm$core$String$split, '/', t)))))));
			},
			time);
	};
	var statementFilter = $author$project$App$Components$Filter$fixedQuery(
		_Utils_update(
			filter,
			{
				aH: A2(
					$elm$core$List$append,
					filter.aH,
					A2(
						$elm$core$List$map,
						function (r) {
							return A2($elm$core$Maybe$withDefault, '', r);
						},
						A2(
							$elm$core$List$filter,
							function (r) {
								return !_Utils_eq(r, $elm$core$Maybe$Nothing);
							},
							_List_fromArray(
								[
									A2(
									$elm$core$Maybe$andThen,
									function (time) {
										return $elm$core$Maybe$Just('updatedat,>=,' + (time + 'T00:00:00'));
									},
									convertDate(filter.bN)),
									A2(
									$elm$core$Maybe$andThen,
									function (time) {
										return $elm$core$Maybe$Just('updatedat,<=,' + (time + 'T23:59:59'));
									},
									convertDate(filter.bw))
								]))))
			}));
	return A2(
		$elm$core$String$join,
		'|',
		A2(
			$elm$core$List$filter,
			function (r) {
				return $elm$core$String$length(r) > 0;
			},
			_List_fromArray(
				[statementFilter, peopleFilter])));
};
var $elm$core$String$filter = _String_filter;
var $author$project$App$Tools$FormHelpers$maskStringHelper = F3(
	function (pattern, value, result) {
		maskStringHelper:
		while (true) {
			var valueUncons = $elm$core$String$uncons(value);
			if (!valueUncons.$) {
				var _v1 = valueUncons.a;
				var charValue = _v1.a;
				var rest = _v1.b;
				var _v2 = $elm$core$String$uncons(pattern);
				if (!_v2.$) {
					var _v3 = _v2.a;
					var a = _v3.a;
					var b = _v3.b;
					if (a === '#') {
						var $temp$pattern = b,
							$temp$value = rest,
							$temp$result = _Utils_ap(
							result,
							$elm$core$String$fromChar(charValue));
						pattern = $temp$pattern;
						value = $temp$value;
						result = $temp$result;
						continue maskStringHelper;
					} else {
						var $temp$pattern = b,
							$temp$value = value,
							$temp$result = _Utils_ap(
							result,
							$elm$core$String$fromChar(a));
						pattern = $temp$pattern;
						value = $temp$value;
						result = $temp$result;
						continue maskStringHelper;
					}
				} else {
					return result;
				}
			} else {
				return result;
			}
		}
	});
var $author$project$App$Tools$FormHelpers$maskStringNumber = F2(
	function (pattern, value) {
		return function (v) {
			return A3($author$project$App$Tools$FormHelpers$maskStringHelper, pattern, v, '');
		}(
			A2($elm$core$String$filter, $elm$core$Char$isDigit, value));
	});
var $author$project$App$Components$Filter$update = F2(
	function (field, filter) {
		switch (field.$) {
			case 0:
				var date = field.a;
				var d = function () {
					if (date === '') {
						return $elm$core$Maybe$Nothing;
					} else {
						return $elm$core$Maybe$Just(date);
					}
				}();
				var da = A2(
					$elm$core$Maybe$andThen,
					function (str) {
						return $elm$core$Maybe$Just(
							A2($author$project$App$Tools$FormHelpers$maskStringNumber, '##/##/####', str));
					},
					d);
				return _Utils_Tuple2(
					_Utils_update(
						filter,
						{bN: da}),
					false);
			case 1:
				var date = field.a;
				var d = function () {
					if (date === '') {
						return $elm$core$Maybe$Nothing;
					} else {
						return $elm$core$Maybe$Just(date);
					}
				}();
				var da = A2(
					$elm$core$Maybe$andThen,
					function (str) {
						return $elm$core$Maybe$Just(
							A2($author$project$App$Tools$FormHelpers$maskStringNumber, '##/##/####', str));
					},
					d);
				return _Utils_Tuple2(
					_Utils_update(
						filter,
						{bw: da}),
					false);
			case 2:
				var text = field.a;
				var t = function () {
					if (text === '') {
						return $elm$core$Maybe$Nothing;
					} else {
						return $elm$core$Maybe$Just(text);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						filter,
						{bP: t}),
					false);
			case 3:
				var b = field.a;
				return _Utils_Tuple2(
					_Utils_update(
						filter,
						{bK: b}),
					false);
			case 4:
				var o = field.a;
				var order = function () {
					if (o === '') {
						return $elm$core$Maybe$Nothing;
					} else {
						return $elm$core$Maybe$Just(o);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						filter,
						{bE: order}),
					false);
			case 5:
				var rs = field.a;
				return _Utils_Tuple2(
					_Utils_update(
						filter,
						{aH: rs}),
					false);
			case 6:
				var p = field.a;
				var uFilter = _Utils_update(
					filter,
					{aQ: p});
				var uQuery = A2(
					$elm$core$Maybe$andThen,
					function (_v5) {
						return $elm$core$Maybe$Just(
							$author$project$App$Components$Filter$generateQuery(uFilter));
					},
					filter.aX);
				return _Utils_Tuple2(
					_Utils_update(
						uFilter,
						{aX: uQuery}),
					true);
			case 7:
				return _Utils_Tuple2(
					_Utils_update(
						$author$project$App$Components$Filter$empty,
						{bE: filter.bE, aH: filter.aH}),
					true);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						filter,
						{
							aX: $elm$core$Maybe$Just(
								$author$project$App$Components$Filter$generateQuery(filter)),
							bK: false
						}),
					true);
		}
	});
var $author$project$App$Components$Filter$query = function (filter) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$App$Components$Filter$fixedQuery(filter),
		A2($author$project$App$Components$Filter$update, $author$project$App$Components$Filter$FilterFieldApply, filter).a.aX);
};
var $author$project$App$Service$Shared$addFilterToPath = F2(
	function (filter, _v0) {
		var _v1 = _v0;
		var service = _v1.a;
		var endpoint = _v1.b;
		return A3(
			$author$project$App$Service$Shared$RID,
			service,
			endpoint,
			$elm$core$Maybe$Just(
				$author$project$App$Components$Filter$query(filter)));
	});
var $author$project$App$Service$ApplicationResources$ridApproved = function (cId) {
	return $author$project$App$Service$Shared$ridFromString('statementService.fromCompany.' + (cId + '.approved'));
};
var $author$project$App$Service$ApplicationResources$pathApprovedForReview = F2(
	function (cId, filter) {
		return A2(
			$author$project$App$Service$Shared$addFilterToPath,
			filter,
			$author$project$App$Service$Shared$pathFromRID(
				$author$project$App$Service$ApplicationResources$ridApproved(cId)));
	});
var $author$project$App$Service$ApplicationResources$subscribeApprovedStatementForReview = F4(
	function (cId, filter, respMsgs, api) {
		return A5(
			$author$project$App$Service$ApiWebSocket$subscribeResource,
			true,
			$author$project$App$Service$SpecificResources$TypeStatementCollection,
			A2($author$project$App$Service$ApplicationResources$pathApprovedForReview, cId, filter),
			respMsgs,
			api);
	});
var $author$project$App$Service$SpecificResources$TypeCompanyCollection = {$: 6};
var $author$project$App$Service$ApplicationResources$serviceCompany = 'companyService';
var $author$project$App$Service$ApplicationResources$ridCompanies = $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceCompany + '.companies.withTemplates');
var $author$project$App$Service$ApplicationResources$pathCompanies = $author$project$App$Service$Shared$pathFromRID($author$project$App$Service$ApplicationResources$ridCompanies);
var $author$project$App$Service$ApplicationResources$subscribeCompaniesWithTemplates = F2(
	function (response, api) {
		return A5($author$project$App$Service$ApiWebSocket$subscribeResource, true, $author$project$App$Service$SpecificResources$TypeCompanyCollection, $author$project$App$Service$ApplicationResources$pathCompanies, response, api);
	});
var $author$project$App$Service$SpecificResources$TypeCompany = {$: 7};
var $author$project$App$Service$ApplicationResources$ridCompanyById = function (cId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceCompany + ('.byId.' + cId));
};
var $author$project$App$Service$ApplicationResources$pathCompanyById = function (cId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridCompanyById(cId));
};
var $author$project$App$Service$ApplicationResources$subscribeCompanyById = F3(
	function (cId, response, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			cId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeCompany,
				$author$project$App$Service$ApplicationResources$pathCompanyById(cId),
				response));
	});
var $author$project$App$Service$SpecificResources$TypePersonCollection = {$: 22};
var $author$project$App$Service$ApplicationResources$ridCompanyPeopleFromPerson = function (pId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$servicePeople + ('.companyPeopleFromPerson.' + pId));
};
var $author$project$App$Service$ApplicationResources$pathCompanyPeopleFromPerson = function (pId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridCompanyPeopleFromPerson(pId));
};
var $author$project$App$Service$ApplicationResources$subscribeCompanyPeopleFromPerson = F3(
	function (pId, respMsgs, api) {
		return A5(
			$author$project$App$Service$ApiWebSocket$subscribeResource,
			true,
			$author$project$App$Service$SpecificResources$TypePersonCollection,
			$author$project$App$Service$ApplicationResources$pathCompanyPeopleFromPerson(pId),
			respMsgs,
			api);
	});
var $author$project$App$Service$ApplicationResources$serviceConference = 'conferenceService';
var $author$project$App$Service$ApplicationResources$ridConferenceFromStatement = function (stmtId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceConference + ('.fromStatement.' + stmtId));
};
var $author$project$App$Service$ApplicationResources$pathConferenceFromStatement = function (stmtId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridConferenceFromStatement(stmtId));
};
var $author$project$App$Service$ApplicationResources$subscribeConferenceFromStatement = F3(
	function (stmtId, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmtId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeConference,
				$author$project$App$Service$ApplicationResources$pathConferenceFromStatement(stmtId),
				respMsgs));
	});
var $author$project$App$Service$SpecificResources$TypeContact = {$: 9};
var $author$project$App$Service$ApplicationResources$contactService = 'contactService';
var $author$project$App$Service$ApplicationResources$ridContactFromOwnerAndKind = F2(
	function (id, kind) {
		return $author$project$App$Service$Shared$ridFromString(
			$author$project$App$Service$ApplicationResources$contactService + ('.fromOwnerId.' + (id + ('.kind.' + $author$project$App$Data$Contact$kindToString(kind)))));
	});
var $author$project$App$Service$ApplicationResources$pathContactFromOwnerAndKind = F2(
	function (id, kind) {
		return $author$project$App$Service$Shared$pathFromRID(
			A2($author$project$App$Service$ApplicationResources$ridContactFromOwnerAndKind, id, kind));
	});
var $author$project$App$Service$ApplicationResources$subscribeContactFromOwnerAndKind = F4(
	function (id, kind, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			id,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeContact,
				A2($author$project$App$Service$ApplicationResources$pathContactFromOwnerAndKind, id, kind),
				respMsgs));
	});
var $author$project$App$Service$SpecificResources$TypeDoctor = {$: 11};
var $author$project$App$Service$ApplicationResources$ridDoctorFromLicense = F2(
	function (license, licenseState) {
		return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceDoctor + ('.byLicense.' + (license + ('.' + licenseState))));
	});
var $author$project$App$Service$ApplicationResources$pathDoctorsFromLicense = F2(
	function (license, licenseState) {
		return $author$project$App$Service$Shared$pathFromRID(
			A2($author$project$App$Service$ApplicationResources$ridDoctorFromLicense, license, licenseState));
	});
var $author$project$App$Service$ApplicationResources$subscribeDoctorFromLicense = F4(
	function (license, licenseState, respMsgs, api) {
		return A5(
			$author$project$App$Service$ApiWebSocket$subscribeResource,
			true,
			$author$project$App$Service$SpecificResources$TypeDoctor,
			A2($author$project$App$Service$ApplicationResources$pathDoctorsFromLicense, license, licenseState),
			respMsgs,
			api);
	});
var $author$project$App$Service$ApplicationResources$ridDoctorInterview = function (dId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatement + ('.interview.doctor.' + dId));
};
var $author$project$App$Service$ApplicationResources$pathDoctorInterview = function (dId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridDoctorInterview(dId));
};
var $author$project$App$Service$ApplicationResources$subscribeDoctorInterview = F3(
	function (dId, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			dId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeStatement,
				$author$project$App$Service$ApplicationResources$pathDoctorInterview(dId),
				respMsgs));
	});
var $author$project$App$Service$SpecificResources$TypeWaitingPersonCollection = {$: 24};
var $author$project$App$Service$ApplicationResources$ridDoctorWaitingLine = function (dId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceDoctor + ('.doctor.' + (dId + '.waiting.line')));
};
var $author$project$App$Service$ApplicationResources$pathDoctorWaitingLine = function (dId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridDoctorWaitingLine(dId));
};
var $author$project$App$Service$ApplicationResources$subscribeDoctorWaitingLine = F3(
	function (dId, respMsg, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			dId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeWaitingPersonCollection,
				$author$project$App$Service$ApplicationResources$pathDoctorWaitingLine(dId),
				respMsg));
	});
var $author$project$App$Service$SpecificResources$TypeDoctorCollection = {$: 10};
var $author$project$App$Service$ApplicationResources$ridDoctorFromCompany = function (cId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceDoctor + ('.fromCompanyId.' + cId));
};
var $author$project$App$Service$ApplicationResources$pathDoctorsFromCompanyFiltered = function (cId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridDoctorFromCompany(cId));
};
var $author$project$App$Service$ApplicationResources$subscribeDoctorsFromCompany = F3(
	function (cId, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			cId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeDoctorCollection,
				$author$project$App$Service$ApplicationResources$pathDoctorsFromCompanyFiltered(cId),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$ridDoctorsOnline = $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceDoctor + '.doctors.online');
var $author$project$App$Service$ApplicationResources$pathDoctorsOnline = $author$project$App$Service$Shared$pathFromRID($author$project$App$Service$ApplicationResources$ridDoctorsOnline);
var $author$project$App$Service$ApplicationResources$subscribeDoctorsOnline = F2(
	function (respMsgs, api) {
		return A5($author$project$App$Service$ApiWebSocket$subscribeResource, true, $author$project$App$Service$SpecificResources$TypeDoctorCollection, $author$project$App$Service$ApplicationResources$pathDoctorsOnline, respMsgs, api);
	});
var $author$project$App$Service$ApplicationResources$ridDoctorsOnlineForDoctor = function (doctorId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceDoctor + ('.doctors.online.forDoctor.' + doctorId));
};
var $author$project$App$Service$ApplicationResources$pathDoctorsOnlineForDoctor = function (doctorId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridDoctorsOnlineForDoctor(doctorId));
};
var $author$project$App$Service$ApplicationResources$subscribeDoctorsOnlineForDoctor = F3(
	function (doctorId, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			doctorId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeDoctorCollection,
				$author$project$App$Service$ApplicationResources$pathDoctorsOnlineForDoctor(doctorId),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$ridDoctorsOnlineForTemplate = function (templateId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceDoctor + ('.doctors.online.forTemplate.' + templateId));
};
var $author$project$App$Service$ApplicationResources$pathDoctorsOnlineForTemplate = function (templateId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridDoctorsOnlineForTemplate(templateId));
};
var $author$project$App$Service$ApplicationResources$subscribeDoctorsOnlineForTemplate = F3(
	function (templateId, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			templateId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeDoctorCollection,
				$author$project$App$Service$ApplicationResources$pathDoctorsOnlineForTemplate(templateId),
				respMsgs));
	});
var $author$project$App$Service$SpecificResources$TypeDocumentCollection = {$: 17};
var $author$project$App$Service$ApplicationResources$ridDocumentFromStatement = function (stmtId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceDocument + ('.' + ('fromStatement.' + stmtId)));
};
var $author$project$App$Service$ApplicationResources$pathDocumentFromStatement = function (stmtId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridDocumentFromStatement(stmtId));
};
var $author$project$App$Service$ApplicationResources$subscribeDocumentsFromStatement = F3(
	function (stmtId, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmtId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeDocumentCollection,
				$author$project$App$Service$ApplicationResources$pathDocumentFromStatement(stmtId),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$ridFinishedStatementsFromAdmin = function (pId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatement + ('.finished.admin.' + pId));
};
var $author$project$App$Service$ApplicationResources$pathFinishedStatementsFromAdminFiltered = F2(
	function (pId, filter) {
		return A2(
			$author$project$App$Service$Shared$addFilterToPath,
			filter,
			$author$project$App$Service$Shared$pathFromRID(
				$author$project$App$Service$ApplicationResources$ridFinishedStatementsFromAdmin(pId)));
	});
var $author$project$App$Service$ApplicationResources$subscribeFinishedStatementsFromAdmin = F4(
	function (pId, filter, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			pId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementCollection,
				A2($author$project$App$Service$ApplicationResources$pathFinishedStatementsFromAdminFiltered, pId, filter),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$ridFinishedStatementsFromDoctor = function (cId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatement + ('.finished.doctor.' + cId));
};
var $author$project$App$Service$ApplicationResources$pathFinishedStatementsFromDoctorFiltered = F2(
	function (cId, filter) {
		return A2(
			$author$project$App$Service$Shared$addFilterToPath,
			filter,
			$author$project$App$Service$Shared$pathFromRID(
				$author$project$App$Service$ApplicationResources$ridFinishedStatementsFromDoctor(cId)));
	});
var $author$project$App$Service$ApplicationResources$subscribeFinishedStatementsFromDoctor = F4(
	function (cId, filter, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			cId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementCollection,
				A2($author$project$App$Service$ApplicationResources$pathFinishedStatementsFromDoctorFiltered, cId, filter),
				respMsgs));
	});
var $author$project$App$Service$SpecificResources$TypeICDCollection = {$: 13};
var $author$project$App$Data$ICD$ridFromStatement = function (stmtId) {
	return 'icdService.fromStatement.' + stmtId;
};
var $author$project$App$Service$ApplicationResources$subscribeGetICDs = F3(
	function (stmtId, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmtId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeICDCollection,
				$author$project$App$Service$Shared$pathFromRID(
					$author$project$App$Service$Shared$ridFromString(
						$author$project$App$Data$ICD$ridFromStatement(stmtId))),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$ridIncompleteFromCompany = function (cId) {
	return $author$project$App$Service$Shared$ridFromString('statementService.fromCompany.' + (cId + '.incomplete'));
};
var $author$project$App$Service$ApplicationResources$pathIncompleteForReview = F2(
	function (cId, filter) {
		return A2(
			$author$project$App$Service$Shared$addFilterToPath,
			filter,
			$author$project$App$Service$Shared$pathFromRID(
				$author$project$App$Service$ApplicationResources$ridIncompleteFromCompany(cId)));
	});
var $author$project$App$Service$ApplicationResources$subscribeIncompleteStatementForReview = F4(
	function (cId, filter, respMsgs, api) {
		return A5(
			$author$project$App$Service$ApiWebSocket$subscribeResource,
			true,
			$author$project$App$Service$SpecificResources$TypeStatementCollection,
			A2($author$project$App$Service$ApplicationResources$pathIncompleteForReview, cId, filter),
			respMsgs,
			api);
	});
var $author$project$App$Data$Person$isDocumentValid = function (doc) {
	return $elm$core$String$length(doc) === 11;
};
var $author$project$App$Service$ApplicationResources$ridPersonByDocument = function (document) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$servicePeople + ('.' + ('byDoc.' + document)));
};
var $author$project$App$Service$ApplicationResources$pathPersonByDocument = function (document) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridPersonByDocument(document));
};
var $author$project$App$Service$ApplicationResources$subscribePersonByDocument = F3(
	function (document, respMsgs, api) {
		return $author$project$App$Data$Person$isDocumentValid(document) ? A5(
			$author$project$App$Service$ApiWebSocket$subscribeResource,
			true,
			$author$project$App$Service$SpecificResources$TypePerson,
			$author$project$App$Service$ApplicationResources$pathPersonByDocument(document),
			respMsgs,
			api) : _Utils_Tuple2(api, $elm$core$Platform$Cmd$none);
	});
var $author$project$App$Service$ApplicationResources$subscribePersonById = F3(
	function (pId, response, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			pId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypePerson,
				$author$project$App$Service$ApplicationResources$pathPersonById(pId),
				response));
	});
var $author$project$App$Service$ApplicationResources$ridReevalStatementsFromCompany = function (cId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatement + ('.reeval.company.' + cId));
};
var $author$project$App$Service$ApplicationResources$pathReevalStatementsFromCompanyFiltered = F2(
	function (cId, filter) {
		return A2(
			$author$project$App$Service$Shared$addFilterToPath,
			filter,
			$author$project$App$Service$Shared$pathFromRID(
				$author$project$App$Service$ApplicationResources$ridReevalStatementsFromCompany(cId)));
	});
var $author$project$App$Service$ApplicationResources$subscribeReevalStatementsFromCompany = F4(
	function (cId, filter, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			cId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementCollection,
				A2($author$project$App$Service$ApplicationResources$pathReevalStatementsFromCompanyFiltered, cId, filter),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$ridReevalStatementsFromDoctor = function (cId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatement + ('.reeval.doctor.' + cId));
};
var $author$project$App$Service$ApplicationResources$pathReevalStatementsFromDoctorFiltered = F2(
	function (cId, filter) {
		return A2(
			$author$project$App$Service$Shared$addFilterToPath,
			filter,
			$author$project$App$Service$Shared$pathFromRID(
				$author$project$App$Service$ApplicationResources$ridReevalStatementsFromDoctor(cId)));
	});
var $author$project$App$Service$ApplicationResources$subscribeReevalStatementsFromDoctor = F4(
	function (cId, filter, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			cId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementCollection,
				A2($author$project$App$Service$ApplicationResources$pathReevalStatementsFromDoctorFiltered, cId, filter),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$subscribeStatementById = F3(
	function (stmtId, response, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmtId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeStatement,
				$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
				response));
	});
var $author$project$App$Service$ApplicationResources$ridStatementDepsById = function (stmtId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatement + ('.byId.' + (stmtId + '.children')));
};
var $author$project$App$Service$ApplicationResources$pathStatementDepsById = function (stmtId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridStatementDepsById(stmtId));
};
var $author$project$App$Service$ApplicationResources$subscribeStatementDepsById = F3(
	function (stmtId, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmtId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementCollection,
				$author$project$App$Service$ApplicationResources$pathStatementDepsById(stmtId),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$ridPendingReview = function (cId) {
	return $author$project$App$Service$Shared$ridFromString('statementService.fromCompany.' + (cId + '.pendingReview'));
};
var $author$project$App$Service$ApplicationResources$pathStatementsForReview = F2(
	function (cId, filter) {
		return A2(
			$author$project$App$Service$Shared$addFilterToPath,
			filter,
			$author$project$App$Service$Shared$pathFromRID(
				$author$project$App$Service$ApplicationResources$ridPendingReview(cId)));
	});
var $author$project$App$Service$ApplicationResources$subscribeStatementForReview = F4(
	function (cId, filter, respMsgs, api) {
		return A5(
			$author$project$App$Service$ApiWebSocket$subscribeResource,
			true,
			$author$project$App$Service$SpecificResources$TypeStatementCollection,
			A2($author$project$App$Service$ApplicationResources$pathStatementsForReview, cId, filter),
			respMsgs,
			api);
	});
var $author$project$App$Service$ApplicationResources$ridStatementFromPerson = function (pId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatement + ('.fromPerson.' + pId));
};
var $author$project$App$Service$ApplicationResources$pathStatementFromPerson = function (pId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridStatementFromPerson(pId));
};
var $author$project$App$Service$ApplicationResources$subscribeStatementFromPerson = F3(
	function (pId, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			pId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeStatement,
				$author$project$App$Service$ApplicationResources$pathStatementFromPerson(pId),
				respMsgs));
	});
var $author$project$App$Service$SpecificResources$TypeStatementMessageCollection = {$: 39};
var $author$project$App$Service$ApplicationResources$ridStatementMessageFromStatement = function (stmtId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatementMessage + ('.byStatementId.' + stmtId));
};
var $author$project$App$Service$ApplicationResources$pathStatementMessageFromStatement = function (stmtId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridStatementMessageFromStatement(stmtId));
};
var $author$project$App$Service$ApplicationResources$subscribeStatementMessageFromStatement = F3(
	function (stmtId, respMsgs, api) {
		return $author$project$App$Data$Id$uuidIsValid(stmtId) ? A5(
			$author$project$App$Service$ApiWebSocket$subscribeResource,
			true,
			$author$project$App$Service$SpecificResources$TypeStatementMessageCollection,
			$author$project$App$Service$ApplicationResources$pathStatementMessageFromStatement(stmtId),
			respMsgs,
			api) : _Utils_Tuple2(api, $elm$core$Platform$Cmd$none);
	});
var $author$project$App$Service$ApplicationResources$ridStatementMinorDepsById = function (stmtId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatement + ('.byId.' + (stmtId + '.children.minors')));
};
var $author$project$App$Service$ApplicationResources$pathStatementMinorDepsById = function (stmtId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridStatementMinorDepsById(stmtId));
};
var $author$project$App$Service$ApplicationResources$subscribeStatementMinorDepsById = F3(
	function (stmtId, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmtId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementCollection,
				$author$project$App$Service$ApplicationResources$pathStatementMinorDepsById(stmtId),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$subscribeStatementTemplateById = F3(
	function (templateId, response, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			templateId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementTemplate,
				$author$project$App$Service$ApplicationResources$pathStatementTemplateById(templateId),
				response));
	});
var $author$project$App$Service$ApplicationResources$ridStatementTemplateFromStatement = function (stmtId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatementTemplate + ('.fromStatement.' + stmtId));
};
var $author$project$App$Service$ApplicationResources$pathStatementTemplateFromStatement = function (stmtId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridStatementTemplateFromStatement(stmtId));
};
var $author$project$App$Service$ApplicationResources$subscribeStatementTemplateByStatementId = F3(
	function (stmtId, response, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			stmtId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				false,
				$author$project$App$Service$SpecificResources$TypeStatementTemplate,
				$author$project$App$Service$ApplicationResources$pathStatementTemplateFromStatement(stmtId),
				response));
	});
var $author$project$App$Service$SpecificResources$TypeStatementTemplateCollection = {$: 31};
var $author$project$App$Service$ApplicationResources$ridStatementTemplateFromCompany = function (cId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatementTemplate + ('.fromCompany.' + cId));
};
var $author$project$App$Service$ApplicationResources$pathStatementTemplateFromCompany = function (cId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridStatementTemplateFromCompany(cId));
};
var $author$project$App$Service$ApplicationResources$subscribeStatementTemplatesFromCompany = F3(
	function (cId, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			cId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementTemplateCollection,
				$author$project$App$Service$ApplicationResources$pathStatementTemplateFromCompany(cId),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$pathStatementsFromCompanyFiltered = F2(
	function (cId, filter) {
		return A2(
			$author$project$App$Service$Shared$addFilterToPath,
			filter,
			$author$project$App$Service$Shared$pathFromRID(
				$author$project$App$Service$ApplicationResources$ridStatementsFromCompany(cId)));
	});
var $author$project$App$Service$ApplicationResources$subscribeStatementsFromCompany = F4(
	function (cId, filter, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			cId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementCollection,
				A2($author$project$App$Service$ApplicationResources$pathStatementsFromCompanyFiltered, cId, filter),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$ridPendingStatementsFromDoctor = function (cId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceStatement + ('.pending.doctor.' + cId));
};
var $author$project$App$Service$ApplicationResources$pathStatementsFromDoctorFiltered = F2(
	function (cId, filter) {
		return A2(
			$author$project$App$Service$Shared$addFilterToPath,
			filter,
			$author$project$App$Service$Shared$pathFromRID(
				$author$project$App$Service$ApplicationResources$ridPendingStatementsFromDoctor(cId)));
	});
var $author$project$App$Service$ApplicationResources$subscribeStatementsFromDoctor = F4(
	function (cId, filter, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			cId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeStatementCollection,
				A2($author$project$App$Service$ApplicationResources$pathStatementsFromDoctorFiltered, cId, filter),
				respMsgs));
	});
var $author$project$App$Service$SpecificResources$TypeUsersCollection = {$: 34};
var $author$project$App$Service$ApplicationResources$ridUserFromCompany = function (cId) {
	return $author$project$App$Service$Shared$ridFromString($author$project$App$Service$ApplicationResources$serviceUser + ('.fromCompanyId.' + cId));
};
var $author$project$App$Service$ApplicationResources$pathUsersFromCompanyFiltered = function (cId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$ApplicationResources$ridUserFromCompany(cId));
};
var $author$project$App$Service$ApplicationResources$subscribeUsersFromCompany = F3(
	function (cId, respMsgs, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			cId,
			api,
			A4(
				$author$project$App$Service$ApiWebSocket$subscribeResource,
				true,
				$author$project$App$Service$SpecificResources$TypeUsersCollection,
				$author$project$App$Service$ApplicationResources$pathUsersFromCompanyFiltered(cId),
				respMsgs));
	});
var $author$project$App$Service$ApplicationResources$unsubscribeStatementFromPerson = F2(
	function (pId, api) {
		return A3(
			$author$project$App$Service$ApplicationResources$ifUUIDIsValid,
			pId,
			api,
			A2(
				$author$project$App$Service$ApiWebSocket$unsubscribeResource,
				$author$project$App$Service$SpecificResources$TypeStatement,
				$author$project$App$Service$ApplicationResources$pathStatementFromPerson(pId)));
	});
var $author$project$App$Data$Id$uuidIsNotValid = function (id) {
	return !$author$project$App$Data$Id$uuidIsValid(id);
};
var $author$project$App$SharedState$updateAPI = F3(
	function (msg, model, api) {
		switch (msg.$) {
			case 0:
				var respMsgs = msg.a;
				var _v1 = A2($author$project$App$Service$ApiWebSocket$clearCache, api, respMsgs);
				var nApi = _v1.a;
				var apiCmd = _v1.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ia: $author$project$App$SharedState$emptyFullStatementCache}),
					nApi,
					apiCmd);
			case 40:
				var fullStmt = msg.a;
				var respMsgs = msg.b;
				var cache = model.ia;
				var _v2 = _Utils_Tuple2(
					model.ia.iP,
					A2($author$project$App$Service$ApplicationResources$getAdvanceDoctorStatementResponse, fullStmt.g.e.as, api));
				_v2$0:
				while (true) {
					switch (_v2.b.$) {
						case 1:
							if (_v2.a === 1) {
								break _v2$0;
							} else {
								var _v4 = _v2.b;
								return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
							}
						case 3:
							if (_v2.a === 1) {
								break _v2$0;
							} else {
								var stmt = _v2.b.a;
								return _Utils_eq(stmt.aw, fullStmt.g.e.aw) ? function (_v5) {
									var a = _v5.a;
									var cmd = _v5.b;
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												ia: _Utils_update(
													cache,
													{iP: 1})
											}),
										a,
										cmd);
								}(
									A2(
										$author$project$App$Service$ApiWebSocket$pipe,
										A2($author$project$App$Service$ApplicationResources$callAdvanceDoctorStatement, fullStmt.g.e, respMsgs),
										A2($author$project$App$Service$ApplicationResources$clearAdvanceDoctorStatement, fullStmt.g.e.as, api))) : _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
							}
						default:
							if (_v2.a === 1) {
								break _v2$0;
							} else {
								return function (_v6) {
									var a = _v6.a;
									var cmd = _v6.b;
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												ia: _Utils_update(
													cache,
													{iP: 1})
											}),
										a,
										cmd);
								}(
									A2(
										$author$project$App$Service$ApiWebSocket$pipe,
										A2($author$project$App$Service$ApplicationResources$callAdvanceDoctorStatement, fullStmt.g.e, respMsgs),
										A2($author$project$App$Service$ApplicationResources$clearAdvanceDoctorStatement, fullStmt.g.e.as, api)));
							}
					}
				}
				var _v3 = _v2.a;
				return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
			case 41:
				var fullStmt = msg.a;
				var respMsgs = msg.b;
				var cache = model.ia;
				var _v7 = _Utils_Tuple2(
					model.ia.iP,
					A2($author$project$App$Service$ApplicationResources$getFinishDoctorStatementResponse, fullStmt.g.e.as, api));
				_v7$0:
				while (true) {
					switch (_v7.b.$) {
						case 1:
							if (_v7.a === 1) {
								break _v7$0;
							} else {
								var _v9 = _v7.b;
								return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
							}
						case 3:
							if (_v7.a === 1) {
								break _v7$0;
							} else {
								var stmt = _v7.b.a;
								return _Utils_eq(stmt.aw, fullStmt.g.e.aw) ? function (_v10) {
									var a = _v10.a;
									var cmd = _v10.b;
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												ia: _Utils_update(
													cache,
													{iP: 1})
											}),
										a,
										cmd);
								}(
									A2(
										$author$project$App$Service$ApiWebSocket$pipe,
										A2($author$project$App$Service$ApplicationResources$callFinishDoctorStatement, fullStmt.g.e, respMsgs),
										A2($author$project$App$Service$ApplicationResources$clearFinishDoctorStatement, fullStmt.g.e.as, api))) : _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
							}
						default:
							if (_v7.a === 1) {
								break _v7$0;
							} else {
								return function (_v11) {
									var a = _v11.a;
									var cmd = _v11.b;
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												ia: _Utils_update(
													cache,
													{iP: 1})
											}),
										a,
										cmd);
								}(
									A2(
										$author$project$App$Service$ApiWebSocket$pipe,
										A2($author$project$App$Service$ApplicationResources$callFinishDoctorStatement, fullStmt.g.e, respMsgs),
										A2($author$project$App$Service$ApplicationResources$clearFinishDoctorStatement, fullStmt.g.e.as, api)));
							}
					}
				}
				var _v8 = _v7.a;
				return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
			case 42:
				var fullStmt = msg.a;
				var respMsgs = msg.b;
				var cache = model.ia;
				var _v12 = _Utils_Tuple2(
					model.ia.iP,
					A2($author$project$App$Service$ApplicationResources$getRevertDoctorStatementResponse, fullStmt.g.e.as, api));
				_v12$0:
				while (true) {
					switch (_v12.b.$) {
						case 1:
							if (_v12.a === 2) {
								break _v12$0;
							} else {
								var _v14 = _v12.b;
								return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
							}
						case 3:
							if (_v12.a === 2) {
								break _v12$0;
							} else {
								var stmt = _v12.b.a;
								return _Utils_eq(stmt.aw, fullStmt.g.e.aw) ? function (_v15) {
									var a = _v15.a;
									var cmd = _v15.b;
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												ia: _Utils_update(
													cache,
													{iP: 2})
											}),
										a,
										cmd);
								}(
									A2(
										$author$project$App$Service$ApiWebSocket$pipe,
										A2($author$project$App$Service$ApplicationResources$callRevertDoctorStatement, fullStmt.g.e, respMsgs),
										A2($author$project$App$Service$ApplicationResources$clearRevertDoctorStatement, fullStmt.g.e.as, api))) : _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
							}
						default:
							if (_v12.a === 2) {
								break _v12$0;
							} else {
								return function (_v16) {
									var a = _v16.a;
									var cmd = _v16.b;
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												ia: _Utils_update(
													cache,
													{iP: 2})
											}),
										a,
										cmd);
								}(
									A2(
										$author$project$App$Service$ApiWebSocket$pipe,
										A2($author$project$App$Service$ApplicationResources$callRevertDoctorStatement, fullStmt.g.e, respMsgs),
										A2($author$project$App$Service$ApplicationResources$clearRevertDoctorStatement, fullStmt.g.e.as, api)));
							}
					}
				}
				var _v13 = _v12.a;
				return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
			case 44:
				var fullStmt = msg.a;
				var respMsgs = msg.b;
				var cache = model.ia;
				var _v17 = _Utils_Tuple2(
					model.ia.iP,
					A2($author$project$App$Service$ApplicationResources$getAdvanceProponentStatementResponse, fullStmt.g.e.as, api));
				_v17$0:
				while (true) {
					switch (_v17.b.$) {
						case 1:
							if (_v17.a === 1) {
								break _v17$0;
							} else {
								var _v19 = _v17.b;
								return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
							}
						case 3:
							if (_v17.a === 1) {
								break _v17$0;
							} else {
								var stmt = _v17.b.a;
								return _Utils_eq(stmt.aw, fullStmt.g.e.aw) ? function (_v20) {
									var a = _v20.a;
									var cmd = _v20.b;
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												ia: _Utils_update(
													cache,
													{iP: 1})
											}),
										a,
										cmd);
								}(
									A2(
										$author$project$App$Service$ApiWebSocket$pipe,
										A2($author$project$App$Service$ApplicationResources$callAdvanceProponentStatement, fullStmt.g.e, respMsgs),
										A2($author$project$App$Service$ApplicationResources$clearAdvanceProponentStatement, fullStmt.g.e.as, api))) : _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
							}
						default:
							if (_v17.a === 1) {
								break _v17$0;
							} else {
								return function (_v21) {
									var a = _v21.a;
									var cmd = _v21.b;
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												ia: _Utils_update(
													cache,
													{iP: 1})
											}),
										a,
										cmd);
								}(
									A2(
										$author$project$App$Service$ApiWebSocket$pipe,
										A2($author$project$App$Service$ApplicationResources$callAdvanceProponentStatement, fullStmt.g.e, respMsgs),
										A2($author$project$App$Service$ApplicationResources$clearAdvanceProponentStatement, fullStmt.g.e.as, api)));
							}
					}
				}
				var _v18 = _v17.a;
				return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
			case 45:
				var fullStmt = msg.a;
				var respMsgs = msg.b;
				var cache = model.ia;
				var _v22 = _Utils_Tuple2(
					model.ia.iP,
					A2($author$project$App$Service$ApplicationResources$getRevertProponentStatementResponse, fullStmt.g.e.as, api));
				_v22$0:
				while (true) {
					switch (_v22.b.$) {
						case 1:
							if (_v22.a === 2) {
								break _v22$0;
							} else {
								var _v24 = _v22.b;
								return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
							}
						case 3:
							if (_v22.a === 2) {
								break _v22$0;
							} else {
								var stmt = _v22.b.a;
								return _Utils_eq(stmt.aw, fullStmt.g.e.aw) ? function (_v25) {
									var a = _v25.a;
									var cmd = _v25.b;
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												ia: _Utils_update(
													cache,
													{iP: 2})
											}),
										a,
										cmd);
								}(
									A2(
										$author$project$App$Service$ApiWebSocket$pipe,
										A2($author$project$App$Service$ApplicationResources$callRevertProponentStatement, fullStmt.g.e, respMsgs),
										A2($author$project$App$Service$ApplicationResources$clearRevertProponentStatement, fullStmt.g.e.as, api))) : _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
							}
						default:
							if (_v22.a === 2) {
								break _v22$0;
							} else {
								return function (_v26) {
									var a = _v26.a;
									var cmd = _v26.b;
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												ia: _Utils_update(
													cache,
													{iP: 2})
											}),
										a,
										cmd);
								}(
									A2(
										$author$project$App$Service$ApiWebSocket$pipe,
										A2($author$project$App$Service$ApplicationResources$callRevertProponentStatement, fullStmt.g.e, respMsgs),
										A2($author$project$App$Service$ApplicationResources$clearRevertProponentStatement, fullStmt.g.e.as, api)));
							}
					}
				}
				var _v23 = _v22.a;
				return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
			case 43:
				var fullStmt = msg.a;
				var respMsgs = msg.b;
				return function (_v27) {
					var a = _v27.a;
					var cmd = _v27.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callDoctorChange, fullStmt.g.e.as, respMsgs, api));
			case 78:
				var conf = msg.a;
				var stmtId = msg.b;
				var prof = msg.c;
				var respMsgs = msg.d;
				return function (_v28) {
					var a = _v28.a;
					var cmd = _v28.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A5($author$project$App$Service$ApplicationResources$callSetConferenceById, conf, stmtId, prof, respMsgs, api));
			case 79:
				var fullStmt = msg.a;
				var respMsgs = msg.b;
				return function (_v29) {
					var a = _v29.a;
					var cmd = _v29.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callStartRecording, fullStmt.g.e.as, respMsgs, api));
			case 80:
				var fullStmt = msg.a;
				var respMsgs = msg.b;
				return function (_v30) {
					var a = _v30.a;
					var cmd = _v30.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callStopRecording, fullStmt.g.e.as, respMsgs, api));
			case 3:
				var user = msg.a;
				var responseMsg = msg.b;
				return function (_v31) {
					var a = _v31.a;
					var cmd = _v31.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$authUserSignIn, user, responseMsg, api));
			case 4:
				var _v32 = $author$project$App$Service$ApiWebSocket$logout(model._);
				var apiModel = _v32.a;
				var apiCmd = _v32.b;
				return _Utils_Tuple3(
					model,
					apiModel,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								apiCmd,
								A2(
								$author$project$App$Route$pushUrl,
								model.fC,
								$author$project$App$Route$Public($author$project$App$Route$Login))
							])));
			case 12:
				var ownerId = msg.a;
				var kind = msg.b;
				var respMsgs = msg.c;
				return function (_v33) {
					var a = _v33.a;
					var cmd = _v33.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$subscribeContactFromOwnerAndKind, ownerId, kind, respMsgs, api));
			case 5:
				var stmtId = msg.a;
				var respMsgs = msg.b;
				return function (_v34) {
					var a = _v34.a;
					var cmd = _v34.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$authUserSignNotification, stmtId, respMsgs, api));
			case 88:
				var cId = msg.a;
				var respMsgs = msg.b;
				return function (_v35) {
					var a = _v35.a;
					var cmd = _v35.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeStatementTemplatesFromCompany, cId, respMsgs, api));
			case 85:
				var templateId = msg.a;
				var responseMsg = msg.b;
				var isAlreadySubscribed = function () {
					var _v37 = A2($author$project$App$Service$ApplicationResources$getStatementTemplateById, templateId, model._);
					if (!_v37.$) {
						var t = _v37.a;
						return true;
					} else {
						return false;
					}
				}();
				return ((!isAlreadySubscribed) && $author$project$App$Data$Id$uuidIsValid(templateId)) ? function (_v36) {
					var a = _v36.a;
					var cmd = _v36.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeStatementTemplateById, templateId, responseMsg, api)) : _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
			case 86:
				var templateId = msg.a;
				var responseMsg = msg.b;
				return $author$project$App$Data$Id$uuidIsNotValid(model.ia.h8.cJ.as) ? function (_v38) {
					var a = _v38.a;
					var cmd = _v38.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeStatementTemplateById, templateId, responseMsg, api)) : _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
			case 13:
				var stmtId = msg.a;
				var respMsgs = msg.b;
				return function (_v39) {
					var a = _v39.a;
					var cmd = _v39.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeDocumentsFromStatement, stmtId, respMsgs, api));
			case 6:
				var user = msg.a;
				var phone = msg.b;
				var juno = msg.c;
				var responseMsg = msg.d;
				var _v40 = A5($author$project$App$Service$ApplicationResources$callCreateDoctorUser, user, phone, juno, responseMsg, api);
				var apiModel = _v40.a;
				var apiCmd = _v40.b;
				return _Utils_Tuple3(model, apiModel, apiCmd);
			case 46:
				var stmtId = msg.a;
				var email = msg.b;
				var address = msg.c;
				var hash = msg.d;
				var responseMsg = msg.e;
				var _v41 = A6($author$project$App$Service$ApplicationResources$callCreateNewPayment, stmtId, email, address, hash, responseMsg, api);
				var apiModel = _v41.a;
				var apiCmd = _v41.b;
				return _Utils_Tuple3(model, apiModel, apiCmd);
			case 16:
				var responseMsg = msg.a;
				var _v42 = $author$project$App$Service$ApplicationResources$getAuthToken(api);
				if (!_v42.$) {
					var token = _v42.a;
					return function (_v43) {
						var a = _v43.a;
						var cmd = _v43.b;
						return _Utils_Tuple3(model, a, cmd);
					}(
						A3(
							$author$project$App$Service$ApplicationResources$subscribeDoctorWaitingLine,
							token.Y,
							_List_fromArray(
								[responseMsg]),
							api));
				} else {
					return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
				}
			case 39:
				var responseMsg = msg.a;
				var _v44 = $author$project$App$Service$ApplicationResources$getAuthToken(api);
				if (!_v44.$) {
					var token = _v44.a;
					return function (_v45) {
						var a = _v45.a;
						var cmd = _v45.b;
						return _Utils_Tuple3(model, a, cmd);
					}(
						A3(
							$author$project$App$Service$ApplicationResources$subscribeDoctorWaitingLine,
							token.Y,
							_List_fromArray(
								[responseMsg]),
							api));
				} else {
					return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
				}
			case 17:
				var respMsgs = msg.a;
				var _v46 = $author$project$App$Service$ApplicationResources$getAuthToken(api);
				if (!_v46.$) {
					var token = _v46.a;
					return function (_v47) {
						var a = _v47.a;
						var cmd = _v47.b;
						return _Utils_Tuple3(model, a, cmd);
					}(
						A3($author$project$App$Service$ApplicationResources$subscribeCompanyPeopleFromPerson, token.Y, respMsgs, api));
				} else {
					return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var timeString = msg.a;
				var respMsgs = msg.b;
				var _v48 = $author$project$App$Service$ApplicationResources$getAuthToken(api);
				if (!_v48.$) {
					var token = _v48.a;
					return function (_v49) {
						var a = _v49.a;
						var cmd = _v49.b;
						return _Utils_Tuple3(model, a, cmd);
					}(
						A4($author$project$App$Service$ApplicationResources$callDoctorInformOnline, token.Y, timeString, respMsgs, api));
				} else {
					return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
				}
			case 8:
				var timeString = msg.a;
				var respMsgs = msg.b;
				return function (_v50) {
					var a = _v50.a;
					var cmd = _v50.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callProponentInformOnline, respMsgs, timeString, api));
			case 18:
				var stmtId = msg.a;
				var responseMsgs = msg.b;
				return function (_v51) {
					var a = _v51.a;
					var cmd = _v51.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeDoctorsOnlineForTemplate, stmtId, responseMsgs, api));
			case 19:
				var responseMsgs = msg.a;
				return function (_v52) {
					var a = _v52.a;
					var cmd = _v52.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A2($author$project$App$Service$ApplicationResources$subscribeDoctorsOnline, responseMsgs, api));
			case 20:
				var templateId = msg.a;
				var responseMsgs = msg.b;
				return function (_v53) {
					var a = _v53.a;
					var cmd = _v53.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callHolidayTextForTemplate, templateId, responseMsgs, api));
			case 21:
				var signToken = msg.a;
				var stmtId = msg.b;
				var responseMsgs = msg.c;
				return function (_v54) {
					var a = _v54.a;
					var cmd = _v54.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$callSignDocument, signToken, stmtId, responseMsgs, api));
			case 22:
				var signToken = msg.a;
				var stmtId = msg.b;
				var responseMsgs = msg.c;
				return function (_v55) {
					var a = _v55.a;
					var cmd = _v55.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$callSignMedicalDocument, signToken, stmtId, responseMsgs, api));
			case 14:
				var stmtId = msg.a;
				var document = msg.b;
				var responseMsgs = msg.c;
				return function (_v56) {
					var a = _v56.a;
					var cmd = _v56.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$callCreateDocumentFromStatement, stmtId, document, responseMsgs, api));
			case 15:
				var stmtId = msg.a;
				var document = msg.b;
				var responseMsgs = msg.c;
				return function (_v57) {
					var a = _v57.a;
					var cmd = _v57.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$callGetPresignedUrl, stmtId, document, responseMsgs, api));
			case 23:
				var responseMsgs = msg.a;
				return function (_v58) {
					var a = _v58.a;
					var cmd = _v58.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A2($author$project$App$Service$ApplicationResources$callGetICDCategories, responseMsgs, api));
			case 75:
				var stmtId = msg.a;
				var responseMsgs = msg.b;
				return function (_v59) {
					var a = _v59.a;
					var cmd = _v59.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeStatementMessageFromStatement, stmtId, responseMsgs, api));
			case 76:
				var stmtMessage = msg.a;
				var responseMsgs = msg.b;
				var _v60 = $author$project$App$Service$ApplicationResources$getAuthToken(api);
				if (!_v60.$) {
					var token = _v60.a;
					return function (_v61) {
						var a = _v61.a;
						var cmd = _v61.b;
						return _Utils_Tuple3(model, a, cmd);
					}(
						A3(
							$author$project$App$Service$ApplicationResources$callSendStatementMessageFromStatementId,
							_Utils_update(
								stmtMessage,
								{eS: token.Y}),
							responseMsgs,
							api));
				} else {
					return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
				}
			case 24:
				var stmtId = msg.a;
				var responseMsgs = msg.b;
				return function (_v62) {
					var a = _v62.a;
					var cmd = _v62.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeGetICDs, stmtId, responseMsgs, api));
			case 25:
				var icdId = msg.a;
				var responseMsgs = msg.b;
				return function (_v63) {
					var a = _v63.a;
					var cmd = _v63.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callDeleteICD, icdId, responseMsgs, api));
			case 26:
				var stmtId = msg.a;
				var responseMsgs = msg.b;
				return function (_v64) {
					var a = _v64.a;
					var cmd = _v64.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callApproveStatement, stmtId, responseMsgs, api));
			case 27:
				var icd = msg.a;
				var responseMsgs = msg.b;
				return function (_v65) {
					var a = _v65.a;
					var cmd = _v65.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callSetICD, icd, responseMsgs, api));
			case 29:
				var stmt = msg.a;
				var responseMsgs = msg.b;
				return function (_v66) {
					var a = _v66.a;
					var cmd = _v66.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callGetNexodataToken, stmt, responseMsgs, api));
			case 30:
				var url = msg.a;
				var stmtId = msg.b;
				var responseMsgs = msg.c;
				return function (_v67) {
					var a = _v67.a;
					var cmd = _v67.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$callUploadDocumentFromStatementId, url, stmtId, responseMsgs, api));
			case 28:
				var id = msg.a;
				var responseMsgs = msg.b;
				return function (_v68) {
					var a = _v68.a;
					var cmd = _v68.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callGetMemedToken, id, responseMsgs, api));
			case 31:
				var state = msg.a;
				var responseMsgs = msg.b;
				return function (_v69) {
					var a = _v69.a;
					var cmd = _v69.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callGetMemedCities, state, responseMsgs, api));
			case 32:
				var responseMsgs = msg.a;
				return function (_v70) {
					var a = _v70.a;
					var cmd = _v70.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A2($author$project$App$Service$ApplicationResources$callGetMemedSpecialties, responseMsgs, api));
			case 38:
				var templateId = msg.a;
				var category = msg.b;
				var respMsgs = msg.c;
				return function (_v71) {
					var a = _v71.a;
					var cmd = _v71.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$callCompleteSectionFromTemplateAndCategory, templateId, category, respMsgs, api));
			case 1:
				var stmtId = msg.a;
				var category = msg.b;
				var respMsgs = msg.c;
				return function (_v72) {
					var a = _v72.a;
					var cmd = _v72.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$Answer$subscribeAnswersFromStatementAndCategory, stmtId, category, respMsgs, api));
			case 2:
				var answer = msg.a;
				var responseMsgs = msg.b;
				return function (_v73) {
					var a = _v73.a;
					var cmd = _v73.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$Answer$callSetAnswerById, answer, responseMsgs, api));
			case 11:
				var stmtId = msg.a;
				var responseMsgs = msg.b;
				return function (_v74) {
					var a = _v74.a;
					var cmd = _v74.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeConferenceFromStatement, stmtId, responseMsgs, api));
			case 87:
				var statementId = msg.a;
				var responseMsgs = msg.b;
				return function (_v75) {
					var a = _v75.a;
					var cmd = _v75.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeStatementTemplateByStatementId, statementId, responseMsgs, api));
			case 50:
				var stmtId = msg.a;
				var assets = msg.b;
				var respMsgs = msg.c;
				var loadingStatus = model.ia.i;
				var _v76 = A3(
					$author$project$App$Service$ApplicationResources$subscribeStatementById,
					stmtId,
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$SharedMsg(
							$author$project$App$Page$ResponseMsgs$SharedStatementLoaded(stmtId))
						]),
					api);
				var uAPI = _v76.a;
				var uAPICmd = _v76.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								$author$project$App$SharedState$emptyFullStatementCache,
								{
									aq: $author$project$App$SharedState$FullLoad(assets),
									cl: respMsgs,
									i: _Utils_update(
										loadingStatus,
										{aG: false})
								})
						}),
					uAPI,
					uAPICmd);
			case 51:
				var stmtId = msg.a;
				var assets = msg.b;
				var respMsgs = msg.c;
				var loadingStatus = model.ia.i;
				var _v77 = A3(
					$author$project$App$Service$ApplicationResources$subscribeStatementById,
					stmtId,
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$SharedMsg(
							$author$project$App$Page$ResponseMsgs$SharedStatementLoaded(stmtId))
						]),
					api);
				var uAPI = _v77.a;
				var uAPICmd = _v77.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								$author$project$App$SharedState$emptyFullStatementCache,
								{
									aq: $author$project$App$SharedState$FullLoadForReview(assets),
									cl: respMsgs,
									i: _Utils_update(
										loadingStatus,
										{aG: false})
								})
						}),
					uAPI,
					uAPICmd);
			case 52:
				var stmtId = msg.a;
				var assets = msg.b;
				var respMsgs = msg.c;
				var loadingStatus = model.ia.i;
				var _v78 = A3(
					$author$project$App$Service$ApplicationResources$subscribeStatementById,
					stmtId,
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$SharedMsg(
							$author$project$App$Page$ResponseMsgs$SharedStatementLoaded(stmtId))
						]),
					api);
				var uAPI = _v78.a;
				var uAPICmd = _v78.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								$author$project$App$SharedState$emptyFullStatementCache,
								{
									aq: $author$project$App$SharedState$FullLoadWithMinors(assets),
									cl: respMsgs,
									i: _Utils_update(
										loadingStatus,
										{aG: false})
								})
						}),
					uAPI,
					uAPICmd);
			case 47:
				var statementId = msg.a;
				var responseMsgs = msg.b;
				var cache = model.ia;
				return function (_v79) {
					var a = _v79.a;
					var cmd = _v79.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								ia: _Utils_update(
									$author$project$App$SharedState$emptyFullStatementCache,
									{aq: $author$project$App$SharedState$SimpleLoad})
							}),
						a,
						cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeStatementById, statementId, responseMsgs, api));
			case 33:
				var personId = msg.a;
				var respMsgs = msg.b;
				return function (_v80) {
					var a = _v80.a;
					var cmd = _v80.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribePersonById, personId, respMsgs, api));
			case 34:
				var document = msg.a;
				var respMsgs = msg.b;
				return function (_v81) {
					var a = _v81.a;
					var cmd = _v81.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribePersonByDocument, document, respMsgs, api));
			case 35:
				var personId = msg.a;
				var respMsgs = msg.b;
				return function (_v82) {
					var a = _v82.a;
					var cmd = _v82.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callHoldPersonById, personId, respMsgs, api));
			case 36:
				var personId = msg.a;
				var respMsgs = msg.b;
				return function (_v83) {
					var a = _v83.a;
					var cmd = _v83.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callReturnPersonToLineById, personId, respMsgs, api));
			case 37:
				var person = msg.a;
				var respMsgs = msg.b;
				return function (_v84) {
					var a = _v84.a;
					var cmd = _v84.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callSetPersonById, person, respMsgs, api));
			case 9:
				var companyId = msg.a;
				var respMsgs = msg.b;
				return function (_v85) {
					var a = _v85.a;
					var cmd = _v85.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeCompanyById, companyId, respMsgs, api));
			case 10:
				var respMsgs = msg.a;
				return function (_v86) {
					var a = _v86.a;
					var cmd = _v86.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A2($author$project$App$Service$ApplicationResources$subscribeCompaniesWithTemplates, respMsgs, api));
			case 48:
				var statementId = msg.a;
				var responseMsgs = msg.b;
				return function (_v87) {
					var a = _v87.a;
					var cmd = _v87.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeStatementDepsById, statementId, responseMsgs, api));
			case 49:
				var statementId = msg.a;
				var responseMsgs = msg.b;
				return function (_v88) {
					var a = _v88.a;
					var cmd = _v88.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeStatementMinorDepsById, statementId, responseMsgs, api));
			case 53:
				var respMsgs = msg.a;
				var _v89 = $author$project$App$Service$ApplicationResources$getAuthToken(api);
				if (!_v89.$) {
					var token = _v89.a;
					return function (_v90) {
						var a = _v90.a;
						var cmd = _v90.b;
						return _Utils_Tuple3(model, a, cmd);
					}(
						A3($author$project$App$Service$ApplicationResources$subscribeDoctorInterview, token.Y, respMsgs, api));
				} else {
					return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
				}
			case 54:
				var companyId = msg.a;
				var filter = msg.b;
				var respMsgs = msg.c;
				return function (_v91) {
					var a = _v91.a;
					var cmd = _v91.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$subscribeStatementsFromCompany, companyId, filter, respMsgs, api));
			case 55:
				var filter = msg.a;
				var respMsgs = msg.b;
				var _v92 = $author$project$App$Service$ApplicationResources$getAuthToken(api);
				if (!_v92.$) {
					var token = _v92.a;
					return function (_v93) {
						var a = _v93.a;
						var cmd = _v93.b;
						return _Utils_Tuple3(model, a, cmd);
					}(
						A4($author$project$App$Service$ApplicationResources$subscribeStatementsFromDoctor, token.Y, filter, respMsgs, api));
				} else {
					return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
				}
			case 56:
				var filter = msg.a;
				var respMsgs = msg.b;
				var _v94 = $author$project$App$Service$ApplicationResources$getAuthToken(api);
				if (!_v94.$) {
					var token = _v94.a;
					return function (_v95) {
						var a = _v95.a;
						var cmd = _v95.b;
						return _Utils_Tuple3(model, a, cmd);
					}(
						A4($author$project$App$Service$ApplicationResources$subscribeReevalStatementsFromDoctor, token.Y, filter, respMsgs, api));
				} else {
					return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
				}
			case 57:
				var filter = msg.a;
				var respMsgs = msg.b;
				var _v96 = $author$project$App$Service$ApplicationResources$getAuthToken(api);
				if (!_v96.$) {
					var token = _v96.a;
					return function (_v97) {
						var a = _v97.a;
						var cmd = _v97.b;
						return _Utils_Tuple3(model, a, cmd);
					}(
						A4($author$project$App$Service$ApplicationResources$subscribeFinishedStatementsFromDoctor, token.Y, filter, respMsgs, api));
				} else {
					return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
				}
			case 58:
				var filter = msg.a;
				var respMsgs = msg.b;
				var _v98 = $author$project$App$Service$ApplicationResources$getAuthToken(api);
				if (!_v98.$) {
					var token = _v98.a;
					return function (_v99) {
						var a = _v99.a;
						var cmd = _v99.b;
						return _Utils_Tuple3(model, a, cmd);
					}(
						A4($author$project$App$Service$ApplicationResources$subscribeFinishedStatementsFromAdmin, token.Y, filter, respMsgs, api));
				} else {
					return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
				}
			case 59:
				var cId = msg.a;
				var filter = msg.b;
				var respMsgs = msg.c;
				return function (_v100) {
					var a = _v100.a;
					var cmd = _v100.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$subscribeReevalStatementsFromCompany, cId, filter, respMsgs, api));
			case 60:
				var personId = msg.a;
				var respMsgs = msg.b;
				return function (_v101) {
					var a = _v101.a;
					var cmd = _v101.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeStatementFromPerson, personId, respMsgs, api));
			case 61:
				var personId = msg.a;
				return function (_v102) {
					var a = _v102.a;
					var cmd = _v102.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A2($author$project$App$Service$ApplicationResources$unsubscribeStatementFromPerson, personId, api));
			case 81:
				var cId = msg.a;
				var filter = msg.b;
				var respMsgs = msg.c;
				return function (_v103) {
					var a = _v103.a;
					var cmd = _v103.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$subscribeStatementForReview, cId, filter, respMsgs, api));
			case 82:
				var cId = msg.a;
				var filter = msg.b;
				var respMsgs = msg.c;
				return function (_v104) {
					var a = _v104.a;
					var cmd = _v104.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$subscribeApprovedStatementForReview, cId, filter, respMsgs, api));
			case 83:
				var cId = msg.a;
				var filter = msg.b;
				var respMsgs = msg.c;
				return function (_v105) {
					var a = _v105.a;
					var cmd = _v105.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$subscribeIncompleteStatementForReview, cId, filter, respMsgs, api));
			case 62:
				var companyId = msg.a;
				var fullStmt = msg.b;
				var respMsgs = msg.c;
				return function (_v106) {
					var a = _v106.a;
					var cmd = _v106.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$callNewFullStatementForCompany, companyId, fullStmt, respMsgs, api));
			case 65:
				var fullStmt = msg.a;
				var respMsgs = msg.b;
				return function (_v107) {
					var a = _v107.a;
					var cmd = _v107.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callStatementSaveDocuments, fullStmt, respMsgs, api));
			case 63:
				var stmt = msg.a;
				var respMsgs = msg.b;
				return function (_v108) {
					var a = _v108.a;
					var cmd = _v108.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callReturnToCompany, stmt, respMsgs, api));
			case 68:
				var stmt = msg.a;
				var respMsgs = msg.b;
				return function (_v109) {
					var a = _v109.a;
					var cmd = _v109.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callPostponeStatement, stmt, respMsgs, api));
			case 69:
				var stmt = msg.a;
				var respMsgs = msg.b;
				var emptyMessageModel = $author$project$App$Data$StatementMessage$emptyModel;
				var _v110 = $author$project$App$Service$ApplicationResources$getAuthToken(api);
				if (!_v110.$) {
					var token = _v110.a;
					return function (_v111) {
						var a = _v111.a;
						var cmd = _v111.b;
						return _Utils_Tuple3(model, a, cmd);
					}(
						A3(
							$author$project$App$Service$ApplicationResources$callReturnToDoctorEvaluation,
							_Utils_update(
								emptyMessageModel,
								{eS: token.Y, aV: stmt.as}),
							respMsgs,
							api));
				} else {
					return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
				}
			case 70:
				var stmt = msg.a;
				var respMsgs = msg.b;
				return function (_v112) {
					var a = _v112.a;
					var cmd = _v112.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callReturnToInterview, stmt, respMsgs, api));
			case 71:
				var stmt = msg.a;
				var respMsgs = msg.b;
				return function (_v113) {
					var a = _v113.a;
					var cmd = _v113.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callReturnToWaitingForRoom, stmt, respMsgs, api));
			case 64:
				var stmt = msg.a;
				var respMsgs = msg.b;
				return function (_v114) {
					var a = _v114.a;
					var cmd = _v114.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callSaveStatementById, stmt, respMsgs, api));
			case 66:
				var stmtId = msg.a;
				var respMsgs = msg.b;
				return function (_v115) {
					var a = _v115.a;
					var cmd = _v115.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callRequestStatementReview, stmtId, respMsgs, api));
			case 67:
				var stmtId = msg.a;
				var respMsgs = msg.b;
				return function (_v116) {
					var a = _v116.a;
					var cmd = _v116.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callRequestStatementUnschedule, stmtId, respMsgs, api));
			case 72:
				var stmtId = msg.a;
				var respMsgs = msg.b;
				return function (_v117) {
					var a = _v117.a;
					var cmd = _v117.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callStatementStartInterviewById, stmtId, respMsgs, api));
			case 73:
				var stmtMessage = msg.a;
				var respMsgs = msg.b;
				var _v118 = $author$project$App$Service$ApplicationResources$getAuthToken(api);
				if (!_v118.$) {
					var token = _v118.a;
					return function (_v119) {
						var a = _v119.a;
						var cmd = _v119.b;
						return _Utils_Tuple3(model, a, cmd);
					}(
						A3(
							$author$project$App$Service$ApplicationResources$callReturnToDoctorEvaluation,
							_Utils_update(
								stmtMessage,
								{eS: token.Y}),
							respMsgs,
							api));
				} else {
					return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
				}
			case 74:
				var stmt = msg.a;
				var respMsgs = msg.b;
				return function (_v120) {
					var a = _v120.a;
					var cmd = _v120.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callStatementFinishReevaluation, stmt, respMsgs, api));
			case 77:
				var stmt = msg.a;
				var respMsgs = msg.b;
				return function (_v121) {
					var a = _v121.a;
					var cmd = _v121.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callStatementSetById, stmt, respMsgs, api));
			case 102:
				var companyId = msg.a;
				var respMsgs = msg.b;
				return function (_v122) {
					var a = _v122.a;
					var cmd = _v122.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeUsersFromCompany, companyId, respMsgs, api));
			case 90:
				var companyId = msg.a;
				var respMsgs = msg.b;
				return function (_v123) {
					var a = _v123.a;
					var cmd = _v123.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$requestMonthSummaryByCompany, companyId, respMsgs, api));
			case 91:
				var doctorId = msg.a;
				var respMsgs = msg.b;
				return function (_v124) {
					var a = _v124.a;
					var cmd = _v124.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$requestMonthSummaryByDoctor, doctorId, respMsgs, api));
			case 92:
				var companyId = msg.a;
				var respMsgs = msg.b;
				return function (_v125) {
					var a = _v125.a;
					var cmd = _v125.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$requestTaxesByCompany, companyId, respMsgs, api));
			case 93:
				var companyId = msg.a;
				var startDate = msg.b;
				var endDate = msg.c;
				var respMsgs = msg.d;
				return function (_v126) {
					var a = _v126.a;
					var cmd = _v126.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A5($author$project$App$Service$ApplicationResources$requestMonthDetailsByCompany, companyId, startDate, endDate, respMsgs, api));
			case 94:
				var medsynId = msg.a;
				var companyId = msg.b;
				var startDate = msg.c;
				var endDate = msg.d;
				var respMsgs = msg.e;
				return function (_v127) {
					var a = _v127.a;
					var cmd = _v127.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A6($author$project$App$Service$ApplicationResources$requestMedsynMonthDetailsByCompany, medsynId, companyId, startDate, endDate, respMsgs, api));
			case 95:
				var doctorId = msg.a;
				var startDate = msg.b;
				var endDate = msg.c;
				var respMsgs = msg.d;
				return function (_v128) {
					var a = _v128.a;
					var cmd = _v128.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A5($author$project$App$Service$ApplicationResources$requestMonthDetailsByDoctor, doctorId, startDate, endDate, respMsgs, api));
			case 96:
				var companyId = msg.a;
				var startDate = msg.b;
				var endDate = msg.c;
				var doctor = msg.d;
				var template = msg.e;
				var respMsgs = msg.f;
				return function (_v129) {
					var a = _v129.a;
					var cmd = _v129.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A7($author$project$App$Service$ApplicationResources$requestCompanyReport, companyId, startDate, endDate, doctor, template, respMsgs, api));
			case 97:
				var medsynId = msg.a;
				var companyId = msg.b;
				var startDate = msg.c;
				var endDate = msg.d;
				var doctor = msg.e;
				var template = msg.f;
				var respMsgs = msg.g;
				return function (_v130) {
					var a = _v130.a;
					var cmd = _v130.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A8($author$project$App$Service$ApplicationResources$requestMedsynCompanyReport, medsynId, companyId, startDate, endDate, doctor, template, respMsgs, api));
			case 98:
				var companyId = msg.a;
				var startDate = msg.b;
				var endDate = msg.c;
				var doctor = msg.d;
				var template = msg.e;
				var respMsgs = msg.f;
				return function (_v131) {
					var a = _v131.a;
					var cmd = _v131.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A7($author$project$App$Service$ApplicationResources$requestCreateCompanyReportCsv, companyId, startDate, endDate, doctor, template, respMsgs, api));
			case 99:
				var companyId = msg.a;
				var startDate = msg.b;
				var endDate = msg.c;
				var doctor = msg.d;
				var template = msg.e;
				var respMsgs = msg.f;
				return function (_v132) {
					var a = _v132.a;
					var cmd = _v132.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A7($author$project$App$Service$ApplicationResources$requestCreateCompanyReportPdf, companyId, startDate, endDate, doctor, template, respMsgs, api));
			case 100:
				var doctorId = msg.a;
				var startDate = msg.b;
				var endDate = msg.c;
				var doctor = msg.d;
				var template = msg.e;
				var respMsgs = msg.f;
				return function (_v133) {
					var a = _v133.a;
					var cmd = _v133.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A7($author$project$App$Service$ApplicationResources$requestCreateDoctorReportCsv, doctorId, startDate, endDate, doctor, template, respMsgs, api));
			case 101:
				var doctorId = msg.a;
				var startDate = msg.b;
				var endDate = msg.c;
				var doctor = msg.d;
				var template = msg.e;
				var respMsgs = msg.f;
				return function (_v134) {
					var a = _v134.a;
					var cmd = _v134.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A7($author$project$App$Service$ApplicationResources$requestCreateDoctorReportPdf, doctorId, startDate, endDate, doctor, template, respMsgs, api));
			case 103:
				var userId = msg.a;
				var respMsgs = msg.b;
				return function (_v135) {
					var a = _v135.a;
					var cmd = _v135.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callRequestUserActivation, userId, respMsgs, api));
			case 104:
				var userId = msg.a;
				var respMsgs = msg.b;
				return function (_v136) {
					var a = _v136.a;
					var cmd = _v136.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callRequestUserDeletion, userId, respMsgs, api));
			case 105:
				var companyId = msg.a;
				var respMsgs = msg.b;
				return function (_v137) {
					var a = _v137.a;
					var cmd = _v137.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeDoctorsFromCompany, companyId, respMsgs, api));
			case 106:
				var companyId = msg.a;
				var respMsgs = msg.b;
				return function (_v138) {
					var a = _v138.a;
					var cmd = _v138.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeDoctorsFromCompany, companyId, respMsgs, api));
			case 107:
				var license = msg.a;
				var licenseState = msg.b;
				var respMsgs = msg.c;
				return function (_v139) {
					var a = _v139.a;
					var cmd = _v139.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$subscribeDoctorFromLicense, license, licenseState, respMsgs, api));
			case 108:
				var doctorId = msg.a;
				var respMsgs = msg.b;
				return function (_v140) {
					var a = _v140.a;
					var cmd = _v140.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$subscribeDoctorsOnlineForDoctor, doctorId, respMsgs, api));
			case 109:
				var doctorId = msg.a;
				var doctorTemplate = msg.b;
				var respMsgs = msg.c;
				return function (_v141) {
					var a = _v141.a;
					var cmd = _v141.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$callRegisterDoctorByDoctorId, doctorId, doctorTemplate, respMsgs, api));
			case 110:
				var doctorId = msg.a;
				var doctorTemplate = msg.b;
				var respMsgs = msg.c;
				return function (_v142) {
					var a = _v142.a;
					var cmd = _v142.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$callRemoveDoctorByDoctorId, doctorId, doctorTemplate, respMsgs, api));
			case 84:
				var stmtId = msg.a;
				var respMsgs = msg.b;
				return function (_v143) {
					var a = _v143.a;
					var cmd = _v143.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callLoadTermsByStatementId, stmtId, respMsgs, api));
			case 111:
				var url = msg.a;
				var path = msg.b;
				var respMsgs = msg.c;
				return function (_v144) {
					var a = _v144.a;
					var cmd = _v144.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$callLoadTemplateFromUrl, url, path, respMsgs, api));
			case 112:
				var document = msg.a;
				var birthdate = msg.b;
				var stmtTemplateId = msg.c;
				var respMsgs = msg.d;
				return function (_v145) {
					var a = _v145.a;
					var cmd = _v145.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A5($author$project$App$Service$ApplicationResources$callGetStatementFromDocumentAndBirthDate, document, birthdate, stmtTemplateId, respMsgs, api));
			case 113:
				var fullStatement = msg.a;
				var stmtTemplateId = msg.b;
				var respMsgs = msg.c;
				return function (_v146) {
					var a = _v146.a;
					var cmd = _v146.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A4($author$project$App$Service$ApplicationResources$callNewFullStatementFromProponent, stmtTemplateId, fullStatement, respMsgs, api));
			case 114:
				var user = msg.a;
				var respMsgs = msg.b;
				if (_Utils_eq(user.bf, user.dv)) {
					var _v147 = $author$project$App$Service$ApplicationResources$getAuthToken(api);
					if (!_v147.$) {
						var tokenModel = _v147.a;
						var token = $truqu$elm_base64$Base64$encode(
							A2(
								$elm$json$Json$Encode$encode,
								0,
								$author$project$App$Data$Token$encode(tokenModel)));
						return function (_v148) {
							var a = _v148.a;
							var cmd = _v148.b;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										b6: $elm$core$Maybe$Just('Carregando')
									}),
								a,
								cmd);
						}(
							A4($author$project$App$Service$ApplicationResources$callResetPasswordWithPassword, token, user, respMsgs, api));
					} else {
						return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								b6: $elm$core$Maybe$Just('Senhas não coincidem')
							}),
						api,
						$elm$core$Platform$Cmd$none);
				}
			case 115:
				var docId = msg.a;
				var respMsgs = msg.b;
				return function (_v149) {
					var a = _v149.a;
					var cmd = _v149.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callDeleteDocumentFromId, docId, respMsgs, api));
			case 116:
				var document = msg.a;
				var respMsgs = msg.b;
				return function (_v150) {
					var a = _v150.a;
					var cmd = _v150.b;
					return _Utils_Tuple3(model, a, cmd);
				}(
					A3($author$project$App$Service$ApplicationResources$callUpdateDocumentFromId, document, respMsgs, api));
			default:
				return _Utils_Tuple3(model, api, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Data$Conference$ProfileDoctor = 1;
var $author$project$App$Components$Video$requestAvailableDevices = _Platform_outgoingPort(
	'requestAvailableDevices',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$App$Data$Conference$roomTypeToString = function (room) {
	if (!room) {
		return '';
	} else {
		return 'statement';
	}
};
var $author$project$App$Data$Conference$roomName = F3(
	function (roomType, profile, conference) {
		var roomTypeStr = $author$project$App$Data$Conference$roomTypeToString(roomType);
		var profileStr = $author$project$App$Data$Conference$profileToString(profile);
		return roomTypeStr + ('=' + (conference.as + ('&&password=' + (conference.bf + ('&&profile=' + profileStr)))));
	});
var $author$project$App$Components$Video$toVideoServers = F3(
	function (roomType, profile, conference) {
		return {
			eO: A3($author$project$App$Data$Conference$roomName, roomType, profile, conference),
			dM: conference.dM,
			dN: _List_fromArray(
				[
					{
					c$: conference.ie,
					dX: _List_fromArray(
						[conference.dN]),
					dY: conference.$8
				}
				]),
			dT: _List_fromArray(
				[
					{
					c$: conference.ie,
					dX: _List_fromArray(
						[conference.dT]),
					dY: conference.$8
				}
				])
		};
	});
var $author$project$App$Components$Video$init = F3(
	function (room, prof, conference) {
		return _Utils_Tuple2(
			_Utils_update(
				$author$project$App$Components$Video$emptyModel,
				{
					d7: conference,
					fM: $elm$core$Maybe$Just(prof),
					dA: $elm$core$Maybe$Just((prof === 1) && (conference.jQ || conference.jR)),
					eT: A3($author$project$App$Components$Video$toVideoServers, room, prof, conference)
				}),
			$author$project$App$Components$Video$requestAvailableDevices(0));
	});
var $author$project$App$Components$Video$initSelfieWithResponse = function (responseMsg) {
	return _Utils_Tuple2(
		_Utils_update(
			$author$project$App$Components$Video$emptyModel,
			{aT: true, dH: responseMsg, bM: false}),
		$author$project$App$Components$Video$requestAvailableDevices(0));
};
var $author$project$App$Components$Video$initTest = _Utils_Tuple2(
	_Utils_update(
		$author$project$App$Components$Video$emptyModel,
		{bM: true}),
	$author$project$App$Components$Video$requestAvailableDevices(0));
var $author$project$App$Components$Video$videoStop = _Platform_outgoingPort(
	'videoStop',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$App$Components$Video$stop = function (model) {
	var _v0 = model.p;
	switch (_v0.$) {
		case 0:
			return $elm$core$Platform$Cmd$none;
		case 1:
			return $elm$core$Platform$Cmd$none;
		case 2:
			return $author$project$App$Components$Video$videoStop(0);
		case 3:
			return $author$project$App$Components$Video$videoStop(0);
		case 5:
			return $elm$core$Platform$Cmd$none;
		case 6:
			return $elm$core$Platform$Cmd$none;
		default:
			return $elm$core$Platform$Cmd$none;
	}
};
var $author$project$App$Components$Video$AcceptSelfie = {$: 4};
var $author$project$App$Components$Video$DiscardSelfie = {$: 3};
var $author$project$App$Components$Video$RequestStop = {$: 0};
var $author$project$App$Components$Video$SwitchCamera = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$App$Components$Video$TakeSelfie = {$: 1};
var $author$project$App$Components$Video$ViewMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$SharedState$asyncMsg = function (msg) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$identity,
		$elm$core$Task$succeed(msg));
};
var $author$project$App$SharedState$updateVideoMapMsg = F2(
	function (videoMsg, model) {
		switch (videoMsg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Components$Video$ViewMsg($author$project$App$Components$Video$RequestStop)));
			case 1:
				var device = videoMsg.a;
				var remote = videoMsg.b;
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Components$Video$ViewMsg(
							A2($author$project$App$Components$Video$SwitchCamera, device, remote))));
			case 3:
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Components$Video$ViewMsg($author$project$App$Components$Video$DiscardSelfie)));
			case 4:
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Components$Video$ViewMsg($author$project$App$Components$Video$AcceptSelfie)));
			default:
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Components$Video$ViewMsg($author$project$App$Components$Video$TakeSelfie)));
		}
	});
var $author$project$App$SharedState$updateVideo = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var room = msg.a;
				var profile = msg.b;
				var conference = msg.c;
				return A3($author$project$App$Components$Video$init, room, profile, conference);
			case 1:
				return _Utils_Tuple2(
					model,
					$author$project$App$Components$Video$stop(model));
			case 2:
				var responseMsg = msg.a;
				return $author$project$App$Components$Video$initSelfieWithResponse(
					_List_fromArray(
						[responseMsg]));
			case 3:
				return $author$project$App$Components$Video$initTest;
			default:
				var videoInternalMsg = msg.a;
				return A2($author$project$App$SharedState$updateVideoMapMsg, videoInternalMsg, model);
		}
	});
var $author$project$App$SharedState$updateSharedSingle = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var zone = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{f6: zone}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var apiMsg = msg.a;
				var _v1 = A3($author$project$App$SharedState$updateAPI, apiMsg, model, model._);
				var uModel = _v1.a;
				var apiModel = _v1.b;
				var apiCmd = _v1.c;
				return _Utils_Tuple2(
					_Utils_update(
						uModel,
						{_: apiModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$SharedState$APICmd, apiCmd));
			default:
				var videoMsg = msg.a;
				var _v2 = A2($author$project$App$SharedState$updateVideo, videoMsg, model.f4);
				var videoModel = _v2.a;
				var videoCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{f4: videoModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$SharedState$VideoCmd, videoCmd));
		}
	});
var $author$project$App$SharedState$updateShared = F2(
	function (list, model) {
		return A2(
			$elm$core$Tuple$mapSecond,
			$elm$core$Platform$Cmd$batch,
			A3(
				$elm$core$List$foldl,
				F2(
					function (msg, _v0) {
						var m = _v0.a;
						var cmds = _v0.b;
						var _v1 = A2($author$project$App$SharedState$updateSharedSingle, msg, m);
						var nM = _v1.a;
						var cmd = _v1.b;
						return _Utils_Tuple2(
							nM,
							A2(
								$elm$core$List$append,
								cmds,
								_List_fromArray(
									[cmd])));
					}),
				_Utils_Tuple2(model, _List_Nil),
				list));
	});
var $author$project$App$Main$init = F3(
	function (_v0, url, key) {
		var wantedRoute = $author$project$App$Route$parseUrl(url);
		var cssCmd = function () {
			switch (wantedRoute.$) {
				case 2:
					return $elm$core$Platform$Cmd$none;
				case 3:
					return $author$project$App$MedsynCss$load('proponent_core');
				default:
					return $author$project$App$MedsynCss$load('core');
			}
		}();
		var _v1 = function () {
			switch (wantedRoute.$) {
				case 2:
					return _Utils_Tuple2(wantedRoute, false);
				case 1:
					return _Utils_Tuple2(wantedRoute, false);
				case 3:
					return _Utils_Tuple2(wantedRoute, false);
				default:
					return _Utils_Tuple2(
						$author$project$App$Route$Public($author$project$App$Route$Loading),
						true);
			}
		}();
		var routeTo = _v1.a;
		var recoverSession = _v1.b;
		var _v3 = A3($author$project$App$SharedState$init, key, wantedRoute, recoverSession);
		var sharedState = _v3.a;
		var sharedInitCmd = _v3.b;
		var _v4 = function () {
			_v5$3:
			while (true) {
				switch (wantedRoute.$) {
					case 2:
						var stmtId = wantedRoute.a;
						return function (_v6) {
							var a = _v6.a;
							var b = _v6.b;
							var c = _v6.c;
							return _Utils_Tuple3(
								$author$project$App$Main$Proponent(a),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Main$ProponentMsg, b),
								c);
						}(
							A2($author$project$App$Page$PageProponent$init, stmtId, sharedState));
					case 3:
						return function (_v7) {
							var a = _v7.a;
							var b = _v7.b;
							var c = _v7.c;
							return _Utils_Tuple3(
								$author$project$App$Main$Client(a),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Main$ClientMsg, b),
								c);
						}($author$project$App$Page$Client$PageClient$init);
					case 1:
						if (wantedRoute.a.$ === 6) {
							var string = wantedRoute.a.a;
							return function (_v8) {
								var a = _v8.a;
								var b = _v8.b;
								var c = _v8.c;
								return _Utils_Tuple3(
									$author$project$App$Main$UserRecoverPassword(a),
									A2($elm$core$Platform$Cmd$map, $author$project$App$Main$UserRecoverPasswordMsg, b),
									c);
							}(
								$author$project$App$Page$PageUserRecoverPassword$init(string));
						} else {
							break _v5$3;
						}
					default:
						break _v5$3;
				}
			}
			return function (_v9) {
				var a = _v9.a;
				var b = _v9.b;
				var c = _v9.c;
				return _Utils_Tuple3(
					$author$project$App$Main$Loading(a),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Main$LoadingMsg, b),
					c);
			}(
				$author$project$App$Page$PageLoading$init(sharedState));
		}();
		var pageModel = _v4.a;
		var pageCmd = _v4.b;
		var sharedMsg = _v4.c;
		var _v10 = A2($author$project$App$SharedState$updateShared, sharedMsg, sharedState);
		var sharedModel = _v10.a;
		var sharedPageLoadCmd = _v10.b;
		return _Utils_Tuple2(
			{
				fC: key,
				ey: $elm$time$Time$millisToPosix(0),
				aQ: pageModel,
				eP: routeTo,
				a: sharedModel,
				eW: true,
				f6: $elm$time$Time$utc
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						cssCmd,
						pageCmd,
						A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedInitCmd),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedPageLoadCmd),
						A2($elm$core$Task$perform, $author$project$App$Main$AdjustTimeZone, $elm$time$Time$here),
						A2($elm$core$Task$perform, $author$project$App$Main$GetTime, $elm$time$Time$now)
					])));
	});
var $author$project$App$Main$AdminMsg = function (a) {
	return {$: 21, a: a};
};
var $author$project$App$Main$CompanyMsg = function (a) {
	return {$: 18, a: a};
};
var $author$project$App$Main$DoctorMsg = function (a) {
	return {$: 17, a: a};
};
var $author$project$App$Main$ReviewMsg = function (a) {
	return {$: 19, a: a};
};
var $author$project$App$Main$SupportMsg = function (a) {
	return {$: 20, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$App$Page$PageCompany$ListCanceledMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$PageCompany$ListConcludedMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$PageCompany$ListPendingMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$PageCompany$ListReevaluationMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$PageCompany$RequestInterviewMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Company$PageListCanceled$ViewFullStmtMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Components$ViewFullStatement$DocumentMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Data$Document$DocumentUrl = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Data$Document$UploadFailed = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Data$Document$documentUrlHasBeenSet = _Platform_incomingPort(
	'documentUrlHasBeenSet',
	A2(
		$elm$json$Json$Decode$andThen,
		function (personId) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (ownerKind) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (ownerId) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (objectUrlRequested) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (objectUrl) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (name) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (mime) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (id) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (docType) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (content) {
																					return $elm$json$Json$Decode$succeed(
																						{iU: content, b7: docType, as: id, dr: mime, hl: name, af: objectUrl, Q: objectUrlRequested, jJ: ownerId, X: ownerKind, Y: personId});
																				},
																				A2($elm$json$Json$Decode$field, 'content', $elm$json$Json$Decode$string));
																		},
																		A2($elm$json$Json$Decode$field, 'docType', $elm$json$Json$Decode$int));
																},
																A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string));
														},
														A2($elm$json$Json$Decode$field, 'mime', $elm$json$Json$Decode$string));
												},
												A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
										},
										A2($elm$json$Json$Decode$field, 'objectUrl', $elm$json$Json$Decode$string));
								},
								A2($elm$json$Json$Decode$field, 'objectUrlRequested', $elm$json$Json$Decode$bool));
						},
						A2($elm$json$Json$Decode$field, 'ownerId', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'ownerKind', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'personId', $elm$json$Json$Decode$string)));
var $author$project$App$Data$Document$TypeCPT = 5;
var $author$project$App$Data$Document$TypeDocumentStatement = 0;
var $author$project$App$Data$Document$TypeMedicalDocument = 3;
var $author$project$App$Data$Document$TypeNexodataDocument = 4;
var $author$project$App$Data$Document$TypeReport = 1;
var $author$project$App$Data$Document$TypeVideo = 2;
var $author$project$App$Data$Document$typeFromInt = function (i) {
	switch (i) {
		case 4:
			return 1;
		case 5:
			return 2;
		case 7:
			return 3;
		case 8:
			return 5;
		case 11:
			return 4;
		default:
			return 0;
	}
};
var $author$project$App$Data$Document$portToModel = function (pm) {
	return {
		iU: pm.iU,
		iW: $elm$time$Time$millisToPosix(0),
		b7: $author$project$App$Data$Document$typeFromInt(pm.b7),
		as: pm.as,
		dr: pm.dr,
		hl: pm.hl,
		af: pm.af,
		Q: pm.Q,
		jJ: pm.jJ,
		X: pm.X,
		Y: pm.Y,
		f2: $elm$time$Time$millisToPosix(0)
	};
};
var $author$project$App$Data$Document$uploadFailed = _Platform_incomingPort('uploadFailed', $elm$json$Json$Decode$string);
var $author$project$App$Data$Document$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$App$Data$Document$documentUrlHasBeenSet(
			function (model) {
				return $author$project$App$Data$Document$DocumentUrl(
					$author$project$App$Data$Document$portToModel(model));
			}),
			$author$project$App$Data$Document$uploadFailed(
			function (h) {
				return $author$project$App$Data$Document$UploadFailed(h);
			})
		]));
var $author$project$App$Components$ViewFullStatement$subscriptions = A2($elm$core$Platform$Sub$map, $author$project$App$Components$ViewFullStatement$DocumentMsg, $author$project$App$Data$Document$subscriptions);
var $author$project$App$Page$Company$PageListCanceled$subscription = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Company$PageListCanceled$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Company$PageListConcluded$ViewFullStmtMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Company$PageListConcluded$subscription = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Company$PageListConcluded$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Company$PageListPending$ViewFullStmtMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$Company$PageListPending$subscription = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Company$PageListPending$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Company$PageRequestInterview$DocumentMsg = function (a) {
	return {$: 17, a: a};
};
var $author$project$App$Page$Company$PageRequestInterview$subscription = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Company$PageRequestInterview$DocumentMsg, $author$project$App$Data$Document$subscriptions);
var $author$project$App$Page$Company$PageListReevaluation$ViewFullStmtMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Company$PageListReevaluation$subscriptions = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Company$PageListReevaluation$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$PageCompany$subscription = function (model) {
	switch (model.$) {
		case 1:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageCompany$ListPendingMsg, $author$project$App$Page$Company$PageListPending$subscription);
		case 2:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageCompany$ListCanceledMsg, $author$project$App$Page$Company$PageListCanceled$subscription);
		case 3:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageCompany$ListConcludedMsg, $author$project$App$Page$Company$PageListConcluded$subscription);
		case 0:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageCompany$RequestInterviewMsg, $author$project$App$Page$Company$PageRequestInterview$subscription);
		case 5:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageCompany$ListReevaluationMsg, $author$project$App$Page$Company$PageListReevaluation$subscriptions);
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$App$Page$PageReviewer$EditStatementMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$PageReviewer$ListApprovedMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$PageReviewer$ListForReviewMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$PageReviewer$ListIncompleteMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$PageReviewer$ListReevaluationMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$PageReviewer$ReviewInterviewMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$PageReviewer$WaitingLineMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$PageReviewer$WaitingProponentDetailsMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$ViewFullStmtMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$subscription = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Reviewer$EditStatement$StatementEdit$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Reviewer$PageListApproved$ViewFullStmtMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Reviewer$PageListApproved$subscription = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Reviewer$PageListApproved$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Reviewer$PageListForReview$ViewFullStmtMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Reviewer$PageListForReview$subscription = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Reviewer$PageListForReview$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Reviewer$PageListIncomplete$ViewFullStmtMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Reviewer$PageListIncomplete$subscription = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Reviewer$PageListIncomplete$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Reviewer$PageReview$ViewFullStmtMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Page$Reviewer$PageReview$subscription = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Reviewer$PageReview$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Reviewer$PageListReevaluation$ViewFullStmtMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Reviewer$PageListReevaluation$subscriptions = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Reviewer$PageListReevaluation$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Reviewer$PageWaitingLine$SoundMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Components$Sound$HasInit = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Components$Sound$Pause = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Components$Sound$Play = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Components$Sound$Stop = {$: 4};
var $author$project$App$Components$Sound$soundIsInitialized = _Platform_incomingPort('soundIsInitialized', $elm$json$Json$Decode$int);
var $author$project$App$Components$Sound$soundIsPaused = _Platform_incomingPort('soundIsPaused', $elm$json$Json$Decode$string);
var $author$project$App$Components$Sound$soundIsPlaying = _Platform_incomingPort('soundIsPlaying', $elm$json$Json$Decode$string);
var $author$project$App$Components$Sound$soundIsStopped = _Platform_incomingPort('soundIsStopped', $elm$json$Json$Decode$string);
var $author$project$App$Components$Sound$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$App$Components$Sound$soundIsInitialized(
			function (v) {
				return $author$project$App$Components$Sound$HasInit(v);
			}),
			$author$project$App$Components$Sound$soundIsPlaying(
			function (snd) {
				return $author$project$App$Components$Sound$Play(snd);
			}),
			$author$project$App$Components$Sound$soundIsPaused(
			function (snd) {
				return $author$project$App$Components$Sound$Pause(snd);
			}),
			$author$project$App$Components$Sound$soundIsStopped(
			function (_v0) {
				return $author$project$App$Components$Sound$Stop;
			})
		]));
var $author$project$App$Page$Reviewer$PageWaitingLine$subscriptions = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Reviewer$PageWaitingLine$SoundMsg, $author$project$App$Components$Sound$subscriptions);
var $author$project$App$Page$Reviewer$PageWaitingProponentDetails$ViewFullStatementMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Reviewer$PageWaitingProponentDetails$subscriptions = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Reviewer$PageWaitingProponentDetails$ViewFullStatementMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$PageReviewer$subscription = function (model) {
	switch (model.$) {
		case 0:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageReviewer$ListIncompleteMsg, $author$project$App$Page$Reviewer$PageListIncomplete$subscription);
		case 1:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageReviewer$ListForReviewMsg, $author$project$App$Page$Reviewer$PageListForReview$subscription);
		case 2:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageReviewer$ListApprovedMsg, $author$project$App$Page$Reviewer$PageListApproved$subscription);
		case 3:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageReviewer$ReviewInterviewMsg, $author$project$App$Page$Reviewer$PageReview$subscription);
		case 4:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageReviewer$EditStatementMsg, $author$project$App$Page$Reviewer$EditStatement$StatementEdit$subscription);
		case 6:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageReviewer$ListReevaluationMsg, $author$project$App$Page$Reviewer$PageListReevaluation$subscriptions);
		case 7:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageReviewer$WaitingLineMsg, $author$project$App$Page$Reviewer$PageWaitingLine$subscriptions);
		case 8:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageReviewer$WaitingProponentDetailsMsg, $author$project$App$Page$Reviewer$PageWaitingProponentDetails$subscriptions);
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$App$Page$PageSupport$EditStatementMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$PageSupport$ListApprovedMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$PageSupport$ListForReviewMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$PageSupport$ListIncompleteMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$PageSupport$ListReevaluationMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$PageSupport$ReviewInterviewMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$ViewFullStmtMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$subscription = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Support$EditStatement$StatementEdit$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Support$PageListApproved$ViewFullStmtMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Support$PageListApproved$subscription = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Support$PageListApproved$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Support$PageListForReview$ViewFullStmtMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Support$PageListForReview$subscription = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Support$PageListForReview$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Support$PageListIncomplete$ViewFullStmtMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Support$PageListIncomplete$subscription = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Support$PageListIncomplete$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Support$PageReview$ViewFullStmtMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Page$Support$PageReview$subscription = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Support$PageReview$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Support$PageListReevaluation$ViewFullStmtMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Support$PageListReevaluation$subscriptions = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Support$PageListReevaluation$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$PageSupport$subscription = function (model) {
	switch (model.$) {
		case 0:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageSupport$ListIncompleteMsg, $author$project$App$Page$Support$PageListIncomplete$subscription);
		case 1:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageSupport$ListForReviewMsg, $author$project$App$Page$Support$PageListForReview$subscription);
		case 2:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageSupport$ListApprovedMsg, $author$project$App$Page$Support$PageListApproved$subscription);
		case 3:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageSupport$ReviewInterviewMsg, $author$project$App$Page$Support$PageReview$subscription);
		case 4:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageSupport$EditStatementMsg, $author$project$App$Page$Support$EditStatement$StatementEdit$subscription);
		case 6:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageSupport$ListReevaluationMsg, $author$project$App$Page$Support$PageListReevaluation$subscriptions);
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$App$Page$PageAdmin$FinanceSalesMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$PageAdmin$ListFinishedMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$PageAdmin$ListReevaluationMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Admin$PageFinanceSales$subscriptions = $elm$core$Platform$Sub$none;
var $author$project$App$Page$Admin$PageListFinished$ViewFullStmtMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Admin$PageListFinished$subscriptions = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Admin$PageListFinished$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Admin$PageListReevaluation$ViewFullStmtMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Admin$PageListReevaluation$subscriptions = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Admin$PageListReevaluation$ViewFullStmtMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$PageAdmin$subscriptions = function (model) {
	switch (model.$) {
		case 2:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageAdmin$FinanceSalesMsg, $author$project$App$Page$Admin$PageFinanceSales$subscriptions);
		case 3:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageAdmin$ListFinishedMsg, $author$project$App$Page$Admin$PageListFinished$subscriptions);
		case 4:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageAdmin$ListReevaluationMsg, $author$project$App$Page$Admin$PageListReevaluation$subscriptions);
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$App$Page$PageDoctor$DoctorSalesMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Page$PageDoctor$FinalEvaluationMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$PageDoctor$InterviewMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$PageDoctor$ReviewMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$PageDoctor$ViewEvaluationMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$PageDoctor$WaitingLineMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Doctor$PageDoctorSales$subscriptions = $elm$core$Platform$Sub$none;
var $author$project$App$Page$Doctor$PageFinalEvaluation$DocumentMsg = function (a) {
	return {$: 18, a: a};
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$NexodataMsg = function (a) {
	return {$: 30, a: a};
};
var $author$project$App$Data$Nexodata$DocumentUrl = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Data$Nexodata$nexodataDocumentUrl = _Platform_incomingPort('nexodataDocumentUrl', $elm$json$Json$Decode$string);
var $author$project$App$Data$Nexodata$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$App$Data$Nexodata$nexodataDocumentUrl(
			function (h) {
				return $author$project$App$Data$Nexodata$DocumentUrl(h);
			})
		]));
var $author$project$App$Page$Doctor$PageFinalEvaluation$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			A2($elm$core$Platform$Sub$map, $author$project$App$Page$Doctor$PageFinalEvaluation$DocumentMsg, $author$project$App$Data$Document$subscriptions),
			A2($elm$core$Platform$Sub$map, $author$project$App$Page$Doctor$PageFinalEvaluation$NexodataMsg, $author$project$App$Data$Nexodata$subscriptions)
		]));
var $author$project$App$Page$Doctor$PageInterview$JitsiIsRecording = function (a) {
	return {$: 23, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$UploadVideoData = function (a) {
	return {$: 22, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$jitsiIsRecording = _Platform_incomingPort('jitsiIsRecording', $elm$json$Json$Decode$bool);
var $author$project$App$Page$Doctor$PageInterview$jitsiRecordingUrl = _Platform_incomingPort(
	'jitsiRecordingUrl',
	A2(
		$elm$json$Json$Decode$andThen,
		function (personId) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (ownerKind) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (ownerId) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (objectUrlRequested) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (objectUrl) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (name) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (mime) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (id) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (docType) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (content) {
																					return $elm$json$Json$Decode$succeed(
																						{iU: content, b7: docType, as: id, dr: mime, hl: name, af: objectUrl, Q: objectUrlRequested, jJ: ownerId, X: ownerKind, Y: personId});
																				},
																				A2($elm$json$Json$Decode$field, 'content', $elm$json$Json$Decode$string));
																		},
																		A2($elm$json$Json$Decode$field, 'docType', $elm$json$Json$Decode$int));
																},
																A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string));
														},
														A2($elm$json$Json$Decode$field, 'mime', $elm$json$Json$Decode$string));
												},
												A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
										},
										A2($elm$json$Json$Decode$field, 'objectUrl', $elm$json$Json$Decode$string));
								},
								A2($elm$json$Json$Decode$field, 'objectUrlRequested', $elm$json$Json$Decode$bool));
						},
						A2($elm$json$Json$Decode$field, 'ownerId', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'ownerKind', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'personId', $elm$json$Json$Decode$string)));
var $author$project$App$Page$Doctor$PageInterview$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$App$Page$Doctor$PageInterview$jitsiRecordingUrl(
			function (h) {
				return $author$project$App$Page$Doctor$PageInterview$UploadVideoData(h);
			}),
			$author$project$App$Page$Doctor$PageInterview$jitsiIsRecording(
			function (h) {
				return $author$project$App$Page$Doctor$PageInterview$JitsiIsRecording(h);
			})
		]));
var $author$project$App$Page$Doctor$PageReview$ViewFullStatementMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Doctor$PageReview$subscriptions = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Doctor$PageReview$ViewFullStatementMsg, $author$project$App$Components$ViewFullStatement$subscriptions);
var $author$project$App$Page$Doctor$PageWaitingLine$SoundMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Page$Doctor$PageWaitingLine$subscriptions = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Doctor$PageWaitingLine$SoundMsg, $author$project$App$Components$Sound$subscriptions);
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$DocumentMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			A2($elm$core$Platform$Sub$map, $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$DocumentMsg, $author$project$App$Data$Document$subscriptions)
		]));
var $author$project$App$Page$PageDoctor$subscriptions = function (model) {
	var _v0 = model.aQ;
	switch (_v0.$) {
		case 1:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageDoctor$WaitingLineMsg, $author$project$App$Page$Doctor$PageWaitingLine$subscriptions);
		case 2:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageDoctor$ReviewMsg, $author$project$App$Page$Doctor$PageReview$subscriptions);
		case 4:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageDoctor$FinalEvaluationMsg, $author$project$App$Page$Doctor$PageFinalEvaluation$subscriptions);
		case 5:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageDoctor$ViewEvaluationMsg, $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$subscriptions);
		case 3:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageDoctor$InterviewMsg, $author$project$App$Page$Doctor$PageInterview$subscriptions);
		case 10:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageDoctor$DoctorSalesMsg, $author$project$App$Page$Doctor$PageDoctorSales$subscriptions);
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$App$Page$PageProponent$ConfirmMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$PageProponent$PaymentMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Proponent$PagePayment$PaymentMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Components$Payment$CardTypeResult = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Components$Payment$HashResult = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Components$Payment$ValidationResult = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Components$Payment$cardType = _Platform_incomingPort('cardType', $elm$json$Json$Decode$string);
var $author$project$App$Components$Payment$hashReturn = _Platform_incomingPort('hashReturn', $elm$json$Json$Decode$string);
var $author$project$App$Components$Payment$validationStatus = _Platform_incomingPort('validationStatus', $elm$json$Json$Decode$bool);
var $author$project$App$Components$Payment$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$App$Components$Payment$hashReturn(
			function (h) {
				return $author$project$App$Components$Payment$HashResult(h);
			}),
			$author$project$App$Components$Payment$validationStatus(
			function (b) {
				return $author$project$App$Components$Payment$ValidationResult(b);
			}),
			$author$project$App$Components$Payment$cardType(
			function (t) {
				return $author$project$App$Components$Payment$CardTypeResult(t);
			})
		]));
var $author$project$App$Page$Proponent$PagePayment$subscription = A2($elm$core$Platform$Sub$map, $author$project$App$Page$Proponent$PagePayment$PaymentMsg, $author$project$App$Components$Payment$subscriptions);
var $author$project$App$Page$Proponent$PageConfirmInterview$JitsiAudioAvailability = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Proponent$PageConfirmInterview$JitsiBrowserSupport = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$Proponent$PageConfirmInterview$JitsiVideoAvailability = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Proponent$PageConfirmInterview$jitsiAudioAvailable = _Platform_incomingPort('jitsiAudioAvailable', $elm$json$Json$Decode$bool);
var $author$project$App$Page$Proponent$PageConfirmInterview$jitsiBrowserSupport = _Platform_incomingPort('jitsiBrowserSupport', $elm$json$Json$Decode$bool);
var $author$project$App$Page$Proponent$PageConfirmInterview$jitsiVideoAvailable = _Platform_incomingPort('jitsiVideoAvailable', $elm$json$Json$Decode$bool);
var $author$project$App$Page$Proponent$PageConfirmInterview$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$App$Page$Proponent$PageConfirmInterview$jitsiVideoAvailable(
			function (h) {
				return $author$project$App$Page$Proponent$PageConfirmInterview$JitsiVideoAvailability(h);
			}),
			$author$project$App$Page$Proponent$PageConfirmInterview$jitsiAudioAvailable(
			function (h) {
				return $author$project$App$Page$Proponent$PageConfirmInterview$JitsiAudioAvailability(h);
			}),
			$author$project$App$Page$Proponent$PageConfirmInterview$jitsiBrowserSupport(
			function (h) {
				return $author$project$App$Page$Proponent$PageConfirmInterview$JitsiBrowserSupport(h);
			})
		]));
var $author$project$App$Page$PageProponent$subscriptions = function (model) {
	var _v0 = model.aQ;
	switch (_v0.$) {
		case 4:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageProponent$PaymentMsg, $author$project$App$Page$Proponent$PagePayment$subscription);
		case 8:
			return A2($elm$core$Platform$Sub$map, $author$project$App$Page$PageProponent$ConfirmMsg, $author$project$App$Page$Proponent$PageConfirmInterview$subscriptions);
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$App$Components$Video$AvailableDevices = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Components$Video$HasStopped = {$: 10};
var $author$project$App$Components$Video$InitFailure = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Components$Video$InitSuccess = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Components$Video$RemoteConnected = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Components$Video$RemoteDisconnected = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Components$Video$RemoteMediaStatus = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$App$Components$Video$RemotePlaying = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Components$Video$RemoteReadyToPlay = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Components$Video$RemoteStatus = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$App$Components$Video$SelfieTaken = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Components$Video$Device = F5(
	function (system, browser, version, mediaDevices, supported) {
		return {go: browser, a9: mediaDevices, fY: supported, dO: system, iB: version};
	});
var $elm$json$Json$Decode$map5 = _Json_map5;
var $author$project$App$Components$Video$MediaDeviceInfo = F4(
	function (deviceId, groupId, kind, label) {
		return {c1: deviceId, i7: groupId, hb: kind, jh: label};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$App$Components$Video$mediaDeviceDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$App$Components$Video$MediaDeviceInfo,
	A2($elm$json$Json$Decode$field, 'deviceId', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'groupId', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'kind', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'label', $elm$json$Json$Decode$string));
var $author$project$App$Components$Video$deviceDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$App$Components$Video$Device,
	A2($elm$json$Json$Decode$field, 'system', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'browser', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'version', $elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$field,
		'mediaDevices',
		$elm$json$Json$Decode$list($author$project$App$Components$Video$mediaDeviceDecoder)),
	A2($elm$json$Json$Decode$field, 'supported', $elm$json$Json$Decode$bool));
var $author$project$App$Components$Video$notifyAvailableDevices = _Platform_incomingPort('notifyAvailableDevices', $elm$json$Json$Decode$string);
var $author$project$App$Components$Video$notifyStopped = _Platform_incomingPort('notifyStopped', $elm$json$Json$Decode$bool);
var $author$project$App$Components$Video$receiveError = _Platform_incomingPort('receiveError', $elm$json$Json$Decode$string);
var $author$project$App$Components$Video$videoEnabled = _Platform_incomingPort('videoEnabled', $elm$json$Json$Decode$string);
var $author$project$App$Components$Video$videoInformPlaying = _Platform_incomingPort('videoInformPlaying', $elm$json$Json$Decode$string);
var $author$project$App$Components$Video$videoInformStatus = _Platform_incomingPort(
	'videoInformStatus',
	A2(
		$elm$json$Json$Decode$andThen,
		function (status) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (mediaId) {
					return $elm$json$Json$Decode$succeed(
						{cn: mediaId, aw: status});
				},
				A2($elm$json$Json$Decode$field, 'mediaId', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'status', $elm$json$Json$Decode$int)));
var $author$project$App$Components$Video$MediaStatusChecking = 2;
var $author$project$App$Components$Video$MediaStatusClosed = 7;
var $author$project$App$Components$Video$MediaStatusCompleted = 4;
var $author$project$App$Components$Video$MediaStatusConnected = 3;
var $author$project$App$Components$Video$MediaStatusDisconnected = 5;
var $author$project$App$Components$Video$MediaStatusFailed = 6;
var $author$project$App$Components$Video$MediaStatusInvalid = 0;
var $author$project$App$Components$Video$MediaStatusNew = 1;
var $author$project$App$Components$Video$videoMediaStatusFromString = function (str) {
	switch (str) {
		case 'new':
			return 1;
		case 'checking':
			return 2;
		case 'connected':
			return 3;
		case 'completed':
			return 4;
		case 'disconnected':
			return 5;
		case 'failed':
			return 6;
		case 'closed':
			return 7;
		default:
			return 0;
	}
};
var $author$project$App$Components$Video$videoReadyToPlay = _Platform_incomingPort('videoReadyToPlay', $elm$json$Json$Decode$string);
var $author$project$App$Components$Video$videoRemoteConnected = _Platform_incomingPort('videoRemoteConnected', $elm$json$Json$Decode$string);
var $author$project$App$Components$Video$videoRemoteDisconnected = _Platform_incomingPort('videoRemoteDisconnected', $elm$json$Json$Decode$string);
var $author$project$App$Components$Video$videoRemoteMediaStatus = _Platform_incomingPort(
	'videoRemoteMediaStatus',
	A2(
		$elm$json$Json$Decode$andThen,
		function (status) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (mediaId) {
					return $elm$json$Json$Decode$succeed(
						{cn: mediaId, aw: status});
				},
				A2($elm$json$Json$Decode$field, 'mediaId', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'status', $elm$json$Json$Decode$string)));
var $author$project$App$Components$Video$videoSelfieTaken = _Platform_incomingPort('videoSelfieTaken', $elm$json$Json$Decode$string);
var $author$project$App$Components$Video$StatusInvalid = 0;
var $author$project$App$Components$Video$StatusPaused = 1;
var $author$project$App$Components$Video$StatusPlaying = 2;
var $author$project$App$Components$Video$videoStatusFromInt = function (i) {
	switch (i) {
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 0;
	}
};
var $author$project$App$Components$Video$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$App$Components$Video$receiveError(
			function (msg) {
				return $author$project$App$Components$Video$InitFailure(msg);
			}),
			$author$project$App$Components$Video$videoEnabled(
			function (id) {
				return $author$project$App$Components$Video$InitSuccess(id);
			}),
			$author$project$App$Components$Video$notifyAvailableDevices(
			A2(
				$elm$core$Basics$composeR,
				$elm$json$Json$Decode$decodeString($author$project$App$Components$Video$deviceDecoder),
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Result$withDefault($author$project$App$Components$Video$emptyDevice),
					$author$project$App$Components$Video$AvailableDevices))),
			$author$project$App$Components$Video$notifyStopped(
			function (_v0) {
				return $author$project$App$Components$Video$HasStopped;
			}),
			$author$project$App$Components$Video$videoRemoteConnected(
			function (id) {
				return $author$project$App$Components$Video$RemoteConnected(id);
			}),
			$author$project$App$Components$Video$videoRemoteDisconnected(
			function (id) {
				return $author$project$App$Components$Video$RemoteDisconnected(id);
			}),
			$author$project$App$Components$Video$videoReadyToPlay(
			function (id) {
				return $author$project$App$Components$Video$RemoteReadyToPlay(id);
			}),
			$author$project$App$Components$Video$videoInformPlaying(
			function (id) {
				return $author$project$App$Components$Video$RemotePlaying(id);
			}),
			$author$project$App$Components$Video$videoInformStatus(
			function (status) {
				return A2(
					$author$project$App$Components$Video$RemoteStatus,
					status.cn,
					$author$project$App$Components$Video$videoStatusFromInt(status.aw));
			}),
			$author$project$App$Components$Video$videoRemoteMediaStatus(
			function (status) {
				return A2(
					$author$project$App$Components$Video$RemoteMediaStatus,
					status.cn,
					$author$project$App$Components$Video$videoMediaStatusFromString(status.aw));
			}),
			$author$project$App$Components$Video$videoSelfieTaken(
			function (content) {
				return $author$project$App$Components$Video$SelfieTaken(content);
			})
		]));
var $author$project$App$Service$ApiWebSocket$Tick = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Service$ApiWebSocket$TokenFromStorage = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Service$ApiWebSocket$InternalTokenRecord = F3(
	function (name, time, value) {
		return {hl: name, il: time, T: value};
	});
var $author$project$App$Service$ApiWebSocket$decodeInternalToken = A2(
	$elm$json$Json$Decode$map,
	function (token) {
		var _v0 = token.il;
		if (!_v0.$) {
			if (!_v0.a) {
				return A3($author$project$App$Service$ApiWebSocket$InternalToken, token.hl, $elm$core$Maybe$Nothing, token.T);
			} else {
				var t = _v0.a;
				return A3(
					$author$project$App$Service$ApiWebSocket$InternalToken,
					token.hl,
					$elm$core$Maybe$Just(
						$elm$time$Time$millisToPosix(t)),
					token.T);
			}
		} else {
			return A3($author$project$App$Service$ApiWebSocket$InternalToken, token.hl, $elm$core$Maybe$Nothing, token.T);
		}
	},
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'value',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$value),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'time',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'name',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$App$Service$ApiWebSocket$InternalTokenRecord)))));
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {hE: processes, ij: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.hE;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.ij);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$App$Service$ApiWebSocket$sessionTokenLoaded = _Platform_incomingPort('sessionTokenLoaded', $elm$json$Json$Decode$value);
var $author$project$App$Service$Websocket$Connected = {$: 0};
var $author$project$App$Service$Websocket$Data = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Service$Websocket$Disconnected = {$: 1};
var $author$project$App$Service$Websocket$Error = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Service$Websocket$websocketConnected = _Platform_incomingPort('websocketConnected', $elm$json$Json$Decode$value);
var $author$project$App$Service$Websocket$websocketDisconnected = _Platform_incomingPort('websocketDisconnected', $elm$json$Json$Decode$value);
var $author$project$App$Service$Websocket$websocketError = _Platform_incomingPort('websocketError', $elm$json$Json$Decode$string);
var $author$project$App$Service$Websocket$websocketIn = _Platform_incomingPort('websocketIn', $elm$json$Json$Decode$string);
var $author$project$App$Service$Websocket$subscription = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$App$Service$Websocket$websocketConnected(
			function (_v0) {
				return $author$project$App$Service$Websocket$Connected;
			}),
			$author$project$App$Service$Websocket$websocketDisconnected(
			function (_v1) {
				return $author$project$App$Service$Websocket$Disconnected;
			}),
			$author$project$App$Service$Websocket$websocketError($author$project$App$Service$Websocket$Error),
			$author$project$App$Service$Websocket$websocketIn($author$project$App$Service$Websocket$Data)
		]));
var $author$project$App$Service$ApiWebSocket$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			A2($elm$core$Platform$Sub$map, $author$project$App$Service$ApiWebSocket$WebsocketMsg, $author$project$App$Service$Websocket$subscription),
			$author$project$App$Service$ApiWebSocket$sessionTokenLoaded(
			function (t) {
				return A2(
					$elm$core$Result$withDefault,
					$author$project$App$Service$ApiWebSocket$TokenFromStorage(
						A3($author$project$App$Service$ApiWebSocket$InternalToken, '', $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)),
					A2(
						$elm$core$Result$map,
						$author$project$App$Service$ApiWebSocket$TokenFromStorage,
						A2($elm$json$Json$Decode$decodeValue, $author$project$App$Service$ApiWebSocket$decodeInternalToken, t)));
			}),
			A2($elm$time$Time$every, 1000, $author$project$App$Service$ApiWebSocket$Tick)
		]));
var $author$project$App$SharedState$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			A2($elm$core$Platform$Sub$map, $author$project$App$SharedState$VideoCmd, $author$project$App$Components$Video$subscriptions),
			A2($elm$core$Platform$Sub$map, $author$project$App$SharedState$APICmd, $author$project$App$Service$ApiWebSocket$subscriptions)
		]));
var $author$project$App$Main$subscriptions = function (m) {
	var pageSub = function () {
		var _v0 = m.aQ;
		switch (_v0.$) {
			case 12:
				var pageModel = _v0.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$App$Main$CompanyMsg,
					$author$project$App$Page$PageCompany$subscription(pageModel));
			case 11:
				var pageModel = _v0.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$App$Main$DoctorMsg,
					$author$project$App$Page$PageDoctor$subscriptions(pageModel));
			case 10:
				var pageModel = _v0.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$App$Main$ProponentMsg,
					$author$project$App$Page$PageProponent$subscriptions(pageModel));
			case 13:
				var pageModel = _v0.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$App$Main$ReviewMsg,
					$author$project$App$Page$PageReviewer$subscription(pageModel));
			case 14:
				var pageModel = _v0.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$App$Main$SupportMsg,
					$author$project$App$Page$PageSupport$subscription(pageModel));
			case 15:
				var pageModel = _v0.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$App$Main$AdminMsg,
					$author$project$App$Page$PageAdmin$subscriptions(pageModel));
			default:
				return $elm$core$Platform$Sub$none;
		}
	}();
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				pageSub,
				A2($elm$core$Platform$Sub$map, $author$project$App$Main$SharedStateMsg, $author$project$App$SharedState$subscriptions)
			]));
};
var $author$project$App$Main$Admin = function (a) {
	return {$: 15, a: a};
};
var $author$project$App$Main$Company = function (a) {
	return {$: 12, a: a};
};
var $author$project$App$Main$Doctor = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Main$DoctorResume = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Main$DoctorResumeMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Main$DoctorSignUp = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Main$DoctorSignUpMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Main$FinanceSales = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Main$FinanceSalesMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Main$Login = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Main$LoginMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Main$Review = function (a) {
	return {$: 13, a: a};
};
var $author$project$App$Main$Support = function (a) {
	return {$: 14, a: a};
};
var $author$project$App$SharedState$UpdateZone = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Main$UserRequestRecoverPassword = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Main$UserRequestRecoverPasswordMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$App$Main$UserSignUp = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Main$UserSignUpComplete = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Main$UserSignUpCompleteMsg = function (a) {
	return {$: 15, a: a};
};
var $author$project$App$Main$UserSignUpMsg = function (a) {
	return {$: 12, a: a};
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $author$project$App$Page$ResponseMsgs$ClientLoginSearchedByDocumentAndBirthDate = {$: 1};
var $author$project$App$Data$Contact$FieldValue = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$SharedState$GetStatementFromDocumentAndBirthDate = F4(
	function (a, b, c, d) {
		return {$: 112, a: a, b: b, c: c, d: d};
	});
var $author$project$App$SharedState$SubmitFullStatementFromProponent = F3(
	function (a, b, c) {
		return {$: 113, a: a, b: b, c: c};
	});
var $author$project$App$Page$Client$PageClient$Submitting = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$TriedSubmitFullStatementFromProponent = {$: 2};
var $author$project$App$Page$Client$PageClient$brDateToPosix = function (dateString) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$time$Time$millisToPosix(0),
		$elm$core$Result$toMaybe(
			$rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(
				A2(
					$elm$core$String$join,
					'-',
					$elm$core$List$reverse(
						A2($elm$core$String$split, '/', dateString))))));
};
var $author$project$App$Data$Statement$formatHeight = function (h) {
	var cm = $elm$core$String$fromInt(h);
	var len = $elm$core$String$length(cm);
	var m = (h >= 100) ? A3($elm$core$String$slice, 0, -2, cm) : '0';
	var subM = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cm)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cm) : cm);
	return (h > 0) ? (m + (',' + (subM + ' m'))) : '';
};
var $author$project$App$Page$Client$PageClient$getContactWithKind = F2(
	function (kind, dict) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Contact$emptyModel(kind),
			A2(
				$elm$core$Dict$get,
				$author$project$App$Data$Contact$kindToString(kind),
				dict));
	});
var $author$project$App$Page$Client$PageClient$maskStringHelper = F3(
	function (pattern, value, result) {
		maskStringHelper:
		while (true) {
			var valueUncons = $elm$core$String$uncons(value);
			if (!valueUncons.$) {
				var _v1 = valueUncons.a;
				var charValue = _v1.a;
				var rest = _v1.b;
				var _v2 = $elm$core$String$uncons(pattern);
				if (!_v2.$) {
					var _v3 = _v2.a;
					var a = _v3.a;
					var b = _v3.b;
					if (a === '#') {
						var $temp$pattern = b,
							$temp$value = rest,
							$temp$result = _Utils_ap(
							result,
							$elm$core$String$fromChar(charValue));
						pattern = $temp$pattern;
						value = $temp$value;
						result = $temp$result;
						continue maskStringHelper;
					} else {
						var $temp$pattern = b,
							$temp$value = value,
							$temp$result = _Utils_ap(
							result,
							$elm$core$String$fromChar(a));
						pattern = $temp$pattern;
						value = $temp$value;
						result = $temp$result;
						continue maskStringHelper;
					}
				} else {
					return result;
				}
			} else {
				return result;
			}
		}
	});
var $author$project$App$Page$Client$PageClient$maskStringNumber = F2(
	function (pattern, value) {
		return function (v) {
			return A3($author$project$App$Page$Client$PageClient$maskStringHelper, pattern, v, '');
		}(
			A2($elm$core$String$filter, $elm$core$Char$isDigit, value));
	});
var $author$project$App$Data$Contact$Commercial = 4;
var $author$project$App$Data$Contact$InvalidKind = 0;
var $author$project$App$Data$Contact$Residential = 3;
var $author$project$App$Data$Contact$WhatsApp = 2;
var $author$project$App$Data$Contact$kindFromString = function (str) {
	switch (str) {
		case 'email':
			return 5;
		case 'mobile':
			return 1;
		case 'whatsapp':
			return 2;
		case 'residential':
			return 3;
		case 'commercial':
			return 4;
		default:
			return 0;
	}
};
var $author$project$App$Data$Contact$updateModel = F2(
	function (field, model) {
		if (!field.$) {
			var kind = field.a;
			return _Utils_update(
				model,
				{
					hb: $author$project$App$Data$Contact$kindFromString(kind)
				});
		} else {
			var value = field.a;
			return _Utils_update(
				model,
				{T: value});
		}
	});
var $author$project$App$Page$Client$PageClient$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				var field = msg.a;
				switch (field.$) {
					case 0:
						var document = field.a;
						var newDocument = A2($author$project$App$Page$Client$PageClient$maskStringNumber, '###.###.###-##', document);
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{i1: newDocument}),
							$elm$core$Platform$Cmd$none,
							_List_Nil);
					case 1:
						var birthDate = field.a;
						var newBirthDate = A2($author$project$App$Page$Client$PageClient$maskStringNumber, '##/##/####', birthDate);
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{al: newBirthDate}),
							$elm$core$Platform$Cmd$none,
							_List_Nil);
					case 3:
						var surName = field.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{cI: surName}),
							$elm$core$Platform$Cmd$none,
							_List_Nil);
					case 2:
						var name = field.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{hl: name}),
							$elm$core$Platform$Cmd$none,
							_List_Nil);
					case 4:
						var motherName = field.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{cp: motherName}),
							$elm$core$Platform$Cmd$none,
							_List_Nil);
					case 5:
						var gender = field.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{db: gender}),
							$elm$core$Platform$Cmd$none,
							_List_Nil);
					case 6:
						var weight = field.a;
						var formatStringWeight = A2(
							$elm$core$Basics$composeR,
							$elm$core$String$toInt,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$Maybe$withDefault(
									A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$String$toInt(model.iD))),
								function (w) {
									return (w > 0) ? ($elm$core$String$fromInt(w) + ' kg') : '';
								}));
						var newWeight = _Utils_eq(
							$elm$core$String$length(weight),
							$elm$core$String$length(model.iD) - 1) ? formatStringWeight(
							A2(
								$elm$core$String$dropRight,
								1,
								A2($elm$core$String$filter, $elm$core$Char$isDigit, model.iD))) : formatStringWeight(
							A2($elm$core$String$filter, $elm$core$Char$isDigit, weight));
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{iD: newWeight}),
							$elm$core$Platform$Cmd$none,
							_List_Nil);
					case 7:
						var height = field.a;
						var formatStringHeight = A2(
							$elm$core$Basics$composeR,
							$elm$core$String$toInt,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$Maybe$withDefault(
									A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$String$toInt(model.g$))),
								$author$project$App$Data$Statement$formatHeight));
						var newHeight = _Utils_eq(
							$elm$core$String$length(height),
							$elm$core$String$length(model.g$) - 1) ? formatStringHeight(
							A2(
								$elm$core$String$dropRight,
								1,
								A2($elm$core$String$filter, $elm$core$Char$isDigit, model.g$))) : formatStringHeight(
							A2($elm$core$String$filter, $elm$core$Char$isDigit, height));
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{g$: newHeight}),
							$elm$core$Platform$Cmd$none,
							_List_Nil);
					case 8:
						var email = field.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{c7: email}),
							$elm$core$Platform$Cmd$none,
							_List_Nil);
					default:
						var phone = field.a;
						var newPhone = A2($author$project$App$Page$Client$PageClient$maskStringNumber, '(##) # ####-####', phone);
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{dx: newPhone}),
							$elm$core$Platform$Cmd$none,
							_List_Nil);
				}
			case 2:
				var formName = msg.a;
				var fullStatement = model.P;
				var bearer = fullStatement.g;
				var email = A2($author$project$App$Page$Client$PageClient$getContactWithKind, 5, bearer.ea);
				var updatedEmail = A2(
					$author$project$App$Data$Contact$updateModel,
					$author$project$App$Data$Contact$FieldValue(model.c7),
					email);
				var person = bearer.dw;
				var phone = A2($author$project$App$Page$Client$PageClient$getContactWithKind, 1, bearer.ea);
				var updatedPhone = A2(
					$author$project$App$Data$Contact$updateModel,
					$author$project$App$Data$Contact$FieldValue(model.dx),
					phone);
				var bdPosix = $author$project$App$Page$Client$PageClient$brDateToPosix(model.al);
				var newFullStatement = _Utils_update(
					fullStatement,
					{
						g: _Utils_update(
							bearer,
							{
								ea: A3(
									$elm$core$Dict$insert,
									$author$project$App$Data$Contact$kindToString(1),
									updatedPhone,
									A3(
										$elm$core$Dict$insert,
										$author$project$App$Data$Contact$kindToString(5),
										updatedEmail,
										bearer.ea)),
								dw: _Utils_update(
									person,
									{
										al: bdPosix,
										i1: model.i1,
										cp: model.cp,
										hl: model.hl,
										dJ: $author$project$App$Data$Person$sexFromString(model.db),
										B: model.hl + (' ' + model.cI),
										ii: model.cI
									})
							})
					});
				var sharedMsgs = function () {
					if (!formName) {
						return A2(
							$elm$core$List$map,
							$author$project$App$SharedState$APIMsg,
							_List_fromArray(
								[
									A4(
									$author$project$App$SharedState$GetStatementFromDocumentAndBirthDate,
									model.i1,
									model.al,
									model.eY,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$ClientMsg($author$project$App$Page$ResponseMsgs$ClientLoginSearchedByDocumentAndBirthDate)
										]))
								]));
					} else {
						return A2(
							$elm$core$List$map,
							$author$project$App$SharedState$APIMsg,
							_List_fromArray(
								[
									A3(
									$author$project$App$SharedState$SubmitFullStatementFromProponent,
									newFullStatement,
									model.eY,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$ClientMsg($author$project$App$Page$ResponseMsgs$TriedSubmitFullStatementFromProponent)
										]))
								]));
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							cH: $author$project$App$Page$Client$PageClient$Submitting(formName)
						}),
					$elm$core$Platform$Cmd$none,
					sharedMsgs);
			default:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aw: 0}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
		}
	});
var $author$project$App$Page$Finances$PageDoctorResume$update = F3(
	function (msg, st, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$Page$Finances$PageFinanceSales$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Public($author$project$App$Route$Login)),
					_List_Nil);
			case 1:
				var m = msg.a;
				var o = msg.b;
				var number = (!(m % $elm$core$List$length(model.w))) ? $elm$core$List$length(model.w) : (m % $elm$core$List$length(model.w));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{m: number}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 2:
				var monthNumber = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							w: A2(
								$elm$core$List$map,
								function (m) {
									return _Utils_eq(m.hk, monthNumber) ? _Utils_update(
										m,
										{bi: 0}) : m;
								},
								model.w)
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 3:
				var monthNumber = msg.a;
				var salesType = msg.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							w: A2(
								$elm$core$List$map,
								function (m) {
									return _Utils_eq(m.hk, monthNumber) ? _Utils_update(
										m,
										{bi: salesType}) : m;
								},
								model.w)
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			default:
				var rowNumber = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{fR: rowNumber + 4}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
		}
	});
var $author$project$App$Page$PageAdmin$FinanceSales = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$PageAdmin$ListDoctors = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$PageAdmin$ListDoctorsMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$PageAdmin$ListFinished = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$PageAdmin$ListReevaluation = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$PageAdmin$ListUsers = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$PageAdmin$ListUsersMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$PageAdmin$Reevaluation = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$PageAdmin$ReevaluationMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$PageAdmin$ViewEvaluation = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$PageAdmin$ViewEvaluationMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedCompanyReportCsv = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedCompanyReportPdf = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedDetails = F5(
	function (a, b, c, d, e) {
		return {$: 3, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedFilteredDetails = F5(
	function (a, b, c, d, e) {
		return {$: 4, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedMedsynDetails = F6(
	function (a, b, c, d, e, f) {
		return {$: 5, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedMedsynFilteredDetails = F6(
	function (a, b, c, d, e, f) {
		return {$: 6, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$AdminMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$SharedState$CreateCompanyReportCsv = F6(
	function (a, b, c, d, e, f) {
		return {$: 98, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $author$project$App$SharedState$CreateCompanyReportPdf = F6(
	function (a, b, c, d, e, f) {
		return {$: 99, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $author$project$App$SharedState$LoadCompanyReport = F6(
	function (a, b, c, d, e, f) {
		return {$: 96, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $author$project$App$SharedState$LoadMedsynCompanyReport = F7(
	function (a, b, c, d, e, f, g) {
		return {$: 97, a: a, b: b, c: c, d: d, e: e, f: f, g: g};
	});
var $author$project$App$SharedState$LoadMedsynMonthDetails = F5(
	function (a, b, c, d, e) {
		return {$: 94, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$App$SharedState$LoadMonthDetails = F4(
	function (a, b, c, d) {
		return {$: 93, a: a, b: b, c: c, d: d};
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $author$project$App$Components$ReportFilter$empty = {aX: $elm$core$Maybe$Nothing, bq: _List_Nil, bu: $elm$core$Maybe$Nothing, gJ: _List_Nil, bw: $elm$core$Maybe$Nothing, fz: $author$project$App$Data$Id$uuidNil, aH: _List_Nil, cB: $author$project$App$Data$Id$uuidNil, hZ: $author$project$App$Data$Id$uuidNil, fT: $author$project$App$Data$Id$uuidNil, bK: false, bN: $elm$core$Maybe$Nothing, cJ: $elm$core$Maybe$Nothing, eZ: _List_Nil};
var $author$project$App$Components$ReportFilter$generateQuery = function (filter) {
	var templateFilter = 'template=' + A2($elm$core$Maybe$withDefault, '', filter.cJ);
	var doctorFilter = '?doctor=' + A2($elm$core$Maybe$withDefault, '', filter.bu);
	return A2(
		$elm$core$String$join,
		'&',
		A2(
			$elm$core$List$filter,
			function (r) {
				return $elm$core$String$length(r) > 0;
			},
			_List_fromArray(
				[doctorFilter, templateFilter])));
};
var $author$project$App$Components$ReportFilter$update = F2(
	function (field, filter) {
		switch (field.$) {
			case 0:
				var date = field.a;
				var d = function () {
					if (date === '') {
						return $elm$core$Maybe$Nothing;
					} else {
						return $elm$core$Maybe$Just(date);
					}
				}();
				var da = A2(
					$elm$core$Maybe$andThen,
					function (str) {
						return $elm$core$Maybe$Just(
							A2($author$project$App$Tools$FormHelpers$maskStringNumber, '##/##/####', str));
					},
					d);
				return _Utils_Tuple2(
					_Utils_update(
						filter,
						{bN: da}),
					false);
			case 1:
				var date = field.a;
				var d = function () {
					if (date === '') {
						return $elm$core$Maybe$Nothing;
					} else {
						return $elm$core$Maybe$Just(date);
					}
				}();
				var da = A2(
					$elm$core$Maybe$andThen,
					function (str) {
						return $elm$core$Maybe$Just(
							A2($author$project$App$Tools$FormHelpers$maskStringNumber, '##/##/####', str));
					},
					d);
				return _Utils_Tuple2(
					_Utils_update(
						filter,
						{bw: da}),
					false);
			case 2:
				var text = field.a;
				var t = function () {
					if (text === '') {
						return $elm$core$Maybe$Nothing;
					} else {
						return $elm$core$Maybe$Just(text);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						filter,
						{bu: t}),
					false);
			case 3:
				var templateId = field.a;
				var text = A2(
					$elm$core$Maybe$withDefault,
					'',
					$elm$core$List$head(
						A2(
							$elm$core$List$map,
							function (st) {
								return st.hl;
							},
							A2(
								$elm$core$List$filter,
								function (st) {
									return _Utils_eq(st.as, templateId);
								},
								filter.eZ))));
				var t = function () {
					if (text === '') {
						return $elm$core$Maybe$Nothing;
					} else {
						return $elm$core$Maybe$Just(text);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						filter,
						{cJ: t}),
					false);
			case 4:
				var b = field.a;
				return _Utils_Tuple2(
					_Utils_update(
						filter,
						{bK: b}),
					false);
			case 5:
				var rs = field.a;
				return _Utils_Tuple2(
					_Utils_update(
						filter,
						{aH: rs}),
					false);
			case 6:
				return _Utils_Tuple2(
					_Utils_update(
						$author$project$App$Components$ReportFilter$empty,
						{bq: filter.bq, aH: filter.aH, cB: filter.cB}),
					true);
			case 7:
				return _Utils_Tuple2(
					_Utils_update(
						filter,
						{
							aX: $elm$core$Maybe$Just(
								$author$project$App$Components$ReportFilter$generateQuery(filter)),
							bK: false
						}),
					true);
			default:
				var cId = field.a;
				var newCid = _Utils_eq(cId, $author$project$App$Data$Id$uuidNil) ? filter.fz : cId;
				return _Utils_Tuple2(
					_Utils_update(
						filter,
						{cB: newCid}),
					false);
		}
	});
var $author$project$App$Page$Admin$PageFinanceSales$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				var m = msg.a;
				var y = msg.b;
				var o = msg.c;
				var _v1 = A2(
					$elm$core$List$any,
					function (ms) {
						return _Utils_eq(ms.hk, m) && _Utils_eq(ms.iF, y);
					},
					model.w) ? _Utils_Tuple2(m, y) : (A2(
					$elm$core$List$any,
					function (ms) {
						return ((_Utils_cmp(ms.hk, m) > -1) && _Utils_eq(ms.iF, y)) || (_Utils_cmp(ms.iF, y) > 0);
					},
					model.w) ? A3(
					$elm$core$List$foldl,
					F2(
						function (ms, a) {
							return (((_Utils_cmp(ms.hk, a.a) < 1) && _Utils_eq(ms.iF, a.b)) || (_Utils_cmp(ms.iF, a.b) < 0)) ? _Utils_Tuple2(ms.hk, ms.iF) : a;
						}),
					_Utils_Tuple2(model.m, model.o),
					model.w) : A3(
					$elm$core$List$foldl,
					F2(
						function (ms, a) {
							return (((_Utils_cmp(ms.hk, a.a) > 0) && _Utils_eq(ms.iF, a.b)) || (_Utils_cmp(ms.iF, a.b) > 0)) ? _Utils_Tuple2(ms.hk, ms.iF) : a;
						}),
					_Utils_Tuple2(model.m, model.o),
					model.w));
				var newMonth = _v1.a;
				var newYear = _v1.b;
				var endDate = (m === 12) ? ($elm$core$String$fromInt(newYear + 1) + ('-' + '01-01')) : ($elm$core$String$fromInt(newYear) + ((((newMonth + 1) >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(newMonth + 1) + '-01')));
				var startDate = $elm$core$String$fromInt(newYear) + (((newMonth >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(newMonth) + '-01'));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{m: newMonth, o: newYear}),
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A4(
								$author$project$App$SharedState$LoadMonthDetails,
								model.gx,
								startDate,
								endDate,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg(
											A5($author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedDetails, model.gx, startDate, endDate, model.o, model.m)))
									]))
							])));
			case 1:
				var monthNumber = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							w: A2(
								$elm$core$List$map,
								function (m) {
									return _Utils_eq(m.hk, monthNumber) ? _Utils_update(
										m,
										{bi: 0}) : m;
								},
								model.w)
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 2:
				var monthNumber = msg.a;
				var salesType = msg.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							w: A2(
								$elm$core$List$map,
								function (m) {
									return _Utils_eq(m.hk, monthNumber) ? _Utils_update(
										m,
										{bi: salesType}) : m;
								},
								model.w)
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 3:
				var rowNumber = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{fR: rowNumber + 4}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 8:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dS: !model.dS}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 6:
				var sharedMsgs = function () {
					if ((!model.m) && (!model.o)) {
						var _v2 = _Utils_Tuple2(
							A2(
								$elm$core$String$join,
								'-',
								$elm$core$List$reverse(
									A2(
										$elm$core$String$split,
										'/',
										A2($elm$core$Maybe$withDefault, '', model.b.bN)))),
							A2(
								$elm$core$String$join,
								'-',
								$elm$core$List$reverse(
									A2(
										$elm$core$String$split,
										'/',
										A2($elm$core$Maybe$withDefault, '', model.b.bw)))));
						var startDate = _v2.a;
						var endDate = _v2.b;
						return _List_fromArray(
							[
								A6(
								$author$project$App$SharedState$CreateCompanyReportCsv,
								model.gx,
								startDate,
								endDate,
								A2($elm$core$Maybe$withDefault, '', model.b.bu),
								A2($elm$core$Maybe$withDefault, '', model.b.cJ),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg(
											$author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedCompanyReportCsv(model.gx)))
									]))
							]);
					} else {
						var sDate = $elm$core$String$fromInt(model.o) + (((model.m >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(model.m) + '-01'));
						var eDate = (model.m === 12) ? ($elm$core$String$fromInt(model.o + 1) + ('-' + '01-01')) : ($elm$core$String$fromInt(model.o) + ((((model.m + 1) >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(model.m + 1) + '-01')));
						return _List_fromArray(
							[
								A6(
								$author$project$App$SharedState$CreateCompanyReportCsv,
								model.gx,
								sDate,
								eDate,
								'',
								'',
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg(
											$author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedCompanyReportCsv(model.gx)))
									]))
							]);
					}
				}();
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsgs));
			case 5:
				var sharedMsgs = function () {
					if ((!model.m) && (!model.o)) {
						var _v3 = _Utils_Tuple2(
							A2(
								$elm$core$String$join,
								'-',
								$elm$core$List$reverse(
									A2(
										$elm$core$String$split,
										'/',
										A2($elm$core$Maybe$withDefault, '', model.b.bN)))),
							A2(
								$elm$core$String$join,
								'-',
								$elm$core$List$reverse(
									A2(
										$elm$core$String$split,
										'/',
										A2($elm$core$Maybe$withDefault, '', model.b.bw)))));
						var startDate = _v3.a;
						var endDate = _v3.b;
						return _List_fromArray(
							[
								A6(
								$author$project$App$SharedState$CreateCompanyReportPdf,
								model.gx,
								startDate,
								endDate,
								A2($elm$core$Maybe$withDefault, '', model.b.bu),
								A2($elm$core$Maybe$withDefault, '', model.b.cJ),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg(
											$author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedCompanyReportPdf(model.gx)))
									]))
							]);
					} else {
						var sDate = $elm$core$String$fromInt(model.o) + (((model.m >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(model.m) + '-01'));
						var eDate = (model.m === 12) ? ($elm$core$String$fromInt(model.o + 1) + ('-' + '01-01')) : ($elm$core$String$fromInt(model.o) + ((((model.m + 1) >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(model.m + 1) + '-01')));
						return _List_fromArray(
							[
								A6(
								$author$project$App$SharedState$CreateCompanyReportPdf,
								model.gx,
								sDate,
								eDate,
								'',
								'',
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg(
											$author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedCompanyReportPdf(model.gx)))
									]))
							]);
					}
				}();
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsgs));
			case 4:
				var filterMsg = msg.a;
				var _v4 = _Utils_Tuple2(
					A2(
						$elm$core$String$join,
						'-',
						$elm$core$List$reverse(
							A2(
								$elm$core$String$split,
								'/',
								A2($elm$core$Maybe$withDefault, '', model.b.bN)))),
					A2(
						$elm$core$String$join,
						'-',
						$elm$core$List$reverse(
							A2(
								$elm$core$String$split,
								'/',
								A2($elm$core$Maybe$withDefault, '', model.b.bw)))));
				var startDate = _v4.a;
				var endDate = _v4.b;
				var _v5 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$ReportFilter$update, filterMsg, model.b));
				var updatedModel = _v5.a;
				var apply = _v5.b;
				var uModel = apply ? _Utils_update(
					updatedModel,
					{gx: updatedModel.b.cB}) : updatedModel;
				var _v6 = (apply && (_Utils_eq(uModel.b.bN, $elm$core$Maybe$Nothing) && _Utils_eq(uModel.b.bw, $elm$core$Maybe$Nothing))) ? _Utils_Tuple2(uModel.ax, uModel.aW) : _Utils_Tuple2(uModel.m, uModel.o);
				var updatedMonth = _v6.a;
				var updatedYear = _v6.b;
				var defaultMonthlySale = {
					b1: $elm$core$Maybe$Nothing,
					gL: $elm$core$Maybe$Nothing,
					hk: uModel.ax,
					aR: $elm$core$Maybe$Nothing,
					S: _List_fromArray(
						[
							{gD: _List_Nil, at: 0, L: 0, hl: 'Atendimentos', au: 1}
						]),
					bi: 0,
					ai: 0,
					iq: 0,
					iF: uModel.aW
				};
				var newMonthlySales = (apply && (_Utils_eq(uModel.b.bN, $elm$core$Maybe$Nothing) && _Utils_eq(uModel.b.bw, $elm$core$Maybe$Nothing))) ? A2(
					$elm$core$List$indexedMap,
					F2(
						function (i, ms) {
							var _v7 = ((ms.hk - i) > 0) ? _Utils_Tuple2(ms.hk - i, ms.iF) : _Utils_Tuple2(12 + (ms.hk - i), ms.iF - 1);
							var newMonth = _v7.a;
							var newYear = _v7.b;
							return _Utils_update(
								ms,
								{hk: newMonth, iF: newYear});
						}),
					A2($elm$core$List$repeat, 12, defaultMonthlySale)) : uModel.w;
				var sharedMsg = function () {
					if (apply && ((!_Utils_eq(uModel.b.bN, $elm$core$Maybe$Nothing)) && (!_Utils_eq(uModel.b.bw, $elm$core$Maybe$Nothing)))) {
						return uModel.dh ? _List_fromArray(
							[
								A7(
								$author$project$App$SharedState$LoadMedsynCompanyReport,
								model.b.fz,
								uModel.b.cB,
								startDate,
								endDate,
								A2($elm$core$Maybe$withDefault, '', uModel.b.bu),
								A2($elm$core$Maybe$withDefault, '', uModel.b.cJ),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg(
											A6(
												$author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedMedsynFilteredDetails,
												model.b.fz,
												uModel.b.cB,
												startDate,
												endDate,
												A2($elm$core$Maybe$withDefault, '', uModel.b.bu),
												A2($elm$core$Maybe$withDefault, '', uModel.b.cJ))))
									]))
							]) : _List_fromArray(
							[
								A6(
								$author$project$App$SharedState$LoadCompanyReport,
								uModel.b.cB,
								startDate,
								endDate,
								A2($elm$core$Maybe$withDefault, '', uModel.b.bu),
								A2($elm$core$Maybe$withDefault, '', uModel.b.cJ),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg(
											A5(
												$author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedFilteredDetails,
												uModel.b.cB,
												startDate,
												endDate,
												A2($elm$core$Maybe$withDefault, '', uModel.b.bu),
												A2($elm$core$Maybe$withDefault, '', uModel.b.cJ))))
									]))
							]);
					} else {
						if (apply) {
							var sDate = $elm$core$String$fromInt(uModel.aW) + (((uModel.ax >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(uModel.ax) + '-01'));
							var eDate = (uModel.ax === 12) ? ($elm$core$String$fromInt(uModel.aW + 1) + ('-' + '01-01')) : ($elm$core$String$fromInt(uModel.aW) + ((((uModel.ax + 1) >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(uModel.ax + 1) + '-01')));
							return uModel.dh ? _List_fromArray(
								[
									A5(
									$author$project$App$SharedState$LoadMedsynMonthDetails,
									model.b.fz,
									uModel.b.cB,
									startDate,
									endDate,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$AdminMsg(
											$author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg(
												A6($author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedMedsynDetails, model.b.fz, uModel.b.cB, sDate, eDate, uModel.ax, uModel.aW)))
										]))
								]) : _List_fromArray(
								[
									A4(
									$author$project$App$SharedState$LoadMonthDetails,
									uModel.b.cB,
									startDate,
									endDate,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$AdminMsg(
											$author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg(
												A5($author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedDetails, uModel.b.cB, sDate, eDate, uModel.ax, uModel.aW)))
										]))
								]);
						} else {
							return _List_Nil;
						}
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						uModel,
						{w: newMonthlySales, m: updatedMonth, o: updatedYear}),
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			default:
				var newOrder = msg.a;
				var updateOrder = _Utils_eq(newOrder, model.ct.a) ? _Utils_Tuple2(newOrder, !model.ct.b) : _Utils_Tuple2(newOrder, false);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ct: updateOrder}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$AdminListDoctorsAccountActivated = {$: 1};
var $author$project$App$Page$ResponseMsgs$AdminListDoctorsDoctorLoaded = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$AdminListDoctorsMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$AdminListDoctorsPersonLoaded = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Admin$PageListDoctors$GotTemplates = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$SharedState$LoadDoctorFromLicense = F3(
	function (a, b, c) {
		return {$: 107, a: a, b: b, c: c};
	});
var $author$project$App$SharedState$LoadPersonById = F2(
	function (a, b) {
		return {$: 33, a: a, b: b};
	});
var $author$project$App$Page$Admin$PageListDoctors$No = 0;
var $author$project$App$SharedState$RegisterDoctor = F3(
	function (a, b, c) {
		return {$: 109, a: a, b: b, c: c};
	});
var $author$project$App$SharedState$RemoveDoctor = F3(
	function (a, b, c) {
		return {$: 110, a: a, b: b, c: c};
	});
var $author$project$App$Page$Admin$PageListDoctors$Yes = 1;
var $author$project$App$Service$ApiRest$ListModel = F2(
	function (href, model) {
		return {ja: href, e: model};
	});
var $author$project$App$Service$ApiRest$decodeList = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$List$map(
			function ($) {
				return $.e;
			}),
		$elm$json$Json$Decode$list(
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'model',
				decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'href',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$App$Service$ApiRest$ListModel)))));
};
var $author$project$App$Data$StatementTemplate$Model = function (id) {
	return function (companyId) {
		return function (doctorLayout) {
			return function (individual) {
				return function (name) {
					return function (review) {
						return function (urlHost) {
							return function (urlTerms) {
								return function (urlPolicy) {
									return function (urlPath) {
										return function (css) {
											return function (titleHeader) {
												return function (titleDescription) {
													return function (greetings) {
														return function (waitInLineText) {
															return function (outOfOfficeText) {
																return function (noDoctorText) {
																	return function (chargeAmount) {
																		return function (paymentRequired) {
																			return function (hasDependents) {
																				return function (registerRequiredFields) {
																					return function (doctorReviewFields) {
																						return function (doctorGreetingsText) {
																							return function (onlyMedicalRecordOnDoctorQuestions) {
																								return function (results) {
																									return {gr: chargeAmount, gx: companyId, gz: css, eg: doctorGreetingsText, gF: doctorLayout, gG: doctorReviewFields, el: greetings, gX: hasDependents, as: id, fy: individual, hl: name, ex: noDoctorText, ht: onlyMedicalRecordOnDoctorQuestions, eB: outOfOfficeText, hw: paymentRequired, eJ: registerRequiredFields, eM: results, hR: review, $9: titleDescription, io: titleHeader, iw: urlHost, ix: urlPath, iy: urlPolicy, iz: urlTerms, e0: waitInLineText};
																								};
																							};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$StatementTemplate$TemplateText = F8(
	function (greetings, waitInLineText, outOfOfficeText, noDoctorText, registerRequiredFields, doctorReviewFields, doctorGreetingsText, results) {
		return {eg: doctorGreetingsText, gG: doctorReviewFields, el: greetings, ex: noDoctorText, eB: outOfOfficeText, eJ: registerRequiredFields, eM: results, e0: waitInLineText};
	});
var $author$project$App$Data$StatementTemplate$Amil = 3;
var $author$project$App$Data$StatementTemplate$Aurora = 12;
var $author$project$App$Data$StatementTemplate$Bradesco = 6;
var $author$project$App$Data$StatementTemplate$CentauroOn = 7;
var $author$project$App$Data$StatementTemplate$Clinipam = 4;
var $author$project$App$Data$StatementTemplate$Hnsg = 8;
var $author$project$App$Data$StatementTemplate$Humana = 5;
var $author$project$App$Data$StatementTemplate$Medsyn = 9;
var $author$project$App$Data$StatementTemplate$SB_Saude = 10;
var $author$project$App$Data$StatementTemplate$Sulamerica = 2;
var $author$project$App$Data$StatementTemplate$TempoMed = 11;
var $author$project$App$Data$StatementTemplate$Unimed = 1;
var $author$project$App$Data$StatementTemplate$layoutFromInt = function (i) {
	switch (i) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 7;
		case 4:
			return 8;
		case 6:
			return 4;
		case 7:
			return 6;
		case 8:
			return 5;
		case 9:
			return 9;
		case 13:
			return 10;
		case 14:
			return 11;
		case 15:
			return 12;
		default:
			return 0;
	}
};
var $author$project$App$Data$StatementTemplate$decodeDoctorLayout = A2(
	$elm$json$Json$Decode$andThen,
	function (i) {
		return $elm$json$Json$Decode$succeed(
			$author$project$App$Data$StatementTemplate$layoutFromInt(i));
	},
	$elm$json$Json$Decode$int);
var $elm$json$Json$Decode$map8 = _Json_map8;
var $author$project$App$Data$StatementTemplate$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (tText) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
			A2(
				$elm$core$Result$withDefault,
				_List_Nil,
				A2(
					$elm$json$Json$Decode$decodeString,
					$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
					tText.eM)),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'onlyMedicalRecordOnDoctorQuestions',
				$elm$json$Json$Decode$bool,
				A2(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
					A2(
						$elm$core$Result$withDefault,
						_List_Nil,
						A2(
							$elm$json$Json$Decode$decodeString,
							$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
							tText.eg)),
					A2(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
						A2(
							$elm$core$Result$withDefault,
							_List_Nil,
							A2(
								$elm$json$Json$Decode$decodeString,
								$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
								tText.gG)),
						A2(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
							A2(
								$elm$core$Result$withDefault,
								_List_Nil,
								A2(
									$elm$json$Json$Decode$decodeString,
									$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
									tText.eJ)),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'hasDependents',
								$elm$json$Json$Decode$bool,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'paymentRequired',
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool),
									$elm$core$Maybe$Nothing,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'chargeAmount',
										$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
										$elm$core$Maybe$Nothing,
										A2(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
											A2(
												$elm$core$Result$withDefault,
												_List_Nil,
												A2(
													$elm$json$Json$Decode$decodeString,
													$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
													tText.ex)),
											A2(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
												A2(
													$elm$core$Result$withDefault,
													_List_Nil,
													A2(
														$elm$json$Json$Decode$decodeString,
														$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
														tText.eB)),
												A2(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
													A2(
														$elm$core$Result$withDefault,
														_List_Nil,
														A2(
															$elm$json$Json$Decode$decodeString,
															$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
															tText.e0)),
													A2(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
														A2(
															$elm$core$Result$withDefault,
															_List_Nil,
															A2(
																$elm$json$Json$Decode$decodeString,
																$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
																tText.el)),
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'titleDescription',
															$elm$json$Json$Decode$string,
															A3(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																'titleHeader',
																$elm$json$Json$Decode$string,
																A4(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																	'css',
																	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
																	$elm$core$Maybe$Nothing,
																	A4(
																		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																		'urlPath',
																		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
																		$elm$core$Maybe$Nothing,
																		A3(
																			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																			'urlPolicy',
																			$elm$json$Json$Decode$string,
																			A3(
																				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																				'urlTerms',
																				$elm$json$Json$Decode$string,
																				A3(
																					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																					'urlHost',
																					$elm$json$Json$Decode$string,
																					A3(
																						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																						'review',
																						$elm$json$Json$Decode$bool,
																						A3(
																							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																							'name',
																							$elm$json$Json$Decode$string,
																							A3(
																								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																								'individual',
																								$elm$json$Json$Decode$bool,
																								A3(
																									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																									'doctorLayout',
																									$author$project$App$Data$StatementTemplate$decodeDoctorLayout,
																									A3(
																										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																										'companyId',
																										$author$project$App$Data$Id$uuidDecoder,
																										A3(
																											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																											'id',
																											$author$project$App$Data$Id$uuidDecoder,
																											$elm$json$Json$Decode$succeed($author$project$App$Data$StatementTemplate$Model))))))))))))))))))))))))));
	},
	A9(
		$elm$json$Json$Decode$map8,
		$author$project$App$Data$StatementTemplate$TemplateText,
		A2($elm$json$Json$Decode$field, 'greetings', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'waitInLineText', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'outOfOfficeText', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'noDoctorText', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'registerRequiredFields', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'doctorReviewFields', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'doctorGreetingsText', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'results', $elm$json$Json$Decode$string)));
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.j$));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $author$project$App$Constants$baseApiRestUrl = 'https://api.medsyn.com.br/api';
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {hL: reqs, ih: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.ir;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.hL));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.ih)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					iI: r.iI,
					e5: r.e5,
					a1: A2(_Http_mapExpect, func, r.a1),
					g_: r.g_,
					z: r.z,
					im: r.im,
					ir: r.ir,
					f3: r.f3
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{iI: false, e5: r.e5, a1: r.a1, g_: r.g_, z: r.z, im: r.im, ir: r.ir, f3: r.f3}));
};
var $author$project$App$Service$ApiRest$request = function (options) {
	var _v0 = options.e$;
	if (_v0.$ === 1) {
		return $elm$http$Http$request(
			{
				e5: options.e5,
				a1: options.a1,
				g_: _List_Nil,
				z: options.z,
				im: $elm$core$Maybe$Nothing,
				ir: $elm$core$Maybe$Nothing,
				f3: _Utils_ap(
					$author$project$App$Constants$baseApiRestUrl + '/',
					A2(
						$elm$core$String$join,
						'/',
						A2($elm$core$String$split, '.', options.f3)))
			});
	} else {
		var tokenValue = _v0.a;
		var token = $truqu$elm_base64$Base64$encode(
			A2($elm$json$Json$Encode$encode, 0, tokenValue));
		return $elm$http$Http$request(
			{
				e5: options.e5,
				a1: options.a1,
				g_: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				z: options.z,
				im: $elm$core$Maybe$Nothing,
				ir: $elm$core$Maybe$Nothing,
				f3: _Utils_ap(
					$author$project$App$Constants$baseApiRestUrl + '/',
					A2(
						$elm$core$String$join,
						'/',
						A2($elm$core$String$split, '.', options.f3)))
			});
	}
};
var $author$project$App$Service$ApiRest$get = function (options) {
	return $author$project$App$Service$ApiRest$request(
		{e5: $elm$http$Http$emptyBody, a1: options.a1, z: 'GET', e$: options.e$, f3: options.f3});
};
var $author$project$App$Service$StatementTemplateRest$getFromCompany = F3(
	function (mToken, cId, expect) {
		return $author$project$App$Service$ApiRest$get(
			{
				a1: A2(
					$elm$http$Http$expectJson,
					expect,
					$author$project$App$Service$ApiRest$decodeList($author$project$App$Data$StatementTemplate$decoder)),
				e$: mToken,
				f3: 'stmtTemplateService.fromCompany.' + cId
			});
	});
var $author$project$App$Service$ApiWebSocket$getTimestamp = function (_v0) {
	var now = _v0.b;
	return now;
};
var $author$project$App$Data$DoctorTemplate$updateModel = F3(
	function (now, field, model) {
		var updatedModel = function () {
			var id = field;
			var templateId = $author$project$App$Data$Id$uuidIsValid(id) ? id : $author$project$App$Data$Id$uuidNil;
			return _Utils_update(
				model,
				{eY: templateId});
		}();
		var updatedAt = function (_v0) {
			var t = _v0.a;
			var b = _v0.b;
			return b ? $elm$time$Time$millisToPosix(t + 10000) : now;
		}(
			function (t) {
				return _Utils_Tuple2(
					t,
					_Utils_cmp(
						$elm$time$Time$posixToMillis(now),
						t) < 0);
			}(
				$elm$time$Time$posixToMillis(model.f2)));
		return _Utils_update(
			updatedModel,
			{f2: updatedAt});
	});
var $author$project$App$Page$Admin$PageListDoctors$update = F3(
	function (msg, st, m) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(
					m,
					A3(
						$author$project$App$Service$StatementTemplateRest$getFromCompany,
						$author$project$App$Service$ApiWebSocket$getAuthToken(st._),
						m.gx,
						$author$project$App$Page$Admin$PageListDoctors$GotTemplates),
					_List_Nil);
			case 5:
				if (!msg.a.$) {
					var list = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							m,
							{ep: true, eZ: list}),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				} else {
					return _Utils_Tuple3(m, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 1:
				var personId = msg.a;
				return _Utils_Tuple3(
					m,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$LoadPersonById,
								personId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminListDoctorsMsg(
											$author$project$App$Page$ResponseMsgs$AdminListDoctorsPersonLoaded(personId)))
									])))
						]));
			case 3:
				var _v1 = $author$project$App$Data$Id$uuidIsValid(m.dw.as) ? _Utils_Tuple2(
					1,
					_List_fromArray(
						[
							A3(
							$author$project$App$SharedState$RegisterDoctor,
							m.dw.as,
							m.aB,
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$AdminMsg(
									$author$project$App$Page$ResponseMsgs$AdminListDoctorsMsg($author$project$App$Page$ResponseMsgs$AdminListDoctorsAccountActivated))
								]))
						])) : _Utils_Tuple2(0, _List_Nil);
				var submitting = _v1.a;
				var persistMsg = _v1.b;
				return _Utils_Tuple3(
					_Utils_update(
						m,
						{cH: submitting}),
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, persistMsg));
			case 4:
				var _v2 = $author$project$App$Data$Id$uuidIsValid(m.dw.as) ? _Utils_Tuple2(
					1,
					_List_fromArray(
						[
							A3(
							$author$project$App$SharedState$RemoveDoctor,
							m.dw.as,
							m.aB,
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$AdminMsg(
									$author$project$App$Page$ResponseMsgs$AdminListDoctorsMsg($author$project$App$Page$ResponseMsgs$AdminListDoctorsAccountActivated))
								]))
						])) : _Utils_Tuple2(0, _List_Nil);
				var submitting = _v2.a;
				var persistMsg = _v2.b;
				return _Utils_Tuple3(
					_Utils_update(
						m,
						{cH: submitting}),
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, persistMsg));
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						m,
						{fp: $elm$core$Dict$empty}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 6:
				var fieldValue = msg.a;
				var now = $author$project$App$Service$ApiWebSocket$getTimestamp(st._);
				var doctorTemplate = m.aB;
				var newModel = _Utils_update(
					m,
					{
						aB: A3($author$project$App$Data$DoctorTemplate$updateModel, now, fieldValue, doctorTemplate)
					});
				return _Utils_Tuple3(newModel, $elm$core$Platform$Cmd$none, _List_Nil);
			case 7:
				var value = msg.a;
				var sharedMsg = (($elm$core$String$length(value) > 0) && ($elm$core$String$length(m.bu.dn) > 0)) ? _List_fromArray(
					[
						$author$project$App$SharedState$APIMsg(
						A3(
							$author$project$App$SharedState$LoadDoctorFromLicense,
							value,
							m.bu.dn,
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$AdminMsg(
									$author$project$App$Page$ResponseMsgs$AdminListDoctorsMsg(
										A2($author$project$App$Page$ResponseMsgs$AdminListDoctorsDoctorLoaded, value, m.bu.dn)))
								])))
					]) : _List_Nil;
				var doctor = m.bu;
				return _Utils_Tuple3(
					_Utils_update(
						m,
						{
							bu: _Utils_update(
								doctor,
								{dm: value})
						}),
					$elm$core$Platform$Cmd$none,
					sharedMsg);
			default:
				var value = msg.a;
				var sharedMsg = (($elm$core$String$length(value) > 0) && ($elm$core$String$length(m.bu.dm) > 0)) ? _List_fromArray(
					[
						$author$project$App$SharedState$APIMsg(
						A3(
							$author$project$App$SharedState$LoadDoctorFromLicense,
							m.bu.dm,
							value,
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$AdminMsg(
									$author$project$App$Page$ResponseMsgs$AdminListDoctorsMsg(
										A2($author$project$App$Page$ResponseMsgs$AdminListDoctorsDoctorLoaded, m.bu.dm, value)))
								])))
					]) : _List_Nil;
				var doctor = m.bu;
				return _Utils_Tuple3(
					_Utils_update(
						m,
						{
							bu: _Utils_update(
								doctor,
								{dn: value})
						}),
					$elm$core$Platform$Cmd$none,
					sharedMsg);
		}
	});
var $author$project$App$Page$ResponseMsgs$AdminListFinishedFullStatementLoaded = {$: 3};
var $author$project$App$Page$ResponseMsgs$AdminListFinishedMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$AdminListFinishedPersonLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$AdminListFinishedStatementsLoaded = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Data$QuestionSection$CategoryCompany = 1;
var $author$project$App$Data$QuestionSection$CategoryDoctor = 3;
var $author$project$App$Data$QuestionSection$CategoryPerson = 2;
var $author$project$App$SharedState$FullStmtAssetAnswers = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$SharedState$FullStmtAssetDocuments = {$: 1};
var $author$project$App$SharedState$FullStmtAssetICD = {$: 3};
var $author$project$App$SharedState$FullStmtAssetQuestions = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$SharedState$LoadFinishedStatementsFromAdmin = F2(
	function (a, b) {
		return {$: 58, a: a, b: b};
	});
var $author$project$App$SharedState$LoadFullStatementWithMinors = F3(
	function (a, b, c) {
		return {$: 52, a: a, b: b, c: c};
	});
var $author$project$App$Components$Filter$FilterFieldOrder = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Components$Filter$setOrder = F2(
	function (order, filter) {
		return A2(
			$author$project$App$Components$Filter$update,
			$author$project$App$Components$Filter$FilterFieldOrder(order),
			filter).a;
	});
var $author$project$App$Page$Admin$PageListFinished$defaultFilter = function (filter) {
	return A2($author$project$App$Components$Filter$setOrder, 'created_at,desc', filter);
};
var $author$project$App$Components$ViewFullStatement$copyStringToClipboard = _Platform_outgoingPort('copyStringToClipboard', $elm$json$Json$Encode$string);
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$App$Data$Document$documentRequestUrl = _Platform_outgoingPort(
	'documentRequestUrl',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'content',
					$elm$json$Json$Encode$string($.iU)),
					_Utils_Tuple2(
					'docType',
					$elm$json$Json$Encode$int($.b7)),
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string($.as)),
					_Utils_Tuple2(
					'mime',
					$elm$json$Json$Encode$string($.dr)),
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string($.hl)),
					_Utils_Tuple2(
					'objectUrl',
					$elm$json$Json$Encode$string($.af)),
					_Utils_Tuple2(
					'objectUrlRequested',
					$elm$json$Json$Encode$bool($.Q)),
					_Utils_Tuple2(
					'ownerId',
					$elm$json$Json$Encode$string($.jJ)),
					_Utils_Tuple2(
					'ownerKind',
					$elm$json$Json$Encode$string($.X)),
					_Utils_Tuple2(
					'personId',
					$elm$json$Json$Encode$string($.Y))
				]));
	});
var $author$project$App$Data$Document$portFromModel = function (m) {
	return {
		iU: m.iU,
		b7: $author$project$App$Data$Document$typeToInt(m.b7),
		as: m.as,
		dr: m.dr,
		hl: m.hl,
		af: m.af,
		Q: m.Q,
		jJ: m.jJ,
		X: m.X,
		Y: m.Y
	};
};
var $author$project$App$Data$Document$requestUrl = function (doc) {
	return ((!doc.Q) && (doc.iU !== '')) ? _Utils_Tuple2(
		_Utils_update(
			doc,
			{Q: true}),
		$author$project$App$Data$Document$documentRequestUrl(
			$author$project$App$Data$Document$portFromModel(
				_Utils_update(
					doc,
					{Q: true})))) : _Utils_Tuple2(doc, $elm$core$Platform$Cmd$none);
};
var $author$project$App$Data$Document$updateUrl = F2(
	function (msg, actualDoc) {
		if (!msg.$) {
			var docWithUrl = msg.a;
			return (_Utils_eq(docWithUrl.hl, actualDoc.hl) && (actualDoc.af === '')) ? docWithUrl : actualDoc;
		} else {
			return actualDoc;
		}
	});
var $author$project$App$Components$ViewFullStatement$update = F4(
	function (msg, fullStmt, sharedState, model) {
		var sections = fullStmt.jM;
		var companySections = fullStmt.bs;
		var _v0 = A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, doc, _v1) {
					var uDocs = _v1.a;
					var cmds = _v1.b;
					var _v2 = $author$project$App$Data$Document$requestUrl(doc);
					var uDoc = _v2.a;
					var cmd = _v2.b;
					return _Utils_Tuple2(
						A3($elm$core$Dict$insert, k, uDoc, uDocs),
						A2($elm$core$List$cons, cmd, cmds));
				}),
			_Utils_Tuple2(fullStmt.an, _List_Nil),
			fullStmt.an);
		var updatedDocs = _v0.a;
		var dCmd = _v0.b;
		var updatedStmt = _Utils_update(
			fullStmt,
			{an: updatedDocs});
		switch (msg.$) {
			case 0:
				var tab = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: $elm$core$Dict$fromList(
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, v) {
											return _Utils_Tuple2(i + 1, v);
										}),
									companySections)),
							b5: tab,
							a5: updatedStmt,
							cA: $elm$core$Dict$fromList(
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, v) {
											return _Utils_Tuple2(i + 1, v);
										}),
									sections))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var docMsg = msg.a;
				var docsUpdated = A2(
					$elm$core$Dict$map,
					F2(
						function (_v4, doc) {
							return A2($author$project$App$Data$Document$updateUrl, docMsg, doc);
						}),
					model.a5.an);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: $elm$core$Dict$fromList(
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, v) {
											return _Utils_Tuple2(i + 1, v);
										}),
									companySections)),
							a5: _Utils_update(
								updatedStmt,
								{an: docsUpdated}),
							cA: $elm$core$Dict$fromList(
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, v) {
											return _Utils_Tuple2(i + 1, v);
										}),
									sections))
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var doctor = msg.a;
				var companyPerson = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cZ: companyPerson, bu: doctor}),
					$elm$core$Platform$Cmd$none);
			default:
				var str = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$App$Components$ViewFullStatement$copyStringToClipboard(str));
		}
	});
var $author$project$App$Page$Admin$PageListFinished$update = F3(
	function (msg, sharedState, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A2(
						$author$project$App$SharedState$LoadFinishedStatementsFromAdmin,
						uModel.b,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$AdminMsg(
								$author$project$App$Page$ResponseMsgs$AdminListFinishedMsg(
									A2($author$project$App$Page$ResponseMsgs$AdminListFinishedStatementsLoaded, uModel.bX, uModel.b)))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin(
								$author$project$App$Route$AdminListFinishedRoute(
									$author$project$App$Route$AdminListFinished(uModel.b))))),
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var filter = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A2(
								$author$project$App$SharedState$LoadFinishedStatementsFromAdmin,
								filter,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminListFinishedMsg(
											A2($author$project$App$Page$ResponseMsgs$AdminListFinishedStatementsLoaded, model.bX, filter)))
									]))
							])));
			case 2:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$AdminMsg(
											$author$project$App$Page$ResponseMsgs$AdminListFinishedMsg(
												$author$project$App$Page$ResponseMsgs$AdminListFinishedPersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			case 3:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin(
								$author$project$App$Route$AdminListFinishedRoute(
									A2($author$project$App$Route$AdminInterviewDetails, stmtId, model.b))))),
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementWithMinors,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1),
										$author$project$App$SharedState$FullStmtAssetAnswers(2),
										$author$project$App$SharedState$FullStmtAssetQuestions(2),
										$author$project$App$SharedState$FullStmtAssetAnswers(3),
										$author$project$App$SharedState$FullStmtAssetQuestions(3),
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetICD
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminListFinishedMsg($author$project$App$Page$ResponseMsgs$AdminListFinishedFullStatementLoaded))
									]))
							])));
			case 4:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: $elm$core$Maybe$Nothing, av: $elm$core$Maybe$Nothing}),
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin(
								$author$project$App$Route$AdminListFinishedRoute(
									$author$project$App$Route$AdminListFinished(
										$author$project$App$Page$Admin$PageListFinished$defaultFilter($author$project$App$Components$Filter$empty)))))),
					_List_Nil);
			case 5:
				var viewMsg = msg.a;
				var _v2 = function () {
					var _v3 = model.av;
					if (!_v3.$) {
						var viewFullStmt = _v3.a;
						return function (_v4) {
							var a = _v4.a;
							var b = _v4.b;
							return _Utils_Tuple3(
								$elm$core$Maybe$Just(viewFullStmt.a5.g.e.as),
								$elm$core$Maybe$Just(a),
								b);
						}(
							A4($author$project$App$Components$ViewFullStatement$update, viewMsg, sharedState.ia.h8, sharedState, viewFullStmt));
					} else {
						return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
					}
				}();
				var showStmt = _v2.a;
				var uViewFullStmt = _v2.b;
				var uViewFullStmtMsg = _v2.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: showStmt, av: uViewFullStmt}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Admin$PageListFinished$ViewFullStmtMsg, uViewFullStmtMsg),
					_List_Nil);
			default:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin(
								$author$project$App$Route$AdminViewEvaluationRoute(stmtId)))),
					_List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$AdminListReevaluationLoaded = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$AdminListReevaluationMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$ResponseMsgs$AdminListReevaluationPersonLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$SharedState$LoadReevalStatementsFromCompany = F3(
	function (a, b, c) {
		return {$: 59, a: a, b: b, c: c};
	});
var $author$project$App$Page$Admin$PageListReevaluation$update = F3(
	function (msg, sharedState, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A3(
						$author$project$App$SharedState$LoadReevalStatementsFromCompany,
						model.gx,
						uModel.b,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$AdminMsg(
								$author$project$App$Page$ResponseMsgs$AdminListReevaluationMsg(
									A2($author$project$App$Page$ResponseMsgs$AdminListReevaluationLoaded, uModel.gx, uModel.b)))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin($author$project$App$Route$AdminListReevaluation))),
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var filter = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadReevalStatementsFromCompany,
								model.gx,
								filter,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminListReevaluationMsg(
											A2($author$project$App$Page$ResponseMsgs$AdminListReevaluationLoaded, model.gx, filter)))
									]))
							])));
			case 2:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$AdminMsg(
											$author$project$App$Page$ResponseMsgs$AdminListReevaluationMsg(
												$author$project$App$Page$ResponseMsgs$AdminListReevaluationPersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			case 3:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin(
								$author$project$App$Route$AdminReevaluationDetails(stmtId)))),
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementWithMinors,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1),
										$author$project$App$SharedState$FullStmtAssetAnswers(2),
										$author$project$App$SharedState$FullStmtAssetQuestions(2),
										$author$project$App$SharedState$FullStmtAssetAnswers(3),
										$author$project$App$SharedState$FullStmtAssetQuestions(3),
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetICD
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminListReevaluationMsg(
											A2($author$project$App$Page$ResponseMsgs$AdminListReevaluationLoaded, model.gx, $author$project$App$Components$Filter$empty)))
									]))
							])));
			case 4:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: $elm$core$Maybe$Nothing, av: $elm$core$Maybe$Nothing}),
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin($author$project$App$Route$AdminListReevaluation))),
					_List_Nil);
			case 5:
				var viewMsg = msg.a;
				var _v2 = function () {
					var _v3 = model.av;
					if (!_v3.$) {
						var viewFullStmt = _v3.a;
						return function (_v4) {
							var a = _v4.a;
							var b = _v4.b;
							return _Utils_Tuple3(
								$elm$core$Maybe$Just(viewFullStmt.a5.g.e.as),
								$elm$core$Maybe$Just(a),
								b);
						}(
							A4($author$project$App$Components$ViewFullStatement$update, viewMsg, sharedState.ia.h8, sharedState, viewFullStmt));
					} else {
						return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
					}
				}();
				var showStmt = _v2.a;
				var uViewFullStmt = _v2.b;
				var uViewFullStmtMsg = _v2.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: showStmt, av: uViewFullStmt}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Admin$PageListReevaluation$ViewFullStmtMsg, uViewFullStmtMsg),
					_List_Nil);
			default:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin(
								$author$project$App$Route$AdminReevaluationDetails(stmtId)))),
					_List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$AdminListUsersAccountActivated = {$: 2};
var $author$project$App$Page$ResponseMsgs$AdminListUsersLoaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$AdminListUsersMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$SharedState$LoadUsersFromCompany = F2(
	function (a, b) {
		return {$: 102, a: a, b: b};
	});
var $author$project$App$SharedState$RequestUserActivation = F2(
	function (a, b) {
		return {$: 103, a: a, b: b};
	});
var $author$project$App$SharedState$RequestUserDeletion = F2(
	function (a, b) {
		return {$: 104, a: a, b: b};
	});
var $author$project$App$Page$Admin$PageListUsers$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				var cId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A2(
								$author$project$App$SharedState$LoadUsersFromCompany,
								cId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminListUsersMsg(
											$author$project$App$Page$ResponseMsgs$AdminListUsersLoaded(cId)))
									]))
							])));
			case 1:
				var userId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$RequestUserActivation,
								userId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminListUsersMsg($author$project$App$Page$ResponseMsgs$AdminListUsersAccountActivated))
									])))
						]));
			default:
				var userId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$RequestUserDeletion,
								userId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminListUsersMsg($author$project$App$Page$ResponseMsgs$AdminListUsersAccountActivated))
									])))
						]));
		}
	});
var $author$project$App$Page$ResponseMsgs$AdminReevaluationMessageSent = 1;
var $author$project$App$Page$ResponseMsgs$AdminReevaluationMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Admin$PageReevaluation$ChatBoxMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Components$Chatbox$SendMessage = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$SharedState$SendStatementMessage = F2(
	function (a, b) {
		return {$: 76, a: a, b: b};
	});
var $author$project$App$SharedState$StatementFinishReevaluation = F2(
	function (a, b) {
		return {$: 74, a: a, b: b};
	});
var $author$project$App$SharedState$StatementSendToDoctorReview = F2(
	function (a, b) {
		return {$: 73, a: a, b: b};
	});
var $author$project$App$Components$Chatbox$DeleteMessage = {$: 5};
var $elm$core$String$reverse = _String_reverse;
var $author$project$App$Components$Chatbox$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var text = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{az: ''}),
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$always($author$project$App$Components$Chatbox$DeleteMessage),
						$elm$core$Task$succeed(0)));
			case 1:
				var text = msg.a;
				var newText = A2(
					$elm$core$String$startsWith,
					'\n',
					$elm$core$String$reverse(text)) ? '' : text;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{az: newText}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var text = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 4:
				var key = msg.a;
				var cmd = (key === 13) ? A2(
					$elm$core$Task$perform,
					A2($elm$core$Basics$always, $author$project$App$Components$Chatbox$SendMessage, model.az),
					$elm$core$Task$succeed(model.az)) : $elm$core$Platform$Cmd$none;
				return _Utils_Tuple2(model, cmd);
			case 3:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{az: ''}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Page$Admin$PageReevaluation$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 1:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin($author$project$App$Route$AdminListReevaluation))),
					_List_Nil);
			case 3:
				var emptyMessage = $author$project$App$Data$StatementMessage$emptyModel;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$StatementSendToDoctorReview,
								_Utils_update(
									emptyMessage,
									{jl: '', aV: model.P.g.e.as}),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminReevaluationMsg(1))
									])))
						]));
			case 4:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$StatementFinishReevaluation,
								model.P.g.e,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminReevaluationMsg(1))
									])))
						]));
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: !model.dC}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 5:
				var str = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{eN: str}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 6:
				var idx = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{b5: idx}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 7:
				var time = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 8:
				var docMsg = msg.a;
				var docsUpdated = A2(
					$elm$core$Dict$map,
					F2(
						function (_v1, doc) {
							return A2($author$project$App$Data$Document$updateUrl, docMsg, doc);
						}),
					model.an);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{an: docsUpdated}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 9:
				if (!msg.a.$) {
					var messageText = msg.a.a;
					var emptyMessage = $author$project$App$Data$StatementMessage$emptyModel;
					var newMessage = _Utils_update(
						emptyMessage,
						{jl: messageText, aV: model.eV});
					var _v2 = A2(
						$author$project$App$Components$Chatbox$update,
						$author$project$App$Components$Chatbox$SendMessage(messageText),
						model.aM);
					var chatBoxUpdated = _v2.a;
					var chatCmd = _v2.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aM: chatBoxUpdated}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Admin$PageReevaluation$ChatBoxMsg, chatCmd),
						_List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$SendStatementMessage, newMessage, _List_Nil))
							]));
				} else {
					var chatMsg = msg.a;
					var _v3 = A2($author$project$App$Components$Chatbox$update, chatMsg, model.aM);
					var chatBoxUpdated = _v3.a;
					var chatCmd = _v3.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aM: chatBoxUpdated}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Admin$PageReevaluation$ChatBoxMsg, chatCmd),
						_List_Nil);
				}
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$AdminViewEvaluationMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$AdminViewReviewRequestSent = 2;
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$ChatBoxMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$defaultFilter = function (filter) {
	return A2($author$project$App$Components$Filter$setOrder, 'created_at,desc', filter);
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 1:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin(
								$author$project$App$Route$AdminListFinishedRoute(
									$author$project$App$Route$AdminListFinished(
										$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$defaultFilter($author$project$App$Components$Filter$empty)))))),
					_List_Nil);
			case 3:
				var emptyMessage = $author$project$App$Data$StatementMessage$emptyModel;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$StatementSendToDoctorReview,
								_Utils_update(
									emptyMessage,
									{jl: model.eN, aV: model.P.g.e.as}),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminViewEvaluationMsg(2))
									])))
						]));
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: !model.dC}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 4:
				var str = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{eN: str}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 5:
				var idx = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{b5: idx}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 6:
				var time = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 7:
				var docMsg = msg.a;
				var docsUpdated = A2(
					$elm$core$Dict$map,
					F2(
						function (_v1, doc) {
							return A2($author$project$App$Data$Document$updateUrl, docMsg, doc);
						}),
					model.an);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{an: docsUpdated}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 8:
				var chatMsg = msg.a;
				var _v2 = A2($author$project$App$Components$Chatbox$update, chatMsg, model.aM);
				var chatBoxUpdated = _v2.a;
				var chatCmd = _v2.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aM: chatBoxUpdated}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$ChatBoxMsg, chatCmd),
					_List_Nil);
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$Page$PageAdmin$update = F4(
	function (msg, now, st, model) {
		var _v0 = _Utils_Tuple2(msg, model);
		switch (_v0.a.$) {
			case 0:
				if (!_v0.b.$) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v1 = A3($author$project$App$Page$Admin$PageListUsers$update, pageMsg, st, pageModel);
					var nPageModel = _v1.a;
					var nPageMsg = _v1.b;
					var stMsgs = _v1.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$ListUsers(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListUsersMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 1:
				if (_v0.b.$ === 1) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v2 = A3($author$project$App$Page$Admin$PageListDoctors$update, pageMsg, st, pageModel);
					var nPageModel = _v2.a;
					var nPageMsg = _v2.b;
					var stMsgs = _v2.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$ListDoctors(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListDoctorsMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 2:
				if (_v0.b.$ === 2) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v3 = A3($author$project$App$Page$Admin$PageFinanceSales$update, pageMsg, st, pageModel);
					var nPageModel = _v3.a;
					var nPageMsg = _v3.b;
					var stMsgs = _v3.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$FinanceSales(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$FinanceSalesMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 3:
				if (_v0.b.$ === 3) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v4 = A3($author$project$App$Page$Admin$PageListFinished$update, pageMsg, st, pageModel);
					var nPageModel = _v4.a;
					var nPageMsg = _v4.b;
					var stMsgs = _v4.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$ListFinished(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListFinishedMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 4:
				if (_v0.b.$ === 6) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v5 = A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$update, pageMsg, st, pageModel);
					var nPageModel = _v5.a;
					var nPageMsg = _v5.b;
					var stMsgs = _v5.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$ViewEvaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ViewEvaluationMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 5:
				if (_v0.b.$ === 4) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v6 = A3($author$project$App$Page$Admin$PageListReevaluation$update, pageMsg, st, pageModel);
					var nPageModel = _v6.a;
					var nPageMsg = _v6.b;
					var stMsgs = _v6.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$ListReevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListReevaluationMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			default:
				if (_v0.b.$ === 5) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v7 = A3($author$project$App$Page$Admin$PageReevaluation$update, pageMsg, st, pageModel);
					var nPageModel = _v7.a;
					var nPageMsg = _v7.b;
					var stMsgs = _v7.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$Reevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ReevaluationMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
		}
	});
var $author$project$App$Page$PageCompany$ListCanceled = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$PageCompany$ListConcluded = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$PageCompany$ListPending = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$PageCompany$ListReevaluation = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$PageCompany$Reevaluation = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$PageCompany$ReevaluationMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$PageCompany$RequestInterview = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$PageCompany$ViewEvaluation = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$PageCompany$ViewEvaluationMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyListCanceledFullStatementLoaded = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyListCanceledMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyListCanceledPersonLoaded = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyListCanceledStatementsLoaded = {$: 2};
var $author$project$App$Page$ResponseMsgs$CompanyListConcludedCompanyPeopleLoaded = {$: 5};
var $author$project$App$Page$ResponseMsgs$CompanyListConcludedMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$SharedState$LoadCompanyPeopleFromPerson = function (a) {
	return {$: 17, a: a};
};
var $author$project$App$SharedState$LoadStatementsFromCompany = F3(
	function (a, b, c) {
		return {$: 54, a: a, b: b, c: c};
	});
var $author$project$App$Page$Company$PageListCanceled$NoDetails = {$: 0};
var $author$project$App$Page$Company$PageListCanceled$Showing = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Data$StatementStatus$StatusExpired = 29;
var $author$project$App$Data$StatementStatus$StatusRefused = 28;
var $author$project$App$Components$Filter$FilterFieldRules = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Components$Filter$addRule = F2(
	function (rule, filter) {
		return function (rules) {
			return A2(
				$author$project$App$Components$Filter$update,
				$author$project$App$Components$Filter$FilterFieldRules(
					A2($elm$core$List$cons, rule, rules)),
				filter);
		}(
			A2(
				$elm$core$List$filter,
				function (r) {
					return !_Utils_eq(r, rule);
				},
				filter.aH)).a;
	});
var $author$project$App$Data$StatementStatus$statusToString = function (status) {
	return $elm$core$String$fromInt(
		$author$project$App$Data$StatementStatus$statusToInt(status));
};
var $author$project$App$Page$Company$PageListCanceled$defaultFilter = function (filter) {
	return A2(
		$author$project$App$Components$Filter$setOrder,
		'created_at,asc',
		A2(
			$author$project$App$Components$Filter$addRule,
			'or(status,=,' + ($author$project$App$Data$StatementStatus$statusToString(28) + ('&status,=,' + ($author$project$App$Data$StatementStatus$statusToString(29) + ')'))),
			filter));
};
var $author$project$App$Service$Shared$pathFromString = A2($elm$core$Basics$composeR, $author$project$App$Service$Shared$ridFromString, $author$project$App$Service$Shared$pathFromRID);
var $author$project$App$Service$ApiWebSocket$collectionResource = F2(
	function (path, model) {
		var resources = A2($author$project$App$Service$ApiWebSocket$getResource, path, model);
		if (((!resources.$) && (resources.a.$ === 3)) && (resources.a.c.$ === 4)) {
			var _v1 = resources.a;
			var _v2 = _v1.c;
			var mType = _v2.a;
			var list = _v2.b;
			return $elm$core$Maybe$Just(
				A3(
					$elm$core$List$foldr,
					F2(
						function (rid, result) {
							var _v3 = A2(
								$author$project$App$Service$ApiWebSocket$getResource,
								$author$project$App$Service$Shared$pathFromString(rid),
								model);
							if (!_v3.$) {
								var resource = _v3.a;
								return A2($elm$core$List$cons, resource, result);
							} else {
								return result;
							}
						}),
					_List_Nil,
					list));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$ApiWebSocket$getSpecificResourceCollection = F4(
	function (path, rType, extractor, model) {
		var mResources = A2($author$project$App$Service$ApiWebSocket$collectionResource, path, model);
		if (!mResources.$) {
			var list = mResources.a;
			return $elm$core$Maybe$Just(
				A3(
					$elm$core$List$foldr,
					extractor,
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (resource) {
							if (resource.$ === 3) {
								var r = resource.c;
								return $elm$core$Maybe$Just(r);
							} else {
								return $elm$core$Maybe$Nothing;
							}
						},
						A2(
							$elm$core$List$filter,
							function (resource) {
								if (resource.$ === 3) {
									var t = resource.a;
									return _Utils_eq(t, rType);
								} else {
									return false;
								}
							},
							list))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$ApplicationResources$getAllCompanyPeople = function (api) {
	var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(api);
	if (!_v0.$) {
		var token = _v0.a;
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				$author$project$App$Service$ApplicationResources$pathCompanyPeopleFromPerson(token.Y),
				$author$project$App$Service$SpecificResources$TypePerson,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 13)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	} else {
		return _List_Nil;
	}
};
var $author$project$App$Page$Company$PageListCanceled$routeFromDetails = function (model) {
	var _v0 = model.gD;
	if (!_v0.$) {
		return $author$project$App$Route$CompanyListCanceled(model.b);
	} else {
		var stmtId = _v0.a;
		return A2($author$project$App$Route$CompanyListCanceledViewDetails, stmtId, model.b);
	}
};
var $author$project$App$Page$Company$PageListCanceled$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A3(
						$author$project$App$SharedState$LoadStatementsFromCompany,
						uModel.gx,
						$author$project$App$Page$Company$PageListCanceled$defaultFilter(uModel.b),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$CompanyMsg(
								$author$project$App$Page$ResponseMsgs$CompanyListCanceledMsg($author$project$App$Page$ResponseMsgs$CompanyListCanceledStatementsLoaded))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyListCanceledRoute(
									$author$project$App$Page$Company$PageListCanceled$routeFromDetails(uModel))))),
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var viewMsg = msg.a;
				var _v2 = model.gD;
				if (_v2.$ === 1) {
					var stmtId = _v2.a;
					var viewModel = _v2.b;
					var _v3 = A4($author$project$App$Components$ViewFullStatement$update, viewMsg, st.ia.h8, st, viewModel);
					var uViewModel = _v3.a;
					var viewCmd = _v3.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Company$PageListCanceled$Showing, stmtId, uViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageListCanceled$ViewFullStmtMsg, viewCmd),
						_List_Nil);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 2:
				var filter = msg.a;
				var loadCompanyPeople = ($elm$core$List$length(
					$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)) <= 0) ? _List_fromArray(
					[
						$author$project$App$SharedState$LoadCompanyPeopleFromPerson(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$CompanyMsg(
								$author$project$App$Page$ResponseMsgs$CompanyListConcludedMsg($author$project$App$Page$ResponseMsgs$CompanyListConcludedCompanyPeopleLoaded))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_Utils_ap(
							_List_fromArray(
								[
									A3(
									$author$project$App$SharedState$LoadStatementsFromCompany,
									model.gx,
									$author$project$App$Page$Company$PageListCanceled$defaultFilter(filter),
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$CompanyMsg(
											$author$project$App$Page$ResponseMsgs$CompanyListCanceledMsg($author$project$App$Page$ResponseMsgs$CompanyListCanceledStatementsLoaded))
										]))
								]),
							loadCompanyPeople)));
			case 3:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$CompanyMsg(
											$author$project$App$Page$ResponseMsgs$CompanyListCanceledMsg(
												$author$project$App$Page$ResponseMsgs$CompanyListCanceledPersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			case 4:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyListCanceledRoute(
									A2(
										$author$project$App$Route$CompanyListCanceledViewDetails,
										stmtId,
										$author$project$App$Page$Company$PageListCanceled$defaultFilter(model.b)))))),
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementWithMinors,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1),
										$author$project$App$SharedState$FullStmtAssetAnswers(2),
										$author$project$App$SharedState$FullStmtAssetQuestions(2),
										$author$project$App$SharedState$FullStmtAssetAnswers(3),
										$author$project$App$SharedState$FullStmtAssetQuestions(3),
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetICD
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$CompanyMsg(
										$author$project$App$Page$ResponseMsgs$CompanyListCanceledMsg(
											$author$project$App$Page$ResponseMsgs$CompanyListCanceledFullStatementLoaded(stmtId)))
									]))
							])));
			default:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{gD: $author$project$App$Page$Company$PageListCanceled$NoDetails}),
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyListCanceledRoute(
									$author$project$App$Route$CompanyListCanceled(model.b))))),
					_List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$CompanyListConcludedFullStatementLoaded = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyListConcludedPersonLoaded = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyListConcludedStatementsLoaded = {$: 2};
var $author$project$App$Page$Company$PageListConcluded$NoDetails = {$: 0};
var $author$project$App$Page$Company$PageListConcluded$Showing = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Data$StatementStatus$StatusAcceptedFull = 33;
var $author$project$App$Page$Company$PageListConcluded$defaultFilter = function (filter) {
	return A2(
		$author$project$App$Components$Filter$setOrder,
		'updatedAt,desc',
		A2(
			$author$project$App$Components$Filter$addRule,
			'status,>=,' + $author$project$App$Data$StatementStatus$statusToString(33),
			filter));
};
var $author$project$App$Page$Company$PageListConcluded$routeFromDetails = function (model) {
	var _v0 = model.gD;
	if (!_v0.$) {
		return $author$project$App$Route$CompanyListConcluded(model.b);
	} else {
		var stmtId = _v0.a;
		return A2($author$project$App$Route$CompanyListConcludedViewDetails, stmtId, model.b);
	}
};
var $author$project$App$Page$Company$PageListConcluded$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A3(
						$author$project$App$SharedState$LoadStatementsFromCompany,
						uModel.gx,
						$author$project$App$Page$Company$PageListConcluded$defaultFilter(uModel.b),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$CompanyMsg(
								$author$project$App$Page$ResponseMsgs$CompanyListConcludedMsg($author$project$App$Page$ResponseMsgs$CompanyListConcludedStatementsLoaded))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyListConcludedRoute(
									$author$project$App$Page$Company$PageListConcluded$routeFromDetails(uModel))))),
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var viewMsg = msg.a;
				var _v2 = model.gD;
				if (_v2.$ === 1) {
					var stmtId = _v2.a;
					var viewModel = _v2.b;
					var _v3 = A4($author$project$App$Components$ViewFullStatement$update, viewMsg, st.ia.h8, st, viewModel);
					var uViewModel = _v3.a;
					var viewCmd = _v3.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Company$PageListConcluded$Showing, stmtId, uViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageListConcluded$ViewFullStmtMsg, viewCmd),
						_List_Nil);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 2:
				var filter = msg.a;
				var loadCompanyPeople = ($elm$core$List$length(
					$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)) <= 0) ? _List_fromArray(
					[
						$author$project$App$SharedState$LoadCompanyPeopleFromPerson(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$CompanyMsg(
								$author$project$App$Page$ResponseMsgs$CompanyListConcludedMsg($author$project$App$Page$ResponseMsgs$CompanyListConcludedCompanyPeopleLoaded))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_Utils_ap(
							_List_fromArray(
								[
									A3(
									$author$project$App$SharedState$LoadStatementsFromCompany,
									model.gx,
									$author$project$App$Page$Company$PageListConcluded$defaultFilter(filter),
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$CompanyMsg(
											$author$project$App$Page$ResponseMsgs$CompanyListConcludedMsg($author$project$App$Page$ResponseMsgs$CompanyListConcludedStatementsLoaded))
										]))
								]),
							loadCompanyPeople)));
			case 3:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$CompanyMsg(
											$author$project$App$Page$ResponseMsgs$CompanyListConcludedMsg(
												$author$project$App$Page$ResponseMsgs$CompanyListConcludedPersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			case 4:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyListConcludedRoute(
									A2(
										$author$project$App$Route$CompanyListConcludedViewDetails,
										stmtId,
										$author$project$App$Page$Company$PageListConcluded$defaultFilter(model.b)))))),
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementWithMinors,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1),
										$author$project$App$SharedState$FullStmtAssetAnswers(2),
										$author$project$App$SharedState$FullStmtAssetQuestions(2),
										$author$project$App$SharedState$FullStmtAssetAnswers(3),
										$author$project$App$SharedState$FullStmtAssetQuestions(3),
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetICD
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$CompanyMsg(
										$author$project$App$Page$ResponseMsgs$CompanyListConcludedMsg(
											$author$project$App$Page$ResponseMsgs$CompanyListConcludedFullStatementLoaded(stmtId)))
									]))
							])));
			case 5:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{gD: $author$project$App$Page$Company$PageListConcluded$NoDetails}),
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyListConcludedRoute(
									$author$project$App$Route$CompanyListConcluded(model.b))))),
					_List_Nil);
			default:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyViewEvaluationRoute(stmtId)))),
					_List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$CompanyListPendingFullStatementLoaded = {$: 4};
var $author$project$App$Page$ResponseMsgs$CompanyListPendingMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyListPendingPersonLoaded = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyListPendingStatementsLoaded = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$App$SharedState$RequestStatementReview = F2(
	function (a, b) {
		return {$: 66, a: a, b: b};
	});
var $author$project$App$SharedState$RequestStatementUnschedule = F2(
	function (a, b) {
		return {$: 67, a: a, b: b};
	});
var $author$project$App$Data$StatementStatus$StatusAcceptedWaitingDep = 32;
var $author$project$App$Page$Company$PageListPending$defaultFilter = function (filter) {
	return A2(
		$author$project$App$Components$Filter$setOrder,
		'updatedAt,desc',
		A2(
			$author$project$App$Components$Filter$addRule,
			'status,!=,' + $author$project$App$Data$StatementStatus$statusToString(28),
			A2(
				$author$project$App$Components$Filter$addRule,
				'status,<=,' + $author$project$App$Data$StatementStatus$statusToString(32),
				filter)));
};
var $author$project$App$Page$Company$PageListPending$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A3(
						$author$project$App$SharedState$LoadStatementsFromCompany,
						uModel.gx,
						uModel.b,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$CompanyMsg(
								$author$project$App$Page$ResponseMsgs$CompanyListPendingMsg(
									A2($author$project$App$Page$ResponseMsgs$CompanyListPendingStatementsLoaded, uModel.gx, uModel.b)))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyListPendingRoute(
									$author$project$App$Route$CompanyListPending(uModel.b))))),
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var cId = msg.a;
				var filter = msg.b;
				var loadCompanyPeople = ($elm$core$List$length(
					$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)) <= 0) ? _List_fromArray(
					[
						$author$project$App$SharedState$LoadCompanyPeopleFromPerson(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$CompanyMsg(
								$author$project$App$Page$ResponseMsgs$CompanyListConcludedMsg($author$project$App$Page$ResponseMsgs$CompanyListConcludedCompanyPeopleLoaded))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_Utils_ap(
							_List_fromArray(
								[
									A3(
									$author$project$App$SharedState$LoadStatementsFromCompany,
									cId,
									filter,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$CompanyMsg(
											$author$project$App$Page$ResponseMsgs$CompanyListPendingMsg(
												A2($author$project$App$Page$ResponseMsgs$CompanyListPendingStatementsLoaded, cId, filter)))
										]))
								]),
							loadCompanyPeople)));
			case 2:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$CompanyMsg(
											$author$project$App$Page$ResponseMsgs$CompanyListPendingMsg(
												$author$project$App$Page$ResponseMsgs$CompanyListPendingPersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			case 3:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyRequestInterview($author$project$App$Route$CompanyRequestInterviewNew)))),
					_List_Nil);
			case 4:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyRequestInterview(
									$author$project$App$Route$CompanyRequestInterviewEdit(stmtId))))),
					_List_Nil);
			case 5:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyListPendingRoute(
									A2(
										$author$project$App$Route$CompanyListPendingViewDetails,
										stmtId,
										$author$project$App$Page$Company$PageListPending$defaultFilter($author$project$App$Components$Filter$empty)))))),
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementWithMinors,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1),
										$author$project$App$SharedState$FullStmtAssetAnswers(2),
										$author$project$App$SharedState$FullStmtAssetQuestions(2),
										$author$project$App$SharedState$FullStmtAssetAnswers(3),
										$author$project$App$SharedState$FullStmtAssetQuestions(3),
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetICD
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$CompanyMsg(
										$author$project$App$Page$ResponseMsgs$CompanyListPendingMsg($author$project$App$Page$ResponseMsgs$CompanyListPendingFullStatementLoaded))
									]))
							])));
			case 6:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: $elm$core$Maybe$Nothing, av: $elm$core$Maybe$Nothing}),
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyListPendingRoute(
									$author$project$App$Route$CompanyListPending(
										$author$project$App$Page$Company$PageListPending$defaultFilter($author$project$App$Components$Filter$empty)))))),
					_List_Nil);
			case 7:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2($author$project$App$SharedState$RequestStatementReview, stmtId, _List_Nil))
						]));
			case 8:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2($author$project$App$SharedState$RequestStatementUnschedule, stmtId, _List_Nil))
						]));
			default:
				var viewMsg = msg.a;
				var _v2 = function () {
					var _v3 = model.av;
					if (!_v3.$) {
						var viewFullStmt = _v3.a;
						return function (_v4) {
							var a = _v4.a;
							var b = _v4.b;
							return _Utils_Tuple3(
								$elm$core$Maybe$Just(viewFullStmt.a5.g.e.as),
								$elm$core$Maybe$Just(a),
								b);
						}(
							A4($author$project$App$Components$ViewFullStatement$update, viewMsg, st.ia.h8, st, viewFullStmt));
					} else {
						return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
					}
				}();
				var showStmt = _v2.a;
				var uViewFullStmt = _v2.b;
				var uViewFullStmtMsg = _v2.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: showStmt, av: uViewFullStmt}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageListPending$ViewFullStmtMsg, uViewFullStmtMsg),
					_List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$CompanyListReevaluationLoaded = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$CompanyListReevaluationMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyListReevaluationPersonLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Company$PageListReevaluation$update = F3(
	function (msg, sharedState, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A3(
						$author$project$App$SharedState$LoadReevalStatementsFromCompany,
						model.gx,
						uModel.b,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$CompanyMsg(
								$author$project$App$Page$ResponseMsgs$CompanyListReevaluationMsg(
									A2($author$project$App$Page$ResponseMsgs$CompanyListReevaluationLoaded, uModel.gx, uModel.b)))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company($author$project$App$Route$CompanyListReevaluation))),
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var filter = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadReevalStatementsFromCompany,
								model.gx,
								filter,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$CompanyMsg(
										$author$project$App$Page$ResponseMsgs$CompanyListReevaluationMsg(
											A2($author$project$App$Page$ResponseMsgs$CompanyListReevaluationLoaded, model.gx, filter)))
									]))
							])));
			case 2:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$CompanyMsg(
											$author$project$App$Page$ResponseMsgs$CompanyListReevaluationMsg(
												$author$project$App$Page$ResponseMsgs$CompanyListReevaluationPersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			case 3:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyReevaluationDetails(stmtId)))),
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementWithMinors,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1),
										$author$project$App$SharedState$FullStmtAssetAnswers(2),
										$author$project$App$SharedState$FullStmtAssetQuestions(2),
										$author$project$App$SharedState$FullStmtAssetAnswers(3),
										$author$project$App$SharedState$FullStmtAssetQuestions(3),
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetICD
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$CompanyMsg(
										$author$project$App$Page$ResponseMsgs$CompanyListReevaluationMsg(
											A2($author$project$App$Page$ResponseMsgs$CompanyListReevaluationLoaded, model.gx, $author$project$App$Components$Filter$empty)))
									]))
							])));
			case 4:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: $elm$core$Maybe$Nothing, av: $elm$core$Maybe$Nothing}),
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company($author$project$App$Route$CompanyListReevaluation))),
					_List_Nil);
			case 5:
				var viewMsg = msg.a;
				var _v2 = function () {
					var _v3 = model.av;
					if (!_v3.$) {
						var viewFullStmt = _v3.a;
						return function (_v4) {
							var a = _v4.a;
							var b = _v4.b;
							return _Utils_Tuple3(
								$elm$core$Maybe$Just(viewFullStmt.a5.g.e.as),
								$elm$core$Maybe$Just(a),
								b);
						}(
							A4($author$project$App$Components$ViewFullStatement$update, viewMsg, sharedState.ia.h8, sharedState, viewFullStmt));
					} else {
						return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
					}
				}();
				var showStmt = _v2.a;
				var uViewFullStmt = _v2.b;
				var uViewFullStmtMsg = _v2.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: showStmt, av: uViewFullStmt}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageListReevaluation$ViewFullStmtMsg, uViewFullStmtMsg),
					_List_Nil);
			default:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyReevaluationDetails(stmtId)))),
					_List_Nil);
		}
	});
var $author$project$App$Page$Company$PageReevaluation$ChatBoxMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyReevaluationMessageSent = 1;
var $author$project$App$Page$ResponseMsgs$CompanyReevaluationMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyReevaluationReload = 4;
var $author$project$App$Page$Company$PageReevaluation$DPSSelected = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Page$Company$PageReevaluation$DocumentLoaded = function (a) {
	return {$: 12, a: a};
};
var $author$project$App$SharedState$SaveDocuments = F2(
	function (a, b) {
		return {$: 65, a: a, b: b};
	});
var $author$project$App$Data$Document$emptyModel = {
	iU: '',
	iW: $elm$time$Time$millisToPosix(0),
	b7: 0,
	as: $author$project$App$Data$Id$uuidNil,
	dr: '',
	hl: '',
	af: '',
	Q: false,
	jJ: $author$project$App$Data$Id$uuidNil,
	X: '',
	Y: $author$project$App$Data$Id$uuidNil,
	f2: $elm$time$Time$millisToPosix(0)
};
var $elm$file$File$Select$file = F2(
	function (mimes, toMsg) {
		return A2(
			$elm$core$Task$perform,
			toMsg,
			_File_uploadOne(mimes));
	});
var $elm$file$File$mime = _File_mime;
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === -2) {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $elm$file$File$toUrl = _File_toUrl;
var $author$project$App$Page$Company$PageReevaluation$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 1:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company($author$project$App$Route$CompanyListReevaluation))),
					_List_Nil);
			case 3:
				var emptyMessage = $author$project$App$Data$StatementMessage$emptyModel;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$StatementSendToDoctorReview,
								_Utils_update(
									emptyMessage,
									{jl: '', aV: model.P.g.e.as}),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$CompanyMsg(
										$author$project$App$Page$ResponseMsgs$CompanyReevaluationMsg(1))
									])))
						]));
			case 4:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$StatementFinishReevaluation,
								model.P.g.e,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$CompanyMsg(
										$author$project$App$Page$ResponseMsgs$CompanyReevaluationMsg(1))
									])))
						]));
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: !model.dC}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 5:
				var str = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{eN: str}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 6:
				var idx = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{b5: idx}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 7:
				var time = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 8:
				var docMsg = msg.a;
				var docsUpdated = A2(
					$elm$core$Dict$map,
					F2(
						function (_v1, doc) {
							return A2($author$project$App$Data$Document$updateUrl, docMsg, doc);
						}),
					model.an);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{an: docsUpdated}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 9:
				if (!msg.a.$) {
					var messageText = msg.a.a;
					var emptyMessage = $author$project$App$Data$StatementMessage$emptyModel;
					var newMessage = _Utils_update(
						emptyMessage,
						{jl: messageText, aV: model.eV});
					var _v2 = A2(
						$author$project$App$Components$Chatbox$update,
						$author$project$App$Components$Chatbox$SendMessage(messageText),
						model.aM);
					var chatBoxUpdated = _v2.a;
					var chatCmd = _v2.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aM: chatBoxUpdated}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageReevaluation$ChatBoxMsg, chatCmd),
						_List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$SendStatementMessage, newMessage, _List_Nil))
							]));
				} else {
					var chatMsg = msg.a;
					var _v3 = A2($author$project$App$Components$Chatbox$update, chatMsg, model.aM);
					var chatBoxUpdated = _v3.a;
					var chatCmd = _v3.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aM: chatBoxUpdated}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageReevaluation$ChatBoxMsg, chatCmd),
						_List_Nil);
				}
			case 10:
				return _Utils_Tuple3(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['application/pdf', 'image/png', 'image/jpeg']),
						$author$project$App$Page$Company$PageReevaluation$DPSSelected),
					_List_Nil);
			case 11:
				var file = msg.a;
				var now = $elm$time$Time$posixToMillis(
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
				var mime = $elm$file$File$mime(file);
				var emptyDoc = $author$project$App$Data$Document$emptyModel;
				var newDocument = _Utils_update(
					emptyDoc,
					{
						iU: '',
						b7: 0,
						dr: mime,
						hl: 'DPS ' + $elm$core$String$fromInt(now),
						af: '',
						Q: false
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ew: $elm$core$Maybe$Just(newDocument)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$perform,
								$author$project$App$Page$Company$PageReevaluation$DocumentLoaded,
								$elm$file$File$toUrl(file))
							])),
					_List_Nil);
			case 12:
				var content = msg.a;
				var idx = $elm$core$Dict$size(model.P.an);
				var fullStmtFromModel = model.P;
				var doc = function () {
					var _v5 = model.ew;
					if (!_v5.$) {
						var d = _v5.a;
						return d;
					} else {
						return $author$project$App$Data$Document$emptyModel;
					}
				}();
				var _v4 = $author$project$App$Data$Document$requestUrl(
					_Utils_update(
						doc,
						{iU: content}));
				var updatedDoc = _v4.a;
				var dCmd = _v4.b;
				var updatedStmt = _Utils_update(
					fullStmtFromModel,
					{
						an: A3($elm$core$Dict$insert, idx, updatedDoc, fullStmtFromModel.an)
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{P: updatedStmt}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$SaveDocuments,
								updatedStmt,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$CompanyMsg(
										$author$project$App$Page$ResponseMsgs$CompanyReevaluationMsg(4))
									])))
						]));
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$CompanyRequestInterviewFullStatementLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyRequestInterviewFullStatementPersisted = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyRequestInterviewMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyRequestInterviewPersonLoadedByDocument = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$CompanyRequestInterviewStatementFromPersonLoaded = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Company$PageRequestInterview$DocumentLoaded = function (a) {
	return {$: 16, a: a};
};
var $author$project$App$Page$Company$PageRequestInterview$DocumentSelected = function (a) {
	return {$: 15, a: a};
};
var $author$project$App$Data$Person$FieldSocialName = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Company$PageRequestInterview$GotTemplates = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$SharedState$LoadFullStatement = F3(
	function (a, b, c) {
		return {$: 50, a: a, b: b, c: c};
	});
var $author$project$App$SharedState$LoadPersonByDocument = F2(
	function (a, b) {
		return {$: 34, a: a, b: b};
	});
var $author$project$App$SharedState$LoadStatementFromPerson = F2(
	function (a, b) {
		return {$: 60, a: a, b: b};
	});
var $author$project$App$Page$Company$PageRequestInterview$No = 0;
var $author$project$App$SharedState$PersistFullStatement = F3(
	function (a, b, c) {
		return {$: 62, a: a, b: b, c: c};
	});
var $author$project$App$Components$ViewFullStatement$TabDocument = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Company$PageRequestInterview$Yes = 1;
var $author$project$App$Data$Document$documentClearUrl = _Platform_outgoingPort(
	'documentClearUrl',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'content',
					$elm$json$Json$Encode$string($.iU)),
					_Utils_Tuple2(
					'docType',
					$elm$json$Json$Encode$int($.b7)),
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string($.as)),
					_Utils_Tuple2(
					'mime',
					$elm$json$Json$Encode$string($.dr)),
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string($.hl)),
					_Utils_Tuple2(
					'objectUrl',
					$elm$json$Json$Encode$string($.af)),
					_Utils_Tuple2(
					'objectUrlRequested',
					$elm$json$Json$Encode$bool($.Q)),
					_Utils_Tuple2(
					'ownerId',
					$elm$json$Json$Encode$string($.jJ)),
					_Utils_Tuple2(
					'ownerKind',
					$elm$json$Json$Encode$string($.X)),
					_Utils_Tuple2(
					'personId',
					$elm$json$Json$Encode$string($.Y))
				]));
	});
var $author$project$App$Data$Document$clearUrl = function (model) {
	return $author$project$App$Data$Document$documentClearUrl(
		$author$project$App$Data$Document$portFromModel(model));
};
var $author$project$App$Page$Company$PageRequestInterview$errorsDictIsEmpty = function (errors) {
	return !$elm$core$Dict$size(
		A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, fields) {
					return $elm$core$List$length(fields) > 0;
				}),
			errors));
};
var $author$project$App$Data$Statement$NoDoc = 0;
var $author$project$App$Data$Statement$ParentRelation = 12;
var $author$project$App$Data$Statement$StatementTemplate = 2;
var $author$project$App$Data$Statement$BirthDate = 6;
var $author$project$App$Data$Statement$CPF = 3;
var $author$project$App$Data$Statement$Email = 10;
var $author$project$App$Data$Statement$Height = 9;
var $author$project$App$Data$Statement$Mobile = 11;
var $author$project$App$Data$Statement$Name = 4;
var $author$project$App$Data$Statement$Sex = 7;
var $author$project$App$Data$Statement$ShouldInterviewDepsOnly = 2;
var $author$project$App$Data$Statement$Surname = 5;
var $author$project$App$Data$Statement$Weight = 8;
var $author$project$App$Data$Person$ageOnDate = F2(
	function (ageOn, person) {
		return (($elm$time$Time$posixToMillis(ageOn) - $elm$time$Time$posixToMillis(person.al)) / ((((60 * 60) * 24) * 365) * 1000)) | 0;
	});
var $author$project$App$Page$Company$PageRequestInterview$errorsFromEditStatement = F3(
	function (now, editStmt, requiredFields) {
		var invalidWeight = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'weight-height';
				},
				requiredFields))) ? _List_Nil : (((editStmt.e.iT < 100) && (editStmt.e.j_ !== 2)) ? _List_fromArray(
			[8]) : _List_Nil);
		var invalidTemplateId = (!$author$project$App$Data$Id$uuidIsValid(editStmt.e.eY)) ? _List_fromArray(
			[2]) : _List_Nil;
		var invalidSurname = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'name';
				},
				requiredFields))) ? _List_Nil : ((editStmt.dw.ii === '') ? _List_fromArray(
			[5]) : _List_Nil);
		var invalidSex = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'sex';
				},
				requiredFields))) ? _List_Nil : ((!editStmt.dw.dJ) ? _List_fromArray(
			[7]) : _List_Nil);
		var invalidRelation = _Utils_eq(editStmt.e.be, $elm$core$Maybe$Nothing) ? _List_fromArray(
			[12]) : _List_Nil;
		var invalidName = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'name';
				},
				requiredFields))) ? _List_Nil : ((editStmt.dw.hl === '') ? _List_fromArray(
			[4]) : _List_Nil);
		var invalidHeight = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'weight-height';
				},
				requiredFields))) ? _List_Nil : (((editStmt.e.iS < 10) && (editStmt.e.j_ !== 2)) ? _List_fromArray(
			[9]) : _List_Nil);
		var invalidDocument = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'cpf';
				},
				requiredFields))) ? _List_Nil : (($elm$core$String$length(editStmt.dw.i1) !== 11) ? _List_fromArray(
			[3]) : _List_Nil);
		var invalidBirthDate = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'birthdate';
				},
				requiredFields))) ? _List_Nil : (_Utils_eq(
			editStmt.dw.al,
			$elm$time$Time$millisToPosix(0)) ? _List_fromArray(
			[6]) : _List_Nil);
		var getContactValue = function (field) {
			return A2(
				$elm$core$Maybe$withDefault,
				$author$project$App$Data$Contact$emptyModel(
					$author$project$App$Data$Contact$kindFromString(field)),
				A2($elm$core$Dict$get, field, editStmt.ea)).T;
		};
		var invalidEmail = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'email';
				},
				requiredFields))) ? _List_Nil : (((getContactValue('email') === '') && (A2($author$project$App$Data$Person$ageOnDate, now, editStmt.dw) >= 18)) ? _List_fromArray(
			[10]) : _List_Nil);
		var invalidMobile = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'phoneNumber';
				},
				requiredFields))) ? _List_Nil : ((($elm$core$String$length(
			getContactValue('mobile')) < 11) && (A2($author$project$App$Data$Person$ageOnDate, now, editStmt.dw) >= 18)) ? _List_fromArray(
			[11]) : _List_Nil);
		var errors = ($elm$core$List$length(invalidDocument) > 0) ? invalidDocument : _Utils_ap(
			invalidDocument,
			_Utils_ap(
				invalidTemplateId,
				_Utils_ap(
					invalidRelation,
					_Utils_ap(
						invalidName,
						_Utils_ap(
							invalidSurname,
							_Utils_ap(
								invalidBirthDate,
								_Utils_ap(
									invalidSex,
									_Utils_ap(
										invalidWeight,
										_Utils_ap(
											invalidHeight,
											_Utils_ap(invalidEmail, invalidMobile))))))))));
		return errors;
	});
var $elm$core$Dict$isEmpty = function (dict) {
	if (dict.$ === -2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$App$Page$Company$PageRequestInterview$errorsFromStatement = F3(
	function (now, fullStmt, modelForm) {
		var stmtTemplate = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$StatementTemplate$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.as, fullStmt.g.e.eY);
					},
					modelForm.eZ)));
		var noDoc = $elm$core$Dict$isEmpty(fullStmt.an);
		var emptyForm = {
			gx: $author$project$App$Data$Id$uuidNil,
			b5: $author$project$App$Components$ViewFullStatement$TabDocument(0),
			fp: $elm$core$Dict$empty,
			a5: $author$project$App$Data$FullStatement$emptyFullStatement,
			ep: false,
			cH: 0,
			eZ: _List_Nil
		};
		var depsErrors = A2(
			$elm$core$Dict$map,
			F2(
				function (_v1, v) {
					return A2(
						$elm$core$List$filter,
						function (field) {
							return field !== 2;
						},
						v);
				}),
			A2(
				$elm$core$Dict$map,
				F2(
					function (_v0, v) {
						return A3($author$project$App$Page$Company$PageRequestInterview$errorsFromEditStatement, now, v, stmtTemplate.eJ);
					}),
				fullStmt.fh));
		var bearerErrors = A2(
			$elm$core$List$filter,
			function (field) {
				return field !== 12;
			},
			A3($author$project$App$Page$Company$PageRequestInterview$errorsFromEditStatement, now, fullStmt.g, stmtTemplate.eJ));
		return (noDoc && fullStmt.cJ.hR) ? $elm$core$Dict$fromList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					-1,
					_List_fromArray(
						[0]))
				])) : A3($elm$core$Dict$insert, -1, bearerErrors, depsErrors);
	});
var $author$project$App$Page$Company$PageRequestInterview$formFromModel = function (m) {
	if (!m.$) {
		var modelForm = m.a;
		return modelForm;
	} else {
		var modelForm = m.b;
		return modelForm;
	}
};
var $author$project$App$Page$Company$PageRequestInterview$getContactWithKind = F2(
	function (kind, dict) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Contact$emptyModel(kind),
			A2(
				$elm$core$Dict$get,
				$author$project$App$Data$Contact$kindToString(kind),
				dict));
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$App$Page$Company$PageRequestInterview$Edit = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$Company$PageRequestInterview$New = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Company$PageRequestInterview$updateForm = F2(
	function (f, m) {
		if (!m.$) {
			return $author$project$App$Page$Company$PageRequestInterview$New(f);
		} else {
			var stmtId = m.a;
			return A2($author$project$App$Page$Company$PageRequestInterview$Edit, stmtId, f);
		}
	});
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $author$project$App$Data$Person$clearDocument = function (doc) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return A3(
			$elm$core$String$slice,
			0,
			11,
			A3(
				$elm$regex$Regex$replace,
				regex,
				function (_v1) {
					return '';
				},
				doc));
	} else {
		return doc;
	}
};
var $author$project$App$Data$Person$setUpdated = function (model) {
	return _Utils_update(
		model,
		{
			f2: $elm$time$Time$millisToPosix(
				1 + $elm$time$Time$posixToMillis(model.f2))
		});
};
var $author$project$App$Data$Person$updateModel = F2(
	function (field, model) {
		switch (field.$) {
			case 0:
				var name = field.a;
				return $author$project$App$Data$Person$setUpdated(
					_Utils_update(
						model,
						{hl: name}));
			case 1:
				var surname = field.a;
				return $author$project$App$Data$Person$setUpdated(
					_Utils_update(
						model,
						{ii: surname}));
			case 2:
				var socialName = field.a;
				return $author$project$App$Data$Person$setUpdated(
					_Utils_update(
						model,
						{B: socialName}));
			case 3:
				var doc = field.a;
				return $author$project$App$Data$Person$setUpdated(
					_Utils_update(
						model,
						{
							i1: $author$project$App$Data$Person$clearDocument(doc)
						}));
			case 4:
				var sex = field.a;
				return $author$project$App$Data$Person$setUpdated(
					_Utils_update(
						model,
						{
							dJ: $author$project$App$Data$Person$sexFromString(sex)
						}));
			case 5:
				var cs = field.a;
				return $author$project$App$Data$Person$setUpdated(
					_Utils_update(
						model,
						{
							D: $author$project$App$Data$Person$civilStatusFromString(cs)
						}));
			case 7:
				var mn = field.a;
				return $author$project$App$Data$Person$setUpdated(
					_Utils_update(
						model,
						{cp: mn}));
			case 8:
				var photo = field.a;
				return $author$project$App$Data$Person$setUpdated(
					_Utils_update(
						model,
						{
							dy: function () {
								if (photo === '') {
									return $elm$core$Maybe$Nothing;
								} else {
									return $elm$core$Maybe$Just(photo);
								}
							}(),
							cD: function () {
								if (photo === '') {
									return !_Utils_eq(model.dy, $elm$core$Maybe$Nothing);
								} else {
									var newPhoto = photo;
									return !_Utils_eq(
										model.dy,
										$elm$core$Maybe$Just(newPhoto));
								}
							}()
						}));
			default:
				var bd = field.a;
				return $author$project$App$Data$Person$setUpdated(
					_Utils_update(
						model,
						{
							al: function () {
								var _v3 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(bd);
								if (!_v3.$) {
									var posix = _v3.a;
									return $elm$time$Time$millisToPosix(
										$elm$time$Time$posixToMillis(posix) + 43200000);
								} else {
									return $elm$time$Time$millisToPosix(0);
								}
							}()
						}));
		}
	});
var $author$project$App$Data$Statement$cleanHeight = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$String$toInt(
				A3(
					$elm$regex$Regex$replace,
					regex,
					function (_v1) {
						return '';
					},
					str)));
	} else {
		return 0;
	}
};
var $author$project$App$Data$Statement$cleanWeight = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return 1000 * A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$String$toInt(
				A3(
					$elm$regex$Regex$replace,
					regex,
					function (_v1) {
						return '';
					},
					str)));
	} else {
		return 0;
	}
};
var $author$project$App$Data$Statement$Child = 2;
var $author$project$App$Data$Statement$Grandchild = 3;
var $author$project$App$Data$Statement$Spouse = 1;
var $author$project$App$Data$Statement$UndefinedRelation = 0;
var $author$project$App$Data$Statement$relationFromString = function (str) {
	switch (str) {
		case 'spouse':
			return 1;
		case 'child':
			return 2;
		case 'grandchild':
			return 3;
		default:
			return 0;
	}
};
var $author$project$App$Data$Statement$relationToMaybe = function (relation) {
	if (!relation) {
		return $elm$core$Maybe$Nothing;
	} else {
		return $elm$core$Maybe$Just(relation);
	}
};
var $author$project$App$Data$Statement$ShouldNotInterview = 0;
var $author$project$App$Data$Statement$shouldInterviewFromInt = function (value) {
	switch (value) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 0;
	}
};
var $author$project$App$Data$Statement$shouldInterviewFromString = function (str) {
	return $author$project$App$Data$Statement$shouldInterviewFromInt(
		A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$String$toInt(str)));
};
var $author$project$App$Data$Statement$updateModel = F3(
	function (now, field, model) {
		var updatedModel = function () {
			switch (field.$) {
				case 10:
					var value = field.a;
					return _Utils_update(
						model,
						{
							j_: $author$project$App$Data$Statement$shouldInterviewFromString(value)
						});
				case 0:
					var date = field.a;
					return _Utils_update(
						model,
						{
							gL: function () {
								var _v2 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(date);
								if (!_v2.$) {
									var posix = _v2.a;
									return posix;
								} else {
									return $elm$time$Time$millisToPosix(0);
								}
							}()
						});
				case 1:
					var relation = field.a;
					return _Utils_update(
						model,
						{
							be: $author$project$App$Data$Statement$relationToMaybe(
								$author$project$App$Data$Statement$relationFromString(relation))
						});
				case 2:
					var id = field.a;
					var templateId = $author$project$App$Data$Id$uuidIsValid(id) ? id : $author$project$App$Data$Id$uuidNil;
					return _Utils_update(
						model,
						{eY: templateId});
				case 3:
					var w = field.a;
					return _Utils_update(
						model,
						{
							iT: $author$project$App$Data$Statement$cleanWeight(w)
						});
				case 4:
					var h = field.a;
					return _Utils_update(
						model,
						{
							iS: $author$project$App$Data$Statement$cleanHeight(h)
						});
				case 5:
					var w = field.a;
					return _Utils_update(
						model,
						{
							jN: $author$project$App$Data$Statement$cleanWeight(w)
						});
				case 6:
					var h = field.a;
					return _Utils_update(
						model,
						{
							jL: $author$project$App$Data$Statement$cleanHeight(h)
						});
				case 7:
					var w = field.a;
					return _Utils_update(
						model,
						{
							ap: $author$project$App$Data$Statement$cleanWeight(w)
						});
				case 8:
					var h = field.a;
					return _Utils_update(
						model,
						{
							ao: $author$project$App$Data$Statement$cleanHeight(h)
						});
				case 9:
					var obs = field.a;
					return _Utils_update(
						model,
						{jx: obs});
				default:
					var d = field.a;
					var date = d + ':00-00:00';
					return _Utils_update(
						model,
						{
							jU: function () {
								var _v3 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(date);
								if (!_v3.$) {
									var posix = _v3.a;
									return $elm$time$Time$millisToPosix(
										$elm$time$Time$posixToMillis(posix));
								} else {
									return $elm$time$Time$millisToPosix(0);
								}
							}()
						});
			}
		}();
		var updatedAt = function (_v0) {
			var t = _v0.a;
			var b = _v0.b;
			return b ? $elm$time$Time$millisToPosix(t + 10000) : now;
		}(
			function (t) {
				return _Utils_Tuple2(
					t,
					_Utils_cmp(
						$elm$time$Time$posixToMillis(now),
						t) < 0);
			}(
				$elm$time$Time$posixToMillis(model.f2)));
		return _Utils_update(
			updatedModel,
			{f2: updatedAt});
	});
var $author$project$App$Page$Company$PageRequestInterview$update = F3(
	function (msg, st, m) {
		var modelForm = $author$project$App$Page$Company$PageRequestInterview$formFromModel(m);
		var fullStmt = modelForm.a5;
		switch (msg.$) {
			case 0:
				if (!m.$) {
					var companyId = m.a.gx;
					return _Utils_Tuple3(
						m,
						A3(
							$author$project$App$Service$StatementTemplateRest$getFromCompany,
							$author$project$App$Service$ApiWebSocket$getAuthToken(st._),
							companyId,
							$author$project$App$Page$Company$PageRequestInterview$GotTemplates),
						_List_Nil);
				} else {
					var stmtId = m.a;
					var companyId = m.b.gx;
					return _Utils_Tuple3(
						m,
						A3(
							$author$project$App$Service$StatementTemplateRest$getFromCompany,
							$author$project$App$Service$ApiWebSocket$getAuthToken(st._),
							companyId,
							$author$project$App$Page$Company$PageRequestInterview$GotTemplates),
						A2(
							$elm$core$List$map,
							$author$project$App$SharedState$APIMsg,
							_List_fromArray(
								[
									A3(
									$author$project$App$SharedState$LoadFullStatement,
									stmtId,
									_List_fromArray(
										[$author$project$App$SharedState$FullStmtAssetDocuments]),
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$CompanyMsg(
											$author$project$App$Page$ResponseMsgs$CompanyRequestInterviewMsg(
												$author$project$App$Page$ResponseMsgs$CompanyRequestInterviewFullStatementLoaded(stmtId)))
										]))
								])));
				}
			case 5:
				if (!msg.a.$) {
					var list = msg.a.a;
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Company$PageRequestInterview$updateForm,
							_Utils_update(
								modelForm,
								{eZ: list}),
							m),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				} else {
					return _Utils_Tuple3(m, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 1:
				var personId = msg.a;
				return _Utils_Tuple3(
					m,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$LoadStatementFromPerson,
								personId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$CompanyMsg(
										$author$project$App$Page$ResponseMsgs$CompanyRequestInterviewMsg(
											$author$project$App$Page$ResponseMsgs$CompanyRequestInterviewStatementFromPersonLoaded(personId)))
									])))
						]));
			case 2:
				return _Utils_Tuple3(
					m,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyListPendingRoute(
									$author$project$App$Route$CompanyListPending($author$project$App$Components$Filter$empty))))),
					_List_Nil);
			case 4:
				var stmtErrors = A3(
					$author$project$App$Page$Company$PageRequestInterview$errorsFromStatement,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					modelForm.a5,
					modelForm);
				var _v2 = $author$project$App$Page$Company$PageRequestInterview$errorsDictIsEmpty(stmtErrors) ? _Utils_Tuple2(
					1,
					_List_fromArray(
						[
							A3(
							$author$project$App$SharedState$PersistFullStatement,
							modelForm.gx,
							modelForm.a5,
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$CompanyMsg(
									$author$project$App$Page$ResponseMsgs$CompanyRequestInterviewMsg(
										$author$project$App$Page$ResponseMsgs$CompanyRequestInterviewFullStatementPersisted(modelForm.gx)))
								]))
						])) : _Utils_Tuple2(0, _List_Nil);
				var submitting = _v2.a;
				var persistMsg = _v2.b;
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Company$PageRequestInterview$updateForm,
						_Utils_update(
							modelForm,
							{fp: stmtErrors, cH: submitting}),
						m),
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, persistMsg));
			case 3:
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Company$PageRequestInterview$updateForm,
						_Utils_update(
							modelForm,
							{fp: $elm$core$Dict$empty}),
						m),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 6:
				var depIdx = msg.a;
				var fieldValue = msg.b;
				if (!depIdx.$) {
					var now = $author$project$App$Service$ApiWebSocket$getTimestamp(st._);
					var newDeps = A2(
						$elm$core$Dict$map,
						F2(
							function (_v4, v) {
								return _Utils_update(
									v,
									{
										e: A3($author$project$App$Data$Statement$updateModel, now, fieldValue, v.e)
									});
							}),
						fullStmt.fh);
					var bearer = fullStmt.g;
					var newBearer = _Utils_update(
						bearer,
						{
							e: A3($author$project$App$Data$Statement$updateModel, now, fieldValue, bearer.e)
						});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Company$PageRequestInterview$updateForm,
							_Utils_update(
								modelForm,
								{
									a5: _Utils_update(
										fullStmt,
										{g: newBearer, fh: newDeps})
								}),
							m),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				} else {
					var idx = depIdx.a;
					var now = $author$project$App$Service$ApiWebSocket$getTimestamp(st._);
					var dep = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$FullStatement$emptyEditStatement,
						A2($elm$core$Dict$get, idx, fullStmt.fh));
					var updatedStmt = A3($author$project$App$Data$Statement$updateModel, now, fieldValue, dep.e);
					var newDep = _Utils_update(
						dep,
						{e: updatedStmt});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Company$PageRequestInterview$updateForm,
							_Utils_update(
								modelForm,
								{
									a5: _Utils_update(
										fullStmt,
										{
											fh: A3($elm$core$Dict$insert, idx, newDep, fullStmt.fh)
										})
								}),
							m),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				}
			case 7:
				var depIdx = msg.a;
				var fieldValue = msg.b;
				var stmtTemplate = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$StatementTemplate$emptyModel,
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (t) {
								return _Utils_eq(t.as, fullStmt.g.e.eY);
							},
							modelForm.eZ)));
				var thereIsSocialNameField = A2($elm$core$List$member, 'socialName', stmtTemplate.eJ);
				var sharedMsg = function (person) {
					return $author$project$App$Data$Id$uuidIsValid(person.as) ? _List_Nil : _List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$LoadPersonByDocument,
								person.i1,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$CompanyMsg(
										$author$project$App$Page$ResponseMsgs$CompanyRequestInterviewMsg(
											A2($author$project$App$Page$ResponseMsgs$CompanyRequestInterviewPersonLoadedByDocument, person.i1, depIdx)))
									])))
						]);
				};
				if (!depIdx.$) {
					var bearer = fullStmt.g;
					var person = thereIsSocialNameField ? A2($author$project$App$Data$Person$updateModel, fieldValue, bearer.dw) : A2(
						$author$project$App$Data$Person$updateModel,
						$author$project$App$Data$Person$FieldSocialName(''),
						A2($author$project$App$Data$Person$updateModel, fieldValue, bearer.dw));
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Company$PageRequestInterview$updateForm,
							_Utils_update(
								modelForm,
								{
									a5: _Utils_update(
										fullStmt,
										{
											g: _Utils_update(
												bearer,
												{dw: person})
										})
								}),
							m),
						$elm$core$Platform$Cmd$none,
						sharedMsg(person));
				} else {
					var idx = depIdx.a;
					var dep = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$FullStatement$emptyEditStatement,
						A2($elm$core$Dict$get, idx, fullStmt.fh));
					var person = thereIsSocialNameField ? A2($author$project$App$Data$Person$updateModel, fieldValue, dep.dw) : A2(
						$author$project$App$Data$Person$updateModel,
						$author$project$App$Data$Person$FieldSocialName(''),
						A2($author$project$App$Data$Person$updateModel, fieldValue, dep.dw));
					var nDep = _Utils_update(
						dep,
						{dw: person});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Company$PageRequestInterview$updateForm,
							_Utils_update(
								modelForm,
								{
									a5: _Utils_update(
										fullStmt,
										{
											fh: A3($elm$core$Dict$insert, idx, nDep, fullStmt.fh)
										})
								}),
							m),
						$elm$core$Platform$Cmd$none,
						sharedMsg(person));
				}
			case 8:
				var mDepIdx = msg.a;
				var kind = msg.b;
				var fieldValue = msg.c;
				if (!mDepIdx.$) {
					var bearer = fullStmt.g;
					var contact = A2($author$project$App$Page$Company$PageRequestInterview$getContactWithKind, kind, bearer.ea);
					var updatedContact = A2($author$project$App$Data$Contact$updateModel, fieldValue, contact);
					var newBearer = _Utils_update(
						bearer,
						{
							ea: A3(
								$elm$core$Dict$insert,
								$author$project$App$Data$Contact$kindToString(kind),
								updatedContact,
								bearer.ea)
						});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Company$PageRequestInterview$updateForm,
							_Utils_update(
								modelForm,
								{
									a5: _Utils_update(
										fullStmt,
										{g: newBearer})
								}),
							m),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				} else {
					var idx = mDepIdx.a;
					var dep = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$FullStatement$emptyEditStatement,
						A2($elm$core$Dict$get, idx, fullStmt.fh));
					var contact = A2($author$project$App$Page$Company$PageRequestInterview$getContactWithKind, kind, dep.ea);
					var updatedContact = A2($author$project$App$Data$Contact$updateModel, fieldValue, contact);
					var newDep = _Utils_update(
						dep,
						{
							ea: A3(
								$elm$core$Dict$insert,
								$author$project$App$Data$Contact$kindToString(kind),
								updatedContact,
								dep.ea)
						});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Company$PageRequestInterview$updateForm,
							_Utils_update(
								modelForm,
								{
									a5: _Utils_update(
										fullStmt,
										{
											fh: A3($elm$core$Dict$insert, idx, newDep, fullStmt.fh)
										})
								}),
							m),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				}
			case 9:
				var templateId = fullStmt.g.e.eY;
				var idx = $elm$core$Dict$size(fullStmt.fh);
				var emptyStmt = $author$project$App$Data$FullStatement$emptyEditStatement;
				var stmtModel = emptyStmt.e;
				var bearerStmtId = fullStmt.g.e.as;
				var stmtDep = _Utils_update(
					emptyStmt,
					{
						e: _Utils_update(
							stmtModel,
							{
								gL: fullStmt.g.e.gL,
								jK: $elm$core$Maybe$Just(bearerStmtId),
								eY: templateId
							})
					});
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Company$PageRequestInterview$updateForm,
						_Utils_update(
							modelForm,
							{
								a5: _Utils_update(
									fullStmt,
									{
										fh: A3($elm$core$Dict$insert, idx, stmtDep, fullStmt.fh)
									})
							}),
						m),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 10:
				var idx = msg.a;
				var updatedDeps = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, _v7) {
								var dep = _v7.b;
								return _Utils_Tuple2(i, dep);
							}),
						$elm$core$Dict$toList(
							A2($elm$core$Dict$remove, idx, fullStmt.fh))));
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Company$PageRequestInterview$updateForm,
						_Utils_update(
							modelForm,
							{
								a5: _Utils_update(
									fullStmt,
									{fh: updatedDeps})
							}),
						m),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 12:
				var idx = $elm$core$Dict$size(fullStmt.an);
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Company$PageRequestInterview$updateForm,
						_Utils_update(
							modelForm,
							{
								b5: $author$project$App$Components$ViewFullStatement$TabDocument(idx),
								a5: _Utils_update(
									fullStmt,
									{
										an: A3($elm$core$Dict$insert, idx, $author$project$App$Data$Document$emptyModel, fullStmt.an)
									})
							}),
						m),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 11:
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Company$PageRequestInterview$updateForm,
						_Utils_update(
							modelForm,
							{d9: true}),
						m),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 13:
				var tab = function () {
					var _v10 = modelForm.b5;
					if (_v10.$ === 1) {
						if (!_v10.a) {
							return modelForm.b5;
						} else {
							var i = _v10.a;
							return $author$project$App$Components$ViewFullStatement$TabDocument(i - 1);
						}
					} else {
						return $author$project$App$Components$ViewFullStatement$TabDocument(0);
					}
				}();
				var idx = function () {
					var _v9 = modelForm.b5;
					if (_v9.$ === 1) {
						var i = _v9.a;
						return i;
					} else {
						return 0;
					}
				}();
				var updatedDocs = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, _v8) {
								var d = _v8.b;
								return _Utils_Tuple2(i, d);
							}),
						$elm$core$Dict$toList(
							A2($elm$core$Dict$remove, idx, fullStmt.an))));
				var doc = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$Document$emptyModel,
					A2($elm$core$Dict$get, idx, fullStmt.an));
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Company$PageRequestInterview$updateForm,
						_Utils_update(
							modelForm,
							{
								b5: tab,
								a5: _Utils_update(
									fullStmt,
									{
										an: updatedDocs,
										fO: A2(
											$elm$core$List$cons,
											_Utils_update(
												doc,
												{iU: ''}),
											fullStmt.fO)
									})
							}),
						m),
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$App$Page$Company$PageRequestInterview$DocumentMsg,
						$author$project$App$Data$Document$clearUrl(doc)),
					_List_Nil);
			case 14:
				return _Utils_Tuple3(
					m,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['application/pdf', 'image/png', 'image/jpeg']),
						$author$project$App$Page$Company$PageRequestInterview$DocumentSelected),
					_List_Nil);
			case 15:
				var file = msg.a;
				var now = $elm$time$Time$posixToMillis(
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
				var mime = $elm$file$File$mime(file);
				var idx = function () {
					var _v12 = modelForm.b5;
					if (_v12.$ === 1) {
						var i = _v12.a;
						return i;
					} else {
						return 0;
					}
				}();
				var doc = function () {
					var _v11 = A2($elm$core$Dict$get, idx, fullStmt.an);
					if (!_v11.$) {
						var d = _v11.a;
						return d;
					} else {
						return $author$project$App$Data$Document$emptyModel;
					}
				}();
				var updatedDoc = _Utils_update(
					doc,
					{
						iU: '',
						dr: mime,
						hl: 'Anexado em ' + $elm$core$String$fromInt(now),
						af: '',
						Q: false
					});
				var updatedStmt = _Utils_update(
					fullStmt,
					{
						an: A3($elm$core$Dict$insert, idx, updatedDoc, fullStmt.an)
					});
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Company$PageRequestInterview$updateForm,
						_Utils_update(
							modelForm,
							{a5: updatedStmt}),
						m),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$perform,
								$author$project$App$Page$Company$PageRequestInterview$DocumentLoaded,
								$elm$file$File$toUrl(file)),
								$author$project$App$Data$Document$clearUrl(doc)
							])),
					_List_Nil);
			case 16:
				var content = msg.a;
				var idx = function () {
					var _v15 = modelForm.b5;
					if (_v15.$ === 1) {
						var i = _v15.a;
						return i;
					} else {
						return 0;
					}
				}();
				var doc = function () {
					var _v14 = A2($elm$core$Dict$get, idx, fullStmt.an);
					if (!_v14.$) {
						var d = _v14.a;
						return d;
					} else {
						return $author$project$App$Data$Document$emptyModel;
					}
				}();
				var _v13 = $author$project$App$Data$Document$requestUrl(
					_Utils_update(
						doc,
						{iU: content}));
				var updatedDoc = _v13.a;
				var dCmd = _v13.b;
				var updatedStmt = _Utils_update(
					fullStmt,
					{
						an: A3($elm$core$Dict$insert, idx, updatedDoc, fullStmt.an)
					});
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Company$PageRequestInterview$updateForm,
						_Utils_update(
							modelForm,
							{a5: updatedStmt}),
						m),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageRequestInterview$DocumentMsg, dCmd),
					_List_Nil);
			case 17:
				var docMsg = msg.a;
				var docsUpdated = A2(
					$elm$core$Dict$map,
					F2(
						function (_v16, doc) {
							return A2($author$project$App$Data$Document$updateUrl, docMsg, doc);
						}),
					fullStmt.an);
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Company$PageRequestInterview$updateForm,
						_Utils_update(
							modelForm,
							{
								a5: _Utils_update(
									fullStmt,
									{an: docsUpdated})
							}),
						m),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 18:
				var tab = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Company$PageRequestInterview$updateForm,
						_Utils_update(
							modelForm,
							{b5: tab}),
						m),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			default:
				var time = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Company$PageRequestInterview$updateForm,
						_Utils_update(
							modelForm,
							{ey: time}),
						m),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
		}
	});
var $author$project$App$Page$Company$PageViewEvaluation$ChatBoxMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyViewEvaluationMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyViewEvaluationReload = 4;
var $author$project$App$Page$ResponseMsgs$CompanyViewReviewRequestSent = 2;
var $author$project$App$Page$Company$PageViewEvaluation$DPSSelected = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Page$Company$PageViewEvaluation$DocumentLoaded = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Page$Company$PageViewEvaluation$defaultFilter = function (filter) {
	return A2($author$project$App$Components$Filter$setOrder, 'created_at,desc', filter);
};
var $author$project$App$Page$Company$PageViewEvaluation$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 1:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyListConcludedRoute(
									$author$project$App$Route$CompanyListConcluded(
										$author$project$App$Page$Company$PageViewEvaluation$defaultFilter($author$project$App$Components$Filter$empty)))))),
					_List_Nil);
			case 3:
				var emptyMessage = $author$project$App$Data$StatementMessage$emptyModel;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$StatementSendToDoctorReview,
								_Utils_update(
									emptyMessage,
									{jl: model.eN, aV: model.P.g.e.as}),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$CompanyMsg(
										$author$project$App$Page$ResponseMsgs$CompanyViewEvaluationMsg(2))
									])))
						]));
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: !model.dC}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 4:
				var str = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{eN: str}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 5:
				var idx = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{b5: idx}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 6:
				var time = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 7:
				var docMsg = msg.a;
				var docsUpdated = A2(
					$elm$core$Dict$map,
					F2(
						function (_v1, doc) {
							return A2($author$project$App$Data$Document$updateUrl, docMsg, doc);
						}),
					model.an);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{an: docsUpdated}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 8:
				var chatMsg = msg.a;
				var _v2 = A2($author$project$App$Components$Chatbox$update, chatMsg, model.aM);
				var chatBoxUpdated = _v2.a;
				var chatCmd = _v2.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aM: chatBoxUpdated}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageViewEvaluation$ChatBoxMsg, chatCmd),
					_List_Nil);
			case 9:
				return _Utils_Tuple3(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['application/pdf', 'image/png', 'image/jpeg']),
						$author$project$App$Page$Company$PageViewEvaluation$DPSSelected),
					_List_Nil);
			case 10:
				var file = msg.a;
				var now = $elm$time$Time$posixToMillis(
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
				var mime = $elm$file$File$mime(file);
				var emptyDoc = $author$project$App$Data$Document$emptyModel;
				var newDocument = _Utils_update(
					emptyDoc,
					{
						iU: '',
						b7: 0,
						dr: mime,
						hl: 'DPS ' + $elm$core$String$fromInt(now),
						af: '',
						Q: false
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ew: $elm$core$Maybe$Just(newDocument)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$perform,
								$author$project$App$Page$Company$PageViewEvaluation$DocumentLoaded,
								$elm$file$File$toUrl(file))
							])),
					_List_Nil);
			case 11:
				var content = msg.a;
				var idx = $elm$core$Dict$size(model.P.an);
				var fullStmtFromModel = model.P;
				var doc = function () {
					var _v4 = model.ew;
					if (!_v4.$) {
						var d = _v4.a;
						return d;
					} else {
						return $author$project$App$Data$Document$emptyModel;
					}
				}();
				var _v3 = $author$project$App$Data$Document$requestUrl(
					_Utils_update(
						doc,
						{iU: content}));
				var updatedDoc = _v3.a;
				var dCmd = _v3.b;
				var updatedStmt = _Utils_update(
					fullStmtFromModel,
					{
						an: A3($elm$core$Dict$insert, idx, updatedDoc, fullStmtFromModel.an)
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{P: updatedStmt}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$SaveDocuments,
								updatedStmt,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$CompanyMsg(
										$author$project$App$Page$ResponseMsgs$CompanyViewEvaluationMsg(4))
									])))
						]));
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$Page$PageCompany$update = F3(
	function (msg, st, model) {
		var _v0 = _Utils_Tuple2(msg, model);
		switch (_v0.a.$) {
			case 1:
				if (_v0.b.$ === 1) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v1 = A3($author$project$App$Page$Company$PageListPending$update, pageMsg, st, pageModel);
					var nPageModel = _v1.a;
					var nPageMsg = _v1.b;
					var stMsgs = _v1.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$ListPending(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListPendingMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 2:
				if (_v0.b.$ === 2) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v2 = A3($author$project$App$Page$Company$PageListCanceled$update, pageMsg, st, pageModel);
					var nPageModel = _v2.a;
					var nPageMsg = _v2.b;
					var stMsgs = _v2.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$ListCanceled(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListCanceledMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 3:
				if (_v0.b.$ === 3) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v3 = A3($author$project$App$Page$Company$PageListConcluded$update, pageMsg, st, pageModel);
					var nPageModel = _v3.a;
					var nPageMsg = _v3.b;
					var stMsgs = _v3.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$ListConcluded(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListConcludedMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 0:
				if (!_v0.b.$) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v4 = A3($author$project$App$Page$Company$PageRequestInterview$update, pageMsg, st, pageModel);
					var nPageModel = _v4.a;
					var nPageMsg = _v4.b;
					var stMsgs = _v4.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$RequestInterview(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$RequestInterviewMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 6:
				if (_v0.b.$ === 6) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v5 = A3($author$project$App$Page$Company$PageViewEvaluation$update, pageMsg, st, pageModel);
					var nPageModel = _v5.a;
					var nPageMsg = _v5.b;
					var stMsgs = _v5.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$ViewEvaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ViewEvaluationMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 4:
				if (_v0.b.$ === 5) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v6 = A3($author$project$App$Page$Company$PageListReevaluation$update, pageMsg, st, pageModel);
					var nPageModel = _v6.a;
					var nPageMsg = _v6.b;
					var stMsgs = _v6.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$ListReevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListReevaluationMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			default:
				if (_v0.b.$ === 4) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v7 = A3($author$project$App$Page$Company$PageReevaluation$update, pageMsg, st, pageModel);
					var nPageModel = _v7.a;
					var nPageMsg = _v7.b;
					var stMsgs = _v7.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$Reevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ReevaluationMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
		}
	});
var $author$project$App$Page$PageDoctor$DoctorSales = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Page$PageDoctor$FinalEvaluation = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$PageDoctor$Interview = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$PageDoctor$ListFinished = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$PageDoctor$ListFinishedMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$PageDoctor$ListPending = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$PageDoctor$ListPendingMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$PageDoctor$ListReevaluation = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$PageDoctor$ListReevaluationMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$PageDoctor$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$PageDoctor$LoadingMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$PageDoctor$Reevaluation = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$PageDoctor$ReevaluationMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$PageDoctor$Review = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$PageDoctor$ViewEvaluation = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$PageDoctor$WaitingLine = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$SharedState$CreateDoctorReportCsv = F6(
	function (a, b, c, d, e, f) {
		return {$: 100, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $author$project$App$SharedState$CreateDoctorReportPdf = F6(
	function (a, b, c, d, e, f) {
		return {$: 101, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $author$project$App$Page$ResponseMsgs$DoctorMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorSalesLoadedDetails = F5(
	function (a, b, c, d, e) {
		return {$: 2, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$App$Page$ResponseMsgs$DoctorSalesLoadedReportCsv = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorSalesLoadedReportPdf = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorSalesMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$SharedState$LoadDoctorMonthDetails = F4(
	function (a, b, c, d) {
		return {$: 95, a: a, b: b, c: c, d: d};
	});
var $author$project$App$Page$Doctor$PageDoctorSales$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				var m = msg.a;
				var y = msg.b;
				var o = msg.c;
				var _v1 = A2(
					$elm$core$List$any,
					function (ms) {
						return _Utils_eq(ms.hk, m) && _Utils_eq(ms.iF, y);
					},
					model.w) ? _Utils_Tuple2(m, y) : (A2(
					$elm$core$List$any,
					function (ms) {
						return ((_Utils_cmp(ms.hk, m) > -1) && _Utils_eq(ms.iF, y)) || (_Utils_cmp(ms.iF, y) > 0);
					},
					model.w) ? A3(
					$elm$core$List$foldl,
					F2(
						function (ms, a) {
							return (((_Utils_cmp(ms.hk, a.a) < 1) && _Utils_eq(ms.iF, a.b)) || (_Utils_cmp(ms.iF, a.b) < 0)) ? _Utils_Tuple2(ms.hk, ms.iF) : a;
						}),
					_Utils_Tuple2(model.m, model.o),
					model.w) : A3(
					$elm$core$List$foldl,
					F2(
						function (ms, a) {
							return (((_Utils_cmp(ms.hk, a.a) > 0) && _Utils_eq(ms.iF, a.b)) || (_Utils_cmp(ms.iF, a.b) > 0)) ? _Utils_Tuple2(ms.hk, ms.iF) : a;
						}),
					_Utils_Tuple2(model.m, model.o),
					model.w));
				var newMonth = _v1.a;
				var newYear = _v1.b;
				var endDate = (m === 12) ? ($elm$core$String$fromInt(newYear + 1) + ('-' + '01-01')) : ($elm$core$String$fromInt(newYear) + ((((newMonth + 1) >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(newMonth + 1) + '-01')));
				var startDate = $elm$core$String$fromInt(newYear) + (((newMonth >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(newMonth) + '-01'));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{m: newMonth, o: newYear}),
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A4(
								$author$project$App$SharedState$LoadDoctorMonthDetails,
								model.fl,
								startDate,
								endDate,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorSalesMsg(
											A5($author$project$App$Page$ResponseMsgs$DoctorSalesLoadedDetails, model.fl, startDate, endDate, model.o, model.m)))
									]))
							])));
			case 1:
				var monthNumber = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							w: A2(
								$elm$core$List$map,
								function (m) {
									return _Utils_eq(m.hk, monthNumber) ? _Utils_update(
										m,
										{bi: 0}) : m;
								},
								model.w)
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 2:
				var monthNumber = msg.a;
				var salesType = msg.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							w: A2(
								$elm$core$List$map,
								function (m) {
									return _Utils_eq(m.hk, monthNumber) ? _Utils_update(
										m,
										{bi: salesType}) : m;
								},
								model.w)
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 3:
				var rowNumber = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{fR: rowNumber + 4}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 7:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dS: !model.dS}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 5:
				var sharedMsgs = function () {
					var sDate = $elm$core$String$fromInt(model.o) + (((model.m >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(model.m) + '-01'));
					var eDate = (model.m === 12) ? ($elm$core$String$fromInt(model.o + 1) + ('-' + '01-01')) : ($elm$core$String$fromInt(model.o) + ((((model.m + 1) >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(model.m + 1) + '-01')));
					return _List_fromArray(
						[
							A6(
							$author$project$App$SharedState$CreateDoctorReportCsv,
							model.fl,
							sDate,
							eDate,
							'',
							'',
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$DoctorMsg(
									$author$project$App$Page$ResponseMsgs$DoctorSalesMsg(
										$author$project$App$Page$ResponseMsgs$DoctorSalesLoadedReportCsv(model.fl)))
								]))
						]);
				}();
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsgs));
			case 4:
				var sharedMsgs = function () {
					var sDate = $elm$core$String$fromInt(model.o) + (((model.m >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(model.m) + '-01'));
					var eDate = (model.m === 12) ? ($elm$core$String$fromInt(model.o + 1) + ('-' + '01-01')) : ($elm$core$String$fromInt(model.o) + ((((model.m + 1) >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(model.m + 1) + '-01')));
					return _List_fromArray(
						[
							A6(
							$author$project$App$SharedState$CreateDoctorReportPdf,
							model.fl,
							sDate,
							eDate,
							'',
							'',
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$DoctorMsg(
									$author$project$App$Page$ResponseMsgs$DoctorSalesMsg(
										$author$project$App$Page$ResponseMsgs$DoctorSalesLoadedReportPdf(model.fl)))
								]))
						]);
				}();
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsgs));
			default:
				var newOrder = msg.a;
				var updateOrder = _Utils_eq(newOrder, model.ct.a) ? _Utils_Tuple2(newOrder, !model.ct.b) : _Utils_Tuple2(newOrder, false);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ct: updateOrder}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
		}
	});
var $author$project$App$SharedState$ApproveStatement = F2(
	function (a, b) {
		return {$: 26, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$CIDAutocompleteMsg = F2(
	function (a, b) {
		return {$: 16, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$CptSelected = function (a) {
	return {$: 21, a: a};
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$DPSSelected = function (a) {
	return {$: 25, a: a};
};
var $author$project$App$SharedState$DeleteICD = F2(
	function (a, b) {
		return {$: 25, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationApproved = 4;
var $author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationMemedTokenLoaded = 6;
var $author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationNexodataTokenLoaded = 7;
var $author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationReload = 5;
var $author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationTriedSignDocument = 2;
var $author$project$App$Page$Doctor$PageFinalEvaluation$DocumentLoaded = function (a) {
	return {$: 26, a: a};
};
var $author$project$App$SharedState$GetMemedToken = F2(
	function (a, b) {
		return {$: 28, a: a, b: b};
	});
var $author$project$App$SharedState$GetNexodataToken = F2(
	function (a, b) {
		return {$: 29, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$MemedMsg = function (a) {
	return {$: 29, a: a};
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$ReportSelected = function (a) {
	return {$: 23, a: a};
};
var $author$project$App$SharedState$SignDocument = F3(
	function (a, b, c) {
		return {$: 21, a: a, b: b, c: c};
	});
var $author$project$App$SharedState$SignMedicalDocument = F3(
	function (a, b, c) {
		return {$: 22, a: a, b: b, c: c};
	});
var $author$project$App$SharedState$UpdateAnswer = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$App$SharedState$UploadDocumentToS3 = F3(
	function (a, b, c) {
		return {$: 30, a: a, b: b, c: c};
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$acICDCatDesc = function (cat) {
	return cat.am;
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$acICDCatFilter = F2(
	function (str, list) {
		var lowerQuery = $elm$core$String$toLower(str);
		return A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					$elm$core$String$contains(lowerQuery),
					$elm$core$String$toLower),
				function ($) {
					return $.am;
				}),
			list);
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$acICDCatId = function (cat) {
	return cat.gu;
};
var $author$project$App$Data$StatementStatus$StatusDoctorFinalEvalNoChange = 25;
var $author$project$App$Page$Doctor$PageFinalEvaluation$defaultFilter = function (filter) {
	return A2(
		$author$project$App$Components$Filter$setOrder,
		'created_at,desc',
		A2(
			$author$project$App$Components$Filter$addRule,
			'status,<=,' + $author$project$App$Data$StatementStatus$statusToString(25),
			filter));
};
var $author$project$App$Data$Statement$depIndexToInt = function (depIdx) {
	if (!depIdx.$) {
		return -1;
	} else {
		var i = depIdx.a;
		return i;
	}
};
var $author$project$App$Data$QuestionSection$CategoryUnknown = 0;
var $author$project$App$Data$Answer$emptyModel = {
	aY: $author$project$App$Data$Id$uuidNil,
	d3: false,
	ay: 0,
	a0: 0,
	am: '',
	as: $author$project$App$Data$Id$uuidNil,
	bB: 0,
	cr: $elm$core$Maybe$Nothing,
	jx: '',
	bh: $author$project$App$Data$Id$uuidNil,
	aS: $author$project$App$Data$Id$uuidNil,
	dF: false,
	aV: $author$project$App$Data$Id$uuidNil,
	G: $elm$core$Maybe$Nothing,
	f2: $elm$time$Time$millisToPosix(0),
	T: ''
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$getAnswerForTemplateId = F3(
	function (depIdx, model, tId) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Answer$emptyModel,
			A2(
				$elm$core$Dict$get,
				tId,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Dict$empty,
					A2(
						$elm$core$Dict$get,
						$author$project$App$Data$Statement$depIndexToInt(depIdx),
						model.aZ))));
	});
var $author$project$App$Components$Autocomplete$getFinalItem = F3(
	function (toId, items, model) {
		var _v0 = model.c9;
		if (_v0.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var finalItem = _v0.a;
			return A3(
				$elm$core$List$foldr,
				F2(
					function (item, result) {
						return _Utils_eq(
							toId(item),
							finalItem) ? $elm$core$Maybe$Just(item) : result;
					}),
				$elm$core$Maybe$Nothing,
				items);
		}
	});
var $author$project$App$Data$Nexodata$initializeNexodataModal = _Platform_outgoingPort('initializeNexodataModal', $elm$json$Json$Encode$string);
var $author$project$App$Components$Autocomplete$resetFinal = function (model) {
	return _Utils_update(
		model,
		{c9: $elm$core$Maybe$Nothing});
};
var $author$project$Menu$State = $elm$core$Basics$identity;
var $author$project$Menu$Internal$empty = {fC: $elm$core$Maybe$Nothing, aC: $elm$core$Maybe$Nothing};
var $author$project$Menu$empty = $author$project$Menu$Internal$empty;
var $author$project$App$Components$Autocomplete$resetMenu = F2(
	function (id, model) {
		return _Utils_update(
			model,
			{aa: $author$project$Menu$empty, as: id, bL: false});
	});
var $elm$core$Maybe$destruct = F3(
	function (_default, func, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return func(a);
		} else {
			return _default;
		}
	});
var $author$project$App$Data$Memed$sendMemedHub = _Platform_outgoingPort(
	'sendMemedHub',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'city',
					function ($) {
						return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
					}($.bp)),
					_Utils_Tuple2(
					'externalId',
					$elm$json$Json$Encode$string($.gR)),
					_Utils_Tuple2(
					'height',
					function ($) {
						return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
					}($.g$)),
					_Utils_Tuple2(
					'locomotions',
					function ($) {
						return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
					}($.hh)),
					_Utils_Tuple2(
					'motherName',
					function ($) {
						return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
					}($.cp)),
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string($.hl)),
					_Utils_Tuple2(
					'phone',
					$elm$json$Json$Encode$string($.dx)),
					_Utils_Tuple2(
					'weight',
					function ($) {
						return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
					}($.iD))
				]));
	});
var $author$project$App$Components$Autocomplete$NoOp = {$: 8};
var $author$project$App$Components$Autocomplete$Reset = {$: 3};
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $author$project$App$Components$Autocomplete$getItemIndexAtId = F3(
	function (show, items, id) {
		return A3(
			$elm$core$Dict$foldr,
			F3(
				function (i, item, result) {
					return _Utils_eq(
						show(item),
						id) ? $elm$core$Maybe$Just(i) : result;
				}),
			$elm$core$Maybe$Nothing,
			$elm$core$Dict$fromList(
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (i, item) {
							return _Utils_Tuple2(i, item);
						}),
					items)));
	});
var $author$project$App$Components$Autocomplete$removeSelection = function (model) {
	return _Utils_update(
		model,
		{aI: $elm$core$Maybe$Nothing});
};
var $author$project$Menu$Internal$reset = F2(
	function (_v0, _v1) {
		var separateSelections = _v0.jZ;
		var mouse = _v1.aC;
		return separateSelections ? {fC: $elm$core$Maybe$Nothing, aC: mouse} : $author$project$Menu$Internal$empty;
	});
var $author$project$Menu$reset = F2(
	function (_v0, _v1) {
		var config = _v0;
		var state = _v1;
		return A2($author$project$Menu$Internal$reset, config, state);
	});
var $author$project$App$Components$Autocomplete$resetInput = function (model) {
	return A2(
		$author$project$App$Components$Autocomplete$resetMenu,
		model.as,
		$author$project$App$Components$Autocomplete$removeSelection(
			_Utils_update(
				model,
				{R: ''})));
};
var $author$project$Menu$Internal$resetToFirst = F3(
	function (config, data, state) {
		var _v0 = config;
		var toId = _v0.ip;
		var separateSelections = _v0.jZ;
		var setFirstItem = F2(
			function (datum, newState) {
				return _Utils_update(
					newState,
					{
						fC: $elm$core$Maybe$Just(
							toId(datum))
					});
			});
		var _v1 = $elm$core$List$head(data);
		if (_v1.$ === 1) {
			return $author$project$Menu$Internal$empty;
		} else {
			var datum = _v1.a;
			return separateSelections ? A2(
				setFirstItem,
				datum,
				A2($author$project$Menu$Internal$reset, config, state)) : A2(setFirstItem, datum, $author$project$Menu$Internal$empty);
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $author$project$Menu$Internal$resetToFirstItem = F4(
	function (config, data, howManyToShow, state) {
		return A3(
			$author$project$Menu$Internal$resetToFirst,
			config,
			A2($elm$core$List$take, howManyToShow, data),
			state);
	});
var $author$project$Menu$resetToFirstItem = F4(
	function (_v0, data, howManyToShow, _v1) {
		var config = _v0;
		var state = _v1;
		return A4($author$project$Menu$Internal$resetToFirstItem, config, data, howManyToShow, state);
	});
var $author$project$Menu$Internal$resetToLastItem = F4(
	function (config, data, howManyToShow, state) {
		var reversedData = $elm$core$List$reverse(
			A2($elm$core$List$take, howManyToShow, data));
		return A3($author$project$Menu$Internal$resetToFirst, config, reversedData, state);
	});
var $author$project$Menu$resetToLastItem = F4(
	function (_v0, data, howManyToShow, _v1) {
		var config = _v0;
		var state = _v1;
		return A4($author$project$Menu$Internal$resetToLastItem, config, data, howManyToShow, state);
	});
var $author$project$App$Components$Autocomplete$setQuery = F4(
	function (toId, items, model, id) {
		return _Utils_update(
			model,
			{
				c9: A3(
					$elm$core$List$foldr,
					F2(
						function (item, result) {
							return _Utils_eq(
								toId(item),
								id) ? $elm$core$Maybe$Just(
								toId(item)) : result;
						}),
					$elm$core$Maybe$Nothing,
					items),
				R: ''
			});
	});
var $author$project$Menu$Internal$WentTooHigh = {$: 2};
var $author$project$Menu$Internal$WentTooLow = {$: 1};
var $author$project$Menu$Internal$getPrevious = F3(
	function (id, selectedId, resultId) {
		return _Utils_eq(selectedId, id) ? $elm$core$Maybe$Just(id) : (_Utils_eq(
			A2($elm$core$Maybe$withDefault, '', resultId),
			id) ? $elm$core$Maybe$Just(selectedId) : resultId);
	});
var $author$project$Menu$Internal$getNextItemId = F2(
	function (ids, selectedId) {
		return A2(
			$elm$core$Maybe$withDefault,
			selectedId,
			A3(
				$elm$core$List$foldl,
				$author$project$Menu$Internal$getPrevious(selectedId),
				$elm$core$Maybe$Nothing,
				ids));
	});
var $author$project$Menu$Internal$getPreviousItemId = F2(
	function (ids, selectedId) {
		return A2(
			$elm$core$Maybe$withDefault,
			selectedId,
			A3(
				$elm$core$List$foldr,
				$author$project$Menu$Internal$getPrevious(selectedId),
				$elm$core$Maybe$Nothing,
				ids));
	});
var $author$project$Menu$Internal$navigateWithKey = F3(
	function (code, ids, maybeId) {
		switch (code) {
			case 38:
				return A2(
					$elm$core$Maybe$map,
					$author$project$Menu$Internal$getPreviousItemId(ids),
					maybeId);
			case 40:
				return A2(
					$elm$core$Maybe$map,
					$author$project$Menu$Internal$getNextItemId(ids),
					maybeId);
			default:
				return maybeId;
		}
	});
var $author$project$Menu$Internal$resetMouseStateWithId = F3(
	function (separateSelections, id, state) {
		return separateSelections ? {
			fC: state.fC,
			aC: $elm$core$Maybe$Just(id)
		} : {
			fC: $elm$core$Maybe$Just(id),
			aC: $elm$core$Maybe$Just(id)
		};
	});
var $author$project$Menu$Internal$update = F5(
	function (config, msg, howManyToShow, state, data) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					var keyCode = msg.a;
					var boundedList = A2(
						$elm$core$List$take,
						howManyToShow,
						A2($elm$core$List$map, config.ip, data));
					var newKey = A3($author$project$Menu$Internal$navigateWithKey, keyCode, boundedList, state.fC);
					if (_Utils_eq(newKey, state.fC) && (keyCode === 38)) {
						var $temp$config = config,
							$temp$msg = $author$project$Menu$Internal$WentTooHigh,
							$temp$howManyToShow = howManyToShow,
							$temp$state = state,
							$temp$data = data;
						config = $temp$config;
						msg = $temp$msg;
						howManyToShow = $temp$howManyToShow;
						state = $temp$state;
						data = $temp$data;
						continue update;
					} else {
						if (_Utils_eq(newKey, state.fC) && (keyCode === 40)) {
							var $temp$config = config,
								$temp$msg = $author$project$Menu$Internal$WentTooLow,
								$temp$howManyToShow = howManyToShow,
								$temp$state = state,
								$temp$data = data;
							config = $temp$config;
							msg = $temp$msg;
							howManyToShow = $temp$howManyToShow;
							state = $temp$state;
							data = $temp$data;
							continue update;
						} else {
							if (config.jZ) {
								return _Utils_Tuple2(
									_Utils_update(
										state,
										{fC: newKey}),
									A2(config.jy, keyCode, newKey));
							} else {
								return _Utils_Tuple2(
									{fC: newKey, aC: newKey},
									A2(config.jy, keyCode, newKey));
							}
						}
					}
				case 1:
					return _Utils_Tuple2(state, config.jE);
				case 2:
					return _Utils_Tuple2(state, config.jD);
				case 3:
					var id = msg.a;
					return _Utils_Tuple2(
						A3($author$project$Menu$Internal$resetMouseStateWithId, config.jZ, id, state),
						config.jA(id));
				case 4:
					var id = msg.a;
					return _Utils_Tuple2(
						A3($author$project$Menu$Internal$resetMouseStateWithId, config.jZ, id, state),
						config.jB(id));
				case 5:
					var id = msg.a;
					return _Utils_Tuple2(
						A3($author$project$Menu$Internal$resetMouseStateWithId, config.jZ, id, state),
						config.jz(id));
				default:
					return _Utils_Tuple2(state, $elm$core$Maybe$Nothing);
			}
		}
	});
var $author$project$Menu$update = F5(
	function (_v0, _v1, howManyToShow, _v2, data) {
		var config = _v0;
		var msg = _v1;
		var state = _v2;
		var _v3 = A5($author$project$Menu$Internal$update, config, msg, howManyToShow, state, data);
		var newState = _v3.a;
		var maybeMsg = _v3.b;
		return _Utils_Tuple2(newState, maybeMsg);
	});
var $author$project$App$Components$Autocomplete$PreviewItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Components$Autocomplete$SelectItem = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Components$Autocomplete$Wrap = function (a) {
	return {$: 2, a: a};
};
var $author$project$Menu$UpdateConfig = $elm$core$Basics$identity;
var $author$project$Menu$Internal$updateConfig = function (_v0) {
	var toId = _v0.ip;
	var onKeyDown = _v0.jy;
	var onTooLow = _v0.jE;
	var onTooHigh = _v0.jD;
	var onMouseEnter = _v0.jA;
	var onMouseLeave = _v0.jB;
	var onMouseClick = _v0.jz;
	var separateSelections = _v0.jZ;
	return {jy: onKeyDown, jz: onMouseClick, jA: onMouseEnter, jB: onMouseLeave, jD: onTooHigh, jE: onTooLow, jZ: separateSelections, ip: toId};
};
var $author$project$Menu$updateConfig = function (config) {
	return $author$project$Menu$Internal$updateConfig(config);
};
var $author$project$App$Components$Autocomplete$updateConfig = function (toId) {
	return $author$project$Menu$updateConfig(
		{
			jy: F2(
				function (code, maybeId) {
					return ((code === 38) || (code === 40)) ? A2($elm$core$Maybe$map, $author$project$App$Components$Autocomplete$PreviewItem, maybeId) : ((code === 13) ? A2($elm$core$Maybe$map, $author$project$App$Components$Autocomplete$SelectItem, maybeId) : $elm$core$Maybe$Just($author$project$App$Components$Autocomplete$Reset));
				}),
			jz: function (id) {
				return $elm$core$Maybe$Just(
					$author$project$App$Components$Autocomplete$SelectItem(id));
			},
			jA: function (id) {
				return $elm$core$Maybe$Just(
					$author$project$App$Components$Autocomplete$PreviewItem(id));
			},
			jB: function (_v0) {
				return $elm$core$Maybe$Nothing;
			},
			jD: $elm$core$Maybe$Just(
				$author$project$App$Components$Autocomplete$Wrap(true)),
			jE: $elm$core$Maybe$Just(
				$author$project$App$Components$Autocomplete$Wrap(false)),
			jZ: false,
			ip: toId
		});
};
var $author$project$App$Components$Autocomplete$update = F6(
	function (msg, filter, toId, show, items, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					var newQuery = msg.a;
					var showMenu = !$elm$core$List$isEmpty(
						A2(filter, newQuery, items));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{R: newQuery, aI: $elm$core$Maybe$Nothing, bL: showMenu}),
						$elm$core$Platform$Cmd$none);
				case 1:
					var autoMsg = msg.a;
					var _v1 = A5(
						$author$project$Menu$update,
						$author$project$App$Components$Autocomplete$updateConfig(toId),
						autoMsg,
						model.bz,
						model.aa,
						A2(filter, model.R, items));
					var newState = _v1.a;
					var maybeMsg = _v1.b;
					var newModel = _Utils_update(
						model,
						{aa: newState});
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none),
						A2(
							$elm$core$Maybe$map,
							function (updateMsg) {
								return A6($author$project$App$Components$Autocomplete$update, updateMsg, filter, toId, show, items, newModel);
							},
							maybeMsg));
				case 4:
					var validOptions = !$elm$core$List$isEmpty(
						A2(filter, model.R, items));
					var handleEscape = validOptions ? A2(
						$author$project$App$Components$Autocomplete$resetMenu,
						model.as,
						$author$project$App$Components$Autocomplete$removeSelection(model)) : $author$project$App$Components$Autocomplete$resetInput(model);
					var escapedModel = function () {
						var _v2 = model.aI;
						if (!_v2.$) {
							var idx = _v2.a;
							var mItem = A2(
								$elm$core$Dict$get,
								idx,
								$elm$core$Dict$fromList(
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (i, it) {
												return _Utils_Tuple2(i, it);
											}),
										items)));
							if (!mItem.$) {
								var item = mItem.a;
								return _Utils_eq(
									model.R,
									show(item)) ? $author$project$App$Components$Autocomplete$resetInput(model) : handleEscape;
							} else {
								return handleEscape;
							}
						} else {
							return handleEscape;
						}
					}();
					return _Utils_Tuple2(escapedModel, $elm$core$Platform$Cmd$none);
				case 2:
					var toTop = msg.a;
					var _v4 = model.aI;
					if (!_v4.$) {
						var $temp$msg = $author$project$App$Components$Autocomplete$Reset,
							$temp$filter = filter,
							$temp$toId = toId,
							$temp$show = show,
							$temp$items = items,
							$temp$model = model;
						msg = $temp$msg;
						filter = $temp$filter;
						toId = $temp$toId;
						show = $temp$show;
						items = $temp$items;
						model = $temp$model;
						continue update;
					} else {
						return toTop ? _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aa: A4(
										$author$project$Menu$resetToLastItem,
										$author$project$App$Components$Autocomplete$updateConfig(toId),
										A2(filter, model.R, items),
										model.bz,
										model.aa),
									aI: A3($author$project$App$Components$Autocomplete$getItemIndexAtId, show, items, model.R)
								}),
							$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aa: A4(
										$author$project$Menu$resetToFirstItem,
										$author$project$App$Components$Autocomplete$updateConfig(toId),
										A2(filter, model.R, items),
										model.bz,
										model.aa),
									aI: A3($author$project$App$Components$Autocomplete$getItemIndexAtId, show, items, model.R)
								}),
							$elm$core$Platform$Cmd$none);
					}
				case 3:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aa: A2(
									$author$project$Menu$reset,
									$author$project$App$Components$Autocomplete$updateConfig(toId),
									model.aa),
								aI: $elm$core$Maybe$Nothing
							}),
						$elm$core$Platform$Cmd$none);
				case 6:
					var id = msg.a;
					var newModel = A2(
						$author$project$App$Components$Autocomplete$resetMenu,
						model.as,
						A4($author$project$App$Components$Autocomplete$setQuery, toId, items, model, id));
					return _Utils_Tuple2(
						newModel,
						A2(
							$elm$core$Task$attempt,
							function (_v5) {
								return $author$project$App$Components$Autocomplete$NoOp;
							},
							$elm$browser$Browser$Dom$focus(model.as)));
				case 5:
					var filteredItem = $elm$core$List$head(
						A2(filter, model.R, items));
					var id = function () {
						if (!filteredItem.$) {
							var item = filteredItem.a;
							return toId(item);
						} else {
							return $author$project$App$Data$Id$uuidNil;
						}
					}();
					var newModel = A2(
						$author$project$App$Components$Autocomplete$resetMenu,
						model.as,
						A4($author$project$App$Components$Autocomplete$setQuery, toId, items, model, id));
					return _Utils_Tuple2(
						newModel,
						A2(
							$elm$core$Task$attempt,
							function (_v6) {
								return $author$project$App$Components$Autocomplete$NoOp;
							},
							$elm$browser$Browser$Dom$focus(model.as)));
				case 7:
					var id = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aI: A3($author$project$App$Components$Autocomplete$getItemIndexAtId, show, items, id)
							}),
						$elm$core$Platform$Cmd$none);
				default:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$App$Data$Memed$lazyLoadMemed = _Platform_outgoingPort('lazyLoadMemed', $elm$json$Json$Encode$string);
var $author$project$App$Data$Memed$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var token = msg.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{e$: token}),
				$author$project$App$Data$Memed$lazyLoadMemed(token));
		} else {
			var params = msg.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						du: $elm$core$Maybe$Just(params)
					}),
				$author$project$App$Data$Memed$sendMemedHub(params));
		}
	});
var $author$project$App$Data$Nexodata$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var token = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{e$: token}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					model,
					$author$project$App$Data$Nexodata$initializeNexodataModal(model.e$));
			default:
				var url = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Data$Answer$FieldDescription = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$getAnswer = F3(
	function (qId, stmtId, answers) {
		var emptyAnswer = $author$project$App$Data$Answer$emptyModel;
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_update(
				emptyAnswer,
				{bh: qId, dF: true, aV: stmtId}),
			A2($elm$core$Dict$get, qId, answers));
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$icdClearSelected = function (list) {
	var _v0 = $elm$regex$Regex$fromString('_selected_(.*)_selected_');
	if (!_v0.$) {
		var regex = _v0.a;
		return A3(
			$elm$regex$Regex$replace,
			regex,
			function (_v1) {
				return '';
			},
			list);
	} else {
		return '';
	}
};
var $author$project$App$Data$Answer$selectedFromString = function (str) {
	if (str === '1') {
		return true;
	} else {
		return false;
	}
};
var $author$project$App$Data$Answer$updateModel = F2(
	function (field, origAns) {
		var answer = _Utils_update(
			origAns,
			{d3: true});
		switch (field.$) {
			case 0:
				var value = field.a;
				return _Utils_update(
					answer,
					{
						dF: $author$project$App$Data$Answer$selectedFromString(value)
					});
			case 1:
				var value = field.a;
				return _Utils_update(
					answer,
					{dF: value});
			case 2:
				var value = field.a;
				return _Utils_update(
					answer,
					{T: value});
			case 3:
				var value = field.a;
				return _Utils_update(
					answer,
					{am: value});
			case 4:
				var value = field.a;
				return _Utils_update(
					answer,
					{am: value});
			case 5:
				var value = field.a;
				return _Utils_update(
					answer,
					{jx: value});
			case 6:
				var row = field.a;
				var header = field.b;
				var value = field.c;
				return _Utils_update(
					answer,
					{
						G: $elm$core$Maybe$Just(
							function (rows) {
								return function (cols) {
									return A3(
										$elm$core$Dict$insert,
										row,
										A3($elm$core$Dict$insert, header, value, cols),
										rows);
								}(
									A2(
										$elm$core$Maybe$withDefault,
										$elm$core$Dict$empty,
										A2($elm$core$Dict$get, row, rows)));
							}(
								A2($elm$core$Maybe$withDefault, $elm$core$Dict$empty, answer.G))),
						T: ''
					});
			case 8:
				var row = field.a;
				return _Utils_update(
					answer,
					{
						G: A2(
							$elm$core$Maybe$andThen,
							function (v) {
								return (!$elm$core$Dict$size(v)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(v);
							},
							A2(
								$elm$core$Maybe$map,
								function (dict) {
									return A2($elm$core$Dict$remove, row, dict);
								},
								answer.G)),
						T: ''
					});
			default:
				var cols = field.a;
				return _Utils_update(
					answer,
					{
						G: $elm$core$Maybe$Just(
							function (rows) {
								return A2(
									$elm$core$Dict$insert,
									$elm$core$Dict$size(rows),
									$elm$core$Dict$fromList(
										A2(
											$elm$core$List$indexedMap,
											F2(
												function (_v1, v) {
													return _Utils_Tuple2(v, '');
												}),
											cols)))(rows);
							}(
								A2($elm$core$Maybe$withDefault, $elm$core$Dict$empty, answer.G))),
						T: ''
					});
		}
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$updateAnswerField = F5(
	function (qId, stmtId, fieldValue, questions, answers) {
		var qIdExists = !$elm$core$List$isEmpty(
			A2(
				$elm$core$List$filter,
				function (q) {
					return _Utils_eq(q.hG.as, qId);
				},
				A2($elm$core$Maybe$withDefault, _List_Nil, questions)));
		var answer = A3($author$project$App$Page$Doctor$PageFinalEvaluation$getAnswer, qId, stmtId, answers);
		var updatedAnswer = A2($author$project$App$Data$Answer$updateModel, fieldValue, answer);
		var finalAnswerDescripton = $author$project$App$Page$Doctor$PageFinalEvaluation$icdClearSelected(updatedAnswer.am);
		var finalAnswer = A2(
			$author$project$App$Data$Answer$updateModel,
			$author$project$App$Data$Answer$FieldDescription(finalAnswerDescripton),
			updatedAnswer);
		return (qIdExists || _Utils_eq(questions, $elm$core$Maybe$Nothing)) ? _Utils_Tuple2(
			A3($elm$core$Dict$insert, qId, finalAnswer, answers),
			$elm$core$Maybe$Just(updatedAnswer)) : _Utils_Tuple2(answers, $elm$core$Maybe$Nothing);
	});
var $author$project$App$Components$Autocomplete$SetQuery = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Data$AnswerTemplate$TypeUnknown = 0;
var $author$project$App$Data$AnswerTemplate$emptyModel = {
	ay: 0,
	as: $author$project$App$Data$Id$uuidNil,
	bB: 0,
	jh: '',
	jj: $elm$core$Maybe$Nothing,
	cr: $elm$core$Maybe$Nothing,
	hp: 0,
	bh: $author$project$App$Data$Id$uuidNil,
	j5: 0,
	iv: $elm$core$Maybe$Nothing,
	f2: $elm$time$Time$millisToPosix(0),
	iA: ''
};
var $author$project$App$Data$Answer$fieldToString = function (f) {
	switch (f.$) {
		case 0:
			var str = f.a;
			return str;
		case 1:
			var value = f.a;
			if (value) {
				return 'true';
			} else {
				return 'false';
			}
		case 2:
			var str = f.a;
			return str;
		case 3:
			var str = f.a;
			return str;
		case 4:
			var str = f.a;
			return str;
		case 5:
			var str = f.a;
			return str;
		case 6:
			var str = f.c;
			return str;
		case 8:
			return '';
		default:
			return '';
	}
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$updateAutocomplete = F4(
	function (cats, fieldValue, acmodel, sharedState) {
		var valueWithAll = $author$project$App$Data$Answer$fieldToString(fieldValue);
		var value = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(
				$elm$core$List$reverse(
					A2($elm$core$String$split, '\n', valueWithAll))));
		var _v0 = A6(
			$author$project$App$Components$Autocomplete$update,
			$author$project$App$Components$Autocomplete$SetQuery(value),
			$author$project$App$Page$Doctor$PageFinalEvaluation$acICDCatFilter,
			$author$project$App$Page$Doctor$PageFinalEvaluation$acICDCatId,
			$author$project$App$Page$Doctor$PageFinalEvaluation$acICDCatDesc,
			cats,
			acmodel.fb);
		var updatedModel = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			updatedModel,
			A2(
				$elm$core$Platform$Cmd$map,
				$author$project$App$Page$Doctor$PageFinalEvaluation$CIDAutocompleteMsg($author$project$App$Data$AnswerTemplate$emptyModel),
				cmd));
	});
var $author$project$App$Data$Statement$Bearer = {$: 0};
var $author$project$App$Page$Doctor$PageFinalEvaluation$InputCIDField = F4(
	function (a, b, c, d) {
		return {$: 15, a: a, b: b, c: c, d: d};
	});
var $author$project$App$SharedState$PersistICD = F2(
	function (a, b) {
		return {$: 27, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$asyncMsg = function (msg) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$identity,
		$elm$core$Task$succeed(msg));
};
var $author$project$App$Data$ICD$emptyModel = {aY: $author$project$App$Data$Id$uuidNil, a0: '', am: '', as: $author$project$App$Data$Id$uuidNil, g2: false, dE: 0, aV: $author$project$App$Data$Id$uuidNil};
var $author$project$App$Page$Doctor$PageFinalEvaluation$updateQuestionICD = F7(
	function (model, qId, cat, stmt, sharedState, ansTemp, sectionCat) {
		var qIcds = A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Dict$empty,
			A2($elm$core$Dict$get, qId, stmt.fx));
		var icd = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$ICD$emptyModel,
			A2($elm$core$Dict$get, cat.gu, qIcds));
		var newSCat = function () {
			if (sectionCat.$ === 1) {
				return icd.dE;
			} else {
				var c = sectionCat.a;
				return c;
			}
		}();
		return _Utils_eq(ansTemp, $author$project$App$Data$AnswerTemplate$emptyModel) ? _Utils_Tuple3(
			model,
			$author$project$App$SharedState$APIMsg(
				A2(
					$author$project$App$SharedState$PersistICD,
					_Utils_update(
						icd,
						{aY: ansTemp.as, a0: cat.gu, am: cat.am, dE: newSCat, aV: stmt.e.as}),
					_List_Nil)),
			$elm$core$Platform$Cmd$none) : _Utils_Tuple3(
			model,
			$author$project$App$SharedState$APIMsg(
				A2(
					$author$project$App$SharedState$PersistICD,
					_Utils_update(
						icd,
						{aY: ansTemp.as, a0: cat.gu, am: cat.am, dE: newSCat, aV: stmt.e.as}),
					_List_Nil)),
			$author$project$App$Page$Doctor$PageFinalEvaluation$asyncMsg(
				A4(
					$author$project$App$Page$Doctor$PageFinalEvaluation$InputCIDField,
					$author$project$App$Data$Statement$Bearer,
					ansTemp.as,
					sharedState.ia.h8.g.e.as,
					$author$project$App$Data$Answer$FieldDescription(''))));
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$updateModelICD = F5(
	function (acIdx, mCat, model, sharedState, ansTemp) {
		if (mCat.$ === 1) {
			return _Utils_Tuple3(model, _List_Nil, $elm$core$Platform$Cmd$none);
		} else {
			var cat = mCat.a;
			if (!acIdx.b.$) {
				var qId = acIdx.a;
				var _v2 = acIdx.b;
				var mSCat = acIdx.c;
				var bearer = sharedState.ia.h8.g;
				var _v3 = A7($author$project$App$Page$Doctor$PageFinalEvaluation$updateQuestionICD, model, qId, cat, bearer, sharedState, ansTemp, mSCat);
				var updatedModel = _v3.a;
				var sharedMsgs = _v3.b;
				var cmdMsgs = _v3.c;
				return _Utils_Tuple3(
					updatedModel,
					_List_fromArray(
						[sharedMsgs]),
					cmdMsgs);
			} else {
				var qId = acIdx.a;
				var depIdx = acIdx.b.a;
				var mSCat = acIdx.c;
				var depStmt = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, depIdx, model.fX));
				var _v4 = A7($author$project$App$Page$Doctor$PageFinalEvaluation$updateQuestionICD, model, qId, cat, depStmt, sharedState, ansTemp, mSCat);
				var updatedModel = _v4.a;
				var sharedMsgs = _v4.b;
				var cmdMsgs = _v4.c;
				return _Utils_Tuple3(
					updatedModel,
					_List_fromArray(
						[sharedMsgs]),
					cmdMsgs);
			}
		}
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 27:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$GetMemedToken,
								A2($elm$core$Maybe$withDefault, $author$project$App$Data$Id$uuidNil, st.ia.h8.g.e.fl),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationMsg(6))
									])))
						]));
			case 28:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$GetNexodataToken,
								st.ia.h8.g.e,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationMsg(7))
									])))
						]));
			case 29:
				var memedMsg = msg.a;
				var _v1 = A2($author$project$App$Data$Memed$update, memedMsg, model.hi);
				var nMemed = _v1.a;
				var memedCmd = _v1.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{hi: nMemed}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageFinalEvaluation$MemedMsg, memedCmd),
					_List_Nil);
			case 30:
				var nexodataMsg = msg.a;
				var sharedMsgs = function () {
					if (nexodataMsg.$ === 2) {
						var url = nexodataMsg.a;
						return _List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A3($author$project$App$SharedState$UploadDocumentToS3, url, model.P.g.e.as, _List_Nil))
							]);
					} else {
						return _List_Nil;
					}
				}();
				var _v2 = A2($author$project$App$Data$Nexodata$update, nexodataMsg, model.ho);
				var nNexodata = _v2.a;
				var nexodataCmd = _v2.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ho: nNexodata}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageFinalEvaluation$NexodataMsg, nexodataCmd),
					sharedMsgs);
			case 31:
				var contact = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$Contact$emptyMobile,
					A2($elm$core$Dict$get, 'mobile', st.ia.h8.g.ea));
				var pPhone = A3(
					$elm$core$String$replace,
					' ',
					'',
					A3(
						$elm$core$String$replace,
						')',
						'',
						A3(
							$elm$core$String$replace,
							'(',
							'',
							A3($elm$core$String$replace, '-', '', contact.T))));
				var memedMsgs = $author$project$App$Data$Memed$sendMemedHub(
					{
						bp: $elm$core$Maybe$Nothing,
						gR: st.ia.h8.g.dw.as,
						g$: $elm$core$Maybe$Nothing,
						hh: $elm$core$Maybe$Nothing,
						cp: $elm$core$Maybe$Nothing,
						hl: _Utils_ap(st.ia.h8.g.dw.hl, st.ia.h8.g.dw.ii),
						dx: pPhone,
						iD: $elm$core$Maybe$Nothing
					});
				return _Utils_Tuple3(
					model,
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageFinalEvaluation$MemedMsg, memedMsgs),
					_List_Nil);
			case 32:
				var nexodataMsgs = $author$project$App$Data$Nexodata$initializeNexodataModal(model.ho.e$);
				return _Utils_Tuple3(
					model,
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageFinalEvaluation$NexodataMsg, nexodataMsgs),
					_List_Nil);
			case 5:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h$: true}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 6:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h$: false, h1: $elm$core$Maybe$Nothing, h2: ''}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 9:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h0: true}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 7:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h$: true, h0: false}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 8:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h0: false}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 13:
				var typeHnsg = A2($elm$core$List$member, 'report:hnsg', st.ia.h8.cJ.eM);
				var haveMedicalReport = $elm$core$List$length(
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$filter,
							F2(
								function (_v4, d) {
									return d.b7 === 1;
								}),
							model.an))) > 0;
				var signDocument = (haveMedicalReport && typeHnsg) ? $author$project$App$SharedState$SignMedicalDocument(model.h2) : $author$project$App$SharedState$SignDocument(model.h2);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h1: $elm$core$Maybe$Nothing, h2: '', h3: true, e$: $author$project$App$Service$Loadable$NotLoaded}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								signDocument,
								st.ia.h8.g.e.as,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationMsg(2))
									])))
						]));
			case 10:
				var token = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h2: token}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 11:
				var key = msg.a;
				var typeHnsg = A2($elm$core$List$member, 'report:hnsg', st.ia.h8.cJ.eM);
				var newModel = (key === 13) ? _Utils_update(
					model,
					{h1: $elm$core$Maybe$Nothing, h2: '', h3: true, e$: $author$project$App$Service$Loadable$NotLoaded}) : model;
				var haveMedicalReport = $elm$core$List$length(
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$filter,
							F2(
								function (_v5, d) {
									return d.b7 === 1;
								}),
							model.an))) > 0;
				var signDocument = (haveMedicalReport && typeHnsg) ? $author$project$App$SharedState$SignMedicalDocument(model.h2) : $author$project$App$SharedState$SignDocument(model.h2);
				var newMsgs = (key === 13) ? _List_fromArray(
					[
						$author$project$App$SharedState$APIMsg(
						A2(
							signDocument,
							st.ia.h8.g.e.as,
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$DoctorMsg(
									$author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationMsg(2))
								])))
					]) : _List_Nil;
				return _Utils_Tuple3(newModel, $elm$core$Platform$Cmd$none, newMsgs);
			case 1:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor(
								$author$project$App$Route$DoctorListPending(
									$author$project$App$Page$Doctor$PageFinalEvaluation$defaultFilter($author$project$App$Components$Filter$empty))))),
					_List_Nil);
			case 3:
				var idx = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{b5: idx}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 4:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ApproveStatement,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationMsg(4))
									])))
						]));
			case 2:
				var depIdx = msg.a;
				var answer = msg.b;
				var fieldValue = msg.c;
				var uAns = A2($author$project$App$Data$Answer$updateModel, fieldValue, answer);
				var stmt = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2(
						$elm$core$Dict$get,
						$author$project$App$Data$Statement$depIndexToInt(depIdx),
						model.fX));
				var statement = st.ia.h8;
				var idx = $author$project$App$Data$Statement$depIndexToInt(depIdx);
				var bearer = statement.g;
				var updatedBearer = _Utils_update(
					bearer,
					{
						aZ: A3($elm$core$Dict$insert, answer.aY, uAns, stmt.aZ)
					});
				var updatedFullStmt = _Utils_update(
					statement,
					{g: updatedBearer});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aZ: A3(
								$elm$core$Dict$insert,
								idx,
								A3($elm$core$Dict$insert, answer.aY, uAns, stmt.aZ),
								model.aZ),
							P: updatedFullStmt,
							fX: A3(
								$elm$core$Dict$insert,
								idx,
								_Utils_update(
									stmt,
									{
										aZ: A3($elm$core$Dict$insert, answer.aY, uAns, stmt.aZ)
									}),
								model.fX)
						}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2($author$project$App$SharedState$UpdateAnswer, uAns, _List_Nil))
						]));
			case 14:
				var time = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 12:
				var fieldValue = msg.b;
				var stmt = st.ia.h8;
				var bearer = stmt.g;
				var updatedBearer = _Utils_update(
					bearer,
					{
						e: A3($author$project$App$Data$Statement$updateModel, model.ey, fieldValue, bearer.e)
					});
				var updatedFullStmt = _Utils_update(
					stmt,
					{g: updatedBearer});
				var updatedModel = _Utils_update(
					model,
					{
						P: _Utils_update(
							stmt,
							{g: updatedBearer})
					});
				return _Utils_Tuple3(
					updatedModel,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A3($author$project$App$SharedState$PersistFullStatement, stmt.b4.as, updatedFullStmt, _List_Nil))
						]));
			case 15:
				var mDepIdx = msg.a;
				var atId = msg.b;
				var stmtId = msg.c;
				var fieldValue = msg.d;
				if (mDepIdx.$ === 1) {
					var idx = mDepIdx.a;
					var _v7 = A2($elm$core$Dict$get, idx, st.ia.h8.fh);
					if (!_v7.$) {
						var dep = _v7.a;
						var acModel = model.f8;
						var _v8 = A4($author$project$App$Page$Doctor$PageFinalEvaluation$updateAutocomplete, model.fa, fieldValue, acModel, st);
						var updatedMenu = _v8.a;
						var updatedMenuCmd = _v8.b;
						var updatedAcModel = _Utils_update(
							acModel,
							{fb: updatedMenu});
						var _v9 = A5($author$project$App$Page$Doctor$PageFinalEvaluation$updateAnswerField, atId, stmtId, fieldValue, $elm$core$Maybe$Nothing, dep.aZ);
						var answers = _v9.a;
						var updatedDep = _Utils_update(
							dep,
							{ge: answers});
						var updatedModel = _Utils_update(
							model,
							{
								f8: updatedAcModel,
								aZ: A3($elm$core$Dict$insert, idx, answers, model.aZ)
							});
						return _Utils_Tuple3(updatedModel, updatedMenuCmd, _List_Nil);
					} else {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
					}
				} else {
					var tempAnswer = A3($author$project$App$Page$Doctor$PageFinalEvaluation$getAnswerForTemplateId, mDepIdx, model, atId);
					var stmt = st.ia.h8;
					var bearer = stmt.g;
					var answer = A2($author$project$App$Data$Answer$updateModel, fieldValue, tempAnswer);
					var acModel = model.f8;
					var _v10 = A4($author$project$App$Page$Doctor$PageFinalEvaluation$updateAutocomplete, model.fa, fieldValue, acModel, st);
					var updatedMenu = _v10.a;
					var updatedMenuCmd = _v10.b;
					var updatedAcModel = _Utils_update(
						acModel,
						{fb: updatedMenu});
					var _v11 = A5($author$project$App$Page$Doctor$PageFinalEvaluation$updateAnswerField, atId, stmtId, fieldValue, $elm$core$Maybe$Nothing, bearer.aZ);
					var answers = _v11.a;
					var updatedBearer = _Utils_update(
						bearer,
						{ge: answers});
					var updatedModel = _Utils_update(
						model,
						{
							f8: updatedAcModel,
							aZ: A3(
								$elm$core$Dict$insert,
								-1,
								A3($elm$core$Dict$insert, atId, answer, answers),
								model.aZ)
						});
					return _Utils_Tuple3(
						updatedModel,
						updatedMenuCmd,
						_List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$UpdateAnswer, answer, _List_Nil))
							]));
				}
			case 16:
				var ansTemp = msg.a;
				var menuMsg = msg.b;
				var acModel = model.f8;
				var _v12 = A6($author$project$App$Components$Autocomplete$update, menuMsg, $author$project$App$Page$Doctor$PageFinalEvaluation$acICDCatFilter, $author$project$App$Page$Doctor$PageFinalEvaluation$acICDCatId, $author$project$App$Page$Doctor$PageFinalEvaluation$acICDCatDesc, model.fa, acModel.fb);
				var cidMenu = _v12.a;
				var cmdMenu = _v12.b;
				var finalItem = A3($author$project$App$Components$Autocomplete$getFinalItem, $author$project$App$Page$Doctor$PageFinalEvaluation$acICDCatId, model.fa, cidMenu);
				var _v13 = A5($author$project$App$Page$Doctor$PageFinalEvaluation$updateModelICD, model.f8.gt, finalItem, model, st, ansTemp);
				var updatedModel = _v13.a;
				var sharedMsgs = _v13.b;
				var cmdMsgs = _v13.c;
				var updateCidMenu = $author$project$App$Components$Autocomplete$resetFinal(cidMenu);
				var updatedAcModel = _Utils_update(
					acModel,
					{fb: updateCidMenu});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{f8: updatedAcModel}),
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$App$Page$Doctor$PageFinalEvaluation$CIDAutocompleteMsg(ansTemp),
						cmdMenu),
					sharedMsgs);
			case 17:
				var mIndex = msg.a;
				var acModel = model.f8;
				var cidMenu = function () {
					if (!mIndex.b.$) {
						var qId = mIndex.a;
						var _v15 = mIndex.b;
						return A2($author$project$App$Components$Autocomplete$resetMenu, qId + 'T', acModel.fb);
					} else {
						var qId = mIndex.a;
						var idx = mIndex.b.a;
						return A2(
							$author$project$App$Components$Autocomplete$resetMenu,
							qId + ('D' + $elm$core$String$fromInt(idx)),
							acModel.fb);
					}
				}();
				var updatedAcModel = _Utils_update(
					acModel,
					{
						fb: _Utils_update(
							cidMenu,
							{R: ''}),
						gt: mIndex
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{f8: updatedAcModel}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 19:
				var mDepIdx = msg.a;
				var catId = msg.b;
				var sharedMsgs = function () {
					if (mDepIdx.$ === 1) {
						var idx = mDepIdx.a;
						return _List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$DeleteICD, catId, _List_Nil))
							]);
					} else {
						return _List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$DeleteICD, catId, _List_Nil))
							]);
					}
				}();
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, sharedMsgs);
			case 18:
				var docMsg = msg.a;
				var docsUpdated = A2(
					$elm$core$Dict$map,
					F2(
						function (_v17, doc) {
							return A2($author$project$App$Data$Document$updateUrl, docMsg, doc);
						}),
					model.an);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{an: docsUpdated}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 20:
				return _Utils_Tuple3(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['application/pdf', 'image/png', 'image/jpeg']),
						$author$project$App$Page$Doctor$PageFinalEvaluation$CptSelected),
					_List_Nil);
			case 21:
				var file = msg.a;
				var now = $elm$time$Time$posixToMillis(
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
				var mime = $elm$file$File$mime(file);
				var emptyDoc = $author$project$App$Data$Document$emptyModel;
				var newDocument = _Utils_update(
					emptyDoc,
					{
						iU: '',
						b7: 5,
						dr: mime,
						hl: 'CPT ' + $elm$core$String$fromInt(now),
						af: '',
						Q: false
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ew: $elm$core$Maybe$Just(newDocument)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$perform,
								$author$project$App$Page$Doctor$PageFinalEvaluation$DocumentLoaded,
								$elm$file$File$toUrl(file))
							])),
					_List_Nil);
			case 24:
				return _Utils_Tuple3(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['application/pdf', 'image/png', 'image/jpeg']),
						$author$project$App$Page$Doctor$PageFinalEvaluation$DPSSelected),
					_List_Nil);
			case 25:
				var file = msg.a;
				var now = $elm$time$Time$posixToMillis(
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
				var mime = $elm$file$File$mime(file);
				var emptyDoc = $author$project$App$Data$Document$emptyModel;
				var newDocument = _Utils_update(
					emptyDoc,
					{
						iU: '',
						b7: 0,
						dr: mime,
						hl: 'DPS ' + $elm$core$String$fromInt(now),
						af: '',
						Q: false
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ew: $elm$core$Maybe$Just(newDocument)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$perform,
								$author$project$App$Page$Doctor$PageFinalEvaluation$DocumentLoaded,
								$elm$file$File$toUrl(file))
							])),
					_List_Nil);
			case 22:
				return _Utils_Tuple3(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['application/pdf', 'image/png', 'image/jpeg']),
						$author$project$App$Page$Doctor$PageFinalEvaluation$ReportSelected),
					_List_Nil);
			case 23:
				var file = msg.a;
				var now = $elm$time$Time$posixToMillis(
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
				var mime = $elm$file$File$mime(file);
				var emptyDoc = $author$project$App$Data$Document$emptyModel;
				var newDocument = _Utils_update(
					emptyDoc,
					{
						iU: '',
						b7: 1,
						dr: mime,
						hl: 'Parecer ' + $elm$core$String$fromInt(now),
						af: '',
						Q: false
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ew: $elm$core$Maybe$Just(newDocument)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$perform,
								$author$project$App$Page$Doctor$PageFinalEvaluation$DocumentLoaded,
								$elm$file$File$toUrl(file))
							])),
					_List_Nil);
			case 26:
				var content = msg.a;
				var idx = $elm$core$Dict$size(model.P.an);
				var fullStmtFromModel = model.P;
				var doc = function () {
					var _v19 = model.ew;
					if (!_v19.$) {
						var d = _v19.a;
						return d;
					} else {
						return $author$project$App$Data$Document$emptyModel;
					}
				}();
				var _v18 = $author$project$App$Data$Document$requestUrl(
					_Utils_update(
						doc,
						{iU: content}));
				var updatedDoc = _v18.a;
				var dCmd = _v18.b;
				var updatedStmt = _Utils_update(
					fullStmtFromModel,
					{
						an: A3($elm$core$Dict$insert, idx, updatedDoc, fullStmtFromModel.an)
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{P: updatedStmt}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$SaveDocuments,
								updatedStmt,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationMsg(5))
									])))
						]));
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$Page$Doctor$PageInterview$ConfirmId = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$ConfirmIdMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$ConfirmMetrics = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$ConfirmMetricsMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$ConfirmQuestions = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$ConfirmQuestionsMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$App$SharedState$CreateNewDocumentFromStatement = F3(
	function (a, b, c) {
		return {$: 14, a: a, b: b, c: c};
	});
var $author$project$App$SharedState$DoctorInformOnline = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$DoctorInterviewInformedOnline = {$: 8};
var $author$project$App$Page$ResponseMsgs$DoctorInterviewMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$EvalDeclared = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$EvalDeclaredMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$FinishCall = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$FinishCallMsg = function (a) {
	return {$: 17, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$InformChanged = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$InformChangedMsg = function (a) {
	return {$: 15, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$InformNoChange = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$InformNoChangeMsg = function (a) {
	return {$: 16, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$LoadingMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$Orientation = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$OrientationMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$Presentation = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$PresentationMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Data$Conference$ProfileProponent = 0;
var $author$project$App$SharedState$ReturnPersonToLineById = F2(
	function (a, b) {
		return {$: 36, a: a, b: b};
	});
var $author$project$App$Data$Conference$RoomForStatement = 1;
var $author$project$App$SharedState$StartConference = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$SharedState$StatementDoctorAdvance = F2(
	function (a, b) {
		return {$: 40, a: a, b: b};
	});
var $author$project$App$SharedState$StatementDoctorChange = F2(
	function (a, b) {
		return {$: 43, a: a, b: b};
	});
var $author$project$App$SharedState$StatementDoctorFinish = F2(
	function (a, b) {
		return {$: 41, a: a, b: b};
	});
var $author$project$App$SharedState$StatementDoctorRevert = F2(
	function (a, b) {
		return {$: 42, a: a, b: b};
	});
var $author$project$App$SharedState$StatementStartRecording = F2(
	function (a, b) {
		return {$: 79, a: a, b: b};
	});
var $author$project$App$SharedState$StatementStopRecording = F2(
	function (a, b) {
		return {$: 80, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageInterview$ViewFullStatementMsg = function (a) {
	return {$: 18, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$restartRecordingJitsi = _Platform_outgoingPort(
	'restartRecordingJitsi',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$App$Page$Doctor$PageInterview$startRecordingJitsi = _Platform_outgoingPort(
	'startRecordingJitsi',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmId$update = F3(
	function (msg, st, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$SharedState$StatementSetById = F2(
	function (a, b) {
		return {$: 77, a: a, b: b};
	});
var $author$project$App$Data$Statement$calcBMI = F2(
	function (weight, height) {
		var w = weight / 1000;
		var h = height / 100;
		return (!height) ? 0 : (w / (h * h));
	});
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$App$Data$Statement$formatBMI = function (bmi) {
	return A3(
		$elm$core$String$slice,
		0,
		5,
		$elm$core$String$fromFloat(bmi));
};
var $author$project$App$Data$Statement$formatDoctorBMI = function (model) {
	return $author$project$App$Data$Statement$formatBMI(
		A2($author$project$App$Data$Statement$calcBMI, model.ap, model.ao));
};
var $author$project$App$Page$Doctor$Interview$PageConfirmMetrics$update = F3(
	function (msg, st, model) {
		var bearerError = ($author$project$App$Data$Statement$formatDoctorBMI(model.a5.g.e) === '0') ? A3($elm$core$Dict$insert, -1, 'Dados inválidos', $elm$core$Dict$empty) : $elm$core$Dict$empty;
		var allErrors = A3(
			$elm$core$Dict$foldl,
			F3(
				function (idx, dep, result) {
					return ($author$project$App$Data$Statement$formatDoctorBMI(dep.e) === '0') ? A3($elm$core$Dict$insert, idx, 'Dados inválidos', result) : result;
				}),
			bearerError,
			model.a5.fh);
		if (!msg.a.$) {
			var _v1 = msg.a;
			var field = msg.b;
			var fullStatement = model.a5;
			var bearer = model.a5.g;
			var stmt = A3(
				$author$project$App$Data$Statement$updateModel,
				$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
				field,
				bearer.e);
			var errors = ($author$project$App$Data$Statement$formatDoctorBMI(stmt) !== '0') ? A2(
				$elm$core$Dict$filter,
				F2(
					function (idx, _v2) {
						return !_Utils_eq(idx, -1);
					}),
				allErrors) : allErrors;
			return _Utils_Tuple3(
				_Utils_update(
					model,
					{
						fp: errors,
						a5: _Utils_update(
							fullStatement,
							{
								g: _Utils_update(
									bearer,
									{e: stmt})
							})
					}),
				$elm$core$Platform$Cmd$none,
				_List_fromArray(
					[
						$author$project$App$SharedState$APIMsg(
						A2($author$project$App$SharedState$StatementSetById, stmt, _List_Nil))
					]));
		} else {
			var depIdx = msg.a.a;
			var field = msg.b;
			var fullStatement = model.a5;
			var deps = model.a5.fh;
			var _v3 = A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(deps, _List_Nil),
				A2(
					$elm$core$Maybe$map,
					function (editStmt) {
						return function (uStmt) {
							return _Utils_Tuple2(
								A3(
									$elm$core$Dict$insert,
									depIdx,
									_Utils_update(
										editStmt,
										{e: uStmt}),
									deps),
								_List_fromArray(
									[
										$author$project$App$SharedState$APIMsg(
										A2($author$project$App$SharedState$StatementSetById, uStmt, _List_Nil))
									]));
						}(
							A3(
								$author$project$App$Data$Statement$updateModel,
								$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
								field,
								editStmt.e));
					},
					A2($elm$core$Dict$get, depIdx, deps)));
			var nDeps = _v3.a;
			var depUpdate = _v3.b;
			var errors = A3(
				$elm$core$Dict$foldl,
				F3(
					function (_v4, dep, result) {
						return ($author$project$App$Data$Statement$formatDoctorBMI(dep.e) !== '0') ? A2(
							$elm$core$Dict$filter,
							F2(
								function (idx, _v5) {
									return !_Utils_eq(idx, -1);
								}),
							result) : result;
					}),
				allErrors,
				nDeps);
			return _Utils_Tuple3(
				_Utils_update(
					model,
					{
						fp: errors,
						a5: _Utils_update(
							fullStatement,
							{fh: nDeps})
					}),
				$elm$core$Platform$Cmd$none,
				depUpdate);
		}
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$update = F3(
	function (msg, st, model) {
		var depIdx = msg.a;
		var answer = msg.b;
		var fieldValue = msg.c;
		var uAns = A2($author$project$App$Data$Answer$updateModel, fieldValue, answer);
		var updateAnswers = _List_fromArray(
			[
				A2($author$project$App$SharedState$UpdateAnswer, uAns, _List_Nil)
			]);
		var idx = $author$project$App$Data$Statement$depIndexToInt(depIdx);
		var stmt = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullStatement$emptyEditStatement,
			A2($elm$core$Dict$get, idx, model.fX));
		var newAnswers = A3($elm$core$Dict$insert, answer.aY, uAns, stmt.aZ);
		var newCompanyAnswers = A3($elm$core$Dict$insert, answer.aY, uAns, stmt.gw);
		return _Utils_Tuple3(
			_Utils_update(
				model,
				{
					fX: A3(
						$elm$core$Dict$insert,
						idx,
						_Utils_update(
							stmt,
							{aZ: newAnswers, gw: newCompanyAnswers}),
						model.fX)
				}),
			$elm$core$Platform$Cmd$none,
			A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, updateAnswers));
	});
var $author$project$App$Data$Answer$FieldSelectedBool = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Data$Answer$FieldValue = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$calcBMI = F2(
	function (weight, height) {
		var w = weight / 1000;
		var h = height / 100;
		return (!height) ? 0 : (w / (h * h));
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$calcBloodDiagnosis = F2(
	function (systolic, diastolic) {
		var classification = ((systolic <= 120) && (diastolic <= 80)) ? 'Normal' : (((systolic < 140) && (diastolic <= 90)) ? 'Pré-hipertensão' : (((systolic < 160) && (diastolic < 100)) ? 'Hipertensão grau 1' : (((systolic < 180) && (diastolic < 110)) ? 'Hipertensão grau 2' : 'Hipertensão grau 3')));
		return 'Classificação Entrevista: ' + classification;
	});
var $author$project$App$Data$Question$TypeUnknown = 0;
var $author$project$App$Data$Question$emptyModel = {
	ay: 0,
	gD: $elm$core$Maybe$Nothing,
	as: $author$project$App$Data$Id$uuidNil,
	bB: 0,
	fE: 0,
	hN: false,
	hY: $author$project$App$Data$Id$uuidNil,
	bP: '',
	j5: 0,
	f2: $elm$time$Time$millisToPosix(0)
};
var $author$project$App$Data$FullQuestion$emptyModel = {bY: _List_Nil, aZ: _List_Nil, hG: $author$project$App$Data$Question$emptyModel};
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$formatBMI = function (bmi) {
	return A3(
		$elm$core$String$slice,
		0,
		5,
		$elm$core$String$fromFloat(bmi));
};
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$update = F3(
	function (msg, st, m) {
		var newErrors = A3(
			$elm$core$Dict$foldl,
			F3(
				function (_v12, section, errorDict) {
					return A6(
						$elm$core$Dict$merge,
						$elm$core$Dict$insert,
						F4(
							function (k, b, _v14, d) {
								return A3($elm$core$Dict$insert, k, b, d);
							}),
						$elm$core$Dict$insert,
						$elm$core$Dict$empty,
						errorDict,
						$elm$core$Dict$fromList(
							A3(
								$elm$core$List$foldl,
								F2(
									function (fq, result) {
										return A2(
											$elm$core$List$append,
											result,
											A3(
												$elm$core$List$foldl,
												F2(
													function (_v13, r) {
														var depId = _v13.a;
														var stmt = _v13.b;
														return A2(
															$elm$core$List$append,
															r,
															A2(
																$elm$core$List$map,
																function (t) {
																	return _Utils_Tuple2(
																		fq.hG.as,
																		A3(
																			$elm$core$Dict$insert,
																			t.as,
																			_Utils_Tuple2('Valor inválido', depId),
																			$elm$core$Dict$empty));
																},
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var tempA = A2(
																			$elm$core$Maybe$withDefault,
																			$author$project$App$Data$Answer$emptyModel,
																			A2($elm$core$Dict$get, t.as, stmt.aZ));
																		var a = _Utils_eq(tempA.as, $author$project$App$Data$Id$uuidNil) ? _Utils_update(
																			tempA,
																			{T: 'error'}) : tempA;
																		return (a.T === '0') || (a.T === '');
																	},
																	A2(
																		$elm$core$List$filter,
																		function (t) {
																			return (t.ay === 10) || (t.ay === 20);
																		},
																		fq.bY))));
													}),
												_List_Nil,
												$elm$core$Dict$toList(m.fX)));
									}),
								_List_Nil,
								A2(
									$elm$core$List$filter,
									function (fq) {
										return A2(
											$elm$core$List$any,
											function (t) {
												return (t.ay === 10) || (t.ay === 20);
											},
											fq.bY);
									},
									section.eI))));
				}),
			$elm$core$Dict$empty,
			m.cA);
		var model = _Utils_update(
			m,
			{fp: newErrors});
		switch (msg.$) {
			case 1:
				var depIdx = msg.a;
				var answer = msg.b;
				var fieldValue = msg.c;
				var uAns = A2($author$project$App$Data$Answer$updateModel, fieldValue, answer);
				var idx = $author$project$App$Data$Statement$depIndexToInt(depIdx);
				var stmt = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, model.fX));
				var currentQuestion = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullQuestion$emptyModel,
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (q) {
								return A2(
									$elm$core$List$any,
									function (t) {
										return _Utils_eq(answer.aY, t.as);
									},
									q.bY);
							},
							$elm$core$List$concat(
								A2(
									$elm$core$List$map,
									function (_v5) {
										var i = _v5.a;
										var s = _v5.b;
										return s.eI;
									},
									$elm$core$Dict$toList(model.cA))))));
				var bmiAnswer = function () {
					var answerTemplate = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$AnswerTemplate$emptyModel,
						$elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (t) {
									return t.ay === 1000;
								},
								currentQuestion.bY)));
					return A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$Answer$emptyModel,
						A2($elm$core$Dict$get, answerTemplate.as, stmt.aZ));
				}();
				var filteredErrors = function () {
					var currentError = A2(
						$elm$core$List$any,
						function (_v3) {
							var tid = _v3.a;
							var _v4 = _v3.b;
							var depIndex = _v4.b;
							return _Utils_eq(tid, uAns.aY) && _Utils_eq(
								$author$project$App$Data$Statement$depIndexToInt(depIdx),
								depIndex);
						},
						$elm$core$Dict$toList(
							A2(
								$elm$core$Maybe$withDefault,
								A3(
									$elm$core$Dict$insert,
									bmiAnswer.aY,
									_Utils_Tuple2('', 0),
									$elm$core$Dict$empty),
								A2($elm$core$Dict$get, currentQuestion.hG.as, model.fp))));
					return currentError ? A2($elm$core$Dict$remove, currentQuestion.hG.as, model.fp) : model.fp;
				}();
				var bloodPressureAnswer = function () {
					var answerTemplate = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$AnswerTemplate$emptyModel,
						$elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (t) {
									return t.ay === 1010;
								},
								currentQuestion.bY)));
					return A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$Answer$emptyModel,
						A2($elm$core$Dict$get, answerTemplate.as, stmt.aZ));
				}();
				var _v1 = _Utils_Tuple3(
					(answer.ay === 10) || (answer.ay === 20),
					function () {
						if (answer.ay === 10) {
							return uAns;
						} else {
							var answerTemplate = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$AnswerTemplate$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (t) {
											return t.ay === 10;
										},
										currentQuestion.bY)));
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$Answer$emptyModel,
								A2($elm$core$Dict$get, answerTemplate.as, stmt.aZ));
						}
					}(),
					function () {
						if (answer.ay === 20) {
							return uAns;
						} else {
							var answerTemplate = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$AnswerTemplate$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (t) {
											return t.ay === 20;
										},
										currentQuestion.bY)));
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$Answer$emptyModel,
								A2($elm$core$Dict$get, answerTemplate.as, stmt.aZ));
						}
					}());
				var calculateBMI = _v1.a;
				var weightAnswer = _v1.b;
				var heightAnswer = _v1.c;
				var currentBMI = calculateBMI ? A2(
					$author$project$App$Page$Doctor$Interview$PageEvalDeclared$calcBMI,
					A2(
						$elm$core$Maybe$withDefault,
						0,
						$elm$core$String$toInt(
							A3($elm$core$String$replace, '.', '', weightAnswer.T))),
					A2(
						$elm$core$Maybe$withDefault,
						0,
						$elm$core$String$toInt(
							A3($elm$core$String$replace, '.', '', heightAnswer.T)))) : 0;
				var updatedBMI = A2(
					$author$project$App$Data$Answer$updateModel,
					$author$project$App$Data$Answer$FieldValue(
						$author$project$App$Page$Doctor$Interview$PageEvalDeclared$formatBMI(currentBMI)),
					bmiAnswer);
				var errors = (calculateBMI && ((weightAnswer.T === '') || (weightAnswer.T === '0'))) ? A3(
					$elm$core$Dict$insert,
					currentQuestion.hG.as,
					A3(
						$elm$core$Dict$insert,
						weightAnswer.aY,
						_Utils_Tuple2(
							'Peso inválido',
							$author$project$App$Data$Statement$depIndexToInt(depIdx)),
						$elm$core$Dict$empty),
					filteredErrors) : ((calculateBMI && ((heightAnswer.T === '') || (heightAnswer.T === '0'))) ? A3(
					$elm$core$Dict$insert,
					currentQuestion.hG.as,
					A3(
						$elm$core$Dict$insert,
						heightAnswer.aY,
						_Utils_Tuple2(
							'Altura inválida',
							$author$project$App$Data$Statement$depIndexToInt(depIdx)),
						$elm$core$Dict$empty),
					filteredErrors) : filteredErrors);
				var _v2 = _Utils_Tuple3(
					(answer.ay === 30) || (answer.ay === 40),
					function () {
						if (answer.ay === 30) {
							return uAns;
						} else {
							var answerTemplate = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$AnswerTemplate$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (t) {
											return t.ay === 30;
										},
										currentQuestion.bY)));
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$Answer$emptyModel,
								A2($elm$core$Dict$get, answerTemplate.as, stmt.aZ));
						}
					}(),
					function () {
						if (answer.ay === 40) {
							return uAns;
						} else {
							var answerTemplate = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$AnswerTemplate$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (t) {
											return t.ay === 40;
										},
										currentQuestion.bY)));
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$Answer$emptyModel,
								A2($elm$core$Dict$get, answerTemplate.as, stmt.aZ));
						}
					}());
				var calculateBlood = _v2.a;
				var systolicAnswer = _v2.b;
				var diastolicAnswer = _v2.c;
				var currentBloodPressure = calculateBlood ? A2(
					$author$project$App$Page$Doctor$Interview$PageEvalDeclared$calcBloodDiagnosis,
					A2(
						$elm$core$Maybe$withDefault,
						0,
						$elm$core$String$toInt(
							A3($elm$core$String$replace, '.', '', systolicAnswer.T))),
					A2(
						$elm$core$Maybe$withDefault,
						0,
						$elm$core$String$toInt(
							A3($elm$core$String$replace, '.', '', diastolicAnswer.T)))) : '';
				var updatedBloodPressure = A2(
					$author$project$App$Data$Answer$updateModel,
					$author$project$App$Data$Answer$FieldValue(currentBloodPressure),
					bloodPressureAnswer);
				var newAnswers = calculateBMI ? A3(
					$elm$core$Dict$insert,
					updatedBMI.aY,
					updatedBMI,
					A3($elm$core$Dict$insert, answer.aY, uAns, stmt.aZ)) : (calculateBlood ? A3(
					$elm$core$Dict$insert,
					updatedBloodPressure.aY,
					updatedBloodPressure,
					A3($elm$core$Dict$insert, answer.aY, uAns, stmt.aZ)) : A3($elm$core$Dict$insert, answer.aY, uAns, stmt.aZ));
				var updateAnswers = calculateBMI ? _List_fromArray(
					[
						A2($author$project$App$SharedState$UpdateAnswer, uAns, _List_Nil),
						A2($author$project$App$SharedState$UpdateAnswer, updatedBMI, _List_Nil)
					]) : (calculateBlood ? _List_fromArray(
					[
						A2($author$project$App$SharedState$UpdateAnswer, uAns, _List_Nil),
						A2($author$project$App$SharedState$UpdateAnswer, updatedBloodPressure, _List_Nil)
					]) : _List_fromArray(
					[
						A2($author$project$App$SharedState$UpdateAnswer, uAns, _List_Nil)
					]));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							fp: errors,
							fX: A3(
								$elm$core$Dict$insert,
								idx,
								_Utils_update(
									stmt,
									{aZ: newAnswers}),
								model.fX)
						}),
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, updateAnswers));
			case 2:
				var depIdx = msg.a;
				var fullQuestion = msg.b;
				var templateId = msg.c;
				var idx = $author$project$App$Data$Statement$depIndexToInt(depIdx);
				var stmt = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, model.fX));
				var _v6 = A3(
					$elm$core$List$foldl,
					F2(
						function (id, _v7) {
							var answers = _v7.a;
							var changed = _v7.b;
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(answers, changed),
								A2(
									$elm$core$Maybe$map,
									function (a) {
										var updatedAnswer = A2(
											$author$project$App$Data$Answer$updateModel,
											$author$project$App$Data$Answer$FieldSelectedBool(false),
											A2(
												$author$project$App$Data$Answer$updateModel,
												$author$project$App$Data$Answer$FieldValue(''),
												a));
										return _Utils_Tuple2(
											A3($elm$core$Dict$insert, id, updatedAnswer, answers),
											A3($elm$core$Dict$insert, a.as, updatedAnswer, changed));
									},
									A2($elm$core$Dict$get, id, stmt.aZ)));
						}),
					_Utils_Tuple2(stmt.aZ, $elm$core$Dict$empty),
					A2(
						$elm$core$List$map,
						function ($) {
							return $.as;
						},
						fullQuestion.bY));
				var clearedAnswers = _v6.a;
				var saveCleared = _v6.b;
				var answersToUpdate = A3(
					$elm$core$Dict$foldl,
					F3(
						function (id, a, changed) {
							return _Utils_eq(id, templateId) ? A3(
								$elm$core$Dict$insert,
								a.as,
								A2(
									$author$project$App$Data$Answer$updateModel,
									$author$project$App$Data$Answer$FieldSelectedBool(true),
									a),
								changed) : changed;
						}),
					saveCleared,
					clearedAnswers);
				var answersFinal = A2(
					A3(
						$elm$core$Basics$composeR,
						$elm$core$Dict$fromList,
						A3(
							$elm$core$Dict$merge,
							$elm$core$Dict$insert,
							F4(
								function (_v10, b, _v11, otherDict) {
									return A3($elm$core$Dict$insert, b.aY, b, otherDict);
								}),
							$elm$core$Dict$insert),
						A2(
							$elm$core$List$map,
							function (_v9) {
								var ans = _v9.b;
								return _Utils_Tuple2(ans.aY, ans);
							},
							$elm$core$Dict$toList(answersToUpdate))),
					stmt.aZ,
					$elm$core$Dict$empty);
				var updateAnswersCmd = A2(
					$elm$core$List$map,
					$author$project$App$SharedState$APIMsg,
					A2(
						$elm$core$List$map,
						function (_v8) {
							var ans = _v8.b;
							return A2($author$project$App$SharedState$UpdateAnswer, ans, _List_Nil);
						},
						$elm$core$Dict$toList(answersToUpdate)));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							fX: A3(
								$elm$core$Dict$insert,
								idx,
								_Utils_update(
									stmt,
									{aZ: answersFinal}),
								model.fX)
						}),
					$elm$core$Platform$Cmd$none,
					updateAnswersCmd);
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$Page$Doctor$Interview$PageFinishCall$update = F3(
	function (msg, st, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$Data$Answer$FieldObservation = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Doctor$Interview$PageInformChanged$update = F3(
	function (msg, st, model) {
		if (msg.$ === 1) {
			var answer = msg.a;
			var value = msg.b;
			var uAns = A2(
				$author$project$App$Data$Answer$updateModel,
				$author$project$App$Data$Answer$FieldObservation(value),
				answer);
			var _v1 = A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(0, $author$project$App$Data$FullStatement$emptyEditStatement),
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v2) {
							var i = _v2.a;
							var s = _v2.b;
							return ($elm$core$List$length(
								A2(
									$elm$core$List$filter,
									function (_v3) {
										var a = _v3.b;
										return _Utils_eq(a.as, answer.as);
									},
									$elm$core$Dict$toList(s.gw))) > 0) || (($elm$core$List$length(
								A2(
									$elm$core$List$filter,
									function (_v4) {
										var a = _v4.b;
										return _Utils_eq(a.as, answer.as);
									},
									$elm$core$Dict$toList(s.gE))) > 0) || ($elm$core$List$length(
								A2(
									$elm$core$List$filter,
									function (_v5) {
										var a = _v5.b;
										return _Utils_eq(a.as, answer.as);
									},
									$elm$core$Dict$toList(s.aZ))) > 0));
						},
						$elm$core$Dict$toList(model.fX))));
			var idx = _v1.a;
			var stmt = _v1.b;
			var _v6 = _Utils_Tuple3(
				$elm$core$List$length(
					A2(
						$elm$core$List$filter,
						function (_v7) {
							var a = _v7.b;
							return _Utils_eq(a.as, answer.as);
						},
						$elm$core$Dict$toList(stmt.aZ))) > 0,
				$elm$core$List$length(
					A2(
						$elm$core$List$filter,
						function (_v8) {
							var a = _v8.b;
							return _Utils_eq(a.as, answer.as);
						},
						$elm$core$Dict$toList(stmt.gE))) > 0,
				$elm$core$List$length(
					A2(
						$elm$core$List$filter,
						function (_v9) {
							var a = _v9.b;
							return _Utils_eq(a.as, answer.as);
						},
						$elm$core$Dict$toList(stmt.gw))) > 0);
			var isPersonAnswers = _v6.a;
			var isDoctorAnswers = _v6.b;
			var isCompanyAnswers = _v6.c;
			var newCompanyAnswers = isCompanyAnswers ? A3($elm$core$Dict$insert, uAns.aY, uAns, stmt.gw) : stmt.gw;
			var newDoctorAnswers = isDoctorAnswers ? A3($elm$core$Dict$insert, uAns.aY, uAns, stmt.gE) : stmt.gE;
			var newAnswers = isPersonAnswers ? A3($elm$core$Dict$insert, uAns.aY, uAns, stmt.aZ) : stmt.aZ;
			return _Utils_Tuple3(
				_Utils_update(
					model,
					{
						fX: A3(
							$elm$core$Dict$insert,
							idx,
							_Utils_update(
								stmt,
								{aZ: newAnswers, gw: newCompanyAnswers, gE: newDoctorAnswers}),
							model.fX)
					}),
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$List$map,
					$author$project$App$SharedState$APIMsg,
					_List_fromArray(
						[
							A2($author$project$App$SharedState$UpdateAnswer, uAns, _List_Nil)
						])));
		} else {
			return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$Page$Doctor$Interview$PageInformNoChange$update = F3(
	function (msg, st, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$Page$ResponseMsgs$DoctorInterviewLoadingMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorInterviewLoadingStatementLoaded = 1;
var $author$project$App$SharedState$LoadConference = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$App$Page$Doctor$Interview$PageLoading$update = F3(
	function (msg, st, model) {
		var stmtId = msg;
		return _Utils_Tuple3(
			model,
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$List$map,
				$author$project$App$SharedState$APIMsg,
				_List_fromArray(
					[
						A3(
						$author$project$App$SharedState$LoadFullStatementWithMinors,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$SharedState$FullStmtAssetQuestions(3),
								$author$project$App$SharedState$FullStmtAssetQuestions(2),
								$author$project$App$SharedState$FullStmtAssetQuestions(1),
								$author$project$App$SharedState$FullStmtAssetAnswers(3),
								$author$project$App$SharedState$FullStmtAssetAnswers(2),
								$author$project$App$SharedState$FullStmtAssetAnswers(1),
								$author$project$App$SharedState$FullStmtAssetDocuments
							]),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
								$author$project$App$Page$ResponseMsgs$DoctorInterviewMsg(
									$author$project$App$Page$ResponseMsgs$DoctorInterviewLoadingMsg(1))),
								model.eq
							])),
						A2(
						$author$project$App$SharedState$LoadConference,
						stmtId,
						_List_fromArray(
							[model.d8]))
					])));
	});
var $author$project$App$Page$Doctor$Interview$PageOrientation$update = F3(
	function (msg, st, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$Page$Doctor$Interview$PagePresentation$update = F3(
	function (msg, st, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmId$NoData = 0;
var $author$project$App$Page$Doctor$Interview$PageConfirmId$init = function (st) {
	return _Utils_Tuple3(0, $elm$core$Platform$Cmd$none, _List_Nil);
};
var $author$project$App$Page$Doctor$Interview$PageConfirmMetrics$initDoctorMetrics = function (editStmt) {
	var model = editStmt.e;
	return _Utils_update(
		editStmt,
		{e: model});
};
var $author$project$App$Page$Doctor$Interview$PageConfirmMetrics$init = function (st) {
	var fullStmt = st.ia.h8;
	var deps = A2(
		$elm$core$Dict$map,
		F2(
			function (_v0, dep) {
				return $author$project$App$Page$Doctor$Interview$PageConfirmMetrics$initDoctorMetrics(dep);
			}),
		fullStmt.fh);
	var bearer = $author$project$App$Page$Doctor$Interview$PageConfirmMetrics$initDoctorMetrics(fullStmt.g);
	var bearerError = ($author$project$App$Data$Statement$formatDoctorBMI(bearer.e) === '0') ? A3($elm$core$Dict$insert, -1, 'Dados inválidos', $elm$core$Dict$empty) : $elm$core$Dict$empty;
	var allErrors = A3(
		$elm$core$Dict$foldl,
		F3(
			function (idx, dep, result) {
				return ($author$project$App$Data$Statement$formatDoctorBMI(dep.e) === '0') ? A3($elm$core$Dict$insert, idx, 'Dados inválidos', result) : result;
			}),
		bearerError,
		deps);
	return _Utils_Tuple3(
		{
			fp: allErrors,
			a5: _Utils_update(
				fullStmt,
				{g: bearer, fh: deps})
		},
		$elm$core$Platform$Cmd$none,
		_List_Nil);
};
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$emptyModel = {a5: $author$project$App$Data$FullStatement$emptyFullStatement, hg: $elm$core$Maybe$Nothing, cA: $elm$core$Dict$empty, fX: $elm$core$Dict$empty};
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$initDoctorMetrics = function (editStmt) {
	var model = function (m) {
		return (!m.ao) ? _Utils_update(
			m,
			{ao: m.jL}) : m;
	}(
		function (m) {
			return (!m.ap) ? _Utils_update(
				m,
				{ap: m.jN}) : m;
		}(editStmt.e));
	return _Utils_update(
		editStmt,
		{e: model});
};
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$init = function (st) {
	var stmts = A2(
		$elm$core$Dict$map,
		F2(
			function (_v1, stmt) {
				var newAnswers = A6(
					$elm$core$Dict$merge,
					$elm$core$Dict$insert,
					F4(
						function (k, b, a, d) {
							return A3($elm$core$Dict$insert, k, b, d);
						}),
					$elm$core$Dict$insert,
					$elm$core$Dict$fromList(
						A2(
							$elm$core$List$map,
							function (_v2) {
								var ans = _v2.b;
								return _Utils_Tuple2(ans.aY, ans);
							},
							$elm$core$Dict$toList(stmt.gE))),
					stmt.aZ,
					$elm$core$Dict$empty);
				return _Utils_update(
					stmt,
					{aZ: newAnswers});
			}),
		A3(
			$elm$core$Dict$insert,
			$author$project$App$Data$Statement$depIndexToInt($author$project$App$Data$Statement$Bearer),
			st.ia.h8.g,
			st.ia.h8.fh));
	var sections = $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (v) {
				return _Utils_Tuple2(v.hX.bB, v);
			},
			st.ia.h8.gH));
	var fullStmt = st.ia.h8;
	var deps = A2(
		$elm$core$Dict$map,
		F2(
			function (_v0, dep) {
				return $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$initDoctorMetrics(dep);
			}),
		fullStmt.fh);
	var bearer = $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$initDoctorMetrics(fullStmt.g);
	return _Utils_Tuple3(
		_Utils_update(
			$author$project$App$Page$Doctor$Interview$PageConfirmQuestions$emptyModel,
			{
				a5: _Utils_update(
					fullStmt,
					{g: bearer, fh: deps}),
				cA: sections,
				fX: stmts
			}),
		$elm$core$Platform$Cmd$none,
		_List_Nil);
};
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$emptyModel = {fp: $elm$core$Dict$empty, hg: $elm$core$Maybe$Nothing, cA: $elm$core$Dict$empty, eU: false, fX: $elm$core$Dict$empty};
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$init = function (st) {
	var stmts = A2(
		$elm$core$Dict$map,
		F2(
			function (_v3, stmt) {
				var newAnswers = A6(
					$elm$core$Dict$merge,
					$elm$core$Dict$insert,
					F4(
						function (k, b, a, d) {
							return A3($elm$core$Dict$insert, k, b, d);
						}),
					$elm$core$Dict$insert,
					$elm$core$Dict$fromList(
						A2(
							$elm$core$List$map,
							function (_v4) {
								var ans = _v4.b;
								return _Utils_Tuple2(ans.aY, ans);
							},
							$elm$core$Dict$toList(stmt.gE))),
					stmt.aZ,
					$elm$core$Dict$empty);
				return _Utils_update(
					stmt,
					{aZ: newAnswers});
			}),
		A3(
			$elm$core$Dict$insert,
			$author$project$App$Data$Statement$depIndexToInt($author$project$App$Data$Statement$Bearer),
			st.ia.h8.g,
			st.ia.h8.fh));
	var sections = $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (v) {
				return _Utils_Tuple2(v.hX.bB, v);
			},
			st.ia.h8.gH));
	var newErrors = A3(
		$elm$core$Dict$foldl,
		F3(
			function (_v0, section, errorDict) {
				return A6(
					$elm$core$Dict$merge,
					$elm$core$Dict$insert,
					F4(
						function (k, b, _v2, d) {
							return A3($elm$core$Dict$insert, k, b, d);
						}),
					$elm$core$Dict$insert,
					$elm$core$Dict$empty,
					errorDict,
					$elm$core$Dict$fromList(
						A3(
							$elm$core$List$foldl,
							F2(
								function (fq, result) {
									return A2(
										$elm$core$List$append,
										result,
										A3(
											$elm$core$List$foldl,
											F2(
												function (_v1, r) {
													var depId = _v1.a;
													var stmt = _v1.b;
													return A2(
														$elm$core$List$append,
														r,
														A2(
															$elm$core$List$map,
															function (t) {
																return _Utils_Tuple2(
																	fq.hG.as,
																	A3(
																		$elm$core$Dict$insert,
																		t.as,
																		_Utils_Tuple2('Valor inválido', depId),
																		$elm$core$Dict$empty));
															},
															A2(
																$elm$core$List$filter,
																function (t) {
																	var tempA = A2(
																		$elm$core$Maybe$withDefault,
																		$author$project$App$Data$Answer$emptyModel,
																		A2($elm$core$Dict$get, t.as, stmt.aZ));
																	var a = _Utils_eq(tempA.as, $author$project$App$Data$Id$uuidNil) ? _Utils_update(
																		tempA,
																		{T: 'error'}) : tempA;
																	return (a.T === '0') || (a.T === '');
																},
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		return (t.ay === 10) || (t.ay === 20);
																	},
																	fq.bY))));
												}),
											_List_Nil,
											$elm$core$Dict$toList(stmts)));
								}),
							_List_Nil,
							A2(
								$elm$core$List$filter,
								function (fq) {
									return A2(
										$elm$core$List$any,
										function (t) {
											return (t.ay === 10) || (t.ay === 20);
										},
										fq.bY);
								},
								section.eI))));
			}),
		$elm$core$Dict$empty,
		sections);
	return _Utils_Tuple3(
		_Utils_update(
			$author$project$App$Page$Doctor$Interview$PageEvalDeclared$emptyModel,
			{
				fp: newErrors,
				cA: sections,
				eU: $elm$core$Dict$size(st.ia.h8.fh) > 0,
				fX: stmts
			}),
		$elm$core$Platform$Cmd$none,
		_List_Nil);
};
var $author$project$App$Page$Doctor$Interview$PageFinishCall$NoData = 0;
var $author$project$App$Page$Doctor$Interview$PageFinishCall$init = function (st) {
	return _Utils_Tuple3(0, $elm$core$Platform$Cmd$none, _List_Nil);
};
var $author$project$App$Page$Doctor$Interview$PageInformChanged$emptyModel = {fX: $elm$core$Dict$empty};
var $author$project$App$Page$Doctor$Interview$PageInformChanged$init = function (st) {
	var stmts = A2(
		$elm$core$Dict$map,
		F2(
			function (_v0, stmt) {
				var newAnswers = A6(
					$elm$core$Dict$merge,
					$elm$core$Dict$insert,
					F4(
						function (k, b, a, d) {
							return A3($elm$core$Dict$insert, k, b, d);
						}),
					$elm$core$Dict$insert,
					$elm$core$Dict$fromList(
						A2(
							$elm$core$List$map,
							function (_v1) {
								var ans = _v1.b;
								return _Utils_Tuple2(ans.aY, ans);
							},
							$elm$core$Dict$toList(stmt.gE))),
					stmt.aZ,
					$elm$core$Dict$empty);
				return _Utils_update(
					stmt,
					{aZ: newAnswers});
			}),
		A3(
			$elm$core$Dict$insert,
			$author$project$App$Data$Statement$depIndexToInt($author$project$App$Data$Statement$Bearer),
			st.ia.h8.g,
			st.ia.h8.fh));
	return _Utils_Tuple3(
		_Utils_update(
			$author$project$App$Page$Doctor$Interview$PageInformChanged$emptyModel,
			{fX: stmts}),
		$elm$core$Platform$Cmd$none,
		_List_Nil);
};
var $author$project$App$Page$Doctor$Interview$PageInformNoChange$NoData = 0;
var $author$project$App$Page$Doctor$Interview$PageInformNoChange$init = function (st) {
	return _Utils_Tuple3(0, $elm$core$Platform$Cmd$none, _List_Nil);
};
var $author$project$App$Page$Doctor$Interview$PageOrientation$NoData = 0;
var $author$project$App$Page$Doctor$Interview$PageOrientation$init = function (_v0) {
	return _Utils_Tuple3(0, $elm$core$Platform$Cmd$none, _List_Nil);
};
var $author$project$App$Page$Doctor$Interview$PagePresentation$NoData = 0;
var $author$project$App$Page$Doctor$Interview$PagePresentation$init = function (st) {
	return _Utils_Tuple3(0, $elm$core$Platform$Cmd$none, _List_Nil);
};
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$App$Route$replaceUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$replaceUrl,
			key,
			$author$project$App$Route$routeToString(route));
	});
var $author$project$App$Page$Doctor$PageInterview$updatePage = F3(
	function (status, st, model) {
		var _v0 = _Utils_Tuple2(status, model.aQ);
		switch (_v0.a) {
			case 13:
				if (_v0.b.$ === 1) {
					var _v1 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v2 = _v0.a;
					var _v3 = $author$project$App$Page$Doctor$Interview$PagePresentation$init(st);
					var pageModel = _v3.a;
					var pageCmd = _v3.b;
					var stMsgs = _v3.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$Doctor$PageInterview$Presentation(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$PresentationMsg, pageCmd),
						stMsgs);
				}
			case 14:
				if (_v0.b.$ === 2) {
					var _v4 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v5 = _v0.a;
					var _v6 = $author$project$App$Page$Doctor$Interview$PageOrientation$init(st);
					var pageModel = _v6.a;
					var pageCmd = _v6.b;
					var stMsgs = _v6.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$Doctor$PageInterview$Orientation(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$OrientationMsg, pageCmd),
						stMsgs);
				}
			case 15:
				if (_v0.b.$ === 3) {
					var _v7 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v8 = _v0.a;
					var _v9 = $author$project$App$Page$Doctor$Interview$PageConfirmId$init(st);
					var pageModel = _v9.a;
					var pageCmd = _v9.b;
					var stMsgs = _v9.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$Doctor$PageInterview$ConfirmId(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$ConfirmIdMsg, pageCmd),
						stMsgs);
				}
			case 16:
				if (_v0.b.$ === 4) {
					var _v10 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v11 = _v0.a;
					var _v12 = $author$project$App$Page$Doctor$Interview$PageConfirmMetrics$init(st);
					var pageModel = _v12.a;
					var pageCmd = _v12.b;
					var stMsgs = _v12.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$Doctor$PageInterview$ConfirmMetrics(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$ConfirmMetricsMsg, pageCmd),
						stMsgs);
				}
			case 17:
				if (_v0.b.$ === 5) {
					var _v13 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v14 = _v0.a;
					var _v15 = $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$init(st);
					var pageModel = _v15.a;
					var pageCmd = _v15.b;
					var stMsgs = _v15.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$Doctor$PageInterview$ConfirmQuestions(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$ConfirmQuestionsMsg, pageCmd),
						stMsgs);
				}
			case 18:
				if (_v0.b.$ === 6) {
					var _v16 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v17 = _v0.a;
					var _v18 = $author$project$App$Page$Doctor$Interview$PageEvalDeclared$init(st);
					var pageModel = _v18.a;
					var pageCmd = _v18.b;
					var stMsgs = _v18.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$Doctor$PageInterview$EvalDeclared(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$EvalDeclaredMsg, pageCmd),
						stMsgs);
				}
			case 19:
				if (_v0.b.$ === 7) {
					var _v19 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v20 = _v0.a;
					var _v21 = $author$project$App$Page$Doctor$Interview$PageInformNoChange$init(st);
					var pageModel = _v21.a;
					var pageCmd = _v21.b;
					var stMsgs = _v21.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$Doctor$PageInterview$InformNoChange(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$InformNoChangeMsg, pageCmd),
						stMsgs);
				}
			case 20:
				if (_v0.b.$ === 8) {
					var _v22 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v23 = _v0.a;
					var _v24 = $author$project$App$Page$Doctor$Interview$PageInformChanged$init(st);
					var pageModel = _v24.a;
					var pageCmd = _v24.b;
					var stMsgs = _v24.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$Doctor$PageInterview$InformChanged(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$InformChangedMsg, pageCmd),
						stMsgs);
				}
			case 21:
				if (_v0.b.$ === 9) {
					var _v25 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v26 = _v0.a;
					var _v27 = $author$project$App$Page$Doctor$Interview$PageFinishCall$init(st);
					var pageModel = _v27.a;
					var pageCmd = _v27.b;
					var stMsgs = _v27.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$Doctor$PageInterview$FinishCall(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$FinishCallMsg, pageCmd),
						stMsgs);
				}
			default:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$replaceUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor($author$project$App$Route$DoctorWaitingLine))),
					_List_Nil);
		}
	});
var $author$project$App$SharedState$VideoMapAcceptSelfie = {$: 4};
var $author$project$App$SharedState$VideoMapDiscardSelfie = {$: 3};
var $author$project$App$SharedState$VideoMapRequestStop = {$: 0};
var $author$project$App$SharedState$VideoMapSwitchCamera = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$SharedState$VideoMapTakeSelfie = {$: 2};
var $author$project$App$SharedState$VideoMappedMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$SharedState$videoMsgToSharedMsg = function (videoMsg) {
	switch (videoMsg.$) {
		case 0:
			return $author$project$App$SharedState$VideoMsg(
				$author$project$App$SharedState$VideoMappedMsg($author$project$App$SharedState$VideoMapRequestStop));
		case 2:
			var a = videoMsg.a;
			var b = videoMsg.b;
			return $author$project$App$SharedState$VideoMsg(
				$author$project$App$SharedState$VideoMappedMsg(
					A2($author$project$App$SharedState$VideoMapSwitchCamera, a, b)));
		case 3:
			return $author$project$App$SharedState$VideoMsg(
				$author$project$App$SharedState$VideoMappedMsg($author$project$App$SharedState$VideoMapDiscardSelfie));
		case 4:
			return $author$project$App$SharedState$VideoMsg(
				$author$project$App$SharedState$VideoMappedMsg($author$project$App$SharedState$VideoMapAcceptSelfie));
		default:
			return $author$project$App$SharedState$VideoMsg(
				$author$project$App$SharedState$VideoMappedMsg($author$project$App$SharedState$VideoMapTakeSelfie));
	}
};
var $author$project$App$Page$Doctor$PageInterview$update = F3(
	function (msg, st, model) {
		var _v0 = _Utils_Tuple2(model.aQ, msg);
		_v0$25:
		while (true) {
			_v0$26:
			while (true) {
				_v0$27:
				while (true) {
					_v0$28:
					while (true) {
						_v0$29:
						while (true) {
							_v0$30:
							while (true) {
								_v0$31:
								while (true) {
									_v0$32:
									while (true) {
										_v0$33:
										while (true) {
											_v0$34:
											while (true) {
												switch (_v0.b.$) {
													case 8:
														switch (_v0.a.$) {
															case 0:
																var pageModel = _v0.a.a;
																var pageMsg = _v0.b.a;
																var _v1 = A3($author$project$App$Page$Doctor$Interview$PageLoading$update, pageMsg, st, pageModel);
																var nPageModel = _v1.a;
																var pageCmd = _v1.b;
																var stMsgs = _v1.c;
																return _Utils_Tuple3(
																	_Utils_update(
																		model,
																		{
																			aQ: $author$project$App$Page$Doctor$PageInterview$Loading(nPageModel)
																		}),
																	A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$LoadingMsg, pageCmd),
																	stMsgs);
															case 1:
																break _v0$26;
															case 2:
																break _v0$27;
															case 3:
																break _v0$28;
															case 4:
																break _v0$29;
															case 5:
																break _v0$30;
															case 6:
																break _v0$31;
															case 7:
																break _v0$32;
															case 8:
																break _v0$33;
															default:
																break _v0$34;
														}
													case 9:
														switch (_v0.a.$) {
															case 0:
																break _v0$25;
															case 1:
																var pageModel = _v0.a.a;
																var pageMsg = _v0.b.a;
																var _v2 = A3($author$project$App$Page$Doctor$Interview$PagePresentation$update, pageMsg, st, pageModel);
																var nPageModel = _v2.a;
																var pageCmd = _v2.b;
																var stMsgs = _v2.c;
																return _Utils_Tuple3(
																	_Utils_update(
																		model,
																		{
																			aQ: $author$project$App$Page$Doctor$PageInterview$Presentation(nPageModel)
																		}),
																	A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$PresentationMsg, pageCmd),
																	stMsgs);
															case 2:
																break _v0$27;
															case 3:
																break _v0$28;
															case 4:
																break _v0$29;
															case 5:
																break _v0$30;
															case 6:
																break _v0$31;
															case 7:
																break _v0$32;
															case 8:
																break _v0$33;
															default:
																break _v0$34;
														}
													case 10:
														switch (_v0.a.$) {
															case 0:
																break _v0$25;
															case 1:
																break _v0$26;
															case 2:
																var pageModel = _v0.a.a;
																var pageMsg = _v0.b.a;
																var _v3 = A3($author$project$App$Page$Doctor$Interview$PageOrientation$update, pageMsg, st, pageModel);
																var nPageModel = _v3.a;
																var pageCmd = _v3.b;
																var stMsgs = _v3.c;
																return _Utils_Tuple3(
																	_Utils_update(
																		model,
																		{
																			aQ: $author$project$App$Page$Doctor$PageInterview$Orientation(nPageModel)
																		}),
																	A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$OrientationMsg, pageCmd),
																	stMsgs);
															case 3:
																break _v0$28;
															case 4:
																break _v0$29;
															case 5:
																break _v0$30;
															case 6:
																break _v0$31;
															case 7:
																break _v0$32;
															case 8:
																break _v0$33;
															default:
																break _v0$34;
														}
													case 11:
														switch (_v0.a.$) {
															case 0:
																break _v0$25;
															case 1:
																break _v0$26;
															case 2:
																break _v0$27;
															case 3:
																var pageModel = _v0.a.a;
																var pageMsg = _v0.b.a;
																var _v4 = A3($author$project$App$Page$Doctor$Interview$PageConfirmId$update, pageMsg, st, pageModel);
																var nPageModel = _v4.a;
																var pageCmd = _v4.b;
																var stMsgs = _v4.c;
																return _Utils_Tuple3(
																	_Utils_update(
																		model,
																		{
																			aQ: $author$project$App$Page$Doctor$PageInterview$ConfirmId(nPageModel)
																		}),
																	A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$ConfirmIdMsg, pageCmd),
																	stMsgs);
															case 4:
																break _v0$29;
															case 5:
																break _v0$30;
															case 6:
																break _v0$31;
															case 7:
																break _v0$32;
															case 8:
																break _v0$33;
															default:
																break _v0$34;
														}
													case 12:
														switch (_v0.a.$) {
															case 0:
																break _v0$25;
															case 1:
																break _v0$26;
															case 2:
																break _v0$27;
															case 3:
																break _v0$28;
															case 4:
																var pageModel = _v0.a.a;
																var pageMsg = _v0.b.a;
																var _v5 = A3($author$project$App$Page$Doctor$Interview$PageConfirmMetrics$update, pageMsg, st, pageModel);
																var nPageModel = _v5.a;
																var pageCmd = _v5.b;
																var stMsgs = _v5.c;
																return _Utils_Tuple3(
																	_Utils_update(
																		model,
																		{
																			aQ: $author$project$App$Page$Doctor$PageInterview$ConfirmMetrics(nPageModel)
																		}),
																	A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$ConfirmMetricsMsg, pageCmd),
																	stMsgs);
															case 5:
																break _v0$30;
															case 6:
																break _v0$31;
															case 7:
																break _v0$32;
															case 8:
																break _v0$33;
															default:
																break _v0$34;
														}
													case 13:
														switch (_v0.a.$) {
															case 0:
																break _v0$25;
															case 1:
																break _v0$26;
															case 2:
																break _v0$27;
															case 3:
																break _v0$28;
															case 4:
																break _v0$29;
															case 5:
																var pageModel = _v0.a.a;
																var pageMsg = _v0.b.a;
																var _v6 = A3($author$project$App$Page$Doctor$Interview$PageConfirmQuestions$update, pageMsg, st, pageModel);
																var nPageModel = _v6.a;
																var pageCmd = _v6.b;
																var stMsgs = _v6.c;
																return _Utils_Tuple3(
																	_Utils_update(
																		model,
																		{
																			aQ: $author$project$App$Page$Doctor$PageInterview$ConfirmQuestions(nPageModel)
																		}),
																	A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$ConfirmQuestionsMsg, pageCmd),
																	stMsgs);
															case 6:
																break _v0$31;
															case 7:
																break _v0$32;
															case 8:
																break _v0$33;
															default:
																break _v0$34;
														}
													case 14:
														switch (_v0.a.$) {
															case 0:
																break _v0$25;
															case 1:
																break _v0$26;
															case 2:
																break _v0$27;
															case 3:
																break _v0$28;
															case 4:
																break _v0$29;
															case 5:
																break _v0$30;
															case 6:
																var pageModel = _v0.a.a;
																var pageMsg = _v0.b.a;
																var _v7 = A3($author$project$App$Page$Doctor$Interview$PageEvalDeclared$update, pageMsg, st, pageModel);
																var nPageModel = _v7.a;
																var pageCmd = _v7.b;
																var stMsgs = _v7.c;
																return _Utils_Tuple3(
																	_Utils_update(
																		model,
																		{
																			aQ: $author$project$App$Page$Doctor$PageInterview$EvalDeclared(nPageModel)
																		}),
																	A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$EvalDeclaredMsg, pageCmd),
																	stMsgs);
															case 7:
																break _v0$32;
															case 8:
																break _v0$33;
															default:
																break _v0$34;
														}
													case 16:
														switch (_v0.a.$) {
															case 0:
																break _v0$25;
															case 1:
																break _v0$26;
															case 2:
																break _v0$27;
															case 3:
																break _v0$28;
															case 4:
																break _v0$29;
															case 5:
																break _v0$30;
															case 6:
																break _v0$31;
															case 7:
																var pageModel = _v0.a.a;
																var pageMsg = _v0.b.a;
																var _v8 = A3($author$project$App$Page$Doctor$Interview$PageInformNoChange$update, pageMsg, st, pageModel);
																var nPageModel = _v8.a;
																var pageCmd = _v8.b;
																var stMsgs = _v8.c;
																return _Utils_Tuple3(
																	_Utils_update(
																		model,
																		{
																			aQ: $author$project$App$Page$Doctor$PageInterview$InformNoChange(nPageModel)
																		}),
																	A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$InformNoChangeMsg, pageCmd),
																	stMsgs);
															case 8:
																break _v0$33;
															default:
																break _v0$34;
														}
													case 15:
														switch (_v0.a.$) {
															case 0:
																break _v0$25;
															case 1:
																break _v0$26;
															case 2:
																break _v0$27;
															case 3:
																break _v0$28;
															case 4:
																break _v0$29;
															case 5:
																break _v0$30;
															case 6:
																break _v0$31;
															case 7:
																break _v0$32;
															case 8:
																var pageModel = _v0.a.a;
																var pageMsg = _v0.b.a;
																var _v9 = A3($author$project$App$Page$Doctor$Interview$PageInformChanged$update, pageMsg, st, pageModel);
																var nPageModel = _v9.a;
																var pageCmd = _v9.b;
																var stMsgs = _v9.c;
																return _Utils_Tuple3(
																	_Utils_update(
																		model,
																		{
																			aQ: $author$project$App$Page$Doctor$PageInterview$InformChanged(nPageModel)
																		}),
																	A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$InformChangedMsg, pageCmd),
																	stMsgs);
															default:
																break _v0$34;
														}
													case 17:
														switch (_v0.a.$) {
															case 0:
																break _v0$25;
															case 1:
																break _v0$26;
															case 2:
																break _v0$27;
															case 3:
																break _v0$28;
															case 4:
																break _v0$29;
															case 5:
																break _v0$30;
															case 6:
																break _v0$31;
															case 7:
																break _v0$32;
															case 8:
																break _v0$33;
															default:
																var pageModel = _v0.a.a;
																var pageMsg = _v0.b.a;
																var _v10 = A3($author$project$App$Page$Doctor$Interview$PageFinishCall$update, pageMsg, st, pageModel);
																var nPageModel = _v10.a;
																var pageCmd = _v10.b;
																var stMsgs = _v10.c;
																return _Utils_Tuple3(
																	_Utils_update(
																		model,
																		{
																			aQ: $author$project$App$Page$Doctor$PageInterview$FinishCall(nPageModel)
																		}),
																	A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$FinishCallMsg, pageCmd),
																	stMsgs);
														}
													case 6:
														var status = _v0.b.a;
														return A3($author$project$App$Page$Doctor$PageInterview$updatePage, status, st, model);
													case 18:
														var viewFullStmtMsg = _v0.b.a;
														var _v11 = A2(
															$elm$core$Maybe$withDefault,
															_Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none),
															A2(
																$elm$core$Maybe$map,
																A2(
																	$elm$core$Basics$composeR,
																	A3($author$project$App$Components$ViewFullStatement$update, viewFullStmtMsg, st.ia.h8, st),
																	$elm$core$Tuple$mapFirst($elm$core$Maybe$Just)),
																model.bU));
														var nViewModel = _v11.a;
														var viewCmd = _v11.b;
														return _Utils_Tuple3(
															_Utils_update(
																model,
																{bU: nViewModel}),
															A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$ViewFullStatementMsg, viewCmd),
															_List_Nil);
													case 0:
														var enabled = _v0.b.a;
														return _Utils_Tuple3(
															_Utils_update(
																model,
																{aA: enabled}),
															$elm$core$Platform$Cmd$none,
															_List_Nil);
													case 1:
														var personId = _v0.b.a;
														return _Utils_Tuple3(
															model,
															$elm$core$Platform$Cmd$none,
															_List_fromArray(
																[
																	$author$project$App$SharedState$APIMsg(
																	A2($author$project$App$SharedState$ReturnPersonToLineById, personId, _List_Nil))
																]));
													case 2:
														var fullStmt = _v0.b.a;
														return _Utils_Tuple3(
															model,
															$author$project$App$Page$Doctor$PageInterview$restartRecordingJitsi(0),
															_List_fromArray(
																[
																	$author$project$App$SharedState$APIMsg(
																	A2($author$project$App$SharedState$StatementDoctorAdvance, fullStmt, _List_Nil))
																]));
													case 3:
														var fullStmt = _v0.b.a;
														return _Utils_Tuple3(
															model,
															$elm$core$Platform$Cmd$none,
															_List_fromArray(
																[
																	$author$project$App$SharedState$APIMsg(
																	A2($author$project$App$SharedState$StatementDoctorFinish, fullStmt, _List_Nil))
																]));
													case 4:
														var fullStmt = _v0.b.a;
														return _Utils_Tuple3(
															model,
															$elm$core$Platform$Cmd$none,
															_List_fromArray(
																[
																	$author$project$App$SharedState$APIMsg(
																	A2($author$project$App$SharedState$StatementDoctorRevert, fullStmt, _List_Nil))
																]));
													case 5:
														var fullStmt = _v0.b.a;
														return _Utils_Tuple3(
															model,
															$elm$core$Platform$Cmd$none,
															_List_fromArray(
																[
																	$author$project$App$SharedState$APIMsg(
																	A2($author$project$App$SharedState$StatementDoctorChange, fullStmt, _List_Nil))
																]));
													case 7:
														var conference = _v0.b.a;
														return _Utils_Tuple3(
															model,
															$elm$core$Platform$Cmd$none,
															_List_fromArray(
																[
																	$author$project$App$SharedState$VideoMsg(
																	A3($author$project$App$SharedState$StartConference, 1, 0, conference))
																]));
													case 19:
														var videoMsg = _v0.b.a;
														return _Utils_Tuple3(
															model,
															$elm$core$Platform$Cmd$none,
															_List_fromArray(
																[
																	$author$project$App$SharedState$videoMsgToSharedMsg(videoMsg)
																]));
													case 20:
														var fullStmt = _v0.b.a;
														return _Utils_Tuple3(
															model,
															$author$project$App$Page$Doctor$PageInterview$startRecordingJitsi(0),
															_List_fromArray(
																[
																	$author$project$App$SharedState$APIMsg(
																	A2($author$project$App$SharedState$StatementStartRecording, fullStmt, _List_Nil))
																]));
													case 21:
														var fullStmt = _v0.b.a;
														return _Utils_Tuple3(
															model,
															$elm$core$Platform$Cmd$none,
															_List_fromArray(
																[
																	$author$project$App$SharedState$APIMsg(
																	A2($author$project$App$SharedState$StatementStopRecording, fullStmt, _List_Nil))
																]));
													case 22:
														var newVideo = _v0.b.a;
														var documentToUpload = _Utils_update(
															newVideo,
															{jJ: st.ia.h8.g.e.as, X: 'statement', Y: st.ia.h8.g.dw.as});
														return _Utils_Tuple3(
															model,
															$elm$core$Platform$Cmd$none,
															_List_fromArray(
																[
																	$author$project$App$SharedState$APIMsg(
																	A3(
																		$author$project$App$SharedState$CreateNewDocumentFromStatement,
																		st.ia.h8.g.e.as,
																		$author$project$App$Data$Document$portToModel(documentToUpload),
																		_List_Nil))
																]));
													case 23:
														var isRecording = _v0.b.a;
														return _Utils_Tuple3(
															_Utils_update(
																model,
																{di: isRecording}),
															$elm$core$Platform$Cmd$none,
															_List_Nil);
													default:
														var id = _v0.b.a;
														return _Utils_eq(id, model.df) ? _Utils_Tuple3(
															model,
															$elm$core$Platform$Cmd$none,
															_List_fromArray(
																[
																	$author$project$App$SharedState$APIMsg(
																	A2(
																		$author$project$App$SharedState$DoctorInformOnline,
																		$elm$core$String$fromInt(model.df),
																		_List_fromArray(
																			[
																				$author$project$App$Page$ResponseMsgs$DoctorMsg(
																				$author$project$App$Page$ResponseMsgs$DoctorInterviewMsg($author$project$App$Page$ResponseMsgs$DoctorInterviewInformedOnline))
																			])))
																])) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
												}
											}
											return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
										}
										return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
									}
									return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
								}
								return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
							}
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
						}
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
					}
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
			}
			return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$Page$ResponseMsgs$DoctorListFinishedFullStatementLoaded = {$: 3};
var $author$project$App$Page$ResponseMsgs$DoctorListFinishedMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorListFinishedPersonLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorListFinishedStatementsLoaded = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$SharedState$LoadFinishedStatementsFromDoctor = F2(
	function (a, b) {
		return {$: 57, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageListFinished$ViewFullStmtMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Doctor$PageListFinished$defaultFilter = function (filter) {
	return A2($author$project$App$Components$Filter$setOrder, 'due_date,desc', filter);
};
var $author$project$App$Page$Doctor$PageListFinished$update = F3(
	function (msg, sharedState, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A2(
						$author$project$App$SharedState$LoadFinishedStatementsFromDoctor,
						uModel.b,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
								$author$project$App$Page$ResponseMsgs$DoctorListFinishedMsg(
									A2($author$project$App$Page$ResponseMsgs$DoctorListFinishedStatementsLoaded, uModel.fl, uModel.b)))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor(
								$author$project$App$Route$DoctorListFinishedRoute(
									$author$project$App$Route$DoctorListFinished(uModel.b))))),
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var filter = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A2(
								$author$project$App$SharedState$LoadFinishedStatementsFromDoctor,
								filter,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorListFinishedMsg(
											A2($author$project$App$Page$ResponseMsgs$DoctorListFinishedStatementsLoaded, model.fl, filter)))
									]))
							])));
			case 2:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$DoctorMsg(
											$author$project$App$Page$ResponseMsgs$DoctorListFinishedMsg(
												$author$project$App$Page$ResponseMsgs$DoctorListFinishedPersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			case 3:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor(
								$author$project$App$Route$DoctorListFinishedRoute(
									A2($author$project$App$Route$DoctorInterviewDetails, stmtId, model.b))))),
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementWithMinors,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1),
										$author$project$App$SharedState$FullStmtAssetAnswers(2),
										$author$project$App$SharedState$FullStmtAssetQuestions(2),
										$author$project$App$SharedState$FullStmtAssetAnswers(3),
										$author$project$App$SharedState$FullStmtAssetQuestions(3),
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetICD
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorListFinishedMsg($author$project$App$Page$ResponseMsgs$DoctorListFinishedFullStatementLoaded))
									]))
							])));
			case 4:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: $elm$core$Maybe$Nothing, av: $elm$core$Maybe$Nothing}),
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor(
								$author$project$App$Route$DoctorListFinishedRoute(
									$author$project$App$Route$DoctorListFinished(
										$author$project$App$Page$Doctor$PageListFinished$defaultFilter($author$project$App$Components$Filter$empty)))))),
					_List_Nil);
			case 5:
				var viewMsg = msg.a;
				var _v2 = function () {
					var _v3 = model.av;
					if (!_v3.$) {
						var viewFullStmt = _v3.a;
						return function (_v4) {
							var a = _v4.a;
							var b = _v4.b;
							return _Utils_Tuple3(
								$elm$core$Maybe$Just(viewFullStmt.a5.g.e.as),
								$elm$core$Maybe$Just(a),
								b);
						}(
							A4($author$project$App$Components$ViewFullStatement$update, viewMsg, sharedState.ia.h8, sharedState, viewFullStmt));
					} else {
						return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
					}
				}();
				var showStmt = _v2.a;
				var uViewFullStmt = _v2.b;
				var uViewFullStmtMsg = _v2.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: showStmt, av: uViewFullStmt}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageListFinished$ViewFullStmtMsg, uViewFullStmtMsg),
					_List_Nil);
			default:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor(
								$author$project$App$Route$DoctorViewEvaluation(stmtId)))),
					_List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$DoctorListPendingMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorListPendingPersonLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorListPendingStatementsLoaded = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$SharedState$LoadStatementsFromDoctor = F2(
	function (a, b) {
		return {$: 55, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageListPending$update = F3(
	function (msg, sharedState, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A2(
						$author$project$App$SharedState$LoadStatementsFromDoctor,
						uModel.b,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
								$author$project$App$Page$ResponseMsgs$DoctorListPendingMsg(
									A2($author$project$App$Page$ResponseMsgs$DoctorListPendingStatementsLoaded, uModel.fl, uModel.b)))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor(
								$author$project$App$Route$DoctorListPending(uModel.b)))),
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var filter = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A2(
								$author$project$App$SharedState$LoadStatementsFromDoctor,
								filter,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorListPendingMsg(
											A2($author$project$App$Page$ResponseMsgs$DoctorListPendingStatementsLoaded, model.fl, filter)))
									]))
							])));
			case 2:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$DoctorMsg(
											$author$project$App$Page$ResponseMsgs$DoctorListPendingMsg(
												$author$project$App$Page$ResponseMsgs$DoctorListPendingPersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			default:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor(
								$author$project$App$Route$DoctorFinalEvaluation(stmtId)))),
					_List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$DoctorListReevaluationMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorListReevaluationPersonLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorListReevaluationStatementsLoaded = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$SharedState$LoadReevalStatementsFromDoctor = F2(
	function (a, b) {
		return {$: 56, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageListReevaluation$update = F3(
	function (msg, sharedState, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A2(
						$author$project$App$SharedState$LoadReevalStatementsFromDoctor,
						uModel.b,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
								$author$project$App$Page$ResponseMsgs$DoctorListReevaluationMsg(
									A2($author$project$App$Page$ResponseMsgs$DoctorListReevaluationStatementsLoaded, uModel.fl, uModel.b)))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor($author$project$App$Route$DoctorListReevaluation))),
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A2(
								$author$project$App$SharedState$LoadReevalStatementsFromDoctor,
								model.b,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorListReevaluationMsg(
											A2($author$project$App$Page$ResponseMsgs$DoctorListReevaluationStatementsLoaded, model.fl, model.b)))
									]))
							])));
			case 2:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$DoctorMsg(
											$author$project$App$Page$ResponseMsgs$DoctorListReevaluationMsg(
												$author$project$App$Page$ResponseMsgs$DoctorListReevaluationPersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			default:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor(
								$author$project$App$Route$DoctorReevaluationDetails(stmtId)))),
					_List_Nil);
		}
	});
var $author$project$App$Page$Doctor$PageLoading$update = F3(
	function (msg, st, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$Page$Doctor$PageReevaluation$CIDAutocompleteMsg = F2(
	function (a, b) {
		return {$: 19, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageReevaluation$ChatBoxMsg = function (a) {
	return {$: 22, a: a};
};
var $author$project$App$Page$Doctor$PageReevaluation$CptSelected = function (a) {
	return {$: 25, a: a};
};
var $author$project$App$Page$Doctor$PageReevaluation$DPSSelected = function (a) {
	return {$: 29, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorReevaluationApproved = 5;
var $author$project$App$Page$ResponseMsgs$DoctorReevaluationMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorReevaluationReload = 6;
var $author$project$App$Page$ResponseMsgs$DoctorReevaluationTriedSignDocument = 2;
var $author$project$App$Page$Doctor$PageReevaluation$DocumentLoaded = function (a) {
	return {$: 30, a: a};
};
var $author$project$App$Page$Doctor$PageReevaluation$ReportSelected = function (a) {
	return {$: 27, a: a};
};
var $author$project$App$Page$Doctor$PageReevaluation$acICDCatDesc = function (cat) {
	return cat.am;
};
var $author$project$App$Page$Doctor$PageReevaluation$acICDCatFilter = F2(
	function (str, list) {
		var lowerQuery = $elm$core$String$toLower(str);
		return A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					$elm$core$String$contains(lowerQuery),
					$elm$core$String$toLower),
				function ($) {
					return $.am;
				}),
			list);
	});
var $author$project$App$Page$Doctor$PageReevaluation$acICDCatId = function (cat) {
	return cat.gu;
};
var $author$project$App$Page$Doctor$PageReevaluation$getAnswerForTemplateId = F3(
	function (depIdx, model, tId) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Answer$emptyModel,
			A2(
				$elm$core$Dict$get,
				tId,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Dict$empty,
					A2(
						$elm$core$Dict$get,
						$author$project$App$Data$Statement$depIndexToInt(depIdx),
						model.aZ))));
	});
var $author$project$App$Page$Doctor$PageReevaluation$getAnswer = F3(
	function (qId, stmtId, answers) {
		var emptyAnswer = $author$project$App$Data$Answer$emptyModel;
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_update(
				emptyAnswer,
				{bh: qId, dF: true, aV: stmtId}),
			A2($elm$core$Dict$get, qId, answers));
	});
var $author$project$App$Page$Doctor$PageReevaluation$icdClearSelected = function (list) {
	var _v0 = $elm$regex$Regex$fromString('_selected_(.*)_selected_');
	if (!_v0.$) {
		var regex = _v0.a;
		return A3(
			$elm$regex$Regex$replace,
			regex,
			function (_v1) {
				return '';
			},
			list);
	} else {
		return '';
	}
};
var $author$project$App$Page$Doctor$PageReevaluation$updateAnswerField = F5(
	function (qId, stmtId, fieldValue, questions, answers) {
		var qIdExists = !$elm$core$List$isEmpty(
			A2(
				$elm$core$List$filter,
				function (q) {
					return _Utils_eq(q.hG.as, qId);
				},
				A2($elm$core$Maybe$withDefault, _List_Nil, questions)));
		var answer = A3($author$project$App$Page$Doctor$PageReevaluation$getAnswer, qId, stmtId, answers);
		var updatedAnswer = A2($author$project$App$Data$Answer$updateModel, fieldValue, answer);
		var finalAnswerDescripton = $author$project$App$Page$Doctor$PageReevaluation$icdClearSelected(updatedAnswer.am);
		var finalAnswer = A2(
			$author$project$App$Data$Answer$updateModel,
			$author$project$App$Data$Answer$FieldDescription(finalAnswerDescripton),
			updatedAnswer);
		return (qIdExists || _Utils_eq(questions, $elm$core$Maybe$Nothing)) ? _Utils_Tuple2(
			A3($elm$core$Dict$insert, qId, finalAnswer, answers),
			$elm$core$Maybe$Just(updatedAnswer)) : _Utils_Tuple2(answers, $elm$core$Maybe$Nothing);
	});
var $author$project$App$Page$Doctor$PageReevaluation$updateAutocomplete = F4(
	function (cats, fieldValue, acmodel, sharedState) {
		var valueWithAll = $author$project$App$Data$Answer$fieldToString(fieldValue);
		var value = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(
				$elm$core$List$reverse(
					A2($elm$core$String$split, '\n', valueWithAll))));
		var _v0 = A6(
			$author$project$App$Components$Autocomplete$update,
			$author$project$App$Components$Autocomplete$SetQuery(value),
			$author$project$App$Page$Doctor$PageReevaluation$acICDCatFilter,
			$author$project$App$Page$Doctor$PageReevaluation$acICDCatId,
			$author$project$App$Page$Doctor$PageReevaluation$acICDCatDesc,
			cats,
			acmodel.fb);
		var updatedModel = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			updatedModel,
			A2(
				$elm$core$Platform$Cmd$map,
				$author$project$App$Page$Doctor$PageReevaluation$CIDAutocompleteMsg($author$project$App$Data$AnswerTemplate$emptyModel),
				cmd));
	});
var $author$project$App$Page$Doctor$PageReevaluation$InputCIDField = F4(
	function (a, b, c, d) {
		return {$: 18, a: a, b: b, c: c, d: d};
	});
var $author$project$App$Page$Doctor$PageReevaluation$asyncMsg = function (msg) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$identity,
		$elm$core$Task$succeed(msg));
};
var $author$project$App$Page$Doctor$PageReevaluation$updateQuestionICD = F7(
	function (model, qId, cat, stmt, sharedState, ansTemp, sectionCat) {
		var qIcds = A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Dict$empty,
			A2($elm$core$Dict$get, qId, stmt.fx));
		var icd = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$ICD$emptyModel,
			A2($elm$core$Dict$get, cat.gu, qIcds));
		var newSCat = function () {
			if (sectionCat.$ === 1) {
				return icd.dE;
			} else {
				var c = sectionCat.a;
				return c;
			}
		}();
		return _Utils_eq(ansTemp, $author$project$App$Data$AnswerTemplate$emptyModel) ? _Utils_Tuple3(
			model,
			$author$project$App$SharedState$APIMsg(
				A2(
					$author$project$App$SharedState$PersistICD,
					_Utils_update(
						icd,
						{aY: ansTemp.as, a0: cat.gu, am: cat.am, dE: newSCat, aV: stmt.e.as}),
					_List_Nil)),
			$elm$core$Platform$Cmd$none) : _Utils_Tuple3(
			model,
			$author$project$App$SharedState$APIMsg(
				A2(
					$author$project$App$SharedState$PersistICD,
					_Utils_update(
						icd,
						{aY: ansTemp.as, a0: cat.gu, am: cat.am, dE: newSCat, aV: stmt.e.as}),
					_List_Nil)),
			$author$project$App$Page$Doctor$PageReevaluation$asyncMsg(
				A4(
					$author$project$App$Page$Doctor$PageReevaluation$InputCIDField,
					$author$project$App$Data$Statement$Bearer,
					ansTemp.as,
					sharedState.ia.h8.g.e.as,
					$author$project$App$Data$Answer$FieldDescription(''))));
	});
var $author$project$App$Page$Doctor$PageReevaluation$updateModelICD = F5(
	function (acIdx, mCat, model, sharedState, ansTemp) {
		if (mCat.$ === 1) {
			return _Utils_Tuple3(model, _List_Nil, $elm$core$Platform$Cmd$none);
		} else {
			var cat = mCat.a;
			if (!acIdx.b.$) {
				var qId = acIdx.a;
				var _v2 = acIdx.b;
				var mSCat = acIdx.c;
				var bearer = sharedState.ia.h8.g;
				var _v3 = A7($author$project$App$Page$Doctor$PageReevaluation$updateQuestionICD, model, qId, cat, bearer, sharedState, ansTemp, mSCat);
				var updatedModel = _v3.a;
				var sharedMsgs = _v3.b;
				var cmdMsgs = _v3.c;
				return _Utils_Tuple3(
					updatedModel,
					_List_fromArray(
						[sharedMsgs]),
					cmdMsgs);
			} else {
				var qId = acIdx.a;
				var depIdx = acIdx.b.a;
				var mSCat = acIdx.c;
				var depStmt = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, depIdx, model.fX));
				var _v4 = A7($author$project$App$Page$Doctor$PageReevaluation$updateQuestionICD, model, qId, cat, depStmt, sharedState, ansTemp, mSCat);
				var updatedModel = _v4.a;
				var sharedMsgs = _v4.b;
				var cmdMsgs = _v4.c;
				return _Utils_Tuple3(
					updatedModel,
					_List_fromArray(
						[sharedMsgs]),
					cmdMsgs);
			}
		}
	});
var $author$project$App$Page$Doctor$PageReevaluation$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 8:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h$: true}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 9:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h$: false, h1: $elm$core$Maybe$Nothing, h2: ''}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 12:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h0: true}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 10:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h$: true, h0: false}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 11:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h0: false}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 5:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dK: true}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 6:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ApproveStatement,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorReevaluationMsg(5))
									])))
						]));
			case 7:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dK: false}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 16:
				var typeHnsg = A2($elm$core$List$member, 'report:hnsg', st.ia.h8.cJ.eM);
				var haveMedicalReport = $elm$core$List$length(
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$filter,
							F2(
								function (_v1, d) {
									return d.b7 === 1;
								}),
							model.an))) > 0;
				var signDocument = (haveMedicalReport && typeHnsg) ? $author$project$App$SharedState$SignMedicalDocument(model.h2) : $author$project$App$SharedState$SignDocument(model.h2);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h1: $elm$core$Maybe$Nothing, h2: '', h3: true, e$: $author$project$App$Service$Loadable$NotLoaded}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								signDocument,
								st.ia.h8.g.e.as,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorReevaluationMsg(2))
									])))
						]));
			case 13:
				var token = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h2: token}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 14:
				var key = msg.a;
				var typeHnsg = A2($elm$core$List$member, 'report:hnsg', st.ia.h8.cJ.eM);
				var newModel = (key === 13) ? _Utils_update(
					model,
					{h1: $elm$core$Maybe$Nothing, h2: '', h3: true, e$: $author$project$App$Service$Loadable$NotLoaded}) : model;
				var haveMedicalReport = $elm$core$List$length(
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$filter,
							F2(
								function (_v2, d) {
									return d.b7 === 1;
								}),
							model.an))) > 0;
				var signDocument = (haveMedicalReport && typeHnsg) ? $author$project$App$SharedState$SignMedicalDocument(model.h2) : $author$project$App$SharedState$SignDocument(model.h2);
				var newMsgs = (key === 13) ? _List_fromArray(
					[
						$author$project$App$SharedState$APIMsg(
						A2(
							signDocument,
							st.ia.h8.g.e.as,
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$DoctorMsg(
									$author$project$App$Page$ResponseMsgs$DoctorReevaluationMsg(2))
								])))
					]) : _List_Nil;
				return _Utils_Tuple3(newModel, $elm$core$Platform$Cmd$none, newMsgs);
			case 1:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor($author$project$App$Route$DoctorListReevaluation))),
					_List_Nil);
			case 3:
				var idx = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{b5: idx}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 4:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ApproveStatement,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorReevaluationMsg(5))
									])))
						]));
			case 2:
				var depIdx = msg.a;
				var answer = msg.b;
				var fieldValue = msg.c;
				var uAns = A2($author$project$App$Data$Answer$updateModel, fieldValue, answer);
				var stmt = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2(
						$elm$core$Dict$get,
						$author$project$App$Data$Statement$depIndexToInt(depIdx),
						model.fX));
				var statement = st.ia.h8;
				var idx = $author$project$App$Data$Statement$depIndexToInt(depIdx);
				var bearer = statement.g;
				var updatedBearer = _Utils_update(
					bearer,
					{
						aZ: A3($elm$core$Dict$insert, answer.aY, uAns, stmt.aZ)
					});
				var updatedFullStmt = _Utils_update(
					statement,
					{g: updatedBearer});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aZ: A3(
								$elm$core$Dict$insert,
								idx,
								A3($elm$core$Dict$insert, answer.aY, uAns, stmt.aZ),
								model.aZ),
							P: updatedFullStmt,
							fX: A3(
								$elm$core$Dict$insert,
								idx,
								_Utils_update(
									stmt,
									{
										aZ: A3($elm$core$Dict$insert, answer.aY, uAns, stmt.aZ)
									}),
								model.fX)
						}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2($author$project$App$SharedState$UpdateAnswer, uAns, _List_Nil))
						]));
			case 17:
				var time = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 15:
				var fieldValue = msg.b;
				var stmt = st.ia.h8;
				var bearer = stmt.g;
				var updatedBearer = _Utils_update(
					bearer,
					{
						e: A3($author$project$App$Data$Statement$updateModel, model.ey, fieldValue, bearer.e)
					});
				var updatedFullStmt = _Utils_update(
					stmt,
					{g: updatedBearer});
				var updatedModel = _Utils_update(
					model,
					{
						P: _Utils_update(
							stmt,
							{g: updatedBearer})
					});
				return _Utils_Tuple3(
					updatedModel,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A3($author$project$App$SharedState$PersistFullStatement, stmt.b4.as, updatedFullStmt, _List_Nil))
						]));
			case 18:
				var mDepIdx = msg.a;
				var atId = msg.b;
				var stmtId = msg.c;
				var fieldValue = msg.d;
				if (mDepIdx.$ === 1) {
					var idx = mDepIdx.a;
					var _v4 = A2($elm$core$Dict$get, idx, st.ia.h8.fh);
					if (!_v4.$) {
						var dep = _v4.a;
						var acModel = model.f8;
						var _v5 = A4($author$project$App$Page$Doctor$PageReevaluation$updateAutocomplete, model.fa, fieldValue, acModel, st);
						var updatedMenu = _v5.a;
						var updatedMenuCmd = _v5.b;
						var updatedAcModel = _Utils_update(
							acModel,
							{fb: updatedMenu});
						var _v6 = A5($author$project$App$Page$Doctor$PageReevaluation$updateAnswerField, atId, stmtId, fieldValue, $elm$core$Maybe$Nothing, dep.aZ);
						var answers = _v6.a;
						var updatedDep = _Utils_update(
							dep,
							{ge: answers});
						var updatedModel = _Utils_update(
							model,
							{
								f8: updatedAcModel,
								aZ: A3($elm$core$Dict$insert, idx, answers, model.aZ)
							});
						return _Utils_Tuple3(updatedModel, updatedMenuCmd, _List_Nil);
					} else {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
					}
				} else {
					var tempAnswer = A3($author$project$App$Page$Doctor$PageReevaluation$getAnswerForTemplateId, mDepIdx, model, atId);
					var stmt = st.ia.h8;
					var bearer = stmt.g;
					var answer = A2($author$project$App$Data$Answer$updateModel, fieldValue, tempAnswer);
					var acModel = model.f8;
					var _v7 = A4($author$project$App$Page$Doctor$PageReevaluation$updateAutocomplete, model.fa, fieldValue, acModel, st);
					var updatedMenu = _v7.a;
					var updatedMenuCmd = _v7.b;
					var updatedAcModel = _Utils_update(
						acModel,
						{fb: updatedMenu});
					var _v8 = A5($author$project$App$Page$Doctor$PageReevaluation$updateAnswerField, atId, stmtId, fieldValue, $elm$core$Maybe$Nothing, bearer.aZ);
					var answers = _v8.a;
					var updatedBearer = _Utils_update(
						bearer,
						{ge: answers});
					var updatedModel = _Utils_update(
						model,
						{
							f8: updatedAcModel,
							aZ: A3(
								$elm$core$Dict$insert,
								-1,
								A3($elm$core$Dict$insert, atId, answer, answers),
								model.aZ)
						});
					return _Utils_Tuple3(
						updatedModel,
						updatedMenuCmd,
						_List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$UpdateAnswer, answer, _List_Nil))
							]));
				}
			case 19:
				var ansTemp = msg.a;
				var menuMsg = msg.b;
				var acModel = model.f8;
				var _v9 = A6($author$project$App$Components$Autocomplete$update, menuMsg, $author$project$App$Page$Doctor$PageReevaluation$acICDCatFilter, $author$project$App$Page$Doctor$PageReevaluation$acICDCatId, $author$project$App$Page$Doctor$PageReevaluation$acICDCatDesc, model.fa, acModel.fb);
				var cidMenu = _v9.a;
				var cmdMenu = _v9.b;
				var finalItem = A3($author$project$App$Components$Autocomplete$getFinalItem, $author$project$App$Page$Doctor$PageReevaluation$acICDCatId, model.fa, cidMenu);
				var _v10 = A5($author$project$App$Page$Doctor$PageReevaluation$updateModelICD, model.f8.gt, finalItem, model, st, ansTemp);
				var updatedModel = _v10.a;
				var sharedMsgs = _v10.b;
				var cmdMsgs = _v10.c;
				var updateCidMenu = $author$project$App$Components$Autocomplete$resetFinal(cidMenu);
				var updatedAcModel = _Utils_update(
					acModel,
					{fb: updateCidMenu});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{f8: updatedAcModel}),
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$App$Page$Doctor$PageReevaluation$CIDAutocompleteMsg(ansTemp),
						cmdMenu),
					sharedMsgs);
			case 20:
				var mIndex = msg.a;
				var acModel = model.f8;
				var cidMenu = function () {
					if (!mIndex.b.$) {
						var qId = mIndex.a;
						var _v12 = mIndex.b;
						return A2($author$project$App$Components$Autocomplete$resetMenu, qId + 'T', acModel.fb);
					} else {
						var qId = mIndex.a;
						var idx = mIndex.b.a;
						return A2(
							$author$project$App$Components$Autocomplete$resetMenu,
							qId + ('D' + $elm$core$String$fromInt(idx)),
							acModel.fb);
					}
				}();
				var updatedAcModel = _Utils_update(
					acModel,
					{
						fb: _Utils_update(
							cidMenu,
							{R: ''}),
						gt: mIndex
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{f8: updatedAcModel}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 23:
				var mDepIdx = msg.a;
				var catId = msg.b;
				var sharedMsgs = function () {
					if (mDepIdx.$ === 1) {
						var idx = mDepIdx.a;
						return _List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$DeleteICD, catId, _List_Nil))
							]);
					} else {
						return _List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$DeleteICD, catId, _List_Nil))
							]);
					}
				}();
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, sharedMsgs);
			case 21:
				var docMsg = msg.a;
				var docsUpdated = A2(
					$elm$core$Dict$map,
					F2(
						function (_v14, doc) {
							return A2($author$project$App$Data$Document$updateUrl, docMsg, doc);
						}),
					model.an);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{an: docsUpdated}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 22:
				if (!msg.a.$) {
					var messageText = msg.a.a;
					var emptyMessage = $author$project$App$Data$StatementMessage$emptyModel;
					var newMessage = _Utils_update(
						emptyMessage,
						{jl: messageText, aV: model.eV});
					var _v15 = A2(
						$author$project$App$Components$Chatbox$update,
						$author$project$App$Components$Chatbox$SendMessage(messageText),
						model.aM);
					var chatBoxUpdated = _v15.a;
					var chatCmd = _v15.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aM: chatBoxUpdated}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageReevaluation$ChatBoxMsg, chatCmd),
						_List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$SendStatementMessage, newMessage, _List_Nil))
							]));
				} else {
					var chatMsg = msg.a;
					var _v16 = A2($author$project$App$Components$Chatbox$update, chatMsg, model.aM);
					var chatBoxUpdated = _v16.a;
					var chatCmd = _v16.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aM: chatBoxUpdated}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageReevaluation$ChatBoxMsg, chatCmd),
						_List_Nil);
				}
			case 24:
				return _Utils_Tuple3(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['application/pdf', 'image/png', 'image/jpeg']),
						$author$project$App$Page$Doctor$PageReevaluation$CptSelected),
					_List_Nil);
			case 25:
				var file = msg.a;
				var now = $elm$time$Time$posixToMillis(
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
				var mime = $elm$file$File$mime(file);
				var emptyDoc = $author$project$App$Data$Document$emptyModel;
				var newDocument = _Utils_update(
					emptyDoc,
					{
						iU: '',
						b7: 5,
						dr: mime,
						hl: 'CPT ' + $elm$core$String$fromInt(now),
						af: '',
						Q: false
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ew: $elm$core$Maybe$Just(newDocument)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$perform,
								$author$project$App$Page$Doctor$PageReevaluation$DocumentLoaded,
								$elm$file$File$toUrl(file))
							])),
					_List_Nil);
			case 28:
				return _Utils_Tuple3(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['application/pdf', 'image/png', 'image/jpeg']),
						$author$project$App$Page$Doctor$PageReevaluation$DPSSelected),
					_List_Nil);
			case 29:
				var file = msg.a;
				var now = $elm$time$Time$posixToMillis(
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
				var mime = $elm$file$File$mime(file);
				var emptyDoc = $author$project$App$Data$Document$emptyModel;
				var newDocument = _Utils_update(
					emptyDoc,
					{
						iU: '',
						b7: 0,
						dr: mime,
						hl: 'DPS ' + $elm$core$String$fromInt(now),
						af: '',
						Q: false
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ew: $elm$core$Maybe$Just(newDocument)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$perform,
								$author$project$App$Page$Doctor$PageReevaluation$DocumentLoaded,
								$elm$file$File$toUrl(file))
							])),
					_List_Nil);
			case 26:
				return _Utils_Tuple3(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['application/pdf', 'image/png', 'image/jpeg']),
						$author$project$App$Page$Doctor$PageReevaluation$ReportSelected),
					_List_Nil);
			case 27:
				var file = msg.a;
				var now = $elm$time$Time$posixToMillis(
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
				var mime = $elm$file$File$mime(file);
				var emptyDoc = $author$project$App$Data$Document$emptyModel;
				var newDocument = _Utils_update(
					emptyDoc,
					{
						iU: '',
						b7: 1,
						dr: mime,
						hl: 'Parecer ' + $elm$core$String$fromInt(now),
						af: '',
						Q: false
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ew: $elm$core$Maybe$Just(newDocument)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$perform,
								$author$project$App$Page$Doctor$PageReevaluation$DocumentLoaded,
								$elm$file$File$toUrl(file))
							])),
					_List_Nil);
			case 30:
				var content = msg.a;
				var idx = $elm$core$Dict$size(model.P.an);
				var fullStmtFromModel = model.P;
				var doc = function () {
					var _v18 = model.ew;
					if (!_v18.$) {
						var d = _v18.a;
						return d;
					} else {
						return $author$project$App$Data$Document$emptyModel;
					}
				}();
				var _v17 = $author$project$App$Data$Document$requestUrl(
					_Utils_update(
						doc,
						{iU: content}));
				var updatedDoc = _v17.a;
				var dCmd = _v17.b;
				var updatedStmt = _Utils_update(
					fullStmtFromModel,
					{
						an: A3($elm$core$Dict$insert, idx, updatedDoc, fullStmtFromModel.an)
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{P: updatedStmt}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$SaveDocuments,
								updatedStmt,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorReevaluationMsg(6))
									])))
						]));
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$DoctorReviewInterviewStarted = 4;
var $author$project$App$Page$ResponseMsgs$DoctorReviewMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorReviewReturnedToLine = 3;
var $author$project$App$Page$ResponseMsgs$DoctorReviewStatementLoaded = 1;
var $author$project$App$SharedState$StatementStartInterview = F2(
	function (a, b) {
		return {$: 72, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageReview$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementWithMinors,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetQuestions(2),
										$author$project$App$SharedState$FullStmtAssetAnswers(2),
										$author$project$App$SharedState$FullStmtAssetQuestions(1),
										$author$project$App$SharedState$FullStmtAssetAnswers(1)
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorReviewMsg(1))
									]))
							])));
			case 1:
				var pId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ReturnPersonToLineById,
								pId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorReviewMsg(3))
									])))
						]));
			case 2:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$StatementStartInterview,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorReviewMsg(4))
									])))
						]));
			default:
				var viewFullMsg = msg.a;
				var _v1 = A4($author$project$App$Components$ViewFullStatement$update, viewFullMsg, st.ia.h8, st, model.bm);
				var viewFullModel = _v1.a;
				var viewFullCmd = _v1.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bm: viewFullModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageReview$ViewFullStatementMsg, viewFullCmd),
					_List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$DoctorInformedOnline = {$: 3};
var $author$project$App$Page$ResponseMsgs$DoctorWaitingLineCompany = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorWaitingLineDoctorListUpdated = {$: 2};
var $author$project$App$Page$ResponseMsgs$DoctorWaitingLineMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorWaitingLinePersonHold = {$: 7};
var $author$project$App$Page$ResponseMsgs$DoctorWaitingLineProponentTemplate = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorWaitingLineStatement = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorWaitingLineStatementLoaded = {$: 8};
var $author$project$App$Page$ResponseMsgs$DoctorWaitingLineUpdated = {$: 1};
var $author$project$App$SharedState$HoldPersonById = F2(
	function (a, b) {
		return {$: 35, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageWaitingLine$InformLoaded = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$SharedState$LoadCompanyById = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$App$SharedState$LoadDoctorFromDoctor = F2(
	function (a, b) {
		return {$: 108, a: a, b: b};
	});
var $author$project$App$SharedState$LoadDoctorInterview = function (a) {
	return {$: 53, a: a};
};
var $author$project$App$SharedState$LoadDoctorWaitingLine = function (a) {
	return {$: 16, a: a};
};
var $author$project$App$SharedState$LoadTemplateById = F2(
	function (a, b) {
		return {$: 85, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageWaitingLine$PlaySound = {$: 9};
var $author$project$App$Data$StatementStatus$StatusWaitingInLine = 11;
var $author$project$App$SharedState$UnsubscribeStatementFromPerson = function (a) {
	return {$: 61, a: a};
};
var $author$project$App$Components$Sound$Stopped = {$: 1};
var $author$project$App$Components$Sound$soundFinish = _Platform_outgoingPort('soundFinish', $elm$json$Json$Encode$string);
var $author$project$App$Components$Sound$disable = function (model) {
	return _Utils_Tuple2(
		$author$project$App$Components$Sound$Stopped,
		$author$project$App$Components$Sound$soundFinish(''));
};
var $author$project$App$Data$Person$emptyWaiting = {
	al: $elm$time$Time$millisToPosix(0),
	D: 0,
	i1: '',
	as: $author$project$App$Data$Id$uuidNil,
	ch: $elm$time$Time$millisToPosix(0),
	cp: '',
	hl: '',
	cs: $elm$core$Maybe$Nothing,
	bd: $elm$core$Maybe$Nothing,
	dy: $elm$core$Maybe$Nothing,
	dJ: 0,
	B: '',
	aw: 0,
	ii: '',
	eY: $author$project$App$Data$Id$uuidNil,
	f2: $elm$time$Time$millisToPosix(0),
	bW: $elm$time$Time$millisToPosix(0)
};
var $author$project$App$Page$Doctor$PageWaitingLine$emptyWaitingWithTemplate = {b4: $author$project$App$Data$Company$emptyModel, dw: $author$project$App$Data$Person$emptyWaiting, h8: $author$project$App$Data$Statement$emptyModel, cJ: $author$project$App$Data$StatementTemplate$emptyModel};
var $author$project$App$Components$Sound$soundInit = _Platform_outgoingPort('soundInit', $elm$json$Json$Encode$string);
var $author$project$App$Components$Sound$enable = function (model) {
	return _Utils_Tuple2(
		model,
		$author$project$App$Components$Sound$soundInit(''));
};
var $author$project$App$Service$ApplicationResources$getCompanyById = F2(
	function (cId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getResource,
			$author$project$App$Service$ApplicationResources$pathCompanyById(cId),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 7)) && (_v0.a.c.$ === 6)) {
			var _v1 = _v0.a;
			var _v2 = _v1.a;
			var model = _v1.c.a;
			return $elm$core$Maybe$Just(model);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$ApplicationResources$getStatementFromPerson = F2(
	function (pId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getResource,
			$author$project$App$Service$ApplicationResources$pathStatementFromPerson(pId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 27) && (_v0.a.c.$ === 16)) {
						var _v1 = _v0.a;
						var _v2 = _v1.a;
						var stmt = _v1.c.a;
						return $author$project$App$Service$Loadable$Got(stmt);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var err = _v4.b;
					return $author$project$App$Service$Loadable$Failed(err);
				case 1:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Components$Sound$WaitingPlay = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Components$Sound$soundPlay = _Platform_outgoingPort('soundPlay', $elm$json$Json$Encode$string);
var $author$project$App$Components$Sound$play = F2(
	function (sound, model) {
		if (!model.$) {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(
				$author$project$App$Components$Sound$WaitingPlay(sound),
				$author$project$App$Components$Sound$soundPlay(sound));
		}
	});
var $author$project$App$Components$Sound$playLongBeep = function (model) {
	return A2($author$project$App$Components$Sound$play, 'beep-long', model);
};
var $author$project$App$Components$Sound$Disabled = {$: 0};
var $author$project$App$Components$Sound$Paused = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Components$Sound$Playing = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Components$Sound$soundHasInitialized = _Platform_outgoingPort('soundHasInitialized', $elm$json$Json$Encode$string);
var $author$project$App$Components$Sound$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					$author$project$App$Components$Sound$Disabled,
					$author$project$App$Components$Sound$soundHasInitialized(''));
			case 1:
				var status = msg.a;
				return (status > 0) ? _Utils_Tuple2($author$project$App$Components$Sound$Stopped, $elm$core$Platform$Cmd$none) : ((status < 0) ? _Utils_Tuple2($author$project$App$Components$Sound$Disabled, $elm$core$Platform$Cmd$none) : _Utils_Tuple2($author$project$App$Components$Sound$Stopped, $elm$core$Platform$Cmd$none));
			case 4:
				return _Utils_Tuple2($author$project$App$Components$Sound$Stopped, $elm$core$Platform$Cmd$none);
			case 2:
				var sound = msg.a;
				return _Utils_Tuple2(
					$author$project$App$Components$Sound$Playing(sound),
					$elm$core$Platform$Cmd$none);
			default:
				var sound = msg.a;
				return _Utils_Tuple2(
					$author$project$App$Components$Sound$Paused(sound),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Page$Doctor$PageWaitingLine$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 2:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								$author$project$App$SharedState$LoadDoctorWaitingLine(
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
									$author$project$App$Page$ResponseMsgs$DoctorWaitingLineMsg($author$project$App$Page$ResponseMsgs$DoctorWaitingLineUpdated))),
								A2(
								$author$project$App$SharedState$LoadDoctorFromDoctor,
								model.fl,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorWaitingLineMsg($author$project$App$Page$ResponseMsgs$DoctorWaitingLineDoctorListUpdated))
									])),
								A2(
								$author$project$App$SharedState$DoctorInformOnline,
								$elm$core$String$fromInt(model.df),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorWaitingLineMsg($author$project$App$Page$ResponseMsgs$DoctorInformedOnline))
									]))
							])));
			case 0:
				var person = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$HoldPersonById,
								person.as,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorWaitingLineMsg($author$project$App$Page$ResponseMsgs$DoctorWaitingLinePersonHold))
									])))
						]));
			case 1:
				var id = msg.a;
				return _Utils_eq(id, model.df) ? _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$DoctorInformOnline,
								$elm$core$String$fromInt(model.df),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorWaitingLineMsg($author$project$App$Page$ResponseMsgs$DoctorInformedOnline))
									])))
						])) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
			case 3:
				var wList = msg.a;
				var updatedWaitingList = A3(
					$elm$core$List$foldr,
					F2(
						function (wlp, l) {
							var maybeNewWaitingPerson = $elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (w) {
										return _Utils_eq(w.as, wlp.dw.as);
									},
									wList));
							var newWaitingPerson = A2($elm$core$Maybe$withDefault, wlp.dw, maybeNewWaitingPerson);
							var currentStatement = wlp.h8;
							var newStatement = _Utils_update(
								currentStatement,
								{aw: newWaitingPerson.aw});
							var currentPerson = wlp.dw;
							var newPerson = _Utils_update(
								currentPerson,
								{aw: newWaitingPerson.aw});
							return _Utils_eq(maybeNewWaitingPerson, $elm$core$Maybe$Nothing) ? l : A2(
								$elm$core$List$cons,
								_Utils_update(
									wlp,
									{dw: newPerson, h8: newStatement}),
								l);
						}),
					_List_Nil,
					model.e1);
				var unsubscribeSharedMsgs = A2(
					$elm$core$List$map,
					function (id) {
						return $author$project$App$SharedState$APIMsg(
							$author$project$App$SharedState$UnsubscribeStatementFromPerson(id));
					},
					A3(
						$elm$core$List$foldr,
						F2(
							function (wlp, l) {
								var maybeNewWaitingPerson = $elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (w) {
											return _Utils_eq(w.as, wlp.dw.as);
										},
										wList));
								return _Utils_eq(maybeNewWaitingPerson, $elm$core$Maybe$Nothing) ? A2($elm$core$List$cons, wlp.dw.as, l) : l;
							}),
						_List_Nil,
						model.e1));
				var _v1 = $elm$core$List$isEmpty(wList) ? _Utils_Tuple2(_List_Nil, _List_Nil) : A3(
					$elm$core$List$foldr,
					F2(
						function (w, _v2) {
							var wts = _v2.a;
							var sharedMsgs = _v2.b;
							if (($elm$core$List$length(
								A2(
									$elm$core$List$filter,
									function (wl) {
										return _Utils_eq(wl.dw.as, w.as);
									},
									model.e1)) > 0) || $author$project$App$Data$Id$uuidIsNotValid(w.as)) {
								return _Utils_Tuple2(wts, sharedMsgs);
							} else {
								var wt = $author$project$App$Page$Doctor$PageWaitingLine$emptyWaitingWithTemplate;
								var wtWithPerson = _Utils_update(
									wt,
									{dw: w});
								var maybeTemplate = A2($author$project$App$Service$ApplicationResources$getStatementTemplateById, w.eY, st._);
								var wtWithTemplate = _Utils_update(
									wtWithPerson,
									{
										cJ: A2($elm$core$Maybe$withDefault, $author$project$App$Data$StatementTemplate$emptyModel, maybeTemplate)
									});
								var maybeCompany = A2($author$project$App$Service$ApplicationResources$getCompanyById, wtWithTemplate.cJ.gx, st._);
								var wtWithCompany = _Utils_update(
									wtWithTemplate,
									{
										b4: A2($elm$core$Maybe$withDefault, $author$project$App$Data$Company$emptyModel, maybeCompany)
									});
								var loadableStatement = A2($author$project$App$Service$ApplicationResources$getStatementFromPerson, w.as, st._);
								var statement = function () {
									switch (loadableStatement.$) {
										case 3:
											var s = loadableStatement.a;
											return s;
										case 2:
											return $author$project$App$Data$Statement$emptyModel;
										case 1:
											return $author$project$App$Data$Statement$emptyModel;
										default:
											return $author$project$App$Data$Statement$emptyModel;
									}
								}();
								var newWt = _Utils_update(
									wtWithCompany,
									{h8: statement});
								var isTemplateAlreadySubscribed = function () {
									if (!maybeTemplate.$) {
										var t = maybeTemplate.a;
										return true;
									} else {
										return false;
									}
								}();
								var isCompanyAlreadySubscribed = function () {
									if (!maybeCompany.$) {
										var c = maybeCompany.a;
										return true;
									} else {
										return false;
									}
								}();
								return (!isTemplateAlreadySubscribed) ? _Utils_Tuple2(
									wts,
									A2(
										$elm$core$List$cons,
										$author$project$App$SharedState$APIMsg(
											A2(
												$author$project$App$SharedState$LoadTemplateById,
												w.eY,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$DoctorMsg(
														$author$project$App$Page$ResponseMsgs$DoctorWaitingLineMsg(
															$author$project$App$Page$ResponseMsgs$DoctorWaitingLineProponentTemplate(newWt)))
													]))),
										sharedMsgs)) : ((!isCompanyAlreadySubscribed) ? _Utils_Tuple2(
									wts,
									A2(
										$elm$core$List$cons,
										$author$project$App$SharedState$APIMsg(
											A2(
												$author$project$App$SharedState$LoadCompanyById,
												newWt.cJ.gx,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$DoctorMsg(
														$author$project$App$Page$ResponseMsgs$DoctorWaitingLineMsg(
															$author$project$App$Page$ResponseMsgs$DoctorWaitingLineCompany(newWt)))
													]))),
										sharedMsgs)) : ((!$author$project$App$Data$Id$uuidIsValid(statement.as)) ? _Utils_Tuple2(
									wts,
									A2(
										$elm$core$List$cons,
										$author$project$App$SharedState$APIMsg(
											A2(
												$author$project$App$SharedState$LoadStatementFromPerson,
												w.as,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$DoctorMsg(
														$author$project$App$Page$ResponseMsgs$DoctorWaitingLineMsg(
															$author$project$App$Page$ResponseMsgs$DoctorWaitingLineStatement(newWt)))
													]))),
										sharedMsgs)) : _Utils_Tuple2(
									A2($elm$core$List$cons, newWt, wts),
									sharedMsgs)));
							}
						}),
					_Utils_Tuple2(_List_Nil, _List_Nil),
					wList);
				var newWaitingElements = _v1.a;
				var remoteMsg = _v1.b;
				var newCmd = ($elm$core$List$length(newWaitingElements) > 0) ? $author$project$App$SharedState$asyncMsg(
					$author$project$App$Page$Doctor$PageWaitingLine$InformLoaded(
						_Utils_ap(updatedWaitingList, newWaitingElements))) : $author$project$App$SharedState$asyncMsg(
					$author$project$App$Page$Doctor$PageWaitingLine$InformLoaded(updatedWaitingList));
				return _Utils_Tuple3(
					model,
					newCmd,
					_Utils_ap(remoteMsg, unsubscribeSharedMsgs));
			case 4:
				var wt = msg.a;
				var maybeCompany = A2($author$project$App$Service$ApplicationResources$getCompanyById, wt.cJ.gx, st._);
				var wtWithCompany = _Utils_update(
					wt,
					{
						b4: A2($elm$core$Maybe$withDefault, $author$project$App$Data$Company$emptyModel, maybeCompany)
					});
				var loadableStatement = A2($author$project$App$Service$ApplicationResources$getStatementFromPerson, wt.dw.as, st._);
				var statement = function () {
					switch (loadableStatement.$) {
						case 3:
							var s = loadableStatement.a;
							return s;
						case 2:
							return $author$project$App$Data$Statement$emptyModel;
						case 1:
							return $author$project$App$Data$Statement$emptyModel;
						default:
							return $author$project$App$Data$Statement$emptyModel;
					}
				}();
				var newWt = _Utils_update(
					wtWithCompany,
					{h8: statement});
				var isCompanyAlreadySubscribed = function () {
					if (!maybeCompany.$) {
						var c = maybeCompany.a;
						return true;
					} else {
						return false;
					}
				}();
				return (!isCompanyAlreadySubscribed) ? _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$LoadCompanyById,
								wt.cJ.gx,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorWaitingLineMsg(
											$author$project$App$Page$ResponseMsgs$DoctorWaitingLineCompany(newWt)))
									])))
						])) : ((!$author$project$App$Data$Id$uuidIsValid(statement.as)) ? _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$LoadStatementFromPerson,
								wt.dw.as,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorWaitingLineMsg(
											$author$project$App$Page$ResponseMsgs$DoctorWaitingLineStatement(newWt)))
									])))
						])) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil));
			case 5:
				var wt = msg.a;
				var loadableStatement = A2($author$project$App$Service$ApplicationResources$getStatementFromPerson, wt.dw.as, st._);
				var statement = function () {
					switch (loadableStatement.$) {
						case 3:
							var s = loadableStatement.a;
							return s;
						case 2:
							return $author$project$App$Data$Statement$emptyModel;
						case 1:
							return $author$project$App$Data$Statement$emptyModel;
						default:
							return $author$project$App$Data$Statement$emptyModel;
					}
				}();
				var newWt = _Utils_update(
					wt,
					{h8: statement});
				return (!$author$project$App$Data$Id$uuidIsValid(statement.as)) ? _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$LoadStatementFromPerson,
								wt.dw.as,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorWaitingLineMsg(
											$author$project$App$Page$ResponseMsgs$DoctorWaitingLineStatement(newWt)))
									])))
						])) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
			case 7:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							$author$project$App$SharedState$LoadDoctorInterview(
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorMsg(
										$author$project$App$Page$ResponseMsgs$DoctorWaitingLineMsg($author$project$App$Page$ResponseMsgs$DoctorWaitingLineStatementLoaded))
									])))
						]));
			case 6:
				var wt = msg.a;
				var playSound = (_Utils_cmp(
					$elm$core$List$length(
						A2(
							$elm$core$List$filter,
							function (wtl) {
								return _Utils_eq(
									$author$project$App$Data$StatementStatus$statusToInt(wtl.dw.aw),
									$author$project$App$Data$StatementStatus$statusToInt(11));
							},
							wt)),
					$elm$core$List$length(
						A2(
							$elm$core$List$filter,
							function (owtl) {
								return _Utils_eq(
									$author$project$App$Data$StatementStatus$statusToInt(owtl.dw.aw),
									$author$project$App$Data$StatementStatus$statusToInt(11));
							},
							model.e1))) > 0) ? $author$project$App$SharedState$asyncMsg($author$project$App$Page$Doctor$PageWaitingLine$PlaySound) : $elm$core$Platform$Cmd$none;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{e1: wt}),
					playSound,
					_List_Nil);
			case 8:
				var _v9 = $author$project$App$Components$Sound$enable(model.N);
				var nSound = _v9.a;
				var nSoundCmd = _v9.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{N: nSound}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageWaitingLine$SoundMsg, nSoundCmd),
					_List_Nil);
			case 9:
				var _v10 = $author$project$App$Components$Sound$playLongBeep(model.N);
				var nSound = _v10.a;
				var nSoundCmd = _v10.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{N: nSound}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageWaitingLine$SoundMsg, nSoundCmd),
					_List_Nil);
			case 10:
				var _v11 = $author$project$App$Components$Sound$disable(model.N);
				var sound = _v11.a;
				var nSoundCmd = _v11.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{N: sound}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageWaitingLine$SoundMsg, nSoundCmd),
					_List_Nil);
			default:
				var soundMsg = msg.a;
				var _v12 = A2($author$project$App$Components$Sound$update, soundMsg, model.N);
				var nSound = _v12.a;
				var nSoundCmd = _v12.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{N: nSound}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageWaitingLine$SoundMsg, nSoundCmd),
					_List_Nil);
		}
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$defaultFilter = function (filter) {
	return A2($author$project$App$Components$Filter$setOrder, 'created_at,desc', filter);
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 1:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor(
								$author$project$App$Route$DoctorListFinishedRoute(
									$author$project$App$Route$DoctorListFinished(
										$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$defaultFilter($author$project$App$Components$Filter$empty)))))),
					_List_Nil);
			case 2:
				var idx = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{b5: idx}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 3:
				var time = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 4:
				var docMsg = msg.a;
				var docsUpdated = A2(
					$elm$core$Dict$map,
					F2(
						function (_v1, doc) {
							return A2($author$project$App$Data$Document$updateUrl, docMsg, doc);
						}),
					model.an);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{an: docsUpdated}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$Page$PageDoctor$update = F3(
	function (msg, partialSharedState, model) {
		var _v0 = _Utils_Tuple2(msg, model.aQ);
		switch (_v0.a.$) {
			case 0:
				if (!_v0.b.$) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v1 = A3($author$project$App$Page$Doctor$PageLoading$update, pageMsg, partialSharedState, pageModel);
					var newPageModel = _v1.a;
					var newPageMsg = _v1.b;
					var stMsgs = _v1.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$Loading(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$LoadingMsg, newPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 1:
				if (_v0.b.$ === 1) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v2 = A3($author$project$App$Page$Doctor$PageWaitingLine$update, pageMsg, partialSharedState, pageModel);
					var newPageModel = _v2.a;
					var newPageMsg = _v2.b;
					var stMsgs = _v2.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$WaitingLine(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$WaitingLineMsg, newPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 2:
				if (_v0.b.$ === 2) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v3 = A3($author$project$App$Page$Doctor$PageReview$update, pageMsg, partialSharedState, pageModel);
					var newPageModel = _v3.a;
					var newPageMsg = _v3.b;
					var stMsgs = _v3.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$Review(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ReviewMsg, newPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 3:
				if (_v0.b.$ === 3) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v4 = A3($author$project$App$Page$Doctor$PageInterview$update, pageMsg, partialSharedState, pageModel);
					var newPageModel = _v4.a;
					var newPageMsg = _v4.b;
					var stMsgs = _v4.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$Interview(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$InterviewMsg, newPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 4:
				if (_v0.b.$ === 4) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v5 = A3($author$project$App$Page$Doctor$PageFinalEvaluation$update, pageMsg, partialSharedState, pageModel);
					var newPageModel = _v5.a;
					var newPageMsg = _v5.b;
					var stMsgs = _v5.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$FinalEvaluation(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$FinalEvaluationMsg, newPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 5:
				if (_v0.b.$ === 5) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v6 = A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$update, pageMsg, partialSharedState, pageModel);
					var newPageModel = _v6.a;
					var newPageMsg = _v6.b;
					var stMsgs = _v6.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$ViewEvaluation(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ViewEvaluationMsg, newPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 6:
				if (_v0.b.$ === 6) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v7 = A3($author$project$App$Page$Doctor$PageListPending$update, pageMsg, partialSharedState, pageModel);
					var newPageModel = _v7.a;
					var newPageMsg = _v7.b;
					var stMsgs = _v7.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$ListPending(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ListPendingMsg, newPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 9:
				if (_v0.b.$ === 7) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v8 = A3($author$project$App$Page$Doctor$PageListFinished$update, pageMsg, partialSharedState, pageModel);
					var newPageModel = _v8.a;
					var newPageMsg = _v8.b;
					var stMsgs = _v8.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$ListFinished(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ListFinishedMsg, newPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 10:
				if (_v0.b.$ === 10) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v9 = A3($author$project$App$Page$Doctor$PageDoctorSales$update, pageMsg, partialSharedState, pageModel);
					var newPageModel = _v9.a;
					var newPageMsg = _v9.b;
					var stMsgs = _v9.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$DoctorSales(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$DoctorSalesMsg, newPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 7:
				if (_v0.b.$ === 8) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v10 = A3($author$project$App$Page$Doctor$PageListReevaluation$update, pageMsg, partialSharedState, pageModel);
					var newPageModel = _v10.a;
					var newPageMsg = _v10.b;
					var stMsgs = _v10.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$ListReevaluation(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ListReevaluationMsg, newPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			default:
				if (_v0.b.$ === 9) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v11 = A3($author$project$App$Page$Doctor$PageReevaluation$update, pageMsg, partialSharedState, pageModel);
					var newPageModel = _v11.a;
					var newPageMsg = _v11.b;
					var stMsgs = _v11.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$Reevaluation(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ReevaluationMsg, newPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
		}
	});
var $author$project$App$SharedState$CreateDoctorUser = F4(
	function (a, b, c, d) {
		return {$: 6, a: a, b: b, c: c, d: d};
	});
var $author$project$App$Page$ResponseMsgs$DoctorSignUpCitiesLoaded = {$: 2};
var $author$project$App$Page$ResponseMsgs$DoctorSignUpGotSelfie = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorSignUpMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorSignUpSpecialtiesLoaded = {$: 3};
var $author$project$App$Page$ResponseMsgs$DoctorSignedUp = {$: 0};
var $author$project$App$Data$DigitalAccount$FieldEmail = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldPhone = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$SharedState$GetMemedCities = F2(
	function (a, b) {
		return {$: 31, a: a, b: b};
	});
var $author$project$App$SharedState$GetMemedSpecialties = function (a) {
	return {$: 32, a: a};
};
var $author$project$App$Page$PageDoctorSignUp$InvalidField = {$: 1};
var $author$project$App$Page$PageDoctorSignUp$Submitting = {$: 2};
var $author$project$App$SharedState$TakeSelfie = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Data$DigitalAccount$isInvalidFieldAddressNumber = function (str) {
	return ((!$elm$core$String$isEmpty(str)) || (str === 'N/A')) ? false : true;
};
var $author$project$App$Data$DigitalAccount$isInvalidFieldCnae = function (str) {
	return ($elm$core$String$length(
		A3(
			$elm$core$String$replace,
			'/',
			'',
			A3($elm$core$String$replace, '-', '', str))) !== 7) ? true : false;
};
var $author$project$App$Data$DigitalAccount$isInvalidFieldDocument = function (str) {
	return (($elm$core$String$length(str) > 10) && ($elm$core$String$length(str) < 15)) ? false : true;
};
var $author$project$App$Data$DigitalAccount$isInvalidFieldLicense = function (license) {
	return $elm$core$String$length(license) < 4;
};
var $author$project$App$Data$DigitalAccount$isInvalidFieldPhone = function (str) {
	return ($elm$core$String$length(str) <= 16) ? false : true;
};
var $author$project$App$Data$DigitalAccount$isInvalidFieldString = $elm$core$String$isEmpty;
var $author$project$App$Data$DigitalAccount$hasInvalidField = function (model) {
	return $author$project$App$Data$DigitalAccount$isInvalidFieldString(model.b4.hs) || ($author$project$App$Data$DigitalAccount$isInvalidFieldString(model.b4.iR) || ($author$project$App$Data$DigitalAccount$isInvalidFieldDocument(model.b4.i1) || ($author$project$App$Data$DigitalAccount$isInvalidFieldString(model.b4.c7) || ($author$project$App$Data$DigitalAccount$isInvalidFieldString(model.b4.al) || ($author$project$App$Data$DigitalAccount$isInvalidFieldPhone(model.b4.dx) || ($author$project$App$Data$DigitalAccount$isInvalidFieldString(model.dw.hl) || ($author$project$App$Data$DigitalAccount$isInvalidFieldDocument(model.dw.i1) || ($author$project$App$Data$DigitalAccount$isInvalidFieldString(model.dw.al) || ($author$project$App$Data$DigitalAccount$isInvalidFieldString(model.dw.cp) || ($author$project$App$Data$DigitalAccount$isInvalidFieldCnae(model.b4.b2) || ($author$project$App$Data$DigitalAccount$isInvalidFieldString(model.b4.cS) || ($author$project$App$Data$DigitalAccount$isInvalidFieldAddressNumber(model.b4.bb) || ($author$project$App$Data$DigitalAccount$isInvalidFieldString(model.b4.bp) || ($author$project$App$Data$DigitalAccount$isInvalidFieldString(model.b4.p) || ($author$project$App$Data$DigitalAccount$isInvalidFieldString(model.b4.cw) || ($author$project$App$Data$DigitalAccount$isInvalidFieldLicense(model.dm) || $author$project$App$Data$DigitalAccount$isInvalidFieldString(model.dn)))))))))))))))));
};
var $author$project$App$Data$User$isInvalidFieldEmail = $elm$core$String$isEmpty;
var $author$project$App$Data$User$isInvalidFieldPassword = $elm$core$String$isEmpty;
var $author$project$App$Data$User$isInvalidFieldPasswordCheck = F2(
	function (password, passwordCheck) {
		return $elm$core$String$isEmpty(passwordCheck) || (!_Utils_eq(password, passwordCheck));
	});
var $author$project$App$Data$User$hasInvalidField = function (model) {
	return $author$project$App$Data$User$isInvalidFieldEmail(model.c7) || ($author$project$App$Data$User$isInvalidFieldPassword(model.bf) || A2($author$project$App$Data$User$isInvalidFieldPasswordCheck, model.bf, model.dv));
};
var $author$project$App$Data$DigitalAccount$updateModel = F2(
	function (field, model) {
		var cmpny = model.b4;
		switch (field.$) {
			case 0:
				var name = field.a;
				return _Utils_update(
					model,
					{
						b4: _Utils_update(
							cmpny,
							{hs: name})
					});
			case 1:
				var name = field.a;
				return _Utils_update(
					model,
					{
						b4: _Utils_update(
							cmpny,
							{iR: name})
					});
			case 2:
				var document = field.a;
				var docNumber = A3(
					$elm$core$String$replace,
					'/',
					'',
					A3(
						$elm$core$String$replace,
						'.',
						'',
						A3($elm$core$String$replace, '-', '', document)));
				var newCompany = _Utils_update(
					cmpny,
					{
						i1: ($elm$core$String$length(docNumber) > 14) ? cmpny.i1 : docNumber
					});
				return _Utils_update(
					model,
					{b4: newCompany});
			case 4:
				var bd = field.a;
				return _Utils_update(
					model,
					{
						b4: _Utils_update(
							cmpny,
							{al: bd})
					});
			case 3:
				var e = field.a;
				return _Utils_update(
					model,
					{
						b4: _Utils_update(
							cmpny,
							{c7: e})
					});
			case 6:
				var p = field.a;
				return _Utils_update(
					model,
					{
						b4: _Utils_update(
							cmpny,
							{
								dx: A3(
									$elm$core$String$replace,
									' ',
									'',
									A3(
										$elm$core$String$replace,
										')',
										'',
										A3(
											$elm$core$String$replace,
											'(',
											'',
											A3($elm$core$String$replace, '-', '', '55' + p))))
							})
					});
			case 5:
				var c = field.a;
				var updatedC = ($elm$core$String$length(
					A3(
						$elm$core$String$replace,
						'/',
						'',
						A3($elm$core$String$replace, '-', '', c))) > 7) ? cmpny.b2 : A3(
					$elm$core$String$replace,
					'/',
					'',
					A3($elm$core$String$replace, '-', '', c));
				return _Utils_update(
					model,
					{
						b4: _Utils_update(
							cmpny,
							{b2: updatedC})
					});
			case 7:
				var lrName = field.a;
				var temp = model.dw;
				var updatedLegalRepresentative = _Utils_update(
					temp,
					{hl: lrName});
				return _Utils_update(
					model,
					{dw: updatedLegalRepresentative});
			case 8:
				var lrSurname = field.a;
				var temp = model.dw;
				var updatedLegalRepresentative = _Utils_update(
					temp,
					{ii: lrSurname});
				return _Utils_update(
					model,
					{dw: updatedLegalRepresentative});
			case 9:
				var lrDocument = field.a;
				var temp = model.dw;
				var docNumber = A3(
					$elm$core$String$replace,
					'.',
					'',
					A3($elm$core$String$replace, '-', '', lrDocument));
				var updatedperson = _Utils_update(
					temp,
					{
						i1: ($elm$core$String$length(docNumber) > 11) ? temp.i1 : docNumber
					});
				return _Utils_update(
					model,
					{dw: updatedperson});
			case 10:
				var s = field.a;
				var temp = model.dw;
				var updatedperson = _Utils_update(
					temp,
					{dJ: s});
				return _Utils_update(
					model,
					{dw: updatedperson});
			case 11:
				var lrBirthdate = field.a;
				var temp = model.dw;
				var updatedperson = _Utils_update(
					temp,
					{al: lrBirthdate});
				return _Utils_update(
					model,
					{dw: updatedperson});
			case 12:
				var lrMotherName = field.a;
				var temp = model.dw;
				var updatedperson = _Utils_update(
					temp,
					{cp: lrMotherName});
				return _Utils_update(
					model,
					{dw: updatedperson});
			case 13:
				var aStreet = field.a;
				var temp = model.b4;
				var updatedCompany = _Utils_update(
					temp,
					{cS: aStreet});
				return _Utils_update(
					model,
					{b4: updatedCompany});
			case 14:
				var aNumber = field.a;
				var temp = model.b4;
				var updatedCompany = _Utils_update(
					temp,
					{bb: aNumber});
				return _Utils_update(
					model,
					{b4: updatedCompany});
			case 15:
				var aNumber = field.a;
				var temp = model.b4;
				var updatedCompany = _Utils_update(
					temp,
					{bb: aNumber});
				return _Utils_update(
					model,
					{b4: updatedCompany});
			case 16:
				var aNeighborhood = field.a;
				var temp = model.b4;
				var updatedCompany = _Utils_update(
					temp,
					{ef: aNeighborhood});
				return _Utils_update(
					model,
					{b4: updatedCompany});
			case 17:
				var aCity = field.a;
				var temp = model.b4;
				var updatedCompany = _Utils_update(
					temp,
					{bp: aCity});
				return _Utils_update(
					model,
					{b4: updatedCompany});
			case 18:
				var aState = field.a;
				var temp = model.b4;
				var updatedCompany = _Utils_update(
					temp,
					{p: aState});
				return _Utils_update(
					model,
					{b4: updatedCompany});
			case 19:
				var aPostCode = field.a;
				var updatedPostalCode = A3($elm$core$String$replace, '-', '', aPostCode);
				var temp = model.b4;
				var updatedCompany = _Utils_update(
					temp,
					{
						cw: ($elm$core$String$length(updatedPostalCode) > 8) ? temp.cw : updatedPostalCode
					});
				return _Utils_update(
					model,
					{b4: updatedCompany});
			case 20:
				var baBankNumber = field.a;
				var temp = model.ak;
				var updatedBankAccount = _Utils_update(
					temp,
					{d4: baBankNumber});
				return _Utils_update(
					model,
					{ak: updatedBankAccount});
			case 21:
				var baAccountNumber = field.a;
				var temp = model.ak;
				var updatedBankAccount = _Utils_update(
					temp,
					{
						d_: A3(
							$elm$core$String$replace,
							'.',
							'',
							A3($elm$core$String$replace, '-', '', baAccountNumber))
					});
				return _Utils_update(
					model,
					{ak: updatedBankAccount});
			case 22:
				var baAgency = field.a;
				var temp = model.ak;
				var updatedBankAccount = _Utils_update(
					temp,
					{d1: baAgency});
				return _Utils_update(
					model,
					{ak: updatedBankAccount});
			case 23:
				var baType = field.a;
				var temp = model.ak;
				var updatedBankAccount = _Utils_update(
					temp,
					{d$: baType});
				return _Utils_update(
					model,
					{ak: updatedBankAccount});
			case 24:
				var license = field.a;
				return _Utils_update(
					model,
					{
						dm: A2($elm$core$String$filter, $elm$core$Char$isDigit, license)
					});
			case 25:
				var licenseState = field.a;
				return _Utils_update(
					model,
					{dn: licenseState});
			case 26:
				var city = field.a;
				return _Utils_update(
					model,
					{et: city});
			default:
				var specialty = field.a;
				return _Utils_update(
					model,
					{ev: specialty});
		}
	});
var $author$project$Mask$maskStringHelper = F3(
	function (pattern, value, result) {
		maskStringHelper:
		while (true) {
			var valueUncons = $elm$core$String$uncons(value);
			if (!valueUncons.$) {
				var _v1 = valueUncons.a;
				var charValue = _v1.a;
				var rest = _v1.b;
				var _v2 = $elm$core$String$uncons(pattern);
				if (!_v2.$) {
					var _v3 = _v2.a;
					var a = _v3.a;
					var b = _v3.b;
					if (a === '#') {
						var $temp$pattern = b,
							$temp$value = rest,
							$temp$result = _Utils_ap(
							result,
							$elm$core$String$fromChar(charValue));
						pattern = $temp$pattern;
						value = $temp$value;
						result = $temp$result;
						continue maskStringHelper;
					} else {
						var $temp$pattern = b,
							$temp$value = value,
							$temp$result = _Utils_ap(
							result,
							$elm$core$String$fromChar(a));
						pattern = $temp$pattern;
						value = $temp$value;
						result = $temp$result;
						continue maskStringHelper;
					}
				} else {
					return result;
				}
			} else {
				return result;
			}
		}
	});
var $author$project$Mask$number = F2(
	function (pattern, value) {
		return function (v) {
			return A3($author$project$Mask$maskStringHelper, pattern, v, '');
		}(
			A2($elm$core$String$filter, $elm$core$Char$isDigit, value));
	});
var $author$project$Mask$cpf = function (c) {
	return A2($author$project$Mask$number, '###.###.###-##', c);
};
var $author$project$App$Data$Doctor$updateModel = F2(
	function (field, model) {
		switch (field.$) {
			case 0:
				var name = field.a;
				return _Utils_update(
					model,
					{hl: name});
			case 1:
				var surname = field.a;
				return _Utils_update(
					model,
					{ii: surname});
			case 2:
				var birthDate = field.a;
				return _Utils_update(
					model,
					{
						al: function () {
							if (birthDate === '') {
								return $elm$time$Time$millisToPosix(0);
							} else {
								var b = birthDate;
								return A2(
									$elm$core$Result$withDefault,
									model.al,
									$rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(b));
							}
						}()
					});
			case 3:
				var document = field.a;
				return _Utils_update(
					model,
					{
						i1: $author$project$Mask$cpf(document)
					});
			case 4:
				var photo = field.a;
				return _Utils_update(
					model,
					{
						dy: function () {
							if (photo === '') {
								return $elm$core$Maybe$Nothing;
							} else {
								var p = photo;
								return $elm$core$Maybe$Just(p);
							}
						}()
					});
			case 5:
				var sex = field.a;
				return _Utils_update(
					model,
					{
						dJ: $author$project$App$Data$Person$sexFromString(sex)
					});
			case 6:
				var license = field.a;
				return _Utils_update(
					model,
					{
						dm: A2($elm$core$String$filter, $elm$core$Char$isDigit, license)
					});
			case 7:
				var licenseState = field.a;
				return _Utils_update(
					model,
					{dn: licenseState});
			case 8:
				var city = field.a;
				return _Utils_update(
					model,
					{et: city});
			default:
				var specialty = field.a;
				return _Utils_update(
					model,
					{ev: specialty});
		}
	});
var $author$project$App$Data$User$updateModel = F2(
	function (field, model) {
		switch (field.$) {
			case 0:
				var email = field.a;
				return _Utils_update(
					model,
					{c7: email});
			case 1:
				var password = field.a;
				return _Utils_update(
					model,
					{bf: password});
			case 2:
				var passwordCheck = field.a;
				return _Utils_update(
					model,
					{dv: passwordCheck});
			case 3:
				var passwordOld = field.a;
				return _Utils_update(
					model,
					{eE: passwordOld});
			default:
				var companyId = field.a;
				return $author$project$App$Data$Id$uuidIsValid(companyId) ? _Utils_update(
					model,
					{
						gx: $elm$core$Maybe$Just(companyId)
					}) : _Utils_update(
					model,
					{gx: $elm$core$Maybe$Nothing});
		}
	});
var $author$project$App$Page$PageDoctorSignUp$update = F3(
	function (msg, partialSharedState, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						partialSharedState.fC,
						$author$project$App$Route$Public($author$project$App$Route$Login)),
					_List_Nil);
			case 1:
				var field = msg.a;
				var updatedJuno = model.O;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							O: updatedJuno,
							bu: A2($author$project$App$Data$Doctor$updateModel, field, model.bu)
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 2:
				var field = msg.a;
				var chanceCities = function () {
					if (field.$ === 25) {
						var v = field.a;
						return _Utils_Tuple2(true, v);
					} else {
						return _Utils_Tuple2(false, '');
					}
				}();
				var sharedMsgs = chanceCities.a ? _List_fromArray(
					[
						$author$project$App$SharedState$APIMsg(
						A2(
							$author$project$App$SharedState$GetMemedCities,
							'filter[uf]=' + chanceCities.b,
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$DoctorSignUpMsg($author$project$App$Page$ResponseMsgs$DoctorSignUpCitiesLoaded)
								]))),
						$author$project$App$SharedState$APIMsg(
						$author$project$App$SharedState$GetMemedSpecialties(
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$DoctorSignUpMsg($author$project$App$Page$ResponseMsgs$DoctorSignUpSpecialtiesLoaded)
								])))
					]) : _List_Nil;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							O: A2($author$project$App$Data$DigitalAccount$updateModel, field, model.O)
						}),
					$elm$core$Platform$Cmd$none,
					sharedMsgs);
			case 3:
				var field = msg.a;
				var value = function () {
					if (!field.$) {
						var v = field.a;
						return v;
					} else {
						return '';
					}
				}();
				var updatedJuno = function () {
					if (!field.$) {
						var v = field.a;
						return A2(
							$author$project$App$Data$DigitalAccount$updateModel,
							$author$project$App$Data$DigitalAccount$FieldEmail(value),
							model.O);
					} else {
						return model.O;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							O: updatedJuno,
							bS: A2($author$project$App$Data$User$updateModel, field, model.bS)
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 4:
				var field = msg.a;
				var value = function () {
					if (field.$ === 1) {
						var v = field.a;
						return v;
					} else {
						return '';
					}
				}();
				var updatedJuno = A2(
					$author$project$App$Data$DigitalAccount$updateModel,
					$author$project$App$Data$DigitalAccount$FieldPhone(value),
					model.O);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							O: updatedJuno,
							dx: A2($author$project$App$Data$Contact$updateModel, field, model.dx)
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 5:
				var hasError = $author$project$App$Data$User$hasInvalidField(model.bS) || ($author$project$App$Data$DigitalAccount$hasInvalidField(model.O) && model.cW);
				return hasError ? _Utils_Tuple3(
					_Utils_update(
						model,
						{aw: $author$project$App$Page$PageDoctorSignUp$InvalidField}),
					$elm$core$Platform$Cmd$none,
					_List_Nil) : _Utils_Tuple3(
					_Utils_update(
						model,
						{aw: $author$project$App$Page$PageDoctorSignUp$Submitting}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A4(
								$author$project$App$SharedState$CreateDoctorUser,
								model.bS,
								model.dx,
								model.O,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$DoctorSignUpMsg($author$project$App$Page$ResponseMsgs$DoctorSignedUp)
									])))
						]));
			case 6:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$VideoMsg(
							$author$project$App$SharedState$TakeSelfie(
								A2($elm$core$Basics$composeL, $author$project$App$Page$ResponseMsgs$DoctorSignUpMsg, $author$project$App$Page$ResponseMsgs$DoctorSignUpGotSelfie)))
						]));
			case 7:
				var videoMsg = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$videoMsgToSharedMsg(videoMsg)
						]));
			default:
				var formMode = msg.a;
				var boolFormMode = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(formMode)) === 1;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cW: boolFormMode}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
		}
	});
var $author$project$App$Page$PageLoading$update = F3(
	function (msg, st, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$SharedState$Login = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$LoginMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$ResponseMsgs$LoginResponse = 0;
var $author$project$App$Page$ResponseMsgs$SharedLoginResponse = {$: 15};
var $author$project$App$Page$PageLogin$update = F3(
	function (msg, st, model) {
		var user = model.bS;
		switch (msg.$) {
			case 0:
				var email = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							bS: _Utils_update(
								user,
								{c7: email})
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 1:
				var passwd = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							bS: _Utils_update(
								user,
								{bf: passwd})
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{e$: $author$project$App$Service$Loadable$Loading}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$Login,
								user,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SharedMsg($author$project$App$Page$ResponseMsgs$SharedLoginResponse),
										$author$project$App$Page$ResponseMsgs$LoginMsg(0)
									])))
						]));
			case 3:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Public($author$project$App$Route$DoctorSignUp)),
					_List_Nil);
			case 4:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Public($author$project$App$Route$UserSignUp)),
					_List_Nil);
			default:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Public($author$project$App$Route$UserRequestRecoverPassword)),
					_List_Nil);
		}
	});
var $author$project$App$Page$PageProponent$Confirm = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$PageProponent$DoctorReview = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Page$PageProponent$DoctorReviewMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$PageProponent$EnteringWaitingLine = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$PageProponent$EnteringWaitingLineMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Page$PageProponent$ErrorLoading = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$PageProponent$ErrorLoadingMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$PageProponent$FinishedProposition = function (a) {
	return {$: 14, a: a};
};
var $author$project$App$Page$PageProponent$FinishedPropositionMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$App$Page$PageProponent$Interview = function (a) {
	return {$: 12, a: a};
};
var $author$project$App$Page$PageProponent$InterviewMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$PageProponent$Intro = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$PageProponent$IntroMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$PageProponent$NotActivated = function (a) {
	return {$: 15, a: a};
};
var $author$project$App$Page$PageProponent$NotActivatedMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$App$Page$PageProponent$Payment = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$SharedState$ProponentInformOnline = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$ProponentInformOnlineResponse = {$: 1};
var $author$project$App$Page$PageProponent$Questions = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$PageProponent$QuestionsMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$PageProponent$Screening = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$PageProponent$ScreeningMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Data$StatementStatus$StatusProponentQuestions = 6;
var $author$project$App$Page$PageProponent$WaitingInLine = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Page$PageProponent$WaitingInLineMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$PageProponent$WaitingResults = function (a) {
	return {$: 13, a: a};
};
var $author$project$App$Page$PageProponent$WaitingResultsMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Page$Proponent$PageError$ProponentError = $elm$core$Basics$identity;
var $author$project$App$Page$Proponent$PageError$init = function (err) {
	return _Utils_Tuple3(
		err,
		$elm$core$Platform$Cmd$none,
		_List_fromArray(
			[
				$author$project$App$SharedState$VideoMsg($author$project$App$SharedState$StopConference)
			]));
};
var $author$project$App$Page$ResponseMsgs$ProponentAdvanced = {$: 3};
var $author$project$App$SharedState$StatementProponentAdvance = F2(
	function (a, b) {
		return {$: 44, a: a, b: b};
	});
var $author$project$App$Page$PageProponent$WaitingForRoom = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$SharedState$LoadHolidayTextForTemplate = F2(
	function (a, b) {
		return {$: 20, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$PropHolidayMessageReceived = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$ProponentConfirmMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$Proponent$PageConfirmInterview$NoImage = 0;
var $author$project$App$Page$Proponent$PageConfirmInterview$emptyModel = {
	aK: $elm$core$Maybe$Nothing,
	aL: $elm$core$Maybe$Nothing,
	de: $elm$core$Maybe$Nothing,
	bA: 0,
	dj: true,
	dk: false,
	dL: true,
	bO: $elm$core$Maybe$Just('NotTested'),
	bT: $elm$core$Maybe$Nothing
};
var $author$project$App$Page$Proponent$PageConfirmInterview$hasAllSelfies = function (fullStmt) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (_v0) {
				return true;
			},
			fullStmt.g.dw.dy));
};
var $author$project$App$Page$Proponent$PageConfirmInterview$init = function (fullStmt) {
	return _Utils_Tuple3(
		_Utils_update(
			$author$project$App$Page$Proponent$PageConfirmInterview$emptyModel,
			{
				dj: !$author$project$App$Page$Proponent$PageConfirmInterview$hasAllSelfies(fullStmt)
			}),
		$elm$core$Platform$Cmd$none,
		_List_fromArray(
			[
				$author$project$App$SharedState$VideoMsg($author$project$App$SharedState$StopConference),
				$author$project$App$SharedState$APIMsg(
				A2(
					$author$project$App$SharedState$LoadHolidayTextForTemplate,
					fullStmt.g.e.eY,
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$ProponentMsg(
							$author$project$App$Page$ResponseMsgs$ProponentConfirmMsg(
								$author$project$App$Page$ResponseMsgs$PropHolidayMessageReceived(fullStmt.g.e.eY)))
						])))
			]));
};
var $author$project$App$Page$Proponent$PageFinishedProposition$init = function (stmt) {
	return _Utils_Tuple3(
		{a5: stmt},
		$elm$core$Platform$Cmd$none,
		_List_fromArray(
			[
				$author$project$App$SharedState$VideoMsg($author$project$App$SharedState$StopConference)
			]));
};
var $author$project$App$Page$Proponent$PageInterview$init = function (fullStmt) {
	return _Utils_Tuple3(
		{P: fullStmt},
		$elm$core$Platform$Cmd$none,
		_List_Nil);
};
var $author$project$App$SharedState$LoadTermsByStatementId = F2(
	function (a, b) {
		return {$: 84, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$PropIntroDocumentLoaded = $elm$core$Basics$identity;
var $author$project$App$Page$ResponseMsgs$ProponentIntroMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$App$Page$Proponent$PageIntro$init = function (stmtId) {
	return _Utils_Tuple3(
		{cQ: false, cR: false, d0: false, c8: $elm$core$Maybe$Nothing, eV: stmtId, dQ: $elm$core$Maybe$Nothing},
		$elm$core$Platform$Cmd$none,
		_List_fromArray(
			[
				$author$project$App$SharedState$VideoMsg($author$project$App$SharedState$StopConference),
				$author$project$App$SharedState$APIMsg(
				A2(
					$author$project$App$SharedState$LoadTermsByStatementId,
					stmtId,
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$ProponentMsg(
							$author$project$App$Page$ResponseMsgs$ProponentIntroMsg(stmtId))
						])))
			]));
};
var $author$project$App$Page$Proponent$PageNotActivated$init = function (stmt) {
	return _Utils_Tuple3(
		{a5: stmt},
		$elm$core$Platform$Cmd$none,
		_List_Nil);
};
var $author$project$App$Page$Proponent$PagePayment$Filling = 0;
var $author$project$App$Components$Payment$init = _Utils_Tuple2(
	{
		e7: '',
		fv: '',
		fB: false,
		du: $elm$core$Dict$fromList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'publicToken',
					_Utils_Tuple2('', 0)),
					_Utils_Tuple2(
					'cNumber',
					_Utils_Tuple2('', 2)),
					_Utils_Tuple2(
					'hName',
					_Utils_Tuple2('', 1)),
					_Utils_Tuple2(
					'secCode',
					_Utils_Tuple2('', 5)),
					_Utils_Tuple2(
					'expMonth',
					_Utils_Tuple2('', 3)),
					_Utils_Tuple2(
					'expYear',
					_Utils_Tuple2('', 4))
				]))
	},
	$elm$core$Platform$Cmd$none);
var $author$project$App$Page$Proponent$PagePayment$init = function () {
	var _v0 = $author$project$App$Components$Payment$init;
	var paymentModel = _v0.a;
	var paymentCmd = _v0.b;
	return _Utils_Tuple3(
		{
			cS: {bp: '', gy: '', hn: '', bb: '', hA: '', p: '', ic: ''},
			d0: false,
			c7: '',
			c8: $elm$core$Maybe$Nothing,
			hv: paymentModel,
			hx: 0,
			dG: false,
			aw: 0
		},
		$elm$core$Platform$Cmd$none,
		_List_fromArray(
			[
				$author$project$App$SharedState$VideoMsg($author$project$App$SharedState$StopConference)
			]));
}();
var $author$project$App$Page$Proponent$PageQuestions$init = function (sharedState) {
	return _Utils_Tuple3(
		{
			fp: $elm$core$Dict$empty,
			en: sharedState.ia.h8.g.e.bG,
			hg: $elm$core$Maybe$Nothing,
			cA: $elm$core$Dict$fromList(
				A2(
					$elm$core$List$map,
					function (s) {
						return _Utils_Tuple2(s.hX.bB, s);
					},
					sharedState.ia.h8.jM)),
			eU: $elm$core$Dict$size(sharedState.ia.h8.fh) > 0,
			fX: A3(
				$elm$core$Dict$insert,
				$author$project$App$Data$Statement$depIndexToInt($author$project$App$Data$Statement$Bearer),
				sharedState.ia.h8.g,
				sharedState.ia.h8.fh)
		},
		$elm$core$Platform$Cmd$none,
		_List_fromArray(
			[
				$author$project$App$SharedState$VideoMsg($author$project$App$SharedState$StopConference)
			]));
};
var $author$project$App$Page$Proponent$PageScreening$NoData = 0;
var $author$project$App$Page$Proponent$PageScreening$init = 0;
var $author$project$App$SharedState$LoadDoctorsOnlineForTemplate = F2(
	function (a, b) {
		return {$: 18, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$PropWaitingLineHolidayTextLoaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$PropWaitingLineOnlineDoctorsLoaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$ResponseMsgs$ProponentWaitingLineMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Page$Proponent$PageWaitingInLine$init = function (fullStmt) {
	return _Utils_Tuple3(
		{gJ: _List_Nil, P: fullStmt, de: $elm$core$Maybe$Nothing},
		$elm$core$Platform$Cmd$none,
		_List_fromArray(
			[
				$author$project$App$SharedState$APIMsg(
				A2(
					$author$project$App$SharedState$LoadDoctorsOnlineForTemplate,
					fullStmt.g.e.eY,
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$ProponentMsg(
							$author$project$App$Page$ResponseMsgs$ProponentWaitingLineMsg(
								$author$project$App$Page$ResponseMsgs$PropWaitingLineOnlineDoctorsLoaded(fullStmt.g.e.eY)))
						]))),
				$author$project$App$SharedState$APIMsg(
				A2(
					$author$project$App$SharedState$LoadHolidayTextForTemplate,
					fullStmt.g.e.eY,
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$ProponentMsg(
							$author$project$App$Page$ResponseMsgs$ProponentWaitingLineMsg(
								$author$project$App$Page$ResponseMsgs$PropWaitingLineHolidayTextLoaded(fullStmt.g.e.eY)))
						]))),
				$author$project$App$SharedState$VideoMsg($author$project$App$SharedState$StopConference)
			]));
};
var $author$project$App$Page$Proponent$PageWaitingResults$init = function (stmt) {
	return _Utils_Tuple3(
		{a5: stmt},
		$elm$core$Platform$Cmd$none,
		_List_fromArray(
			[
				$author$project$App$SharedState$VideoMsg($author$project$App$SharedState$StopConference)
			]));
};
var $author$project$App$Page$Proponent$PageWaitingRoom$NoData = 0;
var $author$project$App$Page$Proponent$PageWaitingRoom$init = 0;
var $author$project$App$Page$PageProponent$routeFromStatus = function (status) {
	switch (status) {
		case 3:
			return 2;
		case 9:
			return 0;
		case 4:
			return 2;
		case 5:
			return 9;
		case 6:
			return 3;
		case 8:
			return 4;
		case 7:
			return 5;
		case 11:
			return 5;
		case 12:
			return 6;
		case 13:
			return 7;
		case 14:
			return 7;
		case 15:
			return 7;
		case 16:
			return 7;
		case 18:
			return 7;
		case 19:
			return 7;
		case 20:
			return 7;
		case 21:
			return 7;
		case 22:
			return 8;
		case 23:
			return 8;
		case 24:
			return 8;
		case 25:
			return 8;
		default:
			return 0;
	}
};
var $author$project$App$Page$PageProponent$pageFromStatus = F2(
	function (st, model) {
		var routeCmd = A2(
			$author$project$App$Route$pushUrl,
			st.fC,
			A2(
				$author$project$App$Route$Proponent,
				st.ia.h8.g.e.as,
				$author$project$App$Page$PageProponent$routeFromStatus(st.ia.h8.g.e.aw)));
		var informContactMsg = $author$project$App$SharedState$APIMsg(
			A2(
				$author$project$App$SharedState$StatementProponentAdvance,
				st.ia.h8,
				_List_fromArray(
					[
						$author$project$App$Page$ResponseMsgs$ProponentMsg($author$project$App$Page$ResponseMsgs$ProponentAdvanced)
					])));
		var _v0 = _Utils_Tuple2(st.ia.h8.g.e.aw, model.aQ);
		switch (_v0.a) {
			case 3:
				switch (_v0.b.$) {
					case 3:
						var _v1 = _v0.a;
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
					case 0:
						var _v2 = _v0.a;
						return _Utils_Tuple3(
							model,
							$elm$core$Platform$Cmd$none,
							_List_fromArray(
								[informContactMsg]));
					default:
						var _v3 = _v0.a;
						var _v4 = $author$project$App$Page$Proponent$PageIntro$init(st.ia.h8.g.e.as);
						var page = _v4.a;
						var cmd = _v4.b;
						var stMsgs = _v4.c;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Page$PageProponent$Intro(page)
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$IntroMsg, cmd),
										routeCmd
									])),
							stMsgs);
				}
			case 9:
				if (_v0.b.$ === 5) {
					var _v5 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v6 = _v0.a;
					var page = $author$project$App$Page$Proponent$PageWaitingRoom$init;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$WaitingForRoom(page)
							}),
						routeCmd,
						_List_Nil);
				}
			case 4:
				if (_v0.b.$ === 3) {
					var _v7 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v8 = _v0.a;
					var _v9 = $author$project$App$Page$Proponent$PageIntro$init(st.ia.h8.g.e.as);
					var page = _v9.a;
					var cmd = _v9.b;
					var stMsgs = _v9.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Intro(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$IntroMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 5:
				if (_v0.b.$ === 4) {
					var _v10 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v11 = _v0.a;
					var _v12 = $author$project$App$Page$Proponent$PagePayment$init;
					var page = _v12.a;
					var cmd = _v12.b;
					var stMsgs = _v12.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Payment(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$PaymentMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 6:
				if (_v0.b.$ === 6) {
					var _v13 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v14 = _v0.a;
					var _v15 = $author$project$App$Page$Proponent$PageQuestions$init(st);
					var page = _v15.a;
					var cmd = _v15.b;
					var stMsgs = _v15.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Questions(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$QuestionsMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 8:
				if (_v0.b.$ === 8) {
					var _v16 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v17 = _v0.a;
					var _v18 = $author$project$App$Page$Proponent$PageConfirmInterview$init(st.ia.h8);
					var page = _v18.a;
					var cmd = _v18.b;
					var stMsgs = _v18.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Confirm(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$ConfirmMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 7:
				if (_v0.b.$ === 7) {
					var _v19 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v20 = _v0.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Screening($author$project$App$Page$Proponent$PageScreening$init)
							}),
						routeCmd,
						_List_Nil);
				}
			case 11:
				if (_v0.b.$ === 10) {
					var _v21 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v22 = _v0.a;
					var _v23 = $author$project$App$Page$Proponent$PageWaitingInLine$init(st.ia.h8);
					var page = _v23.a;
					var cmd = _v23.b;
					var stMsgs = _v23.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$WaitingInLine(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$WaitingInLineMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 10:
				if (_v0.b.$ === 9) {
					var _v24 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v25 = _v0.a;
					var _v26 = $author$project$App$Page$Proponent$PageWaitingInLine$init(st.ia.h8);
					var page = _v26.a;
					var cmd = _v26.b;
					var stMsgs = _v26.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$WaitingInLine(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$WaitingInLineMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 12:
				if (_v0.b.$ === 11) {
					var _v27 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v28 = _v0.a;
					var _v29 = $author$project$App$Page$Proponent$PageInterview$init(st.ia.h8);
					var page = _v29.a;
					var cmd = _v29.b;
					var stMsgs = _v29.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Interview(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$InterviewMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 13:
				if (_v0.b.$ === 12) {
					var _v30 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v31 = _v0.a;
					var _v32 = $author$project$App$Page$Proponent$PageInterview$init(st.ia.h8);
					var page = _v32.a;
					var cmd = _v32.b;
					var stMsgs = _v32.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Interview(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$InterviewMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 14:
				if (_v0.b.$ === 12) {
					var _v33 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v34 = _v0.a;
					var _v35 = $author$project$App$Page$Proponent$PageInterview$init(st.ia.h8);
					var page = _v35.a;
					var cmd = _v35.b;
					var stMsgs = _v35.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Interview(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$InterviewMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 15:
				if (_v0.b.$ === 12) {
					var _v36 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v37 = _v0.a;
					var _v38 = $author$project$App$Page$Proponent$PageInterview$init(st.ia.h8);
					var page = _v38.a;
					var cmd = _v38.b;
					var stMsgs = _v38.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Interview(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$InterviewMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 16:
				if (_v0.b.$ === 12) {
					var _v39 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v40 = _v0.a;
					var _v41 = $author$project$App$Page$Proponent$PageInterview$init(st.ia.h8);
					var page = _v41.a;
					var cmd = _v41.b;
					var stMsgs = _v41.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Interview(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$InterviewMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 17:
				if (_v0.b.$ === 12) {
					var _v42 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v43 = _v0.a;
					var _v44 = $author$project$App$Page$Proponent$PageInterview$init(st.ia.h8);
					var page = _v44.a;
					var cmd = _v44.b;
					var stMsgs = _v44.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Interview(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$InterviewMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 18:
				if (_v0.b.$ === 12) {
					var _v45 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v46 = _v0.a;
					var _v47 = $author$project$App$Page$Proponent$PageInterview$init(st.ia.h8);
					var page = _v47.a;
					var cmd = _v47.b;
					var stMsgs = _v47.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Interview(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$InterviewMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 19:
				if (_v0.b.$ === 12) {
					var _v48 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v49 = _v0.a;
					var _v50 = $author$project$App$Page$Proponent$PageInterview$init(st.ia.h8);
					var page = _v50.a;
					var cmd = _v50.b;
					var stMsgs = _v50.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Interview(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$InterviewMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 20:
				if (_v0.b.$ === 12) {
					var _v51 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v52 = _v0.a;
					var _v53 = $author$project$App$Page$Proponent$PageInterview$init(st.ia.h8);
					var page = _v53.a;
					var cmd = _v53.b;
					var stMsgs = _v53.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Interview(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$InterviewMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 21:
				if (_v0.b.$ === 12) {
					var _v54 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v55 = _v0.a;
					var _v56 = $author$project$App$Page$Proponent$PageInterview$init(st.ia.h8);
					var page = _v56.a;
					var cmd = _v56.b;
					var stMsgs = _v56.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Interview(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$InterviewMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 22:
				if (_v0.b.$ === 13) {
					var _v57 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v58 = _v0.a;
					var _v59 = $author$project$App$Page$Proponent$PageWaitingResults$init(st.ia.h8);
					var page = _v59.a;
					var cmd = _v59.b;
					var stMsgs = _v59.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$WaitingResults(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$WaitingResultsMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 23:
				if (_v0.b.$ === 13) {
					var _v60 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v61 = _v0.a;
					var _v62 = $author$project$App$Page$Proponent$PageWaitingResults$init(st.ia.h8);
					var page = _v62.a;
					var cmd = _v62.b;
					var stMsgs = _v62.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$WaitingResults(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$WaitingResultsMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 24:
				if (_v0.b.$ === 13) {
					var _v63 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v64 = _v0.a;
					var _v65 = $author$project$App$Page$Proponent$PageWaitingResults$init(st.ia.h8);
					var page = _v65.a;
					var cmd = _v65.b;
					var stMsgs = _v65.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$WaitingResults(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$WaitingResultsMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 25:
				if (_v0.b.$ === 13) {
					var _v66 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v67 = _v0.a;
					var _v68 = $author$project$App$Page$Proponent$PageWaitingResults$init(st.ia.h8);
					var page = _v68.a;
					var cmd = _v68.b;
					var stMsgs = _v68.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$WaitingResults(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$WaitingResultsMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 26:
				if (_v0.b.$ === 13) {
					var _v69 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v70 = _v0.a;
					var _v71 = $author$project$App$Page$Proponent$PageWaitingResults$init(st.ia.h8);
					var page = _v71.a;
					var cmd = _v71.b;
					var stMsgs = _v71.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$WaitingResults(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$WaitingResultsMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 33:
				if (_v0.b.$ === 14) {
					var _v72 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v73 = _v0.a;
					var _v74 = $author$project$App$Page$Proponent$PageFinishedProposition$init(st.ia.h8);
					var page = _v74.a;
					var cmd = _v74.b;
					var stMsgs = _v74.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$FinishedProposition(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$FinishedPropositionMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 34:
				if (_v0.b.$ === 14) {
					var _v75 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v76 = _v0.a;
					var _v77 = $author$project$App$Page$Proponent$PageFinishedProposition$init(st.ia.h8);
					var page = _v77.a;
					var cmd = _v77.b;
					var stMsgs = _v77.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$FinishedProposition(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$FinishedPropositionMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 35:
				if (_v0.b.$ === 14) {
					var _v78 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v79 = _v0.a;
					var _v80 = $author$project$App$Page$Proponent$PageFinishedProposition$init(st.ia.h8);
					var page = _v80.a;
					var cmd = _v80.b;
					var stMsgs = _v80.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$FinishedProposition(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$FinishedPropositionMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 36:
				if (_v0.b.$ === 14) {
					var _v81 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v82 = _v0.a;
					var _v83 = $author$project$App$Page$Proponent$PageFinishedProposition$init(st.ia.h8);
					var page = _v83.a;
					var cmd = _v83.b;
					var stMsgs = _v83.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$FinishedProposition(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$FinishedPropositionMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 29:
				if (_v0.b.$ === 14) {
					var _v84 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v85 = _v0.a;
					var _v86 = $author$project$App$Page$Proponent$PageFinishedProposition$init(st.ia.h8);
					var page = _v86.a;
					var cmd = _v86.b;
					var stMsgs = _v86.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$FinishedProposition(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$FinishedPropositionMsg, cmd),
									routeCmd
								])),
						stMsgs);
				}
			case 0:
				var _v87 = _v0.a;
				var _v88 = $author$project$App$Page$Proponent$PageNotActivated$init(st.ia.h8);
				var page = _v88.a;
				var cmd = _v88.b;
				var stMsgs = _v88.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aQ: $author$project$App$Page$PageProponent$NotActivated(page)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$NotActivatedMsg, cmd),
								routeCmd
							])),
					stMsgs);
			case 1:
				var _v89 = _v0.a;
				var _v90 = $author$project$App$Page$Proponent$PageNotActivated$init(st.ia.h8);
				var page = _v90.a;
				var cmd = _v90.b;
				var stMsgs = _v90.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aQ: $author$project$App$Page$PageProponent$NotActivated(page)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$NotActivatedMsg, cmd),
								routeCmd
							])),
					stMsgs);
			case 2:
				var _v91 = _v0.a;
				var _v92 = $author$project$App$Page$Proponent$PageNotActivated$init(st.ia.h8);
				var page = _v92.a;
				var cmd = _v92.b;
				var stMsgs = _v92.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aQ: $author$project$App$Page$PageProponent$NotActivated(page)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$NotActivatedMsg, cmd),
								routeCmd
							])),
					stMsgs);
			default:
				var _v93 = $author$project$App$Page$Proponent$PageLoading$init(st.ia.h8);
				var page = _v93.a;
				var cmd = _v93.b;
				var stMsgs = _v93.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aQ: $author$project$App$Page$PageProponent$Loading(page)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$LoadingMsg, cmd),
								routeCmd
							])),
					stMsgs);
		}
	});
var $author$project$App$Data$StatementStatus$StatusProponentContact = 3;
var $author$project$App$Data$StatementStatus$StatusWaitingForRoom = 9;
var $author$project$App$Data$StatementStatus$statusIsBefore = F2(
	function (cmp, ref) {
		return (cmp === 9) ? (_Utils_cmp(
			$author$project$App$Data$StatementStatus$statusToInt(ref),
			$author$project$App$Data$StatementStatus$statusToInt(3)) > 0) : (_Utils_cmp(
			$author$project$App$Data$StatementStatus$statusToInt(cmp),
			$author$project$App$Data$StatementStatus$statusToInt(ref)) < 0);
	});
var $author$project$App$Data$Person$FieldPhoto = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$ResponseMsgs$PropConfirmAdvanced = {$: 1};
var $author$project$App$Page$ResponseMsgs$PropConfirmGotSelfie = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$PropSelfieUploaded = {$: 2};
var $author$project$App$SharedState$StatementProponentRevert = F2(
	function (a, b) {
		return {$: 45, a: a, b: b};
	});
var $author$project$App$SharedState$UpdatePerson = F2(
	function (a, b) {
		return {$: 37, a: a, b: b};
	});
var $author$project$App$Page$Proponent$PageConfirmInterview$Uploading = 1;
var $author$project$App$Page$Proponent$PageConfirmInterview$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dk: true}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dk: false}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 6:
				var available = msg.a;
				var text = available ? 'ok' : 'NoVideo';
				var newFinalResult = (text !== 'ok') ? text : ((!_Utils_eq(
					model.aL,
					$elm$core$Maybe$Just('ok'))) ? A2($elm$core$Maybe$withDefault, 'NotTested', model.aL) : ((!_Utils_eq(
					model.aK,
					$elm$core$Maybe$Just('ok'))) ? A2($elm$core$Maybe$withDefault, 'NotTested', model.aK) : 'ok'));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							bO: $elm$core$Maybe$Just(newFinalResult),
							bT: $elm$core$Maybe$Just(text)
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 7:
				var available = msg.a;
				var text = available ? (_Utils_eq(
					model.aK,
					$elm$core$Maybe$Just('NoAudio')) ? 'ok' : A2($elm$core$Maybe$withDefault, 'ok', model.aK)) : 'NoAudio';
				var newFinalResult = (text !== 'ok') ? text : ((!_Utils_eq(
					model.aL,
					$elm$core$Maybe$Just('ok'))) ? A2($elm$core$Maybe$withDefault, 'NotTested', model.aL) : ((!_Utils_eq(
					model.bT,
					$elm$core$Maybe$Just('ok'))) ? A2($elm$core$Maybe$withDefault, 'NotTested', model.bT) : 'ok'));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aK: $elm$core$Maybe$Just(text),
							bO: $elm$core$Maybe$Just(newFinalResult)
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 8:
				var supported = msg.a;
				var text = supported ? (_Utils_eq(
					model.aL,
					$elm$core$Maybe$Just('NotSupported')) ? 'ok' : A2($elm$core$Maybe$withDefault, 'ok', model.aL)) : 'NotSupported';
				var newFinalResult = (text !== 'ok') ? text : ((!_Utils_eq(
					model.bT,
					$elm$core$Maybe$Just('ok'))) ? A2($elm$core$Maybe$withDefault, 'NotTested', model.bT) : ((!_Utils_eq(
					model.aK,
					$elm$core$Maybe$Just('ok'))) ? A2($elm$core$Maybe$withDefault, 'NotTested', model.aK) : 'ok'));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aL: $elm$core$Maybe$Just(text),
							bO: $elm$core$Maybe$Just(newFinalResult)
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 2:
				var depIdx = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$VideoMsg(
							$author$project$App$SharedState$TakeSelfie(
								A2(
									$elm$core$Basics$composeL,
									A2($elm$core$Basics$composeL, $author$project$App$Page$ResponseMsgs$ProponentMsg, $author$project$App$Page$ResponseMsgs$ProponentConfirmMsg),
									$author$project$App$Page$ResponseMsgs$PropConfirmGotSelfie(depIdx))))
						]));
			case 3:
				var depIdx = msg.a;
				var content = msg.b;
				var person = function () {
					if (!depIdx.$) {
						return A2(
							$author$project$App$Data$Person$updateModel,
							$author$project$App$Data$Person$FieldPhoto(content),
							st.ia.h8.g.dw);
					} else {
						var idx = depIdx.a;
						return A2(
							$author$project$App$Data$Person$updateModel,
							$author$project$App$Data$Person$FieldPhoto(content),
							A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullStatement$emptyEditStatement,
								A2($elm$core$Dict$get, idx, st.ia.h8.fh)).dw);
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bA: 1}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$UpdatePerson,
								person,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ProponentMsg(
										$author$project$App$Page$ResponseMsgs$ProponentConfirmMsg($author$project$App$Page$ResponseMsgs$PropSelfieUploaded))
									])))
						]));
			case 4:
				var _v2 = _Utils_Tuple2(
					model.bO,
					$author$project$App$Page$Proponent$PageConfirmInterview$hasAllSelfies(st.ia.h8));
				if (!_v2.a.$) {
					if ((_v2.a.a === 'ok') && _v2.b) {
						return _Utils_Tuple3(
							model,
							$elm$core$Platform$Cmd$none,
							_List_fromArray(
								[
									$author$project$App$SharedState$APIMsg(
									A2(
										$author$project$App$SharedState$StatementProponentAdvance,
										st.ia.h8,
										_List_fromArray(
											[
												$author$project$App$Page$ResponseMsgs$ProponentMsg(
												$author$project$App$Page$ResponseMsgs$ProponentConfirmMsg($author$project$App$Page$ResponseMsgs$PropConfirmAdvanced))
											])))
								]));
					} else {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
					}
				} else {
					var _v3 = _v2.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 9:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dL: !model.dL}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 5:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2($author$project$App$SharedState$StatementProponentRevert, st.ia.h8, _List_Nil))
						]));
			default:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dj: false}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
		}
	});
var $author$project$App$Page$Proponent$PageDoctorReview$update = F2(
	function (msg, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$Page$Proponent$PageEnteringWaitingLine$update = F2(
	function (msg, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$Page$Proponent$PageError$update = F2(
	function (_v0, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$Page$Proponent$PageFinishedProposition$update = F2(
	function (msg, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$Components$Video$isInitialized = function (model) {
	var _v0 = model.p;
	if (!_v0.$) {
		return false;
	} else {
		return true;
	}
};
var $author$project$App$Components$Video$isStopped = function (model) {
	var _v0 = model.p;
	if (_v0.$ === 6) {
		return true;
	} else {
		return false;
	}
};
var $author$project$App$Page$Proponent$PageInterview$update = F3(
	function (msg, model, st) {
		var conference = msg;
		var sharedMsg = ($author$project$App$Components$Video$isInitialized(st.f4) && (!$author$project$App$Components$Video$isStopped(st.f4))) ? _List_Nil : _List_fromArray(
			[
				$author$project$App$SharedState$VideoMsg(
				A3($author$project$App$SharedState$StartConference, 1, 0, conference))
			]);
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, sharedMsg);
	});
var $author$project$App$Page$Proponent$PageIntro$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 2:
				return (model.cR && ((!model.d0) && (model.cQ || ($elm$core$String$length(st.ia.h8.cJ.iy) <= 0)))) ? _Utils_Tuple3(
					_Utils_update(
						model,
						{d0: true, c8: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$StatementProponentAdvance,
								st.ia.h8,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ProponentMsg($author$project$App$Page$ResponseMsgs$ProponentAdvanced)
									])))
						])) : _Utils_Tuple3(
					_Utils_update(
						model,
						{
							c8: $elm$core$Maybe$Just('Aceite os termos antes de avançar.')
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 0:
				var accepted = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cR: accepted}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			default:
				var accepted = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cQ: accepted}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$PropLoadingStatementLoaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$PropQuestionStatementUpdated = 0;
var $author$project$App$Page$ResponseMsgs$ProponentLoaded = {$: 2};
var $author$project$App$Page$ResponseMsgs$ProponentQuestionsMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Proponent$PageLoading$update = F3(
	function (msg, st, model) {
		var stmtId = msg;
		return _Utils_Tuple3(
			model,
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg(
					A3(
						$author$project$App$SharedState$LoadFullStatementWithMinors,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$SharedState$FullStmtAssetQuestions(2),
								$author$project$App$SharedState$FullStmtAssetAnswers(2)
							]),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$ProponentMsg(
								$author$project$App$Page$ResponseMsgs$ProponentLoadingMsg(
									$author$project$App$Page$ResponseMsgs$PropLoadingStatementLoaded(stmtId))),
								$author$project$App$Page$ResponseMsgs$ProponentMsg($author$project$App$Page$ResponseMsgs$ProponentLoaded),
								$author$project$App$Page$ResponseMsgs$ProponentMsg(
								$author$project$App$Page$ResponseMsgs$ProponentQuestionsMsg(0))
							])))
				]));
	});
var $author$project$App$Page$Proponent$PageNotActivated$update = F2(
	function (msg, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$SharedState$CreateNewPayment = F5(
	function (a, b, c, d, e) {
		return {$: 46, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$App$Page$ResponseMsgs$PropBillingCreated = $elm$core$Basics$identity;
var $author$project$App$Page$ResponseMsgs$ProponentPaymentMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$ResponseMsgs$ProponentReverted = {$: 4};
var $author$project$App$Page$Proponent$PagePayment$ZipLoaded = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Components$Payment$dictToRecord = function (params) {
	return {
		b$: A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2('', 0),
			A2($elm$core$Dict$get, 'cNumber', params)).a,
		cb: A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2('', 0),
			A2($elm$core$Dict$get, 'expMonth', params)).a,
		cc: A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2('', 0),
			A2($elm$core$Dict$get, 'expYear', params)).a,
		cg: A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2('', 0),
			A2($elm$core$Dict$get, 'hName', params)).a,
		cx: A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2('', 0),
			A2($elm$core$Dict$get, 'publicToken', params)).a,
		cz: A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2('', 0),
			A2($elm$core$Dict$get, 'secCode', params)).a
	};
};
var $author$project$App$Service$ViaCep$emptyViacep = {cS: '', bp: '', gy: '', hn: '', p: ''};
var $author$project$App$Components$Payment$generateHash = _Platform_outgoingPort(
	'generateHash',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'cNumber',
					$elm$json$Json$Encode$string($.b$)),
					_Utils_Tuple2(
					'expMonth',
					$elm$json$Json$Encode$string($.cb)),
					_Utils_Tuple2(
					'expYear',
					$elm$json$Json$Encode$string($.cc)),
					_Utils_Tuple2(
					'hName',
					$elm$json$Json$Encode$string($.cg)),
					_Utils_Tuple2(
					'publicToken',
					$elm$json$Json$Encode$string($.cx)),
					_Utils_Tuple2(
					'secCode',
					$elm$json$Json$Encode$string($.cz))
				]));
	});
var $author$project$App$Components$Payment$getCardType = _Platform_outgoingPort(
	'getCardType',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'cNumber',
					$elm$json$Json$Encode$string($.b$)),
					_Utils_Tuple2(
					'expMonth',
					$elm$json$Json$Encode$string($.cb)),
					_Utils_Tuple2(
					'expYear',
					$elm$json$Json$Encode$string($.cc)),
					_Utils_Tuple2(
					'hName',
					$elm$json$Json$Encode$string($.cg)),
					_Utils_Tuple2(
					'publicToken',
					$elm$json$Json$Encode$string($.cx)),
					_Utils_Tuple2(
					'secCode',
					$elm$json$Json$Encode$string($.cz))
				]));
	});
var $author$project$App$Service$ViaCep$ViaCep = F5(
	function (city, complement, neighborhood, state, address) {
		return {cS: address, bp: city, gy: complement, hn: neighborhood, p: state};
	});
var $author$project$App$Service$ViaCep$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'logradouro',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'uf',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'bairro',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'complemento',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'localidade',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$App$Service$ViaCep$ViaCep))))));
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{e5: $elm$http$Http$emptyBody, a1: r.a1, g_: _List_Nil, z: 'GET', im: $elm$core$Maybe$Nothing, ir: $elm$core$Maybe$Nothing, f3: r.f3});
};
var $author$project$App$Service$ViaCep$getCep = function (options) {
	return $elm$http$Http$get(
		{
			a1: A2($elm$http$Http$expectJson, options.jC, $author$project$App$Service$ViaCep$decoder),
			f3: 'https://viacep.com.br/ws/' + (options.ke + '/json/')
		});
};
var $author$project$App$Components$Payment$validateCard = _Platform_outgoingPort(
	'validateCard',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'cNumber',
					$elm$json$Json$Encode$string($.b$)),
					_Utils_Tuple2(
					'expMonth',
					$elm$json$Json$Encode$string($.cb)),
					_Utils_Tuple2(
					'expYear',
					$elm$json$Json$Encode$string($.cc)),
					_Utils_Tuple2(
					'hName',
					$elm$json$Json$Encode$string($.cg)),
					_Utils_Tuple2(
					'publicToken',
					$elm$json$Json$Encode$string($.cx)),
					_Utils_Tuple2(
					'secCode',
					$elm$json$Json$Encode$string($.cz))
				]));
	});
var $author$project$App$Components$Payment$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 3:
				var params = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: params}),
					$author$project$App$Components$Payment$generateHash(
						$author$project$App$Components$Payment$dictToRecord(params)));
			case 4:
				var params = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: params}),
					$author$project$App$Components$Payment$validateCard(
						$author$project$App$Components$Payment$dictToRecord(params)));
			case 5:
				var params = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: params}),
					$author$project$App$Components$Payment$getCardType(
						$author$project$App$Components$Payment$dictToRecord(params)));
			case 0:
				var sHash = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fv: sHash}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fB: bool}),
					$elm$core$Platform$Cmd$none);
			default:
				var sCardType = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{e7: sCardType}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Page$Proponent$PagePayment$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 1:
				return (model.dG && (!model.d0)) ? _Utils_Tuple3(
					_Utils_update(
						model,
						{d0: true}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$StatementProponentAdvance,
								st.ia.h8,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ProponentMsg($author$project$App$Page$ResponseMsgs$ProponentAdvanced)
									])))
						])) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
			case 0:
				var accepted = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dG: accepted}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 6:
				var portParams = $author$project$App$Components$Payment$dictToRecord(model.hv.du);
				var paymentMsg = $author$project$App$Components$Payment$generateHash(
					{b$: portParams.b$, cb: portParams.cb, cc: portParams.cc, cg: portParams.cg, cx: '69E0B2D17055D049BD3A711B6424B0AA067DE7CC4B1F9A5FE92D771940A86E3C', cz: portParams.cz});
				return _Utils_Tuple3(
					model,
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Proponent$PagePayment$PaymentMsg, paymentMsg),
					_List_Nil);
			case 4:
				var portParams = $author$project$App$Components$Payment$dictToRecord(model.hv.du);
				var paymentMsg = $author$project$App$Components$Payment$validateCard(
					{
						b$: A3($elm$core$String$replace, ' ', '', portParams.b$),
						cb: portParams.cb,
						cc: portParams.cc,
						cg: portParams.cg,
						cx: '69E0B2D17055D049BD3A711B6424B0AA067DE7CC4B1F9A5FE92D771940A86E3C',
						cz: portParams.cz
					});
				return _Utils_Tuple3(
					model,
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Proponent$PagePayment$PaymentMsg, paymentMsg),
					_List_Nil);
			case 5:
				var portParams = $author$project$App$Components$Payment$dictToRecord(model.hv.du);
				var paymentMsg = $author$project$App$Components$Payment$getCardType(
					{b$: portParams.b$, cb: portParams.cb, cc: portParams.cc, cg: portParams.cg, cx: '69E0B2D17055D049BD3A711B6424B0AA067DE7CC4B1F9A5FE92D771940A86E3C', cz: portParams.cz});
				return _Utils_Tuple3(
					model,
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Proponent$PagePayment$PaymentMsg, paymentMsg),
					_List_Nil);
			case 7:
				var paymentMsg = msg.a;
				var _v1 = A2($author$project$App$Components$Payment$update, paymentMsg, model.hv);
				var mPayment = _v1.a;
				var paymentCmd = _v1.b;
				var _v2 = function () {
					switch (paymentMsg.$) {
						case 0:
							var s = paymentMsg.a;
							if (s === 'Invalid holder name') {
								return _Utils_Tuple3(
									paymentCmd,
									_List_Nil,
									$elm$core$Maybe$Just('Nome inválido'));
							} else {
								var stmtId = st.ia.h8.g.e.as;
								return ($elm$core$String$length(s) > 0) ? _Utils_Tuple3(
									paymentCmd,
									_List_fromArray(
										[
											$author$project$App$SharedState$APIMsg(
											A5(
												$author$project$App$SharedState$CreateNewPayment,
												stmtId,
												model.c7,
												model.cS,
												s,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$ProponentMsg(
														$author$project$App$Page$ResponseMsgs$ProponentPaymentMsg(stmtId))
													])))
										]),
									$elm$core$Maybe$Nothing) : _Utils_Tuple3(paymentCmd, _List_Nil, $elm$core$Maybe$Nothing);
							}
						case 1:
							if (paymentMsg.a) {
								var portParams = $author$project$App$Components$Payment$dictToRecord(model.hv.du);
								var newCmd = $author$project$App$Components$Payment$generateHash(
									{
										b$: A3($elm$core$String$replace, ' ', '', portParams.b$),
										cb: portParams.cb,
										cc: portParams.cc,
										cg: portParams.cg,
										cx: '69E0B2D17055D049BD3A711B6424B0AA067DE7CC4B1F9A5FE92D771940A86E3C',
										cz: portParams.cz
									});
								return _Utils_Tuple3(newCmd, _List_Nil, $elm$core$Maybe$Nothing);
							} else {
								return _Utils_Tuple3(
									paymentCmd,
									_List_Nil,
									$elm$core$Maybe$Just('Dados de pagamento inválidos'));
							}
						default:
							return _Utils_Tuple3(paymentCmd, _List_Nil, $elm$core$Maybe$Nothing);
					}
				}();
				var updatedPaymentCmd = _v2.a;
				var sharedMsgs = _v2.b;
				var errorMsg = _v2.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{c8: errorMsg, hv: mPayment}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Proponent$PagePayment$PaymentMsg, updatedPaymentCmd),
					sharedMsgs);
			case 2:
				var key = msg.a;
				var fieldValue = msg.b;
				var p = model.hv;
				var fieldPosition = A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2('', 0),
					A2($elm$core$Dict$get, key, p.du)).b;
				var updateValueFunction = $elm$core$Maybe$map(
					function (v) {
						return _Utils_Tuple2(fieldValue, fieldPosition);
					});
				var updatedPayment = _Utils_update(
					p,
					{
						du: A3($elm$core$Dict$update, key, updateValueFunction, model.hv.du)
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{c8: $elm$core$Maybe$Nothing, hv: updatedPayment}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 3:
				var fieldValue = msg.a;
				var yearValue = function () {
					var list = A2($elm$core$String$contains, '/', fieldValue) ? $elm$core$List$reverse(
						A2($elm$core$String$split, '/', fieldValue)) : _List_fromArray(
						[
							A2($elm$core$String$dropLeft, 2, fieldValue)
						]);
					return A2(
						$elm$core$Maybe$withDefault,
						'',
						$elm$core$List$head(list));
				}();
				var p = model.hv;
				var yearPosition = A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2('', 0),
					A2($elm$core$Dict$get, 'expYear', p.du)).b;
				var updateYearFunction = $elm$core$Maybe$map(
					function (v) {
						return _Utils_Tuple2('20' + yearValue, yearPosition);
					});
				var monthValue = function () {
					var list = A2($elm$core$String$contains, '/', fieldValue) ? A2($elm$core$String$split, '/', fieldValue) : _List_fromArray(
						[fieldValue]);
					return A2(
						$elm$core$Maybe$withDefault,
						'',
						$elm$core$List$head(list));
				}();
				var monthPosition = A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2('', 0),
					A2($elm$core$Dict$get, 'expMonth', p.du)).b;
				var updateMonthFunction = $elm$core$Maybe$map(
					function (v) {
						return _Utils_Tuple2(monthValue, monthPosition);
					});
				var updatedMonth = function () {
					var updatedParams = ($elm$core$String$length(monthValue) > 2) ? model.hv.du : A3($elm$core$Dict$update, 'expMonth', updateMonthFunction, model.hv.du);
					return _Utils_update(
						p,
						{du: updatedParams});
				}();
				var updatedYear = function () {
					var updatedParams = ($elm$core$String$length(yearValue) > 2) ? updatedMonth.du : A3($elm$core$Dict$update, 'expYear', updateYearFunction, updatedMonth.du);
					return _Utils_update(
						p,
						{du: updatedParams});
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{c8: $elm$core$Maybe$Nothing, hv: updatedYear}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 8:
				var field = msg.a;
				var tempAddress = model.cS;
				var updatedAddress = function () {
					switch (field.$) {
						case 13:
							var v = field.a;
							return _Utils_update(
								tempAddress,
								{ic: v});
						case 14:
							var v = field.a;
							return _Utils_update(
								tempAddress,
								{bb: v});
						case 15:
							var v = field.a;
							return _Utils_update(
								tempAddress,
								{gy: v});
						case 16:
							var v = field.a;
							return _Utils_update(
								tempAddress,
								{hn: v});
						case 17:
							var v = field.a;
							return _Utils_update(
								tempAddress,
								{bp: v});
						case 18:
							var v = field.a;
							return _Utils_update(
								tempAddress,
								{p: v});
						case 19:
							var v = field.a;
							return ($elm$core$String$length(v) < 10) ? _Utils_update(
								tempAddress,
								{
									hA: A3($elm$core$String$replace, '-', '', v)
								}) : tempAddress;
						default:
							return tempAddress;
					}
				}();
				var cmd = function () {
					if (field.$ === 19) {
						var v = field.a;
						return $author$project$App$Service$ViaCep$getCep(
							{
								jC: $author$project$App$Page$Proponent$PagePayment$ZipLoaded,
								ke: A3($elm$core$String$replace, '-', '', v)
							});
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cS: updatedAddress, c8: $elm$core$Maybe$Nothing}),
					cmd,
					_List_Nil);
			case 9:
				var field = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{c7: field}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 10:
				var res = msg.a;
				var tempAddress = model.cS;
				var emptyViaCep = $author$project$App$Service$ViaCep$emptyViacep;
				var value = function () {
					if (!res.$) {
						var v = res.a;
						return v;
					} else {
						return _Utils_update(
							emptyViaCep,
							{cS: tempAddress.ic, bp: tempAddress.bp, hn: tempAddress.hn, p: tempAddress.p});
					}
				}();
				var updatedAddress = _Utils_update(
					tempAddress,
					{bp: value.bp, hn: value.hn, p: value.p, ic: value.cS});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cS: updatedAddress}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			default:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$StatementProponentRevert,
								st.ia.h8,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ProponentMsg($author$project$App$Page$ResponseMsgs$ProponentReverted)
									])))
						]));
		}
	});
var $author$project$App$Page$Proponent$PageQuestions$ErrorShown = {$: 5};
var $author$project$App$SharedState$SaveStatementById = F2(
	function (a, b) {
		return {$: 64, a: a, b: b};
	});
var $author$project$App$Page$Proponent$PageQuestions$ShowError = function (a) {
	return {$: 4, a: a};
};
var $elm$browser$Browser$Dom$getElement = _Browser_getElement;
var $elm$browser$Browser$Dom$setViewport = _Browser_setViewport;
var $author$project$App$Data$Statement$Dependent = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Data$Statement$depIndexFromInt = function (depIdx) {
	var _v0 = depIdx + 1;
	if (!_v0) {
		return $author$project$App$Data$Statement$Bearer;
	} else {
		var i = _v0;
		return $author$project$App$Data$Statement$Dependent(i - 1);
	}
};
var $author$project$App$Data$Statement$depIndexToString = function (depIdx) {
	if (!depIdx.$) {
		return 'bearer';
	} else {
		var i = depIdx.a;
		return 'dep_' + $elm$core$String$fromInt(i);
	}
};
var $author$project$App$Page$Proponent$PageQuestions$getAnswerForTemplate = F2(
	function (stmt, template) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Answer$emptyModel,
			A2($elm$core$Dict$get, template.as, stmt.aZ));
	});
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Basics$pow = _Basics_pow;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $author$project$App$Page$Proponent$PageQuestions$validateBeneficiaryNotSumToHundred = function (answerList) {
	var integerList = A2(
		$elm$core$List$map,
		function (s) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2(
						$elm$core$Maybe$withDefault,
						'0',
						$elm$core$List$head(
							A2($elm$core$String$split, '.', s)))));
		},
		answerList);
	var decimalTmpList = A2(
		$elm$core$List$map,
		function (s) {
			return A2($elm$core$String$contains, '.', s) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				$elm$core$List$head(
					$elm$core$List$reverse(
						A2($elm$core$String$split, '.', s)))) : '0';
		},
		answerList);
	var decimalMaxLength = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$List$maximum(
			A2(
				$elm$core$List$map,
				function (s) {
					return $elm$core$String$length(s);
				},
				decimalTmpList)));
	var decimalList = A2(
		$elm$core$List$map,
		function (s) {
			return (_Utils_cmp(
				$elm$core$String$length(s),
				decimalMaxLength) < 0) ? A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					_Utils_ap(
						s,
						A2(
							$elm$core$String$repeat,
							decimalMaxLength - $elm$core$String$length(s),
							'0')))) : A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(s));
		},
		decimalTmpList);
	return ((($elm$core$List$sum(integerList) + (($elm$core$List$sum(decimalList) / A2($elm$core$Basics$pow, 10, decimalMaxLength)) | 0)) === 100) && (!($elm$core$List$sum(decimalList) % A2($elm$core$Basics$pow, 10, decimalMaxLength)))) ? false : (((!($elm$core$List$sum(integerList) + (($elm$core$List$sum(decimalList) / A2($elm$core$Basics$pow, 10, decimalMaxLength)) | 0))) && (!($elm$core$List$sum(decimalList) % A2($elm$core$Basics$pow, 10, decimalMaxLength)))) ? false : true);
};
var $author$project$App$Page$Proponent$PageQuestions$validateAnswerTemplate = F5(
	function (depIdx, stmt, question, template, model) {
		var questionDict = A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Dict$empty,
			A2($elm$core$Dict$get, question.as, model.fp));
		var errorIdx = $author$project$App$Data$Statement$depIndexToString(depIdx) + ('_' + question.as);
		var answer = A2($author$project$App$Page$Proponent$PageQuestions$getAnswerForTemplate, stmt, template);
		var _v0 = _Utils_Tuple3(template.j5, answer.T, answer.dF);
		_v0$5:
		while (true) {
			switch (_v0.a) {
				case 2:
					if (_v0.b === '') {
						var _v1 = _v0.a;
						return _Utils_update(
							model,
							{
								fp: A3(
									$elm$core$Dict$insert,
									errorIdx,
									A3($elm$core$Dict$insert, template.as, template.jh + ' deve ser preenchido', questionDict),
									model.fp)
							});
					} else {
						break _v0$5;
					}
				case 5:
					if (_v0.b === '') {
						var _v2 = _v0.a;
						return _Utils_update(
							model,
							{
								fp: A3(
									$elm$core$Dict$insert,
									errorIdx,
									A3($elm$core$Dict$insert, template.as, template.jh + ' deve ser preenchido', questionDict),
									model.fp)
							});
					} else {
						break _v0$5;
					}
				case 3:
					if (_v0.b === '') {
						var _v3 = _v0.a;
						return _Utils_update(
							model,
							{
								fp: A3(
									$elm$core$Dict$insert,
									errorIdx,
									A3($elm$core$Dict$insert, template.as, template.jh + ' deve ser preenchido', questionDict),
									model.fp)
							});
					} else {
						break _v0$5;
					}
				case 7:
					var _v4 = _v0.a;
					var numberList = A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						A2(
							$elm$core$Maybe$map,
							function (t) {
								return A2(
									$elm$core$List$map,
									function (n) {
										return A2(
											$elm$core$Maybe$withDefault,
											'',
											A2(
												$elm$core$Dict$get,
												'% Beneficiário',
												A2(
													$elm$core$Maybe$withDefault,
													$elm$core$Dict$empty,
													A2($elm$core$Dict$get, n - 1, t))));
									},
									A2(
										$elm$core$List$range,
										1,
										$elm$core$Dict$size(t)));
							},
							answer.G));
					var notSumTo100 = ((template.ay === 340) && $author$project$App$Page$Proponent$PageQuestions$validateBeneficiaryNotSumToHundred(numberList)) ? true : false;
					return notSumTo100 ? _Utils_update(
						model,
						{
							fp: A3(
								$elm$core$Dict$insert,
								errorIdx,
								A3($elm$core$Dict$insert, $author$project$App$Data$Id$uuidNil, 'A soma de % Beneficiário deve ser igual a 100', $elm$core$Dict$empty),
								model.fp)
						}) : _Utils_update(
						model,
						{
							fp: A3(
								$elm$core$Dict$insert,
								errorIdx,
								A2($elm$core$Dict$remove, template.as, questionDict),
								model.fp)
						});
				case 8:
					if (_v0.b === '') {
						var _v5 = _v0.a;
						return _Utils_update(
							model,
							{
								fp: A3(
									$elm$core$Dict$insert,
									errorIdx,
									A3($elm$core$Dict$insert, template.as, template.jh + ' deve ser preenchido', questionDict),
									model.fp)
							});
					} else {
						break _v0$5;
					}
				default:
					break _v0$5;
			}
		}
		return _Utils_update(
			model,
			{
				fp: A3(
					$elm$core$Dict$insert,
					errorIdx,
					A2($elm$core$Dict$remove, template.as, questionDict),
					model.fp)
			});
	});
var $author$project$App$Data$AnswerTemplate$TypeOption = 1;
var $author$project$App$Data$AnswerTemplate$TypeOptionCheck = 6;
var $author$project$App$Data$AnswerTemplate$TypeOptionWithComplement = 4;
var $author$project$App$Page$Proponent$PageQuestions$validateAnswerTemplateChoice = F5(
	function (depIdx, stmt, question, templates, model) {
		var errorIdx = $author$project$App$Data$Statement$depIndexToString(depIdx) + ('_' + question.as);
		var answersWithValue = A2(
			$elm$core$List$map,
			function (t) {
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple3('', 0, false),
					A2(
						$elm$core$Maybe$map,
						function (ans) {
							return _Utils_Tuple3(ans.T, t.j5, ans.dF);
						},
						A2($elm$core$Dict$get, t.as, stmt.aZ)));
			},
			templates);
		return A2(
			$elm$core$Maybe$withDefault,
			A2(
				$elm$core$Maybe$withDefault,
				_Utils_update(
					model,
					{
						fp: A3(
							$elm$core$Dict$insert,
							errorIdx,
							A3($elm$core$Dict$insert, $author$project$App$Data$Id$uuidNil, 'Marque uma das opções acima', $elm$core$Dict$empty),
							model.fp)
					}),
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						return _Utils_update(
							model,
							{
								fp: A3(
									$elm$core$Dict$insert,
									errorIdx,
									A3($elm$core$Dict$insert, $author$project$App$Data$Id$uuidNil, 'Especifique sua resposta', $elm$core$Dict$empty),
									model.fp)
							});
					},
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (_v2) {
								var v = _v2.a;
								var t = _v2.b;
								var s = _v2.c;
								return s && ((t === 4) && (!$elm$core$String$length(v)));
							},
							answersWithValue)))),
			A2(
				$elm$core$Maybe$map,
				function (_v1) {
					return _Utils_update(
						model,
						{
							fp: A2($elm$core$Dict$remove, errorIdx, model.fp)
						});
				},
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var value = _v0.a;
							var type_ = _v0.b;
							var selected = _v0.c;
							return (selected && ((type_ === 1) || (type_ === 6))) || (selected && ((type_ === 4) && ($elm$core$String$length(value) > 0)));
						},
						answersWithValue))));
	});
var $author$project$App$Page$Proponent$PageQuestions$validateQuestion = F4(
	function (depIdx, stmt, fullQuestion, model) {
		var _v0 = fullQuestion.hG.j5;
		switch (_v0) {
			case 2:
				return A5($author$project$App$Page$Proponent$PageQuestions$validateAnswerTemplateChoice, depIdx, stmt, fullQuestion.hG, fullQuestion.bY, model);
			case 3:
				return A5($author$project$App$Page$Proponent$PageQuestions$validateAnswerTemplateChoice, depIdx, stmt, fullQuestion.hG, fullQuestion.bY, model);
			default:
				return A3(
					$elm$core$List$foldl,
					A3($author$project$App$Page$Proponent$PageQuestions$validateAnswerTemplate, depIdx, stmt, fullQuestion.hG),
					model,
					fullQuestion.bY);
		}
	});
var $author$project$App$Page$Proponent$PageQuestions$validate = F2(
	function (st, model) {
		var stmts = A2(
			$elm$core$List$any,
			function (_v2) {
				var i = _v2.a;
				var stmt = _v2.b;
				return stmt.e.j_ === 2;
			},
			$elm$core$Dict$toList(model.fX)) ? A2(
			$elm$core$Dict$filter,
			F2(
				function (i, stmt) {
					return i >= 0;
				}),
			model.fX) : model.fX;
		var current = A2($elm$core$Dict$get, st.ia.h8.g.e.bG, model.cA);
		var validatedModel = function () {
			if (!current.$) {
				var fullSection = current.a;
				return A3(
					$elm$core$Dict$foldl,
					F3(
						function (depIdx, stmt, rModel) {
							return A3(
								$elm$core$List$foldr,
								F2(
									function (q, m) {
										return A4(
											$author$project$App$Page$Proponent$PageQuestions$validateQuestion,
											$author$project$App$Data$Statement$depIndexFromInt(depIdx),
											stmt,
											q,
											m);
									}),
								rModel,
								fullSection.eI);
						}),
					model,
					stmts);
			} else {
				return model;
			}
		}();
		return A3(
			$elm$core$Dict$foldr,
			F3(
				function (_v0, d, result) {
					return result && (!$elm$core$Dict$size(d));
				}),
			true,
			validatedModel.fp) ? _Utils_Tuple2(
			validatedModel,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg(
					A2(
						$author$project$App$SharedState$StatementProponentAdvance,
						st.ia.h8,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$ProponentMsg($author$project$App$Page$ResponseMsgs$ProponentAdvanced)
							])))
				])) : _Utils_Tuple2(validatedModel, _List_Nil);
	});
var $author$project$App$Page$Proponent$PageQuestions$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 2:
				var _v1 = A2($author$project$App$Page$Proponent$PageQuestions$validate, st, model);
				var validatedModel = _v1.a;
				var stAdvance = _v1.b;
				var anchor = ($elm$core$Dict$size(validatedModel.fp) > 0) ? A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							$elm$browser$Browser$Dom$getElement,
							$elm$core$Task$attempt($author$project$App$Page$Proponent$PageQuestions$ShowError)),
						A2(
							$elm$core$Maybe$map,
							$elm$core$Tuple$first,
							$elm$core$List$head(
								$elm$core$Dict$toList(validatedModel.fp))))) : $elm$core$Platform$Cmd$none;
				return _Utils_Tuple3(validatedModel, anchor, stAdvance);
			case 3:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$StatementProponentRevert,
								st.ia.h8,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ProponentMsg($author$project$App$Page$ResponseMsgs$ProponentReverted)
									])))
						]));
			case 4:
				var result = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$elm$core$Result$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Result$map,
							function (element) {
								return A2(
									$elm$core$Task$perform,
									function (_v2) {
										return $author$project$App$Page$Proponent$PageQuestions$ErrorShown;
									},
									A2($elm$browser$Browser$Dom$setViewport, element.gN.kc, element.gN.kd));
							},
							result)),
					_List_Nil);
			case 5:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
			case 0:
				var depIdx = msg.a;
				var answer = msg.b;
				var fieldValue = msg.c;
				var uAns = A2($author$project$App$Data$Answer$updateModel, fieldValue, answer);
				var idx = $author$project$App$Data$Statement$depIndexToInt(depIdx);
				var stmt = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, model.fX));
				var stmtModel = stmt.e;
				var currentQuestion = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullQuestion$emptyModel,
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (q) {
								return A2(
									$elm$core$List$any,
									function (t) {
										return t.ay === 10;
									},
									q.bY);
							},
							$elm$core$List$concat(
								A2(
									$elm$core$List$map,
									function (s) {
										return s.eI;
									},
									st.ia.h8.bs)))));
				var _v3 = _Utils_Tuple3(
					(answer.ay === 10) || (answer.ay === 20),
					function () {
						if (answer.ay === 10) {
							return uAns;
						} else {
							var answerTemplate = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$AnswerTemplate$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (t) {
											return t.ay === 10;
										},
										currentQuestion.bY)));
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$Answer$emptyModel,
								A2($elm$core$Dict$get, answerTemplate.as, stmt.gw));
						}
					}(),
					function () {
						if (answer.ay === 20) {
							return uAns;
						} else {
							var answerTemplate = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$AnswerTemplate$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (t) {
											return t.ay === 20;
										},
										currentQuestion.bY)));
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$Answer$emptyModel,
								A2($elm$core$Dict$get, answerTemplate.as, stmt.gw));
						}
					}());
				var updateBMI = _v3.a;
				var weightAnswer = _v3.b;
				var heightAnswer = _v3.c;
				var _v4 = _Utils_Tuple2(
					(answer.ay === 20) ? A2(
						$elm$core$Maybe$withDefault,
						0,
						$elm$core$String$toInt(
							A3($elm$core$String$replace, '.', '', heightAnswer.T))) : stmt.e.jL,
					(answer.ay === 10) ? A2(
						$elm$core$Maybe$withDefault,
						0,
						$elm$core$String$toInt(
							A3($elm$core$String$replace, '.', '', weightAnswer.T))) : stmt.e.jN);
				var personHeight = _v4.a;
				var personWeight = _v4.b;
				var newStmtModel = _Utils_update(
					stmtModel,
					{jL: personHeight, jN: personWeight});
				var sharedMsgs = updateBMI ? _List_fromArray(
					[
						A2($author$project$App$SharedState$UpdateAnswer, uAns, _List_Nil),
						A2($author$project$App$SharedState$SaveStatementById, newStmtModel, _List_Nil)
					]) : _List_fromArray(
					[
						A2($author$project$App$SharedState$UpdateAnswer, uAns, _List_Nil)
					]);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							fp: $elm$core$Dict$empty,
							fX: A3(
								$elm$core$Dict$insert,
								idx,
								_Utils_update(
									stmt,
									{
										aZ: A3($elm$core$Dict$insert, answer.aY, uAns, stmt.aZ),
										e: newStmtModel
									}),
								model.fX)
						}),
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsgs));
			default:
				var depIdx = msg.a;
				var fullQuestion = msg.b;
				var templateId = msg.c;
				var idx = $author$project$App$Data$Statement$depIndexToInt(depIdx);
				var stmt = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, model.fX));
				var _v5 = A3(
					$elm$core$List$foldl,
					F2(
						function (id, _v6) {
							var answers = _v6.a;
							var changed = _v6.b;
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(answers, changed),
								A2(
									$elm$core$Maybe$map,
									function (a) {
										var updatedAnswer = A2(
											$author$project$App$Data$Answer$updateModel,
											$author$project$App$Data$Answer$FieldSelectedBool(false),
											A2(
												$author$project$App$Data$Answer$updateModel,
												$author$project$App$Data$Answer$FieldValue(''),
												a));
										return _Utils_Tuple2(
											A3($elm$core$Dict$insert, a.aY, updatedAnswer, answers),
											A3($elm$core$Dict$insert, a.aY, updatedAnswer, changed));
									},
									A2($elm$core$Dict$get, id, stmt.aZ)));
						}),
					_Utils_Tuple2(stmt.aZ, $elm$core$Dict$empty),
					A2(
						$elm$core$List$map,
						function ($) {
							return $.as;
						},
						fullQuestion.bY));
				var clearedAnswers = _v5.a;
				var saveCleared = _v5.b;
				var answersToUpdate = A3(
					$elm$core$Dict$foldl,
					F3(
						function (id, a, changed) {
							return _Utils_eq(id, templateId) ? A3(
								$elm$core$Dict$insert,
								a.aY,
								A2(
									$author$project$App$Data$Answer$updateModel,
									$author$project$App$Data$Answer$FieldSelectedBool(true),
									a),
								changed) : changed;
						}),
					saveCleared,
					clearedAnswers);
				var answersFinal = A2(
					A3(
						$elm$core$Basics$composeR,
						$elm$core$Dict$fromList,
						A3(
							$elm$core$Dict$merge,
							$elm$core$Dict$insert,
							F4(
								function (_v9, b, _v10, otherDict) {
									return A3($elm$core$Dict$insert, b.aY, b, otherDict);
								}),
							$elm$core$Dict$insert),
						A2(
							$elm$core$List$map,
							function (_v8) {
								var ans = _v8.b;
								return _Utils_Tuple2(ans.aY, ans);
							},
							$elm$core$Dict$toList(answersToUpdate))),
					stmt.aZ,
					$elm$core$Dict$empty);
				var updateAnswersCmd = A2(
					$elm$core$List$map,
					$author$project$App$SharedState$APIMsg,
					A2(
						$elm$core$List$map,
						function (_v7) {
							var ans = _v7.b;
							return A2($author$project$App$SharedState$UpdateAnswer, ans, _List_Nil);
						},
						$elm$core$Dict$toList(answersToUpdate)));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							fX: A3(
								$elm$core$Dict$insert,
								idx,
								_Utils_update(
									stmt,
									{aZ: answersFinal}),
								model.fX)
						}),
					$elm$core$Platform$Cmd$none,
					updateAnswersCmd);
		}
	});
var $author$project$App$Page$Proponent$PageScreening$update = F3(
	function (msg, st, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$Page$Proponent$PageWaitingInLine$update = F2(
	function (msg, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$Page$Proponent$PageWaitingResults$update = F2(
	function (msg, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$Page$PageProponent$update = F3(
	function (msg, st, model) {
		var _v0 = _Utils_Tuple2(msg, model.aQ);
		switch (_v0.a.$) {
			case 16:
				var _v1 = _v0.a;
				return A2($author$project$App$Page$PageProponent$pageFromStatus, st, model);
			case 17:
				var _v2 = _v0.a;
				return A2($author$project$App$Data$StatementStatus$statusIsBefore, st.ia.h8.g.e.aw, 6) ? _Utils_Tuple3(
					model,
					A2($author$project$App$SharedState$asyncDelayedMsg, $author$project$App$Constants$informOnlineInterval, $author$project$App$Page$PageProponent$InformOnline),
					_List_Nil) : _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ProponentInformOnline,
								$elm$core$String$fromInt(
									$elm$time$Time$posixToMillis(model.ey)),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ProponentMsg($author$project$App$Page$ResponseMsgs$ProponentInformOnlineResponse)
									])))
						]));
			case 18:
				var time = _v0.a.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 15:
				var videoMsg = _v0.a.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$videoMsgToSharedMsg(videoMsg)
						]));
			case 14:
				var err = _v0.a.a;
				var _v3 = $author$project$App$Page$Proponent$PageError$init(err);
				var uModel = _v3.a;
				var uCmd = _v3.b;
				var uStCmd = _v3.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aQ: $author$project$App$Page$PageProponent$ErrorLoading(uModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$ErrorLoadingMsg, uCmd),
					uStCmd);
			case 0:
				if (!_v0.b.$) {
					var lMsg = _v0.a.a;
					var lModel = _v0.b.a;
					var _v4 = A3($author$project$App$Page$Proponent$PageLoading$update, lMsg, st, lModel);
					var uModel = _v4.a;
					var uCmd = _v4.b;
					var uStCmd = _v4.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Loading(uModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$LoadingMsg, uCmd),
						uStCmd);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 2:
				if (_v0.b.$ === 3) {
					var iMsg = _v0.a.a;
					var iModel = _v0.b.a;
					var _v6 = A3($author$project$App$Page$Proponent$PageIntro$update, iMsg, st, iModel);
					var uModel = _v6.a;
					var uCmd = _v6.b;
					var uStMsg = _v6.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Intro(uModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$IntroMsg, uCmd),
						uStMsg);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 3:
				if (_v0.b.$ === 4) {
					var iMsg = _v0.a.a;
					var iModel = _v0.b.a;
					var _v7 = A3($author$project$App$Page$Proponent$PagePayment$update, iMsg, st, iModel);
					var uModel = _v7.a;
					var uCmd = _v7.b;
					var uStMsg = _v7.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Payment(uModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$PaymentMsg, uCmd),
						uStMsg);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 4:
				if (_v0.b.$ === 6) {
					var qMsg = _v0.a.a;
					var qModel = _v0.b.a;
					var _v8 = A3($author$project$App$Page$Proponent$PageQuestions$update, qMsg, st, qModel);
					var uModel = _v8.a;
					var uCmd = _v8.b;
					var uStMsg = _v8.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Questions(uModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$QuestionsMsg, uCmd),
						uStMsg);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 5:
				if (_v0.b.$ === 8) {
					var qMsg = _v0.a.a;
					var qModel = _v0.b.a;
					var _v9 = A3($author$project$App$Page$Proponent$PageConfirmInterview$update, qMsg, st, qModel);
					var uModel = _v9.a;
					var uCmd = _v9.b;
					var uStMsg = _v9.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Confirm(uModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$ConfirmMsg, uCmd),
						uStMsg);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 6:
				if (_v0.b.$ === 7) {
					var sMsg = _v0.a.a;
					var sModel = _v0.b.a;
					var _v10 = A3($author$project$App$Page$Proponent$PageScreening$update, sMsg, st, sModel);
					var uModel = _v10.a;
					var uCmd = _v10.b;
					var uStMsg = _v10.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Screening(uModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$ScreeningMsg, uCmd),
						uStMsg);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 11:
				if (_v0.b.$ === 9) {
					var wMsg = _v0.a.a;
					var wModel = _v0.b.a;
					var _v11 = A2($author$project$App$Page$Proponent$PageEnteringWaitingLine$update, wMsg, wModel);
					var uModel = _v11.a;
					var uCmd = _v11.b;
					var uStMsg = _v11.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$EnteringWaitingLine(uModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$EnteringWaitingLineMsg, uCmd),
						uStMsg);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 7:
				if (_v0.b.$ === 10) {
					var wMsg = _v0.a.a;
					var wModel = _v0.b.a;
					var _v12 = A2($author$project$App$Page$Proponent$PageWaitingInLine$update, wMsg, wModel);
					var uModel = _v12.a;
					var uCmd = _v12.b;
					var uStMsg = _v12.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$WaitingInLine(uModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$WaitingInLineMsg, uCmd),
						uStMsg);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 8:
				if (_v0.b.$ === 11) {
					var dMsg = _v0.a.a;
					var dModel = _v0.b.a;
					var _v13 = A2($author$project$App$Page$Proponent$PageDoctorReview$update, dMsg, dModel);
					var uModel = _v13.a;
					var uCmd = _v13.b;
					var uStMsg = _v13.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$DoctorReview(uModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$DoctorReviewMsg, uCmd),
						uStMsg);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 9:
				if (_v0.b.$ === 12) {
					var iMsg = _v0.a.a;
					var iModel = _v0.b.a;
					var _v14 = A3($author$project$App$Page$Proponent$PageInterview$update, iMsg, iModel, st);
					var uModel = _v14.a;
					var uCmd = _v14.b;
					var uStMsg = _v14.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Interview(uModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$InterviewMsg, uCmd),
						uStMsg);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 10:
				if (_v0.b.$ === 13) {
					var iMsg = _v0.a.a;
					var iModel = _v0.b.a;
					var _v15 = A2($author$project$App$Page$Proponent$PageWaitingResults$update, iMsg, iModel);
					var uModel = _v15.a;
					var uCmd = _v15.b;
					var uStMsg = _v15.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$WaitingResults(uModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$WaitingResultsMsg, uCmd),
						uStMsg);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 1:
				if (_v0.b.$ === 2) {
					var eMsg = _v0.a.a;
					var eModel = _v0.b.a;
					var _v5 = A2($author$project$App$Page$Proponent$PageError$update, eMsg, eModel);
					var uModel = _v5.a;
					var uCmd = _v5.b;
					var uStMsg = _v5.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$ErrorLoading(uModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$ErrorLoadingMsg, uCmd),
						uStMsg);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 12:
				if (_v0.b.$ === 14) {
					var iMsg = _v0.a.a;
					var iModel = _v0.b.a;
					var _v16 = A2($author$project$App$Page$Proponent$PageFinishedProposition$update, iMsg, iModel);
					var uModel = _v16.a;
					var uCmd = _v16.b;
					var uStMsg = _v16.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$FinishedProposition(uModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$FinishedPropositionMsg, uCmd),
						uStMsg);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			default:
				if (_v0.b.$ === 15) {
					var iMsg = _v0.a.a;
					var iModel = _v0.b.a;
					var _v17 = A2($author$project$App$Page$Proponent$PageNotActivated$update, iMsg, iModel);
					var uModel = _v17.a;
					var uCmd = _v17.b;
					var uStMsg = _v17.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$NotActivated(uModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$NotActivatedMsg, uCmd),
						uStMsg);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
		}
	});
var $author$project$App$Page$PageReviewer$EditStatement = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$PageReviewer$ListApproved = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$PageReviewer$ListForReview = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$PageReviewer$ListIncomplete = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$PageReviewer$ListReevaluation = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$PageReviewer$Reevaluation = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$PageReviewer$ReevaluationMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$PageReviewer$ReviewInterview = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$PageReviewer$WaitingLine = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$PageReviewer$WaitingProponentDetails = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$SharedState$DeleteDocument = F2(
	function (a, b) {
		return {$: 115, a: a, b: b};
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentLoaded = F2(
	function (a, b) {
		return {$: 27, a: a, b: b};
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentMsg = function (a) {
	return {$: 29, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentSelected = function (a) {
	return {$: 26, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentShowing = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentViewMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$App$SharedState$GetPresignedUrl = F3(
	function (a, b, c) {
		return {$: 15, a: a, b: b, c: c};
	});
var $author$project$App$SharedState$LoadFullStatementForReview = F3(
	function (a, b, c) {
		return {$: 51, a: a, b: b, c: c};
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$No = 0;
var $author$project$App$SharedState$PostPoneStatement = F2(
	function (a, b) {
		return {$: 68, a: a, b: b};
	});
var $author$project$App$SharedState$ReturnToCompany = F2(
	function (a, b) {
		return {$: 63, a: a, b: b};
	});
var $author$project$App$SharedState$ReturnToDoctorEvaluation = F2(
	function (a, b) {
		return {$: 69, a: a, b: b};
	});
var $author$project$App$SharedState$ReturnToInterview = F2(
	function (a, b) {
		return {$: 70, a: a, b: b};
	});
var $author$project$App$SharedState$ReturnToWaitingForRoom = F2(
	function (a, b) {
		return {$: 71, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$ReviewEditStatementDocumentAdded = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewEditStatementDocumentDeleted = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewEditStatementDocumentUpdated = function (a) {
	return {$: 13, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewEditStatementFullStatementEdited = {$: 4};
var $author$project$App$Page$ResponseMsgs$ReviewEditStatementFullStatementLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewEditStatementFullStatementPersisted = {$: 3};
var $author$project$App$Page$ResponseMsgs$ReviewEditStatementMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewEditStatementPostponeStatementLoaded = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewEditStatementPresignedUrlCalled = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$ReviewEditStatementReturnToConfirmInterviewLoaded = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewEditStatementReturnToDoctorEvaluationLoaded = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewEditStatementReturnToWaitingForRoomLoaded = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$SelectDocumentFile = {$: 25};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$Showing = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$SharedState$UpdateDocument = F2(
	function (a, b) {
		return {$: 116, a: a, b: b};
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$Yes = 1;
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$errorsDictIsEmpty = function (errors) {
	return !$elm$core$Dict$size(
		A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, fields) {
					return $elm$core$List$length(fields) > 0;
				}),
			errors));
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$errorsFromEditStatement = F3(
	function (now, editStmt, requiredFields) {
		var invalidWeight = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'weight-height';
				},
				requiredFields))) ? _List_Nil : (((editStmt.e.iT < 100) && (editStmt.e.j_ !== 2)) ? _List_fromArray(
			[8]) : _List_Nil);
		var invalidTemplateId = (!$author$project$App$Data$Id$uuidIsValid(editStmt.e.eY)) ? _List_fromArray(
			[2]) : _List_Nil;
		var invalidSurname = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'name';
				},
				requiredFields))) ? _List_Nil : ((editStmt.dw.ii === '') ? _List_fromArray(
			[5]) : _List_Nil);
		var invalidSex = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'sex';
				},
				requiredFields))) ? _List_Nil : ((!editStmt.dw.dJ) ? _List_fromArray(
			[7]) : _List_Nil);
		var invalidRelation = _Utils_eq(editStmt.e.be, $elm$core$Maybe$Nothing) ? _List_fromArray(
			[12]) : _List_Nil;
		var invalidName = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'name';
				},
				requiredFields))) ? _List_Nil : ((editStmt.dw.hl === '') ? _List_fromArray(
			[4]) : _List_Nil);
		var invalidHeight = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'weight-height';
				},
				requiredFields))) ? _List_Nil : (((editStmt.e.iS < 10) && (editStmt.e.j_ !== 2)) ? _List_fromArray(
			[9]) : _List_Nil);
		var invalidDocument = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'cpf';
				},
				requiredFields))) ? _List_Nil : (($elm$core$String$length(editStmt.dw.i1) !== 11) ? _List_fromArray(
			[3]) : _List_Nil);
		var invalidBirthDate = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'birthdate';
				},
				requiredFields))) ? _List_Nil : (_Utils_eq(
			editStmt.dw.al,
			$elm$time$Time$millisToPosix(0)) ? _List_fromArray(
			[6]) : _List_Nil);
		var getContactValue = function (field) {
			return A2(
				$elm$core$Maybe$withDefault,
				$author$project$App$Data$Contact$emptyModel(
					$author$project$App$Data$Contact$kindFromString(field)),
				A2($elm$core$Dict$get, field, editStmt.ea)).T;
		};
		var invalidEmail = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'email';
				},
				requiredFields))) ? _List_Nil : (((getContactValue('email') === '') && (A2($author$project$App$Data$Person$ageOnDate, now, editStmt.dw) >= 18)) ? _List_fromArray(
			[10]) : _List_Nil);
		var invalidMobile = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'phoneNumber';
				},
				requiredFields))) ? _List_Nil : ((($elm$core$String$length(
			getContactValue('mobile')) < 11) && (A2($author$project$App$Data$Person$ageOnDate, now, editStmt.dw) >= 18)) ? _List_fromArray(
			[11]) : _List_Nil);
		var errors = ($elm$core$List$length(invalidDocument) > 0) ? invalidDocument : _Utils_ap(
			invalidDocument,
			_Utils_ap(
				invalidTemplateId,
				_Utils_ap(
					invalidRelation,
					_Utils_ap(
						invalidName,
						_Utils_ap(
							invalidSurname,
							_Utils_ap(
								invalidBirthDate,
								_Utils_ap(
									invalidSex,
									_Utils_ap(
										invalidWeight,
										_Utils_ap(
											invalidHeight,
											_Utils_ap(invalidEmail, invalidMobile))))))))));
		return errors;
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$errorsFromStatement = F3(
	function (now, fullStmt, modelForm) {
		var stmtTemplate = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$StatementTemplate$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.as, fullStmt.g.e.eY);
					},
					modelForm.eZ)));
		var noDoc = $elm$core$Dict$isEmpty(fullStmt.an);
		var emptyForm = {
			gx: $author$project$App$Data$Id$uuidNil,
			b5: $author$project$App$Components$ViewFullStatement$TabDocument(0),
			fp: $elm$core$Dict$empty,
			a5: $author$project$App$Data$FullStatement$emptyFullStatement,
			ep: false,
			cH: 0,
			eZ: _List_Nil
		};
		var depsErrors = A2(
			$elm$core$Dict$map,
			F2(
				function (_v1, v) {
					return A2(
						$elm$core$List$filter,
						function (field) {
							return field !== 2;
						},
						v);
				}),
			A2(
				$elm$core$Dict$map,
				F2(
					function (_v0, v) {
						return A3($author$project$App$Page$Reviewer$EditStatement$StatementEdit$errorsFromEditStatement, now, v, stmtTemplate.eJ);
					}),
				fullStmt.fh));
		var bearerErrors = A2(
			$elm$core$List$filter,
			function (field) {
				return field !== 12;
			},
			A3($author$project$App$Page$Reviewer$EditStatement$StatementEdit$errorsFromEditStatement, now, fullStmt.g, stmtTemplate.eJ));
		return (noDoc && fullStmt.cJ.hR) ? $elm$core$Dict$fromList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					-1,
					_List_fromArray(
						[0]))
				])) : A3($elm$core$Dict$insert, -1, bearerErrors, depsErrors);
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$formFromModel = function (m) {
	return m.ej;
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$getContactWithKind = F2(
	function (kind, dict) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Contact$emptyModel(kind),
			A2(
				$elm$core$Dict$get,
				$author$project$App$Data$Contact$kindToString(kind),
				dict));
	});
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$update = F4(
	function (msg, fullStmt, sharedState, model) {
		var sections = fullStmt.jM;
		var companySections = fullStmt.bs;
		var _v0 = A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, doc, _v1) {
					var uDocs = _v1.a;
					var cmds = _v1.b;
					var _v2 = $author$project$App$Data$Document$requestUrl(doc);
					var uDoc = _v2.a;
					var cmd = _v2.b;
					return _Utils_Tuple2(
						A3($elm$core$Dict$insert, k, uDoc, uDocs),
						A2($elm$core$List$cons, cmd, cmds));
				}),
			_Utils_Tuple2(fullStmt.an, _List_Nil),
			fullStmt.an);
		var updatedDocs = _v0.a;
		var dCmd = _v0.b;
		var updatedStmt = _Utils_update(
			fullStmt,
			{an: updatedDocs});
		switch (msg.$) {
			case 0:
				var tab = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b5: tab, a5: updatedStmt}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var docMsg = msg.a;
				var docsUpdated = A2(
					$elm$core$Dict$map,
					F2(
						function (_v4, doc) {
							return A2($author$project$App$Data$Document$updateUrl, docMsg, doc);
						}),
					model.a5.an);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a5: _Utils_update(
								updatedStmt,
								{an: docsUpdated})
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var docId = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				var document = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 4:
				var statementId = msg.a;
				var document = msg.b;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				var documentId = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm = F2(
	function (f, m) {
		return _Utils_update(
			m,
			{ej: f});
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$update = F3(
	function (msg, st, model) {
		var stmtId = model.as;
		var modelForm = $author$project$App$Page$Reviewer$EditStatement$StatementEdit$formFromModel(model);
		var fullStmt = model.h8;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementForReview,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1)
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewEditStatementMsg(
											$author$project$App$Page$ResponseMsgs$ReviewEditStatementFullStatementLoaded(stmtId)))
									]))
							])));
			case 1:
				if (!msg.a.$) {
					var list = msg.a.a;
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
							_Utils_update(
								modelForm,
								{eZ: list}),
							model),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 3:
				var newDueDate = msg.a;
				var newDueDatePosix = function () {
					var _v1 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(newDueDate);
					if (!_v1.$) {
						var posix = _v1.a;
						return posix;
					} else {
						return $elm$time$Time$millisToPosix(0);
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cq: newDueDatePosix}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 2:
				return _Utils_Tuple3(
					model,
					A2($author$project$App$Route$pushUrl, st.fC, model.dD),
					_List_Nil);
			case 10:
				var statementId = msg.a;
				var filter = msg.b;
				var field = msg.c;
				var stmt = model.h8;
				var bearer = stmt.g;
				var updatedModel = _Utils_update(
					model,
					{
						h8: _Utils_update(
							stmt,
							{
								g: _Utils_update(
									bearer,
									{
										e: A3($author$project$App$Data$Statement$updateModel, model.ey, field, bearer.e)
									})
							})
					});
				return _Utils_Tuple3(updatedModel, $elm$core$Platform$Cmd$none, _List_Nil);
			case 12:
				var stmtErrors = A3(
					$author$project$App$Page$Reviewer$EditStatement$StatementEdit$errorsFromStatement,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					modelForm.a5,
					modelForm);
				var _v2 = $author$project$App$Page$Reviewer$EditStatement$StatementEdit$errorsDictIsEmpty(stmtErrors) ? _Utils_Tuple2(
					1,
					_List_fromArray(
						[
							A3(
							$author$project$App$SharedState$PersistFullStatement,
							modelForm.gx,
							modelForm.a5,
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$ReviewMsg(
									$author$project$App$Page$ResponseMsgs$ReviewEditStatementMsg($author$project$App$Page$ResponseMsgs$ReviewEditStatementFullStatementEdited))
								]))
						])) : _Utils_Tuple2(0, _List_Nil);
				var submitting = _v2.a;
				var persistMsg = _v2.b;
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{fp: stmtErrors, cH: submitting}),
						_Utils_update(
							model,
							{y: true})),
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, persistMsg));
			case 13:
				var viewMsg = msg.a;
				var _v3 = model.gD;
				if (_v3.$ === 1) {
					var statementId = _v3.a;
					var viewModel = _v3.b;
					var _v4 = A4($author$project$App$Components$ViewFullStatement$update, viewMsg, st.ia.h8, st, viewModel);
					var uViewModel = _v4.a;
					var viewCmd = _v4.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$Showing, statementId, uViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$EditStatement$StatementEdit$ViewFullStmtMsg, viewCmd),
						_List_Nil);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 14:
				var viewMsg = msg.a;
				switch (viewMsg.$) {
					case 2:
						var documentId = viewMsg.a;
						var _v6 = model.ac;
						if (_v6.$ === 1) {
							var statementId = _v6.a;
							var viewModel = _v6.b;
							var _v7 = A4($author$project$App$Page$Reviewer$EditStatement$DocumentView$update, viewMsg, st.ia.h8, st, viewModel);
							var uDocumentViewModel = _v7.a;
							var viewCmd = _v7.b;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										ac: A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentShowing, statementId, uDocumentViewModel),
										y: true
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentViewMsg, viewCmd),
								A2(
									$elm$core$List$map,
									$author$project$App$SharedState$APIMsg,
									_List_fromArray(
										[
											A2(
											$author$project$App$SharedState$DeleteDocument,
											documentId,
											_List_fromArray(
												[
													$author$project$App$Page$ResponseMsgs$ReviewMsg(
													$author$project$App$Page$ResponseMsgs$ReviewEditStatementMsg(
														$author$project$App$Page$ResponseMsgs$ReviewEditStatementDocumentDeleted(documentId)))
												]))
										])));
						} else {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
						}
					case 4:
						var addDocumentStmtId = viewMsg.a;
						var documentModel = viewMsg.b;
						var _v8 = model.ac;
						if (_v8.$ === 1) {
							var statementId = _v8.a;
							var viewModel = _v8.b;
							var _v9 = A4($author$project$App$Page$Reviewer$EditStatement$DocumentView$update, viewMsg, st.ia.h8, st, viewModel);
							var uDocumentViewModel = _v9.a;
							var viewCmd = _v9.b;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										ac: A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentShowing, statementId, uDocumentViewModel)
									}),
								A2(
									$elm$core$Task$perform,
									$elm$core$Basics$always($author$project$App$Page$Reviewer$EditStatement$StatementEdit$SelectDocumentFile),
									$elm$core$Task$succeed(0)),
								_List_Nil);
						} else {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
						}
					case 3:
						var documentModel = viewMsg.a;
						var _v10 = model.ac;
						if (_v10.$ === 1) {
							var statementId = _v10.a;
							var viewModel = _v10.b;
							var _v11 = A4($author$project$App$Page$Reviewer$EditStatement$DocumentView$update, viewMsg, st.ia.h8, st, viewModel);
							var uDocumentViewModel = _v11.a;
							var viewCmd = _v11.b;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										ac: A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentShowing, statementId, uDocumentViewModel),
										y: true
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentViewMsg, viewCmd),
								A2(
									$elm$core$List$map,
									$author$project$App$SharedState$APIMsg,
									_List_fromArray(
										[
											A2(
											$author$project$App$SharedState$UpdateDocument,
											_Utils_update(
												documentModel,
												{f2: model.ey}),
											_List_fromArray(
												[
													$author$project$App$Page$ResponseMsgs$ReviewMsg(
													$author$project$App$Page$ResponseMsgs$ReviewEditStatementMsg(
														$author$project$App$Page$ResponseMsgs$ReviewEditStatementDocumentUpdated(documentModel.as)))
												]))
										])));
						} else {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
						}
					case 5:
						var id = viewMsg.a;
						var _v12 = $author$project$App$Data$Id$uuidIsNotValid(id) ? _Utils_Tuple2(
							$elm$core$Maybe$Just(
								{v: false, jl: 'Atendimento atualizado.'}),
							_List_Nil) : _Utils_Tuple2(
							$elm$core$Maybe$Just(
								{v: true, jl: 'Erro.'}),
							_List_Nil);
						var newNotification = _v12.a;
						var msgs = _v12.b;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{F: newNotification}),
							$elm$core$Platform$Cmd$none,
							msgs);
					case 1:
						var docMsg = viewMsg.a;
						var _v13 = function () {
							if (docMsg.$ === 1) {
								var id = docMsg.a;
								return $author$project$App$Data$Id$uuidIsNotValid(id) ? _Utils_Tuple2(
									$elm$core$Maybe$Just(
										{v: false, jl: 'Atendimento atualizado.'}),
									_List_Nil) : _Utils_Tuple2(
									$elm$core$Maybe$Just(
										{v: true, jl: 'Erro.'}),
									_List_Nil);
							} else {
								return _Utils_Tuple2(model.F, _List_Nil);
							}
						}();
						var newNotification = _v13.a;
						var msgs = _v13.b;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{F: newNotification}),
							$elm$core$Platform$Cmd$none,
							msgs);
					default:
						var _v15 = model.ac;
						if (_v15.$ === 1) {
							var statementId = _v15.a;
							var viewModel = _v15.b;
							var _v16 = A4($author$project$App$Page$Reviewer$EditStatement$DocumentView$update, viewMsg, st.ia.h8, st, viewModel);
							var uDocumentViewModel = _v16.a;
							var viewCmd = _v16.b;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										ac: A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentShowing, statementId, uDocumentViewModel)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentViewMsg, viewCmd),
								_List_Nil);
						} else {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
						}
				}
			case 15:
				var time = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 8:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: true}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 9:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: false}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 11:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ReturnToCompany,
								model.h8.g.e,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewEditStatementMsg($author$project$App$Page$ResponseMsgs$ReviewEditStatementFullStatementPersisted))
									])))
						]));
			case 16:
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{fp: $elm$core$Dict$empty}),
						model),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 17:
				var depIdx = msg.a;
				var fieldValue = msg.b;
				if (!depIdx.$) {
					var now = $author$project$App$Service$ApiWebSocket$getTimestamp(st._);
					var formFullStmt = modelForm.a5;
					var newDeps = A2(
						$elm$core$Dict$map,
						F2(
							function (_v18, v) {
								return _Utils_update(
									v,
									{
										e: A3($author$project$App$Data$Statement$updateModel, now, fieldValue, v.e)
									});
							}),
						formFullStmt.fh);
					var bearer = formFullStmt.g;
					var newBearer = _Utils_update(
						bearer,
						{
							e: A3($author$project$App$Data$Statement$updateModel, now, fieldValue, bearer.e)
						});
					var newFullStmt = _Utils_update(
						formFullStmt,
						{g: newBearer, fh: newDeps});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
							_Utils_update(
								modelForm,
								{a5: newFullStmt}),
							_Utils_update(
								model,
								{h8: newFullStmt})),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				} else {
					var idx = depIdx.a;
					var now = $author$project$App$Service$ApiWebSocket$getTimestamp(st._);
					var formFullStmt = modelForm.a5;
					var dep = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$FullStatement$emptyEditStatement,
						A2($elm$core$Dict$get, idx, modelForm.a5.fh));
					var updatedStmt = A3($author$project$App$Data$Statement$updateModel, now, fieldValue, dep.e);
					var newDep = _Utils_update(
						dep,
						{e: updatedStmt});
					var newFullStmt = _Utils_update(
						formFullStmt,
						{
							fh: A3($elm$core$Dict$insert, idx, newDep, formFullStmt.fh)
						});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
							_Utils_update(
								modelForm,
								{a5: newFullStmt}),
							_Utils_update(
								model,
								{h8: newFullStmt})),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				}
			case 18:
				var depIdx = msg.a;
				var fieldValue = msg.b;
				var sharedMsg = function (person) {
					return $author$project$App$Data$Id$uuidIsValid(person.as) ? _List_Nil : _List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$LoadPersonByDocument,
								person.i1,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$CompanyMsg(
										$author$project$App$Page$ResponseMsgs$CompanyRequestInterviewMsg(
											A2($author$project$App$Page$ResponseMsgs$CompanyRequestInterviewPersonLoadedByDocument, person.i1, depIdx)))
									])))
						]);
				};
				var formFullStmt = modelForm.a5;
				var stmtTemplate = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$StatementTemplate$emptyModel,
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (t) {
								return _Utils_eq(t.as, formFullStmt.g.e.eY);
							},
							modelForm.eZ)));
				var thereIsSocialNameField = A2($elm$core$List$member, 'socialName', stmtTemplate.eJ);
				if (!depIdx.$) {
					var bearer = formFullStmt.g;
					var person = thereIsSocialNameField ? A2($author$project$App$Data$Person$updateModel, fieldValue, bearer.dw) : A2(
						$author$project$App$Data$Person$updateModel,
						$author$project$App$Data$Person$FieldSocialName(''),
						A2($author$project$App$Data$Person$updateModel, fieldValue, bearer.dw));
					var newFullStmt = _Utils_update(
						formFullStmt,
						{
							g: _Utils_update(
								bearer,
								{dw: person})
						});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
							_Utils_update(
								modelForm,
								{a5: newFullStmt}),
							_Utils_update(
								model,
								{h8: newFullStmt})),
						$elm$core$Platform$Cmd$none,
						sharedMsg(person));
				} else {
					var idx = depIdx.a;
					var dep = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$FullStatement$emptyEditStatement,
						A2($elm$core$Dict$get, idx, formFullStmt.fh));
					var person = thereIsSocialNameField ? A2($author$project$App$Data$Person$updateModel, fieldValue, dep.dw) : A2(
						$author$project$App$Data$Person$updateModel,
						$author$project$App$Data$Person$FieldSocialName(''),
						A2($author$project$App$Data$Person$updateModel, fieldValue, dep.dw));
					var nDep = _Utils_update(
						dep,
						{dw: person});
					var newFullStmt = _Utils_update(
						formFullStmt,
						{
							fh: A3($elm$core$Dict$insert, idx, nDep, formFullStmt.fh)
						});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
							_Utils_update(
								modelForm,
								{a5: newFullStmt}),
							_Utils_update(
								model,
								{h8: newFullStmt})),
						$elm$core$Platform$Cmd$none,
						sharedMsg(person));
				}
			case 19:
				var mDepIdx = msg.a;
				var kind = msg.b;
				var fieldValue = msg.c;
				if (!mDepIdx.$) {
					var formFullStmt = modelForm.a5;
					var bearer = formFullStmt.g;
					var contact = A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$getContactWithKind, kind, bearer.ea);
					var updatedContact = A2($author$project$App$Data$Contact$updateModel, fieldValue, contact);
					var newBearer = _Utils_update(
						bearer,
						{
							ea: A3(
								$elm$core$Dict$insert,
								$author$project$App$Data$Contact$kindToString(kind),
								updatedContact,
								bearer.ea)
						});
					var newFullStmt = _Utils_update(
						formFullStmt,
						{g: newBearer});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
							_Utils_update(
								modelForm,
								{a5: newFullStmt}),
							_Utils_update(
								model,
								{h8: newFullStmt})),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				} else {
					var idx = mDepIdx.a;
					var formFullStmt = modelForm.a5;
					var dep = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$FullStatement$emptyEditStatement,
						A2($elm$core$Dict$get, idx, formFullStmt.fh));
					var contact = A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$getContactWithKind, kind, dep.ea);
					var updatedContact = A2($author$project$App$Data$Contact$updateModel, fieldValue, contact);
					var newDep = _Utils_update(
						dep,
						{
							ea: A3(
								$elm$core$Dict$insert,
								$author$project$App$Data$Contact$kindToString(kind),
								updatedContact,
								dep.ea)
						});
					var newFullStmt = _Utils_update(
						formFullStmt,
						{
							fh: A3($elm$core$Dict$insert, idx, newDep, formFullStmt.fh)
						});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
							_Utils_update(
								modelForm,
								{a5: newFullStmt}),
							_Utils_update(
								model,
								{h8: newFullStmt})),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				}
			case 20:
				var formFullStmt = modelForm.a5;
				var idx = $elm$core$Dict$size(formFullStmt.fh);
				var templateId = formFullStmt.g.e.eY;
				var emptyStmt = $author$project$App$Data$FullStatement$emptyEditStatement;
				var stmtModel = emptyStmt.e;
				var bearerStmtId = formFullStmt.g.e.as;
				var stmtDep = _Utils_update(
					emptyStmt,
					{
						e: _Utils_update(
							stmtModel,
							{
								gL: formFullStmt.g.e.gL,
								jK: $elm$core$Maybe$Just(bearerStmtId),
								eY: templateId
							})
					});
				var newFullStmt = _Utils_update(
					formFullStmt,
					{
						fh: A3($elm$core$Dict$insert, idx, stmtDep, formFullStmt.fh)
					});
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{a5: newFullStmt}),
						_Utils_update(
							model,
							{h8: newFullStmt})),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 21:
				var idx = msg.a;
				var formFullStmt = modelForm.a5;
				var updatedDeps = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, _v21) {
								var dep = _v21.b;
								return _Utils_Tuple2(i, dep);
							}),
						$elm$core$Dict$toList(
							A2($elm$core$Dict$remove, idx, formFullStmt.fh))));
				var newFullStmt = _Utils_update(
					formFullStmt,
					{fh: updatedDeps});
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{a5: newFullStmt}),
						_Utils_update(
							model,
							{h8: newFullStmt})),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 23:
				var idx = $elm$core$Dict$size(fullStmt.an);
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{
								b5: $author$project$App$Components$ViewFullStatement$TabDocument(idx),
								a5: _Utils_update(
									fullStmt,
									{
										an: A3($elm$core$Dict$insert, idx, $author$project$App$Data$Document$emptyModel, fullStmt.an)
									})
							}),
						model),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 22:
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{d9: true}),
						model),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 24:
				var tab = function () {
					var _v24 = modelForm.b5;
					if (_v24.$ === 1) {
						if (!_v24.a) {
							return modelForm.b5;
						} else {
							var i = _v24.a;
							return $author$project$App$Components$ViewFullStatement$TabDocument(i - 1);
						}
					} else {
						return $author$project$App$Components$ViewFullStatement$TabDocument(0);
					}
				}();
				var idx = function () {
					var _v23 = modelForm.b5;
					if (_v23.$ === 1) {
						var i = _v23.a;
						return i;
					} else {
						return 0;
					}
				}();
				var updatedDocs = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, _v22) {
								var d = _v22.b;
								return _Utils_Tuple2(i, d);
							}),
						$elm$core$Dict$toList(
							A2($elm$core$Dict$remove, idx, fullStmt.an))));
				var doc = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$Document$emptyModel,
					A2($elm$core$Dict$get, idx, fullStmt.an));
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{
								b5: tab,
								a5: _Utils_update(
									fullStmt,
									{
										an: updatedDocs,
										fO: A2(
											$elm$core$List$cons,
											_Utils_update(
												doc,
												{iU: ''}),
											fullStmt.fO)
									})
							}),
						model),
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentMsg,
						$author$project$App$Data$Document$clearUrl(doc)),
					_List_Nil);
			case 25:
				return _Utils_Tuple3(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['application/pdf', 'image/png', 'image/jpeg', 'video/mp4']),
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentSelected),
					_List_Nil);
			case 26:
				var file = msg.a;
				var now = $elm$time$Time$posixToMillis(
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
				var mime = $elm$file$File$mime(file);
				var idx = function () {
					var _v27 = modelForm.b5;
					if (_v27.$ === 1) {
						var i = _v27.a;
						return i;
					} else {
						return 0;
					}
				}();
				var doc = function () {
					var _v26 = A2($elm$core$Dict$get, idx, fullStmt.an);
					if (!_v26.$) {
						var d = _v26.a;
						return d;
					} else {
						return $author$project$App$Data$Document$emptyModel;
					}
				}();
				var updatedDoc = _Utils_update(
					doc,
					{
						iU: '',
						dr: mime,
						hl: 'Anexado em ' + $elm$core$String$fromInt(now),
						af: '',
						Q: false
					});
				var updatedStmt = _Utils_update(
					fullStmt,
					{
						an: A3($elm$core$Dict$insert, idx, updatedDoc, fullStmt.an)
					});
				var _v25 = _Utils_Tuple2(
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$perform,
								$author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentLoaded(updatedDoc),
								$elm$file$File$toUrl(file)),
								$author$project$App$Data$Document$clearUrl(doc)
							])),
					_List_Nil);
				var cmds = _v25.a;
				var msgs = _v25.b;
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{a5: updatedStmt}),
						model),
					cmds,
					msgs);
			case 27:
				var doc = msg.a;
				var content = msg.b;
				var now = $elm$time$Time$posixToMillis(
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
				var mime = doc.dr;
				var docWithName = _Utils_update(
					doc,
					{
						iU: '',
						dr: mime,
						hl: 'AnexadoEm' + $elm$core$String$fromInt(now),
						af: '',
						Q: false
					});
				var _v28 = $author$project$App$Data$Document$requestUrl(
					_Utils_update(
						docWithName,
						{iU: content}));
				var updatedDoc = _v28.a;
				var dCmd = _v28.b;
				var updatedStmt = _Utils_update(
					fullStmt,
					{
						an: A3($elm$core$Dict$insert, 0, updatedDoc, fullStmt.an)
					});
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{a5: updatedStmt}),
						model),
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$CreateNewDocumentFromStatement,
								updatedDoc.jJ,
								_Utils_update(
									updatedDoc,
									{f2: model.ey}),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewEditStatementMsg(
											$author$project$App$Page$ResponseMsgs$ReviewEditStatementDocumentAdded(updatedDoc.jJ)))
									]))
							])));
			case 28:
				var doc = msg.a;
				var content = msg.b;
				var now = $elm$time$Time$posixToMillis(
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
				var docWithName = _Utils_update(
					doc,
					{
						iU: content,
						hl: 'Anexado em ' + $elm$core$String$fromInt(now),
						af: '',
						Q: false
					});
				var _v29 = $author$project$App$Data$Document$requestUrl(
					_Utils_update(
						docWithName,
						{iU: content}));
				var updatedDoc = _v29.a;
				var dCmd = _v29.b;
				var updatedStmt = _Utils_update(
					fullStmt,
					{
						an: A3($elm$core$Dict$insert, 0, updatedDoc, fullStmt.an)
					});
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{a5: updatedStmt}),
						model),
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$GetPresignedUrl,
								updatedDoc.jJ,
								_Utils_update(
									updatedDoc,
									{f2: model.ey}),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewEditStatementMsg(
											A2(
												$author$project$App$Page$ResponseMsgs$ReviewEditStatementPresignedUrlCalled,
												updatedDoc.jJ,
												_Utils_update(
													updatedDoc,
													{f2: model.ey}))))
									]))
							])));
			case 29:
				var docMsg = msg.a;
				var docsUpdated = A2(
					$elm$core$Dict$map,
					F2(
						function (_v30, doc) {
							return A2($author$project$App$Data$Document$updateUrl, docMsg, doc);
						}),
					fullStmt.an);
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{
								a5: _Utils_update(
									fullStmt,
									{an: docsUpdated})
							}),
						model),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 30:
				var tab = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{b5: tab}),
						model),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 7:
				var stmt = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{y: true}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$PostPoneStatement,
								stmt,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewEditStatementMsg(
											$author$project$App$Page$ResponseMsgs$ReviewEditStatementPostponeStatementLoaded(stmt.as)))
									])))
						]));
			case 4:
				var stmt = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{y: true}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ReturnToDoctorEvaluation,
								stmt,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewEditStatementMsg(
											$author$project$App$Page$ResponseMsgs$ReviewEditStatementReturnToDoctorEvaluationLoaded(stmt.as)))
									])))
						]));
			case 5:
				var stmt = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{y: true}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ReturnToInterview,
								stmt,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewEditStatementMsg(
											$author$project$App$Page$ResponseMsgs$ReviewEditStatementReturnToConfirmInterviewLoaded(stmt.as)))
									])))
						]));
			case 6:
				var stmt = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{y: true}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ReturnToWaitingForRoom,
								stmt,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewEditStatementMsg(
											$author$project$App$Page$ResponseMsgs$ReviewEditStatementReturnToWaitingForRoomLoaded(stmt.as)))
									])))
						]));
			case 32:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{F: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			default:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{a6: !model.a6}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
		}
	});
var $author$project$App$SharedState$LoadApprovedStatementsForReview = F3(
	function (a, b, c) {
		return {$: 82, a: a, b: b, c: c};
	});
var $author$project$App$SharedState$LoadDoctorsOnline = function (a) {
	return {$: 19, a: a};
};
var $author$project$App$Page$Reviewer$PageListApproved$NoDetails = {$: 0};
var $author$project$App$Page$ResponseMsgs$ReviewListApprovedDoctorsLoaded = {$: 4};
var $author$project$App$Page$ResponseMsgs$ReviewListApprovedFullStatementLoaded = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewListApprovedMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewListApprovedPersonLoaded = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewListApprovedStatementsLoaded = {$: 2};
var $author$project$App$Page$Reviewer$PageListApproved$Showing = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$Reviewer$PageListApproved$defaultFilter = function (filter) {
	return A2($author$project$App$Components$Filter$setOrder, 'updatedAt,desc', filter);
};
var $author$project$App$Page$Reviewer$PageListApproved$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A3(
						$author$project$App$SharedState$LoadApprovedStatementsForReview,
						model.gx,
						$author$project$App$Page$Reviewer$PageListApproved$defaultFilter(uModel.b),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$ReviewMsg(
								$author$project$App$Page$ResponseMsgs$ReviewListApprovedMsg($author$project$App$Page$ResponseMsgs$ReviewListApprovedStatementsLoaded))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var viewMsg = msg.a;
				var _v2 = model.gD;
				if (_v2.$ === 1) {
					var stmtId = _v2.a;
					var viewModel = _v2.b;
					var _v3 = A4($author$project$App$Components$ViewFullStatement$update, viewMsg, st.ia.h8, st, viewModel);
					var uViewModel = _v3.a;
					var viewCmd = _v3.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Reviewer$PageListApproved$Showing, stmtId, uViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageListApproved$ViewFullStmtMsg, viewCmd),
						_List_Nil);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 2:
				var filter = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadApprovedStatementsForReview,
								model.gx,
								$author$project$App$Page$Reviewer$PageListApproved$defaultFilter(filter),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewListApprovedMsg($author$project$App$Page$ResponseMsgs$ReviewListApprovedStatementsLoaded))
									]))
							])));
			case 4:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								$author$project$App$SharedState$LoadDoctorsOnline(
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewListApprovedMsg($author$project$App$Page$ResponseMsgs$ReviewListApprovedDoctorsLoaded))
									]))
							])));
			case 3:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$ReviewMsg(
											$author$project$App$Page$ResponseMsgs$ReviewListApprovedMsg(
												$author$project$App$Page$ResponseMsgs$ReviewListApprovedPersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			case 5:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementWithMinors,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1),
										$author$project$App$SharedState$FullStmtAssetAnswers(2),
										$author$project$App$SharedState$FullStmtAssetQuestions(2),
										$author$project$App$SharedState$FullStmtAssetAnswers(3),
										$author$project$App$SharedState$FullStmtAssetQuestions(3),
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetICD
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewListApprovedMsg(
											$author$project$App$Page$ResponseMsgs$ReviewListApprovedFullStatementLoaded(stmtId)))
									]))
							])));
			case 6:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{gD: $author$project$App$Page$Reviewer$PageListApproved$NoDetails}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			default:
				var stmtId = msg.a;
				return $author$project$App$Data$Id$uuidIsValid(stmtId) ? _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Review(
								$author$project$App$Route$ReviewEditStatementRoute(
									A2($author$project$App$Route$ReviewEditApprovedStatement, stmtId, model.b))))),
					_List_Nil) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$SharedState$LoadStatementsForReview = F3(
	function (a, b, c) {
		return {$: 81, a: a, b: b, c: c};
	});
var $author$project$App$Page$ResponseMsgs$ReviewListForReviewDoctorsLoaded = {$: 4};
var $author$project$App$Page$ResponseMsgs$ReviewListForReviewMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewListForReviewPersonLoaded = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewListForReviewStatementsLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Reviewer$PageListForReview$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A3(
						$author$project$App$SharedState$LoadStatementsForReview,
						model.gx,
						uModel.b,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$ReviewMsg(
								$author$project$App$Page$ResponseMsgs$ReviewListForReviewMsg(
									$author$project$App$Page$ResponseMsgs$ReviewListForReviewStatementsLoaded(uModel.b)))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Review(
								$author$project$App$Route$ReviewListForReviewRoute(
									$author$project$App$Route$ReviewListForReview(uModel.b))))),
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var filter = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadStatementsForReview,
								model.gx,
								filter,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewListForReviewMsg(
											$author$project$App$Page$ResponseMsgs$ReviewListForReviewStatementsLoaded(filter)))
									]))
							])));
			case 2:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$ReviewMsg(
											$author$project$App$Page$ResponseMsgs$ReviewListForReviewMsg(
												$author$project$App$Page$ResponseMsgs$ReviewListForReviewPersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			case 3:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								$author$project$App$SharedState$LoadDoctorsOnline(
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewListForReviewMsg($author$project$App$Page$ResponseMsgs$ReviewListForReviewDoctorsLoaded))
									]))
							])));
			case 4:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Review(
								$author$project$App$Route$ReviewInterviewRoute(stmtId)))),
					_List_Nil);
			case 5:
				var stmtId = msg.a;
				return $author$project$App$Data$Id$uuidIsValid(stmtId) ? _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Review(
								$author$project$App$Route$ReviewInterviewRoute(stmtId)))),
					_List_Nil) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
			default:
				var viewMsg = msg.a;
				var _v2 = function () {
					var _v3 = model.av;
					if (!_v3.$) {
						var viewFullStmt = _v3.a;
						return function (_v4) {
							var a = _v4.a;
							var b = _v4.b;
							return _Utils_Tuple3(
								$elm$core$Maybe$Just(viewFullStmt.a5.g.e.as),
								$elm$core$Maybe$Just(a),
								b);
						}(
							A4($author$project$App$Components$ViewFullStatement$update, viewMsg, st.ia.h8, st, viewFullStmt));
					} else {
						return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
					}
				}();
				var showStmt = _v2.a;
				var uViewFullStmt = _v2.b;
				var uViewFullStmtMsg = _v2.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: showStmt, av: uViewFullStmt}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageListForReview$ViewFullStmtMsg, uViewFullStmtMsg),
					_List_Nil);
		}
	});
var $author$project$App$SharedState$LoadIncompleteStatementsForReview = F3(
	function (a, b, c) {
		return {$: 83, a: a, b: b, c: c};
	});
var $author$project$App$Page$Reviewer$PageListIncomplete$NoDetails = {$: 0};
var $author$project$App$Page$ResponseMsgs$ReviewListIncompleteDoctorsLoaded = {$: 3};
var $author$project$App$Page$ResponseMsgs$ReviewListIncompleteFullStatementLoaded = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewListIncompleteFullStatementPersisted = {$: 6};
var $author$project$App$Page$ResponseMsgs$ReviewListIncompleteMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewListIncompletePersonLoaded = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewListIncompleteStatementsLoaded = {$: 2};
var $author$project$App$Page$Reviewer$PageListIncomplete$Showing = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$Reviewer$PageListIncomplete$defaultFilter = function (filter) {
	return A2($author$project$App$Components$Filter$setOrder, 'created_at,desc', filter);
};
var $author$project$App$Page$Reviewer$PageListIncomplete$routeFromDetails = function (model) {
	var _v0 = model.gD;
	if (!_v0.$) {
		return $author$project$App$Route$ReviewListIncomplete(model.b);
	} else {
		var stmtId = _v0.a;
		return A2($author$project$App$Route$ReviewListIncompleteViewDetails, stmtId, model.b);
	}
};
var $author$project$App$Page$Reviewer$PageListIncomplete$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A3(
						$author$project$App$SharedState$LoadIncompleteStatementsForReview,
						model.gx,
						$author$project$App$Page$Reviewer$PageListIncomplete$defaultFilter(uModel.b),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$ReviewMsg(
								$author$project$App$Page$ResponseMsgs$ReviewListIncompleteMsg($author$project$App$Page$ResponseMsgs$ReviewListIncompleteStatementsLoaded))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Review(
								$author$project$App$Route$ReviewListIncompleteRoute(
									$author$project$App$Page$Reviewer$PageListIncomplete$routeFromDetails(uModel))))),
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var viewMsg = msg.a;
				var _v2 = model.gD;
				if (_v2.$ === 1) {
					var stmtId = _v2.a;
					var viewModel = _v2.b;
					var _v3 = A4($author$project$App$Components$ViewFullStatement$update, viewMsg, st.ia.h8, st, viewModel);
					var uViewModel = _v3.a;
					var viewCmd = _v3.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Reviewer$PageListIncomplete$Showing, stmtId, uViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageListIncomplete$ViewFullStmtMsg, viewCmd),
						_List_Nil);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 4:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								$author$project$App$SharedState$LoadDoctorsOnline(
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewListIncompleteMsg($author$project$App$Page$ResponseMsgs$ReviewListIncompleteDoctorsLoaded))
									]))
							])));
			case 2:
				var filter = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadIncompleteStatementsForReview,
								model.gx,
								$author$project$App$Page$Reviewer$PageListIncomplete$defaultFilter(filter),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewListIncompleteMsg($author$project$App$Page$ResponseMsgs$ReviewListIncompleteStatementsLoaded))
									]))
							])));
			case 3:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$ReviewMsg(
											$author$project$App$Page$ResponseMsgs$ReviewListIncompleteMsg(
												$author$project$App$Page$ResponseMsgs$ReviewListIncompletePersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			case 5:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Review(
								$author$project$App$Route$ReviewListIncompleteRoute(
									A2(
										$author$project$App$Route$ReviewListIncompleteViewDetails,
										stmtId,
										$author$project$App$Page$Reviewer$PageListIncomplete$defaultFilter(model.b)))))),
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementWithMinors,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1),
										$author$project$App$SharedState$FullStmtAssetAnswers(2),
										$author$project$App$SharedState$FullStmtAssetQuestions(2),
										$author$project$App$SharedState$FullStmtAssetAnswers(3),
										$author$project$App$SharedState$FullStmtAssetQuestions(3),
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetICD
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewListIncompleteMsg(
											$author$project$App$Page$ResponseMsgs$ReviewListIncompleteFullStatementLoaded(stmtId)))
									]))
							])));
			case 6:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{gD: $author$project$App$Page$Reviewer$PageListIncomplete$NoDetails}),
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Review(
								$author$project$App$Route$ReviewListIncompleteRoute(
									$author$project$App$Route$ReviewListIncomplete(model.b))))),
					_List_Nil);
			case 7:
				var stmt = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: true, h8: stmt}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 8:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: false}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 10:
				var stmt = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ReturnToCompany,
								stmt,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewListIncompleteMsg($author$project$App$Page$ResponseMsgs$ReviewListIncompleteFullStatementPersisted))
									])))
						]));
			case 11:
				var stmt = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2($author$project$App$SharedState$PostPoneStatement, stmt, _List_Nil))
						]));
			case 9:
				var statementId = msg.a;
				var filter = msg.b;
				var field = msg.c;
				var stmt = model.h8;
				var updatedModel = _Utils_update(
					model,
					{
						h8: A3($author$project$App$Data$Statement$updateModel, model.ey, field, stmt)
					});
				return _Utils_Tuple3(updatedModel, $elm$core$Platform$Cmd$none, _List_Nil);
			case 12:
				var time = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			default:
				var stmtId = msg.a;
				return $author$project$App$Data$Id$uuidIsValid(stmtId) ? _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Review(
								$author$project$App$Route$ReviewEditStatementRoute(
									A2($author$project$App$Route$ReviewEditStatement, stmtId, model.b))))),
					_List_Nil) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$ReviewListReevaluationDoctorsLoaded = {$: 2};
var $author$project$App$Page$ResponseMsgs$ReviewListReevaluationLoaded = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$ReviewListReevaluationMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewListReevaluationPersonLoaded = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Reviewer$PageListReevaluation$update = F3(
	function (msg, sharedState, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A3(
						$author$project$App$SharedState$LoadReevalStatementsFromCompany,
						model.gx,
						uModel.b,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$ReviewMsg(
								$author$project$App$Page$ResponseMsgs$ReviewListReevaluationMsg(
									A2($author$project$App$Page$ResponseMsgs$ReviewListReevaluationLoaded, uModel.gx, uModel.b)))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Review($author$project$App$Route$ReviewListReevaluation))),
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var filter = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadReevalStatementsFromCompany,
								model.gx,
								filter,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewListReevaluationMsg(
											A2($author$project$App$Page$ResponseMsgs$ReviewListReevaluationLoaded, model.gx, filter)))
									]))
							])));
			case 2:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$ReviewMsg(
											$author$project$App$Page$ResponseMsgs$ReviewListReevaluationMsg(
												$author$project$App$Page$ResponseMsgs$ReviewListReevaluationPersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			case 3:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								$author$project$App$SharedState$LoadDoctorsOnline(
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewListReevaluationMsg($author$project$App$Page$ResponseMsgs$ReviewListReevaluationDoctorsLoaded))
									]))
							])));
			case 4:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Review(
								$author$project$App$Route$ReviewReevaluationDetails(stmtId)))),
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementWithMinors,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1),
										$author$project$App$SharedState$FullStmtAssetAnswers(2),
										$author$project$App$SharedState$FullStmtAssetQuestions(2),
										$author$project$App$SharedState$FullStmtAssetAnswers(3),
										$author$project$App$SharedState$FullStmtAssetQuestions(3),
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetICD
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewListReevaluationMsg(
											A2($author$project$App$Page$ResponseMsgs$ReviewListReevaluationLoaded, model.gx, $author$project$App$Components$Filter$empty)))
									]))
							])));
			case 5:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: $elm$core$Maybe$Nothing, av: $elm$core$Maybe$Nothing}),
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Review($author$project$App$Route$ReviewListReevaluation))),
					_List_Nil);
			case 6:
				var viewMsg = msg.a;
				var _v2 = function () {
					var _v3 = model.av;
					if (!_v3.$) {
						var viewFullStmt = _v3.a;
						return function (_v4) {
							var a = _v4.a;
							var b = _v4.b;
							return _Utils_Tuple3(
								$elm$core$Maybe$Just(viewFullStmt.a5.g.e.as),
								$elm$core$Maybe$Just(a),
								b);
						}(
							A4($author$project$App$Components$ViewFullStatement$update, viewMsg, sharedState.ia.h8, sharedState, viewFullStmt));
					} else {
						return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
					}
				}();
				var showStmt = _v2.a;
				var uViewFullStmt = _v2.b;
				var uViewFullStmtMsg = _v2.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: showStmt, av: uViewFullStmt}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageListReevaluation$ViewFullStmtMsg, uViewFullStmtMsg),
					_List_Nil);
			default:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Review(
								$author$project$App$Route$ReviewReevaluationDetails(stmtId)))),
					_List_Nil);
		}
	});
var $author$project$App$Page$Reviewer$PageReevaluation$ChatBoxMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewReevaluationMessageSent = 1;
var $author$project$App$Page$ResponseMsgs$ReviewReevaluationMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Reviewer$PageReevaluation$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 1:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin($author$project$App$Route$AdminListReevaluation))),
					_List_Nil);
			case 3:
				var emptyMessage = $author$project$App$Data$StatementMessage$emptyModel;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$StatementSendToDoctorReview,
								_Utils_update(
									emptyMessage,
									{jl: '', aV: model.P.g.e.as}),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewReevaluationMsg(1))
									])))
						]));
			case 4:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$StatementFinishReevaluation,
								model.P.g.e,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewReevaluationMsg(1))
									])))
						]));
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: !model.dC}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 5:
				var str = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{eN: str}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 6:
				var idx = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{b5: idx}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 7:
				var time = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 8:
				var docMsg = msg.a;
				var docsUpdated = A2(
					$elm$core$Dict$map,
					F2(
						function (_v1, doc) {
							return A2($author$project$App$Data$Document$updateUrl, docMsg, doc);
						}),
					model.an);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{an: docsUpdated}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 9:
				if (!msg.a.$) {
					var messageText = msg.a.a;
					var emptyMessage = $author$project$App$Data$StatementMessage$emptyModel;
					var newMessage = _Utils_update(
						emptyMessage,
						{jl: messageText, aV: model.eV});
					var _v2 = A2(
						$author$project$App$Components$Chatbox$update,
						$author$project$App$Components$Chatbox$SendMessage(messageText),
						model.aM);
					var chatBoxUpdated = _v2.a;
					var chatCmd = _v2.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aM: chatBoxUpdated}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageReevaluation$ChatBoxMsg, chatCmd),
						_List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$SendStatementMessage, newMessage, _List_Nil))
							]));
				} else {
					var chatMsg = msg.a;
					var _v3 = A2($author$project$App$Components$Chatbox$update, chatMsg, model.aM);
					var chatBoxUpdated = _v3.a;
					var chatCmd = _v3.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aM: chatBoxUpdated}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageReevaluation$ChatBoxMsg, chatCmd),
						_List_Nil);
				}
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$ReviewInterviewFullStatementLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewInterviewFullStatementPersisted = {$: 3};
var $author$project$App$Page$ResponseMsgs$ReviewInterviewMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewInterviewStatementFromPersonLoaded = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Reviewer$PageReview$Showing = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Data$StatementStatus$StatusNotify = 2;
var $author$project$App$Page$Reviewer$PageReview$calcBMI = F2(
	function (weight, height) {
		var w = weight / 1000;
		var h = height / 100;
		return (!height) ? 0 : (w / (h * h));
	});
var $author$project$App$Page$Reviewer$PageReview$formatBMI = function (bmi) {
	return A3(
		$elm$core$String$slice,
		0,
		5,
		$elm$core$String$fromFloat(bmi));
};
var $author$project$App$Page$Reviewer$PageReview$updateAnswerField = F4(
	function (tId, stmtId, fieldValue, answers) {
		var answer = function () {
			var _v0 = A2($elm$core$Dict$get, tId, answers);
			if (!_v0.$) {
				var actualAnswer = _v0.a;
				return actualAnswer;
			} else {
				var emptyAnswer = $author$project$App$Data$Answer$emptyModel;
				return _Utils_update(
					emptyAnswer,
					{aY: tId, aV: stmtId});
			}
		}();
		var updatedAnswer = A2($author$project$App$Data$Answer$updateModel, fieldValue, answer);
		return A3($elm$core$Dict$insert, tId, updatedAnswer, answers);
	});
var $author$project$App$Page$Reviewer$PageReview$update = F3(
	function (msg, st, model) {
		var stmtId = model.as;
		var fullStmt = model.h8;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementForReview,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1)
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewInterviewMsg(
											$author$project$App$Page$ResponseMsgs$ReviewInterviewFullStatementLoaded(stmtId)))
									]))
							])));
			case 1:
				var personId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$LoadStatementFromPerson,
								personId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewInterviewMsg(
											$author$project$App$Page$ResponseMsgs$ReviewInterviewStatementFromPersonLoaded(personId)))
									])))
						]));
			case 2:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Review(
								$author$project$App$Route$ReviewListForReviewRoute(
									$author$project$App$Route$ReviewListForReview($author$project$App$Components$Filter$empty))))),
					_List_Nil);
			case 5:
				var statementId = msg.a;
				var filter = msg.b;
				var field = msg.c;
				var stmt = model.h8;
				var bearer = stmt.g;
				var updatedModel = _Utils_update(
					model,
					{
						h8: _Utils_update(
							stmt,
							{
								g: _Utils_update(
									bearer,
									{
										e: A3($author$project$App$Data$Statement$updateModel, model.ey, field, bearer.e)
									})
							})
					});
				return _Utils_Tuple3(updatedModel, $elm$core$Platform$Cmd$none, _List_Nil);
			case 7:
				var depIdx = msg.a;
				var tId = msg.b;
				var statementId = msg.c;
				var answerModel = msg.d;
				var fieldValue = msg.e;
				if (!depIdx.$) {
					var idx = depIdx.a;
					var _v2 = A2($elm$core$Dict$get, idx, fullStmt.fh);
					if (!_v2.$) {
						var dep = _v2.a;
						var updatedAnswer = A2($author$project$App$Data$Answer$updateModel, fieldValue, answerModel);
						var uAns = A2($author$project$App$Data$Answer$updateModel, fieldValue, answerModel);
						var depModel = dep.e;
						var currentQuestion = A2(
							$elm$core$Maybe$withDefault,
							$author$project$App$Data$FullQuestion$emptyModel,
							$elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (q) {
										return A2(
											$elm$core$List$any,
											function (t) {
												return t.ay === 10;
											},
											q.bY);
									},
									$elm$core$List$concat(
										A2(
											$elm$core$List$map,
											function (s) {
												return s.eI;
											},
											st.ia.h8.bs)))));
						var bmiAnswer = function () {
							var answerTemplate = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$AnswerTemplate$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (t) {
											return t.ay === 1000;
										},
										currentQuestion.bY)));
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$Answer$emptyModel,
								A2($elm$core$Dict$get, answerTemplate.as, dep.gw));
						}();
						var _v3 = _Utils_Tuple3(
							(answerModel.ay === 10) || (answerModel.ay === 20),
							function () {
								if (answerModel.ay === 10) {
									return uAns;
								} else {
									var answerTemplate = A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$AnswerTemplate$emptyModel,
										$elm$core$List$head(
											A2(
												$elm$core$List$filter,
												function (t) {
													return t.ay === 10;
												},
												currentQuestion.bY)));
									return A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$Answer$emptyModel,
										A2($elm$core$Dict$get, answerTemplate.as, dep.gw));
								}
							}(),
							function () {
								if (answerModel.ay === 20) {
									return uAns;
								} else {
									var answerTemplate = A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$AnswerTemplate$emptyModel,
										$elm$core$List$head(
											A2(
												$elm$core$List$filter,
												function (t) {
													return t.ay === 20;
												},
												currentQuestion.bY)));
									return A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$Answer$emptyModel,
										A2($elm$core$Dict$get, answerTemplate.as, dep.gw));
								}
							}());
						var updateBMI = _v3.a;
						var weightAnswer = _v3.b;
						var heightAnswer = _v3.c;
						var _v4 = _Utils_Tuple2(
							(answerModel.ay === 20) ? A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(
									A3($elm$core$String$replace, '.', '', heightAnswer.T))) : dep.e.jL,
							(answerModel.ay === 10) ? A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(
									A3($elm$core$String$replace, '.', '', weightAnswer.T))) : dep.e.jN);
						var personHeight = _v4.a;
						var personWeight = _v4.b;
						var currentBMI = updateBMI ? A2($author$project$App$Page$Reviewer$PageReview$calcBMI, personWeight, personHeight) : 111;
						var updatedBMI = A2(
							$author$project$App$Data$Answer$updateModel,
							$author$project$App$Data$Answer$FieldValue(
								$author$project$App$Page$Reviewer$PageReview$formatBMI(currentBMI)),
							bmiAnswer);
						var answers = updateBMI ? A4(
							$author$project$App$Page$Reviewer$PageReview$updateAnswerField,
							updatedBMI.aY,
							statementId,
							$author$project$App$Data$Answer$FieldValue(
								$author$project$App$Page$Reviewer$PageReview$formatBMI(currentBMI)),
							A4($author$project$App$Page$Reviewer$PageReview$updateAnswerField, tId, statementId, fieldValue, dep.gw)) : A4($author$project$App$Page$Reviewer$PageReview$updateAnswerField, tId, statementId, fieldValue, dep.gw);
						var updatedDep = _Utils_update(
							dep,
							{
								gw: answers,
								e: _Utils_update(
									depModel,
									{jL: personHeight, jN: personWeight})
							});
						var sharedMsgs = updateBMI ? _List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$UpdateAnswer, updatedAnswer, _List_Nil)),
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$SaveStatementById, updatedDep.e, _List_Nil)),
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$UpdateAnswer, updatedBMI, _List_Nil))
							]) : _List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$UpdateAnswer, updatedAnswer, _List_Nil))
							]);
						var updatedFullStmt = _Utils_update(
							fullStmt,
							{
								fh: A3($elm$core$Dict$insert, idx, updatedDep, fullStmt.fh)
							});
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{h8: updatedFullStmt}),
							$elm$core$Platform$Cmd$none,
							sharedMsgs);
					} else {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
					}
				} else {
					var updatedAnswer = A2($author$project$App$Data$Answer$updateModel, fieldValue, answerModel);
					var uAns = A2($author$project$App$Data$Answer$updateModel, fieldValue, answerModel);
					var currentQuestion = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$FullQuestion$emptyModel,
						$elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (q) {
									return A2(
										$elm$core$List$any,
										function (t) {
											return t.ay === 10;
										},
										q.bY);
								},
								$elm$core$List$concat(
									A2(
										$elm$core$List$map,
										function (s) {
											return s.eI;
										},
										st.ia.h8.bs)))));
					var bearer = fullStmt.g;
					var bmiAnswer = function () {
						var answerTemplate = A2(
							$elm$core$Maybe$withDefault,
							$author$project$App$Data$AnswerTemplate$emptyModel,
							$elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (t) {
										return t.ay === 1000;
									},
									currentQuestion.bY)));
						return A2(
							$elm$core$Maybe$withDefault,
							$author$project$App$Data$Answer$emptyModel,
							A2($elm$core$Dict$get, answerTemplate.as, bearer.gw));
					}();
					var bModel = bearer.e;
					var _v5 = _Utils_Tuple3(
						(answerModel.ay === 10) || (answerModel.ay === 20),
						function () {
							if (answerModel.ay === 10) {
								return uAns;
							} else {
								var answerTemplate = A2(
									$elm$core$Maybe$withDefault,
									$author$project$App$Data$AnswerTemplate$emptyModel,
									$elm$core$List$head(
										A2(
											$elm$core$List$filter,
											function (t) {
												return t.ay === 10;
											},
											currentQuestion.bY)));
								return A2(
									$elm$core$Maybe$withDefault,
									$author$project$App$Data$Answer$emptyModel,
									A2($elm$core$Dict$get, answerTemplate.as, bearer.gw));
							}
						}(),
						function () {
							if (answerModel.ay === 20) {
								return uAns;
							} else {
								var answerTemplate = A2(
									$elm$core$Maybe$withDefault,
									$author$project$App$Data$AnswerTemplate$emptyModel,
									$elm$core$List$head(
										A2(
											$elm$core$List$filter,
											function (t) {
												return t.ay === 20;
											},
											currentQuestion.bY)));
								return A2(
									$elm$core$Maybe$withDefault,
									$author$project$App$Data$Answer$emptyModel,
									A2($elm$core$Dict$get, answerTemplate.as, bearer.gw));
							}
						}());
					var updateBMI = _v5.a;
					var weightAnswer = _v5.b;
					var heightAnswer = _v5.c;
					var currentBMI = updateBMI ? A2(
						$author$project$App$Page$Reviewer$PageReview$calcBMI,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(
								A3($elm$core$String$replace, '.', '', weightAnswer.T))),
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(
								A3($elm$core$String$replace, '.', '', heightAnswer.T)))) : 0;
					var updatedBMI = A2(
						$author$project$App$Data$Answer$updateModel,
						$author$project$App$Data$Answer$FieldValue(
							$author$project$App$Page$Reviewer$PageReview$formatBMI(currentBMI)),
						bmiAnswer);
					var answers = updateBMI ? A4(
						$author$project$App$Page$Reviewer$PageReview$updateAnswerField,
						updatedBMI.aY,
						statementId,
						$author$project$App$Data$Answer$FieldValue(
							$author$project$App$Page$Reviewer$PageReview$formatBMI(currentBMI)),
						A4($author$project$App$Page$Reviewer$PageReview$updateAnswerField, tId, statementId, fieldValue, bearer.gw)) : A4($author$project$App$Page$Reviewer$PageReview$updateAnswerField, tId, statementId, fieldValue, bearer.gw);
					var _v6 = _Utils_Tuple2(
						(answerModel.ay === 20) ? A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(
								A3($elm$core$String$replace, '.', '', heightAnswer.T))) : bearer.e.iS,
						(answerModel.ay === 10) ? A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(
								A3($elm$core$String$replace, '.', '', weightAnswer.T))) : bearer.e.iT);
					var companyHeight = _v6.a;
					var companyWeight = _v6.b;
					var updatedBearer = _Utils_update(
						bearer,
						{
							gw: answers,
							e: _Utils_update(
								bModel,
								{iS: companyHeight, iT: companyWeight})
						});
					var sharedMsgs = updateBMI ? _List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2($author$project$App$SharedState$UpdateAnswer, updatedAnswer, _List_Nil)),
							$author$project$App$SharedState$APIMsg(
							A2($author$project$App$SharedState$SaveStatementById, updatedBearer.e, _List_Nil)),
							$author$project$App$SharedState$APIMsg(
							A2($author$project$App$SharedState$UpdateAnswer, updatedBMI, _List_Nil))
						]) : _List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2($author$project$App$SharedState$UpdateAnswer, updatedAnswer, _List_Nil))
						]);
					var updatedFullStmt = _Utils_update(
						fullStmt,
						{g: updatedBearer});
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{h8: updatedFullStmt}),
						$elm$core$Platform$Cmd$none,
						sharedMsgs);
				}
			case 8:
				var depIdx = msg.a;
				var fullQuestion = msg.b;
				var templateId = msg.c;
				if (!depIdx.$) {
					var idx = depIdx.a;
					var _v8 = A2($elm$core$Dict$get, idx, fullStmt.fh);
					if (!_v8.$) {
						var dep = _v8.a;
						var modelStatement = model.h8;
						var _v9 = A3(
							$elm$core$List$foldl,
							F2(
								function (id, _v10) {
									var answers = _v10.a;
									var changed = _v10.b;
									return A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(answers, changed),
										A2(
											$elm$core$Maybe$map,
											function (a) {
												var updatedAnswer = A2(
													$author$project$App$Data$Answer$updateModel,
													$author$project$App$Data$Answer$FieldSelectedBool(false),
													A2(
														$author$project$App$Data$Answer$updateModel,
														$author$project$App$Data$Answer$FieldValue(''),
														a));
												return _Utils_Tuple2(
													A3($elm$core$Dict$insert, id, updatedAnswer, answers),
													A3($elm$core$Dict$insert, a.aY, updatedAnswer, changed));
											},
											A2($elm$core$Dict$get, id, dep.gw)));
								}),
							_Utils_Tuple2(dep.gw, $elm$core$Dict$empty),
							A2(
								$elm$core$List$map,
								function ($) {
									return $.as;
								},
								fullQuestion.bY));
						var clearedAnswers = _v9.a;
						var saveCleared = _v9.b;
						var answersToUpdate = A3(
							$elm$core$Dict$foldl,
							F3(
								function (id, a, changed) {
									return _Utils_eq(id, templateId) ? A3(
										$elm$core$Dict$insert,
										templateId,
										A2(
											$author$project$App$Data$Answer$updateModel,
											$author$project$App$Data$Answer$FieldSelectedBool(true),
											a),
										changed) : changed;
								}),
							saveCleared,
							clearedAnswers);
						var answersFinal = A2(
							A3(
								$elm$core$Basics$composeR,
								$elm$core$Dict$fromList,
								A3(
									$elm$core$Dict$merge,
									$elm$core$Dict$insert,
									F4(
										function (_v13, b, _v14, otherDict) {
											return A3($elm$core$Dict$insert, b.aY, b, otherDict);
										}),
									$elm$core$Dict$insert),
								A2(
									$elm$core$List$map,
									function (_v12) {
										var ans = _v12.b;
										return _Utils_Tuple2(ans.aY, ans);
									},
									$elm$core$Dict$toList(answersToUpdate))),
							dep.gw,
							$elm$core$Dict$empty);
						var newDeps = A3(
							$elm$core$Dict$insert,
							idx,
							_Utils_update(
								dep,
								{gw: answersFinal}),
							fullStmt.fh);
						var updateAnswersCmd = A2(
							$elm$core$List$map,
							$author$project$App$SharedState$APIMsg,
							A2(
								$elm$core$List$map,
								function (_v11) {
									var ans = _v11.b;
									return A2($author$project$App$SharedState$UpdateAnswer, ans, _List_Nil);
								},
								$elm$core$Dict$toList(answersToUpdate)));
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									h8: _Utils_update(
										modelStatement,
										{fh: newDeps})
								}),
							$elm$core$Platform$Cmd$none,
							updateAnswersCmd);
					} else {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
					}
				} else {
					var stmt = model.h8.g;
					var modelStatement = model.h8;
					var _v15 = A3(
						$elm$core$List$foldl,
						F2(
							function (id, _v16) {
								var answers = _v16.a;
								var changed = _v16.b;
								return A2(
									$elm$core$Maybe$withDefault,
									_Utils_Tuple2(answers, changed),
									A2(
										$elm$core$Maybe$map,
										function (a) {
											var updatedAnswer = A2(
												$author$project$App$Data$Answer$updateModel,
												$author$project$App$Data$Answer$FieldSelectedBool(false),
												A2(
													$author$project$App$Data$Answer$updateModel,
													$author$project$App$Data$Answer$FieldValue(''),
													a));
											return _Utils_Tuple2(
												A3($elm$core$Dict$insert, id, updatedAnswer, answers),
												A3($elm$core$Dict$insert, a.aY, updatedAnswer, changed));
										},
										A2($elm$core$Dict$get, id, stmt.gw)));
							}),
						_Utils_Tuple2(stmt.gw, $elm$core$Dict$empty),
						A2(
							$elm$core$List$map,
							function ($) {
								return $.as;
							},
							fullQuestion.bY));
					var clearedAnswers = _v15.a;
					var saveCleared = _v15.b;
					var answersToUpdate = A3(
						$elm$core$Dict$foldl,
						F3(
							function (id, a, changed) {
								return _Utils_eq(id, templateId) ? A3(
									$elm$core$Dict$insert,
									templateId,
									A2(
										$author$project$App$Data$Answer$updateModel,
										$author$project$App$Data$Answer$FieldSelectedBool(true),
										a),
									changed) : changed;
							}),
						saveCleared,
						clearedAnswers);
					var answersFinal = A2(
						A3(
							$elm$core$Basics$composeR,
							$elm$core$Dict$fromList,
							A3(
								$elm$core$Dict$merge,
								$elm$core$Dict$insert,
								F4(
									function (_v19, b, _v20, otherDict) {
										return A3($elm$core$Dict$insert, b.aY, b, otherDict);
									}),
								$elm$core$Dict$insert),
							A2(
								$elm$core$List$map,
								function (_v18) {
									var ans = _v18.b;
									return _Utils_Tuple2(ans.aY, ans);
								},
								$elm$core$Dict$toList(answersToUpdate))),
						stmt.gw,
						$elm$core$Dict$empty);
					var updateAnswersCmd = A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (_v17) {
								var ans = _v17.b;
								return A2($author$project$App$SharedState$UpdateAnswer, ans, _List_Nil);
							},
							$elm$core$Dict$toList(answersToUpdate)));
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								h8: _Utils_update(
									modelStatement,
									{
										g: _Utils_update(
											stmt,
											{gw: answersFinal})
									})
							}),
						$elm$core$Platform$Cmd$none,
						updateAnswersCmd);
				}
			case 9:
				var statementId = msg.a;
				var statement = model.h8.g.e;
				var newStatement = _Utils_update(
					statement,
					{aw: 2});
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$SaveStatementById,
								newStatement,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewInterviewMsg($author$project$App$Page$ResponseMsgs$ReviewInterviewFullStatementPersisted))
									])))
						]));
			case 10:
				var viewMsg = msg.a;
				var _v21 = model.gD;
				if (_v21.$ === 1) {
					var statementId = _v21.a;
					var viewModel = _v21.b;
					var _v22 = A4($author$project$App$Components$ViewFullStatement$update, viewMsg, st.ia.h8, st, viewModel);
					var uViewModel = _v22.a;
					var viewCmd = _v22.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Reviewer$PageReview$Showing, statementId, uViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageReview$ViewFullStmtMsg, viewCmd),
						_List_Nil);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 11:
				var time = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 3:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: true}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 4:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: false}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			default:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ReturnToCompany,
								model.h8.g.e,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewInterviewMsg($author$project$App$Page$ResponseMsgs$ReviewInterviewFullStatementPersisted))
									])))
						]));
		}
	});
var $author$project$App$Page$Reviewer$PageWaitingLine$InformLoaded = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$SharedState$LoadDoctorFromPerson = F2(
	function (a, b) {
		return {$: 106, a: a, b: b};
	});
var $author$project$App$SharedState$LoadReviewerWaitingLine = function (a) {
	return {$: 39, a: a};
};
var $author$project$App$Page$Reviewer$PageWaitingLine$PlaySound = {$: 9};
var $author$project$App$Page$ResponseMsgs$ReviewInformedOnline = {$: 3};
var $author$project$App$Page$ResponseMsgs$ReviewWaitingLineCompany = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewWaitingLineDoctorListUpdated = {$: 2};
var $author$project$App$Page$ResponseMsgs$ReviewWaitingLineMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewWaitingLinePersonHold = {$: 7};
var $author$project$App$Page$ResponseMsgs$ReviewWaitingLineProponentTemplate = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewWaitingLineStatement = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewWaitingLineStatementLoaded = {$: 8};
var $author$project$App$Page$ResponseMsgs$ReviewWaitingLineUpdated = {$: 1};
var $author$project$App$Page$Reviewer$PageWaitingLine$emptyWaitingWithTemplate = {b4: $author$project$App$Data$Company$emptyModel, dw: $author$project$App$Data$Person$emptyWaiting, h8: $author$project$App$Data$Statement$emptyModel, cJ: $author$project$App$Data$StatementTemplate$emptyModel};
var $author$project$App$Page$Reviewer$PageWaitingLine$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 2:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								$author$project$App$SharedState$LoadReviewerWaitingLine(
								$author$project$App$Page$ResponseMsgs$ReviewMsg(
									$author$project$App$Page$ResponseMsgs$ReviewWaitingLineMsg($author$project$App$Page$ResponseMsgs$ReviewWaitingLineUpdated))),
								A2(
								$author$project$App$SharedState$LoadDoctorFromPerson,
								model.Y,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewWaitingLineMsg($author$project$App$Page$ResponseMsgs$ReviewWaitingLineDoctorListUpdated))
									])),
								A2(
								$author$project$App$SharedState$DoctorInformOnline,
								$elm$core$String$fromInt(model.df),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewWaitingLineMsg($author$project$App$Page$ResponseMsgs$ReviewInformedOnline))
									]))
							])));
			case 0:
				var person = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$HoldPersonById,
								person.as,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewWaitingLineMsg($author$project$App$Page$ResponseMsgs$ReviewWaitingLinePersonHold))
									])))
						]));
			case 1:
				var id = msg.a;
				return _Utils_eq(id, model.df) ? _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$DoctorInformOnline,
								$elm$core$String$fromInt(model.df),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewWaitingLineMsg($author$project$App$Page$ResponseMsgs$ReviewInformedOnline))
									])))
						])) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
			case 3:
				var wList = msg.a;
				var updatedWaitingList = A3(
					$elm$core$List$foldr,
					F2(
						function (wlp, l) {
							var maybeNewWaitingPerson = $elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (w) {
										return _Utils_eq(w.as, wlp.dw.as);
									},
									wList));
							var newWaitingPerson = A2($elm$core$Maybe$withDefault, wlp.dw, maybeNewWaitingPerson);
							var currentStatement = wlp.h8;
							var newStatement = _Utils_update(
								currentStatement,
								{aw: newWaitingPerson.aw});
							var currentPerson = wlp.dw;
							var newPerson = _Utils_update(
								currentPerson,
								{aw: newWaitingPerson.aw});
							return _Utils_eq(maybeNewWaitingPerson, $elm$core$Maybe$Nothing) ? l : A2(
								$elm$core$List$cons,
								_Utils_update(
									wlp,
									{dw: newPerson, h8: newStatement}),
								l);
						}),
					_List_Nil,
					model.e1);
				var unsubscribeSharedMsgs = A2(
					$elm$core$List$map,
					function (id) {
						return $author$project$App$SharedState$APIMsg(
							$author$project$App$SharedState$UnsubscribeStatementFromPerson(id));
					},
					A3(
						$elm$core$List$foldr,
						F2(
							function (wlp, l) {
								var maybeNewWaitingPerson = $elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (w) {
											return _Utils_eq(w.as, wlp.dw.as);
										},
										wList));
								return _Utils_eq(maybeNewWaitingPerson, $elm$core$Maybe$Nothing) ? A2($elm$core$List$cons, wlp.dw.as, l) : l;
							}),
						_List_Nil,
						model.e1));
				var _v1 = $elm$core$List$isEmpty(wList) ? _Utils_Tuple2(_List_Nil, _List_Nil) : A3(
					$elm$core$List$foldr,
					F2(
						function (w, _v2) {
							var wts = _v2.a;
							var sharedMsgs = _v2.b;
							if (($elm$core$List$length(
								A2(
									$elm$core$List$filter,
									function (wl) {
										return _Utils_eq(wl.dw.as, w.as);
									},
									model.e1)) > 0) || $author$project$App$Data$Id$uuidIsNotValid(w.as)) {
								return _Utils_Tuple2(wts, sharedMsgs);
							} else {
								var wt = $author$project$App$Page$Reviewer$PageWaitingLine$emptyWaitingWithTemplate;
								var wtWithPerson = _Utils_update(
									wt,
									{dw: w});
								var maybeTemplate = A2($author$project$App$Service$ApplicationResources$getStatementTemplateById, w.eY, st._);
								var wtWithTemplate = _Utils_update(
									wtWithPerson,
									{
										cJ: A2($elm$core$Maybe$withDefault, $author$project$App$Data$StatementTemplate$emptyModel, maybeTemplate)
									});
								var maybeCompany = A2($author$project$App$Service$ApplicationResources$getCompanyById, wtWithTemplate.cJ.gx, st._);
								var wtWithCompany = _Utils_update(
									wtWithTemplate,
									{
										b4: A2($elm$core$Maybe$withDefault, $author$project$App$Data$Company$emptyModel, maybeCompany)
									});
								var loadableStatement = A2($author$project$App$Service$ApplicationResources$getStatementFromPerson, w.as, st._);
								var statement = function () {
									switch (loadableStatement.$) {
										case 3:
											var s = loadableStatement.a;
											return s;
										case 2:
											return $author$project$App$Data$Statement$emptyModel;
										case 1:
											return $author$project$App$Data$Statement$emptyModel;
										default:
											return $author$project$App$Data$Statement$emptyModel;
									}
								}();
								var newWt = _Utils_update(
									wtWithCompany,
									{h8: statement});
								var isTemplateAlreadySubscribed = function () {
									if (!maybeTemplate.$) {
										var t = maybeTemplate.a;
										return true;
									} else {
										return false;
									}
								}();
								var isCompanyAlreadySubscribed = function () {
									if (!maybeCompany.$) {
										var c = maybeCompany.a;
										return true;
									} else {
										return false;
									}
								}();
								return (!isTemplateAlreadySubscribed) ? _Utils_Tuple2(
									wts,
									A2(
										$elm$core$List$cons,
										$author$project$App$SharedState$APIMsg(
											A2(
												$author$project$App$SharedState$LoadTemplateById,
												w.eY,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$ReviewMsg(
														$author$project$App$Page$ResponseMsgs$ReviewWaitingLineMsg(
															$author$project$App$Page$ResponseMsgs$ReviewWaitingLineProponentTemplate(newWt)))
													]))),
										sharedMsgs)) : ((!isCompanyAlreadySubscribed) ? _Utils_Tuple2(
									wts,
									A2(
										$elm$core$List$cons,
										$author$project$App$SharedState$APIMsg(
											A2(
												$author$project$App$SharedState$LoadCompanyById,
												newWt.cJ.gx,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$ReviewMsg(
														$author$project$App$Page$ResponseMsgs$ReviewWaitingLineMsg(
															$author$project$App$Page$ResponseMsgs$ReviewWaitingLineCompany(newWt)))
													]))),
										sharedMsgs)) : ((!$author$project$App$Data$Id$uuidIsValid(statement.as)) ? _Utils_Tuple2(
									wts,
									A2(
										$elm$core$List$cons,
										$author$project$App$SharedState$APIMsg(
											A2(
												$author$project$App$SharedState$LoadStatementFromPerson,
												w.as,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$ReviewMsg(
														$author$project$App$Page$ResponseMsgs$ReviewWaitingLineMsg(
															$author$project$App$Page$ResponseMsgs$ReviewWaitingLineStatement(newWt)))
													]))),
										sharedMsgs)) : _Utils_Tuple2(
									A2($elm$core$List$cons, newWt, wts),
									sharedMsgs)));
							}
						}),
					_Utils_Tuple2(_List_Nil, _List_Nil),
					wList);
				var newWaitingElements = _v1.a;
				var remoteMsg = _v1.b;
				var newCmd = ($elm$core$List$length(newWaitingElements) > 0) ? $author$project$App$SharedState$asyncMsg(
					$author$project$App$Page$Reviewer$PageWaitingLine$InformLoaded(
						_Utils_ap(updatedWaitingList, newWaitingElements))) : $author$project$App$SharedState$asyncMsg(
					$author$project$App$Page$Reviewer$PageWaitingLine$InformLoaded(updatedWaitingList));
				return _Utils_Tuple3(
					model,
					newCmd,
					_Utils_ap(remoteMsg, unsubscribeSharedMsgs));
			case 4:
				var wt = msg.a;
				var maybeCompany = A2($author$project$App$Service$ApplicationResources$getCompanyById, wt.cJ.gx, st._);
				var wtWithCompany = _Utils_update(
					wt,
					{
						b4: A2($elm$core$Maybe$withDefault, $author$project$App$Data$Company$emptyModel, maybeCompany)
					});
				var loadableStatement = A2($author$project$App$Service$ApplicationResources$getStatementFromPerson, wt.dw.as, st._);
				var statement = function () {
					switch (loadableStatement.$) {
						case 3:
							var s = loadableStatement.a;
							return s;
						case 2:
							return $author$project$App$Data$Statement$emptyModel;
						case 1:
							return $author$project$App$Data$Statement$emptyModel;
						default:
							return $author$project$App$Data$Statement$emptyModel;
					}
				}();
				var newWt = _Utils_update(
					wtWithCompany,
					{h8: statement});
				var isCompanyAlreadySubscribed = function () {
					if (!maybeCompany.$) {
						var c = maybeCompany.a;
						return true;
					} else {
						return false;
					}
				}();
				return (!isCompanyAlreadySubscribed) ? _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$LoadCompanyById,
								wt.cJ.gx,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewWaitingLineMsg(
											$author$project$App$Page$ResponseMsgs$ReviewWaitingLineCompany(newWt)))
									])))
						])) : ((!$author$project$App$Data$Id$uuidIsValid(statement.as)) ? _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$LoadStatementFromPerson,
								wt.dw.as,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewWaitingLineMsg(
											$author$project$App$Page$ResponseMsgs$ReviewWaitingLineStatement(newWt)))
									])))
						])) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil));
			case 5:
				var wt = msg.a;
				var loadableStatement = A2($author$project$App$Service$ApplicationResources$getStatementFromPerson, wt.dw.as, st._);
				var statement = function () {
					switch (loadableStatement.$) {
						case 3:
							var s = loadableStatement.a;
							return s;
						case 2:
							return $author$project$App$Data$Statement$emptyModel;
						case 1:
							return $author$project$App$Data$Statement$emptyModel;
						default:
							return $author$project$App$Data$Statement$emptyModel;
					}
				}();
				var newWt = _Utils_update(
					wt,
					{h8: statement});
				return (!$author$project$App$Data$Id$uuidIsValid(statement.as)) ? _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$LoadStatementFromPerson,
								wt.dw.as,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewWaitingLineMsg(
											$author$project$App$Page$ResponseMsgs$ReviewWaitingLineStatement(newWt)))
									])))
						])) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
			case 7:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							$author$project$App$SharedState$LoadDoctorInterview(
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewWaitingLineMsg($author$project$App$Page$ResponseMsgs$ReviewWaitingLineStatementLoaded))
									])))
						]));
			case 6:
				var wt = msg.a;
				var playSound = (_Utils_cmp(
					$elm$core$List$length(
						A2(
							$elm$core$List$filter,
							function (wtl) {
								return _Utils_eq(
									$author$project$App$Data$StatementStatus$statusToInt(wtl.dw.aw),
									$author$project$App$Data$StatementStatus$statusToInt(11));
							},
							wt)),
					$elm$core$List$length(
						A2(
							$elm$core$List$filter,
							function (owtl) {
								return _Utils_eq(
									$author$project$App$Data$StatementStatus$statusToInt(owtl.dw.aw),
									$author$project$App$Data$StatementStatus$statusToInt(11));
							},
							model.e1))) > 0) ? $author$project$App$SharedState$asyncMsg($author$project$App$Page$Reviewer$PageWaitingLine$PlaySound) : $elm$core$Platform$Cmd$none;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{e1: wt}),
					playSound,
					_List_Nil);
			case 8:
				var _v9 = $author$project$App$Components$Sound$enable(model.N);
				var nSound = _v9.a;
				var nSoundCmd = _v9.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{N: nSound}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageWaitingLine$SoundMsg, nSoundCmd),
					_List_Nil);
			case 9:
				var _v10 = $author$project$App$Components$Sound$playLongBeep(model.N);
				var nSound = _v10.a;
				var nSoundCmd = _v10.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{N: nSound}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageWaitingLine$SoundMsg, nSoundCmd),
					_List_Nil);
			case 10:
				var _v11 = $author$project$App$Components$Sound$disable(model.N);
				var sound = _v11.a;
				var nSoundCmd = _v11.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{N: sound}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageWaitingLine$SoundMsg, nSoundCmd),
					_List_Nil);
			default:
				var soundMsg = msg.a;
				var _v12 = A2($author$project$App$Components$Sound$update, soundMsg, model.N);
				var nSound = _v12.a;
				var nSoundCmd = _v12.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{N: nSound}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageWaitingLine$SoundMsg, nSoundCmd),
					_List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$ReviewWaitingProponentDetailsMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewerReturnedToLine = 3;
var $author$project$App$Page$ResponseMsgs$ReviewerStatementLoaded = 1;
var $author$project$App$Page$Reviewer$PageWaitingProponentDetails$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementWithMinors,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetQuestions(2),
										$author$project$App$SharedState$FullStmtAssetAnswers(2),
										$author$project$App$SharedState$FullStmtAssetQuestions(1),
										$author$project$App$SharedState$FullStmtAssetAnswers(1)
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewWaitingProponentDetailsMsg(1))
									]))
							])));
			case 1:
				var pId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ReturnPersonToLineById,
								pId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewWaitingProponentDetailsMsg(3))
									])))
						]));
			default:
				var viewFullMsg = msg.a;
				var _v1 = A4($author$project$App$Components$ViewFullStatement$update, viewFullMsg, st.ia.h8, st, model.bm);
				var viewFullModel = _v1.a;
				var viewFullCmd = _v1.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bm: viewFullModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageWaitingProponentDetails$ViewFullStatementMsg, viewFullCmd),
					_List_Nil);
		}
	});
var $author$project$App$Page$PageReviewer$update = F3(
	function (msg, st, model) {
		var _v0 = _Utils_Tuple2(msg, model);
		switch (_v0.a.$) {
			case 0:
				if (!_v0.b.$) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v1 = A3($author$project$App$Page$Reviewer$PageListIncomplete$update, pageMsg, st, pageModel);
					var nPageModel = _v1.a;
					var nPageMsg = _v1.b;
					var stMsgs = _v1.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$ListIncomplete(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListIncompleteMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 1:
				if (_v0.b.$ === 1) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v2 = A3($author$project$App$Page$Reviewer$PageListForReview$update, pageMsg, st, pageModel);
					var nPageModel = _v2.a;
					var nPageMsg = _v2.b;
					var stMsgs = _v2.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$ListForReview(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListForReviewMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 2:
				if (_v0.b.$ === 2) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v3 = A3($author$project$App$Page$Reviewer$PageListApproved$update, pageMsg, st, pageModel);
					var nPageModel = _v3.a;
					var nPageMsg = _v3.b;
					var stMsgs = _v3.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$ListApproved(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListApprovedMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 3:
				if (_v0.b.$ === 3) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v4 = A3($author$project$App$Page$Reviewer$PageReview$update, pageMsg, st, pageModel);
					var nPageModel = _v4.a;
					var nPageMsg = _v4.b;
					var stMsgs = _v4.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$ReviewInterview(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ReviewInterviewMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 4:
				if (_v0.b.$ === 4) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v5 = A3($author$project$App$Page$Reviewer$EditStatement$StatementEdit$update, pageMsg, st, pageModel);
					var nPageModel = _v5.a;
					var nPageMsg = _v5.b;
					var stMsgs = _v5.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$EditStatement(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$EditStatementMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 5:
				if (_v0.b.$ === 6) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v6 = A3($author$project$App$Page$Reviewer$PageListReevaluation$update, pageMsg, st, pageModel);
					var nPageModel = _v6.a;
					var nPageMsg = _v6.b;
					var stMsgs = _v6.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$ListReevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListReevaluationMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 6:
				if (_v0.b.$ === 5) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v7 = A3($author$project$App$Page$Reviewer$PageReevaluation$update, pageMsg, st, pageModel);
					var nPageModel = _v7.a;
					var nPageMsg = _v7.b;
					var stMsgs = _v7.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$Reevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ReevaluationMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 7:
				if (_v0.b.$ === 7) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v8 = A3($author$project$App$Page$Reviewer$PageWaitingLine$update, pageMsg, st, pageModel);
					var nPageModel = _v8.a;
					var nPageMsg = _v8.b;
					var stMsgs = _v8.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$WaitingLine(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$WaitingLineMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			default:
				if (_v0.b.$ === 8) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v9 = A3($author$project$App$Page$Reviewer$PageWaitingProponentDetails$update, pageMsg, st, pageModel);
					var nPageModel = _v9.a;
					var nPageMsg = _v9.b;
					var stMsgs = _v9.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$WaitingProponentDetails(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$WaitingProponentDetailsMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
		}
	});
var $author$project$App$Page$PageSupport$EditStatement = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$PageSupport$ListApproved = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$PageSupport$ListForReview = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$PageSupport$ListIncomplete = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$PageSupport$ListReevaluation = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$PageSupport$Reevaluation = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$PageSupport$ReevaluationMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$PageSupport$ReviewInterview = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$DocumentLoaded = function (a) {
	return {$: 27, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$DocumentMsg = function (a) {
	return {$: 28, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$DocumentSelected = function (a) {
	return {$: 26, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$DocumentShowing = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$DocumentViewMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$No = 0;
var $author$project$App$Page$Support$EditStatement$StatementEdit$SelectDocumentFile = {$: 25};
var $author$project$App$Page$Support$EditStatement$StatementEdit$Showing = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$SupportEditStatementDocumentAdded = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportEditStatementDocumentDeleted = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportEditStatementDocumentUpdated = function (a) {
	return {$: 12, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportEditStatementFullStatementEdited = {$: 4};
var $author$project$App$Page$ResponseMsgs$SupportEditStatementFullStatementLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportEditStatementFullStatementPersisted = {$: 3};
var $author$project$App$Page$ResponseMsgs$SupportEditStatementMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportEditStatementPostponeStatementLoaded = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportEditStatementReturnToConfirmInterviewLoaded = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportEditStatementReturnToDoctorEvaluationLoaded = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportEditStatementReturnToWaitingForRoomLoaded = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$Yes = 1;
var $author$project$App$Page$Support$EditStatement$StatementEdit$errorsDictIsEmpty = function (errors) {
	return !$elm$core$Dict$size(
		A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, fields) {
					return $elm$core$List$length(fields) > 0;
				}),
			errors));
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$errorsFromEditStatement = F3(
	function (now, editStmt, requiredFields) {
		var invalidWeight = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'weight-height';
				},
				requiredFields))) ? _List_Nil : (((editStmt.e.iT < 100) && (editStmt.e.j_ !== 2)) ? _List_fromArray(
			[8]) : _List_Nil);
		var invalidTemplateId = (!$author$project$App$Data$Id$uuidIsValid(editStmt.e.eY)) ? _List_fromArray(
			[2]) : _List_Nil;
		var invalidSurname = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'name';
				},
				requiredFields))) ? _List_Nil : ((editStmt.dw.ii === '') ? _List_fromArray(
			[5]) : _List_Nil);
		var invalidSex = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'sex';
				},
				requiredFields))) ? _List_Nil : ((!editStmt.dw.dJ) ? _List_fromArray(
			[7]) : _List_Nil);
		var invalidRelation = _Utils_eq(editStmt.e.be, $elm$core$Maybe$Nothing) ? _List_fromArray(
			[12]) : _List_Nil;
		var invalidName = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'name';
				},
				requiredFields))) ? _List_Nil : ((editStmt.dw.hl === '') ? _List_fromArray(
			[4]) : _List_Nil);
		var invalidHeight = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'weight-height';
				},
				requiredFields))) ? _List_Nil : (((editStmt.e.iS < 10) && (editStmt.e.j_ !== 2)) ? _List_fromArray(
			[9]) : _List_Nil);
		var invalidDocument = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'cpf';
				},
				requiredFields))) ? _List_Nil : (($elm$core$String$length(editStmt.dw.i1) !== 11) ? _List_fromArray(
			[3]) : _List_Nil);
		var invalidBirthDate = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'birthdate';
				},
				requiredFields))) ? _List_Nil : (_Utils_eq(
			editStmt.dw.al,
			$elm$time$Time$millisToPosix(0)) ? _List_fromArray(
			[6]) : _List_Nil);
		var getContactValue = function (field) {
			return A2(
				$elm$core$Maybe$withDefault,
				$author$project$App$Data$Contact$emptyModel(
					$author$project$App$Data$Contact$kindFromString(field)),
				A2($elm$core$Dict$get, field, editStmt.ea)).T;
		};
		var invalidEmail = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'email';
				},
				requiredFields))) ? _List_Nil : (((getContactValue('email') === '') && (A2($author$project$App$Data$Person$ageOnDate, now, editStmt.dw) >= 18)) ? _List_fromArray(
			[10]) : _List_Nil);
		var invalidMobile = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (f) {
					return f === 'phoneNumber';
				},
				requiredFields))) ? _List_Nil : ((($elm$core$String$length(
			getContactValue('mobile')) < 11) && (A2($author$project$App$Data$Person$ageOnDate, now, editStmt.dw) >= 18)) ? _List_fromArray(
			[11]) : _List_Nil);
		var errors = ($elm$core$List$length(invalidDocument) > 0) ? invalidDocument : _Utils_ap(
			invalidDocument,
			_Utils_ap(
				invalidTemplateId,
				_Utils_ap(
					invalidRelation,
					_Utils_ap(
						invalidName,
						_Utils_ap(
							invalidSurname,
							_Utils_ap(
								invalidBirthDate,
								_Utils_ap(
									invalidSex,
									_Utils_ap(
										invalidWeight,
										_Utils_ap(
											invalidHeight,
											_Utils_ap(invalidEmail, invalidMobile))))))))));
		return errors;
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$errorsFromStatement = F3(
	function (now, fullStmt, modelForm) {
		var stmtTemplate = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$StatementTemplate$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.as, fullStmt.g.e.eY);
					},
					modelForm.eZ)));
		var noDoc = $elm$core$Dict$isEmpty(fullStmt.an);
		var emptyForm = {
			gx: $author$project$App$Data$Id$uuidNil,
			b5: $author$project$App$Components$ViewFullStatement$TabDocument(0),
			fp: $elm$core$Dict$empty,
			a5: $author$project$App$Data$FullStatement$emptyFullStatement,
			ep: false,
			cH: 0,
			eZ: _List_Nil
		};
		var depsErrors = A2(
			$elm$core$Dict$map,
			F2(
				function (_v1, v) {
					return A2(
						$elm$core$List$filter,
						function (field) {
							return field !== 2;
						},
						v);
				}),
			A2(
				$elm$core$Dict$map,
				F2(
					function (_v0, v) {
						return A3($author$project$App$Page$Support$EditStatement$StatementEdit$errorsFromEditStatement, now, v, stmtTemplate.eJ);
					}),
				fullStmt.fh));
		var bearerErrors = A2(
			$elm$core$List$filter,
			function (field) {
				return field !== 12;
			},
			A3($author$project$App$Page$Support$EditStatement$StatementEdit$errorsFromEditStatement, now, fullStmt.g, stmtTemplate.eJ));
		return (noDoc && fullStmt.cJ.hR) ? $elm$core$Dict$fromList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					-1,
					_List_fromArray(
						[0]))
				])) : A3($elm$core$Dict$insert, -1, bearerErrors, depsErrors);
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$formFromModel = function (m) {
	return m.ej;
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$getContactWithKind = F2(
	function (kind, dict) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Contact$emptyModel(kind),
			A2(
				$elm$core$Dict$get,
				$author$project$App$Data$Contact$kindToString(kind),
				dict));
	});
var $author$project$App$Page$Support$EditStatement$DocumentView$update = F4(
	function (msg, fullStmt, sharedState, model) {
		var sections = fullStmt.jM;
		var companySections = fullStmt.bs;
		var _v0 = A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, doc, _v1) {
					var uDocs = _v1.a;
					var cmds = _v1.b;
					var _v2 = $author$project$App$Data$Document$requestUrl(doc);
					var uDoc = _v2.a;
					var cmd = _v2.b;
					return _Utils_Tuple2(
						A3($elm$core$Dict$insert, k, uDoc, uDocs),
						A2($elm$core$List$cons, cmd, cmds));
				}),
			_Utils_Tuple2(fullStmt.an, _List_Nil),
			fullStmt.an);
		var updatedDocs = _v0.a;
		var dCmd = _v0.b;
		var updatedStmt = _Utils_update(
			fullStmt,
			{an: updatedDocs});
		switch (msg.$) {
			case 0:
				var tab = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b5: tab, a5: updatedStmt}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var docMsg = msg.a;
				var docsUpdated = A2(
					$elm$core$Dict$map,
					F2(
						function (_v4, doc) {
							return A2($author$project$App$Data$Document$updateUrl, docMsg, doc);
						}),
					model.a5.an);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a5: _Utils_update(
								updatedStmt,
								{an: docsUpdated})
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var docId = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				var document = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				var statementId = msg.a;
				var document = msg.b;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$updateForm = F2(
	function (f, m) {
		return _Utils_update(
			m,
			{ej: f});
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$update = F3(
	function (msg, st, model) {
		var stmtId = model.as;
		var modelForm = $author$project$App$Page$Support$EditStatement$StatementEdit$formFromModel(model);
		var fullStmt = model.h8;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementForReview,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1)
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportEditStatementMsg(
											$author$project$App$Page$ResponseMsgs$SupportEditStatementFullStatementLoaded(stmtId)))
									]))
							])));
			case 1:
				if (!msg.a.$) {
					var list = msg.a.a;
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
							_Utils_update(
								modelForm,
								{eZ: list}),
							model),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 3:
				var newDueDate = msg.a;
				var newDueDatePosix = function () {
					var _v1 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(newDueDate);
					if (!_v1.$) {
						var posix = _v1.a;
						return posix;
					} else {
						return $elm$time$Time$millisToPosix(0);
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cq: newDueDatePosix}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 2:
				return _Utils_Tuple3(
					model,
					A2($author$project$App$Route$pushUrl, st.fC, model.dD),
					_List_Nil);
			case 10:
				var statementId = msg.a;
				var filter = msg.b;
				var field = msg.c;
				var stmt = model.h8;
				var bearer = stmt.g;
				var updatedModel = _Utils_update(
					model,
					{
						h8: _Utils_update(
							stmt,
							{
								g: _Utils_update(
									bearer,
									{
										e: A3($author$project$App$Data$Statement$updateModel, model.ey, field, bearer.e)
									})
							})
					});
				return _Utils_Tuple3(updatedModel, $elm$core$Platform$Cmd$none, _List_Nil);
			case 12:
				var stmtErrors = A3(
					$author$project$App$Page$Support$EditStatement$StatementEdit$errorsFromStatement,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					modelForm.a5,
					modelForm);
				var _v2 = $author$project$App$Page$Support$EditStatement$StatementEdit$errorsDictIsEmpty(stmtErrors) ? _Utils_Tuple2(
					1,
					_List_fromArray(
						[
							A3(
							$author$project$App$SharedState$PersistFullStatement,
							modelForm.gx,
							modelForm.a5,
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$SupportMsg(
									$author$project$App$Page$ResponseMsgs$SupportEditStatementMsg($author$project$App$Page$ResponseMsgs$SupportEditStatementFullStatementEdited))
								]))
						])) : _Utils_Tuple2(0, _List_Nil);
				var submitting = _v2.a;
				var persistMsg = _v2.b;
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{fp: stmtErrors, cH: submitting}),
						_Utils_update(
							model,
							{y: true})),
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, persistMsg));
			case 13:
				var viewMsg = msg.a;
				var _v3 = model.gD;
				if (_v3.$ === 1) {
					var statementId = _v3.a;
					var viewModel = _v3.b;
					var _v4 = A4($author$project$App$Components$ViewFullStatement$update, viewMsg, st.ia.h8, st, viewModel);
					var uViewModel = _v4.a;
					var viewCmd = _v4.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Support$EditStatement$StatementEdit$Showing, statementId, uViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$EditStatement$StatementEdit$ViewFullStmtMsg, viewCmd),
						_List_Nil);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 14:
				var viewMsg = msg.a;
				switch (viewMsg.$) {
					case 2:
						var documentId = viewMsg.a;
						var _v6 = model.ac;
						if (_v6.$ === 1) {
							var statementId = _v6.a;
							var viewModel = _v6.b;
							var _v7 = A4($author$project$App$Page$Support$EditStatement$DocumentView$update, viewMsg, st.ia.h8, st, viewModel);
							var uDocumentViewModel = _v7.a;
							var viewCmd = _v7.b;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										ac: A2($author$project$App$Page$Support$EditStatement$StatementEdit$DocumentShowing, statementId, uDocumentViewModel),
										y: true
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$EditStatement$StatementEdit$DocumentViewMsg, viewCmd),
								A2(
									$elm$core$List$map,
									$author$project$App$SharedState$APIMsg,
									_List_fromArray(
										[
											A2(
											$author$project$App$SharedState$DeleteDocument,
											documentId,
											_List_fromArray(
												[
													$author$project$App$Page$ResponseMsgs$SupportMsg(
													$author$project$App$Page$ResponseMsgs$SupportEditStatementMsg(
														$author$project$App$Page$ResponseMsgs$SupportEditStatementDocumentDeleted(documentId)))
												]))
										])));
						} else {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
						}
					case 4:
						var addDocumentStmtId = viewMsg.a;
						var documentModel = viewMsg.b;
						var _v8 = model.ac;
						if (_v8.$ === 1) {
							var statementId = _v8.a;
							var viewModel = _v8.b;
							var _v9 = A4($author$project$App$Page$Support$EditStatement$DocumentView$update, viewMsg, st.ia.h8, st, viewModel);
							var uDocumentViewModel = _v9.a;
							var viewCmd = _v9.b;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										ac: A2($author$project$App$Page$Support$EditStatement$StatementEdit$DocumentShowing, statementId, uDocumentViewModel)
									}),
								A2(
									$elm$core$Task$perform,
									$elm$core$Basics$always($author$project$App$Page$Support$EditStatement$StatementEdit$SelectDocumentFile),
									$elm$core$Task$succeed(0)),
								_List_Nil);
						} else {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
						}
					case 3:
						var documentModel = viewMsg.a;
						var _v10 = model.ac;
						if (_v10.$ === 1) {
							var statementId = _v10.a;
							var viewModel = _v10.b;
							var _v11 = A4($author$project$App$Page$Support$EditStatement$DocumentView$update, viewMsg, st.ia.h8, st, viewModel);
							var uDocumentViewModel = _v11.a;
							var viewCmd = _v11.b;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										ac: A2($author$project$App$Page$Support$EditStatement$StatementEdit$DocumentShowing, statementId, uDocumentViewModel),
										y: true
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$EditStatement$StatementEdit$DocumentViewMsg, viewCmd),
								A2(
									$elm$core$List$map,
									$author$project$App$SharedState$APIMsg,
									_List_fromArray(
										[
											A2(
											$author$project$App$SharedState$UpdateDocument,
											_Utils_update(
												documentModel,
												{f2: model.ey}),
											_List_fromArray(
												[
													$author$project$App$Page$ResponseMsgs$SupportMsg(
													$author$project$App$Page$ResponseMsgs$SupportEditStatementMsg(
														$author$project$App$Page$ResponseMsgs$SupportEditStatementDocumentUpdated(documentModel.as)))
												]))
										])));
						} else {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
						}
					default:
						var _v12 = model.ac;
						if (_v12.$ === 1) {
							var statementId = _v12.a;
							var viewModel = _v12.b;
							var _v13 = A4($author$project$App$Page$Support$EditStatement$DocumentView$update, viewMsg, st.ia.h8, st, viewModel);
							var uDocumentViewModel = _v13.a;
							var viewCmd = _v13.b;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										ac: A2($author$project$App$Page$Support$EditStatement$StatementEdit$DocumentShowing, statementId, uDocumentViewModel)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$EditStatement$StatementEdit$DocumentViewMsg, viewCmd),
								_List_Nil);
						} else {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
						}
				}
			case 15:
				var time = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 8:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: true}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 9:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: false}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 11:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ReturnToCompany,
								model.h8.g.e,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportEditStatementMsg($author$project$App$Page$ResponseMsgs$SupportEditStatementFullStatementPersisted))
									])))
						]));
			case 16:
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{fp: $elm$core$Dict$empty}),
						model),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 17:
				var depIdx = msg.a;
				var fieldValue = msg.b;
				if (!depIdx.$) {
					var now = $author$project$App$Service$ApiWebSocket$getTimestamp(st._);
					var formFullStmt = modelForm.a5;
					var newDeps = A2(
						$elm$core$Dict$map,
						F2(
							function (_v15, v) {
								return _Utils_update(
									v,
									{
										e: A3($author$project$App$Data$Statement$updateModel, now, fieldValue, v.e)
									});
							}),
						formFullStmt.fh);
					var bearer = formFullStmt.g;
					var newBearer = _Utils_update(
						bearer,
						{
							e: A3($author$project$App$Data$Statement$updateModel, now, fieldValue, bearer.e)
						});
					var newFullStmt = _Utils_update(
						formFullStmt,
						{g: newBearer, fh: newDeps});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
							_Utils_update(
								modelForm,
								{a5: newFullStmt}),
							_Utils_update(
								model,
								{h8: newFullStmt})),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				} else {
					var idx = depIdx.a;
					var now = $author$project$App$Service$ApiWebSocket$getTimestamp(st._);
					var formFullStmt = modelForm.a5;
					var dep = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$FullStatement$emptyEditStatement,
						A2($elm$core$Dict$get, idx, modelForm.a5.fh));
					var updatedStmt = A3($author$project$App$Data$Statement$updateModel, now, fieldValue, dep.e);
					var newDep = _Utils_update(
						dep,
						{e: updatedStmt});
					var newFullStmt = _Utils_update(
						formFullStmt,
						{
							fh: A3($elm$core$Dict$insert, idx, newDep, formFullStmt.fh)
						});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
							_Utils_update(
								modelForm,
								{a5: newFullStmt}),
							_Utils_update(
								model,
								{h8: newFullStmt})),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				}
			case 18:
				var depIdx = msg.a;
				var fieldValue = msg.b;
				var sharedMsg = function (person) {
					return $author$project$App$Data$Id$uuidIsValid(person.as) ? _List_Nil : _List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$LoadPersonByDocument,
								person.i1,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$CompanyMsg(
										$author$project$App$Page$ResponseMsgs$CompanyRequestInterviewMsg(
											A2($author$project$App$Page$ResponseMsgs$CompanyRequestInterviewPersonLoadedByDocument, person.i1, depIdx)))
									])))
						]);
				};
				var formFullStmt = modelForm.a5;
				var stmtTemplate = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$StatementTemplate$emptyModel,
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (t) {
								return _Utils_eq(t.as, formFullStmt.g.e.eY);
							},
							modelForm.eZ)));
				var thereIsSocialNameField = A2($elm$core$List$member, 'socialName', stmtTemplate.eJ);
				if (!depIdx.$) {
					var bearer = formFullStmt.g;
					var person = thereIsSocialNameField ? A2($author$project$App$Data$Person$updateModel, fieldValue, bearer.dw) : A2(
						$author$project$App$Data$Person$updateModel,
						$author$project$App$Data$Person$FieldSocialName(''),
						A2($author$project$App$Data$Person$updateModel, fieldValue, bearer.dw));
					var newFullStmt = _Utils_update(
						formFullStmt,
						{
							g: _Utils_update(
								bearer,
								{dw: person})
						});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
							_Utils_update(
								modelForm,
								{a5: newFullStmt}),
							_Utils_update(
								model,
								{h8: newFullStmt})),
						$elm$core$Platform$Cmd$none,
						sharedMsg(person));
				} else {
					var idx = depIdx.a;
					var dep = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$FullStatement$emptyEditStatement,
						A2($elm$core$Dict$get, idx, formFullStmt.fh));
					var person = thereIsSocialNameField ? A2($author$project$App$Data$Person$updateModel, fieldValue, dep.dw) : A2(
						$author$project$App$Data$Person$updateModel,
						$author$project$App$Data$Person$FieldSocialName(''),
						A2($author$project$App$Data$Person$updateModel, fieldValue, dep.dw));
					var nDep = _Utils_update(
						dep,
						{dw: person});
					var newFullStmt = _Utils_update(
						formFullStmt,
						{
							fh: A3($elm$core$Dict$insert, idx, nDep, formFullStmt.fh)
						});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
							_Utils_update(
								modelForm,
								{a5: newFullStmt}),
							_Utils_update(
								model,
								{h8: newFullStmt})),
						$elm$core$Platform$Cmd$none,
						sharedMsg(person));
				}
			case 19:
				var mDepIdx = msg.a;
				var kind = msg.b;
				var fieldValue = msg.c;
				if (!mDepIdx.$) {
					var formFullStmt = modelForm.a5;
					var bearer = formFullStmt.g;
					var contact = A2($author$project$App$Page$Support$EditStatement$StatementEdit$getContactWithKind, kind, bearer.ea);
					var updatedContact = A2($author$project$App$Data$Contact$updateModel, fieldValue, contact);
					var newBearer = _Utils_update(
						bearer,
						{
							ea: A3(
								$elm$core$Dict$insert,
								$author$project$App$Data$Contact$kindToString(kind),
								updatedContact,
								bearer.ea)
						});
					var newFullStmt = _Utils_update(
						formFullStmt,
						{g: newBearer});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
							_Utils_update(
								modelForm,
								{a5: newFullStmt}),
							_Utils_update(
								model,
								{h8: newFullStmt})),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				} else {
					var idx = mDepIdx.a;
					var formFullStmt = modelForm.a5;
					var dep = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$FullStatement$emptyEditStatement,
						A2($elm$core$Dict$get, idx, formFullStmt.fh));
					var contact = A2($author$project$App$Page$Support$EditStatement$StatementEdit$getContactWithKind, kind, dep.ea);
					var updatedContact = A2($author$project$App$Data$Contact$updateModel, fieldValue, contact);
					var newDep = _Utils_update(
						dep,
						{
							ea: A3(
								$elm$core$Dict$insert,
								$author$project$App$Data$Contact$kindToString(kind),
								updatedContact,
								dep.ea)
						});
					var newFullStmt = _Utils_update(
						formFullStmt,
						{
							fh: A3($elm$core$Dict$insert, idx, newDep, formFullStmt.fh)
						});
					return _Utils_Tuple3(
						A2(
							$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
							_Utils_update(
								modelForm,
								{a5: newFullStmt}),
							_Utils_update(
								model,
								{h8: newFullStmt})),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				}
			case 20:
				var formFullStmt = modelForm.a5;
				var idx = $elm$core$Dict$size(formFullStmt.fh);
				var templateId = formFullStmt.g.e.eY;
				var emptyStmt = $author$project$App$Data$FullStatement$emptyEditStatement;
				var stmtModel = emptyStmt.e;
				var bearerStmtId = formFullStmt.g.e.as;
				var stmtDep = _Utils_update(
					emptyStmt,
					{
						e: _Utils_update(
							stmtModel,
							{
								gL: formFullStmt.g.e.gL,
								jK: $elm$core$Maybe$Just(bearerStmtId),
								eY: templateId
							})
					});
				var newFullStmt = _Utils_update(
					formFullStmt,
					{
						fh: A3($elm$core$Dict$insert, idx, stmtDep, formFullStmt.fh)
					});
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{a5: newFullStmt}),
						_Utils_update(
							model,
							{h8: newFullStmt})),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 21:
				var idx = msg.a;
				var formFullStmt = modelForm.a5;
				var updatedDeps = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, _v18) {
								var dep = _v18.b;
								return _Utils_Tuple2(i, dep);
							}),
						$elm$core$Dict$toList(
							A2($elm$core$Dict$remove, idx, formFullStmt.fh))));
				var newFullStmt = _Utils_update(
					formFullStmt,
					{fh: updatedDeps});
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{a5: newFullStmt}),
						_Utils_update(
							model,
							{h8: newFullStmt})),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 23:
				var idx = $elm$core$Dict$size(fullStmt.an);
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{
								b5: $author$project$App$Components$ViewFullStatement$TabDocument(idx),
								a5: _Utils_update(
									fullStmt,
									{
										an: A3($elm$core$Dict$insert, idx, $author$project$App$Data$Document$emptyModel, fullStmt.an)
									})
							}),
						model),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 22:
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{d9: true}),
						model),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 24:
				var tab = function () {
					var _v21 = modelForm.b5;
					if (_v21.$ === 1) {
						if (!_v21.a) {
							return modelForm.b5;
						} else {
							var i = _v21.a;
							return $author$project$App$Components$ViewFullStatement$TabDocument(i - 1);
						}
					} else {
						return $author$project$App$Components$ViewFullStatement$TabDocument(0);
					}
				}();
				var idx = function () {
					var _v20 = modelForm.b5;
					if (_v20.$ === 1) {
						var i = _v20.a;
						return i;
					} else {
						return 0;
					}
				}();
				var updatedDocs = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, _v19) {
								var d = _v19.b;
								return _Utils_Tuple2(i, d);
							}),
						$elm$core$Dict$toList(
							A2($elm$core$Dict$remove, idx, fullStmt.an))));
				var doc = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$Document$emptyModel,
					A2($elm$core$Dict$get, idx, fullStmt.an));
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{
								b5: tab,
								a5: _Utils_update(
									fullStmt,
									{
										an: updatedDocs,
										fO: A2(
											$elm$core$List$cons,
											_Utils_update(
												doc,
												{iU: ''}),
											fullStmt.fO)
									})
							}),
						model),
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$App$Page$Support$EditStatement$StatementEdit$DocumentMsg,
						$author$project$App$Data$Document$clearUrl(doc)),
					_List_Nil);
			case 25:
				return _Utils_Tuple3(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['application/pdf', 'image/png', 'image/jpeg']),
						$author$project$App$Page$Support$EditStatement$StatementEdit$DocumentSelected),
					_List_Nil);
			case 26:
				var file = msg.a;
				var now = $elm$time$Time$posixToMillis(
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
				var mime = $elm$file$File$mime(file);
				var idx = function () {
					var _v23 = modelForm.b5;
					if (_v23.$ === 1) {
						var i = _v23.a;
						return i;
					} else {
						return 0;
					}
				}();
				var doc = function () {
					var _v22 = A2($elm$core$Dict$get, idx, fullStmt.an);
					if (!_v22.$) {
						var d = _v22.a;
						return d;
					} else {
						return $author$project$App$Data$Document$emptyModel;
					}
				}();
				var updatedDoc = _Utils_update(
					doc,
					{
						iU: '',
						dr: mime,
						hl: 'Anexado em ' + $elm$core$String$fromInt(now),
						af: '',
						Q: false
					});
				var updatedStmt = _Utils_update(
					fullStmt,
					{
						an: A3($elm$core$Dict$insert, idx, updatedDoc, fullStmt.an)
					});
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{a5: updatedStmt}),
						model),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$perform,
								$author$project$App$Page$Support$EditStatement$StatementEdit$DocumentLoaded,
								$elm$file$File$toUrl(file)),
								$author$project$App$Data$Document$clearUrl(doc)
							])),
					_List_Nil);
			case 27:
				var content = msg.a;
				var now = $elm$time$Time$posixToMillis(
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
				var mime = 'video/mp4';
				var doc = $author$project$App$Data$Document$emptyModel;
				var docWithName = _Utils_update(
					doc,
					{
						iU: '',
						dr: mime,
						hl: 'Anexado em ' + $elm$core$String$fromInt(now),
						af: '',
						Q: false
					});
				var _v24 = $author$project$App$Data$Document$requestUrl(
					_Utils_update(
						docWithName,
						{iU: content}));
				var updatedDoc = _v24.a;
				var dCmd = _v24.b;
				var updatedStmt = _Utils_update(
					fullStmt,
					{
						an: A3($elm$core$Dict$insert, 0, updatedDoc, fullStmt.an)
					});
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{a5: updatedStmt}),
						model),
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$CreateNewDocumentFromStatement,
								updatedDoc.jJ,
								_Utils_update(
									updatedDoc,
									{f2: model.ey}),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportEditStatementMsg(
											$author$project$App$Page$ResponseMsgs$SupportEditStatementDocumentAdded(updatedDoc.jJ)))
									]))
							])));
			case 28:
				var docMsg = msg.a;
				var docsUpdated = A2(
					$elm$core$Dict$map,
					F2(
						function (_v25, doc) {
							return A2($author$project$App$Data$Document$updateUrl, docMsg, doc);
						}),
					fullStmt.an);
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{
								a5: _Utils_update(
									fullStmt,
									{an: docsUpdated})
							}),
						model),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 29:
				var tab = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{b5: tab}),
						model),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 7:
				var stmt = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{y: true}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$PostPoneStatement,
								stmt,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportEditStatementMsg(
											$author$project$App$Page$ResponseMsgs$SupportEditStatementPostponeStatementLoaded(stmt.as)))
									])))
						]));
			case 4:
				var stmt = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{y: true}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ReturnToDoctorEvaluation,
								stmt,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportEditStatementMsg(
											$author$project$App$Page$ResponseMsgs$SupportEditStatementReturnToDoctorEvaluationLoaded(stmt.as)))
									])))
						]));
			case 5:
				var stmt = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{y: true}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ReturnToInterview,
								stmt,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportEditStatementMsg(
											$author$project$App$Page$ResponseMsgs$SupportEditStatementReturnToConfirmInterviewLoaded(stmt.as)))
									])))
						]));
			case 6:
				var stmt = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{y: true}),
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ReturnToWaitingForRoom,
								stmt,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportEditStatementMsg(
											$author$project$App$Page$ResponseMsgs$SupportEditStatementReturnToWaitingForRoomLoaded(stmt.as)))
									])))
						]));
			case 31:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{F: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			default:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{a6: !model.a6}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
		}
	});
var $author$project$App$Page$Support$PageListApproved$NoDetails = {$: 0};
var $author$project$App$Page$Support$PageListApproved$Showing = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$SupportListApprovedFullStatementLoaded = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportListApprovedMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportListApprovedPersonLoaded = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportListApprovedStatementsLoaded = {$: 2};
var $author$project$App$Page$Support$PageListApproved$defaultFilter = function (filter) {
	return A2($author$project$App$Components$Filter$setOrder, 'updatedAt,desc', filter);
};
var $author$project$App$Page$Support$PageListApproved$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A3(
						$author$project$App$SharedState$LoadApprovedStatementsForReview,
						model.gx,
						$author$project$App$Page$Support$PageListApproved$defaultFilter(uModel.b),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$SupportMsg(
								$author$project$App$Page$ResponseMsgs$SupportListApprovedMsg($author$project$App$Page$ResponseMsgs$SupportListApprovedStatementsLoaded))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var viewMsg = msg.a;
				var _v2 = model.gD;
				if (_v2.$ === 1) {
					var stmtId = _v2.a;
					var viewModel = _v2.b;
					var _v3 = A4($author$project$App$Components$ViewFullStatement$update, viewMsg, st.ia.h8, st, viewModel);
					var uViewModel = _v3.a;
					var viewCmd = _v3.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Support$PageListApproved$Showing, stmtId, uViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$PageListApproved$ViewFullStmtMsg, viewCmd),
						_List_Nil);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 2:
				var filter = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadApprovedStatementsForReview,
								model.gx,
								$author$project$App$Page$Support$PageListApproved$defaultFilter(filter),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportListApprovedMsg($author$project$App$Page$ResponseMsgs$SupportListApprovedStatementsLoaded))
									]))
							])));
			case 3:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$SupportMsg(
											$author$project$App$Page$ResponseMsgs$SupportListApprovedMsg(
												$author$project$App$Page$ResponseMsgs$SupportListApprovedPersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			case 4:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementWithMinors,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1),
										$author$project$App$SharedState$FullStmtAssetAnswers(2),
										$author$project$App$SharedState$FullStmtAssetQuestions(2),
										$author$project$App$SharedState$FullStmtAssetAnswers(3),
										$author$project$App$SharedState$FullStmtAssetQuestions(3),
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetICD
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportListApprovedMsg(
											$author$project$App$Page$ResponseMsgs$SupportListApprovedFullStatementLoaded(stmtId)))
									]))
							])));
			case 5:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{gD: $author$project$App$Page$Support$PageListApproved$NoDetails}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			default:
				var stmtId = msg.a;
				return $author$project$App$Data$Id$uuidIsValid(stmtId) ? _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Support(
								$author$project$App$Route$SupportEditStatementRoute(
									A2($author$project$App$Route$SupportEditApprovedStatement, stmtId, model.b))))),
					_List_Nil) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$SupportListForReviewMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportListForReviewPersonLoaded = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportListForReviewStatementsLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Support$PageListForReview$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A3(
						$author$project$App$SharedState$LoadStatementsForReview,
						model.gx,
						uModel.b,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$SupportMsg(
								$author$project$App$Page$ResponseMsgs$SupportListForReviewMsg(
									$author$project$App$Page$ResponseMsgs$SupportListForReviewStatementsLoaded(uModel.b)))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Support(
								$author$project$App$Route$SupportListForReviewRoute(
									$author$project$App$Route$SupportListForReview(uModel.b))))),
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var filter = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadStatementsForReview,
								model.gx,
								filter,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportListForReviewMsg(
											$author$project$App$Page$ResponseMsgs$SupportListForReviewStatementsLoaded(filter)))
									]))
							])));
			case 2:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$SupportMsg(
											$author$project$App$Page$ResponseMsgs$SupportListForReviewMsg(
												$author$project$App$Page$ResponseMsgs$SupportListForReviewPersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			case 3:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Support(
								$author$project$App$Route$SupportInterviewRoute(stmtId)))),
					_List_Nil);
			case 4:
				var stmtId = msg.a;
				return $author$project$App$Data$Id$uuidIsValid(stmtId) ? _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Support(
								$author$project$App$Route$SupportInterviewRoute(stmtId)))),
					_List_Nil) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
			default:
				var viewMsg = msg.a;
				var _v2 = function () {
					var _v3 = model.av;
					if (!_v3.$) {
						var viewFullStmt = _v3.a;
						return function (_v4) {
							var a = _v4.a;
							var b = _v4.b;
							return _Utils_Tuple3(
								$elm$core$Maybe$Just(viewFullStmt.a5.g.e.as),
								$elm$core$Maybe$Just(a),
								b);
						}(
							A4($author$project$App$Components$ViewFullStatement$update, viewMsg, st.ia.h8, st, viewFullStmt));
					} else {
						return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
					}
				}();
				var showStmt = _v2.a;
				var uViewFullStmt = _v2.b;
				var uViewFullStmtMsg = _v2.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: showStmt, av: uViewFullStmt}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$PageListForReview$ViewFullStmtMsg, uViewFullStmtMsg),
					_List_Nil);
		}
	});
var $author$project$App$Page$Support$PageListIncomplete$NoDetails = {$: 0};
var $author$project$App$Page$Support$PageListIncomplete$Showing = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$SupportListIncompleteFullStatementLoaded = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportListIncompleteFullStatementPersisted = {$: 5};
var $author$project$App$Page$ResponseMsgs$SupportListIncompleteMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportListIncompletePersonLoaded = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportListIncompleteStatementsLoaded = {$: 2};
var $author$project$App$Page$Support$PageListIncomplete$defaultFilter = function (filter) {
	return A2($author$project$App$Components$Filter$setOrder, 'created_at,desc', filter);
};
var $author$project$App$Page$Support$PageListIncomplete$routeFromDetails = function (model) {
	var _v0 = model.gD;
	if (!_v0.$) {
		return $author$project$App$Route$SupportListIncomplete(model.b);
	} else {
		var stmtId = _v0.a;
		return A2($author$project$App$Route$SupportListIncompleteViewDetails, stmtId, model.b);
	}
};
var $author$project$App$Page$Support$PageListIncomplete$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A3(
						$author$project$App$SharedState$LoadIncompleteStatementsForReview,
						model.gx,
						$author$project$App$Page$Support$PageListIncomplete$defaultFilter(uModel.b),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$SupportMsg(
								$author$project$App$Page$ResponseMsgs$SupportListIncompleteMsg($author$project$App$Page$ResponseMsgs$SupportListIncompleteStatementsLoaded))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Support(
								$author$project$App$Route$SupportListIncompleteRoute(
									$author$project$App$Page$Support$PageListIncomplete$routeFromDetails(uModel))))),
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var viewMsg = msg.a;
				var _v2 = model.gD;
				if (_v2.$ === 1) {
					var stmtId = _v2.a;
					var viewModel = _v2.b;
					var _v3 = A4($author$project$App$Components$ViewFullStatement$update, viewMsg, st.ia.h8, st, viewModel);
					var uViewModel = _v3.a;
					var viewCmd = _v3.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Support$PageListIncomplete$Showing, stmtId, uViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$PageListIncomplete$ViewFullStmtMsg, viewCmd),
						_List_Nil);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 2:
				var filter = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadIncompleteStatementsForReview,
								model.gx,
								$author$project$App$Page$Support$PageListIncomplete$defaultFilter(filter),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportListIncompleteMsg($author$project$App$Page$ResponseMsgs$SupportListIncompleteStatementsLoaded))
									]))
							])));
			case 3:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$SupportMsg(
											$author$project$App$Page$ResponseMsgs$SupportListIncompleteMsg(
												$author$project$App$Page$ResponseMsgs$SupportListIncompletePersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			case 4:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Support(
								$author$project$App$Route$SupportListIncompleteRoute(
									A2(
										$author$project$App$Route$SupportListIncompleteViewDetails,
										stmtId,
										$author$project$App$Page$Support$PageListIncomplete$defaultFilter(model.b)))))),
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementWithMinors,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1),
										$author$project$App$SharedState$FullStmtAssetAnswers(2),
										$author$project$App$SharedState$FullStmtAssetQuestions(2),
										$author$project$App$SharedState$FullStmtAssetAnswers(3),
										$author$project$App$SharedState$FullStmtAssetQuestions(3),
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetICD
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportListIncompleteMsg(
											$author$project$App$Page$ResponseMsgs$SupportListIncompleteFullStatementLoaded(stmtId)))
									]))
							])));
			case 5:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{gD: $author$project$App$Page$Support$PageListIncomplete$NoDetails}),
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Support(
								$author$project$App$Route$SupportListIncompleteRoute(
									$author$project$App$Route$SupportListIncomplete(model.b))))),
					_List_Nil);
			case 6:
				var stmt = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: true, h8: stmt}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 7:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: false}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 9:
				var stmt = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ReturnToCompany,
								stmt,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportListIncompleteMsg($author$project$App$Page$ResponseMsgs$SupportListIncompleteFullStatementPersisted))
									])))
						]));
			case 10:
				var stmt = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2($author$project$App$SharedState$PostPoneStatement, stmt, _List_Nil))
						]));
			case 8:
				var statementId = msg.a;
				var filter = msg.b;
				var field = msg.c;
				var stmt = model.h8;
				var updatedModel = _Utils_update(
					model,
					{
						h8: A3($author$project$App$Data$Statement$updateModel, model.ey, field, stmt)
					});
				return _Utils_Tuple3(updatedModel, $elm$core$Platform$Cmd$none, _List_Nil);
			case 11:
				var time = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			default:
				var stmtId = msg.a;
				return $author$project$App$Data$Id$uuidIsValid(stmtId) ? _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Support(
								$author$project$App$Route$SupportEditStatementRoute(
									A2($author$project$App$Route$SupportEditStatement, stmtId, model.b))))),
					_List_Nil) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$Page$ResponseMsgs$SupportListReevaluationLoaded = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$SupportListReevaluationMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportListReevaluationPersonLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Support$PageListReevaluation$update = F3(
	function (msg, sharedState, model) {
		switch (msg.$) {
			case 0:
				var filterMsg = msg.a;
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					function (f) {
						return _Utils_update(
							model,
							{b: f});
					},
					A2($author$project$App$Components$Filter$update, filterMsg, model.b));
				var uModel = _v1.a;
				var apply = _v1.b;
				var sharedMsg = apply ? _List_fromArray(
					[
						A3(
						$author$project$App$SharedState$LoadReevalStatementsFromCompany,
						model.gx,
						uModel.b,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$SupportMsg(
								$author$project$App$Page$ResponseMsgs$SupportListReevaluationMsg(
									A2($author$project$App$Page$ResponseMsgs$SupportListReevaluationLoaded, uModel.gx, uModel.b)))
							]))
					]) : _List_Nil;
				return _Utils_Tuple3(
					uModel,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Support($author$project$App$Route$SupportListReevaluation))),
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, sharedMsg));
			case 1:
				var filter = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadReevalStatementsFromCompany,
								model.gx,
								filter,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportListReevaluationMsg(
											A2($author$project$App$Page$ResponseMsgs$SupportListReevaluationLoaded, model.gx, filter)))
									]))
							])));
			case 2:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (stmt) {
								return A2(
									$author$project$App$SharedState$LoadPersonById,
									stmt.Y,
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$SupportMsg(
											$author$project$App$Page$ResponseMsgs$SupportListReevaluationMsg(
												$author$project$App$Page$ResponseMsgs$SupportListReevaluationPersonLoaded(stmt.Y)))
										]));
							},
							model.bk)));
			case 3:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Support(
								$author$project$App$Route$SupportReevaluationDetails(stmtId)))),
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementWithMinors,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1),
										$author$project$App$SharedState$FullStmtAssetAnswers(2),
										$author$project$App$SharedState$FullStmtAssetQuestions(2),
										$author$project$App$SharedState$FullStmtAssetAnswers(3),
										$author$project$App$SharedState$FullStmtAssetQuestions(3),
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetICD
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportListReevaluationMsg(
											A2($author$project$App$Page$ResponseMsgs$SupportListReevaluationLoaded, model.gx, $author$project$App$Components$Filter$empty)))
									]))
							])));
			case 4:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: $elm$core$Maybe$Nothing, av: $elm$core$Maybe$Nothing}),
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Support($author$project$App$Route$SupportListReevaluation))),
					_List_Nil);
			case 5:
				var viewMsg = msg.a;
				var _v2 = function () {
					var _v3 = model.av;
					if (!_v3.$) {
						var viewFullStmt = _v3.a;
						return function (_v4) {
							var a = _v4.a;
							var b = _v4.b;
							return _Utils_Tuple3(
								$elm$core$Maybe$Just(viewFullStmt.a5.g.e.as),
								$elm$core$Maybe$Just(a),
								b);
						}(
							A4($author$project$App$Components$ViewFullStatement$update, viewMsg, sharedState.ia.h8, sharedState, viewFullStmt));
					} else {
						return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
					}
				}();
				var showStmt = _v2.a;
				var uViewFullStmt = _v2.b;
				var uViewFullStmtMsg = _v2.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: showStmt, av: uViewFullStmt}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$PageListReevaluation$ViewFullStmtMsg, uViewFullStmtMsg),
					_List_Nil);
			default:
				var stmtId = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Support(
								$author$project$App$Route$SupportReevaluationDetails(stmtId)))),
					_List_Nil);
		}
	});
var $author$project$App$Page$Support$PageReevaluation$ChatBoxMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportReevaluationMessageSent = 1;
var $author$project$App$Page$ResponseMsgs$SupportReevaluationMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Support$PageReevaluation$update = F3(
	function (msg, st, model) {
		switch (msg.$) {
			case 1:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin($author$project$App$Route$AdminListReevaluation))),
					_List_Nil);
			case 3:
				var emptyMessage = $author$project$App$Data$StatementMessage$emptyModel;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$StatementSendToDoctorReview,
								_Utils_update(
									emptyMessage,
									{jl: '', aV: model.P.g.e.as}),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportReevaluationMsg(1))
									])))
						]));
			case 4:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$StatementFinishReevaluation,
								model.P.g.e,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportReevaluationMsg(1))
									])))
						]));
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: !model.dC}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 5:
				var str = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{eN: str}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 6:
				var idx = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{b5: idx}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 7:
				var time = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 8:
				var docMsg = msg.a;
				var docsUpdated = A2(
					$elm$core$Dict$map,
					F2(
						function (_v1, doc) {
							return A2($author$project$App$Data$Document$updateUrl, docMsg, doc);
						}),
					model.an);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{an: docsUpdated}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 9:
				if (!msg.a.$) {
					var messageText = msg.a.a;
					var emptyMessage = $author$project$App$Data$StatementMessage$emptyModel;
					var newMessage = _Utils_update(
						emptyMessage,
						{jl: messageText, aV: model.eV});
					var _v2 = A2(
						$author$project$App$Components$Chatbox$update,
						$author$project$App$Components$Chatbox$SendMessage(messageText),
						model.aM);
					var chatBoxUpdated = _v2.a;
					var chatCmd = _v2.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aM: chatBoxUpdated}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$PageReevaluation$ChatBoxMsg, chatCmd),
						_List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$SendStatementMessage, newMessage, _List_Nil))
							]));
				} else {
					var chatMsg = msg.a;
					var _v3 = A2($author$project$App$Components$Chatbox$update, chatMsg, model.aM);
					var chatBoxUpdated = _v3.a;
					var chatCmd = _v3.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aM: chatBoxUpdated}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$PageReevaluation$ChatBoxMsg, chatCmd),
						_List_Nil);
				}
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$Page$Support$PageReview$Showing = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$SupportInterviewFullStatementLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportInterviewFullStatementPersisted = {$: 3};
var $author$project$App$Page$ResponseMsgs$SupportInterviewMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportInterviewStatementFromPersonLoaded = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Support$PageReview$calcBMI = F2(
	function (weight, height) {
		var w = weight / 1000;
		var h = height / 100;
		return (!height) ? 0 : (w / (h * h));
	});
var $author$project$App$Page$Support$PageReview$formatBMI = function (bmi) {
	return A3(
		$elm$core$String$slice,
		0,
		5,
		$elm$core$String$fromFloat(bmi));
};
var $author$project$App$Page$Support$PageReview$updateAnswerField = F4(
	function (tId, stmtId, fieldValue, answers) {
		var answer = function () {
			var _v0 = A2($elm$core$Dict$get, tId, answers);
			if (!_v0.$) {
				var actualAnswer = _v0.a;
				return actualAnswer;
			} else {
				var emptyAnswer = $author$project$App$Data$Answer$emptyModel;
				return _Utils_update(
					emptyAnswer,
					{aY: tId, aV: stmtId});
			}
		}();
		var updatedAnswer = A2($author$project$App$Data$Answer$updateModel, fieldValue, answer);
		return A3($elm$core$Dict$insert, tId, updatedAnswer, answers);
	});
var $author$project$App$Page$Support$PageReview$update = F3(
	function (msg, st, model) {
		var stmtId = model.as;
		var fullStmt = model.h8;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A3(
								$author$project$App$SharedState$LoadFullStatementForReview,
								stmtId,
								_List_fromArray(
									[
										$author$project$App$SharedState$FullStmtAssetDocuments,
										$author$project$App$SharedState$FullStmtAssetAnswers(1),
										$author$project$App$SharedState$FullStmtAssetQuestions(1)
									]),
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportInterviewMsg(
											$author$project$App$Page$ResponseMsgs$SupportInterviewFullStatementLoaded(stmtId)))
									]))
							])));
			case 1:
				var personId = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$LoadStatementFromPerson,
								personId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportInterviewMsg(
											$author$project$App$Page$ResponseMsgs$SupportInterviewStatementFromPersonLoaded(personId)))
									])))
						]));
			case 2:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Support(
								$author$project$App$Route$SupportListForReviewRoute(
									$author$project$App$Route$SupportListForReview($author$project$App$Components$Filter$empty))))),
					_List_Nil);
			case 5:
				var statementId = msg.a;
				var filter = msg.b;
				var field = msg.c;
				var stmt = model.h8;
				var bearer = stmt.g;
				var updatedModel = _Utils_update(
					model,
					{
						h8: _Utils_update(
							stmt,
							{
								g: _Utils_update(
									bearer,
									{
										e: A3($author$project$App$Data$Statement$updateModel, model.ey, field, bearer.e)
									})
							})
					});
				return _Utils_Tuple3(updatedModel, $elm$core$Platform$Cmd$none, _List_Nil);
			case 7:
				var depIdx = msg.a;
				var tId = msg.b;
				var statementId = msg.c;
				var answerModel = msg.d;
				var fieldValue = msg.e;
				if (!depIdx.$) {
					var idx = depIdx.a;
					var _v2 = A2($elm$core$Dict$get, idx, fullStmt.fh);
					if (!_v2.$) {
						var dep = _v2.a;
						var updatedAnswer = A2($author$project$App$Data$Answer$updateModel, fieldValue, answerModel);
						var uAns = A2($author$project$App$Data$Answer$updateModel, fieldValue, answerModel);
						var depModel = dep.e;
						var currentQuestion = A2(
							$elm$core$Maybe$withDefault,
							$author$project$App$Data$FullQuestion$emptyModel,
							$elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (q) {
										return A2(
											$elm$core$List$any,
											function (t) {
												return t.ay === 10;
											},
											q.bY);
									},
									$elm$core$List$concat(
										A2(
											$elm$core$List$map,
											function (s) {
												return s.eI;
											},
											st.ia.h8.bs)))));
						var bmiAnswer = function () {
							var answerTemplate = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$AnswerTemplate$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (t) {
											return t.ay === 1000;
										},
										currentQuestion.bY)));
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$Answer$emptyModel,
								A2($elm$core$Dict$get, answerTemplate.as, dep.gw));
						}();
						var _v3 = _Utils_Tuple3(
							(answerModel.ay === 10) || (answerModel.ay === 20),
							function () {
								if (answerModel.ay === 10) {
									return uAns;
								} else {
									var answerTemplate = A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$AnswerTemplate$emptyModel,
										$elm$core$List$head(
											A2(
												$elm$core$List$filter,
												function (t) {
													return t.ay === 10;
												},
												currentQuestion.bY)));
									return A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$Answer$emptyModel,
										A2($elm$core$Dict$get, answerTemplate.as, dep.gw));
								}
							}(),
							function () {
								if (answerModel.ay === 20) {
									return uAns;
								} else {
									var answerTemplate = A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$AnswerTemplate$emptyModel,
										$elm$core$List$head(
											A2(
												$elm$core$List$filter,
												function (t) {
													return t.ay === 20;
												},
												currentQuestion.bY)));
									return A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$Answer$emptyModel,
										A2($elm$core$Dict$get, answerTemplate.as, dep.gw));
								}
							}());
						var updateBMI = _v3.a;
						var weightAnswer = _v3.b;
						var heightAnswer = _v3.c;
						var _v4 = _Utils_Tuple2(
							(answerModel.ay === 20) ? A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(
									A3($elm$core$String$replace, '.', '', heightAnswer.T))) : dep.e.jL,
							(answerModel.ay === 10) ? A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(
									A3($elm$core$String$replace, '.', '', weightAnswer.T))) : dep.e.jN);
						var personHeight = _v4.a;
						var personWeight = _v4.b;
						var currentBMI = updateBMI ? A2($author$project$App$Page$Support$PageReview$calcBMI, personWeight, personHeight) : 111;
						var updatedBMI = A2(
							$author$project$App$Data$Answer$updateModel,
							$author$project$App$Data$Answer$FieldValue(
								$author$project$App$Page$Support$PageReview$formatBMI(currentBMI)),
							bmiAnswer);
						var answers = updateBMI ? A4(
							$author$project$App$Page$Support$PageReview$updateAnswerField,
							updatedBMI.aY,
							statementId,
							$author$project$App$Data$Answer$FieldValue(
								$author$project$App$Page$Support$PageReview$formatBMI(currentBMI)),
							A4($author$project$App$Page$Support$PageReview$updateAnswerField, tId, statementId, fieldValue, dep.gw)) : A4($author$project$App$Page$Support$PageReview$updateAnswerField, tId, statementId, fieldValue, dep.gw);
						var updatedDep = _Utils_update(
							dep,
							{
								gw: answers,
								e: _Utils_update(
									depModel,
									{jL: personHeight, jN: personWeight})
							});
						var sharedMsgs = updateBMI ? _List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$UpdateAnswer, updatedAnswer, _List_Nil)),
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$SaveStatementById, updatedDep.e, _List_Nil)),
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$UpdateAnswer, updatedBMI, _List_Nil))
							]) : _List_fromArray(
							[
								$author$project$App$SharedState$APIMsg(
								A2($author$project$App$SharedState$UpdateAnswer, updatedAnswer, _List_Nil))
							]);
						var updatedFullStmt = _Utils_update(
							fullStmt,
							{
								fh: A3($elm$core$Dict$insert, idx, updatedDep, fullStmt.fh)
							});
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{h8: updatedFullStmt}),
							$elm$core$Platform$Cmd$none,
							sharedMsgs);
					} else {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
					}
				} else {
					var updatedAnswer = A2($author$project$App$Data$Answer$updateModel, fieldValue, answerModel);
					var uAns = A2($author$project$App$Data$Answer$updateModel, fieldValue, answerModel);
					var currentQuestion = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$FullQuestion$emptyModel,
						$elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (q) {
									return A2(
										$elm$core$List$any,
										function (t) {
											return t.ay === 10;
										},
										q.bY);
								},
								$elm$core$List$concat(
									A2(
										$elm$core$List$map,
										function (s) {
											return s.eI;
										},
										st.ia.h8.bs)))));
					var bearer = fullStmt.g;
					var bmiAnswer = function () {
						var answerTemplate = A2(
							$elm$core$Maybe$withDefault,
							$author$project$App$Data$AnswerTemplate$emptyModel,
							$elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (t) {
										return t.ay === 1000;
									},
									currentQuestion.bY)));
						return A2(
							$elm$core$Maybe$withDefault,
							$author$project$App$Data$Answer$emptyModel,
							A2($elm$core$Dict$get, answerTemplate.as, bearer.gw));
					}();
					var bModel = bearer.e;
					var _v5 = _Utils_Tuple3(
						(answerModel.ay === 10) || (answerModel.ay === 20),
						function () {
							if (answerModel.ay === 10) {
								return uAns;
							} else {
								var answerTemplate = A2(
									$elm$core$Maybe$withDefault,
									$author$project$App$Data$AnswerTemplate$emptyModel,
									$elm$core$List$head(
										A2(
											$elm$core$List$filter,
											function (t) {
												return t.ay === 10;
											},
											currentQuestion.bY)));
								return A2(
									$elm$core$Maybe$withDefault,
									$author$project$App$Data$Answer$emptyModel,
									A2($elm$core$Dict$get, answerTemplate.as, bearer.gw));
							}
						}(),
						function () {
							if (answerModel.ay === 20) {
								return uAns;
							} else {
								var answerTemplate = A2(
									$elm$core$Maybe$withDefault,
									$author$project$App$Data$AnswerTemplate$emptyModel,
									$elm$core$List$head(
										A2(
											$elm$core$List$filter,
											function (t) {
												return t.ay === 20;
											},
											currentQuestion.bY)));
								return A2(
									$elm$core$Maybe$withDefault,
									$author$project$App$Data$Answer$emptyModel,
									A2($elm$core$Dict$get, answerTemplate.as, bearer.gw));
							}
						}());
					var updateBMI = _v5.a;
					var weightAnswer = _v5.b;
					var heightAnswer = _v5.c;
					var currentBMI = updateBMI ? A2(
						$author$project$App$Page$Support$PageReview$calcBMI,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(
								A3($elm$core$String$replace, '.', '', weightAnswer.T))),
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(
								A3($elm$core$String$replace, '.', '', heightAnswer.T)))) : 0;
					var updatedBMI = A2(
						$author$project$App$Data$Answer$updateModel,
						$author$project$App$Data$Answer$FieldValue(
							$author$project$App$Page$Support$PageReview$formatBMI(currentBMI)),
						bmiAnswer);
					var answers = updateBMI ? A4(
						$author$project$App$Page$Support$PageReview$updateAnswerField,
						updatedBMI.aY,
						statementId,
						$author$project$App$Data$Answer$FieldValue(
							$author$project$App$Page$Support$PageReview$formatBMI(currentBMI)),
						A4($author$project$App$Page$Support$PageReview$updateAnswerField, tId, statementId, fieldValue, bearer.gw)) : A4($author$project$App$Page$Support$PageReview$updateAnswerField, tId, statementId, fieldValue, bearer.gw);
					var _v6 = _Utils_Tuple2(
						(answerModel.ay === 20) ? A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(
								A3($elm$core$String$replace, '.', '', heightAnswer.T))) : bearer.e.iS,
						(answerModel.ay === 10) ? A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(
								A3($elm$core$String$replace, '.', '', weightAnswer.T))) : bearer.e.iT);
					var companyHeight = _v6.a;
					var companyWeight = _v6.b;
					var updatedBearer = _Utils_update(
						bearer,
						{
							gw: answers,
							e: _Utils_update(
								bModel,
								{iS: companyHeight, iT: companyWeight})
						});
					var sharedMsgs = updateBMI ? _List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2($author$project$App$SharedState$UpdateAnswer, updatedAnswer, _List_Nil)),
							$author$project$App$SharedState$APIMsg(
							A2($author$project$App$SharedState$SaveStatementById, updatedBearer.e, _List_Nil)),
							$author$project$App$SharedState$APIMsg(
							A2($author$project$App$SharedState$UpdateAnswer, updatedBMI, _List_Nil))
						]) : _List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2($author$project$App$SharedState$UpdateAnswer, updatedAnswer, _List_Nil))
						]);
					var updatedFullStmt = _Utils_update(
						fullStmt,
						{g: updatedBearer});
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{h8: updatedFullStmt}),
						$elm$core$Platform$Cmd$none,
						sharedMsgs);
				}
			case 8:
				var depIdx = msg.a;
				var fullQuestion = msg.b;
				var templateId = msg.c;
				if (!depIdx.$) {
					var idx = depIdx.a;
					var _v8 = A2($elm$core$Dict$get, idx, fullStmt.fh);
					if (!_v8.$) {
						var dep = _v8.a;
						var modelStatement = model.h8;
						var _v9 = A3(
							$elm$core$List$foldl,
							F2(
								function (id, _v10) {
									var answers = _v10.a;
									var changed = _v10.b;
									return A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(answers, changed),
										A2(
											$elm$core$Maybe$map,
											function (a) {
												var updatedAnswer = A2(
													$author$project$App$Data$Answer$updateModel,
													$author$project$App$Data$Answer$FieldSelectedBool(false),
													A2(
														$author$project$App$Data$Answer$updateModel,
														$author$project$App$Data$Answer$FieldValue(''),
														a));
												return _Utils_Tuple2(
													A3($elm$core$Dict$insert, id, updatedAnswer, answers),
													A3($elm$core$Dict$insert, a.aY, updatedAnswer, changed));
											},
											A2($elm$core$Dict$get, id, dep.gw)));
								}),
							_Utils_Tuple2(dep.gw, $elm$core$Dict$empty),
							A2(
								$elm$core$List$map,
								function ($) {
									return $.as;
								},
								fullQuestion.bY));
						var clearedAnswers = _v9.a;
						var saveCleared = _v9.b;
						var answersToUpdate = A3(
							$elm$core$Dict$foldl,
							F3(
								function (id, a, changed) {
									return _Utils_eq(id, templateId) ? A3(
										$elm$core$Dict$insert,
										templateId,
										A2(
											$author$project$App$Data$Answer$updateModel,
											$author$project$App$Data$Answer$FieldSelectedBool(true),
											a),
										changed) : changed;
								}),
							saveCleared,
							clearedAnswers);
						var answersFinal = A2(
							A3(
								$elm$core$Basics$composeR,
								$elm$core$Dict$fromList,
								A3(
									$elm$core$Dict$merge,
									$elm$core$Dict$insert,
									F4(
										function (_v13, b, _v14, otherDict) {
											return A3($elm$core$Dict$insert, b.aY, b, otherDict);
										}),
									$elm$core$Dict$insert),
								A2(
									$elm$core$List$map,
									function (_v12) {
										var ans = _v12.b;
										return _Utils_Tuple2(ans.aY, ans);
									},
									$elm$core$Dict$toList(answersToUpdate))),
							dep.gw,
							$elm$core$Dict$empty);
						var newDeps = A3(
							$elm$core$Dict$insert,
							idx,
							_Utils_update(
								dep,
								{gw: answersFinal}),
							fullStmt.fh);
						var updateAnswersCmd = A2(
							$elm$core$List$map,
							$author$project$App$SharedState$APIMsg,
							A2(
								$elm$core$List$map,
								function (_v11) {
									var ans = _v11.b;
									return A2($author$project$App$SharedState$UpdateAnswer, ans, _List_Nil);
								},
								$elm$core$Dict$toList(answersToUpdate)));
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									h8: _Utils_update(
										modelStatement,
										{fh: newDeps})
								}),
							$elm$core$Platform$Cmd$none,
							updateAnswersCmd);
					} else {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
					}
				} else {
					var stmt = model.h8.g;
					var modelStatement = model.h8;
					var _v15 = A3(
						$elm$core$List$foldl,
						F2(
							function (id, _v16) {
								var answers = _v16.a;
								var changed = _v16.b;
								return A2(
									$elm$core$Maybe$withDefault,
									_Utils_Tuple2(answers, changed),
									A2(
										$elm$core$Maybe$map,
										function (a) {
											var updatedAnswer = A2(
												$author$project$App$Data$Answer$updateModel,
												$author$project$App$Data$Answer$FieldSelectedBool(false),
												A2(
													$author$project$App$Data$Answer$updateModel,
													$author$project$App$Data$Answer$FieldValue(''),
													a));
											return _Utils_Tuple2(
												A3($elm$core$Dict$insert, id, updatedAnswer, answers),
												A3($elm$core$Dict$insert, a.aY, updatedAnswer, changed));
										},
										A2($elm$core$Dict$get, id, stmt.gw)));
							}),
						_Utils_Tuple2(stmt.gw, $elm$core$Dict$empty),
						A2(
							$elm$core$List$map,
							function ($) {
								return $.as;
							},
							fullQuestion.bY));
					var clearedAnswers = _v15.a;
					var saveCleared = _v15.b;
					var answersToUpdate = A3(
						$elm$core$Dict$foldl,
						F3(
							function (id, a, changed) {
								return _Utils_eq(id, templateId) ? A3(
									$elm$core$Dict$insert,
									templateId,
									A2(
										$author$project$App$Data$Answer$updateModel,
										$author$project$App$Data$Answer$FieldSelectedBool(true),
										a),
									changed) : changed;
							}),
						saveCleared,
						clearedAnswers);
					var answersFinal = A2(
						A3(
							$elm$core$Basics$composeR,
							$elm$core$Dict$fromList,
							A3(
								$elm$core$Dict$merge,
								$elm$core$Dict$insert,
								F4(
									function (_v19, b, _v20, otherDict) {
										return A3($elm$core$Dict$insert, b.aY, b, otherDict);
									}),
								$elm$core$Dict$insert),
							A2(
								$elm$core$List$map,
								function (_v18) {
									var ans = _v18.b;
									return _Utils_Tuple2(ans.aY, ans);
								},
								$elm$core$Dict$toList(answersToUpdate))),
						stmt.gw,
						$elm$core$Dict$empty);
					var updateAnswersCmd = A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						A2(
							$elm$core$List$map,
							function (_v17) {
								var ans = _v17.b;
								return A2($author$project$App$SharedState$UpdateAnswer, ans, _List_Nil);
							},
							$elm$core$Dict$toList(answersToUpdate)));
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								h8: _Utils_update(
									modelStatement,
									{
										g: _Utils_update(
											stmt,
											{gw: answersFinal})
									})
							}),
						$elm$core$Platform$Cmd$none,
						updateAnswersCmd);
				}
			case 9:
				var statementId = msg.a;
				var statement = model.h8.g.e;
				var newStatement = _Utils_update(
					statement,
					{aw: 2});
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$SaveStatementById,
								newStatement,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportInterviewMsg($author$project$App$Page$ResponseMsgs$SupportInterviewFullStatementPersisted))
									])))
						]));
			case 10:
				var viewMsg = msg.a;
				var _v21 = model.gD;
				if (_v21.$ === 1) {
					var statementId = _v21.a;
					var viewModel = _v21.b;
					var _v22 = A4($author$project$App$Components$ViewFullStatement$update, viewMsg, st.ia.h8, st, viewModel);
					var uViewModel = _v22.a;
					var viewCmd = _v22.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Support$PageReview$Showing, statementId, uViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$PageReview$ViewFullStmtMsg, viewCmd),
						_List_Nil);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 11:
				var time = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ey: time}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 3:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: true}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 4:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dC: false}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			default:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					_List_fromArray(
						[
							$author$project$App$SharedState$APIMsg(
							A2(
								$author$project$App$SharedState$ReturnToCompany,
								model.h8.g.e,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportInterviewMsg($author$project$App$Page$ResponseMsgs$SupportInterviewFullStatementPersisted))
									])))
						]));
		}
	});
var $author$project$App$Page$PageSupport$update = F3(
	function (msg, st, model) {
		var _v0 = _Utils_Tuple2(msg, model);
		switch (_v0.a.$) {
			case 0:
				if (!_v0.b.$) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v1 = A3($author$project$App$Page$Support$PageListIncomplete$update, pageMsg, st, pageModel);
					var nPageModel = _v1.a;
					var nPageMsg = _v1.b;
					var stMsgs = _v1.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$ListIncomplete(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListIncompleteMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 1:
				if (_v0.b.$ === 1) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v2 = A3($author$project$App$Page$Support$PageListForReview$update, pageMsg, st, pageModel);
					var nPageModel = _v2.a;
					var nPageMsg = _v2.b;
					var stMsgs = _v2.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$ListForReview(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListForReviewMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 2:
				if (_v0.b.$ === 2) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v3 = A3($author$project$App$Page$Support$PageListApproved$update, pageMsg, st, pageModel);
					var nPageModel = _v3.a;
					var nPageMsg = _v3.b;
					var stMsgs = _v3.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$ListApproved(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListApprovedMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 3:
				if (_v0.b.$ === 3) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v4 = A3($author$project$App$Page$Support$PageReview$update, pageMsg, st, pageModel);
					var nPageModel = _v4.a;
					var nPageMsg = _v4.b;
					var stMsgs = _v4.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$ReviewInterview(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ReviewInterviewMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 4:
				if (_v0.b.$ === 4) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v5 = A3($author$project$App$Page$Support$EditStatement$StatementEdit$update, pageMsg, st, pageModel);
					var nPageModel = _v5.a;
					var nPageMsg = _v5.b;
					var stMsgs = _v5.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$EditStatement(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$EditStatementMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 5:
				if (_v0.b.$ === 6) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v6 = A3($author$project$App$Page$Support$PageListReevaluation$update, pageMsg, st, pageModel);
					var nPageModel = _v6.a;
					var nPageMsg = _v6.b;
					var stMsgs = _v6.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$ListReevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListReevaluationMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			default:
				if (_v0.b.$ === 5) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v7 = A3($author$project$App$Page$Support$PageReevaluation$update, pageMsg, st, pageModel);
					var nPageModel = _v7.a;
					var nPageMsg = _v7.b;
					var stMsgs = _v7.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$Reevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ReevaluationMsg, nPageMsg),
						stMsgs);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
		}
	});
var $author$project$App$Page$PageUserRecoverPassword$Error = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$PageUserRecoverPassword$GotResetPassword = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$PageUserRecoverPassword$Success = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$expectString = function (toMsg) {
	return A2(
		$elm$http$Http$expectStringResponse,
		toMsg,
		$elm$http$Http$resolve($elm$core$Result$Ok));
};
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$App$Service$ApiRest$post = function (options) {
	return $author$project$App$Service$ApiRest$request(
		{e5: options.e5, a1: options.a1, z: 'POST', e$: options.e$, f3: options.f3});
};
var $author$project$App$Service$RequestResetPasswordRest$resetPassword = F3(
	function (user, token, expect) {
		var data = token;
		return $author$project$App$Service$ApiRest$post(
			{
				e5: $elm$http$Http$jsonBody(
					$author$project$App$Data$User$encoder(user)),
				a1: $elm$http$Http$expectString(expect),
				e$: $elm$core$Maybe$Nothing,
				f3: 'userService.password.' + (data + '.reset')
			});
	});
var $author$project$App$Page$PageUserRecoverPassword$update = F3(
	function (msg, sharedState, model) {
		switch (msg.$) {
			case 0:
				var field = msg.a;
				var user = A2($author$project$App$Data$User$updateModel, field, model.bS);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bS: user}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 1:
				return _Utils_Tuple3(
					model,
					A3($author$project$App$Service$RequestResetPasswordRest$resetPassword, model.bS, model.dI, $author$project$App$Page$PageUserRecoverPassword$GotResetPassword),
					_List_Nil);
			case 2:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Public($author$project$App$Route$Login)),
					_List_Nil);
			default:
				if (!msg.a.$) {
					var str = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aw: $author$project$App$Page$PageUserRecoverPassword$Success('Senha atualizada')
							}),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aw: $author$project$App$Page$PageUserRecoverPassword$Error('Falha ao atualizar senha')
							}),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				}
		}
	});
var $author$project$App$Page$PageUserRequestRecoverPassword$Error = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$PageUserRequestRecoverPassword$GotRequestResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$PageUserRequestRecoverPassword$Success = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Data$Token$PasswordRecoverToken = F2(
	function (email, session) {
		return {c7: email, dI: session};
	});
var $author$project$App$Data$Token$passwordRecoverTokendecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'session',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'email',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$App$Data$Token$PasswordRecoverToken)));
var $author$project$App$Service$RequestResetPasswordRest$requestResetPasswordEncoder = function (_v0) {
	var user = _v0.bS;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(user.c7))
			]));
};
var $author$project$App$Service$RequestResetPasswordRest$requestResetPassword = F2(
	function (model, expect) {
		var data = $truqu$elm_base64$Base64$encode(model.bS.c7);
		return $author$project$App$Service$ApiRest$post(
			{
				e5: $elm$http$Http$jsonBody(
					$author$project$App$Service$RequestResetPasswordRest$requestResetPasswordEncoder(model)),
				a1: A2($elm$http$Http$expectJson, expect, $author$project$App$Data$Token$passwordRecoverTokendecoder),
				e$: $elm$core$Maybe$Nothing,
				f3: 'userService.password.' + (data + '.request')
			});
	});
var $author$project$App$Page$PageUserRequestRecoverPassword$update = F3(
	function (msg, sharedState, model) {
		switch (msg.$) {
			case 0:
				var field = msg.a;
				var user = A2($author$project$App$Data$User$updateModel, field, model.bS);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bS: user}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 1:
				return _Utils_Tuple3(
					model,
					A2($author$project$App$Service$RequestResetPasswordRest$requestResetPassword, model, $author$project$App$Page$PageUserRequestRecoverPassword$GotRequestResponse),
					_List_Nil);
			case 2:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Public($author$project$App$Route$Login)),
					_List_Nil);
			default:
				if (!msg.a.$) {
					var user = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aw: $author$project$App$Page$PageUserRequestRecoverPassword$Success('Email enviado!')
							}),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				} else {
					var err = msg.a.a;
					var message = function () {
						_v1$3:
						while (true) {
							if (err.$ === 3) {
								switch (err.a) {
									case 404:
										return 'E-mail não encontrado';
									case 400:
										return 'Mensagem já enviada. Aguarde 30 minutos ou entre em contato com o suporte';
									case 500:
										return 'Erro desconhecido, entre em contato com o suporte';
									default:
										break _v1$3;
								}
							} else {
								break _v1$3;
							}
						}
						return 'Erro desconhecido, entre em contato com o suporte';
					}();
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aw: $author$project$App$Page$PageUserRequestRecoverPassword$Error(message)
							}),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				}
		}
	});
var $author$project$App$Page$PageUserSignUp$Error = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$PageUserSignUp$GotSignedUp = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Data$User$Model = F9(
	function (id, email, password, passwordCheck, passwordOld, personId, companyId, activatedAt, createdAt) {
		return {e3: activatedAt, gx: companyId, iW: createdAt, c7: email, as: id, bf: password, dv: passwordCheck, eE: passwordOld, Y: personId};
	});
var $author$project$App$Data$User$decoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'createdAt',
	$elm$json$Json$Decode$maybe($author$project$App$Data$Id$timeDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'activatedAt',
		$elm$json$Json$Decode$maybe($author$project$App$Data$Id$timeDecoder),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'companyId',
			$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'personId',
				$author$project$App$Data$Id$uuidDecoder,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'passwordOld',
					$elm$json$Json$Decode$string,
					'',
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'passwordCheck',
						$elm$json$Json$Decode$string,
						'',
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'password',
							$elm$json$Json$Decode$string,
							'',
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'email',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'id',
									$author$project$App$Data$Id$uuidDecoder,
									$elm$json$Json$Decode$succeed($author$project$App$Data$User$Model))))))))));
var $author$project$App$Service$SignUpRest$signUpUserEncoder = function (_v0) {
	var user = _v0.bS;
	var person = _v0.dw;
	var phone = _v0.dx;
	var role = _v0.hS;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(user.c7)),
				_Utils_Tuple2(
				'companyId',
				$author$project$App$Data$Id$uuidMaybeEncoder(user.gx)),
				_Utils_Tuple2(
				'password',
				$elm$json$Json$Encode$string(user.bf)),
				_Utils_Tuple2(
				'passwordCheck',
				$elm$json$Json$Encode$string(user.dv)),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(person.hl)),
				_Utils_Tuple2(
				'surname',
				$elm$json$Json$Encode$string(person.ii)),
				_Utils_Tuple2(
				'document',
				$elm$json$Json$Encode$string(person.i1)),
				_Utils_Tuple2(
				'sex',
				$author$project$App$Data$Person$sexEncoder(person.dJ)),
				_Utils_Tuple2(
				'birthDate',
				$author$project$App$Data$Id$timeEncoder(person.al)),
				_Utils_Tuple2(
				'phone',
				$elm$json$Json$Encode$string(phone.T)),
				_Utils_Tuple2(
				'role',
				$elm$json$Json$Encode$string(
					$author$project$App$Data$Profile$codeToString(role)))
			]));
};
var $author$project$App$Service$SignUpRest$signUpUser = F2(
	function (user, expect) {
		return $author$project$App$Service$ApiRest$post(
			{
				e5: $elm$http$Http$jsonBody(
					$author$project$App$Service$SignUpRest$signUpUserEncoder(user)),
				a1: A2($elm$http$Http$expectJson, expect, $author$project$App$Data$User$decoder),
				e$: $elm$core$Maybe$Nothing,
				f3: 'userService.users.new'
			});
	});
var $author$project$App$Page$PageUserSignUp$update = F3(
	function (msg, sharedState, model) {
		switch (msg.$) {
			case 0:
				var field = msg.a;
				var person = A2($author$project$App$Data$Person$updateModel, field, model.dw);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dw: person}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 1:
				var field = msg.a;
				var user = A2($author$project$App$Data$User$updateModel, field, model.bS);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bS: user}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 2:
				var field = msg.a;
				var phone = A2($author$project$App$Data$Contact$updateModel, field, model.dx);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dx: phone}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 3:
				return _Utils_Tuple3(
					model,
					A2($author$project$App$Service$SignUpRest$signUpUser, model, $author$project$App$Page$PageUserSignUp$GotSignedUp),
					_List_Nil);
			case 4:
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						sharedState.fC,
						$author$project$App$Route$Public($author$project$App$Route$Login)),
					_List_Nil);
			case 5:
				if (!msg.a.$) {
					var companies = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{bq: companies}),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			default:
				if (!msg.a.$) {
					var user = msg.a.a;
					return _Utils_Tuple3(
						model,
						A2(
							$author$project$App$Route$pushUrl,
							sharedState.fC,
							$author$project$App$Route$Public($author$project$App$Route$UserSignUpComplete)),
						_List_Nil);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aw: $author$project$App$Page$PageUserSignUp$Error('Falha ao criar usuário')
							}),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				}
		}
	});
var $author$project$App$Page$PageUserSignUpComplete$update = F3(
	function (msg, st, model) {
		return _Utils_Tuple3(
			model,
			A2(
				$author$project$App$Route$pushUrl,
				st.fC,
				$author$project$App$Route$Public($author$project$App$Route$Login)),
			_List_Nil);
	});
var $author$project$App$Page$ResponseMsgs$SharedGetUpdatePasswordResult = {$: 19};
var $author$project$App$SharedState$StatementSetConference = F4(
	function (a, b, c, d) {
		return {$: 78, a: a, b: b, c: c, d: d};
	});
var $author$project$App$SharedState$UpdatePassword = F2(
	function (a, b) {
		return {$: 114, a: a, b: b};
	});
var $author$project$App$Components$Video$getRecorder = function (model) {
	return model.bJ;
};
var $author$project$App$Components$Video$Compatible = {$: 0};
var $author$project$App$Components$Video$DevicesDetected = {$: 1};
var $author$project$App$Components$Video$Incompatible = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Components$Video$InitFailed = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$App$Components$Video$Running = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$App$Components$Video$Stopped = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Components$Video$VideoQuality = $elm$core$Basics$identity;
var $author$project$App$Components$Video$qualityValue = function (spec) {
	var value = spec;
	return value;
};
var $author$project$App$Components$Video$videoQualityLow = 125;
var $author$project$App$Components$Video$getVideoQuality = F2(
	function (device, model) {
		return $author$project$App$Components$Video$qualityValue(
			A2(
				$elm$core$Maybe$withDefault,
				$author$project$App$Components$Video$videoQualityLow,
				A2($elm$core$Dict$get, device, model.dp)));
	});
var $author$project$App$Components$Video$isDeviceMobile = function (device) {
	return A2(
		$elm$core$List$member,
		device.dO,
		_List_fromArray(
			['Android', 'iOS']));
};
var $author$project$App$Components$Video$SwitchingTo = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$App$Components$Video$updateStateWithRemote = F2(
	function (remote, state) {
		switch (state.$) {
			case 2:
				var device = state.a;
				return A2($author$project$App$Components$Video$Running, device, remote);
			case 3:
				var device = state.a;
				return A2($author$project$App$Components$Video$SwitchingTo, device, remote);
			default:
				return state;
		}
	});
var $author$project$App$Components$Video$videoDiscardSelfie = _Platform_outgoingPort(
	'videoDiscardSelfie',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$App$Components$Video$videoInit = _Platform_outgoingPort('videoInit', $elm$json$Json$Encode$string);
var $author$project$App$Components$Video$videoTakeSelfie = _Platform_outgoingPort(
	'videoTakeSelfie',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$App$Components$Video$updateViewMsg = F2(
	function (viewMsg, model) {
		switch (viewMsg.$) {
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cC: false}),
					$author$project$App$Components$Video$videoTakeSelfie(0),
					_List_Nil);
			case 3:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cC: false, bj: ''}),
					$author$project$App$Components$Video$videoDiscardSelfie(0),
					_List_Nil);
			case 4:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cC: true, dH: _List_Nil}),
					$author$project$App$Components$Video$videoStop(0),
					A2(
						$elm$core$List$map,
						function (selfieResponse) {
							return selfieResponse(model.bj);
						},
						model.dH));
			case 2:
				var device = viewMsg.a;
				var remote = viewMsg.b;
				return _Utils_eq(
					model.p,
					A2($author$project$App$Components$Video$SwitchingTo, device, remote)) ? _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil) : _Utils_Tuple3(
					_Utils_update(
						model,
						{
							p: A2($author$project$App$Components$Video$SwitchingTo, device, remote)
						}),
					$author$project$App$Components$Video$videoInit(device),
					_List_Nil);
			default:
				return _Utils_Tuple3(
					model,
					$author$project$App$Components$Video$videoStop(0),
					_List_Nil);
		}
	});
var $author$project$App$Components$Video$videoPlay = _Platform_outgoingPort(
	'videoPlay',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'room',
					$elm$json$Json$Encode$string($.eO)),
					_Utils_Tuple2(
					'signaler',
					$elm$json$Json$Encode$string($.dM)),
					_Utils_Tuple2(
					'stun',
					$elm$json$Json$Encode$list(
						function ($) {
							return $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'credential',
										$elm$json$Json$Encode$string($.c$)),
										_Utils_Tuple2(
										'urls',
										$elm$json$Json$Encode$list($elm$json$Json$Encode$string)($.dX)),
										_Utils_Tuple2(
										'username',
										$elm$json$Json$Encode$string($.dY))
									]));
						})($.dN)),
					_Utils_Tuple2(
					'turn',
					$elm$json$Json$Encode$list(
						function ($) {
							return $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'credential',
										$elm$json$Json$Encode$string($.c$)),
										_Utils_Tuple2(
										'urls',
										$elm$json$Json$Encode$list($elm$json$Json$Encode$string)($.dX)),
										_Utils_Tuple2(
										'username',
										$elm$json$Json$Encode$string($.dY))
									]));
						})($.dT))
				]));
	});
var $author$project$App$Components$Video$videoRemotePlay = _Platform_outgoingPort(
	'videoRemotePlay',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string($.as)),
					_Utils_Tuple2(
					'quality',
					$elm$json$Json$Encode$int($.eH))
				]));
	});
var $author$project$App$Components$Video$videoRemoteSetup = _Platform_outgoingPort('videoRemoteSetup', $elm$json$Json$Encode$string);
var $author$project$App$Components$Video$videoRemoteStop = _Platform_outgoingPort('videoRemoteStop', $elm$json$Json$Encode$string);
var $author$project$App$Components$Video$videoStatus = _Platform_outgoingPort('videoStatus', $elm$json$Json$Encode$string);
var $author$project$App$Components$Video$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var device = msg.a;
				if (device.fY) {
					var _v1 = $elm$core$List$head(device.a9);
					if (!_v1.$) {
						var mediaDevice = _v1.a;
						var newModel = _Utils_update(
							model,
							{bt: device, p: $author$project$App$Components$Video$DevicesDetected});
						var deviceId = $author$project$App$Components$Video$isDeviceMobile(device) ? 'user' : mediaDevice.c1;
						return A2(
							$author$project$App$Components$Video$updateViewMsg,
							A2($author$project$App$Components$Video$SwitchCamera, deviceId, $elm$core$Maybe$Nothing),
							newModel);
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									p: A2($author$project$App$Components$Video$InitFailed, 'No devices available', $elm$core$Maybe$Nothing)
								}),
							$elm$core$Platform$Cmd$none,
							_List_Nil);
					}
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								p: A2(
									$author$project$App$Components$Video$InitFailed,
									'NotSupported',
									$elm$core$Maybe$Just(device))
							}),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				}
			case 8:
				var device = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							p: A2($author$project$App$Components$Video$Running, device, $elm$core$Maybe$Nothing)
						}),
					$author$project$App$Components$Video$videoPlay(model.eT),
					_List_Nil);
			case 1:
				var device = msg.a;
				return A2($elm$core$String$contains, 'recorder', device) ? _Utils_Tuple3(
					_Utils_update(
						model,
						{bJ: true}),
					$elm$core$Platform$Cmd$none,
					_List_Nil) : _Utils_Tuple3(
					_Utils_update(
						model,
						{
							dp: A3($elm$core$Dict$insert, device, $author$project$App$Components$Video$videoQualityLow, model.dp),
							cy: A3($elm$core$Dict$insert, device, device, model.cy),
							p: A2(
								$author$project$App$Components$Video$updateStateWithRemote,
								$elm$core$Maybe$Just(device),
								model.p)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$Components$Video$videoRemoteSetup(device),
								$author$project$App$Components$Video$videoStatus(device)
							])),
					_List_Nil);
			case 2:
				var device = msg.a;
				return A2($elm$core$String$contains, 'recorder', device) ? _Utils_Tuple3(
					_Utils_update(
						model,
						{bJ: false}),
					$elm$core$Platform$Cmd$none,
					_List_Nil) : _Utils_Tuple3(
					_Utils_update(
						model,
						{
							cy: A2($elm$core$Dict$remove, device, model.cy),
							p: A2($author$project$App$Components$Video$updateStateWithRemote, $elm$core$Maybe$Nothing, model.p)
						}),
					$author$project$App$Components$Video$videoRemoteStop(device),
					_List_Nil);
			case 3:
				var device = msg.a;
				return _Utils_Tuple3(
					model,
					$author$project$App$Components$Video$videoRemotePlay(
						{
							as: device,
							eH: A2($author$project$App$Components$Video$getVideoQuality, device, model)
						}),
					_List_Nil);
			case 4:
				var device = msg.a;
				return _Utils_Tuple3(
					model,
					$author$project$App$Components$Video$videoStatus(device),
					_List_Nil);
			case 5:
				var mediaId = msg.a;
				var status = msg.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aw: A3($elm$core$Dict$insert, mediaId, status, model.aw)
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 6:
				var mediaId = msg.a;
				var status = msg.b;
				var updatedRecorder = function () {
					switch (status) {
						case 3:
							return A2($elm$core$String$contains, 'recorder', mediaId) ? _Utils_update(
								model,
								{
									bJ: true,
									dA: $elm$core$Maybe$Just(true)
								}) : model;
						case 5:
							return A2($elm$core$String$contains, 'recorder', mediaId) ? _Utils_update(
								model,
								{
									bJ: false,
									dA: $elm$core$Maybe$Just(false)
								}) : model;
						default:
							return model;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						updatedRecorder,
						{
							dq: A3($elm$core$Dict$insert, mediaId, status, model.dq)
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 7:
				var content = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bj: content}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 9:
				var errorName = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							p: A2($author$project$App$Components$Video$InitFailed, errorName, $elm$core$Maybe$Nothing)
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 10:
				var _v3 = model.p;
				switch (_v3.$) {
					case 3:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
					case 4:
						var str = _v3.a;
						return _Utils_Tuple3(
							_Utils_update(
								$author$project$App$Components$Video$emptyModel,
								{
									p: $author$project$App$Components$Video$Stopped(
										$author$project$App$Components$Video$Incompatible(str))
								}),
							$elm$core$Platform$Cmd$none,
							_List_Nil);
					default:
						return _Utils_Tuple3(
							_Utils_update(
								$author$project$App$Components$Video$emptyModel,
								{
									p: $author$project$App$Components$Video$Stopped($author$project$App$Components$Video$Compatible)
								}),
							$elm$core$Platform$Cmd$none,
							_List_Nil);
				}
			default:
				var viewMsg = msg.a;
				return A2($author$project$App$Components$Video$updateViewMsg, viewMsg, model);
		}
	});
var $author$project$App$Page$Menu$menuToRoute = function (model) {
	switch (model.$) {
		case 1:
			switch (model.a) {
				case 0:
					var _v1 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Doctor($author$project$App$Route$DoctorWaitingLine));
				case 1:
					var _v2 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Doctor(
							$author$project$App$Route$DoctorListPending($author$project$App$Components$Filter$empty)));
				case 3:
					var _v3 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Doctor(
							$author$project$App$Route$DoctorListFinishedRoute(
								$author$project$App$Route$DoctorListFinished($author$project$App$Components$Filter$empty))));
				case 2:
					var _v4 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Doctor($author$project$App$Route$DoctorListReevaluation));
				default:
					var _v5 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Doctor(
							$author$project$App$Route$DoctorSalesRoute(0)));
			}
		case 2:
			switch (model.a) {
				case 0:
					var _v6 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Company(
							$author$project$App$Route$CompanyListPendingRoute(
								$author$project$App$Route$CompanyListPending($author$project$App$Components$Filter$empty))));
				case 1:
					var _v7 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Company(
							$author$project$App$Route$CompanyListCanceledRoute(
								$author$project$App$Route$CompanyListCanceled($author$project$App$Components$Filter$empty))));
				case 2:
					var _v8 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Company(
							$author$project$App$Route$CompanyListConcludedRoute(
								$author$project$App$Route$CompanyListConcluded($author$project$App$Components$Filter$empty))));
				default:
					var _v9 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Company($author$project$App$Route$CompanyListReevaluation));
			}
		case 3:
			switch (model.a) {
				case 0:
					var _v10 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Review(
							$author$project$App$Route$ReviewListForReviewRoute(
								$author$project$App$Route$ReviewListForReview($author$project$App$Components$Filter$empty))));
				case 2:
					var _v11 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Review(
							$author$project$App$Route$ReviewListApprovedRoute(
								$author$project$App$Route$ReviewListApproved($author$project$App$Components$Filter$empty))));
				case 1:
					var _v12 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Review(
							$author$project$App$Route$ReviewListIncompleteRoute(
								$author$project$App$Route$ReviewListIncomplete($author$project$App$Components$Filter$empty))));
				default:
					var _v13 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Review($author$project$App$Route$ReviewListReevaluation));
			}
		case 4:
			switch (model.a) {
				case 0:
					var _v14 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Admin(
							$author$project$App$Route$AdminFinanceSalesRoute(0)));
				case 1:
					var _v15 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Admin(
							$author$project$App$Route$AdminListUsersRoute(0)));
				case 2:
					var _v16 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Admin(
							$author$project$App$Route$AdminListFinishedRoute(
								$author$project$App$Route$AdminListFinished($author$project$App$Components$Filter$empty))));
				default:
					var _v17 = model.a;
					return $author$project$App$Route$Private(
						$author$project$App$Route$Admin($author$project$App$Route$AdminListReevaluation));
			}
		default:
			return $author$project$App$Route$Public($author$project$App$Route$Login);
	}
};
var $author$project$App$Page$Menu$update = F3(
	function (key, _v0, model) {
		var menu = _v0;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{ec: menu}),
			A2(
				$author$project$App$Route$pushUrl,
				key,
				$author$project$App$Page$Menu$menuToRoute(menu)));
	});
var $author$project$App$Service$ApiWebSocket$Authenticated = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$App$Service$ApiWebSocket$Renewing = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Service$Shared$Version = 0;
var $author$project$App$Service$ApiWebSocket$versionMethod = A3(
	$author$project$App$Service$Shared$Method,
	0,
	$author$project$App$Service$Shared$ridFromString(''),
	'');
var $author$project$App$Service$ApiWebSocket$getVersion = function (c) {
	return A2(
		$elm$core$Dict$get,
		$author$project$App$Service$Shared$methodToString($author$project$App$Service$ApiWebSocket$versionMethod),
		c);
};
var $author$project$App$Constants$keepAliveInterval = 8000;
var $author$project$App$Service$SpecificResources$TypeVersion = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Service$ApiWebSocket$requestVersion = F2(
	function (m, v) {
		return A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			false,
			$author$project$App$Service$SpecificResources$TypeVersion(
				$author$project$App$Service$ApiWebSocket$getTimestamp(m)),
			$author$project$App$Service$ApiWebSocket$versionMethod,
			$elm$json$Json$Encode$null,
			_List_Nil,
			m);
	});
var $author$project$App$Service$ApiWebSocket$keepAlive = function (_v0) {
	var id = _v0.a;
	var now = _v0.b;
	var smo = _v0.c;
	var sme = _v0.d;
	var ws = _v0.e;
	var sc = _v0.f;
	var cm = _v0.g;
	var c = _v0.h;
	var _v1 = _Utils_Tuple2(
		$author$project$App$Service$Websocket$isConnected(ws),
		$author$project$App$Service$ApiWebSocket$getVersion(c));
	_v1$2:
	while (true) {
		if (_v1.a) {
			if (!_v1.b.$) {
				if ((_v1.b.a.$ === 3) && (_v1.b.a.c.$ === 1)) {
					var _v2 = _v1.b.a;
					var _v3 = _v2.c;
					var time = _v3.a;
					return (_Utils_cmp(
						$elm$time$Time$posixToMillis(now) - $elm$time$Time$posixToMillis(time),
						$author$project$App$Constants$keepAliveInterval) > 0) ? $author$project$App$Service$ApiWebSocket$requestVersion(
						A2(
							$author$project$App$Service$ApiWebSocket$clearMethod,
							$author$project$App$Service$ApiWebSocket$versionMethod,
							A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, c)))(
						$elm$core$String$fromInt(
							$elm$time$Time$posixToMillis(now))) : ((!$elm$time$Time$posixToMillis(time)) ? $author$project$App$Service$ApiWebSocket$requestVersion(
						A2(
							$author$project$App$Service$ApiWebSocket$clearMethod,
							$author$project$App$Service$ApiWebSocket$versionMethod,
							A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, c)))(
						$elm$core$String$fromInt(
							$elm$time$Time$posixToMillis(now))) : _Utils_Tuple2(
						A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, c),
						$elm$core$Platform$Cmd$none));
				} else {
					break _v1$2;
				}
			} else {
				var _v4 = _v1.b;
				return $author$project$App$Service$ApiWebSocket$requestVersion(
					A2(
						$author$project$App$Service$ApiWebSocket$clearMethod,
						$author$project$App$Service$ApiWebSocket$versionMethod,
						A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, c)))(
					$elm$core$String$fromInt(
						$elm$time$Time$posixToMillis(now)));
			}
		} else {
			break _v1$2;
		}
	}
	return _Utils_Tuple2(
		A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, ws, sc, cm, c),
		$elm$core$Platform$Cmd$none);
};
var $author$project$App$Service$ApiWebSocket$UnknownResponse = {$: 0};
var $author$project$App$Service$Websocket$getData = function (m) {
	var a = m.a;
	var b = m.b;
	var c = m.c;
	var d = m.d;
	return _Utils_Tuple2(
		A4($author$project$App$Service$Websocket$Cache, a, b, c, _List_Nil),
		d);
};
var $author$project$App$Service$SpecificResources$Collection = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$App$Service$Shared$EventAdd = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$App$Service$Shared$EventChange = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Service$SpecificResources$UnknownResource = {$: 0};
var $author$project$App$Service$SpecificResources$Answer = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Service$SpecificResources$Doctor = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Service$SpecificResources$ICD = function (a) {
	return {$: 23, a: a};
};
var $author$project$App$Service$SpecificResources$Person = function (a) {
	return {$: 13, a: a};
};
var $author$project$App$Service$SpecificResources$Statement = function (a) {
	return {$: 16, a: a};
};
var $author$project$App$Service$SpecificResources$StatementMessage = function (a) {
	return {$: 20, a: a};
};
var $author$project$App$Service$SpecificResources$WaitingPerson = function (a) {
	return {$: 14, a: a};
};
var $author$project$App$Data$Answer$Model = function (id) {
	return function (questionSectionId) {
		return function (questionId) {
			return function (statementId) {
				return function (answerTemplateId) {
					return function (answered) {
						return function (value) {
							return function (category) {
								return function (selected) {
									return function (description) {
										return function (observation) {
											return function (businessValue) {
												return function (nextSectionId) {
													return function (updatedAt) {
														return function (tableRows) {
															return function (index) {
																return {aY: answerTemplateId, d3: answered, ay: businessValue, a0: category, am: description, as: id, bB: index, cr: nextSectionId, jx: observation, bh: questionId, aS: questionSectionId, dF: selected, aV: statementId, G: tableRows, f2: updatedAt, T: value};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$QuestionSection$categoryFromString = function (cat) {
	switch (cat) {
		case 'company':
			return 1;
		case 'person':
			return 2;
		case 'doctor':
			return 3;
		default:
			return 0;
	}
};
var $author$project$App$Data$QuestionSection$categoryDecoder = A2($elm$json$Json$Decode$map, $author$project$App$Data$QuestionSection$categoryFromString, $elm$json$Json$Decode$string);
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $author$project$App$Data$Answer$tableValueDecoder = A2(
	$elm$json$Json$Decode$map,
	function (dict) {
		return $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (_v0) {
					var i = _v0.a;
					var v = _v0.b;
					return _Utils_Tuple2(
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(i)),
						v);
				},
				$elm$core$Dict$toList(dict)));
	},
	$elm$json$Json$Decode$dict(
		$elm$json$Json$Decode$dict($elm$json$Json$Decode$string)));
var $author$project$App$Data$Answer$decoder = A2(
	$elm$json$Json$Decode$map,
	function (model) {
		var _v0 = A2($elm$json$Json$Decode$decodeString, $author$project$App$Data$Answer$tableValueDecoder, model.T);
		if (!_v0.$) {
			var tableRows = _v0.a;
			return _Utils_update(
				model,
				{
					G: $elm$core$Maybe$Just(tableRows),
					T: ''
				});
		} else {
			return _Utils_update(
				model,
				{G: $elm$core$Maybe$Nothing});
		}
	},
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'index',
		$elm$json$Json$Decode$int,
		0,
		A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'updatedAt',
				$author$project$App$Data$Id$timeDecoder,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'nextSectionId',
					$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
					$elm$core$Maybe$Nothing,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'businessValue',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'observation',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'description',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'selected',
									$elm$json$Json$Decode$bool,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'category',
										$author$project$App$Data$QuestionSection$categoryDecoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'value',
											$elm$json$Json$Decode$string,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'answered',
												$elm$json$Json$Decode$bool,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'answerTemplateId',
													$author$project$App$Data$Id$uuidDecoder,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'statementId',
														$author$project$App$Data$Id$uuidDecoder,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'questionId',
															$author$project$App$Data$Id$uuidDecoder,
															A3(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																'questionSectionId',
																$author$project$App$Data$Id$uuidDecoder,
																A3(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																	'id',
																	$author$project$App$Data$Id$uuidDecoder,
																	$elm$json$Json$Decode$succeed($author$project$App$Data$Answer$Model))))))))))))))))));
var $author$project$App$Data$Doctor$Model = function (id) {
	return function (name) {
		return function (surname) {
			return function (birthDate) {
				return function (document) {
					return function (photo) {
						return function (sex) {
							return function (license) {
								return function (licenseState) {
									return function (updatedAt) {
										return function (memedCityID) {
											return function (memedSpecialtyID) {
												return {al: birthDate, i1: document, as: id, dm: license, dn: licenseState, et: memedCityID, ev: memedSpecialtyID, hl: name, dy: photo, dJ: sex, ii: surname, f2: updatedAt};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$Doctor$decoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'memedSpecialtyID',
	$elm$json$Json$Decode$int,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'memedCityID',
		$elm$json$Json$Decode$int,
		0,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'updatedAt',
			$author$project$App$Data$Id$timeDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'licenseState',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'license',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'sex',
						$author$project$App$Data$Person$sexDecoder,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'photo',
							$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
							$elm$core$Maybe$Nothing,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'document',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'birthDate',
									$author$project$App$Data$Id$timeDecoder,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'surname',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'name',
											$elm$json$Json$Decode$string,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'id',
												$author$project$App$Data$Id$uuidDecoder,
												$elm$json$Json$Decode$succeed($author$project$App$Data$Doctor$Model)))))))))))));
var $author$project$App$Data$ICD$Model = F7(
	function (id, answerTemplateId, statementId, category, sectionCategory, description, includeICDToReport) {
		return {aY: answerTemplateId, a0: category, am: description, as: id, g2: includeICDToReport, dE: sectionCategory, aV: statementId};
	});
var $author$project$App$Data$ICD$sectionCategoryDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		return $elm$json$Json$Decode$succeed(
			$author$project$App$Data$QuestionSection$categoryFromString(str));
	},
	$elm$json$Json$Decode$string);
var $author$project$App$Data$ICD$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'includeICDToReport',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'description',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'sectionCategory',
			$author$project$App$Data$ICD$sectionCategoryDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'category',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'statementId',
					$author$project$App$Data$Id$uuidDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'answerTemplateId',
						$author$project$App$Data$Id$uuidDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$author$project$App$Data$Id$uuidDecoder,
							$elm$json$Json$Decode$succeed($author$project$App$Data$ICD$Model))))))));
var $author$project$App$Data$Statement$Model = function (id) {
	return function (parentId) {
		return function (parentRelation) {
			return function (personId) {
				return function (companyId) {
					return function (templateId) {
						return function (doctorId) {
							return function (recordedDoctorId) {
								return function (companyWeight) {
									return function (companyHeight) {
										return function (personWeight) {
											return function (personHeight) {
												return function (doctorWeight) {
													return function (doctorHeight) {
														return function (companySectionIndex) {
															return function (personSectionIndex) {
																return function (doctorSectionIndex) {
																	return function (status) {
																		return function (observation) {
																			return function (childrenCount) {
																				return function (shouldInterview) {
																					return function (dueDate) {
																						return function (createdAt) {
																							return function (createdBy) {
																								return function (updatedAt) {
																									return function (scheduleDateTime) {
																										return function (doctorChanged) {
																											return {bo: childrenCount, iS: companyHeight, gx: companyId, br: companySectionIndex, iT: companyWeight, iW: createdAt, iX: createdBy, b8: doctorChanged, ao: doctorHeight, fl: doctorId, bv: doctorSectionIndex, ap: doctorWeight, gL: dueDate, as: id, jx: observation, jK: parentId, be: parentRelation, jL: personHeight, Y: personId, bG: personSectionIndex, jN: personWeight, bI: recordedDoctorId, jU: scheduleDateTime, j_: shouldInterview, aw: status, eY: templateId, f2: updatedAt};
																										};
																									};
																								};
																							};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$Statement$decodeRelation = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		return $elm$json$Json$Decode$succeed(
			$author$project$App$Data$Statement$relationFromString(str));
	},
	$elm$json$Json$Decode$string);
var $author$project$App$Data$Statement$decodeShouldInterview = A2(
	$elm$json$Json$Decode$andThen,
	function (i) {
		return $elm$json$Json$Decode$succeed(
			$author$project$App$Data$Statement$shouldInterviewFromInt(i));
	},
	$elm$json$Json$Decode$int);
var $author$project$App$Data$StatementStatus$StatusAdminReevaluation = 31;
var $author$project$App$Data$StatementStatus$StatusConfirmInterview = 8;
var $author$project$App$Data$StatementStatus$StatusDoctorConfirmId = 15;
var $author$project$App$Data$StatementStatus$StatusDoctorConfirmMetrics = 16;
var $author$project$App$Data$StatementStatus$StatusDoctorConfirmQuestions = 17;
var $author$project$App$Data$StatementStatus$StatusDoctorEvalDeclared = 18;
var $author$project$App$Data$StatementStatus$StatusDoctorFinalEvalChanged = 24;
var $author$project$App$Data$StatementStatus$StatusDoctorFinishCall = 21;
var $author$project$App$Data$StatementStatus$StatusDoctorInformChanged = 20;
var $author$project$App$Data$StatementStatus$StatusDoctorInformNoChange = 19;
var $author$project$App$Data$StatementStatus$StatusDoctorMixing = 23;
var $author$project$App$Data$StatementStatus$StatusDoctorOrientation = 14;
var $author$project$App$Data$StatementStatus$StatusDoctorPreparingResults = 26;
var $author$project$App$Data$StatementStatus$StatusDoctorPresentation = 13;
var $author$project$App$Data$StatementStatus$StatusDoctorReview = 12;
var $author$project$App$Data$StatementStatus$StatusDoctorWaitingMix = 22;
var $author$project$App$Data$StatementStatus$StatusEnteringWaitingLine = 10;
var $author$project$App$Data$StatementStatus$StatusExtraDocument = 27;
var $author$project$App$Data$StatementStatus$StatusProponentLegal = 4;
var $author$project$App$Data$StatementStatus$StatusProponentPayment = 5;
var $author$project$App$Data$StatementStatus$StatusProponentScreening = 7;
var $author$project$App$Data$StatementStatus$StatusRecoverMixing = 35;
var $author$project$App$Data$StatementStatus$StatusRecoverMixingFailed = 36;
var $author$project$App$Data$StatementStatus$StatusRecoverWaitingMix = 34;
var $author$project$App$Data$StatementStatus$StatusReevaluation = 30;
var $author$project$App$Data$StatementStatus$StatusWaitingTecEval = 1;
var $author$project$App$Data$StatementStatus$statusFromInt = function (value) {
	switch (value) {
		case 0:
			return 0;
		case 3:
			return 1;
		case 5:
			return 2;
		case 10:
			return 3;
		case 15:
			return 4;
		case 16:
			return 5;
		case 20:
			return 6;
		case 23:
			return 7;
		case 24:
			return 10;
		case 25:
			return 8;
		case 26:
			return 9;
		case 27:
			return 11;
		case 28:
			return 12;
		case 30:
			return 13;
		case 31:
			return 14;
		case 32:
			return 15;
		case 33:
			return 16;
		case 34:
			return 17;
		case 35:
			return 18;
		case 37:
			return 19;
		case 38:
			return 20;
		case 39:
			return 21;
		case 40:
			return 22;
		case 41:
			return 23;
		case 42:
			return 24;
		case 43:
			return 25;
		case 44:
			return 27;
		case 45:
			return 28;
		case 46:
			return 30;
		case 47:
			return 31;
		case 50:
			return 26;
		case 60:
			return 29;
		case 63:
			return 32;
		case 65:
			return 33;
		case 70:
			return 34;
		case 75:
			return 35;
		case 80:
			return 36;
		default:
			return 0;
	}
};
var $author$project$App$Data$StatementStatus$decodeStatus = A2(
	$elm$json$Json$Decode$andThen,
	function (i) {
		return $elm$json$Json$Decode$succeed(
			$author$project$App$Data$StatementStatus$statusFromInt(i));
	},
	$elm$json$Json$Decode$int);
var $elm$parser$Parser$deadEndsToString = function (deadEnds) {
	return 'TODO deadEndsToString';
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(str);
		if (_v0.$ === 1) {
			var deadEnds = _v0.a;
			return $elm$json$Json$Decode$fail(
				$elm$parser$Parser$deadEndsToString(deadEnds));
		} else {
			var time = _v0.a;
			return $elm$json$Json$Decode$succeed(time);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$App$Data$Statement$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'doctorChanged',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'scheduleDateTime',
		$elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[$author$project$App$Data$Id$timeDecoder, $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder])),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'updatedAt',
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[$author$project$App$Data$Id$timeDecoder, $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder])),
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'createdBy',
				$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
				$elm$core$Maybe$Nothing,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'createdAt',
					$elm$json$Json$Decode$oneOf(
						_List_fromArray(
							[$author$project$App$Data$Id$timeDecoder, $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder])),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'dueDate',
						$elm$json$Json$Decode$oneOf(
							_List_fromArray(
								[$author$project$App$Data$Id$timeDecoder, $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder])),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'shouldInterview',
							$author$project$App$Data$Statement$decodeShouldInterview,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'childrenCount',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'observation',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'status',
										$author$project$App$Data$StatementStatus$decodeStatus,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'doctorSectionIndex',
											$elm$json$Json$Decode$int,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'personSectionIndex',
												$elm$json$Json$Decode$int,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'companySectionIndex',
													$elm$json$Json$Decode$int,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'doctorHeight',
														$elm$json$Json$Decode$int,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'doctorWeight',
															$elm$json$Json$Decode$int,
															A3(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																'personHeight',
																$elm$json$Json$Decode$int,
																A3(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																	'personWeight',
																	$elm$json$Json$Decode$int,
																	A3(
																		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																		'companyHeight',
																		$elm$json$Json$Decode$int,
																		A3(
																			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																			'companyWeight',
																			$elm$json$Json$Decode$int,
																			A4(
																				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																				'recordedDoctorId',
																				$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
																				$elm$core$Maybe$Nothing,
																				A4(
																					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																					'doctorId',
																					$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
																					$elm$core$Maybe$Nothing,
																					A3(
																						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																						'templateId',
																						$author$project$App$Data$Id$uuidDecoder,
																						A3(
																							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																							'companyId',
																							$author$project$App$Data$Id$uuidDecoder,
																							A3(
																								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																								'personId',
																								$author$project$App$Data$Id$uuidDecoder,
																								A4(
																									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																									'parentRelation',
																									$elm$json$Json$Decode$maybe($author$project$App$Data$Statement$decodeRelation),
																									$elm$core$Maybe$Nothing,
																									A4(
																										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																										'parentId',
																										$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
																										$elm$core$Maybe$Nothing,
																										A3(
																											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																											'id',
																											$author$project$App$Data$Id$uuidDecoder,
																											$elm$json$Json$Decode$succeed($author$project$App$Data$Statement$Model))))))))))))))))))))))))))));
var $author$project$App$Data$StatementMessage$Model = F8(
	function (id, senderId, statementId, senderName, message, messageType, status, createdAt) {
		return {iW: createdAt, as: id, jl: message, fF: messageType, eS: senderId, jX: senderName, aV: statementId, aw: status};
	});
var $author$project$App$Data$StatementMessage$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'createdAt',
	$author$project$App$Data$Id$timeDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'status',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'type',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'message',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'senderName',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'statementId',
						$author$project$App$Data$Id$uuidDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'senderId',
							$author$project$App$Data$Id$uuidDecoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'id',
								$author$project$App$Data$Id$uuidDecoder,
								$elm$json$Json$Decode$succeed($author$project$App$Data$StatementMessage$Model)))))))));
var $author$project$App$Service$SpecificResources$ridDecoder = A2($elm$json$Json$Decode$field, 'rid', $elm$json$Json$Decode$string);
var $elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
	});
var $author$project$App$Service$SpecificResources$handleSpecificEventAdd = F7(
	function (path, evtData, currentList, typ, collectionType, decoder, resource) {
		return A2(
			$elm$core$Result$map,
			function (dict) {
				return A3(
					$elm$core$Dict$foldr,
					F3(
						function (rid, v, d) {
							return A3($elm$core$Dict$insert, rid, v, d);
						}),
					dict,
					A2(
						$elm$core$Result$withDefault,
						$elm$core$Dict$empty,
						A2(
							$elm$core$Result$map,
							$elm$core$Dict$map(
								F2(
									function (_v1, v) {
										return _Utils_Tuple2(
											typ,
											resource(v));
									})),
							A2(
								$elm$json$Json$Decode$decodeValue,
								$elm$json$Json$Decode$dict(decoder),
								evtData.K))));
			},
			A2(
				$elm$core$Result$map,
				function (rid) {
					return A2(
						$elm$core$Dict$singleton,
						$author$project$App$Service$Shared$pathToString(path),
						function (v) {
							return _Utils_Tuple2(collectionType, v);
						}(
							A2(
								$author$project$App$Service$SpecificResources$Collection,
								typ,
								A2(
									$elm$core$List$map,
									function (_v0) {
										var v = _v0.b;
										return v;
									},
									$elm$core$Dict$toList(
										A3(
											$elm$core$Dict$insert,
											evtData.jb,
											rid,
											$elm$core$Dict$fromList(
												A2(
													$elm$core$List$indexedMap,
													F2(
														function (i, v) {
															return (_Utils_cmp(i, evtData.jb) < 0) ? _Utils_Tuple2(i, v) : _Utils_Tuple2(i + 1, v);
														}),
													currentList))))))));
				},
				A2($elm$json$Json$Decode$decodeValue, $author$project$App$Service$SpecificResources$ridDecoder, evtData.T)));
	});
var $author$project$App$Data$Person$Waiting = function (id) {
	return function (name) {
		return function (surname) {
			return function (socialName) {
				return function (document) {
					return function (sex) {
						return function (civilState) {
							return function (birthDate) {
								return function (motherName) {
									return function (photo) {
										return function (waitStart) {
											return function (lastSeen) {
												return function (onHoldSince) {
													return function (onHoldBy) {
														return function (status) {
															return function (updatedAt) {
																return function (templateId) {
																	return {al: birthDate, D: civilState, i1: document, as: id, ch: lastSeen, cp: motherName, hl: name, cs: onHoldBy, bd: onHoldSince, dy: photo, dJ: sex, B: socialName, aw: status, ii: surname, eY: templateId, f2: updatedAt, bW: waitStart};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$Person$waitingDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'templateId',
	$author$project$App$Data$Id$uuidDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'updatedAt',
		$author$project$App$Data$Id$timeDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'status',
			$author$project$App$Data$StatementStatus$decodeStatus,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'onHoldBy',
				$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'onHoldSince',
					$elm$json$Json$Decode$maybe($author$project$App$Data$Id$timeDecoder),
					$elm$core$Maybe$Nothing,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'lastSeen',
						$author$project$App$Data$Id$timeDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'waitStart',
							$author$project$App$Data$Id$timeDecoder,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'photo',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
								$elm$core$Maybe$Nothing,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'motherName',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'birthDate',
										$author$project$App$Data$Id$timeDecoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'civilState',
											$author$project$App$Data$Person$civilStatusDecoder,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'sex',
												$author$project$App$Data$Person$sexDecoder,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'document',
													$elm$json$Json$Decode$string,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'socialName',
														$elm$json$Json$Decode$string,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'surname',
															$elm$json$Json$Decode$string,
															A3(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																'name',
																$elm$json$Json$Decode$string,
																A3(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																	'id',
																	$author$project$App$Data$Id$uuidDecoder,
																	$elm$json$Json$Decode$succeed($author$project$App$Data$Person$Waiting))))))))))))))))));
var $author$project$App$Service$SpecificResources$getSpecificEventAddDecoded = F2(
	function (evt, r) {
		var _v0 = _Utils_Tuple2(evt, r);
		_v0$7:
		while (true) {
			switch (_v0.a.$) {
				case 2:
					if (_v0.b.$ === 4) {
						switch (_v0.b.a.$) {
							case 11:
								var _v1 = _v0.a;
								var path = _v1.a;
								var evtData = _v1.b;
								var _v2 = _v0.b;
								var _v3 = _v2.a;
								var currentList = _v2.b;
								return A7($author$project$App$Service$SpecificResources$handleSpecificEventAdd, path, evtData, currentList, $author$project$App$Service$SpecificResources$TypeDoctor, $author$project$App$Service$SpecificResources$TypeDoctorCollection, $author$project$App$Data$Doctor$decoder, $author$project$App$Service$SpecificResources$Doctor);
							case 23:
								var _v4 = _v0.a;
								var path = _v4.a;
								var evtData = _v4.b;
								var _v5 = _v0.b;
								var _v6 = _v5.a;
								var currentList = _v5.b;
								return A7($author$project$App$Service$SpecificResources$handleSpecificEventAdd, path, evtData, currentList, $author$project$App$Service$SpecificResources$TypePerson, $author$project$App$Service$SpecificResources$TypePersonCollection, $author$project$App$Data$Person$decoder, $author$project$App$Service$SpecificResources$Person);
							case 40:
								var _v7 = _v0.a;
								var path = _v7.a;
								var evtData = _v7.b;
								var _v8 = _v0.b;
								var _v9 = _v8.a;
								var currentList = _v8.b;
								return A7($author$project$App$Service$SpecificResources$handleSpecificEventAdd, path, evtData, currentList, $author$project$App$Service$SpecificResources$TypeStatementMessage, $author$project$App$Service$SpecificResources$TypeStatementMessageCollection, $author$project$App$Data$StatementMessage$decoder, $author$project$App$Service$SpecificResources$StatementMessage);
							case 25:
								var _v10 = _v0.a;
								var path = _v10.a;
								var evtData = _v10.b;
								var _v11 = _v0.b;
								var _v12 = _v11.a;
								var currentList = _v11.b;
								return A7($author$project$App$Service$SpecificResources$handleSpecificEventAdd, path, evtData, currentList, $author$project$App$Service$SpecificResources$TypeWaitingPerson, $author$project$App$Service$SpecificResources$TypeWaitingPersonCollection, $author$project$App$Data$Person$waitingDecoder, $author$project$App$Service$SpecificResources$WaitingPerson);
							case 5:
								var _v13 = _v0.a;
								var path = _v13.a;
								var evtData = _v13.b;
								var _v14 = _v0.b;
								var _v15 = _v14.a;
								var currentList = _v14.b;
								return A7($author$project$App$Service$SpecificResources$handleSpecificEventAdd, path, evtData, currentList, $author$project$App$Service$SpecificResources$TypeAnswer, $author$project$App$Service$SpecificResources$TypeAnswersCollection, $author$project$App$Data$Answer$decoder, $author$project$App$Service$SpecificResources$Answer);
							case 12:
								var _v16 = _v0.a;
								var path = _v16.a;
								var evtData = _v16.b;
								var _v17 = _v0.b;
								var _v18 = _v17.a;
								var currentList = _v17.b;
								return A7($author$project$App$Service$SpecificResources$handleSpecificEventAdd, path, evtData, currentList, $author$project$App$Service$SpecificResources$TypeICD, $author$project$App$Service$SpecificResources$TypeICDCollection, $author$project$App$Data$ICD$decoder, $author$project$App$Service$SpecificResources$ICD);
							case 27:
								var _v19 = _v0.a;
								var path = _v19.a;
								var evtData = _v19.b;
								var _v20 = _v0.b;
								var _v21 = _v20.a;
								var currentList = _v20.b;
								return A7($author$project$App$Service$SpecificResources$handleSpecificEventAdd, path, evtData, currentList, $author$project$App$Service$SpecificResources$TypeStatement, $author$project$App$Service$SpecificResources$TypeStatementCollection, $author$project$App$Data$Statement$decoder, $author$project$App$Service$SpecificResources$Statement);
							default:
								break _v0$7;
						}
					} else {
						break _v0$7;
					}
				case 1:
					var _v23 = _v0.a;
					return $elm$core$Result$Ok($elm$core$Dict$empty);
				case 3:
					var _v24 = _v0.a;
					return $elm$core$Result$Ok($elm$core$Dict$empty);
				default:
					var _v25 = _v0.a;
					return $elm$core$Result$Ok($elm$core$Dict$empty);
			}
		}
		var _v22 = _v0.a;
		return $elm$core$Result$Ok($elm$core$Dict$empty);
	});
var $author$project$App$Service$SpecificResources$Conference = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Data$Answer$EventChange = function (id) {
	return function (questionSectionId) {
		return function (questionId) {
			return function (statementId) {
				return function (answerTemplateId) {
					return function (answered) {
						return function (value) {
							return function (category) {
								return function (selected) {
									return function (description) {
										return function (observation) {
											return function (businessValue) {
												return function (nextSectionId) {
													return function (updatedAt) {
														return function (tableRows) {
															return function (index) {
																return {aY: answerTemplateId, d3: answered, ay: businessValue, a0: category, am: description, as: id, bB: index, cr: nextSectionId, jx: observation, bh: questionId, aS: questionSectionId, dF: selected, aV: statementId, G: tableRows, f2: updatedAt, T: value};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$EventHelpers$maybeEventFieldValue = F2(
	function (model, evt) {
		if (!model.$) {
			var a = model.a;
			if (!evt.$) {
				var b = evt.a;
				return $elm$core$Maybe$Just(b);
			} else {
				return $elm$core$Maybe$Just(a);
			}
		} else {
			if (!evt.$) {
				var b = evt.a;
				return $elm$core$Maybe$Just(b);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}
	});
var $author$project$App$Data$Answer$eventToModel = F2(
	function (model, evt) {
		return {
			aY: A2($elm$core$Maybe$withDefault, model.aY, evt.aY),
			d3: A2($elm$core$Maybe$withDefault, model.d3, evt.d3),
			ay: A2($elm$core$Maybe$withDefault, model.ay, evt.ay),
			a0: A2($elm$core$Maybe$withDefault, model.a0, evt.a0),
			am: A2($elm$core$Maybe$withDefault, model.am, evt.am),
			as: A2($elm$core$Maybe$withDefault, model.as, evt.as),
			bB: A2($elm$core$Maybe$withDefault, model.bB, evt.bB),
			cr: A2($author$project$App$Data$EventHelpers$maybeEventFieldValue, model.cr, evt.cr),
			jx: A2($elm$core$Maybe$withDefault, model.jx, evt.jx),
			bh: A2($elm$core$Maybe$withDefault, model.bh, evt.aS),
			aS: A2($elm$core$Maybe$withDefault, model.aS, evt.aS),
			dF: A2($elm$core$Maybe$withDefault, model.dF, evt.dF),
			aV: A2($elm$core$Maybe$withDefault, model.aV, evt.aV),
			G: A2($author$project$App$Data$EventHelpers$maybeEventFieldValue, model.G, evt.G),
			f2: A2($elm$core$Maybe$withDefault, model.f2, evt.f2),
			T: A2($elm$core$Maybe$withDefault, model.T, evt.T)
		};
	});
var $author$project$App$Data$Answer$eventChangeDecoder = function (current) {
	return A2(
		$elm$json$Json$Decode$map,
		function (m) {
			return A2(
				$author$project$App$Data$Answer$eventToModel,
				current,
				function () {
					var _v0 = m.T;
					if (!_v0.$) {
						var value = _v0.a;
						var _v1 = A2($elm$json$Json$Decode$decodeString, $author$project$App$Data$Answer$tableValueDecoder, value);
						if (!_v1.$) {
							var tableRows = _v1.a;
							return _Utils_update(
								m,
								{
									G: $elm$core$Maybe$Just(tableRows),
									T: $elm$core$Maybe$Nothing
								});
						} else {
							return _Utils_update(
								m,
								{G: $elm$core$Maybe$Nothing});
						}
					} else {
						return m;
					}
				}());
		},
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'index',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
			$elm$core$Maybe$Nothing,
			A2(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'updatedAt',
					$elm$json$Json$Decode$maybe($author$project$App$Data$Id$timeDecoder),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'nextSectionId',
						$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'businessValue',
							$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'observation',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
								$elm$core$Maybe$Nothing,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'description',
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
									$elm$core$Maybe$Nothing,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'selected',
										$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool),
										$elm$core$Maybe$Nothing,
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'category',
											$elm$json$Json$Decode$maybe($author$project$App$Data$QuestionSection$categoryDecoder),
											$elm$core$Maybe$Nothing,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'value',
												$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
												$elm$core$Maybe$Nothing,
												A4(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
													'answered',
													$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool),
													$elm$core$Maybe$Nothing,
													A4(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
														'answerTemplateId',
														$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
														$elm$core$Maybe$Nothing,
														A4(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
															'statementId',
															$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
															$elm$core$Maybe$Nothing,
															A4(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																'questionId',
																$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
																$elm$core$Maybe$Nothing,
																A4(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																	'questionSectionId',
																	$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
																	$elm$core$Maybe$Nothing,
																	A4(
																		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																		'id',
																		$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
																		$elm$core$Maybe$Nothing,
																		$elm$json$Json$Decode$succeed($author$project$App$Data$Answer$EventChange))))))))))))))))));
};
var $author$project$App$Data$Conference$EventChange = function (id) {
	return function (stun) {
		return function (turn) {
			return function (signaler) {
				return function (password) {
					return function (stunUser) {
						return function (stunPassword) {
							return function (recordingDoctor) {
								return function (recordingProponent) {
									return function (createdAt) {
										return {iW: createdAt, as: id, bf: password, jQ: recordingDoctor, jR: recordingProponent, dM: signaler, dN: stun, ie: stunPassword, $8: stunUser, dT: turn};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$Conference$eventToModel = F2(
	function (model, evt) {
		return {
			iW: A2($elm$core$Maybe$withDefault, model.iW, evt.iW),
			as: A2($elm$core$Maybe$withDefault, model.as, evt.as),
			bf: A2($elm$core$Maybe$withDefault, model.bf, evt.bf),
			jQ: A2($elm$core$Maybe$withDefault, model.jQ, evt.jQ),
			jR: A2($elm$core$Maybe$withDefault, model.jR, evt.jR),
			dM: A2($elm$core$Maybe$withDefault, model.dM, evt.dM),
			dN: A2($elm$core$Maybe$withDefault, model.dN, evt.dN),
			ie: A2($elm$core$Maybe$withDefault, model.ie, evt.ie),
			$8: A2($elm$core$Maybe$withDefault, model.$8, evt.$8),
			dT: A2($elm$core$Maybe$withDefault, model.dT, evt.dT)
		};
	});
var $author$project$App$Data$Conference$eventChangeDecoder = function (current) {
	return A2(
		$elm$json$Json$Decode$map,
		$author$project$App$Data$Conference$eventToModel(current),
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'createdAt',
			$elm$json$Json$Decode$maybe($author$project$App$Data$Id$timeDecoder),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'recordingProponent',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'recordingDoctor',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'stunPassword',
						$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'stunUser',
							$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'password',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
								$elm$core$Maybe$Nothing,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'signaler',
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
									$elm$core$Maybe$Nothing,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'turn',
										$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
										$elm$core$Maybe$Nothing,
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'stun',
											$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
											$elm$core$Maybe$Nothing,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'id',
												$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
												$elm$core$Maybe$Nothing,
												$elm$json$Json$Decode$succeed($author$project$App$Data$Conference$EventChange))))))))))));
};
var $author$project$App$Data$Person$EventChange = function (id) {
	return function (name) {
		return function (surname) {
			return function (socialName) {
				return function (document) {
					return function (sex) {
						return function (civilState) {
							return function (birthDate) {
								return function (motherName) {
									return function (photo) {
										return function (updatedAt) {
											return {al: birthDate, D: civilState, i1: document, as: id, cp: motherName, hl: name, dy: photo, dJ: sex, B: socialName, ii: surname, f2: updatedAt};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$Person$eventToModel = F2(
	function (model, evt) {
		return {
			al: A2($elm$core$Maybe$withDefault, model.al, evt.al),
			D: A2($elm$core$Maybe$withDefault, model.D, evt.D),
			i1: A2($elm$core$Maybe$withDefault, model.i1, evt.i1),
			as: A2($elm$core$Maybe$withDefault, model.as, evt.as),
			cp: A2($elm$core$Maybe$withDefault, model.cp, evt.cp),
			hl: A2($elm$core$Maybe$withDefault, model.hl, evt.hl),
			dy: A2($author$project$App$Data$EventHelpers$maybeEventFieldValue, model.dy, evt.dy),
			cD: model.cD,
			dJ: A2($elm$core$Maybe$withDefault, model.dJ, evt.dJ),
			B: A2($elm$core$Maybe$withDefault, model.B, evt.B),
			ii: A2($elm$core$Maybe$withDefault, model.ii, evt.ii),
			f2: A2($elm$core$Maybe$withDefault, model.f2, evt.f2)
		};
	});
var $author$project$App$Data$Person$eventChangeDecoder = function (current) {
	return A2(
		$elm$json$Json$Decode$map,
		$author$project$App$Data$Person$eventToModel(current),
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'updatedAt',
			$elm$json$Json$Decode$maybe($author$project$App$Data$Id$timeDecoder),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'photo',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'motherName',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'birthDate',
						$elm$json$Json$Decode$maybe($author$project$App$Data$Id$timeDecoder),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'civilState',
							$elm$json$Json$Decode$maybe($author$project$App$Data$Person$civilStatusDecoder),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'sex',
								$elm$json$Json$Decode$maybe($author$project$App$Data$Person$sexDecoder),
								$elm$core$Maybe$Nothing,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'document',
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
									$elm$core$Maybe$Nothing,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'socialName',
										$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
										$elm$core$Maybe$Nothing,
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'surname',
											$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
											$elm$core$Maybe$Nothing,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'name',
												$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
												$elm$core$Maybe$Nothing,
												A4(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
													'id',
													$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
													$elm$core$Maybe$Nothing,
													$elm$json$Json$Decode$succeed($author$project$App$Data$Person$EventChange)))))))))))));
};
var $author$project$App$Data$Statement$EventChange = function (id) {
	return function (parentId) {
		return function (parentRelation) {
			return function (personId) {
				return function (companyId) {
					return function (templateId) {
						return function (doctorId) {
							return function (recordedDoctorId) {
								return function (companyWeight) {
									return function (companyHeight) {
										return function (personWeight) {
											return function (personHeight) {
												return function (doctorWeight) {
													return function (doctorHeight) {
														return function (companySectionIndex) {
															return function (personSectionIndex) {
																return function (doctorSectionIndex) {
																	return function (status) {
																		return function (observation) {
																			return function (childrenCount) {
																				return function (shouldInterview) {
																					return function (dueDate) {
																						return function (createdAt) {
																							return function (createdBy) {
																								return function (updatedAt) {
																									return function (scheduleDateTime) {
																										return function (doctorChanged) {
																											return {bo: childrenCount, iS: companyHeight, gx: companyId, br: companySectionIndex, iT: companyWeight, iW: createdAt, iX: createdBy, b8: doctorChanged, ao: doctorHeight, fl: doctorId, bv: doctorSectionIndex, ap: doctorWeight, gL: dueDate, as: id, jx: observation, jK: parentId, be: parentRelation, jL: personHeight, Y: personId, bG: personSectionIndex, jN: personWeight, bI: recordedDoctorId, jU: scheduleDateTime, j_: shouldInterview, aw: status, eY: templateId, f2: updatedAt};
																										};
																									};
																								};
																							};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$Statement$eventToModel = F2(
	function (model, evt) {
		return {
			bo: A2($elm$core$Maybe$withDefault, model.bo, evt.bo),
			iS: A2($elm$core$Maybe$withDefault, model.iS, evt.iS),
			gx: A2($elm$core$Maybe$withDefault, model.gx, evt.gx),
			br: A2($elm$core$Maybe$withDefault, model.br, evt.br),
			iT: A2($elm$core$Maybe$withDefault, model.iT, evt.iT),
			iW: A2($elm$core$Maybe$withDefault, model.iW, evt.iW),
			iX: A2($author$project$App$Data$EventHelpers$maybeEventFieldValue, model.iX, evt.iX),
			b8: A2($elm$core$Maybe$withDefault, model.b8, evt.b8),
			ao: A2($elm$core$Maybe$withDefault, model.ao, evt.ao),
			fl: A2($author$project$App$Data$EventHelpers$maybeEventFieldValue, model.fl, evt.fl),
			bv: A2($elm$core$Maybe$withDefault, model.bv, evt.bv),
			ap: A2($elm$core$Maybe$withDefault, model.ap, evt.ap),
			gL: A2($elm$core$Maybe$withDefault, model.gL, evt.gL),
			as: A2($elm$core$Maybe$withDefault, model.as, evt.as),
			jx: A2($elm$core$Maybe$withDefault, model.jx, evt.jx),
			jK: A2($author$project$App$Data$EventHelpers$maybeEventFieldValue, model.jK, evt.jK),
			be: A2($author$project$App$Data$EventHelpers$maybeEventFieldValue, model.be, evt.be),
			jL: A2($elm$core$Maybe$withDefault, model.jL, evt.jL),
			Y: A2($elm$core$Maybe$withDefault, model.Y, evt.Y),
			bG: A2($elm$core$Maybe$withDefault, model.bG, evt.bG),
			jN: A2($elm$core$Maybe$withDefault, model.jN, evt.jN),
			bI: A2($author$project$App$Data$EventHelpers$maybeEventFieldValue, model.bI, evt.bI),
			jU: A2($elm$core$Maybe$withDefault, model.jU, evt.jU),
			j_: A2($elm$core$Maybe$withDefault, model.j_, evt.j_),
			aw: A2($elm$core$Maybe$withDefault, model.aw, evt.aw),
			eY: A2($elm$core$Maybe$withDefault, model.eY, evt.eY),
			f2: A2($elm$core$Maybe$withDefault, model.f2, evt.f2)
		};
	});
var $author$project$App$Data$Statement$eventChangeDecoder = function (current) {
	return A2(
		$elm$json$Json$Decode$map,
		$author$project$App$Data$Statement$eventToModel(current),
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'doctorChanged',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'scheduleDateTime',
				$elm$json$Json$Decode$maybe(
					$elm$json$Json$Decode$oneOf(
						_List_fromArray(
							[$author$project$App$Data$Id$timeDecoder, $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder]))),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'updatedAt',
					$elm$json$Json$Decode$maybe(
						$elm$json$Json$Decode$oneOf(
							_List_fromArray(
								[$author$project$App$Data$Id$timeDecoder, $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder]))),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'createdBy',
						$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'createdAt',
							$elm$json$Json$Decode$maybe(
								$elm$json$Json$Decode$oneOf(
									_List_fromArray(
										[$author$project$App$Data$Id$timeDecoder, $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder]))),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'dueDate',
								$elm$json$Json$Decode$maybe(
									$elm$json$Json$Decode$oneOf(
										_List_fromArray(
											[$author$project$App$Data$Id$timeDecoder, $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder]))),
								$elm$core$Maybe$Nothing,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'shouldInterview',
									$elm$json$Json$Decode$maybe($author$project$App$Data$Statement$decodeShouldInterview),
									$elm$core$Maybe$Nothing,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'childrenCount',
										$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
										$elm$core$Maybe$Nothing,
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'observation',
											$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
											$elm$core$Maybe$Nothing,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'status',
												$elm$json$Json$Decode$maybe($author$project$App$Data$StatementStatus$decodeStatus),
												$elm$core$Maybe$Nothing,
												A4(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
													'doctorSectionIndex',
													$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
													$elm$core$Maybe$Nothing,
													A4(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
														'personSectionIndex',
														$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
														$elm$core$Maybe$Nothing,
														A4(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
															'companySectionIndex',
															$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
															$elm$core$Maybe$Nothing,
															A4(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																'doctorHeight',
																$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
																$elm$core$Maybe$Nothing,
																A4(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																	'doctorWeight',
																	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
																	$elm$core$Maybe$Nothing,
																	A4(
																		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																		'personHeight',
																		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
																		$elm$core$Maybe$Nothing,
																		A4(
																			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																			'personWeight',
																			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
																			$elm$core$Maybe$Nothing,
																			A4(
																				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																				'companyHeight',
																				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
																				$elm$core$Maybe$Nothing,
																				A4(
																					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																					'companyWeight',
																					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
																					$elm$core$Maybe$Nothing,
																					A4(
																						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																						'recordedDoctorId',
																						$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
																						$elm$core$Maybe$Nothing,
																						A4(
																							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																							'doctorId',
																							$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
																							$elm$core$Maybe$Nothing,
																							A4(
																								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																								'templateId',
																								$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
																								$elm$core$Maybe$Nothing,
																								A4(
																									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																									'companyId',
																									$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
																									$elm$core$Maybe$Nothing,
																									A4(
																										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																										'personId',
																										$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
																										$elm$core$Maybe$Nothing,
																										A4(
																											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																											'parentRelation',
																											$elm$json$Json$Decode$maybe($author$project$App$Data$Statement$decodeRelation),
																											$elm$core$Maybe$Nothing,
																											A4(
																												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																												'parentId',
																												$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
																												$elm$core$Maybe$Nothing,
																												A4(
																													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																													'id',
																													$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
																													$elm$core$Maybe$Nothing,
																													$elm$json$Json$Decode$succeed($author$project$App$Data$Statement$EventChange)))))))))))))))))))))))))))));
};
var $author$project$App$Data$Person$WaitingEventChange = function (id) {
	return function (name) {
		return function (surname) {
			return function (socialName) {
				return function (document) {
					return function (sex) {
						return function (civilState) {
							return function (birthDate) {
								return function (motherName) {
									return function (photo) {
										return function (waitStart) {
											return function (lastSeen) {
												return function (onHoldSince) {
													return function (onHoldBy) {
														return function (status) {
															return function (updatedAt) {
																return function (templateId) {
																	return {al: birthDate, D: civilState, i1: document, as: id, ch: lastSeen, cp: motherName, hl: name, cs: onHoldBy, bd: onHoldSince, dy: photo, dJ: sex, B: socialName, aw: status, ii: surname, eY: templateId, f2: updatedAt, bW: waitStart};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$Person$waitingEventToWaiting = F2(
	function (model, evt) {
		return {
			al: A2($elm$core$Maybe$withDefault, model.al, evt.al),
			D: A2($elm$core$Maybe$withDefault, model.D, evt.D),
			i1: A2($elm$core$Maybe$withDefault, model.i1, evt.i1),
			as: A2($elm$core$Maybe$withDefault, model.as, evt.as),
			ch: A2($elm$core$Maybe$withDefault, model.ch, evt.ch),
			cp: A2($elm$core$Maybe$withDefault, model.cp, evt.cp),
			hl: A2($elm$core$Maybe$withDefault, model.hl, evt.hl),
			cs: A2($author$project$App$Data$EventHelpers$maybeEventFieldValue, model.cs, evt.cs),
			bd: A2($author$project$App$Data$EventHelpers$maybeEventFieldValue, model.bd, evt.bd),
			dy: A2($author$project$App$Data$EventHelpers$maybeEventFieldValue, model.dy, evt.dy),
			dJ: A2($elm$core$Maybe$withDefault, model.dJ, evt.dJ),
			B: A2($elm$core$Maybe$withDefault, model.B, evt.B),
			aw: A2($elm$core$Maybe$withDefault, model.aw, evt.aw),
			ii: A2($elm$core$Maybe$withDefault, model.ii, evt.ii),
			eY: A2($elm$core$Maybe$withDefault, model.eY, evt.eY),
			f2: A2($elm$core$Maybe$withDefault, model.f2, evt.f2),
			bW: A2($elm$core$Maybe$withDefault, model.bW, evt.bW)
		};
	});
var $author$project$App$Data$Person$waitingEventChangeDecoder = function (current) {
	return A2(
		$elm$json$Json$Decode$map,
		$author$project$App$Data$Person$waitingEventToWaiting(current),
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'templateId',
			$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'updatedAt',
				$elm$json$Json$Decode$maybe($author$project$App$Data$Id$timeDecoder),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'status',
					$elm$json$Json$Decode$maybe($author$project$App$Data$StatementStatus$decodeStatus),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'onHoldBy',
						$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'onHoldSince',
							$elm$json$Json$Decode$maybe($author$project$App$Data$Id$timeDecoder),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'lastSeen',
								$elm$json$Json$Decode$maybe($author$project$App$Data$Id$timeDecoder),
								$elm$core$Maybe$Nothing,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'waitStart',
									$elm$json$Json$Decode$maybe($author$project$App$Data$Id$timeDecoder),
									$elm$core$Maybe$Nothing,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'photo',
										$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
										$elm$core$Maybe$Nothing,
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'motherName',
											$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
											$elm$core$Maybe$Nothing,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'birthDate',
												$elm$json$Json$Decode$maybe($author$project$App$Data$Id$timeDecoder),
												$elm$core$Maybe$Nothing,
												A4(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
													'civilState',
													$elm$json$Json$Decode$maybe($author$project$App$Data$Person$civilStatusDecoder),
													$elm$core$Maybe$Nothing,
													A4(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
														'sex',
														$elm$json$Json$Decode$maybe($author$project$App$Data$Person$sexDecoder),
														$elm$core$Maybe$Nothing,
														A4(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
															'document',
															$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
															$elm$core$Maybe$Nothing,
															A4(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																'socialName',
																$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
																$elm$core$Maybe$Nothing,
																A4(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																	'surname',
																	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
																	$elm$core$Maybe$Nothing,
																	A4(
																		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																		'name',
																		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
																		$elm$core$Maybe$Nothing,
																		A4(
																			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																			'id',
																			$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
																			$elm$core$Maybe$Nothing,
																			$elm$json$Json$Decode$succeed($author$project$App$Data$Person$WaitingEventChange)))))))))))))))))));
};
var $author$project$App$Service$SpecificResources$getSpecificEventChangeDecoded = F2(
	function (evt, r) {
		var _v0 = _Utils_Tuple2(evt, r);
		switch (_v0.a.$) {
			case 1:
				switch (_v0.b.$) {
					case 16:
						var _v1 = _v0.a;
						var path = _v1.a;
						var evtData = _v1.b;
						var model = _v0.b.a;
						return A2(
							$elm$core$Result$map,
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Service$SpecificResources$Statement,
								$elm$core$Dict$singleton(
									$author$project$App$Service$Shared$pathToString(path))),
							A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$App$Data$Statement$eventChangeDecoder(model),
								evtData.j9));
					case 13:
						var _v2 = _v0.a;
						var path = _v2.a;
						var evtData = _v2.b;
						var model = _v0.b.a;
						return A2(
							$elm$core$Result$map,
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Service$SpecificResources$Person,
								$elm$core$Dict$singleton(
									$author$project$App$Service$Shared$pathToString(path))),
							A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$App$Data$Person$eventChangeDecoder(model),
								evtData.j9));
					case 14:
						var _v3 = _v0.a;
						var path = _v3.a;
						var evtData = _v3.b;
						var model = _v0.b.a;
						return A2(
							$elm$core$Result$map,
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Service$SpecificResources$WaitingPerson,
								$elm$core$Dict$singleton(
									$author$project$App$Service$Shared$pathToString(path))),
							A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$App$Data$Person$waitingEventChangeDecoder(model),
								evtData.j9));
					case 5:
						var _v4 = _v0.a;
						var path = _v4.a;
						var evtData = _v4.b;
						var model = _v0.b.a;
						return A2(
							$elm$core$Result$map,
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Service$SpecificResources$Answer,
								$elm$core$Dict$singleton(
									$author$project$App$Service$Shared$pathToString(path))),
							A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$App$Data$Answer$eventChangeDecoder(model),
								evtData.j9));
					case 7:
						var _v5 = _v0.a;
						var path = _v5.a;
						var evtData = _v5.b;
						var model = _v0.b.a;
						return A2(
							$elm$core$Result$map,
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Service$SpecificResources$Conference,
								$elm$core$Dict$singleton(
									$author$project$App$Service$Shared$pathToString(path))),
							A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$App$Data$Conference$eventChangeDecoder(model),
								evtData.j9));
					default:
						var _v6 = _v0.a;
						return $elm$core$Result$Ok($elm$core$Dict$empty);
				}
			case 2:
				var _v7 = _v0.a;
				return $elm$core$Result$Ok($elm$core$Dict$empty);
			case 3:
				var _v8 = _v0.a;
				return $elm$core$Result$Ok($elm$core$Dict$empty);
			default:
				var _v9 = _v0.a;
				return $elm$core$Result$Ok($elm$core$Dict$empty);
		}
	});
var $author$project$App$Service$ApiWebSocket$getSpecificResourceByEvent = F2(
	function (resultEvt, cache) {
		if (!resultEvt.$) {
			switch (resultEvt.a.$) {
				case 3:
					var _v1 = resultEvt.a;
					var path = _v1.a;
					var evtData = _v1.b;
					var rid = $author$project$App$Service$Shared$pathToString(path);
					var mFound = A2(
						$elm$core$Maybe$andThen,
						function (resource) {
							if ((resource.$ === 3) && (resource.c.$ === 4)) {
								var rType = resource.a;
								var respMsg = resource.b;
								var _v5 = resource.c;
								var cType = _v5.a;
								var list = _v5.b;
								return function (_v8) {
									var toRemove = _v8.a;
									var u = _v8.b;
									return $elm$core$Maybe$Just(
										_Utils_Tuple3(
											toRemove,
											A3(
												$author$project$App$Service$ApiWebSocket$ResourceAvailable,
												rType,
												respMsg,
												A2($author$project$App$Service$SpecificResources$Collection, cType, u)),
											respMsg));
								}(
									A3(
										$elm$core$List$foldl,
										F2(
											function (_v6, _v7) {
												var i = _v6.a;
												var v = _v6.b;
												var toRemove = _v7.a;
												var toUpdate = _v7.b;
												return (!_Utils_eq(i, evtData.jb)) ? _Utils_Tuple2(
													toRemove,
													A2(
														$elm$core$List$append,
														toUpdate,
														_List_fromArray(
															[v]))) : _Utils_Tuple2(v, toUpdate);
											}),
										_Utils_Tuple2('', _List_Nil),
										A2(
											$elm$core$List$indexedMap,
											F2(
												function (i, v) {
													return _Utils_Tuple2(i, v);
												}),
											list)));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						},
						A2($elm$core$Dict$get, rid, cache));
					if (!mFound.$) {
						var _v3 = mFound.a;
						var removedRid = _v3.a;
						var updatedResource = _v3.b;
						var respMsg = _v3.c;
						return _Utils_Tuple2(
							A2(
								$elm$core$Dict$remove,
								removedRid,
								A3($elm$core$Dict$insert, rid, updatedResource, cache)),
							respMsg);
					} else {
						return _Utils_Tuple2(cache, _List_Nil);
					}
				case 2:
					var _v9 = resultEvt.a;
					var path = _v9.a;
					var value = _v9.b;
					var evt = A2($author$project$App$Service$Shared$EventAdd, path, value);
					var _v10 = A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2($elm$core$Dict$empty, _List_Nil),
						A2(
							$elm$core$Maybe$andThen,
							function (v) {
								var _v11 = function () {
									if (v.$ === 3) {
										var mRespMsg = v.b;
										var list = v.c;
										return _Utils_Tuple2(list, mRespMsg);
									} else {
										return _Utils_Tuple2($author$project$App$Service$SpecificResources$UnknownResource, _List_Nil);
									}
								}();
								var vCollection = _v11.a;
								var respMsg = _v11.b;
								var _v13 = A2($author$project$App$Service$SpecificResources$getSpecificEventAddDecoded, evt, vCollection);
								if (!_v13.$) {
									var dict = _v13.a;
									return $elm$core$Maybe$Just(
										_Utils_Tuple2(
											A2(
												$elm$core$Dict$map,
												F2(
													function (_v14, _v15) {
														var t = _v15.a;
														var d = _v15.b;
														return A3($author$project$App$Service$ApiWebSocket$ResourceAvailable, t, respMsg, d);
													}),
												dict),
											respMsg));
								} else {
									return $elm$core$Maybe$Nothing;
								}
							},
							A2(
								$elm$core$Dict$get,
								$author$project$App$Service$Shared$pathToString(path),
								cache)));
					var foundEntries = _v10.a;
					var foundRespMsg = _v10.b;
					return _Utils_Tuple2(
						A6(
							$elm$core$Dict$merge,
							$elm$core$Dict$insert,
							F4(
								function (k, _v16, b, d) {
									return A3($elm$core$Dict$insert, k, b, d);
								}),
							$elm$core$Dict$insert,
							cache,
							foundEntries,
							$elm$core$Dict$empty),
						foundRespMsg);
				case 1:
					var _v17 = resultEvt.a;
					var path = _v17.a;
					var value = _v17.b;
					var evt = A2($author$project$App$Service$Shared$EventChange, path, value);
					var _v18 = A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2($elm$core$Dict$empty, _List_Nil),
						A2(
							$elm$core$Maybe$map,
							$elm$core$Tuple$mapFirst(
								$elm$core$Dict$singleton(
									$author$project$App$Service$Shared$pathToString(path))),
							A2(
								$elm$core$Maybe$andThen,
								function (v) {
									var _v19 = function () {
										if (v.$ === 3) {
											var t = v.a;
											var mRespMsg = v.b;
											var model = v.c;
											return _Utils_Tuple3(t, mRespMsg, model);
										} else {
											return _Utils_Tuple3($author$project$App$Service$SpecificResources$TypeUnknown, _List_Nil, $author$project$App$Service$SpecificResources$UnknownResource);
										}
									}();
									var vType = _v19.a;
									var vRespMsg = _v19.b;
									var vModel = _v19.c;
									var _v21 = A2($author$project$App$Service$SpecificResources$getSpecificEventChangeDecoded, evt, vModel);
									if (!_v21.$) {
										var dict = _v21.a;
										return A2(
											$elm$core$Maybe$map,
											function (sv) {
												return _Utils_Tuple2(
													A3($author$project$App$Service$ApiWebSocket$ResourceAvailable, vType, vRespMsg, sv),
													vRespMsg);
											},
											$elm$core$List$head(
												$elm$core$Dict$values(dict)));
									} else {
										return $elm$core$Maybe$Nothing;
									}
								},
								A2(
									$elm$core$Dict$get,
									$author$project$App$Service$Shared$pathToString(path),
									cache))));
					var foundEntry = _v18.a;
					var foundRespMsg = _v18.b;
					return _Utils_Tuple2(
						A6(
							$elm$core$Dict$merge,
							$elm$core$Dict$insert,
							F4(
								function (k, _v22, b, d) {
									return A3($elm$core$Dict$insert, k, b, d);
								}),
							$elm$core$Dict$insert,
							cache,
							foundEntry,
							$elm$core$Dict$empty),
						foundRespMsg);
				default:
					return _Utils_Tuple2(cache, _List_Nil);
			}
		} else {
			var error = resultEvt.a;
			return _Utils_Tuple2(cache, _List_Nil);
		}
	});
var $author$project$App$Service$SpecificResources$Company = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Service$SpecificResources$Contact = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Service$SpecificResources$DoctorTemplate = F2(
	function (a, b) {
		return {$: 21, a: a, b: b};
	});
var $author$project$App$Service$SpecificResources$Document = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Service$SpecificResources$Holiday = function (a) {
	return {$: 12, a: a};
};
var $author$project$App$Service$SpecificResources$ICDCategory = F2(
	function (a, b) {
		return {$: 24, a: a, b: b};
	});
var $author$project$App$Service$SpecificResources$MemedCities = F2(
	function (a, b) {
		return {$: 27, a: a, b: b};
	});
var $author$project$App$Service$SpecificResources$MemedSpecialties = F2(
	function (a, b) {
		return {$: 28, a: a, b: b};
	});
var $author$project$App$Service$SpecificResources$MemedToken = F2(
	function (a, b) {
		return {$: 26, a: a, b: b};
	});
var $author$project$App$Service$SpecificResources$NexodataToken = F2(
	function (a, b) {
		return {$: 25, a: a, b: b};
	});
var $author$project$App$Service$SpecificResources$QuestionSectionFull = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$App$Service$SpecificResources$ReportMonthDetails = F2(
	function (a, b) {
		return {$: 30, a: a, b: b};
	});
var $author$project$App$Service$SpecificResources$ReportSummaries = F2(
	function (a, b) {
		return {$: 29, a: a, b: b};
	});
var $author$project$App$Service$SpecificResources$SimpleText = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$App$Service$SpecificResources$StatementFull = F2(
	function (a, b) {
		return {$: 18, a: a, b: b};
	});
var $author$project$App$Service$SpecificResources$StatementResult = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$App$Service$SpecificResources$StatementTemplate = function (a) {
	return {$: 19, a: a};
};
var $author$project$App$Service$SpecificResources$Taxes = F2(
	function (a, b) {
		return {$: 31, a: a, b: b};
	});
var $author$project$App$Service$SpecificResources$Token = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$App$Service$SpecificResources$TypeDocument = {$: 18};
var $author$project$App$Service$SpecificResources$User = function (a) {
	return {$: 22, a: a};
};
var $author$project$App$Service$SpecificResources$Version = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Data$ICD$Category = F2(
	function (code, description) {
		return {gu: code, am: description};
	});
var $author$project$App$Data$ICD$categoryDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'description',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'code',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$App$Data$ICD$Category)));
var $author$project$App$Service$SpecificResources$collectionDataDecoder = function (field) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (mElm) {
			if (!mElm.$) {
				var elm = mElm.a;
				return $elm$json$Json$Decode$succeed(elm);
			} else {
				return $elm$json$Json$Decode$fail('invalid payload');
			}
		},
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$List$head,
			A2(
				$elm$json$Json$Decode$field,
				field,
				$elm$json$Json$Decode$keyValuePairs(
					$elm$json$Json$Decode$list($author$project$App$Service$SpecificResources$ridDecoder)))));
};
var $author$project$App$Service$SpecificResources$collectionDecoder = $author$project$App$Service$SpecificResources$collectionDataDecoder('collections');
var $author$project$App$Data$Company$Model = F4(
	function (id, document, officialName, commercialName) {
		return {iR: commercialName, i1: document, as: id, hs: officialName};
	});
var $author$project$App$Data$Company$decode = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'commercialName',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'officialName',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'document',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$author$project$App$Data$Id$uuidDecoder,
				$elm$json$Json$Decode$succeed($author$project$App$Data$Company$Model)))));
var $author$project$App$Data$Conference$Model = function (id) {
	return function (stun) {
		return function (turn) {
			return function (signaler) {
				return function (password) {
					return function (stunUser) {
						return function (stunPassword) {
							return function (recordingDoctor) {
								return function (recordingProponent) {
									return function (createdAt) {
										return {iW: createdAt, as: id, bf: password, jQ: recordingDoctor, jR: recordingProponent, dM: signaler, dN: stun, ie: stunPassword, $8: stunUser, dT: turn};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$Conference$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'createdAt',
	$author$project$App$Data$Id$timeDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'recordingProponent',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'recordingDoctor',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'stunPassword',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'stunUser',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'password',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'signaler',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'turn',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'stun',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'id',
										$author$project$App$Data$Id$uuidDecoder,
										$elm$json$Json$Decode$succeed($author$project$App$Data$Conference$Model)))))))))));
var $author$project$App$Data$Contact$Model = F4(
	function (id, ownerId, kind, value) {
		return {as: id, hb: kind, jJ: ownerId, T: value};
	});
var $author$project$App$Data$Contact$kindDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		return $elm$json$Json$Decode$succeed(
			$author$project$App$Data$Contact$kindFromString(str));
	},
	$elm$json$Json$Decode$string);
var $author$project$App$Data$Contact$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'value',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'kind',
		$author$project$App$Data$Contact$kindDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'ownerId',
			$author$project$App$Data$Id$uuidDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$author$project$App$Data$Id$uuidDecoder,
				$elm$json$Json$Decode$succeed($author$project$App$Data$Contact$Model)))));
var $author$project$App$Data$DoctorTemplate$Model = F7(
	function (id, doctorId, templateId, doctorAmount, doctorCharge, updatedAt, createdAt) {
		return {iW: createdAt, c2: doctorAmount, c3: doctorCharge, fl: doctorId, as: id, eY: templateId, f2: updatedAt};
	});
var $author$project$App$Data$DoctorTemplate$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'updatedAt',
	$author$project$App$Data$Id$timeDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'createdAt',
		$author$project$App$Data$Id$timeDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'doctorCharge',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'doctorAmount',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'templateId',
					$author$project$App$Data$Id$uuidDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'doctorId',
						$author$project$App$Data$Id$uuidDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$author$project$App$Data$Id$uuidDecoder,
							$elm$json$Json$Decode$succeed($author$project$App$Data$DoctorTemplate$Model))))))));
var $author$project$App$Data$Document$Model = function (id) {
	return function (mime) {
		return function (name) {
			return function (ownerId) {
				return function (ownerKind) {
					return function (personId) {
						return function (content) {
							return function (objectUrl) {
								return function (objectUrlRequested) {
									return function (docType) {
										return function (updatedAt) {
											return function (createdAt) {
												return {iU: content, iW: createdAt, b7: docType, as: id, dr: mime, hl: name, af: objectUrl, Q: objectUrlRequested, jJ: ownerId, X: ownerKind, Y: personId, f2: updatedAt};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$Document$typeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (i) {
		return $elm$json$Json$Decode$succeed(
			$author$project$App$Data$Document$typeFromInt(i));
	},
	$elm$json$Json$Decode$int);
var $author$project$App$Data$Document$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'updatedAt',
	$author$project$App$Data$Id$timeDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'createdAt',
		$author$project$App$Data$Id$timeDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'docType',
			$author$project$App$Data$Document$typeDecoder,
			A2(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
				false,
				A2(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
					'',
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'content',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'personId',
							$author$project$App$Data$Id$uuidDecoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'ownerKind',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'ownerId',
									$author$project$App$Data$Id$uuidDecoder,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'name',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'mime',
											$elm$json$Json$Decode$string,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'id',
												$author$project$App$Data$Id$uuidDecoder,
												$elm$json$Json$Decode$succeed($author$project$App$Data$Document$Model)))))))))))));
var $author$project$App$Data$FullQuestionSection$Model = F2(
	function (section, questions) {
		return {eI: questions, hX: section};
	});
var $author$project$App$Data$FullQuestion$Model = F3(
	function (question, answerTemplates, answers) {
		return {bY: answerTemplates, aZ: answers, hG: question};
	});
var $author$project$App$Data$AnswerTemplate$Model = function (id) {
	return function (questionId) {
		return function (type_) {
			return function (label) {
				return function (unit) {
					return function (businessValue) {
						return function (mask) {
							return function (validation) {
								return function (nextSectionId) {
									return function (nextSectionIndex) {
										return function (updatedAt) {
											return function (index) {
												return {ay: businessValue, as: id, bB: index, jh: label, jj: mask, cr: nextSectionId, hp: nextSectionIndex, bh: questionId, j5: type_, iv: unit, f2: updatedAt, iA: validation};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$AnswerTemplate$MaskDecimal = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Data$AnswerTemplate$MaskHeight = {$: 1};
var $author$project$App$Data$AnswerTemplate$MaskPressure = {$: 2};
var $author$project$App$Data$AnswerTemplate$MaskWeight = {$: 0};
var $author$project$App$Data$AnswerTemplate$maskFromString = function (str) {
	switch (str) {
		case 'weight':
			return $elm$core$Maybe$Just($author$project$App$Data$AnswerTemplate$MaskWeight);
		case 'height':
			return $elm$core$Maybe$Just($author$project$App$Data$AnswerTemplate$MaskHeight);
		case 'pressure':
			return $elm$core$Maybe$Just($author$project$App$Data$AnswerTemplate$MaskPressure);
		case 'decimal:1':
			return $elm$core$Maybe$Just(
				$author$project$App$Data$AnswerTemplate$MaskDecimal(1));
		case 'decimal:2':
			return $elm$core$Maybe$Just(
				$author$project$App$Data$AnswerTemplate$MaskDecimal(2));
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Data$AnswerTemplate$decodeMask = A2($elm$json$Json$Decode$map, $author$project$App$Data$AnswerTemplate$maskFromString, $elm$json$Json$Decode$string);
var $author$project$App$Data$AnswerTemplate$TypeDate = 5;
var $author$project$App$Data$AnswerTemplate$TypeNumeric = 3;
var $author$project$App$Data$AnswerTemplate$TypeString = 2;
var $author$project$App$Data$AnswerTemplate$TypeTableRow = 7;
var $author$project$App$Data$AnswerTemplate$TypeTextBox = 8;
var $author$project$App$Data$AnswerTemplate$typeFromString = function (t) {
	switch (t) {
		case 'option':
			return 1;
		case 'string':
			return 2;
		case 'numeric':
			return 3;
		case 'option-string':
			return 4;
		case 'date':
			return 5;
		case 'option-check':
			return 6;
		case 'table-row':
			return 7;
		case 'text-box':
			return 8;
		default:
			return 0;
	}
};
var $author$project$App$Data$AnswerTemplate$decodeType = A2($elm$json$Json$Decode$map, $author$project$App$Data$AnswerTemplate$typeFromString, $elm$json$Json$Decode$string);
var $author$project$App$Data$AnswerTemplate$decoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'index',
	$elm$json$Json$Decode$int,
	0,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'updatedAt',
		$author$project$App$Data$Id$timeDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'nextSectionIndex',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'nextSectionId',
				$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'validation',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'mask',
						$author$project$App$Data$AnswerTemplate$decodeMask,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'businessValue',
							$elm$json$Json$Decode$int,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'unit',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
								$elm$core$Maybe$Nothing,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'label',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'type',
										$author$project$App$Data$AnswerTemplate$decodeType,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'questionId',
											$author$project$App$Data$Id$uuidDecoder,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'id',
												$author$project$App$Data$Id$uuidDecoder,
												$elm$json$Json$Decode$succeed($author$project$App$Data$AnswerTemplate$Model)))))))))))));
var $author$project$App$Data$Question$Model = function (id) {
	return function (sectionId) {
		return function (index) {
			return function (text) {
				return function (type_) {
					return function (businessValue) {
						return function (required) {
							return function (details) {
								return function (medicalClass) {
									return function (updatedAt) {
										return {ay: businessValue, gD: details, as: id, bB: index, fE: medicalClass, hN: required, hY: sectionId, bP: text, j5: type_, f2: updatedAt};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$Question$TypeChoice = 2;
var $author$project$App$Data$Question$TypeMultiple = 3;
var $author$project$App$Data$Question$TypeString = 1;
var $author$project$App$Data$Question$TypeTable = 4;
var $author$project$App$Data$Question$TypeTextBox = 5;
var $author$project$App$Data$Question$typeFromString = function (t) {
	switch (t) {
		case 'string':
			return 1;
		case 'choice':
			return 2;
		case 'multiple':
			return 3;
		case 'table':
			return 4;
		case 'text-box':
			return 5;
		default:
			return 0;
	}
};
var $author$project$App$Data$Question$decodeType = A2($elm$json$Json$Decode$map, $author$project$App$Data$Question$typeFromString, $elm$json$Json$Decode$string);
var $author$project$App$Data$Question$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'updatedAt',
	$author$project$App$Data$Id$timeDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'medicalClass',
		$elm$json$Json$Decode$int,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'details',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'required',
				$elm$json$Json$Decode$bool,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'businessValue',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'type',
						$author$project$App$Data$Question$decodeType,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'text',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'index',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'sectionId',
									$author$project$App$Data$Id$uuidDecoder,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'id',
										$author$project$App$Data$Id$uuidDecoder,
										$elm$json$Json$Decode$succeed($author$project$App$Data$Question$Model)))))))))));
var $author$project$App$Data$FullQuestion$decoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'answers',
	$elm$json$Json$Decode$list($author$project$App$Data$Answer$decoder),
	_List_Nil,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'answerTemplates',
		$elm$json$Json$Decode$list($author$project$App$Data$AnswerTemplate$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'question',
			$author$project$App$Data$Question$decoder,
			$elm$json$Json$Decode$succeed($author$project$App$Data$FullQuestion$Model))));
var $author$project$App$Data$QuestionSection$Model = F8(
	function (id, templateId, nextSectionId, index, nextSectionIndex, name, category, updatedAt) {
		return {a0: category, as: id, bB: index, hl: name, cr: nextSectionId, hp: nextSectionIndex, eY: templateId, f2: updatedAt};
	});
var $author$project$App$Data$QuestionSection$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'updatedAt',
	$author$project$App$Data$Id$timeDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'category',
		$author$project$App$Data$QuestionSection$categoryDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'nextSectionIndex',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'index',
					$elm$json$Json$Decode$int,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'nextSectionId',
						$elm$json$Json$Decode$maybe($author$project$App$Data$Id$uuidDecoder),
						$elm$core$Maybe$Nothing,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'templateId',
							$author$project$App$Data$Id$uuidDecoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'id',
								$author$project$App$Data$Id$uuidDecoder,
								$elm$json$Json$Decode$succeed($author$project$App$Data$QuestionSection$Model)))))))));
var $author$project$App$Data$FullQuestionSection$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'questions',
	$elm$json$Json$Decode$list($author$project$App$Data$FullQuestion$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'section',
		$author$project$App$Data$QuestionSection$decoder,
		$elm$json$Json$Decode$succeed($author$project$App$Data$FullQuestionSection$Model)));
var $author$project$App$Data$Holiday$Model = F6(
	function (id, ownerId, ownerType, startDate, endDate, textForUsers) {
		return {bw: endDate, as: id, jJ: ownerId, fL: ownerType, bN: startDate, f$: textForUsers};
	});
var $author$project$App$Data$Holiday$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'textForUsers',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'endDate',
		$author$project$App$Data$Id$timeDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'startDate',
			$author$project$App$Data$Id$timeDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'ownerType',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'ownerId',
					$author$project$App$Data$Id$uuidDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$author$project$App$Data$Id$uuidDecoder,
						$elm$json$Json$Decode$succeed($author$project$App$Data$Holiday$Model)))))));
var $author$project$App$Data$Memed$Model = F2(
	function (token, params) {
		return {du: params, e$: token};
	});
var $author$project$App$Data$Memed$paramsDecoder = $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing);
var $author$project$App$Data$Memed$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'uf',
	$author$project$App$Data$Memed$paramsDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'token',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$App$Data$Memed$Model)));
var $author$project$App$Data$MemedRegister$IdModel = F2(
	function (id, attributes) {
		return {iL: attributes, as: id};
	});
var $author$project$App$Data$MemedRegister$Attributes = function (name) {
	return {hl: name};
};
var $author$project$App$Data$MemedRegister$attributesDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'nome',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$App$Data$MemedRegister$Attributes));
var $author$project$App$Data$MemedRegister$decodeModel = A3(
	$elm$json$Json$Decode$map2,
	$author$project$App$Data$MemedRegister$IdModel,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'attributes', $author$project$App$Data$MemedRegister$attributesDecoder));
var $author$project$App$Data$MemedRegister$decoder = $elm$json$Json$Decode$list($author$project$App$Data$MemedRegister$decodeModel);
var $author$project$App$Data$Nexodata$Model = function (token) {
	return {e$: token};
};
var $author$project$App$Data$Nexodata$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'token',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$App$Data$Nexodata$Model));
var $author$project$App$Data$ReportDetails$Model = F9(
	function (description, date, doctor, value, questionValue, doctorValue, statementType, template, companyId) {
		return {gx: companyId, gA: date, am: description, bu: doctor, gI: doctorValue, hH: questionValue, h9: statementType, cJ: template, T: value};
	});
var $author$project$App$Data$ReportDetails$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'companyId',
	$author$project$App$Data$Id$uuidDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'template',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'type',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'doctorValue',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'questionValue',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'value',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'doctor',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'date',
								$author$project$App$Data$Id$timeDecoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'name',
									$elm$json$Json$Decode$string,
									$elm$json$Json$Decode$succeed($author$project$App$Data$ReportDetails$Model))))))))));
var $author$project$App$Data$ReportSummary$Model = F7(
	function (id, month, year, dueDate, status, totalValue, liquidValue) {
		return {gL: dueDate, as: id, hf: liquidValue, hk: month, aw: status, iq: totalValue, iF: year};
	});
var $author$project$App$Data$ReportSummary$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'liquidValue',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'totalValue',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'status',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'dueDate',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'year',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'month',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$author$project$App$Data$Id$uuidDecoder,
							$elm$json$Json$Decode$succeed($author$project$App$Data$ReportSummary$Model))))))));
var $author$project$App$Data$Taxes$Model = F4(
	function (id, name, millipercent, isActive) {
		return {as: id, g7: isActive, hj: millipercent, hl: name};
	});
var $author$project$App$Data$Taxes$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'isActive',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'millipercent',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$author$project$App$Data$Id$uuidDecoder,
				$elm$json$Json$Decode$succeed($author$project$App$Data$Taxes$Model)))));
var $author$project$App$Data$FullStatement$FullStatement = function (bearer) {
	return function (deps) {
		return function (docs) {
			return function (personSections) {
				return function (doctorSections) {
					return function (companySections) {
						return function (removedDocs) {
							return function (template) {
								return function (company) {
									return function (link) {
										return {g: bearer, b4: company, bs: companySections, fh: deps, an: docs, gH: doctorSections, he: link, jM: personSections, fO: removedDocs, cJ: template};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$FullStatement$dictToIntKeyDecoder = function (decoder) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$json$Json$Decode$list,
		$elm$json$Json$Decode$map(
			function (list) {
				return $elm$core$Dict$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (idx, v) {
								return _Utils_Tuple2(idx, v);
							}),
						list));
			}))(decoder);
};
var $author$project$App$Data$FullStatement$EditStatement = function (model) {
	return function (person) {
		return function (contacts) {
			return function (answers) {
				return function (doctorAnswers) {
					return function (companyAnswers) {
						return function (ansDoctor) {
							return function (ansCompany) {
								return function (ansProp) {
									return function (ansICD) {
										return function (icds) {
											return {gc: ansCompany, gd: ansDoctor, ge: ansICD, gf: ansProp, aZ: answers, gw: companyAnswers, ea: contacts, gE: doctorAnswers, fx: icds, e: model, dw: person};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Data$FullStatement$editStatementDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
	$elm$core$Dict$empty,
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
		$elm$core$Dict$empty,
		A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
			$elm$core$Dict$empty,
			A2(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
				$elm$core$Dict$empty,
				A2(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
					$elm$core$Dict$empty,
					A2(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
						$elm$core$Dict$empty,
						A2(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
							$elm$core$Dict$empty,
							A2(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
								$elm$core$Dict$empty,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'contacts',
									$elm$json$Json$Decode$dict($author$project$App$Data$Contact$decoder),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'person',
										$author$project$App$Data$Person$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'model',
											$author$project$App$Data$Statement$decoder,
											$elm$json$Json$Decode$succeed($author$project$App$Data$FullStatement$EditStatement))))))))))));
var $author$project$App$Data$FullStatement$fullStatementDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
	$elm$core$Maybe$Nothing,
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
		$author$project$App$Data$Company$emptyModel,
		A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
			$author$project$App$Data$StatementTemplate$emptyModel,
			A2(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
				_List_Nil,
				A2(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
					_List_Nil,
					A2(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
						_List_Nil,
						A2(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
							_List_Nil,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'documents',
								$author$project$App$Data$FullStatement$dictToIntKeyDecoder($author$project$App$Data$Document$decoder),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'deps',
									$author$project$App$Data$FullStatement$dictToIntKeyDecoder($author$project$App$Data$FullStatement$editStatementDecoder),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'bearer',
										$author$project$App$Data$FullStatement$editStatementDecoder,
										$elm$json$Json$Decode$succeed($author$project$App$Data$FullStatement$FullStatement)))))))))));
var $author$project$App$Service$SpecificResources$modelsDecoder = function (dec) {
	return A2(
		$elm$json$Json$Decode$field,
		'models',
		$elm$json$Json$Decode$dict(dec));
};
var $author$project$App$Service$SpecificResources$versionDecoder = A2($elm$json$Json$Decode$field, 'protocol', $elm$json$Json$Decode$string);
var $author$project$App$Service$SpecificResources$getSpecificResourceDecoded = F2(
	function (t, value) {
		switch (t.$) {
			case 0:
				return $elm$core$Result$Ok($elm$core$Dict$empty);
			case 1:
				var time = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$Version(time),
						$elm$core$Dict$singleton('version')),
					A2($elm$json$Json$Decode$decodeValue, $author$project$App$Service$SpecificResources$versionDecoder, value));
			case 2:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$Token(method),
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2($elm$json$Json$Decode$decodeValue, $elm$json$Json$Decode$value, value));
			case 3:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$SimpleText(method),
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2($elm$json$Json$Decode$decodeValue, $elm$json$Json$Decode$string, value));
			case 5:
				return A2(
					$elm$core$Result$map,
					$elm$core$Dict$map(
						F2(
							function (_v1, v) {
								return $author$project$App$Service$SpecificResources$Answer(v);
							})),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Answer$decoder),
						value));
			case 4:
				return A2(
					$elm$core$Result$map,
					function (dict) {
						return A3(
							$elm$core$Dict$foldr,
							F3(
								function (rid, v, d) {
									return A3($elm$core$Dict$insert, rid, v, d);
								}),
							dict,
							A2(
								$elm$core$Result$withDefault,
								$elm$core$Dict$empty,
								A2(
									$elm$core$Result$map,
									$elm$core$Dict$map(
										F2(
											function (_v3, v) {
												return $author$project$App$Service$SpecificResources$Answer(v);
											})),
									A2(
										$elm$json$Json$Decode$decodeValue,
										$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Answer$decoder),
										value))));
					},
					A2(
						$elm$core$Result$map,
						function (_v2) {
							var rid = _v2.a;
							var elms = _v2.b;
							return A2(
								$elm$core$Dict$singleton,
								rid,
								A2($author$project$App$Service$SpecificResources$Collection, $author$project$App$Service$SpecificResources$TypeAnswer, elms));
						},
						A2($elm$json$Json$Decode$decodeValue, $author$project$App$Service$SpecificResources$collectionDecoder, value)));
			case 7:
				return A2(
					$elm$core$Result$map,
					$elm$core$Dict$map(
						F2(
							function (_v4, v) {
								return $author$project$App$Service$SpecificResources$Company(v);
							})),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Company$decode),
						value));
			case 6:
				return A2(
					$elm$core$Result$map,
					function (dict) {
						return A3(
							$elm$core$Dict$foldr,
							F3(
								function (rid, v, d) {
									return A3($elm$core$Dict$insert, rid, v, d);
								}),
							dict,
							A2(
								$elm$core$Result$withDefault,
								$elm$core$Dict$empty,
								A2(
									$elm$core$Result$map,
									$elm$core$Dict$map(
										F2(
											function (_v6, v) {
												return $author$project$App$Service$SpecificResources$Company(v);
											})),
									A2(
										$elm$json$Json$Decode$decodeValue,
										$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Company$decode),
										value))));
					},
					A2(
						$elm$core$Result$map,
						function (_v5) {
							var rid = _v5.a;
							var elms = _v5.b;
							return A2(
								$elm$core$Dict$singleton,
								rid,
								A2($author$project$App$Service$SpecificResources$Collection, $author$project$App$Service$SpecificResources$TypeCompany, elms));
						},
						A2($elm$json$Json$Decode$decodeValue, $author$project$App$Service$SpecificResources$collectionDecoder, value)));
			case 8:
				return A2(
					$elm$core$Result$map,
					$elm$core$Dict$map(
						F2(
							function (_v7, v) {
								return $author$project$App$Service$SpecificResources$Conference(v);
							})),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Conference$decoder),
						value));
			case 9:
				return A2(
					$elm$core$Result$map,
					$elm$core$Dict$map(
						F2(
							function (_v8, v) {
								return $author$project$App$Service$SpecificResources$Contact(v);
							})),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Contact$decoder),
						value));
			case 11:
				return A2(
					$elm$core$Result$map,
					$elm$core$Dict$map(
						F2(
							function (_v9, v) {
								return $author$project$App$Service$SpecificResources$Doctor(v);
							})),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Doctor$decoder),
						value));
			case 10:
				return A2(
					$elm$core$Result$map,
					function (dict) {
						return A3(
							$elm$core$Dict$foldr,
							F3(
								function (rid, v, d) {
									return A3($elm$core$Dict$insert, rid, v, d);
								}),
							dict,
							A2(
								$elm$core$Result$withDefault,
								$elm$core$Dict$empty,
								A2(
									$elm$core$Result$map,
									$elm$core$Dict$map(
										F2(
											function (_v11, v) {
												return $author$project$App$Service$SpecificResources$Doctor(v);
											})),
									A2(
										$elm$json$Json$Decode$decodeValue,
										$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Doctor$decoder),
										value))));
					},
					A2(
						$elm$core$Result$map,
						function (_v10) {
							var rid = _v10.a;
							var elms = _v10.b;
							return A2(
								$elm$core$Dict$singleton,
								rid,
								A2($author$project$App$Service$SpecificResources$Collection, $author$project$App$Service$SpecificResources$TypeDoctor, elms));
						},
						A2($elm$json$Json$Decode$decodeValue, $author$project$App$Service$SpecificResources$collectionDecoder, value)));
			case 12:
				return A2(
					$elm$core$Result$map,
					$elm$core$Dict$map(
						F2(
							function (_v12, v) {
								return $author$project$App$Service$SpecificResources$ICD(v);
							})),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$ICD$decoder),
						value));
			case 13:
				return A2(
					$elm$core$Result$map,
					function (dict) {
						return A3(
							$elm$core$Dict$foldr,
							F3(
								function (rid, v, d) {
									return A3($elm$core$Dict$insert, rid, v, d);
								}),
							dict,
							A2(
								$elm$core$Result$withDefault,
								$elm$core$Dict$empty,
								A2(
									$elm$core$Result$map,
									$elm$core$Dict$map(
										F2(
											function (_v14, v) {
												return $author$project$App$Service$SpecificResources$ICD(v);
											})),
									A2(
										$elm$json$Json$Decode$decodeValue,
										$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$ICD$decoder),
										value))));
					},
					A2(
						$elm$core$Result$map,
						function (_v13) {
							var rid = _v13.a;
							var elms = _v13.b;
							return A2(
								$elm$core$Dict$singleton,
								rid,
								A2($author$project$App$Service$SpecificResources$Collection, $author$project$App$Service$SpecificResources$TypeICD, elms));
						},
						A2($elm$json$Json$Decode$decodeValue, $author$project$App$Service$SpecificResources$collectionDecoder, value)));
			case 14:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$ICDCategory(method),
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$elm$json$Json$Decode$list($author$project$App$Data$ICD$categoryDecoder),
						value));
			case 26:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$QuestionSectionFull(method),
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$elm$json$Json$Decode$list($author$project$App$Data$FullQuestionSection$decoder),
						value));
			case 31:
				return A2(
					$elm$core$Result$map,
					function (dict) {
						return A3(
							$elm$core$Dict$foldr,
							F3(
								function (rid, v, d) {
									return A3($elm$core$Dict$insert, rid, v, d);
								}),
							dict,
							A2(
								$elm$core$Result$withDefault,
								$elm$core$Dict$empty,
								A2(
									$elm$core$Result$map,
									$elm$core$Dict$map(
										F2(
											function (_v16, v) {
												return $author$project$App$Service$SpecificResources$StatementTemplate(v);
											})),
									A2(
										$elm$json$Json$Decode$decodeValue,
										$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$StatementTemplate$decoder),
										value))));
					},
					A2(
						$elm$core$Result$map,
						function (_v15) {
							var rid = _v15.a;
							var elms = _v15.b;
							return A2(
								$elm$core$Dict$singleton,
								rid,
								A2($author$project$App$Service$SpecificResources$Collection, $author$project$App$Service$SpecificResources$TypeStatementTemplate, elms));
						},
						A2($elm$json$Json$Decode$decodeValue, $author$project$App$Service$SpecificResources$collectionDecoder, value)));
			case 32:
				return A2(
					$elm$core$Result$map,
					$elm$core$Dict$map(
						F2(
							function (_v17, v) {
								return $author$project$App$Service$SpecificResources$StatementTemplate(v);
							})),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$StatementTemplate$decoder),
						value));
			case 28:
				return A2(
					$elm$core$Result$map,
					function (dict) {
						return A3(
							$elm$core$Dict$foldr,
							F3(
								function (rid, v, d) {
									return A3($elm$core$Dict$insert, rid, v, d);
								}),
							dict,
							A2(
								$elm$core$Result$withDefault,
								$elm$core$Dict$empty,
								A2(
									$elm$core$Result$map,
									$elm$core$Dict$map(
										F2(
											function (_v19, v) {
												return $author$project$App$Service$SpecificResources$Statement(v);
											})),
									A2(
										$elm$json$Json$Decode$decodeValue,
										$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Statement$decoder),
										value))));
					},
					A2(
						$elm$core$Result$map,
						function (_v18) {
							var rid = _v18.a;
							var elms = _v18.b;
							return A2(
								$elm$core$Dict$singleton,
								rid,
								A2($author$project$App$Service$SpecificResources$Collection, $author$project$App$Service$SpecificResources$TypeStatement, elms));
						},
						A2($elm$json$Json$Decode$decodeValue, $author$project$App$Service$SpecificResources$collectionDecoder, value)));
			case 27:
				return A2(
					$elm$core$Result$map,
					$elm$core$Dict$map(
						F2(
							function (_v20, v) {
								return $author$project$App$Service$SpecificResources$Statement(v);
							})),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Statement$decoder),
						value));
			case 29:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$StatementResult(method),
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2($elm$json$Json$Decode$decodeValue, $author$project$App$Data$Statement$decoder, value));
			case 30:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$StatementFull(method),
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2($elm$json$Json$Decode$decodeValue, $author$project$App$Data$FullStatement$fullStatementDecoder, value));
			case 38:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$DoctorTemplate(method),
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2($elm$json$Json$Decode$decodeValue, $author$project$App$Data$DoctorTemplate$decoder, value));
			case 17:
				return A2(
					$elm$core$Result$map,
					function (dict) {
						return A3(
							$elm$core$Dict$foldr,
							F3(
								function (rid, v, d) {
									return A3($elm$core$Dict$insert, rid, v, d);
								}),
							dict,
							A2(
								$elm$core$Result$withDefault,
								$elm$core$Dict$empty,
								A2(
									$elm$core$Result$map,
									$elm$core$Dict$map(
										F2(
											function (_v22, v) {
												return $author$project$App$Service$SpecificResources$Document(v);
											})),
									A2(
										$elm$json$Json$Decode$decodeValue,
										$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Document$decoder),
										value))));
					},
					A2(
						$elm$core$Result$map,
						function (_v21) {
							var rid = _v21.a;
							var elms = _v21.b;
							return A2(
								$elm$core$Dict$singleton,
								rid,
								A2($author$project$App$Service$SpecificResources$Collection, $author$project$App$Service$SpecificResources$TypeDocument, elms));
						},
						A2($elm$json$Json$Decode$decodeValue, $author$project$App$Service$SpecificResources$collectionDecoder, value)));
			case 18:
				return A2(
					$elm$core$Result$map,
					$elm$core$Dict$map(
						F2(
							function (_v23, v) {
								return $author$project$App$Service$SpecificResources$Document(v);
							})),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Document$decoder),
						value));
			case 20:
				return A2(
					$elm$core$Result$map,
					function (dict) {
						return A3(
							$elm$core$Dict$foldr,
							F3(
								function (rid, v, d) {
									return A3($elm$core$Dict$insert, rid, v, d);
								}),
							dict,
							A2(
								$elm$core$Result$withDefault,
								$elm$core$Dict$empty,
								A2(
									$elm$core$Result$map,
									$elm$core$Dict$map(
										F2(
											function (_v25, v) {
												return $author$project$App$Service$SpecificResources$Holiday(v);
											})),
									A2(
										$elm$json$Json$Decode$decodeValue,
										$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Holiday$decoder),
										value))));
					},
					A2(
						$elm$core$Result$map,
						function (_v24) {
							var rid = _v24.a;
							var elms = _v24.b;
							return A2(
								$elm$core$Dict$singleton,
								rid,
								A2($author$project$App$Service$SpecificResources$Collection, $author$project$App$Service$SpecificResources$TypeHoliday, elms));
						},
						A2($elm$json$Json$Decode$decodeValue, $author$project$App$Service$SpecificResources$collectionDecoder, value)));
			case 21:
				return A2(
					$elm$core$Result$map,
					$elm$core$Dict$map(
						F2(
							function (_v26, v) {
								return $author$project$App$Service$SpecificResources$Holiday(v);
							})),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Holiday$decoder),
						value));
			case 19:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$Document,
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2($elm$json$Json$Decode$decodeValue, $author$project$App$Data$Document$decoder, value));
			case 22:
				return A2(
					$elm$core$Result$map,
					function (dict) {
						return A3(
							$elm$core$Dict$foldr,
							F3(
								function (rid, v, d) {
									return A3($elm$core$Dict$insert, rid, v, d);
								}),
							dict,
							A2(
								$elm$core$Result$withDefault,
								$elm$core$Dict$empty,
								A2(
									$elm$core$Result$map,
									$elm$core$Dict$map(
										F2(
											function (_v28, v) {
												return $author$project$App$Service$SpecificResources$Person(v);
											})),
									A2(
										$elm$json$Json$Decode$decodeValue,
										$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Person$decoder),
										value))));
					},
					A2(
						$elm$core$Result$map,
						function (_v27) {
							var rid = _v27.a;
							var elms = _v27.b;
							return A2(
								$elm$core$Dict$singleton,
								rid,
								A2($author$project$App$Service$SpecificResources$Collection, $author$project$App$Service$SpecificResources$TypePerson, elms));
						},
						A2($elm$json$Json$Decode$decodeValue, $author$project$App$Service$SpecificResources$collectionDecoder, value)));
			case 23:
				return A2(
					$elm$core$Result$map,
					$elm$core$Dict$map(
						F2(
							function (_v29, v) {
								return $author$project$App$Service$SpecificResources$Person(v);
							})),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Person$decoder),
						value));
			case 39:
				return A2(
					$elm$core$Result$map,
					function (dict) {
						return A3(
							$elm$core$Dict$foldr,
							F3(
								function (rid, v, d) {
									return A3($elm$core$Dict$insert, rid, v, d);
								}),
							dict,
							A2(
								$elm$core$Result$withDefault,
								$elm$core$Dict$empty,
								A2(
									$elm$core$Result$map,
									$elm$core$Dict$map(
										F2(
											function (_v31, v) {
												return $author$project$App$Service$SpecificResources$StatementMessage(v);
											})),
									A2(
										$elm$json$Json$Decode$decodeValue,
										$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$StatementMessage$decoder),
										value))));
					},
					A2(
						$elm$core$Result$map,
						function (_v30) {
							var rid = _v30.a;
							var elms = _v30.b;
							return A2(
								$elm$core$Dict$singleton,
								rid,
								A2($author$project$App$Service$SpecificResources$Collection, $author$project$App$Service$SpecificResources$TypeStatementMessage, elms));
						},
						A2($elm$json$Json$Decode$decodeValue, $author$project$App$Service$SpecificResources$collectionDecoder, value)));
			case 40:
				return A2(
					$elm$core$Result$map,
					$elm$core$Dict$map(
						F2(
							function (_v32, v) {
								return $author$project$App$Service$SpecificResources$Person(v);
							})),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Person$decoder),
						value));
			case 24:
				return A2(
					$elm$core$Result$map,
					function (dict) {
						return A3(
							$elm$core$Dict$foldr,
							F3(
								function (rid, v, d) {
									return A3($elm$core$Dict$insert, rid, v, d);
								}),
							dict,
							A2(
								$elm$core$Result$withDefault,
								$elm$core$Dict$empty,
								A2(
									$elm$core$Result$map,
									$elm$core$Dict$map(
										F2(
											function (_v34, v) {
												return $author$project$App$Service$SpecificResources$WaitingPerson(v);
											})),
									A2(
										$elm$json$Json$Decode$decodeValue,
										$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Person$waitingDecoder),
										value))));
					},
					A2(
						$elm$core$Result$map,
						function (_v33) {
							var rid = _v33.a;
							var elms = _v33.b;
							return A2(
								$elm$core$Dict$singleton,
								rid,
								A2($author$project$App$Service$SpecificResources$Collection, $author$project$App$Service$SpecificResources$TypeWaitingPerson, elms));
						},
						A2($elm$json$Json$Decode$decodeValue, $author$project$App$Service$SpecificResources$collectionDecoder, value)));
			case 25:
				return A2(
					$elm$core$Result$map,
					$elm$core$Dict$map(
						F2(
							function (_v35, v) {
								return $author$project$App$Service$SpecificResources$WaitingPerson(v);
							})),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$Person$waitingDecoder),
						value));
			case 33:
				return A2(
					$elm$core$Result$map,
					$elm$core$Dict$map(
						F2(
							function (_v36, v) {
								return $author$project$App$Service$SpecificResources$User(v);
							})),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$User$decoder),
						value));
			case 34:
				return A2(
					$elm$core$Result$map,
					function (dict) {
						return A3(
							$elm$core$Dict$foldr,
							F3(
								function (rid, v, d) {
									return A3($elm$core$Dict$insert, rid, v, d);
								}),
							dict,
							A2(
								$elm$core$Result$withDefault,
								$elm$core$Dict$empty,
								A2(
									$elm$core$Result$map,
									$elm$core$Dict$map(
										F2(
											function (_v38, v) {
												return $author$project$App$Service$SpecificResources$User(v);
											})),
									A2(
										$elm$json$Json$Decode$decodeValue,
										$author$project$App$Service$SpecificResources$modelsDecoder($author$project$App$Data$User$decoder),
										value))));
					},
					A2(
						$elm$core$Result$map,
						function (_v37) {
							var rid = _v37.a;
							var elms = _v37.b;
							return A2(
								$elm$core$Dict$singleton,
								rid,
								A2($author$project$App$Service$SpecificResources$Collection, $author$project$App$Service$SpecificResources$TypeUser, elms));
						},
						A2($elm$json$Json$Decode$decodeValue, $author$project$App$Service$SpecificResources$collectionDecoder, value)));
			case 35:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$User,
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2($elm$json$Json$Decode$decodeValue, $author$project$App$Data$User$decoder, value));
			case 15:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$MemedToken(method),
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2($elm$json$Json$Decode$decodeValue, $author$project$App$Data$Memed$decoder, value));
			case 16:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$NexodataToken(method),
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2($elm$json$Json$Decode$decodeValue, $author$project$App$Data$Nexodata$decoder, value));
			case 36:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$MemedCities(method),
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2($elm$json$Json$Decode$decodeValue, $author$project$App$Data$MemedRegister$decoder, value));
			case 37:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$MemedSpecialties(method),
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2($elm$json$Json$Decode$decodeValue, $author$project$App$Data$MemedRegister$decoder, value));
			case 41:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$ReportSummaries(method),
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$elm$json$Json$Decode$list($author$project$App$Data$ReportSummary$decoder),
						value));
			case 43:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$ReportMonthDetails(method),
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$elm$json$Json$Decode$list($author$project$App$Data$ReportDetails$decoder),
						value));
			default:
				var method = t.a;
				return A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$App$Service$SpecificResources$Taxes(method),
						$elm$core$Dict$singleton(
							$author$project$App$Service$Shared$methodToString(method))),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$elm$json$Json$Decode$list($author$project$App$Data$Taxes$decoder),
						value));
		}
	});
var $author$project$App$Service$SpecificResources$TypeHolidayCollection = {$: 20};
var $author$project$App$Service$SpecificResources$typeFromSpecificResource = function (specific) {
	switch (specific.$) {
		case 0:
			return $author$project$App$Service$SpecificResources$TypeUnknown;
		case 1:
			return $author$project$App$Service$SpecificResources$TypeVersion(
				$elm$time$Time$millisToPosix(0));
		case 2:
			var rid = specific.a;
			return $author$project$App$Service$SpecificResources$TypeToken(rid);
		case 3:
			var rid = specific.a;
			return $author$project$App$Service$SpecificResources$TypeSimpleString(rid);
		case 4:
			switch (specific.a.$) {
				case 0:
					var _v1 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeUnknown;
				case 1:
					var v = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeVersion(v);
				case 2:
					var t = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeToken(t);
				case 3:
					var m = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeSimpleString(m);
				case 4:
					var _v2 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeAnswer;
				case 5:
					var _v3 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeAnswer;
				case 7:
					var _v4 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeCompany;
				case 6:
					var _v5 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeCompanyCollection;
				case 8:
					var _v6 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeConference;
				case 9:
					var _v7 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeContact;
				case 10:
					var _v8 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeDoctorCollection;
				case 11:
					var _v9 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeDoctor;
				case 13:
					var _v10 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeICDCollection;
				case 12:
					var _v11 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeICDCollection;
				case 14:
					var method = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeICDCategory(method);
				case 17:
					var _v12 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeDocumentCollection;
				case 19:
					var method = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeSingleDocument(method);
				case 20:
					var _v13 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeHolidayCollection;
				case 22:
					var _v14 = specific.a;
					return $author$project$App$Service$SpecificResources$TypePersonCollection;
				case 39:
					var _v15 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeStatementMessageCollection;
				case 24:
					var _v16 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeWaitingPersonCollection;
				case 26:
					var method = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeQuestionSectionFull(method);
				case 28:
					var _v17 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeStatement;
				case 31:
					var _v18 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeStatementTemplateCollection;
				case 32:
					var _v19 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeStatementTemplateCollection;
				case 27:
					var _v20 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeStatement;
				case 29:
					var method = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeStatementResult(method);
				case 30:
					var rid = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeStatementFull(rid);
				case 38:
					var rid = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeDoctorTemplate(rid);
				case 18:
					var _v21 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeDocument;
				case 21:
					var _v22 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeHoliday;
				case 23:
					var _v23 = specific.a;
					return $author$project$App$Service$SpecificResources$TypePerson;
				case 40:
					var _v24 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeStatementMessage;
				case 25:
					var _v25 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeWaitingPerson;
				case 34:
					var _v26 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeUser;
				case 33:
					var _v27 = specific.a;
					return $author$project$App$Service$SpecificResources$TypeUser;
				case 35:
					var method = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeUserResult(method);
				case 16:
					var method = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeNexodataToken(method);
				case 15:
					var method = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeMemedToken(method);
				case 36:
					var method = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeMemedCities(method);
				case 37:
					var method = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeMemedSpecialties(method);
				case 41:
					var method = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeReportSummaries(method);
				case 43:
					var method = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeReportMonthDetails(method);
				default:
					var method = specific.a.a;
					return $author$project$App$Service$SpecificResources$TypeTaxes(method);
			}
		case 5:
			return $author$project$App$Service$SpecificResources$TypeAnswer;
		case 6:
			return $author$project$App$Service$SpecificResources$TypeCompany;
		case 7:
			return $author$project$App$Service$SpecificResources$TypeConference;
		case 8:
			return $author$project$App$Service$SpecificResources$TypeContact;
		case 9:
			return $author$project$App$Service$SpecificResources$TypeDoctor;
		case 23:
			return $author$project$App$Service$SpecificResources$TypeICD;
		case 24:
			var method = specific.a;
			return $author$project$App$Service$SpecificResources$TypeICDCategory(method);
		case 15:
			var method = specific.a;
			return $author$project$App$Service$SpecificResources$TypeQuestionSectionFull(method);
		case 19:
			return $author$project$App$Service$SpecificResources$TypeStatementTemplate;
		case 16:
			return $author$project$App$Service$SpecificResources$TypeStatement;
		case 17:
			var method = specific.a;
			return $author$project$App$Service$SpecificResources$TypeStatementResult(method);
		case 18:
			var rid = specific.a;
			return $author$project$App$Service$SpecificResources$TypeStatementFull(rid);
		case 21:
			var rid = specific.a;
			return $author$project$App$Service$SpecificResources$TypeDoctorTemplate(rid);
		case 10:
			return $author$project$App$Service$SpecificResources$TypeDocument;
		case 11:
			var method = specific.a;
			return $author$project$App$Service$SpecificResources$TypeSingleDocument(method);
		case 12:
			return $author$project$App$Service$SpecificResources$TypeHoliday;
		case 13:
			return $author$project$App$Service$SpecificResources$TypePerson;
		case 20:
			return $author$project$App$Service$SpecificResources$TypeStatementMessage;
		case 14:
			return $author$project$App$Service$SpecificResources$TypeWaitingPerson;
		case 22:
			return $author$project$App$Service$SpecificResources$TypeUser;
		case 25:
			var method = specific.a;
			return $author$project$App$Service$SpecificResources$TypeNexodataToken(method);
		case 26:
			var method = specific.a;
			return $author$project$App$Service$SpecificResources$TypeMemedToken(method);
		case 27:
			var method = specific.a;
			return $author$project$App$Service$SpecificResources$TypeMemedCities(method);
		case 28:
			var method = specific.a;
			return $author$project$App$Service$SpecificResources$TypeMemedSpecialties(method);
		case 29:
			var method = specific.a;
			return $author$project$App$Service$SpecificResources$TypeReportSummaries(method);
		case 30:
			var method = specific.a;
			return $author$project$App$Service$SpecificResources$TypeReportMonthDetails(method);
		default:
			var method = specific.a;
			return $author$project$App$Service$SpecificResources$TypeTaxes(method);
	}
};
var $author$project$App$Service$ApiWebSocket$getSpecificResourceById = F4(
	function (id, result, cm, c) {
		var mRid = A2($elm$core$Dict$get, id, cm);
		var _v0 = _Utils_Tuple2(mRid, result);
		if (_v0.a.$ === 1) {
			var _v1 = _v0.a;
			return _Utils_Tuple2($elm$core$Dict$empty, _List_Nil);
		} else {
			if (!_v0.b.$) {
				var _v2 = _v0.a.a;
				var rid = _v2.a;
				var t = _v2.b;
				var value = _v0.b.a;
				var request = A2($elm$core$Dict$get, rid, c);
				var _v3 = _Utils_Tuple2(
					A2($author$project$App$Service$SpecificResources$getSpecificResourceDecoded, t, value),
					request);
				_v3$6:
				while (true) {
					if (!_v3.a.$) {
						if (!_v3.b.$) {
							switch (_v3.b.a.$) {
								case 1:
									var resources = _v3.a.a;
									var _v4 = _v3.b.a;
									var mResponseMsg = _v4.b;
									return _Utils_Tuple2(
										A2(
											$elm$core$Dict$map,
											F2(
												function (_v5, v) {
													return A3(
														$author$project$App$Service$ApiWebSocket$ResourceAvailable,
														$author$project$App$Service$SpecificResources$typeFromSpecificResource(v),
														mResponseMsg,
														v);
												}),
											resources),
										mResponseMsg);
								case 3:
									var resources = _v3.a.a;
									var _v6 = _v3.b.a;
									var mResponseMsg = _v6.b;
									return _Utils_Tuple2(
										A2(
											$elm$core$Dict$map,
											F2(
												function (_v7, v) {
													return A3(
														$author$project$App$Service$ApiWebSocket$ResourceAvailable,
														$author$project$App$Service$SpecificResources$typeFromSpecificResource(v),
														mResponseMsg,
														v);
												}),
											resources),
										mResponseMsg);
								case 2:
									var resources = _v3.a.a;
									var _v8 = _v3.b.a;
									var mResponseMsg = _v8.a;
									return _Utils_Tuple2(
										A2(
											$elm$core$Dict$map,
											F2(
												function (_v9, v) {
													return A3(
														$author$project$App$Service$ApiWebSocket$ResourceAvailable,
														$author$project$App$Service$SpecificResources$typeFromSpecificResource(v),
														mResponseMsg,
														v);
												}),
											resources),
										mResponseMsg);
								default:
									break _v3$6;
							}
						} else {
							break _v3$6;
						}
					} else {
						if (!_v3.b.$) {
							switch (_v3.b.a.$) {
								case 1:
									var error = _v3.a.a;
									var _v10 = _v3.b.a;
									var mResponseMsg = _v10.b;
									return _Utils_Tuple2(
										A2(
											$elm$core$Dict$singleton,
											rid,
											A3(
												$elm$core$Basics$composeL,
												$author$project$App$Service$ApiWebSocket$ResourceError(mResponseMsg),
												$author$project$App$Service$ApiWebSocket$unknownError,
												$elm$json$Json$Decode$errorToString(error))),
										mResponseMsg);
								case 3:
									var error = _v3.a.a;
									var _v11 = _v3.b.a;
									var mResponseMsg = _v11.b;
									return _Utils_Tuple2(
										A2(
											$elm$core$Dict$singleton,
											rid,
											A3(
												$elm$core$Basics$composeL,
												$author$project$App$Service$ApiWebSocket$ResourceError(mResponseMsg),
												$author$project$App$Service$ApiWebSocket$unknownError,
												$elm$json$Json$Decode$errorToString(error))),
										mResponseMsg);
								case 2:
									var error = _v3.a.a;
									var _v12 = _v3.b.a;
									var mResponseMsg = _v12.a;
									return _Utils_Tuple2(
										A2(
											$elm$core$Dict$singleton,
											rid,
											A3(
												$elm$core$Basics$composeL,
												$author$project$App$Service$ApiWebSocket$ResourceError(mResponseMsg),
												$author$project$App$Service$ApiWebSocket$unknownError,
												$elm$json$Json$Decode$errorToString(error))),
										mResponseMsg);
								default:
									break _v3$6;
							}
						} else {
							break _v3$6;
						}
					}
				}
				return _Utils_Tuple2($elm$core$Dict$empty, _List_Nil);
			} else {
				var _v13 = _v0.a.a;
				var rid = _v13.a;
				var error = _v0.b.a;
				var responseMsg = function () {
					var _v14 = A2($elm$core$Dict$get, rid, c);
					if ((!_v14.$) && (_v14.a.$ === 1)) {
						var _v15 = _v14.a;
						var when = _v15.a;
						var mResponseMsg = _v15.b;
						return mResponseMsg;
					} else {
						return _List_Nil;
					}
				}();
				return _Utils_Tuple2(
					A2(
						$elm$core$Dict$singleton,
						rid,
						A2($author$project$App$Service$ApiWebSocket$ResourceError, responseMsg, error)),
					responseMsg);
			}
		}
	});
var $author$project$App$Service$ApiWebSocket$parseResponse = F2(
	function (response, _v0) {
		var cm = _v0.a;
		var c = _v0.b;
		var mResponseMsg = _v0.c;
		switch (response.$) {
			case 1:
				var id = response.a;
				var result = response.b;
				var nCm = A2($elm$core$Dict$remove, id, cm);
				var _v2 = A4($author$project$App$Service$ApiWebSocket$getSpecificResourceById, id, result, cm, c);
				var dict = _v2.a;
				var responseMsg = _v2.b;
				var cache = A6(
					$elm$core$Dict$merge,
					$elm$core$Dict$insert,
					F4(
						function (k, a, b, d) {
							return A3($elm$core$Dict$insert, k, b, d);
						}),
					$elm$core$Dict$insert,
					c,
					dict,
					$elm$core$Dict$empty);
				return _Utils_Tuple3(
					nCm,
					cache,
					A2($elm$core$List$append, mResponseMsg, responseMsg));
			case 2:
				var evt = response.a;
				var _v3 = A2($author$project$App$Service$ApiWebSocket$getSpecificResourceByEvent, evt, c);
				var cache = _v3.a;
				var respMsg = _v3.b;
				return _Utils_Tuple3(
					cm,
					cache,
					A2($elm$core$List$append, mResponseMsg, respMsg));
			default:
				return _Utils_Tuple3(cm, c, mResponseMsg);
		}
	});
var $author$project$App$Service$ApiWebSocket$Event = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Service$ApiWebSocket$ResourceResponseJson = F4(
	function (id, result, error, event) {
		return {fo: error, gP: event, as: id, hQ: result};
	});
var $author$project$App$Service$ApiWebSocket$Response = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Service$ApiWebSocket$ErrorInternal = F3(
	function (code, message, data) {
		return {gu: code, c0: data, jl: message};
	});
var $author$project$App$Service$SpecificResources$AccessDenied = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Service$SpecificResources$JunoInsufficientCard = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Service$SpecificResources$JunoInvalidCard = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Service$SpecificResources$JunoOperationError = function (a) {
	return {$: 12, a: a};
};
var $author$project$App$Service$SpecificResources$JunoRestrictedCard = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Service$SpecificResources$NotFound = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Service$SpecificResources$PersonInvalidDocument = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Service$SpecificResources$StatementExpired = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Service$SpecificResources$UnknownError = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Service$SpecificResources$UserDuplicateEmail = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Service$SpecificResources$UserNotActivated = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Service$SpecificResources$errorFromCode = F2(
	function (code, msg) {
		switch (code) {
			case 'system.decodeError':
				return $author$project$App$Service$SpecificResources$DecodeError('Dados do servidor inválidos');
			case 'system.notFound':
				return $author$project$App$Service$SpecificResources$NotFound('Não encontrado');
			case 'system.invalidParams':
				return $author$project$App$Service$SpecificResources$NotFound('Não encontrado');
			case 'system.accessDenied':
				return $author$project$App$Service$SpecificResources$AccessDenied(
					($elm$core$String$length(msg) > 0) ? msg : 'Acesso negado');
			case 'peopleService.invalidDocument':
				return $author$project$App$Service$SpecificResources$PersonInvalidDocument(msg);
			case 'statementService.expired':
				return $author$project$App$Service$SpecificResources$StatementExpired(msg);
			case 'userService.create.duplicate.email':
				return $author$project$App$Service$SpecificResources$UserDuplicateEmail(msg);
			case 'userService.notactive':
				return $author$project$App$Service$SpecificResources$UserNotActivated(msg);
			case 'statementService.insufficientCard':
				return $author$project$App$Service$SpecificResources$JunoInsufficientCard(msg);
			case 'statementService.restrictedCard':
				return $author$project$App$Service$SpecificResources$JunoRestrictedCard(msg);
			case 'statementService.invalidCard':
				return $author$project$App$Service$SpecificResources$JunoInvalidCard(msg);
			case 'statementService.operationError':
				return $author$project$App$Service$SpecificResources$JunoOperationError(msg);
			default:
				return $author$project$App$Service$SpecificResources$UnknownError('Erro desconhecido');
		}
	});
var $author$project$App$Service$ApiWebSocket$errorDecoder = A2(
	$elm$json$Json$Decode$map,
	function (err) {
		return A2(
			$author$project$App$Service$ApiWebSocket$Error,
			A2($author$project$App$Service$SpecificResources$errorFromCode, err.gu, err.jl),
			err.c0);
	},
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'data',
		$elm$json$Json$Decode$value,
		$elm$json$Json$Encode$null,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'message',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'code',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$App$Service$ApiWebSocket$ErrorInternal)))));
var $author$project$App$Service$Shared$EventInternal = F2(
	function (event, data) {
		return {c0: data, gP: event};
	});
var $author$project$App$Service$Shared$EventData = F4(
	function (values, models, value, idx) {
		return {jb: idx, K: models, T: value, j9: values};
	});
var $author$project$App$Service$Shared$eventDataDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'idx',
	$elm$json$Json$Decode$int,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'value',
		$elm$json$Json$Decode$value,
		$elm$json$Json$Encode$null,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'models',
			$elm$json$Json$Decode$value,
			$elm$json$Json$Encode$null,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'values',
				$elm$json$Json$Decode$value,
				$elm$json$Json$Encode$null,
				$elm$json$Json$Decode$succeed($author$project$App$Service$Shared$EventData)))));
var $author$project$App$Service$Shared$EventRemove = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$App$Service$Shared$EventUnknown = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $author$project$App$Service$Shared$eventFromString = F2(
	function (str, data) {
		var ridStr = A2(
			$elm$core$String$join,
			'.',
			$elm$core$List$reverse(
				A2(
					$elm$core$List$drop,
					1,
					$elm$core$List$reverse(
						A2($elm$core$String$split, '.', str)))));
		var path = $author$project$App$Service$Shared$pathFromRID(
			$author$project$App$Service$Shared$ridFromString(ridStr));
		var evtType = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Service$Shared$EventUnknown,
			A2(
				$elm$core$Maybe$map,
				function (evtStr) {
					switch (evtStr) {
						case 'change':
							return $author$project$App$Service$Shared$EventChange;
						case 'add':
							return $author$project$App$Service$Shared$EventAdd;
						case 'remove':
							return $author$project$App$Service$Shared$EventRemove;
						default:
							return $author$project$App$Service$Shared$EventUnknown;
					}
				},
				$elm$core$List$head(
					$elm$core$List$reverse(
						A2($elm$core$String$split, '.', str)))));
		return A2(evtType, path, data);
	});
var $author$project$App$Service$Shared$eventDecoder = A2(
	$elm$json$Json$Decode$map,
	function (evt) {
		return A2($author$project$App$Service$Shared$eventFromString, evt.gP, evt.c0);
	},
	A3(
		$elm$json$Json$Decode$map2,
		$author$project$App$Service$Shared$EventInternal,
		A2($elm$json$Json$Decode$field, 'event', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'data', $author$project$App$Service$Shared$eventDataDecoder)));
var $author$project$App$Service$ApiWebSocket$resourceResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	function (v) {
		var _v0 = _Utils_Tuple2(
			_Utils_Tuple2(v.fo, v.as),
			_Utils_Tuple2(v.gP, v.hQ));
		if (!_v0.a.b.$) {
			if (_v0.a.a.$ === 1) {
				if (!_v0.b.b.$) {
					var _v1 = _v0.a;
					var _v2 = _v1.a;
					var id = _v1.b.a;
					var _v3 = _v0.b;
					var result = _v3.b.a;
					return A2(
						$author$project$App$Service$ApiWebSocket$Response,
						id,
						$elm$core$Result$Ok(result));
				} else {
					var _v11 = _v0.a;
					var id = _v11.b.a;
					return A2(
						$author$project$App$Service$ApiWebSocket$Response,
						id,
						$elm$core$Result$Err(
							$author$project$App$Service$ApiWebSocket$unknownError('Falha na decodificação')));
				}
			} else {
				var _v8 = _v0.a;
				var error = _v8.a.a;
				var id = _v8.b.a;
				return A2(
					$author$project$App$Service$ApiWebSocket$Response,
					id,
					$elm$core$Result$Err(error));
			}
		} else {
			if (_v0.a.a.$ === 1) {
				if (!_v0.b.a.$) {
					var _v4 = _v0.a;
					var _v5 = _v4.a;
					var _v6 = _v4.b;
					var _v7 = _v0.b;
					var evt = _v7.a.a;
					return $author$project$App$Service$ApiWebSocket$Event(
						$elm$core$Result$Ok(evt));
				} else {
					var _v12 = _v0.a;
					var _v13 = _v12.b;
					return $author$project$App$Service$ApiWebSocket$Event(
						$elm$core$Result$Err(
							$author$project$App$Service$ApiWebSocket$unknownError('Falha na decodificação')));
				}
			} else {
				var _v9 = _v0.a;
				var error = _v9.a.a;
				var _v10 = _v9.b;
				return $author$project$App$Service$ApiWebSocket$Event(
					$elm$core$Result$Err(error));
			}
		}
	},
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
		$elm$json$Json$Decode$maybe($author$project$App$Service$Shared$eventDecoder),
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'error',
			$elm$json$Json$Decode$maybe($author$project$App$Service$ApiWebSocket$errorDecoder),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'result',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$value),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'id',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
					$elm$core$Maybe$Nothing,
					$elm$json$Json$Decode$succeed($author$project$App$Service$ApiWebSocket$ResourceResponseJson))))));
var $author$project$App$Service$ApiWebSocket$parseWebsocketData = function (_v0) {
	var id = _v0.a;
	var now = _v0.b;
	var smo = _v0.c;
	var sme = _v0.d;
	var ws = _v0.e;
	var sc = _v0.f;
	var cm = _v0.g;
	var c = _v0.h;
	var _v1 = $author$project$App$Service$Websocket$getData(ws);
	var mWs = _v1.a;
	var data = _v1.b;
	var _v2 = A3(
		$elm$core$List$foldr,
		$author$project$App$Service$ApiWebSocket$parseResponse,
		_Utils_Tuple3(cm, c, _List_Nil),
		A2(
			$elm$core$List$map,
			$elm$core$Result$withDefault($author$project$App$Service$ApiWebSocket$UnknownResponse),
			A2(
				$elm$core$List$map,
				$elm$json$Json$Decode$decodeValue($author$project$App$Service$ApiWebSocket$resourceResponseDecoder),
				data)));
	var nCm = _v2.a;
	var nC = _v2.b;
	var mResponseMsg = _v2.c;
	return _Utils_Tuple2(
		A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, mWs, sc, nCm, nC),
		mResponseMsg);
};
var $author$project$App$Service$ApiWebSocket$processWaitingRequest = function (_v0) {
	var id = _v0.a;
	var now = _v0.b;
	var smo = _v0.c;
	var sme = _v0.d;
	var ws = _v0.e;
	var sc = _v0.f;
	var cm = _v0.g;
	var c = _v0.h;
	var _v1 = function () {
		var _v2 = _Utils_Tuple2(
			$author$project$App$Service$Websocket$isConnected(ws),
			smo);
		if (_v2.a) {
			if (_v2.b.$ === 3) {
				var _v3 = _v2.b;
				return A3(
					$elm$core$Dict$foldr,
					F3(
						function (rid, v, _v4) {
							var uC = _v4.a;
							var uCm = _v4.b;
							var list = _v4.c;
							if (!v.$) {
								var rId = v.c;
								var rIdv = v.d;
								var responseMsg = v.e;
								var request = v.f;
								return _Utils_Tuple3(
									A3(
										$elm$core$Dict$insert,
										rid,
										A2($author$project$App$Service$ApiWebSocket$ResourceRequested, now, responseMsg),
										uC),
									A3($elm$core$Dict$insert, rId, rIdv, uCm),
									A2($elm$core$List$cons, request, list));
							} else {
								return _Utils_Tuple3(uC, uCm, list);
							}
						}),
					_Utils_Tuple3(c, cm, _List_Nil),
					c);
			} else {
				return A3(
					$elm$core$Dict$foldr,
					F3(
						function (rid, v, _v6) {
							var uC = _v6.a;
							var uCm = _v6.b;
							var list = _v6.c;
							if ((!v.$) && (!v.b)) {
								var rId = v.c;
								var rIdv = v.d;
								var responseMsg = v.e;
								var request = v.f;
								return _Utils_Tuple3(
									A3(
										$elm$core$Dict$insert,
										rid,
										A2($author$project$App$Service$ApiWebSocket$ResourceRequested, now, responseMsg),
										uC),
									A3($elm$core$Dict$insert, rId, rIdv, uCm),
									A2($elm$core$List$cons, request, list));
							} else {
								return _Utils_Tuple3(uC, uCm, list);
							}
						}),
					_Utils_Tuple3(c, cm, _List_Nil),
					c);
			}
		} else {
			return A3(
				$elm$core$Dict$foldr,
				F3(
					function (rid, _v8, _v9) {
						var uC = _v9.a;
						var uCm = _v9.b;
						var list = _v9.c;
						return _Utils_Tuple3(
							A2($elm$core$Dict$remove, rid, uC),
							uCm,
							list);
					}),
				_Utils_Tuple3(c, cm, _List_Nil),
				sc);
		}
	}();
	var nC = _v1.a;
	var nCm = _v1.b;
	var waitingRequest = _v1.c;
	var _v10 = A3(
		$elm$core$List$foldr,
		F2(
			function (v, _v11) {
				var foldWs = _v11.a;
				var cmd = _v11.b;
				var _v12 = A2($author$project$App$Service$Websocket$send, v, foldWs);
				var wsModel = _v12.a;
				var wsCmd = _v12.b;
				return _Utils_Tuple2(
					wsModel,
					A2(
						$elm$core$List$cons,
						A2($elm$core$Platform$Cmd$map, $author$project$App$Service$ApiWebSocket$WebsocketMsg, wsCmd),
						cmd));
			}),
		_Utils_Tuple2(ws, _List_Nil),
		waitingRequest);
	var nWs = _v10.a;
	var cmds = _v10.b;
	return A2(
		$elm$core$Tuple$mapSecond,
		$elm$core$Platform$Cmd$batch,
		A3(
			$elm$core$Dict$foldr,
			F3(
				function (_v13, _v14, _v15) {
					var auth = _v14.a;
					var t = _v14.b;
					var path = _v14.c;
					var resp = _v14.d;
					var nModel = _v15.a;
					var nCmds = _v15.b;
					var _v16 = function () {
						var _v17 = A2($author$project$App$Service$ApiWebSocket$getResource, path, nModel);
						if (_v17.$ === 1) {
							return A5($author$project$App$Service$ApiWebSocket$subscribeResourceInternal, auth, t, path, resp, nModel);
						} else {
							return _Utils_Tuple2(nModel, $elm$core$Platform$Cmd$none);
						}
					}();
					var sModel = _v16.a;
					var sCmd = _v16.b;
					return _Utils_Tuple2(
						sModel,
						A2($elm$core$List$cons, sCmd, nCmds));
				}),
			_Utils_Tuple2(
				A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, nWs, sc, nCm, nC),
				cmds),
			sc));
};
var $author$project$App$Service$ApiWebSocket$websocketFromModel = function (_v0) {
	var websocket = _v0.e;
	return websocket;
};
var $author$project$App$Service$ApiWebSocket$reconnect = function (m) {
	var ws = $author$project$App$Service$ApiWebSocket$websocketFromModel(m);
	var _v0 = _Utils_Tuple2(
		$author$project$App$Service$Websocket$isConnected(ws),
		m);
	_v0$2:
	while (true) {
		if (!_v0.a) {
			switch (_v0.b.c.$) {
				case 3:
					if (!_v0.b.c.b.c.$) {
						var _v1 = _v0.b;
						var id = _v1.a;
						var now = _v1.b;
						var _v2 = _v1.c;
						var respMsgs = _v2.a;
						var _v3 = _v2.b;
						var name = _v3.a;
						var time = _v3.b;
						var t = _v3.c.a;
						var rMethod = _v1.d;
						var sc = _v1.f;
						var cm = _v1.g;
						var c = _v1.h;
						var _v4 = A2(
							$author$project$App$Service$Websocket$connect,
							$elm$time$Time$posixToMillis(now),
							ws);
						var wsModel = _v4.a;
						var wsCmd = _v4.b;
						var _v5 = A6(
							$author$project$App$Service$ApiWebSocket$executeMethod,
							false,
							$author$project$App$Service$SpecificResources$TypeToken(rMethod),
							rMethod,
							t,
							respMsgs,
							A2(
								$author$project$App$Service$ApiWebSocket$clearMethod,
								rMethod,
								A8(
									$author$project$App$Service$ApiWebSocket$Resgate,
									id,
									now,
									$author$project$App$Service$ApiWebSocket$Renewing(
										A3(
											$author$project$App$Service$ApiWebSocket$InternalToken,
											name,
											time,
											$elm$core$Maybe$Just(t))),
									rMethod,
									wsModel,
									sc,
									$elm$core$Dict$empty,
									c)));
						var nModel = _v5.a;
						var nCmd = _v5.b;
						return _Utils_Tuple2(
							nModel,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Service$ApiWebSocket$WebsocketMsg, wsCmd),
										nCmd
									])));
					} else {
						break _v0$2;
					}
				case 0:
					var _v6 = _v0.b;
					var id = _v6.a;
					var now = _v6.b;
					var t = _v6.c.a;
					var sme = _v6.d;
					var sc = _v6.f;
					var c = _v6.h;
					var _v7 = A2(
						$author$project$App$Service$Websocket$connect,
						$elm$time$Time$posixToMillis(now),
						ws);
					var wsModel = _v7.a;
					var wsCmd = _v7.b;
					return _Utils_Tuple2(
						A8(
							$author$project$App$Service$ApiWebSocket$Resgate,
							id,
							now,
							$author$project$App$Service$ApiWebSocket$NotAuthenticated(t),
							sme,
							wsModel,
							sc,
							$elm$core$Dict$empty,
							c),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Service$ApiWebSocket$WebsocketMsg, wsCmd));
				default:
					break _v0$2;
			}
		} else {
			break _v0$2;
		}
	}
	return _Utils_Tuple2(m, $elm$core$Platform$Cmd$none);
};
var $author$project$App$Constants$tokenRenewInterval = 3600000;
var $author$project$App$Service$ApiWebSocket$renewToken = function (m) {
	if (((m.c.$ === 3) && (!m.c.b.b.$)) && (!m.c.b.c.$)) {
		var id = m.a;
		var now = m.b;
		var _v1 = m.c;
		var respMsgs = _v1.a;
		var _v2 = _v1.b;
		var name = _v2.a;
		var time = _v2.b.a;
		var t = _v2.c.a;
		var rMethod = m.d;
		var ws = m.e;
		var sc = m.f;
		var cm = m.g;
		var c = m.h;
		return (_Utils_cmp(
			$elm$time$Time$posixToMillis(now) - $elm$time$Time$posixToMillis(time),
			$author$project$App$Constants$tokenRenewInterval) > 0) ? A6(
			$author$project$App$Service$ApiWebSocket$executeMethod,
			false,
			$author$project$App$Service$SpecificResources$TypeToken(rMethod),
			rMethod,
			t,
			respMsgs,
			A2(
				$author$project$App$Service$ApiWebSocket$clearMethod,
				rMethod,
				A8(
					$author$project$App$Service$ApiWebSocket$Resgate,
					id,
					now,
					$author$project$App$Service$ApiWebSocket$Renewing(
						A3(
							$author$project$App$Service$ApiWebSocket$InternalToken,
							name,
							$elm$core$Maybe$Just(time),
							$elm$core$Maybe$Just(t))),
					rMethod,
					ws,
					sc,
					cm,
					c))) : _Utils_Tuple2(m, $elm$core$Platform$Cmd$none);
	} else {
		return _Utils_Tuple2(m, $elm$core$Platform$Cmd$none);
	}
};
var $author$project$App$Service$Websocket$StateConnected = 2;
var $author$project$App$Service$Websocket$update = F2(
	function (msg, cache) {
		var _v0 = _Utils_Tuple2(msg, cache);
		switch (_v0.a.$) {
			case 0:
				var _v1 = _v0.a;
				var _v2 = _v0.b;
				var host = _v2.a;
				var err = _v2.c;
				var data = _v2.d;
				return A4($author$project$App$Service$Websocket$Cache, host, 2, err, data);
			case 1:
				var _v3 = _v0.a;
				var _v4 = _v0.b;
				var host = _v4.a;
				var err = _v4.c;
				var data = _v4.d;
				return A4($author$project$App$Service$Websocket$Cache, host, 0, err, data);
			case 2:
				var newErr = _v0.a.a;
				var _v5 = _v0.b;
				var host = _v5.a;
				var c = _v5.b;
				var err = _v5.c;
				var data = _v5.d;
				return A4(
					$author$project$App$Service$Websocket$Cache,
					host,
					c,
					A2(
						$elm$core$List$append,
						err,
						_List_fromArray(
							[newErr])),
					data);
			default:
				var newData = _v0.a.a;
				var _v6 = _v0.b;
				var host = _v6.a;
				var c = _v6.b;
				var err = _v6.c;
				var data = _v6.d;
				var _v7 = A2($elm$json$Json$Decode$decodeString, $elm$json$Json$Decode$value, newData);
				if (!_v7.$) {
					var d = _v7.a;
					return A4(
						$author$project$App$Service$Websocket$Cache,
						host,
						c,
						err,
						A2(
							$elm$core$List$append,
							data,
							_List_fromArray(
								[d])));
				} else {
					var e = _v7.a;
					return A4(
						$author$project$App$Service$Websocket$Cache,
						host,
						c,
						A2(
							$elm$core$List$append,
							err,
							_List_fromArray(
								[
									$elm$json$Json$Decode$errorToString(e)
								])),
						data);
				}
		}
	});
var $author$project$App$Service$ApiWebSocket$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model);
		_v0$5:
		while (true) {
			switch (_v0.a.$) {
				case 1:
					var respMsg = _v0.a.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, respMsg);
				case 0:
					switch (_v0.b.c.$) {
						case 4:
							var wMsg = _v0.a.a;
							var _v1 = _v0.b;
							var id = _v1.a;
							var now = _v1.b;
							var _v2 = _v1.c;
							var lMethod = _v2.a;
							var _v3 = _v2.b;
							var name = _v3.a;
							var tTime = _v3.b;
							var token = _v3.c;
							var rMethod = _v1.d;
							var ws = _v1.e;
							var sc = _v1.f;
							var cm = _v1.g;
							var c = _v1.h;
							var wsm = A2($author$project$App$Service$Websocket$update, wMsg, ws);
							var _v4 = $author$project$App$Service$ApiWebSocket$parseWebsocketData(
								A8(
									$author$project$App$Service$ApiWebSocket$Resgate,
									id,
									now,
									A2(
										$author$project$App$Service$ApiWebSocket$SigningIn,
										lMethod,
										A3($author$project$App$Service$ApiWebSocket$InternalToken, name, tTime, token)),
									rMethod,
									wsm,
									sc,
									cm,
									c));
							var uModel = _v4.a;
							var respMsgsList = _v4.b;
							var _v5 = function () {
								var _v6 = _Utils_Tuple2(
									A2($author$project$App$Service$ApiWebSocket$getMethodResponse, lMethod, uModel),
									uModel);
								_v6$2:
								while (true) {
									if (!_v6.a.$) {
										switch (_v6.a.a.$) {
											case 3:
												if (_v6.a.a.c.$ === 2) {
													var _v7 = _v6.a.a;
													var respMsgs = _v7.b;
													var _v8 = _v7.c;
													var uToken = _v8.b;
													var _v9 = _v6.b;
													var sme = _v9.d;
													var nWs = _v9.e;
													var nSc = _v9.f;
													var nCm = _v9.g;
													var nC = _v9.h;
													return _Utils_Tuple2(
														A8(
															$author$project$App$Service$ApiWebSocket$Resgate,
															id,
															now,
															A2(
																$author$project$App$Service$ApiWebSocket$Authenticated,
																respMsgs,
																A3(
																	$author$project$App$Service$ApiWebSocket$InternalToken,
																	name,
																	$elm$core$Maybe$Just(now),
																	$elm$core$Maybe$Just(uToken))),
															sme,
															nWs,
															nSc,
															nCm,
															nC),
														$author$project$App$Service$ApiWebSocket$saveInternalToken(
															A3(
																$author$project$App$Service$ApiWebSocket$InternalToken,
																name,
																$elm$core$Maybe$Just(now),
																$elm$core$Maybe$Just(uToken))));
												} else {
													break _v6$2;
												}
											case 2:
												var _v10 = _v6.a.a;
												var error = _v10.a;
												var _v11 = _v6.b;
												var sme = _v11.d;
												var nWs = _v11.e;
												var nSc = _v11.f;
												var nCm = _v11.g;
												var nC = _v11.h;
												return _Utils_Tuple2(
													A8(
														$author$project$App$Service$ApiWebSocket$Resgate,
														id,
														now,
														$author$project$App$Service$ApiWebSocket$NotAuthenticated(
															A3(
																$author$project$App$Service$ApiWebSocket$InternalToken,
																name,
																$elm$core$Maybe$Just(now),
																$elm$core$Maybe$Nothing)),
														sme,
														nWs,
														nSc,
														nCm,
														nC),
													$author$project$App$Service$ApiWebSocket$saveInternalToken(
														A3(
															$author$project$App$Service$ApiWebSocket$InternalToken,
															name,
															$elm$core$Maybe$Just(now),
															$elm$core$Maybe$Nothing)));
											default:
												break _v6$2;
										}
									} else {
										break _v6$2;
									}
								}
								return _Utils_Tuple2(uModel, $elm$core$Platform$Cmd$none);
							}();
							var rModel = _v5.a;
							var rCmd = _v5.b;
							var _v12 = $author$project$App$Service$ApiWebSocket$processWaitingRequest(rModel);
							var pModel = _v12.a;
							var pCmd = _v12.b;
							return _Utils_Tuple3(
								pModel,
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[rCmd, pCmd])),
								respMsgsList);
						case 2:
							var wMsg = _v0.a.a;
							var _v13 = _v0.b;
							var id = _v13.a;
							var now = _v13.b;
							var _v14 = _v13.c.a;
							var name = _v14.a;
							var tTime = _v14.b;
							var token = _v14.c;
							var rMethod = _v13.d;
							var ws = _v13.e;
							var sc = _v13.f;
							var cm = _v13.g;
							var c = _v13.h;
							var wsm = A2($author$project$App$Service$Websocket$update, wMsg, ws);
							var _v15 = $author$project$App$Service$ApiWebSocket$parseWebsocketData(
								A8(
									$author$project$App$Service$ApiWebSocket$Resgate,
									id,
									now,
									$author$project$App$Service$ApiWebSocket$Renewing(
										A3($author$project$App$Service$ApiWebSocket$InternalToken, name, tTime, token)),
									rMethod,
									wsm,
									sc,
									cm,
									c));
							var uModel = _v15.a;
							var respMsgsList = _v15.b;
							var _v16 = function () {
								var _v17 = _Utils_Tuple2(
									A2($author$project$App$Service$ApiWebSocket$getMethodResponse, rMethod, uModel),
									uModel);
								_v17$2:
								while (true) {
									if (!_v17.a.$) {
										switch (_v17.a.a.$) {
											case 3:
												if (_v17.a.a.c.$ === 2) {
													var _v18 = _v17.a.a;
													var respMsgs = _v18.b;
													var _v19 = _v18.c;
													var uToken = _v19.b;
													var _v20 = _v17.b;
													var sme = _v20.d;
													var nWs = _v20.e;
													var nSc = _v20.f;
													var nCm = _v20.g;
													var nC = _v20.h;
													return _Utils_Tuple2(
														A8(
															$author$project$App$Service$ApiWebSocket$Resgate,
															id,
															now,
															A2(
																$author$project$App$Service$ApiWebSocket$Authenticated,
																respMsgs,
																A3(
																	$author$project$App$Service$ApiWebSocket$InternalToken,
																	name,
																	$elm$core$Maybe$Just(now),
																	$elm$core$Maybe$Just(uToken))),
															sme,
															nWs,
															nSc,
															nCm,
															nC),
														$author$project$App$Service$ApiWebSocket$saveInternalToken(
															A3(
																$author$project$App$Service$ApiWebSocket$InternalToken,
																name,
																$elm$core$Maybe$Just(now),
																$elm$core$Maybe$Just(uToken))));
												} else {
													break _v17$2;
												}
											case 2:
												var _v21 = _v17.a.a;
												var _v22 = _v17.b;
												var sme = _v22.d;
												var nWs = _v22.e;
												var nSc = _v22.f;
												var nCm = _v22.g;
												var nC = _v22.h;
												return _Utils_Tuple2(
													A8(
														$author$project$App$Service$ApiWebSocket$Resgate,
														id,
														now,
														$author$project$App$Service$ApiWebSocket$NotAuthenticated(
															A3(
																$author$project$App$Service$ApiWebSocket$InternalToken,
																name,
																$elm$core$Maybe$Just(now),
																$elm$core$Maybe$Nothing)),
														sme,
														nWs,
														nSc,
														nCm,
														nC),
													$author$project$App$Service$ApiWebSocket$saveInternalToken(
														A3(
															$author$project$App$Service$ApiWebSocket$InternalToken,
															name,
															$elm$core$Maybe$Just(now),
															$elm$core$Maybe$Nothing)));
											default:
												break _v17$2;
										}
									} else {
										break _v17$2;
									}
								}
								return _Utils_Tuple2(uModel, $elm$core$Platform$Cmd$none);
							}();
							var rModel = _v16.a;
							var rCmd = _v16.b;
							var _v23 = $author$project$App$Service$ApiWebSocket$processWaitingRequest(rModel);
							var pModel = _v23.a;
							var pCmd = _v23.b;
							return _Utils_Tuple3(
								pModel,
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[rCmd, pCmd])),
								respMsgsList);
						default:
							var wMsg = _v0.a.a;
							var _v24 = _v0.b;
							var id = _v24.a;
							var now = _v24.b;
							var smo = _v24.c;
							var sme = _v24.d;
							var ws = _v24.e;
							var sc = _v24.f;
							var cm = _v24.g;
							var c = _v24.h;
							var wsm = A2($author$project$App$Service$Websocket$update, wMsg, ws);
							var _v25 = $author$project$App$Service$ApiWebSocket$reconnect(
								A8($author$project$App$Service$ApiWebSocket$Resgate, id, now, smo, sme, wsm, sc, cm, c));
							var wsModel = _v25.a;
							var wsCmd = _v25.b;
							var _v26 = $author$project$App$Service$ApiWebSocket$parseWebsocketData(wsModel);
							var newModel = _v26.a;
							var respMsgs = _v26.b;
							var _v27 = $author$project$App$Service$ApiWebSocket$processWaitingRequest(newModel);
							var pModel = _v27.a;
							var pCmd = _v27.b;
							return _Utils_Tuple3(
								pModel,
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[wsCmd, pCmd])),
								respMsgs);
					}
				case 2:
					if (!_v0.a.a.c.$) {
						switch (_v0.b.c.$) {
							case 1:
								var _v28 = _v0.a.a;
								var tTime = _v28.b;
								var t = _v28.c.a;
								var _v29 = _v0.b;
								var id = _v29.a;
								var now = _v29.b;
								var _v30 = _v29.c;
								var renewResp = _v30.a;
								var _v31 = _v30.b;
								var name = _v31.a;
								var rMethod = _v29.d;
								var ws = _v29.e;
								var sc = _v29.f;
								var cm = _v29.g;
								var c = _v29.h;
								return function (_v32) {
									var newModel = _v32.a;
									var cmd = _v32.b;
									return _Utils_Tuple3(newModel, cmd, _List_Nil);
								}(
									A6(
										$author$project$App$Service$ApiWebSocket$executeMethod,
										false,
										$author$project$App$Service$SpecificResources$TypeToken(rMethod),
										rMethod,
										t,
										renewResp,
										A2(
											$author$project$App$Service$ApiWebSocket$clearMethod,
											rMethod,
											A8(
												$author$project$App$Service$ApiWebSocket$Resgate,
												id,
												now,
												$author$project$App$Service$ApiWebSocket$Renewing(
													A3(
														$author$project$App$Service$ApiWebSocket$InternalToken,
														name,
														tTime,
														$elm$core$Maybe$Just(t))),
												rMethod,
												ws,
												sc,
												cm,
												c))));
							case 4:
								break _v0$5;
							default:
								return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
						}
					} else {
						switch (_v0.b.c.$) {
							case 4:
								break _v0$5;
							case 1:
								var _v35 = _v0.a.a;
								var name = _v35.a;
								var tTime = _v35.b;
								var _v36 = _v35.c;
								var _v37 = _v0.b;
								var id = _v37.a;
								var now = _v37.b;
								var _v38 = _v37.c;
								var renewResp = _v38.a;
								var token = _v38.b;
								var rMethod = _v37.d;
								var ws = _v37.e;
								var sc = _v37.f;
								var cm = _v37.g;
								var c = _v37.h;
								return _Utils_Tuple3(
									A8(
										$author$project$App$Service$ApiWebSocket$Resgate,
										id,
										now,
										$author$project$App$Service$ApiWebSocket$NotAuthenticated(
											A3($author$project$App$Service$ApiWebSocket$InternalToken, name, tTime, $elm$core$Maybe$Nothing)),
										rMethod,
										ws,
										sc,
										cm,
										c),
									$elm$core$Platform$Cmd$none,
									renewResp);
							default:
								var _v39 = _v0.a.a;
								var name = _v39.a;
								var tTime = _v39.b;
								var _v40 = _v39.c;
								var _v41 = _v0.b;
								var id = _v41.a;
								var now = _v41.b;
								var sme = _v41.d;
								var ws = _v41.e;
								var sc = _v41.f;
								var cm = _v41.g;
								var c = _v41.h;
								return _Utils_Tuple3(
									A8(
										$author$project$App$Service$ApiWebSocket$Resgate,
										id,
										now,
										$author$project$App$Service$ApiWebSocket$NotAuthenticated(
											A3($author$project$App$Service$ApiWebSocket$InternalToken, name, tTime, $elm$core$Maybe$Nothing)),
										sme,
										ws,
										sc,
										cm,
										c),
									$elm$core$Platform$Cmd$none,
									_List_Nil);
						}
					}
				default:
					var now = _v0.a.a;
					var _v42 = _v0.b;
					var smo = _v42.c;
					var sme = _v42.d;
					var ws = _v42.e;
					var sc = _v42.f;
					var cm = _v42.g;
					var c = _v42.h;
					var _v43 = $author$project$App$Service$ApiWebSocket$keepAlive(
						A8(
							$author$project$App$Service$ApiWebSocket$Resgate,
							$elm$time$Time$posixToMillis(now),
							now,
							smo,
							sme,
							ws,
							sc,
							cm,
							c));
					var kaModel = _v43.a;
					var kaCmd = _v43.b;
					var _v44 = $author$project$App$Service$ApiWebSocket$renewToken(kaModel);
					var tModel = _v44.a;
					var tCmd = _v44.b;
					return _Utils_Tuple3(
						tModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[kaCmd, tCmd])),
						_List_Nil);
			}
		}
		var _v33 = _v0.b;
		var _v34 = _v33.c;
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
	});
var $author$project$App$SharedState$update = F2(
	function (msg, model) {
		var recordingStatus = _Utils_eq(
			model.f4.fM,
			$elm$core$Maybe$Just(0)) ? model.f4.d7.jR : model.f4.d7.jQ;
		var conf = model.f4.d7;
		var _v0 = function () {
			if (_Utils_eq(
				recordingStatus,
				$author$project$App$Components$Video$getRecorder(model.f4))) {
				return _Utils_Tuple2(
					_Utils_Tuple3(model, model._, $elm$core$Platform$Cmd$none),
					$author$project$App$Components$Video$getRecorder(model.f4));
			} else {
				var stmtId = model.ia.h8.g.e.as;
				var propRecordingStatus = _Utils_eq(
					model.f4.fM,
					$elm$core$Maybe$Just(0)) ? $author$project$App$Components$Video$getRecorder(model.f4) : conf.jR;
				var prof = A2($elm$core$Maybe$withDefault, 0, model.f4.fM);
				var doctorRecordingStatus = _Utils_eq(
					model.f4.fM,
					$elm$core$Maybe$Just(1)) ? $author$project$App$Components$Video$getRecorder(model.f4) : conf.jQ;
				return _Utils_Tuple2(
					A3(
						$author$project$App$SharedState$updateAPI,
						A4(
							$author$project$App$SharedState$StatementSetConference,
							_Utils_update(
								conf,
								{jQ: doctorRecordingStatus, jR: propRecordingStatus}),
							stmtId,
							prof,
							_List_Nil),
						model,
						model._),
					$author$project$App$Components$Video$getRecorder(model.f4));
			}
		}();
		var _v1 = _v0.a;
		var updatedModel = _v1.a;
		var updatedAPI = _v1.b;
		var sharedCmd = _v1.c;
		var newRecordingButton = _v0.b;
		switch (msg.$) {
			case 0:
				var _v3 = $author$project$App$Service$ApiWebSocket$logout(updatedAPI);
				var apiModel = _v3.a;
				var apiCmd = _v3.b;
				return _Utils_Tuple3(
					_Utils_update(
						updatedModel,
						{
							_: apiModel,
							eP: $author$project$App$Route$Public($author$project$App$Route$Login)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$SharedState$APICmd, apiCmd),
								A2(
								$author$project$App$Route$pushUrl,
								model.fC,
								$author$project$App$Route$Public($author$project$App$Route$Login))
							])),
					_List_Nil);
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cY: !model.cY}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 2:
				var _v4 = A3(
					$author$project$App$SharedState$updateAPI,
					A2(
						$author$project$App$SharedState$UpdatePassword,
						model.bS,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$SharedMsg($author$project$App$Page$ResponseMsgs$SharedGetUpdatePasswordResult)
							])),
					model,
					model._);
				var newUpdatedModel = _v4.a;
				var newUpdatedAPI = _v4.b;
				var newSharedCmd = _v4.c;
				return _Utils_Tuple3(
					_Utils_update(
						newUpdatedModel,
						{_: newUpdatedAPI}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$SharedState$APICmd, newSharedCmd)
							])),
					_List_Nil);
			case 3:
				var field = msg.a;
				var newUser = A2($author$project$App$Data$User$updateModel, field, model.bS);
				return _Utils_Tuple3(
					_Utils_update(
						updatedModel,
						{bS: newUser}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 4:
				var respMsgs = msg.a;
				var cache = model.ia;
				var loadingStatus = cache.i;
				return _Utils_Tuple3(
					_Utils_update(
						updatedModel,
						{
							_: updatedAPI,
							ia: _Utils_update(
								cache,
								{
									i: _Utils_update(
										loadingStatus,
										{aG: true})
								})
						}),
					$elm$core$Platform$Cmd$none,
					respMsgs);
			case 5:
				var apiMsg = msg.a;
				var _v5 = A2($author$project$App$Service$ApiWebSocket$update, apiMsg, updatedAPI);
				var apiModel = _v5.a;
				var apiCmd = _v5.b;
				var respMsgs = _v5.c;
				return _Utils_Tuple3(
					_Utils_update(
						updatedModel,
						{_: apiModel}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$SharedState$APICmd, apiCmd),
								A2($elm$core$Platform$Cmd$map, $author$project$App$SharedState$APICmd, sharedCmd)
							])),
					respMsgs);
			case 6:
				var videoMsg = msg.a;
				var _v6 = A2($author$project$App$Components$Video$update, videoMsg, model.f4);
				var videoModel = _v6.a;
				var videoCmd = _v6.b;
				var respMsgs = _v6.c;
				return _Utils_Tuple3(
					_Utils_update(
						updatedModel,
						{_: updatedAPI, f4: videoModel}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$SharedState$APICmd, sharedCmd),
								A2($elm$core$Platform$Cmd$map, $author$project$App$SharedState$VideoCmd, videoCmd)
							])),
					respMsgs);
			default:
				var menuMsg = msg.a;
				var _v7 = A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model.ba, $elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							A2($author$project$App$Page$Menu$update, model.fC, menuMsg),
							$elm$core$Tuple$mapFirst($elm$core$Maybe$Just)),
						model.ba));
				var nMenu = _v7.a;
				var menuCmd = _v7.b;
				return _Utils_Tuple3(
					_Utils_update(
						updatedModel,
						{_: updatedAPI, ba: nMenu}),
					menuCmd,
					_List_Nil);
		}
	});
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $author$project$App$Page$Client$PageClient$GotError = 1;
var $author$project$App$Page$Client$PageClient$NotFound = 2;
var $author$project$App$Service$ApplicationResources$getFullStatementMadeFromProponent = F2(
	function (templateId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodStatementFromTemplateIdNewFromProponent(templateId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 29) && (_v0.a.c.$ === 17)) {
						var _v1 = _v0.a;
						var _v2 = _v1.c;
						var fullStmt = _v2.b;
						return $author$project$App$Service$Loadable$Got(fullStmt);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Service$ApplicationResources$getLoadTemplateFromUrl = F3(
	function (url, path, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getResource,
			A2($author$project$App$Service$ApplicationResources$pathLoadTemplateFromUrl, url, path),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 32)) && (_v0.a.c.$ === 19)) {
			var _v1 = _v0.a;
			var _v2 = _v1.a;
			var model = _v1.c.a;
			return $elm$core$Maybe$Just(model);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$ApplicationResources$getStatementFromDocumentAndBirthDate = F2(
	function (templateId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodStatementFromTemplateIdNewFromExisting(templateId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 29) && (_v0.a.c.$ === 17)) {
						var _v1 = _v0.a;
						var _v2 = _v1.c;
						var stmt = _v2.b;
						return $author$project$App$Service$Loadable$Got(stmt);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Page$Client$PageClient$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 0:
				var url = respMsg.a;
				var path = respMsg.b;
				var stmtTemplate = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$StatementTemplate$emptyModel,
					A3($author$project$App$Service$ApplicationResources$getLoadTemplateFromUrl, url, path, st._));
				var errorMessage = $author$project$App$Data$Id$uuidIsValid(stmtTemplate.as) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just('Erro de desconhecido');
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cF: errorMessage, eY: stmtTemplate.as}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var stmt = A2($author$project$App$Service$ApplicationResources$getStatementFromDocumentAndBirthDate, model.eY, st._);
				var status = function () {
					_v2$2:
					while (true) {
						if (stmt.$ === 2) {
							switch (stmt.a.a.$) {
								case 2:
									var _v3 = stmt.a;
									return 2;
								case 0:
									var _v4 = stmt.a;
									return 1;
								default:
									break _v2$2;
							}
						} else {
							break _v2$2;
						}
					}
					return 0;
				}();
				var gotStmtId = function () {
					if (stmt.$ === 3) {
						var statement = stmt.a;
						return statement.as;
					} else {
						return $author$project$App$Data$Id$uuidNil;
					}
				}();
				var newCmd = $author$project$App$Data$Id$uuidIsValid(gotStmtId) ? A2(
					$author$project$App$Route$pushUrl,
					st.fC,
					A2($author$project$App$Route$Proponent, gotStmtId, 1)) : $elm$core$Platform$Cmd$none;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aw: status}),
					newCmd);
			default:
				var stmt = A2($author$project$App$Service$ApplicationResources$getFullStatementMadeFromProponent, model.eY, st._);
				var gotStmtId = function () {
					if (stmt.$ === 3) {
						var statement = stmt.a;
						return statement.as;
					} else {
						return $author$project$App$Data$Id$uuidNil;
					}
				}();
				var newCmd = $author$project$App$Data$Id$uuidIsValid(gotStmtId) ? A2(
					$author$project$App$Route$pushUrl,
					st.fC,
					A2($author$project$App$Route$Proponent, gotStmtId, 1)) : $elm$core$Platform$Cmd$none;
				var errorMsg = $author$project$App$Data$Id$uuidIsValid(gotStmtId) ? '' : 'Erro no cadastro. Por favor entre em contato com o suporte';
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cF: $elm$core$Maybe$Just(errorMsg)
						}),
					newCmd);
		}
	});
var $author$project$App$Service$ApplicationResources$getCompanies = function (api) {
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A4(
			$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
			$author$project$App$Service$ApplicationResources$pathCompanies,
			$author$project$App$Service$SpecificResources$TypeCompany,
			F2(
				function (resource, list) {
					if ((!resource.$) && (resource.a.$ === 6)) {
						var model = resource.a.a;
						return A2($elm$core$List$cons, model, list);
					} else {
						return list;
					}
				}),
			api));
};
var $author$project$App$Service$ApplicationResources$getCompanyReportCsv = F2(
	function (cId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodCreateCompanyReportCsv(cId),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 18)) && (_v0.a.c.$ === 10)) {
			var _v1 = _v0.a;
			var _v2 = _v1.a;
			var doc = _v1.c.a;
			return $elm$core$Maybe$Just(doc);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$ApplicationResources$getCompanyReportPdf = F2(
	function (cId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodCreateCompanyReportPdf(cId),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 18)) && (_v0.a.c.$ === 10)) {
			var _v1 = _v0.a;
			var _v2 = _v1.a;
			var doc = _v1.c.a;
			return $elm$core$Maybe$Just(doc);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$ApplicationResources$getDoctorsFromCompany = F2(
	function (cId, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				$author$project$App$Service$ApplicationResources$pathDoctorsFromCompanyFiltered(cId),
				$author$project$App$Service$SpecificResources$TypeDoctor,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 9)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Service$ApplicationResources$getMedsynMonthDetailsByCompany = F5(
	function (mId, cId, startDate, endDate, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			A4($author$project$App$Service$ApplicationResources$methodMedsynMonthDetailsFromCompanyAndDate, mId, cId, startDate, endDate),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 43)) && (_v0.a.c.$ === 30)) {
			var _v1 = _v0.a;
			var _v2 = _v1.c;
			var summaries = _v2.b;
			return $elm$core$Maybe$Just(summaries);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$ApplicationResources$getMedsynReportByCompany = F5(
	function (mId, cId, startDate, endDate, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			A4($author$project$App$Service$ApplicationResources$methodMedsynReportFromCompanyAndDate, mId, cId, startDate, endDate),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 43)) && (_v0.a.c.$ === 30)) {
			var _v1 = _v0.a;
			var _v2 = _v1.c;
			var details = _v2.b;
			return $elm$core$Maybe$Just(details);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$ApplicationResources$getMonthDetailsByCompany = F4(
	function (cId, startDate, endDate, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			A3($author$project$App$Service$ApplicationResources$methodMonthDetailsFromCompanyAndDate, cId, startDate, endDate),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 43)) && (_v0.a.c.$ === 30)) {
			var _v1 = _v0.a;
			var _v2 = _v1.c;
			var summaries = _v2.b;
			return $elm$core$Maybe$Just(summaries);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$ApplicationResources$getMonthSummaryByCompany = F2(
	function (cId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodMonthSummariesFromCompany(cId),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 41)) && (_v0.a.c.$ === 29)) {
			var _v1 = _v0.a;
			var _v2 = _v1.c;
			var summaries = _v2.b;
			return $elm$core$Maybe$Just(summaries);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$ApplicationResources$getReportByCompany = F4(
	function (cId, startDate, endDate, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			A3($author$project$App$Service$ApplicationResources$methodReportFromCompanyAndDate, cId, startDate, endDate),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 43)) && (_v0.a.c.$ === 30)) {
			var _v1 = _v0.a;
			var _v2 = _v1.c;
			var details = _v2.b;
			return $elm$core$Maybe$Just(details);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$ApplicationResources$getStatementTemplatesFromCompany = F2(
	function (cId, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				$author$project$App$Service$ApplicationResources$pathStatementTemplateFromCompany(cId),
				$author$project$App$Service$SpecificResources$TypeStatementTemplate,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 19)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Service$ApplicationResources$getTaxesByCompany = F2(
	function (cId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodTaxesFromCompany(cId),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 42)) && (_v0.a.c.$ === 31)) {
			var _v1 = _v0.a;
			var _v2 = _v1.c;
			var taxes = _v2.b;
			return $elm$core$Maybe$Just(taxes);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Page$Admin$PageFinanceSales$openDocument = _Platform_outgoingPort('openDocument', $elm$json$Json$Encode$string);
var $author$project$App$Page$Admin$PageFinanceSales$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 0:
				var cId = respMsg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				var cId = respMsg.a;
				var summaries = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($author$project$App$Service$ApplicationResources$getMonthSummaryByCompany, cId, st._));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fS: summaries}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var cId = respMsg.a;
				var taxes = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($author$project$App$Service$ApplicationResources$getTaxesByCompany, cId, st._));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aO: taxes}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var cId = respMsg.a;
				var doc = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$Document$emptyModel,
					A2($author$project$App$Service$ApplicationResources$getCompanyReportCsv, cId, st._));
				var _v1 = (doc.iU === '') ? _Utils_Tuple2(
					$elm$core$Maybe$Just('erro'),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					$elm$core$Maybe$Nothing,
					$author$project$App$Page$Admin$PageFinanceSales$openDocument(doc.iU));
				var errorMsg = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eb: errorMsg}),
					cmds);
			case 8:
				var cId = respMsg.a;
				var doc = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$Document$emptyModel,
					A2($author$project$App$Service$ApplicationResources$getCompanyReportPdf, cId, st._));
				var _v2 = (doc.iU === '') ? _Utils_Tuple2(
					$elm$core$Maybe$Just('erro'),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					$elm$core$Maybe$Nothing,
					$author$project$App$Page$Admin$PageFinanceSales$openDocument(doc.iU));
				var errorMsg = _v2.a;
				var cmds = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eF: errorMsg}),
					cmds);
			case 9:
				var filter = model.b;
				var c = $author$project$App$Service$ApplicationResources$getCompanies(st._);
				var updatedFilter = ($elm$core$List$length(
					A2(
						$elm$core$List$filter,
						function (company) {
							return _Utils_eq(company.as, model.gx);
						},
						c)) > 0) ? filter : _Utils_update(
					filter,
					{bq: c});
				var isMedsyn = $elm$core$List$length(updatedFilter.bq) > 0;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b: updatedFilter, dh: isMedsyn}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var t = A2($author$project$App$Service$ApplicationResources$getStatementTemplatesFromCompany, model.gx, st._);
				var filter = model.b;
				var updatedFilter = _Utils_update(
					filter,
					{eZ: t});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b: updatedFilter}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var filter = model.b;
				var d = A2($author$project$App$Service$ApplicationResources$getDoctorsFromCompany, model.gx, st._);
				var updatedFilter = _Utils_update(
					filter,
					{gJ: d});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b: updatedFilter}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var cId = respMsg.a;
				var startDate = respMsg.b;
				var endDate = respMsg.c;
				var year = respMsg.d;
				var month = respMsg.e;
				var details = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A4($author$project$App$Service$ApplicationResources$getMonthDetailsByCompany, cId, startDate, endDate, st._));
				var extramedValue = A3(
					$elm$core$List$foldr,
					F2(
						function (d, a) {
							return d.T + a;
						}),
					0,
					A2(
						$elm$core$List$filter,
						function (d) {
							return d.cJ !== 'Completo';
						},
						details));
				var centauroValue = A3(
					$elm$core$List$foldr,
					F2(
						function (d, a) {
							return d.T + a;
						}),
					0,
					A2(
						$elm$core$List$filter,
						function (d) {
							return d.cJ === 'Completo';
						},
						details));
				var sumValue = extramedValue + centauroValue;
				var taxFree = A3(
					$elm$core$List$foldl,
					F2(
						function (ct, a) {
							var minValue = function () {
								var _v3 = ct.hl;
								switch (_v3) {
									case 'IRRF':
										return 66667;
									case 'CSLL':
										return 21506;
									case 'PIS':
										return 21506;
									case 'COFINS':
										return 21506;
									default:
										return 0;
								}
							}();
							var extramedTaxFreeValue = (_Utils_cmp(minValue, extramedValue) < 0) ? 0 : $elm$core$Basics$round((extramedValue * ct.hj) / 100000);
							var centauroTaxFreeValue = (_Utils_cmp(minValue, centauroValue) < 0) ? 0 : $elm$core$Basics$round((centauroValue * ct.hj) / 100000);
							return (a + centauroTaxFreeValue) + extramedTaxFreeValue;
						}),
					0,
					model.aO);
				var sales = A2(
					$elm$core$List$map,
					function (ms) {
						if (_Utils_eq(ms.iF, model.o) && _Utils_eq(ms.hk, model.m)) {
							var sale = A2(
								$elm$core$Maybe$withDefault,
								{gD: _List_Nil, at: 0, L: 0, hl: 'Atendimentos', au: 1},
								$elm$core$List$head(ms.S));
							var newSale = _Utils_update(
								sale,
								{gD: details, L: sumValue});
							return _Utils_update(
								ms,
								{
									S: _List_fromArray(
										[newSale]),
									ai: taxFree,
									iq: sumValue
								});
						} else {
							return ms;
						}
					},
					model.w);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{w: sales}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var mId = respMsg.a;
				var cId = respMsg.b;
				var startDate = respMsg.c;
				var endDate = respMsg.d;
				var year = respMsg.e;
				var month = respMsg.f;
				var details = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A5($author$project$App$Service$ApplicationResources$getMedsynMonthDetailsByCompany, mId, cId, startDate, endDate, st._));
				var extramedValue = A3(
					$elm$core$List$foldr,
					F2(
						function (d, a) {
							return d.T + a;
						}),
					0,
					A2(
						$elm$core$List$filter,
						function (d) {
							return d.cJ !== 'Completo';
						},
						details));
				var centauroValue = A3(
					$elm$core$List$foldr,
					F2(
						function (d, a) {
							return d.T + a;
						}),
					0,
					A2(
						$elm$core$List$filter,
						function (d) {
							return d.cJ === 'Completo';
						},
						details));
				var sumValue = extramedValue + centauroValue;
				var taxFree = A3(
					$elm$core$List$foldl,
					F2(
						function (ct, a) {
							var minValue = function () {
								var _v4 = ct.hl;
								switch (_v4) {
									case 'IRRF':
										return 66667;
									case 'CSLL':
										return 21506;
									case 'PIS':
										return 21506;
									case 'COFINS':
										return 21506;
									default:
										return 0;
								}
							}();
							var extramedTaxFreeValue = (_Utils_cmp(minValue, extramedValue) < 0) ? 0 : $elm$core$Basics$round((extramedValue * ct.hj) / 100000);
							var centauroTaxFreeValue = (_Utils_cmp(minValue, centauroValue) < 0) ? 0 : $elm$core$Basics$round((centauroValue * ct.hj) / 100000);
							return (a + centauroTaxFreeValue) + extramedTaxFreeValue;
						}),
					0,
					model.aO);
				var sales = A2(
					$elm$core$List$map,
					function (ms) {
						if (_Utils_eq(ms.iF, model.o) && _Utils_eq(ms.hk, model.m)) {
							var sale = A2(
								$elm$core$Maybe$withDefault,
								{gD: _List_Nil, at: 0, L: 0, hl: 'Atendimentos', au: 1},
								$elm$core$List$head(ms.S));
							var newSale = _Utils_update(
								sale,
								{gD: details, L: sumValue});
							return _Utils_update(
								ms,
								{
									S: _List_fromArray(
										[newSale]),
									ai: taxFree,
									iq: sumValue
								});
						} else {
							return ms;
						}
					},
					model.w);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{w: sales}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var cId = respMsg.a;
				var startDate = respMsg.b;
				var endDate = respMsg.c;
				var doctor = respMsg.d;
				var template = respMsg.e;
				var details = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A4($author$project$App$Service$ApplicationResources$getReportByCompany, cId, startDate, endDate, st._));
				var extramedValue = A3(
					$elm$core$List$foldr,
					F2(
						function (d, a) {
							return d.T + a;
						}),
					0,
					A2(
						$elm$core$List$filter,
						function (d) {
							return d.cJ !== 'Completo';
						},
						details));
				var defaultMonthlySale = {
					b1: $elm$core$Maybe$Nothing,
					gL: $elm$core$Maybe$Nothing,
					hk: 0,
					aR: $elm$core$Maybe$Nothing,
					S: _List_fromArray(
						[
							{gD: _List_Nil, at: 0, L: 0, hl: 'Atendimentos', au: 1}
						]),
					bi: 0,
					ai: 0,
					iq: 0,
					iF: 0
				};
				var centauroValue = A3(
					$elm$core$List$foldr,
					F2(
						function (d, a) {
							return d.T + a;
						}),
					0,
					A2(
						$elm$core$List$filter,
						function (d) {
							return d.cJ === 'Completo';
						},
						details));
				var sumValue = extramedValue + centauroValue;
				var taxFree = A3(
					$elm$core$List$foldl,
					F2(
						function (ct, a) {
							var minValue = function () {
								var _v5 = ct.hl;
								switch (_v5) {
									case 'IRRF':
										return 66667;
									case 'CSLL':
										return 21506;
									case 'PIS':
										return 21506;
									case 'COFINS':
										return 21506;
									default:
										return 0;
								}
							}();
							var extramedTaxFreeValue = (_Utils_cmp(minValue, extramedValue) < 0) ? 0 : $elm$core$Basics$round((extramedValue * ct.hj) / 100000);
							var centauroTaxFreeValue = (_Utils_cmp(minValue, centauroValue) < 0) ? 0 : $elm$core$Basics$round((centauroValue * ct.hj) / 100000);
							return (a + centauroTaxFreeValue) + extramedTaxFreeValue;
						}),
					0,
					model.aO);
				var sales = A2(
					$elm$core$List$map,
					function (ms) {
						var sale = A2(
							$elm$core$Maybe$withDefault,
							{gD: _List_Nil, at: 0, L: 0, hl: 'Atendimentos', au: 1},
							$elm$core$List$head(ms.S));
						var newSale = _Utils_update(
							sale,
							{gD: details, L: sumValue});
						return _Utils_update(
							ms,
							{
								S: _List_fromArray(
									[newSale]),
								ai: taxFree,
								iq: sumValue
							});
					},
					_List_fromArray(
						[defaultMonthlySale]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{w: sales, m: 0, o: 0}),
					$elm$core$Platform$Cmd$none);
			default:
				var mId = respMsg.a;
				var cId = respMsg.b;
				var startDate = respMsg.c;
				var endDate = respMsg.d;
				var doctor = respMsg.e;
				var template = respMsg.f;
				var details = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A5($author$project$App$Service$ApplicationResources$getMedsynReportByCompany, mId, cId, startDate, endDate, st._));
				var extramedValue = A3(
					$elm$core$List$foldr,
					F2(
						function (d, a) {
							return d.T + a;
						}),
					0,
					A2(
						$elm$core$List$filter,
						function (d) {
							return d.cJ !== 'Completo';
						},
						details));
				var defaultMonthlySale = {
					b1: $elm$core$Maybe$Nothing,
					gL: $elm$core$Maybe$Nothing,
					hk: 0,
					aR: $elm$core$Maybe$Nothing,
					S: _List_fromArray(
						[
							{gD: _List_Nil, at: 0, L: 0, hl: 'Atendimentos', au: 1}
						]),
					bi: 0,
					ai: 0,
					iq: 0,
					iF: 0
				};
				var centauroValue = A3(
					$elm$core$List$foldr,
					F2(
						function (d, a) {
							return d.T + a;
						}),
					0,
					A2(
						$elm$core$List$filter,
						function (d) {
							return d.cJ === 'Completo';
						},
						details));
				var sumValue = extramedValue + centauroValue;
				var taxFree = A3(
					$elm$core$List$foldl,
					F2(
						function (ct, a) {
							var minValue = function () {
								var _v6 = ct.hl;
								switch (_v6) {
									case 'IRRF':
										return 66667;
									case 'CSLL':
										return 21506;
									case 'PIS':
										return 21506;
									case 'COFINS':
										return 21506;
									default:
										return 0;
								}
							}();
							var extramedTaxFreeValue = (_Utils_cmp(minValue, extramedValue) < 0) ? 0 : $elm$core$Basics$round((extramedValue * ct.hj) / 100000);
							var centauroTaxFreeValue = (_Utils_cmp(minValue, centauroValue) < 0) ? 0 : $elm$core$Basics$round((centauroValue * ct.hj) / 100000);
							return (a + centauroTaxFreeValue) + extramedTaxFreeValue;
						}),
					0,
					model.aO);
				var sales = A2(
					$elm$core$List$map,
					function (ms) {
						var sale = A2(
							$elm$core$Maybe$withDefault,
							{gD: _List_Nil, at: 0, L: 0, hl: 'Atendimentos', au: 1},
							$elm$core$List$head(ms.S));
						var newSale = _Utils_update(
							sale,
							{gD: details, L: sumValue});
						return _Utils_update(
							ms,
							{
								S: _List_fromArray(
									[newSale]),
								ai: taxFree,
								iq: sumValue
							});
					},
					_List_fromArray(
						[defaultMonthlySale]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{w: sales, m: 0, o: 0}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Page$Admin$PageListDoctors$Load = {$: 0};
var $author$project$App$Page$Admin$PageListDoctors$LoadDoctorFromPerson = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Data$Doctor$emptyModel = {
	al: $elm$time$Time$millisToPosix(0),
	i1: '',
	as: $author$project$App$Data$Id$uuidNil,
	dm: '',
	dn: '',
	et: 0,
	ev: 0,
	hl: '',
	dy: $elm$core$Maybe$Nothing,
	dJ: 0,
	ii: '',
	f2: $elm$time$Time$millisToPosix(0)
};
var $author$project$App$Service$ApplicationResources$getDoctorFromLicense = F3(
	function (license, licenseState, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getResource,
			A2($author$project$App$Service$ApplicationResources$pathDoctorsFromLicense, license, licenseState),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 11) && (_v0.a.c.$ === 9)) {
						var _v1 = _v0.a;
						var _v2 = _v1.a;
						var doctor = _v1.c.a;
						return $author$project$App$Service$Loadable$Got(doctor);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Service$ApplicationResources$getPersonById = F2(
	function (pId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getResource,
			$author$project$App$Service$ApplicationResources$pathPersonById(pId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 23) && (_v0.a.c.$ === 13)) {
						var _v1 = _v0.a;
						var _v2 = _v1.a;
						var person = _v1.c.a;
						return $author$project$App$Service$Loadable$Got(person);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $elm$browser$Browser$Navigation$reload = _Browser_reload(false);
var $author$project$App$Page$Admin$PageListDoctors$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 0:
				var cId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{gx: cId}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Admin$PageListDoctors$Load));
			case 3:
				var pId = respMsg.a;
				var mTemp = model.aB;
				var mPer = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$Person$emptyModel,
					function () {
						var _v1 = A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._);
						if (_v1.$ === 3) {
							var person = _v1.a;
							return $elm$core$Maybe$Just(person);
						} else {
							return $elm$core$Maybe$Nothing;
						}
					}());
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aB: _Utils_update(
								mTemp,
								{fl: model.bu.as}),
							dw: mPer
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var license = respMsg.a;
				var licenseState = respMsg.b;
				var mDoc = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$Doctor$emptyModel,
					function () {
						var _v2 = A3($author$project$App$Service$ApplicationResources$getDoctorFromLicense, license, licenseState, st._);
						if (_v2.$ === 3) {
							var doctor = _v2.a;
							return $elm$core$Maybe$Just(doctor);
						} else {
							return $elm$core$Maybe$Nothing;
						}
					}());
				var cmdMsgs = $author$project$App$Data$Id$uuidIsValid(mDoc.as) ? $author$project$App$SharedState$asyncMsg(
					$author$project$App$Page$Admin$PageListDoctors$LoadDoctorFromPerson(mDoc.as)) : $elm$core$Platform$Cmd$none;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bu: mDoc}),
					cmdMsgs);
			default:
				return _Utils_Tuple2(model, $elm$browser$Browser$Navigation$reload);
		}
	});
var $author$project$App$Page$Admin$PageListFinished$LoadPeople = {$: 2};
var $author$project$App$Page$Admin$PageListFinished$LoadStatements = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Components$ViewFullStatement$NewPeople = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$App$Page$Admin$PageListFinished$ViewInterview = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Service$ApplicationResources$getFinishedStatementsFromAdmin = F3(
	function (cId, filter, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				A2($author$project$App$Service$ApplicationResources$pathFinishedStatementsFromAdminFiltered, cId, filter),
				$author$project$App$Service$SpecificResources$TypeStatement,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 16)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Components$ViewFullStatement$TabProfile = {$: 5};
var $author$project$App$Components$ViewFullStatement$init = F7(
	function (now, fullStmt, sections, companySections, sharedState, doctor, companyUser) {
		var _v0 = A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, doc, _v1) {
					var uDocs = _v1.a;
					var cmds = _v1.b;
					var _v2 = $author$project$App$Data$Document$requestUrl(doc);
					var uDoc = _v2.a;
					var cmd = _v2.b;
					return _Utils_Tuple2(
						A3($elm$core$Dict$insert, k, uDoc, uDocs),
						A2($elm$core$List$cons, cmd, cmds));
				}),
			_Utils_Tuple2(fullStmt.an, _List_Nil),
			fullStmt.an);
		var updatedDocs = _v0.a;
		var dCmd = _v0.b;
		var updatedStmt = _Utils_update(
			fullStmt,
			{an: updatedDocs});
		return _Utils_Tuple2(
			{
				bs: $elm$core$Dict$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, v) {
								return _Utils_Tuple2(i + 1, v);
							}),
						companySections)),
				cZ: companyUser,
				b5: $author$project$App$Components$ViewFullStatement$TabProfile,
				bu: doctor,
				a5: updatedStmt,
				ey: now,
				cA: $elm$core$Dict$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, v) {
								return _Utils_Tuple2(i + 1, v);
							}),
						sections))
			},
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$Cmd$map($author$project$App$Components$ViewFullStatement$DocumentMsg),
					dCmd)));
	});
var $author$project$App$Page$Admin$PageListFinished$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 1:
				var pId = respMsg.a;
				var filter = respMsg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3($author$project$App$Service$ApplicationResources$getFinishedStatementsFromAdmin, pId, filter, st._)
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Admin$PageListFinished$LoadPeople));
			case 2:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			case 0:
				var filter = respMsg.a;
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Admin$PageListFinished$LoadStatements(filter)));
			case 4:
				var stmtId = respMsg.a;
				var filter = respMsg.b;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Admin$PageListFinished$LoadStatements(filter)),
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Admin$PageListFinished$ViewInterview(stmtId))
							])));
			case 3:
				var doctor = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (doc) {
							return _Utils_eq(
								st.ia.h8.g.e.fl,
								$elm$core$Maybe$Just(doc.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var companyPerson = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (person) {
							return _Utils_eq(
								st.ia.h8.g.e.iX,
								$elm$core$Maybe$Just(person.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var _v1 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					_List_Nil,
					_List_Nil,
					st,
					doctor,
					companyPerson);
				var viewModel = _v1.a;
				var viewCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aU: $elm$core$Maybe$Just(st.ia.h8.g.e.as),
							av: $elm$core$Maybe$Just(viewModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Admin$PageListFinished$ViewFullStmtMsg, viewCmd));
			default:
				var _v2 = model.av;
				if (!_v2.$) {
					var viewModel = _v2.a;
					var doctor = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (doc) {
								return _Utils_eq(
									st.ia.h8.g.e.bI,
									$elm$core$Maybe$Just(doc.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var companyPerson = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (person) {
								return _Utils_eq(
									st.ia.h8.g.e.iX,
									$elm$core$Maybe$Just(person.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var _v3 = A4(
						$author$project$App$Components$ViewFullStatement$update,
						A2($author$project$App$Components$ViewFullStatement$NewPeople, doctor, companyPerson),
						st.ia.h8,
						st,
						viewModel);
					var newViewModel = _v3.a;
					var viewCmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								av: $elm$core$Maybe$Just(newViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Admin$PageListFinished$ViewFullStmtMsg, viewCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Page$Admin$PageListReevaluation$LoadPeople = {$: 2};
var $author$project$App$Page$Admin$PageListReevaluation$LoadStatements = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Service$ApplicationResources$getReevalStatementsFromCompany = F3(
	function (cId, filter, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				A2($author$project$App$Service$ApplicationResources$pathReevalStatementsFromCompanyFiltered, cId, filter),
				$author$project$App$Service$SpecificResources$TypeStatement,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 16)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Page$Admin$PageListReevaluation$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 1:
				var cId = respMsg.a;
				var filter = respMsg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3($author$project$App$Service$ApplicationResources$getReevalStatementsFromCompany, cId, filter, st._)
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Admin$PageListReevaluation$LoadPeople));
			case 2:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Admin$PageListReevaluation$LoadStatements($author$project$App$Components$Filter$empty)));
		}
	});
var $author$project$App$Page$Admin$PageListUsers$LoadUsers = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Service$ApplicationResources$getUsersFromCompany = F2(
	function (cId, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				$author$project$App$Service$ApplicationResources$pathUsersFromCompanyFiltered(cId),
				$author$project$App$Service$SpecificResources$TypeUser,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 22)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Page$Admin$PageListUsers$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 0:
				var cId = respMsg.a;
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Admin$PageListUsers$LoadUsers(cId)));
			case 1:
				var cId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cO: A2($author$project$App$Service$ApplicationResources$getUsersFromCompany, cId, st._)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$browser$Browser$Navigation$reload);
		}
	});
var $author$project$App$Page$Admin$PageReevaluation$DocumentMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$Admin$PageReevaluation$Loaded = 1;
var $author$project$App$Components$Chatbox$UpdateMessages = {$: 3};
var $author$project$App$Page$Admin$PageReevaluation$filterSections = F2(
	function (answers, sections) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, section) {
					return !$elm$core$List$isEmpty(
						A2(
							$elm$core$List$filter,
							function (question) {
								return !$elm$core$List$isEmpty(
									A2(
										$elm$core$List$filter,
										function (template) {
											return A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$Answer$emptyModel,
												A2($elm$core$Dict$get, template.as, answers)).d3;
										},
										question.bY));
							},
							section.eI));
				}),
			sections);
	});
var $author$project$App$Service$ApplicationResources$pathCategoryFromStatement = $author$project$App$Service$Shared$pathFromRID(
	$author$project$App$Service$Shared$ridFromString('call.' + ($author$project$App$Data$ICD$ridCategories + '.all')));
var $author$project$App$Service$ApplicationResources$getCategoryFromStatement = function (api) {
	var _v0 = A2($author$project$App$Service$ApiWebSocket$getResource, $author$project$App$Service$ApplicationResources$pathCategoryFromStatement, api);
	if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 14)) && (_v0.a.c.$ === 24)) {
		var _v1 = _v0.a;
		var _v2 = _v1.c;
		var list = _v2.b;
		return $elm$core$Maybe$Just(list);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Service$ApplicationResources$getStatementMessagesFromStatement = F2(
	function (stmtId, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				$author$project$App$Service$ApplicationResources$pathStatementMessageFromStatement(stmtId),
				$author$project$App$Service$SpecificResources$TypeStatementMessage,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 20)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Page$Admin$PageReevaluation$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg) {
			case 0:
				var newFullStmt = model.P;
				var answersDictWithKey = $elm$core$Dict$fromList(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2(-1, st.ia.h8.g.aZ)
							]),
						A2(
							$elm$core$List$map,
							function (_v4) {
								var k = _v4.a;
								var d = _v4.b;
								return _Utils_Tuple2(k, d.aZ);
							},
							$elm$core$Dict$toList(st.ia.h8.fh))));
				var _v1 = A3(
					$elm$core$Dict$foldl,
					F3(
						function (k, doc, _v2) {
							var uDocs = _v2.a;
							var cmds = _v2.b;
							var _v3 = $author$project$App$Data$Document$requestUrl(doc);
							var uDoc = _v3.a;
							var cmd = _v3.b;
							return _Utils_Tuple2(
								A3($elm$core$Dict$insert, k, uDoc, uDocs),
								A2($elm$core$List$cons, cmd, cmds));
						}),
					_Utils_Tuple2(st.ia.h8.an, _List_Nil),
					st.ia.h8.an);
				var updatedDocs = _v1.a;
				var dCmd = _v1.b;
				var newModel = (model.p === 1) ? _Utils_update(
					model,
					{
						an: updatedDocs,
						P: _Utils_update(
							newFullStmt,
							{an: updatedDocs})
					}) : _Utils_update(
					model,
					{
						aZ: answersDictWithKey,
						an: updatedDocs,
						gH: st.ia.h8.gH,
						P: st.ia.h8,
						cA: A2(
							$author$project$App$Page$Admin$PageReevaluation$filterSections,
							st.ia.h8.g.aZ,
							$elm$core$Dict$fromList(
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, s) {
											return _Utils_Tuple2(i, s);
										}),
									st.ia.h8.gH))),
						p: 1,
						fX: A3(
							$elm$core$Dict$insert,
							$author$project$App$Data$Statement$depIndexToInt($author$project$App$Data$Statement$Bearer),
							st.ia.h8.g,
							st.ia.h8.fh)
					});
				return _Utils_Tuple2(
					newModel,
					$elm$core$Platform$Cmd$batch(
						A2(
							$elm$core$List$map,
							$elm$core$Platform$Cmd$map($author$project$App$Page$Admin$PageReevaluation$DocumentMsg),
							dCmd)));
			case 2:
				var cidCategories = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$author$project$App$Service$ApplicationResources$getCategoryFromStatement(st._));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fa: cidCategories}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin($author$project$App$Route$AdminListReevaluation))));
			default:
				var oldChatModel = model.aM;
				var newMessages = $elm$core$List$reverse(
					A2($author$project$App$Service$ApplicationResources$getStatementMessagesFromStatement, model.eV, st._));
				var _v5 = A2(
					$author$project$App$Components$Chatbox$update,
					$author$project$App$Components$Chatbox$UpdateMessages,
					_Utils_update(
						oldChatModel,
						{co: newMessages}));
				var newChatBoxModel = _v5.a;
				var chatBoxCmd = _v5.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aM: newChatBoxModel}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Admin$PageReevaluation$ChatBoxMsg, chatBoxCmd)
							])));
		}
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$DocumentMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$Loaded = 1;
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$filterSections = F2(
	function (answers, sections) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, section) {
					return !$elm$core$List$isEmpty(
						A2(
							$elm$core$List$filter,
							function (question) {
								return !$elm$core$List$isEmpty(
									A2(
										$elm$core$List$filter,
										function (template) {
											return A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$Answer$emptyModel,
												A2($elm$core$Dict$get, template.as, answers)).d3;
										},
										question.bY));
							},
							section.eI));
				}),
			sections);
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg) {
			case 0:
				var newFullStmt = model.P;
				var answersDictWithKey = $elm$core$Dict$fromList(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2(-1, st.ia.h8.g.aZ)
							]),
						A2(
							$elm$core$List$map,
							function (_v4) {
								var k = _v4.a;
								var d = _v4.b;
								return _Utils_Tuple2(k, d.aZ);
							},
							$elm$core$Dict$toList(st.ia.h8.fh))));
				var _v1 = A3(
					$elm$core$Dict$foldl,
					F3(
						function (k, doc, _v2) {
							var uDocs = _v2.a;
							var cmds = _v2.b;
							var _v3 = $author$project$App$Data$Document$requestUrl(doc);
							var uDoc = _v3.a;
							var cmd = _v3.b;
							return _Utils_Tuple2(
								A3($elm$core$Dict$insert, k, uDoc, uDocs),
								A2($elm$core$List$cons, cmd, cmds));
						}),
					_Utils_Tuple2(st.ia.h8.an, _List_Nil),
					st.ia.h8.an);
				var updatedDocs = _v1.a;
				var dCmd = _v1.b;
				var newModel = (model.p === 1) ? _Utils_update(
					model,
					{
						an: updatedDocs,
						P: _Utils_update(
							newFullStmt,
							{an: updatedDocs})
					}) : _Utils_update(
					model,
					{
						aZ: answersDictWithKey,
						an: updatedDocs,
						gH: st.ia.h8.gH,
						P: st.ia.h8,
						cA: A2(
							$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$filterSections,
							st.ia.h8.g.aZ,
							$elm$core$Dict$fromList(
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, s) {
											return _Utils_Tuple2(i, s);
										}),
									st.ia.h8.gH))),
						p: 1,
						fX: A3(
							$elm$core$Dict$insert,
							$author$project$App$Data$Statement$depIndexToInt($author$project$App$Data$Statement$Bearer),
							st.ia.h8.g,
							st.ia.h8.fh)
					});
				return _Utils_Tuple2(
					newModel,
					$elm$core$Platform$Cmd$batch(
						A2(
							$elm$core$List$map,
							$elm$core$Platform$Cmd$map($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$DocumentMsg),
							dCmd)));
			case 1:
				var cidCategories = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$author$project$App$Service$ApplicationResources$getCategoryFromStatement(st._));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fa: cidCategories}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin(
								$author$project$App$Route$AdminListFinishedRoute(
									$author$project$App$Route$AdminListFinished(
										$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$defaultFilter($author$project$App$Components$Filter$empty)))))));
			default:
				var oldChatModel = model.aM;
				var newMessages = $elm$core$List$reverse(
					A2($author$project$App$Service$ApplicationResources$getStatementMessagesFromStatement, model.eV, st._));
				var _v5 = A2(
					$author$project$App$Components$Chatbox$update,
					$author$project$App$Components$Chatbox$UpdateMessages,
					_Utils_update(
						oldChatModel,
						{co: newMessages}));
				var newChatBoxModel = _v5.a;
				var chatBoxCmd = _v5.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aM: newChatBoxModel}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$ChatBoxMsg, chatBoxCmd)
							])));
		}
	});
var $author$project$App$Page$PageAdmin$updateResponse = F3(
	function (respMsg, st, model) {
		var _v0 = _Utils_Tuple2(respMsg, model);
		switch (_v0.a.$) {
			case 0:
				if (!_v0.b.$) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v1 = A3($author$project$App$Page$Admin$PageListUsers$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v1.a;
					var nPageMsg = _v1.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageAdmin$ListUsers(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListUsersMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				if (_v0.b.$ === 1) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v2 = A3($author$project$App$Page$Admin$PageListDoctors$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v2.a;
					var nPageMsg = _v2.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageAdmin$ListDoctors(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListDoctorsMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				if (_v0.b.$ === 2) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v3 = A3($author$project$App$Page$Admin$PageFinanceSales$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v3.a;
					var nPageMsg = _v3.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageAdmin$FinanceSales(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$FinanceSalesMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				if (_v0.b.$ === 3) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v4 = A3($author$project$App$Page$Admin$PageListFinished$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v4.a;
					var nPageMsg = _v4.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageAdmin$ListFinished(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListFinishedMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				if (_v0.b.$ === 6) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v5 = A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v5.a;
					var nPageMsg = _v5.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageAdmin$ViewEvaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ViewEvaluationMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				if (_v0.b.$ === 4) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v6 = A3($author$project$App$Page$Admin$PageListReevaluation$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v6.a;
					var nPageMsg = _v6.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageAdmin$ListReevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListReevaluationMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				if (_v0.b.$ === 5) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v7 = A3($author$project$App$Page$Admin$PageReevaluation$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v7.a;
					var nPageMsg = _v7.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageAdmin$Reevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ReevaluationMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Page$Company$PageListCanceled$LoadPeople = {$: 3};
var $author$project$App$Page$Company$PageListCanceled$LoadStatements = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Company$PageListCanceled$ViewDetails = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Service$ApplicationResources$getStatementsFromCompany = F3(
	function (cId, filter, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				A2($author$project$App$Service$ApplicationResources$pathStatementsFromCompanyFiltered, cId, filter),
				$author$project$App$Service$SpecificResources$TypeStatement,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 16)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Page$Company$PageListCanceled$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Company$PageListCanceled$LoadStatements(model.b)));
			case 1:
				var stmtId = respMsg.a;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Company$PageListCanceled$LoadStatements(model.b)),
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Company$PageListCanceled$ViewDetails(stmtId))
							])));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3(
								$author$project$App$Service$ApplicationResources$getStatementsFromCompany,
								model.gx,
								$author$project$App$Page$Company$PageListCanceled$defaultFilter(model.b),
								st._)
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Company$PageListCanceled$LoadPeople));
			case 3:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var stmtId = respMsg.a;
				var doctor = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (doc) {
							return _Utils_eq(
								st.ia.h8.g.e.fl,
								$elm$core$Maybe$Just(doc.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var companyPerson = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (person) {
							return _Utils_eq(
								st.ia.h8.g.e.iX,
								$elm$core$Maybe$Just(person.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var _v1 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					_List_Nil,
					_List_Nil,
					st,
					doctor,
					companyPerson);
				var viewModel = _v1.a;
				var viewCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gD: A2($author$project$App$Page$Company$PageListCanceled$Showing, stmtId, viewModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageListCanceled$ViewFullStmtMsg, viewCmd));
			default:
				var _v2 = model.gD;
				if (_v2.$ === 1) {
					var stmtId = _v2.a;
					var viewModel = _v2.b;
					var doctor = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (doc) {
								return _Utils_eq(
									st.ia.h8.g.e.bI,
									$elm$core$Maybe$Just(doc.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var companyPerson = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (person) {
								return _Utils_eq(
									st.ia.h8.g.e.iX,
									$elm$core$Maybe$Just(person.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var _v3 = A4(
						$author$project$App$Components$ViewFullStatement$update,
						A2($author$project$App$Components$ViewFullStatement$NewPeople, doctor, companyPerson),
						st.ia.h8,
						st,
						viewModel);
					var newViewModel = _v3.a;
					var viewCmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Company$PageListCanceled$Showing, stmtId, newViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageListCanceled$ViewFullStmtMsg, viewCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Page$Company$PageListConcluded$LoadPeople = {$: 3};
var $author$project$App$Page$Company$PageListConcluded$LoadStatements = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Company$PageListConcluded$ViewDetails = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Company$PageListConcluded$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Company$PageListConcluded$LoadStatements(model.b)));
			case 1:
				var stmtId = respMsg.a;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Company$PageListConcluded$LoadStatements(model.b)),
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Company$PageListConcluded$ViewDetails(stmtId))
							])));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3(
								$author$project$App$Service$ApplicationResources$getStatementsFromCompany,
								model.gx,
								$author$project$App$Page$Company$PageListConcluded$defaultFilter(model.b),
								st._)
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Company$PageListConcluded$LoadPeople));
			case 3:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var stmtId = respMsg.a;
				var doctor = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (doc) {
							return _Utils_eq(
								st.ia.h8.g.e.fl,
								$elm$core$Maybe$Just(doc.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var companyPerson = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (person) {
							return _Utils_eq(
								st.ia.h8.g.e.iX,
								$elm$core$Maybe$Just(person.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var _v1 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					_List_Nil,
					_List_Nil,
					st,
					doctor,
					companyPerson);
				var viewModel = _v1.a;
				var viewCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gD: A2($author$project$App$Page$Company$PageListConcluded$Showing, stmtId, viewModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageListConcluded$ViewFullStmtMsg, viewCmd));
			default:
				var _v2 = model.gD;
				if (_v2.$ === 1) {
					var stmtId = _v2.a;
					var viewModel = _v2.b;
					var doctor = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (doc) {
								return _Utils_eq(
									st.ia.h8.g.e.bI,
									$elm$core$Maybe$Just(doc.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var companyPerson = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (person) {
								return _Utils_eq(
									st.ia.h8.g.e.iX,
									$elm$core$Maybe$Just(person.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var _v3 = A4(
						$author$project$App$Components$ViewFullStatement$update,
						A2($author$project$App$Components$ViewFullStatement$NewPeople, doctor, companyPerson),
						st.ia.h8,
						st,
						viewModel);
					var newViewModel = _v3.a;
					var viewCmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Company$PageListConcluded$Showing, stmtId, newViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageListConcluded$ViewFullStmtMsg, viewCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Page$Company$PageListPending$LoadPeople = {$: 2};
var $author$project$App$Page$Company$PageListPending$LoadStatements = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$Company$PageListPending$ViewInterview = function (a) {
	return {$: 5, a: a};
};
var $elm$core$List$sortWith = _List_sortWith;
var $author$project$App$Page$Company$PageListPending$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 2:
				var cId = respMsg.a;
				var filter = respMsg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A2(
								$elm$core$List$sortWith,
								F2(
									function (a, b) {
										var _v1 = A2(
											$elm$core$Basics$compare,
											$elm$time$Time$posixToMillis(a.f2),
											$elm$time$Time$posixToMillis(b.f2));
										switch (_v1) {
											case 2:
												return 0;
											case 0:
												return 2;
											default:
												return 1;
										}
									}),
								A3($author$project$App$Service$ApplicationResources$getStatementsFromCompany, cId, filter, st._))
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Company$PageListPending$LoadPeople));
			case 3:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			case 0:
				var cId = respMsg.a;
				var filter = respMsg.b;
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						A2($author$project$App$Page$Company$PageListPending$LoadStatements, cId, filter)));
			case 1:
				var cId = respMsg.a;
				var stmtId = respMsg.b;
				var filter = respMsg.c;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$SharedState$asyncMsg(
								A2($author$project$App$Page$Company$PageListPending$LoadStatements, cId, filter)),
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Company$PageListPending$ViewInterview(stmtId))
							])));
			case 4:
				var doctor = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (doc) {
							return _Utils_eq(
								st.ia.h8.g.e.fl,
								$elm$core$Maybe$Just(doc.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var companyPerson = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (person) {
							return _Utils_eq(
								st.ia.h8.g.e.iX,
								$elm$core$Maybe$Just(person.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var _v2 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					_List_Nil,
					_List_Nil,
					st,
					doctor,
					companyPerson);
				var viewModel = _v2.a;
				var viewCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aU: $elm$core$Maybe$Just(st.ia.h8.g.e.as),
							av: $elm$core$Maybe$Just(viewModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageListPending$ViewFullStmtMsg, viewCmd));
			default:
				var _v3 = model.av;
				if (!_v3.$) {
					var viewModel = _v3.a;
					var doctor = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (doc) {
								return _Utils_eq(
									st.ia.h8.g.e.bI,
									$elm$core$Maybe$Just(doc.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var companyPerson = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (person) {
								return _Utils_eq(
									st.ia.h8.g.e.iX,
									$elm$core$Maybe$Just(person.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var _v4 = A4(
						$author$project$App$Components$ViewFullStatement$update,
						A2($author$project$App$Components$ViewFullStatement$NewPeople, doctor, companyPerson),
						st.ia.h8,
						st,
						viewModel);
					var newViewModel = _v4.a;
					var viewCmd = _v4.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								av: $elm$core$Maybe$Just(newViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageListPending$ViewFullStmtMsg, viewCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Page$Company$PageListReevaluation$LoadPeople = {$: 2};
var $author$project$App$Page$Company$PageListReevaluation$LoadStatements = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Company$PageListReevaluation$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 1:
				var cId = respMsg.a;
				var filter = respMsg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3($author$project$App$Service$ApplicationResources$getReevalStatementsFromCompany, cId, filter, st._)
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Company$PageListReevaluation$LoadPeople));
			case 2:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Company$PageListReevaluation$LoadStatements($author$project$App$Components$Filter$empty)));
		}
	});
var $author$project$App$Page$Company$PageReevaluation$DocumentMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$Company$PageReevaluation$Loaded = 1;
var $author$project$App$Page$Company$PageReevaluation$filterSections = F2(
	function (answers, sections) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, section) {
					return !$elm$core$List$isEmpty(
						A2(
							$elm$core$List$filter,
							function (question) {
								return !$elm$core$List$isEmpty(
									A2(
										$elm$core$List$filter,
										function (template) {
											return A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$Answer$emptyModel,
												A2($elm$core$Dict$get, template.as, answers)).d3;
										},
										question.bY));
							},
							section.eI));
				}),
			sections);
	});
var $author$project$App$Page$Company$PageReevaluation$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg) {
			case 0:
				var newFullStmt = model.P;
				var answersDictWithKey = $elm$core$Dict$fromList(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2(-1, st.ia.h8.g.aZ)
							]),
						A2(
							$elm$core$List$map,
							function (_v4) {
								var k = _v4.a;
								var d = _v4.b;
								return _Utils_Tuple2(k, d.aZ);
							},
							$elm$core$Dict$toList(st.ia.h8.fh))));
				var _v1 = A3(
					$elm$core$Dict$foldl,
					F3(
						function (k, doc, _v2) {
							var uDocs = _v2.a;
							var cmds = _v2.b;
							var _v3 = $author$project$App$Data$Document$requestUrl(doc);
							var uDoc = _v3.a;
							var cmd = _v3.b;
							return _Utils_Tuple2(
								A3($elm$core$Dict$insert, k, uDoc, uDocs),
								A2($elm$core$List$cons, cmd, cmds));
						}),
					_Utils_Tuple2(st.ia.h8.an, _List_Nil),
					st.ia.h8.an);
				var updatedDocs = _v1.a;
				var dCmd = _v1.b;
				var newModel = (model.p === 1) ? _Utils_update(
					model,
					{
						an: updatedDocs,
						P: _Utils_update(
							newFullStmt,
							{an: updatedDocs})
					}) : _Utils_update(
					model,
					{
						aZ: answersDictWithKey,
						an: updatedDocs,
						gH: st.ia.h8.gH,
						P: st.ia.h8,
						cA: A2(
							$author$project$App$Page$Company$PageReevaluation$filterSections,
							st.ia.h8.g.aZ,
							$elm$core$Dict$fromList(
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, s) {
											return _Utils_Tuple2(i, s);
										}),
									st.ia.h8.gH))),
						p: 1,
						fX: A3(
							$elm$core$Dict$insert,
							$author$project$App$Data$Statement$depIndexToInt($author$project$App$Data$Statement$Bearer),
							st.ia.h8.g,
							st.ia.h8.fh)
					});
				return _Utils_Tuple2(
					newModel,
					$elm$core$Platform$Cmd$batch(
						A2(
							$elm$core$List$map,
							$elm$core$Platform$Cmd$map($author$project$App$Page$Company$PageReevaluation$DocumentMsg),
							dCmd)));
			case 2:
				var cidCategories = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$author$project$App$Service$ApplicationResources$getCategoryFromStatement(st._));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fa: cidCategories}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company($author$project$App$Route$CompanyListReevaluation))));
			case 3:
				var oldChatModel = model.aM;
				var newMessages = $elm$core$List$reverse(
					A2($author$project$App$Service$ApplicationResources$getStatementMessagesFromStatement, model.eV, st._));
				var _v5 = A2(
					$author$project$App$Components$Chatbox$update,
					$author$project$App$Components$Chatbox$UpdateMessages,
					_Utils_update(
						oldChatModel,
						{co: newMessages}));
				var newChatBoxModel = _v5.a;
				var chatBoxCmd = _v5.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aM: newChatBoxModel}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageReevaluation$ChatBoxMsg, chatBoxCmd)
							])));
			default:
				return _Utils_Tuple2(model, $elm$browser$Browser$Navigation$reload);
		}
	});
var $author$project$App$Page$Company$PageRequestInterview$Load = {$: 0};
var $author$project$App$Page$Company$PageRequestInterview$LoadStatementFromPerson = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Service$ApplicationResources$getPersonByDocument = F2(
	function (document, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getResource,
			$author$project$App$Service$ApplicationResources$pathPersonByDocument(document),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 23) && (_v0.a.c.$ === 13)) {
						var _v1 = _v0.a;
						var _v2 = _v1.a;
						var person = _v1.c.a;
						return $author$project$App$Service$Loadable$Got(person);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Page$Company$PageRequestInterview$updateResponse = F3(
	function (respMsg, st, model) {
		var form = $author$project$App$Page$Company$PageRequestInterview$formFromModel(model);
		var fullStmt = form.a5;
		var bearer = fullStmt.g;
		switch (respMsg.$) {
			case 0:
				var cId = respMsg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$App$Page$Company$PageRequestInterview$updateForm,
						_Utils_update(
							form,
							{gx: cId}),
						model),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Company$PageRequestInterview$Load));
			case 4:
				if (!respMsg.b.$) {
					var document = respMsg.a;
					var _v1 = respMsg.b;
					var _v2 = function () {
						var _v3 = A2($author$project$App$Service$ApplicationResources$getPersonByDocument, document, st._);
						if (_v3.$ === 3) {
							var p = _v3.a;
							return _Utils_Tuple2(
								p,
								$author$project$App$SharedState$asyncMsg(
									$author$project$App$Page$Company$PageRequestInterview$LoadStatementFromPerson(p.as)));
						} else {
							return _Utils_Tuple2(bearer.dw, $elm$core$Platform$Cmd$none);
						}
					}();
					var person = _v2.a;
					var cmd = _v2.b;
					return _Utils_Tuple2(
						A2(
							$author$project$App$Page$Company$PageRequestInterview$updateForm,
							_Utils_update(
								form,
								{
									a5: _Utils_update(
										fullStmt,
										{
											g: _Utils_update(
												bearer,
												{dw: person})
										})
								}),
							model),
						cmd);
				} else {
					var document = respMsg.a;
					var idx = respMsg.b.a;
					var dep = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$FullStatement$emptyEditStatement,
						A2($elm$core$Dict$get, idx, fullStmt.fh));
					var person = function () {
						var _v4 = A2($author$project$App$Service$ApplicationResources$getPersonByDocument, document, st._);
						if (_v4.$ === 3) {
							var p = _v4.a;
							return p;
						} else {
							return dep.dw;
						}
					}();
					var nDep = _Utils_update(
						dep,
						{dw: person});
					return _Utils_Tuple2(
						A2(
							$author$project$App$Page$Company$PageRequestInterview$updateForm,
							_Utils_update(
								form,
								{
									a5: _Utils_update(
										fullStmt,
										{
											fh: A3($elm$core$Dict$insert, idx, nDep, fullStmt.fh)
										})
								}),
							model),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var personId = respMsg.a;
				var _v5 = A2($author$project$App$Service$ApplicationResources$getStatementFromPerson, personId, st._);
				if (_v5.$ === 3) {
					var stmt = _v5.a;
					var _v6 = _Utils_Tuple2(model, stmt.aw);
					if ((!_v6.a.$) && (!_v6.b)) {
						var _v7 = _v6.b;
						return _Utils_Tuple2(
							A2(
								$author$project$App$Page$Company$PageRequestInterview$Edit,
								stmt.as,
								_Utils_update(
									form,
									{
										a5: _Utils_update(
											fullStmt,
											{
												g: _Utils_update(
													bearer,
													{e: stmt})
											})
									})),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2(
										$author$project$App$Route$pushUrl,
										st.fC,
										$author$project$App$Route$Private(
											$author$project$App$Route$Company(
												$author$project$App$Route$CompanyRequestInterview(
													$author$project$App$Route$CompanyRequestInterviewEdit(stmt.as))))),
										$author$project$App$SharedState$asyncMsg($author$project$App$Page$Company$PageRequestInterview$Load)
									])));
					} else {
						return _Utils_Tuple2(
							model,
							A2(
								$author$project$App$Route$pushUrl,
								st.fC,
								$author$project$App$Route$Private(
									$author$project$App$Route$Company(
										$author$project$App$Route$CompanyListPendingRoute(
											$author$project$App$Route$CompanyListPending($author$project$App$Components$Filter$empty))))));
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var stmtId = respMsg.a;
				var loadedFullStmt = st.ia.h8;
				var _v8 = A3(
					$elm$core$Dict$foldl,
					F3(
						function (k, doc, _v9) {
							var uDocs = _v9.a;
							var cmds = _v9.b;
							var _v10 = $author$project$App$Data$Document$requestUrl(doc);
							var uDoc = _v10.a;
							var cmd = _v10.b;
							return _Utils_Tuple2(
								A3($elm$core$Dict$insert, k, uDoc, uDocs),
								A2($elm$core$List$cons, cmd, cmds));
						}),
					_Utils_Tuple2(loadedFullStmt.an, _List_Nil),
					loadedFullStmt.an);
				var updatedDocs = _v8.a;
				var dCmd = _v8.b;
				var updatedStmt = _Utils_update(
					loadedFullStmt,
					{an: updatedDocs});
				return _Utils_Tuple2(
					A2(
						$author$project$App$Page$Company$PageRequestInterview$updateForm,
						_Utils_update(
							form,
							{a5: updatedStmt, ep: true}),
						model),
					$elm$core$Platform$Cmd$batch(
						A2(
							$elm$core$List$map,
							$elm$core$Platform$Cmd$map($author$project$App$Page$Company$PageRequestInterview$DocumentMsg),
							dCmd)));
			case 1:
				var cId = respMsg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				var cId = respMsg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyListPendingRoute(
									$author$project$App$Route$CompanyListPending($author$project$App$Components$Filter$empty))))));
		}
	});
var $author$project$App$Page$Company$PageViewEvaluation$DocumentMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Company$PageViewEvaluation$Loaded = 1;
var $author$project$App$Page$Company$PageViewEvaluation$filterSections = F2(
	function (answers, sections) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, section) {
					return !$elm$core$List$isEmpty(
						A2(
							$elm$core$List$filter,
							function (question) {
								return !$elm$core$List$isEmpty(
									A2(
										$elm$core$List$filter,
										function (template) {
											return A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$Answer$emptyModel,
												A2($elm$core$Dict$get, template.as, answers)).d3;
										},
										question.bY));
							},
							section.eI));
				}),
			sections);
	});
var $author$project$App$Page$Company$PageViewEvaluation$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg) {
			case 0:
				var newFullStmt = model.P;
				var answersDictWithKey = $elm$core$Dict$fromList(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2(-1, st.ia.h8.g.aZ)
							]),
						A2(
							$elm$core$List$map,
							function (_v4) {
								var k = _v4.a;
								var d = _v4.b;
								return _Utils_Tuple2(k, d.aZ);
							},
							$elm$core$Dict$toList(st.ia.h8.fh))));
				var _v1 = A3(
					$elm$core$Dict$foldl,
					F3(
						function (k, doc, _v2) {
							var uDocs = _v2.a;
							var cmds = _v2.b;
							var _v3 = $author$project$App$Data$Document$requestUrl(doc);
							var uDoc = _v3.a;
							var cmd = _v3.b;
							return _Utils_Tuple2(
								A3($elm$core$Dict$insert, k, uDoc, uDocs),
								A2($elm$core$List$cons, cmd, cmds));
						}),
					_Utils_Tuple2(st.ia.h8.an, _List_Nil),
					st.ia.h8.an);
				var updatedDocs = _v1.a;
				var dCmd = _v1.b;
				var newModel = (model.p === 1) ? _Utils_update(
					model,
					{
						an: updatedDocs,
						P: _Utils_update(
							newFullStmt,
							{an: updatedDocs})
					}) : _Utils_update(
					model,
					{
						aZ: answersDictWithKey,
						an: updatedDocs,
						gH: st.ia.h8.gH,
						P: st.ia.h8,
						cA: A2(
							$author$project$App$Page$Company$PageViewEvaluation$filterSections,
							st.ia.h8.g.aZ,
							$elm$core$Dict$fromList(
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, s) {
											return _Utils_Tuple2(i, s);
										}),
									st.ia.h8.gH))),
						p: 1,
						fX: A3(
							$elm$core$Dict$insert,
							$author$project$App$Data$Statement$depIndexToInt($author$project$App$Data$Statement$Bearer),
							st.ia.h8.g,
							st.ia.h8.fh)
					});
				return _Utils_Tuple2(
					newModel,
					$elm$core$Platform$Cmd$batch(
						A2(
							$elm$core$List$map,
							$elm$core$Platform$Cmd$map($author$project$App$Page$Company$PageViewEvaluation$DocumentMsg),
							dCmd)));
			case 1:
				var cidCategories = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$author$project$App$Service$ApplicationResources$getCategoryFromStatement(st._));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fa: cidCategories}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Company(
								$author$project$App$Route$CompanyListConcludedRoute(
									$author$project$App$Route$CompanyListConcluded(
										$author$project$App$Page$Company$PageViewEvaluation$defaultFilter($author$project$App$Components$Filter$empty)))))));
			case 3:
				var oldChatModel = model.aM;
				var newMessages = $elm$core$List$reverse(
					A2($author$project$App$Service$ApplicationResources$getStatementMessagesFromStatement, model.eV, st._));
				var _v5 = A2(
					$author$project$App$Components$Chatbox$update,
					$author$project$App$Components$Chatbox$UpdateMessages,
					_Utils_update(
						oldChatModel,
						{co: newMessages}));
				var newChatBoxModel = _v5.a;
				var chatBoxCmd = _v5.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aM: newChatBoxModel}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Company$PageViewEvaluation$ChatBoxMsg, chatBoxCmd)
							])));
			default:
				return _Utils_Tuple2(model, $elm$browser$Browser$Navigation$reload);
		}
	});
var $author$project$App$Page$PageCompany$updateResponse = F3(
	function (respMsg, st, model) {
		var _v0 = _Utils_Tuple2(respMsg, model);
		switch (_v0.a.$) {
			case 1:
				if (_v0.b.$ === 1) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v1 = A3($author$project$App$Page$Company$PageListPending$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v1.a;
					var nPageMsg = _v1.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageCompany$ListPending(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListPendingMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				if (_v0.b.$ === 2) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v2 = A3($author$project$App$Page$Company$PageListCanceled$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v2.a;
					var nPageMsg = _v2.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageCompany$ListCanceled(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListCanceledMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				if (_v0.b.$ === 3) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v3 = A3($author$project$App$Page$Company$PageListConcluded$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v3.a;
					var nPageMsg = _v3.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageCompany$ListConcluded(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListConcludedMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 0:
				if (!_v0.b.$) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v4 = A3($author$project$App$Page$Company$PageRequestInterview$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v4.a;
					var nPageMsg = _v4.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageCompany$RequestInterview(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$RequestInterviewMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				if (_v0.b.$ === 6) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v5 = A3($author$project$App$Page$Company$PageViewEvaluation$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v5.a;
					var nPageMsg = _v5.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageCompany$ViewEvaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ViewEvaluationMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				if (_v0.b.$ === 5) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v6 = A3($author$project$App$Page$Company$PageListReevaluation$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v6.a;
					var nPageMsg = _v6.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageCompany$ListReevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListReevaluationMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				if (_v0.b.$ === 4) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v7 = A3($author$project$App$Page$Company$PageReevaluation$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v7.a;
					var nPageMsg = _v7.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageCompany$Reevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ReevaluationMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Service$ApplicationResources$getDoctorReportCsv = F2(
	function (dId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodCreateDoctorReportCsv(dId),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 18)) && (_v0.a.c.$ === 10)) {
			var _v1 = _v0.a;
			var _v2 = _v1.a;
			var doc = _v1.c.a;
			return $elm$core$Maybe$Just(doc);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$ApplicationResources$getDoctorReportPdf = F2(
	function (dId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodCreateDoctorReportPdf(dId),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 18)) && (_v0.a.c.$ === 10)) {
			var _v1 = _v0.a;
			var _v2 = _v1.a;
			var doc = _v1.c.a;
			return $elm$core$Maybe$Just(doc);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$ApplicationResources$getMonthDetailsByDoctor = F4(
	function (dId, startDate, endDate, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			A3($author$project$App$Service$ApplicationResources$methodMonthDetailsFromDoctorAndDate, dId, startDate, endDate),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 43)) && (_v0.a.c.$ === 30)) {
			var _v1 = _v0.a;
			var _v2 = _v1.c;
			var summaries = _v2.b;
			return $elm$core$Maybe$Just(summaries);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$ApplicationResources$getMonthSummaryByDoctor = F2(
	function (pId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodMonthSummariesFromDoctor(pId),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 41)) && (_v0.a.c.$ === 29)) {
			var _v1 = _v0.a;
			var _v2 = _v1.c;
			var summaries = _v2.b;
			return $elm$core$Maybe$Just(summaries);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Page$Doctor$PageDoctorSales$openDocumentAsDoctor = _Platform_outgoingPort('openDocumentAsDoctor', $elm$json$Json$Encode$string);
var $author$project$App$Page$Doctor$PageDoctorSales$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 0:
				var cId = respMsg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				var cId = respMsg.a;
				var summaries = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($author$project$App$Service$ApplicationResources$getMonthSummaryByDoctor, cId, st._));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fS: summaries}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var cId = respMsg.a;
				var doc = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$Document$emptyModel,
					A2($author$project$App$Service$ApplicationResources$getDoctorReportCsv, cId, st._));
				var _v1 = (doc.iU === '') ? _Utils_Tuple2(
					$elm$core$Maybe$Just('erro'),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					$elm$core$Maybe$Nothing,
					$author$project$App$Page$Doctor$PageDoctorSales$openDocumentAsDoctor(doc.iU));
				var errorMsg = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eb: errorMsg}),
					cmds);
			case 5:
				var cId = respMsg.a;
				var doc = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$Document$emptyModel,
					A2($author$project$App$Service$ApplicationResources$getDoctorReportPdf, cId, st._));
				var _v2 = (doc.iU === '') ? _Utils_Tuple2(
					$elm$core$Maybe$Just('erro'),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					$elm$core$Maybe$Nothing,
					$author$project$App$Page$Doctor$PageDoctorSales$openDocumentAsDoctor(doc.iU));
				var errorMsg = _v2.a;
				var cmds = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eF: errorMsg}),
					cmds);
			case 6:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 7:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 2:
				var dId = respMsg.a;
				var startDate = respMsg.b;
				var endDate = respMsg.c;
				var year = respMsg.d;
				var month = respMsg.e;
				var details = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A4($author$project$App$Service$ApplicationResources$getMonthDetailsByDoctor, dId, startDate, endDate, st._));
				var sumValue = A3(
					$elm$core$List$foldr,
					F2(
						function (d, a) {
							return d.gI + a;
						}),
					0,
					details);
				var sales = A2(
					$elm$core$List$map,
					function (ms) {
						if (_Utils_eq(ms.iF, model.o) && _Utils_eq(ms.hk, model.m)) {
							var sale = A2(
								$elm$core$Maybe$withDefault,
								{gD: _List_Nil, at: 0, L: 0, hl: 'Atendimentos', au: 1},
								$elm$core$List$head(ms.S));
							var newSale = _Utils_update(
								sale,
								{gD: details, L: sumValue});
							return _Utils_update(
								ms,
								{
									S: _List_fromArray(
										[newSale]),
									iq: sumValue
								});
						} else {
							return ms;
						}
					},
					model.w);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{w: sales}),
					$elm$core$Platform$Cmd$none);
			default:
				var cId = respMsg.a;
				var startDate = respMsg.b;
				var endDate = respMsg.c;
				var doctor = respMsg.d;
				var template = respMsg.e;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$LoadMemed = {$: 27};
var $author$project$App$Page$Doctor$PageFinalEvaluation$LoadNexodata = {$: 28};
var $author$project$App$Page$Doctor$PageFinalEvaluation$Loaded = 1;
var $author$project$App$Page$Doctor$PageFinalEvaluation$filterSections = F2(
	function (answers, sections) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, section) {
					return !$elm$core$List$isEmpty(
						A2(
							$elm$core$List$filter,
							function (question) {
								return !$elm$core$List$isEmpty(
									A2(
										$elm$core$List$filter,
										function (template) {
											return A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$Answer$emptyModel,
												A2($elm$core$Dict$get, template.as, answers)).d3;
										},
										question.bY));
							},
							section.eI));
				}),
			sections);
	});
var $author$project$App$Service$ApplicationResources$getNexodataToken = function (api) {
	var _v0 = A2(
		$author$project$App$Service$ApiWebSocket$getResource,
		$author$project$App$Service$Shared$pathFromRID(
			$author$project$App$Service$Shared$ridFromString('call.' + ($author$project$App$Service$ApplicationResources$ridGetNexodataToken + '.getToken'))),
		api);
	if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 16)) && (_v0.a.c.$ === 25)) {
		var _v1 = _v0.a;
		var _v2 = _v1.c;
		var token = _v2.b;
		return $elm$core$Maybe$Just(token);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Service$ApplicationResources$getSignDocumentError = F2(
	function (api, stmtId) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodSignDocument(stmtId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					var _v1 = _v0.a;
					return $author$project$App$Service$Loadable$Got('signed');
				case 2:
					var _v2 = _v0.a;
					var error = _v2.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v3 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v4 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Service$ApplicationResources$getTokenFromDoctor = function (api) {
	var _v0 = A2(
		$author$project$App$Service$ApiWebSocket$getResource,
		$author$project$App$Service$Shared$pathFromRID(
			$author$project$App$Service$Shared$ridFromString('call.' + ($author$project$App$Service$ApplicationResources$ridGetMemedToken + '.doctorToken'))),
		api);
	if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 15)) && (_v0.a.c.$ === 26)) {
		var _v1 = _v0.a;
		var _v2 = _v1.c;
		var token = _v2.b;
		return $elm$core$Maybe$Just(token);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg) {
			case 0:
				var newFullStmt = model.P;
				var answersDictWithKey = $elm$core$Dict$fromList(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2(-1, st.ia.h8.g.aZ)
							]),
						A2(
							$elm$core$List$map,
							function (_v4) {
								var k = _v4.a;
								var d = _v4.b;
								return _Utils_Tuple2(k, d.aZ);
							},
							$elm$core$Dict$toList(st.ia.h8.fh))));
				var _v1 = A3(
					$elm$core$Dict$foldl,
					F3(
						function (k, doc, _v2) {
							var uDocs = _v2.a;
							var cmds = _v2.b;
							var _v3 = $author$project$App$Data$Document$requestUrl(doc);
							var uDoc = _v3.a;
							var cmd = _v3.b;
							return _Utils_Tuple2(
								A3($elm$core$Dict$insert, k, uDoc, uDocs),
								A2($elm$core$List$cons, cmd, cmds));
						}),
					_Utils_Tuple2(st.ia.h8.an, _List_Nil),
					st.ia.h8.an);
				var updatedDocs = _v1.a;
				var dCmd = _v1.b;
				var newModel = (model.p === 1) ? _Utils_update(
					model,
					{
						an: updatedDocs,
						P: _Utils_update(
							newFullStmt,
							{an: updatedDocs})
					}) : _Utils_update(
					model,
					{
						aZ: answersDictWithKey,
						an: updatedDocs,
						gH: st.ia.h8.gH,
						P: st.ia.h8,
						cA: A2(
							$author$project$App$Page$Doctor$PageFinalEvaluation$filterSections,
							st.ia.h8.g.aZ,
							$elm$core$Dict$fromList(
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, s) {
											return _Utils_Tuple2(i, s);
										}),
									st.ia.h8.gH))),
						p: 1,
						fX: A3(
							$elm$core$Dict$insert,
							$author$project$App$Data$Statement$depIndexToInt($author$project$App$Data$Statement$Bearer),
							st.ia.h8.g,
							st.ia.h8.fh)
					});
				return _Utils_Tuple2(
					newModel,
					$elm$core$Platform$Cmd$batch(
						A2(
							$elm$core$List$append,
							_List_fromArray(
								[
									A2(
									$elm$core$Task$perform,
									$elm$core$Basics$always($author$project$App$Page$Doctor$PageFinalEvaluation$LoadMemed),
									$elm$core$Task$succeed(0)),
									A2(
									$elm$core$Task$perform,
									$elm$core$Basics$always($author$project$App$Page$Doctor$PageFinalEvaluation$LoadNexodata),
									$elm$core$Task$succeed(0))
								]),
							A2(
								$elm$core$List$map,
								$elm$core$Platform$Cmd$map($author$project$App$Page$Doctor$PageFinalEvaluation$DocumentMsg),
								dCmd))));
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cA: A2(
								$author$project$App$Page$Doctor$PageFinalEvaluation$filterSections,
								st.ia.h8.g.aZ,
								$elm$core$Dict$fromList(
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (i, s) {
												return _Utils_Tuple2(i, s);
											}),
										st.ia.h8.gH))),
							p: 1,
							fX: A3(
								$elm$core$Dict$insert,
								$author$project$App$Data$Statement$depIndexToInt($author$project$App$Data$Statement$Bearer),
								st.ia.h8.g,
								st.ia.h8.fh)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var t = A2($author$project$App$Service$ApplicationResources$getSignDocumentError, st._, st.ia.h8.g.e.as);
				var cmd = _Utils_eq(
					t,
					$author$project$App$Service$Loadable$Got('signed')) ? $elm$browser$Browser$Navigation$reload : $elm$core$Platform$Cmd$none;
				var c = _Utils_eq(
					t,
					$author$project$App$Service$Loadable$Got('signed')) ? false : true;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h$: c, h3: false, e$: t}),
					cmd);
			case 4:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor(
								$author$project$App$Route$DoctorListPending(
									$author$project$App$Page$Doctor$PageFinalEvaluation$defaultFilter($author$project$App$Components$Filter$empty))))));
			case 5:
				return _Utils_Tuple2(model, $elm$browser$Browser$Navigation$reload);
			case 3:
				var cidCategories = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$author$project$App$Service$ApplicationResources$getCategoryFromStatement(st._));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fa: cidCategories}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var nMemed = A2(
					$elm$core$Maybe$withDefault,
					{du: $elm$core$Maybe$Nothing, e$: ''},
					$author$project$App$Service$ApplicationResources$getTokenFromDoctor(st._));
				var memedMsg = $author$project$App$Data$Memed$lazyLoadMemed(nMemed.e$);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: nMemed}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageFinalEvaluation$MemedMsg, memedMsg));
			default:
				var newNexodata = A2(
					$elm$core$Maybe$withDefault,
					{e$: ''},
					$author$project$App$Service$ApplicationResources$getNexodataToken(st._));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ho: newNexodata}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Page$Doctor$PageInterview$GoToPage = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$InformOnline = function (a) {
	return {$: 24, a: a};
};
var $author$project$App$Service$ApplicationResources$getDoctorInterview = function (api) {
	var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(api);
	if (_v0.$ === 1) {
		return $author$project$App$Service$Loadable$NotLoaded;
	} else {
		var token = _v0.a;
		var _v1 = A2(
			$author$project$App$Service$ApiWebSocket$getResource,
			$author$project$App$Service$ApplicationResources$pathDoctorInterview(token.Y),
			api);
		if (!_v1.$) {
			switch (_v1.a.$) {
				case 3:
					if ((_v1.a.a.$ === 27) && (_v1.a.c.$ === 16)) {
						var _v2 = _v1.a;
						var _v3 = _v2.a;
						var stmt = _v2.c.a;
						return $author$project$App$Service$Loadable$Got(stmt);
					} else {
						var _v4 = _v1.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v5 = _v1.a;
					var err = _v5.b;
					return $author$project$App$Service$Loadable$Failed(err);
				case 1:
					var _v6 = _v1.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v7 = _v1.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	}
};
var $author$project$App$Service$Loadable$map = F2(
	function (f, loadable) {
		switch (loadable.$) {
			case 3:
				var v = loadable.a;
				return $author$project$App$Service$Loadable$Got(
					f(v));
			case 0:
				return $author$project$App$Service$Loadable$NotLoaded;
			case 1:
				return $author$project$App$Service$Loadable$Loading;
			default:
				var err = loadable.a;
				return $author$project$App$Service$Loadable$Failed(err);
		}
	});
var $author$project$App$Data$StatementStatus$statusInInterview = function (status) {
	return (_Utils_cmp(
		$author$project$App$Data$StatementStatus$statusToInt(status),
		$author$project$App$Data$StatementStatus$statusToInt(12)) > -1) && (_Utils_cmp(
		$author$project$App$Data$StatementStatus$statusToInt(status),
		$author$project$App$Data$StatementStatus$statusToInt(21)) < 1);
};
var $author$project$App$Page$Doctor$Interview$PageLoading$LoadStatement = $elm$core$Basics$identity;
var $author$project$App$Page$Doctor$Interview$PageLoading$Loaded = 1;
var $author$project$App$Page$Doctor$Interview$PageLoading$getRouteByStatus = function (stmt) {
	var _v0 = stmt.aw;
	switch (_v0) {
		case 13:
			return $elm$core$Maybe$Just(1);
		case 14:
			return $elm$core$Maybe$Just(1);
		case 15:
			return $elm$core$Maybe$Just(1);
		case 16:
			return $elm$core$Maybe$Just(1);
		case 17:
			return $elm$core$Maybe$Just(1);
		case 18:
			return $elm$core$Maybe$Just(1);
		case 19:
			return $elm$core$Maybe$Just(1);
		case 20:
			return $elm$core$Maybe$Just(1);
		case 21:
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Page$Doctor$Interview$PageLoading$updateResponse = F3(
	function (respMsg, st, model) {
		var _v0 = _Utils_Tuple2(model.p, respMsg);
		if (!_v0.a) {
			if (!_v0.b) {
				var _v1 = _v0.a;
				var _v2 = _v0.b;
				var _v3 = $author$project$App$Service$ApplicationResources$getDoctorInterview(st._);
				if (_v3.$ === 3) {
					var stmt = _v3.a;
					return _Utils_Tuple2(
						model,
						$author$project$App$SharedState$asyncMsg(stmt.as));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			} else {
				var _v4 = _v0.a;
				var _v5 = _v0.b;
				var routeCmd = A2(
					$author$project$App$Route$replaceUrl,
					st.fC,
					$author$project$App$Route$Private(
						$author$project$App$Route$Doctor(
							A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Route$DoctorWaitingLine,
								A2(
									$elm$core$Maybe$map,
									$author$project$App$Route$DoctorInterview,
									$author$project$App$Page$Doctor$Interview$PageLoading$getRouteByStatus(st.ia.h8.g.e))))));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{p: 1}),
					routeCmd);
			}
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Page$Doctor$Interview$PagePresentation$updateResponse = F3(
	function (msg, shared, model) {
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$App$Service$Loadable$withDefault = F2(
	function (_default, loadable) {
		if (loadable.$ === 3) {
			var v = loadable.a;
			return v;
		} else {
			return _default;
		}
	});
var $author$project$App$Page$Doctor$PageInterview$updateResponse = F3(
	function (respMsg, st, model) {
		var _v0 = _Utils_Tuple2(model.aQ, respMsg);
		_v0$2:
		while (true) {
			_v0$3:
			while (true) {
				_v0$4:
				while (true) {
					switch (_v0.a.$) {
						case 0:
							switch (_v0.b.$) {
								case 3:
									var pageModel = _v0.a.a;
									var respPageMsg = _v0.b.a;
									var _v1 = A3($author$project$App$Page$Doctor$Interview$PageLoading$updateResponse, respPageMsg, st, pageModel);
									var nPageModel = _v1.a;
									var pageCmd = _v1.b;
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												aQ: $author$project$App$Page$Doctor$PageInterview$Loading(nPageModel)
											}),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$LoadingMsg, pageCmd));
								case 0:
									break _v0$2;
								case 1:
									break _v0$3;
								case 8:
									break _v0$4;
								default:
									return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							}
						case 1:
							switch (_v0.b.$) {
								case 4:
									var pageModel = _v0.a.a;
									var respPageMsg = _v0.b.a;
									var _v2 = A3($author$project$App$Page$Doctor$Interview$PagePresentation$updateResponse, respPageMsg, st, pageModel);
									var nPageModel = _v2.a;
									var pageCmd = _v2.b;
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												aQ: $author$project$App$Page$Doctor$PageInterview$Presentation(nPageModel)
											}),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$PresentationMsg, pageCmd));
								case 0:
									break _v0$2;
								case 1:
									break _v0$3;
								case 8:
									break _v0$4;
								default:
									return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							}
						case 2:
							switch (_v0.b.$) {
								case 0:
									break _v0$2;
								case 1:
									break _v0$3;
								case 8:
									break _v0$4;
								default:
									return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							}
						case 3:
							switch (_v0.b.$) {
								case 0:
									break _v0$2;
								case 1:
									break _v0$3;
								case 8:
									break _v0$4;
								default:
									return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							}
						case 5:
							switch (_v0.b.$) {
								case 0:
									break _v0$2;
								case 1:
									break _v0$3;
								case 8:
									break _v0$4;
								default:
									return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							}
						case 4:
							switch (_v0.b.$) {
								case 0:
									break _v0$2;
								case 1:
									break _v0$3;
								case 8:
									break _v0$4;
								default:
									return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							}
						case 6:
							switch (_v0.b.$) {
								case 0:
									break _v0$2;
								case 1:
									break _v0$3;
								case 8:
									break _v0$4;
								default:
									return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							}
						case 7:
							switch (_v0.b.$) {
								case 0:
									break _v0$2;
								case 1:
									break _v0$3;
								case 8:
									break _v0$4;
								default:
									return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							}
						case 8:
							switch (_v0.b.$) {
								case 0:
									break _v0$2;
								case 1:
									break _v0$3;
								case 8:
									break _v0$4;
								default:
									return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							}
						default:
							switch (_v0.b.$) {
								case 0:
									break _v0$2;
								case 1:
									break _v0$3;
								case 8:
									break _v0$4;
								default:
									return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							}
					}
				}
				var _v6 = _v0.b;
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$App$SharedState$asyncDelayedMsg,
						$author$project$App$Constants$informOnlineInterval,
						$author$project$App$Page$Doctor$PageInterview$InformOnline(model.df)));
			}
			var _v4 = _v0.b;
			var confCmd = $author$project$App$Data$StatementStatus$statusInInterview(st.ia.h8.g.e.aw) ? $author$project$App$SharedState$asyncMsg(
				$author$project$App$Page$Doctor$PageInterview$GoToPage(st.ia.h8.g.e.aw)) : A2(
				$author$project$App$Route$replaceUrl,
				st.fC,
				$author$project$App$Route$Private(
					$author$project$App$Route$Doctor($author$project$App$Route$DoctorWaitingLine)));
			var _v5 = A7(
				$author$project$App$Components$ViewFullStatement$init,
				$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
				st.ia.h8,
				st.ia.h8.jM,
				st.ia.h8.bs,
				st,
				$elm$core$Maybe$Nothing,
				$elm$core$Maybe$Nothing);
			var nView = _v5.a;
			var viewCmd = _v5.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						bU: $elm$core$Maybe$Just(nView)
					}),
				$elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$ViewFullStatementMsg, viewCmd),
							confCmd
						])));
		}
		var _v3 = _v0.b;
		return A2(
			$author$project$App$Service$Loadable$withDefault,
			_Utils_Tuple2(
				model,
				A2(
					$author$project$App$Route$replaceUrl,
					st.fC,
					$author$project$App$Route$Private(
						$author$project$App$Route$Doctor($author$project$App$Route$DoctorWaitingLine)))),
			A2(
				$author$project$App$Service$Loadable$map,
				function (stmt) {
					return $author$project$App$Data$StatementStatus$statusInInterview(stmt.aw) ? _Utils_Tuple2(model, $elm$core$Platform$Cmd$none) : _Utils_Tuple2(
						model,
						A2(
							$author$project$App$Route$replaceUrl,
							st.fC,
							$author$project$App$Route$Private(
								$author$project$App$Route$Doctor($author$project$App$Route$DoctorWaitingLine))));
				},
				$author$project$App$Service$ApplicationResources$getDoctorInterview(st._)));
	});
var $author$project$App$Page$Doctor$PageListFinished$LoadPeople = {$: 2};
var $author$project$App$Page$Doctor$PageListFinished$LoadStatements = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Doctor$PageListFinished$ViewInterview = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Service$ApplicationResources$getFinishedStatementsFromDoctor = F3(
	function (cId, filter, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				A2($author$project$App$Service$ApplicationResources$pathFinishedStatementsFromDoctorFiltered, cId, filter),
				$author$project$App$Service$SpecificResources$TypeStatement,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 16)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Page$Doctor$PageListFinished$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 1:
				var pId = respMsg.a;
				var filter = respMsg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3($author$project$App$Service$ApplicationResources$getFinishedStatementsFromDoctor, pId, filter, st._)
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Doctor$PageListFinished$LoadPeople));
			case 2:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			case 0:
				var filter = respMsg.a;
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Doctor$PageListFinished$LoadStatements(filter)));
			case 4:
				var stmtId = respMsg.a;
				var filter = respMsg.b;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Doctor$PageListFinished$LoadStatements(filter)),
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Doctor$PageListFinished$ViewInterview(stmtId))
							])));
			case 3:
				var doctor = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (doc) {
							return _Utils_eq(
								st.ia.h8.g.e.fl,
								$elm$core$Maybe$Just(doc.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var companyPerson = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (person) {
							return _Utils_eq(
								st.ia.h8.g.e.iX,
								$elm$core$Maybe$Just(person.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var _v1 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					_List_Nil,
					_List_Nil,
					st,
					doctor,
					companyPerson);
				var viewModel = _v1.a;
				var viewCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aU: $elm$core$Maybe$Just(st.ia.h8.g.e.as),
							av: $elm$core$Maybe$Just(viewModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageListFinished$ViewFullStmtMsg, viewCmd));
			default:
				var _v2 = model.av;
				if (!_v2.$) {
					var viewModel = _v2.a;
					var doctor = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (doc) {
								return _Utils_eq(
									st.ia.h8.g.e.bI,
									$elm$core$Maybe$Just(doc.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var companyPerson = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (person) {
								return _Utils_eq(
									st.ia.h8.g.e.iX,
									$elm$core$Maybe$Just(person.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var _v3 = A4(
						$author$project$App$Components$ViewFullStatement$update,
						A2($author$project$App$Components$ViewFullStatement$NewPeople, doctor, companyPerson),
						st.ia.h8,
						st,
						viewModel);
					var newViewModel = _v3.a;
					var viewCmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								av: $elm$core$Maybe$Just(newViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageListFinished$ViewFullStmtMsg, viewCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Page$Doctor$PageListPending$LoadPeople = {$: 2};
var $author$project$App$Page$Doctor$PageListPending$LoadStatements = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Service$ApplicationResources$getStatementsFromDoctor = F3(
	function (cId, filter, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				A2($author$project$App$Service$ApplicationResources$pathStatementsFromDoctorFiltered, cId, filter),
				$author$project$App$Service$SpecificResources$TypeStatement,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 16)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Page$Doctor$PageListPending$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 1:
				var pId = respMsg.a;
				var filter = respMsg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3($author$project$App$Service$ApplicationResources$getStatementsFromDoctor, pId, filter, st._)
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Doctor$PageListPending$LoadPeople));
			case 2:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var filter = respMsg.a;
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Doctor$PageListPending$LoadStatements(filter)));
		}
	});
var $author$project$App$Page$Doctor$PageListReevaluation$LoadPeople = {$: 2};
var $author$project$App$Page$Doctor$PageListReevaluation$LoadStatements = {$: 1};
var $author$project$App$Service$ApplicationResources$getReevalStatementsFromDoctor = F3(
	function (cId, filter, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				A2($author$project$App$Service$ApplicationResources$pathReevalStatementsFromDoctorFiltered, cId, filter),
				$author$project$App$Service$SpecificResources$TypeStatement,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 16)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Page$Doctor$PageListReevaluation$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 1:
				var pId = respMsg.a;
				var filter = respMsg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3($author$project$App$Service$ApplicationResources$getReevalStatementsFromDoctor, pId, filter, st._)
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Doctor$PageListReevaluation$LoadPeople));
			case 2:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Doctor$PageListReevaluation$LoadStatements));
		}
	});
var $author$project$App$Page$Doctor$PageLoading$getRouteByStatus = function (stmt) {
	var _v0 = stmt.aw;
	switch (_v0) {
		case 12:
			return $author$project$App$Route$DoctorReviewStatement;
		case 13:
			return $author$project$App$Route$DoctorInterview(0);
		case 14:
			return $author$project$App$Route$DoctorInterview(0);
		case 15:
			return $author$project$App$Route$DoctorInterview(0);
		case 16:
			return $author$project$App$Route$DoctorInterview(0);
		case 17:
			return $author$project$App$Route$DoctorInterview(0);
		case 18:
			return $author$project$App$Route$DoctorInterview(0);
		case 19:
			return $author$project$App$Route$DoctorInterview(0);
		case 20:
			return $author$project$App$Route$DoctorInterview(0);
		case 21:
			return $author$project$App$Route$DoctorInterview(0);
		default:
			return $author$project$App$Route$DoctorWaitingLine;
	}
};
var $author$project$App$Page$Doctor$PageLoading$updateResponse = F3(
	function (respMsg, st, model) {
		var route = function () {
			var _v1 = $author$project$App$Service$ApplicationResources$getDoctorInterview(st._);
			if (_v1.$ === 3) {
				var stmtModel = _v1.a;
				return $author$project$App$Page$Doctor$PageLoading$getRouteByStatus(stmtModel);
			} else {
				return model.f5;
			}
		}();
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{ep: true}),
			A2(
				$author$project$App$Route$pushUrl,
				st.fC,
				$author$project$App$Route$Private(
					$author$project$App$Route$Doctor(route))));
	});
var $author$project$App$Page$Doctor$PageReevaluation$DocumentMsg = function (a) {
	return {$: 21, a: a};
};
var $author$project$App$Page$Doctor$PageReevaluation$Loaded = 1;
var $author$project$App$Page$Doctor$PageReevaluation$filterSections = F2(
	function (answers, sections) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, section) {
					return !$elm$core$List$isEmpty(
						A2(
							$elm$core$List$filter,
							function (question) {
								return !$elm$core$List$isEmpty(
									A2(
										$elm$core$List$filter,
										function (template) {
											return A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$Answer$emptyModel,
												A2($elm$core$Dict$get, template.as, answers)).d3;
										},
										question.bY));
							},
							section.eI));
				}),
			sections);
	});
var $author$project$App$Page$Doctor$PageReevaluation$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg) {
			case 0:
				var newFullStmt = model.P;
				var answersDictWithKey = $elm$core$Dict$fromList(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2(-1, st.ia.h8.g.aZ)
							]),
						A2(
							$elm$core$List$map,
							function (_v4) {
								var k = _v4.a;
								var d = _v4.b;
								return _Utils_Tuple2(k, d.aZ);
							},
							$elm$core$Dict$toList(st.ia.h8.fh))));
				var _v1 = A3(
					$elm$core$Dict$foldl,
					F3(
						function (k, doc, _v2) {
							var uDocs = _v2.a;
							var cmds = _v2.b;
							var _v3 = $author$project$App$Data$Document$requestUrl(doc);
							var uDoc = _v3.a;
							var cmd = _v3.b;
							return _Utils_Tuple2(
								A3($elm$core$Dict$insert, k, uDoc, uDocs),
								A2($elm$core$List$cons, cmd, cmds));
						}),
					_Utils_Tuple2(st.ia.h8.an, _List_Nil),
					st.ia.h8.an);
				var updatedDocs = _v1.a;
				var dCmd = _v1.b;
				var newModel = (model.p === 1) ? _Utils_update(
					model,
					{
						an: updatedDocs,
						P: _Utils_update(
							newFullStmt,
							{an: updatedDocs})
					}) : _Utils_update(
					model,
					{
						aZ: answersDictWithKey,
						an: updatedDocs,
						gH: st.ia.h8.gH,
						P: st.ia.h8,
						cA: A2(
							$author$project$App$Page$Doctor$PageReevaluation$filterSections,
							st.ia.h8.g.aZ,
							$elm$core$Dict$fromList(
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, s) {
											return _Utils_Tuple2(i, s);
										}),
									st.ia.h8.gH))),
						p: 1,
						fX: A3(
							$elm$core$Dict$insert,
							$author$project$App$Data$Statement$depIndexToInt($author$project$App$Data$Statement$Bearer),
							st.ia.h8.g,
							st.ia.h8.fh)
					});
				return _Utils_Tuple2(
					newModel,
					$elm$core$Platform$Cmd$batch(
						A2(
							$elm$core$List$map,
							$elm$core$Platform$Cmd$map($author$project$App$Page$Doctor$PageReevaluation$DocumentMsg),
							dCmd)));
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cA: A2(
								$author$project$App$Page$Doctor$PageReevaluation$filterSections,
								st.ia.h8.g.aZ,
								$elm$core$Dict$fromList(
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (i, s) {
												return _Utils_Tuple2(i, s);
											}),
										st.ia.h8.gH))),
							p: 1,
							fX: A3(
								$elm$core$Dict$insert,
								$author$project$App$Data$Statement$depIndexToInt($author$project$App$Data$Statement$Bearer),
								st.ia.h8.g,
								st.ia.h8.fh)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var t = A2($author$project$App$Service$ApplicationResources$getSignDocumentError, st._, st.ia.h8.g.e.as);
				var cmd = _Utils_eq(
					t,
					$author$project$App$Service$Loadable$Got('signed')) ? $elm$browser$Browser$Navigation$reload : $elm$core$Platform$Cmd$none;
				var c = _Utils_eq(
					t,
					$author$project$App$Service$Loadable$Got('signed')) ? false : true;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h$: c, h3: false, e$: t}),
					cmd);
			case 5:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor($author$project$App$Route$DoctorListReevaluation))));
			case 3:
				var cidCategories = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$author$project$App$Service$ApplicationResources$getCategoryFromStatement(st._));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fa: cidCategories}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var oldChatModel = model.aM;
				var newMessages = $elm$core$List$reverse(
					A2($author$project$App$Service$ApplicationResources$getStatementMessagesFromStatement, model.eV, st._));
				var _v5 = A2(
					$author$project$App$Components$Chatbox$update,
					$author$project$App$Components$Chatbox$UpdateMessages,
					_Utils_update(
						oldChatModel,
						{co: newMessages}));
				var newChatBoxModel = _v5.a;
				var chatBoxCmd = _v5.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aM: newChatBoxModel}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageReevaluation$ChatBoxMsg, chatBoxCmd)
							])));
			default:
				return _Utils_Tuple2(model, $elm$browser$Browser$Navigation$reload);
		}
	});
var $author$project$App$Page$Doctor$PageReview$LoadFullStatement = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Doctor$PageReview$Loaded = 1;
var $author$project$App$Service$ApplicationResources$getDoctorWaitingLine = F2(
	function (dId, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				$author$project$App$Service$ApplicationResources$pathDoctorWaitingLine(dId),
				$author$project$App$Service$SpecificResources$TypeWaitingPerson,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 14)) {
							var person = resource.a.a;
							return A2($elm$core$List$cons, person, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Page$Doctor$PageReview$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg) {
			case 0:
				var cmd = function () {
					var _v1 = $author$project$App$Service$ApplicationResources$getDoctorInterview(st._);
					if (_v1.$ === 3) {
						var stmtModel = _v1.a;
						return (stmtModel.aw === 12) ? $author$project$App$SharedState$asyncMsg(
							$author$project$App$Page$Doctor$PageReview$LoadFullStatement(stmtModel.as)) : ($author$project$App$Data$StatementStatus$statusInInterview(stmtModel.aw) ? A2(
							$author$project$App$Route$replaceUrl,
							st.fC,
							$author$project$App$Route$Private(
								$author$project$App$Route$Doctor(
									$author$project$App$Route$DoctorInterview(0)))) : A2(
							$author$project$App$Route$replaceUrl,
							st.fC,
							$author$project$App$Route$Private(
								$author$project$App$Route$Doctor($author$project$App$Route$DoctorWaitingLine))));
					} else {
						return A2(
							$author$project$App$Route$replaceUrl,
							st.fC,
							$author$project$App$Route$Private(
								$author$project$App$Route$Doctor($author$project$App$Route$DoctorWaitingLine)));
					}
				}();
				return _Utils_Tuple2(model, cmd);
			case 1:
				var _v2 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					st.ia.h8.jM,
					st.ia.h8.bs,
					st,
					$elm$core$Maybe$Nothing,
					$elm$core$Maybe$Nothing);
				var viewModel = _v2.a;
				var viewCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{p: 1, bm: viewModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageReview$ViewFullStatementMsg, viewCmd));
			case 3:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor($author$project$App$Route$DoctorWaitingLine))));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							e1: A2($author$project$App$Service$ApplicationResources$getDoctorWaitingLine, model.e$.Y, st._)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				var doctor = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (doc) {
							return _Utils_eq(
								st.ia.h8.g.e.bI,
								$elm$core$Maybe$Just(doc.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var companyPerson = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (person) {
							return _Utils_eq(
								st.ia.h8.g.e.iX,
								$elm$core$Maybe$Just(person.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var _v3 = A4(
					$author$project$App$Components$ViewFullStatement$update,
					A2($author$project$App$Components$ViewFullStatement$NewPeople, doctor, companyPerson),
					st.ia.h8,
					st,
					model.bm);
				var newViewModel = _v3.a;
				var viewCmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bm: newViewModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageReview$ViewFullStatementMsg, viewCmd));
		}
	});
var $author$project$App$Page$Doctor$PageWaitingLine$InformOnline = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Doctor$PageWaitingLine$Load = {$: 2};
var $author$project$App$Page$Doctor$PageWaitingLine$LoadCompanyOrNext = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Doctor$PageWaitingLine$LoadInterviewStatement = {$: 7};
var $author$project$App$Page$Doctor$PageWaitingLine$LoadStatementOrNext = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Doctor$PageWaitingLine$LoadTemplateOrNext = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Service$ApplicationResources$getDoctorsOnlineForDoctor = F2(
	function (doctorId, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				$author$project$App$Service$ApplicationResources$pathDoctorsOnlineForDoctor(doctorId),
				$author$project$App$Service$SpecificResources$TypeDoctor,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 9)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$App$Page$Doctor$PageWaitingLine$sortWaitingLine = function (wt) {
	var _v0 = wt.dw.aw;
	if (_v0 === 11) {
		return $elm$time$Time$posixToMillis(wt.dw.bW);
	} else {
		return 4102444800000 - $author$project$App$Data$StatementStatus$statusToInt(wt.dw.aw);
	}
};
var $author$project$App$Page$Doctor$PageWaitingLine$updateResponse = F3(
	function (respMsg, st, model) {
		var _v0 = _Utils_Tuple2(
			respMsg,
			$author$project$App$Service$ApplicationResources$getAuthToken(st._));
		switch (_v0.a.$) {
			case 2:
				if (!_v0.b.$) {
					var _v2 = _v0.a;
					var token = _v0.b.a;
					var newDoctorList = A2($author$project$App$Service$ApplicationResources$getDoctorsOnlineForDoctor, token.Y, st._);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c5: newDoctorList}),
						$elm$core$Platform$Cmd$none);
				} else {
					var _v3 = _v0.a;
					var _v4 = _v0.b;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!_v0.b.$) {
					var _v1 = _v0.a;
					var token = _v0.b.a;
					return _Utils_Tuple2(
						model,
						$author$project$App$SharedState$asyncMsg(
							$author$project$App$Page$Doctor$PageWaitingLine$LoadTemplateOrNext(
								A2($author$project$App$Service$ApplicationResources$getDoctorWaitingLine, token.Y, st._))));
				} else {
					var _v5 = _v0.a;
					var _v6 = _v0.b;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				if (!_v0.b.$) {
					var _v7 = _v0.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$App$SharedState$asyncDelayedMsg,
							$author$project$App$Constants$informOnlineInterval,
							$author$project$App$Page$Doctor$PageWaitingLine$InformOnline(model.df)));
				} else {
					var _v8 = _v0.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 0:
				if (!_v0.b.$) {
					var _v9 = _v0.a;
					var token = _v0.b.a;
					var _v10 = $author$project$App$Components$Sound$enable(model.N);
					var nSound = _v10.a;
					var nSoundCmd = _v10.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{fl: token.Y, N: nSound}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageWaitingLine$SoundMsg, nSoundCmd),
									$author$project$App$SharedState$asyncMsg($author$project$App$Page$Doctor$PageWaitingLine$Load)
								])));
				} else {
					var _v11 = _v0.a;
					var _v12 = _v0.b;
					var _v13 = $author$project$App$Components$Sound$enable(model.N);
					var nSound = _v13.a;
					var nSoundCmd = _v13.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{N: nSound}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageWaitingLine$SoundMsg, nSoundCmd),
									$author$project$App$SharedState$asyncMsg($author$project$App$Page$Doctor$PageWaitingLine$Load)
								])));
				}
			case 4:
				var wt = _v0.a.a;
				var template = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$StatementTemplate$emptyModel,
					A2($author$project$App$Service$ApplicationResources$getStatementTemplateById, wt.dw.eY, st._));
				var wtWithTemplate = _Utils_update(
					wt,
					{cJ: template});
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Doctor$PageWaitingLine$LoadCompanyOrNext(wtWithTemplate)));
			case 5:
				var wt = _v0.a.a;
				var company = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$Company$emptyModel,
					A2($author$project$App$Service$ApplicationResources$getCompanyById, wt.cJ.gx, st._));
				var wtWithCompany = _Utils_update(
					wt,
					{b4: company});
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Doctor$PageWaitingLine$LoadStatementOrNext(wtWithCompany)));
			case 6:
				if (!_v0.b.$) {
					var waitingPerson = _v0.a.a;
					var token = _v0.b.a;
					var statement = function () {
						var _v14 = A2($author$project$App$Service$ApplicationResources$getStatementFromPerson, waitingPerson.dw.as, st._);
						if (_v14.$ === 3) {
							var stmt = _v14.a;
							return stmt;
						} else {
							return $author$project$App$Data$Statement$emptyModel;
						}
					}();
					var currentWaitingLine = A2($author$project$App$Service$ApplicationResources$getDoctorWaitingLine, token.Y, st._);
					var wtp = A2(
						$elm$core$Maybe$withDefault,
						waitingPerson.dw,
						$elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (w) {
									return _Utils_eq(w.as, waitingPerson.dw.as);
								},
								currentWaitingLine)));
					var wt = _Utils_update(
						waitingPerson,
						{dw: wtp});
					var wtWithStatement = _List_fromArray(
						[
							_Utils_update(
							wt,
							{h8: statement})
						]);
					var orderedList = A2(
						$elm$core$List$sortBy,
						$author$project$App$Page$Doctor$PageWaitingLine$sortWaitingLine,
						_Utils_ap(
							wtWithStatement,
							A2(
								$elm$core$List$filter,
								function (lWt) {
									return !_Utils_eq(lWt.dw.as, wt.dw.as);
								},
								model.e1)));
					return _Utils_Tuple2(
						model,
						$author$project$App$SharedState$asyncMsg(
							$author$project$App$Page$Doctor$PageWaitingLine$InformLoaded(orderedList)));
				} else {
					var _v15 = _v0.b;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var _v16 = _v0.a;
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Doctor$PageWaitingLine$LoadInterviewStatement));
			default:
				var _v17 = _v0.a;
				var _v18 = $author$project$App$Service$ApplicationResources$getDoctorInterview(st._);
				if (_v18.$ === 3) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$App$Route$pushUrl,
							st.fC,
							$author$project$App$Route$Private(
								$author$project$App$Route$Doctor($author$project$App$Route$DoctorReviewStatement))));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$Loaded = 1;
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$filterSections = F2(
	function (answers, sections) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, section) {
					return !$elm$core$List$isEmpty(
						A2(
							$elm$core$List$filter,
							function (question) {
								return !$elm$core$List$isEmpty(
									A2(
										$elm$core$List$filter,
										function (template) {
											return A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$Answer$emptyModel,
												A2($elm$core$Dict$get, template.as, answers)).d3;
										},
										question.bY));
							},
							section.eI));
				}),
			sections);
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$updateResponse = F3(
	function (respMsg, st, model) {
		if (!respMsg) {
			var newFullStmt = model.P;
			var answersDictWithKey = $elm$core$Dict$fromList(
				_Utils_ap(
					_List_fromArray(
						[
							_Utils_Tuple2(-1, st.ia.h8.g.aZ)
						]),
					A2(
						$elm$core$List$map,
						function (_v4) {
							var k = _v4.a;
							var d = _v4.b;
							return _Utils_Tuple2(k, d.aZ);
						},
						$elm$core$Dict$toList(st.ia.h8.fh))));
			var _v1 = A3(
				$elm$core$Dict$foldl,
				F3(
					function (k, doc, _v2) {
						var uDocs = _v2.a;
						var cmds = _v2.b;
						var _v3 = $author$project$App$Data$Document$requestUrl(doc);
						var uDoc = _v3.a;
						var cmd = _v3.b;
						return _Utils_Tuple2(
							A3($elm$core$Dict$insert, k, uDoc, uDocs),
							A2($elm$core$List$cons, cmd, cmds));
					}),
				_Utils_Tuple2(st.ia.h8.an, _List_Nil),
				st.ia.h8.an);
			var updatedDocs = _v1.a;
			var dCmd = _v1.b;
			var newModel = (model.p === 1) ? _Utils_update(
				model,
				{
					an: updatedDocs,
					P: _Utils_update(
						newFullStmt,
						{an: updatedDocs})
				}) : _Utils_update(
				model,
				{
					aZ: answersDictWithKey,
					an: updatedDocs,
					gH: st.ia.h8.gH,
					P: st.ia.h8,
					cA: A2(
						$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$filterSections,
						st.ia.h8.g.aZ,
						$elm$core$Dict$fromList(
							A2(
								$elm$core$List$indexedMap,
								F2(
									function (i, s) {
										return _Utils_Tuple2(i, s);
									}),
								st.ia.h8.gH))),
					p: 1,
					fX: A3(
						$elm$core$Dict$insert,
						$author$project$App$Data$Statement$depIndexToInt($author$project$App$Data$Statement$Bearer),
						st.ia.h8.g,
						st.ia.h8.fh)
				});
			return _Utils_Tuple2(
				newModel,
				$elm$core$Platform$Cmd$batch(
					A2(
						$elm$core$List$map,
						$elm$core$Platform$Cmd$map($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$DocumentMsg),
						dCmd)));
		} else {
			var cidCategories = A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$author$project$App$Service$ApplicationResources$getCategoryFromStatement(st._));
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{fa: cidCategories}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Page$PageDoctor$updateResponse = F3(
	function (responseMsg, sharedState, model) {
		var _v0 = _Utils_Tuple2(responseMsg, model.aQ);
		switch (_v0.a.$) {
			case 0:
				if (!_v0.b.$) {
					var loadingMsg = _v0.a.a;
					var wlModel = _v0.b.a;
					var _v1 = A3($author$project$App$Page$Doctor$PageLoading$updateResponse, loadingMsg, sharedState, wlModel);
					var newWlModel = _v1.a;
					var wlCmd = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$Loading(newWlModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$LoadingMsg, wlCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				if (_v0.b.$ === 1) {
					var docMsg = _v0.a.a;
					var wlModel = _v0.b.a;
					var _v2 = A3($author$project$App$Page$Doctor$PageWaitingLine$updateResponse, docMsg, sharedState, wlModel);
					var newWlModel = _v2.a;
					var wlCmd = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$WaitingLine(newWlModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$WaitingLineMsg, wlCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				if (_v0.b.$ === 2) {
					var reviewMsg = _v0.a.a;
					var reviewModel = _v0.b.a;
					var _v3 = A3($author$project$App$Page$Doctor$PageReview$updateResponse, reviewMsg, sharedState, reviewModel);
					var newReviewModel = _v3.a;
					var newReviewCmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$Review(newReviewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ReviewMsg, newReviewCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				if (_v0.b.$ === 3) {
					var interviewMsg = _v0.a.a;
					var interviewModel = _v0.b.a;
					var _v4 = A3($author$project$App$Page$Doctor$PageInterview$updateResponse, interviewMsg, sharedState, interviewModel);
					var newInterviewModel = _v4.a;
					var newInterviewCmd = _v4.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$Interview(newInterviewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$InterviewMsg, newInterviewCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				if (_v0.b.$ === 4) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v5 = A3($author$project$App$Page$Doctor$PageFinalEvaluation$updateResponse, pageMsg, sharedState, pageModel);
					var newPageModel = _v5.a;
					var newPageMsg = _v5.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$FinalEvaluation(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$FinalEvaluationMsg, newPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				if (_v0.b.$ === 8) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v6 = A3($author$project$App$Page$Doctor$PageListReevaluation$updateResponse, pageMsg, sharedState, pageModel);
					var newPageModel = _v6.a;
					var newPageMsg = _v6.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$ListReevaluation(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ListReevaluationMsg, newPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				if (_v0.b.$ === 9) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v7 = A3($author$project$App$Page$Doctor$PageReevaluation$updateResponse, pageMsg, sharedState, pageModel);
					var newPageModel = _v7.a;
					var newPageMsg = _v7.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$Reevaluation(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ReevaluationMsg, newPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				if (_v0.b.$ === 5) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v8 = A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$updateResponse, pageMsg, sharedState, pageModel);
					var newPageModel = _v8.a;
					var newPageMsg = _v8.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$ViewEvaluation(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ViewEvaluationMsg, newPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				if (_v0.b.$ === 6) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v9 = A3($author$project$App$Page$Doctor$PageListPending$updateResponse, pageMsg, sharedState, pageModel);
					var newPageModel = _v9.a;
					var newPageMsg = _v9.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$ListPending(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ListPendingMsg, newPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 9:
				if (_v0.b.$ === 7) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v10 = A3($author$project$App$Page$Doctor$PageListFinished$updateResponse, pageMsg, sharedState, pageModel);
					var newPageModel = _v10.a;
					var newPageMsg = _v10.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$ListFinished(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ListFinishedMsg, newPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				if (_v0.b.$ === 10) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v11 = A3($author$project$App$Page$Doctor$PageDoctorSales$updateResponse, pageMsg, sharedState, pageModel);
					var newPageModel = _v11.a;
					var newPageMsg = _v11.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$DoctorSales(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$DoctorSalesMsg, newPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Page$PageDoctorSignUp$Error = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Data$Doctor$FieldPhoto = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Service$ApplicationResources$getCreateDoctorUserResponse = function (api) {
	var _v0 = A2($author$project$App$Service$ApiWebSocket$getMethodResponse, $author$project$App$Service$ApplicationResources$methodCreateDoctorUser, api);
	_v0$2:
	while (true) {
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 2:
					var _v1 = _v0.a;
					var error = _v1.b;
					return $elm$core$Maybe$Just(
						$elm$core$Result$Err(error));
				case 3:
					if (_v0.a.c.$ === 22) {
						var _v2 = _v0.a;
						var user = _v2.c.a;
						return $elm$core$Maybe$Just(
							$elm$core$Result$Ok(user));
					} else {
						break _v0$2;
					}
				default:
					break _v0$2;
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $author$project$App$Service$ApplicationResources$getMemedCities = function (api) {
	var _v0 = A2(
		$author$project$App$Service$ApiWebSocket$getResource,
		$author$project$App$Service$Shared$pathFromRID(
			$author$project$App$Service$Shared$ridFromString('call.' + ($author$project$App$Service$ApplicationResources$ridGetMemedToken + '.filterCities'))),
		api);
	if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 36)) && (_v0.a.c.$ === 27)) {
		var _v1 = _v0.a;
		var _v2 = _v1.c;
		var cities = _v2.b;
		return $elm$core$Maybe$Just(cities);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Service$ApplicationResources$getMemedSpecialties = function (api) {
	var _v0 = A2(
		$author$project$App$Service$ApiWebSocket$getResource,
		$author$project$App$Service$Shared$pathFromRID(
			$author$project$App$Service$Shared$ridFromString('call.' + ($author$project$App$Service$ApplicationResources$ridGetMemedToken + '.filterSpecialties'))),
		api);
	if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 37)) && (_v0.a.c.$ === 28)) {
		var _v1 = _v0.a;
		var _v2 = _v1.c;
		var specialties = _v2.b;
		return $elm$core$Maybe$Just(specialties);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Page$PageDoctorSignUp$updateResponse = F3(
	function (responseMsg, sharedState, model) {
		switch (responseMsg.$) {
			case 0:
				var _v1 = $author$project$App$Service$ApplicationResources$getCreateDoctorUserResponse(sharedState._);
				if (!_v1.$) {
					if (!_v1.a.$) {
						return _Utils_Tuple2(
							model,
							A2(
								$author$project$App$Route$pushUrl,
								sharedState.fC,
								$author$project$App$Route$Public($author$project$App$Route$UserSignUpComplete)));
					} else {
						if (_v1.a.a.a.$ === 6) {
							var _v2 = _v1.a.a;
							var error = _v2.a.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aw: $author$project$App$Page$PageDoctorSignUp$Error(error)
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aw: $author$project$App$Page$PageDoctorSignUp$Error('Dados inválidos')
									}),
								$elm$core$Platform$Cmd$none);
						}
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var selfie = responseMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bu: A2(
								$author$project$App$Data$Doctor$updateModel,
								$author$project$App$Data$Doctor$FieldPhoto(selfie),
								model.bu)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var mCities = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$author$project$App$Service$ApplicationResources$getMemedCities(sharedState._));
				var rearrangedCities = A2(
					$elm$core$List$map,
					function (v) {
						return _Utils_Tuple2(
							$elm$core$String$fromInt(v.as),
							v.iL.hl);
					},
					mCities);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{es: rearrangedCities}),
					$elm$core$Platform$Cmd$none);
			default:
				var mSpecialties = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$author$project$App$Service$ApplicationResources$getMemedSpecialties(sharedState._));
				var rearrangedSpecialties = A2(
					$elm$core$List$map,
					function (v) {
						return _Utils_Tuple2(
							$elm$core$String$fromInt(v.as),
							v.iL.hl);
					},
					mSpecialties);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eu: rearrangedSpecialties}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Service$ApiWebSocket$decodeError = F2(
	function (err, value) {
		return A2(
			$author$project$App$Service$ApiWebSocket$Error,
			$author$project$App$Service$SpecificResources$DecodeError(
				$elm$json$Json$Decode$errorToString(err)),
			value);
	});
var $author$project$App$Service$ApplicationResources$getSignInError = function (api) {
	var _v0 = A2($author$project$App$Service$ApiWebSocket$getMethodResponse, $author$project$App$Service$ApplicationResources$methodUserSignIn, api);
	if (!_v0.$) {
		switch (_v0.a.$) {
			case 3:
				var _v1 = _v0.a;
				var _v2 = $author$project$App$Service$ApiWebSocket$getAuthToken(api);
				if (!_v2.$) {
					var tokenValue = _v2.a;
					var _v3 = A2($elm$json$Json$Decode$decodeValue, $author$project$App$Data$Token$decoder, tokenValue);
					if (!_v3.$) {
						var token = _v3.a;
						return $author$project$App$Service$Loadable$Got(token);
					} else {
						var error = _v3.a;
						return $author$project$App$Service$Loadable$Failed(
							A2($author$project$App$Service$ApiWebSocket$decodeError, error, tokenValue));
					}
				} else {
					return $author$project$App$Service$Loadable$NotLoaded;
				}
			case 2:
				var _v4 = _v0.a;
				var error = _v4.b;
				return $author$project$App$Service$Loadable$Failed(error);
			case 0:
				var _v5 = _v0.a;
				return $author$project$App$Service$Loadable$Loading;
			default:
				var _v6 = _v0.a;
				return $author$project$App$Service$Loadable$Loading;
		}
	} else {
		return $author$project$App$Service$Loadable$NotLoaded;
	}
};
var $author$project$App$Page$PageLogin$updateResponse = F3(
	function (msg, st, model) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					e$: $author$project$App$Service$ApplicationResources$getSignInError(st._)
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$App$Page$PageProponent$LoginError = function (a) {
	return {$: 14, a: a};
};
var $author$project$App$Page$PageProponent$UpdatePageWithStatus = {$: 16};
var $author$project$App$Service$ApplicationResources$getSignNotificationError = function (api) {
	var _v0 = A2($author$project$App$Service$ApiWebSocket$getMethodResponse, $author$project$App$Constants$appSignInterviewMethod, api);
	if ((!_v0.$) && (_v0.a.$ === 2)) {
		var _v1 = _v0.a;
		var error = _v1.b;
		return $elm$core$Maybe$Just(error);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Page$Proponent$PageConfirmInterview$SaveSelfie = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$App$Page$Proponent$PageConfirmInterview$Uploaded = 2;
var $author$project$App$Data$Holiday$emptyModel = {
	bw: $elm$time$Time$millisToPosix(0),
	as: $author$project$App$Data$Id$uuidNil,
	jJ: $author$project$App$Data$Id$uuidNil,
	fL: '',
	bN: $elm$time$Time$millisToPosix(0),
	f$: ''
};
var $author$project$App$Service$ApplicationResources$getHolidayTextForTemplate = F2(
	function (templateId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getResource,
			$author$project$App$Service$ApplicationResources$pathHolidayTextForTemplate(templateId),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 21)) && (_v0.a.c.$ === 12)) {
			var _v1 = _v0.a;
			var _v2 = _v1.a;
			var model = _v1.c.a;
			return $elm$core$Maybe$Just(model);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Page$Proponent$PageConfirmInterview$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 1:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var depIdx = respMsg.a;
				var content = respMsg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bA: 2}),
					$author$project$App$SharedState$asyncMsg(
						A2($author$project$App$Page$Proponent$PageConfirmInterview$SaveSelfie, depIdx, content)));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bA: 2}),
					$elm$core$Platform$Cmd$none);
			default:
				var templateId = respMsg.a;
				var holiday = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$Holiday$emptyModel,
					A2($author$project$App$Service$ApplicationResources$getHolidayTextForTemplate, templateId, st._));
				var newHolidayText = (holiday.f$ === '') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(holiday.f$);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{de: newHolidayText}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Page$Proponent$PageDoctorReview$updateResponse = F3(
	function (respMsg, st, model) {
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$App$Page$Proponent$PageInterview$StartConference = $elm$core$Basics$identity;
var $author$project$App$Service$ApplicationResources$getConferenceFromStatement = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getResource,
			$author$project$App$Service$ApplicationResources$pathConferenceFromStatement(stmtId),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 8)) && (_v0.a.c.$ === 7)) {
			var _v1 = _v0.a;
			var _v2 = _v1.a;
			var conf = _v1.c.a;
			return $elm$core$Maybe$Just(conf);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Page$Proponent$PageInterview$updateResponse = F3(
	function (respMsg, st, model) {
		var stmtId = respMsg;
		return _Utils_Tuple2(
			model,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$Maybe$map,
					A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $author$project$App$SharedState$asyncMsg),
					A2($author$project$App$Service$ApplicationResources$getConferenceFromStatement, stmtId, st._))));
	});
var $author$project$App$Service$ApplicationResources$getLoadTermsByStatementId = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodTermsDocumentFromStatement(stmtId),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 18)) && (_v0.a.c.$ === 10)) {
			var _v1 = _v0.a;
			var _v2 = _v1.a;
			var doc = _v1.c.a;
			return $elm$core$Maybe$Just(doc);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Page$Proponent$PageIntro$updateResponse = F3(
	function (respMsg, st, model) {
		var stmtId = respMsg;
		var newDoc = A2($author$project$App$Service$ApplicationResources$getLoadTermsByStatementId, stmtId, st._);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{dQ: newDoc}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$App$Page$Proponent$PageLoading$LoadStatement = $elm$core$Basics$identity;
var $author$project$App$Service$ApplicationResources$getStatementTemplateByStatementId = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getResource,
			$author$project$App$Service$ApplicationResources$pathStatementTemplateFromStatement(stmtId),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 32)) && (_v0.a.c.$ === 19)) {
			var _v1 = _v0.a;
			var _v2 = _v1.a;
			var model = _v1.c.a;
			return $elm$core$Maybe$Just(model);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$MedsynCss$maybeLoad = function (mStr) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Platform$Cmd$none,
		A2($elm$core$Maybe$map, $author$project$App$MedsynCss$loadCss, mStr));
};
var $author$project$App$Page$Proponent$PageLoading$updateResponse = F3(
	function (respMsg, st, model) {
		if (!respMsg.$) {
			var stmtId = respMsg.a;
			var _v1 = A2($author$project$App$Service$ApplicationResources$getStatementTemplateByStatementId, stmtId, st._);
			if (!_v1.$) {
				var template = _v1.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fg: true}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$MedsynCss$maybeLoad(template.gz),
								$author$project$App$SharedState$asyncMsg(stmtId)
							])));
			} else {
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Service$ApplicationResources$getCreateNewPayment = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodCreateNewPayment(stmtId),
			api);
		_v0$2:
		while (true) {
			if (!_v0.$) {
				switch (_v0.a.$) {
					case 2:
						var _v1 = _v0.a;
						var error = _v1.b;
						return $elm$core$Result$Err(error);
					case 3:
						var _v2 = _v0.a;
						return $elm$core$Result$Ok($elm$core$Maybe$Nothing);
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return $elm$core$Result$Ok($elm$core$Maybe$Nothing);
	});
var $author$project$App$Page$Proponent$PagePayment$updateResponse = F3(
	function (respMsg, st, model) {
		var stmtId = respMsg;
		var uModel = _Utils_update(
			model,
			{dG: true});
		var errorMsg = function () {
			var _v1 = A2($author$project$App$Service$ApplicationResources$getCreateNewPayment, stmtId, st._);
			if (_v1.$ === 1) {
				var _v2 = _v1.a;
				var specificError = _v2.a;
				switch (specificError.$) {
					case 2:
						return $elm$core$Maybe$Just('Dados inválidos');
					case 9:
						var msg = specificError.a;
						return $elm$core$Maybe$Just(msg);
					case 10:
						var msg = specificError.a;
						return $elm$core$Maybe$Just(msg);
					case 11:
						var msg = specificError.a;
						return $elm$core$Maybe$Just(msg);
					case 12:
						var msg = specificError.a;
						return $elm$core$Maybe$Just(msg);
					default:
						return $elm$core$Maybe$Just('Erro Desconhecido');
				}
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		return _Utils_Tuple2(
			_Utils_update(
				uModel,
				{c8: errorMsg}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$App$Page$Proponent$PageQuestions$updateResponse = F3(
	function (respMsg, st, model) {
		var sectionIndex = st.ia.h8.g.e.bG;
		var uModel = (_Utils_cmp(model.en, sectionIndex) < 1) ? model : model;
		return _Utils_Tuple2(
			_Utils_update(
				uModel,
				{en: sectionIndex}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$App$Service$ApplicationResources$getDoctorsOnlineForTemplate = F2(
	function (templateId, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				$author$project$App$Service$ApplicationResources$pathDoctorsOnlineForTemplate(templateId),
				$author$project$App$Service$SpecificResources$TypeDoctor,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 9)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Page$Proponent$PageWaitingInLine$updateResponse = F3(
	function (respMsg, st, model) {
		if (!respMsg.$) {
			var templateId = respMsg.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						gJ: A2($author$project$App$Service$ApplicationResources$getDoctorsOnlineForTemplate, templateId, st._)
					}),
				$elm$core$Platform$Cmd$none);
		} else {
			var templateId = respMsg.a;
			var holiday = A2(
				$elm$core$Maybe$withDefault,
				$author$project$App$Data$Holiday$emptyModel,
				A2($author$project$App$Service$ApplicationResources$getHolidayTextForTemplate, templateId, st._));
			var newHolidayText = (holiday.f$ === '') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(holiday.f$);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{de: newHolidayText}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Page$PageProponent$updateResponse = F3(
	function (respMsg, st, model) {
		var _v0 = _Utils_Tuple2(respMsg, model.aQ);
		switch (_v0.a.$) {
			case 0:
				var _v1 = _v0.a;
				var _v2 = $author$project$App$Service$ApplicationResources$getSignNotificationError(st._);
				if (!_v2.$) {
					var err = _v2.a;
					return _Utils_Tuple2(
						model,
						$author$project$App$SharedState$asyncMsg(
							$author$project$App$Page$PageProponent$LoginError(err)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var _v3 = _v0.a;
				return _Utils_Tuple2(
					model,
					A2($author$project$App$SharedState$asyncDelayedMsg, $author$project$App$Constants$informOnlineInterval, $author$project$App$Page$PageProponent$InformOnline));
			case 2:
				var _v4 = _v0.a;
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$PageProponent$UpdatePageWithStatus));
			case 3:
				var _v5 = _v0.a;
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$PageProponent$UpdatePageWithStatus));
			case 4:
				var _v6 = _v0.a;
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$PageProponent$UpdatePageWithStatus));
			case 5:
				if (!_v0.b.$) {
					var pLoadingMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v7 = A3($author$project$App$Page$Proponent$PageLoading$updateResponse, pLoadingMsg, st, pageModel);
					var newPageModel = _v7.a;
					var pageCmd = _v7.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Loading(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$LoadingMsg, pageCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				if (_v0.b.$ === 6) {
					var proponentQuestionMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v8 = A3($author$project$App$Page$Proponent$PageQuestions$updateResponse, proponentQuestionMsg, st, pageModel);
					var newPageModel = _v8.a;
					var pageCmd = _v8.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Questions(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$QuestionsMsg, pageCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				if (_v0.b.$ === 4) {
					var propPaymentMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v9 = A3($author$project$App$Page$Proponent$PagePayment$updateResponse, propPaymentMsg, st, pageModel);
					var newPageModel = _v9.a;
					var pageCmd = _v9.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Payment(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$PaymentMsg, pageCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				if (_v0.b.$ === 8) {
					var proponentConfirmMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v10 = A3($author$project$App$Page$Proponent$PageConfirmInterview$updateResponse, proponentConfirmMsg, st, pageModel);
					var newPageModel = _v10.a;
					var pageCmd = _v10.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Confirm(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$ConfirmMsg, pageCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 9:
				var _v15 = _v0.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 10:
				if (_v0.b.$ === 10) {
					var proponentWaitingLineMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v11 = A3($author$project$App$Page$Proponent$PageWaitingInLine$updateResponse, proponentWaitingLineMsg, st, pageModel);
					var newPageModel = _v11.a;
					var pageCmd = _v11.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$WaitingInLine(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$WaitingInLineMsg, pageCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 11:
				if (_v0.b.$ === 11) {
					var proponentDocReviewMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v12 = A3($author$project$App$Page$Proponent$PageDoctorReview$updateResponse, proponentDocReviewMsg, st, pageModel);
					var newPageModel = _v12.a;
					var pageCmd = _v12.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$DoctorReview(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$DoctorReviewMsg, pageCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 12:
				if (_v0.b.$ === 12) {
					var proponentInterviewMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v13 = A3($author$project$App$Page$Proponent$PageInterview$updateResponse, proponentInterviewMsg, st, pageModel);
					var newPageModel = _v13.a;
					var pageCmd = _v13.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Interview(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$InterviewMsg, pageCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				if (_v0.b.$ === 3) {
					var proponentIntroMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v14 = A3($author$project$App$Page$Proponent$PageIntro$updateResponse, proponentIntroMsg, st, pageModel);
					var newPageModel = _v14.a;
					var pageCmd = _v14.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageProponent$Intro(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageProponent$IntroMsg, pageCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$Load = {$: 0};
var $author$project$App$Service$ApplicationResources$getCreateDocumentFromStatement = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodCreateDocumentFromStatement(stmtId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					var _v1 = _v0.a;
					return $author$project$App$Service$Loadable$Got(true);
				case 2:
					var _v2 = _v0.a;
					var error = _v2.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v3 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v4 = _v0.a;
					return $author$project$App$Service$Loadable$Got(true);
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Service$ApplicationResources$getDeleteDocumentFromId = F2(
	function (documentId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodDeleteDocumentFromId(documentId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					var _v1 = _v0.a;
					return $author$project$App$Service$Loadable$Got(true);
				case 2:
					var _v2 = _v0.a;
					var error = _v2.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v3 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v4 = _v0.a;
					return $author$project$App$Service$Loadable$Got(true);
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Service$ApplicationResources$getPostponeStatement = F2(
	function (stmtId, api) {
		var _v0 = A2($author$project$App$Service$ApiWebSocket$getMethodResponse, $author$project$App$Service$ApplicationResources$methodPostponeStatement, api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 29) && (_v0.a.c.$ === 17)) {
						var _v1 = _v0.a;
						var _v2 = _v1.c;
						return $author$project$App$Service$Loadable$Got(true);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Service$ApplicationResources$getPresignedUrl = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodGetPresignedUrl(stmtId),
			api);
		_v0$4:
		while (true) {
			if (!_v0.$) {
				switch (_v0.a.$) {
					case 3:
						if ((_v0.a.a.$ === 3) && (_v0.a.c.$ === 3)) {
							var _v1 = _v0.a;
							var _v2 = _v1.c;
							var url = _v2.b;
							return $author$project$App$Service$Loadable$Got(url);
						} else {
							break _v0$4;
						}
					case 2:
						var _v3 = _v0.a;
						var error = _v3.b;
						return $author$project$App$Service$Loadable$Failed(error);
					case 0:
						var _v4 = _v0.a;
						return $author$project$App$Service$Loadable$Loading;
					default:
						var _v5 = _v0.a;
						return $author$project$App$Service$Loadable$Loading;
				}
			} else {
				break _v0$4;
			}
		}
		return $author$project$App$Service$Loadable$NotLoaded;
	});
var $author$project$App$Service$ApplicationResources$getReturnToDoctorEvaluation = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodReturnToDoctorEvaluation(stmtId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 29) && (_v0.a.c.$ === 17)) {
						var _v1 = _v0.a;
						var _v2 = _v1.c;
						return $author$project$App$Service$Loadable$Got(true);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Service$ApplicationResources$getReturnToInterview = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodReturnToInterview(stmtId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 29) && (_v0.a.c.$ === 17)) {
						var _v1 = _v0.a;
						var _v2 = _v1.c;
						return $author$project$App$Service$Loadable$Got(true);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Service$ApplicationResources$getReturnToWaitingForRoom = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodReturnToWaitingForRoom(stmtId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 29) && (_v0.a.c.$ === 17)) {
						var _v1 = _v0.a;
						var _v2 = _v1.c;
						return $author$project$App$Service$Loadable$Got(true);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Service$ApplicationResources$getUpdateDocumentFromId = F2(
	function (documentId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodUpdateDocumentFromId(documentId),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					var _v1 = _v0.a;
					return $author$project$App$Service$Loadable$Got(true);
				case 2:
					var _v2 = _v0.a;
					var error = _v2.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v3 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v4 = _v0.a;
					return $author$project$App$Service$Loadable$Got(true);
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$DocumentMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$TabDocument = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$init = F4(
	function (now, fullStmt, sharedState, doctor) {
		var _v0 = A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, doc, _v1) {
					var uDocs = _v1.a;
					var cmds = _v1.b;
					var _v2 = $author$project$App$Data$Document$requestUrl(doc);
					var uDoc = _v2.a;
					var cmd = _v2.b;
					return _Utils_Tuple2(
						A3($elm$core$Dict$insert, k, uDoc, uDocs),
						A2($elm$core$List$cons, cmd, cmds));
				}),
			_Utils_Tuple2(fullStmt.an, _List_Nil),
			fullStmt.an);
		var updatedDocs = _v0.a;
		var dCmd = _v0.b;
		var updatedStmt = _Utils_update(
			fullStmt,
			{an: updatedDocs});
		return _Utils_Tuple2(
			{
				b5: $author$project$App$Page$Reviewer$EditStatement$DocumentView$TabDocument(0),
				bu: doctor,
				a5: updatedStmt,
				ey: now
			},
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$Cmd$map($author$project$App$Page$Reviewer$EditStatement$DocumentView$DocumentMsg),
					dCmd)));
	});
var $author$project$App$Data$Document$uploadToS3 = _Platform_outgoingPort(
	'uploadToS3',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'content',
					$elm$json$Json$Encode$string($.iU)),
					_Utils_Tuple2(
					'docType',
					$elm$json$Json$Encode$int($.b7)),
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string($.as)),
					_Utils_Tuple2(
					'mime',
					$elm$json$Json$Encode$string($.dr)),
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string($.hl)),
					_Utils_Tuple2(
					'objectUrl',
					$elm$json$Json$Encode$string($.af)),
					_Utils_Tuple2(
					'objectUrlRequested',
					$elm$json$Json$Encode$bool($.Q)),
					_Utils_Tuple2(
					'ownerId',
					$elm$json$Json$Encode$string($.jJ)),
					_Utils_Tuple2(
					'ownerKind',
					$elm$json$Json$Encode$string($.X)),
					_Utils_Tuple2(
					'personId',
					$elm$json$Json$Encode$string($.Y))
				]));
	});
var $author$project$App$Data$Document$uploadDocumentToS3 = function (model) {
	return $author$project$App$Data$Document$uploadToS3(
		$author$project$App$Data$Document$portFromModel(model));
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateResponse = F3(
	function (respMsg, st, model) {
		var modelForm = $author$project$App$Page$Reviewer$EditStatement$StatementEdit$formFromModel(model);
		var fullStmt = model.h8;
		var companyObj = fullStmt.b4;
		var bearer = fullStmt.g;
		switch (respMsg.$) {
			case 0:
				var cId = respMsg.a;
				var loadedFullStmt = st.ia.h8;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							h8: _Utils_update(
								fullStmt,
								{
									b4: _Utils_update(
										companyObj,
										{as: cId})
								})
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Reviewer$EditStatement$StatementEdit$Load));
			case 2:
				var stmtId = respMsg.a;
				var loadedFullStmt = st.ia.h8;
				var _v1 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					_List_Nil,
					_List_Nil,
					st,
					$elm$core$Maybe$Nothing,
					$elm$core$Maybe$Nothing);
				var viewModel = _v1.a;
				var viewCmd = _v1.b;
				var _v2 = A4(
					$author$project$App$Page$Reviewer$EditStatement$DocumentView$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					st,
					$elm$core$Maybe$Nothing);
				var documentViewModel = _v2.a;
				var documentViewCmd = _v2.b;
				var _v3 = A3(
					$elm$core$Dict$foldl,
					F3(
						function (k, doc, _v4) {
							var uDocs = _v4.a;
							var cmds = _v4.b;
							var _v5 = $author$project$App$Data$Document$requestUrl(doc);
							var uDoc = _v5.a;
							var cmd = _v5.b;
							return _Utils_Tuple2(
								A3($elm$core$Dict$insert, k, uDoc, uDocs),
								A2($elm$core$List$cons, cmd, cmds));
						}),
					_Utils_Tuple2(loadedFullStmt.an, _List_Nil),
					loadedFullStmt.an);
				var updatedDocs = _v3.a;
				var dCmd = _v3.b;
				var updatedStmt = function () {
					if ($author$project$App$Data$Id$uuidIsValid(model.h8.g.e.as)) {
						var oldFullStatement = model.h8;
						return _Utils_update(
							oldFullStatement,
							{an: updatedDocs});
					} else {
						return _Utils_update(
							loadedFullStmt,
							{an: updatedDocs});
					}
				}();
				return _Utils_Tuple2(
					A2(
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{
								a5: _Utils_update(
									loadedFullStmt,
									{an: updatedDocs})
							}),
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$Showing, stmtId, viewModel),
								ac: A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentShowing, stmtId, documentViewModel),
								ep: true,
								cq: st.ia.h8.g.e.gL,
								h8: updatedStmt
							})),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$EditStatement$StatementEdit$ViewFullStmtMsg, viewCmd),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentViewMsg, documentViewCmd)
							])));
			case 1:
				var cId = respMsg.a;
				return _Utils_Tuple2(
					A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateForm, modelForm, model),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{y: false}),
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Review(
								$author$project$App$Route$ReviewListForReviewRoute(
									$author$project$App$Route$ReviewListForReview($author$project$App$Components$Filter$empty))))));
			case 9:
				var stmtId = respMsg.a;
				var isSuccess = _Utils_eq(
					A2($author$project$App$Service$ApplicationResources$getPostponeStatement, stmtId, st._),
					$author$project$App$Service$Loadable$Got(true));
				var newNotification = isSuccess ? {v: false, jl: 'Atendimento atualizado.'} : {v: true, jl: 'Erro.'};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: false,
							F: $elm$core$Maybe$Just(newNotification)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var stmtId = respMsg.a;
				var isSuccess = _Utils_eq(
					A2($author$project$App$Service$ApplicationResources$getReturnToInterview, stmtId, st._),
					$author$project$App$Service$Loadable$Got(true));
				var newNotification = isSuccess ? {v: false, jl: 'Atendimento atualizado.'} : {v: true, jl: 'Erro.'};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: false,
							F: $elm$core$Maybe$Just(newNotification)
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var stmtId = respMsg.a;
				var isSuccess = _Utils_eq(
					A2($author$project$App$Service$ApplicationResources$getReturnToDoctorEvaluation, stmtId, st._),
					$author$project$App$Service$Loadable$Got(true));
				var newNotification = isSuccess ? {v: false, jl: 'Atendimento atualizado.'} : {v: true, jl: 'Erro.'};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: false,
							F: $elm$core$Maybe$Just(newNotification)
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var stmtId = respMsg.a;
				var isSuccess = _Utils_eq(
					A2($author$project$App$Service$ApplicationResources$getReturnToWaitingForRoom, stmtId, st._),
					$author$project$App$Service$Loadable$Got(true));
				var newNotification = isSuccess ? {v: false, jl: 'Atendimento atualizado.'} : {v: true, jl: 'Erro.'};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: false,
							F: $elm$core$Maybe$Just(newNotification)
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a6: !model.a6}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var documentId = respMsg.a;
				var isSuccess = _Utils_eq(
					A2($author$project$App$Service$ApplicationResources$getDeleteDocumentFromId, documentId, st._),
					$author$project$App$Service$Loadable$Got(true));
				var newNotification = isSuccess ? {v: false, jl: 'Atendimento atualizado.'} : {v: true, jl: 'Erro.'};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: false,
							F: $elm$core$Maybe$Just(newNotification)
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var documentId = respMsg.a;
				var isSuccess = _Utils_eq(
					A2($author$project$App$Service$ApplicationResources$getUpdateDocumentFromId, documentId, st._),
					$author$project$App$Service$Loadable$Got(true));
				var newNotification = isSuccess ? {v: false, jl: 'Atendimento atualizado.'} : {v: true, jl: 'Erro.'};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: false,
							F: $elm$core$Maybe$Just(newNotification)
						}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var statementId = respMsg.a;
				var isSuccess = _Utils_eq(
					A2($author$project$App$Service$ApplicationResources$getCreateDocumentFromStatement, statementId, st._),
					$author$project$App$Service$Loadable$Got(true));
				var newNotification = isSuccess ? {v: false, jl: 'Atendimento atualizado.'} : {v: true, jl: 'Erro.'};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: false,
							F: $elm$core$Maybe$Just(newNotification)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var statementId = respMsg.a;
				var doc = respMsg.b;
				var url = function () {
					var _v6 = A2($author$project$App$Service$ApplicationResources$getPresignedUrl, statementId, st._);
					if (_v6.$ === 3) {
						var str = _v6.a;
						return str;
					} else {
						return '';
					}
				}();
				var newNotification = (url === '') ? $elm$core$Maybe$Just(
					{v: true, jl: 'Erro.'}) : model.F;
				var cmds = (url !== '') ? $author$project$App$Data$Document$uploadDocumentToS3(
					_Utils_update(
						doc,
						{af: url})) : $elm$core$Platform$Cmd$none;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{y: false, F: newNotification}),
					cmds);
		}
	});
var $author$project$App$Page$Reviewer$PageListApproved$LoadDoctors = {$: 4};
var $author$project$App$Page$Reviewer$PageListApproved$LoadPeople = {$: 3};
var $author$project$App$Page$Reviewer$PageListApproved$LoadStatements = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Reviewer$PageListApproved$ViewDetails = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Service$ApplicationResources$getApprovedStatementsForReview = F3(
	function (cId, filter, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				A2($author$project$App$Service$ApplicationResources$pathApprovedForReview, cId, filter),
				$author$project$App$Service$SpecificResources$TypeStatement,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 16)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Service$ApplicationResources$getDoctorsOnline = function (api) {
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A4(
			$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
			$author$project$App$Service$ApplicationResources$pathDoctorsOnline,
			$author$project$App$Service$SpecificResources$TypeDoctor,
			F2(
				function (resource, list) {
					if ((!resource.$) && (resource.a.$ === 9)) {
						var model = resource.a.a;
						return A2($elm$core$List$cons, model, list);
					} else {
						return list;
					}
				}),
			api));
};
var $author$project$App$Page$Reviewer$PageListApproved$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Reviewer$PageListApproved$LoadStatements(model.b)));
			case 1:
				var stmtId = respMsg.a;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Reviewer$PageListApproved$LoadStatements(model.b)),
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Reviewer$PageListApproved$ViewDetails(stmtId)),
								$author$project$App$SharedState$asyncMsg($author$project$App$Page$Reviewer$PageListApproved$LoadDoctors)
							])));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3(
								$author$project$App$Service$ApplicationResources$getApprovedStatementsForReview,
								model.gx,
								$author$project$App$Page$Reviewer$PageListApproved$defaultFilter(model.b),
								st._)
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Reviewer$PageListApproved$LoadPeople));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b9: $author$project$App$Service$ApplicationResources$getDoctorsOnline(st._)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var stmtId = respMsg.a;
				var doctor = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (doc) {
							return _Utils_eq(
								st.ia.h8.g.e.fl,
								$elm$core$Maybe$Just(doc.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var companyPerson = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (person) {
							return _Utils_eq(
								st.ia.h8.g.e.iX,
								$elm$core$Maybe$Just(person.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var _v1 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					_List_Nil,
					_List_Nil,
					st,
					doctor,
					companyPerson);
				var viewModel = _v1.a;
				var viewCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gD: A2($author$project$App$Page$Reviewer$PageListApproved$Showing, stmtId, viewModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageListApproved$ViewFullStmtMsg, viewCmd));
			default:
				var _v2 = model.gD;
				if (_v2.$ === 1) {
					var stmtId = _v2.a;
					var viewModel = _v2.b;
					var doctor = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (doc) {
								return _Utils_eq(
									st.ia.h8.g.e.bI,
									$elm$core$Maybe$Just(doc.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var companyPerson = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (person) {
								return _Utils_eq(
									st.ia.h8.g.e.iX,
									$elm$core$Maybe$Just(person.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var _v3 = A4(
						$author$project$App$Components$ViewFullStatement$update,
						A2($author$project$App$Components$ViewFullStatement$NewPeople, doctor, companyPerson),
						st.ia.h8,
						st,
						viewModel);
					var newViewModel = _v3.a;
					var viewCmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Reviewer$PageListApproved$Showing, stmtId, newViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageListApproved$ViewFullStmtMsg, viewCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Page$Reviewer$PageListForReview$LoadDoctors = {$: 3};
var $author$project$App$Page$Reviewer$PageListForReview$LoadPeople = {$: 2};
var $author$project$App$Page$Reviewer$PageListForReview$LoadStatements = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Reviewer$PageListForReview$Review = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Service$ApplicationResources$getStatementsForReview = F3(
	function (cId, filter, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				A2($author$project$App$Service$ApplicationResources$pathStatementsForReview, cId, filter),
				$author$project$App$Service$SpecificResources$TypeStatement,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 16)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Page$Reviewer$PageListForReview$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 2:
				var filter = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3($author$project$App$Service$ApplicationResources$getStatementsForReview, model.gx, filter, st._)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$SharedState$asyncMsg($author$project$App$Page$Reviewer$PageListForReview$LoadPeople),
								$author$project$App$SharedState$asyncMsg($author$project$App$Page$Reviewer$PageListForReview$LoadDoctors)
							])));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b9: $author$project$App$Service$ApplicationResources$getDoctorsOnline(st._)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			case 0:
				var filter = respMsg.a;
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Reviewer$PageListForReview$LoadStatements(filter)));
			case 1:
				var stmtId = respMsg.a;
				var filter = respMsg.b;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Reviewer$PageListForReview$LoadStatements(filter)),
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Reviewer$PageListForReview$Review(stmtId))
							])));
			default:
				var _v1 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					_List_Nil,
					_List_Nil,
					st,
					$elm$core$Maybe$Nothing,
					$elm$core$Maybe$Nothing);
				var viewModel = _v1.a;
				var viewCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aU: $elm$core$Maybe$Just(st.ia.h8.g.e.as),
							av: $elm$core$Maybe$Just(viewModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageListForReview$ViewFullStmtMsg, viewCmd));
		}
	});
var $author$project$App$Page$Reviewer$PageListIncomplete$LoadDoctors = {$: 4};
var $author$project$App$Page$Reviewer$PageListIncomplete$LoadPeople = {$: 3};
var $author$project$App$Page$Reviewer$PageListIncomplete$LoadStatements = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Reviewer$PageListIncomplete$ViewDetails = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Service$ApplicationResources$getIncompleteStatementsForReview = F3(
	function (cId, filter, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				A2($author$project$App$Service$ApplicationResources$pathIncompleteForReview, cId, filter),
				$author$project$App$Service$SpecificResources$TypeStatement,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 16)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Page$Reviewer$PageListIncomplete$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Reviewer$PageListIncomplete$LoadStatements(model.b)));
			case 1:
				var stmtId = respMsg.a;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Reviewer$PageListIncomplete$LoadStatements(model.b)),
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Reviewer$PageListIncomplete$ViewDetails(stmtId)),
								$author$project$App$SharedState$asyncMsg($author$project$App$Page$Reviewer$PageListIncomplete$LoadDoctors)
							])));
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b9: $author$project$App$Service$ApplicationResources$getDoctorsOnline(st._)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3(
								$author$project$App$Service$ApplicationResources$getIncompleteStatementsForReview,
								model.gx,
								$author$project$App$Page$Reviewer$PageListIncomplete$defaultFilter(model.b),
								st._)
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Reviewer$PageListIncomplete$LoadPeople));
			case 4:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var stmtId = respMsg.a;
				var doctor = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (doc) {
							return _Utils_eq(
								st.ia.h8.g.e.fl,
								$elm$core$Maybe$Just(doc.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var companyPerson = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (person) {
							return _Utils_eq(
								st.ia.h8.g.e.iX,
								$elm$core$Maybe$Just(person.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var _v1 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					_List_Nil,
					_List_Nil,
					st,
					doctor,
					companyPerson);
				var viewModel = _v1.a;
				var viewCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gD: A2($author$project$App$Page$Reviewer$PageListIncomplete$Showing, stmtId, viewModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageListIncomplete$ViewFullStmtMsg, viewCmd));
			case 6:
				return _Utils_Tuple2(model, $elm$browser$Browser$Navigation$reload);
			default:
				var _v2 = model.gD;
				if (_v2.$ === 1) {
					var stmtId = _v2.a;
					var viewModel = _v2.b;
					var doctor = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (doc) {
								return _Utils_eq(
									st.ia.h8.g.e.bI,
									$elm$core$Maybe$Just(doc.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var companyPerson = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (person) {
								return _Utils_eq(
									st.ia.h8.g.e.iX,
									$elm$core$Maybe$Just(person.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var _v3 = A4(
						$author$project$App$Components$ViewFullStatement$update,
						A2($author$project$App$Components$ViewFullStatement$NewPeople, doctor, companyPerson),
						st.ia.h8,
						st,
						viewModel);
					var newViewModel = _v3.a;
					var viewCmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Reviewer$PageListIncomplete$Showing, stmtId, newViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageListIncomplete$ViewFullStmtMsg, viewCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Page$Reviewer$PageListReevaluation$LoadDoctors = {$: 3};
var $author$project$App$Page$Reviewer$PageListReevaluation$LoadPeople = {$: 2};
var $author$project$App$Page$Reviewer$PageListReevaluation$LoadStatements = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Reviewer$PageListReevaluation$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 1:
				var cId = respMsg.a;
				var filter = respMsg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3($author$project$App$Service$ApplicationResources$getReevalStatementsFromCompany, cId, filter, st._)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$SharedState$asyncMsg($author$project$App$Page$Reviewer$PageListReevaluation$LoadPeople),
								$author$project$App$SharedState$asyncMsg($author$project$App$Page$Reviewer$PageListReevaluation$LoadDoctors)
							])));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b9: $author$project$App$Service$ApplicationResources$getDoctorsOnline(st._)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Reviewer$PageListReevaluation$LoadStatements($author$project$App$Components$Filter$empty)));
		}
	});
var $author$project$App$Page$Reviewer$PageReevaluation$DocumentMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$Reviewer$PageReevaluation$Loaded = 1;
var $author$project$App$Page$Reviewer$PageReevaluation$filterSections = F2(
	function (answers, sections) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, section) {
					return !$elm$core$List$isEmpty(
						A2(
							$elm$core$List$filter,
							function (question) {
								return !$elm$core$List$isEmpty(
									A2(
										$elm$core$List$filter,
										function (template) {
											return A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$Answer$emptyModel,
												A2($elm$core$Dict$get, template.as, answers)).d3;
										},
										question.bY));
							},
							section.eI));
				}),
			sections);
	});
var $author$project$App$Page$Reviewer$PageReevaluation$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg) {
			case 0:
				var newFullStmt = model.P;
				var answersDictWithKey = $elm$core$Dict$fromList(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2(-1, st.ia.h8.g.aZ)
							]),
						A2(
							$elm$core$List$map,
							function (_v4) {
								var k = _v4.a;
								var d = _v4.b;
								return _Utils_Tuple2(k, d.aZ);
							},
							$elm$core$Dict$toList(st.ia.h8.fh))));
				var _v1 = A3(
					$elm$core$Dict$foldl,
					F3(
						function (k, doc, _v2) {
							var uDocs = _v2.a;
							var cmds = _v2.b;
							var _v3 = $author$project$App$Data$Document$requestUrl(doc);
							var uDoc = _v3.a;
							var cmd = _v3.b;
							return _Utils_Tuple2(
								A3($elm$core$Dict$insert, k, uDoc, uDocs),
								A2($elm$core$List$cons, cmd, cmds));
						}),
					_Utils_Tuple2(st.ia.h8.an, _List_Nil),
					st.ia.h8.an);
				var updatedDocs = _v1.a;
				var dCmd = _v1.b;
				var newModel = (model.p === 1) ? _Utils_update(
					model,
					{
						an: updatedDocs,
						P: _Utils_update(
							newFullStmt,
							{an: updatedDocs})
					}) : _Utils_update(
					model,
					{
						aZ: answersDictWithKey,
						an: updatedDocs,
						gH: st.ia.h8.gH,
						P: st.ia.h8,
						cA: A2(
							$author$project$App$Page$Reviewer$PageReevaluation$filterSections,
							st.ia.h8.g.aZ,
							$elm$core$Dict$fromList(
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, s) {
											return _Utils_Tuple2(i, s);
										}),
									st.ia.h8.gH))),
						p: 1,
						fX: A3(
							$elm$core$Dict$insert,
							$author$project$App$Data$Statement$depIndexToInt($author$project$App$Data$Statement$Bearer),
							st.ia.h8.g,
							st.ia.h8.fh)
					});
				return _Utils_Tuple2(
					newModel,
					$elm$core$Platform$Cmd$batch(
						A2(
							$elm$core$List$map,
							$elm$core$Platform$Cmd$map($author$project$App$Page$Reviewer$PageReevaluation$DocumentMsg),
							dCmd)));
			case 2:
				var cidCategories = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$author$project$App$Service$ApplicationResources$getCategoryFromStatement(st._));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fa: cidCategories}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin($author$project$App$Route$AdminListReevaluation))));
			case 3:
				var oldChatModel = model.aM;
				var newMessages = $elm$core$List$reverse(
					A2($author$project$App$Service$ApplicationResources$getStatementMessagesFromStatement, model.eV, st._));
				var _v5 = A2(
					$author$project$App$Components$Chatbox$update,
					$author$project$App$Components$Chatbox$UpdateMessages,
					_Utils_update(
						oldChatModel,
						{co: newMessages}));
				var newChatBoxModel = _v5.a;
				var chatBoxCmd = _v5.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aM: newChatBoxModel}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageReevaluation$ChatBoxMsg, chatBoxCmd)
							])));
			default:
				return _Utils_Tuple2(model, $elm$browser$Browser$Navigation$reload);
		}
	});
var $author$project$App$Page$Reviewer$PageReview$Load = {$: 0};
var $author$project$App$Page$Reviewer$PageReview$LoadStatementFromPerson = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Reviewer$PageReview$updateResponse = F3(
	function (respMsg, st, model) {
		var fullStmt = model.h8;
		var companyObj = fullStmt.b4;
		var bearer = fullStmt.g;
		switch (respMsg.$) {
			case 0:
				var cId = respMsg.a;
				var loadedFullStmt = st.ia.h8;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							h8: _Utils_update(
								fullStmt,
								{
									b4: _Utils_update(
										companyObj,
										{as: cId})
								})
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Reviewer$PageReview$Load));
			case 4:
				if (!respMsg.b.$) {
					var document = respMsg.a;
					var _v1 = respMsg.b;
					var loadedFullStmt = st.ia.h8;
					var _v2 = function () {
						var _v3 = A2($author$project$App$Service$ApplicationResources$getPersonByDocument, document, st._);
						if (_v3.$ === 3) {
							var p = _v3.a;
							return _Utils_Tuple2(
								p,
								$author$project$App$SharedState$asyncMsg(
									$author$project$App$Page$Reviewer$PageReview$LoadStatementFromPerson(p.as)));
						} else {
							return _Utils_Tuple2(bearer.dw, $elm$core$Platform$Cmd$none);
						}
					}();
					var person = _v2.a;
					var cmd = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h8: _Utils_update(
									fullStmt,
									{
										g: _Utils_update(
											bearer,
											{dw: person})
									})
							}),
						cmd);
				} else {
					var document = respMsg.a;
					var idx = respMsg.b.a;
					var loadedFullStmt = st.ia.h8;
					var dep = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$FullStatement$emptyEditStatement,
						A2($elm$core$Dict$get, idx, fullStmt.fh));
					var person = function () {
						var _v4 = A2($author$project$App$Service$ApplicationResources$getPersonByDocument, document, st._);
						if (_v4.$ === 3) {
							var p = _v4.a;
							return p;
						} else {
							return dep.dw;
						}
					}();
					var nDep = _Utils_update(
						dep,
						{dw: person});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h8: _Utils_update(
									fullStmt,
									{
										fh: A3($elm$core$Dict$insert, idx, nDep, fullStmt.fh)
									})
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var personId = respMsg.a;
				var _v5 = A2($author$project$App$Service$ApplicationResources$getStatementFromPerson, personId, st._);
				if (_v5.$ === 3) {
					var stmt = _v5.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h8: _Utils_update(
									fullStmt,
									{
										g: _Utils_update(
											bearer,
											{e: stmt})
									})
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$App$Route$pushUrl,
									st.fC,
									$author$project$App$Route$Private(
										$author$project$App$Route$Review(
											$author$project$App$Route$ReviewInterviewRoute(stmt.as)))),
									$author$project$App$SharedState$asyncMsg($author$project$App$Page$Reviewer$PageReview$Load)
								])));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var stmtId = respMsg.a;
				var loadedFullStmt = st.ia.h8;
				var _v6 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					_List_Nil,
					_List_Nil,
					st,
					$elm$core$Maybe$Nothing,
					$elm$core$Maybe$Nothing);
				var viewModel = _v6.a;
				var viewCmd = _v6.b;
				var _v7 = A3(
					$elm$core$Dict$foldl,
					F3(
						function (k, doc, _v8) {
							var uDocs = _v8.a;
							var cmds = _v8.b;
							var _v9 = $author$project$App$Data$Document$requestUrl(doc);
							var uDoc = _v9.a;
							var cmd = _v9.b;
							return _Utils_Tuple2(
								A3($elm$core$Dict$insert, k, uDoc, uDocs),
								A2($elm$core$List$cons, cmd, cmds));
						}),
					_Utils_Tuple2(loadedFullStmt.an, _List_Nil),
					loadedFullStmt.an);
				var updatedDocs = _v7.a;
				var dCmd = _v7.b;
				var updatedStmt = function () {
					if ($author$project$App$Data$Id$uuidIsValid(model.h8.g.e.as)) {
						var oldFullStatement = model.h8;
						return _Utils_update(
							oldFullStatement,
							{an: updatedDocs});
					} else {
						return _Utils_update(
							loadedFullStmt,
							{an: updatedDocs});
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gD: A2($author$project$App$Page$Reviewer$PageReview$Showing, stmtId, viewModel),
							ep: true,
							h8: updatedStmt
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageReview$ViewFullStmtMsg, viewCmd)
							])));
			case 1:
				var cId = respMsg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Review(
								$author$project$App$Route$ReviewListForReviewRoute(
									$author$project$App$Route$ReviewListForReview($author$project$App$Components$Filter$empty))))));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Page$Reviewer$PageWaitingLine$InformOnline = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Reviewer$PageWaitingLine$Load = {$: 2};
var $author$project$App$Page$Reviewer$PageWaitingLine$LoadCompanyOrNext = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Reviewer$PageWaitingLine$LoadInterviewStatement = {$: 7};
var $author$project$App$Page$Reviewer$PageWaitingLine$LoadStatementOrNext = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Reviewer$PageWaitingLine$LoadTemplateOrNext = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Reviewer$PageWaitingLine$sortWaitingLine = function (wt) {
	var _v0 = wt.dw.aw;
	if (_v0 === 11) {
		return $elm$time$Time$posixToMillis(wt.dw.bW);
	} else {
		return 4102444800000 - $author$project$App$Data$StatementStatus$statusToInt(wt.dw.aw);
	}
};
var $author$project$App$Page$Reviewer$PageWaitingLine$updateResponse = F3(
	function (respMsg, st, model) {
		var _v0 = _Utils_Tuple2(
			respMsg,
			$author$project$App$Service$ApplicationResources$getAuthToken(st._));
		switch (_v0.a.$) {
			case 2:
				if (!_v0.b.$) {
					var _v2 = _v0.a;
					var token = _v0.b.a;
					var newDoctorList = A2($author$project$App$Service$ApplicationResources$getDoctorsOnlineForDoctor, token.Y, st._);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c5: newDoctorList}),
						$elm$core$Platform$Cmd$none);
				} else {
					var _v3 = _v0.a;
					var _v4 = _v0.b;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!_v0.b.$) {
					var _v1 = _v0.a;
					var token = _v0.b.a;
					return _Utils_Tuple2(
						model,
						$author$project$App$SharedState$asyncMsg(
							$author$project$App$Page$Reviewer$PageWaitingLine$LoadTemplateOrNext(
								A2($author$project$App$Service$ApplicationResources$getDoctorWaitingLine, token.Y, st._))));
				} else {
					var _v5 = _v0.a;
					var _v6 = _v0.b;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				if (!_v0.b.$) {
					var _v7 = _v0.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$App$SharedState$asyncDelayedMsg,
							$author$project$App$Constants$informOnlineInterval,
							$author$project$App$Page$Reviewer$PageWaitingLine$InformOnline(model.df)));
				} else {
					var _v8 = _v0.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 0:
				if (!_v0.b.$) {
					var _v9 = _v0.a;
					var token = _v0.b.a;
					var _v10 = $author$project$App$Components$Sound$enable(model.N);
					var nSound = _v10.a;
					var nSoundCmd = _v10.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{Y: token.Y, N: nSound}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageWaitingLine$SoundMsg, nSoundCmd),
									$author$project$App$SharedState$asyncMsg($author$project$App$Page$Reviewer$PageWaitingLine$Load)
								])));
				} else {
					var _v11 = _v0.a;
					var _v12 = _v0.b;
					var _v13 = $author$project$App$Components$Sound$enable(model.N);
					var nSound = _v13.a;
					var nSoundCmd = _v13.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{N: nSound}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageWaitingLine$SoundMsg, nSoundCmd),
									$author$project$App$SharedState$asyncMsg($author$project$App$Page$Reviewer$PageWaitingLine$Load)
								])));
				}
			case 4:
				var wt = _v0.a.a;
				var template = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$StatementTemplate$emptyModel,
					A2($author$project$App$Service$ApplicationResources$getStatementTemplateById, wt.dw.eY, st._));
				var wtWithTemplate = _Utils_update(
					wt,
					{cJ: template});
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Reviewer$PageWaitingLine$LoadCompanyOrNext(wtWithTemplate)));
			case 5:
				var wt = _v0.a.a;
				var company = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$Company$emptyModel,
					A2($author$project$App$Service$ApplicationResources$getCompanyById, wt.cJ.gx, st._));
				var wtWithCompany = _Utils_update(
					wt,
					{b4: company});
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Reviewer$PageWaitingLine$LoadStatementOrNext(wtWithCompany)));
			case 6:
				if (!_v0.b.$) {
					var waitingPerson = _v0.a.a;
					var token = _v0.b.a;
					var statement = function () {
						var _v14 = A2($author$project$App$Service$ApplicationResources$getStatementFromPerson, waitingPerson.dw.as, st._);
						if (_v14.$ === 3) {
							var stmt = _v14.a;
							return stmt;
						} else {
							return $author$project$App$Data$Statement$emptyModel;
						}
					}();
					var currentWaitingLine = A2($author$project$App$Service$ApplicationResources$getDoctorWaitingLine, token.Y, st._);
					var wtp = A2(
						$elm$core$Maybe$withDefault,
						waitingPerson.dw,
						$elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (w) {
									return _Utils_eq(w.as, waitingPerson.dw.as);
								},
								currentWaitingLine)));
					var wt = _Utils_update(
						waitingPerson,
						{dw: wtp});
					var wtWithStatement = _List_fromArray(
						[
							_Utils_update(
							wt,
							{h8: statement})
						]);
					var orderedList = A2(
						$elm$core$List$sortBy,
						$author$project$App$Page$Reviewer$PageWaitingLine$sortWaitingLine,
						_Utils_ap(
							wtWithStatement,
							A2(
								$elm$core$List$filter,
								function (lWt) {
									return !_Utils_eq(lWt.dw.as, wt.dw.as);
								},
								model.e1)));
					return _Utils_Tuple2(
						model,
						$author$project$App$SharedState$asyncMsg(
							$author$project$App$Page$Reviewer$PageWaitingLine$InformLoaded(orderedList)));
				} else {
					var _v15 = _v0.b;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var _v16 = _v0.a;
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Reviewer$PageWaitingLine$LoadInterviewStatement));
			default:
				var _v17 = _v0.a;
				var _v18 = $author$project$App$Service$ApplicationResources$getDoctorInterview(st._);
				if (_v18.$ === 3) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$App$Route$pushUrl,
							st.fC,
							$author$project$App$Route$Private(
								$author$project$App$Route$Doctor($author$project$App$Route$DoctorReviewStatement))));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Page$Reviewer$PageWaitingProponentDetails$LoadFullStatement = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Reviewer$PageWaitingProponentDetails$Loaded = 1;
var $author$project$App$Page$Reviewer$PageWaitingProponentDetails$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg) {
			case 0:
				var cmd = function () {
					var _v1 = $author$project$App$Service$ApplicationResources$getDoctorInterview(st._);
					if (_v1.$ === 3) {
						var stmtModel = _v1.a;
						return (stmtModel.aw === 12) ? $author$project$App$SharedState$asyncMsg(
							$author$project$App$Page$Reviewer$PageWaitingProponentDetails$LoadFullStatement(stmtModel.as)) : ($author$project$App$Data$StatementStatus$statusInInterview(stmtModel.aw) ? A2(
							$author$project$App$Route$replaceUrl,
							st.fC,
							$author$project$App$Route$Private(
								$author$project$App$Route$Doctor(
									$author$project$App$Route$DoctorInterview(0)))) : A2(
							$author$project$App$Route$replaceUrl,
							st.fC,
							$author$project$App$Route$Private(
								$author$project$App$Route$Doctor($author$project$App$Route$DoctorWaitingLine))));
					} else {
						return A2(
							$author$project$App$Route$replaceUrl,
							st.fC,
							$author$project$App$Route$Private(
								$author$project$App$Route$Doctor($author$project$App$Route$DoctorWaitingLine)));
					}
				}();
				return _Utils_Tuple2(model, cmd);
			case 1:
				var _v2 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					st.ia.h8.jM,
					st.ia.h8.bs,
					st,
					$elm$core$Maybe$Nothing,
					$elm$core$Maybe$Nothing);
				var viewModel = _v2.a;
				var viewCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{p: 1, bm: viewModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageWaitingProponentDetails$ViewFullStatementMsg, viewCmd));
			case 3:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Doctor($author$project$App$Route$DoctorWaitingLine))));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							e1: A2($author$project$App$Service$ApplicationResources$getDoctorWaitingLine, model.e$.Y, st._)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var doctor = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (doc) {
							return _Utils_eq(
								st.ia.h8.g.e.bI,
								$elm$core$Maybe$Just(doc.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var companyPerson = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (person) {
							return _Utils_eq(
								st.ia.h8.g.e.iX,
								$elm$core$Maybe$Just(person.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var _v3 = A4(
					$author$project$App$Components$ViewFullStatement$update,
					A2($author$project$App$Components$ViewFullStatement$NewPeople, doctor, companyPerson),
					st.ia.h8,
					st,
					model.bm);
				var newViewModel = _v3.a;
				var viewCmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bm: newViewModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageWaitingProponentDetails$ViewFullStatementMsg, viewCmd));
		}
	});
var $author$project$App$Page$PageReviewer$updateResponse = F3(
	function (respMsg, st, model) {
		var _v0 = _Utils_Tuple2(respMsg, model);
		switch (_v0.a.$) {
			case 1:
				if (!_v0.b.$) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v1 = A3($author$project$App$Page$Reviewer$PageListIncomplete$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v1.a;
					var nPageMsg = _v1.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageReviewer$ListIncomplete(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListIncompleteMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 0:
				if (_v0.b.$ === 1) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v2 = A3($author$project$App$Page$Reviewer$PageListForReview$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v2.a;
					var nPageMsg = _v2.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageReviewer$ListForReview(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListForReviewMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				if (_v0.b.$ === 2) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v3 = A3($author$project$App$Page$Reviewer$PageListApproved$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v3.a;
					var nPageMsg = _v3.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageReviewer$ListApproved(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListApprovedMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				if (_v0.b.$ === 3) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v4 = A3($author$project$App$Page$Reviewer$PageReview$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v4.a;
					var nPageMsg = _v4.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageReviewer$ReviewInterview(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ReviewInterviewMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				if (_v0.b.$ === 4) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v5 = A3($author$project$App$Page$Reviewer$EditStatement$StatementEdit$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v5.a;
					var nPageMsg = _v5.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageReviewer$EditStatement(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$EditStatementMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				if (_v0.b.$ === 6) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v6 = A3($author$project$App$Page$Reviewer$PageListReevaluation$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v6.a;
					var nPageMsg = _v6.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageReviewer$ListReevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListReevaluationMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				if (_v0.b.$ === 5) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v7 = A3($author$project$App$Page$Reviewer$PageReevaluation$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v7.a;
					var nPageMsg = _v7.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageReviewer$Reevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ReevaluationMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				if (_v0.b.$ === 7) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v8 = A3($author$project$App$Page$Reviewer$PageWaitingLine$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v8.a;
					var nPageMsg = _v8.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageReviewer$WaitingLine(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$WaitingLineMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				if (_v0.b.$ === 8) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v9 = A3($author$project$App$Page$Reviewer$PageWaitingProponentDetails$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v9.a;
					var nPageMsg = _v9.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageReviewer$WaitingProponentDetails(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$WaitingProponentDetailsMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$Load = {$: 0};
var $author$project$App$Page$Support$EditStatement$DocumentView$DocumentMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Support$EditStatement$DocumentView$TabDocument = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Support$EditStatement$DocumentView$init = F4(
	function (now, fullStmt, sharedState, doctor) {
		var _v0 = A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, doc, _v1) {
					var uDocs = _v1.a;
					var cmds = _v1.b;
					var _v2 = $author$project$App$Data$Document$requestUrl(doc);
					var uDoc = _v2.a;
					var cmd = _v2.b;
					return _Utils_Tuple2(
						A3($elm$core$Dict$insert, k, uDoc, uDocs),
						A2($elm$core$List$cons, cmd, cmds));
				}),
			_Utils_Tuple2(fullStmt.an, _List_Nil),
			fullStmt.an);
		var updatedDocs = _v0.a;
		var dCmd = _v0.b;
		var updatedStmt = _Utils_update(
			fullStmt,
			{an: updatedDocs});
		return _Utils_Tuple2(
			{
				b5: $author$project$App$Page$Support$EditStatement$DocumentView$TabDocument(0),
				bu: doctor,
				a5: updatedStmt,
				ey: now
			},
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$Cmd$map($author$project$App$Page$Support$EditStatement$DocumentView$DocumentMsg),
					dCmd)));
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$updateResponse = F3(
	function (respMsg, st, model) {
		var modelForm = $author$project$App$Page$Support$EditStatement$StatementEdit$formFromModel(model);
		var fullStmt = model.h8;
		var companyObj = fullStmt.b4;
		var bearer = fullStmt.g;
		switch (respMsg.$) {
			case 0:
				var cId = respMsg.a;
				var loadedFullStmt = st.ia.h8;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							h8: _Utils_update(
								fullStmt,
								{
									b4: _Utils_update(
										companyObj,
										{as: cId})
								})
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Support$EditStatement$StatementEdit$Load));
			case 2:
				var stmtId = respMsg.a;
				var loadedFullStmt = st.ia.h8;
				var _v1 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					_List_Nil,
					_List_Nil,
					st,
					$elm$core$Maybe$Nothing,
					$elm$core$Maybe$Nothing);
				var viewModel = _v1.a;
				var viewCmd = _v1.b;
				var _v2 = A4(
					$author$project$App$Page$Support$EditStatement$DocumentView$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					st,
					$elm$core$Maybe$Nothing);
				var documentViewModel = _v2.a;
				var documentViewCmd = _v2.b;
				var _v3 = A3(
					$elm$core$Dict$foldl,
					F3(
						function (k, doc, _v4) {
							var uDocs = _v4.a;
							var cmds = _v4.b;
							var _v5 = $author$project$App$Data$Document$requestUrl(doc);
							var uDoc = _v5.a;
							var cmd = _v5.b;
							return _Utils_Tuple2(
								A3($elm$core$Dict$insert, k, uDoc, uDocs),
								A2($elm$core$List$cons, cmd, cmds));
						}),
					_Utils_Tuple2(loadedFullStmt.an, _List_Nil),
					loadedFullStmt.an);
				var updatedDocs = _v3.a;
				var dCmd = _v3.b;
				var updatedStmt = function () {
					if ($author$project$App$Data$Id$uuidIsValid(model.h8.g.e.as)) {
						var oldFullStatement = model.h8;
						return _Utils_update(
							oldFullStatement,
							{an: updatedDocs});
					} else {
						return _Utils_update(
							loadedFullStmt,
							{an: updatedDocs});
					}
				}();
				return _Utils_Tuple2(
					A2(
						$author$project$App$Page$Support$EditStatement$StatementEdit$updateForm,
						_Utils_update(
							modelForm,
							{
								a5: _Utils_update(
									loadedFullStmt,
									{an: updatedDocs})
							}),
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Support$EditStatement$StatementEdit$Showing, stmtId, viewModel),
								ac: A2($author$project$App$Page$Support$EditStatement$StatementEdit$DocumentShowing, stmtId, documentViewModel),
								ep: true,
								cq: st.ia.h8.g.e.gL,
								h8: updatedStmt
							})),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$EditStatement$StatementEdit$ViewFullStmtMsg, viewCmd),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$EditStatement$StatementEdit$DocumentViewMsg, documentViewCmd)
							])));
			case 1:
				var cId = respMsg.a;
				return _Utils_Tuple2(
					A2($author$project$App$Page$Support$EditStatement$StatementEdit$updateForm, modelForm, model),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{y: false}),
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Support(
								$author$project$App$Route$SupportListForReviewRoute(
									$author$project$App$Route$SupportListForReview($author$project$App$Components$Filter$empty))))));
			case 9:
				var stmtId = respMsg.a;
				var isSuccess = _Utils_eq(
					A2($author$project$App$Service$ApplicationResources$getPostponeStatement, stmtId, st._),
					$author$project$App$Service$Loadable$Got(true));
				var newNotification = isSuccess ? {v: false, jl: 'Atendimento atualizado.'} : {v: true, jl: 'Erro.'};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: false,
							F: $elm$core$Maybe$Just(newNotification)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var stmtId = respMsg.a;
				var isSuccess = _Utils_eq(
					A2($author$project$App$Service$ApplicationResources$getReturnToInterview, stmtId, st._),
					$author$project$App$Service$Loadable$Got(true));
				var newNotification = isSuccess ? {v: false, jl: 'Atendimento atualizado.'} : {v: true, jl: 'Erro.'};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: false,
							F: $elm$core$Maybe$Just(newNotification)
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var stmtId = respMsg.a;
				var isSuccess = _Utils_eq(
					A2($author$project$App$Service$ApplicationResources$getReturnToDoctorEvaluation, stmtId, st._),
					$author$project$App$Service$Loadable$Got(true));
				var newNotification = isSuccess ? {v: false, jl: 'Atendimento atualizado.'} : {v: true, jl: 'Erro.'};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: false,
							F: $elm$core$Maybe$Just(newNotification)
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var stmtId = respMsg.a;
				var isSuccess = _Utils_eq(
					A2($author$project$App$Service$ApplicationResources$getReturnToWaitingForRoom, stmtId, st._),
					$author$project$App$Service$Loadable$Got(true));
				var newNotification = isSuccess ? {v: false, jl: 'Atendimento atualizado.'} : {v: true, jl: 'Erro.'};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: false,
							F: $elm$core$Maybe$Just(newNotification)
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a6: !model.a6}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var documentId = respMsg.a;
				var isSuccess = _Utils_eq(
					A2($author$project$App$Service$ApplicationResources$getDeleteDocumentFromId, documentId, st._),
					$author$project$App$Service$Loadable$Got(true));
				var newNotification = isSuccess ? {v: false, jl: 'Atendimento atualizado.'} : {v: true, jl: 'Erro.'};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: false,
							F: $elm$core$Maybe$Just(newNotification)
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var documentId = respMsg.a;
				var isSuccess = _Utils_eq(
					A2($author$project$App$Service$ApplicationResources$getUpdateDocumentFromId, documentId, st._),
					$author$project$App$Service$Loadable$Got(true));
				var newNotification = isSuccess ? {v: false, jl: 'Atendimento atualizado.'} : {v: true, jl: 'Erro.'};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: false,
							F: $elm$core$Maybe$Just(newNotification)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var statementId = respMsg.a;
				var isSuccess = _Utils_eq(
					A2($author$project$App$Service$ApplicationResources$getCreateDocumentFromStatement, statementId, st._),
					$author$project$App$Service$Loadable$Got(true));
				var newNotification = isSuccess ? {v: false, jl: 'Atendimento atualizado.'} : {v: true, jl: 'Erro.'};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: false,
							F: $elm$core$Maybe$Just(newNotification)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Page$Support$PageListApproved$LoadPeople = {$: 3};
var $author$project$App$Page$Support$PageListApproved$LoadStatements = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Support$PageListApproved$ViewDetails = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Support$PageListApproved$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Support$PageListApproved$LoadStatements(model.b)));
			case 1:
				var stmtId = respMsg.a;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Support$PageListApproved$LoadStatements(model.b)),
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Support$PageListApproved$ViewDetails(stmtId))
							])));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3(
								$author$project$App$Service$ApplicationResources$getApprovedStatementsForReview,
								model.gx,
								$author$project$App$Page$Support$PageListApproved$defaultFilter(model.b),
								st._)
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Support$PageListApproved$LoadPeople));
			case 3:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var stmtId = respMsg.a;
				var doctor = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (doc) {
							return _Utils_eq(
								st.ia.h8.g.e.fl,
								$elm$core$Maybe$Just(doc.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var companyPerson = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (person) {
							return _Utils_eq(
								st.ia.h8.g.e.iX,
								$elm$core$Maybe$Just(person.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var _v1 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					_List_Nil,
					_List_Nil,
					st,
					doctor,
					companyPerson);
				var viewModel = _v1.a;
				var viewCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gD: A2($author$project$App$Page$Support$PageListApproved$Showing, stmtId, viewModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$PageListApproved$ViewFullStmtMsg, viewCmd));
			default:
				var _v2 = model.gD;
				if (_v2.$ === 1) {
					var stmtId = _v2.a;
					var viewModel = _v2.b;
					var doctor = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (doc) {
								return _Utils_eq(
									st.ia.h8.g.e.bI,
									$elm$core$Maybe$Just(doc.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var companyPerson = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (person) {
								return _Utils_eq(
									st.ia.h8.g.e.iX,
									$elm$core$Maybe$Just(person.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var _v3 = A4(
						$author$project$App$Components$ViewFullStatement$update,
						A2($author$project$App$Components$ViewFullStatement$NewPeople, doctor, companyPerson),
						st.ia.h8,
						st,
						viewModel);
					var newViewModel = _v3.a;
					var viewCmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Support$PageListApproved$Showing, stmtId, newViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$PageListApproved$ViewFullStmtMsg, viewCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Page$Support$PageListForReview$LoadPeople = {$: 2};
var $author$project$App$Page$Support$PageListForReview$LoadStatements = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Support$PageListForReview$Review = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Support$PageListForReview$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 2:
				var filter = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3($author$project$App$Service$ApplicationResources$getStatementsForReview, model.gx, filter, st._)
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Support$PageListForReview$LoadPeople));
			case 3:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			case 0:
				var filter = respMsg.a;
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Support$PageListForReview$LoadStatements(filter)));
			case 1:
				var stmtId = respMsg.a;
				var filter = respMsg.b;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Support$PageListForReview$LoadStatements(filter)),
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Support$PageListForReview$Review(stmtId))
							])));
			default:
				var _v1 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					_List_Nil,
					_List_Nil,
					st,
					$elm$core$Maybe$Nothing,
					$elm$core$Maybe$Nothing);
				var viewModel = _v1.a;
				var viewCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aU: $elm$core$Maybe$Just(st.ia.h8.g.e.as),
							av: $elm$core$Maybe$Just(viewModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$PageListForReview$ViewFullStmtMsg, viewCmd));
		}
	});
var $author$project$App$Page$Support$PageListIncomplete$LoadPeople = {$: 3};
var $author$project$App$Page$Support$PageListIncomplete$LoadStatements = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Support$PageListIncomplete$ViewDetails = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Support$PageListIncomplete$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Support$PageListIncomplete$LoadStatements(model.b)));
			case 1:
				var stmtId = respMsg.a;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Support$PageListIncomplete$LoadStatements(model.b)),
								$author$project$App$SharedState$asyncMsg(
								$author$project$App$Page$Support$PageListIncomplete$ViewDetails(stmtId))
							])));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3(
								$author$project$App$Service$ApplicationResources$getIncompleteStatementsForReview,
								model.gx,
								$author$project$App$Page$Support$PageListIncomplete$defaultFilter(model.b),
								st._)
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Support$PageListIncomplete$LoadPeople));
			case 3:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var stmtId = respMsg.a;
				var doctor = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (doc) {
							return _Utils_eq(
								st.ia.h8.g.e.fl,
								$elm$core$Maybe$Just(doc.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var companyPerson = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (person) {
							return _Utils_eq(
								st.ia.h8.g.e.iX,
								$elm$core$Maybe$Just(person.as));
						},
						$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
				var _v1 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					_List_Nil,
					_List_Nil,
					st,
					doctor,
					companyPerson);
				var viewModel = _v1.a;
				var viewCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gD: A2($author$project$App$Page$Support$PageListIncomplete$Showing, stmtId, viewModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$PageListIncomplete$ViewFullStmtMsg, viewCmd));
			case 5:
				return _Utils_Tuple2(model, $elm$browser$Browser$Navigation$reload);
			default:
				var _v2 = model.gD;
				if (_v2.$ === 1) {
					var stmtId = _v2.a;
					var viewModel = _v2.b;
					var doctor = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (doc) {
								return _Utils_eq(
									st.ia.h8.g.e.bI,
									$elm$core$Maybe$Just(doc.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var companyPerson = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (person) {
								return _Utils_eq(
									st.ia.h8.g.e.iX,
									$elm$core$Maybe$Just(person.as));
							},
							$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
					var _v3 = A4(
						$author$project$App$Components$ViewFullStatement$update,
						A2($author$project$App$Components$ViewFullStatement$NewPeople, doctor, companyPerson),
						st.ia.h8,
						st,
						viewModel);
					var newViewModel = _v3.a;
					var viewCmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								gD: A2($author$project$App$Page$Support$PageListIncomplete$Showing, stmtId, newViewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$PageListIncomplete$ViewFullStmtMsg, viewCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$Page$Support$PageListReevaluation$LoadPeople = {$: 2};
var $author$project$App$Page$Support$PageListReevaluation$LoadStatements = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Support$PageListReevaluation$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg.$) {
			case 1:
				var cId = respMsg.a;
				var filter = respMsg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: A3($author$project$App$Service$ApplicationResources$getReevalStatementsFromCompany, cId, filter, st._)
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Support$PageListReevaluation$LoadPeople));
			case 2:
				var pId = respMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bF: A3(
								$elm$core$Dict$insert,
								pId,
								A2($author$project$App$Service$ApplicationResources$getPersonById, pId, st._),
								model.bF)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					model,
					$author$project$App$SharedState$asyncMsg(
						$author$project$App$Page$Support$PageListReevaluation$LoadStatements($author$project$App$Components$Filter$empty)));
		}
	});
var $author$project$App$Page$Support$PageReevaluation$DocumentMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$Support$PageReevaluation$Loaded = 1;
var $author$project$App$Page$Support$PageReevaluation$filterSections = F2(
	function (answers, sections) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, section) {
					return !$elm$core$List$isEmpty(
						A2(
							$elm$core$List$filter,
							function (question) {
								return !$elm$core$List$isEmpty(
									A2(
										$elm$core$List$filter,
										function (template) {
											return A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$Answer$emptyModel,
												A2($elm$core$Dict$get, template.as, answers)).d3;
										},
										question.bY));
							},
							section.eI));
				}),
			sections);
	});
var $author$project$App$Page$Support$PageReevaluation$updateResponse = F3(
	function (respMsg, st, model) {
		switch (respMsg) {
			case 0:
				var newFullStmt = model.P;
				var answersDictWithKey = $elm$core$Dict$fromList(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2(-1, st.ia.h8.g.aZ)
							]),
						A2(
							$elm$core$List$map,
							function (_v4) {
								var k = _v4.a;
								var d = _v4.b;
								return _Utils_Tuple2(k, d.aZ);
							},
							$elm$core$Dict$toList(st.ia.h8.fh))));
				var _v1 = A3(
					$elm$core$Dict$foldl,
					F3(
						function (k, doc, _v2) {
							var uDocs = _v2.a;
							var cmds = _v2.b;
							var _v3 = $author$project$App$Data$Document$requestUrl(doc);
							var uDoc = _v3.a;
							var cmd = _v3.b;
							return _Utils_Tuple2(
								A3($elm$core$Dict$insert, k, uDoc, uDocs),
								A2($elm$core$List$cons, cmd, cmds));
						}),
					_Utils_Tuple2(st.ia.h8.an, _List_Nil),
					st.ia.h8.an);
				var updatedDocs = _v1.a;
				var dCmd = _v1.b;
				var newModel = (model.p === 1) ? _Utils_update(
					model,
					{
						an: updatedDocs,
						P: _Utils_update(
							newFullStmt,
							{an: updatedDocs})
					}) : _Utils_update(
					model,
					{
						aZ: answersDictWithKey,
						an: updatedDocs,
						gH: st.ia.h8.gH,
						P: st.ia.h8,
						cA: A2(
							$author$project$App$Page$Support$PageReevaluation$filterSections,
							st.ia.h8.g.aZ,
							$elm$core$Dict$fromList(
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, s) {
											return _Utils_Tuple2(i, s);
										}),
									st.ia.h8.gH))),
						p: 1,
						fX: A3(
							$elm$core$Dict$insert,
							$author$project$App$Data$Statement$depIndexToInt($author$project$App$Data$Statement$Bearer),
							st.ia.h8.g,
							st.ia.h8.fh)
					});
				return _Utils_Tuple2(
					newModel,
					$elm$core$Platform$Cmd$batch(
						A2(
							$elm$core$List$map,
							$elm$core$Platform$Cmd$map($author$project$App$Page$Support$PageReevaluation$DocumentMsg),
							dCmd)));
			case 2:
				var cidCategories = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$author$project$App$Service$ApplicationResources$getCategoryFromStatement(st._));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fa: cidCategories}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Admin($author$project$App$Route$AdminListReevaluation))));
			case 3:
				var oldChatModel = model.aM;
				var newMessages = $elm$core$List$reverse(
					A2($author$project$App$Service$ApplicationResources$getStatementMessagesFromStatement, model.eV, st._));
				var _v5 = A2(
					$author$project$App$Components$Chatbox$update,
					$author$project$App$Components$Chatbox$UpdateMessages,
					_Utils_update(
						oldChatModel,
						{co: newMessages}));
				var newChatBoxModel = _v5.a;
				var chatBoxCmd = _v5.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aM: newChatBoxModel}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$PageReevaluation$ChatBoxMsg, chatBoxCmd)
							])));
			default:
				return _Utils_Tuple2(model, $elm$browser$Browser$Navigation$reload);
		}
	});
var $author$project$App$Page$Support$PageReview$Load = {$: 0};
var $author$project$App$Page$Support$PageReview$LoadStatementFromPerson = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Support$PageReview$updateResponse = F3(
	function (respMsg, st, model) {
		var fullStmt = model.h8;
		var companyObj = fullStmt.b4;
		var bearer = fullStmt.g;
		switch (respMsg.$) {
			case 0:
				var cId = respMsg.a;
				var loadedFullStmt = st.ia.h8;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							h8: _Utils_update(
								fullStmt,
								{
									b4: _Utils_update(
										companyObj,
										{as: cId})
								})
						}),
					$author$project$App$SharedState$asyncMsg($author$project$App$Page$Support$PageReview$Load));
			case 4:
				if (!respMsg.b.$) {
					var document = respMsg.a;
					var _v1 = respMsg.b;
					var loadedFullStmt = st.ia.h8;
					var _v2 = function () {
						var _v3 = A2($author$project$App$Service$ApplicationResources$getPersonByDocument, document, st._);
						if (_v3.$ === 3) {
							var p = _v3.a;
							return _Utils_Tuple2(
								p,
								$author$project$App$SharedState$asyncMsg(
									$author$project$App$Page$Support$PageReview$LoadStatementFromPerson(p.as)));
						} else {
							return _Utils_Tuple2(bearer.dw, $elm$core$Platform$Cmd$none);
						}
					}();
					var person = _v2.a;
					var cmd = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h8: _Utils_update(
									fullStmt,
									{
										g: _Utils_update(
											bearer,
											{dw: person})
									})
							}),
						cmd);
				} else {
					var document = respMsg.a;
					var idx = respMsg.b.a;
					var loadedFullStmt = st.ia.h8;
					var dep = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$FullStatement$emptyEditStatement,
						A2($elm$core$Dict$get, idx, fullStmt.fh));
					var person = function () {
						var _v4 = A2($author$project$App$Service$ApplicationResources$getPersonByDocument, document, st._);
						if (_v4.$ === 3) {
							var p = _v4.a;
							return p;
						} else {
							return dep.dw;
						}
					}();
					var nDep = _Utils_update(
						dep,
						{dw: person});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h8: _Utils_update(
									fullStmt,
									{
										fh: A3($elm$core$Dict$insert, idx, nDep, fullStmt.fh)
									})
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var personId = respMsg.a;
				var _v5 = A2($author$project$App$Service$ApplicationResources$getStatementFromPerson, personId, st._);
				if (_v5.$ === 3) {
					var stmt = _v5.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h8: _Utils_update(
									fullStmt,
									{
										g: _Utils_update(
											bearer,
											{e: stmt})
									})
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$App$Route$pushUrl,
									st.fC,
									$author$project$App$Route$Private(
										$author$project$App$Route$Support(
											$author$project$App$Route$SupportInterviewRoute(stmt.as)))),
									$author$project$App$SharedState$asyncMsg($author$project$App$Page$Support$PageReview$Load)
								])));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var stmtId = respMsg.a;
				var loadedFullStmt = st.ia.h8;
				var _v6 = A7(
					$author$project$App$Components$ViewFullStatement$init,
					$author$project$App$Service$ApiWebSocket$getTimestamp(st._),
					st.ia.h8,
					_List_Nil,
					_List_Nil,
					st,
					$elm$core$Maybe$Nothing,
					$elm$core$Maybe$Nothing);
				var viewModel = _v6.a;
				var viewCmd = _v6.b;
				var _v7 = A3(
					$elm$core$Dict$foldl,
					F3(
						function (k, doc, _v8) {
							var uDocs = _v8.a;
							var cmds = _v8.b;
							var _v9 = $author$project$App$Data$Document$requestUrl(doc);
							var uDoc = _v9.a;
							var cmd = _v9.b;
							return _Utils_Tuple2(
								A3($elm$core$Dict$insert, k, uDoc, uDocs),
								A2($elm$core$List$cons, cmd, cmds));
						}),
					_Utils_Tuple2(loadedFullStmt.an, _List_Nil),
					loadedFullStmt.an);
				var updatedDocs = _v7.a;
				var dCmd = _v7.b;
				var updatedStmt = function () {
					if ($author$project$App$Data$Id$uuidIsValid(model.h8.g.e.as)) {
						var oldFullStatement = model.h8;
						return _Utils_update(
							oldFullStatement,
							{an: updatedDocs});
					} else {
						return _Utils_update(
							loadedFullStmt,
							{an: updatedDocs});
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gD: A2($author$project$App$Page$Support$PageReview$Showing, stmtId, viewModel),
							ep: true,
							h8: updatedStmt
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Support$PageReview$ViewFullStmtMsg, viewCmd)
							])));
			case 1:
				var cId = respMsg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$App$Route$pushUrl,
						st.fC,
						$author$project$App$Route$Private(
							$author$project$App$Route$Support(
								$author$project$App$Route$SupportListForReviewRoute(
									$author$project$App$Route$SupportListForReview($author$project$App$Components$Filter$empty))))));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$App$Page$PageSupport$updateResponse = F3(
	function (respMsg, st, model) {
		var _v0 = _Utils_Tuple2(respMsg, model);
		switch (_v0.a.$) {
			case 1:
				if (!_v0.b.$) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v1 = A3($author$project$App$Page$Support$PageListIncomplete$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v1.a;
					var nPageMsg = _v1.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageSupport$ListIncomplete(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListIncompleteMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 0:
				if (_v0.b.$ === 1) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v2 = A3($author$project$App$Page$Support$PageListForReview$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v2.a;
					var nPageMsg = _v2.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageSupport$ListForReview(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListForReviewMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				if (_v0.b.$ === 2) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v3 = A3($author$project$App$Page$Support$PageListApproved$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v3.a;
					var nPageMsg = _v3.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageSupport$ListApproved(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListApprovedMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				if (_v0.b.$ === 3) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v4 = A3($author$project$App$Page$Support$PageReview$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v4.a;
					var nPageMsg = _v4.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageSupport$ReviewInterview(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ReviewInterviewMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				if (_v0.b.$ === 4) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v5 = A3($author$project$App$Page$Support$EditStatement$StatementEdit$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v5.a;
					var nPageMsg = _v5.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageSupport$EditStatement(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$EditStatementMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				if (_v0.b.$ === 6) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v6 = A3($author$project$App$Page$Support$PageListReevaluation$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v6.a;
					var nPageMsg = _v6.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageSupport$ListReevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListReevaluationMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				if (_v0.b.$ === 5) {
					var pageMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v7 = A3($author$project$App$Page$Support$PageReevaluation$updateResponse, pageMsg, st, pageModel);
					var nPageModel = _v7.a;
					var nPageMsg = _v7.b;
					return _Utils_Tuple2(
						$author$project$App$Page$PageSupport$Reevaluation(nPageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ReevaluationMsg, nPageMsg));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$App$SharedState$GetICDs = F2(
	function (a, b) {
		return {$: 24, a: a, b: b};
	});
var $author$project$App$SharedState$LoadAnswers = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$App$SharedState$LoadCompleteQuestionSections = F3(
	function (a, b, c) {
		return {$: 38, a: a, b: b, c: c};
	});
var $author$project$App$SharedState$LoadContactFromOwner = F3(
	function (a, b, c) {
		return {$: 12, a: a, b: b, c: c};
	});
var $author$project$App$SharedState$LoadDocumentsFromStatement = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$App$SharedState$LoadStatementDeps = F2(
	function (a, b) {
		return {$: 48, a: a, b: b};
	});
var $author$project$App$SharedState$LoadStatementMinorDeps = F2(
	function (a, b) {
		return {$: 49, a: a, b: b};
	});
var $author$project$App$SharedState$LoadTemplateByIdForSharedState = F2(
	function (a, b) {
		return {$: 86, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$SharedAnswersLoaded = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$SharedCompanyLoaded = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$ResponseMsgs$SharedContactLoaded = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$SharedDepAnswersLoaded = F3(
	function (a, b, c) {
		return {$: 11, a: a, b: b, c: c};
	});
var $author$project$App$Page$ResponseMsgs$SharedDepContactLoaded = F3(
	function (a, b, c) {
		return {$: 14, a: a, b: b, c: c};
	});
var $author$project$App$Page$ResponseMsgs$SharedDepPersonLoaded = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$SharedICDsLoaded = function (a) {
	return {$: 17, a: a};
};
var $author$project$App$Page$ResponseMsgs$SharedPersonLoaded = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$ResponseMsgs$SharedQuestionsLoaded = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$SharedStatementDepsLoaded = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$ResponseMsgs$SharedStatementDocumentsLoaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$ResponseMsgs$SharedStatementMinorDepsLoaded = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$ResponseMsgs$SharedTemplateLoaded = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Route$defaultProfileRoute = function (profile) {
	var _v0 = profile.gu;
	switch (_v0) {
		case 0:
			return $author$project$App$Route$Doctor($author$project$App$Route$DoctorWaitingLine);
		case 1:
			return $author$project$App$Route$Company(
				$author$project$App$Route$CompanyListPendingRoute(
					$author$project$App$Route$CompanyListPending($author$project$App$Components$Filter$empty)));
		case 2:
			return $author$project$App$Route$Review(
				$author$project$App$Route$ReviewListForReviewRoute(
					$author$project$App$Route$ReviewListForReview($author$project$App$Components$Filter$empty)));
		case 3:
			return $author$project$App$Route$Support(
				$author$project$App$Route$SupportListForReviewRoute(
					$author$project$App$Route$SupportListForReview($author$project$App$Components$Filter$empty)));
		case 5:
			return $author$project$App$Route$Admin(
				$author$project$App$Route$AdminFinanceSalesRoute(0));
		default:
			return $author$project$App$Route$AccessRestricted;
	}
};
var $author$project$App$Data$ICD$dictFromList = function (list) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (icd, dict) {
				return A3(
					$elm$core$Dict$insert,
					icd.aY,
					A3(
						$elm$core$Dict$insert,
						icd.a0,
						icd,
						A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Dict$empty,
							A2($elm$core$Dict$get, icd.aY, dict))),
					dict);
			}),
		$elm$core$Dict$empty,
		list);
};
var $author$project$App$Data$Profile$emptyModel = {gu: 6, am: '', as: $author$project$App$Data$Id$uuidNil};
var $author$project$App$Service$SpecificResources$errorToMessage = function (error) {
	switch (error.$) {
		case 1:
			var msg = error.a;
			return msg;
		case 2:
			var msg = error.a;
			return msg;
		case 3:
			var msg = error.a;
			return msg;
		case 4:
			var msg = error.a;
			return msg;
		case 5:
			var msg = error.a;
			return msg;
		case 6:
			var msg = error.a;
			return msg;
		case 7:
			var msg = error.a;
			return msg;
		case 8:
			var msg = error.a;
			return msg;
		case 9:
			var msg = error.a;
			return msg;
		case 10:
			var msg = error.a;
			return msg;
		case 11:
			var msg = error.a;
			return msg;
		case 12:
			var msg = error.a;
			return msg;
		default:
			var msg = error.a;
			return msg;
	}
};
var $author$project$App$Service$Answer$getAnswersFromStatementAndCategory = F3(
	function (stmtId, cat, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				A2($author$project$App$Service$Answer$pathAnswersFromStatementAndCategory, stmtId, cat),
				$author$project$App$Service$SpecificResources$TypeAnswer,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 5)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Service$ApplicationResources$getCallResetPasswordWithPassword = F2(
	function (data, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodResetPasswordWithPass(data),
			api);
		_v0$2:
		while (true) {
			if (!_v0.$) {
				switch (_v0.a.$) {
					case 2:
						var _v1 = _v0.a;
						var error = _v1.b;
						return $elm$core$Result$Err(error);
					case 3:
						var _v2 = _v0.a;
						return $elm$core$Result$Ok($elm$core$Maybe$Nothing);
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return $elm$core$Result$Ok($elm$core$Maybe$Nothing);
	});
var $author$project$App$Service$ApplicationResources$getCompleteSectionFromTemplateAndCategoryResponse = F3(
	function (templateId, category, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			A2($author$project$App$Service$ApplicationResources$methodCompleteSectionFromTemplateAndCategory, templateId, category),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 26) && (_v0.a.c.$ === 15)) {
						var _v1 = _v0.a;
						var _v2 = _v1.c;
						var fullSectionList = _v2.b;
						return $author$project$App$Service$Loadable$Got(fullSectionList);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Service$ApplicationResources$getContactFromOwnerAndKind = F3(
	function (id, kind, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getResource,
			A2($author$project$App$Service$ApplicationResources$pathContactFromOwnerAndKind, id, kind),
			api);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 3:
					if ((_v0.a.a.$ === 9) && (_v0.a.c.$ === 8)) {
						var _v1 = _v0.a;
						var _v2 = _v1.a;
						var contact = _v1.c.a;
						return $author$project$App$Service$Loadable$Got(contact);
					} else {
						var _v3 = _v0.a;
						return $author$project$App$Service$Loadable$Failed(
							$author$project$App$Service$ApiWebSocket$unknownError('Recurso inválido'));
					}
				case 2:
					var _v4 = _v0.a;
					var error = _v4.b;
					return $author$project$App$Service$Loadable$Failed(error);
				case 0:
					var _v5 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
				default:
					var _v6 = _v0.a;
					return $author$project$App$Service$Loadable$Loading;
			}
		} else {
			return $author$project$App$Service$Loadable$NotLoaded;
		}
	});
var $author$project$App$Service$ApplicationResources$getDocumentsFromStatement = F2(
	function (stmtId, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				$author$project$App$Service$ApplicationResources$pathDocumentFromStatement(stmtId),
				$author$project$App$Service$SpecificResources$TypeDocument,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 10)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Service$ApplicationResources$pathICDFromStatement = function (stmtId) {
	return $author$project$App$Service$Shared$pathFromRID(
		$author$project$App$Service$Shared$ridFromString(
			$author$project$App$Data$ICD$ridFromStatement(stmtId)));
};
var $author$project$App$Service$ApplicationResources$getICDFromStatement = F2(
	function (stmtId, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				$author$project$App$Service$ApplicationResources$pathICDFromStatement(stmtId),
				$author$project$App$Service$SpecificResources$TypeICD,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 23)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Service$ApplicationResources$getStatementById = F2(
	function (stmtId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getResource,
			$author$project$App$Service$ApplicationResources$pathStatementById(stmtId),
			api);
		if ((((!_v0.$) && (_v0.a.$ === 3)) && (_v0.a.a.$ === 27)) && (_v0.a.c.$ === 16)) {
			var _v1 = _v0.a;
			var _v2 = _v1.a;
			var model = _v1.c.a;
			return $elm$core$Maybe$Just(model);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Service$ApplicationResources$getStatementDepsById = F2(
	function (stmtId, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				$author$project$App$Service$ApplicationResources$pathStatementDepsById(stmtId),
				$author$project$App$Service$SpecificResources$TypeStatement,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 16)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Service$ApplicationResources$getStatementMinorDepsById = F2(
	function (stmtId, api) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A4(
				$author$project$App$Service$ApiWebSocket$getSpecificResourceCollection,
				$author$project$App$Service$ApplicationResources$pathStatementMinorDepsById(stmtId),
				$author$project$App$Service$SpecificResources$TypeStatement,
				F2(
					function (resource, list) {
						if ((!resource.$) && (resource.a.$ === 16)) {
							var model = resource.a.a;
							return A2($elm$core$List$cons, model, list);
						} else {
							return list;
						}
					}),
				api));
	});
var $author$project$App$Service$ApplicationResources$getUserSignInResponse = function (api) {
	var _v0 = A2($author$project$App$Service$ApiWebSocket$getMethodResponse, $author$project$App$Service$ApplicationResources$methodUserSignIn, api);
	_v0$2:
	while (true) {
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 2:
					var _v1 = _v0.a;
					var error = _v1.b;
					return $elm$core$Maybe$Just(
						$elm$core$Result$Err(error));
				case 3:
					if (_v0.a.c.$ === 2) {
						var _v2 = _v0.a;
						var _v3 = _v2.c;
						var tokenValue = _v3.b;
						var _v4 = A2($elm$json$Json$Decode$decodeValue, $author$project$App$Data$Token$decoder, tokenValue);
						if (!_v4.$) {
							var token = _v4.a;
							return $elm$core$Maybe$Just(
								$elm$core$Result$Ok(token));
						} else {
							var error = _v4.a;
							return $elm$core$Maybe$Just(
								$elm$core$Result$Err(
									A2($author$project$App$Service$ApiWebSocket$decodeError, error, tokenValue)));
						}
					} else {
						break _v0$2;
					}
				default:
					break _v0$2;
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $author$project$App$SharedState$hasAsset = F2(
	function (asset, assets) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (a, t) {
					return t || _Utils_eq(a, asset);
				}),
			false,
			assets);
	});
var $author$project$App$Page$Menu$AdminFinanceSales = 0;
var $author$project$App$Page$Menu$AdminListFinished = 2;
var $author$project$App$Page$Menu$AdminListReevaluation = 3;
var $author$project$App$Page$Menu$AdminListUsers = 1;
var $author$project$App$Page$Menu$AdminMenu = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Menu$CompanyListCanceled = 1;
var $author$project$App$Page$Menu$CompanyListConcluded = 2;
var $author$project$App$Page$Menu$CompanyListPending = 0;
var $author$project$App$Page$Menu$CompanyListReevaluation = 3;
var $author$project$App$Page$Menu$CompanyMenu = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Menu$DoctorListFinished = 3;
var $author$project$App$Page$Menu$DoctorListPending = 1;
var $author$project$App$Page$Menu$DoctorListReevaluation = 2;
var $author$project$App$Page$Menu$DoctorMenu = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Menu$DoctorSales = 4;
var $author$project$App$Page$Menu$DoctorWaitingLine = 0;
var $author$project$App$Page$Menu$NoMenu = {$: 0};
var $author$project$App$Page$Menu$ReviewListApproved = 2;
var $author$project$App$Page$Menu$ReviewListForReview = 0;
var $author$project$App$Page$Menu$ReviewListIncomplete = 1;
var $author$project$App$Page$Menu$ReviewListReevaluation = 3;
var $author$project$App$Page$Menu$ReviewMenu = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Menu$menuFromRoute = function (route) {
	_v0$30:
	while (true) {
		if (!route.$) {
			switch (route.a.$) {
				case 1:
					switch (route.a.a.$) {
						case 1:
							var _v1 = route.a.a;
							return $author$project$App$Page$Menu$DoctorMenu(0);
						case 2:
							var _v2 = route.a.a;
							return $author$project$App$Page$Menu$DoctorMenu(0);
						case 3:
							return $author$project$App$Page$Menu$DoctorMenu(0);
						case 4:
							return $author$project$App$Page$Menu$DoctorMenu(1);
						case 5:
							return $author$project$App$Page$Menu$DoctorMenu(1);
						case 6:
							return $author$project$App$Page$Menu$DoctorMenu(1);
						case 7:
							if (!route.a.a.a.$) {
								var filter = route.a.a.a.a;
								return $author$project$App$Page$Menu$DoctorMenu(3);
							} else {
								var _v3 = route.a.a.a;
								var filter = _v3.b;
								return $author$project$App$Page$Menu$DoctorMenu(3);
							}
						case 8:
							var _v4 = route.a.a;
							return $author$project$App$Page$Menu$DoctorMenu(2);
						case 9:
							return $author$project$App$Page$Menu$DoctorMenu(2);
						case 10:
							var _v5 = route.a.a.a;
							return $author$project$App$Page$Menu$DoctorMenu(4);
						default:
							break _v0$30;
					}
				case 2:
					switch (route.a.a.$) {
						case 1:
							if (!route.a.a.a.$) {
								var filter = route.a.a.a.a;
								return $author$project$App$Page$Menu$CompanyMenu(0);
							} else {
								var _v6 = route.a.a.a;
								var filter = _v6.b;
								return $author$project$App$Page$Menu$CompanyMenu(0);
							}
						case 2:
							if (!route.a.a.a.$) {
								var filter = route.a.a.a.a;
								return $author$project$App$Page$Menu$CompanyMenu(1);
							} else {
								var _v7 = route.a.a.a;
								var filter = _v7.b;
								return $author$project$App$Page$Menu$CompanyMenu(1);
							}
						case 3:
							if (!route.a.a.a.$) {
								var filter = route.a.a.a.a;
								return $author$project$App$Page$Menu$CompanyMenu(2);
							} else {
								var _v8 = route.a.a.a;
								var filter = _v8.b;
								return $author$project$App$Page$Menu$CompanyMenu(2);
							}
						case 5:
							var _v9 = route.a.a;
							return $author$project$App$Page$Menu$CompanyMenu(3);
						default:
							break _v0$30;
					}
				case 3:
					switch (route.a.a.$) {
						case 0:
							if (!route.a.a.a.$) {
								var filter = route.a.a.a.a;
								return $author$project$App$Page$Menu$ReviewMenu(0);
							} else {
								var _v10 = route.a.a.a;
								var filter = _v10.b;
								return $author$project$App$Page$Menu$ReviewMenu(0);
							}
						case 1:
							if (!route.a.a.a.$) {
								var filter = route.a.a.a.a;
								return $author$project$App$Page$Menu$ReviewMenu(1);
							} else {
								var _v11 = route.a.a.a;
								var filter = _v11.b;
								return $author$project$App$Page$Menu$ReviewMenu(1);
							}
						case 2:
							if (!route.a.a.a.$) {
								var filter = route.a.a.a.a;
								return $author$project$App$Page$Menu$ReviewMenu(2);
							} else {
								var _v12 = route.a.a.a;
								var filter = _v12.b;
								return $author$project$App$Page$Menu$ReviewMenu(2);
							}
						case 5:
							var _v13 = route.a.a;
							return $author$project$App$Page$Menu$ReviewMenu(3);
						default:
							break _v0$30;
					}
				case 5:
					switch (route.a.a.$) {
						case 2:
							var _v14 = route.a.a.a;
							return $author$project$App$Page$Menu$AdminMenu(0);
						case 0:
							var _v15 = route.a.a.a;
							return $author$project$App$Page$Menu$AdminMenu(1);
						case 4:
							if (!route.a.a.a.$) {
								var filter = route.a.a.a.a;
								return $author$project$App$Page$Menu$AdminMenu(2);
							} else {
								var _v16 = route.a.a.a;
								var filter = _v16.b;
								return $author$project$App$Page$Menu$AdminMenu(2);
							}
						case 5:
							var _v17 = route.a.a;
							return $author$project$App$Page$Menu$AdminMenu(3);
						default:
							break _v0$30;
					}
				default:
					break _v0$30;
			}
		} else {
			break _v0$30;
		}
	}
	return $author$project$App$Page$Menu$NoMenu;
};
var $author$project$App$Page$Menu$init = F2(
	function (token, route) {
		var current = $author$project$App$Page$Menu$menuFromRoute(route);
		return {ec: current, fN: token.fN};
	});
var $author$project$App$SharedState$initializeMenus = F3(
	function (token, route, model) {
		return _Utils_update(
			model,
			{
				ba: $elm$core$Maybe$Just(
					A2(
						$author$project$App$Page$Menu$init,
						token,
						$author$project$App$Route$Private(route)))
			});
	});
var $author$project$App$Route$validatePrivateRoute = F2(
	function (profiles, route) {
		return function (l) {
			return $elm$core$List$isEmpty(l) ? $author$project$App$Route$AccessRestricted : route;
		}(
			A2(
				$elm$core$List$filter,
				function (p) {
					var _v0 = _Utils_Tuple2(p.gu, route);
					switch (_v0.a) {
						case 0:
							if (_v0.b.$ === 1) {
								var _v1 = _v0.a;
								return true;
							} else {
								var _v2 = _v0.a;
								return false;
							}
						case 1:
							if (_v0.b.$ === 2) {
								var _v3 = _v0.a;
								return true;
							} else {
								var _v4 = _v0.a;
								return false;
							}
						case 2:
							if (_v0.b.$ === 3) {
								var _v5 = _v0.a;
								return true;
							} else {
								var _v6 = _v0.a;
								return false;
							}
						case 3:
							if (_v0.b.$ === 4) {
								var _v7 = _v0.a;
								return true;
							} else {
								var _v8 = _v0.a;
								return false;
							}
						case 5:
							if (_v0.b.$ === 5) {
								var _v9 = _v0.a;
								return true;
							} else {
								var _v10 = _v0.a;
								return false;
							}
						case 4:
							var _v11 = _v0.a;
							return false;
						default:
							var _v12 = _v0.a;
							return false;
					}
				},
				profiles));
	});
var $author$project$App$SharedState$updateResponseInternal = F2(
	function (respMsg, model) {
		var cache = model.ia;
		var fullStmt = cache.h8;
		var bearer = cache.h8.g;
		switch (respMsg.$) {
			case 15:
				var _v1 = _Utils_Tuple2(
					$author$project$App$Service$ApplicationResources$getUserSignInResponse(model._),
					model.eP);
				if (!_v1.a.$) {
					if (!_v1.a.a.$) {
						if (!_v1.b.$) {
							var token = _v1.a.a.a;
							var privateRoute = _v1.b.a;
							return _Utils_Tuple3(
								A3($author$project$App$SharedState$initializeMenus, token, privateRoute, model),
								A2(
									$author$project$App$Route$pushUrl,
									model.fC,
									$author$project$App$Route$Private(
										A2($author$project$App$Route$validatePrivateRoute, token.fN, privateRoute))),
								_List_Nil);
						} else {
							var token = _v1.a.a.a;
							var defaultRoute = $author$project$App$Route$defaultProfileRoute(
								A2(
									$elm$core$Maybe$withDefault,
									$author$project$App$Data$Profile$emptyModel,
									$elm$core$List$head(token.fN)));
							return _Utils_Tuple3(
								A3($author$project$App$SharedState$initializeMenus, token, defaultRoute, model),
								A2(
									$author$project$App$Route$pushUrl,
									model.fC,
									$author$project$App$Route$Private(defaultRoute)),
								_List_Nil);
						}
					} else {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
					}
				} else {
					var _v2 = _v1.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
			case 16:
				var mToken = $author$project$App$Service$ApplicationResources$getAuthToken(model._);
				var _v3 = function () {
					var _v4 = _Utils_Tuple2(mToken, model.eP);
					switch (_v4.b.$) {
						case 0:
							if (!_v4.a.$) {
								var token = _v4.a.a;
								var route = _v4.b.a;
								return _Utils_Tuple2(
									$elm$core$Maybe$Just(
										A2(
											$author$project$App$Page$Menu$init,
											token,
											$author$project$App$Route$Private(route))),
									A2(
										$author$project$App$Route$pushUrl,
										model.fC,
										$author$project$App$Route$Private(
											A2($author$project$App$Route$validatePrivateRoute, token.fN, route))));
							} else {
								var _v6 = _v4.a;
								return _Utils_Tuple2(
									$elm$core$Maybe$Nothing,
									A2(
										$author$project$App$Route$pushUrl,
										model.fC,
										$author$project$App$Route$Public($author$project$App$Route$Login)));
							}
						case 2:
							var _v5 = _v4.b;
							var stmtId = _v5.a;
							var proponentRoute = _v5.b;
							return _Utils_Tuple2(
								$elm$core$Maybe$Nothing,
								A2(
									$author$project$App$Route$replaceUrl,
									model.fC,
									A2($author$project$App$Route$Proponent, stmtId, proponentRoute)));
						case 3:
							var clientRoute = _v4.b.a;
							return _Utils_Tuple2(
								$elm$core$Maybe$Nothing,
								A2(
									$author$project$App$Route$replaceUrl,
									model.fC,
									$author$project$App$Route$Client(clientRoute)));
						default:
							var publicRoute = _v4.b.a;
							return _Utils_Tuple2(
								$elm$core$Maybe$Nothing,
								A2(
									$author$project$App$Route$replaceUrl,
									model.fC,
									$author$project$App$Route$Public(publicRoute)));
					}
				}();
				var menu = _v3.a;
				var routeCmd = _v3.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ba: menu}),
					routeCmd,
					_List_Nil);
			case 1:
				var stmtId = respMsg.a;
				var stmt = function () {
					var _v7 = A2($author$project$App$Service$ApplicationResources$getAdvanceProponentStatementResponse, stmtId, model._);
					if (_v7.$ === 3) {
						var s = _v7.a;
						return s;
					} else {
						return cache.h8.g.e;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{
									h8: _Utils_update(
										fullStmt,
										{
											g: _Utils_update(
												bearer,
												{e: stmt})
										})
								})
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 2:
				var stmtId = respMsg.a;
				var stmt = A2(
					$elm$core$Maybe$withDefault,
					bearer.e,
					A2($author$project$App$Service$ApplicationResources$getStatementById, stmtId, model._));
				var loadingStatus = cache.i;
				var fullLoadMessages = _List_fromArray(
					[
						A2(
						$author$project$App$SharedState$LoadPersonById,
						stmt.Y,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$SharedMsg(
								$author$project$App$Page$ResponseMsgs$SharedPersonLoaded(stmt.Y))
							])),
						A2(
						$author$project$App$SharedState$LoadTemplateByIdForSharedState,
						stmt.eY,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$SharedMsg(
								$author$project$App$Page$ResponseMsgs$SharedTemplateLoaded(stmt.eY))
							])),
						A2(
						$author$project$App$SharedState$LoadCompanyById,
						stmt.gx,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$SharedMsg(
								$author$project$App$Page$ResponseMsgs$SharedCompanyLoaded(stmt.gx))
							])),
						A3(
						$author$project$App$SharedState$LoadContactFromOwner,
						stmt.Y,
						5,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$SharedMsg(
								A2($author$project$App$Page$ResponseMsgs$SharedContactLoaded, stmt.Y, 5))
							])),
						A3(
						$author$project$App$SharedState$LoadContactFromOwner,
						stmt.Y,
						1,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$SharedMsg(
								A2($author$project$App$Page$ResponseMsgs$SharedContactLoaded, stmt.Y, 1))
							]))
					]);
				var fullLoadMsgs = function () {
					var _v8 = cache.aq;
					switch (_v8.$) {
						case 0:
							return _List_Nil;
						case 1:
							var assets = _v8.a;
							return _Utils_ap(
								fullLoadMessages,
								_Utils_ap(
									_List_fromArray(
										[
											A2(
											$author$project$App$SharedState$LoadStatementDeps,
											stmtId,
											_List_fromArray(
												[
													$author$project$App$Page$ResponseMsgs$SharedMsg(
													$author$project$App$Page$ResponseMsgs$SharedStatementDepsLoaded(stmtId))
												]))
										]),
									_Utils_ap(
										A2(
											$author$project$App$SharedState$hasAsset,
											$author$project$App$SharedState$FullStmtAssetQuestions(2),
											assets) ? _List_fromArray(
											[
												A3(
												$author$project$App$SharedState$LoadCompleteQuestionSections,
												stmt.eY,
												2,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$SharedMsg(
														A2($author$project$App$Page$ResponseMsgs$SharedQuestionsLoaded, stmt.eY, 2))
													]))
											]) : _List_Nil,
										_Utils_ap(
											A2(
												$author$project$App$SharedState$hasAsset,
												$author$project$App$SharedState$FullStmtAssetQuestions(3),
												assets) ? _List_fromArray(
												[
													A3(
													$author$project$App$SharedState$LoadCompleteQuestionSections,
													stmt.eY,
													3,
													_List_fromArray(
														[
															$author$project$App$Page$ResponseMsgs$SharedMsg(
															A2($author$project$App$Page$ResponseMsgs$SharedQuestionsLoaded, stmt.eY, 3))
														]))
												]) : _List_Nil,
											_Utils_ap(
												A2($author$project$App$SharedState$hasAsset, $author$project$App$SharedState$FullStmtAssetDocuments, assets) ? _List_fromArray(
													[
														A2(
														$author$project$App$SharedState$LoadDocumentsFromStatement,
														stmtId,
														_List_fromArray(
															[
																$author$project$App$Page$ResponseMsgs$SharedMsg(
																$author$project$App$Page$ResponseMsgs$SharedStatementDocumentsLoaded(stmtId))
															]))
													]) : _List_Nil,
												_Utils_ap(
													A2(
														$author$project$App$SharedState$hasAsset,
														$author$project$App$SharedState$FullStmtAssetAnswers(2),
														assets) ? _List_fromArray(
														[
															A3(
															$author$project$App$SharedState$LoadAnswers,
															stmt.as,
															2,
															_List_fromArray(
																[
																	$author$project$App$Page$ResponseMsgs$SharedMsg(
																	A2($author$project$App$Page$ResponseMsgs$SharedAnswersLoaded, stmt.as, 2))
																]))
														]) : _List_Nil,
													_Utils_ap(
														A2(
															$author$project$App$SharedState$hasAsset,
															$author$project$App$SharedState$FullStmtAssetAnswers(3),
															assets) ? _List_fromArray(
															[
																A3(
																$author$project$App$SharedState$LoadAnswers,
																stmt.as,
																3,
																_List_fromArray(
																	[
																		$author$project$App$Page$ResponseMsgs$SharedMsg(
																		A2($author$project$App$Page$ResponseMsgs$SharedAnswersLoaded, stmt.as, 3))
																	]))
															]) : _List_Nil,
														A2($author$project$App$SharedState$hasAsset, $author$project$App$SharedState$FullStmtAssetICD, assets) ? _List_fromArray(
															[
																A2(
																$author$project$App$SharedState$GetICDs,
																stmtId,
																_List_fromArray(
																	[
																		$author$project$App$Page$ResponseMsgs$SharedMsg(
																		$author$project$App$Page$ResponseMsgs$SharedICDsLoaded(stmtId))
																	]))
															]) : _List_Nil)))))));
						case 3:
							var assets = _v8.a;
							return _Utils_ap(
								fullLoadMessages,
								_Utils_ap(
									_List_fromArray(
										[
											A2(
											$author$project$App$SharedState$LoadStatementDeps,
											stmtId,
											_List_fromArray(
												[
													$author$project$App$Page$ResponseMsgs$SharedMsg(
													$author$project$App$Page$ResponseMsgs$SharedStatementDepsLoaded(stmtId))
												]))
										]),
									_Utils_ap(
										A2(
											$author$project$App$SharedState$hasAsset,
											$author$project$App$SharedState$FullStmtAssetQuestions(1),
											assets) ? _List_fromArray(
											[
												A3(
												$author$project$App$SharedState$LoadCompleteQuestionSections,
												stmt.eY,
												1,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$SharedMsg(
														A2($author$project$App$Page$ResponseMsgs$SharedQuestionsLoaded, stmt.eY, 1))
													]))
											]) : _List_Nil,
										_Utils_ap(
											A2($author$project$App$SharedState$hasAsset, $author$project$App$SharedState$FullStmtAssetDocuments, assets) ? _List_fromArray(
												[
													A2(
													$author$project$App$SharedState$LoadDocumentsFromStatement,
													stmtId,
													_List_fromArray(
														[
															$author$project$App$Page$ResponseMsgs$SharedMsg(
															$author$project$App$Page$ResponseMsgs$SharedStatementDocumentsLoaded(stmtId))
														]))
												]) : _List_Nil,
											A2(
												$author$project$App$SharedState$hasAsset,
												$author$project$App$SharedState$FullStmtAssetAnswers(1),
												assets) ? _List_fromArray(
												[
													A3(
													$author$project$App$SharedState$LoadAnswers,
													stmt.as,
													1,
													_List_fromArray(
														[
															$author$project$App$Page$ResponseMsgs$SharedMsg(
															A2($author$project$App$Page$ResponseMsgs$SharedAnswersLoaded, stmt.as, 1))
														]))
												]) : _List_Nil))));
						default:
							var assets = _v8.a;
							return _Utils_ap(
								fullLoadMessages,
								_Utils_ap(
									_List_fromArray(
										[
											A2(
											$author$project$App$SharedState$LoadStatementMinorDeps,
											stmtId,
											_List_fromArray(
												[
													$author$project$App$Page$ResponseMsgs$SharedMsg(
													$author$project$App$Page$ResponseMsgs$SharedStatementMinorDepsLoaded(stmtId))
												]))
										]),
									_Utils_ap(
										A2(
											$author$project$App$SharedState$hasAsset,
											$author$project$App$SharedState$FullStmtAssetQuestions(2),
											assets) ? _List_fromArray(
											[
												A3(
												$author$project$App$SharedState$LoadCompleteQuestionSections,
												stmt.eY,
												2,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$SharedMsg(
														A2($author$project$App$Page$ResponseMsgs$SharedQuestionsLoaded, stmt.eY, 2))
													]))
											]) : _List_Nil,
										_Utils_ap(
											A2(
												$author$project$App$SharedState$hasAsset,
												$author$project$App$SharedState$FullStmtAssetQuestions(3),
												assets) ? _List_fromArray(
												[
													A3(
													$author$project$App$SharedState$LoadCompleteQuestionSections,
													stmt.eY,
													3,
													_List_fromArray(
														[
															$author$project$App$Page$ResponseMsgs$SharedMsg(
															A2($author$project$App$Page$ResponseMsgs$SharedQuestionsLoaded, stmt.eY, 3))
														]))
												]) : _List_Nil,
											_Utils_ap(
												A2(
													$author$project$App$SharedState$hasAsset,
													$author$project$App$SharedState$FullStmtAssetQuestions(1),
													assets) ? _List_fromArray(
													[
														A3(
														$author$project$App$SharedState$LoadCompleteQuestionSections,
														stmt.eY,
														1,
														_List_fromArray(
															[
																$author$project$App$Page$ResponseMsgs$SharedMsg(
																A2($author$project$App$Page$ResponseMsgs$SharedQuestionsLoaded, stmt.eY, 1))
															]))
													]) : _List_Nil,
												_Utils_ap(
													A2($author$project$App$SharedState$hasAsset, $author$project$App$SharedState$FullStmtAssetDocuments, assets) ? _List_fromArray(
														[
															A2(
															$author$project$App$SharedState$LoadDocumentsFromStatement,
															stmtId,
															_List_fromArray(
																[
																	$author$project$App$Page$ResponseMsgs$SharedMsg(
																	$author$project$App$Page$ResponseMsgs$SharedStatementDocumentsLoaded(stmtId))
																]))
														]) : _List_Nil,
													_Utils_ap(
														A2(
															$author$project$App$SharedState$hasAsset,
															$author$project$App$SharedState$FullStmtAssetAnswers(2),
															assets) ? _List_fromArray(
															[
																A3(
																$author$project$App$SharedState$LoadAnswers,
																stmt.as,
																2,
																_List_fromArray(
																	[
																		$author$project$App$Page$ResponseMsgs$SharedMsg(
																		A2($author$project$App$Page$ResponseMsgs$SharedAnswersLoaded, stmt.as, 2))
																	]))
															]) : _List_Nil,
														_Utils_ap(
															A2(
																$author$project$App$SharedState$hasAsset,
																$author$project$App$SharedState$FullStmtAssetAnswers(3),
																assets) ? _List_fromArray(
																[
																	A3(
																	$author$project$App$SharedState$LoadAnswers,
																	stmt.as,
																	3,
																	_List_fromArray(
																		[
																			$author$project$App$Page$ResponseMsgs$SharedMsg(
																			A2($author$project$App$Page$ResponseMsgs$SharedAnswersLoaded, stmt.as, 3))
																		]))
																]) : _List_Nil,
															_Utils_ap(
																A2(
																	$author$project$App$SharedState$hasAsset,
																	$author$project$App$SharedState$FullStmtAssetAnswers(1),
																	assets) ? _List_fromArray(
																	[
																		A3(
																		$author$project$App$SharedState$LoadAnswers,
																		stmt.as,
																		1,
																		_List_fromArray(
																			[
																				$author$project$App$Page$ResponseMsgs$SharedMsg(
																				A2($author$project$App$Page$ResponseMsgs$SharedAnswersLoaded, stmt.as, 1))
																			]))
																	]) : _List_Nil,
																A2($author$project$App$SharedState$hasAsset, $author$project$App$SharedState$FullStmtAssetICD, assets) ? _List_fromArray(
																	[
																		A2(
																		$author$project$App$SharedState$GetICDs,
																		stmtId,
																		_List_fromArray(
																			[
																				$author$project$App$Page$ResponseMsgs$SharedMsg(
																				$author$project$App$Page$ResponseMsgs$SharedICDsLoaded(stmtId))
																			]))
																	]) : _List_Nil)))))))));
					}
				}();
				var bearerLoading = loadingStatus.g;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{
									iP: 0,
									i: _Utils_update(
										loadingStatus,
										{
											g: _Utils_update(
												bearerLoading,
												{e: true}),
											aG: false
										}),
									h8: _Utils_update(
										fullStmt,
										{
											g: _Utils_update(
												bearer,
												{e: stmt})
										})
								})
						}),
					$elm$core$Platform$Cmd$none,
					loadingStatus.aG ? _List_Nil : A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, fullLoadMsgs));
			case 0:
				var stmtId = respMsg.a;
				var loadingStatus = cache.i;
				var docs = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, d) {
								return _Utils_Tuple2(i, d);
							}),
						A2($author$project$App$Service$ApplicationResources$getDocumentsFromStatement, stmtId, model._)));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{
									i: _Utils_update(
										loadingStatus,
										{an: true}),
									h8: _Utils_update(
										fullStmt,
										{an: docs})
								})
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 17:
				var stmtId = respMsg.a;
				var newDeps = A2(
					$elm$core$Dict$map,
					F2(
						function (key, s) {
							var newIcds = _Utils_eq(s.e.as, stmtId) ? $author$project$App$Data$ICD$dictFromList(
								A2(
									$elm$core$List$filter,
									function (icd) {
										return _Utils_eq(icd.aV, s.e.as);
									},
									A2($author$project$App$Service$ApplicationResources$getICDFromStatement, stmtId, model._))) : s.fx;
							return _Utils_update(
								s,
								{fx: newIcds});
						}),
					model.ia.h8.fh);
				var bearerIcds = _Utils_eq(model.ia.h8.g.e.as, stmtId) ? $author$project$App$Data$ICD$dictFromList(
					A2(
						$elm$core$List$filter,
						function (icd) {
							return _Utils_eq(icd.aV, stmtId);
						},
						A2($author$project$App$Service$ApplicationResources$getICDFromStatement, stmtId, model._))) : model.ia.h8.g.fx;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{
									h8: _Utils_update(
										fullStmt,
										{
											g: _Utils_update(
												bearer,
												{fx: bearerIcds}),
											fh: newDeps
										})
								})
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 10:
				var stmtId = respMsg.a;
				var category = respMsg.b;
				var newDeps = A2(
					$elm$core$Dict$map,
					F2(
						function (key, s) {
							var newAnswers = A6(
								$elm$core$Dict$merge,
								$elm$core$Dict$insert,
								F4(
									function (k, _v12, a, d) {
										return A3($elm$core$Dict$insert, k, a, d);
									}),
								$elm$core$Dict$insert,
								$elm$core$Dict$empty,
								s.gw,
								$elm$core$Dict$fromList(
									A2(
										$elm$core$List$map,
										function (ans) {
											return _Utils_Tuple2(ans.aY, ans);
										},
										A2(
											$elm$core$List$filter,
											function (ans) {
												return _Utils_eq(ans.aV, s.e.as);
											},
											A2(
												$elm$core$List$filter,
												function (ans) {
													return ans.a0 === 1;
												},
												A3($author$project$App$Service$Answer$getAnswersFromStatementAndCategory, stmtId, category, model._))))));
							return _Utils_update(
								s,
								{gw: newAnswers});
						}),
					model.ia.h8.fh);
				var loadingStatus = cache.i;
				var doctorAnswers = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$map,
						function (ans) {
							return _Utils_Tuple2(ans.aY, ans);
						},
						A2(
							$elm$core$List$filter,
							function (ans) {
								return ans.a0 === 3;
							},
							A3($author$project$App$Service$Answer$getAnswersFromStatementAndCategory, stmtId, 3, model._))));
				var companyAnswers = A6(
					$elm$core$Dict$merge,
					$elm$core$Dict$insert,
					F4(
						function (k, _v11, a, d) {
							return A3($elm$core$Dict$insert, k, a, d);
						}),
					$elm$core$Dict$insert,
					$elm$core$Dict$empty,
					bearer.gw,
					$elm$core$Dict$fromList(
						A2(
							$elm$core$List$map,
							function (ans) {
								return _Utils_Tuple2(ans.aY, ans);
							},
							A2(
								$elm$core$List$filter,
								function (ans) {
									return _Utils_eq(ans.aV, model.ia.h8.g.e.as);
								},
								A2(
									$elm$core$List$filter,
									function (ans) {
										return ans.a0 === 1;
									},
									A3($author$project$App$Service$Answer$getAnswersFromStatementAndCategory, stmtId, category, model._))))));
				var bearerLoading = loadingStatus.g;
				var loadingValue = function () {
					switch (category) {
						case 2:
							return _Utils_update(
								bearerLoading,
								{cv: true});
						case 3:
							return _Utils_update(
								bearerLoading,
								{gE: true});
						case 1:
							return _Utils_update(
								bearerLoading,
								{gw: true});
						default:
							return bearerLoading;
					}
				}();
				var answersDict = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$map,
						function (ans) {
							return _Utils_Tuple2(ans.aY, ans);
						},
						A3($author$project$App$Service$Answer$getAnswersFromStatementAndCategory, stmtId, category, model._)));
				var answers = A6(
					$elm$core$Dict$merge,
					$elm$core$Dict$insert,
					F4(
						function (k, a, _v9, d) {
							return A3($elm$core$Dict$insert, k, a, d);
						}),
					$elm$core$Dict$insert,
					answersDict,
					bearer.aZ,
					$elm$core$Dict$empty);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{
									i: _Utils_update(
										loadingStatus,
										{g: loadingValue}),
									h8: _Utils_update(
										fullStmt,
										{
											g: _Utils_update(
												bearer,
												{aZ: answers, gw: companyAnswers, gE: doctorAnswers}),
											fh: newDeps
										})
								})
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 11:
				var stmtId = respMsg.a;
				var category = respMsg.b;
				var idx = respMsg.c;
				var loadingStatus = cache.i;
				var doctorAnswers = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$map,
						function (ans) {
							return _Utils_Tuple2(ans.aY, ans);
						},
						A2(
							$elm$core$List$filter,
							function (ans) {
								return ans.a0 === 3;
							},
							A3($author$project$App$Service$Answer$getAnswersFromStatementAndCategory, stmtId, category, model._))));
				var depsLoading = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$SharedState$emptyFullLoadingStatus,
					A2(
						$elm$core$Maybe$map,
						function (d) {
							switch (category) {
								case 2:
									return _Utils_update(
										d,
										{cv: true});
								case 3:
									return _Utils_update(
										d,
										{gE: true});
								case 1:
									return _Utils_update(
										d,
										{gw: true});
								default:
									return d;
							}
						},
						A2($elm$core$Dict$get, idx, loadingStatus.fh)));
				var dep = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, fullStmt.fh));
				var companyAnswers = A6(
					$elm$core$Dict$merge,
					$elm$core$Dict$insert,
					F4(
						function (k, _v14, a, d) {
							return A3($elm$core$Dict$insert, k, a, d);
						}),
					$elm$core$Dict$insert,
					$elm$core$Dict$empty,
					dep.gw,
					$elm$core$Dict$fromList(
						A2(
							$elm$core$List$map,
							function (ans) {
								return _Utils_Tuple2(ans.aY, ans);
							},
							A2(
								$elm$core$List$filter,
								function (ans) {
									return _Utils_eq(ans.aV, dep.e.as);
								},
								A2(
									$elm$core$List$filter,
									function (ans) {
										return ans.a0 === 1;
									},
									A3($author$project$App$Service$Answer$getAnswersFromStatementAndCategory, stmtId, category, model._))))));
				var answersDict = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$map,
						function (ans) {
							return _Utils_Tuple2(ans.aY, ans);
						},
						A3($author$project$App$Service$Answer$getAnswersFromStatementAndCategory, stmtId, category, model._)));
				var answers = A6(
					$elm$core$Dict$merge,
					$elm$core$Dict$insert,
					F4(
						function (k, a, _v13, d) {
							return A3($elm$core$Dict$insert, k, a, d);
						}),
					$elm$core$Dict$insert,
					answersDict,
					dep.aZ,
					$elm$core$Dict$empty);
				var nDep = _Utils_update(
					dep,
					{aZ: answers, gw: companyAnswers, gE: doctorAnswers});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{
									i: _Utils_update(
										loadingStatus,
										{
											fh: A3($elm$core$Dict$insert, idx, depsLoading, loadingStatus.fh)
										}),
									h8: _Utils_update(
										fullStmt,
										{
											fh: A3($elm$core$Dict$insert, idx, nDep, fullStmt.fh)
										})
								})
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 12:
				var stmtId = respMsg.a;
				var category = respMsg.b;
				var loadingStatus = cache.i;
				var loadingValue = function () {
					switch (category) {
						case 2:
							return _Utils_update(
								loadingStatus,
								{eG: true});
						case 3:
							return _Utils_update(
								loadingStatus,
								{eh: true});
						case 1:
							return _Utils_update(
								loadingStatus,
								{d6: true});
						default:
							return loadingStatus;
					}
				}();
				var fullStmtWithSections = function () {
					var _v16 = _Utils_Tuple2(
						category,
						A3($author$project$App$Service$ApplicationResources$getCompleteSectionFromTemplateAndCategoryResponse, stmtId, category, model._));
					_v16$3:
					while (true) {
						if (_v16.b.$ === 3) {
							switch (_v16.a) {
								case 2:
									var _v17 = _v16.a;
									var sections = _v16.b.a;
									return _Utils_update(
										fullStmt,
										{jM: sections});
								case 3:
									var _v18 = _v16.a;
									var sections = _v16.b.a;
									return _Utils_update(
										fullStmt,
										{gH: sections});
								case 1:
									var _v19 = _v16.a;
									var sections = _v16.b.a;
									return _Utils_update(
										fullStmt,
										{bs: sections});
								default:
									break _v16$3;
							}
						} else {
							break _v16$3;
						}
					}
					return fullStmt;
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{i: loadingValue, h8: fullStmtWithSections})
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 3:
				var stmtId = respMsg.a;
				var loadingStatus = cache.i;
				var deps = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, d) {
								return _Utils_Tuple2(
									i,
									_Utils_update(
										$author$project$App$Data$FullStatement$emptyEditStatement,
										{e: d}));
							}),
						A2($author$project$App$Service$ApplicationResources$getStatementDepsById, stmtId, model._)));
				var depsLoading = A3(
					$elm$core$Dict$foldl,
					F3(
						function (k, _v22, loadingDict) {
							return A3(
								$elm$core$Dict$insert,
								k,
								_Utils_update(
									$author$project$App$SharedState$emptyFullLoadingStatus,
									{e: true}),
								loadingDict);
						}),
					$elm$core$Dict$empty,
					deps);
				var depModel = A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(0, $author$project$App$Data$FullStatement$emptyEditStatement),
					$elm$core$List$head(
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, d) {
									return _Utils_Tuple2(
										i,
										_Utils_update(
											$author$project$App$Data$FullStatement$emptyEditStatement,
											{e: d}));
								}),
							A2($author$project$App$Service$ApplicationResources$getStatementDepsById, stmtId, model._)))).b;
				var stmtTemplateId = depModel.e.eY;
				var fullLoadMessages = function () {
					var _v21 = cache.aq;
					switch (_v21.$) {
						case 0:
							return _List_Nil;
						case 3:
							return A3(
								$elm$core$Dict$foldl,
								F3(
									function (k, d, msgs) {
										return _Utils_ap(
											_List_fromArray(
												[
													A2(
													$author$project$App$SharedState$LoadPersonById,
													d.e.Y,
													_List_fromArray(
														[
															$author$project$App$Page$ResponseMsgs$SharedMsg(
															A2($author$project$App$Page$ResponseMsgs$SharedDepPersonLoaded, d.e.Y, k))
														])),
													A3(
													$author$project$App$SharedState$LoadCompleteQuestionSections,
													stmtTemplateId,
													1,
													_List_fromArray(
														[
															$author$project$App$Page$ResponseMsgs$SharedMsg(
															A2($author$project$App$Page$ResponseMsgs$SharedQuestionsLoaded, stmtTemplateId, 1))
														])),
													A3(
													$author$project$App$SharedState$LoadAnswers,
													d.e.as,
													1,
													_List_fromArray(
														[
															$author$project$App$Page$ResponseMsgs$SharedMsg(
															A3($author$project$App$Page$ResponseMsgs$SharedDepAnswersLoaded, d.e.as, 1, k))
														])),
													A3(
													$author$project$App$SharedState$LoadContactFromOwner,
													d.e.Y,
													5,
													_List_fromArray(
														[
															$author$project$App$Page$ResponseMsgs$SharedMsg(
															A3($author$project$App$Page$ResponseMsgs$SharedDepContactLoaded, d.e.Y, 5, k))
														])),
													A3(
													$author$project$App$SharedState$LoadContactFromOwner,
													d.e.Y,
													1,
													_List_fromArray(
														[
															$author$project$App$Page$ResponseMsgs$SharedMsg(
															A3($author$project$App$Page$ResponseMsgs$SharedDepContactLoaded, d.e.Y, 1, k))
														]))
												]),
											msgs);
									}),
								_List_Nil,
								deps);
						default:
							return A3(
								$elm$core$Dict$foldl,
								F3(
									function (k, d, msgs) {
										return _Utils_ap(
											_List_fromArray(
												[
													A2(
													$author$project$App$SharedState$LoadPersonById,
													d.e.Y,
													_List_fromArray(
														[
															$author$project$App$Page$ResponseMsgs$SharedMsg(
															A2($author$project$App$Page$ResponseMsgs$SharedDepPersonLoaded, d.e.Y, k))
														])),
													A3(
													$author$project$App$SharedState$LoadAnswers,
													d.e.as,
													2,
													_List_fromArray(
														[
															$author$project$App$Page$ResponseMsgs$SharedMsg(
															A3($author$project$App$Page$ResponseMsgs$SharedDepAnswersLoaded, d.e.as, 2, k))
														])),
													A3(
													$author$project$App$SharedState$LoadAnswers,
													d.e.as,
													3,
													_List_fromArray(
														[
															$author$project$App$Page$ResponseMsgs$SharedMsg(
															A3($author$project$App$Page$ResponseMsgs$SharedDepAnswersLoaded, d.e.as, 3, k))
														])),
													A3(
													$author$project$App$SharedState$LoadContactFromOwner,
													d.e.Y,
													5,
													_List_fromArray(
														[
															$author$project$App$Page$ResponseMsgs$SharedMsg(
															A3($author$project$App$Page$ResponseMsgs$SharedDepContactLoaded, d.e.Y, 5, k))
														])),
													A3(
													$author$project$App$SharedState$LoadContactFromOwner,
													d.e.Y,
													1,
													_List_fromArray(
														[
															$author$project$App$Page$ResponseMsgs$SharedMsg(
															A3($author$project$App$Page$ResponseMsgs$SharedDepContactLoaded, d.e.Y, 1, k))
														]))
												]),
											msgs);
									}),
								_List_Nil,
								deps);
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{
									i: _Utils_update(
										loadingStatus,
										{
											fh: depsLoading,
											dd: $elm$core$Maybe$Just(
												$elm$core$Dict$size(deps) > 0)
										}),
									h8: _Utils_update(
										fullStmt,
										{fh: deps})
								})
						}),
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, fullLoadMessages));
			case 4:
				var stmtId = respMsg.a;
				var loadingStatus = cache.i;
				var deps = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, d) {
								return _Utils_Tuple2(
									i,
									_Utils_update(
										$author$project$App$Data$FullStatement$emptyEditStatement,
										{e: d}));
							}),
						A2($author$project$App$Service$ApplicationResources$getStatementMinorDepsById, stmtId, model._)));
				var depsLoading = A3(
					$elm$core$Dict$foldl,
					F3(
						function (k, _v24, loadingDict) {
							return A3(
								$elm$core$Dict$insert,
								k,
								_Utils_update(
									$author$project$App$SharedState$emptyFullLoadingStatus,
									{e: true}),
								loadingDict);
						}),
					$elm$core$Dict$empty,
					deps);
				var fullLoadMessages = function () {
					var _v23 = cache.aq;
					if (!_v23.$) {
						return _List_Nil;
					} else {
						return A3(
							$elm$core$Dict$foldl,
							F3(
								function (k, d, msgs) {
									return _Utils_ap(
										_List_fromArray(
											[
												A2(
												$author$project$App$SharedState$LoadPersonById,
												d.e.Y,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$SharedMsg(
														A2($author$project$App$Page$ResponseMsgs$SharedDepPersonLoaded, d.e.Y, k))
													])),
												A3(
												$author$project$App$SharedState$LoadAnswers,
												d.e.as,
												2,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$SharedMsg(
														A3($author$project$App$Page$ResponseMsgs$SharedDepAnswersLoaded, d.e.as, 2, k))
													])),
												A3(
												$author$project$App$SharedState$LoadAnswers,
												d.e.as,
												3,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$SharedMsg(
														A3($author$project$App$Page$ResponseMsgs$SharedDepAnswersLoaded, d.e.as, 3, k))
													])),
												A3(
												$author$project$App$SharedState$LoadAnswers,
												d.e.as,
												1,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$SharedMsg(
														A3($author$project$App$Page$ResponseMsgs$SharedDepAnswersLoaded, d.e.as, 1, k))
													])),
												A3(
												$author$project$App$SharedState$LoadContactFromOwner,
												d.e.Y,
												5,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$SharedMsg(
														A3($author$project$App$Page$ResponseMsgs$SharedDepContactLoaded, d.e.Y, 5, k))
													])),
												A3(
												$author$project$App$SharedState$LoadContactFromOwner,
												d.e.Y,
												1,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$SharedMsg(
														A3($author$project$App$Page$ResponseMsgs$SharedDepContactLoaded, d.e.Y, 1, k))
													])),
												A2(
												$author$project$App$SharedState$GetICDs,
												d.e.as,
												_List_fromArray(
													[
														$author$project$App$Page$ResponseMsgs$SharedMsg(
														$author$project$App$Page$ResponseMsgs$SharedICDsLoaded(d.e.as))
													]))
											]),
										msgs);
								}),
							_List_Nil,
							deps);
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{
									i: _Utils_update(
										loadingStatus,
										{
											fh: depsLoading,
											dd: $elm$core$Maybe$Just(
												$elm$core$Dict$size(deps) > 0)
										}),
									h8: _Utils_update(
										fullStmt,
										{fh: deps})
								})
						}),
					$elm$core$Platform$Cmd$none,
					A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, fullLoadMessages));
			case 8:
				var personId = respMsg.a;
				var person = function () {
					var _v25 = A2($author$project$App$Service$ApplicationResources$getPersonById, personId, model._);
					if (_v25.$ === 3) {
						var p = _v25.a;
						return _Utils_eq(p.as, fullStmt.g.e.Y) ? p : bearer.dw;
					} else {
						return bearer.dw;
					}
				}();
				var loadingStatus = cache.i;
				var bearerLoading = loadingStatus.g;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{
									i: _Utils_update(
										loadingStatus,
										{
											g: _Utils_update(
												bearerLoading,
												{dw: true})
										}),
									h8: _Utils_update(
										fullStmt,
										{
											g: _Utils_update(
												bearer,
												{dw: person})
										})
								})
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 9:
				var personId = respMsg.a;
				var idx = respMsg.b;
				var loadingStatus = cache.i;
				var depsLoading = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$SharedState$emptyFullLoadingStatus,
					A2(
						$elm$core$Maybe$map,
						function (d) {
							return _Utils_update(
								d,
								{dw: true});
						},
						A2($elm$core$Dict$get, idx, loadingStatus.fh)));
				var dep = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, fullStmt.fh));
				var person = function () {
					var _v26 = A2($author$project$App$Service$ApplicationResources$getPersonById, personId, model._);
					if (_v26.$ === 3) {
						var p = _v26.a;
						return p;
					} else {
						return dep.dw;
					}
				}();
				var nDep = _Utils_update(
					dep,
					{dw: person});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{
									i: _Utils_update(
										loadingStatus,
										{
											fh: A3($elm$core$Dict$insert, idx, depsLoading, loadingStatus.fh)
										}),
									h8: _Utils_update(
										fullStmt,
										{
											fh: A3($elm$core$Dict$insert, idx, nDep, fullStmt.fh)
										})
								})
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 13:
				var ownerId = respMsg.a;
				var kind = respMsg.b;
				var loadingStatus = cache.i;
				var kindStr = $author$project$App$Data$Contact$kindToString(kind);
				var contact = function () {
					var _v28 = A3($author$project$App$Service$ApplicationResources$getContactFromOwnerAndKind, ownerId, kind, model._);
					if (_v28.$ === 3) {
						var c = _v28.a;
						return c;
					} else {
						return A2(
							$elm$core$Maybe$withDefault,
							$author$project$App$Data$Contact$emptyModel(kind),
							A2($elm$core$Dict$get, kindStr, bearer.ea));
					}
				}();
				var bearerLoading = loadingStatus.g;
				var loadingValue = function () {
					var _v27 = bearerLoading.ea;
					if (!_v27) {
						return 1;
					} else {
						return 2;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{
									i: _Utils_update(
										loadingStatus,
										{
											g: _Utils_update(
												bearerLoading,
												{ea: loadingValue})
										}),
									h8: _Utils_update(
										fullStmt,
										{
											g: _Utils_update(
												bearer,
												{
													ea: A3($elm$core$Dict$insert, kindStr, contact, bearer.ea)
												})
										})
								})
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 14:
				var ownerId = respMsg.a;
				var kind = respMsg.b;
				var idx = respMsg.c;
				var loadingStatus = cache.i;
				var kindStr = $author$project$App$Data$Contact$kindToString(kind);
				var depsLoading = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$SharedState$emptyFullLoadingStatus,
					A2(
						$elm$core$Maybe$map,
						function (d) {
							return _Utils_update(
								d,
								{ea: d.ea + 1});
						},
						A2($elm$core$Dict$get, idx, loadingStatus.fh)));
				var dep = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, fullStmt.fh));
				var contact = function () {
					var _v29 = A3($author$project$App$Service$ApplicationResources$getContactFromOwnerAndKind, ownerId, kind, model._);
					if (_v29.$ === 3) {
						var c = _v29.a;
						return c;
					} else {
						return A2(
							$elm$core$Maybe$withDefault,
							$author$project$App$Data$Contact$emptyModel(kind),
							A2($elm$core$Dict$get, kindStr, dep.ea));
					}
				}();
				var nDep = _Utils_update(
					dep,
					{
						ea: A3($elm$core$Dict$insert, kindStr, contact, dep.ea)
					});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{
									i: _Utils_update(
										loadingStatus,
										{
											fh: A3($elm$core$Dict$insert, idx, depsLoading, loadingStatus.fh)
										}),
									h8: _Utils_update(
										fullStmt,
										{
											fh: A3($elm$core$Dict$insert, idx, nDep, fullStmt.fh)
										})
								})
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 5:
				var templateId = respMsg.a;
				var template = function () {
					var _v30 = A2($author$project$App$Service$ApplicationResources$getStatementTemplateById, templateId, model._);
					if (!_v30.$) {
						var t = _v30.a;
						return t;
					} else {
						return fullStmt.cJ;
					}
				}();
				var loadingStatus = cache.i;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{
									i: _Utils_update(
										loadingStatus,
										{cJ: true}),
									h8: _Utils_update(
										fullStmt,
										{cJ: template})
								})
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 6:
				var stmtId = respMsg.a;
				var template = function () {
					var _v31 = A2($author$project$App$Service$ApplicationResources$getStatementTemplateByStatementId, stmtId, model._);
					if (!_v31.$) {
						var t = _v31.a;
						return t;
					} else {
						return fullStmt.cJ;
					}
				}();
				var loadingStatus = cache.i;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{
									i: _Utils_update(
										loadingStatus,
										{cJ: true}),
									h8: _Utils_update(
										fullStmt,
										{cJ: template})
								})
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 7:
				var companyId = respMsg.a;
				var company = A2(
					$elm$core$Maybe$withDefault,
					fullStmt.b4,
					A2($author$project$App$Service$ApplicationResources$getCompanyById, companyId, model._));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ia: _Utils_update(
								cache,
								{
									h8: _Utils_update(
										fullStmt,
										{b4: company})
								})
						}),
					$elm$core$Platform$Cmd$none,
					_List_Nil);
			case 18:
				var profile = respMsg.a;
				var stmtId = respMsg.b;
				var temp = model.f4;
				var updatedVideo = A2(
					$elm$core$Maybe$withDefault,
					model.f4,
					A2(
						$elm$core$Maybe$map,
						function (c) {
							return _Utils_update(
								temp,
								{d7: c});
						},
						A2($author$project$App$Service$ApplicationResources$getConferenceFromStatement, stmtId, model._)));
				var sharedMsg = ($author$project$App$Components$Video$isInitialized(updatedVideo) && (!$author$project$App$Components$Video$isStopped(updatedVideo))) ? _List_Nil : _List_fromArray(
					[
						$author$project$App$SharedState$VideoMsg(
						A3($author$project$App$SharedState$StartConference, 1, profile, updatedVideo.d7))
					]);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{f4: updatedVideo}),
					$elm$core$Platform$Cmd$none,
					sharedMsg);
			default:
				var _v32 = $author$project$App$Service$ApplicationResources$getAuthToken(model._);
				if (!_v32.$) {
					var tokenModel = _v32.a;
					var token = $truqu$elm_base64$Base64$encode(
						A2(
							$elm$json$Json$Encode$encode,
							0,
							$author$project$App$Data$Token$encode(tokenModel)));
					var result = A2($author$project$App$Service$ApplicationResources$getCallResetPasswordWithPassword, token, model._);
					var status = function () {
						if (!result.$) {
							var string = result.a;
							return A2($elm$core$Maybe$withDefault, 'Senha Modificada!', string);
						} else {
							var _v34 = result.a;
							var specificError = _v34.a;
							return $author$project$App$Service$SpecificResources$errorToMessage(specificError);
						}
					}();
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								b6: $elm$core$Maybe$Just(status)
							}),
						$elm$core$Platform$Cmd$none,
						_List_Nil);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				}
		}
	});
var $author$project$App$SharedState$PropagateFullLoadedResponse = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$SharedState$validateFullLoaded = function (_v0) {
	var model = _v0.a;
	var cmds = _v0.b;
	var msgs = _v0.c;
	var validateStmt = function (stmt) {
		return stmt.e && (stmt.dw && ((stmt.ea === 2) && function () {
			var _v4 = model.ia.aq;
			switch (_v4.$) {
				case 0:
					return true;
				case 1:
					var assets = _v4.a;
					return ((!A2(
						$author$project$App$SharedState$hasAsset,
						$author$project$App$SharedState$FullStmtAssetAnswers(2),
						assets)) || (A2(
						$author$project$App$SharedState$hasAsset,
						$author$project$App$SharedState$FullStmtAssetAnswers(2),
						assets) && stmt.cv)) && (((!A2(
						$author$project$App$SharedState$hasAsset,
						$author$project$App$SharedState$FullStmtAssetAnswers(3),
						assets)) || (A2(
						$author$project$App$SharedState$hasAsset,
						$author$project$App$SharedState$FullStmtAssetAnswers(3),
						assets) && stmt.gE)) && ((!A2($author$project$App$SharedState$hasAsset, $author$project$App$SharedState$FullStmtAssetICD, assets)) || A2($author$project$App$SharedState$hasAsset, $author$project$App$SharedState$FullStmtAssetICD, assets)));
				case 2:
					var assets = _v4.a;
					return ((!A2(
						$author$project$App$SharedState$hasAsset,
						$author$project$App$SharedState$FullStmtAssetAnswers(2),
						assets)) || (A2(
						$author$project$App$SharedState$hasAsset,
						$author$project$App$SharedState$FullStmtAssetAnswers(2),
						assets) && stmt.cv)) && (((!A2(
						$author$project$App$SharedState$hasAsset,
						$author$project$App$SharedState$FullStmtAssetAnswers(3),
						assets)) || (A2(
						$author$project$App$SharedState$hasAsset,
						$author$project$App$SharedState$FullStmtAssetAnswers(3),
						assets) && stmt.gE)) && (((!A2(
						$author$project$App$SharedState$hasAsset,
						$author$project$App$SharedState$FullStmtAssetAnswers(1),
						assets)) || (A2(
						$author$project$App$SharedState$hasAsset,
						$author$project$App$SharedState$FullStmtAssetAnswers(1),
						assets) && stmt.gw)) && ((!A2($author$project$App$SharedState$hasAsset, $author$project$App$SharedState$FullStmtAssetICD, assets)) || A2($author$project$App$SharedState$hasAsset, $author$project$App$SharedState$FullStmtAssetICD, assets))));
				default:
					var assets = _v4.a;
					return (!A2(
						$author$project$App$SharedState$hasAsset,
						$author$project$App$SharedState$FullStmtAssetAnswers(1),
						assets)) || (A2(
						$author$project$App$SharedState$hasAsset,
						$author$project$App$SharedState$FullStmtAssetAnswers(1),
						assets) && stmt.gw);
			}
		}()));
	};
	var loadingStatus = model.ia.i;
	var sectionsLoaded = function () {
		var _v3 = model.ia.aq;
		switch (_v3.$) {
			case 0:
				return true;
			case 1:
				var assets = _v3.a;
				return ((!A2(
					$author$project$App$SharedState$hasAsset,
					$author$project$App$SharedState$FullStmtAssetQuestions(2),
					assets)) || (A2(
					$author$project$App$SharedState$hasAsset,
					$author$project$App$SharedState$FullStmtAssetQuestions(2),
					assets) && loadingStatus.eG)) && ((!A2(
					$author$project$App$SharedState$hasAsset,
					$author$project$App$SharedState$FullStmtAssetQuestions(3),
					assets)) || (A2(
					$author$project$App$SharedState$hasAsset,
					$author$project$App$SharedState$FullStmtAssetQuestions(3),
					assets) && loadingStatus.eh));
			case 2:
				var assets = _v3.a;
				return (((!A2(
					$author$project$App$SharedState$hasAsset,
					$author$project$App$SharedState$FullStmtAssetQuestions(2),
					assets)) || (A2(
					$author$project$App$SharedState$hasAsset,
					$author$project$App$SharedState$FullStmtAssetQuestions(2),
					assets) && loadingStatus.eG)) && (((!A2(
					$author$project$App$SharedState$hasAsset,
					$author$project$App$SharedState$FullStmtAssetQuestions(3),
					assets)) || (A2(
					$author$project$App$SharedState$hasAsset,
					$author$project$App$SharedState$FullStmtAssetQuestions(3),
					assets) && loadingStatus.eh)) && (!A2(
					$author$project$App$SharedState$hasAsset,
					$author$project$App$SharedState$FullStmtAssetQuestions(1),
					assets)))) || (A2(
					$author$project$App$SharedState$hasAsset,
					$author$project$App$SharedState$FullStmtAssetQuestions(1),
					assets) && loadingStatus.d6);
			default:
				var assets = _v3.a;
				return (!A2(
					$author$project$App$SharedState$hasAsset,
					$author$project$App$SharedState$FullStmtAssetQuestions(1),
					assets)) || (A2(
					$author$project$App$SharedState$hasAsset,
					$author$project$App$SharedState$FullStmtAssetQuestions(1),
					assets) && loadingStatus.d6);
		}
	}();
	var depsLoaded = A3(
		$elm$core$Dict$foldl,
		F3(
			function (_v2, ls, t) {
				return t && validateStmt(ls);
			}),
		true,
		loadingStatus.fh);
	var cache = model.ia;
	var bearerLoaded = validateStmt(loadingStatus.g) && loadingStatus.cJ;
	var _v1 = (bearerLoaded && (sectionsLoaded && (((depsLoaded && _Utils_eq(
		loadingStatus.dd,
		$elm$core$Maybe$Just(true))) || _Utils_eq(
		loadingStatus.dd,
		$elm$core$Maybe$Just(false))) && (!loadingStatus.aG)))) ? _Utils_Tuple2(
		$author$project$App$SharedState$asyncMsg(
			$author$project$App$SharedState$PropagateFullLoadedResponse(model.ia.cl)),
		true) : _Utils_Tuple2($elm$core$Platform$Cmd$none, loadingStatus.aG);
	var fullLoadedMsg = _v1.a;
	var propagated = _v1.b;
	return _Utils_Tuple3(
		_Utils_update(
			model,
			{
				ia: _Utils_update(
					cache,
					{
						i: _Utils_update(
							loadingStatus,
							{aG: propagated})
					})
			}),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[cmds, fullLoadedMsg])),
		msgs);
};
var $author$project$App$SharedState$updateResponse = F2(
	function (respMsg, model) {
		return function (_v0) {
			var m = _v0.a;
			var c = _v0.b;
			var l = _v0.c;
			return A2(
				$elm$core$Tuple$mapSecond,
				function (uc) {
					return $elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[c, uc]));
				},
				A2($author$project$App$SharedState$updateShared, l, m));
		}(
			$author$project$App$SharedState$validateFullLoaded(
				A2($author$project$App$SharedState$updateResponseInternal, respMsg, model)));
	});
var $author$project$App$Main$updateSingleResponse = F2(
	function (maybeResponseMsg, model) {
		var _v0 = _Utils_Tuple2(maybeResponseMsg, model.aQ);
		switch (_v0.a.$) {
			case 0:
				if (_v0.b.$ === 1) {
					var loginMsg = _v0.a.a;
					var loginModel = _v0.b.a;
					var _v1 = A3($author$project$App$Page$PageLogin$updateResponse, loginMsg, model.a, loginModel);
					var newLoginModel = _v1.a;
					var newLoginCmd = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Main$Login(newLoginModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Main$LoginMsg, newLoginCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 10:
				if (_v0.b.$ === 3) {
					var pMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v2 = A3($author$project$App$Page$Client$PageClient$updateResponse, pMsg, model.a, pageModel);
					var newPageModel = _v2.a;
					var newPageCmd = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Main$Client(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Main$ClientMsg, newPageCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				if (_v0.b.$ === 10) {
					var pMsg = _v0.a.a;
					var pageModel = _v0.b.a;
					var _v3 = A3($author$project$App$Page$PageProponent$updateResponse, pMsg, model.a, pageModel);
					var newPageModel = _v3.a;
					var newPageCmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Main$Proponent(newPageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Main$ProponentMsg, newPageCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				if (_v0.b.$ === 5) {
					var doctorSignUpMsg = _v0.a.a;
					var signUpModel = _v0.b.a;
					var _v4 = A3($author$project$App$Page$PageDoctorSignUp$updateResponse, doctorSignUpMsg, model.a, signUpModel);
					var newSignUpModel = _v4.a;
					var signUpCmd = _v4.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Main$DoctorSignUp(newSignUpModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Main$DoctorSignUpMsg, signUpCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				if (_v0.b.$ === 11) {
					var doctorResponseMsg = _v0.a.a;
					var doctorModel = _v0.b.a;
					var _v5 = A3($author$project$App$Page$PageDoctor$updateResponse, doctorResponseMsg, model.a, doctorModel);
					var newDoctorModel = _v5.a;
					var doctorCmd = _v5.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Main$Doctor(newDoctorModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Main$DoctorMsg, doctorCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				if (_v0.b.$ === 12) {
					var companyResponseMsg = _v0.a.a;
					var doctorModel = _v0.b.a;
					var _v6 = A3($author$project$App$Page$PageCompany$updateResponse, companyResponseMsg, model.a, doctorModel);
					var newCompanyModel = _v6.a;
					var doctorCmd = _v6.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Main$Company(newCompanyModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Main$CompanyMsg, doctorCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				if (_v0.b.$ === 13) {
					var reviewResponseMsg = _v0.a.a;
					var doctorModel = _v0.b.a;
					var _v7 = A3($author$project$App$Page$PageReviewer$updateResponse, reviewResponseMsg, model.a, doctorModel);
					var newReviewModel = _v7.a;
					var doctorCmd = _v7.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Main$Review(newReviewModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Main$ReviewMsg, doctorCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				if (_v0.b.$ === 14) {
					var supportResponseMsg = _v0.a.a;
					var supportModel = _v0.b.a;
					var _v8 = A3($author$project$App$Page$PageSupport$updateResponse, supportResponseMsg, model.a, supportModel);
					var newSupportModel = _v8.a;
					var doctorCmd = _v8.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Main$Support(newSupportModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SupportMsg, doctorCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				if (_v0.b.$ === 15) {
					var adminResponseMsg = _v0.a.a;
					var adminModel = _v0.b.a;
					var _v9 = A3($author$project$App$Page$PageAdmin$updateResponse, adminResponseMsg, model.a, adminModel);
					var newAdminMsg = _v9.a;
					var adminCmd = _v9.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Main$Admin(newAdminMsg)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Main$AdminMsg, adminCmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				var sharedResponseMsg = _v0.a.a;
				var _v10 = A2($author$project$App$SharedState$updateResponse, sharedResponseMsg, model.a);
				var newSharedState = _v10.a;
				var sharedStateCmd = _v10.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a: newSharedState}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd));
		}
	});
var $author$project$App$Main$updateResponse = F2(
	function (respMsgs, model) {
		return A2(
			$elm$core$Tuple$mapSecond,
			$elm$core$Platform$Cmd$batch,
			A3(
				$elm$core$List$foldl,
				F2(
					function (respMsg, _v0) {
						var m = _v0.a;
						var c = _v0.b;
						return A2(
							$elm$core$Tuple$mapSecond,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$List$singleton,
								$elm$core$List$append(c)),
							A2($author$project$App$Main$updateSingleResponse, respMsg, m));
					}),
				_Utils_Tuple2(model, _List_Nil),
				respMsgs));
	});
var $author$project$App$SharedState$Logout = {$: 4};
var $author$project$App$Page$Finances$PageDoctorResume$emptyModel = {
	fk: _List_fromArray(
		[
			{c_: 1, c4: '', c6: 'DoctorOne', iq: 10.0},
			{c_: 1, c4: '', c6: 'DoctorTwo', iq: 10.0},
			{c_: 1, c4: '', c6: 'DoctorThree', iq: 10.0}
		]),
	b: $author$project$App$Components$Filter$empty,
	hk: 11,
	iF: 2020
};
var $author$project$App$Page$Finances$PageDoctorResume$init = function (f) {
	return _Utils_update(
		$author$project$App$Page$Finances$PageDoctorResume$emptyModel,
		{b: f});
};
var $author$project$App$Page$Finances$PageFinanceSales$emptyModel = {
	w: _List_fromArray(
		[
			{
			b1: $elm$core$Maybe$Nothing,
			gL: $elm$core$Maybe$Nothing,
			hk: 8,
			aR: $elm$core$Maybe$Nothing,
			S: _List_fromArray(
				[
					{
					gD: _List_fromArray(
						[
							{gA: '1 AGO', am: 'Atendimentos Vídeo', bu: 'Dr. Fausto', T: 5000},
							{gA: '3 AGO', am: 'Atendimentos Vídeo', bu: 'Dr. Hélio Freitas', T: 5000},
							{gA: '3 AGO', am: 'Atendimentos Vídeo', bu: 'Dra Regina Costa', T: 5000},
							{gA: '4 AGO', am: 'Atendimentos Vídeo', bu: 'Dra Liz Moskano', T: 5000}
						]),
					at: 3333,
					L: 3333,
					hl: 'Médicos',
					au: 1
				}
				]),
			bi: 0,
			iq: 12300,
			iF: 2022
		},
			{
			b1: $elm$core$Maybe$Just('04 set'),
			gL: $elm$core$Maybe$Just('10 SET'),
			hk: 9,
			aR: $elm$core$Maybe$Just('Fatura vencida'),
			S: _List_fromArray(
				[
					{
					gD: _List_fromArray(
						[
							{gA: '1 SET', am: 'Atendimentos Vídeo', bu: 'Dr. Fausto', T: 50000},
							{gA: '3 SET', am: 'Atendimentos Vídeo', bu: 'Dr. Hélio Freitas', T: 50000},
							{gA: '3 SET', am: 'Atendimentos Vídeo', bu: 'Dra Regina Costa', T: 50000},
							{gA: '4 SET', am: 'Atendimentos Vídeo', bu: 'Dra Liz Moskano', T: 50000}
						]),
					at: 33333,
					L: 33333,
					hl: 'Médicos',
					au: 1
				}
				]),
			bi: 0,
			iq: 123400,
			iF: 2022
		},
			{
			b1: $elm$core$Maybe$Just('04 out'),
			gL: $elm$core$Maybe$Just('10 OUT'),
			hk: 10,
			aR: $elm$core$Maybe$Just('Fatura em aberto'),
			S: _List_fromArray(
				[
					{
					gD: _List_fromArray(
						[
							{gA: '1 OUT', am: 'Atendimentos Vídeo', bu: 'Dr. Fausto', T: 150000},
							{gA: '3 OUT', am: 'Atendimentos Vídeo', bu: 'Dr. Hélio Freitas', T: 150000},
							{gA: '3 OUT', am: 'Atendimentos Vídeo', bu: 'Dra Regina Costa', T: 150000},
							{gA: '4 OUT', am: 'Atendimentos Vídeo', bu: 'Dra Liz Moskano', T: 150000}
						]),
					at: 133333,
					L: 133333,
					hl: 'Médicos',
					au: 1
				}
				]),
			bi: 0,
			iq: 1123400,
			iF: 2022
		}
		]),
	fR: 4,
	fS: _List_fromArray(
		[
			{
			gL: $elm$core$Maybe$Just('10 SET'),
			hk: 9,
			aw: $elm$core$Maybe$Nothing,
			iq: 155554,
			iF: 2021
		},
			{
			gL: $elm$core$Maybe$Just('10 AGO'),
			hk: 10,
			aw: $elm$core$Maybe$Nothing,
			iq: 155554,
			iF: 2021
		},
			{
			gL: $elm$core$Maybe$Just('10 NOV'),
			hk: 11,
			aw: $elm$core$Maybe$Nothing,
			iq: 155554,
			iF: 2021
		},
			{
			gL: $elm$core$Maybe$Just('10 DEZ'),
			hk: 12,
			aw: $elm$core$Maybe$Nothing,
			iq: 155554,
			iF: 2021
		},
			{
			gL: $elm$core$Maybe$Just('10 JAN'),
			hk: 1,
			aw: $elm$core$Maybe$Nothing,
			iq: 155554,
			iF: 2022
		},
			{
			gL: $elm$core$Maybe$Just('10 FEV'),
			hk: 2,
			aw: $elm$core$Maybe$Nothing,
			iq: 155554,
			iF: 2022
		},
			{
			gL: $elm$core$Maybe$Just('10 MAR'),
			hk: 3,
			aw: $elm$core$Maybe$Nothing,
			iq: 155554,
			iF: 2022
		},
			{
			gL: $elm$core$Maybe$Just('10 ABR'),
			hk: 4,
			aw: $elm$core$Maybe$Nothing,
			iq: 155554,
			iF: 2022
		},
			{
			gL: $elm$core$Maybe$Just('10 MAI'),
			hk: 5,
			aw: $elm$core$Maybe$Nothing,
			iq: 155554,
			iF: 2022
		},
			{
			gL: $elm$core$Maybe$Just('10 JUN'),
			hk: 6,
			aw: $elm$core$Maybe$Nothing,
			iq: 155554,
			iF: 2022
		},
			{
			gL: $elm$core$Maybe$Just('10 JUL'),
			hk: 7,
			aw: $elm$core$Maybe$Nothing,
			iq: 155554,
			iF: 2022
		},
			{
			gL: $elm$core$Maybe$Just('10 AGO'),
			hk: 8,
			aw: $elm$core$Maybe$Nothing,
			iq: 155554,
			iF: 2022
		},
			{
			gL: $elm$core$Maybe$Just('10 SET'),
			hk: 9,
			aw: $elm$core$Maybe$Nothing,
			iq: 155554,
			iF: 2022
		}
		]),
	m: 1
};
var $author$project$App$Page$Finances$PageFinanceSales$init = $author$project$App$Page$Finances$PageFinanceSales$emptyModel;
var $author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedCompanies = {$: 9};
var $author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedCompanyTaxes = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedDoctors = {$: 10};
var $author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedSummaries = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedTemplates = {$: 11};
var $author$project$App$SharedState$LoadCompanies = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$SharedState$LoadCompanyTaxes = F2(
	function (a, b) {
		return {$: 92, a: a, b: b};
	});
var $author$project$App$SharedState$LoadDoctorsFromCompany = F2(
	function (a, b) {
		return {$: 105, a: a, b: b};
	});
var $author$project$App$SharedState$LoadMonthSummary = F2(
	function (a, b) {
		return {$: 90, a: a, b: b};
	});
var $author$project$App$SharedState$LoadTemplatesFromCompany = F2(
	function (a, b) {
		return {$: 88, a: a, b: b};
	});
var $author$project$App$Page$Admin$PageFinanceSales$OrderByDate = 1;
var $author$project$App$Page$Admin$PageFinanceSales$monthToInt = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.fW, posixMinutes) < 0) {
					return posixMinutes + era.f;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		gB: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		hk: month,
		iF: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).hk;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).iF;
	});
var $author$project$App$Page$Admin$PageFinanceSales$emptyModel = F3(
	function (now, st, cId) {
		var emptyFilter = $author$project$App$Components$ReportFilter$empty;
		var updatedEmptyFilter = _Utils_update(
			emptyFilter,
			{fz: cId, cB: cId});
		var defaultMonthlySale = {
			b1: $elm$core$Maybe$Nothing,
			gL: $elm$core$Maybe$Nothing,
			hk: $author$project$App$Page$Admin$PageFinanceSales$monthToInt(
				A2($elm$time$Time$toMonth, st.f6, now)),
			aR: $elm$core$Maybe$Nothing,
			S: _List_fromArray(
				[
					{gD: _List_Nil, at: 0, L: 0, hl: 'Atendimentos', au: 1}
				]),
			bi: 0,
			ai: 0,
			iq: 0,
			iF: A2($elm$time$Time$toYear, st.f6, now)
		};
		var newMonthlySales = A2(
			$elm$core$List$indexedMap,
			F2(
				function (i, ms) {
					var _v0 = ((ms.hk - i) > 0) ? _Utils_Tuple2(ms.hk - i, ms.iF) : _Utils_Tuple2(12 + (ms.hk - i), ms.iF - 1);
					var newMonth = _v0.a;
					var newYear = _v0.b;
					return _Utils_update(
						ms,
						{hk: newMonth, iF: newYear});
				}),
			A2($elm$core$List$repeat, 12, defaultMonthlySale));
		return {
			gx: cId,
			eb: $elm$core$Maybe$Nothing,
			aO: _List_Nil,
			b: updatedEmptyFilter,
			dh: false,
			w: newMonthlySales,
			ct: _Utils_Tuple2(1, false),
			eF: $elm$core$Maybe$Nothing,
			fR: 4,
			fS: _List_Nil,
			m: $author$project$App$Page$Admin$PageFinanceSales$monthToInt(
				A2($elm$time$Time$toMonth, st.f6, now)),
			o: A2($elm$time$Time$toYear, st.f6, now),
			ax: $author$project$App$Page$Admin$PageFinanceSales$monthToInt(
				A2($elm$time$Time$toMonth, st.f6, now)),
			aW: A2($elm$time$Time$toYear, st.f6, now),
			dS: true
		};
	});
var $author$project$App$Page$Admin$PageFinanceSales$init = F2(
	function (now, st) {
		var startDate = $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, st.f6, now)) + ((($author$project$App$Page$Admin$PageFinanceSales$monthToInt(
			A2($elm$time$Time$toMonth, st.f6, now)) >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(
			$author$project$App$Page$Admin$PageFinanceSales$monthToInt(
				A2($elm$time$Time$toMonth, st.f6, now))) + '-01'));
		var logout = _Utils_Tuple3(
			A3($author$project$App$Page$Admin$PageFinanceSales$emptyModel, now, st, $author$project$App$Data$Id$uuidNil),
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
		var endDate = ($author$project$App$Page$Admin$PageFinanceSales$monthToInt(
			A2($elm$time$Time$toMonth, st.f6, now)) === 12) ? ($elm$core$String$fromInt(
			A2($elm$time$Time$toYear, st.f6, now) + 1) + ('-' + '01-01')) : ($elm$core$String$fromInt(
			A2($elm$time$Time$toYear, st.f6, now)) + (((($author$project$App$Page$Admin$PageFinanceSales$monthToInt(
			A2($elm$time$Time$toMonth, st.f6, now)) + 1) >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(
			$author$project$App$Page$Admin$PageFinanceSales$monthToInt(
				A2($elm$time$Time$toMonth, st.f6, now)) + 1) + '-01')));
		var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
		if (!_v0.$) {
			var token = _v0.a;
			var _v1 = token.gx;
			if (!_v1.$) {
				var cId = _v1.a;
				return _Utils_Tuple3(
					A3($author$project$App$Page$Admin$PageFinanceSales$emptyModel, now, st, cId),
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								A2(
								$author$project$App$SharedState$LoadMonthSummary,
								cId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg(
											$author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedSummaries(cId)))
									])),
								A2(
								$author$project$App$SharedState$LoadCompanyTaxes,
								cId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg(
											$author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedCompanyTaxes(cId)))
									])),
								A4(
								$author$project$App$SharedState$LoadMonthDetails,
								cId,
								startDate,
								endDate,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg(
											A5(
												$author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedDetails,
												cId,
												startDate,
												endDate,
												A2($elm$time$Time$toYear, st.f6, now),
												$author$project$App$Page$Admin$PageFinanceSales$monthToInt(
													A2($elm$time$Time$toMonth, st.f6, now)))))
									])),
								$author$project$App$SharedState$LoadCompanies(
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg($author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedCompanies))
									])),
								A2(
								$author$project$App$SharedState$LoadDoctorsFromCompany,
								cId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg($author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedDoctors))
									])),
								A2(
								$author$project$App$SharedState$LoadTemplatesFromCompany,
								cId,
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminFinanceSalesMsg($author$project$App$Page$ResponseMsgs$AdminFinanceSalesLoadedTemplates))
									]))
							])));
			} else {
				return logout;
			}
		} else {
			return logout;
		}
	});
var $author$project$App$Page$ResponseMsgs$AdminListDoctorsCacheCleared = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$SharedState$ClearCache = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Data$DoctorTemplate$emptyModel = {
	iW: $elm$time$Time$millisToPosix(0),
	c2: 0,
	c3: false,
	fl: $author$project$App$Data$Id$uuidNil,
	as: $author$project$App$Data$Id$uuidNil,
	eY: $author$project$App$Data$Id$uuidNil,
	f2: $elm$time$Time$millisToPosix(0)
};
var $author$project$App$Page$Admin$PageListDoctors$init = F2(
	function (route, st) {
		var _v0 = function () {
			var _v1 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
			if (!_v1.$) {
				var token = _v1.a;
				var _v2 = token.gx;
				if (!_v2.$) {
					var companyId = _v2.a;
					return _Utils_Tuple2(
						companyId,
						_List_fromArray(
							[
								$author$project$App$SharedState$ClearCache(
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminListDoctorsMsg(
											$author$project$App$Page$ResponseMsgs$AdminListDoctorsCacheCleared(companyId)))
									]))
							]));
				} else {
					return _Utils_Tuple2(
						$author$project$App$Data$Id$uuidNil,
						_List_fromArray(
							[$author$project$App$SharedState$Logout]));
				}
			} else {
				return _Utils_Tuple2(
					$author$project$App$Data$Id$uuidNil,
					_List_fromArray(
						[$author$project$App$SharedState$Logout]));
			}
		}();
		var cId = _v0.a;
		var cacheMsgs = _v0.b;
		var modelForm = {gx: cId, bu: $author$project$App$Data$Doctor$emptyModel, aB: $author$project$App$Data$DoctorTemplate$emptyModel, fp: $elm$core$Dict$empty, ep: false, dw: $author$project$App$Data$Person$emptyModel, cH: 0, eZ: _List_Nil};
		return _Utils_Tuple3(
			modelForm,
			$elm$core$Platform$Cmd$none,
			A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, cacheMsgs));
	});
var $author$project$App$Page$ResponseMsgs$AdminListFinishedCacheCleared = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$ResponseMsgs$AdminListFinishedCacheClearedViewDetails = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$AdminListFinishedCompanyPeopleLoaded = {$: 5};
var $author$project$App$Page$Admin$PageListFinished$init = F2(
	function (listFinishedRoute, sharedState) {
		var loadCompanyPeople = ($elm$core$List$length(
			$author$project$App$Service$ApplicationResources$getAllCompanyPeople(sharedState._)) <= 0) ? _List_fromArray(
			[
				$author$project$App$SharedState$LoadCompanyPeopleFromPerson(
				_List_fromArray(
					[
						$author$project$App$Page$ResponseMsgs$AdminMsg(
						$author$project$App$Page$ResponseMsgs$AdminListFinishedMsg($author$project$App$Page$ResponseMsgs$AdminListFinishedCompanyPeopleLoaded))
					]))
			]) : _List_Nil;
		var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(sharedState._);
		if (!_v0.$) {
			var token = _v0.a;
			if (!listFinishedRoute.$) {
				var filter = listFinishedRoute.a;
				return _Utils_Tuple3(
					{
						bX: token.Y,
						b: $author$project$App$Page$Admin$PageListFinished$defaultFilter(filter),
						bF: $elm$core$Dict$empty,
						aU: $elm$core$Maybe$Nothing,
						av: $elm$core$Maybe$Nothing,
						bk: _List_Nil
					},
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$App$SharedState$ClearCache(
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$AdminMsg(
											$author$project$App$Page$ResponseMsgs$AdminListFinishedMsg(
												$author$project$App$Page$ResponseMsgs$AdminListFinishedCacheCleared(
													$author$project$App$Page$Admin$PageListFinished$defaultFilter(filter))))
										]))
								]),
							loadCompanyPeople)));
			} else {
				var stmtId = listFinishedRoute.a;
				var filter = listFinishedRoute.b;
				return _Utils_Tuple3(
					{
						bX: token.Y,
						b: $author$project$App$Page$Admin$PageListFinished$defaultFilter(filter),
						bF: $elm$core$Dict$empty,
						aU: $elm$core$Maybe$Nothing,
						av: $elm$core$Maybe$Nothing,
						bk: _List_Nil
					},
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$App$SharedState$ClearCache(
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$AdminMsg(
											$author$project$App$Page$ResponseMsgs$AdminListFinishedMsg(
												A2($author$project$App$Page$ResponseMsgs$AdminListFinishedCacheClearedViewDetails, stmtId, filter)))
										]))
								]),
							loadCompanyPeople)));
			}
		} else {
			return _Utils_Tuple3(
				{
					bX: $author$project$App$Data$Id$uuidNil,
					b: $author$project$App$Page$Admin$PageListFinished$defaultFilter($author$project$App$Components$Filter$empty),
					bF: $elm$core$Dict$empty,
					aU: $elm$core$Maybe$Nothing,
					av: $elm$core$Maybe$Nothing,
					bk: _List_Nil
				},
				$elm$core$Platform$Cmd$none,
				_List_fromArray(
					[
						$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
					]));
		}
	});
var $author$project$App$Page$ResponseMsgs$AdminListReevaluationCacheCleared = {$: 0};
var $author$project$App$Page$Admin$PageListReevaluation$defaultFilter = function (filter) {
	return A2($author$project$App$Components$Filter$setOrder, 'created_at,desc', filter);
};
var $author$project$App$Page$Admin$PageListReevaluation$init = function (sharedState) {
	var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(sharedState._);
	if (!_v0.$) {
		var token = _v0.a;
		return _Utils_Tuple3(
			{
				bX: token.Y,
				gx: A2($elm$core$Maybe$withDefault, $author$project$App$Data$Id$uuidNil, token.gx),
				b: $author$project$App$Page$Admin$PageListReevaluation$defaultFilter($author$project$App$Components$Filter$empty),
				bF: $elm$core$Dict$empty,
				aU: $elm$core$Maybe$Nothing,
				av: $elm$core$Maybe$Nothing,
				bk: _List_Nil
			},
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$List$map,
				$author$project$App$SharedState$APIMsg,
				_List_fromArray(
					[
						$author$project$App$SharedState$ClearCache(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$AdminMsg(
								$author$project$App$Page$ResponseMsgs$AdminListReevaluationMsg($author$project$App$Page$ResponseMsgs$AdminListReevaluationCacheCleared))
							]))
					])));
	} else {
		return _Utils_Tuple3(
			{
				bX: $author$project$App$Data$Id$uuidNil,
				gx: $author$project$App$Data$Id$uuidNil,
				b: $author$project$App$Page$Admin$PageListReevaluation$defaultFilter($author$project$App$Components$Filter$empty),
				bF: $elm$core$Dict$empty,
				aU: $elm$core$Maybe$Nothing,
				av: $elm$core$Maybe$Nothing,
				bk: _List_Nil
			},
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
	}
};
var $author$project$App$Page$ResponseMsgs$AdminListUsersCacheCleared = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Admin$PageListUsers$init = F2(
	function (route, st) {
		var logout = _Utils_Tuple3(
			{gx: $author$project$App$Data$Id$uuidNil, av: $elm$core$Maybe$Nothing, cO: _List_Nil},
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
		var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
		if (!_v0.$) {
			var token = _v0.a;
			var _v1 = _Utils_Tuple2(token.gx, route);
			if (!_v1.a.$) {
				var cId = _v1.a.a;
				var _v2 = _v1.b;
				return _Utils_Tuple3(
					{gx: cId, av: $elm$core$Maybe$Nothing, cO: _List_Nil},
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								$author$project$App$SharedState$ClearCache(
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$AdminMsg(
										$author$project$App$Page$ResponseMsgs$AdminListUsersMsg(
											$author$project$App$Page$ResponseMsgs$AdminListUsersCacheCleared(cId)))
									]))
							])));
			} else {
				var _v3 = _v1.a;
				return logout;
			}
		} else {
			return logout;
		}
	});
var $author$project$App$Page$ResponseMsgs$AdminReevaluationICDCategoriesLoaded = 2;
var $author$project$App$Page$ResponseMsgs$AdminReevaluationLoaded = 0;
var $author$project$App$Page$ResponseMsgs$AdminReevaluationStatementMessagesLoaded = 3;
var $author$project$App$SharedState$GetICDCategories = function (a) {
	return {$: 23, a: a};
};
var $author$project$App$SharedState$GetStatementMessages = F2(
	function (a, b) {
		return {$: 75, a: a, b: b};
	});
var $author$project$App$Page$Admin$PageReevaluation$GotTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Admin$PageReevaluation$Loading = 0;
var $author$project$App$Page$Admin$PageReevaluation$TabCIDs = {$: 0};
var $author$project$App$Page$Admin$PageReevaluation$AcQuestionIndex = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$Components$Autocomplete$empty = {aa: $author$project$Menu$empty, c9: $elm$core$Maybe$Nothing, bz: 5, as: '', R: '', aI: $elm$core$Maybe$Nothing, bL: false};
var $author$project$App$Components$Autocomplete$init = F2(
	function (id, howMany) {
		return _Utils_update(
			$author$project$App$Components$Autocomplete$empty,
			{bz: howMany, as: id});
	});
var $author$project$App$Page$Admin$PageReevaluation$emptyACModel = {
	fb: A2($author$project$App$Components$Autocomplete$init, '', 10),
	gt: A3($author$project$App$Page$Admin$PageReevaluation$AcQuestionIndex, $author$project$App$Data$Id$uuidNil, $author$project$App$Data$Statement$Bearer, $elm$core$Maybe$Nothing)
};
var $author$project$App$Components$Chatbox$init = F2(
	function (pId, isEnabled) {
		return {ed: pId, az: '', dg: isEnabled, fA: false, co: _List_Nil};
	});
var $author$project$App$Page$Admin$PageReevaluation$init = F2(
	function (stmtId, sharedState) {
		var currentPersonId = function () {
			var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(sharedState._);
			if (!_v0.$) {
				var token = _v0.a;
				return token.Y;
			} else {
				return $author$project$App$Data$Id$uuidNil;
			}
		}();
		var newChatBoxModel = A2($author$project$App$Components$Chatbox$init, currentPersonId, true);
		return _Utils_Tuple3(
			{
				f8: $author$project$App$Page$Admin$PageReevaluation$emptyACModel,
				aZ: $elm$core$Dict$empty,
				aM: newChatBoxModel,
				fa: _List_Nil,
				bs: _List_Nil,
				b5: $author$project$App$Page$Admin$PageReevaluation$TabCIDs,
				an: $elm$core$Dict$empty,
				gH: _List_Nil,
				P: $author$project$App$Data$FullStatement$emptyFullStatement,
				ey: $elm$time$Time$millisToPosix(0),
				dC: false,
				eN: '',
				cA: $elm$core$Dict$empty,
				h$: false,
				h0: false,
				h1: $elm$core$Maybe$Nothing,
				h2: '',
				h3: false,
				p: 0,
				eV: stmtId,
				fX: $elm$core$Dict$empty,
				e$: $author$project$App$Service$Loadable$NotLoaded
			},
			A2($elm$core$Task$perform, $author$project$App$Page$Admin$PageReevaluation$GotTime, $elm$time$Time$now),
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg(
					A3(
						$author$project$App$SharedState$LoadFullStatementWithMinors,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$SharedState$FullStmtAssetAnswers(1),
								$author$project$App$SharedState$FullStmtAssetQuestions(1),
								$author$project$App$SharedState$FullStmtAssetAnswers(2),
								$author$project$App$SharedState$FullStmtAssetQuestions(2),
								$author$project$App$SharedState$FullStmtAssetAnswers(3),
								$author$project$App$SharedState$FullStmtAssetQuestions(3),
								$author$project$App$SharedState$FullStmtAssetDocuments,
								$author$project$App$SharedState$FullStmtAssetICD
							]),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$AdminMsg(
								$author$project$App$Page$ResponseMsgs$AdminReevaluationMsg(0))
							]))),
					$author$project$App$SharedState$APIMsg(
					$author$project$App$SharedState$GetICDCategories(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$AdminMsg(
								$author$project$App$Page$ResponseMsgs$AdminReevaluationMsg(2))
							]))),
					$author$project$App$SharedState$APIMsg(
					A2(
						$author$project$App$SharedState$GetStatementMessages,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$AdminMsg(
								$author$project$App$Page$ResponseMsgs$AdminReevaluationMsg(3))
							])))
				]));
	});
var $author$project$App$Page$ResponseMsgs$AdminViewEvaluationICDCategoriesLoaded = 1;
var $author$project$App$Page$ResponseMsgs$AdminViewEvaluationMessagesLoaded = 3;
var $author$project$App$Page$ResponseMsgs$AdminViewEvaluationStatementLoaded = 0;
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$GotTime = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$Loading = 0;
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabCIDs = {$: 0};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$AcQuestionIndex = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$emptyACModel = {
	fb: A2($author$project$App$Components$Autocomplete$init, '', 10),
	gt: A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$AcQuestionIndex, $author$project$App$Data$Id$uuidNil, $author$project$App$Data$Statement$Bearer, $elm$core$Maybe$Nothing)
};
var $author$project$App$Data$Memed$init = _Utils_Tuple2(
	{du: $elm$core$Maybe$Nothing, e$: ''},
	$elm$core$Platform$Cmd$none);
var $author$project$App$Data$Nexodata$init = _Utils_Tuple2(
	{e$: ''},
	$elm$core$Platform$Cmd$none);
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$init = F2(
	function (stmtId, sharedState) {
		var currentPersonId = function () {
			var _v2 = $author$project$App$Service$ApplicationResources$getAuthToken(sharedState._);
			if (!_v2.$) {
				var token = _v2.a;
				return token.Y;
			} else {
				return $author$project$App$Data$Id$uuidNil;
			}
		}();
		var newChatBoxModel = A2($author$project$App$Components$Chatbox$init, currentPersonId, false);
		var _v0 = $author$project$App$Data$Nexodata$init;
		var initNexodata = _v0.a;
		var nCmd = _v0.b;
		var _v1 = $author$project$App$Data$Memed$init;
		var initMemed = _v1.a;
		var mCmd = _v1.b;
		return _Utils_Tuple3(
			{
				f8: $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$emptyACModel,
				aZ: $elm$core$Dict$empty,
				aM: newChatBoxModel,
				fa: _List_Nil,
				bs: _List_Nil,
				b5: $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabCIDs,
				an: $elm$core$Dict$empty,
				gH: _List_Nil,
				P: $author$project$App$Data$FullStatement$emptyFullStatement,
				hi: initMemed,
				ho: initNexodata,
				ey: $elm$time$Time$millisToPosix(0),
				dC: false,
				eN: '',
				cA: $elm$core$Dict$empty,
				h$: false,
				h0: false,
				h1: $elm$core$Maybe$Nothing,
				h2: '',
				h3: false,
				p: 0,
				eV: stmtId,
				fX: $elm$core$Dict$empty,
				e$: $author$project$App$Service$Loadable$NotLoaded
			},
			A2($elm$core$Task$perform, $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$GotTime, $elm$time$Time$now),
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg(
					A3(
						$author$project$App$SharedState$LoadFullStatementWithMinors,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$SharedState$FullStmtAssetAnswers(1),
								$author$project$App$SharedState$FullStmtAssetQuestions(1),
								$author$project$App$SharedState$FullStmtAssetAnswers(2),
								$author$project$App$SharedState$FullStmtAssetQuestions(2),
								$author$project$App$SharedState$FullStmtAssetAnswers(3),
								$author$project$App$SharedState$FullStmtAssetQuestions(3),
								$author$project$App$SharedState$FullStmtAssetDocuments,
								$author$project$App$SharedState$FullStmtAssetICD
							]),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$AdminMsg(
								$author$project$App$Page$ResponseMsgs$AdminViewEvaluationMsg(0))
							]))),
					$author$project$App$SharedState$APIMsg(
					$author$project$App$SharedState$GetICDCategories(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$AdminMsg(
								$author$project$App$Page$ResponseMsgs$AdminViewEvaluationMsg(1))
							]))),
					$author$project$App$SharedState$APIMsg(
					A2(
						$author$project$App$SharedState$GetStatementMessages,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$AdminMsg(
								$author$project$App$Page$ResponseMsgs$AdminViewEvaluationMsg(3))
							])))
				]));
	});
var $author$project$App$Page$PageAdmin$init = F3(
	function (route, now, st) {
		var _v0 = function () {
			switch (route.$) {
				case 0:
					var listUsersRoute = route.a;
					return function (_v2) {
						var p = _v2.a;
						var c = _v2.b;
						var m = _v2.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageAdmin$ListUsers(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListUsersMsg, c),
							m);
					}(
						A2($author$project$App$Page$Admin$PageListUsers$init, listUsersRoute, st));
				case 1:
					var listDoctorsRoute = route.a;
					return function (_v3) {
						var p = _v3.a;
						var c = _v3.b;
						var m = _v3.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageAdmin$ListDoctors(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListDoctorsMsg, c),
							m);
					}(
						A2($author$project$App$Page$Admin$PageListDoctors$init, listDoctorsRoute, st));
				case 2:
					return function (_v4) {
						var p = _v4.a;
						var c = _v4.b;
						var m = _v4.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageAdmin$FinanceSales(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$FinanceSalesMsg, c),
							m);
					}(
						A2($author$project$App$Page$Admin$PageFinanceSales$init, now, st));
				case 4:
					var finishedRoute = route.a;
					var _v5 = A2($author$project$App$Page$Admin$PageListFinished$init, finishedRoute, st);
					var pModel = _v5.a;
					var pageMsg = _v5.b;
					var listMsgs = _v5.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$ListFinished(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListFinishedMsg, pageMsg),
						listMsgs);
				case 3:
					var stmtId = route.a;
					var _v6 = A2($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$init, stmtId, st);
					var pModel = _v6.a;
					var pageMsg = _v6.b;
					var listMsgs = _v6.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$ViewEvaluation(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ViewEvaluationMsg, pageMsg),
						listMsgs);
				case 5:
					var _v7 = $author$project$App$Page$Admin$PageListReevaluation$init(st);
					var pModel = _v7.a;
					var pageMsg = _v7.b;
					var listMsgs = _v7.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$ListReevaluation(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListReevaluationMsg, pageMsg),
						listMsgs);
				default:
					var stmtId = route.a;
					var _v8 = A2($author$project$App$Page$Admin$PageReevaluation$init, stmtId, st);
					var pModel = _v8.a;
					var pageMsg = _v8.b;
					var listMsgs = _v8.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$Reevaluation(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ReevaluationMsg, pageMsg),
						listMsgs);
			}
		}();
		var pageModel = _v0.a;
		var pageCmd = _v0.b;
		var stMsgs = _v0.c;
		return _Utils_Tuple3(pageModel, pageCmd, stMsgs);
	});
var $author$project$App$Page$ResponseMsgs$CompanyListCanceledCacheCleared = {$: 0};
var $author$project$App$Page$ResponseMsgs$CompanyListCanceledCacheClearedViewDetails = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$CompanyListCanceledCompanyPeopleLoaded = {$: 5};
var $author$project$App$Page$Company$PageListCanceled$init = F2(
	function (route, st) {
		var logout = _Utils_Tuple3(
			{gx: $author$project$App$Data$Id$uuidNil, gD: $author$project$App$Page$Company$PageListCanceled$NoDetails, b: $author$project$App$Components$Filter$empty, bF: $elm$core$Dict$empty, bk: _List_Nil},
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
		var loadCompanyPeople = ($elm$core$List$length(
			$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)) <= 0) ? _List_fromArray(
			[
				$author$project$App$SharedState$LoadCompanyPeopleFromPerson(
				_List_fromArray(
					[
						$author$project$App$Page$ResponseMsgs$CompanyMsg(
						$author$project$App$Page$ResponseMsgs$CompanyListCanceledMsg($author$project$App$Page$ResponseMsgs$CompanyListCanceledCompanyPeopleLoaded))
					]))
			]) : _List_Nil;
		var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
		if (!_v0.$) {
			var token = _v0.a;
			var _v1 = _Utils_Tuple2(token.gx, route);
			if (!_v1.a.$) {
				if (!_v1.b.$) {
					var cId = _v1.a.a;
					var filter = _v1.b.a;
					return _Utils_Tuple3(
						{gx: cId, gD: $author$project$App$Page$Company$PageListCanceled$NoDetails, b: filter, bF: $elm$core$Dict$empty, bk: _List_Nil},
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$List$map,
							$author$project$App$SharedState$APIMsg,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$App$SharedState$ClearCache(
										_List_fromArray(
											[
												$author$project$App$Page$ResponseMsgs$CompanyMsg(
												$author$project$App$Page$ResponseMsgs$CompanyListCanceledMsg($author$project$App$Page$ResponseMsgs$CompanyListCanceledCacheCleared))
											]))
									]),
								loadCompanyPeople)));
				} else {
					var cId = _v1.a.a;
					var _v2 = _v1.b;
					var stmtId = _v2.a;
					var filter = _v2.b;
					return _Utils_Tuple3(
						{gx: cId, gD: $author$project$App$Page$Company$PageListCanceled$NoDetails, b: filter, bF: $elm$core$Dict$empty, bk: _List_Nil},
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$List$map,
							$author$project$App$SharedState$APIMsg,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$App$SharedState$ClearCache(
										_List_fromArray(
											[
												$author$project$App$Page$ResponseMsgs$CompanyMsg(
												$author$project$App$Page$ResponseMsgs$CompanyListCanceledMsg(
													$author$project$App$Page$ResponseMsgs$CompanyListCanceledCacheClearedViewDetails(stmtId)))
											]))
									]),
								loadCompanyPeople)));
				}
			} else {
				var _v3 = _v1.a;
				return logout;
			}
		} else {
			return logout;
		}
	});
var $author$project$App$Page$ResponseMsgs$CompanyListConcludedCacheCleared = {$: 0};
var $author$project$App$Page$ResponseMsgs$CompanyListConcludedCacheClearedViewDetails = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Company$PageListConcluded$init = F2(
	function (route, st) {
		var logout = _Utils_Tuple3(
			{gx: $author$project$App$Data$Id$uuidNil, gD: $author$project$App$Page$Company$PageListConcluded$NoDetails, b: $author$project$App$Components$Filter$empty, bF: $elm$core$Dict$empty, bk: _List_Nil},
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
		var loadCompanyPeople = ($elm$core$List$length(
			$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)) <= 0) ? _List_fromArray(
			[
				$author$project$App$SharedState$LoadCompanyPeopleFromPerson(
				_List_fromArray(
					[
						$author$project$App$Page$ResponseMsgs$CompanyMsg(
						$author$project$App$Page$ResponseMsgs$CompanyListConcludedMsg($author$project$App$Page$ResponseMsgs$CompanyListConcludedCompanyPeopleLoaded))
					]))
			]) : _List_Nil;
		var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
		if (!_v0.$) {
			var token = _v0.a;
			var _v1 = _Utils_Tuple2(token.gx, route);
			if (!_v1.a.$) {
				if (!_v1.b.$) {
					var cId = _v1.a.a;
					var filter = _v1.b.a;
					return _Utils_Tuple3(
						{gx: cId, gD: $author$project$App$Page$Company$PageListConcluded$NoDetails, b: filter, bF: $elm$core$Dict$empty, bk: _List_Nil},
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$List$map,
							$author$project$App$SharedState$APIMsg,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$App$SharedState$ClearCache(
										_List_fromArray(
											[
												$author$project$App$Page$ResponseMsgs$CompanyMsg(
												$author$project$App$Page$ResponseMsgs$CompanyListConcludedMsg($author$project$App$Page$ResponseMsgs$CompanyListConcludedCacheCleared))
											]))
									]),
								loadCompanyPeople)));
				} else {
					var cId = _v1.a.a;
					var _v2 = _v1.b;
					var stmtId = _v2.a;
					var filter = _v2.b;
					return _Utils_Tuple3(
						{gx: cId, gD: $author$project$App$Page$Company$PageListConcluded$NoDetails, b: filter, bF: $elm$core$Dict$empty, bk: _List_Nil},
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$List$map,
							$author$project$App$SharedState$APIMsg,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$App$SharedState$ClearCache(
										_List_fromArray(
											[
												$author$project$App$Page$ResponseMsgs$CompanyMsg(
												$author$project$App$Page$ResponseMsgs$CompanyListConcludedMsg(
													$author$project$App$Page$ResponseMsgs$CompanyListConcludedCacheClearedViewDetails(stmtId)))
											]))
									]),
								loadCompanyPeople)));
				}
			} else {
				var _v3 = _v1.a;
				return logout;
			}
		} else {
			return logout;
		}
	});
var $author$project$App$Page$ResponseMsgs$CompanyListPendingCacheCleared = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$CompanyListPendingCacheClearedViewDetails = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$App$Page$ResponseMsgs$CompanyListPendingCompanyPeopleLoaded = {$: 5};
var $author$project$App$Page$Company$PageListPending$init = F2(
	function (route, st) {
		var logout = _Utils_Tuple3(
			{
				gx: $author$project$App$Data$Id$uuidNil,
				b: $author$project$App$Page$Company$PageListPending$defaultFilter($author$project$App$Components$Filter$empty),
				bF: $elm$core$Dict$empty,
				aU: $elm$core$Maybe$Nothing,
				av: $elm$core$Maybe$Nothing,
				bk: _List_Nil
			},
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
		var loadCompanyPeople = ($elm$core$List$length(
			$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)) <= 0) ? _List_fromArray(
			[
				$author$project$App$SharedState$LoadCompanyPeopleFromPerson(
				_List_fromArray(
					[
						$author$project$App$Page$ResponseMsgs$CompanyMsg(
						$author$project$App$Page$ResponseMsgs$CompanyListPendingMsg($author$project$App$Page$ResponseMsgs$CompanyListPendingCompanyPeopleLoaded))
					]))
			]) : _List_Nil;
		var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
		if (!_v0.$) {
			var token = _v0.a;
			var _v1 = _Utils_Tuple2(token.gx, route);
			if (!_v1.a.$) {
				if (!_v1.b.$) {
					var cId = _v1.a.a;
					var filter = _v1.b.a;
					return _Utils_Tuple3(
						{
							gx: cId,
							b: $author$project$App$Page$Company$PageListPending$defaultFilter(filter),
							bF: $elm$core$Dict$empty,
							aU: $elm$core$Maybe$Nothing,
							av: $elm$core$Maybe$Nothing,
							bk: _List_Nil
						},
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$List$map,
							$author$project$App$SharedState$APIMsg,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$App$SharedState$ClearCache(
										_List_fromArray(
											[
												$author$project$App$Page$ResponseMsgs$CompanyMsg(
												$author$project$App$Page$ResponseMsgs$CompanyListPendingMsg(
													A2(
														$author$project$App$Page$ResponseMsgs$CompanyListPendingCacheCleared,
														cId,
														$author$project$App$Page$Company$PageListPending$defaultFilter(filter))))
											]))
									]),
								loadCompanyPeople)));
				} else {
					var cId = _v1.a.a;
					var _v2 = _v1.b;
					var stmtId = _v2.a;
					var filter = _v2.b;
					return _Utils_Tuple3(
						{
							gx: cId,
							b: $author$project$App$Page$Company$PageListPending$defaultFilter(filter),
							bF: $elm$core$Dict$empty,
							aU: $elm$core$Maybe$Nothing,
							av: $elm$core$Maybe$Nothing,
							bk: _List_Nil
						},
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$List$map,
							$author$project$App$SharedState$APIMsg,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$App$SharedState$ClearCache(
										_List_fromArray(
											[
												$author$project$App$Page$ResponseMsgs$CompanyMsg(
												$author$project$App$Page$ResponseMsgs$CompanyListPendingMsg(
													A3(
														$author$project$App$Page$ResponseMsgs$CompanyListPendingCacheClearedViewDetails,
														cId,
														stmtId,
														$author$project$App$Page$Company$PageListPending$defaultFilter(filter))))
											]))
									]),
								loadCompanyPeople)));
				}
			} else {
				var _v3 = _v1.a;
				return logout;
			}
		} else {
			return logout;
		}
	});
var $author$project$App$Page$ResponseMsgs$CompanyListReevaluationCacheCleared = {$: 0};
var $author$project$App$Page$Company$PageListReevaluation$defaultFilter = function (filter) {
	return A2($author$project$App$Components$Filter$setOrder, 'created_at,desc', filter);
};
var $author$project$App$Page$Company$PageListReevaluation$init = function (sharedState) {
	var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(sharedState._);
	if (!_v0.$) {
		var token = _v0.a;
		return _Utils_Tuple3(
			{
				bX: token.Y,
				gx: A2($elm$core$Maybe$withDefault, $author$project$App$Data$Id$uuidNil, token.gx),
				b: $author$project$App$Page$Company$PageListReevaluation$defaultFilter($author$project$App$Components$Filter$empty),
				bF: $elm$core$Dict$empty,
				aU: $elm$core$Maybe$Nothing,
				av: $elm$core$Maybe$Nothing,
				bk: _List_Nil
			},
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$List$map,
				$author$project$App$SharedState$APIMsg,
				_List_fromArray(
					[
						$author$project$App$SharedState$ClearCache(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$CompanyMsg(
								$author$project$App$Page$ResponseMsgs$CompanyListReevaluationMsg($author$project$App$Page$ResponseMsgs$CompanyListReevaluationCacheCleared))
							]))
					])));
	} else {
		return _Utils_Tuple3(
			{
				bX: $author$project$App$Data$Id$uuidNil,
				gx: $author$project$App$Data$Id$uuidNil,
				b: $author$project$App$Page$Company$PageListReevaluation$defaultFilter($author$project$App$Components$Filter$empty),
				bF: $elm$core$Dict$empty,
				aU: $elm$core$Maybe$Nothing,
				av: $elm$core$Maybe$Nothing,
				bk: _List_Nil
			},
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
	}
};
var $author$project$App$Page$ResponseMsgs$CompanyReevaluationICDCategoriesLoaded = 2;
var $author$project$App$Page$ResponseMsgs$CompanyReevaluationLoaded = 0;
var $author$project$App$Page$ResponseMsgs$CompanyReevaluationStatementMessagesLoaded = 3;
var $author$project$App$Page$Company$PageReevaluation$GotTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Company$PageReevaluation$Loading = 0;
var $author$project$App$Page$Company$PageReevaluation$TabDocument = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Company$PageReevaluation$AcQuestionIndex = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$Page$Company$PageReevaluation$emptyACModel = {
	fb: A2($author$project$App$Components$Autocomplete$init, '', 10),
	gt: A3($author$project$App$Page$Company$PageReevaluation$AcQuestionIndex, $author$project$App$Data$Id$uuidNil, $author$project$App$Data$Statement$Bearer, $elm$core$Maybe$Nothing)
};
var $author$project$App$Page$Company$PageReevaluation$init = F2(
	function (stmtId, sharedState) {
		var currentPersonId = function () {
			var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(sharedState._);
			if (!_v0.$) {
				var token = _v0.a;
				return token.Y;
			} else {
				return $author$project$App$Data$Id$uuidNil;
			}
		}();
		var newChatBoxModel = A2($author$project$App$Components$Chatbox$init, currentPersonId, true);
		return _Utils_Tuple3(
			{
				f8: $author$project$App$Page$Company$PageReevaluation$emptyACModel,
				aZ: $elm$core$Dict$empty,
				aM: newChatBoxModel,
				fa: _List_Nil,
				bs: _List_Nil,
				b5: $author$project$App$Page$Company$PageReevaluation$TabDocument(0),
				an: $elm$core$Dict$empty,
				gH: _List_Nil,
				P: $author$project$App$Data$FullStatement$emptyFullStatement,
				ew: $elm$core$Maybe$Nothing,
				ey: $elm$time$Time$millisToPosix(0),
				dC: false,
				eN: '',
				cA: $elm$core$Dict$empty,
				h$: false,
				h0: false,
				h1: $elm$core$Maybe$Nothing,
				h2: '',
				h3: false,
				p: 0,
				eV: stmtId,
				fX: $elm$core$Dict$empty,
				e$: $author$project$App$Service$Loadable$NotLoaded
			},
			A2($elm$core$Task$perform, $author$project$App$Page$Company$PageReevaluation$GotTime, $elm$time$Time$now),
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg(
					A3(
						$author$project$App$SharedState$LoadFullStatementWithMinors,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$SharedState$FullStmtAssetAnswers(1),
								$author$project$App$SharedState$FullStmtAssetQuestions(1),
								$author$project$App$SharedState$FullStmtAssetAnswers(2),
								$author$project$App$SharedState$FullStmtAssetQuestions(2),
								$author$project$App$SharedState$FullStmtAssetAnswers(3),
								$author$project$App$SharedState$FullStmtAssetQuestions(3),
								$author$project$App$SharedState$FullStmtAssetDocuments,
								$author$project$App$SharedState$FullStmtAssetICD
							]),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$CompanyMsg(
								$author$project$App$Page$ResponseMsgs$CompanyReevaluationMsg(0))
							]))),
					$author$project$App$SharedState$APIMsg(
					$author$project$App$SharedState$GetICDCategories(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$CompanyMsg(
								$author$project$App$Page$ResponseMsgs$CompanyReevaluationMsg(2))
							]))),
					$author$project$App$SharedState$APIMsg(
					A2(
						$author$project$App$SharedState$GetStatementMessages,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$CompanyMsg(
								$author$project$App$Page$ResponseMsgs$CompanyReevaluationMsg(3))
							])))
				]));
	});
var $author$project$App$Page$ResponseMsgs$CompanyRequestInterviewCacheCleared = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Company$PageRequestInterview$GotTime = function (a) {
	return {$: 19, a: a};
};
var $author$project$App$Page$Company$PageRequestInterview$init = F2(
	function (route, st) {
		var _v0 = function () {
			var _v1 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
			if (!_v1.$) {
				var token = _v1.a;
				var _v2 = token.gx;
				if (!_v2.$) {
					var companyId = _v2.a;
					return _Utils_Tuple2(
						companyId,
						_List_fromArray(
							[
								$author$project$App$SharedState$ClearCache(
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$CompanyMsg(
										$author$project$App$Page$ResponseMsgs$CompanyRequestInterviewMsg(
											$author$project$App$Page$ResponseMsgs$CompanyRequestInterviewCacheCleared(companyId)))
									]))
							]));
				} else {
					return _Utils_Tuple2(
						$author$project$App$Data$Id$uuidNil,
						_List_fromArray(
							[$author$project$App$SharedState$Logout]));
				}
			} else {
				return _Utils_Tuple2(
					$author$project$App$Data$Id$uuidNil,
					_List_fromArray(
						[$author$project$App$SharedState$Logout]));
			}
		}();
		var cId = _v0.a;
		var cacheMsgs = _v0.b;
		var modelForm = {
			gx: cId,
			d9: false,
			b5: $author$project$App$Components$ViewFullStatement$TabDocument(0),
			fp: $elm$core$Dict$empty,
			a5: $author$project$App$Data$FullStatement$emptyFullStatement,
			ep: false,
			ey: $elm$time$Time$millisToPosix(0),
			cH: 0,
			eZ: _List_Nil
		};
		var uModel = function () {
			if (route.$ === 1) {
				var stmtId = route.a;
				return A2($author$project$App$Page$Company$PageRequestInterview$Edit, stmtId, modelForm);
			} else {
				return $author$project$App$Page$Company$PageRequestInterview$New(
					_Utils_update(
						modelForm,
						{ep: true}));
			}
		}();
		return _Utils_Tuple3(
			uModel,
			A2($elm$core$Task$perform, $author$project$App$Page$Company$PageRequestInterview$GotTime, $elm$time$Time$now),
			A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, cacheMsgs));
	});
var $author$project$App$Page$ResponseMsgs$CompanyViewEvaluationICDCategoriesLoaded = 1;
var $author$project$App$Page$ResponseMsgs$CompanyViewEvaluationMessagesLoaded = 3;
var $author$project$App$Page$ResponseMsgs$CompanyViewEvaluationStatementLoaded = 0;
var $author$project$App$Page$Company$PageViewEvaluation$GotTime = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Company$PageViewEvaluation$Loading = 0;
var $author$project$App$Page$Company$PageViewEvaluation$TabDocument = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Company$PageViewEvaluation$AcQuestionIndex = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$Page$Company$PageViewEvaluation$emptyACModel = {
	fb: A2($author$project$App$Components$Autocomplete$init, '', 10),
	gt: A3($author$project$App$Page$Company$PageViewEvaluation$AcQuestionIndex, $author$project$App$Data$Id$uuidNil, $author$project$App$Data$Statement$Bearer, $elm$core$Maybe$Nothing)
};
var $author$project$App$Page$Company$PageViewEvaluation$init = F2(
	function (stmtId, sharedState) {
		var currentPersonId = function () {
			var _v2 = $author$project$App$Service$ApplicationResources$getAuthToken(sharedState._);
			if (!_v2.$) {
				var token = _v2.a;
				return token.Y;
			} else {
				return $author$project$App$Data$Id$uuidNil;
			}
		}();
		var newChatBoxModel = A2($author$project$App$Components$Chatbox$init, currentPersonId, false);
		var _v0 = $author$project$App$Data$Nexodata$init;
		var initNexodata = _v0.a;
		var nCmd = _v0.b;
		var _v1 = $author$project$App$Data$Memed$init;
		var initMemed = _v1.a;
		var mCmd = _v1.b;
		return _Utils_Tuple3(
			{
				f8: $author$project$App$Page$Company$PageViewEvaluation$emptyACModel,
				aZ: $elm$core$Dict$empty,
				aM: newChatBoxModel,
				fa: _List_Nil,
				bs: _List_Nil,
				b5: $author$project$App$Page$Company$PageViewEvaluation$TabDocument(0),
				an: $elm$core$Dict$empty,
				gH: _List_Nil,
				P: $author$project$App$Data$FullStatement$emptyFullStatement,
				hi: initMemed,
				ew: $elm$core$Maybe$Nothing,
				ho: initNexodata,
				ey: $elm$time$Time$millisToPosix(0),
				dC: false,
				eN: '',
				cA: $elm$core$Dict$empty,
				h$: false,
				h0: false,
				h1: $elm$core$Maybe$Nothing,
				h2: '',
				h3: false,
				p: 0,
				eV: stmtId,
				fX: $elm$core$Dict$empty,
				e$: $author$project$App$Service$Loadable$NotLoaded
			},
			A2($elm$core$Task$perform, $author$project$App$Page$Company$PageViewEvaluation$GotTime, $elm$time$Time$now),
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg(
					A3(
						$author$project$App$SharedState$LoadFullStatementWithMinors,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$SharedState$FullStmtAssetAnswers(1),
								$author$project$App$SharedState$FullStmtAssetQuestions(1),
								$author$project$App$SharedState$FullStmtAssetAnswers(2),
								$author$project$App$SharedState$FullStmtAssetQuestions(2),
								$author$project$App$SharedState$FullStmtAssetAnswers(3),
								$author$project$App$SharedState$FullStmtAssetQuestions(3),
								$author$project$App$SharedState$FullStmtAssetDocuments,
								$author$project$App$SharedState$FullStmtAssetICD
							]),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$CompanyMsg(
								$author$project$App$Page$ResponseMsgs$CompanyViewEvaluationMsg(0))
							]))),
					$author$project$App$SharedState$APIMsg(
					$author$project$App$SharedState$GetICDCategories(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$CompanyMsg(
								$author$project$App$Page$ResponseMsgs$CompanyViewEvaluationMsg(1))
							]))),
					$author$project$App$SharedState$APIMsg(
					A2(
						$author$project$App$SharedState$GetStatementMessages,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$CompanyMsg(
								$author$project$App$Page$ResponseMsgs$CompanyViewEvaluationMsg(3))
							])))
				]));
	});
var $author$project$App$Page$PageCompany$init = F2(
	function (route, st) {
		var _v0 = function () {
			switch (route.$) {
				case 1:
					var listPendingRoute = route.a;
					return function (_v2) {
						var p = _v2.a;
						var c = _v2.b;
						var m = _v2.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageCompany$ListPending(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListPendingMsg, c),
							m);
					}(
						A2($author$project$App$Page$Company$PageListPending$init, listPendingRoute, st));
				case 2:
					var listCanceledRoute = route.a;
					return function (_v3) {
						var p = _v3.a;
						var c = _v3.b;
						var m = _v3.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageCompany$ListCanceled(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListCanceledMsg, c),
							m);
					}(
						A2($author$project$App$Page$Company$PageListCanceled$init, listCanceledRoute, st));
				case 3:
					var listConcludedRoute = route.a;
					return function (_v4) {
						var p = _v4.a;
						var c = _v4.b;
						var m = _v4.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageCompany$ListConcluded(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListConcludedMsg, c),
							m);
					}(
						A2($author$project$App$Page$Company$PageListConcluded$init, listConcludedRoute, st));
				case 0:
					var pageRoute = route.a;
					return function (_v5) {
						var p = _v5.a;
						var c = _v5.b;
						var m = _v5.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageCompany$RequestInterview(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$RequestInterviewMsg, c),
							m);
					}(
						A2($author$project$App$Page$Company$PageRequestInterview$init, pageRoute, st));
				case 4:
					var stmtId = route.a;
					var _v6 = A2($author$project$App$Page$Company$PageViewEvaluation$init, stmtId, st);
					var pModel = _v6.a;
					var pageMsg = _v6.b;
					var listMsgs = _v6.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$ViewEvaluation(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ViewEvaluationMsg, pageMsg),
						listMsgs);
				case 5:
					var _v7 = $author$project$App$Page$Company$PageListReevaluation$init(st);
					var pModel = _v7.a;
					var pageMsg = _v7.b;
					var listMsgs = _v7.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$ListReevaluation(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListReevaluationMsg, pageMsg),
						listMsgs);
				default:
					var stmtId = route.a;
					var _v8 = A2($author$project$App$Page$Company$PageReevaluation$init, stmtId, st);
					var pModel = _v8.a;
					var pageMsg = _v8.b;
					var listMsgs = _v8.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$Reevaluation(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ReevaluationMsg, pageMsg),
						listMsgs);
			}
		}();
		var pageModel = _v0.a;
		var pageCmd = _v0.b;
		var stMsgs = _v0.c;
		return _Utils_Tuple3(pageModel, pageCmd, stMsgs);
	});
var $author$project$App$Page$ResponseMsgs$DoctorCurrentInterviewLoaded = 0;
var $author$project$App$Page$ResponseMsgs$DoctorLoadingMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Doctor$PageLoading$init = F2(
	function (route, st) {
		return _Utils_Tuple3(
			{ep: false, f5: route},
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg(
					$author$project$App$SharedState$LoadDoctorInterview(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
								$author$project$App$Page$ResponseMsgs$DoctorLoadingMsg(0))
							])))
				]));
	});
var $author$project$App$Page$PageDoctor$init = F2(
	function (route, st) {
		var _v0 = A2($author$project$App$Page$Doctor$PageLoading$init, route, st);
		var pageModel = _v0.a;
		var pageCmd = _v0.b;
		var stMsgs = _v0.c;
		return _Utils_Tuple3(
			{
				aQ: $author$project$App$Page$PageDoctor$Loading(pageModel)
			},
			A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$LoadingMsg, pageCmd),
			stMsgs);
	});
var $author$project$App$Page$PageDoctorSignUp$Filling = {$: 0};
var $author$project$App$Data$DigitalAccount$emptyModel = {
	ak: {d_: '', d$: '', d1: '', d4: ''},
	b4: {cS: '', al: '', bp: '', b2: '', iR: '', fd: 'MEI', ff: 'BR', ef: '', i1: '', c7: '', bb: '', hs: '', dx: '', cw: '', p: ''},
	dm: '',
	dn: '',
	et: 0,
	ev: 0,
	dw: {al: '', i1: '', cp: '', hl: '', dJ: '', ii: ''}
};
var $author$project$App$Page$PageDoctorSignUp$init = function () {
	var doctorEmptyModel = $author$project$App$Data$Doctor$emptyModel;
	return _Utils_Tuple2(
		{
			cW: false,
			O: $author$project$App$Data$DigitalAccount$emptyModel,
			bu: _Utils_update(
				doctorEmptyModel,
				{dJ: 1}),
			es: _List_Nil,
			eu: _List_Nil,
			dx: $author$project$App$Data$Contact$emptyMobile,
			aw: $author$project$App$Page$PageDoctorSignUp$Filling,
			bS: $author$project$App$Data$User$emptyModel
		},
		_List_fromArray(
			[
				$author$project$App$SharedState$APIMsg(
				$author$project$App$SharedState$GetMemedSpecialties(
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$DoctorSignUpMsg($author$project$App$Page$ResponseMsgs$DoctorSignUpSpecialtiesLoaded)
						])))
			]));
}();
var $author$project$App$Page$PageLogin$init = function () {
	var _v0 = $author$project$App$Components$Payment$init;
	var paymentModel = _v0.a;
	var paymentCmd = _v0.b;
	return _Utils_Tuple2(
		{hv: paymentModel, e$: $author$project$App$Service$Loadable$NotLoaded, bS: $author$project$App$Data$User$emptyModel},
		_List_fromArray(
			[
				$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
			]));
}();
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$GotTemplates = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$GotTime = function (a) {
	return {$: 15, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$NoDetails = {$: 0};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$NoDocumentDetails = {$: 0};
var $author$project$App$Page$ResponseMsgs$ReviewEditStatementCacheCleared = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$init = F2(
	function (route, st) {
		var newCompanyId = function () {
			var _v2 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
			if (!_v2.$) {
				var token = _v2.a;
				var _v3 = token.gx;
				if (!_v3.$) {
					var companyId = _v3.a;
					return companyId;
				} else {
					return $author$project$App$Data$Id$uuidNil;
				}
			} else {
				return $author$project$App$Data$Id$uuidNil;
			}
		}();
		var _v0 = _Utils_Tuple2(
			$author$project$App$Data$Id$uuidNil,
			_List_fromArray(
				[
					$author$project$App$SharedState$ClearCache(
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$ReviewMsg(
							$author$project$App$Page$ResponseMsgs$ReviewEditStatementMsg(
								$author$project$App$Page$ResponseMsgs$ReviewEditStatementCacheCleared($author$project$App$Data$Id$uuidNil)))
						]))
				]));
		var cId = _v0.a;
		var cacheMsgs = _v0.b;
		var modelForm = {
			gx: cId,
			d9: false,
			b5: $author$project$App$Components$ViewFullStatement$TabDocument(0),
			fp: $elm$core$Dict$empty,
			a5: $author$project$App$Data$FullStatement$emptyFullStatement,
			ep: false,
			ey: $elm$time$Time$millisToPosix(0),
			cH: 0,
			eZ: _List_Nil
		};
		var emptyModel = {
			gx: cId,
			b5: $author$project$App$Components$ViewFullStatement$TabDocument(0),
			gD: $author$project$App$Page$Reviewer$EditStatement$StatementEdit$NoDetails,
			gK: $author$project$App$Page$Reviewer$EditStatement$DocumentView$TabDocument(0),
			ac: $author$project$App$Page$Reviewer$EditStatement$StatementEdit$NoDocumentDetails,
			fp: $elm$core$Dict$empty,
			b: $author$project$App$Components$Filter$empty,
			ej: modelForm,
			as: $author$project$App$Data$Id$uuidNil,
			a6: false,
			y: false,
			ep: false,
			cq: $elm$time$Time$millisToPosix(0),
			F: $elm$core$Maybe$Nothing,
			ey: $elm$time$Time$millisToPosix(0),
			dC: false,
			dD: $author$project$App$Route$Private(
				$author$project$App$Route$Review(
					$author$project$App$Route$ReviewListIncompleteRoute(
						$author$project$App$Route$ReviewListIncomplete($author$project$App$Components$Filter$empty)))),
			h8: $author$project$App$Data$FullStatement$emptyFullStatement,
			cH: 0,
			eZ: _List_Nil
		};
		var uModel = function () {
			if (!route.$) {
				var stmtId = route.a;
				var returnFilter = route.b;
				return _Utils_update(
					emptyModel,
					{
						b: returnFilter,
						as: stmtId,
						dD: $author$project$App$Route$Private(
							$author$project$App$Route$Review(
								$author$project$App$Route$ReviewListIncompleteRoute(
									$author$project$App$Route$ReviewListIncomplete(returnFilter))))
					});
			} else {
				var stmtId = route.a;
				var returnFilter = route.b;
				return _Utils_update(
					emptyModel,
					{
						b: returnFilter,
						as: stmtId,
						dD: $author$project$App$Route$Private(
							$author$project$App$Route$Review(
								$author$project$App$Route$ReviewListApprovedRoute(
									$author$project$App$Route$ReviewListApproved(returnFilter))))
					});
			}
		}();
		return _Utils_Tuple3(
			uModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$App$Page$Reviewer$EditStatement$StatementEdit$GotTime, $elm$time$Time$now),
						A3(
						$author$project$App$Service$StatementTemplateRest$getFromCompany,
						$author$project$App$Service$ApiWebSocket$getAuthToken(st._),
						newCompanyId,
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$GotTemplates)
					])),
			A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, cacheMsgs));
	});
var $author$project$App$Page$ResponseMsgs$ReviewListApprovedCacheCleared = {$: 0};
var $author$project$App$Page$ResponseMsgs$ReviewListApprovedCacheClearedViewDetails = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewListApprovedCompanyPeopleLoaded = {$: 6};
var $author$project$App$Page$Reviewer$PageListApproved$init = F2(
	function (route, st) {
		var logout = _Utils_Tuple3(
			{gx: $author$project$App$Data$Id$uuidNil, gD: $author$project$App$Page$Reviewer$PageListApproved$NoDetails, b9: _List_Nil, b: $author$project$App$Components$Filter$empty, bF: $elm$core$Dict$empty, bk: _List_Nil},
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
		var loadCompanyPeople = ($elm$core$List$length(
			$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)) <= 0) ? _List_fromArray(
			[
				$author$project$App$SharedState$LoadCompanyPeopleFromPerson(
				_List_fromArray(
					[
						$author$project$App$Page$ResponseMsgs$ReviewMsg(
						$author$project$App$Page$ResponseMsgs$ReviewListApprovedMsg($author$project$App$Page$ResponseMsgs$ReviewListApprovedCompanyPeopleLoaded))
					]))
			]) : _List_Nil;
		var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
		if (!_v0.$) {
			var token = _v0.a;
			var cId = A2($elm$core$Maybe$withDefault, $author$project$App$Data$Id$uuidNil, token.gx);
			if (!route.$) {
				var filter = route.a;
				return _Utils_Tuple3(
					{gx: cId, gD: $author$project$App$Page$Reviewer$PageListApproved$NoDetails, b9: _List_Nil, b: filter, bF: $elm$core$Dict$empty, bk: _List_Nil},
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$App$SharedState$ClearCache(
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$ReviewMsg(
											$author$project$App$Page$ResponseMsgs$ReviewListApprovedMsg($author$project$App$Page$ResponseMsgs$ReviewListApprovedCacheCleared))
										]))
								]),
							loadCompanyPeople)));
			} else {
				var stmtId = route.a;
				var filter = route.b;
				return _Utils_Tuple3(
					{gx: cId, gD: $author$project$App$Page$Reviewer$PageListApproved$NoDetails, b9: _List_Nil, b: filter, bF: $elm$core$Dict$empty, bk: _List_Nil},
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$App$SharedState$ClearCache(
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$ReviewMsg(
											$author$project$App$Page$ResponseMsgs$ReviewListApprovedMsg(
												$author$project$App$Page$ResponseMsgs$ReviewListApprovedCacheClearedViewDetails(stmtId)))
										]))
								]),
							loadCompanyPeople)));
			}
		} else {
			return logout;
		}
	});
var $author$project$App$Page$ResponseMsgs$ReviewListForReviewCacheCleared = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewListForReviewCacheClearedViewDetails = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$Reviewer$PageListForReview$defaultFilter = function (filter) {
	return A2(
		$author$project$App$Components$Filter$setOrder,
		'created_at,desc',
		A2(
			$author$project$App$Components$Filter$addRule,
			'status,<=,' + $author$project$App$Data$StatementStatus$statusToString(25),
			filter));
};
var $author$project$App$Page$Reviewer$PageListForReview$init = F2(
	function (route, st) {
		var logout = _Utils_Tuple3(
			{
				gx: $author$project$App$Data$Id$uuidNil,
				b9: _List_Nil,
				b: $author$project$App$Page$Reviewer$PageListForReview$defaultFilter($author$project$App$Components$Filter$empty),
				bF: $elm$core$Dict$empty,
				aU: $elm$core$Maybe$Nothing,
				av: $elm$core$Maybe$Nothing,
				bk: _List_Nil
			},
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
		var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
		if (!_v0.$) {
			var token = _v0.a;
			var cId = A2($elm$core$Maybe$withDefault, $author$project$App$Data$Id$uuidNil, token.gx);
			if (!route.$) {
				var filter = route.a;
				return _Utils_Tuple3(
					{
						gx: cId,
						b9: _List_Nil,
						b: $author$project$App$Page$Reviewer$PageListForReview$defaultFilter(filter),
						bF: $elm$core$Dict$empty,
						aU: $elm$core$Maybe$Nothing,
						av: $elm$core$Maybe$Nothing,
						bk: _List_Nil
					},
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								$author$project$App$SharedState$ClearCache(
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewListForReviewMsg(
											$author$project$App$Page$ResponseMsgs$ReviewListForReviewCacheCleared(
												$author$project$App$Page$Reviewer$PageListForReview$defaultFilter(filter))))
									]))
							])));
			} else {
				var stmtId = route.a;
				var filter = route.b;
				return _Utils_Tuple3(
					{
						gx: cId,
						b9: _List_Nil,
						b: $author$project$App$Page$Reviewer$PageListForReview$defaultFilter(filter),
						bF: $elm$core$Dict$empty,
						aU: $elm$core$Maybe$Nothing,
						av: $elm$core$Maybe$Nothing,
						bk: _List_Nil
					},
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								$author$project$App$SharedState$ClearCache(
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$ReviewMsg(
										$author$project$App$Page$ResponseMsgs$ReviewListForReviewMsg(
											A2(
												$author$project$App$Page$ResponseMsgs$ReviewListForReviewCacheClearedViewDetails,
												stmtId,
												$author$project$App$Page$Reviewer$PageListForReview$defaultFilter(filter))))
									]))
							])));
			}
		} else {
			return logout;
		}
	});
var $author$project$App$Page$Reviewer$PageListIncomplete$GotTime = function (a) {
	return {$: 12, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewListIncompleteCacheCleared = {$: 0};
var $author$project$App$Page$ResponseMsgs$ReviewListIncompleteCacheClearedViewDetails = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$ReviewListIncompleteCompanyPeopleLoaded = {$: 7};
var $author$project$App$Page$Reviewer$PageListIncomplete$init = F2(
	function (route, st) {
		var logout = _Utils_Tuple3(
			{
				gx: $author$project$App$Data$Id$uuidNil,
				gD: $author$project$App$Page$Reviewer$PageListIncomplete$NoDetails,
				b9: _List_Nil,
				b: $author$project$App$Components$Filter$empty,
				ey: $elm$time$Time$millisToPosix(0),
				bF: $elm$core$Dict$empty,
				dC: false,
				h8: $author$project$App$Data$Statement$emptyModel,
				bk: _List_Nil
			},
			A2($elm$core$Task$perform, $author$project$App$Page$Reviewer$PageListIncomplete$GotTime, $elm$time$Time$now),
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
		var loadCompanyPeople = ($elm$core$List$length(
			$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)) <= 0) ? _List_fromArray(
			[
				$author$project$App$SharedState$LoadCompanyPeopleFromPerson(
				_List_fromArray(
					[
						$author$project$App$Page$ResponseMsgs$ReviewMsg(
						$author$project$App$Page$ResponseMsgs$ReviewListIncompleteMsg($author$project$App$Page$ResponseMsgs$ReviewListIncompleteCompanyPeopleLoaded))
					]))
			]) : _List_Nil;
		var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
		if (!_v0.$) {
			var token = _v0.a;
			var cId = A2($elm$core$Maybe$withDefault, $author$project$App$Data$Id$uuidNil, token.gx);
			if (!route.$) {
				var filter = route.a;
				return _Utils_Tuple3(
					{
						gx: cId,
						gD: $author$project$App$Page$Reviewer$PageListIncomplete$NoDetails,
						b9: _List_Nil,
						b: filter,
						ey: $elm$time$Time$millisToPosix(0),
						bF: $elm$core$Dict$empty,
						dC: false,
						h8: $author$project$App$Data$Statement$emptyModel,
						bk: _List_Nil
					},
					A2($elm$core$Task$perform, $author$project$App$Page$Reviewer$PageListIncomplete$GotTime, $elm$time$Time$now),
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$App$SharedState$ClearCache(
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$ReviewMsg(
											$author$project$App$Page$ResponseMsgs$ReviewListIncompleteMsg($author$project$App$Page$ResponseMsgs$ReviewListIncompleteCacheCleared))
										]))
								]),
							loadCompanyPeople)));
			} else {
				var stmtId = route.a;
				var filter = route.b;
				return _Utils_Tuple3(
					{
						gx: cId,
						gD: $author$project$App$Page$Reviewer$PageListIncomplete$NoDetails,
						b9: _List_Nil,
						b: filter,
						ey: $elm$time$Time$millisToPosix(0),
						bF: $elm$core$Dict$empty,
						dC: false,
						h8: $author$project$App$Data$Statement$emptyModel,
						bk: _List_Nil
					},
					A2($elm$core$Task$perform, $author$project$App$Page$Reviewer$PageListIncomplete$GotTime, $elm$time$Time$now),
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$App$SharedState$ClearCache(
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$ReviewMsg(
											$author$project$App$Page$ResponseMsgs$ReviewListIncompleteMsg(
												$author$project$App$Page$ResponseMsgs$ReviewListIncompleteCacheClearedViewDetails(stmtId)))
										]))
								]),
							loadCompanyPeople)));
			}
		} else {
			return logout;
		}
	});
var $author$project$App$Page$ResponseMsgs$ReviewListReevaluationCacheCleared = {$: 0};
var $author$project$App$Page$Reviewer$PageListReevaluation$defaultFilter = function (filter) {
	return A2($author$project$App$Components$Filter$setOrder, 'created_at,desc', filter);
};
var $author$project$App$Page$Reviewer$PageListReevaluation$init = function (sharedState) {
	var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(sharedState._);
	if (!_v0.$) {
		var token = _v0.a;
		return _Utils_Tuple3(
			{
				bX: token.Y,
				gx: A2($elm$core$Maybe$withDefault, $author$project$App$Data$Id$uuidNil, token.gx),
				b9: _List_Nil,
				b: $author$project$App$Page$Reviewer$PageListReevaluation$defaultFilter($author$project$App$Components$Filter$empty),
				bF: $elm$core$Dict$empty,
				aU: $elm$core$Maybe$Nothing,
				av: $elm$core$Maybe$Nothing,
				bk: _List_Nil
			},
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$List$map,
				$author$project$App$SharedState$APIMsg,
				_List_fromArray(
					[
						$author$project$App$SharedState$ClearCache(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$ReviewMsg(
								$author$project$App$Page$ResponseMsgs$ReviewListReevaluationMsg($author$project$App$Page$ResponseMsgs$ReviewListReevaluationCacheCleared))
							]))
					])));
	} else {
		return _Utils_Tuple3(
			{
				bX: $author$project$App$Data$Id$uuidNil,
				gx: $author$project$App$Data$Id$uuidNil,
				b9: _List_Nil,
				b: $author$project$App$Page$Reviewer$PageListReevaluation$defaultFilter($author$project$App$Components$Filter$empty),
				bF: $elm$core$Dict$empty,
				aU: $elm$core$Maybe$Nothing,
				av: $elm$core$Maybe$Nothing,
				bk: _List_Nil
			},
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
	}
};
var $author$project$App$Page$Reviewer$PageReevaluation$GotTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Reviewer$PageReevaluation$Loading = 0;
var $author$project$App$Page$ResponseMsgs$ReviewReevaluationICDCategoriesLoaded = 2;
var $author$project$App$Page$ResponseMsgs$ReviewReevaluationLoaded = 0;
var $author$project$App$Page$ResponseMsgs$ReviewReevaluationStatementMessagesLoaded = 3;
var $author$project$App$Page$Reviewer$PageReevaluation$TabCIDs = {$: 0};
var $author$project$App$Page$Reviewer$PageReevaluation$AcQuestionIndex = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$Page$Reviewer$PageReevaluation$emptyACModel = {
	fb: A2($author$project$App$Components$Autocomplete$init, '', 10),
	gt: A3($author$project$App$Page$Reviewer$PageReevaluation$AcQuestionIndex, $author$project$App$Data$Id$uuidNil, $author$project$App$Data$Statement$Bearer, $elm$core$Maybe$Nothing)
};
var $author$project$App$Page$Reviewer$PageReevaluation$init = F2(
	function (stmtId, sharedState) {
		var currentPersonId = function () {
			var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(sharedState._);
			if (!_v0.$) {
				var token = _v0.a;
				return token.Y;
			} else {
				return $author$project$App$Data$Id$uuidNil;
			}
		}();
		var newChatBoxModel = A2($author$project$App$Components$Chatbox$init, currentPersonId, true);
		return _Utils_Tuple3(
			{
				f8: $author$project$App$Page$Reviewer$PageReevaluation$emptyACModel,
				aZ: $elm$core$Dict$empty,
				aM: newChatBoxModel,
				fa: _List_Nil,
				bs: _List_Nil,
				b5: $author$project$App$Page$Reviewer$PageReevaluation$TabCIDs,
				an: $elm$core$Dict$empty,
				gH: _List_Nil,
				P: $author$project$App$Data$FullStatement$emptyFullStatement,
				ey: $elm$time$Time$millisToPosix(0),
				dC: false,
				eN: '',
				cA: $elm$core$Dict$empty,
				h$: false,
				h0: false,
				h1: $elm$core$Maybe$Nothing,
				h2: '',
				h3: false,
				p: 0,
				eV: stmtId,
				fX: $elm$core$Dict$empty,
				e$: $author$project$App$Service$Loadable$NotLoaded
			},
			A2($elm$core$Task$perform, $author$project$App$Page$Reviewer$PageReevaluation$GotTime, $elm$time$Time$now),
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg(
					A3(
						$author$project$App$SharedState$LoadFullStatementWithMinors,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$SharedState$FullStmtAssetAnswers(1),
								$author$project$App$SharedState$FullStmtAssetQuestions(1),
								$author$project$App$SharedState$FullStmtAssetAnswers(2),
								$author$project$App$SharedState$FullStmtAssetQuestions(2),
								$author$project$App$SharedState$FullStmtAssetAnswers(3),
								$author$project$App$SharedState$FullStmtAssetQuestions(3),
								$author$project$App$SharedState$FullStmtAssetDocuments,
								$author$project$App$SharedState$FullStmtAssetICD
							]),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$ReviewMsg(
								$author$project$App$Page$ResponseMsgs$ReviewReevaluationMsg(0))
							]))),
					$author$project$App$SharedState$APIMsg(
					$author$project$App$SharedState$GetICDCategories(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$ReviewMsg(
								$author$project$App$Page$ResponseMsgs$ReviewReevaluationMsg(2))
							]))),
					$author$project$App$SharedState$APIMsg(
					A2(
						$author$project$App$SharedState$GetStatementMessages,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$ReviewMsg(
								$author$project$App$Page$ResponseMsgs$ReviewReevaluationMsg(3))
							])))
				]));
	});
var $author$project$App$Page$Reviewer$PageReview$GotTime = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Page$Reviewer$PageReview$No = 0;
var $author$project$App$Page$Reviewer$PageReview$NoDetails = {$: 0};
var $author$project$App$Page$ResponseMsgs$ReviewInterviewCacheCleared = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Reviewer$PageReview$init = F2(
	function (route, st) {
		var _v0 = _Utils_Tuple2(
			$author$project$App$Data$Id$uuidNil,
			_List_fromArray(
				[
					$author$project$App$SharedState$ClearCache(
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$ReviewMsg(
							$author$project$App$Page$ResponseMsgs$ReviewInterviewMsg(
								$author$project$App$Page$ResponseMsgs$ReviewInterviewCacheCleared($author$project$App$Data$Id$uuidNil)))
						]))
				]));
		var cId = _v0.a;
		var cacheMsgs = _v0.b;
		var emptyModel = {
			gx: cId,
			b5: $author$project$App$Components$ViewFullStatement$TabDocument(0),
			gD: $author$project$App$Page$Reviewer$PageReview$NoDetails,
			fp: $elm$core$Dict$empty,
			b: $author$project$App$Components$Filter$empty,
			as: $author$project$App$Data$Id$uuidNil,
			ep: false,
			ey: $elm$time$Time$millisToPosix(0),
			dC: false,
			h8: $author$project$App$Data$FullStatement$emptyFullStatement,
			cH: 0,
			eZ: _List_Nil
		};
		var uModel = function () {
			var stmtId = route;
			return _Utils_update(
				emptyModel,
				{as: stmtId});
		}();
		return _Utils_Tuple3(
			uModel,
			A2($elm$core$Task$perform, $author$project$App$Page$Reviewer$PageReview$GotTime, $elm$time$Time$now),
			A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, cacheMsgs));
	});
var $author$project$App$Page$ResponseMsgs$ReviewWaitingLineCacheCleared = {$: 0};
var $author$project$App$Components$Sound$CheckInit = {$: 0};
var $author$project$App$Components$Sound$init = _Utils_Tuple2(
	$author$project$App$Components$Sound$Disabled,
	A2(
		$elm$core$Task$perform,
		$elm$core$Basics$always($author$project$App$Components$Sound$CheckInit),
		$elm$core$Process$sleep(10.0)));
var $author$project$App$Page$Reviewer$PageWaitingLine$init = function (st) {
	var _v0 = $author$project$App$Components$Sound$init;
	var sound = _v0.a;
	var soundCmd = _v0.b;
	return _Utils_Tuple3(
		{
			c5: _List_Nil,
			df: $elm$time$Time$posixToMillis(
				$author$project$App$Service$ApiWebSocket$getTimestamp(st._)),
			Y: $author$project$App$Data$Id$uuidNil,
			N: sound,
			e1: _List_Nil
		},
		A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Reviewer$PageWaitingLine$SoundMsg, soundCmd),
		_List_fromArray(
			[
				$author$project$App$SharedState$APIMsg(
				$author$project$App$SharedState$ClearCache(
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$ReviewMsg(
							$author$project$App$Page$ResponseMsgs$ReviewWaitingLineMsg($author$project$App$Page$ResponseMsgs$ReviewWaitingLineCacheCleared))
						]))),
				$author$project$App$SharedState$VideoMsg($author$project$App$SharedState$StopConference)
			]));
};
var $author$project$App$Page$Reviewer$PageWaitingProponentDetails$Loading = 0;
var $author$project$App$Page$ResponseMsgs$ReviewerCompanyPeopleLoaded = 4;
var $author$project$App$Page$ResponseMsgs$ReviewerCurrentReviewLoaded = 0;
var $author$project$App$Components$ViewFullStatement$empty = {
	bs: $elm$core$Dict$empty,
	cZ: $elm$core$Maybe$Nothing,
	b5: $author$project$App$Components$ViewFullStatement$TabProfile,
	bu: $elm$core$Maybe$Nothing,
	a5: $author$project$App$Data$FullStatement$emptyFullStatement,
	ey: $elm$time$Time$millisToPosix(0),
	cA: $elm$core$Dict$empty
};
var $author$project$App$Data$Token$emptyModel = {
	gx: $elm$core$Maybe$Nothing,
	c7: '',
	fn: $elm$time$Time$millisToPosix(0),
	fq: '',
	dw: $author$project$App$Data$Person$emptyModel,
	Y: $author$project$App$Data$Id$uuidNil,
	fN: _List_Nil,
	dI: '',
	aV: $elm$core$Maybe$Nothing
};
var $author$project$App$Page$Reviewer$PageWaitingProponentDetails$init = function (st) {
	return _Utils_Tuple3(
		{
			p: 0,
			e$: A2(
				$elm$core$Maybe$withDefault,
				$author$project$App$Data$Token$emptyModel,
				$author$project$App$Service$ApplicationResources$getAuthToken(st._)),
			bm: $author$project$App$Components$ViewFullStatement$empty,
			e1: _List_Nil
		},
		$elm$core$Platform$Cmd$none,
		A2(
			$elm$core$List$map,
			$author$project$App$SharedState$APIMsg,
			_List_fromArray(
				[
					$author$project$App$SharedState$LoadDoctorInterview(
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$ReviewMsg(
							$author$project$App$Page$ResponseMsgs$ReviewWaitingProponentDetailsMsg(0))
						])),
					$author$project$App$SharedState$LoadCompanyPeopleFromPerson(
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$ReviewMsg(
							$author$project$App$Page$ResponseMsgs$ReviewWaitingProponentDetailsMsg(4))
						]))
				])));
};
var $author$project$App$Page$PageReviewer$init = F2(
	function (route, st) {
		var _v0 = function () {
			switch (route.$) {
				case 1:
					var listIncompleteRoute = route.a;
					return function (_v2) {
						var p = _v2.a;
						var c = _v2.b;
						var m = _v2.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageReviewer$ListIncomplete(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListIncompleteMsg, c),
							m);
					}(
						A2($author$project$App$Page$Reviewer$PageListIncomplete$init, listIncompleteRoute, st));
				case 0:
					var listForReviewRoute = route.a;
					return function (_v3) {
						var p = _v3.a;
						var c = _v3.b;
						var m = _v3.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageReviewer$ListForReview(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListForReviewMsg, c),
							m);
					}(
						A2($author$project$App$Page$Reviewer$PageListForReview$init, listForReviewRoute, st));
				case 2:
					var listApprovedRoute = route.a;
					return function (_v4) {
						var p = _v4.a;
						var c = _v4.b;
						var m = _v4.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageReviewer$ListApproved(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListApprovedMsg, c),
							m);
					}(
						A2($author$project$App$Page$Reviewer$PageListApproved$init, listApprovedRoute, st));
				case 3:
					var reviewRoute = route.a;
					return function (_v5) {
						var p = _v5.a;
						var c = _v5.b;
						var m = _v5.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageReviewer$ReviewInterview(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ReviewInterviewMsg, c),
							m);
					}(
						A2($author$project$App$Page$Reviewer$PageReview$init, reviewRoute, st));
				case 4:
					var editStatementRoute = route.a;
					return function (_v6) {
						var p = _v6.a;
						var c = _v6.b;
						var m = _v6.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageReviewer$EditStatement(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$EditStatementMsg, c),
							m);
					}(
						A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$init, editStatementRoute, st));
				case 5:
					var _v7 = $author$project$App$Page$Reviewer$PageListReevaluation$init(st);
					var pModel = _v7.a;
					var pageMsg = _v7.b;
					var listMsgs = _v7.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$ListReevaluation(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListReevaluationMsg, pageMsg),
						listMsgs);
				case 6:
					var stmtId = route.a;
					var _v8 = A2($author$project$App$Page$Reviewer$PageReevaluation$init, stmtId, st);
					var pModel = _v8.a;
					var pageMsg = _v8.b;
					var listMsgs = _v8.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$Reevaluation(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ReevaluationMsg, pageMsg),
						listMsgs);
				case 7:
					var _v9 = $author$project$App$Page$Reviewer$PageWaitingLine$init(st);
					var pModel = _v9.a;
					var pageMsg = _v9.b;
					var listMsgs = _v9.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$WaitingLine(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$WaitingLineMsg, pageMsg),
						listMsgs);
				default:
					var stmtId = route.a;
					var _v10 = $author$project$App$Page$Reviewer$PageWaitingProponentDetails$init(st);
					var pModel = _v10.a;
					var pageMsg = _v10.b;
					var listMsgs = _v10.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$WaitingProponentDetails(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$WaitingProponentDetailsMsg, pageMsg),
						listMsgs);
			}
		}();
		var pageModel = _v0.a;
		var pageCmd = _v0.b;
		var stMsgs = _v0.c;
		return _Utils_Tuple3(pageModel, pageCmd, stMsgs);
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$GotTemplates = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$GotTime = function (a) {
	return {$: 15, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$NoDetails = {$: 0};
var $author$project$App$Page$Support$EditStatement$StatementEdit$NoDocumentDetails = {$: 0};
var $author$project$App$Page$ResponseMsgs$SupportEditStatementCacheCleared = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$init = F2(
	function (route, st) {
		var newCompanyId = function () {
			var _v2 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
			if (!_v2.$) {
				var token = _v2.a;
				var _v3 = token.gx;
				if (!_v3.$) {
					var companyId = _v3.a;
					return companyId;
				} else {
					return $author$project$App$Data$Id$uuidNil;
				}
			} else {
				return $author$project$App$Data$Id$uuidNil;
			}
		}();
		var _v0 = _Utils_Tuple2(
			$author$project$App$Data$Id$uuidNil,
			_List_fromArray(
				[
					$author$project$App$SharedState$ClearCache(
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$SupportMsg(
							$author$project$App$Page$ResponseMsgs$SupportEditStatementMsg(
								$author$project$App$Page$ResponseMsgs$SupportEditStatementCacheCleared($author$project$App$Data$Id$uuidNil)))
						]))
				]));
		var cId = _v0.a;
		var cacheMsgs = _v0.b;
		var modelForm = {
			gx: cId,
			d9: false,
			b5: $author$project$App$Components$ViewFullStatement$TabDocument(0),
			fp: $elm$core$Dict$empty,
			a5: $author$project$App$Data$FullStatement$emptyFullStatement,
			ep: false,
			ey: $elm$time$Time$millisToPosix(0),
			cH: 0,
			eZ: _List_Nil
		};
		var emptyModel = {
			gx: cId,
			b5: $author$project$App$Components$ViewFullStatement$TabDocument(0),
			gD: $author$project$App$Page$Support$EditStatement$StatementEdit$NoDetails,
			gK: $author$project$App$Page$Support$EditStatement$DocumentView$TabDocument(0),
			ac: $author$project$App$Page$Support$EditStatement$StatementEdit$NoDocumentDetails,
			fp: $elm$core$Dict$empty,
			b: $author$project$App$Components$Filter$empty,
			ej: modelForm,
			as: $author$project$App$Data$Id$uuidNil,
			a6: false,
			y: false,
			ep: false,
			cq: $elm$time$Time$millisToPosix(0),
			F: $elm$core$Maybe$Nothing,
			ey: $elm$time$Time$millisToPosix(0),
			dC: false,
			dD: $author$project$App$Route$Private(
				$author$project$App$Route$Support(
					$author$project$App$Route$SupportListIncompleteRoute(
						$author$project$App$Route$SupportListIncomplete($author$project$App$Components$Filter$empty)))),
			h8: $author$project$App$Data$FullStatement$emptyFullStatement,
			cH: 0,
			eZ: _List_Nil
		};
		var uModel = function () {
			if (!route.$) {
				var stmtId = route.a;
				var returnFilter = route.b;
				return _Utils_update(
					emptyModel,
					{
						b: returnFilter,
						as: stmtId,
						dD: $author$project$App$Route$Private(
							$author$project$App$Route$Support(
								$author$project$App$Route$SupportListIncompleteRoute(
									$author$project$App$Route$SupportListIncomplete(returnFilter))))
					});
			} else {
				var stmtId = route.a;
				var returnFilter = route.b;
				return _Utils_update(
					emptyModel,
					{
						b: returnFilter,
						as: stmtId,
						dD: $author$project$App$Route$Private(
							$author$project$App$Route$Support(
								$author$project$App$Route$SupportListApprovedRoute(
									$author$project$App$Route$SupportListApproved(returnFilter))))
					});
			}
		}();
		return _Utils_Tuple3(
			uModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$App$Page$Support$EditStatement$StatementEdit$GotTime, $elm$time$Time$now),
						A3(
						$author$project$App$Service$StatementTemplateRest$getFromCompany,
						$author$project$App$Service$ApiWebSocket$getAuthToken(st._),
						newCompanyId,
						$author$project$App$Page$Support$EditStatement$StatementEdit$GotTemplates)
					])),
			A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, cacheMsgs));
	});
var $author$project$App$Page$ResponseMsgs$SupportListApprovedCacheCleared = {$: 0};
var $author$project$App$Page$ResponseMsgs$SupportListApprovedCacheClearedViewDetails = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportListApprovedCompanyPeopleLoaded = {$: 5};
var $author$project$App$Page$Support$PageListApproved$init = F2(
	function (route, st) {
		var logout = _Utils_Tuple3(
			{gx: $author$project$App$Data$Id$uuidNil, gD: $author$project$App$Page$Support$PageListApproved$NoDetails, b: $author$project$App$Components$Filter$empty, bF: $elm$core$Dict$empty, bk: _List_Nil},
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
		var loadCompanyPeople = ($elm$core$List$length(
			$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)) <= 0) ? _List_fromArray(
			[
				$author$project$App$SharedState$LoadCompanyPeopleFromPerson(
				_List_fromArray(
					[
						$author$project$App$Page$ResponseMsgs$SupportMsg(
						$author$project$App$Page$ResponseMsgs$SupportListApprovedMsg($author$project$App$Page$ResponseMsgs$SupportListApprovedCompanyPeopleLoaded))
					]))
			]) : _List_Nil;
		var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
		if (!_v0.$) {
			var token = _v0.a;
			var cId = A2($elm$core$Maybe$withDefault, $author$project$App$Data$Id$uuidNil, token.gx);
			if (!route.$) {
				var filter = route.a;
				return _Utils_Tuple3(
					{gx: cId, gD: $author$project$App$Page$Support$PageListApproved$NoDetails, b: filter, bF: $elm$core$Dict$empty, bk: _List_Nil},
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$App$SharedState$ClearCache(
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$SupportMsg(
											$author$project$App$Page$ResponseMsgs$SupportListApprovedMsg($author$project$App$Page$ResponseMsgs$SupportListApprovedCacheCleared))
										]))
								]),
							loadCompanyPeople)));
			} else {
				var stmtId = route.a;
				var filter = route.b;
				return _Utils_Tuple3(
					{gx: cId, gD: $author$project$App$Page$Support$PageListApproved$NoDetails, b: filter, bF: $elm$core$Dict$empty, bk: _List_Nil},
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$App$SharedState$ClearCache(
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$SupportMsg(
											$author$project$App$Page$ResponseMsgs$SupportListApprovedMsg(
												$author$project$App$Page$ResponseMsgs$SupportListApprovedCacheClearedViewDetails(stmtId)))
										]))
								]),
							loadCompanyPeople)));
			}
		} else {
			return logout;
		}
	});
var $author$project$App$Page$ResponseMsgs$SupportListForReviewCacheCleared = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportListForReviewCacheClearedViewDetails = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$Support$PageListForReview$defaultFilter = function (filter) {
	return A2(
		$author$project$App$Components$Filter$setOrder,
		'created_at,desc',
		A2(
			$author$project$App$Components$Filter$addRule,
			'status,<=,' + $author$project$App$Data$StatementStatus$statusToString(25),
			filter));
};
var $author$project$App$Page$Support$PageListForReview$init = F2(
	function (route, st) {
		var logout = _Utils_Tuple3(
			{
				gx: $author$project$App$Data$Id$uuidNil,
				b: $author$project$App$Page$Support$PageListForReview$defaultFilter($author$project$App$Components$Filter$empty),
				bF: $elm$core$Dict$empty,
				aU: $elm$core$Maybe$Nothing,
				av: $elm$core$Maybe$Nothing,
				bk: _List_Nil
			},
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
		var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
		if (!_v0.$) {
			var token = _v0.a;
			var cId = A2($elm$core$Maybe$withDefault, $author$project$App$Data$Id$uuidNil, token.gx);
			if (!route.$) {
				var filter = route.a;
				return _Utils_Tuple3(
					{
						gx: cId,
						b: $author$project$App$Page$Support$PageListForReview$defaultFilter(filter),
						bF: $elm$core$Dict$empty,
						aU: $elm$core$Maybe$Nothing,
						av: $elm$core$Maybe$Nothing,
						bk: _List_Nil
					},
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								$author$project$App$SharedState$ClearCache(
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportListForReviewMsg(
											$author$project$App$Page$ResponseMsgs$SupportListForReviewCacheCleared(
												$author$project$App$Page$Support$PageListForReview$defaultFilter(filter))))
									]))
							])));
			} else {
				var stmtId = route.a;
				var filter = route.b;
				return _Utils_Tuple3(
					{
						gx: cId,
						b: $author$project$App$Page$Support$PageListForReview$defaultFilter(filter),
						bF: $elm$core$Dict$empty,
						aU: $elm$core$Maybe$Nothing,
						av: $elm$core$Maybe$Nothing,
						bk: _List_Nil
					},
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_List_fromArray(
							[
								$author$project$App$SharedState$ClearCache(
								_List_fromArray(
									[
										$author$project$App$Page$ResponseMsgs$SupportMsg(
										$author$project$App$Page$ResponseMsgs$SupportListForReviewMsg(
											A2(
												$author$project$App$Page$ResponseMsgs$SupportListForReviewCacheClearedViewDetails,
												stmtId,
												$author$project$App$Page$Support$PageListForReview$defaultFilter(filter))))
									]))
							])));
			}
		} else {
			return logout;
		}
	});
var $author$project$App$Page$Support$PageListIncomplete$GotTime = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportListIncompleteCacheCleared = {$: 0};
var $author$project$App$Page$ResponseMsgs$SupportListIncompleteCacheClearedViewDetails = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$ResponseMsgs$SupportListIncompleteCompanyPeopleLoaded = {$: 6};
var $author$project$App$Page$Support$PageListIncomplete$init = F2(
	function (route, st) {
		var logout = _Utils_Tuple3(
			{
				gx: $author$project$App$Data$Id$uuidNil,
				gD: $author$project$App$Page$Support$PageListIncomplete$NoDetails,
				b: $author$project$App$Components$Filter$empty,
				ey: $elm$time$Time$millisToPosix(0),
				bF: $elm$core$Dict$empty,
				dC: false,
				h8: $author$project$App$Data$Statement$emptyModel,
				bk: _List_Nil
			},
			A2($elm$core$Task$perform, $author$project$App$Page$Support$PageListIncomplete$GotTime, $elm$time$Time$now),
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
		var loadCompanyPeople = ($elm$core$List$length(
			$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)) <= 0) ? _List_fromArray(
			[
				$author$project$App$SharedState$LoadCompanyPeopleFromPerson(
				_List_fromArray(
					[
						$author$project$App$Page$ResponseMsgs$SupportMsg(
						$author$project$App$Page$ResponseMsgs$SupportListIncompleteMsg($author$project$App$Page$ResponseMsgs$SupportListIncompleteCompanyPeopleLoaded))
					]))
			]) : _List_Nil;
		var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
		if (!_v0.$) {
			var token = _v0.a;
			var cId = A2($elm$core$Maybe$withDefault, $author$project$App$Data$Id$uuidNil, token.gx);
			if (!route.$) {
				var filter = route.a;
				return _Utils_Tuple3(
					{
						gx: cId,
						gD: $author$project$App$Page$Support$PageListIncomplete$NoDetails,
						b: filter,
						ey: $elm$time$Time$millisToPosix(0),
						bF: $elm$core$Dict$empty,
						dC: false,
						h8: $author$project$App$Data$Statement$emptyModel,
						bk: _List_Nil
					},
					A2($elm$core$Task$perform, $author$project$App$Page$Support$PageListIncomplete$GotTime, $elm$time$Time$now),
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$App$SharedState$ClearCache(
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$SupportMsg(
											$author$project$App$Page$ResponseMsgs$SupportListIncompleteMsg($author$project$App$Page$ResponseMsgs$SupportListIncompleteCacheCleared))
										]))
								]),
							loadCompanyPeople)));
			} else {
				var stmtId = route.a;
				var filter = route.b;
				return _Utils_Tuple3(
					{
						gx: cId,
						gD: $author$project$App$Page$Support$PageListIncomplete$NoDetails,
						b: filter,
						ey: $elm$time$Time$millisToPosix(0),
						bF: $elm$core$Dict$empty,
						dC: false,
						h8: $author$project$App$Data$Statement$emptyModel,
						bk: _List_Nil
					},
					A2($elm$core$Task$perform, $author$project$App$Page$Support$PageListIncomplete$GotTime, $elm$time$Time$now),
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$App$SharedState$ClearCache(
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$SupportMsg(
											$author$project$App$Page$ResponseMsgs$SupportListIncompleteMsg(
												$author$project$App$Page$ResponseMsgs$SupportListIncompleteCacheClearedViewDetails(stmtId)))
										]))
								]),
							loadCompanyPeople)));
			}
		} else {
			return logout;
		}
	});
var $author$project$App$Page$ResponseMsgs$SupportListReevaluationCacheCleared = {$: 0};
var $author$project$App$Page$Support$PageListReevaluation$defaultFilter = function (filter) {
	return A2($author$project$App$Components$Filter$setOrder, 'created_at,desc', filter);
};
var $author$project$App$Page$Support$PageListReevaluation$init = function (sharedState) {
	var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(sharedState._);
	if (!_v0.$) {
		var token = _v0.a;
		return _Utils_Tuple3(
			{
				bX: token.Y,
				gx: A2($elm$core$Maybe$withDefault, $author$project$App$Data$Id$uuidNil, token.gx),
				b: $author$project$App$Page$Support$PageListReevaluation$defaultFilter($author$project$App$Components$Filter$empty),
				bF: $elm$core$Dict$empty,
				aU: $elm$core$Maybe$Nothing,
				av: $elm$core$Maybe$Nothing,
				bk: _List_Nil
			},
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$List$map,
				$author$project$App$SharedState$APIMsg,
				_List_fromArray(
					[
						$author$project$App$SharedState$ClearCache(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$SupportMsg(
								$author$project$App$Page$ResponseMsgs$SupportListReevaluationMsg($author$project$App$Page$ResponseMsgs$SupportListReevaluationCacheCleared))
							]))
					])));
	} else {
		return _Utils_Tuple3(
			{
				bX: $author$project$App$Data$Id$uuidNil,
				gx: $author$project$App$Data$Id$uuidNil,
				b: $author$project$App$Page$Support$PageListReevaluation$defaultFilter($author$project$App$Components$Filter$empty),
				bF: $elm$core$Dict$empty,
				aU: $elm$core$Maybe$Nothing,
				av: $elm$core$Maybe$Nothing,
				bk: _List_Nil
			},
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
	}
};
var $author$project$App$Page$Support$PageReevaluation$GotTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Support$PageReevaluation$Loading = 0;
var $author$project$App$Page$ResponseMsgs$SupportReevaluationICDCategoriesLoaded = 2;
var $author$project$App$Page$ResponseMsgs$SupportReevaluationLoaded = 0;
var $author$project$App$Page$ResponseMsgs$SupportReevaluationStatementMessagesLoaded = 3;
var $author$project$App$Page$Support$PageReevaluation$TabCIDs = {$: 0};
var $author$project$App$Page$Support$PageReevaluation$AcQuestionIndex = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$Page$Support$PageReevaluation$emptyACModel = {
	fb: A2($author$project$App$Components$Autocomplete$init, '', 10),
	gt: A3($author$project$App$Page$Support$PageReevaluation$AcQuestionIndex, $author$project$App$Data$Id$uuidNil, $author$project$App$Data$Statement$Bearer, $elm$core$Maybe$Nothing)
};
var $author$project$App$Page$Support$PageReevaluation$init = F2(
	function (stmtId, sharedState) {
		var currentPersonId = function () {
			var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(sharedState._);
			if (!_v0.$) {
				var token = _v0.a;
				return token.Y;
			} else {
				return $author$project$App$Data$Id$uuidNil;
			}
		}();
		var newChatBoxModel = A2($author$project$App$Components$Chatbox$init, currentPersonId, true);
		return _Utils_Tuple3(
			{
				f8: $author$project$App$Page$Support$PageReevaluation$emptyACModel,
				aZ: $elm$core$Dict$empty,
				aM: newChatBoxModel,
				fa: _List_Nil,
				bs: _List_Nil,
				b5: $author$project$App$Page$Support$PageReevaluation$TabCIDs,
				an: $elm$core$Dict$empty,
				gH: _List_Nil,
				P: $author$project$App$Data$FullStatement$emptyFullStatement,
				ey: $elm$time$Time$millisToPosix(0),
				dC: false,
				eN: '',
				cA: $elm$core$Dict$empty,
				h$: false,
				h0: false,
				h1: $elm$core$Maybe$Nothing,
				h2: '',
				h3: false,
				p: 0,
				eV: stmtId,
				fX: $elm$core$Dict$empty,
				e$: $author$project$App$Service$Loadable$NotLoaded
			},
			A2($elm$core$Task$perform, $author$project$App$Page$Support$PageReevaluation$GotTime, $elm$time$Time$now),
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg(
					A3(
						$author$project$App$SharedState$LoadFullStatementWithMinors,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$SharedState$FullStmtAssetAnswers(1),
								$author$project$App$SharedState$FullStmtAssetQuestions(1),
								$author$project$App$SharedState$FullStmtAssetAnswers(2),
								$author$project$App$SharedState$FullStmtAssetQuestions(2),
								$author$project$App$SharedState$FullStmtAssetAnswers(3),
								$author$project$App$SharedState$FullStmtAssetQuestions(3),
								$author$project$App$SharedState$FullStmtAssetDocuments,
								$author$project$App$SharedState$FullStmtAssetICD
							]),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$SupportMsg(
								$author$project$App$Page$ResponseMsgs$SupportReevaluationMsg(0))
							]))),
					$author$project$App$SharedState$APIMsg(
					$author$project$App$SharedState$GetICDCategories(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$SupportMsg(
								$author$project$App$Page$ResponseMsgs$SupportReevaluationMsg(2))
							]))),
					$author$project$App$SharedState$APIMsg(
					A2(
						$author$project$App$SharedState$GetStatementMessages,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$SupportMsg(
								$author$project$App$Page$ResponseMsgs$SupportReevaluationMsg(3))
							])))
				]));
	});
var $author$project$App$Page$Support$PageReview$GotTime = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Page$Support$PageReview$No = 0;
var $author$project$App$Page$Support$PageReview$NoDetails = {$: 0};
var $author$project$App$Page$ResponseMsgs$SupportInterviewCacheCleared = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Support$PageReview$init = F2(
	function (route, st) {
		var _v0 = _Utils_Tuple2(
			$author$project$App$Data$Id$uuidNil,
			_List_fromArray(
				[
					$author$project$App$SharedState$ClearCache(
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$SupportMsg(
							$author$project$App$Page$ResponseMsgs$SupportInterviewMsg(
								$author$project$App$Page$ResponseMsgs$SupportInterviewCacheCleared($author$project$App$Data$Id$uuidNil)))
						]))
				]));
		var cId = _v0.a;
		var cacheMsgs = _v0.b;
		var emptyModel = {
			gx: cId,
			b5: $author$project$App$Components$ViewFullStatement$TabDocument(0),
			gD: $author$project$App$Page$Support$PageReview$NoDetails,
			fp: $elm$core$Dict$empty,
			b: $author$project$App$Components$Filter$empty,
			as: $author$project$App$Data$Id$uuidNil,
			ep: false,
			ey: $elm$time$Time$millisToPosix(0),
			dC: false,
			h8: $author$project$App$Data$FullStatement$emptyFullStatement,
			cH: 0,
			eZ: _List_Nil
		};
		var uModel = function () {
			var stmtId = route;
			return _Utils_update(
				emptyModel,
				{as: stmtId});
		}();
		return _Utils_Tuple3(
			uModel,
			A2($elm$core$Task$perform, $author$project$App$Page$Support$PageReview$GotTime, $elm$time$Time$now),
			A2($elm$core$List$map, $author$project$App$SharedState$APIMsg, cacheMsgs));
	});
var $author$project$App$Page$PageSupport$init = F2(
	function (route, st) {
		var _v0 = function () {
			switch (route.$) {
				case 1:
					var listIncompleteRoute = route.a;
					return function (_v2) {
						var p = _v2.a;
						var c = _v2.b;
						var m = _v2.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageSupport$ListIncomplete(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListIncompleteMsg, c),
							m);
					}(
						A2($author$project$App$Page$Support$PageListIncomplete$init, listIncompleteRoute, st));
				case 0:
					var listForReviewRoute = route.a;
					return function (_v3) {
						var p = _v3.a;
						var c = _v3.b;
						var m = _v3.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageSupport$ListForReview(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListForReviewMsg, c),
							m);
					}(
						A2($author$project$App$Page$Support$PageListForReview$init, listForReviewRoute, st));
				case 2:
					var listApprovedRoute = route.a;
					return function (_v4) {
						var p = _v4.a;
						var c = _v4.b;
						var m = _v4.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageSupport$ListApproved(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListApprovedMsg, c),
							m);
					}(
						A2($author$project$App$Page$Support$PageListApproved$init, listApprovedRoute, st));
				case 3:
					var reviewRoute = route.a;
					return function (_v5) {
						var p = _v5.a;
						var c = _v5.b;
						var m = _v5.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageSupport$ReviewInterview(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ReviewInterviewMsg, c),
							m);
					}(
						A2($author$project$App$Page$Support$PageReview$init, reviewRoute, st));
				case 4:
					var editStatementRoute = route.a;
					return function (_v6) {
						var p = _v6.a;
						var c = _v6.b;
						var m = _v6.c;
						return _Utils_Tuple3(
							$author$project$App$Page$PageSupport$EditStatement(p),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$EditStatementMsg, c),
							m);
					}(
						A2($author$project$App$Page$Support$EditStatement$StatementEdit$init, editStatementRoute, st));
				case 5:
					var _v7 = $author$project$App$Page$Support$PageListReevaluation$init(st);
					var pModel = _v7.a;
					var pageMsg = _v7.b;
					var listMsgs = _v7.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$ListReevaluation(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListReevaluationMsg, pageMsg),
						listMsgs);
				default:
					var stmtId = route.a;
					var _v8 = A2($author$project$App$Page$Support$PageReevaluation$init, stmtId, st);
					var pModel = _v8.a;
					var pageMsg = _v8.b;
					var listMsgs = _v8.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$Reevaluation(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ReevaluationMsg, pageMsg),
						listMsgs);
			}
		}();
		var pageModel = _v0.a;
		var pageCmd = _v0.b;
		var stMsgs = _v0.c;
		return _Utils_Tuple3(pageModel, pageCmd, stMsgs);
	});
var $author$project$App$Page$PageUserRequestRecoverPassword$Filling = {$: 0};
var $author$project$App$Data$Token$emptyPasswordRecoverToken = {c7: '', dI: ''};
var $author$project$App$Page$PageUserRequestRecoverPassword$empty = {aw: $author$project$App$Page$PageUserRequestRecoverPassword$Filling, e$: $author$project$App$Data$Token$emptyPasswordRecoverToken, bS: $author$project$App$Data$User$emptyModel};
var $author$project$App$Page$PageUserRequestRecoverPassword$init = _Utils_Tuple2($author$project$App$Page$PageUserRequestRecoverPassword$empty, $elm$core$Platform$Cmd$none);
var $author$project$App$Page$PageUserSignUp$GotCompanies = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$PageUserSignUp$Filling = {$: 0};
var $author$project$App$Page$PageUserSignUp$empty = {bq: _List_Nil, dw: $author$project$App$Data$Person$emptyModel, dx: $author$project$App$Data$Contact$emptyMobile, hS: 1, aw: $author$project$App$Page$PageUserSignUp$Filling, bS: $author$project$App$Data$User$emptyModel};
var $author$project$App$Service$CompanyRest$getCompaniesWithTemplates = function (msg) {
	return $author$project$App$Service$ApiRest$get(
		{
			a1: A2(
				$elm$http$Http$expectJson,
				msg,
				$author$project$App$Service$ApiRest$decodeList($author$project$App$Data$Company$decode)),
			e$: $elm$core$Maybe$Nothing,
			f3: 'companyService.companies.withTemplates'
		});
};
var $author$project$App$Page$PageUserSignUp$init = _Utils_Tuple2(
	$author$project$App$Page$PageUserSignUp$empty,
	$author$project$App$Service$CompanyRest$getCompaniesWithTemplates($author$project$App$Page$PageUserSignUp$GotCompanies));
var $author$project$App$Page$PageUserSignUpComplete$NoData = 0;
var $author$project$App$Page$PageUserSignUpComplete$init = 0;
var $author$project$App$Page$PageAdmin$updateRoute = F4(
	function (route, now, st, model) {
		var _v0 = _Utils_Tuple2(route, model);
		switch (_v0.a.$) {
			case 0:
				if (!_v0.b.$) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var listIncompleteRoute = _v0.a.a;
					var _v1 = A2($author$project$App$Page$Admin$PageListUsers$init, listIncompleteRoute, st);
					var pageModel = _v1.a;
					var pageMsg = _v1.b;
					var stMsgs = _v1.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$ListUsers(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListUsersMsg, pageMsg),
						stMsgs);
				}
			case 1:
				if (_v0.b.$ === 1) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var listDoctorsRoute = _v0.a.a;
					var _v2 = A2($author$project$App$Page$Admin$PageListDoctors$init, listDoctorsRoute, st);
					var pageModel = _v2.a;
					var pageMsg = _v2.b;
					var stMsgs = _v2.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$ListDoctors(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListDoctorsMsg, pageMsg),
						stMsgs);
				}
			case 2:
				if (_v0.b.$ === 2) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v3 = A2($author$project$App$Page$Admin$PageFinanceSales$init, now, st);
					var pageModel = _v3.a;
					var pageMsg = _v3.b;
					var stMsgs = _v3.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$FinanceSales(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$FinanceSalesMsg, pageMsg),
						stMsgs);
				}
			case 4:
				if (_v0.b.$ === 3) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var finishedRoute = _v0.a.a;
					var _v4 = A2($author$project$App$Page$Admin$PageListFinished$init, finishedRoute, st);
					var pModel = _v4.a;
					var pageMsg = _v4.b;
					var listMsgs = _v4.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$ListFinished(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListFinishedMsg, pageMsg),
						listMsgs);
				}
			case 3:
				if (_v0.b.$ === 6) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var stmtId = _v0.a.a;
					var _v5 = A2($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$init, stmtId, st);
					var pageModel = _v5.a;
					var pageMsg = _v5.b;
					var stMsgs = _v5.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$ViewEvaluation(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ViewEvaluationMsg, pageMsg),
						stMsgs);
				}
			case 5:
				if (_v0.b.$ === 4) {
					var _v6 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v7 = _v0.a;
					var _v8 = $author$project$App$Page$Admin$PageListReevaluation$init(st);
					var pModel = _v8.a;
					var pageMsg = _v8.b;
					var listMsgs = _v8.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$ListReevaluation(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ListReevaluationMsg, pageMsg),
						listMsgs);
				}
			default:
				if (_v0.b.$ === 5) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var stmtId = _v0.a.a;
					var _v9 = A2($author$project$App$Page$Admin$PageReevaluation$init, stmtId, st);
					var pageModel = _v9.a;
					var pageMsg = _v9.b;
					var stMsgs = _v9.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageAdmin$Reevaluation(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageAdmin$ReevaluationMsg, pageMsg),
						stMsgs);
				}
		}
	});
var $author$project$App$Page$PageCompany$updateRoute = F3(
	function (route, st, model) {
		var _v0 = _Utils_Tuple2(route, model);
		switch (_v0.a.$) {
			case 1:
				if (_v0.b.$ === 1) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var listPendingRoute = _v0.a.a;
					var _v1 = A2($author$project$App$Page$Company$PageListPending$init, listPendingRoute, st);
					var pageModel = _v1.a;
					var pageMsg = _v1.b;
					var stMsgs = _v1.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$ListPending(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListPendingMsg, pageMsg),
						stMsgs);
				}
			case 2:
				if (_v0.b.$ === 2) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var listCanceledRoute = _v0.a.a;
					var _v2 = A2($author$project$App$Page$Company$PageListCanceled$init, listCanceledRoute, st);
					var pageModel = _v2.a;
					var pageMsg = _v2.b;
					var stMsgs = _v2.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$ListCanceled(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListCanceledMsg, pageMsg),
						stMsgs);
				}
			case 3:
				if (_v0.b.$ === 3) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var listConcludedRoute = _v0.a.a;
					var _v3 = A2($author$project$App$Page$Company$PageListConcluded$init, listConcludedRoute, st);
					var pageModel = _v3.a;
					var pageMsg = _v3.b;
					var stMsgs = _v3.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$ListConcluded(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListConcludedMsg, pageMsg),
						stMsgs);
				}
			case 0:
				if (!_v0.b.$) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var riRoute = _v0.a.a;
					var _v4 = A2($author$project$App$Page$Company$PageRequestInterview$init, riRoute, st);
					var pageModel = _v4.a;
					var pageMsg = _v4.b;
					var stMsgs = _v4.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$RequestInterview(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$RequestInterviewMsg, pageMsg),
						stMsgs);
				}
			case 4:
				if (_v0.b.$ === 6) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var stmtId = _v0.a.a;
					var _v5 = A2($author$project$App$Page$Company$PageViewEvaluation$init, stmtId, st);
					var pageModel = _v5.a;
					var pageMsg = _v5.b;
					var stMsgs = _v5.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$ViewEvaluation(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ViewEvaluationMsg, pageMsg),
						stMsgs);
				}
			case 5:
				if (_v0.b.$ === 5) {
					var _v6 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v7 = _v0.a;
					var _v8 = $author$project$App$Page$Company$PageListReevaluation$init(st);
					var pModel = _v8.a;
					var pageMsg = _v8.b;
					var listMsgs = _v8.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$ListReevaluation(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ListReevaluationMsg, pageMsg),
						listMsgs);
				}
			default:
				if (_v0.b.$ === 4) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var stmtId = _v0.a.a;
					var _v9 = A2($author$project$App$Page$Company$PageReevaluation$init, stmtId, st);
					var pageModel = _v9.a;
					var pageMsg = _v9.b;
					var stMsgs = _v9.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageCompany$Reevaluation(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageCompany$ReevaluationMsg, pageMsg),
						stMsgs);
				}
		}
	});
var $author$project$App$Page$Doctor$PageLoading$isLoaded = function (model) {
	return model.ep;
};
var $author$project$App$Page$ResponseMsgs$DoctorSalesLoadedSummaries = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$SharedState$LoadDoctorMonthSummary = F2(
	function (a, b) {
		return {$: 91, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageDoctorSales$OrderByDate = 1;
var $author$project$App$Page$Doctor$PageDoctorSales$monthToInt = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $author$project$App$Page$Doctor$PageDoctorSales$emptyModel = F3(
	function (now, st, dId) {
		var defaultMonthlySale = {
			b1: $elm$core$Maybe$Nothing,
			gL: $elm$core$Maybe$Nothing,
			hk: $author$project$App$Page$Doctor$PageDoctorSales$monthToInt(
				A2($elm$time$Time$toMonth, st.f6, now)),
			aR: $elm$core$Maybe$Nothing,
			S: _List_fromArray(
				[
					{gD: _List_Nil, at: 0, L: 0, hl: 'Atendimentos', au: 1}
				]),
			bi: 0,
			iq: 0,
			iF: A2($elm$time$Time$toYear, st.f6, now)
		};
		var newMonthlySales = A2(
			$elm$core$List$indexedMap,
			F2(
				function (i, ms) {
					var _v0 = ((ms.hk - i) > 0) ? _Utils_Tuple2(ms.hk - i, ms.iF) : _Utils_Tuple2(12 + (ms.hk - i), ms.iF - 1);
					var newMonth = _v0.a;
					var newYear = _v0.b;
					return _Utils_update(
						ms,
						{hk: newMonth, iF: newYear});
				}),
			A2($elm$core$List$repeat, 12, defaultMonthlySale));
		return {
			eb: $elm$core$Maybe$Nothing,
			aO: _List_Nil,
			fl: dId,
			w: newMonthlySales,
			ct: _Utils_Tuple2(1, false),
			eF: $elm$core$Maybe$Nothing,
			fR: 4,
			fS: _List_Nil,
			m: $author$project$App$Page$Doctor$PageDoctorSales$monthToInt(
				A2($elm$time$Time$toMonth, st.f6, now)),
			o: A2($elm$time$Time$toYear, st.f6, now),
			ax: $author$project$App$Page$Doctor$PageDoctorSales$monthToInt(
				A2($elm$time$Time$toMonth, st.f6, now)),
			aW: A2($elm$time$Time$toYear, st.f6, now),
			dS: false
		};
	});
var $author$project$App$Page$Doctor$PageDoctorSales$init = F2(
	function (now, st) {
		var startDate = $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, st.f6, now)) + ((($author$project$App$Page$Doctor$PageDoctorSales$monthToInt(
			A2($elm$time$Time$toMonth, st.f6, now)) >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(
			$author$project$App$Page$Doctor$PageDoctorSales$monthToInt(
				A2($elm$time$Time$toMonth, st.f6, now))) + '-01'));
		var logout = _Utils_Tuple3(
			A3($author$project$App$Page$Doctor$PageDoctorSales$emptyModel, now, st, $author$project$App$Data$Id$uuidNil),
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
		var endDate = ($author$project$App$Page$Doctor$PageDoctorSales$monthToInt(
			A2($elm$time$Time$toMonth, st.f6, now)) === 12) ? ($elm$core$String$fromInt(
			A2($elm$time$Time$toYear, st.f6, now) + 1) + ('-' + '01-01')) : ($elm$core$String$fromInt(
			A2($elm$time$Time$toYear, st.f6, now)) + (((($author$project$App$Page$Doctor$PageDoctorSales$monthToInt(
			A2($elm$time$Time$toMonth, st.f6, now)) + 1) >= 10) ? '-' : '-0') + ($elm$core$String$fromInt(
			$author$project$App$Page$Doctor$PageDoctorSales$monthToInt(
				A2($elm$time$Time$toMonth, st.f6, now)) + 1) + '-01')));
		var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
		if (!_v0.$) {
			var token = _v0.a;
			return (!_Utils_eq(token.Y, $author$project$App$Data$Id$uuidNil)) ? _Utils_Tuple3(
				A3($author$project$App$Page$Doctor$PageDoctorSales$emptyModel, now, st, token.Y),
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$List$map,
					$author$project$App$SharedState$APIMsg,
					_List_fromArray(
						[
							A2(
							$author$project$App$SharedState$LoadDoctorMonthSummary,
							token.Y,
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$DoctorMsg(
									$author$project$App$Page$ResponseMsgs$DoctorSalesMsg(
										$author$project$App$Page$ResponseMsgs$DoctorSalesLoadedSummaries(token.Y)))
								])),
							A4(
							$author$project$App$SharedState$LoadDoctorMonthDetails,
							token.Y,
							startDate,
							endDate,
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$DoctorMsg(
									$author$project$App$Page$ResponseMsgs$DoctorSalesMsg(
										A5(
											$author$project$App$Page$ResponseMsgs$DoctorSalesLoadedDetails,
											token.Y,
											startDate,
											endDate,
											A2($elm$time$Time$toYear, st.f6, now),
											$author$project$App$Page$Doctor$PageDoctorSales$monthToInt(
												A2($elm$time$Time$toMonth, st.f6, now)))))
								]))
						]))) : logout;
		} else {
			return logout;
		}
	});
var $author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationICDCategoriesLoaded = 3;
var $author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationStatementLoaded = 0;
var $author$project$App$Page$Doctor$PageFinalEvaluation$GotTime = function (a) {
	return {$: 14, a: a};
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$Loading = 0;
var $author$project$App$Page$Doctor$PageFinalEvaluation$TabCIDs = {$: 0};
var $author$project$App$Page$Doctor$PageFinalEvaluation$AcQuestionIndex = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$emptyACModel = {
	fb: A2($author$project$App$Components$Autocomplete$init, '', 10),
	gt: A3($author$project$App$Page$Doctor$PageFinalEvaluation$AcQuestionIndex, $author$project$App$Data$Id$uuidNil, $author$project$App$Data$Statement$Bearer, $elm$core$Maybe$Nothing)
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$init = F2(
	function (stmtId, sharedState) {
		var _v0 = $author$project$App$Data$Nexodata$init;
		var initNexodata = _v0.a;
		var nCmd = _v0.b;
		var _v1 = $author$project$App$Data$Memed$init;
		var initMemed = _v1.a;
		var mCmd = _v1.b;
		return _Utils_Tuple3(
			{
				f8: $author$project$App$Page$Doctor$PageFinalEvaluation$emptyACModel,
				aZ: $elm$core$Dict$empty,
				fa: _List_Nil,
				bs: _List_Nil,
				b5: $author$project$App$Page$Doctor$PageFinalEvaluation$TabCIDs,
				an: $elm$core$Dict$empty,
				gH: _List_Nil,
				P: $author$project$App$Data$FullStatement$emptyFullStatement,
				hi: initMemed,
				ew: $elm$core$Maybe$Nothing,
				ho: initNexodata,
				ey: $elm$time$Time$millisToPosix(0),
				cA: $elm$core$Dict$empty,
				h$: false,
				h0: false,
				h1: $elm$core$Maybe$Nothing,
				h2: '',
				h3: false,
				p: 0,
				fX: $elm$core$Dict$empty,
				e$: $author$project$App$Service$Loadable$NotLoaded
			},
			A2($elm$core$Task$perform, $author$project$App$Page$Doctor$PageFinalEvaluation$GotTime, $elm$time$Time$now),
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg(
					A3(
						$author$project$App$SharedState$LoadFullStatementWithMinors,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$SharedState$FullStmtAssetAnswers(1),
								$author$project$App$SharedState$FullStmtAssetQuestions(1),
								$author$project$App$SharedState$FullStmtAssetAnswers(2),
								$author$project$App$SharedState$FullStmtAssetQuestions(2),
								$author$project$App$SharedState$FullStmtAssetAnswers(3),
								$author$project$App$SharedState$FullStmtAssetQuestions(3),
								$author$project$App$SharedState$FullStmtAssetDocuments,
								$author$project$App$SharedState$FullStmtAssetICD
							]),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
								$author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationMsg(0))
							]))),
					$author$project$App$SharedState$APIMsg(
					$author$project$App$SharedState$GetICDCategories(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
								$author$project$App$Page$ResponseMsgs$DoctorFinalEvaluationMsg(3))
							])))
				]));
	});
var $author$project$App$Page$ResponseMsgs$DoctorInterviewLoaded = {$: 1};
var $author$project$App$Page$ResponseMsgs$DoctorInterviewStatementLoaded = {$: 0};
var $author$project$App$Page$ResponseMsgs$SharedConferenceUpdated = F2(
	function (a, b) {
		return {$: 18, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$DoctorInterviewLoadingInterviewLoaded = 0;
var $author$project$App$Page$Doctor$Interview$PageLoading$Loading = 0;
var $author$project$App$Page$Doctor$Interview$PageLoading$init = F2(
	function (st, msgs) {
		return _Utils_Tuple3(
			{d8: msgs.d8, eq: msgs.eq, p: 0},
			A2(
				$author$project$App$Route$replaceUrl,
				st.fC,
				$author$project$App$Route$Private(
					$author$project$App$Route$Doctor(
						$author$project$App$Route$DoctorInterview(0)))),
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg(
					$author$project$App$SharedState$LoadDoctorInterview(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
								$author$project$App$Page$ResponseMsgs$DoctorInterviewMsg(
									$author$project$App$Page$ResponseMsgs$DoctorInterviewLoadingMsg(0))),
								msgs.i0
							])))
				]));
	});
var $author$project$App$Page$Doctor$PageInterview$init = function (st) {
	var stmtId = function () {
		var _v2 = $author$project$App$Service$ApplicationResources$getDoctorInterview(st._);
		if (_v2.$ === 3) {
			var stmt = _v2.a;
			return stmt.as;
		} else {
			return '';
		}
	}();
	var isMedsynAccount = function () {
		var _v1 = $author$project$App$Service$ApplicationResources$getAuthToken(st._);
		if (!_v1.$) {
			var token = _v1.a;
			return token.c7 === 'doctor@medsyn.com.br';
		} else {
			return false;
		}
	}();
	var currentTime = $elm$time$Time$posixToMillis(
		$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
	var _v0 = A2(
		$author$project$App$Page$Doctor$Interview$PageLoading$init,
		st,
		{
			d8: $author$project$App$Page$ResponseMsgs$SharedMsg(
				A2($author$project$App$Page$ResponseMsgs$SharedConferenceUpdated, 1, stmtId)),
			i0: $author$project$App$Page$ResponseMsgs$DoctorMsg(
				$author$project$App$Page$ResponseMsgs$DoctorInterviewMsg($author$project$App$Page$ResponseMsgs$DoctorInterviewStatementLoaded)),
			eq: $author$project$App$Page$ResponseMsgs$DoctorMsg(
				$author$project$App$Page$ResponseMsgs$DoctorInterviewMsg($author$project$App$Page$ResponseMsgs$DoctorInterviewLoaded))
		});
	var loadingModel = _v0.a;
	var loadingCmd = _v0.b;
	var loadingStMsgs = _v0.c;
	return _Utils_Tuple3(
		{
			aA: true,
			df: currentTime,
			dh: isMedsynAccount,
			di: false,
			aQ: $author$project$App$Page$Doctor$PageInterview$Loading(loadingModel),
			eV: stmtId,
			bU: $elm$core$Maybe$Nothing
		},
		A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$LoadingMsg, loadingCmd),
		_Utils_ap(
			A2(
				$elm$core$List$map,
				$author$project$App$SharedState$APIMsg,
				_List_fromArray(
					[
						A2(
						$author$project$App$SharedState$DoctorInformOnline,
						$elm$core$String$fromInt(currentTime),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
								$author$project$App$Page$ResponseMsgs$DoctorInterviewMsg($author$project$App$Page$ResponseMsgs$DoctorInterviewInformedOnline))
							]))
					])),
			loadingStMsgs));
};
var $author$project$App$Page$ResponseMsgs$DoctorListFinishedCacheCleared = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorListFinishedCacheClearedViewDetails = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$App$Page$ResponseMsgs$DoctorListFinishedCompanyPeopleLoaded = {$: 5};
var $author$project$App$Page$Doctor$PageListFinished$init = F2(
	function (listFinishedRoute, sharedState) {
		var loadCompanyPeople = ($elm$core$List$length(
			$author$project$App$Service$ApplicationResources$getAllCompanyPeople(sharedState._)) <= 0) ? _List_fromArray(
			[
				$author$project$App$SharedState$LoadCompanyPeopleFromPerson(
				_List_fromArray(
					[
						$author$project$App$Page$ResponseMsgs$DoctorMsg(
						$author$project$App$Page$ResponseMsgs$DoctorListFinishedMsg($author$project$App$Page$ResponseMsgs$DoctorListFinishedCompanyPeopleLoaded))
					]))
			]) : _List_Nil;
		var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(sharedState._);
		if (!_v0.$) {
			var token = _v0.a;
			if (!listFinishedRoute.$) {
				var filter = listFinishedRoute.a;
				return _Utils_Tuple3(
					{
						fl: token.Y,
						b: $author$project$App$Page$Doctor$PageListFinished$defaultFilter(filter),
						bF: $elm$core$Dict$empty,
						aU: $elm$core$Maybe$Nothing,
						av: $elm$core$Maybe$Nothing,
						bk: _List_Nil
					},
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$App$SharedState$ClearCache(
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$DoctorMsg(
											$author$project$App$Page$ResponseMsgs$DoctorListFinishedMsg(
												$author$project$App$Page$ResponseMsgs$DoctorListFinishedCacheCleared(
													$author$project$App$Page$Doctor$PageListFinished$defaultFilter(filter))))
										]))
								]),
							loadCompanyPeople)));
			} else {
				var stmtId = listFinishedRoute.a;
				var filter = listFinishedRoute.b;
				return _Utils_Tuple3(
					{
						fl: token.Y,
						b: $author$project$App$Page$Doctor$PageListFinished$defaultFilter(filter),
						bF: $elm$core$Dict$empty,
						aU: $elm$core$Maybe$Nothing,
						av: $elm$core$Maybe$Nothing,
						bk: _List_Nil
					},
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$List$map,
						$author$project$App$SharedState$APIMsg,
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$App$SharedState$ClearCache(
									_List_fromArray(
										[
											$author$project$App$Page$ResponseMsgs$DoctorMsg(
											$author$project$App$Page$ResponseMsgs$DoctorListFinishedMsg(
												A2($author$project$App$Page$ResponseMsgs$DoctorListFinishedCacheClearedViewDetails, stmtId, filter)))
										]))
								]),
							loadCompanyPeople)));
			}
		} else {
			return _Utils_Tuple3(
				{
					fl: $author$project$App$Data$Id$uuidNil,
					b: $author$project$App$Page$Doctor$PageListFinished$defaultFilter($author$project$App$Components$Filter$empty),
					bF: $elm$core$Dict$empty,
					aU: $elm$core$Maybe$Nothing,
					av: $elm$core$Maybe$Nothing,
					bk: _List_Nil
				},
				$elm$core$Platform$Cmd$none,
				_List_fromArray(
					[
						$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
					]));
		}
	});
var $author$project$App$Page$ResponseMsgs$DoctorListPendingCacheCleared = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Doctor$PageListPending$defaultFilter = function (filter) {
	return A2($author$project$App$Components$Filter$setOrder, 'created_at,desc', filter);
};
var $author$project$App$Page$Doctor$PageListPending$init = F2(
	function (filter, sharedState) {
		var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(sharedState._);
		if (!_v0.$) {
			var token = _v0.a;
			return _Utils_Tuple3(
				{
					fl: token.Y,
					b: $author$project$App$Page$Doctor$PageListPending$defaultFilter(filter),
					bF: $elm$core$Dict$empty,
					bk: _List_Nil
				},
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$List$map,
					$author$project$App$SharedState$APIMsg,
					_List_fromArray(
						[
							$author$project$App$SharedState$ClearCache(
							_List_fromArray(
								[
									$author$project$App$Page$ResponseMsgs$DoctorMsg(
									$author$project$App$Page$ResponseMsgs$DoctorListPendingMsg(
										$author$project$App$Page$ResponseMsgs$DoctorListPendingCacheCleared(
											$author$project$App$Page$Doctor$PageListPending$defaultFilter(filter))))
								]))
						])));
		} else {
			return _Utils_Tuple3(
				{
					fl: $author$project$App$Data$Id$uuidNil,
					b: $author$project$App$Page$Doctor$PageListPending$defaultFilter($author$project$App$Components$Filter$empty),
					bF: $elm$core$Dict$empty,
					bk: _List_Nil
				},
				$elm$core$Platform$Cmd$none,
				_List_fromArray(
					[
						$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
					]));
		}
	});
var $author$project$App$Page$ResponseMsgs$DoctorListReevaluationCacheCleared = {$: 0};
var $author$project$App$Page$Doctor$PageListReevaluation$defaultFilter = function (filter) {
	return A2($author$project$App$Components$Filter$setOrder, 'created_at,desc', filter);
};
var $author$project$App$Page$Doctor$PageListReevaluation$init = function (sharedState) {
	var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(sharedState._);
	if (!_v0.$) {
		var token = _v0.a;
		return _Utils_Tuple3(
			{
				fl: token.Y,
				b: $author$project$App$Page$Doctor$PageListReevaluation$defaultFilter($author$project$App$Components$Filter$empty),
				bF: $elm$core$Dict$empty,
				bk: _List_Nil
			},
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$List$map,
				$author$project$App$SharedState$APIMsg,
				_List_fromArray(
					[
						$author$project$App$SharedState$ClearCache(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
								$author$project$App$Page$ResponseMsgs$DoctorListReevaluationMsg($author$project$App$Page$ResponseMsgs$DoctorListReevaluationCacheCleared))
							]))
					])));
	} else {
		return _Utils_Tuple3(
			{
				fl: $author$project$App$Data$Id$uuidNil,
				b: $author$project$App$Page$Doctor$PageListReevaluation$defaultFilter($author$project$App$Components$Filter$empty),
				bF: $elm$core$Dict$empty,
				bk: _List_Nil
			},
			$elm$core$Platform$Cmd$none,
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
				]));
	}
};
var $author$project$App$Page$ResponseMsgs$DoctorReevaluationICDCategoriesLoaded = 3;
var $author$project$App$Page$ResponseMsgs$DoctorReevaluationStatementLoaded = 0;
var $author$project$App$Page$ResponseMsgs$DoctorReevaluationStatementMessagesLoaded = 4;
var $author$project$App$Page$Doctor$PageReevaluation$GotTime = function (a) {
	return {$: 17, a: a};
};
var $author$project$App$Page$Doctor$PageReevaluation$Loading = 0;
var $author$project$App$Page$Doctor$PageReevaluation$TabCIDs = {$: 0};
var $author$project$App$Page$Doctor$PageReevaluation$AcQuestionIndex = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$Page$Doctor$PageReevaluation$emptyACModel = {
	fb: A2($author$project$App$Components$Autocomplete$init, '', 10),
	gt: A3($author$project$App$Page$Doctor$PageReevaluation$AcQuestionIndex, $author$project$App$Data$Id$uuidNil, $author$project$App$Data$Statement$Bearer, $elm$core$Maybe$Nothing)
};
var $author$project$App$Page$Doctor$PageReevaluation$init = F2(
	function (stmtId, sharedState) {
		var currentPersonId = function () {
			var _v0 = $author$project$App$Service$ApplicationResources$getAuthToken(sharedState._);
			if (!_v0.$) {
				var token = _v0.a;
				return token.Y;
			} else {
				return $author$project$App$Data$Id$uuidNil;
			}
		}();
		var newChatBoxModel = A2($author$project$App$Components$Chatbox$init, currentPersonId, true);
		return _Utils_Tuple3(
			{
				f8: $author$project$App$Page$Doctor$PageReevaluation$emptyACModel,
				aZ: $elm$core$Dict$empty,
				aM: newChatBoxModel,
				fa: _List_Nil,
				bs: _List_Nil,
				b5: $author$project$App$Page$Doctor$PageReevaluation$TabCIDs,
				an: $elm$core$Dict$empty,
				gH: _List_Nil,
				P: $author$project$App$Data$FullStatement$emptyFullStatement,
				ew: $elm$core$Maybe$Nothing,
				ey: $elm$time$Time$millisToPosix(0),
				cA: $elm$core$Dict$empty,
				dK: false,
				h$: false,
				h0: false,
				h1: $elm$core$Maybe$Nothing,
				h2: '',
				h3: false,
				p: 0,
				eV: stmtId,
				fX: $elm$core$Dict$empty,
				e$: $author$project$App$Service$Loadable$NotLoaded
			},
			A2($elm$core$Task$perform, $author$project$App$Page$Doctor$PageReevaluation$GotTime, $elm$time$Time$now),
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg(
					A3(
						$author$project$App$SharedState$LoadFullStatementWithMinors,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$SharedState$FullStmtAssetAnswers(1),
								$author$project$App$SharedState$FullStmtAssetQuestions(1),
								$author$project$App$SharedState$FullStmtAssetAnswers(2),
								$author$project$App$SharedState$FullStmtAssetQuestions(2),
								$author$project$App$SharedState$FullStmtAssetAnswers(3),
								$author$project$App$SharedState$FullStmtAssetQuestions(3),
								$author$project$App$SharedState$FullStmtAssetDocuments,
								$author$project$App$SharedState$FullStmtAssetICD
							]),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
								$author$project$App$Page$ResponseMsgs$DoctorReevaluationMsg(0))
							]))),
					$author$project$App$SharedState$APIMsg(
					$author$project$App$SharedState$GetICDCategories(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
								$author$project$App$Page$ResponseMsgs$DoctorReevaluationMsg(3))
							]))),
					$author$project$App$SharedState$APIMsg(
					A2(
						$author$project$App$SharedState$GetStatementMessages,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
								$author$project$App$Page$ResponseMsgs$DoctorReevaluationMsg(4))
							])))
				]));
	});
var $author$project$App$Page$ResponseMsgs$DoctorCurrentReviewLoaded = 0;
var $author$project$App$Page$ResponseMsgs$DoctorReviewCompanyPeopleLoaded = 5;
var $author$project$App$Page$ResponseMsgs$DoctorReviewWaitingLineUpdated = 2;
var $author$project$App$Page$Doctor$PageReview$Loading = 0;
var $author$project$App$Page$Doctor$PageReview$init = function (st) {
	return _Utils_Tuple3(
		{
			p: 0,
			e$: A2(
				$elm$core$Maybe$withDefault,
				$author$project$App$Data$Token$emptyModel,
				$author$project$App$Service$ApplicationResources$getAuthToken(st._)),
			bm: $author$project$App$Components$ViewFullStatement$empty,
			e1: _List_Nil
		},
		$elm$core$Platform$Cmd$none,
		A2(
			$elm$core$List$map,
			$author$project$App$SharedState$APIMsg,
			_List_fromArray(
				[
					$author$project$App$SharedState$LoadDoctorInterview(
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$DoctorMsg(
							$author$project$App$Page$ResponseMsgs$DoctorReviewMsg(0))
						])),
					$author$project$App$SharedState$LoadDoctorWaitingLine(
					$author$project$App$Page$ResponseMsgs$DoctorMsg(
						$author$project$App$Page$ResponseMsgs$DoctorReviewMsg(2))),
					$author$project$App$SharedState$LoadCompanyPeopleFromPerson(
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$DoctorMsg(
							$author$project$App$Page$ResponseMsgs$DoctorReviewMsg(5))
						]))
				])));
};
var $author$project$App$Page$ResponseMsgs$DoctorWaitingLineCacheCleared = {$: 0};
var $author$project$App$Page$Doctor$PageWaitingLine$init = function (st) {
	var _v0 = $author$project$App$Components$Sound$init;
	var sound = _v0.a;
	var soundCmd = _v0.b;
	return _Utils_Tuple3(
		{
			fl: $author$project$App$Data$Id$uuidNil,
			c5: _List_Nil,
			df: $elm$time$Time$posixToMillis(
				$author$project$App$Service$ApiWebSocket$getTimestamp(st._)),
			N: sound,
			e1: _List_Nil
		},
		A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageWaitingLine$SoundMsg, soundCmd),
		_List_fromArray(
			[
				$author$project$App$SharedState$APIMsg(
				$author$project$App$SharedState$ClearCache(
					_List_fromArray(
						[
							$author$project$App$Page$ResponseMsgs$DoctorMsg(
							$author$project$App$Page$ResponseMsgs$DoctorWaitingLineMsg($author$project$App$Page$ResponseMsgs$DoctorWaitingLineCacheCleared))
						]))),
				$author$project$App$SharedState$VideoMsg($author$project$App$SharedState$StopConference)
			]));
};
var $author$project$App$Page$ResponseMsgs$DoctorViewEvaluationICDCategoriesLoaded = 1;
var $author$project$App$Page$ResponseMsgs$DoctorViewEvaluationMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$ResponseMsgs$DoctorViewEvaluationStatementLoaded = 0;
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$GotTime = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$Loading = 0;
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabCIDs = {$: 0};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$AcQuestionIndex = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$emptyACModel = {
	fb: A2($author$project$App$Components$Autocomplete$init, '', 10),
	gt: A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$AcQuestionIndex, $author$project$App$Data$Id$uuidNil, $author$project$App$Data$Statement$Bearer, $elm$core$Maybe$Nothing)
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$init = F2(
	function (stmtId, sharedState) {
		var _v0 = $author$project$App$Data$Nexodata$init;
		var initNexodata = _v0.a;
		var nCmd = _v0.b;
		var _v1 = $author$project$App$Data$Memed$init;
		var initMemed = _v1.a;
		var mCmd = _v1.b;
		return _Utils_Tuple3(
			{
				f8: $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$emptyACModel,
				aZ: $elm$core$Dict$empty,
				fa: _List_Nil,
				bs: _List_Nil,
				b5: $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabCIDs,
				an: $elm$core$Dict$empty,
				gH: _List_Nil,
				P: $author$project$App$Data$FullStatement$emptyFullStatement,
				hi: initMemed,
				ho: initNexodata,
				ey: $elm$time$Time$millisToPosix(0),
				cA: $elm$core$Dict$empty,
				h$: false,
				h0: false,
				h1: $elm$core$Maybe$Nothing,
				h2: '',
				h3: false,
				p: 0,
				fX: $elm$core$Dict$empty,
				e$: $author$project$App$Service$Loadable$NotLoaded
			},
			A2($elm$core$Task$perform, $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$GotTime, $elm$time$Time$now),
			_List_fromArray(
				[
					$author$project$App$SharedState$APIMsg(
					A3(
						$author$project$App$SharedState$LoadFullStatementWithMinors,
						stmtId,
						_List_fromArray(
							[
								$author$project$App$SharedState$FullStmtAssetAnswers(1),
								$author$project$App$SharedState$FullStmtAssetQuestions(1),
								$author$project$App$SharedState$FullStmtAssetAnswers(2),
								$author$project$App$SharedState$FullStmtAssetQuestions(2),
								$author$project$App$SharedState$FullStmtAssetAnswers(3),
								$author$project$App$SharedState$FullStmtAssetQuestions(3),
								$author$project$App$SharedState$FullStmtAssetDocuments,
								$author$project$App$SharedState$FullStmtAssetICD
							]),
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
								$author$project$App$Page$ResponseMsgs$DoctorViewEvaluationMsg(0))
							]))),
					$author$project$App$SharedState$APIMsg(
					$author$project$App$SharedState$GetICDCategories(
						_List_fromArray(
							[
								$author$project$App$Page$ResponseMsgs$DoctorMsg(
								$author$project$App$Page$ResponseMsgs$DoctorViewEvaluationMsg(1))
							])))
				]));
	});
var $author$project$App$Page$Doctor$PageInterview$updateRouteInternal = F3(
	function (route, st, model) {
		var _v0 = _Utils_Tuple3(route, model.aQ, st.ia.h8.g.e.aw);
		if (_v0.a === 1) {
			switch (_v0.b.$) {
				case 1:
					var _v1 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				case 2:
					var _v2 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				case 3:
					var _v3 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				case 4:
					var _v4 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				case 5:
					var _v5 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				default:
					switch (_v0.c) {
						case 13:
							var _v6 = _v0.a;
							var _v7 = _v0.c;
							var _v8 = $author$project$App$Page$Doctor$Interview$PagePresentation$init(st);
							var nPageModel = _v8.a;
							var pageCmd = _v8.b;
							var stMsgs = _v8.c;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										aQ: $author$project$App$Page$Doctor$PageInterview$Presentation(nPageModel)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$PresentationMsg, pageCmd),
								stMsgs);
						case 14:
							var _v9 = _v0.a;
							var _v10 = _v0.c;
							var _v11 = $author$project$App$Page$Doctor$Interview$PageOrientation$init(st);
							var nPageModel = _v11.a;
							var pageCmd = _v11.b;
							var stMsgs = _v11.c;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										aQ: $author$project$App$Page$Doctor$PageInterview$Orientation(nPageModel)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$OrientationMsg, pageCmd),
								stMsgs);
						case 15:
							var _v12 = _v0.a;
							var _v13 = _v0.c;
							var _v14 = $author$project$App$Page$Doctor$Interview$PageConfirmId$init(st);
							var nPageModel = _v14.a;
							var pageCmd = _v14.b;
							var stMsgs = _v14.c;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										aQ: $author$project$App$Page$Doctor$PageInterview$ConfirmId(nPageModel)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$ConfirmIdMsg, pageCmd),
								stMsgs);
						case 16:
							var _v15 = _v0.a;
							var _v16 = _v0.c;
							var _v17 = $author$project$App$Page$Doctor$Interview$PageConfirmMetrics$init(st);
							var nPageModel = _v17.a;
							var pageCmd = _v17.b;
							var stMsgs = _v17.c;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										aQ: $author$project$App$Page$Doctor$PageInterview$ConfirmMetrics(nPageModel)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$ConfirmMetricsMsg, pageCmd),
								stMsgs);
						case 17:
							var _v18 = _v0.a;
							var _v19 = _v0.c;
							var _v20 = $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$init(st);
							var nPageModel = _v20.a;
							var pageCmd = _v20.b;
							var stMsgs = _v20.c;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										aQ: $author$project$App$Page$Doctor$PageInterview$ConfirmQuestions(nPageModel)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Page$Doctor$PageInterview$ConfirmQuestionsMsg, pageCmd),
								stMsgs);
						default:
							var _v21 = _v0.a;
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
					}
			}
		} else {
			var _v22 = _v0.a;
			return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		}
	});
var $author$project$App$Page$Doctor$PageInterview$updateRoute = F3(
	function (route, st, model) {
		var _v0 = _Utils_Tuple2(model.aQ, route);
		if ((!_v0.a.$) && (!_v0.b)) {
			var _v1 = _v0.b;
			return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		} else {
			return A3($author$project$App$Page$Doctor$PageInterview$updateRouteInternal, route, st, model);
		}
	});
var $author$project$App$Page$PageDoctor$updateLoadedRoute = F4(
	function (route, now, sharedState, model) {
		var _v0 = _Utils_Tuple2(route, model.aQ);
		switch (_v0.a.$) {
			case 0:
				var _v1 = _v0.a;
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
			case 1:
				if (_v0.b.$ === 1) {
					var _v2 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v3 = _v0.a;
					var _v4 = $author$project$App$Page$Doctor$PageWaitingLine$init(sharedState);
					var pageModel = _v4.a;
					var pageCmd = _v4.b;
					var stMsgs = _v4.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$WaitingLine(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$WaitingLineMsg, pageCmd),
						stMsgs);
				}
			case 2:
				if (_v0.b.$ === 2) {
					var _v5 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v6 = _v0.a;
					var _v7 = $author$project$App$Page$Doctor$PageReview$init(sharedState);
					var pageModel = _v7.a;
					var pageCmd = _v7.b;
					var stMsgs = _v7.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$Review(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ReviewMsg, pageCmd),
						stMsgs);
				}
			case 3:
				if (_v0.b.$ === 3) {
					var pageRoute = _v0.a.a;
					var pModel = _v0.b.a;
					var _v8 = A3($author$project$App$Page$Doctor$PageInterview$updateRoute, pageRoute, sharedState, pModel);
					var pageModel = _v8.a;
					var pageMsg = _v8.b;
					var listMsgs = _v8.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$Interview(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$InterviewMsg, pageMsg),
						listMsgs);
				} else {
					var _v9 = $author$project$App$Page$Doctor$PageInterview$init(sharedState);
					var pageModel = _v9.a;
					var pageMsg = _v9.b;
					var listMsgs = _v9.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$Interview(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$InterviewMsg, pageMsg),
						listMsgs);
				}
			case 4:
				if (_v0.b.$ === 4) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var stmtId = _v0.a.a;
					var _v10 = A2($author$project$App$Page$Doctor$PageFinalEvaluation$init, stmtId, sharedState);
					var pageModel = _v10.a;
					var pageMsg = _v10.b;
					var listMsgs = _v10.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$FinalEvaluation(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$FinalEvaluationMsg, pageMsg),
						listMsgs);
				}
			case 8:
				if (_v0.b.$ === 8) {
					var _v11 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v12 = _v0.a;
					var _v13 = $author$project$App$Page$Doctor$PageListReevaluation$init(sharedState);
					var pageModel = _v13.a;
					var pageMsg = _v13.b;
					var listMsgs = _v13.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$ListReevaluation(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ListReevaluationMsg, pageMsg),
						listMsgs);
				}
			case 9:
				if (_v0.b.$ === 9) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var stmtId = _v0.a.a;
					var _v14 = A2($author$project$App$Page$Doctor$PageReevaluation$init, stmtId, sharedState);
					var pageModel = _v14.a;
					var pageMsg = _v14.b;
					var listMsgs = _v14.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$Reevaluation(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ReevaluationMsg, pageMsg),
						listMsgs);
				}
			case 5:
				if (_v0.b.$ === 5) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var stmtId = _v0.a.a;
					var _v15 = A2($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$init, stmtId, sharedState);
					var pageModel = _v15.a;
					var pageMsg = _v15.b;
					var listMsgs = _v15.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$ViewEvaluation(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ViewEvaluationMsg, pageMsg),
						listMsgs);
				}
			case 6:
				if (_v0.b.$ === 6) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var filter = _v0.a.a;
					var _v16 = A2($author$project$App$Page$Doctor$PageListPending$init, filter, sharedState);
					var pageModel = _v16.a;
					var pageMsg = _v16.b;
					var listMsgs = _v16.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$ListPending(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ListPendingMsg, pageMsg),
						listMsgs);
				}
			case 7:
				if (_v0.b.$ === 7) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var finishedRoute = _v0.a.a;
					var _v17 = A2($author$project$App$Page$Doctor$PageListFinished$init, finishedRoute, sharedState);
					var pageModel = _v17.a;
					var pageMsg = _v17.b;
					var listMsgs = _v17.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$ListFinished(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$ListFinishedMsg, pageMsg),
						listMsgs);
				}
			default:
				if (_v0.b.$ === 10) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v18 = A2($author$project$App$Page$Doctor$PageDoctorSales$init, now, sharedState);
					var pageModel = _v18.a;
					var pageMsg = _v18.b;
					var listMsgs = _v18.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								aQ: $author$project$App$Page$PageDoctor$DoctorSales(pageModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageDoctor$DoctorSalesMsg, pageMsg),
						listMsgs);
				}
		}
	});
var $author$project$App$Page$PageDoctor$updateRoute = F4(
	function (route, now, st, model) {
		var _v0 = model.aQ;
		if (!_v0.$) {
			var lModel = _v0.a;
			return $author$project$App$Page$Doctor$PageLoading$isLoaded(lModel) ? A4($author$project$App$Page$PageDoctor$updateLoadedRoute, route, now, st, model) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
		} else {
			return A4($author$project$App$Page$PageDoctor$updateLoadedRoute, route, now, st, model);
		}
	});
var $author$project$App$Page$PageReviewer$updateRoute = F3(
	function (route, st, model) {
		var _v0 = _Utils_Tuple2(route, model);
		switch (_v0.a.$) {
			case 1:
				if (!_v0.b.$) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var listIncompleteRoute = _v0.a.a;
					var _v1 = A2($author$project$App$Page$Reviewer$PageListIncomplete$init, listIncompleteRoute, st);
					var pageModel = _v1.a;
					var pageMsg = _v1.b;
					var stMsgs = _v1.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$ListIncomplete(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListIncompleteMsg, pageMsg),
						stMsgs);
				}
			case 0:
				if (_v0.b.$ === 1) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var listForReviewRoute = _v0.a.a;
					var _v2 = A2($author$project$App$Page$Reviewer$PageListForReview$init, listForReviewRoute, st);
					var pageModel = _v2.a;
					var pageMsg = _v2.b;
					var stMsgs = _v2.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$ListForReview(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListForReviewMsg, pageMsg),
						stMsgs);
				}
			case 2:
				if (_v0.b.$ === 2) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var listApprovedRoute = _v0.a.a;
					var _v3 = A2($author$project$App$Page$Reviewer$PageListApproved$init, listApprovedRoute, st);
					var pageModel = _v3.a;
					var pageMsg = _v3.b;
					var stMsgs = _v3.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$ListApproved(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListApprovedMsg, pageMsg),
						stMsgs);
				}
			case 3:
				if (_v0.b.$ === 3) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var riRoute = _v0.a.a;
					var _v4 = A2($author$project$App$Page$Reviewer$PageReview$init, riRoute, st);
					var pageModel = _v4.a;
					var pageMsg = _v4.b;
					var stMsgs = _v4.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$ReviewInterview(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ReviewInterviewMsg, pageMsg),
						stMsgs);
				}
			case 4:
				if (_v0.b.$ === 4) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var riRoute = _v0.a.a;
					var _v5 = A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$init, riRoute, st);
					var pageModel = _v5.a;
					var pageMsg = _v5.b;
					var stMsgs = _v5.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$EditStatement(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$EditStatementMsg, pageMsg),
						stMsgs);
				}
			case 5:
				if (_v0.b.$ === 6) {
					var _v6 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v7 = _v0.a;
					var _v8 = $author$project$App$Page$Reviewer$PageListReevaluation$init(st);
					var pModel = _v8.a;
					var pageMsg = _v8.b;
					var listMsgs = _v8.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$ListReevaluation(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ListReevaluationMsg, pageMsg),
						listMsgs);
				}
			case 6:
				if (_v0.b.$ === 5) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var stmtId = _v0.a.a;
					var _v9 = A2($author$project$App$Page$Reviewer$PageReevaluation$init, stmtId, st);
					var pageModel = _v9.a;
					var pageMsg = _v9.b;
					var stMsgs = _v9.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$Reevaluation(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$ReevaluationMsg, pageMsg),
						stMsgs);
				}
			case 7:
				if (_v0.b.$ === 7) {
					var _v10 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v11 = _v0.a;
					var _v12 = $author$project$App$Page$Reviewer$PageWaitingLine$init(st);
					var pageModel = _v12.a;
					var pageMsg = _v12.b;
					var stMsgs = _v12.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$WaitingLine(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$WaitingLineMsg, pageMsg),
						stMsgs);
				}
			default:
				if (_v0.b.$ === 8) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var stmtId = _v0.a.a;
					var _v13 = $author$project$App$Page$Reviewer$PageWaitingProponentDetails$init(st);
					var pageModel = _v13.a;
					var pageMsg = _v13.b;
					var stMsgs = _v13.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageReviewer$WaitingProponentDetails(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageReviewer$WaitingProponentDetailsMsg, pageMsg),
						stMsgs);
				}
		}
	});
var $author$project$App$Page$PageSupport$updateRoute = F3(
	function (route, st, model) {
		var _v0 = _Utils_Tuple2(route, model);
		switch (_v0.a.$) {
			case 1:
				if (!_v0.b.$) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var listIncompleteRoute = _v0.a.a;
					var _v1 = A2($author$project$App$Page$Support$PageListIncomplete$init, listIncompleteRoute, st);
					var pageModel = _v1.a;
					var pageMsg = _v1.b;
					var stMsgs = _v1.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$ListIncomplete(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListIncompleteMsg, pageMsg),
						stMsgs);
				}
			case 0:
				if (_v0.b.$ === 1) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var listForReviewRoute = _v0.a.a;
					var _v2 = A2($author$project$App$Page$Support$PageListForReview$init, listForReviewRoute, st);
					var pageModel = _v2.a;
					var pageMsg = _v2.b;
					var stMsgs = _v2.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$ListForReview(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListForReviewMsg, pageMsg),
						stMsgs);
				}
			case 2:
				if (_v0.b.$ === 2) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var listApprovedRoute = _v0.a.a;
					var _v3 = A2($author$project$App$Page$Support$PageListApproved$init, listApprovedRoute, st);
					var pageModel = _v3.a;
					var pageMsg = _v3.b;
					var stMsgs = _v3.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$ListApproved(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListApprovedMsg, pageMsg),
						stMsgs);
				}
			case 3:
				if (_v0.b.$ === 3) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var riRoute = _v0.a.a;
					var _v4 = A2($author$project$App$Page$Support$PageReview$init, riRoute, st);
					var pageModel = _v4.a;
					var pageMsg = _v4.b;
					var stMsgs = _v4.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$ReviewInterview(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ReviewInterviewMsg, pageMsg),
						stMsgs);
				}
			case 4:
				if (_v0.b.$ === 4) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var riRoute = _v0.a.a;
					var _v5 = A2($author$project$App$Page$Support$EditStatement$StatementEdit$init, riRoute, st);
					var pageModel = _v5.a;
					var pageMsg = _v5.b;
					var stMsgs = _v5.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$EditStatement(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$EditStatementMsg, pageMsg),
						stMsgs);
				}
			case 5:
				if (_v0.b.$ === 6) {
					var _v6 = _v0.a;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var _v7 = _v0.a;
					var _v8 = $author$project$App$Page$Support$PageListReevaluation$init(st);
					var pModel = _v8.a;
					var pageMsg = _v8.b;
					var listMsgs = _v8.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$ListReevaluation(pModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ListReevaluationMsg, pageMsg),
						listMsgs);
				}
			default:
				if (_v0.b.$ === 5) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, _List_Nil);
				} else {
					var stmtId = _v0.a.a;
					var _v9 = A2($author$project$App$Page$Support$PageReevaluation$init, stmtId, st);
					var pageModel = _v9.a;
					var pageMsg = _v9.b;
					var stMsgs = _v9.c;
					return _Utils_Tuple3(
						$author$project$App$Page$PageSupport$Reevaluation(pageModel),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Page$PageSupport$ReevaluationMsg, pageMsg),
						stMsgs);
				}
		}
	});
var $author$project$App$Main$updateRoute = function (modelUpdated) {
	var _v0 = _Utils_Tuple2(modelUpdated.eP, modelUpdated.aQ);
	switch (_v0.a.$) {
		case 3:
			return _Utils_Tuple2(modelUpdated, $elm$core$Platform$Cmd$none);
		case 1:
			switch (_v0.a.a.$) {
				case 0:
					var _v1 = _v0.a.a;
					return _Utils_Tuple2(modelUpdated, $elm$core$Platform$Cmd$none);
				case 2:
					if (_v0.b.$ === 5) {
						var _v2 = _v0.a.a;
						return _Utils_Tuple2(modelUpdated, $elm$core$Platform$Cmd$none);
					} else {
						var _v3 = _v0.a.a;
						var _v4 = $author$project$App$Page$PageDoctorSignUp$init;
						var pModel = _v4.a;
						var sharedMsg = _v4.b;
						var _v5 = A2($author$project$App$SharedState$updateShared, sharedMsg, modelUpdated.a);
						var sharedModel = _v5.a;
						var sharedCmd = _v5.b;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$DoctorSignUp(pModel),
									a: sharedModel
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedCmd));
					}
				case 3:
					if (_v0.b.$ === 6) {
						var _v6 = _v0.a.a;
						return _Utils_Tuple2(modelUpdated, $elm$core$Platform$Cmd$none);
					} else {
						var _v7 = _v0.a.a;
						var _v8 = $author$project$App$Page$PageUserSignUp$init;
						var pModel = _v8.a;
						var pCmd = _v8.b;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$UserSignUp(pModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Main$UserSignUpMsg, pCmd));
					}
				case 5:
					if (_v0.b.$ === 8) {
						var _v9 = _v0.a.a;
						return _Utils_Tuple2(modelUpdated, $elm$core$Platform$Cmd$none);
					} else {
						var _v10 = _v0.a.a;
						var _v11 = $author$project$App$Page$PageUserRequestRecoverPassword$init;
						var pModel = _v11.a;
						var pCmd = _v11.b;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$UserRequestRecoverPassword(pModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Main$UserRequestRecoverPasswordMsg, pCmd));
					}
				case 6:
					if (_v0.b.$ === 9) {
						return _Utils_Tuple2(modelUpdated, $elm$core$Platform$Cmd$none);
					} else {
						var session = _v0.a.a.a;
						var _v12 = $author$project$App$Page$PageUserRecoverPassword$init(session);
						var pModel = _v12.a;
						var pCmd = _v12.b;
						var sharedMsg = _v12.c;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$UserRecoverPassword(pModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Main$UserRecoverPasswordMsg, pCmd));
					}
				case 4:
					if (_v0.b.$ === 7) {
						var _v13 = _v0.a.a;
						return _Utils_Tuple2(modelUpdated, $elm$core$Platform$Cmd$none);
					} else {
						var _v14 = _v0.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$UserSignUpComplete($author$project$App$Page$PageUserSignUpComplete$init)
								}),
							$elm$core$Platform$Cmd$none);
					}
				case 8:
					var _v15 = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							modelUpdated,
							{
								aQ: $author$project$App$Main$FinanceSales($author$project$App$Page$Finances$PageFinanceSales$init)
							}),
						$elm$core$Platform$Cmd$none);
				case 1:
					if (_v0.b.$ === 1) {
						var _v16 = _v0.a.a;
						return _Utils_Tuple2(modelUpdated, $elm$core$Platform$Cmd$none);
					} else {
						var _v17 = _v0.a.a;
						var _v18 = $author$project$App$Page$PageLogin$init;
						var lModel = _v18.a;
						var sharedMsg = _v18.b;
						var _v19 = A2($author$project$App$SharedState$updateShared, sharedMsg, modelUpdated.a);
						var sharedModel = _v19.a;
						var sharedCmd = _v19.b;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$Login(lModel),
									a: sharedModel
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedCmd));
					}
				default:
					var filter = _v0.a.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							modelUpdated,
							{
								aQ: $author$project$App$Main$DoctorResume(
									$author$project$App$Page$Finances$PageDoctorResume$init(filter))
							}),
						$elm$core$Platform$Cmd$none);
			}
		case 2:
			if (_v0.a.b === 1) {
				var _v22 = _v0.a;
				var stmtId = _v22.a;
				var _v23 = _v22.b;
				var _v24 = A2($author$project$App$Page$PageProponent$init, stmtId, modelUpdated.a);
				var pModel = _v24.a;
				var pCmd = _v24.b;
				var sharedMsgs = _v24.c;
				var _v25 = A2($author$project$App$SharedState$updateShared, sharedMsgs, modelUpdated.a);
				var sharedModel = _v25.a;
				var sharedCmd = _v25.b;
				return _Utils_Tuple2(
					_Utils_update(
						modelUpdated,
						{
							aQ: $author$project$App$Main$Proponent(pModel),
							a: sharedModel
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$App$Main$ProponentMsg, pCmd),
								A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedCmd)
							])));
			} else {
				var _v26 = _v0.a;
				return _Utils_Tuple2(modelUpdated, $elm$core$Platform$Cmd$none);
			}
		default:
			switch (_v0.a.a.$) {
				case 0:
					var _v20 = _v0.a.a;
					var _v21 = A2(
						$author$project$App$SharedState$updateShared,
						_List_fromArray(
							[
								$author$project$App$SharedState$APIMsg($author$project$App$SharedState$Logout)
							]),
						modelUpdated.a);
					var sharedModel = _v21.a;
					var sharedCmd = _v21.b;
					return _Utils_Tuple2(
						_Utils_update(
							modelUpdated,
							{a: sharedModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedCmd));
				case 1:
					if (_v0.b.$ === 11) {
						var doctorRoute = _v0.a.a.a;
						var pageModel = _v0.b.a;
						var _v27 = A4($author$project$App$Page$PageDoctor$updateRoute, doctorRoute, modelUpdated.ey, modelUpdated.a, pageModel);
						var dModel = _v27.a;
						var dCmd = _v27.b;
						var sharedMsg = _v27.c;
						var _v28 = A2($author$project$App$SharedState$updateShared, sharedMsg, modelUpdated.a);
						var sharedModel = _v28.a;
						var sharedCmd = _v28.b;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$Doctor(dModel),
									a: sharedModel
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$DoctorMsg, dCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedCmd)
									])));
					} else {
						var doctorRoute = _v0.a.a.a;
						var _v29 = A2($author$project$App$Page$PageDoctor$init, doctorRoute, modelUpdated.a);
						var dModel = _v29.a;
						var dCmd = _v29.b;
						var sharedMsg = _v29.c;
						var _v30 = A2($author$project$App$SharedState$updateShared, sharedMsg, modelUpdated.a);
						var sharedModel = _v30.a;
						var sharedCmd = _v30.b;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$Doctor(dModel),
									a: sharedModel
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$DoctorMsg, dCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedCmd)
									])));
					}
				case 2:
					if (_v0.b.$ === 12) {
						var companyRoute = _v0.a.a.a;
						var pageModel = _v0.b.a;
						var _v31 = A3($author$project$App$Page$PageCompany$updateRoute, companyRoute, modelUpdated.a, pageModel);
						var cModel = _v31.a;
						var cCmd = _v31.b;
						var sharedMsg = _v31.c;
						var _v32 = A2($author$project$App$SharedState$updateShared, sharedMsg, modelUpdated.a);
						var sharedModel = _v32.a;
						var sharedCmd = _v32.b;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$Company(cModel),
									a: sharedModel
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$CompanyMsg, cCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedCmd)
									])));
					} else {
						var companyRoute = _v0.a.a.a;
						var _v33 = A2($author$project$App$Page$PageCompany$init, companyRoute, modelUpdated.a);
						var dModel = _v33.a;
						var dCmd = _v33.b;
						var sharedMsg = _v33.c;
						var _v34 = A2($author$project$App$SharedState$updateShared, sharedMsg, modelUpdated.a);
						var sharedModel = _v34.a;
						var sharedCmd = _v34.b;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$Company(dModel),
									a: sharedModel
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$CompanyMsg, dCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedCmd)
									])));
					}
				case 3:
					if (_v0.b.$ === 13) {
						var reviewRoute = _v0.a.a.a;
						var pageModel = _v0.b.a;
						var _v35 = A3($author$project$App$Page$PageReviewer$updateRoute, reviewRoute, modelUpdated.a, pageModel);
						var cModel = _v35.a;
						var cCmd = _v35.b;
						var sharedMsg = _v35.c;
						var _v36 = A2($author$project$App$SharedState$updateShared, sharedMsg, modelUpdated.a);
						var sharedModel = _v36.a;
						var sharedCmd = _v36.b;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$Review(cModel),
									a: sharedModel
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$ReviewMsg, cCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedCmd)
									])));
					} else {
						var reviewerRoute = _v0.a.a.a;
						var _v37 = A2($author$project$App$Page$PageReviewer$init, reviewerRoute, modelUpdated.a);
						var dModel = _v37.a;
						var dCmd = _v37.b;
						var sharedMsg = _v37.c;
						var _v38 = A2($author$project$App$SharedState$updateShared, sharedMsg, modelUpdated.a);
						var sharedModel = _v38.a;
						var sharedCmd = _v38.b;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$Review(dModel),
									a: sharedModel
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$ReviewMsg, dCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedCmd)
									])));
					}
				case 4:
					if (_v0.b.$ === 14) {
						var supportRoute = _v0.a.a.a;
						var pageModel = _v0.b.a;
						var _v39 = A3($author$project$App$Page$PageSupport$updateRoute, supportRoute, modelUpdated.a, pageModel);
						var cModel = _v39.a;
						var cCmd = _v39.b;
						var sharedMsg = _v39.c;
						var _v40 = A2($author$project$App$SharedState$updateShared, sharedMsg, modelUpdated.a);
						var sharedModel = _v40.a;
						var sharedCmd = _v40.b;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$Support(cModel),
									a: sharedModel
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SupportMsg, cCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedCmd)
									])));
					} else {
						var supportRoute = _v0.a.a.a;
						var _v41 = A2($author$project$App$Page$PageSupport$init, supportRoute, modelUpdated.a);
						var dModel = _v41.a;
						var dCmd = _v41.b;
						var sharedMsg = _v41.c;
						var _v42 = A2($author$project$App$SharedState$updateShared, sharedMsg, modelUpdated.a);
						var sharedModel = _v42.a;
						var sharedCmd = _v42.b;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$Support(dModel),
									a: sharedModel
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SupportMsg, dCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedCmd)
									])));
					}
				default:
					if (_v0.b.$ === 15) {
						var adminRoute = _v0.a.a.a;
						var pageModel = _v0.b.a;
						var _v43 = A4($author$project$App$Page$PageAdmin$updateRoute, adminRoute, modelUpdated.ey, modelUpdated.a, pageModel);
						var cModel = _v43.a;
						var cCmd = _v43.b;
						var sharedMsg = _v43.c;
						var _v44 = A2($author$project$App$SharedState$updateShared, sharedMsg, modelUpdated.a);
						var sharedModel = _v44.a;
						var sharedCmd = _v44.b;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$Admin(cModel),
									a: sharedModel
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$AdminMsg, cCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedCmd)
									])));
					} else {
						var adminRoute = _v0.a.a.a;
						var _v45 = A3($author$project$App$Page$PageAdmin$init, adminRoute, modelUpdated.ey, modelUpdated.a);
						var dModel = _v45.a;
						var dCmd = _v45.b;
						var sharedMsg = _v45.c;
						var _v46 = A2($author$project$App$SharedState$updateShared, sharedMsg, modelUpdated.a);
						var sharedModel = _v46.a;
						var sharedCmd = _v46.b;
						return _Utils_Tuple2(
							_Utils_update(
								modelUpdated,
								{
									aQ: $author$project$App$Main$Admin(dModel),
									a: sharedModel
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$AdminMsg, dCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedCmd)
									])));
					}
			}
	}
};
var $author$project$App$Main$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model.aQ);
		_v0$22:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					var urlRequest = _v0.a.a;
					if (!urlRequest.$) {
						var url = urlRequest.a;
						return _Utils_Tuple2(
							model,
							A2(
								$author$project$App$Route$pushUrl,
								model.fC,
								$author$project$App$Route$parseUrl(url)));
					} else {
						var href = urlRequest.a;
						return _Utils_Tuple2(
							model,
							$elm$browser$Browser$Navigation$load(href));
					}
				case 1:
					var url = _v0.a.a;
					var route = $author$project$App$Route$parseUrl(url);
					return $author$project$App$Main$updateRoute(
						_Utils_update(
							model,
							{eP: route}));
				case 2:
					var zone = _v0.a.a;
					var _v2 = A2(
						$author$project$App$SharedState$updateShared,
						_List_fromArray(
							[
								$author$project$App$SharedState$UpdateZone(zone)
							]),
						model.a);
					var newShared = _v2.a;
					var newSharedCmd = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{a: newShared, f6: zone}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, newSharedCmd));
				case 3:
					var now = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ey: now}),
						$elm$core$Platform$Cmd$none);
				case 5:
					var _v3 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{eW: false}),
						$elm$core$Platform$Cmd$none);
				case 4:
					var stMsg = _v0.a.a;
					var _v4 = A2($author$project$App$SharedState$update, stMsg, model.a);
					var stModel = _v4.a;
					var stCmds = _v4.b;
					var respMsgs = _v4.c;
					var _v5 = A2(
						$author$project$App$Main$updateResponse,
						respMsgs,
						_Utils_update(
							model,
							{a: stModel}));
					var newModel = _v5.a;
					var newCmd = _v5.b;
					return _Utils_Tuple2(
						newModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, stCmds),
									newCmd
								])));
				case 6:
					if (!_v0.b.$) {
						var lMsg = _v0.a.a;
						var lModel = _v0.b.a;
						var _v6 = A3($author$project$App$Page$PageLoading$update, lMsg, model.a, lModel);
						var m = _v6.a;
						var c = _v6.b;
						var stMsgs = _v6.c;
						var _v7 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v7.a;
						var sharedStateCmd = _v7.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$Loading(m),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$LoadingMsg, c),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
				case 7:
					if (_v0.b.$ === 1) {
						var lMsg = _v0.a.a;
						var lModel = _v0.b.a;
						var _v8 = A3($author$project$App$Page$PageLogin$update, lMsg, model.a, lModel);
						var m = _v8.a;
						var c = _v8.b;
						var stMsgs = _v8.c;
						var _v9 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v9.a;
						var sharedStateCmd = _v9.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$Login(m),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$LoginMsg, c),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
				case 8:
					if (_v0.b.$ === 2) {
						var sMsg = _v0.a.a;
						var pModel = _v0.b.a;
						var _v10 = A3($author$project$App$Page$Finances$PageFinanceSales$update, sMsg, model.a, pModel);
						var m = _v10.a;
						var c = _v10.b;
						var stMsgs = _v10.c;
						var _v11 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v11.a;
						var sharedStateCmd = _v11.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$FinanceSales(m),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$FinanceSalesMsg, c),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
				case 10:
					if (_v0.b.$ === 4) {
						var rMsg = _v0.a.a;
						var tModel = _v0.b.a;
						var _v12 = A3($author$project$App$Page$Finances$PageDoctorResume$update, rMsg, model.a, tModel);
						var m = _v12.a;
						var c = _v12.b;
						var stMsgs = _v12.c;
						var _v13 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v13.a;
						var sharedStateCmd = _v13.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$DoctorResume(m),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$DoctorResumeMsg, c),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
				case 11:
					if (_v0.b.$ === 5) {
						var dsMsg = _v0.a.a;
						var dsModel = _v0.b.a;
						var _v14 = A3($author$project$App$Page$PageDoctorSignUp$update, dsMsg, model.a, dsModel);
						var m = _v14.a;
						var c = _v14.b;
						var stMsgs = _v14.c;
						var _v15 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v15.a;
						var sharedStateCmd = _v15.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$DoctorSignUp(m),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$DoctorSignUpMsg, c),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
				case 13:
					if (_v0.b.$ === 8) {
						var usMsg = _v0.a.a;
						var usModel = _v0.b.a;
						var _v16 = A3($author$project$App$Page$PageUserRequestRecoverPassword$update, usMsg, model.a, usModel);
						var m = _v16.a;
						var c = _v16.b;
						var stMsgs = _v16.c;
						var _v17 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v17.a;
						var sharedStateCmd = _v17.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$UserRequestRecoverPassword(m),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$UserRequestRecoverPasswordMsg, c),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
				case 14:
					if (_v0.b.$ === 9) {
						var usMsg = _v0.a.a;
						var usModel = _v0.b.a;
						var _v18 = A3($author$project$App$Page$PageUserRecoverPassword$update, usMsg, model.a, usModel);
						var m = _v18.a;
						var c = _v18.b;
						var stMsgs = _v18.c;
						var _v19 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v19.a;
						var sharedStateCmd = _v19.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$UserRecoverPassword(m),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$UserRecoverPasswordMsg, c),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
				case 12:
					if (_v0.b.$ === 6) {
						var usMsg = _v0.a.a;
						var usModel = _v0.b.a;
						var _v20 = A3($author$project$App$Page$PageUserSignUp$update, usMsg, model.a, usModel);
						var m = _v20.a;
						var c = _v20.b;
						var stMsgs = _v20.c;
						var _v21 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v21.a;
						var sharedStateCmd = _v21.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$UserSignUp(m),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$UserSignUpMsg, c),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
				case 15:
					if (_v0.b.$ === 7) {
						var dMsg = _v0.a.a;
						var pModel = _v0.b.a;
						var _v22 = A3($author$project$App$Page$PageUserSignUpComplete$update, dMsg, model.a, pModel);
						var m = _v22.a;
						var c = _v22.b;
						var stMsgs = _v22.c;
						var _v23 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v23.a;
						var sharedStateCmd = _v23.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$UserSignUpComplete(m),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$UserSignUpCompleteMsg, c),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
				case 16:
					if (_v0.b.$ === 10) {
						var pMsg = _v0.a.a;
						var pModel = _v0.b.a;
						var _v24 = A3($author$project$App$Page$PageProponent$update, pMsg, model.a, pModel);
						var p = _v24.a;
						var c = _v24.b;
						var stMsgs = _v24.c;
						var _v25 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v25.a;
						var sharedStateCmd = _v25.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$Proponent(p),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$ProponentMsg, c),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
				case 17:
					if (_v0.b.$ === 11) {
						var doctorMsg = _v0.a.a;
						var doctorModel = _v0.b.a;
						var _v26 = A3($author$project$App$Page$PageDoctor$update, doctorMsg, model.a, doctorModel);
						var newDoctorModel = _v26.a;
						var doctorCmd = _v26.b;
						var stMsgs = _v26.c;
						var _v27 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v27.a;
						var sharedStateCmd = _v27.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$Doctor(newDoctorModel),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$DoctorMsg, doctorCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
				case 18:
					if (_v0.b.$ === 12) {
						var companyMsg = _v0.a.a;
						var companyModel = _v0.b.a;
						var _v28 = A3($author$project$App$Page$PageCompany$update, companyMsg, model.a, companyModel);
						var newCompanyModel = _v28.a;
						var companyCmd = _v28.b;
						var stMsgs = _v28.c;
						var _v29 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v29.a;
						var sharedStateCmd = _v29.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$Company(newCompanyModel),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$CompanyMsg, companyCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
				case 19:
					if (_v0.b.$ === 13) {
						var reviewMsg = _v0.a.a;
						var reviewModel = _v0.b.a;
						var _v30 = A3($author$project$App$Page$PageReviewer$update, reviewMsg, model.a, reviewModel);
						var newReviewModel = _v30.a;
						var reviewCmd = _v30.b;
						var stMsgs = _v30.c;
						var _v31 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v31.a;
						var sharedStateCmd = _v31.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$Review(newReviewModel),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$ReviewMsg, reviewCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
				case 20:
					if (_v0.b.$ === 14) {
						var supportMsg = _v0.a.a;
						var supportModel = _v0.b.a;
						var _v32 = A3($author$project$App$Page$PageSupport$update, supportMsg, model.a, supportModel);
						var newSupportModel = _v32.a;
						var supportCmd = _v32.b;
						var stMsgs = _v32.c;
						var _v33 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v33.a;
						var sharedStateCmd = _v33.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$Support(newSupportModel),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SupportMsg, supportCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
				case 21:
					if (_v0.b.$ === 15) {
						var adminMsg = _v0.a.a;
						var adminModel = _v0.b.a;
						var _v34 = A4($author$project$App$Page$PageAdmin$update, adminMsg, model.ey, model.a, adminModel);
						var newAdminModel = _v34.a;
						var adminCmd = _v34.b;
						var stMsgs = _v34.c;
						var _v35 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v35.a;
						var sharedStateCmd = _v35.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$Admin(newAdminModel),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$AdminMsg, adminCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
				default:
					if (_v0.b.$ === 3) {
						var clientMsg = _v0.a.a;
						var clientModel = _v0.b.a;
						var _v36 = A3($author$project$App$Page$Client$PageClient$update, clientMsg, model.a, clientModel);
						var newClientModel = _v36.a;
						var clientCmd = _v36.b;
						var stMsgs = _v36.c;
						var _v37 = A2($author$project$App$SharedState$updateShared, stMsgs, model.a);
						var newSharedState = _v37.a;
						var sharedStateCmd = _v37.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aQ: $author$project$App$Main$Client(newClientModel),
									a: newSharedState
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$ClientMsg, clientCmd),
										A2($elm$core$Platform$Cmd$map, $author$project$App$Main$SharedStateMsg, sharedStateCmd)
									])));
					} else {
						break _v0$22;
					}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$App$Page$Client$PageClient$Login = 0;
var $author$project$App$Page$Client$PageClient$SignUp = 1;
var $author$project$App$Page$Client$PageClient$Submit = function (a) {
	return {$: 2, a: a};
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$footer = _VirtualDom_node('footer');
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$header = _VirtualDom_node('header');
var $elm$html$Html$i = _VirtualDom_node('i');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$section = _VirtualDom_node('section');
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$App$Page$Client$PageClient$FieldBirthDate = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Client$PageClient$FieldDocument = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Client$PageClient$Input = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$fieldset = _VirtualDom_node('fieldset');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$legend = _VirtualDom_node('legend');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$App$Page$Client$PageClient$viewDocumentError = function (model) {
	var _v0 = model.cH;
	if (_v0.$ === 2) {
		return A2(
			$elm$html$Html$tr,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('invalidcpf')
				]),
			_List_fromArray(
				[
					A2($elm$html$Html$td, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('errorMessage')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('CPF Inválido')
						]))
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$App$Page$Client$PageClient$viewLoginForm = function (model) {
	var errorElement = function () {
		var _v0 = model.cF;
		if (!_v0.$) {
			var error = _v0.a;
			return A2(
				$elm$html$Html$tr,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('invalidcpf')
					]),
				_List_fromArray(
					[
						A2($elm$html$Html$td, _List_Nil, _List_Nil),
						A2(
						$elm$html$Html$td,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('errorMessage')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(error)
							]))
					]));
		} else {
			return $elm$html$Html$text('');
		}
	}();
	return A2(
		$elm$html$Html$fieldset,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$legend,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Login')
					])),
				A2(
				$elm$html$Html$table,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tbody,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('login')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('CPF')
											])),
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Events$onInput(
														A2($elm$core$Basics$composeL, $author$project$App$Page$Client$PageClient$Input, $author$project$App$Page$Client$PageClient$FieldDocument)),
														$elm$html$Html$Attributes$placeholder('Digite seu CPF'),
														$elm$html$Html$Attributes$type_('text'),
														$elm$html$Html$Attributes$value(model.i1)
													]),
												_List_Nil)
											]))
									])),
								$author$project$App$Page$Client$PageClient$viewDocumentError(model),
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Data de Nascimento')
											])),
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$placeholder('Digite sua data de nascimento'),
														$elm$html$Html$Attributes$type_('text'),
														$elm$html$Html$Attributes$value(model.al),
														$elm$html$Html$Events$onInput(
														A2($elm$core$Basics$composeL, $author$project$App$Page$Client$PageClient$Input, $author$project$App$Page$Client$PageClient$FieldBirthDate))
													]),
												_List_Nil)
											]))
									])),
								errorElement,
								(model.aw === 1) ? A2(
								$elm$html$Html$tr,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('invalidLogin')
									]),
								_List_fromArray(
									[
										A2($elm$html$Html$td, _List_Nil, _List_Nil),
										A2(
										$elm$html$Html$td,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('errorMessage')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Dados Invalidos')
											]))
									])) : $elm$html$Html$text('')
							]))
					]))
			]));
};
var $author$project$App$Page$Client$PageClient$FieldSignUpEmail = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$Client$PageClient$FieldSignUpGender = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Client$PageClient$FieldSignUpMotherName = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Client$PageClient$FieldSignUpName = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Client$PageClient$FieldSignUpPhone = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$Client$PageClient$FieldSignUpSurName = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$select = _VirtualDom_node('select');
var $author$project$App$Page$Client$PageClient$viewTrInput = F6(
	function (txtLabel, txtPlaceholdr, typeTxt, valueInput, inputMsg, isDisabled) {
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(txtLabel)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$placeholder(txtPlaceholdr),
									$elm$html$Html$Attributes$type_(typeTxt),
									$elm$html$Html$Attributes$value(valueInput),
									$elm$html$Html$Events$onInput(inputMsg),
									$elm$html$Html$Attributes$disabled(isDisabled)
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$App$Page$Client$PageClient$viewSignUpForm = function (model) {
	var errorElement = function () {
		var _v0 = model.cF;
		if (!_v0.$) {
			var error = _v0.a;
			return A2(
				$elm$html$Html$tr,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('invalidcpf')
					]),
				_List_fromArray(
					[
						A2($elm$html$Html$td, _List_Nil, _List_Nil),
						A2(
						$elm$html$Html$td,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('errorMessage')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(error)
							]))
					]));
		} else {
			return $elm$html$Html$text('');
		}
	}();
	return A2(
		$elm$html$Html$fieldset,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$legend,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Cadastro')
					])),
				A2(
				$elm$html$Html$table,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tbody,
						_List_Nil,
						_List_fromArray(
							[
								A6(
								$author$project$App$Page$Client$PageClient$viewTrInput,
								'CPF',
								'Digite seu CPF',
								'text',
								model.i1,
								A2($elm$core$Basics$composeL, $author$project$App$Page$Client$PageClient$Input, $author$project$App$Page$Client$PageClient$FieldDocument),
								true),
								A6(
								$author$project$App$Page$Client$PageClient$viewTrInput,
								'Data de nascimento',
								'Digite sua data de nascimento',
								'text',
								model.al,
								A2($elm$core$Basics$composeL, $author$project$App$Page$Client$PageClient$Input, $author$project$App$Page$Client$PageClient$FieldBirthDate),
								true),
								A6(
								$author$project$App$Page$Client$PageClient$viewTrInput,
								'Nome',
								'Digite o Nome',
								'text',
								model.hl,
								A2($elm$core$Basics$composeL, $author$project$App$Page$Client$PageClient$Input, $author$project$App$Page$Client$PageClient$FieldSignUpName),
								false),
								A6(
								$author$project$App$Page$Client$PageClient$viewTrInput,
								'Sobrenome',
								'Digite o Sobrenome',
								'text',
								model.cI,
								A2($elm$core$Basics$composeL, $author$project$App$Page$Client$PageClient$Input, $author$project$App$Page$Client$PageClient$FieldSignUpSurName),
								false),
								A6(
								$author$project$App$Page$Client$PageClient$viewTrInput,
								'Vínculo (EBSERH, RJU, Residente)',
								'Digite o vínculo',
								'text',
								model.cp,
								A2($elm$core$Basics$composeL, $author$project$App$Page$Client$PageClient$Input, $author$project$App$Page$Client$PageClient$FieldSignUpMotherName),
								false),
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Sexo')
											])),
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$select,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class(''),
														A2($elm$html$Html$Attributes$attribute, 'value', model.db),
														$elm$html$Html$Events$onInput(
														A2($elm$core$Basics$composeL, $author$project$App$Page$Client$PageClient$Input, $author$project$App$Page$Client$PageClient$FieldSignUpGender))
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$option,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$value('')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Selecione')
															])),
														A2(
														$elm$html$Html$option,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$value('M')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Masculino')
															])),
														A2(
														$elm$html$Html$option,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$value('F')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Feminino')
															]))
													]))
											]))
									])),
								A6(
								$author$project$App$Page$Client$PageClient$viewTrInput,
								'E-mail',
								'Digite o email',
								'email',
								model.c7,
								A2($elm$core$Basics$composeL, $author$project$App$Page$Client$PageClient$Input, $author$project$App$Page$Client$PageClient$FieldSignUpEmail),
								false),
								A6(
								$author$project$App$Page$Client$PageClient$viewTrInput,
								'Telefone Celular',
								'Digite o Celular',
								'tel',
								model.dx,
								A2($elm$core$Basics$composeL, $author$project$App$Page$Client$PageClient$Input, $author$project$App$Page$Client$PageClient$FieldSignUpPhone),
								false),
								errorElement
							]))
					]))
			]));
};
var $author$project$App$Page$Client$PageClient$view = function (model) {
	var form = function () {
		var _v2 = model.aw;
		switch (_v2) {
			case 0:
				return $author$project$App$Page$Client$PageClient$viewLoginForm;
			case 1:
				return $author$project$App$Page$Client$PageClient$viewLoginForm;
			default:
				return $author$project$App$Page$Client$PageClient$viewSignUpForm;
		}
	}();
	var _v0 = function () {
		var _v1 = model.aw;
		switch (_v1) {
			case 0:
				return _Utils_Tuple2(
					A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(
								$elm$core$String$isEmpty(model.i1) || $elm$core$String$isEmpty(model.al)),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Client$PageClient$Submit(0)),
								$elm$html$Html$Attributes$class('ready')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Login')
									])),
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('material-icons')
									]),
								_List_Nil)
							])),
					A2($elm$html$Html$Attributes$attribute, 'style', 'width: 0%;'));
			case 2:
				return _Utils_Tuple2(
					A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Client$PageClient$Submit(1)),
								$elm$html$Html$Attributes$class('ready')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Cadastrar')
									])),
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('material-icons')
									]),
								_List_Nil)
							])),
					A2($elm$html$Html$Attributes$attribute, 'style', 'width: 6%;'));
			default:
				return _Utils_Tuple2(
					A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(true)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('')
									])),
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('material-icons')
									]),
								_List_Nil)
							])),
					A2($elm$html$Html$Attributes$attribute, 'style', 'width: 6%;'));
		}
	}();
	var btnAction = _v0.a;
	var progress = _v0.b;
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$header,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('brand')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Login')
						]))
				])),
			A2(
			$elm$html$Html$section,
			_List_Nil,
			_List_fromArray(
				[
					form(model)
				])),
			A2(
			$elm$html$Html$footer,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('progress')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'style', 'width: 0%;')
								]),
							_List_Nil)
						])),
					btnAction
				]))
		]);
};
var $author$project$App$Page$Finances$PageDoctorResume$convertIntToMonth = function (m) {
	switch (m) {
		case 1:
			return 'Janeiro';
		case 2:
			return 'Fevereiro';
		case 3:
			return 'Março';
		case 4:
			return 'Abril';
		case 5:
			return 'Maio';
		case 6:
			return 'Junho';
		case 7:
			return 'Julho';
		case 8:
			return 'Agosto';
		case 9:
			return 'Setembro';
		case 10:
			return 'Outubro';
		case 11:
			return 'Novembro';
		case 12:
			return 'Dezembro';
		default:
			return 'Inválido';
	}
};
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$App$Page$Finances$PageDoctorResume$view = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('section-examdata')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$header,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('section-examdata-header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Gestão de Médicos')
								])),
							A2(
							$elm$html$Html$ul,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('months')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$li,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('arrow-left')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$li,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('month')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$App$Page$Finances$PageDoctorResume$convertIntToMonth(model.hk))
										])),
									A2(
									$elm$html$Html$li,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('arrow-right')
										]),
									_List_Nil)
								])),
							A2($elm$html$Html$button, _List_Nil, _List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('section-main-examdata')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$ul,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('doctor-examdata')
								]),
							A2(
								$elm$core$List$map,
								function (d) {
									return A2(
										$elm$html$Html$li,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$img,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$src(d.c4)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('t-doctor-examdata')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('t-doctor-examdata')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Médico (a)')
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('doctorname-examdata')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(d.c6)
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('details-examdata')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('t-details-examdata')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Detalhamento')
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('detail-icon-examdata')
															]),
														_List_Nil)
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('exams-examdata')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('t-exams-examdata')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Nº Atendimentos')
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('examnumber-examdata')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(
																$elm$core$String$fromInt(d.c_))
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('t-totalvalue-examdata')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Saldo Total')
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('totalvalue-examdata')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(
																'R$' + $elm$core$String$fromFloat(d.iq))
															]))
													]))
											]));
								},
								model.fk))
						]))
				]))
		]);
};
var $author$project$App$Page$Finances$PageFinanceSales$ChangeSelectedMonth = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$Finances$PageFinanceSales$CloseSelectedSales = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Finances$PageFinanceSales$Negative = 0;
var $author$project$App$Page$Finances$PageFinanceSales$Neutral = 2;
var $author$project$App$Page$Finances$PageFinanceSales$Positive = 1;
var $author$project$App$Page$Finances$PageFinanceSales$SelectSales = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $author$project$Mask$intDecimal = function (signedDec) {
	var _v0 = (signedDec < 0) ? _Utils_Tuple2(
		signedDec * (-1),
		function (s) {
			return '-' + s;
		}) : _Utils_Tuple2(signedDec, $elm$core$Basics$identity);
	var dec = _v0.a;
	var signFun = _v0.b;
	var moneyStr = $elm$core$String$reverse(
		A3(
			$elm$core$String$padLeft,
			3,
			'0',
			$elm$core$String$fromInt(dec)));
	return signFun(
		$elm$core$String$reverse(
			function (_v2) {
				var result = _v2.c;
				return result;
			}(
				A3(
					$elm$core$String$foldl,
					F2(
						function (ch, _v1) {
							var counter = _v1.a;
							var isDecimal = _v1.b;
							var result = _v1.c;
							return (isDecimal && (counter === 2)) ? _Utils_Tuple3(
								0,
								false,
								result + (',' + $elm$core$String$fromChar(ch))) : (((!isDecimal) && (counter === 2)) ? _Utils_Tuple3(
								0,
								false,
								result + ('.' + $elm$core$String$fromChar(ch))) : _Utils_Tuple3(
								counter + 1,
								isDecimal,
								_Utils_ap(
									result,
									$elm$core$String$fromChar(ch))));
						}),
					_Utils_Tuple3(0, true, ''),
					moneyStr))));
};
var $author$project$App$Page$Finances$PageFinanceSales$monthToName = function (_int) {
	switch (_int) {
		case 1:
			return 'JAN';
		case 2:
			return 'FEV';
		case 3:
			return 'MAR';
		case 4:
			return 'ABR';
		case 5:
			return 'MAI';
		case 6:
			return 'JUN';
		case 7:
			return 'JUL';
		case 8:
			return 'AGO';
		case 9:
			return 'SET';
		case 10:
			return 'OUT';
		case 11:
			return 'NOV';
		case 12:
			return 'DEZ';
		default:
			return 'ERR';
	}
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $author$project$App$Page$Finances$PageFinanceSales$paymentStatusToColorClass = function (status) {
	_v0$3:
	while (true) {
		if (!status.$) {
			switch (status.a) {
				case 'Fatura em aberto':
					return 'blue';
				case 'Fatura vencida':
					return 'red';
				case 'Fatura recebida':
					return 'green';
				default:
					break _v0$3;
			}
		} else {
			break _v0$3;
		}
	}
	return 'black';
};
var $author$project$App$Page$Finances$PageFinanceSales$paymentStatusToInfoColorClass = function (status) {
	_v0$3:
	while (true) {
		if (!status.$) {
			switch (status.a) {
				case 'Fatura em aberto':
					return 'fatura-em-aberto';
				case 'Fatura vencida':
					return 'fatura-vencida';
				case 'Fatura recebida':
					return 'fatura-recebida';
				default:
					break _v0$3;
			}
		} else {
			break _v0$3;
		}
	}
	return '';
};
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $author$project$App$Page$Finances$PageFinanceSales$view = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('page-finance')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('finance')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$section,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('main-section')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$header,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('section-header')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$ul,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('months')
												]),
											_Utils_ap(
												_List_fromArray(
													[
														A2(
														$elm$html$Html$li,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('arrow-left'),
																$elm$html$Html$Events$onClick(
																A2($author$project$App$Page$Finances$PageFinanceSales$ChangeSelectedMonth, model.m - 1, 0))
															]),
														_List_Nil)
													]),
												_Utils_ap(
													A2(
														$elm$core$List$map,
														function (_v0) {
															var i = _v0.a;
															var m = _v0.b;
															return A2(
																$elm$html$Html$li,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class(
																		'month-cel' + (_Utils_eq(i + 1, model.m) ? ' active-cel' : '')),
																		$elm$html$Html$Events$onClick(
																		A2($author$project$App$Page$Finances$PageFinanceSales$ChangeSelectedMonth, i + 1, 2))
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('month-container')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$div,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class(
																						'bar ' + $author$project$App$Page$Finances$PageFinanceSales$paymentStatusToColorClass(m.aR))
																					]),
																				_List_Nil),
																				A2(
																				$elm$html$Html$p,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('month')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text(
																						$author$project$App$Page$Finances$PageFinanceSales$monthToName(m.hk))
																					])),
																				A2(
																				$elm$html$Html$p,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('year')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text(
																						$elm$core$String$fromInt(m.iF))
																					])),
																				A2(
																				$elm$html$Html$p,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('value')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text(
																						'R$' + $author$project$Mask$intDecimal(m.iq))
																					]))
																			]))
																	]));
														},
														A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, model.w)),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$li,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('complete-space')
																]),
															_List_Nil),
															A2(
															$elm$html$Html$li,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('arrow-right'),
																	$elm$html$Html$Events$onClick(
																	A2($author$project$App$Page$Finances$PageFinanceSales$ChangeSelectedMonth, model.m + 1, 1))
																]),
															_List_Nil)
														]))))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('section-main')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('accordion-mf-container')
												]),
											$elm$core$List$concat(
												A2(
													$elm$core$List$map,
													function (_v2) {
														var i = _v2.a;
														var m = _v2.b;
														return _Utils_ap(
															$elm$core$List$concat(
																A2(
																	$elm$core$List$map,
																	function (s) {
																		return _Utils_eq(m.bi, s.au) ? _List_fromArray(
																			[
																				A2(
																				$elm$html$Html$div,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('accordion-mf-tab'),
																						$elm$html$Html$Events$onClick(
																						$author$project$App$Page$Finances$PageFinanceSales$CloseSelectedSales(m.hk))
																					]),
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$ul,
																						_List_Nil,
																						_List_fromArray(
																							[
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text('Modelo')
																											])),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text(s.hl)
																											]))
																									])),
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text('')
																											])),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_Nil)
																									])),
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_Nil),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_Nil)
																									])),
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text('Total')
																											])),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text(
																												'R$' + $author$project$Mask$intDecimal(s.L + s.at))
																											]))
																									]))
																							]))
																					])),
																				A2(
																				$elm$html$Html$div,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('accordion-mf-content')
																					]),
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$table,
																						_List_fromArray(
																							[
																								$elm$html$Html$Attributes$class('data-table-faturamento')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$elm$html$Html$thead,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$tr,
																										_List_Nil,
																										_List_fromArray(
																											[
																												A2(
																												$elm$html$Html$th,
																												_List_Nil,
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Proponente')
																													])),
																												A2(
																												$elm$html$Html$th,
																												_List_Nil,
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Data')
																													])),
																												A2(
																												$elm$html$Html$th,
																												_List_Nil,
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Profissional')
																													])),
																												A2(
																												$elm$html$Html$th,
																												_List_Nil,
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Valor')
																													]))
																											]))
																									])),
																								A2(
																								$elm$html$Html$tbody,
																								_List_Nil,
																								A2(
																									$elm$core$List$map,
																									function (d) {
																										return A2(
																											$elm$html$Html$tr,
																											_List_Nil,
																											_List_fromArray(
																												[
																													A2(
																													$elm$html$Html$td,
																													_List_Nil,
																													_List_fromArray(
																														[
																															$elm$html$Html$text(d.am)
																														])),
																													A2(
																													$elm$html$Html$td,
																													_List_Nil,
																													_List_fromArray(
																														[
																															$elm$html$Html$text(d.gA)
																														])),
																													A2(
																													$elm$html$Html$td,
																													_List_Nil,
																													_List_fromArray(
																														[
																															$elm$html$Html$text(d.bu)
																														])),
																													A2(
																													$elm$html$Html$td,
																													_List_Nil,
																													_List_fromArray(
																														[
																															$elm$html$Html$text(
																															'R$' + $author$project$Mask$intDecimal(d.T))
																														]))
																												]));
																									},
																									s.gD))
																							]))
																					]))
																			]) : _List_fromArray(
																			[
																				A2(
																				$elm$html$Html$div,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('accordion-mf-tab'),
																						$elm$html$Html$Events$onClick(
																						A2($author$project$App$Page$Finances$PageFinanceSales$SelectSales, m.hk, s.au))
																					]),
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$ul,
																						_List_Nil,
																						_List_fromArray(
																							[
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text('Modelo de Contratação')
																											])),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text(s.hl)
																											]))
																									])),
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_Nil),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_Nil)
																									])),
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_Nil),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_Nil)
																									])),
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text('Total')
																											])),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text(
																												'R$' + $author$project$Mask$intDecimal(s.L + s.at))
																											]))
																									]))
																							]))
																					]))
																			]);
																	},
																	m.S)),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('accordion-mf-tab total-tab')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$ul,
																			_List_Nil,
																			_List_fromArray(
																				[
																					A2(
																					$elm$html$Html$li,
																					_List_Nil,
																					_List_fromArray(
																						[
																							A2(
																							$elm$html$Html$p,
																							_List_fromArray(
																								[
																									$elm$html$Html$Attributes$class('ac-value')
																								]),
																							_List_fromArray(
																								[
																									$elm$html$Html$text('TOTAL')
																								])),
																							A2(
																							$elm$html$Html$div,
																							_List_fromArray(
																								[
																									$elm$html$Html$Attributes$class('info-container')
																								]),
																							_Utils_eq(m.gL, $elm$core$Maybe$Nothing) ? _List_Nil : _List_fromArray(
																								[
																									A2(
																									$elm$html$Html$p,
																									_List_fromArray(
																										[
																											$elm$html$Html$Attributes$class('ac-vencimento')
																										]),
																									_List_fromArray(
																										[
																											$elm$html$Html$text(
																											'Vencimento ' + A2($elm$core$Maybe$withDefault, '', m.gL))
																										])),
																									A2(
																									$elm$html$Html$p,
																									_List_fromArray(
																										[
																											$elm$html$Html$Attributes$class('fechamento')
																										]),
																									_List_fromArray(
																										[
																											$elm$html$Html$text(
																											'Fechamento ' + A2($elm$core$Maybe$withDefault, '', m.b1))
																										]))
																								]))
																						])),
																					A2(
																					$elm$html$Html$li,
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class(
																							$author$project$App$Page$Finances$PageFinanceSales$paymentStatusToInfoColorClass(m.aR))
																						]),
																					_List_fromArray(
																						[
																							A2(
																							$elm$html$Html$p,
																							_List_fromArray(
																								[
																									$elm$html$Html$Attributes$class(
																									'ac-title info-' + $author$project$App$Page$Finances$PageFinanceSales$paymentStatusToInfoColorClass(m.aR))
																								]),
																							_List_fromArray(
																								[
																									$elm$html$Html$text(
																									A2($elm$core$Maybe$withDefault, '', m.aR))
																								])),
																							A2(
																							$elm$html$Html$p,
																							_List_fromArray(
																								[
																									$elm$html$Html$Attributes$class('ac-value')
																								]),
																							_List_fromArray(
																								[
																									$elm$html$Html$text(
																									'R$' + $author$project$Mask$intDecimal(m.iq))
																								]))
																						]))
																				]))
																		])),
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('buttons-container')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$button,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('btn-default btn-exportar-csv')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('EXPORTAR CSV')
																				])),
																			A2(
																			$elm$html$Html$button,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('btn-default btn-exportar-pdf')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('EXPORTAR PDF')
																				]))
																		]))
																]));
													},
													A2(
														$elm$core$List$filter,
														function (_v1) {
															var i = _v1.a;
															var m = _v1.b;
															return _Utils_eq(i + 1, model.m);
														},
														A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, model.w)))))
										]))
								]))
						]))
				]))
		]);
};
var $author$project$App$Page$Admin$PageFinanceSales$ChangeOrder = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Admin$PageFinanceSales$ChangeSelectedMonth = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$Page$Admin$PageFinanceSales$CloseSelectedSales = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Admin$PageFinanceSales$ExportCsv = {$: 6};
var $author$project$App$Page$Admin$PageFinanceSales$ExportPdf = {$: 5};
var $author$project$App$Page$Admin$PageFinanceSales$FilterMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Admin$PageFinanceSales$Negative = 0;
var $author$project$App$Page$Admin$PageFinanceSales$Neutral = 2;
var $author$project$App$Page$Admin$PageFinanceSales$OrderByCompanyAndTemplate = 2;
var $author$project$App$Page$Admin$PageFinanceSales$OrderByDoctorName = 0;
var $author$project$App$Page$Admin$PageFinanceSales$OrderByProponent = 3;
var $author$project$App$Page$Admin$PageFinanceSales$OrderByValue = 4;
var $author$project$App$Page$Admin$PageFinanceSales$Positive = 1;
var $author$project$App$Page$Admin$PageFinanceSales$SelectSales = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$App$Page$Admin$PageFinanceSales$ToggleTotal = {$: 8};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromMonth = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).gB;
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString = F2(
	function (digits, time) {
		return A3(
			$elm$core$String$padLeft,
			digits,
			'0',
			$elm$core$String$fromInt(time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime = function (time) {
	return A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		4,
		A2($elm$time$Time$toYear, $elm$time$Time$utc, time)) + ('-' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		$rtfeldman$elm_iso8601_date_strings$Iso8601$fromMonth(
			A2($elm$time$Time$toMonth, $elm$time$Time$utc, time))) + ('-' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toDay, $elm$time$Time$utc, time)) + ('T' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toHour, $elm$time$Time$utc, time)) + (':' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toMinute, $elm$time$Time$utc, time)) + (':' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toSecond, $elm$time$Time$utc, time)) + ('.' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		3,
		A2($elm$time$Time$toMillis, $elm$time$Time$utc, time)) + 'Z'))))))))))));
};
var $author$project$App$Tools$FormHelpers$dateFormat = function (posix) {
	var date = A3(
		$elm$core$String$slice,
		0,
		10,
		$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(posix));
	var m = A3($elm$core$String$slice, 5, 7, date);
	var y = A3($elm$core$String$slice, 0, 4, date);
	var d = A3($elm$core$String$slice, 8, 10, date);
	return d + ('-' + (m + ('-' + y)));
};
var $author$project$App$Page$Admin$PageFinanceSales$lastMonthYear = F2(
	function (month, year) {
		return (month === 1) ? _Utils_Tuple2(12, year - 1) : _Utils_Tuple2(month - 1, year);
	});
var $author$project$App$Page$Admin$PageFinanceSales$monthToName = function (_int) {
	switch (_int) {
		case 1:
			return 'JAN';
		case 2:
			return 'FEV';
		case 3:
			return 'MAR';
		case 4:
			return 'ABR';
		case 5:
			return 'MAI';
		case 6:
			return 'JUN';
		case 7:
			return 'JUL';
		case 8:
			return 'AGO';
		case 9:
			return 'SET';
		case 10:
			return 'OUT';
		case 11:
			return 'NOV';
		case 12:
			return 'DEZ';
		default:
			return 'ERR';
	}
};
var $author$project$App$Page$Admin$PageFinanceSales$nextMonthYear = F2(
	function (month, year) {
		return (month === 12) ? _Utils_Tuple2(1, year + 1) : _Utils_Tuple2(month + 1, year);
	});
var $author$project$App$Page$Admin$PageFinanceSales$paymentStatusToColorClass = function (status) {
	_v0$3:
	while (true) {
		if (!status.$) {
			switch (status.a) {
				case 'Fatura em aberto':
					return 'blue';
				case 'Fatura vencida':
					return 'red';
				case 'Fatura recebida':
					return 'green';
				default:
					break _v0$3;
			}
		} else {
			break _v0$3;
		}
	}
	return 'black';
};
var $author$project$App$Page$Admin$PageFinanceSales$paymentStatusToInfoColorClass = function (status) {
	_v0$3:
	while (true) {
		if (!status.$) {
			switch (status.a) {
				case 'Fatura em aberto':
					return 'fatura-em-aberto';
				case 'Fatura vencida':
					return 'fatura-vencida';
				case 'Fatura recebida':
					return 'fatura-recebida';
				default:
					break _v0$3;
			}
		} else {
			break _v0$3;
		}
	}
	return '';
};
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$App$Components$ReportFilter$FilterFieldApply = {$: 7};
var $author$project$App$Components$ReportFilter$FilterFieldClear = {$: 6};
var $author$project$App$Components$ReportFilter$FilterFieldCompany = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Components$ReportFilter$FilterFieldDoctor = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Components$ReportFilter$FilterFieldEndDate = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Components$ReportFilter$FilterFieldShow = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Components$ReportFilter$FilterFieldStartDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Components$ReportFilter$FilterFieldTemplate = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $author$project$App$Tools$FormHelpers$select = F4(
	function (attrs, sVal, msg, list) {
		return A2(
			$elm$html$Html$select,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Events$onInput(msg),
				attrs),
			A2(
				$elm$core$List$map,
				function (_v0) {
					var i = _v0.a;
					var val = _v0.b;
					return A2(
						$elm$html$Html$option,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$selected(
								_Utils_eq(sVal, i)),
								$elm$html$Html$Attributes$value(i)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(val)
							]));
				},
				list));
	});
var $author$project$App$Tools$FormHelpers$selectFromCollection = F5(
	function (attrs, selected, modelList, mDecode, msg) {
		var selectList = A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					_Utils_Tuple2($author$project$App$Data$Id$uuidNil, 'Selecione')
				]),
			A2($elm$core$List$map, mDecode, modelList));
		return A4($author$project$App$Tools$FormHelpers$select, attrs, selected, msg, selectList);
	});
var $author$project$App$Components$ReportFilter$view = function (filter) {
	var visibility = filter.bK ? 'visible' : 'hidden';
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('button'),
						$elm$html$Html$Events$onClick(
						$author$project$App$Components$ReportFilter$FilterFieldShow(!filter.bK))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('material-icons')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('search')
							])),
						_Utils_eq(filter.aX, $elm$core$Maybe$Nothing) ? $elm$html$Html$text('') : A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('badge')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('popover filter'),
						A2($elm$html$Html$Attributes$style, 'visibility', visibility)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Events$onSubmit($author$project$App$Components$ReportFilter$FilterFieldApply)
							]),
						_Utils_ap(
							_List_fromArray(
								[
									A2(
									$elm$html$Html$fieldset,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Filtrar por período'),
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Início'),
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Events$onInput($author$project$App$Components$ReportFilter$FilterFieldStartDate),
															$elm$html$Html$Attributes$value(
															A2($elm$core$Maybe$withDefault, '', filter.bN))
														]),
													_List_Nil)
												])),
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Fim'),
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Events$onInput($author$project$App$Components$ReportFilter$FilterFieldEndDate),
															$elm$html$Html$Attributes$value(
															A2($elm$core$Maybe$withDefault, '', filter.bw))
														]),
													_List_Nil)
												]))
										]))
								]),
							_Utils_ap(
								($elm$core$List$length(filter.bq) > 0) ? _List_fromArray(
									[
										A2(
										$elm$html$Html$fieldset,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Médico'),
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Events$onInput($author$project$App$Components$ReportFilter$FilterFieldDoctor),
																$elm$html$Html$Attributes$value(
																A2($elm$core$Maybe$withDefault, '', filter.bu))
															]),
														_List_Nil)
													]))
											]))
									]) : _List_Nil,
								_Utils_ap(
									_List_fromArray(
										[
											A2(
											$elm$html$Html$fieldset,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Modelo de Atendimento'),
													A2(
													$elm$html$Html$label,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$style, 'padding-bottom', '12px')
														]),
													_List_fromArray(
														[
															A5(
															$author$project$App$Tools$FormHelpers$selectFromCollection,
															_List_Nil,
															filter.fT,
															filter.eZ,
															function (t) {
																return _Utils_Tuple2(t.as, t.hl);
															},
															$author$project$App$Components$ReportFilter$FilterFieldTemplate)
														]))
												]))
										]),
									_Utils_ap(
										($elm$core$List$length(filter.bq) > 0) ? _List_fromArray(
											[
												A2(
												$elm$html$Html$fieldset,
												_List_fromArray(
													[
														A2($elm$html$Html$Attributes$style, 'padding-bottom', '12px')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Empresas'),
														A2(
														$elm$html$Html$label,
														_List_Nil,
														_List_fromArray(
															[
																A5(
																$author$project$App$Tools$FormHelpers$selectFromCollection,
																_List_Nil,
																filter.cB,
																filter.bq,
																function (t) {
																	return _Utils_Tuple2(t.as, t.iR);
																},
																$author$project$App$Components$ReportFilter$FilterFieldCompany)
															]))
													]))
											]) : _List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('secondary'),
																$elm$html$Html$Events$onClick($author$project$App$Components$ReportFilter$FilterFieldClear)
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Limpar')
															])),
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Events$onClick($author$project$App$Components$ReportFilter$FilterFieldApply)
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Aplicar')
															]))
													]))
											]))))))
					]))
			]));
};
var $author$project$App$Page$Admin$PageFinanceSales$view = function (model) {
	var isMasterAccount = (!A2(
		$elm$core$List$any,
		function (c) {
			return _Utils_eq(c.as, model.b.fz);
		},
		model.b.bq)) && ($elm$core$List$length(model.b.bq) > 0);
	var filterText = (_Utils_eq(model.b.bN, $elm$core$Maybe$Nothing) || _Utils_eq(model.b.bw, $elm$core$Maybe$Nothing)) ? 'Filtro' : (A2($elm$core$Maybe$withDefault, '', model.b.bN) + ('~' + (A2($elm$core$Maybe$withDefault, '', model.b.bw) + (((!_Utils_eq(model.b.bu, $elm$core$Maybe$Nothing)) ? (', Médico:' + A2($elm$core$Maybe$withDefault, '', model.b.bu)) : '') + ((!_Utils_eq(model.b.cJ, $elm$core$Maybe$Nothing)) ? (', Modelo:' + A2($elm$core$Maybe$withDefault, '', model.b.cJ)) : '')))));
	var _v0 = A2($author$project$App$Page$Admin$PageFinanceSales$nextMonthYear, model.m, model.o);
	var nextMonth = _v0.a;
	var updatedYear = _v0.b;
	var _v1 = A2($author$project$App$Page$Admin$PageFinanceSales$lastMonthYear, model.m, model.o);
	var lastMonth = _v1.a;
	var subtractedYear = _v1.b;
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('page-finance')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('finance')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$section,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('main-section')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$header,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('section-filter')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h1,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(filterText)
												])),
											A2(
											$elm$html$Html$map,
											$author$project$App$Page$Admin$PageFinanceSales$FilterMsg,
											$author$project$App$Components$ReportFilter$view(model.b))
										])),
									A2(
									$elm$html$Html$header,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('section-header'),
											((!model.m) && (!model.o)) ? A2($elm$html$Html$Attributes$style, 'display', 'none') : A2($elm$html$Html$Attributes$style, '', '')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$ul,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('months')
												]),
											_Utils_ap(
												_List_fromArray(
													[
														A2(
														$elm$html$Html$li,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('arrow-left'),
																$elm$html$Html$Events$onClick(
																A3($author$project$App$Page$Admin$PageFinanceSales$ChangeSelectedMonth, lastMonth, subtractedYear, 0))
															]),
														_List_Nil)
													]),
												_Utils_ap(
													A2(
														$elm$core$List$map,
														function (_v8) {
															var i = _v8.a;
															var m = _v8.b;
															return A2(
																$elm$html$Html$li,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class(
																		'month-cel' + ((_Utils_eq(m.hk, model.m) && _Utils_eq(m.iF, model.o)) ? ' active-cel' : '')),
																		$elm$html$Html$Events$onClick(
																		A3($author$project$App$Page$Admin$PageFinanceSales$ChangeSelectedMonth, m.hk, m.iF, 2))
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('month-container')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$div,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class(
																						'bar ' + $author$project$App$Page$Admin$PageFinanceSales$paymentStatusToColorClass(m.aR))
																					]),
																				_List_Nil),
																				A2(
																				$elm$html$Html$p,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('month')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text(
																						$author$project$App$Page$Admin$PageFinanceSales$monthToName(m.hk))
																					])),
																				A2(
																				$elm$html$Html$p,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('year')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text(
																						$elm$core$String$fromInt(m.iF))
																					]))
																			]))
																	]));
														},
														A2(
															$elm$core$List$indexedMap,
															$elm$core$Tuple$pair,
															A2(
																$elm$core$List$take,
																5,
																A3(
																	$elm$core$List$foldl,
																	F2(
																		function (m, a) {
																			if (_Utils_eq(m.hk, model.m) && _Utils_eq(m.iF, model.o)) {
																				return A2($elm$core$List$cons, m, a);
																			} else {
																				if (_Utils_eq(m.hk, lastMonth) && _Utils_eq(m.iF, subtractedYear)) {
																					return A2($elm$core$List$cons, m, a);
																				} else {
																					if (_Utils_eq(m.hk, nextMonth) && _Utils_eq(m.iF, updatedYear)) {
																						return A2($elm$core$List$cons, m, a);
																					} else {
																						var _v2 = A2($author$project$App$Page$Admin$PageFinanceSales$nextMonthYear, nextMonth, updatedYear);
																						var nnMonth = _v2.a;
																						var nnYear = _v2.b;
																						var _v3 = A2($author$project$App$Page$Admin$PageFinanceSales$lastMonthYear, lastMonth, subtractedYear);
																						var llMonth = _v3.a;
																						var llYear = _v3.b;
																						if (_Utils_eq(m.hk, llMonth) && _Utils_eq(m.iF, llYear)) {
																							return A2($elm$core$List$cons, m, a);
																						} else {
																							if (_Utils_eq(m.hk, nnMonth) && _Utils_eq(m.iF, nnYear)) {
																								return A2($elm$core$List$cons, m, a);
																							} else {
																								if ($elm$core$List$length(a) < 5) {
																									var _v4 = A2($author$project$App$Page$Admin$PageFinanceSales$nextMonthYear, nnMonth, nnYear);
																									var nnnMonth = _v4.a;
																									var nnnYear = _v4.b;
																									var _v5 = A2($author$project$App$Page$Admin$PageFinanceSales$nextMonthYear, nnnMonth, nnnYear);
																									var nnnnMonth = _v5.a;
																									var nnnnYear = _v5.b;
																									var _v6 = A2($author$project$App$Page$Admin$PageFinanceSales$lastMonthYear, llMonth, llYear);
																									var lllMonth = _v6.a;
																									var lllYear = _v6.b;
																									var _v7 = A2($author$project$App$Page$Admin$PageFinanceSales$lastMonthYear, lllMonth, lllYear);
																									var llllMonth = _v7.a;
																									var llllYear = _v7.b;
																									return (_Utils_eq(m.hk, lllMonth) && _Utils_eq(m.iF, lllYear)) ? A2($elm$core$List$cons, m, a) : ((_Utils_eq(m.hk, nnnMonth) && _Utils_eq(m.iF, nnnYear)) ? A2($elm$core$List$cons, m, a) : ((_Utils_eq(m.hk, llllMonth) && _Utils_eq(m.iF, llllYear)) ? A2($elm$core$List$cons, m, a) : ((_Utils_eq(m.hk, nnnnMonth) && _Utils_eq(m.iF, nnnnYear)) ? A2($elm$core$List$cons, m, a) : a)));
																								} else {
																									return a;
																								}
																							}
																						}
																					}
																				}
																			}
																		}),
																	_List_Nil,
																	model.w)))),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$li,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('complete-space')
																]),
															_List_Nil),
															A2(
															$elm$html$Html$li,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('arrow-right'),
																	$elm$html$Html$Events$onClick(
																	A3($author$project$App$Page$Admin$PageFinanceSales$ChangeSelectedMonth, nextMonth, updatedYear, 1))
																]),
															_List_Nil)
														]))))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('section-main')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('accordion-mf-container')
												]),
											$elm$core$List$concat(
												A2(
													$elm$core$List$map,
													function (_v10) {
														var i = _v10.a;
														var m = _v10.b;
														var taxSum = A3(
															$elm$core$List$foldl,
															F2(
																function (ct, a) {
																	return a + $elm$core$Basics$round((m.iq * ct.hj) / 100000);
																}),
															0,
															model.aO);
														return _Utils_ap(
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('buttons-container')
																		]),
																	_List_fromArray(
																		[
																			_Utils_eq(model.eb, $elm$core$Maybe$Nothing) ? A2(
																			$elm$html$Html$button,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('btn-default btn-exportar-csv'),
																					$elm$html$Html$Events$onClick($author$project$App$Page$Admin$PageFinanceSales$ExportCsv)
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('EXPORTAR CSV')
																				])) : A2(
																			$elm$html$Html$button,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('btn-error btn-exportar-csv'),
																					$elm$html$Html$Events$onClick($author$project$App$Page$Admin$PageFinanceSales$ExportCsv)
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('Erro, contate o suporte')
																				])),
																			_Utils_eq(model.eF, $elm$core$Maybe$Nothing) ? A2(
																			$elm$html$Html$button,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('btn-default btn-exportar-pdf'),
																					$elm$html$Html$Events$onClick($author$project$App$Page$Admin$PageFinanceSales$ExportPdf)
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('EXPORTAR PDF')
																				])) : A2(
																			$elm$html$Html$button,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('btn-error btn-exportar-pdf'),
																					$elm$html$Html$Events$onClick($author$project$App$Page$Admin$PageFinanceSales$ExportPdf)
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('Erro, contate o suporte')
																				]))
																		])),
																	A2(
																	$elm$html$Html$div,
																	_Utils_ap(
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('accordion-mf-tab total-tab'),
																				$elm$html$Html$Events$onClick($author$project$App$Page$Admin$PageFinanceSales$ToggleTotal)
																			]),
																		isMasterAccount ? _List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('medsyn')
																			]) : _List_Nil),
																	A2(
																		$elm$core$List$cons,
																		A2(
																			$elm$html$Html$ul,
																			_List_Nil,
																			_Utils_ap(
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$li,
																						_List_Nil,
																						_List_fromArray(
																							[
																								A2(
																								$elm$html$Html$p,
																								_List_fromArray(
																									[
																										$elm$html$Html$Attributes$class('ac-value')
																									]),
																								_List_fromArray(
																									[
																										$elm$html$Html$text('TOTAL LÍQUIDO')
																									])),
																								A2(
																								$elm$html$Html$div,
																								_List_fromArray(
																									[
																										$elm$html$Html$Attributes$class('info-container')
																									]),
																								_Utils_eq(m.gL, $elm$core$Maybe$Nothing) ? _List_Nil : _List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-vencimento')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text(
																												'Vencimento ' + A2($elm$core$Maybe$withDefault, '', m.gL))
																											])),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('fechamento')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text(
																												'Fechamento ' + A2($elm$core$Maybe$withDefault, '', m.b1))
																											]))
																									]))
																							]))
																					]),
																				_Utils_ap(
																					isMasterAccount ? _List_Nil : _List_Nil,
																					_List_fromArray(
																						[
																							A2(
																							$elm$html$Html$li,
																							_List_fromArray(
																								[
																									$elm$html$Html$Attributes$class(
																									$author$project$App$Page$Admin$PageFinanceSales$paymentStatusToInfoColorClass(m.aR))
																								]),
																							_List_fromArray(
																								[
																									A2(
																									$elm$html$Html$p,
																									_List_fromArray(
																										[
																											$elm$html$Html$Attributes$class(
																											'ac-title info-' + $author$project$App$Page$Admin$PageFinanceSales$paymentStatusToInfoColorClass(m.aR))
																										]),
																									_List_fromArray(
																										[
																											$elm$html$Html$text(
																											A2($elm$core$Maybe$withDefault, '', m.aR))
																										])),
																									A2(
																									$elm$html$Html$p,
																									_List_fromArray(
																										[
																											$elm$html$Html$Attributes$class('ac-value')
																										]),
																									_List_fromArray(
																										[
																											$elm$html$Html$text(
																											'R$' + $author$project$Mask$intDecimal((m.iq - taxSum) + m.ai))
																										]))
																								]))
																						])))),
																		model.dS ? _List_fromArray(
																			[
																				A2(
																				$elm$html$Html$div,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('accordion-mf-content')
																					]),
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$table,
																						_List_fromArray(
																							[
																								$elm$html$Html$Attributes$class('data-table-total-faturamento')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$elm$html$Html$thead,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$tr,
																										_List_Nil,
																										_List_fromArray(
																											[
																												A2(
																												$elm$html$Html$th,
																												_List_fromArray(
																													[
																														A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																													]),
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Nome')
																													])),
																												A2(
																												$elm$html$Html$th,
																												_List_Nil,
																												_List_fromArray(
																													[
																														$elm$html$Html$text('%')
																													])),
																												A2(
																												$elm$html$Html$th,
																												_List_Nil,
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Valor')
																													]))
																											]))
																									])),
																								A2(
																								$elm$html$Html$tbody,
																								_List_Nil,
																								A2(
																									$elm$core$List$cons,
																									A2(
																										$elm$html$Html$tr,
																										_List_Nil,
																										_List_fromArray(
																											[
																												A2(
																												$elm$html$Html$td,
																												_List_fromArray(
																													[
																														A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																													]),
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Bruto')
																													])),
																												A2(
																												$elm$html$Html$td,
																												_List_Nil,
																												_List_fromArray(
																													[
																														$elm$html$Html$text('')
																													])),
																												A2(
																												$elm$html$Html$td,
																												_List_fromArray(
																													[
																														A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																													]),
																												_List_fromArray(
																													[
																														$elm$html$Html$text(
																														'R$' + $author$project$Mask$intDecimal(m.iq))
																													]))
																											])),
																									_Utils_ap(
																										A2(
																											$elm$core$List$map,
																											function (tax) {
																												var valueDecimal = $elm$core$Basics$round((m.iq * tax.hj) / 100000);
																												var percentage = $author$project$Mask$intDecimal(
																													$elm$core$Basics$round(tax.hj / 10)) + '%';
																												var minValue = function () {
																													var _v11 = tax.hl;
																													switch (_v11) {
																														case 'IRRF':
																															return 66667;
																														case 'CSLL':
																															return 21506;
																														case 'PIS':
																															return 21506;
																														case 'COFINS':
																															return 21506;
																														default:
																															return 0;
																													}
																												}();
																												var value = (_Utils_cmp(m.iq, minValue) < 0) ? $author$project$Mask$intDecimal(0) : $author$project$Mask$intDecimal(valueDecimal);
																												var hasReducedTax = (m.ai > 0) ? ' (com dispensa)' : '';
																												return A2(
																													$elm$html$Html$tr,
																													_List_Nil,
																													_List_fromArray(
																														[
																															A2(
																															$elm$html$Html$td,
																															_List_fromArray(
																																[
																																	A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																																]),
																															_List_fromArray(
																																[
																																	$elm$html$Html$text(
																																	_Utils_ap(tax.hl, hasReducedTax))
																																])),
																															A2(
																															$elm$html$Html$td,
																															_List_fromArray(
																																[
																																	A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																																]),
																															_List_fromArray(
																																[
																																	$elm$html$Html$text(percentage)
																																])),
																															A2(
																															$elm$html$Html$td,
																															_List_fromArray(
																																[
																																	A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																																]),
																															_List_fromArray(
																																[
																																	$elm$html$Html$text('R$' + value)
																																]))
																														]));
																											},
																											model.aO),
																										_List_fromArray(
																											[
																												A2(
																												$elm$html$Html$tr,
																												_List_Nil,
																												_List_fromArray(
																													[
																														A2(
																														$elm$html$Html$td,
																														_List_fromArray(
																															[
																																A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																															]),
																														_List_fromArray(
																															[
																																$elm$html$Html$text('Líquido')
																															])),
																														A2(
																														$elm$html$Html$td,
																														_List_Nil,
																														_List_fromArray(
																															[
																																$elm$html$Html$text('')
																															])),
																														A2(
																														$elm$html$Html$td,
																														_List_fromArray(
																															[
																																A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																															]),
																														_List_fromArray(
																															[
																																$elm$html$Html$text(
																																'R$' + $author$project$Mask$intDecimal((m.iq - taxSum) + m.ai))
																															]))
																													]))
																											]))))
																							]))
																					]))
																			]) : _List_Nil))
																]),
															$elm$core$List$concat(
																A2(
																	$elm$core$List$map,
																	function (s) {
																		var sortedDetails = function () {
																			var _v12 = model.ct;
																			if (!_v12.b) {
																				switch (_v12.a) {
																					case 1:
																						var _v13 = _v12.a;
																						return A2(
																							$elm$core$List$sortBy,
																							A2(
																								$elm$core$Basics$composeR,
																								function ($) {
																									return $.gA;
																								},
																								$elm$time$Time$posixToMillis),
																							s.gD);
																					case 2:
																						var _v15 = _v12.a;
																						return A2(
																							$elm$core$List$sortBy,
																							function ($) {
																								return $.cJ;
																							},
																							s.gD);
																					case 3:
																						var _v17 = _v12.a;
																						return A2(
																							$elm$core$List$sortBy,
																							function ($) {
																								return $.am;
																							},
																							s.gD);
																					case 0:
																						var _v19 = _v12.a;
																						return A2(
																							$elm$core$List$sortBy,
																							function ($) {
																								return $.bu;
																							},
																							s.gD);
																					default:
																						var _v21 = _v12.a;
																						return A2(
																							$elm$core$List$sortBy,
																							function ($) {
																								return $.T;
																							},
																							s.gD);
																				}
																			} else {
																				switch (_v12.a) {
																					case 1:
																						var _v14 = _v12.a;
																						return $elm$core$List$reverse(
																							A2(
																								$elm$core$List$sortBy,
																								A2(
																									$elm$core$Basics$composeR,
																									function ($) {
																										return $.gA;
																									},
																									$elm$time$Time$posixToMillis),
																								s.gD));
																					case 2:
																						var _v16 = _v12.a;
																						return $elm$core$List$reverse(
																							A2(
																								$elm$core$List$sortBy,
																								function ($) {
																									return $.cJ;
																								},
																								s.gD));
																					case 3:
																						var _v18 = _v12.a;
																						return $elm$core$List$reverse(
																							A2(
																								$elm$core$List$sortBy,
																								function ($) {
																									return $.am;
																								},
																								s.gD));
																					case 0:
																						var _v20 = _v12.a;
																						return $elm$core$List$reverse(
																							A2(
																								$elm$core$List$sortBy,
																								function ($) {
																									return $.bu;
																								},
																								s.gD));
																					default:
																						var _v22 = _v12.a;
																						return $elm$core$List$reverse(
																							A2(
																								$elm$core$List$sortBy,
																								function ($) {
																									return $.T;
																								},
																								s.gD));
																				}
																			}
																		}();
																		return _Utils_eq(m.bi, s.au) ? _List_fromArray(
																			[
																				A2(
																				$elm$html$Html$div,
																				_Utils_ap(
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('accordion-mf-tab'),
																							$elm$html$Html$Events$onClick(
																							$author$project$App$Page$Admin$PageFinanceSales$CloseSelectedSales(m.hk))
																						]),
																					isMasterAccount ? _List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('medsyn')
																						]) : _List_Nil),
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$ul,
																						_List_Nil,
																						_Utils_ap(
																							_List_fromArray(
																								[
																									A2(
																									$elm$html$Html$li,
																									_List_Nil,
																									_List_fromArray(
																										[
																											A2(
																											$elm$html$Html$p,
																											_List_fromArray(
																												[
																													$elm$html$Html$Attributes$class('ac-title')
																												]),
																											_List_fromArray(
																												[
																													$elm$html$Html$text('Modelo de Atendimento')
																												])),
																											A2(
																											$elm$html$Html$p,
																											_List_fromArray(
																												[
																													$elm$html$Html$Attributes$class('ac-value')
																												]),
																											_List_fromArray(
																												[
																													$elm$html$Html$text(
																													$elm$core$String$fromInt(
																														$elm$core$List$length(s.gD)) + (' ' + s.hl))
																												]))
																										]))
																								]),
																							_Utils_ap(
																								function () {
																									if (isMasterAccount) {
																										var doctorCost = A3(
																											$elm$core$List$foldr,
																											F2(
																												function (d, a) {
																													return d.gI + a;
																												}),
																											0,
																											s.gD);
																										return _List_fromArray(
																											[
																												A2(
																												$elm$html$Html$li,
																												_List_Nil,
																												_List_fromArray(
																													[
																														A2(
																														$elm$html$Html$p,
																														_List_fromArray(
																															[
																																$elm$html$Html$Attributes$class('ac-title')
																															]),
																														_List_fromArray(
																															[
																																$elm$html$Html$text('Custo')
																															])),
																														A2(
																														$elm$html$Html$p,
																														_List_fromArray(
																															[
																																$elm$html$Html$Attributes$class('ac-value')
																															]),
																														_List_fromArray(
																															[
																																$elm$html$Html$text(
																																'R$' + $author$project$Mask$intDecimal(doctorCost))
																															]))
																													]))
																											]);
																									} else {
																										return _List_Nil;
																									}
																								}(),
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$li,
																										_List_Nil,
																										_List_fromArray(
																											[
																												A2(
																												$elm$html$Html$p,
																												_List_fromArray(
																													[
																														$elm$html$Html$Attributes$class('ac-title')
																													]),
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Total')
																													])),
																												A2(
																												$elm$html$Html$p,
																												_List_fromArray(
																													[
																														$elm$html$Html$Attributes$class('ac-value')
																													]),
																												_List_fromArray(
																													[
																														$elm$html$Html$text(
																														'R$' + $author$project$Mask$intDecimal(s.L + s.at))
																													]))
																											]))
																									]))))
																					])),
																				A2(
																				$elm$html$Html$div,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('accordion-mf-content')
																					]),
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$table,
																						_List_fromArray(
																							[
																								$elm$html$Html$Attributes$class('data-table-faturamento')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$elm$html$Html$thead,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$tr,
																										_List_Nil,
																										_Utils_ap(
																											_List_fromArray(
																												[
																													A2(
																													$elm$html$Html$th,
																													_List_fromArray(
																														[
																															$elm$html$Html$Events$onClick(
																															$author$project$App$Page$Admin$PageFinanceSales$ChangeOrder(3)),
																															A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
																														]),
																													_List_fromArray(
																														[
																															$elm$html$Html$text('Proponente')
																														])),
																													isMasterAccount ? A2(
																													$elm$html$Html$th,
																													_List_fromArray(
																														[
																															$elm$html$Html$Events$onClick(
																															$author$project$App$Page$Admin$PageFinanceSales$ChangeOrder(2)),
																															A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
																														]),
																													_List_fromArray(
																														[
																															$elm$html$Html$text('Empresa/Modelo')
																														])) : A2(
																													$elm$html$Html$th,
																													_List_fromArray(
																														[
																															$elm$html$Html$Events$onClick(
																															$author$project$App$Page$Admin$PageFinanceSales$ChangeOrder(1)),
																															A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
																														]),
																													_List_fromArray(
																														[
																															$elm$html$Html$text('Data')
																														]))
																												]),
																											_Utils_ap(
																												isMasterAccount ? _List_fromArray(
																													[
																														A2(
																														$elm$html$Html$th,
																														_List_fromArray(
																															[
																																$elm$html$Html$Events$onClick(
																																$author$project$App$Page$Admin$PageFinanceSales$ChangeOrder(0)),
																																A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
																															]),
																														_List_fromArray(
																															[
																																$elm$html$Html$text('Profissional')
																															]))
																													]) : _List_Nil,
																												_List_fromArray(
																													[
																														A2(
																														$elm$html$Html$th,
																														_List_fromArray(
																															[
																																$elm$html$Html$Events$onClick(
																																$author$project$App$Page$Admin$PageFinanceSales$ChangeOrder(4)),
																																A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
																															]),
																														_List_fromArray(
																															[
																																$elm$html$Html$text('Valor')
																															]))
																													]))))
																									])),
																								A2(
																								$elm$html$Html$tbody,
																								_List_Nil,
																								A2(
																									$elm$core$List$map,
																									function (d) {
																										return A2(
																											$elm$html$Html$tr,
																											_List_Nil,
																											_Utils_ap(
																												_List_fromArray(
																													[
																														A2(
																														$elm$html$Html$td,
																														_List_Nil,
																														_List_fromArray(
																															[
																																$elm$html$Html$text(d.am)
																															])),
																														isMasterAccount ? A2(
																														$elm$html$Html$td,
																														_List_Nil,
																														_List_fromArray(
																															[
																																$elm$html$Html$text(
																																A2(
																																	$elm$core$Maybe$withDefault,
																																	'',
																																	$elm$core$List$head(
																																		A2(
																																			$elm$core$List$map,
																																			function (c) {
																																				return c.iR;
																																			},
																																			A2(
																																				$elm$core$List$filter,
																																				function (c) {
																																					return _Utils_eq(c.as, d.gx);
																																				},
																																				model.b.bq)))) + ('/ ' + d.cJ))
																															])) : A2(
																														$elm$html$Html$td,
																														_List_fromArray(
																															[
																																A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																															]),
																														_List_fromArray(
																															[
																																$elm$html$Html$text(
																																$author$project$App$Tools$FormHelpers$dateFormat(d.gA))
																															]))
																													]),
																												_Utils_ap(
																													isMasterAccount ? _List_fromArray(
																														[
																															A2(
																															$elm$html$Html$td,
																															_List_Nil,
																															_List_fromArray(
																																[
																																	$elm$html$Html$text(d.bu)
																																]))
																														]) : _List_Nil,
																													_List_fromArray(
																														[
																															A2(
																															$elm$html$Html$td,
																															_List_fromArray(
																																[
																																	A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																																]),
																															_List_fromArray(
																																[
																																	$elm$html$Html$text(
																																	isMasterAccount ? ('R$' + ($author$project$Mask$intDecimal(d.gI) + ('/ ' + ('R$' + $author$project$Mask$intDecimal(d.T))))) : ('R$' + $author$project$Mask$intDecimal(d.T)))
																																]))
																														]))));
																									},
																									sortedDetails))
																							]))
																					]))
																			]) : _List_fromArray(
																			[
																				A2(
																				$elm$html$Html$div,
																				_Utils_ap(
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('accordion-mf-tab'),
																							$elm$html$Html$Events$onClick(
																							A2($author$project$App$Page$Admin$PageFinanceSales$SelectSales, m.hk, s.au))
																						]),
																					isMasterAccount ? _List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('medsyn')
																						]) : _List_Nil),
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$ul,
																						_List_Nil,
																						_Utils_ap(
																							_List_fromArray(
																								[
																									A2(
																									$elm$html$Html$li,
																									_List_Nil,
																									_List_fromArray(
																										[
																											A2(
																											$elm$html$Html$p,
																											_List_fromArray(
																												[
																													$elm$html$Html$Attributes$class('ac-title')
																												]),
																											_List_fromArray(
																												[
																													$elm$html$Html$text('Modelo de Atendimento')
																												])),
																											A2(
																											$elm$html$Html$p,
																											_List_fromArray(
																												[
																													$elm$html$Html$Attributes$class('ac-value')
																												]),
																											_List_fromArray(
																												[
																													$elm$html$Html$text(
																													$elm$core$String$fromInt(
																														$elm$core$List$length(s.gD)) + (' ' + s.hl))
																												]))
																										]))
																								]),
																							_Utils_ap(
																								function () {
																									if (isMasterAccount) {
																										var doctorCost = A3(
																											$elm$core$List$foldr,
																											F2(
																												function (d, a) {
																													return d.gI + a;
																												}),
																											0,
																											s.gD);
																										return _List_fromArray(
																											[
																												A2(
																												$elm$html$Html$li,
																												_List_Nil,
																												_List_fromArray(
																													[
																														A2(
																														$elm$html$Html$p,
																														_List_fromArray(
																															[
																																$elm$html$Html$Attributes$class('ac-title')
																															]),
																														_List_fromArray(
																															[
																																$elm$html$Html$text('Custo')
																															])),
																														A2(
																														$elm$html$Html$p,
																														_List_fromArray(
																															[
																																$elm$html$Html$Attributes$class('ac-value')
																															]),
																														_List_fromArray(
																															[
																																$elm$html$Html$text(
																																'R$' + $author$project$Mask$intDecimal(doctorCost))
																															]))
																													]))
																											]);
																									} else {
																										return _List_Nil;
																									}
																								}(),
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$li,
																										_List_Nil,
																										_List_fromArray(
																											[
																												A2(
																												$elm$html$Html$p,
																												_List_fromArray(
																													[
																														$elm$html$Html$Attributes$class('ac-title')
																													]),
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Total')
																													])),
																												A2(
																												$elm$html$Html$p,
																												_List_fromArray(
																													[
																														$elm$html$Html$Attributes$class('ac-value')
																													]),
																												_List_fromArray(
																													[
																														$elm$html$Html$text(
																														'R$' + $author$project$Mask$intDecimal(s.L + s.at))
																													]))
																											]))
																									]))))
																					]))
																			]);
																	},
																	m.S)));
													},
													A2(
														$elm$core$List$filter,
														function (_v9) {
															var i = _v9.a;
															var m = _v9.b;
															return _Utils_eq(m.hk, model.m) && _Utils_eq(m.iF, model.o);
														},
														A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, model.w)))))
										]))
								]))
						]))
				]))
		]);
};
var $author$project$App$Page$Admin$PageListDoctors$viewErrors = F2(
	function (st, model) {
		return $elm$html$Html$text('');
	});
var $author$project$App$Page$Admin$PageListDoctors$Persist = {$: 3};
var $author$project$App$Page$Admin$PageListDoctors$Remove = {$: 4};
var $author$project$App$Page$Admin$PageListDoctors$InputDoctorDocument = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Admin$PageListDoctors$UpdateLicenseState = function (a) {
	return {$: 8, a: a};
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $author$project$App$Tools$FormHelpers$selectStates = F3(
	function (attributes, sValue, msg) {
		return A4(
			$author$project$App$Tools$FormHelpers$select,
			attributes,
			sValue,
			msg,
			_List_fromArray(
				[
					_Utils_Tuple2('', 'Selecione Estado'),
					_Utils_Tuple2('AC', 'Acre'),
					_Utils_Tuple2('AL', 'Alagoas'),
					_Utils_Tuple2('AP', 'Amapá'),
					_Utils_Tuple2('AM', 'Amazonas'),
					_Utils_Tuple2('BA', 'Bahia'),
					_Utils_Tuple2('CE', 'Ceará'),
					_Utils_Tuple2('DF', 'Distrito Federal'),
					_Utils_Tuple2('ES', 'Espírito Santo'),
					_Utils_Tuple2('GO', 'Goiás'),
					_Utils_Tuple2('MA', 'Maranhão'),
					_Utils_Tuple2('MT', 'Mato Grosso'),
					_Utils_Tuple2('MS', 'Mato Grosso do Sul'),
					_Utils_Tuple2('MG', 'Minas Gerais'),
					_Utils_Tuple2('PA', 'Pará'),
					_Utils_Tuple2('PB', 'Paraíba'),
					_Utils_Tuple2('PR', 'Paraná'),
					_Utils_Tuple2('PE', 'Pernambuco'),
					_Utils_Tuple2('PI', 'Piauí'),
					_Utils_Tuple2('RJ', 'Rio de Janeiro'),
					_Utils_Tuple2('RN', 'Rio Grande do Norte'),
					_Utils_Tuple2('RS', 'Rio Grande do Sul'),
					_Utils_Tuple2('RO', 'Rondônia'),
					_Utils_Tuple2('RR', 'Roraima'),
					_Utils_Tuple2('SC', 'Santa Catarina'),
					_Utils_Tuple2('SP', 'São Paulo'),
					_Utils_Tuple2('SE', 'Sergipe'),
					_Utils_Tuple2('TO', 'Tocantins')
				]));
	});
var $author$project$App$Page$Admin$PageListDoctors$viewEditTemplateContent = F2(
	function (model, api) {
		var isInvalid = false;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('CRM'),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$placeholder('Digite o CRM do Profissional'),
										$elm$html$Html$Attributes$type_('text'),
										$elm$html$Html$Attributes$disabled(
										$author$project$App$Data$Id$uuidIsValid(model.bu.as)),
										$elm$html$Html$Attributes$value(model.bu.dm),
										$elm$html$Html$Events$onInput($author$project$App$Page$Admin$PageListDoctors$InputDoctorDocument),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('error-message', isInvalid)
											]))
									]),
								_List_Nil),
								$elm$html$Html$text('')
							]))
					])),
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Estado'),
						A3(
						$author$project$App$Tools$FormHelpers$selectStates,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('error-message', isInvalid)
									]))
							]),
						model.bu.dn,
						$author$project$App$Page$Admin$PageListDoctors$UpdateLicenseState)
					]))
			]);
	});
var $author$project$App$Page$Admin$PageListDoctors$viewEditDoctorDocument = F2(
	function (model, api) {
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$legend,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Dados do(a) Profissional')
								]))
						]))
				]),
			A2($author$project$App$Page$Admin$PageListDoctors$viewEditTemplateContent, model, api));
	});
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $author$project$App$Page$Admin$PageListDoctors$viewEditPersonInformation = function (m) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$label,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Nome'),
					A2(
					$elm$html$Html$h4,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(m.dw.hl + (' ' + m.dw.ii))
						]))
				]))
		]);
};
var $author$project$App$Data$DoctorTemplate$FieldTemplateId = $elm$core$Basics$identity;
var $author$project$App$Page$Admin$PageListDoctors$InputStmtField = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Admin$PageListDoctors$viewTemplateContent = function (model) {
	var templates = model.eZ;
	var stmtTemplate = A2(
		$elm$core$Maybe$withDefault,
		$author$project$App$Data$StatementTemplate$emptyModel,
		$elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (t) {
					return _Utils_eq(t.as, model.aB.eY);
				},
				model.eZ)));
	var showModelOfInterviewText = A2($elm$core$List$member, 'interview-model:visible', stmtTemplate.eM);
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$legend,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Cadastro')
						]))
				])),
			A2(
			$elm$html$Html$label,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Modelo de Atendimento'),
					A5(
					$author$project$App$Tools$FormHelpers$selectFromCollection,
					_List_Nil,
					model.aB.eY,
					templates,
					function (t) {
						return _Utils_Tuple2(t.as, t.hl);
					},
					A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $author$project$App$Page$Admin$PageListDoctors$InputStmtField))
				]))
		]);
};
var $author$project$App$Page$Admin$PageListDoctors$viewForm = F2(
	function (st, model) {
		var templateEditContent = function (m) {
			return $author$project$App$Data$Id$uuidIsValid(model.dw.as) ? $author$project$App$Page$Admin$PageListDoctors$viewEditPersonInformation(model) : _List_fromArray(
				[
					$elm$html$Html$text('')
				]);
		};
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_Nil,
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$fieldset,
							_List_Nil,
							$author$project$App$Page$Admin$PageListDoctors$viewTemplateContent(model))
						]),
					_Utils_ap(
						A2($author$project$App$Page$Admin$PageListDoctors$viewEditDoctorDocument, model, st._),
						templateEditContent(model))))
			]);
	});
var $author$project$App$Page$Admin$PageListDoctors$viewFormSection = F3(
	function (st, model, _v0) {
		var headerTitle = _v0.gZ;
		var actionText = _v0.f9;
		var removeText = _v0.hJ;
		return model.ep ? A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cad-proposta')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$header,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(headerTitle)
								])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('action-buttons'),
											A2($elm$html$Html$Attributes$style, 'margin-right', '8px')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('cancel-button'),
													$elm$html$Html$Attributes$type_('button'),
													$elm$html$Html$Attributes$disabled(model.cH === 1),
													$elm$html$Html$Events$onClick($author$project$App$Page$Admin$PageListDoctors$Remove)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(removeText)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('action-buttons'),
											A2($elm$html$Html$Attributes$style, 'margin-right', '8px')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$type_('button'),
													$elm$html$Html$Attributes$disabled(model.cH === 1),
													$elm$html$Html$Events$onClick($author$project$App$Page$Admin$PageListDoctors$Persist)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(actionText)
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('authentication')
						]),
					A2($author$project$App$Page$Admin$PageListDoctors$viewForm, st, model))
				])) : A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cad-proposta')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$header,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Adicionar médico no modelo de atendimento')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('authentication')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Carregando...')
						]))
				]));
	});
var $author$project$App$Page$Admin$PageListDoctors$view = F2(
	function (st, m) {
		var _v0 = _Utils_Tuple3('Adicionar médico(a) nos modelos de atendimentos', 'Adicionar', 'Remover');
		var headerTitle = _v0.a;
		var actionText = _v0.b;
		var removeText = _v0.c;
		return _List_fromArray(
			[
				A2($author$project$App$Page$Admin$PageListDoctors$viewErrors, st, m),
				A3(
				$author$project$App$Page$Admin$PageListDoctors$viewFormSection,
				st,
				m,
				{f9: actionText, gZ: headerTitle, hJ: removeText})
			]);
	});
var $author$project$App$Page$Admin$PageListFinished$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Components$Filter$FilterFieldClear = {$: 7};
var $author$project$App$Components$Filter$FilterFieldEndDate = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Components$Filter$FilterFieldShow = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Components$Filter$FilterFieldStartDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Components$Filter$FilterFieldText = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Components$Filter$view = function (filter) {
	var visibility = filter.bK ? 'visible' : 'hidden';
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('button'),
						$elm$html$Html$Events$onClick(
						$author$project$App$Components$Filter$FilterFieldShow(!filter.bK))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('material-icons')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('search')
							])),
						_Utils_eq(filter.aX, $elm$core$Maybe$Nothing) ? $elm$html$Html$text('') : A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('badge')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('popover'),
						A2($elm$html$Html$Attributes$style, 'visibility', visibility)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Events$onSubmit($author$project$App$Components$Filter$FilterFieldApply)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$fieldset,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Pesquisar'),
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Events$onInput($author$project$App$Components$Filter$FilterFieldText),
														$elm$html$Html$Attributes$value(
														A2($elm$core$Maybe$withDefault, '', filter.bP))
													]),
												_List_Nil)
											]))
									])),
								A2(
								$elm$html$Html$fieldset,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Filtrar por período'),
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Início'),
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Events$onInput($author$project$App$Components$Filter$FilterFieldStartDate),
														$elm$html$Html$Attributes$value(
														A2($elm$core$Maybe$withDefault, '', filter.bN))
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Fim'),
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Events$onInput($author$project$App$Components$Filter$FilterFieldEndDate),
														$elm$html$Html$Attributes$value(
														A2($elm$core$Maybe$withDefault, '', filter.bw))
													]),
												_List_Nil)
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('secondary'),
										$elm$html$Html$Events$onClick($author$project$App$Components$Filter$FilterFieldClear)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Limpar')
									])),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$App$Components$Filter$FilterFieldApply)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Aplicar')
									]))
							]))
					]))
			]));
};
var $elm$html$Html$article = _VirtualDom_node('article');
var $elm$html$Html$Attributes$colspan = function (n) {
	return A2(
		_VirtualDom_attribute,
		'colspan',
		$elm$core$String$fromInt(n));
};
var $author$project$App$Data$Person$fullPersonName = function (p) {
	return ($elm$core$String$length(p.B) > 0) ? p.B : (p.hl + (' ' + p.ii));
};
var $author$project$App$Legacy$Doctor$ResultLayout$Bradesco$viewCPTPersonICDs = function (stmt) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$App$Data$Person$fullPersonName(stmt.dw))
						]))
				]))
		]);
};
var $author$project$App$Legacy$Doctor$ResultLayout$Bradesco$viewCPT = F3(
	function (mDepIdx, fullStmt, now) {
		var statement = function () {
			if (!mDepIdx.$) {
				return fullStmt.g;
			} else {
				var idx = mDepIdx.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, fullStmt.fh));
			}
		}();
		var fullName = $author$project$App$Data$Person$fullPersonName(statement.dw);
		var date = 'Curitiba';
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('doctor-result-layout'),
					$elm$html$Html$Attributes$class('bradesco-cpt')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src('/assets/images/bradesco-logo.png'),
							A2($elm$html$Html$Attributes$style, 'width', '200px'),
							A2($elm$html$Html$Attributes$style, 'border-radius', '0%')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'text-align', 'center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Carta de Oferecimento de Cobertura Parcial Temporária (CPT)')
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '2px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Número da Proposta:')
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '2px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Estipulante: EXTRAMED ADMINISTRAÇÃO E SERVIÇOS MÉDICOS LTDA')
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '2px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sub-Estipulante:')
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '2px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Apólice nº:')
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '2px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Proponente Titular: ' + fullName)
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Prezado(a) Senhor(a),')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Recebemos a documentação para análise da inclusão de titular e/ou dependente(s)' + (' e identificamos a necessidade de inclusão de Cobertura Parcial Temporária (CPT),' + (' após análise da Declaração Pessoal de Saúde apresentada ou através do Exame Médico' + ' para Avaliação de Risco realizado.')))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('À inclusão da Cobertura Parcial Temporária acrescentam-se seus correspondentes' + (' Procedimentos de Alta Complexidade (PAC), para o(s) integrante(s) abaixo relacionado(s),' + ' conforme prevista na Lei nº 9.656 de 1998, e sua regulamentação'))
						])),
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'width', '100%'),
							A2($elm$html$Html$Attributes$attribute, 'border', '1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$colspan(2),
											A2($elm$html$Html$Attributes$style, 'text-align', 'center')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('NOME DO PROPONENTE A SEGURADO TITULAR/DEPENDENTE(S)')
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							$author$project$App$Legacy$Doctor$ResultLayout$Bradesco$viewCPTPersonICDs(statement))
						])),
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'width', '100%'),
							A2($elm$html$Html$Attributes$attribute, 'border', '1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$colspan(2),
											A2($elm$html$Html$Attributes$style, 'text-align', 'center')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('NOME DO PROPONENTE A SEGURADO TITULAR/DEPENDENTE(S)')
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							$author$project$App$Legacy$Doctor$ResultLayout$Bradesco$viewCPTPersonICDs(statement))
						])),
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'text-align', 'center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('PROCEDIMENTOS DE ALTA COMPLEXIDADE (PAC)')
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '2px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Código do PAC(*) Nome dos Procedimentos(*)')
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '2px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('(*) em anexo')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('A partir da inclusão da(s) CPT(s), na eventualidade de internação relacionada' + (' especificamente à(s) patologia(s) em que seja necessário a realização de evento cirúrgico,' + (' ou que exija o uso de leito de alta tecnologia ou, ainda, que requeira a realização de Procedimentos' + (' de Alta Complexidade, dentre os listados no anexo, a cobertura será concedida somente após' + (' 24 (vinte e quatro) meses, contados do inicio de vigência da(s) respectiva(s) inclusão(ões) na apólice,' + ' conforme consta nas Condições Gerais do seguro e na Carta de Orientação ao Beneficiário.')))))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Ressaltamos que o oferecimento da referida CPT segue as determinações da Resolução' + (' Normativa RN nº 162, editada pela Agência Nacional de Saúde Suplementar (ANS) em 17/10/2007,' + ' especificamente em seu art.6º.'))
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'text-align', 'center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('_________________________________________________________')
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'text-align', 'center'),
							A2($elm$html$Html$Attributes$style, 'margin', '2px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Declaro estar ciente do oferecimento da Cobertura Parcial Temporária (CPT)')
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'text-align', 'center'),
							A2($elm$html$Html$Attributes$style, 'margin', '2px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Assinatura do proponente/segurado titular')
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'text-align', 'center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(date)
						]))
				]));
	});
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$strong = _VirtualDom_node('strong');
var $author$project$App$Data$ICD$listFromDict = function (dict) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (icd, result) {
				return ((icd.dE !== 1) && A2(
					$elm$core$List$any,
					function (i) {
						return _Utils_eq(i.a0, icd.a0);
					},
					result)) ? result : ((icd.dE === 1) ? A2(
					$elm$core$List$cons,
					icd,
					A2(
						$elm$core$List$filter,
						function (i) {
							return !_Utils_eq(i.a0, icd.a0);
						},
						result)) : A2($elm$core$List$cons, icd, result));
			}),
		_List_Nil,
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, result) {
					var innerDict = _v0.b;
					return A2(
						$elm$core$List$append,
						result,
						A2(
							$elm$core$List$map,
							function (_v1) {
								var icd = _v1.b;
								return icd;
							},
							$elm$core$Dict$toList(innerDict)));
				}),
			_List_Nil,
			$elm$core$Dict$toList(dict)));
};
var $author$project$App$Legacy$Doctor$ResultLayout$CentauroOn$viewCPTPersonICDs = function (stmt) {
	return A2(
		$elm$core$List$map,
		function (icd) {
			return A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Person$fullPersonName(stmt.dw))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(icd.a0)
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(icd.am)
							]))
					]));
		},
		$author$project$App$Data$ICD$listFromDict(stmt.fx));
};
var $author$project$App$Legacy$Doctor$ResultLayout$CentauroOn$viewCPT = F3(
	function (mDepIdx, fullStmt, now) {
		var statement = function () {
			if (!mDepIdx.$) {
				return fullStmt.g;
			} else {
				var idx = mDepIdx.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, fullStmt.fh));
			}
		}();
		var fullName = $author$project$App$Data$Person$fullPersonName(statement.dw);
		var date = 'Curitiba';
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('doctor-result-layout'),
					$elm$html$Html$Attributes$class('CentauroOn-cpt')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src('/assets/images/extramed_logo_ans.png'),
							A2($elm$html$Html$Attributes$style, 'width', '200px')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'text-align', 'center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(date)
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('A (o)')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Sr (a) ' + fullName)
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('CPF: ' + statement.dw.i1)
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Ref: ')
								])),
							A2(
							$elm$html$Html$strong,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'text-decoration', 'underline')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Cobertura Parcial Temporária')
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Prezado (a) Senhor (a),')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Conforme previsto na sua solicitação de adesão e respeitando a determinação ' + 'da Resolução Normativa da ANS Nº 162 de outubro de 2007, ')
								])),
							A2(
							$elm$html$Html$strong,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('informamos que a operadora, optou pela implantação de Cobertura Parcial Temporária ' + 'de 24 meses para os CID(s) descrito(s) abaixo;')
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('A Cobertura Parcial Temporária (CPT) é aquela que admite, por um período ininterrupto de ' + ('até 24 meses, a partir da data da contratação ou adesão ao plano privado de assistência à ' + ('saúde, a suspensão da cobertura de Procedimentos de Alta Complexidade (PAC), leitos de alta ' + ('tecnologia (UTI/CTI) e procedimentos cirúrgicos, desde que relacionados exclusivamente às ' + 'doenças ou lesões preexistentes declaradas pelo beneficiário ou seu representante legal. '))))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Os procedimentos de alta complexidade encontram-se especificados no Rol de Procedimentos e Eventos' + 'em Saúde da ANS, disponível no site '),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('https://www.ans.gov.br')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('www.ans.gov.br')
								])),
							$elm$html$Html$text('.')
						])),
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'width', '100%'),
							A2($elm$html$Html$Attributes$attribute, 'border', '1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Nome do Cliente')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('CID')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Descrição da Patologia')
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							$author$project$App$Legacy$Doctor$ResultLayout$CentauroOn$viewCPTPersonICDs(statement))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Data: ____/____/____  Ciente e de acordo, ___________________________________')
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'page-break-inside', 'avoid')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Aguardaremos sua manifestação formal de aceitação até o prazo de 02 dias corridos, a ' + ('contar do recebimento desta. Findo este prazo sem que até tenhamos recebido o retorno ' + ('sobre a aceitação da Cobertura Parcial Temporária a solicitação de adesão ficará ' + ('automaticamente sem efeito, e permanecendo o interesse na adesão, deverá ser encaminhada ' + 'nova solicitação de adesão.'))))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Agradecemos a oportunidade do contato e colocamo-nos ao inteiro dispor para maiores esclarecimentos,')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Atenciosamente,')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('EXTRAMED ADMINISTRADORA DE BENEFÍCIOS')
						]))
				]));
	});
var $elm$html$Html$br = _VirtualDom_node('br');
var $elm$html$Html$ol = _VirtualDom_node('ol');
var $author$project$App$Legacy$Doctor$ResultLayout$Medsyn$viewPersonICDForCPT = function (icd) {
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(icd.am)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('24 meses')
					]))
			]));
};
var $author$project$App$Legacy$Doctor$ResultLayout$Medsyn$viewPersonICDsForCPT = function (stmt) {
	return A2(
		$elm$core$List$map,
		function (icd) {
			return $author$project$App$Legacy$Doctor$ResultLayout$Medsyn$viewPersonICDForCPT(icd);
		},
		$author$project$App$Data$ICD$listFromDict(stmt.fx));
};
var $author$project$App$Legacy$Doctor$ResultLayout$Medsyn$viewCPT = F3(
	function (mDepIdx, fullStmt, now) {
		var stmt = function () {
			if (!mDepIdx.$) {
				return fullStmt.g;
			} else {
				var idx = mDepIdx.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, fullStmt.fh));
			}
		}();
		var fullName = $author$project$App$Data$Person$fullPersonName(stmt.dw);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('doctor-result-layout')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('TERMO DE OPÇÃO POR COBERTURA PARCIAL TEMPORÁRIA – CPT')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Eu, ' + (fullName + (' opto nesta oportunidade por cumprir Cobertura Parcial Temporária ' + '(CPT) para as doenças ou lesões preexistentes abaixo discriminadas:')))
						])),
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'width', '100%'),
							A2($elm$html$Html$Attributes$attribute, 'border', '1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Descrição')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Prazo de suspensão')
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							$author$project$App$Legacy$Doctor$ResultLayout$Medsyn$viewPersonICDsForCPT(stmt))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Declaro estar plenamente ciente de que:')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$ol,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$li,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('A Cobertura Parcial Temporária (CPT) é aquela que admite, por um ' + ('período ininterrupto de até 24 meses, a partir da data da contratação ou ' + ('adesão ao plano privado de assistência à saúde, a suspensão da cobertura de ' + ('Procedimentos de Alta Complexidade (PAC), leitos de alta tecnologia (UTI/CTI) e ' + ('procedimentos cirúrgicos, desde que relacionados exclusivamente às doenças ou lesões ' + 'preexistentes declaradas pelo beneficiário ou seu representante legal;')))))
										])),
									A2(
									$elm$html$Html$li,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('As doenças ou lesões preexistentes supracitadas foram obtidas pelas informações constantes ' + ('na Parte II – Informações sobre sua saúde e/ou de seus dependentes e/ou por meio da ' + 'perícia médica, se realizada; ')),
											A2($elm$html$Html$br, _List_Nil, _List_Nil),
											A2($elm$html$Html$br, _List_Nil, _List_Nil),
											$elm$html$Html$text('E')
										])),
									A2(
									$elm$html$Html$li,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Os procedimentos de alta complexidade (PAC) não terão cobertura pelo prazo respectivo, ' + 'em razão da constatação de doenças ou lesões pré-existentes*.')
										]))
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$table,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$td,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('_______________________________,_____/_____/______')
												])),
											A2(
											$elm$html$Html$td,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('________________________________')
												]))
										])),
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2($elm$html$Html$td, _List_Nil, _List_Nil),
											A2(
											$elm$html$Html$td,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Titular cliente ou responsável legal')
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('*Os procedimentos de alta complexidade são aqueles classificados como tal pela Agência ' + ('Nacional de Saúde Suplementar - ANS, no Rol de Procedimentos vigente na data da solicitação ' + ('do serviço. Tal lista pode ser acessada por V.Sa. no site da ANS: acessar www.ans.gov.br e ' + ('no ícone “Espaço do Consumidor” clicar em “o que o seu plano deve cobrir”, ' + ('“Confira mais informações sobre a cobertura mínima obrigatória em vigor” e ' + ('“Lista completa de procedimentos”. Se ao lado do procedimento selecionado constar a ' + 'sigla PAC significa que se trata de Procedimento de Alta Complexidade.'))))))
						]))
				]));
	});
var $author$project$App$Data$Person$formatDocument = function (doc) {
	var ns = $author$project$App$Data$Person$clearDocument(doc);
	var size = $elm$core$String$length(ns);
	return (size < 3) ? doc : (((size >= 3) && (size < 6)) ? ('' + (A3($elm$core$String$slice, 0, 3, doc) + ('.' + A3($elm$core$String$slice, 3, 6, doc)))) : (((size >= 6) && (size < 9)) ? ('' + (A3($elm$core$String$slice, 0, 3, doc) + ('.' + (A3($elm$core$String$slice, 3, 6, doc) + ('.' + A3($elm$core$String$slice, 6, 9, doc)))))) : ('' + (A3($elm$core$String$slice, 0, 3, doc) + ('.' + (A3($elm$core$String$slice, 3, 6, doc) + ('.' + (A3($elm$core$String$slice, 6, 9, doc) + ('-' + A3($elm$core$String$slice, 9, 11, doc))))))))));
};
var $author$project$App$Legacy$Doctor$ResultLayout$SB_Saude$viewCPTPersonICDs = function (stmt) {
	return A2(
		$elm$core$List$map,
		function (icd) {
			return A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Person$fullPersonName(stmt.dw))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(icd.a0)
							])),
						A2(
						$elm$html$Html$td,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'white-space', 'normal')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(icd.am)
							]))
					]));
		},
		$author$project$App$Data$ICD$listFromDict(stmt.fx));
};
var $author$project$App$Legacy$Doctor$ResultLayout$SB_Saude$viewCPT = F3(
	function (mDepIdx, fullStmt, now) {
		var statement = function () {
			if (!mDepIdx.$) {
				return fullStmt.g;
			} else {
				var idx = mDepIdx.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, fullStmt.fh));
			}
		}();
		var fullName = $author$project$App$Data$Person$fullPersonName(statement.dw);
		var date = 'Curitiba ' + $author$project$App$Tools$FormHelpers$dateFormat(now);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('doctor-result-layout'),
					$elm$html$Html$Attributes$class('sulamerica-cpt')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src('/assets/images/header.png'),
							A2($elm$html$Html$Attributes$style, 'width', '100%'),
							A2($elm$html$Html$Attributes$style, 'height', 'auto'),
							A2($elm$html$Html$Attributes$style, 'margin', 'auto')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'text-align', 'center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('TERMO DE ACEITAÇÃO DE COBERTURA'),
							A2($elm$html$Html$br, _List_Nil, _List_Nil),
							$elm$html$Html$text('PARCIAL TEMPORÁRIA')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Eu,' + fullName)
								])),
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									' portador(a) do CPF nº ' + ($author$project$App$Data$Person$formatDocument(statement.dw.i1) + ('Beneficiário(a) do plano SB SAÚDE, Estipulado' + ('pela Extramed Administração e Serviços Médicos LTDA, declaro, sob as penas da lei, que fui' + ('devidamente orientado sobre a legislação relacionada a declaração de doenças ou lesões' + ('pré-existentes no momento da adesão ao plano da Operadora, especialmente o disposto na' + ('RN nº 558/2022 da Agência Nacional de Saúde Suplementar (ANS), e que fui submetido' + ('entrevista qualificada com médico orientador, de forma que foram constatadas e são de meu' + ('conhecimento, ser possuidor(a) da(s) seguinte(s) condição(ões) e/ou problema(as) de saúde,' + 'disfunção(ões) e/ou doença(s), ou lesão(ões) pré-existente(s):')))))))))
								]))
						])),
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'width', '100%'),
							A2($elm$html$Html$Attributes$attribute, 'max-width', '700px'),
							A2($elm$html$Html$Attributes$attribute, 'border', '1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Nome do Cliente')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('CID')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Descrição da Patologia')
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							$author$project$App$Legacy$Doctor$ResultLayout$SB_Saude$viewCPTPersonICDs(statement))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Declaro também que optei pela Cobertura Parcial Temporária (CPT) que me foi oferecida pela' + (' Operadora, sem qualquer ônus adicional, estando ciente de que com essa opção, ficam' + (' suspensos por 24 (vinte e quatro) meses, a contar da data de assinatura da Declaração de' + (' Saúde, a cobertura de procedimentos cirúrgicos, uso de leito de alta tecnologia,' + (' procedimentos e exames de alta complexidade, listados no Rol de Procedimentos da ANS,' + (' relacionados diretamente à(s) condição(ões) e/ou problema(s) de saúde, disfunção(ões) e/ou' + ' doença(s), ou lesão(ões) pré-existente(s) especificada(s).'))))))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Este documento passa a fazer parte do contrato de plano de saúde coletivo por adesão.')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Curitiba,_____ de ____________ de ______.')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Nome:_________________________________________.')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('CPF:_____._____._____-___')
						])),
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src('/assets/images/footer.png'),
							A2($elm$html$Html$Attributes$style, 'width', '100%'),
							A2($elm$html$Html$Attributes$style, 'height', 'auto'),
							A2($elm$html$Html$Attributes$style, 'margin', 'auto')
						]),
					_List_Nil)
				]));
	});
var $author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewCPTPersonICDs = function (stmt) {
	return A2(
		$elm$core$List$map,
		function (icd) {
			return A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Person$fullPersonName(stmt.dw))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(icd.a0)
							])),
						A2(
						$elm$html$Html$td,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'white-space', 'normal')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(icd.am)
							]))
					]));
		},
		$author$project$App$Data$ICD$listFromDict(stmt.fx));
};
var $author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewCPT = F3(
	function (mDepIdx, fullStmt, now) {
		var statement = function () {
			if (!mDepIdx.$) {
				return fullStmt.g;
			} else {
				var idx = mDepIdx.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, fullStmt.fh));
			}
		}();
		var fullName = $author$project$App$Data$Person$fullPersonName(statement.dw);
		var date = 'Curitiba ' + $author$project$App$Tools$FormHelpers$dateFormat(now);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('doctor-result-layout'),
					$elm$html$Html$Attributes$class('sulamerica-cpt')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src('/assets/images/extramed_logo_ans.png'),
							A2($elm$html$Html$Attributes$style, 'width', '200px'),
							A2($elm$html$Html$Attributes$style, 'max-width', '150px'),
							A2($elm$html$Html$Attributes$style, 'height', 'auto')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'text-align', 'center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(date)
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('A (o)')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Sr (a) ' + fullName)
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('CPF: ' + statement.dw.i1)
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Ref: ')
								])),
							A2(
							$elm$html$Html$strong,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'text-decoration', 'underline')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Cobertura Parcial Temporária')
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Prezado (a) Senhor (a),')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Conforme previsto na sua solicitação de adesão e respeitando a determinação ' + 'da Resolução Normativa da ANS Nº 162 de outubro de 2007, ')
								])),
							A2(
							$elm$html$Html$strong,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('informamos que a operadora, optou pela implantação de Cobertura Parcial Temporária ' + 'de 24 meses para os CID(s) descrito(s) abaixo;')
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'font-weight', '700')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('A Cobertura Parcial Temporária (CPT) é aquela que admite, por um período ininterrupto de ' + ('até 24 meses, a partir da data da contratação ou adesão ao plano privado de assistência à ' + ('saúde, a suspensão da cobertura de Procedimentos de Alta Complexidade (PAC), leitos de alta ' + ('tecnologia (UTI/CTI) e procedimentos cirúrgicos, desde que relacionados exclusivamente às ' + 'doenças ou lesões preexistentes declaradas pelo beneficiário ou seu representante legal. '))))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Os procedimentos de alta complexidade encontram-se especificados no Rol de Procedimentos e Eventos' + 'em Saúde da ANS, disponível no site '),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('https://www.ans.gov.br')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('www.ans.gov.br')
								])),
							$elm$html$Html$text('.')
						])),
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'width', '100%'),
							A2($elm$html$Html$Attributes$attribute, 'max-width', '700px'),
							A2($elm$html$Html$Attributes$attribute, 'border', '1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Nome do Cliente')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('CID')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Descrição da Patologia')
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							$author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewCPTPersonICDs(statement))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Data: ____/____/____  Ciente e de acordo, ___________________________________')
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'page-break-inside', 'avoid')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Aguardaremos sua manifestação formal de aceitação até o prazo de 02 dias corridos, a ' + ('contar do recebimento desta. Findo este prazo sem que até tenhamos recebido o retorno ' + ('sobre a aceitação da Cobertura Parcial Temporária a solicitação de adesão ficará ' + ('automaticamente sem efeito, e permanecendo o interesse na adesão, deverá ser encaminhada ' + 'nova solicitação de adesão.'))))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Agradecemos a oportunidade do contato e colocamo-nos ao inteiro dispor para maiores esclarecimentos,')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Atenciosamente,')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('EXTRAMED ADMINISTRADORA DE BENEFÍCIOS')
						]))
				]));
	});
var $author$project$App$Legacy$Doctor$ResultLayout$UnimedFederacao$viewPersonICDForCPT = function (icd) {
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(icd.am)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('24 meses')
					]))
			]));
};
var $author$project$App$Legacy$Doctor$ResultLayout$UnimedFederacao$viewPersonICDsForCPT = function (stmt) {
	return A2(
		$elm$core$List$map,
		function (icd) {
			return $author$project$App$Legacy$Doctor$ResultLayout$UnimedFederacao$viewPersonICDForCPT(icd);
		},
		$author$project$App$Data$ICD$listFromDict(stmt.fx));
};
var $author$project$App$Legacy$Doctor$ResultLayout$UnimedFederacao$viewCPT = F3(
	function (mDepIdx, fullStmt, now) {
		var stmt = function () {
			if (!mDepIdx.$) {
				return fullStmt.g;
			} else {
				var idx = mDepIdx.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, fullStmt.fh));
			}
		}();
		var fullName = $author$project$App$Data$Person$fullPersonName(stmt.dw);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('doctor-result-layout')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('TERMO DE OPÇÃO POR COBERTURA PARCIAL TEMPORÁRIA – CPT')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Eu, ' + (fullName + (' opto nesta oportunidade por cumprir Cobertura Parcial Temporária ' + '(CPT) para as doenças ou lesões preexistentes abaixo discriminadas:')))
						])),
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'width', '100%'),
							A2($elm$html$Html$Attributes$attribute, 'border', '1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Descrição')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Prazo de suspensão')
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							$author$project$App$Legacy$Doctor$ResultLayout$UnimedFederacao$viewPersonICDsForCPT(stmt))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Declaro estar plenamente ciente de que:')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$ol,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$li,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('A Cobertura Parcial Temporária (CPT) é aquela que admite, por um ' + ('período ininterrupto de até 24 meses, a partir da data da contratação ou ' + ('adesão ao plano privado de assistência à saúde, a suspensão da cobertura de ' + ('Procedimentos de Alta Complexidade (PAC), leitos de alta tecnologia (UTI/CTI) e ' + ('procedimentos cirúrgicos, desde que relacionados exclusivamente às doenças ou lesões ' + 'preexistentes declaradas pelo beneficiário ou seu representante legal;')))))
										])),
									A2(
									$elm$html$Html$li,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('As doenças ou lesões preexistentes supracitadas foram obtidas pelas informações constantes ' + ('na Parte II – Informações sobre sua saúde e/ou de seus dependentes e/ou por meio da ' + 'perícia médica, se realizada; ')),
											A2($elm$html$Html$br, _List_Nil, _List_Nil),
											A2($elm$html$Html$br, _List_Nil, _List_Nil),
											$elm$html$Html$text('E')
										])),
									A2(
									$elm$html$Html$li,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Os procedimentos de alta complexidade (PAC) não terão cobertura pelo prazo respectivo, ' + 'em razão da constatação de doenças ou lesões pré-existentes*.')
										]))
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$table,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$td,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('_______________________________,_____/_____/______')
												])),
											A2(
											$elm$html$Html$td,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('________________________________')
												]))
										])),
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2($elm$html$Html$td, _List_Nil, _List_Nil),
											A2(
											$elm$html$Html$td,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Titular cliente ou responsável legal')
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('*Os procedimentos de alta complexidade são aqueles classificados como tal pela Agência ' + ('Nacional de Saúde Suplementar - ANS, no Rol de Procedimentos vigente na data da solicitação ' + ('do serviço. Tal lista pode ser acessada por V.Sa. no site da ANS: acessar www.ans.gov.br e ' + ('no ícone “Espaço do Consumidor” clicar em “o que o seu plano deve cobrir”, ' + ('“Confira mais informações sobre a cobertura mínima obrigatória em vigor” e ' + ('“Lista completa de procedimentos”. Se ao lado do procedimento selecionado constar a ' + 'sigla PAC significa que se trata de Procedimento de Alta Complexidade.'))))))
						]))
				]));
	});
var $author$project$App$Legacy$Doctor$ResultLayout$Common$viewCPTLayout = F3(
	function (mDepIdx, fullStmt, now) {
		var _v0 = fullStmt.cJ.gF;
		switch (_v0) {
			case 1:
				return A3($author$project$App$Legacy$Doctor$ResultLayout$UnimedFederacao$viewCPT, mDepIdx, fullStmt, now);
			case 2:
				return A3($author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewCPT, mDepIdx, fullStmt, now);
			case 3:
				return A3($author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewCPT, mDepIdx, fullStmt, now);
			case 4:
				return A3($author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewCPT, mDepIdx, fullStmt, now);
			case 5:
				return A3($author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewCPT, mDepIdx, fullStmt, now);
			case 6:
				return A3($author$project$App$Legacy$Doctor$ResultLayout$Bradesco$viewCPT, mDepIdx, fullStmt, now);
			case 7:
				return A3($author$project$App$Legacy$Doctor$ResultLayout$CentauroOn$viewCPT, mDepIdx, fullStmt, now);
			case 9:
				return A3($author$project$App$Legacy$Doctor$ResultLayout$Medsyn$viewCPT, mDepIdx, fullStmt, now);
			case 10:
				return A3($author$project$App$Legacy$Doctor$ResultLayout$SB_Saude$viewCPT, mDepIdx, fullStmt, now);
			case 11:
				return A3($author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewCPT, mDepIdx, fullStmt, now);
			case 12:
				return A3($author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewCPT, mDepIdx, fullStmt, now);
			case 8:
				return $elm$html$Html$text('');
			default:
				return $elm$html$Html$text('');
		}
	});
var $elm$html$Html$embed = _VirtualDom_node('embed');
var $author$project$App$Data$Document$view = function (model) {
	var _v0 = model.dr;
	switch (_v0) {
		case 'image/png':
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(model.iU),
						A2($elm$html$Html$Attributes$style, 'width', '100%'),
						A2($elm$html$Html$Attributes$style, 'height', '100%')
					]),
				_List_Nil);
		case 'image/jpeg':
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(model.iU),
						A2($elm$html$Html$Attributes$style, 'width', '100%'),
						A2($elm$html$Html$Attributes$style, 'height', '100%')
					]),
				_List_Nil);
		default:
			return A2(
				$elm$html$Html$embed,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
						$elm$html$Html$Attributes$src(model.iU + '#FitH&navpanes=0'),
						$elm$html$Html$Attributes$type_(model.dr),
						A2($elm$html$Html$Attributes$attribute, 'width', '100%')
					]),
				_List_Nil);
	}
};
var $author$project$App$Components$ViewFullStatement$viewDocumentContent = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Components$ViewFullStatement$viewCPTContent = F3(
	function (mDepIdx, fullStmt, now) {
		var cptDoc = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Document$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					function (_v1) {
						var i = _v1.a;
						var d = _v1.b;
						return d;
					},
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var d = _v0.b;
							return _Utils_eq(d.jJ, fullStmt.g.e.as) && (d.b7 === 5);
						},
						$elm$core$Dict$toList(fullStmt.an)))));
		return $author$project$App$Data$Id$uuidIsValid(cptDoc.as) ? $author$project$App$Components$ViewFullStatement$viewDocumentContent(cptDoc) : A3($author$project$App$Legacy$Doctor$ResultLayout$Common$viewCPTLayout, mDepIdx, fullStmt, now);
	});
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$App$Data$Person$photoUrl = function (person) {
	var _v0 = person.dy;
	if (!_v0.$) {
		var photo = _v0.a;
		return photo;
	} else {
		return (person.dJ === 1) ? '/assets/images/user_male.svg' : '/assets/images/user_female.svg';
	}
};
var $author$project$App$Components$ViewFullStatement$filterSections = F2(
	function (answers, sections) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, section) {
					return !$elm$core$List$isEmpty(
						A2(
							$elm$core$List$filter,
							function (question) {
								return !$elm$core$List$isEmpty(
									A2(
										$elm$core$List$filter,
										function (template) {
											return (A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$Answer$emptyModel,
												A2($elm$core$Dict$get, template.as, answers)).d3 && (template.ay !== 9)) || (A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$Answer$emptyModel,
												A2($elm$core$Dict$get, template.as, answers)).dF && (template.ay !== 9));
										},
										question.bY));
							},
							section.eI));
				}),
			sections);
	});
var $author$project$App$Data$Answer$maskDecimal = F2(
	function (precision, value) {
		return A3($elm$core$String$replace, '.', ',', value);
	});
var $author$project$App$Data$Answer$maskHeight = function (cm) {
	var len = $elm$core$String$length(cm);
	var subM = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cm)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cm) : cm);
	var h = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(cm));
	var m = (h >= 100) ? A3($elm$core$String$slice, 0, -2, cm) : '0';
	return (h > 0) ? (m + (',' + subM)) : '';
};
var $elm$core$Basics$truncate = _Basics_truncate;
var $author$project$App$Data$Answer$maskPressure = function (str) {
	return function (p) {
		return (p > 0) ? $elm$core$String$fromInt(p) : '';
	}(
		function (p) {
			return (p > 999) ? (p / 10) : (p / 1.0);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(str))) | 0);
};
var $author$project$App$Data$Answer$maskWeight = function (wStr) {
	return function (w) {
		return (w > 0) ? $elm$core$String$fromInt(w) : '';
	}(
		function (w) {
			return w / 1000;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(wStr))) | 0);
};
var $author$project$App$Components$ViewFullStatement$viewAnswerTypeMasked = F2(
	function (template, answer) {
		var unit = A2($elm$core$Maybe$withDefault, '', template.iv);
		var _v0 = template.jj;
		if (_v0.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh + (' ' + (answer.T + (' ' + unit))))
					]));
		} else {
			switch (_v0.a.$) {
				case 0:
					var _v1 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								template.jh + (' ' + ($author$project$App$Data$Answer$maskWeight(answer.T) + (' ' + unit))))
							]));
				case 1:
					var _v2 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								template.jh + (' ' + ($author$project$App$Data$Answer$maskHeight(answer.T) + (' ' + unit))))
							]));
				case 3:
					var precision = _v0.a.a;
					return A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								template.jh + (' ' + (A2($author$project$App$Data$Answer$maskDecimal, precision, answer.T) + (' ' + unit))))
							]));
				default:
					var _v3 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								template.jh + (' ' + ($author$project$App$Data$Answer$maskPressure(answer.T) + (' ' + unit))))
							]));
			}
		}
	});
var $author$project$App$Components$ViewFullStatement$viewAnswerTemplate = F2(
	function (answers, template) {
		var unit = A2($elm$core$Maybe$withDefault, '', template.iv);
		var answer = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Answer$emptyModel,
			A2($elm$core$Dict$get, template.as, answers));
		var _v0 = _Utils_Tuple2(template.j5, answer.dF);
		switch (_v0.a) {
			case 0:
				var _v1 = _v0.a;
				return $elm$html$Html$text('');
			case 2:
				var _v2 = _v0.a;
				return A2($author$project$App$Components$ViewFullStatement$viewAnswerTypeMasked, template, answer);
			case 3:
				var _v3 = _v0.a;
				return A2($author$project$App$Components$ViewFullStatement$viewAnswerTypeMasked, template, answer);
			case 5:
				var _v4 = _v0.a;
				return A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(template.jh + (' ' + answer.T))
						]));
			case 1:
				if (_v0.b) {
					var _v5 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(template.jh + (' ' + answer.T))
							]));
				} else {
					var _v6 = _v0.a;
					return $elm$html$Html$text('');
				}
			case 6:
				if (_v0.b) {
					var _v7 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(template.jh + (' ' + answer.T))
							]));
				} else {
					var _v8 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(template.jh + (' ' + answer.T))
							]));
				}
			case 4:
				if (_v0.b) {
					var _v9 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(template.jh + (' ' + (answer.T + (' ' + unit))))
							]));
				} else {
					var _v10 = _v0.a;
					return $elm$html$Html$text('');
				}
			case 7:
				var _v11 = _v0.a;
				return A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(template.jh + (' ' + answer.T))
						]));
			default:
				var _v12 = _v0.a;
				return A2($author$project$App$Components$ViewFullStatement$viewAnswerTypeMasked, template, answer);
		}
	});
var $author$project$App$Components$ViewFullStatement$viewQuestionAnswer = F2(
	function (answers, fullQuestion) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(fullQuestion.hG.bP)
					])),
				A2(
				$elm$html$Html$ul,
				_List_Nil,
				A2(
					$elm$core$List$map,
					$author$project$App$Components$ViewFullStatement$viewAnswerTemplate(answers),
					fullQuestion.bY))
			]);
	});
var $author$project$App$Components$ViewFullStatement$viewSectionAnswers = F3(
	function (answers, idx, section) {
		return $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				$author$project$App$Components$ViewFullStatement$viewQuestionAnswer(answers),
				A2(
					$elm$core$List$filter,
					function (question) {
						return !$elm$core$List$isEmpty(
							A2(
								$elm$core$List$filter,
								function (template) {
									return (A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$Answer$emptyModel,
										A2($elm$core$Dict$get, template.as, answers)).d3 && ((template.ay > 9) && (template.ay < 9))) || (A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$Answer$emptyModel,
										A2($elm$core$Dict$get, template.as, answers)).dF && (template.ay !== 9));
								},
								question.bY));
					},
					section.eI)));
	});
var $author$project$App$Components$ViewFullStatement$viewAnswers = F2(
	function (sections, answers) {
		return $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$second,
				$elm$core$Dict$toList(
					A2(
						$elm$core$Dict$map,
						$author$project$App$Components$ViewFullStatement$viewSectionAnswers(answers),
						A2($author$project$App$Components$ViewFullStatement$filterSections, answers, sections)))));
	});
var $author$project$App$Components$ViewFullStatement$viewObservation = function (observation) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$h3,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Observação')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(observation)
						]))
				]))
		]);
};
var $author$project$App$Components$ViewFullStatement$CopyToClipboard = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Data$Contact$clearMobile = function (mobile) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return A3(
			$elm$core$String$slice,
			0,
			11,
			A3(
				$elm$regex$Regex$replace,
				regex,
				function (_v1) {
					return '';
				},
				mobile));
	} else {
		return mobile;
	}
};
var $author$project$App$Data$Contact$formatMobile = function (mobile) {
	var clean = $author$project$App$Data$Contact$clearMobile(mobile);
	var size = $elm$core$String$length(clean);
	return (!size) ? '' : ((size === 1) ? ('(' + clean) : (((size >= 2) && (size < 7)) ? ('(' + (A3($elm$core$String$slice, 0, 2, clean) + (') ' + A3($elm$core$String$slice, 2, 7, clean)))) : ('(' + (A3($elm$core$String$slice, 0, 2, clean) + (') ' + (A3($elm$core$String$slice, 2, 7, clean) + ('-' + A3($elm$core$String$slice, 7, 11, clean))))))));
};
var $author$project$App$Components$ViewFullStatement$viewPersonContact = F2(
	function (mobile, email) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Contato')
					])),
				A2(
				$elm$html$Html$ul,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Telefone: '),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Events$onClick(
										$author$project$App$Components$ViewFullStatement$CopyToClipboard(
											$author$project$App$Data$Contact$formatMobile(mobile.T)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Data$Contact$formatMobile(mobile.T))
									]))
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								'Email: ' + $elm$core$String$toLower(email.T))
							]))
					]))
			]);
	});
var $elm$html$Html$b = _VirtualDom_node('b');
var $author$project$App$Data$Statement$formatCompanyBMI = function (model) {
	return $author$project$App$Data$Statement$formatBMI(
		A2($author$project$App$Data$Statement$calcBMI, model.iT, model.iS));
};
var $author$project$App$Data$Statement$formatPersonBMI = function (model) {
	return $author$project$App$Data$Statement$formatBMI(
		A2($author$project$App$Data$Statement$calcBMI, model.jN, model.jL));
};
var $author$project$App$Data$Statement$formatWeight = function (w) {
	var kg = $elm$core$String$fromInt((w / 1000) | 0);
	return (w > 0) ? (kg + ' kg') : '';
};
var $author$project$App$Components$ViewFullStatement$viewPersonMetrics = function (stmt) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$h3,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Dados Antropométricos')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							'Peso na Declaração: ' + $author$project$App$Data$Statement$formatWeight(stmt.iT))
						])),
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							'Peso no Questionário: ' + $author$project$App$Data$Statement$formatWeight(stmt.jN))
						])),
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							'Altura na Declaração: ' + $author$project$App$Data$Statement$formatHeight(stmt.iS))
						])),
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							'Altura no Questionário: ' + $author$project$App$Data$Statement$formatHeight(stmt.jL))
						])),
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$b,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									'IMC na Declaração: ' + $author$project$App$Data$Statement$formatCompanyBMI(stmt))
								]))
						])),
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$b,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									'IMC no Questionário: ' + $author$project$App$Data$Statement$formatPersonBMI(stmt))
								]))
						]))
				]))
		]);
};
var $author$project$App$Route$href = function (targetRoute) {
	return $elm$html$Html$Attributes$href(
		$author$project$App$Route$routeToString(targetRoute));
};
var $author$project$App$Components$ViewFullStatement$viewStatementLink = function (stmtId) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$h3,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Acesso')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$author$project$App$Route$href(
									A2($author$project$App$Route$Proponent, stmtId, 2))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Link para acesso ao sistema')
								]))
						]))
				]))
		]);
};
var $author$project$App$Components$ViewFullStatement$viewEditStatement = function (now) {
	return function (idStr) {
		return function (mobile) {
			return function (email) {
				return function (sections) {
					return function (companySections) {
						return function (fullStatement) {
							return function (stmt) {
								return function (showUrl) {
									return function (showAnthropometric) {
										return function (showContact) {
											return function (showObservation) {
												return function (showQuestions) {
													return function (showDPSQuestions) {
														return function (doctor) {
															return function (companyPerson) {
																return A2(
																	$elm$html$Html$section,
																	_List_Nil,
																	_Utils_ap(
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$header,
																				_List_Nil,
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$img,
																						_List_fromArray(
																							[
																								$elm$html$Html$Attributes$src(
																								$author$project$App$Data$Person$photoUrl(stmt.dw))
																							]),
																						_List_Nil),
																						A2(
																						$elm$html$Html$h2,
																						_List_Nil,
																						_List_fromArray(
																							[
																								$elm$html$Html$text(
																								$author$project$App$Data$Person$fullPersonName(stmt.dw))
																							])),
																						A2(
																						$elm$html$Html$h2,
																						_List_Nil,
																						_List_fromArray(
																							[
																								$elm$html$Html$text(idStr)
																							])),
																						A2(
																						$elm$html$Html$h3,
																						_List_Nil,
																						_List_fromArray(
																							[
																								$elm$html$Html$text(
																								$elm$core$String$fromInt(
																									A2($author$project$App$Data$Person$ageOnDate, now, stmt.dw)) + ' anos')
																							])),
																						A2(
																						$elm$html$Html$h2,
																						_List_Nil,
																						_List_fromArray(
																							[
																								$elm$html$Html$text(fullStatement.b4.iR + ('/' + fullStatement.cJ.hl))
																							]))
																					])),
																				A2($elm$html$Html$br, _List_Nil, _List_Nil),
																				A2(
																				$elm$html$Html$h3,
																				_List_Nil,
																				_List_fromArray(
																					[
																						$elm$html$Html$text('Documento')
																					])),
																				A2(
																				$elm$html$Html$ul,
																				_List_Nil,
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$li,
																						_List_Nil,
																						_List_fromArray(
																							[
																								$elm$html$Html$text(
																								$author$project$App$Data$Person$formatDocument(stmt.dw.i1))
																							]))
																					])),
																				A2(
																				$elm$html$Html$h3,
																				_List_Nil,
																				_List_fromArray(
																					[
																						$elm$html$Html$text('Agendamento')
																					])),
																				A2(
																				$elm$html$Html$ul,
																				_List_Nil,
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$li,
																						_List_Nil,
																						_List_fromArray(
																							[
																								$elm$html$Html$text(
																								A3(
																									$elm$core$String$slice,
																									0,
																									16,
																									$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(stmt.e.jU)))
																							]))
																					]))
																			]),
																		_Utils_ap(
																			function () {
																				if ((!_Utils_eq(stmt.e.iX, $elm$core$Maybe$Nothing)) && (!_Utils_eq(companyPerson, $elm$core$Maybe$Nothing))) {
																					var companyPersonFullName = function () {
																						if (!companyPerson.$) {
																							var personModel = companyPerson.a;
																							return personModel.hl + (' ' + personModel.ii);
																						} else {
																							return '(carregando...)';
																						}
																					}();
																					return _List_fromArray(
																						[
																							A2(
																							$elm$html$Html$h3,
																							_List_Nil,
																							_List_fromArray(
																								[
																									$elm$html$Html$text('Criado por')
																								])),
																							A2(
																							$elm$html$Html$ul,
																							_List_Nil,
																							_List_fromArray(
																								[
																									A2(
																									$elm$html$Html$li,
																									_List_Nil,
																									_List_fromArray(
																										[
																											$elm$html$Html$text(companyPersonFullName)
																										]))
																								]))
																						]);
																				} else {
																					return _List_Nil;
																				}
																			}(),
																			_Utils_ap(
																				showAnthropometric ? $author$project$App$Components$ViewFullStatement$viewPersonMetrics(stmt.e) : _List_Nil,
																				_Utils_ap(
																					showUrl ? $author$project$App$Components$ViewFullStatement$viewStatementLink(stmt.e.as) : _List_Nil,
																					_Utils_ap(
																						showContact ? A2($author$project$App$Components$ViewFullStatement$viewPersonContact, mobile, email) : _List_Nil,
																						_Utils_ap(
																							showObservation ? $author$project$App$Components$ViewFullStatement$viewObservation(stmt.e.jx) : _List_Nil,
																							_Utils_ap(
																								(showDPSQuestions && ($elm$core$Dict$size(stmt.gw) > 0)) ? _Utils_ap(
																									_List_fromArray(
																										[
																											A2($elm$html$Html$br, _List_Nil, _List_Nil),
																											A2(
																											$elm$html$Html$h3,
																											_List_Nil,
																											_List_fromArray(
																												[
																													$elm$html$Html$text('DPS')
																												]))
																										]),
																									A2($author$project$App$Components$ViewFullStatement$viewAnswers, companySections, stmt.gw)) : _List_Nil,
																								_Utils_ap(
																									(showQuestions && ($elm$core$Dict$size(stmt.aZ) > 0)) ? _Utils_ap(
																										_List_fromArray(
																											[
																												A2($elm$html$Html$br, _List_Nil, _List_Nil),
																												A2(
																												$elm$html$Html$h3,
																												_List_Nil,
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Questionário')
																													]))
																											]),
																										A2($author$project$App$Components$ViewFullStatement$viewAnswers, sections, stmt.aZ)) : _List_Nil,
																									_Utils_ap(
																										function () {
																											if ((_Utils_cmp(
																												$author$project$App$Data$StatementStatus$statusToInt(stmt.e.aw),
																												$author$project$App$Data$StatementStatus$statusToInt(21)) > 0) && ((_Utils_cmp(
																												$author$project$App$Data$StatementStatus$statusToInt(stmt.e.aw),
																												$author$project$App$Data$StatementStatus$statusToInt(33)) < 0) && (!_Utils_eq(doctor, $elm$core$Maybe$Nothing)))) {
																												var doctorFullName = function () {
																													if (!doctor.$) {
																														var doctorModel = doctor.a;
																														return doctorModel.hl + (' ' + doctorModel.ii);
																													} else {
																														return '(carregando...)';
																													}
																												}();
																												return _List_fromArray(
																													[
																														A2(
																														$elm$html$Html$h3,
																														_List_Nil,
																														_List_fromArray(
																															[
																																$elm$html$Html$text('Atendido(a) por')
																															])),
																														A2(
																														$elm$html$Html$ul,
																														_List_Nil,
																														_List_fromArray(
																															[
																																A2(
																																$elm$html$Html$li,
																																_List_Nil,
																																_List_fromArray(
																																	[
																																		$elm$html$Html$text(doctorFullName)
																																	]))
																															]))
																													]);
																											} else {
																												return _List_Nil;
																											}
																										}(),
																										(stmt.e.aw === 33) ? _List_fromArray(
																											[
																												A2(
																												$elm$html$Html$h3,
																												_List_Nil,
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Finalizado em')
																													])),
																												A2(
																												$elm$html$Html$ul,
																												_List_Nil,
																												_List_fromArray(
																													[
																														A2(
																														$elm$html$Html$li,
																														_List_Nil,
																														_List_fromArray(
																															[
																																$elm$html$Html$text(
																																A3(
																																	$elm$core$String$slice,
																																	0,
																																	16,
																																	$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(stmt.e.f2)))
																															]))
																													]))
																											]) : _List_Nil))))))))));
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$App$Components$ViewFullStatement$filterSectionsForFullStatements = F2(
	function (stmts, sections) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, section) {
					return !$elm$core$List$isEmpty(
						A2(
							$elm$core$List$filter,
							function (question) {
								return !$elm$core$List$isEmpty(
									A2(
										$elm$core$List$filter,
										function (template) {
											return A2(
												$elm$core$List$any,
												function (_v1) {
													var idx = _v1.a;
													var stmt = _v1.b;
													return (A2(
														$elm$core$Maybe$withDefault,
														$author$project$App$Data$Answer$emptyModel,
														A2($elm$core$Dict$get, template.as, stmt.aZ)).d3 && (template.ay !== 9)) || (A2(
														$elm$core$Maybe$withDefault,
														$author$project$App$Data$Answer$emptyModel,
														A2($elm$core$Dict$get, template.as, stmt.aZ)).dF && (template.ay !== 9));
												},
												$elm$core$Dict$toList(stmts));
										},
										question.bY));
							},
							section.eI));
				}),
			sections);
	});
var $author$project$App$Components$ViewFullStatement$viewQuestionAnswerForFullStatement = F2(
	function (stmts, fullQuestion) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(fullQuestion.hG.bP)
					])),
				A2(
				$elm$html$Html$ul,
				_List_Nil,
				$elm$core$List$concat(
					A2(
						$elm$core$List$map,
						function (at) {
							return $elm$core$List$concat(
								A2(
									$elm$core$List$map,
									function (_v0) {
										var idx = _v0.a;
										var stmt = _v0.b;
										var showAnswer = !_Utils_eq(
											A2($author$project$App$Components$ViewFullStatement$viewAnswerTemplate, stmt.aZ, at),
											$elm$html$Html$text(''));
										return showAnswer ? _List_fromArray(
											[
												A2(
												$elm$html$Html$li,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$h2,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text(
																$author$project$App$Data$Person$fullPersonName(stmt.dw))
															]))
													])),
												A2($author$project$App$Components$ViewFullStatement$viewAnswerTemplate, stmt.aZ, at)
											]) : _List_Nil;
									},
									$elm$core$Dict$toList(stmts)));
						},
						fullQuestion.bY)))
			]);
	});
var $author$project$App$Components$ViewFullStatement$viewSectionAnswersForFullStatement = F2(
	function (stmts, section) {
		return $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				$author$project$App$Components$ViewFullStatement$viewQuestionAnswerForFullStatement(stmts),
				A2(
					$elm$core$List$filter,
					function (question) {
						return !$elm$core$List$isEmpty(
							A2(
								$elm$core$List$filter,
								function (template) {
									return A2(
										$elm$core$List$any,
										function (_v0) {
											var idx = _v0.a;
											var stmt = _v0.b;
											return (A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$Answer$emptyModel,
												A2($elm$core$Dict$get, template.as, stmt.aZ)).d3 && ((template.ay > 9) && (template.ay < 9))) || (A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$Answer$emptyModel,
												A2($elm$core$Dict$get, template.as, stmt.aZ)).dF && (template.ay !== 9));
										},
										$elm$core$Dict$toList(stmts));
								},
								question.bY));
					},
					section.eI)));
	});
var $author$project$App$Components$ViewFullStatement$viewQuestionAnswers = F2(
	function (sections, fullStatement) {
		var stmts = A3($elm$core$Dict$insert, 0, fullStatement.g, fullStatement.fh);
		return $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (_v0) {
					var idx = _v0.a;
					var section = _v0.b;
					return A2($author$project$App$Components$ViewFullStatement$viewSectionAnswersForFullStatement, stmts, section);
				},
				$elm$core$Dict$toList(
					A2($author$project$App$Components$ViewFullStatement$filterSectionsForFullStatements, stmts, sections))));
	});
var $author$project$App$Components$ViewFullStatement$viewQuestions = F2(
	function (fullStatement, sections) {
		return A2(
			$elm$html$Html$section,
			_List_Nil,
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h2,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Questionário')
									]))
							])),
						A2($elm$html$Html$br, _List_Nil, _List_Nil)
					]),
				A2($author$project$App$Components$ViewFullStatement$viewQuestionAnswers, sections, fullStatement)));
	});
var $author$project$App$Components$ViewFullStatement$viewSelected = F2(
	function (_v0, sharedState) {
		var fullStatement = _v0.a5;
		var now = _v0.ey;
		var currentTab = _v0.b5;
		var sections = _v0.cA;
		var companySections = _v0.bs;
		var doctor = _v0.bu;
		var companyUser = _v0.cZ;
		var showUrl = !(((fullStatement.b4.iR === 'Extramed') && (fullStatement.g.e.aw === 1)) || ((fullStatement.g.e.aw === 12) || ((fullStatement.g.e.aw === 24) || ((fullStatement.g.e.aw === 32) || (fullStatement.g.e.aw === 33)))));
		var showQuestions = ((fullStatement.g.e.aw === 12) || ((fullStatement.g.e.aw === 8) || ((fullStatement.g.e.aw === 9) || ((fullStatement.g.e.aw === 11) || ((fullStatement.g.e.aw === 12) || ((fullStatement.g.e.aw === 13) || ((fullStatement.g.e.aw === 14) || ((fullStatement.g.e.aw === 15) || ((fullStatement.g.e.aw === 16) || ((fullStatement.g.e.aw === 17) || ((fullStatement.g.e.aw === 18) || ((fullStatement.g.e.aw === 19) || ((fullStatement.g.e.aw === 20) || (fullStatement.g.e.aw === 21)))))))))))))) && (!(fullStatement.b4.iR === 'Centauro-ON'));
		var showObs = fullStatement.g.e.aw === 28;
		var showDPSQuestions = showQuestions || (fullStatement.b4.iR === 'Centauro-ON');
		var showContact = !((!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return d === 'Contact';
				},
				fullStatement.cJ.gG))) && (fullStatement.g.e.aw === 12));
		var showAnthropometric = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return d === 'Anthropometric';
				},
				fullStatement.cJ.gG)) > 0;
		var mobile = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Contact$emptyMobile,
			A2(
				$elm$core$Dict$get,
				$author$project$App$Data$Contact$kindToString(1),
				fullStatement.g.ea));
		var email = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Contact$emptyEmail,
			A2(
				$elm$core$Dict$get,
				$author$project$App$Data$Contact$kindToString(5),
				fullStatement.g.ea));
		var depsNoRepeat = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$filter,
				function (_v2) {
					var i = _v2.a;
					var d = _v2.b;
					return (!_Utils_eq(d.e.as, fullStatement.g.e.as)) && $author$project$App$Data$Id$uuidIsValid(d.e.as);
				},
				$elm$core$Dict$toList(fullStatement.fh)));
		var bearerTitle = (!_Utils_eq(fullStatement.g.e.jK, $elm$core$Maybe$Nothing)) ? 'Dependente' : 'Titular';
		switch (currentTab.$) {
			case 5:
				return A2(
					$elm$core$List$cons,
					$author$project$App$Components$ViewFullStatement$viewEditStatement(now)(
						fullStatement.cJ.gX ? bearerTitle : '')(mobile)(email)(sections)(companySections)(fullStatement)(fullStatement.g)(showUrl)(showAnthropometric)(showContact)(showObs)(showQuestions)(showDPSQuestions)(doctor)(companyUser),
					A2(
						$elm$core$List$map,
						$elm$core$Tuple$second,
						$elm$core$Dict$toList(
							A2(
								$elm$core$Dict$map,
								F2(
									function (k, dep) {
										var mContact = A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$Contact$emptyMobile,
											A2(
												$elm$core$Dict$get,
												$author$project$App$Data$Contact$kindToString(1),
												dep.ea));
										var eContact = A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$Contact$emptyEmail,
											A2(
												$elm$core$Dict$get,
												$author$project$App$Data$Contact$kindToString(5),
												dep.ea));
										return $author$project$App$Components$ViewFullStatement$viewEditStatement(now)(
											fullStatement.cJ.gX ? ('Dependente ' + $elm$core$String$fromInt(k + 1)) : '')(mContact)(eContact)(sections)(companySections)(fullStatement)(dep)(showUrl)(showAnthropometric)(showContact)(showObs)(showQuestions)(showDPSQuestions)(doctor)(companyUser);
									}),
								depsNoRepeat))));
			case 1:
				var idx = currentTab.a;
				return _List_fromArray(
					[
						$author$project$App$Components$ViewFullStatement$viewDocumentContent(
						A2(
							$elm$core$Maybe$withDefault,
							$author$project$App$Data$Document$emptyModel,
							A2($elm$core$Dict$get, idx, fullStatement.an)))
					]);
			case 3:
				var depIdx = currentTab.a;
				var fullStmt = sharedState.ia.h8;
				return _List_fromArray(
					[
						A3($author$project$App$Components$ViewFullStatement$viewCPTContent, depIdx, fullStmt, now)
					]);
			case 6:
				var fullStmt = sharedState.ia.h8;
				return _List_fromArray(
					[
						A2($author$project$App$Components$ViewFullStatement$viewQuestions, fullStmt, sections)
					]);
			default:
				return _List_Nil;
		}
	});
var $author$project$App$Components$ViewFullStatement$ChangeToTab = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Components$ViewFullStatement$TabCPT = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Components$ViewFullStatement$TabQuestions = {$: 6};
var $author$project$App$Components$ViewFullStatement$viewDocumentTab = F3(
	function (currentTab, idx, doc) {
		var _v0 = doc.dr;
		switch (_v0) {
			case 'video/webm':
				return $elm$html$Html$text('');
			case 'video/mp4':
				return A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'tab-selected',
									_Utils_eq(
										currentTab,
										$author$project$App$Components$ViewFullStatement$TabDocument(idx)))
								])),
							$elm$html$Html$Events$onClick(
							$author$project$App$Components$ViewFullStatement$ChangeToTab(
								$author$project$App$Components$ViewFullStatement$TabDocument(idx)))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Video')
						]));
			default:
				return A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'tab-selected',
									_Utils_eq(
										currentTab,
										$author$project$App$Components$ViewFullStatement$TabDocument(idx)))
								])),
							$elm$html$Html$Events$onClick(
							$author$project$App$Components$ViewFullStatement$ChangeToTab(
								$author$project$App$Components$ViewFullStatement$TabDocument(idx)))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							function () {
								var _v1 = doc.b7;
								switch (_v1) {
									case 1:
										return 'Parecer';
									case 3:
										return 'Atestado';
									case 4:
										return 'Receita';
									default:
										return 'Anexo';
								}
							}())
						]));
		}
	});
var $author$project$App$Components$ViewFullStatement$viewDocumentTabNumered = F4(
	function (currentTab, idx, doc, documentTabs) {
		var index = $elm$core$List$length(documentTabs) + 1;
		return A2(
			$elm$core$List$append,
			documentTabs,
			function () {
				var _v0 = doc.dr;
				switch (_v0) {
					case 'video/webm':
						return _List_fromArray(
							[
								$elm$html$Html$text('')
							]);
					case 'video/mp4':
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Components$ViewFullStatement$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Components$ViewFullStatement$ChangeToTab(
											$author$project$App$Components$ViewFullStatement$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										'Video' + $elm$core$String$fromInt(index))
									]))
							]);
					default:
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Components$ViewFullStatement$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Components$ViewFullStatement$ChangeToTab(
											$author$project$App$Components$ViewFullStatement$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											var _v1 = doc.b7;
											switch (_v1) {
												case 1:
													return 'Parecer Antigo' + $elm$core$String$fromInt(index);
												case 3:
													return 'Atestado' + $elm$core$String$fromInt(index);
												case 4:
													return 'Receita' + $elm$core$String$fromInt(index);
												default:
													return 'Anexo';
											}
										}())
									]))
							]);
				}
			}());
	});
var $author$project$App$Components$ViewFullStatement$viewDocumentTabs = F2(
	function (currentTab, docs) {
		var reportDocs = A2(
			$elm$core$List$sortWith,
			F2(
				function (a, b) {
					var _v4 = A2(
						$elm$core$Basics$compare,
						$elm$time$Time$posixToMillis(a.b.iW),
						$elm$time$Time$posixToMillis(b.b.iW));
					switch (_v4) {
						case 0:
							return 2;
						case 1:
							return 1;
						default:
							return 0;
					}
				}),
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v3, doc) {
							return doc.b7 === 1;
						}),
					docs)));
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$second,
				$elm$core$Dict$toList(
					A2(
						$elm$core$Dict$map,
						$author$project$App$Components$ViewFullStatement$viewDocumentTab(currentTab),
						$elm$core$Dict$fromList(
							A2(
								$elm$core$List$take,
								1,
								A2(
									$elm$core$List$sortWith,
									F2(
										function (a, b) {
											var _v1 = A2(
												$elm$core$Basics$compare,
												$elm$time$Time$posixToMillis(a.b.f2),
												$elm$time$Time$posixToMillis(b.b.f2));
											switch (_v1) {
												case 0:
													return 2;
												case 1:
													return 1;
												default:
													return 0;
											}
										}),
									$elm$core$Dict$toList(
										A2(
											$elm$core$Dict$filter,
											F2(
												function (_v0, doc) {
													return doc.dr === 'video/mp4';
												}),
											docs)))))))),
			_Utils_ap(
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$second,
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$map,
							$author$project$App$Components$ViewFullStatement$viewDocumentTab(currentTab),
							$elm$core$Dict$fromList(
								A2($elm$core$List$take, 1, reportDocs))))),
				_Utils_ap(
					A3(
						$elm$core$Dict$foldl,
						$author$project$App$Components$ViewFullStatement$viewDocumentTabNumered(currentTab),
						_List_Nil,
						$elm$core$Dict$fromList(
							A2(
								$elm$core$Maybe$withDefault,
								_List_Nil,
								$elm$core$List$tail(reportDocs)))),
					A2(
						$elm$core$List$map,
						$elm$core$Tuple$second,
						$elm$core$Dict$toList(
							A2(
								$elm$core$Dict$map,
								$author$project$App$Components$ViewFullStatement$viewDocumentTab(currentTab),
								A2(
									$elm$core$Dict$filter,
									F2(
										function (_v2, doc) {
											return (doc.dr !== 'video/webm') && ((doc.dr !== 'video/mp4') && ((doc.b7 !== 5) && (doc.b7 !== 1)));
										}),
									docs)))))));
	});
var $author$project$App$Components$ViewFullStatement$viewProfileTab = function (currentTab) {
	return A2(
		$elm$html$Html$a,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$href('#'),
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tab-selected',
						_Utils_eq(currentTab, $author$project$App$Components$ViewFullStatement$TabProfile))
					])),
				$elm$html$Html$Events$onClick(
				$author$project$App$Components$ViewFullStatement$ChangeToTab($author$project$App$Components$ViewFullStatement$TabProfile))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Dados')
			]));
};
var $author$project$App$Components$ViewFullStatement$viewTabs = F2(
	function (currentTab, fullStmt) {
		var viewQuestionTab = fullStmt.b4.iR === 'Centauro-ON';
		var viewCPT = ((fullStmt.g.e.aw === 18) || ((fullStmt.g.e.aw === 19) || ((fullStmt.g.e.aw === 20) || ((fullStmt.g.e.aw === 21) || ((fullStmt.g.e.aw === 24) || ((fullStmt.g.e.aw === 32) || ((fullStmt.g.e.aw === 33) || ((fullStmt.g.e.aw === 31) || (fullStmt.g.e.aw === 30))))))))) && (fullStmt.b4.iR === 'Extramed');
		var resultView = A2($author$project$App$Data$StatementStatus$statusIsBefore, fullStmt.g.e.aw, 32) ? _List_Nil : _List_Nil;
		var questionTabs = viewQuestionTab ? _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(currentTab, $author$project$App$Components$ViewFullStatement$TabQuestions))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Components$ViewFullStatement$ChangeToTab($author$project$App$Components$ViewFullStatement$TabQuestions))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Questionário ')
					]))
			]) : _List_Nil;
		var bearerCpt = (fullStmt.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Components$ViewFullStatement$TabCPT($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Components$ViewFullStatement$ChangeToTab(
							$author$project$App$Components$ViewFullStatement$TabCPT($author$project$App$Data$Statement$Bearer)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('CPT Titular')
					]))
			]);
		var cptTabs = viewCPT ? A2(
			$elm$core$List$append,
			bearerCpt,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var idx = _v0.a;
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('#'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'tab-selected',
										_Utils_eq(
											currentTab,
											$author$project$App$Components$ViewFullStatement$TabCPT(
												$author$project$App$Data$Statement$Dependent(idx))))
									])),
								$elm$html$Html$Events$onClick(
								$author$project$App$Components$ViewFullStatement$ChangeToTab(
									$author$project$App$Components$ViewFullStatement$TabCPT(
										$author$project$App$Data$Statement$Dependent(idx))))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'CPT Dep ' + $elm$core$String$fromInt(idx + 1))
							]));
				},
				$elm$core$Dict$toList(fullStmt.fh))) : _List_Nil;
		var commonView = A2(
			$elm$core$List$cons,
			$author$project$App$Components$ViewFullStatement$viewProfileTab(currentTab),
			_Utils_ap(
				A2($author$project$App$Components$ViewFullStatement$viewDocumentTabs, currentTab, fullStmt.an),
				_Utils_ap(cptTabs, questionTabs)));
		return _Utils_ap(commonView, resultView);
	});
var $author$project$App$Components$ViewFullStatement$view = F2(
	function (sharedState, model) {
		return A2(
			$elm$html$Html$article,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-detail')
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$header,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('tabs')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							A2($author$project$App$Components$ViewFullStatement$viewTabs, model.b5, model.a5))
						])),
				A2($author$project$App$Components$ViewFullStatement$viewSelected, model, sharedState)));
	});
var $author$project$App$Components$Filter$FilterFieldPage = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Components$Filter$viewPagination = F2(
	function (list, model) {
		var nextPageEvent = _Utils_eq(
			$elm$core$List$length(list),
			model.cm) ? _List_fromArray(
			[
				$elm$html$Html$Events$onClick(
				$author$project$App$Components$Filter$FilterFieldPage(model.aQ + 1))
			]) : _List_Nil;
		var lastPageEvent = (model.aQ > 1) ? _List_fromArray(
			[
				$elm$html$Html$Events$onClick(
				$author$project$App$Components$Filter$FilterFieldPage(model.aQ - 1))
			]) : _List_Nil;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pagination')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					lastPageEvent,
					_List_fromArray(
						[
							$elm$html$Html$text('<')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pag_numbers')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							'Página ' + $elm$core$String$fromInt(model.aQ))
						])),
					A2(
					$elm$html$Html$button,
					nextPageEvent,
					_List_fromArray(
						[
							$elm$html$Html$text('>')
						]))
				]));
	});
var $author$project$App$Page$Admin$PageListFinished$Evaluate = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Admin$PageListFinished$HideInterview = {$: 4};
var $author$project$App$Service$ApiWebSocket$errorMessage = function (_v0) {
	var err = _v0.a;
	return $author$project$App$Service$SpecificResources$errorToMessage(err);
};
var $author$project$App$Data$StatementStatus$statusToText = function (status) {
	switch (status) {
		case 0:
			return 'Nova';
		case 1:
			return 'Aguardando Revisão';
		case 2:
			return 'Enviando notificações';
		case 3:
			return 'Aguardando Cliente';
		case 4:
			return 'Cliente validando Termos';
		case 5:
			return 'Cliente preenchendo dados de pagamento';
		case 6:
			return 'Cliente respondendo formulário';
		case 7:
			return 'Em triagem';
		case 10:
			return 'Entrando na fila';
		case 8:
			return 'Confirmando atendimento';
		case 9:
			return 'Aguardando sala de atendimento';
		case 11:
			return 'Aguardando por um médico';
		case 12:
			return 'Médico Revisando Documentação';
		case 13:
			return 'Médico fazendo apresentação';
		case 14:
			return 'Médico orientando';
		case 15:
			return 'Confirmando dados pessoais';
		case 16:
			return 'Confirmando dados antropométricos';
		case 17:
			return 'Validando declarações';
		case 18:
			return 'Validando declarações';
		case 26:
			return 'Gerando resultados finais';
		case 20:
			return 'Informando mudanças';
		case 19:
			return 'Informando que não há mudanças';
		case 21:
			return 'Finalizando video chamada';
		case 22:
			return 'Aguardando processamento de vídeo';
		case 23:
			return 'Processando vídeo';
		case 24:
			return 'Aguardando parecer médico c/a';
		case 25:
			return 'Aguardando parecer médico';
		case 27:
			return 'Aguardando documentação extra';
		case 28:
			return 'Recusada';
		case 29:
			return 'Vencido';
		case 30:
			return 'Aguardando Reavaliação';
		case 31:
			return 'Aguardando confirmação';
		case 32:
			return 'Aguardando dependentes';
		case 33:
			return 'Completa';
		case 34:
			return 'Recuperando Vídeo';
		case 35:
			return 'Reprocessando Vídeo';
		default:
			return 'Recuperaração de Vídeo Falha';
	}
};
var $author$project$App$Page$Admin$PageListFinished$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var _v0 = function () {
			var _v1 = _Utils_Tuple2(
				stmt.aw,
				_Utils_eq(
					model.aU,
					$elm$core$Maybe$Just(stmt.as)));
			if (!_v1.b) {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Admin$PageListFinished$Evaluate(stmt.as))
						]),
					_List_Nil);
			} else {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$App$Page$Admin$PageListFinished$HideInterview)
						]),
					_List_Nil);
			}
		}();
		var onClickMsg = _v0.a;
		var buttons = _v0.b;
		var _v2 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v2.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v2.a.$) {
				case 3:
					var person = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_Utils_ap(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('user-item'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'user-selected',
											_Utils_eq(
												model.aU,
												$elm$core$Maybe$Just(stmt.as)))
										]))
								]),
							onClickMsg),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status:')
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$StatementStatus$statusToText(stmt.aw))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_Utils_ap(
									buttons,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$App$Data$Person$fullPersonName(person))
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
												])),
											(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente titular')
												])) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente dependente')
												])))) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$elm$core$String$fromInt(stmt.bo) + ' dependentes')
												]))
										])))
							]));
				case 2:
					var err = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v3 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v4 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Admin$PageListFinished$viewStatements = F2(
	function (model, stmtTemplate) {
		var _v0 = model.bk;
		if (!_v0.b) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Nenhum Atendimento realizado')
						]))
				]);
		} else {
			var statements = _v0;
			return A2(
				$elm$core$List$map,
				A2($author$project$App$Page$Admin$PageListFinished$viewUserListRow, model, stmtTemplate),
				statements);
		}
	});
var $author$project$App$Page$Admin$PageListFinished$view = F2(
	function (sharedState, model) {
		var viewDetails = function () {
			var _v0 = model.av;
			if (!_v0.$) {
				var viewFullStmt = _v0.a;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Admin$PageListFinished$ViewFullStmtMsg,
							A2($author$project$App$Components$ViewFullStatement$view, sharedState, viewFullStmt))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Atividades Realizadas')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('action-buttons')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$map,
										$author$project$App$Page$Admin$PageListFinished$FilterMsg,
										$author$project$App$Components$Filter$view(model.b))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								A2($author$project$App$Page$Admin$PageListFinished$viewStatements, model, sharedState.ia.h8.cJ))
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Admin$PageListFinished$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					])),
				viewDetails
			]);
	});
var $author$project$App$Page$Admin$PageListReevaluation$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Admin$PageListReevaluation$Evaluate = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Admin$PageListReevaluation$HideInterview = {$: 4};
var $author$project$App$Page$Admin$PageListReevaluation$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var _v0 = function () {
			var _v1 = _Utils_Tuple2(
				stmt.aw,
				_Utils_eq(
					model.aU,
					$elm$core$Maybe$Just(stmt.as)));
			if (!_v1.b) {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Admin$PageListReevaluation$Evaluate(stmt.as))
						]),
					_List_Nil);
			} else {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$App$Page$Admin$PageListReevaluation$HideInterview)
						]),
					_List_Nil);
			}
		}();
		var onClickMsg = _v0.a;
		var buttons = _v0.b;
		var _v2 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v2.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v2.a.$) {
				case 3:
					var person = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_Utils_ap(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('user-item'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'user-selected',
											_Utils_eq(
												model.aU,
												$elm$core$Maybe$Just(stmt.as)))
										]))
								]),
							onClickMsg),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status:')
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$StatementStatus$statusToText(stmt.aw))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_Utils_ap(
									buttons,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$App$Data$Person$fullPersonName(person))
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
												])),
											(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente titular')
												])) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente dependente')
												])))) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$elm$core$String$fromInt(stmt.bo) + ' dependentes')
												]))
										])))
							]));
				case 2:
					var err = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v3 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v4 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Admin$PageListReevaluation$viewStatements = F2(
	function (model, stmtTemplate) {
		var _v0 = model.bk;
		if (!_v0.b) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Nenhum atendimento em reavaliação')
						]))
				]);
		} else {
			var statements = _v0;
			return A2(
				$elm$core$List$map,
				A2($author$project$App$Page$Admin$PageListReevaluation$viewUserListRow, model, stmtTemplate),
				statements);
		}
	});
var $author$project$App$Page$Admin$PageListReevaluation$view = F2(
	function (sharedState, model) {
		var viewDetails = function () {
			var _v0 = model.av;
			if (!_v0.$) {
				var viewFullStmt = _v0.a;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Admin$PageListReevaluation$ViewFullStmtMsg,
							A2(
								$author$project$App$Components$ViewFullStatement$view,
								sharedState,
								_Utils_update(
									viewFullStmt,
									{a5: sharedState.ia.h8})))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Em Reavaliação')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('action-buttons')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$map,
										$author$project$App$Page$Admin$PageListReevaluation$FilterMsg,
										$author$project$App$Components$Filter$view(model.b))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								A2($author$project$App$Page$Admin$PageListReevaluation$viewStatements, model, sharedState.ia.h8.cJ))
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Admin$PageListReevaluation$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					])),
				viewDetails
			]);
	});
var $author$project$App$Page$Admin$PageListUsers$comparePosixOfUser = F2(
	function (a, b) {
		var _v0 = A2(
			$elm$core$Basics$compare,
			$elm$time$Time$posixToMillis(
				A2(
					$elm$core$Maybe$withDefault,
					$elm$time$Time$millisToPosix(0),
					a.iW)),
			$elm$time$Time$posixToMillis(
				A2(
					$elm$core$Maybe$withDefault,
					$elm$time$Time$millisToPosix(0),
					b.iW)));
		switch (_v0) {
			case 2:
				return 0;
			case 0:
				return 2;
			default:
				return 1;
		}
	});
var $author$project$App$Page$Admin$PageListUsers$ActivateAccount = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Admin$PageListUsers$DeleteAccount = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$App$Page$Admin$PageListUsers$viewUserListRow = F2(
	function (model, user) {
		var deleteButton = A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list-buttons'),
					$elm$html$Html$Attributes$title('Apagar'),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Admin$PageListUsers$DeleteAccount(user.Y))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('close')
						]))
				]));
		var activationButton = A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list-buttons'),
					$elm$html$Html$Attributes$title('Ativar Cadastro'),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Admin$PageListUsers$ActivateAccount(user.Y))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('done')
						]))
				]));
		var _v0 = _Utils_Tuple2(
			_List_Nil,
			_List_fromArray(
				[activationButton, deleteButton]));
		var onClickMsg = _v0.a;
		var buttons = _v0.b;
		return A2(
			$elm$html$Html$li,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('inactive-user-item'),
						A2($elm$html$Html$Attributes$style, 'margin-left', '12px'),
						$elm$html$Html$Attributes$classList(_List_Nil)
					]),
				onClickMsg),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description'),
							A2($elm$html$Html$Attributes$style, 'margin-right', '40px')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(user.c7)
										])),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$App$Tools$FormHelpers$dateFormat(
												A2(
													$elm$core$Maybe$withDefault,
													$elm$time$Time$millisToPosix(0),
													user.iW)))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					buttons)
				]));
	});
var $author$project$App$Page$Admin$PageListUsers$view = F2(
	function (st, model) {
		var viewUsers = ($elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (u) {
					return _Utils_eq(u.e3, $elm$core$Maybe$Nothing);
				},
				model.cO)) > 0) ? A2(
			$elm$core$List$map,
			$author$project$App$Page$Admin$PageListUsers$viewUserListRow(model),
			A2(
				$elm$core$List$sortWith,
				$author$project$App$Page$Admin$PageListUsers$comparePosixOfUser,
				A2(
					$elm$core$List$filter,
					function (u) {
						return _Utils_eq(u.e3, $elm$core$Maybe$Nothing);
					},
					model.cO))) : _List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Nenhum item encontrado.')
					]))
			]);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Contas para ativar')
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								viewUsers)
							]))
					]))
			]);
	});
var $author$project$App$Page$Admin$PageReevaluation$BackToFinished = {$: 1};
var $author$project$App$Page$Admin$PageReevaluation$FinishReevaluation = {$: 4};
var $author$project$App$Page$Admin$PageReevaluation$SendToReview = {$: 3};
var $elm$html$Html$Attributes$controls = $elm$html$Html$Attributes$boolProperty('controls');
var $author$project$App$Page$Admin$PageReevaluation$ToggleDialog = {$: 2};
var $author$project$App$Page$Admin$PageReevaluation$UpdateReviewMessage = function (a) {
	return {$: 5, a: a};
};
var $elm$html$Html$Attributes$cols = function (n) {
	return A2(
		_VirtualDom_attribute,
		'cols',
		$elm$core$String$fromInt(n));
};
var $author$project$App$Components$Dialog$emptyConfig = function (_class) {
	return {e5: $elm$core$Maybe$Nothing, e6: $elm$core$Maybe$Nothing, fc: $elm$core$Maybe$Nothing, fe: _class, ft: $elm$core$Maybe$Nothing, fu: $elm$core$Maybe$Nothing, gY: $elm$core$Maybe$Nothing, f1: ''};
};
var $elm$html$Html$Attributes$rows = function (n) {
	return A2(
		_VirtualDom_attribute,
		'rows',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$App$Components$Dialog$viewWithConfig = function (config) {
	var footerAttrs = A2($elm$core$Maybe$withDefault, _List_Nil, config.fu);
	var closeEvt = function () {
		var _v0 = config.fc;
		if (!_v0.$) {
			var msg = _v0.a;
			return $elm$html$Html$Events$onClick(msg);
		} else {
			return A2($elm$html$Html$Attributes$attribute, '', '');
		}
	}();
	var footer = A2(
		$elm$core$Maybe$withDefault,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[closeEvt]),
				_List_Nil)
			]),
		config.ft);
	var bodyAttrs = A2($elm$core$Maybe$withDefault, _List_Nil, config.e6);
	var body = A2(
		$elm$core$Maybe$withDefault,
		_List_fromArray(
			[
				$elm$html$Html$text('')
			]),
		config.e5);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('dialog'),
				$elm$html$Html$Attributes$class(config.fe)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(config.f1)
									]))
							])),
						A2($elm$html$Html$div, bodyAttrs, body),
						A2($elm$html$Html$div, footerAttrs, footer)
					]))
			]));
};
var $author$project$App$Components$Dialog$view = function (mConfig) {
	if (mConfig.$ === 1) {
		return $elm$html$Html$text('');
	} else {
		var config = mConfig.a;
		return $author$project$App$Components$Dialog$viewWithConfig(config);
	}
};
var $author$project$App$Page$Admin$PageReevaluation$dialogFromModel = function (model) {
	var header = _List_Nil;
	var footer = _List_fromArray(
		[
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cancel-button'),
					$elm$html$Html$Events$onClick($author$project$App$Page$Admin$PageReevaluation$ToggleDialog)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Cancelar')
				])),
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$App$Page$Admin$PageReevaluation$SendToReview)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Devolver')
				]))
		]);
	var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
	var body = _List_fromArray(
		[
			A2(
			$elm$html$Html$textarea,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$cols(50),
					$elm$html$Html$Attributes$rows(5),
					$elm$html$Html$Attributes$value(model.eN),
					$elm$html$Html$Events$onInput($author$project$App$Page$Admin$PageReevaluation$UpdateReviewMessage)
				]),
			_List_Nil)
		]);
	var completeConfig = _Utils_update(
		config,
		{
			e5: $elm$core$Maybe$Just(body),
			ft: $elm$core$Maybe$Just(footer),
			gY: $elm$core$Maybe$Just(header),
			f1: 'Motivo da Reavaliação'
		});
	return model.dC ? $author$project$App$Components$Dialog$view(
		$elm$core$Maybe$Just(completeConfig)) : $elm$html$Html$text('');
};
var $elm$html$Html$source = _VirtualDom_node('source');
var $author$project$App$Data$VideoRepository$statementVideoUrl = F2(
	function (model, stmtId) {
		return 'https://' + (model.f3 + ('/processed/' + (stmtId + '.mp4')));
	});
var $elm$html$Html$video = _VirtualDom_node('video');
var $author$project$App$Page$Admin$PageReevaluation$viewDocumentContent = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Page$Admin$PageReevaluation$viewCPTContent = F3(
	function (mDepIdx, fullStmt, now) {
		var cptDoc = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Document$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					function (_v1) {
						var i = _v1.a;
						var d = _v1.b;
						return d;
					},
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var d = _v0.b;
							return _Utils_eq(d.jJ, fullStmt.g.e.as) && (d.b7 === 5);
						},
						$elm$core$Dict$toList(fullStmt.an)))));
		return $author$project$App$Data$Id$uuidIsValid(cptDoc.as) ? $author$project$App$Page$Admin$PageReevaluation$viewDocumentContent(cptDoc) : A3($author$project$App$Legacy$Doctor$ResultLayout$Common$viewCPTLayout, mDepIdx, fullStmt, now);
	});
var $author$project$App$Page$Admin$PageReevaluation$viewDoc = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Components$Chatbox$KeyDown = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Components$Chatbox$UpdateText = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Components$Chatbox$messageBubble = F2(
	function (pId, statementMessage) {
		var senderName = _Utils_eq(pId, statementMessage.eS) ? $elm$html$Html$text('') : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('msg-info-name')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(statementMessage.jX)
				]));
		var multilineClass = _Utils_eq(pId, statementMessage.eS) ? $elm$html$Html$Attributes$class('') : $elm$html$Html$Attributes$class('multiline');
		var marginTop = _Utils_eq(pId, statementMessage.eS) ? $elm$html$Html$Attributes$class('') : A2($elm$html$Html$Attributes$style, 'margin-top', '12px');
		var classText = _Utils_eq(pId, statementMessage.eS) ? 'chat-bubble chat-me' : 'chat-bubble chat-you';
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(classText)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[multilineClass]),
					_List_fromArray(
						[
							senderName,
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[marginTop]),
							_List_fromArray(
								[
									$elm$html$Html$text(statementMessage.jl)
								]))
						]))
				]));
	});
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $author$project$App$Components$Chatbox$onKeyDown = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'keydown',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$keyCode));
};
var $author$project$App$Components$Chatbox$toLocalDate = function (oldTime) {
	return $elm$time$Time$millisToPosix(
		$elm$time$Time$posixToMillis(oldTime) - (3600000 * 3));
};
var $author$project$App$Components$Chatbox$view = function (model) {
	var fisrtMessage = A2(
		$elm$core$Maybe$withDefault,
		$author$project$App$Data$StatementMessage$emptyModel,
		$elm$core$List$head(
			$elm$core$List$reverse(model.co)));
	var spanText = ($elm$core$List$length(model.co) > 0) ? A3(
		$elm$core$String$slice,
		0,
		16,
		$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(
			$author$project$App$Components$Chatbox$toLocalDate(fisrtMessage.iW))) : 'Nenhuma mensagem';
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('chat-container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('chat-box')
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$author$project$App$Components$Chatbox$messageBubble(model.ed),
						model.co),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('chat-conversation-start')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(spanText)
										]))
								]))
						]))),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('chat-write')
					]),
				_List_fromArray(
					[
						model.dg ? A2(
						$elm$html$Html$textarea,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value(model.az),
								$elm$html$Html$Events$onInput($author$project$App$Components$Chatbox$UpdateText),
								$author$project$App$Components$Chatbox$onKeyDown($author$project$App$Components$Chatbox$KeyDown)
							]),
						_List_Nil) : $elm$html$Html$text(''),
						model.dg ? A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('chat-send'),
								$elm$html$Html$Events$onClick(
								$author$project$App$Components$Chatbox$SendMessage(model.az))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Enviar')
							])) : $elm$html$Html$text('')
					]))
			]));
};
var $author$project$App$Page$Admin$PageReevaluation$viewPersonICD = F4(
	function (now, mDepIdx, stmt, sharedState) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(stmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					A3(
						$elm$core$Dict$foldr,
						F3(
							function (cat, icd, html) {
								return A2(
									$elm$core$List$append,
									html,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('chip'),
													$elm$html$Html$Attributes$title(icd.am)
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(cat)
														]))
												]))
										]));
							}),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Data$Person$fullPersonName(stmt.dw))
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(
											A2($author$project$App$Data$Person$ageOnDate, now, stmt.dw)) + ' anos')
									])),
								sharedState.ia.h8.cJ.gX ? A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											if (mDepIdx.$ === 1) {
												var i = mDepIdx.a;
												return 'Dependente ' + $elm$core$String$fromInt(i + 1);
											} else {
												return _Utils_eq(stmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
											}
										}())
									])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
							]),
						A3(
							$elm$core$Dict$foldr,
							F3(
								function (_v0, qCatDict, catDict) {
									return A3(
										$elm$core$Dict$foldl,
										F3(
											function (cat, icd, catDictInner) {
												return A3($elm$core$Dict$insert, cat, icd, catDictInner);
											}),
										catDict,
										qCatDict);
								}),
							$elm$core$Dict$empty,
							stmt.fx)))
				]));
	});
var $author$project$App$Page$Admin$PageReevaluation$viewICDSection = F2(
	function (sharedState, model) {
		var chatView = $author$project$App$Components$Chatbox$view;
		var bearerPersonIcd = (sharedState.ia.h8.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A4($author$project$App$Page$Admin$PageReevaluation$viewPersonICD, model.ey, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g, sharedState)
			]);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tabs ')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Dados por Pessoa')
									])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('material-icons')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('print')
													]))
											]))
									]))
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Admin$PageReevaluation$ChatBoxMsg,
						chatView(model.aM)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('person-icds')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_Nil,
								A3(
									$elm$core$Dict$foldl,
									F3(
										function (i, dep, html) {
											return A2(
												$elm$core$List$append,
												html,
												_List_fromArray(
													[
														A4(
														$author$project$App$Page$Admin$PageReevaluation$viewPersonICD,
														model.ey,
														$author$project$App$Data$Statement$Dependent(i),
														dep,
														sharedState)
													]));
										}),
									bearerPersonIcd,
									sharedState.ia.h8.fh))
							]))
					]))
			]);
	});
var $elm$html$Html$pre = _VirtualDom_node('pre');
var $author$project$App$Legacy$Doctor$ResultLayout$Hnsg$getAnswerForTemplateId = F2(
	function (answers, tId) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Answer$emptyModel,
			A2($elm$core$Dict$get, tId, answers));
	});
var $author$project$App$Legacy$Doctor$ResultLayout$Hnsg$getQuestionForQuestionId = F2(
	function (questions, tId) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Question$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (q) {
						return _Utils_eq(q.as, tId);
					},
					questions)));
	});
var $author$project$App$Legacy$Doctor$ResultLayout$Hnsg$viewDoctorObservations = F2(
	function (stmt, personSection) {
		var templates = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (s) {
					return $elm$core$List$concat(
						A2(
							$elm$core$List$map,
							function (q) {
								return A2(
									$elm$core$List$map,
									function (t) {
										return _Utils_Tuple3(t.jh, t.as, t.bh);
									},
									q.bY);
							},
							s.eI));
				},
				personSection));
		var questions = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (s) {
					return A2(
						$elm$core$List$map,
						function (q) {
							return q.hG;
						},
						s.eI);
				},
				personSection));
		return A2(
			$elm$core$List$indexedMap,
			F2(
				function (i, _v2) {
					var label = _v2.a;
					var id = _v2.b;
					var qid = _v2.c;
					var question = A2($author$project$App$Legacy$Doctor$ResultLayout$Hnsg$getQuestionForQuestionId, questions, qid);
					var answer = A2($author$project$App$Legacy$Doctor$ResultLayout$Hnsg$getAnswerForTemplateId, stmt.aZ, id);
					return A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(answer.jx)
									]))
							]));
				}),
			A2(
				$elm$core$List$filter,
				function (_v1) {
					var label = _v1.a;
					var id = _v1.b;
					var qid = _v1.c;
					var answer = A2($author$project$App$Legacy$Doctor$ResultLayout$Hnsg$getAnswerForTemplateId, stmt.aZ, id);
					return answer.dF || ($elm$core$String$length(answer.T) > 0);
				},
				$elm$core$List$reverse(
					_Utils_ap(
						A2(
							$elm$core$List$map,
							function (_v0) {
								var label = _v0.a;
								var id = _v0.b;
								var qid = _v0.c;
								return _Utils_Tuple3('', id, qid);
							},
							A2(
								$elm$core$List$take,
								1,
								$elm$core$List$reverse(templates))),
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							$elm$core$List$tail(
								$elm$core$List$reverse(templates)))))));
	});
var $author$project$App$Legacy$Doctor$ResultLayout$Hnsg$viewMedicalDocument = function (fullStmt) {
	var title = A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src('/assets/images/logo-hnsg.png'),
								A2($elm$html$Html$Attributes$style, 'width', '80px')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$h1,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'margin-left', '10px')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('TELEMEDICINA HOSPITAL NOSSA SENHORA DAS GRAÇAS')
							]))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Identificação do paciente: ')
							]))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Nome: ')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Person$fullPersonName(fullStmt.g.dw))
							]))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Data de Nascimento: ')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Tools$FormHelpers$dateFormat(fullStmt.g.dw.al))
							]))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Documento: ')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Mask$cpf(fullStmt.g.dw.i1))
							]))
					]))
			]),
		A3(
			$elm$core$Dict$foldl,
			F3(
				function (idx, dep, html) {
					var idxStr = $elm$core$String$fromInt(idx + 1);
					return A2(
						$elm$core$List$append,
						html,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('DEP 0' + (idxStr + ':'))
											])),
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Person$fullPersonName(dep.dw))
											]))
									]))
							]));
				}),
			_List_Nil,
			fullStmt.fh));
	var answers = _List_fromArray(
		[
			A2(
			$elm$html$Html$pre,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$table,
					_List_Nil,
					A2($author$project$App$Legacy$Doctor$ResultLayout$Hnsg$viewDoctorObservations, fullStmt.g, fullStmt.gH))
				]))
		]);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('doctor-result-layout CentauroOn')
			]),
		$elm$core$List$concat(
			_List_fromArray(
				[
					title,
					answers,
					_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'text-align', 'right')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'Curitiba, ' + $author$project$App$Tools$FormHelpers$dateFormat(fullStmt.g.e.f2))
							]))
					])
				])));
};
var $author$project$App$Legacy$Doctor$ResultLayout$Common$viewMedicalDocumentLayout = function (fullStmt) {
	var _v0 = fullStmt.cJ.gF;
	if (_v0 === 8) {
		return $author$project$App$Legacy$Doctor$ResultLayout$Hnsg$viewMedicalDocument(fullStmt);
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$App$Page$Admin$PageReevaluation$viewMedicalDocumentContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewMedicalDocumentLayout(fullStmt);
};
var $elm$html$Html$col = _VirtualDom_node('col');
var $elm$html$Html$colgroup = _VirtualDom_node('colgroup');
var $author$project$App$Data$QuestionSection$emptyModel = {
	a0: 0,
	as: $author$project$App$Data$Id$uuidNil,
	bB: 0,
	hl: '',
	cr: $elm$core$Maybe$Nothing,
	hp: 0,
	eY: $author$project$App$Data$Id$uuidNil,
	f2: $elm$time$Time$millisToPosix(0)
};
var $author$project$App$Data$FullQuestionSection$emptyModel = {eI: _List_Nil, hX: $author$project$App$Data$QuestionSection$emptyModel};
var $author$project$App$Page$Admin$PageReevaluation$getAnswerForTemplateId = F3(
	function (depIdx, model, tId) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Answer$emptyModel,
			A2(
				$elm$core$Dict$get,
				tId,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Dict$empty,
					A2(
						$elm$core$Dict$get,
						$author$project$App$Data$Statement$depIndexToInt(depIdx),
						model.aZ))));
	});
var $author$project$App$Page$Admin$PageReevaluation$viewICD = F4(
	function (ansTemp, catCode, mIdx, icd) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('chip'),
					$elm$html$Html$Attributes$title(icd.am)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(icd.a0)
						]))
				]));
	});
var $author$project$App$Page$Admin$PageReevaluation$viewICDs = F3(
	function (icds, ansTemp, mIdx) {
		return A3(
			$elm$core$Dict$foldr,
			F3(
				function (catCode, icd, result) {
					return A2(
						$elm$core$List$append,
						result,
						_List_fromArray(
							[
								A4($author$project$App$Page$Admin$PageReevaluation$viewICD, ansTemp, catCode, mIdx, icd)
							]));
				}),
			_List_Nil,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Dict$empty,
				A2($elm$core$Dict$get, ansTemp.as, icds)));
	});
var $author$project$App$Page$Admin$PageReevaluation$viewBMI = F4(
	function (sharedState, model, depIdx, stmt) {
		var weightTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 10) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.jM);
		var personQuestion = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestion$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (q) {
						return $author$project$App$Data$Id$uuidIsValid(q.hG.as);
					},
					A2(
						$elm$core$List$map,
						function (_v0) {
							var s = _v0.b;
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (q) {
											return _Utils_eq(q.hG.as, weightTemplate.bh);
										},
										s.eI)));
						},
						$elm$core$Dict$toList(model.cA)))));
		var heightTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 20) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.jM);
		var bmiTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 1000) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.gH);
		var doctorQuestion = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestion$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (q) {
						return $author$project$App$Data$Id$uuidIsValid(q.hG.as);
					},
					A2(
						$elm$core$List$map,
						function (s) {
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (q) {
											return _Utils_eq(q.hG.as, bmiTemplate.bh);
										},
										s.eI)));
						},
						model.P.gH))));
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no DPS')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatCompanyBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Admin$PageReevaluation$viewICDs, stmt.fx, weightTemplate, depIdx))
							]))
					])),
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no Questionário')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatPersonBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Admin$PageReevaluation$viewICDs, stmt.fx, heightTemplate, depIdx))
							]))
					])),
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no Atendimento')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatDoctorBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Admin$PageReevaluation$viewICDs, stmt.fx, bmiTemplate, depIdx))
							]))
					]))
			]);
	});
var $author$project$App$Page$Admin$PageReevaluation$maskDecimal = F2(
	function (precision, value) {
		return A3($elm$core$String$replace, '.', ',', value);
	});
var $author$project$App$Page$Admin$PageReevaluation$maskHeight = function (cm) {
	var len = $elm$core$String$length(cm);
	var subM = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cm)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cm) : cm);
	var h = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(cm));
	var m = (h >= 100) ? A3($elm$core$String$slice, 0, -2, cm) : '0';
	return (h > 0) ? (m + (',' + subM)) : '';
};
var $author$project$App$Page$Admin$PageReevaluation$maskPressure = function (str) {
	return function (p) {
		return (p > 0) ? $elm$core$String$fromInt(p) : '';
	}(
		function (p) {
			return (p > 999) ? (p / 10) : (p / 1.0);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(str))) | 0);
};
var $author$project$App$Page$Admin$PageReevaluation$maskWeight = function (wStr) {
	return function (w) {
		return (w > 0) ? $elm$core$String$fromInt(w) : '';
	}(
		function (w) {
			return w / 1000;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(wStr))) | 0);
};
var $author$project$App$Page$Admin$PageReevaluation$setupMask = F2(
	function (template, answer) {
		var _v0 = template.jj;
		if (_v0.$ === 1) {
			return answer.T;
		} else {
			switch (_v0.a.$) {
				case 3:
					var precision = _v0.a.a;
					return A2($author$project$App$Page$Admin$PageReevaluation$maskDecimal, precision, answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 0:
					var _v1 = _v0.a;
					return $author$project$App$Page$Admin$PageReevaluation$maskWeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 1:
					var _v2 = _v0.a;
					return $author$project$App$Page$Admin$PageReevaluation$maskHeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				default:
					var _v3 = _v0.a;
					return $author$project$App$Page$Admin$PageReevaluation$maskPressure(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
			}
		}
	});
var $author$project$App$Page$Admin$PageReevaluation$viewQuestion = F5(
	function (depIdx, question, sharedState, model, doctorQuestion) {
		var stmt = function () {
			if (!depIdx.$) {
				return sharedState.ia.h8.g;
			} else {
				var idx = depIdx.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, sharedState.ia.h8.fh));
			}
		}();
		var showCids = !A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		return A2(
			$elm$core$List$map,
			function (t) {
				var answer = A3($author$project$App$Page$Admin$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
				var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
				return (show || (t.ay >= 1000)) ? A2(
					$elm$html$Html$tr,
					_List_Nil,
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(question.hG.bP)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(t.jh)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($author$project$App$Page$Admin$PageReevaluation$setupMask, t, answer))
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(answer.jx)
									]))
							]),
						showCids ? _List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('chips chips-initial input-field')
											]),
										A3($author$project$App$Page$Admin$PageReevaluation$viewICDs, stmt.fx, t, depIdx))
									]))
							]) : _List_Nil)) : A2($elm$html$Html$span, _List_Nil, _List_Nil);
			},
			A2(
				$elm$core$List$filter,
				function (t) {
					var answer = A3($author$project$App$Page$Admin$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
					var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
					return show;
				},
				question.bY));
	});
var $author$project$App$Page$Admin$PageReevaluation$viewEditStatementSectionWithHeader = F5(
	function (depIdx, sharedState, model, fullQuestionSection, doctorQuestionSection) {
		var showCids = !A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var sectionName = function () {
			if (sharedState.ia.h8.b4.iR === 'Extramed') {
				var _v0 = fullQuestionSection.hX.a0;
				switch (_v0) {
					case 2:
						return 'Questionário';
					case 1:
						return 'Declaração';
					case 3:
						return 'Atendimento';
					default:
						return 'Desconhecido';
				}
			} else {
				return A2($elm$core$String$left, 50, fullQuestionSection.hX.hl) + '...';
			}
		}();
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '90%')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$colgroup,
					_List_Nil,
					_List_fromArray(
						[
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil)
						])),
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(sectionName)
											])),
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$colspan(2)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Resposta')
											]))
									]),
								showCids ? _List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Obs')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('CIDs')
											]))
									]) : _List_Nil))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, q) {
									return A5(
										$author$project$App$Page$Admin$PageReevaluation$viewQuestion,
										depIdx,
										q,
										sharedState,
										model,
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$FullQuestion$emptyModel,
											$elm$core$List$head(
												A2($elm$core$List$drop, i, doctorQuestionSection.eI))));
								}),
							A2(
								$elm$core$List$sortBy,
								function (sec) {
									return sec.hG.bB;
								},
								A2(
									$elm$core$List$filter,
									function (q) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (t) {
													var answer = A3($author$project$App$Page$Admin$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
													var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
													return show;
												},
												q.bY)) > 0;
									},
									fullQuestionSection.eI)))))
				]));
	});
var $author$project$App$Page$Admin$PageReevaluation$viewEditStatementSectionWithoutHeader = F5(
	function (depIdx, sharedState, model, fullQuestionSection, doctorQuestionSection) {
		return $elm$core$List$concat(
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (i, q) {
						return A5(
							$author$project$App$Page$Admin$PageReevaluation$viewQuestion,
							depIdx,
							q,
							sharedState,
							model,
							A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2($elm$core$List$drop, i, doctorQuestionSection.eI))));
					}),
				A2(
					$elm$core$List$sortBy,
					function (sec) {
						return sec.hG.bB;
					},
					A2(
						$elm$core$List$filter,
						function (q) {
							return $elm$core$List$length(
								A2(
									$elm$core$List$filter,
									function (t) {
										var answer = A3($author$project$App$Page$Admin$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
										var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
										return show;
									},
									q.bY)) > 0;
						},
						fullQuestionSection.eI))));
	});
var $author$project$App$Page$Admin$PageReevaluation$viewPersonDetails = F3(
	function (sharedState, depIdx, editStmt) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(editStmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$fullPersonName(editStmt.dw))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$formatDocument(editStmt.dw.i1))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Tools$FormHelpers$dateFormat(editStmt.dw.al))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									function (str) {
										return str + ' anos';
									}(
										$elm$core$String$fromInt(
											A2(
												$author$project$App$Data$Person$ageOnDate,
												$author$project$App$Service$ApiWebSocket$getTimestamp(sharedState._),
												editStmt.dw))))
								])),
							sharedState.ia.h8.cJ.gX ? A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									function () {
										if (depIdx.$ === 1) {
											var i = depIdx.a;
											return 'Dependente ' + $elm$core$String$fromInt(i + 1);
										} else {
											return _Utils_eq(editStmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
										}
									}())
								])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
						]))
				]));
	});
var $author$project$App$Page$Admin$PageReevaluation$viewPersonAnswers = F4(
	function (sharedState, model, depIdx, stmt) {
		var showPerson = sharedState.ia.h8.b4.iR === 'Extramed';
		var showDoctorQuestions = (sharedState.ia.h8.b4.iR === 'Extramed') || (sharedState.ia.h8.b4.iR === 'Centauro-ON');
		var showBMI = sharedState.ia.h8.b4.iR === 'Extramed';
		var doctorSection = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestionSection$emptyModel,
			$elm$core$List$head(
				$elm$core$List$reverse(sharedState.ia.h8.gH)));
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$ul,
							_List_Nil,
							_List_fromArray(
								[
									A3($author$project$App$Page$Admin$PageReevaluation$viewPersonDetails, sharedState, depIdx, stmt)
								]))
						])),
					showBMI ? A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '100%')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Dados Antropométricos')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Valor')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('CIDs')
												]))
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							A4($author$project$App$Page$Admin$PageReevaluation$viewBMI, sharedState, model, depIdx, stmt))
						])) : $elm$html$Html$text('')
				]),
			_Utils_ap(
				showBMI ? _List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '100%')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Declaração')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Marcado')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Resposta')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Observação Médica')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('CID')
													]))
											]))
									])),
								A2(
								$elm$html$Html$tbody,
								_List_Nil,
								$elm$core$List$concat(
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (i, s) {
												return A5(
													$author$project$App$Page$Admin$PageReevaluation$viewEditStatementSectionWithoutHeader,
													depIdx,
													sharedState,
													model,
													s,
													A2(
														$elm$core$Maybe$withDefault,
														$author$project$App$Data$FullQuestionSection$emptyModel,
														$elm$core$List$head(
															A2($elm$core$List$drop, i, sharedState.ia.h8.bs))));
											}),
										A2(
											$elm$core$List$filter,
											function (s) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (q) {
															return $elm$core$List$length(
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var answer = A3($author$project$App$Page$Admin$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																		var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
																		return show;
																	},
																	q.bY)) > 0;
														},
														A2(
															$elm$core$List$sortBy,
															function (sec) {
																return sec.hG.bB;
															},
															s.eI))) > 0;
											},
											sharedState.ia.h8.bs))))
							]))
					]) : _List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '100%')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Declaração')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Marcado')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Resposta')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Observação Médica')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('CID')
													]))
											]))
									])),
								A2(
								$elm$html$Html$tbody,
								_List_Nil,
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, s) {
											return A5(
												$author$project$App$Page$Admin$PageReevaluation$viewEditStatementSectionWithHeader,
												depIdx,
												sharedState,
												model,
												s,
												A2(
													$elm$core$Maybe$withDefault,
													$author$project$App$Data$FullQuestionSection$emptyModel,
													$elm$core$List$head(
														A2($elm$core$List$drop, i, sharedState.ia.h8.bs))));
										}),
									A2(
										$elm$core$List$filter,
										function (s) {
											return $elm$core$List$length(
												A2(
													$elm$core$List$filter,
													function (q) {
														return $elm$core$List$length(
															A2(
																$elm$core$List$filter,
																function (t) {
																	var answer = A3($author$project$App$Page$Admin$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																	var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
																	return show;
																},
																q.bY)) > 0;
													},
													A2(
														$elm$core$List$sortBy,
														function (sec) {
															return sec.hG.bB;
														},
														s.eI))) > 0;
										},
										sharedState.ia.h8.bs)))
							]))
					]),
				_Utils_ap(
					_List_fromArray(
						[
							showBMI ? A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '100%')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$thead,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Questionário')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Marcado')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Resposta')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Observação Médica')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('CID')
														]))
												]))
										])),
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											F2(
												function (i, s) {
													return A5(
														$author$project$App$Page$Admin$PageReevaluation$viewEditStatementSectionWithoutHeader,
														depIdx,
														sharedState,
														model,
														s,
														A2(
															$elm$core$Maybe$withDefault,
															$author$project$App$Data$FullQuestionSection$emptyModel,
															$elm$core$List$head(
																A2($elm$core$List$drop, i, sharedState.ia.h8.jM))));
												}),
											A2(
												$elm$core$List$filter,
												function (s) {
													return $elm$core$List$length(
														A2(
															$elm$core$List$filter,
															function (q) {
																return $elm$core$List$length(
																	A2(
																		$elm$core$List$filter,
																		function (t) {
																			var answer = A3($author$project$App$Page$Admin$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																			var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
																			return show;
																		},
																		q.bY)) > 0;
															},
															A2(
																$elm$core$List$sortBy,
																function (sec) {
																	return sec.hG.bB;
																},
																s.eI))) > 0;
												},
												sharedState.ia.h8.jM))))
								])) : (showPerson ? A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '100%')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$thead,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Questionário')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Marcado')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Resposta')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Observação Médica')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('CID')
														]))
												]))
										])),
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (i, s) {
												return A5(
													$author$project$App$Page$Admin$PageReevaluation$viewEditStatementSectionWithHeader,
													depIdx,
													sharedState,
													model,
													s,
													A2(
														$elm$core$Maybe$withDefault,
														$author$project$App$Data$FullQuestionSection$emptyModel,
														$elm$core$List$head(
															A2($elm$core$List$drop, i, sharedState.ia.h8.jM))));
											}),
										A2(
											$elm$core$List$filter,
											function (s) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (q) {
															return $elm$core$List$length(
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var answer = A3($author$project$App$Page$Admin$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																		var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																		return show;
																	},
																	q.bY)) > 0;
														},
														A2(
															$elm$core$List$sortBy,
															function (sec) {
																return sec.hG.bB;
															},
															s.eI))) > 0;
											},
											sharedState.ia.h8.jM)))
								])) : $elm$html$Html$text(''))
						]),
					function () {
						if (showDoctorQuestions) {
							return showBMI ? A2(
								$elm$core$List$cons,
								A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'width', '100%')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$colgroup,
											_List_Nil,
											_List_fromArray(
												[
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil)
												])),
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$tr,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$th,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$colspan(5)
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Atendimento')
																]))
														]))
												]))
										])),
								$elm$core$List$concat(
									A2(
										$elm$core$List$map,
										function (s) {
											return A5($author$project$App$Page$Admin$PageReevaluation$viewEditStatementSectionWithoutHeader, depIdx, sharedState, model, s, s);
										},
										A2(
											$elm$core$List$filter,
											function (s) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (q) {
															return ($elm$core$List$length(
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var answer = A3($author$project$App$Page$Admin$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																		var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																		return show;
																	},
																	q.bY)) > 0) && (!A2(
																$elm$core$List$any,
																function (t) {
																	return (t.ay === 10) || (t.ay === 20);
																},
																q.bY));
														},
														A2(
															$elm$core$List$sortBy,
															function (sec) {
																return sec.hG.bB;
															},
															s.eI))) > 0;
											},
											sharedState.ia.h8.gH)))) : A2(
								$elm$core$List$map,
								function (s) {
									return A5($author$project$App$Page$Admin$PageReevaluation$viewEditStatementSectionWithHeader, depIdx, sharedState, model, s, s);
								},
								A2(
									$elm$core$List$filter,
									function (s) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (q) {
													return $elm$core$List$length(
														A2(
															$elm$core$List$filter,
															function (t) {
																var answer = A3($author$project$App$Page$Admin$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																return show;
															},
															q.bY)) > 0;
												},
												A2(
													$elm$core$List$sortBy,
													function (sec) {
														return sec.hG.bB;
													},
													s.eI))) > 0;
									},
									sharedState.ia.h8.gH));
						} else {
							var question = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(doctorSection.eI));
							var template = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$AnswerTemplate$emptyModel,
								$elm$core$List$head(question.bY));
							var answer = A3($author$project$App$Page$Admin$PageReevaluation$getAnswerForTemplateId, depIdx, model, template.as);
							return (answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0))) ? _List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Evoluções médicas:')
										])),
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(answer.T)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Atestado Médico:')
										])),
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(answer.jx)
										]),
									_List_Nil)
								]) : _List_Nil;
						}
					}())));
	});
var $author$project$App$Page$Admin$PageReevaluation$viewQuestions = F2(
	function (model, sharedState) {
		var depsPersonAnswers = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$second,
				$elm$core$Dict$toList(
					A2(
						$elm$core$Dict$map,
						F2(
							function (idx, dep) {
								return A4(
									$author$project$App$Page$Admin$PageReevaluation$viewPersonAnswers,
									sharedState,
									model,
									$author$project$App$Data$Statement$depIndexFromInt(idx),
									dep);
							}),
						sharedState.ia.h8.fh))));
		var bearerPersonAnswers = (!(sharedState.ia.h8.g.e.j_ === 2)) ? A4($author$project$App$Page$Admin$PageReevaluation$viewPersonAnswers, sharedState, model, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g) : _List_Nil;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('questions')
				]),
			_Utils_ap(
				bearerPersonAnswers,
				_Utils_ap(
					depsPersonAnswers,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Conclusões finais do atendimento:')
								])),
							A2(
							$elm$html$Html$textarea,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(model.P.g.e.jx)
								]),
							_List_Nil)
						]))));
	});
var $author$project$App$Page$Admin$PageReevaluation$SelectDetailsTab = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Admin$PageReevaluation$TabCPT = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Admin$PageReevaluation$TabDocument = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Admin$PageReevaluation$viewDocumentTabNumered = F4(
	function (currentTab, idx, doc, documentTabs) {
		var index = $elm$core$List$length(documentTabs) + 1;
		return A2(
			$elm$core$List$append,
			documentTabs,
			function () {
				var _v0 = doc.dr;
				switch (_v0) {
					case 'video/webm':
						return _List_fromArray(
							[
								$elm$html$Html$text('')
							]);
					case 'video/mp4':
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Admin$PageReevaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Admin$PageReevaluation$SelectDetailsTab(
											$author$project$App$Page$Admin$PageReevaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										'Video' + $elm$core$String$fromInt(index))
									]))
							]);
					default:
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Admin$PageReevaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Admin$PageReevaluation$SelectDetailsTab(
											$author$project$App$Page$Admin$PageReevaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											var _v1 = doc.b7;
											switch (_v1) {
												case 1:
													return 'Parecer Antigo' + $elm$core$String$fromInt(index);
												case 3:
													return 'Atestado' + $elm$core$String$fromInt(index);
												case 4:
													return 'Receita' + $elm$core$String$fromInt(index);
												default:
													return 'Anexo';
											}
										}())
									]))
							]);
				}
			}());
	});
var $author$project$App$Page$Admin$PageReevaluation$TabMedicalDocument = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Admin$PageReevaluation$viewResultTab = F5(
	function (previewTitle, typeHnsg, currentTab, idx, d) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Admin$PageReevaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Admin$PageReevaluation$SelectDetailsTab(
							$author$project$App$Page$Admin$PageReevaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(previewTitle)
					])),
				typeHnsg ? A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Admin$PageReevaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Admin$PageReevaluation$SelectDetailsTab(
							$author$project$App$Page$Admin$PageReevaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Pré-Visualizar Atestado')
					])) : A2($elm$html$Html$span, _List_Nil, _List_Nil)
			]);
	});
var $author$project$App$Page$Admin$PageReevaluation$viewQuestionsTabs = F5(
	function (fullStmt, viewCPT, typeHnsg, currentTab, docs) {
		var reportDocs = A2(
			$elm$core$List$sortWith,
			F2(
				function (a, b) {
					var _v8 = A2(
						$elm$core$Basics$compare,
						$elm$time$Time$posixToMillis(a.b.iW),
						$elm$time$Time$posixToMillis(b.b.iW));
					switch (_v8) {
						case 0:
							return 2;
						case 1:
							return 1;
						default:
							return 0;
					}
				}),
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v7, doc) {
							return doc.b7 === 1;
						}),
					docs)));
		var previewTitle = typeHnsg ? 'Pré-Visualizar Prontuário' : 'Pré-Visualizar Parecer';
		var resultTab = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$second,
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$map,
							A3($author$project$App$Page$Admin$PageReevaluation$viewResultTab, previewTitle, typeHnsg, currentTab),
							$elm$core$Dict$fromList(
								A2($elm$core$List$take, 1, reportDocs)))))));
		var oldResultsTab = A3(
			$elm$core$Dict$foldl,
			$author$project$App$Page$Admin$PageReevaluation$viewDocumentTabNumered(currentTab),
			_List_Nil,
			$elm$core$Dict$fromList(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(reportDocs))));
		var docsTabs = A3(
			$elm$core$Dict$foldl,
			F3(
				function (i, d, list) {
					var attachName = function () {
						var _v6 = d.dr;
						if (_v6 === 'video/mp4') {
							return 'Video';
						} else {
							return 'Anexo ' + $elm$core$String$fromInt(i);
						}
					}();
					return A2(
						$elm$core$List$append,
						list,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Admin$PageReevaluation$TabDocument(i)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Admin$PageReevaluation$SelectDetailsTab(
											$author$project$App$Page$Admin$PageReevaluation$TabDocument(i))),
										$elm$html$Html$Attributes$href('#')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(attachName)
									]))
							]));
				}),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'tab-selected',
									_Utils_eq(currentTab, $author$project$App$Page$Admin$PageReevaluation$TabCIDs))
								])),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Admin$PageReevaluation$SelectDetailsTab($author$project$App$Page$Admin$PageReevaluation$TabCIDs))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Dados do Atendimento')
						]))
				]),
			A2(
				$elm$core$Dict$filter,
				F2(
					function (_v5, d) {
						return d.b7 !== 3;
					}),
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v4, d) {
							return d.b7 !== 5;
						}),
					A2(
						$elm$core$Dict$filter,
						F2(
							function (_v3, d) {
								return d.b7 !== 1;
							}),
						$elm$core$Dict$fromList(
							_Utils_ap(
								A2(
									$elm$core$List$take,
									1,
									$elm$core$List$reverse(
										$elm$core$Dict$toList(
											A2(
												$elm$core$Dict$filter,
												F2(
													function (_v1, doc) {
														return doc.dr === 'video/mp4';
													}),
												docs)))),
								$elm$core$Dict$toList(
									A2(
										$elm$core$Dict$filter,
										F2(
											function (_v2, doc) {
												return (doc.dr !== 'video/webm') && (doc.dr !== 'video/mp4');
											}),
										docs))))))));
		var bearerCpt = (fullStmt.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Admin$PageReevaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Admin$PageReevaluation$SelectDetailsTab(
							$author$project$App$Page$Admin$PageReevaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('CPT Titular')
					]))
			]);
		var cptTabs = viewCPT ? A2(
			$elm$core$List$append,
			bearerCpt,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var idx = _v0.a;
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('#'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'tab-selected',
										_Utils_eq(
											currentTab,
											$author$project$App$Page$Admin$PageReevaluation$TabCPT(
												$author$project$App$Data$Statement$Dependent(idx))))
									])),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Admin$PageReevaluation$SelectDetailsTab(
									$author$project$App$Page$Admin$PageReevaluation$TabCPT(
										$author$project$App$Data$Statement$Dependent(idx))))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'CPT Dep ' + $elm$core$String$fromInt(idx + 1))
							]));
				},
				$elm$core$Dict$toList(fullStmt.fh))) : _List_Nil;
		var allTabs = _Utils_ap(
			docsTabs,
			_Utils_ap(
				resultTab,
				_Utils_ap(oldResultsTab, cptTabs)));
		return A2($elm$html$Html$div, _List_Nil, allTabs);
	});
var $author$project$App$Legacy$Doctor$ResultLayout$Helpers$hasICDsToDeclare = F2(
	function (fullStmt, test) {
		var filterFunc = function () {
			if (test.$ === 1) {
				return function (_v2) {
					return true;
				};
			} else {
				var t = test.a;
				return function (icd) {
					return A2(t, icd.dE, 1) || A2(t, icd.dE, 2);
				};
			}
		}();
		var icdsSize = function (stmt) {
			return $elm$core$Dict$size(
				$elm$core$Dict$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, icd) {
								return _Utils_Tuple2(i, icd);
							}),
						A2(
							$elm$core$List$filter,
							filterFunc,
							$author$project$App$Data$ICD$listFromDict(stmt.fx)))));
		};
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (_v0, dep, result) {
					return result || (icdsSize(dep) > 0);
				}),
			icdsSize(fullStmt.g) > 0,
			fullStmt.fh);
	});
var $author$project$App$Legacy$Doctor$ResultLayout$CentauroOn$viewPersonICDs = F3(
	function (mDepIdx, stmt, filterFuncWithoutIcds) {
		var title = function () {
			if (!mDepIdx.$) {
				return 'TITULAR';
			} else {
				var i = mDepIdx.a;
				return 'DEP 0' + $elm$core$String$fromInt(i + 1);
			}
		}();
		var companyIcds = A2(
			$elm$core$List$filter,
			function (icd) {
				return icd.dE === 1;
			},
			$author$project$App$Data$ICD$listFromDict(stmt.fx));
		var filterFunc = function (icd) {
			return A2(filterFuncWithoutIcds, icd, companyIcds);
		};
		var icds = $elm$core$Dict$toList(
			$elm$core$Dict$fromList(
				A2(
					$elm$core$List$filter,
					function (_v1) {
						var icd = _v1.b;
						return icd.g2;
					},
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, icd) {
								return _Utils_Tuple2(i, icd);
							}),
						A2(
							$elm$core$List$filter,
							filterFunc,
							$author$project$App$Data$ICD$listFromDict(stmt.fx))))));
		return A2(
			$elm$core$List$map,
			function (_v0) {
				var i = _v0.a;
				var icd = _v0.b;
				return A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$td,
							_List_Nil,
							_List_fromArray(
								[
									(!i) ? $elm$html$Html$text(title) : $elm$html$Html$text('')
								])),
							A2(
							$elm$html$Html$td,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(icd.am)
								]))
						]));
			},
			icds);
	});
var $author$project$App$Legacy$Doctor$ResultLayout$CentauroOn$viewDoctorStatement = function (fullStmt) {
	var title = A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('PARECER MÉDICO – PLANO COLETIVO POR ADESÃO')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('TITULAR: ')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Person$fullPersonName(fullStmt.g.dw))
							]))
					]))
			]),
		A3(
			$elm$core$Dict$foldl,
			F3(
				function (idx, dep, html) {
					var idxStr = $elm$core$String$fromInt(idx + 1);
					return A2(
						$elm$core$List$append,
						html,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('DEP 0' + (idxStr + ':'))
											])),
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Person$fullPersonName(dep.dw))
											]))
									]))
							]));
				}),
			_List_Nil,
			fullStmt.fh));
	var obs = ($elm$core$String$length(fullStmt.g.e.jx) > 0) ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'white-space', 'pre-wrap')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Observações médicas:')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(fullStmt.g.e.jx)
					]))
			])) : $elm$html$Html$text('');
	var isCompanyIcdWithList = F2(
		function (icd, list) {
			return icd.dE === 1;
		});
	var isCompanyIcd = function (icd) {
		return icd.dE === 1;
	};
	var notBeenAlreadyDeclared = F2(
		function (icd, cIcdList) {
			return (!A2(
				$elm$core$List$any,
				function (i) {
					return _Utils_eq(i.a0, icd.a0) && _Utils_eq(i.aV, icd.aV);
				},
				cIcdList)) && (!isCompanyIcd(icd));
		});
	var nonCompanyIcds = A2(
		$author$project$App$Legacy$Doctor$ResultLayout$Helpers$hasICDsToDeclare,
		fullStmt,
		$elm$core$Maybe$Just($elm$core$Basics$neq)) ? A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('2. PREEXISTÊNCIAS DIAGNOSTICADAS EM RELATÓRIO, EXAMES MÉDICOS E ENTREVISTA COM MÉDICO')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_Nil,
						A3($author$project$App$Legacy$Doctor$ResultLayout$CentauroOn$viewPersonICDs, $author$project$App$Data$Statement$Bearer, fullStmt.g, notBeenAlreadyDeclared))
					]))
			]),
		A3(
			$elm$core$Dict$foldl,
			F3(
				function (idx, dep, html) {
					return A2(
						$elm$core$List$append,
						html,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$table,
										_List_Nil,
										A3(
											$author$project$App$Legacy$Doctor$ResultLayout$CentauroOn$viewPersonICDs,
											$author$project$App$Data$Statement$Dependent(idx),
											dep,
											notBeenAlreadyDeclared))
									]))
							]));
				}),
			_List_Nil,
			fullStmt.fh)) : _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('2. PREEXISTÊNCIAS DIAGNOSTICADAS EM RELATÓRIO, EXAMES MÉDICOS E ENTREVISTA COM MÉDICO')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('NADA CONSTA')
				]))
		]);
	var finalResult = A2($author$project$App$Legacy$Doctor$ResultLayout$Helpers$hasICDsToDeclare, fullStmt, $elm$core$Maybe$Nothing) ? $elm$core$List$concat(
		_List_fromArray(
			[
				_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('PARECER FINAL')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Aplicar CPT para procedimentos de alta complexidade pela Agência Nacional de Saúde ' + 'Suplementar conforme diagnósticos declarados:')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$table,
							_List_Nil,
							A3(
								$author$project$App$Legacy$Doctor$ResultLayout$CentauroOn$viewPersonICDs,
								$author$project$App$Data$Statement$Bearer,
								fullStmt.g,
								F2(
									function (_v4, _v5) {
										return true;
									})))
						]))
				]),
				A3(
				$elm$core$Dict$foldl,
				F3(
					function (idx, dep, html) {
						return A2(
							$elm$core$List$append,
							html,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$table,
											_List_Nil,
											A3(
												$author$project$App$Legacy$Doctor$ResultLayout$CentauroOn$viewPersonICDs,
												$author$project$App$Data$Statement$Dependent(idx),
												dep,
												F2(
													function (_v6, _v7) {
														return true;
													})))
										]))
								]));
					}),
				_List_Nil,
				fullStmt.fh),
				_List_fromArray(
				[obs])
			])) : _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('PARECER FINAL')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Aplicar CPT para procedimentos de alta complexidade pela Agência Nacional de Saúde ' + 'Suplementar conforme diagnósticos declarados:')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Não se aplica')
				])),
			obs
		]);
	var companyIcds = A2(
		$author$project$App$Legacy$Doctor$ResultLayout$Helpers$hasICDsToDeclare,
		fullStmt,
		$elm$core$Maybe$Just($elm$core$Basics$eq)) ? A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('1. PREEXISTÊNCIAS DECLARADAS NA DECLARAÇÃO DE SAÚDE')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_Nil,
						A3($author$project$App$Legacy$Doctor$ResultLayout$CentauroOn$viewPersonICDs, $author$project$App$Data$Statement$Bearer, fullStmt.g, isCompanyIcdWithList))
					]))
			]),
		A3(
			$elm$core$Dict$foldl,
			F3(
				function (idx, dep, html) {
					return A2(
						$elm$core$List$append,
						html,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$table,
										_List_Nil,
										A3(
											$author$project$App$Legacy$Doctor$ResultLayout$CentauroOn$viewPersonICDs,
											$author$project$App$Data$Statement$Dependent(idx),
											dep,
											isCompanyIcdWithList))
									]))
							]));
				}),
			_List_Nil,
			fullStmt.fh)) : _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('1. PREEXISTÊNCIAS DECLARADAS NA DECLARAÇÃO DE SAÚDE')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('NADA CONSTA')
				]))
		]);
	var bearerIcds = (fullStmt.g.e.j_ === 2) ? _List_Nil : A2(
		$elm$core$List$filter,
		function (i) {
			return i.g2;
		},
		$author$project$App$Data$ICD$listFromDict(fullStmt.g.fx));
	var companyIcdList = A2(
		$elm$core$List$filter,
		isCompanyIcd,
		A2(
			$elm$core$List$append,
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (_v3, dep, list) {
						return A2(
							$elm$core$List$append,
							list,
							$author$project$App$Data$ICD$listFromDict(dep.fx));
					}),
				_List_Nil,
				fullStmt.fh),
			bearerIcds));
	var companyIcdsKeys = $elm$core$Dict$keys(
		$elm$core$Dict$fromList(
			A2(
				$elm$core$List$filter,
				function (_v2) {
					var i = _v2.b;
					return i.g2;
				},
				A2(
					$elm$core$List$map,
					function (icd) {
						return _Utils_Tuple2(icd.a0, icd);
					},
					companyIcdList))));
	var nonCompanyIcdList = A2(
		$elm$core$List$filter,
		function (icd) {
			return A2(notBeenAlreadyDeclared, icd, companyIcdList);
		},
		A2(
			$elm$core$List$append,
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (_v1, dep, list) {
						return A2(
							$elm$core$List$append,
							list,
							$author$project$App$Data$ICD$listFromDict(dep.fx));
					}),
				_List_Nil,
				fullStmt.fh),
			bearerIcds));
	var nonCompanyIcdsKeys = $elm$core$Dict$keys(
		$elm$core$Dict$fromList(
			A2(
				$elm$core$List$filter,
				function (_v0) {
					var i = _v0.b;
					return i.g2;
				},
				A2(
					$elm$core$List$map,
					function (icd) {
						return _Utils_Tuple2(icd.a0, icd);
					},
					nonCompanyIcdList))));
	var hasChanged = 0 < $elm$core$List$length(
		A2(
			$elm$core$List$filter,
			function (icd) {
				return !A2($elm$core$List$member, icd, companyIcdsKeys);
			},
			nonCompanyIcdsKeys));
	var hasChangedText = hasChanged ? $elm$html$Html$text('(X) SIM') : $elm$html$Html$text('( ) SIM');
	var hasNotChangedText = (!hasChanged) ? $elm$html$Html$text('(X) NÃO') : $elm$html$Html$text('( ) NÃO');
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('doctor-result-layout CentauroOn')
			]),
		$elm$core$List$concat(
			_List_fromArray(
				[
					title,
					companyIcds,
					nonCompanyIcds,
					finalResult,
					_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Constatado divergência entre o item 1 com o item 2')
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[hasChangedText])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[hasNotChangedText])),
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'text-align', 'right')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'Curitiba, ' + $author$project$App$Tools$FormHelpers$dateFormat(fullStmt.g.e.f2))
							]))
					])
				])));
};
var $author$project$App$Legacy$Doctor$ResultLayout$Hnsg$viewDoctorAnswers = F2(
	function (stmt, personSection) {
		var templates = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (s) {
					return $elm$core$List$concat(
						A2(
							$elm$core$List$map,
							function (q) {
								return A2(
									$elm$core$List$map,
									function (t) {
										return _Utils_Tuple3(t.jh, t.as, t.bh);
									},
									q.bY);
							},
							s.eI));
				},
				personSection));
		var questions = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (s) {
					return A2(
						$elm$core$List$map,
						function (q) {
							return q.hG;
						},
						s.eI);
				},
				personSection));
		return A2(
			$elm$core$List$indexedMap,
			F2(
				function (i, _v2) {
					var label = _v2.a;
					var id = _v2.b;
					var qid = _v2.c;
					var question = A2($author$project$App$Legacy$Doctor$ResultLayout$Hnsg$getQuestionForQuestionId, questions, qid);
					var answer = A2($author$project$App$Legacy$Doctor$ResultLayout$Hnsg$getAnswerForTemplateId, stmt.aZ, id);
					return A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(answer.T)
									]))
							]));
				}),
			A2(
				$elm$core$List$filter,
				function (_v1) {
					var label = _v1.a;
					var id = _v1.b;
					var qid = _v1.c;
					var answer = A2($author$project$App$Legacy$Doctor$ResultLayout$Hnsg$getAnswerForTemplateId, stmt.aZ, id);
					return answer.dF || ($elm$core$String$length(answer.T) > 0);
				},
				$elm$core$List$reverse(
					_Utils_ap(
						A2(
							$elm$core$List$map,
							function (_v0) {
								var label = _v0.a;
								var id = _v0.b;
								var qid = _v0.c;
								return _Utils_Tuple3('', id, qid);
							},
							A2(
								$elm$core$List$take,
								1,
								$elm$core$List$reverse(templates))),
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							$elm$core$List$tail(
								$elm$core$List$reverse(templates)))))));
	});
var $author$project$App$Legacy$Doctor$ResultLayout$Hnsg$viewPersonAnswers = F2(
	function (stmt, personSection) {
		var templates = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (s) {
					return $elm$core$List$concat(
						A2(
							$elm$core$List$map,
							function (q) {
								return A2(
									$elm$core$List$map,
									function (t) {
										return _Utils_Tuple3(t.jh, t.as, t.bh);
									},
									q.bY);
							},
							s.eI));
				},
				personSection));
		var questions = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (s) {
					return A2(
						$elm$core$List$map,
						function (q) {
							return q.hG;
						},
						s.eI);
				},
				personSection));
		return A2(
			$elm$core$List$indexedMap,
			F2(
				function (i, _v2) {
					var label = _v2.a;
					var id = _v2.b;
					var qid = _v2.c;
					var question = A2($author$project$App$Legacy$Doctor$ResultLayout$Hnsg$getQuestionForQuestionId, questions, qid);
					var answer = A2($author$project$App$Legacy$Doctor$ResultLayout$Hnsg$getAnswerForTemplateId, stmt.aZ, id);
					return A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2($elm$html$Html$td, _List_Nil, _List_Nil),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(question.bP)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(label)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(answer.T)
									]))
							]));
				}),
			A2(
				$elm$core$List$filter,
				function (_v1) {
					var label = _v1.a;
					var id = _v1.b;
					var qid = _v1.c;
					var answer = A2($author$project$App$Legacy$Doctor$ResultLayout$Hnsg$getAnswerForTemplateId, stmt.aZ, id);
					return answer.dF || ($elm$core$String$length(answer.T) > 0);
				},
				$elm$core$List$reverse(
					_Utils_ap(
						A2(
							$elm$core$List$map,
							function (_v0) {
								var label = _v0.a;
								var id = _v0.b;
								var qid = _v0.c;
								return _Utils_Tuple3('', id, qid);
							},
							A2(
								$elm$core$List$take,
								1,
								$elm$core$List$reverse(templates))),
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							$elm$core$List$tail(
								$elm$core$List$reverse(templates)))))));
	});
var $author$project$App$Legacy$Doctor$ResultLayout$Hnsg$viewDoctorStatement = function (fullStmt) {
	var title = A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src('/assets/images/logo-hnsg.png'),
								A2($elm$html$Html$Attributes$style, 'width', '80px')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$h1,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'margin-left', '10px')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('TELEMEDICINA HOSPITAL NOSSA SENHORA DAS GRAÇAS')
							]))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Identificação do paciente: ')
							]))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Nome: ')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Person$fullPersonName(fullStmt.g.dw))
							]))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Data de Nascimento: ')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Tools$FormHelpers$dateFormat(fullStmt.g.dw.al))
							]))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Documento: ')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Mask$cpf(fullStmt.g.dw.i1))
							]))
					]))
			]),
		A3(
			$elm$core$Dict$foldl,
			F3(
				function (idx, dep, html) {
					var idxStr = $elm$core$String$fromInt(idx + 1);
					return A2(
						$elm$core$List$append,
						html,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('DEP 0' + (idxStr + ':'))
											])),
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Person$fullPersonName(dep.dw))
											]))
									]))
							]));
				}),
			_List_Nil,
			fullStmt.fh));
	var finalEvaluation = _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('3. Conduta médica')
				])),
			A2(
			$elm$html$Html$pre,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(fullStmt.g.e.jx)
				]))
		]);
	var doctorEvaluation = _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('2. Evolução Médica')
				])),
			A2(
			$elm$html$Html$pre,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$table,
					_List_Nil,
					A2($author$project$App$Legacy$Doctor$ResultLayout$Hnsg$viewDoctorAnswers, fullStmt.g, fullStmt.gH))
				]))
		]);
	var answers = _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('1. Respostas do Questionário')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$table,
					_List_Nil,
					A2($author$project$App$Legacy$Doctor$ResultLayout$Hnsg$viewPersonAnswers, fullStmt.g, fullStmt.jM))
				]))
		]);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('doctor-result-layout CentauroOn')
			]),
		$elm$core$List$concat(
			_List_fromArray(
				[
					title,
					answers,
					doctorEvaluation,
					finalEvaluation,
					_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'text-align', 'right')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'Curitiba, ' + $author$project$App$Tools$FormHelpers$dateFormat(fullStmt.g.e.f2))
							]))
					])
				])));
};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$App$Legacy$Doctor$ResultLayout$Medsyn$viewPersonICDForDoctorStatement = F2(
	function (person, icd) {
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$App$Data$Person$fullPersonName(person))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(icd.am)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(icd.a0)
						]))
				]));
	});
var $author$project$App$Legacy$Doctor$ResultLayout$Medsyn$viewPersonICDsForDoctorStatement = F2(
	function (stmt, test) {
		var filterFunc = function () {
			if (test.$ === 1) {
				return function (_v2) {
					return true;
				};
			} else {
				var t = test.a;
				return function (icd) {
					return A2(t, icd.dE, 1);
				};
			}
		}();
		return A2(
			$elm$core$List$map,
			function (_v0) {
				var icd = _v0.b;
				return A2($author$project$App$Legacy$Doctor$ResultLayout$Medsyn$viewPersonICDForDoctorStatement, stmt.dw, icd);
			},
			$elm$core$Dict$toList(
				$elm$core$Dict$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, icd) {
								return _Utils_Tuple2(i, icd);
							}),
						A2(
							$elm$core$List$filter,
							filterFunc,
							$author$project$App$Data$ICD$listFromDict(stmt.fx))))));
	});
var $author$project$App$Legacy$Doctor$ResultLayout$Medsyn$viewDoctorStatement = function (fullStmt) {
	var xWithICDs = (($elm$core$List$length(
		$author$project$App$Data$ICD$listFromDict(fullStmt.g.fx)) > 0) || A3(
		$elm$core$Dict$foldl,
		F3(
			function (_v5, dep, t) {
				return ($elm$core$List$length(
					$author$project$App$Data$ICD$listFromDict(dep.fx)) > 0) || t;
			}),
		false,
		fullStmt.fh)) ? 'X' : '';
	var obs = F2(
		function (person, stmt) {
			return (stmt.j_ === 2) ? _List_fromArray(
				[
					$elm$html$Html$text(stmt.jx),
					A2($elm$html$Html$br, _List_Nil, _List_Nil)
				]) : _List_fromArray(
				[
					$elm$html$Html$text(
					$author$project$App$Data$Person$fullPersonName(person) + ':'),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					$elm$html$Html$text(
					'IMC na DPS: ' + $author$project$App$Data$Statement$formatCompanyBMI(stmt)),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					$elm$html$Html$text(
					'IMC no Questionário: ' + $author$project$App$Data$Statement$formatPersonBMI(stmt)),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					$elm$html$Html$text(
					'IMC no Atendimento:' + $author$project$App$Data$Statement$formatDoctorBMI(stmt)),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					$elm$html$Html$text(stmt.jx),
					A2($elm$html$Html$br, _List_Nil, _List_Nil)
				]);
		});
	var bearerIcdsView = function (test) {
		return (fullStmt.g.e.j_ === 2) ? _List_Nil : A2($author$project$App$Legacy$Doctor$ResultLayout$Medsyn$viewPersonICDsForDoctorStatement, fullStmt.g, test);
	};
	var nonCompanyIcds = A2(
		$elm$core$List$append,
		bearerIcdsView(
			$elm$core$Maybe$Just($elm$core$Basics$neq)),
		A3(
			$elm$core$Dict$foldl,
			F3(
				function (_v4, dep, html) {
					return A2(
						$elm$core$List$append,
						html,
						A2(
							$author$project$App$Legacy$Doctor$ResultLayout$Medsyn$viewPersonICDsForDoctorStatement,
							dep,
							$elm$core$Maybe$Just($elm$core$Basics$neq)));
				}),
			_List_Nil,
			fullStmt.fh));
	var bearerIcds = (fullStmt.g.e.j_ === 2) ? _List_Nil : $author$project$App$Data$ICD$listFromDict(fullStmt.g.fx);
	var companyIcdsKeys = $elm$core$Dict$keys(
		$elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (icd) {
					return _Utils_Tuple2(icd.a0, icd);
				},
				A2(
					$elm$core$List$filter,
					function (icd) {
						return icd.dE === 1;
					},
					A2(
						$elm$core$List$append,
						A3(
							$elm$core$Dict$foldl,
							F3(
								function (_v3, dep, list) {
									return A2(
										$elm$core$List$append,
										list,
										$author$project$App$Data$ICD$listFromDict(dep.fx));
								}),
							_List_Nil,
							fullStmt.fh),
						bearerIcds)))));
	var nonCompanyIcdsKeys = $elm$core$Dict$keys(
		$elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (icd) {
					return _Utils_Tuple2(icd.a0, icd);
				},
				A2(
					$elm$core$List$filter,
					function (icd) {
						return icd.dE !== 1;
					},
					A2(
						$elm$core$List$append,
						A3(
							$elm$core$Dict$foldl,
							F3(
								function (_v2, dep, list) {
									return A2(
										$elm$core$List$append,
										list,
										$author$project$App$Data$ICD$listFromDict(dep.fx));
								}),
							_List_Nil,
							fullStmt.fh),
						bearerIcds)))));
	var hasChanged = 0 < $elm$core$List$length(
		A2(
			$elm$core$List$filter,
			function (icd) {
				return !A2($elm$core$List$member, icd, companyIcdsKeys);
			},
			nonCompanyIcdsKeys));
	var hasChangedText = hasChanged ? $elm$html$Html$text('(X) SIM') : $elm$html$Html$text('( ) SIM');
	var hasNotChangedText = (!hasChanged) ? $elm$html$Html$text('(X) NÃO') : $elm$html$Html$text('( ) NÃO');
	var allIcds = A2(
		$elm$core$List$append,
		bearerIcdsView($elm$core$Maybe$Nothing),
		A3(
			$elm$core$Dict$foldl,
			F3(
				function (_v1, dep, html) {
					return A2(
						$elm$core$List$append,
						html,
						A2($author$project$App$Legacy$Doctor$ResultLayout$Medsyn$viewPersonICDsForDoctorStatement, dep, $elm$core$Maybe$Nothing));
				}),
			_List_Nil,
			fullStmt.fh));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('doctor-result-layout')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('PARTE III – PARECER DO MÉDICO AUDITOR')
					])),
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('(Para uso exclusivo da Unimed)')
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('recommendation'),
						A2($elm$html$Html$Attributes$attribute, 'border', '0'),
						A2($elm$html$Html$Attributes$attribute, 'align', 'center')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('border')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(' ')
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Não é necessário a perícia médica.')
									])),
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('border')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(' ')
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Com observações, mas sem necessidade de perícia.')
									]))
							])),
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2($elm$html$Html$td, _List_Nil, _List_Nil)
							])),
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('border')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(' ')
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('É necessário a perícia médica.')
									])),
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('border')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(xWithICDs)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Declarada a doença ou lesão preexistente.')
									]))
							]))
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'border', '0')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Observações')
									])),
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$style, 'white-space', 'pre-wrap')
									]),
								A2(
									$elm$core$List$append,
									A2(obs, fullStmt.g.dw, fullStmt.g.e),
									A3(
										$elm$core$Dict$foldl,
										F3(
											function (_v0, dep, list) {
												return A2(
													$elm$core$List$append,
													list,
													A2(obs, dep.dw, dep.e));
											}),
										_List_Nil,
										fullStmt.fh)))
							]))
					])),
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Relação de Doenças e Lesões Preexistentes declaradas (identificar com o CID-10):')
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'width', '100%'),
						A2($elm$html$Html$Attributes$attribute, 'border', '1')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Nome')
									])),
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Doença/Patologia')
									])),
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('CID')
									]))
							])),
						A2($elm$html$Html$tbody, _List_Nil, allIcds)
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						'Data: ' + $author$project$App$Tools$FormHelpers$dateFormat(fullStmt.g.e.f2))
					])),
				A2($elm$html$Html$br, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'text-decoration', 'overline')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Assinatura e Carimbo do Médico Avaliador')
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'page-break-before', 'always')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Constatou-se divergência entre as preexistências declaradas na declaração de saúde ' + 'e as preexistências diagnosticadas em relatório, exames médicos e videoconferência')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[hasChangedText])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[hasNotChangedText])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Se sim, informar divergências abaixo')
					])),
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('PREEXISTÊNCIAS DIAGNOSTICADAS EM RELATÓRIO, EXAMES MÉDICOS E VIDEOCONFERÊNCIA:')
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'width', '100%'),
						A2($elm$html$Html$Attributes$attribute, 'border', '1')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Nome')
									])),
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Doença/Patologia')
									])),
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('CID')
									]))
							])),
						A2($elm$html$Html$tbody, _List_Nil, nonCompanyIcds)
					]))
			]));
};
var $author$project$App$Legacy$Doctor$ResultLayout$SB_Saude$viewPersonICDs = F3(
	function (mDepIdx, stmt, filterFuncWithoutIcds) {
		var title = function () {
			if (!mDepIdx.$) {
				return 'TITULAR';
			} else {
				var i = mDepIdx.a;
				return 'DEP 0' + $elm$core$String$fromInt(i + 1);
			}
		}();
		var companyIcds = A2(
			$elm$core$List$filter,
			function (icd) {
				return icd.dE === 1;
			},
			$author$project$App$Data$ICD$listFromDict(stmt.fx));
		var filterFunc = function (icd) {
			return A2(filterFuncWithoutIcds, icd, companyIcds);
		};
		var icds = $elm$core$Dict$toList(
			$elm$core$Dict$fromList(
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (i, icd) {
							return _Utils_Tuple2(i, icd);
						}),
					A2(
						$elm$core$List$filter,
						filterFunc,
						$author$project$App$Data$ICD$listFromDict(stmt.fx)))));
		return A2(
			$elm$core$List$map,
			function (_v0) {
				var i = _v0.a;
				var icd = _v0.b;
				return A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$td,
							_List_Nil,
							_List_fromArray(
								[
									(!i) ? $elm$html$Html$text(title) : $elm$html$Html$text('')
								])),
							A2(
							$elm$html$Html$td,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(icd.am)
								]))
						]));
			},
			icds);
	});
var $author$project$App$Legacy$Doctor$ResultLayout$SB_Saude$viewDoctorStatement = function (fullStmt) {
	var title = A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('PARECER MÉDICO – PLANO COLETIVO POR ADESÃO')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('TITULAR: ')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Person$fullPersonName(fullStmt.g.dw))
							]))
					]))
			]),
		A3(
			$elm$core$Dict$foldl,
			F3(
				function (idx, dep, html) {
					var idxStr = $elm$core$String$fromInt(idx + 1);
					return A2(
						$elm$core$List$append,
						html,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('DEP 0' + (idxStr + ':'))
											])),
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Person$fullPersonName(dep.dw))
											]))
									]))
							]));
				}),
			_List_Nil,
			fullStmt.fh));
	var obs = ($elm$core$String$length(fullStmt.g.e.jx) > 0) ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'white-space', 'pre-wrap')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Observações médicas:')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(fullStmt.g.e.jx)
					]))
			])) : $elm$html$Html$text('');
	var isCompanyIcdWithList = F2(
		function (icd, list) {
			return icd.dE === 1;
		});
	var isCompanyIcd = function (icd) {
		return icd.dE === 1;
	};
	var notBeenAlreadyDeclared = F2(
		function (icd, cIcdList) {
			return (!A2(
				$elm$core$List$any,
				function (i) {
					return _Utils_eq(i.a0, icd.a0) && _Utils_eq(i.aV, icd.aV);
				},
				cIcdList)) && (!isCompanyIcd(icd));
		});
	var nonCompanyIcds = A2(
		$author$project$App$Legacy$Doctor$ResultLayout$Helpers$hasICDsToDeclare,
		fullStmt,
		$elm$core$Maybe$Just($elm$core$Basics$neq)) ? A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('2. PREEXISTÊNCIAS DIAGNOSTICADAS EM RELATÓRIO, EXAMES MÉDICOS E ENTREVISTA COM MÉDICO')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_Nil,
						A3($author$project$App$Legacy$Doctor$ResultLayout$SB_Saude$viewPersonICDs, $author$project$App$Data$Statement$Bearer, fullStmt.g, notBeenAlreadyDeclared))
					]))
			]),
		A3(
			$elm$core$Dict$foldl,
			F3(
				function (idx, dep, html) {
					return A2(
						$elm$core$List$append,
						html,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$table,
										_List_Nil,
										A3(
											$author$project$App$Legacy$Doctor$ResultLayout$SB_Saude$viewPersonICDs,
											$author$project$App$Data$Statement$Dependent(idx),
											dep,
											notBeenAlreadyDeclared))
									]))
							]));
				}),
			_List_Nil,
			fullStmt.fh)) : _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('2. PREEXISTÊNCIAS DIAGNOSTICADAS EM RELATÓRIO, EXAMES MÉDICOS E ENTREVISTA COM MÉDICO')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('NADA CONSTA')
				]))
		]);
	var finalResult = A2($author$project$App$Legacy$Doctor$ResultLayout$Helpers$hasICDsToDeclare, fullStmt, $elm$core$Maybe$Nothing) ? $elm$core$List$concat(
		_List_fromArray(
			[
				_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('PARECER FINAL')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Aplicar CPT para procedimentos de alta complexidade pela Agência Nacional de Saúde ' + 'Suplementar conforme diagnósticos declarados:')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$table,
							_List_Nil,
							A3(
								$author$project$App$Legacy$Doctor$ResultLayout$SB_Saude$viewPersonICDs,
								$author$project$App$Data$Statement$Bearer,
								fullStmt.g,
								F2(
									function (_v2, _v3) {
										return true;
									})))
						]))
				]),
				A3(
				$elm$core$Dict$foldl,
				F3(
					function (idx, dep, html) {
						return A2(
							$elm$core$List$append,
							html,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$table,
											_List_Nil,
											A3(
												$author$project$App$Legacy$Doctor$ResultLayout$SB_Saude$viewPersonICDs,
												$author$project$App$Data$Statement$Dependent(idx),
												dep,
												F2(
													function (_v4, _v5) {
														return true;
													})))
										]))
								]));
					}),
				_List_Nil,
				fullStmt.fh),
				_List_fromArray(
				[obs])
			])) : _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('PARECER FINAL')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Aplicar CPT para procedimentos de alta complexidade pela Agência Nacional de Saúde ' + 'Suplementar conforme diagnósticos declarados:')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Não se aplica')
				])),
			obs
		]);
	var companyIcds = A2(
		$author$project$App$Legacy$Doctor$ResultLayout$Helpers$hasICDsToDeclare,
		fullStmt,
		$elm$core$Maybe$Just($elm$core$Basics$eq)) ? A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('1. PREEXISTÊNCIAS DECLARADAS NA DECLARAÇÃO DE SAÚDE')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_Nil,
						A3($author$project$App$Legacy$Doctor$ResultLayout$SB_Saude$viewPersonICDs, $author$project$App$Data$Statement$Bearer, fullStmt.g, isCompanyIcdWithList))
					]))
			]),
		A3(
			$elm$core$Dict$foldl,
			F3(
				function (idx, dep, html) {
					return A2(
						$elm$core$List$append,
						html,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$table,
										_List_Nil,
										A3(
											$author$project$App$Legacy$Doctor$ResultLayout$SB_Saude$viewPersonICDs,
											$author$project$App$Data$Statement$Dependent(idx),
											dep,
											isCompanyIcdWithList))
									]))
							]));
				}),
			_List_Nil,
			fullStmt.fh)) : _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('1. PREEXISTÊNCIAS DECLARADAS NA DECLARAÇÃO DE SAÚDE')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('NADA CONSTA')
				]))
		]);
	var bearerIcds = (fullStmt.g.e.j_ === 2) ? _List_Nil : $author$project$App$Data$ICD$listFromDict(fullStmt.g.fx);
	var companyIcdList = A2(
		$elm$core$List$filter,
		isCompanyIcd,
		A2(
			$elm$core$List$append,
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (_v1, dep, list) {
						return A2(
							$elm$core$List$append,
							list,
							$author$project$App$Data$ICD$listFromDict(dep.fx));
					}),
				_List_Nil,
				fullStmt.fh),
			bearerIcds));
	var companyIcdsKeys = $elm$core$Dict$keys(
		$elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (icd) {
					return _Utils_Tuple2(icd.a0, icd);
				},
				companyIcdList)));
	var nonCompanyIcdsKeys = $elm$core$Dict$keys(
		$elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (icd) {
					return _Utils_Tuple2(icd.a0, icd);
				},
				A2(
					$elm$core$List$filter,
					function (icd) {
						return A2(notBeenAlreadyDeclared, icd, companyIcdList);
					},
					A2(
						$elm$core$List$append,
						A3(
							$elm$core$Dict$foldl,
							F3(
								function (_v0, dep, list) {
									return A2(
										$elm$core$List$append,
										list,
										$author$project$App$Data$ICD$listFromDict(dep.fx));
								}),
							_List_Nil,
							fullStmt.fh),
						bearerIcds)))));
	var hasChanged = 0 < $elm$core$List$length(
		A2(
			$elm$core$List$filter,
			function (icd) {
				return !A2($elm$core$List$member, icd, companyIcdsKeys);
			},
			nonCompanyIcdsKeys));
	var hasChangedText = hasChanged ? $elm$html$Html$text('(X) SIM') : $elm$html$Html$text('( ) SIM');
	var hasNotChangedText = (!hasChanged) ? $elm$html$Html$text('(X) NÃO') : $elm$html$Html$text('( ) NÃO');
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('doctor-result-layout sulamerica')
			]),
		$elm$core$List$concat(
			_List_fromArray(
				[
					title,
					companyIcds,
					nonCompanyIcds,
					finalResult,
					_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Constatado divergência entre o item 1 com o item 2')
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[hasChangedText])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[hasNotChangedText])),
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'text-align', 'right')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'Curitiba, ' + $author$project$App$Tools$FormHelpers$dateFormat(fullStmt.g.e.f2))
							]))
					])
				])));
};
var $author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewPersonICDs = F3(
	function (mDepIdx, stmt, filterFuncWithoutIcds) {
		var title = function () {
			if (!mDepIdx.$) {
				return 'TITULAR';
			} else {
				var i = mDepIdx.a;
				return 'DEP 0' + $elm$core$String$fromInt(i + 1);
			}
		}();
		var companyIcds = A2(
			$elm$core$List$filter,
			function (icd) {
				return icd.dE === 1;
			},
			$author$project$App$Data$ICD$listFromDict(stmt.fx));
		var filterFunc = function (icd) {
			return A2(filterFuncWithoutIcds, icd, companyIcds);
		};
		var icds = $elm$core$Dict$toList(
			$elm$core$Dict$fromList(
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (i, icd) {
							return _Utils_Tuple2(i, icd);
						}),
					A2(
						$elm$core$List$filter,
						filterFunc,
						$author$project$App$Data$ICD$listFromDict(stmt.fx)))));
		return A2(
			$elm$core$List$map,
			function (_v0) {
				var i = _v0.a;
				var icd = _v0.b;
				return A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$td,
							_List_Nil,
							_List_fromArray(
								[
									(!i) ? $elm$html$Html$text(title) : $elm$html$Html$text('')
								])),
							A2(
							$elm$html$Html$td,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(icd.am)
								]))
						]));
			},
			icds);
	});
var $author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewDoctorStatement = function (fullStmt) {
	var title = A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('PARECER MÉDICO – PLANO COLETIVO POR ADESÃO')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('TITULAR: ')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Person$fullPersonName(fullStmt.g.dw))
							]))
					]))
			]),
		A3(
			$elm$core$Dict$foldl,
			F3(
				function (idx, dep, html) {
					var idxStr = $elm$core$String$fromInt(idx + 1);
					return A2(
						$elm$core$List$append,
						html,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('DEP 0' + (idxStr + ':'))
											])),
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Person$fullPersonName(dep.dw))
											]))
									]))
							]));
				}),
			_List_Nil,
			fullStmt.fh));
	var obs = ($elm$core$String$length(fullStmt.g.e.jx) > 0) ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'white-space', 'pre-wrap')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Observações médicas:')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(fullStmt.g.e.jx)
					]))
			])) : $elm$html$Html$text('');
	var isCompanyIcdWithList = F2(
		function (icd, list) {
			return icd.dE === 1;
		});
	var isCompanyIcd = function (icd) {
		return icd.dE === 1;
	};
	var notBeenAlreadyDeclared = F2(
		function (icd, cIcdList) {
			return (!A2(
				$elm$core$List$any,
				function (i) {
					return _Utils_eq(i.a0, icd.a0) && _Utils_eq(i.aV, icd.aV);
				},
				cIcdList)) && (!isCompanyIcd(icd));
		});
	var nonCompanyIcds = A2(
		$author$project$App$Legacy$Doctor$ResultLayout$Helpers$hasICDsToDeclare,
		fullStmt,
		$elm$core$Maybe$Just($elm$core$Basics$neq)) ? A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('2. PREEXISTÊNCIAS DIAGNOSTICADAS EM RELATÓRIO, EXAMES MÉDICOS E ENTREVISTA COM MÉDICO')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_Nil,
						A3($author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewPersonICDs, $author$project$App$Data$Statement$Bearer, fullStmt.g, notBeenAlreadyDeclared))
					]))
			]),
		A3(
			$elm$core$Dict$foldl,
			F3(
				function (idx, dep, html) {
					return A2(
						$elm$core$List$append,
						html,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$table,
										_List_Nil,
										A3(
											$author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewPersonICDs,
											$author$project$App$Data$Statement$Dependent(idx),
											dep,
											notBeenAlreadyDeclared))
									]))
							]));
				}),
			_List_Nil,
			fullStmt.fh)) : _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('2. PREEXISTÊNCIAS DIAGNOSTICADAS EM RELATÓRIO, EXAMES MÉDICOS E ENTREVISTA COM MÉDICO')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('NADA CONSTA')
				]))
		]);
	var finalResult = A2($author$project$App$Legacy$Doctor$ResultLayout$Helpers$hasICDsToDeclare, fullStmt, $elm$core$Maybe$Nothing) ? $elm$core$List$concat(
		_List_fromArray(
			[
				_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('PARECER FINAL')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Aplicar CPT para procedimentos de alta complexidade pela Agência Nacional de Saúde ' + 'Suplementar conforme diagnósticos declarados:')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$table,
							_List_Nil,
							A3(
								$author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewPersonICDs,
								$author$project$App$Data$Statement$Bearer,
								fullStmt.g,
								F2(
									function (_v2, _v3) {
										return true;
									})))
						]))
				]),
				A3(
				$elm$core$Dict$foldl,
				F3(
					function (idx, dep, html) {
						return A2(
							$elm$core$List$append,
							html,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$table,
											_List_Nil,
											A3(
												$author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewPersonICDs,
												$author$project$App$Data$Statement$Dependent(idx),
												dep,
												F2(
													function (_v4, _v5) {
														return true;
													})))
										]))
								]));
					}),
				_List_Nil,
				fullStmt.fh),
				_List_fromArray(
				[obs])
			])) : _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('PARECER FINAL')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Aplicar CPT para procedimentos de alta complexidade pela Agência Nacional de Saúde ' + 'Suplementar conforme diagnósticos declarados:')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Não se aplica')
				])),
			obs
		]);
	var companyIcds = A2(
		$author$project$App$Legacy$Doctor$ResultLayout$Helpers$hasICDsToDeclare,
		fullStmt,
		$elm$core$Maybe$Just($elm$core$Basics$eq)) ? A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('1. PREEXISTÊNCIAS DECLARADAS NA DECLARAÇÃO DE SAÚDE')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_Nil,
						A3($author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewPersonICDs, $author$project$App$Data$Statement$Bearer, fullStmt.g, isCompanyIcdWithList))
					]))
			]),
		A3(
			$elm$core$Dict$foldl,
			F3(
				function (idx, dep, html) {
					return A2(
						$elm$core$List$append,
						html,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$table,
										_List_Nil,
										A3(
											$author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewPersonICDs,
											$author$project$App$Data$Statement$Dependent(idx),
											dep,
											isCompanyIcdWithList))
									]))
							]));
				}),
			_List_Nil,
			fullStmt.fh)) : _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('1. PREEXISTÊNCIAS DECLARADAS NA DECLARAÇÃO DE SAÚDE')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('NADA CONSTA')
				]))
		]);
	var bearerIcds = (fullStmt.g.e.j_ === 2) ? _List_Nil : $author$project$App$Data$ICD$listFromDict(fullStmt.g.fx);
	var companyIcdList = A2(
		$elm$core$List$filter,
		isCompanyIcd,
		A2(
			$elm$core$List$append,
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (_v1, dep, list) {
						return A2(
							$elm$core$List$append,
							list,
							$author$project$App$Data$ICD$listFromDict(dep.fx));
					}),
				_List_Nil,
				fullStmt.fh),
			bearerIcds));
	var companyIcdsKeys = $elm$core$Dict$keys(
		$elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (icd) {
					return _Utils_Tuple2(icd.a0, icd);
				},
				companyIcdList)));
	var nonCompanyIcdsKeys = $elm$core$Dict$keys(
		$elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (icd) {
					return _Utils_Tuple2(icd.a0, icd);
				},
				A2(
					$elm$core$List$filter,
					function (icd) {
						return A2(notBeenAlreadyDeclared, icd, companyIcdList);
					},
					A2(
						$elm$core$List$append,
						A3(
							$elm$core$Dict$foldl,
							F3(
								function (_v0, dep, list) {
									return A2(
										$elm$core$List$append,
										list,
										$author$project$App$Data$ICD$listFromDict(dep.fx));
								}),
							_List_Nil,
							fullStmt.fh),
						bearerIcds)))));
	var hasChanged = 0 < $elm$core$List$length(
		A2(
			$elm$core$List$filter,
			function (icd) {
				return !A2($elm$core$List$member, icd, companyIcdsKeys);
			},
			nonCompanyIcdsKeys));
	var hasChangedText = hasChanged ? $elm$html$Html$text('(X) SIM') : $elm$html$Html$text('( ) SIM');
	var hasNotChangedText = (!hasChanged) ? $elm$html$Html$text('(X) NÃO') : $elm$html$Html$text('( ) NÃO');
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('doctor-result-layout sulamerica')
			]),
		$elm$core$List$concat(
			_List_fromArray(
				[
					title,
					companyIcds,
					nonCompanyIcds,
					finalResult,
					_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Constatado divergência entre o item 1 com o item 2')
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[hasChangedText])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[hasNotChangedText])),
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'text-align', 'right')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'Curitiba, ' + $author$project$App$Tools$FormHelpers$dateFormat(fullStmt.g.e.f2))
							]))
					])
				])));
};
var $author$project$App$Legacy$Doctor$ResultLayout$UnimedFederacao$viewPersonICDForDoctorStatement = F2(
	function (person, icd) {
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$App$Data$Person$fullPersonName(person))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(icd.am)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(icd.a0)
						]))
				]));
	});
var $author$project$App$Legacy$Doctor$ResultLayout$UnimedFederacao$viewPersonICDsForDoctorStatement = F2(
	function (stmt, test) {
		var filterFunc = function () {
			if (test.$ === 1) {
				return function (_v2) {
					return true;
				};
			} else {
				var t = test.a;
				return function (icd) {
					return A2(t, icd.dE, 1);
				};
			}
		}();
		return A2(
			$elm$core$List$map,
			function (_v0) {
				var icd = _v0.b;
				return A2($author$project$App$Legacy$Doctor$ResultLayout$UnimedFederacao$viewPersonICDForDoctorStatement, stmt.dw, icd);
			},
			$elm$core$Dict$toList(
				$elm$core$Dict$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, icd) {
								return _Utils_Tuple2(i, icd);
							}),
						A2(
							$elm$core$List$filter,
							filterFunc,
							$author$project$App$Data$ICD$listFromDict(stmt.fx))))));
	});
var $author$project$App$Legacy$Doctor$ResultLayout$UnimedFederacao$viewDoctorStatement = function (fullStmt) {
	var xWithICDs = (($elm$core$List$length(
		$author$project$App$Data$ICD$listFromDict(fullStmt.g.fx)) > 0) || A3(
		$elm$core$Dict$foldl,
		F3(
			function (_v5, dep, t) {
				return ($elm$core$List$length(
					$author$project$App$Data$ICD$listFromDict(dep.fx)) > 0) || t;
			}),
		false,
		fullStmt.fh)) ? 'X' : '';
	var obs = F2(
		function (person, stmt) {
			return (stmt.j_ === 2) ? _List_fromArray(
				[
					$elm$html$Html$text(stmt.jx),
					A2($elm$html$Html$br, _List_Nil, _List_Nil)
				]) : _List_fromArray(
				[
					$elm$html$Html$text(
					$author$project$App$Data$Person$fullPersonName(person) + ':'),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					$elm$html$Html$text(
					'IMC na DPS: ' + $author$project$App$Data$Statement$formatCompanyBMI(stmt)),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					$elm$html$Html$text(
					'IMC no Questionário: ' + $author$project$App$Data$Statement$formatPersonBMI(stmt)),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					$elm$html$Html$text(
					'IMC no Atendimento:' + $author$project$App$Data$Statement$formatDoctorBMI(stmt)),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					$elm$html$Html$text(stmt.jx),
					A2($elm$html$Html$br, _List_Nil, _List_Nil)
				]);
		});
	var bearerIcdsView = function (test) {
		return (fullStmt.g.e.j_ === 2) ? _List_Nil : A2($author$project$App$Legacy$Doctor$ResultLayout$UnimedFederacao$viewPersonICDsForDoctorStatement, fullStmt.g, test);
	};
	var nonCompanyIcds = A2(
		$elm$core$List$append,
		bearerIcdsView(
			$elm$core$Maybe$Just($elm$core$Basics$neq)),
		A3(
			$elm$core$Dict$foldl,
			F3(
				function (_v4, dep, html) {
					return A2(
						$elm$core$List$append,
						html,
						A2(
							$author$project$App$Legacy$Doctor$ResultLayout$UnimedFederacao$viewPersonICDsForDoctorStatement,
							dep,
							$elm$core$Maybe$Just($elm$core$Basics$neq)));
				}),
			_List_Nil,
			fullStmt.fh));
	var bearerIcds = (fullStmt.g.e.j_ === 2) ? _List_Nil : $author$project$App$Data$ICD$listFromDict(fullStmt.g.fx);
	var companyIcdsKeys = $elm$core$Dict$keys(
		$elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (icd) {
					return _Utils_Tuple2(icd.a0, icd);
				},
				A2(
					$elm$core$List$filter,
					function (icd) {
						return icd.dE === 1;
					},
					A2(
						$elm$core$List$append,
						A3(
							$elm$core$Dict$foldl,
							F3(
								function (_v3, dep, list) {
									return A2(
										$elm$core$List$append,
										list,
										$author$project$App$Data$ICD$listFromDict(dep.fx));
								}),
							_List_Nil,
							fullStmt.fh),
						bearerIcds)))));
	var nonCompanyIcdsKeys = $elm$core$Dict$keys(
		$elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (icd) {
					return _Utils_Tuple2(icd.a0, icd);
				},
				A2(
					$elm$core$List$filter,
					function (icd) {
						return icd.dE !== 1;
					},
					A2(
						$elm$core$List$append,
						A3(
							$elm$core$Dict$foldl,
							F3(
								function (_v2, dep, list) {
									return A2(
										$elm$core$List$append,
										list,
										$author$project$App$Data$ICD$listFromDict(dep.fx));
								}),
							_List_Nil,
							fullStmt.fh),
						bearerIcds)))));
	var hasChanged = 0 < $elm$core$List$length(
		A2(
			$elm$core$List$filter,
			function (icd) {
				return !A2($elm$core$List$member, icd, companyIcdsKeys);
			},
			nonCompanyIcdsKeys));
	var hasChangedText = hasChanged ? $elm$html$Html$text('(X) SIM') : $elm$html$Html$text('( ) SIM');
	var hasNotChangedText = (!hasChanged) ? $elm$html$Html$text('(X) NÃO') : $elm$html$Html$text('( ) NÃO');
	var allIcds = A2(
		$elm$core$List$append,
		bearerIcdsView($elm$core$Maybe$Nothing),
		A3(
			$elm$core$Dict$foldl,
			F3(
				function (_v1, dep, html) {
					return A2(
						$elm$core$List$append,
						html,
						A2($author$project$App$Legacy$Doctor$ResultLayout$UnimedFederacao$viewPersonICDsForDoctorStatement, dep, $elm$core$Maybe$Nothing));
				}),
			_List_Nil,
			fullStmt.fh));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('doctor-result-layout')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('PARTE III – PARECER DO MÉDICO AUDITOR')
					])),
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('(Para uso exclusivo da Unimed)')
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('recommendation'),
						A2($elm$html$Html$Attributes$attribute, 'border', '0'),
						A2($elm$html$Html$Attributes$attribute, 'align', 'center')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('border')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(' ')
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Não é necessário a perícia médica.')
									])),
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('border')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(' ')
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Com observações, mas sem necessidade de perícia.')
									]))
							])),
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2($elm$html$Html$td, _List_Nil, _List_Nil)
							])),
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('border')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(' ')
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('É necessário a perícia médica.')
									])),
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('border')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(xWithICDs)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Declarada a doença ou lesão preexistente.')
									]))
							]))
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'border', '0')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Observações')
									])),
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$style, 'white-space', 'pre-wrap')
									]),
								A2(
									$elm$core$List$append,
									A2(obs, fullStmt.g.dw, fullStmt.g.e),
									A3(
										$elm$core$Dict$foldl,
										F3(
											function (_v0, dep, list) {
												return A2(
													$elm$core$List$append,
													list,
													A2(obs, dep.dw, dep.e));
											}),
										_List_Nil,
										fullStmt.fh)))
							]))
					])),
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Relação de Doenças e Lesões Preexistentes declaradas (identificar com o CID-10):')
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'width', '100%'),
						A2($elm$html$Html$Attributes$attribute, 'border', '1')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Nome')
									])),
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Doença/Patologia')
									])),
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('CID')
									]))
							])),
						A2($elm$html$Html$tbody, _List_Nil, allIcds)
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						'Data: ' + $author$project$App$Tools$FormHelpers$dateFormat(fullStmt.g.e.f2))
					])),
				A2($elm$html$Html$br, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'text-decoration', 'overline')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Assinatura e Carimbo do Médico Avaliador')
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'page-break-before', 'always')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Constatou-se divergência entre as preexistências declaradas na declaração de saúde ' + 'e as preexistências diagnosticadas em relatório, exames médicos e videoconferência')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[hasChangedText])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[hasNotChangedText])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Se sim, informar divergências abaixo')
					])),
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('PREEXISTÊNCIAS DIAGNOSTICADAS EM RELATÓRIO, EXAMES MÉDICOS E VIDEOCONFERÊNCIA:')
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'width', '100%'),
						A2($elm$html$Html$Attributes$attribute, 'border', '1')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Nome')
									])),
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Doença/Patologia')
									])),
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('CID')
									]))
							])),
						A2($elm$html$Html$tbody, _List_Nil, nonCompanyIcds)
					]))
			]));
};
var $author$project$App$Legacy$Doctor$ResultLayout$Common$viewResultLayout = function (fullStmt) {
	var _v0 = fullStmt.cJ.gF;
	switch (_v0) {
		case 1:
			return $author$project$App$Legacy$Doctor$ResultLayout$UnimedFederacao$viewDoctorStatement(fullStmt);
		case 2:
			return $author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewDoctorStatement(fullStmt);
		case 3:
			return $author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewDoctorStatement(fullStmt);
		case 4:
			return $author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewDoctorStatement(fullStmt);
		case 7:
			return $author$project$App$Legacy$Doctor$ResultLayout$CentauroOn$viewDoctorStatement(fullStmt);
		case 6:
			return $author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewDoctorStatement(fullStmt);
		case 5:
			return $author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewDoctorStatement(fullStmt);
		case 8:
			return $author$project$App$Legacy$Doctor$ResultLayout$Hnsg$viewDoctorStatement(fullStmt);
		case 9:
			return $author$project$App$Legacy$Doctor$ResultLayout$Medsyn$viewDoctorStatement(fullStmt);
		case 10:
			return $author$project$App$Legacy$Doctor$ResultLayout$SB_Saude$viewDoctorStatement(fullStmt);
		case 11:
			return $author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewDoctorStatement(fullStmt);
		case 12:
			return $author$project$App$Legacy$Doctor$ResultLayout$Sulamerica$viewDoctorStatement(fullStmt);
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$App$Page$Admin$PageReevaluation$viewResultContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewResultLayout(fullStmt);
};
var $author$project$App$Page$Admin$PageReevaluation$view = F3(
	function (now, sharedState, model) {
		var viewCPT = sharedState.ia.h8.b4.iR === 'Extramed';
		var typeHnsg = A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var maybePdfResult = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var maybePdfDocument = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var hasMedicalReport = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var hasMedicalDocument = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var dialogView = $author$project$App$Page$Admin$PageReevaluation$dialogFromModel(model);
		var confirmButton = (model.P.g.e.aw === 31) ? _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$App$Page$Admin$PageReevaluation$SendToReview)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Retornar para médico')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$App$Page$Admin$PageReevaluation$FinishReevaluation)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Finalizar')
							]))
					]))
			]) : _List_Nil;
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$header,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('tabs')
								]),
							_Utils_ap(
								_List_fromArray(
									[
										A5($author$project$App$Page$Admin$PageReevaluation$viewQuestionsTabs, sharedState.ia.h8, viewCPT, typeHnsg, model.b5, sharedState.ia.h8.an),
										A2(
										$elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('cancel-button'),
														$elm$html$Html$Events$onClick($author$project$App$Page$Admin$PageReevaluation$BackToFinished)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Retornar')
													]))
											]))
									]),
								confirmButton)),
							function () {
							var _v0 = model.b5;
							switch (_v0.$) {
								case 0:
									return A2($author$project$App$Page$Admin$PageReevaluation$viewQuestions, model, sharedState);
								case 1:
									var docIdx = _v0.a;
									return $author$project$App$Page$Admin$PageReevaluation$viewDoc(
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$Document$emptyModel,
											A2($elm$core$Dict$get, docIdx, model.an)));
								case 2:
									var mDepIdx = _v0.a;
									if (!maybePdfResult.$) {
										var pdfResult = maybePdfResult.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Admin$PageReevaluation$viewResultContent(sharedState.ia.h8);
									}
								case 3:
									var mDepIdx = _v0.a;
									if (!maybePdfDocument.$) {
										var pdfResult = maybePdfDocument.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Admin$PageReevaluation$viewMedicalDocumentContent(model.P);
									}
								case 5:
									var videoRepo = {f3: 'video-repo-enterprise-covid.ingress.medsyn.com.br'};
									var videoUrl = A2($author$project$App$Data$VideoRepository$statementVideoUrl, videoRepo, sharedState.ia.h8.g.e.as);
									return A2(
										$elm$html$Html$video,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('video-preview'),
												$elm$html$Html$Attributes$controls(true)
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$source,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$src(videoUrl),
														$elm$html$Html$Attributes$type_('video/mp4')
													]),
												_List_Nil),
												$elm$html$Html$text('Seu browser não suporte exibição de vídeo HTML5')
											]));
								case 4:
									var depIdx = _v0.a;
									var fullStmt = sharedState.ia.h8;
									return A3($author$project$App$Page$Admin$PageReevaluation$viewCPTContent, depIdx, fullStmt, now);
								default:
									return $elm$html$Html$text('');
							}
						}()
						]))
				]),
			_Utils_ap(
				A2($author$project$App$Page$Admin$PageReevaluation$viewICDSection, sharedState, model),
				_List_fromArray(
					[dialogView])));
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$BackToFinished = {$: 1};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$ToggleDialog = {$: 2};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$SendToReview = {$: 3};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$UpdateReviewMessage = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$dialogFromModel = function (model) {
	var header = _List_Nil;
	var footer = _List_fromArray(
		[
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cancel-button'),
					$elm$html$Html$Events$onClick($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$ToggleDialog)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Cancelar')
				])),
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$SendToReview)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Devolver')
				]))
		]);
	var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
	var body = _List_fromArray(
		[
			A2(
			$elm$html$Html$textarea,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$cols(50),
					$elm$html$Html$Attributes$rows(5),
					$elm$html$Html$Attributes$value(model.eN),
					$elm$html$Html$Events$onInput($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$UpdateReviewMessage)
				]),
			_List_Nil)
		]);
	var completeConfig = _Utils_update(
		config,
		{
			e5: $elm$core$Maybe$Just(body),
			ft: $elm$core$Maybe$Just(footer),
			gY: $elm$core$Maybe$Just(header),
			f1: 'Motivo da Reavaliação'
		});
	return model.dC ? $author$project$App$Components$Dialog$view(
		$elm$core$Maybe$Just(completeConfig)) : $elm$html$Html$text('');
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewDocumentContent = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewCPTContent = F3(
	function (mDepIdx, fullStmt, now) {
		var cptDoc = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Document$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					function (_v1) {
						var i = _v1.a;
						var d = _v1.b;
						return d;
					},
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var d = _v0.b;
							return _Utils_eq(d.jJ, fullStmt.g.e.as) && (d.b7 === 5);
						},
						$elm$core$Dict$toList(fullStmt.an)))));
		return $author$project$App$Data$Id$uuidIsValid(cptDoc.as) ? $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewDocumentContent(cptDoc) : A3($author$project$App$Legacy$Doctor$ResultLayout$Common$viewCPTLayout, mDepIdx, fullStmt, now);
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewDoc = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewPersonICD = F4(
	function (now, mDepIdx, stmt, sharedState) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(stmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					A3(
						$elm$core$Dict$foldr,
						F3(
							function (cat, icd, html) {
								return A2(
									$elm$core$List$append,
									html,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('chip'),
													$elm$html$Html$Attributes$title(icd.am)
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(cat)
														]))
												]))
										]));
							}),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Data$Person$fullPersonName(stmt.dw))
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(
											A2($author$project$App$Data$Person$ageOnDate, now, stmt.dw)) + ' anos')
									])),
								sharedState.ia.h8.cJ.gX ? A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											if (mDepIdx.$ === 1) {
												var i = mDepIdx.a;
												return 'Dependente ' + $elm$core$String$fromInt(i + 1);
											} else {
												return _Utils_eq(stmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
											}
										}())
									])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
							]),
						A3(
							$elm$core$Dict$foldr,
							F3(
								function (_v0, qCatDict, catDict) {
									return A3(
										$elm$core$Dict$foldl,
										F3(
											function (cat, icd, catDictInner) {
												return A3($elm$core$Dict$insert, cat, icd, catDictInner);
											}),
										catDict,
										qCatDict);
								}),
							$elm$core$Dict$empty,
							stmt.fx)))
				]));
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewICDSection = F2(
	function (sharedState, model) {
		var chatView = $author$project$App$Components$Chatbox$view;
		var bearerPersonIcd = (sharedState.ia.h8.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A4($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewPersonICD, model.ey, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g, sharedState)
			]);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tabs ')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Dados por Pessoa')
									])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('material-icons')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('print')
													]))
											]))
									]))
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$ChatBoxMsg,
						chatView(model.aM)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('person-icds')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_Nil,
								A3(
									$elm$core$Dict$foldl,
									F3(
										function (i, dep, html) {
											return A2(
												$elm$core$List$append,
												html,
												_List_fromArray(
													[
														A4(
														$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewPersonICD,
														model.ey,
														$author$project$App$Data$Statement$Dependent(i),
														dep,
														sharedState)
													]));
										}),
									bearerPersonIcd,
									sharedState.ia.h8.fh))
							]))
					]))
			]);
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewMedicalDocumentContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewMedicalDocumentLayout(fullStmt);
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId = F3(
	function (depIdx, model, tId) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Answer$emptyModel,
			A2(
				$elm$core$Dict$get,
				tId,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Dict$empty,
					A2(
						$elm$core$Dict$get,
						$author$project$App$Data$Statement$depIndexToInt(depIdx),
						model.aZ))));
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewICD = F4(
	function (ansTemp, catCode, mIdx, icd) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('chip'),
					$elm$html$Html$Attributes$title(icd.am)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(icd.a0)
						]))
				]));
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewICDs = F3(
	function (icds, ansTemp, mIdx) {
		return A3(
			$elm$core$Dict$foldr,
			F3(
				function (catCode, icd, result) {
					return A2(
						$elm$core$List$append,
						result,
						_List_fromArray(
							[
								A4($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewICD, ansTemp, catCode, mIdx, icd)
							]));
				}),
			_List_Nil,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Dict$empty,
				A2($elm$core$Dict$get, ansTemp.as, icds)));
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewBMI = F4(
	function (sharedState, model, depIdx, stmt) {
		var weightTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 10) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.jM);
		var personQuestion = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestion$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (q) {
						return $author$project$App$Data$Id$uuidIsValid(q.hG.as);
					},
					A2(
						$elm$core$List$map,
						function (_v0) {
							var s = _v0.b;
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (q) {
											return _Utils_eq(q.hG.as, weightTemplate.bh);
										},
										s.eI)));
						},
						$elm$core$Dict$toList(model.cA)))));
		var heightTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 20) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.jM);
		var bmiTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 1000) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.gH);
		var doctorQuestion = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestion$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (q) {
						return $author$project$App$Data$Id$uuidIsValid(q.hG.as);
					},
					A2(
						$elm$core$List$map,
						function (s) {
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (q) {
											return _Utils_eq(q.hG.as, bmiTemplate.bh);
										},
										s.eI)));
						},
						model.P.gH))));
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no DPS')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatCompanyBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewICDs, stmt.fx, weightTemplate, depIdx))
							]))
					])),
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no Questionário')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatPersonBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewICDs, stmt.fx, heightTemplate, depIdx))
							]))
					])),
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no Atendimento')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatDoctorBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewICDs, stmt.fx, bmiTemplate, depIdx))
							]))
					]))
			]);
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$maskDecimal = F2(
	function (precision, value) {
		return A3($elm$core$String$replace, '.', ',', value);
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$maskHeight = function (cm) {
	var len = $elm$core$String$length(cm);
	var subM = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cm)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cm) : cm);
	var h = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(cm));
	var m = (h >= 100) ? A3($elm$core$String$slice, 0, -2, cm) : '0';
	return (h > 0) ? (m + (',' + subM)) : '';
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$maskPressure = function (str) {
	return function (p) {
		return (p > 0) ? $elm$core$String$fromInt(p) : '';
	}(
		function (p) {
			return (p > 999) ? (p / 10) : (p / 1.0);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(str))) | 0);
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$maskWeight = function (wStr) {
	return function (w) {
		return (w > 0) ? $elm$core$String$fromInt(w) : '';
	}(
		function (w) {
			return w / 1000;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(wStr))) | 0);
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$setupMask = F2(
	function (template, answer) {
		var _v0 = template.jj;
		if (_v0.$ === 1) {
			return answer.T;
		} else {
			switch (_v0.a.$) {
				case 3:
					var precision = _v0.a.a;
					return A2($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$maskDecimal, precision, answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 0:
					var _v1 = _v0.a;
					return $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$maskWeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 1:
					var _v2 = _v0.a;
					return $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$maskHeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				default:
					var _v3 = _v0.a;
					return $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$maskPressure(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
			}
		}
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewQuestion = F5(
	function (depIdx, question, sharedState, model, doctorQuestion) {
		var stmt = function () {
			if (!depIdx.$) {
				return sharedState.ia.h8.g;
			} else {
				var idx = depIdx.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, sharedState.ia.h8.fh));
			}
		}();
		var showCids = !A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		return A2(
			$elm$core$List$map,
			function (t) {
				var answer = A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
				var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
				return (show || (t.ay >= 1000)) ? A2(
					$elm$html$Html$tr,
					_List_Nil,
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(question.hG.bP)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(t.jh)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$setupMask, t, answer))
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(answer.jx)
									]))
							]),
						showCids ? _List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('chips chips-initial input-field')
											]),
										A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewICDs, stmt.fx, t, depIdx))
									]))
							]) : _List_Nil)) : A2($elm$html$Html$span, _List_Nil, _List_Nil);
			},
			A2(
				$elm$core$List$filter,
				function (t) {
					var answer = A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
					var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
					return show;
				},
				question.bY));
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithHeader = F5(
	function (depIdx, sharedState, model, fullQuestionSection, doctorQuestionSection) {
		var showCids = !A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var sectionName = function () {
			if (sharedState.ia.h8.b4.iR === 'Extramed') {
				var _v0 = fullQuestionSection.hX.a0;
				switch (_v0) {
					case 2:
						return 'Questionário';
					case 1:
						return 'Declaração';
					case 3:
						return 'Atendimento';
					default:
						return 'Desconhecido';
				}
			} else {
				return A2($elm$core$String$left, 50, fullQuestionSection.hX.hl) + '...';
			}
		}();
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '90%')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$colgroup,
					_List_Nil,
					_List_fromArray(
						[
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil)
						])),
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(sectionName)
											])),
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$colspan(2)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Resposta')
											]))
									]),
								showCids ? _List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Obs')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('CIDs')
											]))
									]) : _List_Nil))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, q) {
									return A5(
										$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewQuestion,
										depIdx,
										q,
										sharedState,
										model,
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$FullQuestion$emptyModel,
											$elm$core$List$head(
												A2($elm$core$List$drop, i, doctorQuestionSection.eI))));
								}),
							A2(
								$elm$core$List$sortBy,
								function (sec) {
									return sec.hG.bB;
								},
								A2(
									$elm$core$List$filter,
									function (q) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (t) {
													var answer = A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
													var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
													return show;
												},
												q.bY)) > 0;
									},
									fullQuestionSection.eI)))))
				]));
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithoutHeader = F5(
	function (depIdx, sharedState, model, fullQuestionSection, doctorQuestionSection) {
		return $elm$core$List$concat(
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (i, q) {
						return A5(
							$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewQuestion,
							depIdx,
							q,
							sharedState,
							model,
							A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2($elm$core$List$drop, i, doctorQuestionSection.eI))));
					}),
				A2(
					$elm$core$List$sortBy,
					function (sec) {
						return sec.hG.bB;
					},
					A2(
						$elm$core$List$filter,
						function (q) {
							return $elm$core$List$length(
								A2(
									$elm$core$List$filter,
									function (t) {
										var answer = A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
										var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
										return show;
									},
									q.bY)) > 0;
						},
						fullQuestionSection.eI))));
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewPersonDetails = F3(
	function (sharedState, depIdx, editStmt) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(editStmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$fullPersonName(editStmt.dw))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$formatDocument(editStmt.dw.i1))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Tools$FormHelpers$dateFormat(editStmt.dw.al))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									function (str) {
										return str + ' anos';
									}(
										$elm$core$String$fromInt(
											A2(
												$author$project$App$Data$Person$ageOnDate,
												$author$project$App$Service$ApiWebSocket$getTimestamp(sharedState._),
												editStmt.dw))))
								])),
							sharedState.ia.h8.cJ.gX ? A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									function () {
										if (depIdx.$ === 1) {
											var i = depIdx.a;
											return 'Dependente ' + $elm$core$String$fromInt(i + 1);
										} else {
											return _Utils_eq(editStmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
										}
									}())
								])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
						]))
				]));
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewPersonAnswers = F4(
	function (sharedState, model, depIdx, stmt) {
		var showPerson = sharedState.ia.h8.b4.iR === 'Extramed';
		var showDoctorQuestions = (sharedState.ia.h8.b4.iR === 'Extramed') || (sharedState.ia.h8.b4.iR === 'Centauro-ON');
		var showBMI = sharedState.ia.h8.b4.iR === 'Extramed';
		var doctorSection = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestionSection$emptyModel,
			$elm$core$List$head(
				$elm$core$List$reverse(sharedState.ia.h8.gH)));
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$ul,
							_List_Nil,
							_List_fromArray(
								[
									A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewPersonDetails, sharedState, depIdx, stmt)
								]))
						])),
					showBMI ? A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '100%')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Dados Antropométricos')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Valor')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('CIDs')
												]))
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							A4($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewBMI, sharedState, model, depIdx, stmt))
						])) : $elm$html$Html$text('')
				]),
			_Utils_ap(
				showBMI ? _List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '100%')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Declaração')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Marcado')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Resposta')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Observação Médica')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('CID')
													]))
											]))
									])),
								A2(
								$elm$html$Html$tbody,
								_List_Nil,
								$elm$core$List$concat(
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (i, s) {
												return A5(
													$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithoutHeader,
													depIdx,
													sharedState,
													model,
													s,
													A2(
														$elm$core$Maybe$withDefault,
														$author$project$App$Data$FullQuestionSection$emptyModel,
														$elm$core$List$head(
															A2($elm$core$List$drop, i, sharedState.ia.h8.bs))));
											}),
										A2(
											$elm$core$List$filter,
											function (s) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (q) {
															return $elm$core$List$length(
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var answer = A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
																		var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
																		return show;
																	},
																	q.bY)) > 0;
														},
														A2(
															$elm$core$List$sortBy,
															function (sec) {
																return sec.hG.bB;
															},
															s.eI))) > 0;
											},
											sharedState.ia.h8.bs))))
							]))
					]) : _List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '100%')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Declaração')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Marcado')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Resposta')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Observação Médica')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('CID')
													]))
											]))
									])),
								A2(
								$elm$html$Html$tbody,
								_List_Nil,
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, s) {
											return A5(
												$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithHeader,
												depIdx,
												sharedState,
												model,
												s,
												A2(
													$elm$core$Maybe$withDefault,
													$author$project$App$Data$FullQuestionSection$emptyModel,
													$elm$core$List$head(
														A2($elm$core$List$drop, i, sharedState.ia.h8.bs))));
										}),
									A2(
										$elm$core$List$filter,
										function (s) {
											return $elm$core$List$length(
												A2(
													$elm$core$List$filter,
													function (q) {
														return $elm$core$List$length(
															A2(
																$elm$core$List$filter,
																function (t) {
																	var answer = A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
																	var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
																	return show;
																},
																q.bY)) > 0;
													},
													A2(
														$elm$core$List$sortBy,
														function (sec) {
															return sec.hG.bB;
														},
														s.eI))) > 0;
										},
										sharedState.ia.h8.bs)))
							]))
					]),
				_Utils_ap(
					_List_fromArray(
						[
							showBMI ? A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '100%')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$thead,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Questionário')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Marcado')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Resposta')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Observação Médica')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('CID')
														]))
												]))
										])),
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											F2(
												function (i, s) {
													return A5(
														$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithoutHeader,
														depIdx,
														sharedState,
														model,
														s,
														A2(
															$elm$core$Maybe$withDefault,
															$author$project$App$Data$FullQuestionSection$emptyModel,
															$elm$core$List$head(
																A2($elm$core$List$drop, i, sharedState.ia.h8.jM))));
												}),
											A2(
												$elm$core$List$filter,
												function (s) {
													return $elm$core$List$length(
														A2(
															$elm$core$List$filter,
															function (q) {
																return $elm$core$List$length(
																	A2(
																		$elm$core$List$filter,
																		function (t) {
																			var answer = A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
																			var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
																			return show;
																		},
																		q.bY)) > 0;
															},
															A2(
																$elm$core$List$sortBy,
																function (sec) {
																	return sec.hG.bB;
																},
																s.eI))) > 0;
												},
												sharedState.ia.h8.jM))))
								])) : (showPerson ? A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '100%')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$thead,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Questionário')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Marcado')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Resposta')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Observação Médica')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('CID')
														]))
												]))
										])),
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (i, s) {
												return A5(
													$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithHeader,
													depIdx,
													sharedState,
													model,
													s,
													A2(
														$elm$core$Maybe$withDefault,
														$author$project$App$Data$FullQuestionSection$emptyModel,
														$elm$core$List$head(
															A2($elm$core$List$drop, i, sharedState.ia.h8.jM))));
											}),
										A2(
											$elm$core$List$filter,
											function (s) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (q) {
															return $elm$core$List$length(
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var answer = A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
																		var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																		return show;
																	},
																	q.bY)) > 0;
														},
														A2(
															$elm$core$List$sortBy,
															function (sec) {
																return sec.hG.bB;
															},
															s.eI))) > 0;
											},
											sharedState.ia.h8.jM)))
								])) : $elm$html$Html$text(''))
						]),
					function () {
						if (showDoctorQuestions) {
							return showBMI ? A2(
								$elm$core$List$cons,
								A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'width', '100%')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$colgroup,
											_List_Nil,
											_List_fromArray(
												[
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil)
												])),
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$tr,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$th,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$colspan(5)
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Atendimento')
																]))
														]))
												]))
										])),
								$elm$core$List$concat(
									A2(
										$elm$core$List$map,
										function (s) {
											return A5($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithoutHeader, depIdx, sharedState, model, s, s);
										},
										A2(
											$elm$core$List$filter,
											function (s) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (q) {
															return ($elm$core$List$length(
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var answer = A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
																		var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																		return show;
																	},
																	q.bY)) > 0) && (!A2(
																$elm$core$List$any,
																function (t) {
																	return (t.ay === 10) || (t.ay === 20);
																},
																q.bY));
														},
														A2(
															$elm$core$List$sortBy,
															function (sec) {
																return sec.hG.bB;
															},
															s.eI))) > 0;
											},
											sharedState.ia.h8.gH)))) : A2(
								$elm$core$List$map,
								function (s) {
									return A5($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithHeader, depIdx, sharedState, model, s, s);
								},
								A2(
									$elm$core$List$filter,
									function (s) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (q) {
													return $elm$core$List$length(
														A2(
															$elm$core$List$filter,
															function (t) {
																var answer = A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
																var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																return show;
															},
															q.bY)) > 0;
												},
												A2(
													$elm$core$List$sortBy,
													function (sec) {
														return sec.hG.bB;
													},
													s.eI))) > 0;
									},
									sharedState.ia.h8.gH));
						} else {
							var question = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(doctorSection.eI));
							var template = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$AnswerTemplate$emptyModel,
								$elm$core$List$head(question.bY));
							var answer = A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, template.as);
							return (answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0))) ? _List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Evoluções médicas:')
										])),
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(answer.T)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Atestado Médico:')
										])),
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(answer.jx)
										]),
									_List_Nil)
								]) : _List_Nil;
						}
					}())));
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewQuestions = F2(
	function (model, sharedState) {
		var depsPersonAnswers = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$second,
				$elm$core$Dict$toList(
					A2(
						$elm$core$Dict$map,
						F2(
							function (idx, dep) {
								return A4(
									$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewPersonAnswers,
									sharedState,
									model,
									$author$project$App$Data$Statement$depIndexFromInt(idx),
									dep);
							}),
						sharedState.ia.h8.fh))));
		var bearerPersonAnswers = (!(sharedState.ia.h8.g.e.j_ === 2)) ? A4($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewPersonAnswers, sharedState, model, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g) : _List_Nil;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('questions')
				]),
			_Utils_ap(
				bearerPersonAnswers,
				_Utils_ap(
					depsPersonAnswers,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Conclusões finais do atendimento:')
								])),
							A2(
							$elm$html$Html$textarea,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(model.P.g.e.jx)
								]),
							_List_Nil)
						]))));
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$SelectDetailsTab = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabCPT = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabDocument = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewDocumentTabNumered = F4(
	function (currentTab, idx, doc, documentTabs) {
		var index = $elm$core$List$length(documentTabs) + 1;
		return A2(
			$elm$core$List$append,
			documentTabs,
			function () {
				var _v0 = doc.dr;
				switch (_v0) {
					case 'video/webm':
						return _List_fromArray(
							[
								$elm$html$Html$text('')
							]);
					case 'video/mp4':
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$SelectDetailsTab(
											$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										'Video' + $elm$core$String$fromInt(index))
									]))
							]);
					default:
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$SelectDetailsTab(
											$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											var _v1 = doc.b7;
											switch (_v1) {
												case 1:
													return 'Parecer Antigo' + $elm$core$String$fromInt(index);
												case 3:
													return 'Atestado' + $elm$core$String$fromInt(index);
												case 4:
													return 'Receita' + $elm$core$String$fromInt(index);
												default:
													return 'Anexo';
											}
										}())
									]))
							]);
				}
			}());
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabMedicalDocument = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewResultTab = F5(
	function (previewTitle, typeHnsg, currentTab, idx, d) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$SelectDetailsTab(
							$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(previewTitle)
					])),
				typeHnsg ? A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$SelectDetailsTab(
							$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Pré-Visualizar Atestado')
					])) : A2($elm$html$Html$span, _List_Nil, _List_Nil)
			]);
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewQuestionsTabs = F5(
	function (fullStmt, viewCPT, typeHnsg, currentTab, docs) {
		var reportDocs = A2(
			$elm$core$List$sortWith,
			F2(
				function (a, b) {
					var _v8 = A2(
						$elm$core$Basics$compare,
						$elm$time$Time$posixToMillis(a.b.iW),
						$elm$time$Time$posixToMillis(b.b.iW));
					switch (_v8) {
						case 0:
							return 2;
						case 1:
							return 1;
						default:
							return 0;
					}
				}),
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v7, doc) {
							return doc.b7 === 1;
						}),
					docs)));
		var previewTitle = typeHnsg ? 'Pré-Visualizar Prontuário' : 'Pré-Visualizar Parecer';
		var resultTab = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$second,
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$map,
							A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewResultTab, previewTitle, typeHnsg, currentTab),
							$elm$core$Dict$fromList(
								A2($elm$core$List$take, 1, reportDocs)))))));
		var oldResultsTab = A3(
			$elm$core$Dict$foldl,
			$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewDocumentTabNumered(currentTab),
			_List_Nil,
			$elm$core$Dict$fromList(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(reportDocs))));
		var docsTabs = A3(
			$elm$core$Dict$foldl,
			F3(
				function (i, d, list) {
					var attachName = function () {
						var _v6 = d.dr;
						if (_v6 === 'video/mp4') {
							return 'Video';
						} else {
							return (d.b7 === 4) ? 'Receita' : ('Anexo ' + $elm$core$String$fromInt(i));
						}
					}();
					return A2(
						$elm$core$List$append,
						list,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabDocument(i)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$SelectDetailsTab(
											$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabDocument(i))),
										$elm$html$Html$Attributes$href('#')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(attachName)
									]))
							]));
				}),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'tab-selected',
									_Utils_eq(currentTab, $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabCIDs))
								])),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$SelectDetailsTab($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabCIDs))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Dados do Atendimento')
						]))
				]),
			A2(
				$elm$core$Dict$filter,
				F2(
					function (_v5, d) {
						return d.b7 !== 3;
					}),
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v4, d) {
							return d.b7 !== 5;
						}),
					A2(
						$elm$core$Dict$filter,
						F2(
							function (_v3, d) {
								return d.b7 !== 1;
							}),
						$elm$core$Dict$fromList(
							_Utils_ap(
								A2(
									$elm$core$List$take,
									1,
									$elm$core$List$reverse(
										$elm$core$Dict$toList(
											A2(
												$elm$core$Dict$filter,
												F2(
													function (_v1, doc) {
														return doc.dr === 'video/mp4';
													}),
												docs)))),
								$elm$core$Dict$toList(
									A2(
										$elm$core$Dict$filter,
										F2(
											function (_v2, doc) {
												return (doc.dr !== 'video/webm') && (doc.dr !== 'video/mp4');
											}),
										docs))))))));
		var bearerCpt = (fullStmt.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$SelectDetailsTab(
							$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('CPT Titular')
					]))
			]);
		var cptTabs = viewCPT ? A2(
			$elm$core$List$append,
			bearerCpt,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var idx = _v0.a;
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('#'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'tab-selected',
										_Utils_eq(
											currentTab,
											$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabCPT(
												$author$project$App$Data$Statement$Dependent(idx))))
									])),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$SelectDetailsTab(
									$author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$TabCPT(
										$author$project$App$Data$Statement$Dependent(idx))))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'CPT Dep ' + $elm$core$String$fromInt(idx + 1))
							]));
				},
				$elm$core$Dict$toList(fullStmt.fh))) : _List_Nil;
		var allTabs = _Utils_ap(
			docsTabs,
			_Utils_ap(
				resultTab,
				_Utils_ap(oldResultsTab, cptTabs)));
		return A2($elm$html$Html$div, _List_Nil, allTabs);
	});
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewResultContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewResultLayout(fullStmt);
};
var $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$view = F3(
	function (now, sharedState, model) {
		var viewCPT = sharedState.ia.h8.b4.iR === 'Extramed';
		var typeHnsg = A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var maybePdfResult = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var maybePdfDocument = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var hasMedicalReport = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var hasMedicalDocument = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var dialogView = $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$dialogFromModel(model);
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$header,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('tabs')
								]),
							_List_fromArray(
								[
									A5($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewQuestionsTabs, sharedState.ia.h8, viewCPT, typeHnsg, model.b5, sharedState.ia.h8.an),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('cancel-button'),
													$elm$html$Html$Events$onClick($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$BackToFinished)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Retornar')
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$ToggleDialog)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Reavaliar')
												]))
										]))
								])),
							function () {
							var _v0 = model.b5;
							switch (_v0.$) {
								case 0:
									return A2($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewQuestions, model, sharedState);
								case 1:
									var docIdx = _v0.a;
									return $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewDoc(
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$Document$emptyModel,
											A2($elm$core$Dict$get, docIdx, model.an)));
								case 2:
									var mDepIdx = _v0.a;
									if (!maybePdfResult.$) {
										var pdfResult = maybePdfResult.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewResultContent(sharedState.ia.h8);
									}
								case 3:
									var mDepIdx = _v0.a;
									if (!maybePdfDocument.$) {
										var pdfResult = maybePdfDocument.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewMedicalDocumentContent(model.P);
									}
								case 5:
									var videoRepo = {f3: 'video-repo-enterprise-covid.ingress.medsyn.com.br'};
									var videoUrl = A2($author$project$App$Data$VideoRepository$statementVideoUrl, videoRepo, sharedState.ia.h8.g.e.as);
									return A2(
										$elm$html$Html$video,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('video-preview'),
												$elm$html$Html$Attributes$controls(true)
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$source,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$src(videoUrl),
														$elm$html$Html$Attributes$type_('video/mp4')
													]),
												_List_Nil),
												$elm$html$Html$text('Seu browser não suporte exibição de vídeo HTML5')
											]));
								case 4:
									var depIdx = _v0.a;
									var fullStmt = sharedState.ia.h8;
									return A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewCPTContent, depIdx, fullStmt, now);
								default:
									return $elm$html$Html$text('');
							}
						}()
						]))
				]),
			_Utils_ap(
				A2($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$viewICDSection, sharedState, model),
				_List_fromArray(
					[dialogView])));
	});
var $author$project$App$Page$PageAdmin$view = F3(
	function (st, now, model) {
		switch (model.$) {
			case 0:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageAdmin$ListUsersMsg),
					A2($author$project$App$Page$Admin$PageListUsers$view, st, pageModel));
			case 1:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageAdmin$ListDoctorsMsg),
					A2($author$project$App$Page$Admin$PageListDoctors$view, st, pageModel));
			case 2:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageAdmin$FinanceSalesMsg),
					$author$project$App$Page$Admin$PageFinanceSales$view(pageModel));
			case 3:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageAdmin$ListFinishedMsg),
					A2($author$project$App$Page$Admin$PageListFinished$view, st, pageModel));
			case 6:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageAdmin$ViewEvaluationMsg),
					A3($author$project$App$Page$Admin$ViewEvaluation$PageViewEvaluation$view, now, st, pageModel));
			case 4:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageAdmin$ListReevaluationMsg),
					A2($author$project$App$Page$Admin$PageListReevaluation$view, st, pageModel));
			default:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageAdmin$ReevaluationMsg),
					A3($author$project$App$Page$Admin$PageReevaluation$view, now, st, pageModel));
		}
	});
var $author$project$App$Page$Company$PageListCanceled$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Company$PageListCanceled$HideDetails = {$: 5};
var $author$project$App$Page$Company$PageListCanceled$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var selectedStmtId = function () {
			var _v4 = model.gD;
			if (_v4.$ === 1) {
				var stmtId = _v4.a;
				return $elm$core$Maybe$Just(stmtId);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var onClickMsg = function (stmtId) {
			var _v3 = model.gD;
			if (_v3.$ === 1) {
				return $author$project$App$Page$Company$PageListCanceled$HideDetails;
			} else {
				return $author$project$App$Page$Company$PageListCanceled$ViewDetails(stmtId);
			}
		};
		var _v0 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v0.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v0.a.$) {
				case 3:
					var person = _v0.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'user-selected',
										_Utils_eq(
											selectedStmtId,
											$elm$core$Maybe$Just(stmt.as)))
									])),
								$elm$html$Html$Events$onClick(
								onClickMsg(stmt.as))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status:')
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$StatementStatus$statusToText(stmt.aw))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Person$fullPersonName(person))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
											])),
										(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Somente titular')
											])) : A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Somente dependente')
											])))) : A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$elm$core$String$fromInt(stmt.bo) + ' dependentes')
											]))
									]))
							]));
				case 2:
					var err = _v0.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v1 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v2 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Company$PageListCanceled$view = F2(
	function (st, model) {
		var viewStatements = ($elm$core$List$length(model.bk) > 0) ? A2(
			$elm$core$List$map,
			A2($author$project$App$Page$Company$PageListCanceled$viewUserListRow, model, st.ia.h8.cJ),
			model.bk) : _List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Nenhum item encontrado.')
					]))
			]);
		var viewDetails = function () {
			var _v0 = model.gD;
			if (_v0.$ === 1) {
				var viewFullStmt = _v0.b;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Company$PageListCanceled$ViewFullStmtMsg,
							A2($author$project$App$Components$ViewFullStatement$view, st, viewFullStmt))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Atendimentos Cancelados')
									])),
								A2(
								$elm$html$Html$map,
								$author$project$App$Page$Company$PageListCanceled$FilterMsg,
								$author$project$App$Components$Filter$view(model.b))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								viewStatements)
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Company$PageListCanceled$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					])),
				viewDetails
			]);
	});
var $author$project$App$Page$Company$PageListConcluded$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Company$PageListConcluded$Evaluate = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Company$PageListConcluded$HideDetails = {$: 5};
var $author$project$App$Page$Company$PageListConcluded$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var selectedStmtId = function () {
			var _v4 = model.gD;
			if (_v4.$ === 1) {
				var stmtId = _v4.a;
				return $elm$core$Maybe$Just(stmtId);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var onClickMsg = function (stmtId) {
			var _v3 = model.gD;
			if (_v3.$ === 1) {
				return $author$project$App$Page$Company$PageListConcluded$HideDetails;
			} else {
				return $author$project$App$Page$Company$PageListConcluded$Evaluate(stmtId);
			}
		};
		var _v0 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v0.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v0.a.$) {
				case 3:
					var person = _v0.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'user-selected',
										_Utils_eq(
											selectedStmtId,
											$elm$core$Maybe$Just(stmt.as)))
									])),
								$elm$html$Html$Events$onClick(
								onClickMsg(stmt.as))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status:')
											])),
										(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Somente titular')
											])) : A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Somente dependente')
											])))) : A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$elm$core$String$fromInt(stmt.bo) + ' dependentes')
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Person$fullPersonName(person))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Última atualização: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.f2))
											]))
									]))
							]));
				case 2:
					var err = _v0.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v1 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v2 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Company$PageListConcluded$view = F2(
	function (st, model) {
		var viewStatements = ($elm$core$List$length(model.bk) > 0) ? A2(
			$elm$core$List$map,
			A2($author$project$App$Page$Company$PageListConcluded$viewUserListRow, model, st.ia.h8.cJ),
			model.bk) : _List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Nenhum item encontrado.')
					]))
			]);
		var viewDetails = function () {
			var _v0 = model.gD;
			if (_v0.$ === 1) {
				var viewFullStmt = _v0.b;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Company$PageListConcluded$ViewFullStmtMsg,
							A2($author$project$App$Components$ViewFullStatement$view, st, viewFullStmt))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Atendimentos Concluídos')
									])),
								A2(
								$elm$html$Html$map,
								$author$project$App$Page$Company$PageListConcluded$FilterMsg,
								$author$project$App$Components$Filter$view(model.b))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								viewStatements)
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Company$PageListConcluded$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					])),
				viewDetails
			]);
	});
var $author$project$App$Page$Company$PageListPending$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Company$PageListPending$RequestInterview = {$: 3};
var $author$project$App$Page$Company$PageListPending$compareStatementUpdate = F2(
	function (a, b) {
		var _v0 = A2(
			$elm$core$Basics$compare,
			$elm$time$Time$posixToMillis(a.f2),
			$elm$time$Time$posixToMillis(b.f2));
		switch (_v0) {
			case 2:
				return 0;
			case 0:
				return 2;
			default:
				return 1;
		}
	});
var $author$project$App$Page$Company$PageListPending$EditInterview = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Company$PageListPending$HideInterview = {$: 6};
var $author$project$App$Page$Company$PageListPending$RequestReview = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Company$PageListPending$RequestUnschedule = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$Company$PageListPending$viewUserListRow = F5(
	function (st, model, stmtTemplate, company, stmt) {
		var unscheduleButton = (company.iR !== 'HNSG') ? $elm$html$Html$text('') : A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list-buttons'),
					$elm$html$Html$Attributes$title('Cancelar Agendamento'),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Company$PageListPending$RequestUnschedule(stmt.as))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('close')
						]))
				]));
		var reviewButton = _Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list-buttons'),
					$elm$html$Html$Attributes$title('Enviar para Revisão'),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Company$PageListPending$RequestReview(stmt.as))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('done')
						]))
				])) : $elm$html$Html$text('');
		var editButton = _Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list-buttons'),
					$elm$html$Html$Attributes$title('Alterar Cadastro'),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Company$PageListPending$EditInterview(stmt.as))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('edit')
						]))
				])) : $elm$html$Html$text('');
		var _v0 = function () {
			var _v1 = _Utils_Tuple2(
				stmt.aw,
				_Utils_eq(
					model.aU,
					$elm$core$Maybe$Just(stmt.as)));
			_v1$0:
			while (true) {
				if (!_v1.b) {
					switch (_v1.a) {
						case 0:
							break _v1$0;
						case 2:
							var _v4 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Company$PageListPending$ViewInterview(stmt.as))
									]),
								_List_fromArray(
									[unscheduleButton]));
						case 3:
							var _v6 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Company$PageListPending$ViewInterview(stmt.as))
									]),
								_List_fromArray(
									[unscheduleButton]));
						case 4:
							var _v8 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Company$PageListPending$ViewInterview(stmt.as))
									]),
								_List_fromArray(
									[unscheduleButton]));
						case 5:
							var _v10 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Company$PageListPending$ViewInterview(stmt.as))
									]),
								_List_fromArray(
									[unscheduleButton]));
						case 6:
							var _v12 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Company$PageListPending$ViewInterview(stmt.as))
									]),
								_List_fromArray(
									[unscheduleButton]));
						case 7:
							var _v14 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Company$PageListPending$ViewInterview(stmt.as))
									]),
								_List_fromArray(
									[unscheduleButton]));
						case 9:
							var _v16 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Company$PageListPending$ViewInterview(stmt.as))
									]),
								_List_fromArray(
									[unscheduleButton]));
						case 11:
							var _v18 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Company$PageListPending$ViewInterview(stmt.as))
									]),
								_List_fromArray(
									[unscheduleButton]));
						default:
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Company$PageListPending$ViewInterview(stmt.as))
									]),
								_List_Nil);
					}
				} else {
					switch (_v1.a) {
						case 0:
							break _v1$0;
						case 2:
							var _v3 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageListPending$HideInterview)
									]),
								_List_fromArray(
									[unscheduleButton]));
						case 3:
							var _v5 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageListPending$HideInterview)
									]),
								_List_fromArray(
									[unscheduleButton]));
						case 4:
							var _v7 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageListPending$HideInterview)
									]),
								_List_fromArray(
									[unscheduleButton]));
						case 5:
							var _v9 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageListPending$HideInterview)
									]),
								_List_fromArray(
									[unscheduleButton]));
						case 6:
							var _v11 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageListPending$HideInterview)
									]),
								_List_fromArray(
									[unscheduleButton]));
						case 7:
							var _v13 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageListPending$HideInterview)
									]),
								_List_fromArray(
									[unscheduleButton]));
						case 9:
							var _v15 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageListPending$HideInterview)
									]),
								_List_fromArray(
									[unscheduleButton]));
						case 11:
							var _v17 = _v1.a;
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageListPending$HideInterview)
									]),
								_List_fromArray(
									[unscheduleButton]));
						default:
							return _Utils_Tuple2(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageListPending$HideInterview)
									]),
								_List_Nil);
					}
				}
			}
			var _v2 = _v1.a;
			return _Utils_Tuple2(
				_List_Nil,
				_List_fromArray(
					[editButton, reviewButton]));
		}();
		var onClickMsg = _v0.a;
		var buttons = _v0.b;
		var _v19 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v19.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v19.a.$) {
				case 3:
					var person = _v19.a.a;
					if ((_Utils_cmp(
						$author$project$App$Data$StatementStatus$statusToInt(stmt.aw),
						$author$project$App$Data$StatementStatus$statusToInt(12)) > -1) && (_Utils_cmp(
						$author$project$App$Data$StatementStatus$statusToInt(stmt.aw),
						$author$project$App$Data$StatementStatus$statusToInt(21)) < 1)) {
						var mDoctor = $elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (doc) {
									return _Utils_eq(
										stmt.fl,
										$elm$core$Maybe$Just(doc.as));
								},
								$author$project$App$Service$ApplicationResources$getAllCompanyPeople(st._)));
						var doctorFullName = function () {
							if (!mDoctor.$) {
								var doctor = mDoctor.a;
								return doctor.hl + (' ' + doctor.ii);
							} else {
								return '(carregando...)';
							}
						}();
						return A2(
							$elm$html$Html$li,
							_Utils_ap(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-item')
									]),
								onClickMsg),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											$author$project$App$Data$Person$photoUrl(person))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('user-description')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$App$Data$Person$fullPersonName(person))
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$App$Data$StatementStatus$statusToText(stmt.aw))
												])),
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('cannot-be-taken')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Sendo atendido por ' + doctorFullName)
												]))
										]))
								]));
					} else {
						return A2(
							$elm$html$Html$li,
							_Utils_ap(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-item'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'user-selected',
												_Utils_eq(
													model.aU,
													$elm$core$Maybe$Just(stmt.as)))
											]))
									]),
								onClickMsg),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											$author$project$App$Data$Person$photoUrl(person))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('user-description')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Status:')
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$App$Data$StatementStatus$statusToText(stmt.aw))
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('user-description')
										]),
									_Utils_ap(
										buttons,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text(
														$author$project$App$Data$Person$fullPersonName(person))
													])),
												A2(
												$elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text(
														'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
													])),
												(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
												$elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Somente titular')
													])) : A2(
												$elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Somente dependente')
													])))) : A2(
												$elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text(
														$elm$core$String$fromInt(stmt.bo) + ' dependentes')
													]))
											])))
								]));
					}
				case 2:
					var err = _v19.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v21 = _v19.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v22 = _v19.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Company$PageListPending$view = F2(
	function (st, model) {
		var viewStatements = ($elm$core$List$length(model.bk) > 0) ? A2(
			$elm$core$List$map,
			A4($author$project$App$Page$Company$PageListPending$viewUserListRow, st, model, st.ia.h8.cJ, st.ia.h8.b4),
			A2($elm$core$List$sortWith, $author$project$App$Page$Company$PageListPending$compareStatementUpdate, model.bk)) : _List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Nenhum item encontrado.')
					]))
			]);
		var viewDetails = function () {
			var _v0 = model.av;
			if (!_v0.$) {
				var viewFullStmt = _v0.a;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Company$PageListPending$ViewFullStmtMsg,
							A2($author$project$App$Components$ViewFullStatement$view, st, viewFullStmt))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Atendimentos em Andamento')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('action-buttons')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$type_('button'),
												$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageListPending$RequestInterview)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Cadastrar')
											])),
										A2(
										$elm$html$Html$map,
										$author$project$App$Page$Company$PageListPending$FilterMsg,
										$author$project$App$Components$Filter$view(model.b))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								viewStatements)
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Company$PageListPending$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					])),
				viewDetails
			]);
	});
var $author$project$App$Page$Company$PageListReevaluation$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Company$PageListReevaluation$Evaluate = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Company$PageListReevaluation$HideInterview = {$: 4};
var $author$project$App$Page$Company$PageListReevaluation$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var _v0 = function () {
			var _v1 = _Utils_Tuple2(
				stmt.aw,
				_Utils_eq(
					model.aU,
					$elm$core$Maybe$Just(stmt.as)));
			if (!_v1.b) {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Company$PageListReevaluation$Evaluate(stmt.as))
						]),
					_List_Nil);
			} else {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageListReevaluation$HideInterview)
						]),
					_List_Nil);
			}
		}();
		var onClickMsg = _v0.a;
		var buttons = _v0.b;
		var _v2 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v2.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v2.a.$) {
				case 3:
					var person = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_Utils_ap(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('user-item'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'user-selected',
											_Utils_eq(
												model.aU,
												$elm$core$Maybe$Just(stmt.as)))
										]))
								]),
							onClickMsg),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status:')
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$StatementStatus$statusToText(stmt.aw))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_Utils_ap(
									buttons,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$App$Data$Person$fullPersonName(person))
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
												])),
											(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente titular')
												])) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente dependente')
												])))) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$elm$core$String$fromInt(stmt.bo) + ' dependentes')
												]))
										])))
							]));
				case 2:
					var err = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v3 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v4 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Company$PageListReevaluation$viewStatements = F2(
	function (model, stmtTemplate) {
		var _v0 = model.bk;
		if (!_v0.b) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Nenhum atendimento em reavaliação')
						]))
				]);
		} else {
			var statements = _v0;
			return A2(
				$elm$core$List$map,
				A2($author$project$App$Page$Company$PageListReevaluation$viewUserListRow, model, stmtTemplate),
				statements);
		}
	});
var $author$project$App$Page$Company$PageListReevaluation$view = F2(
	function (sharedState, model) {
		var viewDetails = function () {
			var _v0 = model.av;
			if (!_v0.$) {
				var viewFullStmt = _v0.a;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Company$PageListReevaluation$ViewFullStmtMsg,
							A2(
								$author$project$App$Components$ViewFullStatement$view,
								sharedState,
								_Utils_update(
									viewFullStmt,
									{a5: sharedState.ia.h8})))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Em Reavaliação')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('action-buttons')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$map,
										$author$project$App$Page$Company$PageListReevaluation$FilterMsg,
										$author$project$App$Components$Filter$view(model.b))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								A2($author$project$App$Page$Company$PageListReevaluation$viewStatements, model, sharedState.ia.h8.cJ))
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Company$PageListReevaluation$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					])),
				viewDetails
			]);
	});
var $author$project$App$Page$Company$PageReevaluation$BackToFinished = {$: 1};
var $author$project$App$Page$Company$PageReevaluation$FinishReevaluation = {$: 4};
var $author$project$App$Page$Company$PageReevaluation$SendToReview = {$: 3};
var $author$project$App$Page$Company$PageReevaluation$ToggleDialog = {$: 2};
var $author$project$App$Page$Company$PageReevaluation$UpdateReviewMessage = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Company$PageReevaluation$dialogFromModel = function (model) {
	var header = _List_Nil;
	var footer = _List_fromArray(
		[
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cancel-button'),
					$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageReevaluation$ToggleDialog)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Cancelar')
				])),
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageReevaluation$SendToReview)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Devolver')
				]))
		]);
	var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
	var body = _List_fromArray(
		[
			A2(
			$elm$html$Html$textarea,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$cols(50),
					$elm$html$Html$Attributes$rows(5),
					$elm$html$Html$Attributes$value(model.eN),
					$elm$html$Html$Events$onInput($author$project$App$Page$Company$PageReevaluation$UpdateReviewMessage)
				]),
			_List_Nil)
		]);
	var completeConfig = _Utils_update(
		config,
		{
			e5: $elm$core$Maybe$Just(body),
			ft: $elm$core$Maybe$Just(footer),
			gY: $elm$core$Maybe$Just(header),
			f1: 'Motivo da Reavaliação'
		});
	return model.dC ? $author$project$App$Components$Dialog$view(
		$elm$core$Maybe$Just(completeConfig)) : $elm$html$Html$text('');
};
var $author$project$App$Page$Company$PageReevaluation$viewDocumentContent = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Page$Company$PageReevaluation$viewCPTContent = F3(
	function (mDepIdx, fullStmt, now) {
		var cptDoc = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Document$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					function (_v1) {
						var i = _v1.a;
						var d = _v1.b;
						return d;
					},
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var d = _v0.b;
							return _Utils_eq(d.jJ, fullStmt.g.e.as) && (d.b7 === 5);
						},
						$elm$core$Dict$toList(fullStmt.an)))));
		return $author$project$App$Data$Id$uuidIsValid(cptDoc.as) ? $author$project$App$Page$Company$PageReevaluation$viewDocumentContent(cptDoc) : A3($author$project$App$Legacy$Doctor$ResultLayout$Common$viewCPTLayout, mDepIdx, fullStmt, now);
	});
var $author$project$App$Page$Company$PageReevaluation$AddDPS = {$: 10};
var $author$project$App$Page$Company$PageReevaluation$viewDoc = function (doc) {
	return (!doc.b7) ? A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pdfviewer')
					]),
				(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
					[
						$elm$html$Html$text('Nenhum PDF carregado')
					]) : _List_fromArray(
					[
						$author$project$App$Data$Document$view(doc),
						$elm$html$Html$text('                    ')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'justify-content', 'center'),
						A2($elm$html$Html$Attributes$style, 'margin-bottom', '2em')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageReevaluation$AddDPS),
								$elm$html$Html$Attributes$value('Adicionar DPS')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Adicionar DPS')
							]))
					]))
			])) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Page$Company$PageReevaluation$viewPersonICD = F4(
	function (now, mDepIdx, stmt, sharedState) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(stmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					A3(
						$elm$core$Dict$foldr,
						F3(
							function (cat, icd, html) {
								return A2(
									$elm$core$List$append,
									html,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('chip'),
													$elm$html$Html$Attributes$title(icd.am)
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(cat)
														]))
												]))
										]));
							}),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Data$Person$fullPersonName(stmt.dw))
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(
											A2($author$project$App$Data$Person$ageOnDate, now, stmt.dw)) + ' anos')
									])),
								sharedState.ia.h8.cJ.gX ? A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											if (mDepIdx.$ === 1) {
												var i = mDepIdx.a;
												return 'Dependente ' + $elm$core$String$fromInt(i + 1);
											} else {
												return _Utils_eq(stmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
											}
										}())
									])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
							]),
						A3(
							$elm$core$Dict$foldr,
							F3(
								function (_v0, qCatDict, catDict) {
									return A3(
										$elm$core$Dict$foldl,
										F3(
											function (cat, icd, catDictInner) {
												return A3($elm$core$Dict$insert, cat, icd, catDictInner);
											}),
										catDict,
										qCatDict);
								}),
							$elm$core$Dict$empty,
							stmt.fx)))
				]));
	});
var $author$project$App$Page$Company$PageReevaluation$viewICDSection = F2(
	function (sharedState, model) {
		var chatView = $author$project$App$Components$Chatbox$view;
		var bearerPersonIcd = (sharedState.ia.h8.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A4($author$project$App$Page$Company$PageReevaluation$viewPersonICD, model.ey, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g, sharedState)
			]);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tabs ')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Dados por Pessoa')
									])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('material-icons')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('print')
													]))
											]))
									]))
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Company$PageReevaluation$ChatBoxMsg,
						chatView(model.aM)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('person-icds')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_Nil,
								A3(
									$elm$core$Dict$foldl,
									F3(
										function (i, dep, html) {
											return A2(
												$elm$core$List$append,
												html,
												_List_fromArray(
													[
														A4(
														$author$project$App$Page$Company$PageReevaluation$viewPersonICD,
														model.ey,
														$author$project$App$Data$Statement$Dependent(i),
														dep,
														sharedState)
													]));
										}),
									bearerPersonIcd,
									sharedState.ia.h8.fh))
							]))
					]))
			]);
	});
var $author$project$App$Page$Company$PageReevaluation$viewMedicalDocumentContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewMedicalDocumentLayout(fullStmt);
};
var $author$project$App$Page$Company$PageReevaluation$SelectDetailsTab = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Company$PageReevaluation$TabCPT = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Company$PageReevaluation$viewDocumentTabNumered = F4(
	function (currentTab, idx, doc, documentTabs) {
		var index = $elm$core$List$length(documentTabs) + 1;
		return A2(
			$elm$core$List$append,
			documentTabs,
			function () {
				var _v0 = doc.dr;
				switch (_v0) {
					case 'video/webm':
						return _List_fromArray(
							[
								$elm$html$Html$text('')
							]);
					case 'video/mp4':
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Company$PageReevaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Company$PageReevaluation$SelectDetailsTab(
											$author$project$App$Page$Company$PageReevaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										'Video' + $elm$core$String$fromInt(index))
									]))
							]);
					default:
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Company$PageReevaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Company$PageReevaluation$SelectDetailsTab(
											$author$project$App$Page$Company$PageReevaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											var _v1 = doc.b7;
											switch (_v1) {
												case 1:
													return 'Parecer Antigo' + $elm$core$String$fromInt(index);
												case 3:
													return 'Atestado' + $elm$core$String$fromInt(index);
												case 4:
													return 'Receita' + $elm$core$String$fromInt(index);
												case 5:
													return 'CPT' + $elm$core$String$fromInt(index);
												default:
													return 'Anexo';
											}
										}())
									]))
							]);
				}
			}());
	});
var $author$project$App$Page$Company$PageReevaluation$TabMedicalDocument = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Company$PageReevaluation$viewResultTab = F5(
	function (previewTitle, typeHnsg, currentTab, idx, d) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Company$PageReevaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Company$PageReevaluation$SelectDetailsTab(
							$author$project$App$Page$Company$PageReevaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(previewTitle)
					])),
				typeHnsg ? A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Company$PageReevaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Company$PageReevaluation$SelectDetailsTab(
							$author$project$App$Page$Company$PageReevaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Pré-Visualizar Atestado')
					])) : A2($elm$html$Html$span, _List_Nil, _List_Nil)
			]);
	});
var $author$project$App$Page$Company$PageReevaluation$viewQuestionsTabs = F5(
	function (fullStmt, viewCPT, typeHnsg, currentTab, docs) {
		var reportDocs = A2(
			$elm$core$List$sortWith,
			F2(
				function (a, b) {
					var _v10 = A2(
						$elm$core$Basics$compare,
						$elm$time$Time$posixToMillis(a.b.iW),
						$elm$time$Time$posixToMillis(b.b.iW));
					switch (_v10) {
						case 0:
							return 2;
						case 1:
							return 1;
						default:
							return 0;
					}
				}),
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v9, doc) {
							return doc.b7 === 1;
						}),
					docs)));
		var previewTitle = typeHnsg ? 'Prontuário' : 'Parecer';
		var resultTab = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$second,
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$map,
							A3($author$project$App$Page$Company$PageReevaluation$viewResultTab, previewTitle, typeHnsg, currentTab),
							$elm$core$Dict$fromList(
								A2($elm$core$List$take, 1, reportDocs)))))));
		var oldResultsTab = A3(
			$elm$core$Dict$foldl,
			$author$project$App$Page$Company$PageReevaluation$viewDocumentTabNumered(currentTab),
			_List_Nil,
			$elm$core$Dict$fromList(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(reportDocs))));
		var docsTabs = A3(
			$elm$core$Dict$foldl,
			F3(
				function (i, d, list) {
					var attachName = function () {
						var _v8 = d.dr;
						if (_v8 === 'video/mp4') {
							return 'Video';
						} else {
							return 'Anexo ' + $elm$core$String$fromInt(i);
						}
					}();
					return A2(
						$elm$core$List$append,
						list,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Company$PageReevaluation$TabDocument(i)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Company$PageReevaluation$SelectDetailsTab(
											$author$project$App$Page$Company$PageReevaluation$TabDocument(i))),
										$elm$html$Html$Attributes$href('#')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(attachName)
									]))
							]));
				}),
			_List_Nil,
			A2(
				$elm$core$Dict$filter,
				F2(
					function (_v7, d) {
						return d.b7 !== 5;
					}),
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v6, d) {
							return d.b7 !== 3;
						}),
					A2(
						$elm$core$Dict$filter,
						F2(
							function (_v5, d) {
								return d.b7 !== 1;
							}),
						$elm$core$Dict$fromList(
							_Utils_ap(
								A2(
									$elm$core$List$take,
									1,
									$elm$core$List$reverse(
										$elm$core$Dict$toList(
											A2(
												$elm$core$Dict$filter,
												F2(
													function (_v3, doc) {
														return doc.dr === 'video/mp4';
													}),
												docs)))),
								$elm$core$Dict$toList(
									A2(
										$elm$core$Dict$filter,
										F2(
											function (_v4, doc) {
												return (doc.dr !== 'video/webm') && (doc.dr !== 'video/mp4');
											}),
										docs))))))));
		var cptDocs = A2(
			$elm$core$List$sortWith,
			F2(
				function (a, b) {
					var _v2 = A2(
						$elm$core$Basics$compare,
						$elm$time$Time$posixToMillis(a.b.iW),
						$elm$time$Time$posixToMillis(b.b.iW));
					switch (_v2) {
						case 0:
							return 2;
						case 1:
							return 1;
						default:
							return 0;
					}
				}),
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v1, doc) {
							return doc.b7 === 5;
						}),
					docs)));
		var bearerCpt = (fullStmt.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Company$PageReevaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Company$PageReevaluation$SelectDetailsTab(
							$author$project$App$Page$Company$PageReevaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('CPT Titular')
					]))
			]);
		var cptTabs = viewCPT ? A2(
			$elm$core$List$append,
			bearerCpt,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var idx = _v0.a;
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('#'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'tab-selected',
										_Utils_eq(
											currentTab,
											$author$project$App$Page$Company$PageReevaluation$TabCPT(
												$author$project$App$Data$Statement$Dependent(idx))))
									])),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Company$PageReevaluation$SelectDetailsTab(
									$author$project$App$Page$Company$PageReevaluation$TabCPT(
										$author$project$App$Data$Statement$Dependent(idx))))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'CPT Dep ' + $elm$core$String$fromInt(idx + 1))
							]));
				},
				$elm$core$Dict$toList(fullStmt.fh))) : _List_Nil;
		var cptDocumentsTab = ($elm$core$List$length(cptDocs) > 1) ? _Utils_ap(
			A3(
				$elm$core$Dict$foldl,
				$author$project$App$Page$Company$PageReevaluation$viewDocumentTabNumered(currentTab),
				_List_Nil,
				$elm$core$Dict$fromList(
					A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						$elm$core$List$tail(cptDocs)))),
			cptTabs) : cptTabs;
		var allTabs = _Utils_ap(
			docsTabs,
			_Utils_ap(
				resultTab,
				_Utils_ap(oldResultsTab, cptDocumentsTab)));
		return A2($elm$html$Html$div, _List_Nil, allTabs);
	});
var $author$project$App$Page$Company$PageReevaluation$viewResultContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewResultLayout(fullStmt);
};
var $author$project$App$Page$Company$PageReevaluation$view = F3(
	function (now, sharedState, model) {
		var viewCPT = sharedState.ia.h8.b4.iR === 'Extramed';
		var typeHnsg = A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var maybePdfResult = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var maybePdfDocument = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var hasMedicalReport = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var hasMedicalDocument = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var dialogView = $author$project$App$Page$Company$PageReevaluation$dialogFromModel(model);
		var confirmButton = (model.P.g.e.aw === 31) ? _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageReevaluation$SendToReview)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Retornar para médico')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageReevaluation$FinishReevaluation)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Finalizar')
							]))
					]))
			]) : _List_Nil;
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$header,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('tabs')
								]),
							_Utils_ap(
								_List_fromArray(
									[
										A5($author$project$App$Page$Company$PageReevaluation$viewQuestionsTabs, sharedState.ia.h8, viewCPT, typeHnsg, model.b5, sharedState.ia.h8.an),
										A2(
										$elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('cancel-button'),
														$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageReevaluation$BackToFinished)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Retornar')
													]))
											]))
									]),
								confirmButton)),
							function () {
							var _v0 = model.b5;
							switch (_v0.$) {
								case 0:
									var docIdx = _v0.a;
									return $author$project$App$Page$Company$PageReevaluation$viewDoc(
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$Document$emptyModel,
											A2($elm$core$Dict$get, docIdx, model.an)));
								case 1:
									var mDepIdx = _v0.a;
									if (!maybePdfResult.$) {
										var pdfResult = maybePdfResult.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Company$PageReevaluation$viewResultContent(sharedState.ia.h8);
									}
								case 2:
									var mDepIdx = _v0.a;
									if (!maybePdfDocument.$) {
										var pdfResult = maybePdfDocument.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Company$PageReevaluation$viewMedicalDocumentContent(model.P);
									}
								case 4:
									var videoRepo = {f3: 'video-repo-enterprise-covid.ingress.medsyn.com.br'};
									var videoUrl = A2($author$project$App$Data$VideoRepository$statementVideoUrl, videoRepo, sharedState.ia.h8.g.e.as);
									return A2(
										$elm$html$Html$video,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('video-preview'),
												$elm$html$Html$Attributes$controls(true)
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$source,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$src(videoUrl),
														$elm$html$Html$Attributes$type_('video/mp4')
													]),
												_List_Nil),
												$elm$html$Html$text('Seu browser não suporte exibição de vídeo HTML5')
											]));
								case 3:
									var depIdx = _v0.a;
									var fullStmt = sharedState.ia.h8;
									return A3($author$project$App$Page$Company$PageReevaluation$viewCPTContent, depIdx, fullStmt, now);
								default:
									return $elm$html$Html$text('');
							}
						}()
						]))
				]),
			_Utils_ap(
				A2($author$project$App$Page$Company$PageReevaluation$viewICDSection, sharedState, model),
				_List_fromArray(
					[dialogView])));
	});
var $author$project$App$Page$Company$PageRequestInterview$AddDocument = {$: 12};
var $author$project$App$Page$Company$PageRequestInterview$RemoveDocument = {$: 13};
var $author$project$App$Page$Company$PageRequestInterview$SelectDocumentFile = {$: 14};
var $author$project$App$Page$Company$PageRequestInterview$SelectTab = function (a) {
	return {$: 18, a: a};
};
var $author$project$App$Page$Company$PageRequestInterview$viewDocument = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Page$Company$PageRequestInterview$viewStatementDocuments = F2(
	function (st, modelForm) {
		var fullStmt = modelForm.a5;
		var currentIdx = function () {
			var _v0 = modelForm.b5;
			if (_v0.$ === 1) {
				var i = _v0.a;
				return i;
			} else {
				return 0;
			}
		}();
		return _List_fromArray(
			[
				$author$project$App$Page$Company$PageRequestInterview$viewDocument(
				A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$Document$emptyModel,
					A2($elm$core$Dict$get, currentIdx, fullStmt.an)))
			]);
	});
var $author$project$App$Page$Company$PageRequestInterview$viewDocumentsSection = F2(
	function (st, modelForm) {
		return A2(
			$elm$html$Html$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$header,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('tabs')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							A2(
								$elm$core$List$map,
								function (_v1) {
									var idx = _v1.a;
									var txt = _v1.b;
									return A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$href('#'),
												$elm$html$Html$Attributes$classList(
												_List_fromArray(
													[
														_Utils_Tuple2(
														'tab-selected',
														_Utils_eq(
															$author$project$App$Components$ViewFullStatement$TabDocument(idx),
															modelForm.b5))
													])),
												$elm$html$Html$Events$onClick(
												$author$project$App$Page$Company$PageRequestInterview$SelectTab(
													$author$project$App$Components$ViewFullStatement$TabDocument(idx)))
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text(txt)
													]))
											]));
								},
								A3(
									$elm$core$Dict$foldr,
									F3(
										function (idx, _v0, list) {
											return A2(
												$elm$core$List$cons,
												_Utils_Tuple2(
													idx,
													'Anexo ' + $elm$core$String$fromInt(idx + 1)),
												list);
										}),
									_List_Nil,
									modelForm.a5.an))),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									($elm$core$Dict$size(modelForm.a5.an) > 0) ? A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Attributes$title('Remove Documento'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageRequestInterview$RemoveDocument)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('material-icons')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('delete_forever')
												]))
										])) : $elm$html$Html$text(''),
									($elm$core$Dict$size(modelForm.a5.an) > 0) ? A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$title('Escolher arquivo'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageRequestInterview$SelectDocumentFile)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('material-icons')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('attach_file')
												]))
										])) : $elm$html$Html$text(''),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$title('Adicionar documento'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageRequestInterview$AddDocument)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('material-icons')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('note_add')
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					A2($author$project$App$Page$Company$PageRequestInterview$viewStatementDocuments, st, modelForm))
				]));
	});
var $author$project$App$Page$Company$PageRequestInterview$Error = 2;
var $author$project$App$Service$ApplicationResources$getNewFullStatementForCompanyResponse = F2(
	function (cId, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getMethodResponse,
			$author$project$App$Service$ApplicationResources$methodNewFullStatementForCompany(cId),
			api);
		_v0$2:
		while (true) {
			if (!_v0.$) {
				switch (_v0.a.$) {
					case 3:
						if ((_v0.a.a.$ === 30) && (_v0.a.c.$ === 18)) {
							var _v1 = _v0.a;
							var _v2 = _v1.c;
							var fullStmt = _v2.b;
							return $elm$core$Result$Ok(
								$elm$core$Maybe$Just(fullStmt));
						} else {
							break _v0$2;
						}
					case 2:
						var _v3 = _v0.a;
						var error = _v3.b;
						return $elm$core$Result$Err(error);
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return $elm$core$Result$Ok($elm$core$Maybe$Nothing);
	});
var $author$project$App$Page$Company$PageRequestInterview$fieldToErrorString = function (field) {
	switch (field) {
		case 0:
			return 'Arquivo do Cadastro deve ser enviado';
		case 1:
			return 'Documento maior que 30MB não é permitido';
		case 2:
			return 'Modelo de atendimento deve ser selecionado';
		case 3:
			return 'CPF inválido';
		case 4:
			return 'Nome não pode ser vazio';
		case 5:
			return 'Sobrenome não pode ser vazio';
		case 6:
			return 'Data de nascimento não pode ser vazia';
		case 7:
			return 'Sexo não pode ser vazio';
		case 8:
			return 'Peso não pode ser vazio';
		case 9:
			return 'Altura não pode ser vazio';
		case 10:
			return 'E-mail não pode ser vazio';
		case 11:
			return 'Celular inválido';
		case 12:
			return 'Parentesco não pode ser vazio';
		default:
			return 'Data e/ou horário inválidos';
	}
};
var $author$project$App$Page$Company$PageRequestInterview$viewStmtErrors = function (errors) {
	var parseErrors = function (_v0) {
		var person = _v0.a;
		var fields = _v0.b;
		return A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(
					(!$elm$core$List$length(fields)) ? '' : _Utils_ap(
						(_Utils_cmp(person, -1) > 0) ? ('Dependente ' + ($elm$core$String$fromInt(person + 1) + ': ')) : 'Titular: ',
						function (s) {
							return s + '.';
						}(
							A2(
								$elm$core$String$join,
								', ',
								A2($elm$core$List$map, $author$project$App$Page$Company$PageRequestInterview$fieldToErrorString, fields)))))
				]));
	};
	return A2(
		$elm$core$List$map,
		parseErrors,
		$elm$core$Dict$toList(errors));
};
var $author$project$App$Page$Company$PageRequestInterview$viewErrors = F2(
	function (st, modelForm) {
		var _v0 = A2($author$project$App$Service$ApplicationResources$getNewFullStatementForCompanyResponse, modelForm.gx, st._);
		if (_v0.$ === 1) {
			var _v1 = _v0.a;
			var specificError = _v1.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$App$Service$SpecificResources$errorToMessage(specificError))
					]));
		} else {
			return (!$author$project$App$Page$Company$PageRequestInterview$errorsDictIsEmpty(modelForm.fp)) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				$author$project$App$Page$Company$PageRequestInterview$viewStmtErrors(modelForm.fp)) : ((modelForm.cH === 1) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Cadastrando atendimento...')
					])) : ((modelForm.cH === 2) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Ocorreu um erro ao tentar cadastrar o atendimento.')
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Tente novamente em alguns instantes.')
							]))
					])) : $elm$html$Html$text('')));
		}
	});
var $author$project$App$Page$Company$PageRequestInterview$GoToPending = {$: 2};
var $author$project$App$Page$Company$PageRequestInterview$Persist = {$: 4};
var $author$project$App$Page$Company$PageRequestInterview$AddDependent = {$: 9};
var $author$project$App$Page$Company$PageRequestInterview$NoUpload = {$: 11};
var $author$project$App$Service$ApplicationResources$getPersonByDocumentError = F2(
	function (document, api) {
		var _v0 = A2(
			$author$project$App$Service$ApiWebSocket$getResource,
			$author$project$App$Service$ApplicationResources$pathPersonByDocument(document),
			api);
		if ((!_v0.$) && (_v0.a.$ === 2)) {
			var _v1 = _v0.a;
			var error = _v1.b;
			return $elm$core$Maybe$Just(error);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Page$Company$PageRequestInterview$documentIsValid = F2(
	function (document, api) {
		var _v0 = A2($author$project$App$Service$ApplicationResources$getPersonByDocumentError, document, api);
		_v0$2:
		while (true) {
			if (!_v0.$) {
				switch (_v0.a.a.$) {
					case 4:
						var _v1 = _v0.a;
						return false;
					case 2:
						var _v2 = _v0.a;
						return true;
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return $author$project$App$Data$Person$isDocumentValid(document);
	});
var $author$project$App$Page$Company$PageRequestInterview$InputContactField = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var $author$project$App$Page$Company$PageRequestInterview$errorsInField = F3(
	function (field, idx, errors) {
		return A2(
			$elm$core$List$member,
			field,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$elm$core$Dict$get,
					$author$project$App$Data$Statement$depIndexToInt(idx),
					errors)));
	});
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $author$project$App$Tools$FormHelpers$onKey = F6(
	function (on, evt, msg, keys, stop, prevent) {
		var isKey = function (code) {
			return A2($elm$core$List$member, code, keys) ? $elm$json$Json$Decode$succeed(
				{jl: msg, hB: prevent, ib: stop}) : $elm$json$Json$Decode$fail('not ENTER');
		};
		return A2(
			on,
			evt,
			A2($elm$json$Json$Decode$andThen, isKey, $elm$html$Html$Events$keyCode));
	});
var $author$project$App$Tools$FormHelpers$onKeyDown = F4(
	function (stop, prevent, keys, msg) {
		return A6($author$project$App$Tools$FormHelpers$onKey, $elm$html$Html$Events$custom, 'keydown', msg, keys, stop, prevent);
	});
var $author$project$App$Tools$FormHelpers$onBackspace = function (msg) {
	return A4(
		$author$project$App$Tools$FormHelpers$onKeyDown,
		true,
		true,
		_List_fromArray(
			[8, 46]),
		msg);
};
var $author$project$App$Page$Company$PageRequestInterview$viewEditContacts = F3(
	function (mDepIdx, fullStmt, contacts) {
		var submitting = fullStmt.cH === 1;
		var showErrorMessageClass = function (field) {
			return _Utils_Tuple2(
				'error-message',
				A3($author$project$App$Page$Company$PageRequestInterview$errorsInField, field, mDepIdx, fullStmt.fp));
		};
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('e-mail'),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('email'),
								$elm$html$Html$Attributes$disabled(submitting),
								$elm$html$Html$Attributes$placeholder('email@exemplo.com.br'),
								$elm$html$Html$Attributes$value(
								A2($author$project$App$Page$Company$PageRequestInterview$getContactWithKind, 5, contacts).T),
								$elm$html$Html$Events$onInput(
								A2(
									$elm$core$Basics$composeL,
									A2($author$project$App$Page$Company$PageRequestInterview$InputContactField, mDepIdx, 5),
									$author$project$App$Data$Contact$FieldValue)),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										showErrorMessageClass(10)
									]))
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Telefone celular'),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('tel'),
										$elm$html$Html$Attributes$disabled(submitting),
										$elm$html$Html$Attributes$placeholder('Celular'),
										$elm$html$Html$Attributes$value(
										$author$project$App$Data$Contact$formatMobile(
											A2($author$project$App$Page$Company$PageRequestInterview$getContactWithKind, 1, contacts).T)),
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($author$project$App$Page$Company$PageRequestInterview$InputContactField, mDepIdx, 1),
											$author$project$App$Data$Contact$FieldValue)),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												showErrorMessageClass(11)
											])),
										$author$project$App$Tools$FormHelpers$onBackspace(
										A3(
											$author$project$App$Page$Company$PageRequestInterview$InputContactField,
											mDepIdx,
											1,
											$author$project$App$Data$Contact$FieldValue(
												A2(
													$elm$core$String$dropRight,
													1,
													A2($author$project$App$Page$Company$PageRequestInterview$getContactWithKind, 1, contacts).T))))
									]),
								_List_Nil)
							]))
					]))
			]);
	});
var $author$project$App$Data$Person$ridByDoc = function (doc) {
	return 'peopleService.byDoc.' + $author$project$App$Data$Person$clearDocument(doc);
};
var $author$project$App$Data$Person$ridById = function (id) {
	return 'peopleService.person.' + id;
};
var $author$project$App$Page$Company$PageRequestInterview$RemoveDependent = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Data$Person$FieldDocument = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Company$PageRequestInterview$InputPersonField = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$App$Page$Company$PageRequestInterview$viewEditCPFContent = F3(
	function (mDepIdx, person, api) {
		var isInvalid = !A2($author$project$App$Page$Company$PageRequestInterview$documentIsValid, person.i1, api);
		return A2(
			$elm$html$Html$label,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('CPF'),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$placeholder('Digite o CPF do Cliente'),
									$elm$html$Html$Attributes$type_('text'),
									$elm$html$Html$Attributes$disabled(
									$author$project$App$Data$Id$uuidIsValid(person.as)),
									$elm$html$Html$Attributes$value(
									$author$project$App$Data$Person$formatDocument(person.i1)),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeL,
										$author$project$App$Page$Company$PageRequestInterview$InputPersonField(mDepIdx),
										$author$project$App$Data$Person$FieldDocument)),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('error-message', isInvalid)
										])),
									$author$project$App$Tools$FormHelpers$onBackspace(
									A2(
										$author$project$App$Page$Company$PageRequestInterview$InputPersonField,
										mDepIdx,
										$author$project$App$Data$Person$FieldDocument(
											A2($elm$core$String$dropRight, 1, person.i1))))
								]),
							_List_Nil),
							$elm$html$Html$text('')
						]))
				]));
	});
var $author$project$App$Page$Company$PageRequestInterview$viewEditPersonDocument = F4(
	function (mDepIdx, person, api, stmtTemplate) {
		var isDisabled = false;
		if (!mDepIdx.$) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$legend,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									stmtTemplate.gX ? 'Titular' : '')
								]))
						])),
					A3($author$project$App$Page$Company$PageRequestInterview$viewEditCPFContent, mDepIdx, person, api)
				]);
		} else {
			var depIdx = mDepIdx.a;
			return stmtTemplate.gX ? _List_fromArray(
				[
					A2(
					$elm$html$Html$legend,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									'Dependente ' + $elm$core$String$fromInt(depIdx + 1))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('action-buttons')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('action-buttons')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('cancel-button'),
													$elm$html$Html$Attributes$type_('button'),
													$elm$html$Html$Attributes$disabled(isDisabled),
													$elm$html$Html$Events$onClick(
													$author$project$App$Page$Company$PageRequestInterview$RemoveDependent(depIdx))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Remover')
												]))
										]))
								]))
						])),
					A3($author$project$App$Page$Company$PageRequestInterview$viewEditCPFContent, mDepIdx, person, api)
				]) : _List_Nil;
		}
	});
var $author$project$App$Data$Person$FieldBirthDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Data$Statement$FieldCompanyHeight = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Data$Statement$FieldCompanyWeight = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Data$Person$FieldName = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Data$Person$FieldSex = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Data$Person$FieldSurname = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Company$PageRequestInterview$InputStmtField = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$App$Data$Statement$backspaceHeight = F2(
	function (field, height) {
		return field(
			A2(
				$elm$core$String$dropRight,
				1,
				$elm$core$String$fromInt(height)));
	});
var $author$project$App$Data$Statement$backspaceWeight = F2(
	function (field, weight) {
		return field(
			A2(
				$elm$core$String$dropRight,
				4,
				$elm$core$String$fromInt(weight)));
	});
var $author$project$App$Tools$FormHelpers$dateInputWithAttrs = F3(
	function (attrs, date, msg) {
		return _Utils_eq(
			date,
			$elm$time$Time$millisToPosix(0)) ? A2(
			$elm$html$Html$input,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('date'),
						$elm$html$Html$Events$onInput(msg)
					]),
				attrs),
			_List_Nil) : A2(
			$elm$html$Html$input,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('date'),
						$elm$html$Html$Events$onInput(msg),
						$elm$html$Html$Attributes$value(
						A3(
							$elm$core$String$slice,
							0,
							10,
							$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(date)))
					]),
				attrs),
			_List_Nil);
	});
var $author$project$App$Tools$FormHelpers$selectSex = F3(
	function (attrs, sex, msg) {
		return A4(
			$author$project$App$Tools$FormHelpers$select,
			attrs,
			$author$project$App$Data$Person$sexToString(sex),
			msg,
			_List_fromArray(
				[
					_Utils_Tuple2('', 'Selecione'),
					_Utils_Tuple2('M', 'Masculino'),
					_Utils_Tuple2('F', 'Feminino')
				]));
	});
var $author$project$App$Data$Statement$FieldRelation = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Data$Statement$relationToText = function (relation) {
	switch (relation) {
		case 0:
			return 'Parentesco não declarado';
		case 1:
			return 'Conjuge';
		case 2:
			return 'Filho(a)';
		default:
			return 'Neto(a)';
	}
};
var $author$project$App$Page$Company$PageRequestInterview$viewRelationContent = F3(
	function (idx, editStmt, modelForm) {
		return _List_fromArray(
			[
				A4(
				$author$project$App$Tools$FormHelpers$select,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'error-message',
								A3($author$project$App$Page$Company$PageRequestInterview$errorsInField, 12, idx, modelForm.fp))
							]))
					]),
				$author$project$App$Data$Statement$relationToString(
					A2($elm$core$Maybe$withDefault, 0, editStmt.e.be)),
				A2(
					$elm$core$Basics$composeL,
					$author$project$App$Page$Company$PageRequestInterview$InputStmtField(idx),
					$author$project$App$Data$Statement$FieldRelation),
				A2(
					$elm$core$List$map,
					function (relation) {
						return _Utils_Tuple2(
							$author$project$App$Data$Statement$relationToString(relation),
							$author$project$App$Data$Statement$relationToText(relation));
					},
					_List_fromArray(
						[0, 1, 2, 3])))
			]);
	});
var $author$project$App$Page$Company$PageRequestInterview$viewEditPersonInformation = F4(
	function (mDepIdx, editStmt, modelForm, now) {
		var viewRelation = function () {
			if (!mDepIdx.$) {
				return _List_Nil;
			} else {
				return A3($author$project$App$Page$Company$PageRequestInterview$viewRelationContent, mDepIdx, editStmt, modelForm);
			}
		}();
		var submitting = modelForm.cH === 1;
		var stmt = editStmt.e;
		var stmtTemplate = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$StatementTemplate$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.as, stmt.eY);
					},
					modelForm.eZ)));
		var showErrorMessageClass = function (field) {
			return _Utils_Tuple2(
				'error-message',
				A3($author$project$App$Page$Company$PageRequestInterview$errorsInField, field, mDepIdx, modelForm.fp));
		};
		var person = editStmt.dw;
		var emptyForm = {
			gx: $author$project$App$Data$Id$uuidNil,
			b5: $author$project$App$Components$ViewFullStatement$TabDocument(0),
			fp: $elm$core$Dict$empty,
			a5: $author$project$App$Data$FullStatement$emptyFullStatement,
			ep: false,
			cH: 0,
			eZ: _List_Nil
		};
		return _Utils_ap(
			viewRelation,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'name', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Nome Completo'),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder('Primeiro Nome'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$disabled(submitting),
											$elm$html$Html$Attributes$value(person.hl),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												$author$project$App$Page$Company$PageRequestInterview$InputPersonField(mDepIdx),
												$author$project$App$Data$Person$FieldName)),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													showErrorMessageClass(4)
												]))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder('Todos os sobrenomes'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$disabled(submitting),
											$elm$html$Html$Attributes$value(person.ii),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												$author$project$App$Page$Company$PageRequestInterview$InputPersonField(mDepIdx),
												$author$project$App$Data$Person$FieldSurname)),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													showErrorMessageClass(5)
												]))
										]),
									_List_Nil)
								]))
						]) : _List_Nil),
					A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'socialName', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Nome Social'),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder('Nome Social'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$disabled(submitting),
											$elm$html$Html$Attributes$value(person.B),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												$author$project$App$Page$Company$PageRequestInterview$InputPersonField(mDepIdx),
												$author$project$App$Data$Person$FieldSocialName)),
											$elm$html$Html$Attributes$classList(_List_Nil)
										]),
									_List_Nil)
								]))
						]) : _List_Nil),
					A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'birthdate', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Data de Nascimento'),
							A3(
							$author$project$App$Tools$FormHelpers$dateInputWithAttrs,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											showErrorMessageClass(6)
										])),
									$elm$html$Html$Attributes$disabled(submitting)
								]),
							person.al,
							A2(
								$elm$core$Basics$composeL,
								$author$project$App$Page$Company$PageRequestInterview$InputPersonField(mDepIdx),
								$author$project$App$Data$Person$FieldBirthDate))
						]) : _List_Nil),
					A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'sex', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Sexo'),
							A3(
							$author$project$App$Tools$FormHelpers$selectSex,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											showErrorMessageClass(7)
										])),
									$elm$html$Html$Attributes$disabled(submitting)
								]),
							person.dJ,
							A2(
								$elm$core$Basics$composeL,
								$author$project$App$Page$Company$PageRequestInterview$InputPersonField(mDepIdx),
								$author$project$App$Data$Person$FieldSex))
						]) : _List_Nil),
					$elm$html$Html$text(''),
					((editStmt.e.j_ === 2) && _Utils_eq(mDepIdx, $author$project$App$Data$Statement$Bearer)) ? $elm$html$Html$text('') : A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'weight-height', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Peso e Altura'),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder('Peso'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$disabled(submitting),
											$elm$html$Html$Attributes$value(
											$author$project$App$Data$Statement$formatWeight(stmt.iT)),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												$author$project$App$Page$Company$PageRequestInterview$InputStmtField(mDepIdx),
												$author$project$App$Data$Statement$FieldCompanyWeight)),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													showErrorMessageClass(8)
												])),
											$author$project$App$Tools$FormHelpers$onBackspace(
											A2(
												$author$project$App$Page$Company$PageRequestInterview$InputStmtField,
												mDepIdx,
												A2($author$project$App$Data$Statement$backspaceWeight, $author$project$App$Data$Statement$FieldCompanyWeight, stmt.iT)))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder('Altura'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$disabled(submitting),
											$elm$html$Html$Attributes$value(
											$author$project$App$Data$Statement$formatHeight(stmt.iS)),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												$author$project$App$Page$Company$PageRequestInterview$InputStmtField(mDepIdx),
												$author$project$App$Data$Statement$FieldCompanyHeight)),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													showErrorMessageClass(9)
												])),
											$author$project$App$Tools$FormHelpers$onBackspace(
											A2(
												$author$project$App$Page$Company$PageRequestInterview$InputStmtField,
												mDepIdx,
												A2($author$project$App$Data$Statement$backspaceHeight, $author$project$App$Data$Statement$FieldCompanyHeight, stmt.iS)))
										]),
									_List_Nil)
								]))
						]) : _List_Nil)
				]));
	});
var $author$project$App$Page$Company$PageRequestInterview$viewEditDependent = F6(
	function (now, idx, modelForm, dep, fullStmt, api) {
		var ridById = $author$project$App$Data$Person$ridById(dep.dw.as);
		var ridByDoc = $author$project$App$Data$Person$ridByDoc(dep.dw.i1);
		var personLoaded = true;
		var invalidDoc = A2($author$project$App$Page$Company$PageRequestInterview$documentIsValid, dep.dw.i1, api) ? false : true;
		var docComplete = $author$project$App$Data$Person$isDocumentValid(dep.dw.i1);
		var currentTemplate = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$StatementTemplate$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.as, fullStmt.g.e.eY);
					},
					modelForm.eZ)));
		return A2(
			$elm$html$Html$fieldset,
			_List_Nil,
			$elm$core$List$concat(
				_List_fromArray(
					[
						A4(
						$author$project$App$Page$Company$PageRequestInterview$viewEditPersonDocument,
						$author$project$App$Data$Statement$Dependent(idx),
						dep.dw,
						api,
						currentTemplate),
						docComplete ? ((personLoaded && (!invalidDoc)) ? $elm$core$List$concat(
						_List_fromArray(
							[
								A4(
								$author$project$App$Page$Company$PageRequestInterview$viewEditPersonInformation,
								$author$project$App$Data$Statement$Dependent(idx),
								dep,
								modelForm,
								now),
								(A2($author$project$App$Data$Person$ageOnDate, now, dep.dw) < 18) ? _List_Nil : A3(
								$author$project$App$Page$Company$PageRequestInterview$viewEditContacts,
								$author$project$App$Data$Statement$Dependent(idx),
								modelForm,
								dep.ea)
							])) : (invalidDoc ? _List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('CPF inválido!')
								]))
						]) : _List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Validando CPF...')
								]))
						]))) : _List_fromArray(
						[
							$elm$html$Html$text('')
						])
					])));
	});
var $author$project$App$Data$Statement$FieldDueDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Data$Statement$FieldShouldInterview = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Data$Statement$FieldTemplateId = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Data$Statement$isEditable = function (model) {
	var _v0 = model.aw;
	if (!_v0) {
		return true;
	} else {
		return false;
	}
};
var $author$project$App$Data$Statement$shouldInterviewToString = function (shouldInterview) {
	return $elm$core$String$fromInt(
		$author$project$App$Data$Statement$shouldInterviewToInt(shouldInterview));
};
var $author$project$App$Data$Statement$shouldInterviewToText = function (shouldInterview) {
	switch (shouldInterview) {
		case 1:
			return 'Titular + Dependentes';
		case 2:
			return 'Apenas Dependentes';
		default:
			return 'Não Atender';
	}
};
var $author$project$App$Page$Company$PageRequestInterview$viewTemplateContent = F2(
	function (fullStmt, modelForm) {
		var templates = A2(
			$elm$core$List$filter,
			function (t) {
				return t.hl !== 'Entrevista';
			},
			modelForm.eZ);
		var stmtTemplate = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$StatementTemplate$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.as, fullStmt.g.e.eY);
					},
					modelForm.eZ)));
		var stmtModel = modelForm.a5.g.e;
		var showModelOfInterviewText = A2($elm$core$List$member, 'interview-model:visible', stmtTemplate.eM);
		var dueDate = $author$project$App$Data$Id$uuidIsValid(stmtModel.as) ? A2(
			$elm$html$Html$label,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Prazo Final: '),
					A3(
					$author$project$App$Tools$FormHelpers$dateInputWithAttrs,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'disabled', '')
						]),
					stmtModel.gL,
					A2(
						$elm$core$Basics$composeL,
						$author$project$App$Page$Company$PageRequestInterview$InputStmtField($author$project$App$Data$Statement$Bearer),
						$author$project$App$Data$Statement$FieldDueDate))
				])) : $elm$html$Html$text('');
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$legend,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Cadastro')
							]))
					])),
				dueDate,
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Modelo de Atendimento'),
						A5(
						$author$project$App$Tools$FormHelpers$selectFromCollection,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(
								(modelForm.cH === 1) || ($author$project$App$Data$Id$uuidIsValid(stmtModel.as) && $author$project$App$Data$Statement$isEditable(stmtModel))),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'error-message',
										A3($author$project$App$Page$Company$PageRequestInterview$errorsInField, 2, $author$project$App$Data$Statement$Bearer, modelForm.fp))
									]))
							]),
						stmtModel.eY,
						templates,
						function (t) {
							return _Utils_Tuple2(t.as, t.hl);
						},
						A2(
							$elm$core$Basics$composeR,
							$author$project$App$Data$Statement$FieldTemplateId,
							$author$project$App$Page$Company$PageRequestInterview$InputStmtField($author$project$App$Data$Statement$Bearer)))
					])),
				showModelOfInterviewText ? A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Categoria do Atendimento'),
						A4(
						$author$project$App$Tools$FormHelpers$select,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(modelForm.cH === 1)
							]),
						$author$project$App$Data$Statement$shouldInterviewToString(stmtModel.j_),
						A2(
							$elm$core$Basics$composeR,
							$author$project$App$Data$Statement$FieldShouldInterview,
							$author$project$App$Page$Company$PageRequestInterview$InputStmtField($author$project$App$Data$Statement$Bearer)),
						_List_fromArray(
							[
								_Utils_Tuple2(
								$author$project$App$Data$Statement$shouldInterviewToString(1),
								$author$project$App$Data$Statement$shouldInterviewToText(1)),
								_Utils_Tuple2(
								$author$project$App$Data$Statement$shouldInterviewToString(2),
								$author$project$App$Data$Statement$shouldInterviewToText(2))
							]))
					])) : A2($elm$html$Html$label, _List_Nil, _List_Nil)
			]);
	});
var $author$project$App$Page$Company$PageRequestInterview$viewForm = F3(
	function (st, modelForm, model) {
		var now = $author$project$App$Service$ApiWebSocket$getTimestamp(st._);
		var personEditContent = F2(
			function (mDepIdx, editStmt) {
				return A2($author$project$App$Page$Company$PageRequestInterview$documentIsValid, editStmt.dw.i1, st._) ? _Utils_ap(
					A4($author$project$App$Page$Company$PageRequestInterview$viewEditPersonInformation, mDepIdx, editStmt, modelForm, now),
					((A2($author$project$App$Data$Person$ageOnDate, now, editStmt.dw) >= 18) && (!(!$elm$time$Time$posixToMillis(editStmt.dw.al)))) ? A3($author$project$App$Page$Company$PageRequestInterview$viewEditContacts, mDepIdx, modelForm, editStmt.ea) : _List_Nil) : _List_fromArray(
					[
						$elm$html$Html$text('')
					]);
			});
		var fullStmt = modelForm.a5;
		var depsContent = A2(
			$elm$core$List$map,
			function (_v0) {
				var idx = _v0.a;
				var dep = _v0.b;
				return A6($author$project$App$Page$Company$PageRequestInterview$viewEditDependent, modelForm.ey, idx, modelForm, dep, modelForm.a5, st._);
			},
			$elm$core$Dict$toList(modelForm.a5.fh));
		var currentTemplate = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$StatementTemplate$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.as, modelForm.a5.g.e.eY);
					},
					modelForm.eZ)));
		var depButtonContent = (!currentTemplate.gX) ? $elm$html$Html$text('') : (A2($author$project$App$Page$Company$PageRequestInterview$documentIsValid, fullStmt.g.dw.i1, st._) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('action-buttons')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('button'),
							$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageRequestInterview$AddDependent)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Incluir dependente')
						]))
				])) : $elm$html$Html$text(''));
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_Nil,
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$fieldset,
							_List_Nil,
							($elm$core$Dict$isEmpty(fullStmt.an) && ($author$project$App$Data$Id$uuidIsNotValid(fullStmt.g.e.as) && (!modelForm.d9))) ? _List_fromArray(
								[
									A2(
									$elm$html$Html$legend,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h1,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Iniciando Cadastro')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('action-buttons')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('')
												]))
										])),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('button'),
											A2($elm$html$Html$Attributes$style, 'margin', '0'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageRequestInterview$SelectDocumentFile),
											$elm$html$Html$Attributes$value('Enviar arquivo do Cadastro')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Enviar arquivo do Cadastro')
										])),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Attributes$class('cancel-button'),
											A2($elm$html$Html$Attributes$style, 'margin-left', '20px'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageRequestInterview$NoUpload),
											$elm$html$Html$Attributes$value('Não cadastrar arquivo')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Não cadastrar arquivo')
										]))
								]) : _Utils_ap(
								A2($author$project$App$Page$Company$PageRequestInterview$viewTemplateContent, fullStmt, modelForm),
								_Utils_ap(
									A4($author$project$App$Page$Company$PageRequestInterview$viewEditPersonDocument, $author$project$App$Data$Statement$Bearer, fullStmt.g.dw, st._, st.ia.h8.cJ),
									A2(personEditContent, $author$project$App$Data$Statement$Bearer, fullStmt.g))))
						]),
					depsContent)),
				depButtonContent
			]);
	});
var $author$project$App$Page$Company$PageRequestInterview$viewFormSection = F4(
	function (st, modelForm, _v0, model) {
		var headerTitle = _v0.gZ;
		var actionText = _v0.f9;
		return modelForm.ep ? A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cad-proposta')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$header,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(headerTitle)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('action-buttons')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageRequestInterview$GoToPending)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Cancelar')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Attributes$disabled(modelForm.cH === 1),
											$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageRequestInterview$Persist)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(actionText)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('authentication')
						]),
					A3($author$project$App$Page$Company$PageRequestInterview$viewForm, st, modelForm, model))
				])) : A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cad-proposta')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$header,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Requisitar Novo Atendimento')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('authentication')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Carregando...')
						]))
				]));
	});
var $author$project$App$Page$Company$PageRequestInterview$view = F2(
	function (st, m) {
		var modelForm = $author$project$App$Page$Company$PageRequestInterview$formFromModel(m);
		var _v0 = function () {
			if (m.$ === 1) {
				return _Utils_Tuple2('Editar Atendimento', 'Atualizar');
			} else {
				return _Utils_Tuple2('Requisitar Novo Atendimento', 'Prosseguir');
			}
		}();
		var headerTitle = _v0.a;
		var actionText = _v0.b;
		return _List_fromArray(
			[
				A2($author$project$App$Page$Company$PageRequestInterview$viewErrors, st, modelForm),
				A4(
				$author$project$App$Page$Company$PageRequestInterview$viewFormSection,
				st,
				modelForm,
				{f9: actionText, gZ: headerTitle},
				m),
				A2($author$project$App$Page$Company$PageRequestInterview$viewDocumentsSection, st, modelForm)
			]);
	});
var $author$project$App$Page$Company$PageViewEvaluation$BackToFinished = {$: 1};
var $author$project$App$Page$Company$PageViewEvaluation$ToggleDialog = {$: 2};
var $author$project$App$Page$Company$PageViewEvaluation$SendToReview = {$: 3};
var $author$project$App$Page$Company$PageViewEvaluation$UpdateReviewMessage = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Company$PageViewEvaluation$dialogFromModel = function (model) {
	var header = _List_Nil;
	var footer = _List_fromArray(
		[
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cancel-button'),
					$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageViewEvaluation$ToggleDialog)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Cancelar')
				])),
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageViewEvaluation$SendToReview)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Devolver')
				]))
		]);
	var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
	var body = _List_fromArray(
		[
			A2(
			$elm$html$Html$textarea,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$cols(50),
					$elm$html$Html$Attributes$rows(5),
					$elm$html$Html$Attributes$value(model.eN),
					$elm$html$Html$Events$onInput($author$project$App$Page$Company$PageViewEvaluation$UpdateReviewMessage)
				]),
			_List_Nil)
		]);
	var completeConfig = _Utils_update(
		config,
		{
			e5: $elm$core$Maybe$Just(body),
			ft: $elm$core$Maybe$Just(footer),
			gY: $elm$core$Maybe$Just(header),
			f1: 'Motivo da Reavaliação'
		});
	return model.dC ? $author$project$App$Components$Dialog$view(
		$elm$core$Maybe$Just(completeConfig)) : $elm$html$Html$text('');
};
var $author$project$App$Page$Company$PageViewEvaluation$viewDocumentContent = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Page$Company$PageViewEvaluation$viewCPTContent = F3(
	function (mDepIdx, fullStmt, now) {
		var cptDoc = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Document$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					function (_v1) {
						var i = _v1.a;
						var d = _v1.b;
						return d;
					},
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var d = _v0.b;
							return _Utils_eq(d.jJ, fullStmt.g.e.as) && (d.b7 === 5);
						},
						$elm$core$Dict$toList(fullStmt.an)))));
		return $author$project$App$Data$Id$uuidIsValid(cptDoc.as) ? $author$project$App$Page$Company$PageViewEvaluation$viewDocumentContent(cptDoc) : A3($author$project$App$Legacy$Doctor$ResultLayout$Common$viewCPTLayout, mDepIdx, fullStmt, now);
	});
var $author$project$App$Page$Company$PageViewEvaluation$AddDPS = {$: 9};
var $author$project$App$Page$Company$PageViewEvaluation$viewDoc = function (doc) {
	return (!doc.b7) ? A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pdfviewer')
					]),
				(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
					[
						$elm$html$Html$text('Nenhum PDF carregado')
					]) : _List_fromArray(
					[
						$author$project$App$Data$Document$view(doc),
						$elm$html$Html$text('                    ')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'justify-content', 'center'),
						A2($elm$html$Html$Attributes$style, 'margin-bottom', '2em')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageViewEvaluation$AddDPS),
								$elm$html$Html$Attributes$value('Adicionar DPS')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Adicionar DPS')
							]))
					]))
			])) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Page$Company$PageViewEvaluation$viewPersonICD = F4(
	function (now, mDepIdx, stmt, sharedState) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(stmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					A3(
						$elm$core$Dict$foldr,
						F3(
							function (cat, icd, html) {
								return A2(
									$elm$core$List$append,
									html,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('chip'),
													$elm$html$Html$Attributes$title(icd.am)
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(cat)
														]))
												]))
										]));
							}),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Data$Person$fullPersonName(stmt.dw))
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(
											A2($author$project$App$Data$Person$ageOnDate, now, stmt.dw)) + ' anos')
									])),
								sharedState.ia.h8.cJ.gX ? A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											if (mDepIdx.$ === 1) {
												var i = mDepIdx.a;
												return 'Dependente ' + $elm$core$String$fromInt(i + 1);
											} else {
												return _Utils_eq(stmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
											}
										}())
									])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
							]),
						A3(
							$elm$core$Dict$foldr,
							F3(
								function (_v0, qCatDict, catDict) {
									return A3(
										$elm$core$Dict$foldl,
										F3(
											function (cat, icd, catDictInner) {
												return A3($elm$core$Dict$insert, cat, icd, catDictInner);
											}),
										catDict,
										qCatDict);
								}),
							$elm$core$Dict$empty,
							stmt.fx)))
				]));
	});
var $author$project$App$Page$Company$PageViewEvaluation$viewICDSection = F2(
	function (sharedState, model) {
		var chatView = $author$project$App$Components$Chatbox$view;
		var bearerPersonIcd = (sharedState.ia.h8.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A4($author$project$App$Page$Company$PageViewEvaluation$viewPersonICD, model.ey, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g, sharedState)
			]);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tabs ')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Dados por Pessoa')
									])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('material-icons')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('print')
													]))
											]))
									]))
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Company$PageViewEvaluation$ChatBoxMsg,
						chatView(model.aM)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('person-icds')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_Nil,
								A3(
									$elm$core$Dict$foldl,
									F3(
										function (i, dep, html) {
											return A2(
												$elm$core$List$append,
												html,
												_List_fromArray(
													[
														A4(
														$author$project$App$Page$Company$PageViewEvaluation$viewPersonICD,
														model.ey,
														$author$project$App$Data$Statement$Dependent(i),
														dep,
														sharedState)
													]));
										}),
									bearerPersonIcd,
									sharedState.ia.h8.fh))
							]))
					]))
			]);
	});
var $author$project$App$Page$Company$PageViewEvaluation$viewMedicalDocumentContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewMedicalDocumentLayout(fullStmt);
};
var $author$project$App$Page$Company$PageViewEvaluation$SelectDetailsTab = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Company$PageViewEvaluation$TabCPT = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Company$PageViewEvaluation$viewDocumentTabNumered = F4(
	function (currentTab, idx, doc, documentTabs) {
		var index = $elm$core$List$length(documentTabs) + 1;
		return A2(
			$elm$core$List$append,
			documentTabs,
			function () {
				var _v0 = doc.dr;
				switch (_v0) {
					case 'video/webm':
						return _List_fromArray(
							[
								$elm$html$Html$text('')
							]);
					case 'video/mp4':
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Company$PageViewEvaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Company$PageViewEvaluation$SelectDetailsTab(
											$author$project$App$Page$Company$PageViewEvaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										'Video' + $elm$core$String$fromInt(index))
									]))
							]);
					default:
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Company$PageViewEvaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Company$PageViewEvaluation$SelectDetailsTab(
											$author$project$App$Page$Company$PageViewEvaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											var _v1 = doc.b7;
											switch (_v1) {
												case 1:
													return 'Parecer Antigo' + $elm$core$String$fromInt(index);
												case 3:
													return 'Atestado' + $elm$core$String$fromInt(index);
												case 4:
													return 'Receita' + $elm$core$String$fromInt(index);
												case 5:
													return 'CPT' + $elm$core$String$fromInt(index);
												default:
													return 'Anexo';
											}
										}())
									]))
							]);
				}
			}());
	});
var $author$project$App$Page$Company$PageViewEvaluation$TabMedicalDocument = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Company$PageViewEvaluation$viewResultTab = F5(
	function (previewTitle, typeHnsg, currentTab, idx, d) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Company$PageViewEvaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Company$PageViewEvaluation$SelectDetailsTab(
							$author$project$App$Page$Company$PageViewEvaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(previewTitle)
					])),
				typeHnsg ? A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Company$PageViewEvaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Company$PageViewEvaluation$SelectDetailsTab(
							$author$project$App$Page$Company$PageViewEvaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Pré-Visualizar Atestado')
					])) : A2($elm$html$Html$span, _List_Nil, _List_Nil)
			]);
	});
var $author$project$App$Page$Company$PageViewEvaluation$viewQuestionsTabs = F5(
	function (fullStmt, viewCPT, typeHnsg, currentTab, docs) {
		var reportDocs = A2(
			$elm$core$List$sortWith,
			F2(
				function (a, b) {
					var _v10 = A2(
						$elm$core$Basics$compare,
						$elm$time$Time$posixToMillis(a.b.iW),
						$elm$time$Time$posixToMillis(b.b.iW));
					switch (_v10) {
						case 0:
							return 2;
						case 1:
							return 1;
						default:
							return 0;
					}
				}),
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v9, doc) {
							return doc.b7 === 1;
						}),
					docs)));
		var previewTitle = typeHnsg ? 'Pré-Visualizar Prontuário' : 'Pré-Visualizar Parecer';
		var resultTab = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$second,
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$map,
							A3($author$project$App$Page$Company$PageViewEvaluation$viewResultTab, previewTitle, typeHnsg, currentTab),
							$elm$core$Dict$fromList(
								A2($elm$core$List$take, 1, reportDocs)))))));
		var oldResultsTab = A3(
			$elm$core$Dict$foldl,
			$author$project$App$Page$Company$PageViewEvaluation$viewDocumentTabNumered(currentTab),
			_List_Nil,
			$elm$core$Dict$fromList(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(reportDocs))));
		var docsTabs = A3(
			$elm$core$Dict$foldl,
			F3(
				function (i, d, list) {
					var attachName = function () {
						var _v8 = d.dr;
						if (_v8 === 'video/mp4') {
							return 'Video';
						} else {
							return (d.b7 === 4) ? 'Receita' : ('Anexo ' + $elm$core$String$fromInt(i));
						}
					}();
					return A2(
						$elm$core$List$append,
						list,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Company$PageViewEvaluation$TabDocument(i)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Company$PageViewEvaluation$SelectDetailsTab(
											$author$project$App$Page$Company$PageViewEvaluation$TabDocument(i))),
										$elm$html$Html$Attributes$href('#')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(attachName)
									]))
							]));
				}),
			_List_Nil,
			A2(
				$elm$core$Dict$filter,
				F2(
					function (_v7, d) {
						return d.b7 !== 5;
					}),
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v6, d) {
							return d.b7 !== 3;
						}),
					A2(
						$elm$core$Dict$filter,
						F2(
							function (_v5, d) {
								return d.b7 !== 1;
							}),
						$elm$core$Dict$fromList(
							_Utils_ap(
								A2(
									$elm$core$List$take,
									1,
									$elm$core$List$reverse(
										$elm$core$Dict$toList(
											A2(
												$elm$core$Dict$filter,
												F2(
													function (_v3, doc) {
														return doc.dr === 'video/mp4';
													}),
												docs)))),
								$elm$core$Dict$toList(
									A2(
										$elm$core$Dict$filter,
										F2(
											function (_v4, doc) {
												return (doc.dr !== 'video/webm') && (doc.dr !== 'video/mp4');
											}),
										docs))))))));
		var cptDocs = A2(
			$elm$core$List$sortWith,
			F2(
				function (a, b) {
					var _v2 = A2(
						$elm$core$Basics$compare,
						$elm$time$Time$posixToMillis(a.b.iW),
						$elm$time$Time$posixToMillis(b.b.iW));
					switch (_v2) {
						case 0:
							return 2;
						case 1:
							return 1;
						default:
							return 0;
					}
				}),
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v1, doc) {
							return doc.b7 === 5;
						}),
					docs)));
		var bearerCpt = (fullStmt.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Company$PageViewEvaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Company$PageViewEvaluation$SelectDetailsTab(
							$author$project$App$Page$Company$PageViewEvaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('CPT Titular')
					]))
			]);
		var cptTabs = viewCPT ? A2(
			$elm$core$List$append,
			bearerCpt,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var idx = _v0.a;
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('#'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'tab-selected',
										_Utils_eq(
											currentTab,
											$author$project$App$Page$Company$PageViewEvaluation$TabCPT(
												$author$project$App$Data$Statement$Dependent(idx))))
									])),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Company$PageViewEvaluation$SelectDetailsTab(
									$author$project$App$Page$Company$PageViewEvaluation$TabCPT(
										$author$project$App$Data$Statement$Dependent(idx))))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'CPT Dep ' + $elm$core$String$fromInt(idx + 1))
							]));
				},
				$elm$core$Dict$toList(fullStmt.fh))) : _List_Nil;
		var cptDocumentsTab = ($elm$core$List$length(cptDocs) > 1) ? _Utils_ap(
			A3(
				$elm$core$Dict$foldl,
				$author$project$App$Page$Company$PageViewEvaluation$viewDocumentTabNumered(currentTab),
				_List_Nil,
				$elm$core$Dict$fromList(
					A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						$elm$core$List$tail(cptDocs)))),
			cptTabs) : cptTabs;
		var allTabs = _Utils_ap(
			docsTabs,
			_Utils_ap(
				resultTab,
				_Utils_ap(oldResultsTab, cptDocumentsTab)));
		return A2($elm$html$Html$div, _List_Nil, allTabs);
	});
var $author$project$App$Page$Company$PageViewEvaluation$viewResultContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewResultLayout(fullStmt);
};
var $author$project$App$Page$Company$PageViewEvaluation$view = F3(
	function (now, sharedState, model) {
		var viewCPT = sharedState.ia.h8.b4.iR === 'Extramed';
		var typeHnsg = A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var maybePdfResult = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var maybePdfDocument = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var hasMedicalReport = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var hasMedicalDocument = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var dialogView = $author$project$App$Page$Company$PageViewEvaluation$dialogFromModel(model);
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$header,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('tabs')
								]),
							_List_fromArray(
								[
									A5($author$project$App$Page$Company$PageViewEvaluation$viewQuestionsTabs, sharedState.ia.h8, viewCPT, typeHnsg, model.b5, sharedState.ia.h8.an),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('cancel-button'),
													$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageViewEvaluation$BackToFinished)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Retornar')
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick($author$project$App$Page$Company$PageViewEvaluation$ToggleDialog)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Reavaliar')
												]))
										]))
								])),
							function () {
							var _v0 = model.b5;
							switch (_v0.$) {
								case 0:
									var docIdx = _v0.a;
									return $author$project$App$Page$Company$PageViewEvaluation$viewDoc(
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$Document$emptyModel,
											A2($elm$core$Dict$get, docIdx, model.an)));
								case 1:
									var mDepIdx = _v0.a;
									if (!maybePdfResult.$) {
										var pdfResult = maybePdfResult.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Company$PageViewEvaluation$viewResultContent(sharedState.ia.h8);
									}
								case 2:
									var mDepIdx = _v0.a;
									if (!maybePdfDocument.$) {
										var pdfResult = maybePdfDocument.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Company$PageViewEvaluation$viewMedicalDocumentContent(model.P);
									}
								case 4:
									var videoRepo = {f3: 'video-repo-enterprise-covid.ingress.medsyn.com.br'};
									var videoUrl = A2($author$project$App$Data$VideoRepository$statementVideoUrl, videoRepo, sharedState.ia.h8.g.e.as);
									return A2(
										$elm$html$Html$video,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('video-preview'),
												$elm$html$Html$Attributes$controls(true)
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$source,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$src(videoUrl),
														$elm$html$Html$Attributes$type_('video/mp4')
													]),
												_List_Nil),
												$elm$html$Html$text('Seu browser não suporte exibição de vídeo HTML5')
											]));
								case 3:
									var depIdx = _v0.a;
									var fullStmt = sharedState.ia.h8;
									return A3($author$project$App$Page$Company$PageViewEvaluation$viewCPTContent, depIdx, fullStmt, now);
								default:
									return $elm$html$Html$text('');
							}
						}()
						]))
				]),
			_Utils_ap(
				A2($author$project$App$Page$Company$PageViewEvaluation$viewICDSection, sharedState, model),
				_List_fromArray(
					[dialogView])));
	});
var $author$project$App$Page$PageCompany$view = F3(
	function (st, now, model) {
		switch (model.$) {
			case 1:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageCompany$ListPendingMsg),
					A2($author$project$App$Page$Company$PageListPending$view, st, pageModel));
			case 2:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageCompany$ListCanceledMsg),
					A2($author$project$App$Page$Company$PageListCanceled$view, st, pageModel));
			case 3:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageCompany$ListConcludedMsg),
					A2($author$project$App$Page$Company$PageListConcluded$view, st, pageModel));
			case 0:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageCompany$RequestInterviewMsg),
					A2($author$project$App$Page$Company$PageRequestInterview$view, st, pageModel));
			case 6:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageCompany$ViewEvaluationMsg),
					A3($author$project$App$Page$Company$PageViewEvaluation$view, now, st, pageModel));
			case 5:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageCompany$ListReevaluationMsg),
					A2($author$project$App$Page$Company$PageListReevaluation$view, st, pageModel));
			default:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageCompany$ReevaluationMsg),
					A3($author$project$App$Page$Company$PageReevaluation$view, now, st, pageModel));
		}
	});
var $author$project$App$Page$Doctor$PageDoctorSales$ChangeOrder = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Doctor$PageDoctorSales$ChangeSelectedMonth = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$Page$Doctor$PageDoctorSales$CloseSelectedSales = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Doctor$PageDoctorSales$ExportCsv = {$: 5};
var $author$project$App$Page$Doctor$PageDoctorSales$ExportPdf = {$: 4};
var $author$project$App$Page$Doctor$PageDoctorSales$Negative = 0;
var $author$project$App$Page$Doctor$PageDoctorSales$Neutral = 2;
var $author$project$App$Page$Doctor$PageDoctorSales$OrderByDoctorName = 0;
var $author$project$App$Page$Doctor$PageDoctorSales$OrderByProponent = 2;
var $author$project$App$Page$Doctor$PageDoctorSales$OrderByValue = 3;
var $author$project$App$Page$Doctor$PageDoctorSales$Positive = 1;
var $author$project$App$Page$Doctor$PageDoctorSales$SelectSales = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageDoctorSales$ToggleTotal = {$: 7};
var $author$project$App$Page$Doctor$PageDoctorSales$lastMonthYear = F2(
	function (month, year) {
		return (month === 1) ? _Utils_Tuple2(12, year - 1) : _Utils_Tuple2(month - 1, year);
	});
var $author$project$App$Page$Doctor$PageDoctorSales$monthToName = function (_int) {
	switch (_int) {
		case 1:
			return 'JAN';
		case 2:
			return 'FEV';
		case 3:
			return 'MAR';
		case 4:
			return 'ABR';
		case 5:
			return 'MAI';
		case 6:
			return 'JUN';
		case 7:
			return 'JUL';
		case 8:
			return 'AGO';
		case 9:
			return 'SET';
		case 10:
			return 'OUT';
		case 11:
			return 'NOV';
		case 12:
			return 'DEZ';
		default:
			return 'ERR';
	}
};
var $author$project$App$Page$Doctor$PageDoctorSales$nextMonthYear = F2(
	function (month, year) {
		return (month === 12) ? _Utils_Tuple2(1, year + 1) : _Utils_Tuple2(month + 1, year);
	});
var $author$project$App$Page$Doctor$PageDoctorSales$paymentStatusToColorClass = function (status) {
	_v0$3:
	while (true) {
		if (!status.$) {
			switch (status.a) {
				case 'Fatura em aberto':
					return 'blue';
				case 'Fatura vencida':
					return 'red';
				case 'Fatura recebida':
					return 'green';
				default:
					break _v0$3;
			}
		} else {
			break _v0$3;
		}
	}
	return 'black';
};
var $author$project$App$Page$Doctor$PageDoctorSales$paymentStatusToInfoColorClass = function (status) {
	_v0$3:
	while (true) {
		if (!status.$) {
			switch (status.a) {
				case 'Fatura em aberto':
					return 'fatura-em-aberto';
				case 'Fatura vencida':
					return 'fatura-vencida';
				case 'Fatura recebida':
					return 'fatura-recebida';
				default:
					break _v0$3;
			}
		} else {
			break _v0$3;
		}
	}
	return '';
};
var $author$project$App$Page$Doctor$PageDoctorSales$view = function (model) {
	var _v0 = A2($author$project$App$Page$Doctor$PageDoctorSales$nextMonthYear, model.m, model.o);
	var nextMonth = _v0.a;
	var updatedYear = _v0.b;
	var _v1 = A2($author$project$App$Page$Doctor$PageDoctorSales$lastMonthYear, model.m, model.o);
	var lastMonth = _v1.a;
	var subtractedYear = _v1.b;
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('page-finance')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('finance')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$section,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('main-section')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$header,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('section-filter')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h1,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Relatório')
												]))
										])),
									A2(
									$elm$html$Html$header,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('section-header'),
											((!model.m) && (!model.o)) ? A2($elm$html$Html$Attributes$style, 'display', 'none') : A2($elm$html$Html$Attributes$style, '', '')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$ul,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('months')
												]),
											_Utils_ap(
												_List_fromArray(
													[
														A2(
														$elm$html$Html$li,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('arrow-left'),
																$elm$html$Html$Events$onClick(
																A3($author$project$App$Page$Doctor$PageDoctorSales$ChangeSelectedMonth, lastMonth, subtractedYear, 0))
															]),
														_List_Nil)
													]),
												_Utils_ap(
													A2(
														$elm$core$List$map,
														function (_v8) {
															var i = _v8.a;
															var m = _v8.b;
															return A2(
																$elm$html$Html$li,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class(
																		'month-cel' + ((_Utils_eq(m.hk, model.m) && _Utils_eq(m.iF, model.o)) ? ' active-cel' : '')),
																		$elm$html$Html$Events$onClick(
																		A3($author$project$App$Page$Doctor$PageDoctorSales$ChangeSelectedMonth, m.hk, m.iF, 2))
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('month-container')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$div,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class(
																						'bar ' + $author$project$App$Page$Doctor$PageDoctorSales$paymentStatusToColorClass(m.aR))
																					]),
																				_List_Nil),
																				A2(
																				$elm$html$Html$p,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('month')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text(
																						$author$project$App$Page$Doctor$PageDoctorSales$monthToName(m.hk))
																					])),
																				A2(
																				$elm$html$Html$p,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('year')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text(
																						$elm$core$String$fromInt(m.iF))
																					]))
																			]))
																	]));
														},
														A2(
															$elm$core$List$indexedMap,
															$elm$core$Tuple$pair,
															A2(
																$elm$core$List$take,
																5,
																A3(
																	$elm$core$List$foldl,
																	F2(
																		function (m, a) {
																			if (_Utils_eq(m.hk, model.m) && _Utils_eq(m.iF, model.o)) {
																				return A2($elm$core$List$cons, m, a);
																			} else {
																				if (_Utils_eq(m.hk, lastMonth) && _Utils_eq(m.iF, subtractedYear)) {
																					return A2($elm$core$List$cons, m, a);
																				} else {
																					if (_Utils_eq(m.hk, nextMonth) && _Utils_eq(m.iF, updatedYear)) {
																						return A2($elm$core$List$cons, m, a);
																					} else {
																						var _v2 = A2($author$project$App$Page$Doctor$PageDoctorSales$nextMonthYear, nextMonth, updatedYear);
																						var nnMonth = _v2.a;
																						var nnYear = _v2.b;
																						var _v3 = A2($author$project$App$Page$Doctor$PageDoctorSales$lastMonthYear, lastMonth, subtractedYear);
																						var llMonth = _v3.a;
																						var llYear = _v3.b;
																						if (_Utils_eq(m.hk, llMonth) && _Utils_eq(m.iF, llYear)) {
																							return A2($elm$core$List$cons, m, a);
																						} else {
																							if (_Utils_eq(m.hk, nnMonth) && _Utils_eq(m.iF, nnYear)) {
																								return A2($elm$core$List$cons, m, a);
																							} else {
																								if ($elm$core$List$length(a) < 5) {
																									var _v4 = A2($author$project$App$Page$Doctor$PageDoctorSales$nextMonthYear, nnMonth, nnYear);
																									var nnnMonth = _v4.a;
																									var nnnYear = _v4.b;
																									var _v5 = A2($author$project$App$Page$Doctor$PageDoctorSales$nextMonthYear, nnnMonth, nnnYear);
																									var nnnnMonth = _v5.a;
																									var nnnnYear = _v5.b;
																									var _v6 = A2($author$project$App$Page$Doctor$PageDoctorSales$lastMonthYear, llMonth, llYear);
																									var lllMonth = _v6.a;
																									var lllYear = _v6.b;
																									var _v7 = A2($author$project$App$Page$Doctor$PageDoctorSales$lastMonthYear, lllMonth, lllYear);
																									var llllMonth = _v7.a;
																									var llllYear = _v7.b;
																									return (_Utils_eq(m.hk, lllMonth) && _Utils_eq(m.iF, lllYear)) ? A2($elm$core$List$cons, m, a) : ((_Utils_eq(m.hk, nnnMonth) && _Utils_eq(m.iF, nnnYear)) ? A2($elm$core$List$cons, m, a) : ((_Utils_eq(m.hk, llllMonth) && _Utils_eq(m.iF, llllYear)) ? A2($elm$core$List$cons, m, a) : ((_Utils_eq(m.hk, nnnnMonth) && _Utils_eq(m.iF, nnnnYear)) ? A2($elm$core$List$cons, m, a) : a)));
																								} else {
																									return a;
																								}
																							}
																						}
																					}
																				}
																			}
																		}),
																	_List_Nil,
																	model.w)))),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$li,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('complete-space')
																]),
															_List_Nil),
															A2(
															$elm$html$Html$li,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('arrow-right'),
																	$elm$html$Html$Events$onClick(
																	A3($author$project$App$Page$Doctor$PageDoctorSales$ChangeSelectedMonth, nextMonth, updatedYear, 1))
																]),
															_List_Nil)
														]))))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('section-main')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('accordion-mf-container')
												]),
											$elm$core$List$concat(
												A2(
													$elm$core$List$map,
													function (_v10) {
														var i = _v10.a;
														var m = _v10.b;
														var taxSum = A3(
															$elm$core$List$foldl,
															F2(
																function (ct, a) {
																	return a + $elm$core$Basics$round((m.iq * ct.hj) / 100000);
																}),
															0,
															model.aO);
														return _Utils_ap(
															$elm$core$List$concat(
																A2(
																	$elm$core$List$map,
																	function (s) {
																		var sortedDetails = function () {
																			var _v11 = model.ct;
																			if (!_v11.b) {
																				switch (_v11.a) {
																					case 1:
																						var _v12 = _v11.a;
																						return A2(
																							$elm$core$List$sortBy,
																							A2(
																								$elm$core$Basics$composeR,
																								function ($) {
																									return $.gA;
																								},
																								$elm$time$Time$posixToMillis),
																							s.gD);
																					case 2:
																						var _v14 = _v11.a;
																						return A2(
																							$elm$core$List$sortBy,
																							function ($) {
																								return $.am;
																							},
																							s.gD);
																					case 0:
																						var _v16 = _v11.a;
																						return A2(
																							$elm$core$List$sortBy,
																							function ($) {
																								return $.bu;
																							},
																							s.gD);
																					default:
																						var _v18 = _v11.a;
																						return A2(
																							$elm$core$List$sortBy,
																							function ($) {
																								return $.gI;
																							},
																							s.gD);
																				}
																			} else {
																				switch (_v11.a) {
																					case 1:
																						var _v13 = _v11.a;
																						return $elm$core$List$reverse(
																							A2(
																								$elm$core$List$sortBy,
																								A2(
																									$elm$core$Basics$composeR,
																									function ($) {
																										return $.gA;
																									},
																									$elm$time$Time$posixToMillis),
																								s.gD));
																					case 2:
																						var _v15 = _v11.a;
																						return $elm$core$List$reverse(
																							A2(
																								$elm$core$List$sortBy,
																								function ($) {
																									return $.am;
																								},
																								s.gD));
																					case 0:
																						var _v17 = _v11.a;
																						return $elm$core$List$reverse(
																							A2(
																								$elm$core$List$sortBy,
																								function ($) {
																									return $.bu;
																								},
																								s.gD));
																					default:
																						var _v19 = _v11.a;
																						return $elm$core$List$reverse(
																							A2(
																								$elm$core$List$sortBy,
																								function ($) {
																									return $.gI;
																								},
																								s.gD));
																				}
																			}
																		}();
																		return _Utils_eq(m.bi, s.au) ? _List_fromArray(
																			[
																				A2(
																				$elm$html$Html$div,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('accordion-mf-tab'),
																						$elm$html$Html$Events$onClick(
																						$author$project$App$Page$Doctor$PageDoctorSales$CloseSelectedSales(m.hk))
																					]),
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$ul,
																						_List_Nil,
																						_List_fromArray(
																							[
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text('Modelo de Atendimento')
																											])),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text(
																												$elm$core$String$fromInt(
																													$elm$core$List$length(s.gD)) + (' ' + s.hl))
																											]))
																									])),
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text('')
																											])),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_Nil)
																									])),
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_Nil),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_Nil)
																									])),
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text('Total')
																											])),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text(
																												'R$' + $author$project$Mask$intDecimal(s.L + s.at))
																											]))
																									]))
																							]))
																					])),
																				A2(
																				$elm$html$Html$div,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('accordion-mf-content')
																					]),
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$table,
																						_List_fromArray(
																							[
																								$elm$html$Html$Attributes$class('data-table-faturamento')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$elm$html$Html$thead,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$tr,
																										_List_Nil,
																										_List_fromArray(
																											[
																												A2(
																												$elm$html$Html$th,
																												_List_fromArray(
																													[
																														$elm$html$Html$Events$onClick(
																														$author$project$App$Page$Doctor$PageDoctorSales$ChangeOrder(2)),
																														A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
																													]),
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Proponente')
																													])),
																												A2(
																												$elm$html$Html$th,
																												_List_fromArray(
																													[
																														$elm$html$Html$Events$onClick(
																														$author$project$App$Page$Doctor$PageDoctorSales$ChangeOrder(1)),
																														A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
																													]),
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Data')
																													])),
																												A2(
																												$elm$html$Html$th,
																												_List_fromArray(
																													[
																														$elm$html$Html$Events$onClick(
																														$author$project$App$Page$Doctor$PageDoctorSales$ChangeOrder(0)),
																														A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
																													]),
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Profissional')
																													])),
																												A2(
																												$elm$html$Html$th,
																												_List_fromArray(
																													[
																														$elm$html$Html$Events$onClick(
																														$author$project$App$Page$Doctor$PageDoctorSales$ChangeOrder(3)),
																														A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
																													]),
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Valor')
																													]))
																											]))
																									])),
																								A2(
																								$elm$html$Html$tbody,
																								_List_Nil,
																								A2(
																									$elm$core$List$map,
																									function (d) {
																										return A2(
																											$elm$html$Html$tr,
																											_List_Nil,
																											_List_fromArray(
																												[
																													A2(
																													$elm$html$Html$td,
																													_List_Nil,
																													_List_fromArray(
																														[
																															$elm$html$Html$text(d.am)
																														])),
																													A2(
																													$elm$html$Html$td,
																													_List_Nil,
																													_List_fromArray(
																														[
																															$elm$html$Html$text(
																															$author$project$App$Tools$FormHelpers$dateFormat(d.gA))
																														])),
																													A2(
																													$elm$html$Html$td,
																													_List_Nil,
																													_List_fromArray(
																														[
																															$elm$html$Html$text(d.bu)
																														])),
																													A2(
																													$elm$html$Html$td,
																													_List_Nil,
																													_List_fromArray(
																														[
																															$elm$html$Html$text(
																															'R$' + $author$project$Mask$intDecimal(d.gI))
																														]))
																												]));
																									},
																									sortedDetails))
																							]))
																					]))
																			]) : _List_fromArray(
																			[
																				A2(
																				$elm$html$Html$div,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('accordion-mf-tab'),
																						$elm$html$Html$Events$onClick(
																						A2($author$project$App$Page$Doctor$PageDoctorSales$SelectSales, m.hk, s.au))
																					]),
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$ul,
																						_List_Nil,
																						_List_fromArray(
																							[
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text('Modelo de Atendimento')
																											])),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text(
																												$elm$core$String$fromInt(
																													$elm$core$List$length(s.gD)) + (' ' + s.hl))
																											]))
																									])),
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_Nil),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_Nil)
																									])),
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_Nil),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_Nil)
																									])),
																								A2(
																								$elm$html$Html$li,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-title')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text('Total')
																											])),
																										A2(
																										$elm$html$Html$p,
																										_List_fromArray(
																											[
																												$elm$html$Html$Attributes$class('ac-value')
																											]),
																										_List_fromArray(
																											[
																												$elm$html$Html$text(
																												'R$' + $author$project$Mask$intDecimal(s.L + s.at))
																											]))
																									]))
																							]))
																					]))
																			]);
																	},
																	m.S)),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('accordion-mf-tab total-tab'),
																			$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageDoctorSales$ToggleTotal)
																		]),
																	A2(
																		$elm$core$List$cons,
																		A2(
																			$elm$html$Html$ul,
																			_List_Nil,
																			_List_fromArray(
																				[
																					A2(
																					$elm$html$Html$li,
																					_List_Nil,
																					_List_fromArray(
																						[
																							A2(
																							$elm$html$Html$p,
																							_List_fromArray(
																								[
																									$elm$html$Html$Attributes$class('ac-value')
																								]),
																							_List_fromArray(
																								[
																									$elm$html$Html$text('TOTAL')
																								])),
																							A2(
																							$elm$html$Html$div,
																							_List_fromArray(
																								[
																									$elm$html$Html$Attributes$class('info-container')
																								]),
																							_Utils_eq(m.gL, $elm$core$Maybe$Nothing) ? _List_Nil : _List_fromArray(
																								[
																									A2(
																									$elm$html$Html$p,
																									_List_fromArray(
																										[
																											$elm$html$Html$Attributes$class('ac-vencimento')
																										]),
																									_List_fromArray(
																										[
																											$elm$html$Html$text(
																											'Vencimento ' + A2($elm$core$Maybe$withDefault, '', m.gL))
																										])),
																									A2(
																									$elm$html$Html$p,
																									_List_fromArray(
																										[
																											$elm$html$Html$Attributes$class('fechamento')
																										]),
																									_List_fromArray(
																										[
																											$elm$html$Html$text(
																											'Fechamento ' + A2($elm$core$Maybe$withDefault, '', m.b1))
																										]))
																								]))
																						])),
																					A2(
																					$elm$html$Html$li,
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class(
																							$author$project$App$Page$Doctor$PageDoctorSales$paymentStatusToInfoColorClass(m.aR))
																						]),
																					_List_fromArray(
																						[
																							A2(
																							$elm$html$Html$p,
																							_List_fromArray(
																								[
																									$elm$html$Html$Attributes$class(
																									'ac-title info-' + $author$project$App$Page$Doctor$PageDoctorSales$paymentStatusToInfoColorClass(m.aR))
																								]),
																							_List_fromArray(
																								[
																									$elm$html$Html$text(
																									A2($elm$core$Maybe$withDefault, '', m.aR))
																								])),
																							A2(
																							$elm$html$Html$p,
																							_List_fromArray(
																								[
																									$elm$html$Html$Attributes$class('ac-value')
																								]),
																							_List_fromArray(
																								[
																									$elm$html$Html$text(
																									'R$' + $author$project$Mask$intDecimal(m.iq - taxSum))
																								]))
																						]))
																				])),
																		model.dS ? _List_fromArray(
																			[
																				A2(
																				$elm$html$Html$div,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('accordion-mf-content')
																					]),
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$table,
																						_List_fromArray(
																							[
																								$elm$html$Html$Attributes$class('data-table-total-faturamento')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$elm$html$Html$thead,
																								_List_Nil,
																								_List_fromArray(
																									[
																										A2(
																										$elm$html$Html$tr,
																										_List_Nil,
																										_List_fromArray(
																											[
																												A2(
																												$elm$html$Html$th,
																												_List_fromArray(
																													[
																														A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																													]),
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Nome')
																													])),
																												A2(
																												$elm$html$Html$th,
																												_List_Nil,
																												_List_fromArray(
																													[
																														$elm$html$Html$text('%')
																													])),
																												A2(
																												$elm$html$Html$th,
																												_List_Nil,
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Valor')
																													]))
																											]))
																									])),
																								A2(
																								$elm$html$Html$tbody,
																								_List_Nil,
																								A2(
																									$elm$core$List$cons,
																									A2(
																										$elm$html$Html$tr,
																										_List_Nil,
																										_List_fromArray(
																											[
																												A2(
																												$elm$html$Html$td,
																												_List_fromArray(
																													[
																														A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																													]),
																												_List_fromArray(
																													[
																														$elm$html$Html$text('Bruto')
																													])),
																												A2(
																												$elm$html$Html$td,
																												_List_Nil,
																												_List_fromArray(
																													[
																														$elm$html$Html$text('')
																													])),
																												A2(
																												$elm$html$Html$td,
																												_List_fromArray(
																													[
																														A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																													]),
																												_List_fromArray(
																													[
																														$elm$html$Html$text(
																														'R$' + $author$project$Mask$intDecimal(m.iq))
																													]))
																											])),
																									_Utils_ap(
																										A2(
																											$elm$core$List$map,
																											function (tax) {
																												var value = $author$project$Mask$intDecimal(
																													$elm$core$Basics$round((m.iq * tax.hj) / 100000));
																												var percentage = $author$project$Mask$intDecimal(
																													$elm$core$Basics$round(tax.hj / 10)) + '%';
																												return A2(
																													$elm$html$Html$tr,
																													_List_Nil,
																													_List_fromArray(
																														[
																															A2(
																															$elm$html$Html$td,
																															_List_fromArray(
																																[
																																	A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																																]),
																															_List_fromArray(
																																[
																																	$elm$html$Html$text(tax.hl)
																																])),
																															A2(
																															$elm$html$Html$td,
																															_List_fromArray(
																																[
																																	A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																																]),
																															_List_fromArray(
																																[
																																	$elm$html$Html$text(percentage)
																																])),
																															A2(
																															$elm$html$Html$td,
																															_List_fromArray(
																																[
																																	A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																																]),
																															_List_fromArray(
																																[
																																	$elm$html$Html$text('R$' + value)
																																]))
																														]));
																											},
																											model.aO),
																										_List_fromArray(
																											[
																												A2(
																												$elm$html$Html$tr,
																												_List_Nil,
																												_List_fromArray(
																													[
																														A2(
																														$elm$html$Html$td,
																														_List_fromArray(
																															[
																																A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																															]),
																														_List_fromArray(
																															[
																																$elm$html$Html$text('Líquido')
																															])),
																														A2(
																														$elm$html$Html$td,
																														_List_Nil,
																														_List_fromArray(
																															[
																																$elm$html$Html$text('')
																															])),
																														A2(
																														$elm$html$Html$td,
																														_List_fromArray(
																															[
																																A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																															]),
																														_List_fromArray(
																															[
																																$elm$html$Html$text(
																																'R$' + $author$project$Mask$intDecimal(m.iq - taxSum))
																															]))
																													]))
																											]))))
																							]))
																					]))
																			]) : _List_Nil)),
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('buttons-container')
																		]),
																	_List_fromArray(
																		[
																			_Utils_eq(model.eb, $elm$core$Maybe$Nothing) ? A2(
																			$elm$html$Html$button,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('btn-default btn-exportar-csv'),
																					$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageDoctorSales$ExportCsv)
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('EXPORTAR CSV')
																				])) : A2(
																			$elm$html$Html$button,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('btn-error btn-exportar-csv'),
																					$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageDoctorSales$ExportCsv)
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('Erro, contate o suporte')
																				])),
																			_Utils_eq(model.eF, $elm$core$Maybe$Nothing) ? A2(
																			$elm$html$Html$button,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('btn-default btn-exportar-pdf'),
																					$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageDoctorSales$ExportPdf)
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('EXPORTAR PDF')
																				])) : A2(
																			$elm$html$Html$button,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('btn-error btn-exportar-pdf'),
																					$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageDoctorSales$ExportPdf)
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('Erro, contate o suporte')
																				]))
																		]))
																]));
													},
													A2(
														$elm$core$List$filter,
														function (_v9) {
															var i = _v9.a;
															var m = _v9.b;
															return _Utils_eq(m.hk, model.m) && _Utils_eq(m.iF, model.o);
														},
														A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, model.w)))))
										]))
								]))
						]))
				]))
		]);
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$AproveStatement = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$BackToPending = {$: 1};
var $author$project$App$Page$Doctor$PageFinalEvaluation$OpenNexodata = {$: 32};
var $author$project$App$Page$Doctor$PageFinalEvaluation$ShowWarning = {$: 9};
var $author$project$App$Page$Doctor$PageFinalEvaluation$SignDocument = {$: 5};
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$AddCpt = {$: 20};
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewDocumentContent = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewCPTContent = F3(
	function (mDepIdx, fullStmt, now) {
		var cptDoc = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Document$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					function (_v1) {
						var i = _v1.a;
						var d = _v1.b;
						return d;
					},
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var d = _v0.b;
							return _Utils_eq(d.jJ, fullStmt.g.e.as) && (d.b7 === 5);
						},
						$elm$core$Dict$toList(fullStmt.an)))));
		return $author$project$App$Data$Id$uuidIsValid(cptDoc.as) ? $author$project$App$Page$Doctor$PageFinalEvaluation$viewDocumentContent(cptDoc) : A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A3($author$project$App$Legacy$Doctor$ResultLayout$Common$viewCPTLayout, mDepIdx, fullStmt, now),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'display', 'flex'),
							A2($elm$html$Html$Attributes$style, 'justify-content', 'center'),
							A2($elm$html$Html$Attributes$style, 'margin-bottom', '2em')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('button'),
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageFinalEvaluation$AddCpt),
									$elm$html$Html$Attributes$value('Adicionar CPT')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Adicionar CPT')
								]))
						]))
				]));
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$CancelSignDocument = {$: 6};
var $author$project$App$Page$Doctor$PageFinalEvaluation$ConfirmSignDocument = {$: 13};
var $author$project$App$Page$Doctor$PageFinalEvaluation$InputSignToken = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$KeyDownSignToken = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$onKeyDown = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'keydown',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$keyCode));
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewError = function (err) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('error-message')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$App$Service$ApiWebSocket$errorMessage(err))
			]));
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewLoading = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('loading')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('Carregando...')
		]));
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewLoadingOrError = function (model) {
	var _v0 = model.e$;
	switch (_v0.$) {
		case 1:
			return $author$project$App$Page$Doctor$PageFinalEvaluation$viewLoading;
		case 2:
			var err = _v0.a;
			return $author$project$App$Page$Doctor$PageFinalEvaluation$viewError(err);
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewDialog = F2(
	function (sharedState, model) {
		if (model.h$) {
			var typeHnsg = A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
			var previewTitle = typeHnsg ? 'Prontuário' : 'Parecer';
			var header = _List_Nil;
			var documentToSign = function () {
				var haveMedicalReport = $elm$core$List$length(
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$filter,
							F2(
								function (_v0, d) {
									return d.b7 === 1;
								}),
							model.an))) > 0;
				return haveMedicalReport ? 'Atestado médico' : previewTitle;
			}();
			var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
			var btnSignText = model.h3 ? 'Enviando...' : 'Enviar';
			var footer = _List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'flex-direction', 'row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageFinalEvaluation$CancelSignDocument),
											$elm$html$Html$Attributes$disabled(model.h3)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Cancelar')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageFinalEvaluation$ConfirmSignDocument),
											$elm$html$Html$Attributes$disabled(model.h3)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(btnSignText)
										]))
								])),
							$author$project$App$Page$Doctor$PageFinalEvaluation$viewLoadingOrError(model)
						]))
				]);
			var body = _List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(model.h2),
							$elm$html$Html$Events$onInput($author$project$App$Page$Doctor$PageFinalEvaluation$InputSignToken),
							$author$project$App$Page$Doctor$PageFinalEvaluation$onKeyDown($author$project$App$Page$Doctor$PageFinalEvaluation$KeyDownSignToken),
							$elm$html$Html$Attributes$disabled(model.h3)
						]),
					_List_Nil)
				]);
			var completeConfig = _Utils_update(
				config,
				{
					e5: $elm$core$Maybe$Just(body),
					ft: $elm$core$Maybe$Just(footer),
					gY: $elm$core$Maybe$Just(header),
					f1: 'Token de assinatura para ' + documentToSign
				});
			return $author$project$App$Components$Dialog$view(
				$elm$core$Maybe$Just(completeConfig));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$AddDPS = {$: 24};
var $author$project$App$Page$Doctor$PageFinalEvaluation$AddReport = {$: 22};
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewDoc = function (doc) {
	return (!doc.b7) ? A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pdfviewer')
					]),
				(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
					[
						$elm$html$Html$text('Nenhum PDF carregado')
					]) : _List_fromArray(
					[
						$author$project$App$Data$Document$view(doc),
						$elm$html$Html$text('                    ')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'justify-content', 'center'),
						A2($elm$html$Html$Attributes$style, 'margin-bottom', '2em')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageFinalEvaluation$AddDPS),
								$elm$html$Html$Attributes$value('Adicionar DPS')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Adicionar DPS')
							]))
					]))
			])) : ((doc.b7 === 5) ? A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pdfviewer')
					]),
				(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
					[
						$elm$html$Html$text('Nenhum PDF carregado')
					]) : _List_fromArray(
					[
						$author$project$App$Data$Document$view(doc),
						$elm$html$Html$text('                    ')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'justify-content', 'center'),
						A2($elm$html$Html$Attributes$style, 'margin-bottom', '2em')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageFinalEvaluation$AddCpt),
								$elm$html$Html$Attributes$value('Adicionar CPT')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Adicionar CPT')
							]))
					]))
			])) : ((doc.b7 === 1) ? A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pdfviewer')
					]),
				(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
					[
						$elm$html$Html$text('Nenhum PDF carregado')
					]) : _List_fromArray(
					[
						$author$project$App$Data$Document$view(doc),
						$elm$html$Html$text('                    ')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'justify-content', 'center'),
						A2($elm$html$Html$Attributes$style, 'margin-bottom', '2em')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageFinalEvaluation$AddReport),
								$elm$html$Html$Attributes$value('Adicionar Parecer')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Adicionar Parecer')
							]))
					]))
			])) : ((doc.dr === 'video/mp4') ? A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pdfviewer')
					]),
				(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
					[
						$elm$html$Html$text('Nenhum PDF carregado')
					]) : _List_fromArray(
					[
						$author$project$App$Data$Document$view(doc),
						$elm$html$Html$text('                    ')
					]))
			])) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			])))));
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewPersonICD = F4(
	function (now, mDepIdx, stmt, sharedState) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(stmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					A3(
						$elm$core$Dict$foldr,
						F3(
							function (cat, icd, html) {
								return A2(
									$elm$core$List$append,
									html,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('chip'),
													$elm$html$Html$Attributes$title(icd.am)
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(cat)
														]))
												]))
										]));
							}),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Data$Person$fullPersonName(stmt.dw))
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(
											A2($author$project$App$Data$Person$ageOnDate, now, stmt.dw)) + ' anos')
									])),
								sharedState.ia.h8.cJ.gX ? A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											if (mDepIdx.$ === 1) {
												var i = mDepIdx.a;
												return 'Dependente ' + $elm$core$String$fromInt(i + 1);
											} else {
												return _Utils_eq(stmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
											}
										}())
									])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
							]),
						A3(
							$elm$core$Dict$foldr,
							F3(
								function (_v0, qCatDict, catDict) {
									return A3(
										$elm$core$Dict$foldl,
										F3(
											function (cat, icd, catDictInner) {
												return A3($elm$core$Dict$insert, cat, icd, catDictInner);
											}),
										catDict,
										qCatDict);
								}),
							$elm$core$Dict$empty,
							stmt.fx)))
				]));
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewICDSection = F2(
	function (sharedState, model) {
		var bearerPersonIcd = (sharedState.ia.h8.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A4($author$project$App$Page$Doctor$PageFinalEvaluation$viewPersonICD, model.ey, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g, sharedState)
			]);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tabs ')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Dados por Pessoa')
									])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('material-icons')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('print')
													]))
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('person-icds')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_Nil,
								A3(
									$elm$core$Dict$foldl,
									F3(
										function (i, dep, html) {
											return A2(
												$elm$core$List$append,
												html,
												_List_fromArray(
													[
														A4(
														$author$project$App$Page$Doctor$PageFinalEvaluation$viewPersonICD,
														model.ey,
														$author$project$App$Data$Statement$Dependent(i),
														dep,
														sharedState)
													]));
										}),
									bearerPersonIcd,
									sharedState.ia.h8.fh))
							]))
					]))
			]);
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewMedicalDocumentContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewMedicalDocumentLayout(fullStmt);
};
var $author$project$App$Data$Statement$FieldObservation = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$InputStmtField = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$helpInputStmt = F4(
	function (msg, depIdx, field, str) {
		return A2(
			msg,
			depIdx,
			field(str));
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$InputAnswer = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$CIDSetAutocomplete = function (a) {
	return {$: 17, a: a};
};
var $author$project$App$Components$Autocomplete$HandleEscape = {$: 4};
var $author$project$App$Components$Autocomplete$SelectClosestName = {$: 5};
var $elm$html$Html$Attributes$autocomplete = function (bool) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'autocomplete',
		bool ? 'on' : 'off');
};
var $author$project$App$Components$Autocomplete$boolToString = function (bool) {
	if (bool) {
		return 'true';
	} else {
		return 'false';
	}
};
var $author$project$App$Components$Autocomplete$attributes = function (model) {
	var upDownEscDecoderHelper = function (code) {
		return ((code === 38) || (code === 40)) ? $elm$json$Json$Decode$succeed($author$project$App$Components$Autocomplete$NoOp) : ((code === 27) ? $elm$json$Json$Decode$succeed($author$project$App$Components$Autocomplete$HandleEscape) : ((code === 13) ? $elm$json$Json$Decode$succeed($author$project$App$Components$Autocomplete$SelectClosestName) : $elm$json$Json$Decode$fail('not handling that key')));
	};
	var upDownEscDecoder = A2(
		$elm$json$Json$Decode$map,
		function (msg) {
			return _Utils_Tuple2(msg, true);
		},
		A2($elm$json$Json$Decode$andThen, upDownEscDecoderHelper, $elm$html$Html$Events$keyCode));
	return _List_fromArray(
		[
			A2($elm$html$Html$Events$preventDefaultOn, 'keydown', upDownEscDecoder),
			$elm$html$Html$Attributes$id(model.as),
			$elm$html$Html$Attributes$class('autocomplete-input'),
			$elm$html$Html$Attributes$autocomplete(false),
			A2(
			$elm$html$Html$Attributes$attribute,
			'aria-expanded',
			$author$project$App$Components$Autocomplete$boolToString(model.bL)),
			A2(
			$elm$html$Html$Attributes$attribute,
			'aria-haspopup',
			$author$project$App$Components$Autocomplete$boolToString(model.bL)),
			A2($elm$html$Html$Attributes$attribute, 'role', 'combobox'),
			A2($elm$html$Html$Attributes$attribute, 'aria-autocomplete', 'list')
		]);
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$helpInputAnswer = F6(
	function (msg, depIdx, qId, stmtId, field, str) {
		return A4(
			msg,
			depIdx,
			qId,
			stmtId,
			field(str));
	});
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $elm$html$Html$Attributes$map = $elm$virtual_dom$VirtualDom$mapAttribute;
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$App$Components$Autocomplete$SetAutoState = function (a) {
	return {$: 1, a: a};
};
var $author$project$Menu$Msg = $elm$core$Basics$identity;
var $author$project$Menu$Internal$NoOp = {$: 6};
var $author$project$Menu$Internal$mapNeverToMsg = function (msg) {
	return A2(
		$elm$html$Html$Attributes$map,
		function (_v0) {
			return $author$project$Menu$Internal$NoOp;
		},
		msg);
};
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $elm$html$Html$Keyed$ul = $elm$html$Html$Keyed$node('ul');
var $author$project$Menu$Internal$MouseClick = function (a) {
	return {$: 5, a: a};
};
var $author$project$Menu$Internal$MouseEnter = function (a) {
	return {$: 3, a: a};
};
var $author$project$Menu$Internal$MouseLeave = function (a) {
	return {$: 4, a: a};
};
var $elm$html$Html$Events$onMouseEnter = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseenter',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onMouseLeave = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseleave',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Menu$Internal$viewItem = F3(
	function (_v0, _v1, data) {
		var toId = _v0.ip;
		var li = _v0.ji;
		var key = _v1.fC;
		var mouse = _v1.aC;
		var id = toId(data);
		var isSelected = function (maybeId) {
			if (!maybeId.$) {
				var someId = maybeId.a;
				return _Utils_eq(someId, id);
			} else {
				return false;
			}
		};
		var listItemData = A3(
			li,
			isSelected(key),
			isSelected(mouse),
			data);
		var customAttributes = A2($elm$core$List$map, $author$project$Menu$Internal$mapNeverToMsg, listItemData.iL);
		var customLiAttr = A2(
			$elm$core$List$append,
			customAttributes,
			_List_fromArray(
				[
					$elm$html$Html$Events$onMouseEnter(
					$author$project$Menu$Internal$MouseEnter(id)),
					$elm$html$Html$Events$onMouseLeave(
					$author$project$Menu$Internal$MouseLeave(id)),
					$elm$html$Html$Events$onClick(
					$author$project$Menu$Internal$MouseClick(id))
				]));
		return A2(
			$elm$html$Html$li,
			customLiAttr,
			A2(
				$elm$core$List$map,
				$elm$html$Html$map(
					function (_v2) {
						return $author$project$Menu$Internal$NoOp;
					}),
				listItemData.iQ));
	});
var $author$project$Menu$Internal$view = F4(
	function (config, howManyToShow, state, data) {
		var getKeyedItems = function (datum) {
			return _Utils_Tuple2(
				config.ip(datum),
				A3($author$project$Menu$Internal$viewItem, config, state, datum));
		};
		var customUlAttr = A2($elm$core$List$map, $author$project$Menu$Internal$mapNeverToMsg, config.j6);
		return A2(
			$elm$html$Html$Keyed$ul,
			customUlAttr,
			A2(
				$elm$core$List$map,
				getKeyedItems,
				A2($elm$core$List$take, howManyToShow, data)));
	});
var $author$project$Menu$view = F4(
	function (_v0, howManyToShow, _v1, data) {
		var config = _v0;
		var state = _v1;
		return A2(
			$elm$html$Html$map,
			$elm$core$Basics$identity,
			A4($author$project$Menu$Internal$view, config, howManyToShow, state, data));
	});
var $author$project$Menu$ViewConfig = $elm$core$Basics$identity;
var $author$project$Menu$Internal$viewConfig = function (_v0) {
	var toId = _v0.ip;
	var ul = _v0.j6;
	var li = _v0.ji;
	return {ji: li, ip: toId, j6: ul};
};
var $author$project$Menu$viewConfig = function (config) {
	return $author$project$Menu$Internal$viewConfig(config);
};
var $author$project$App$Components$Autocomplete$viewConfig = F2(
	function (toId, show) {
		var customizedLi = F3(
			function (keySelected, mouseSelected, item) {
				return {
					iL: _List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('autocomplete-item', true),
									_Utils_Tuple2('key-selected', keySelected || mouseSelected)
								])),
							$elm$html$Html$Attributes$id(
							toId(item))
						]),
					iQ: _List_fromArray(
						[
							$elm$html$Html$text(
							show(item))
						])
				};
			});
		return $author$project$Menu$viewConfig(
			{
				ji: customizedLi,
				ip: toId,
				j6: _List_fromArray(
					[
						$elm$html$Html$Attributes$class('autocomplete-list')
					])
			});
	});
var $author$project$App$Components$Autocomplete$viewMenu = F5(
	function (filter, toId, show, list, model) {
		return model.bL ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('autocomplete-menu')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$map,
					$author$project$App$Components$Autocomplete$SetAutoState,
					A4(
						$author$project$Menu$view,
						A2($author$project$App$Components$Autocomplete$viewConfig, toId, show),
						model.bz,
						model.aa,
						A2(filter, model.R, list)))
				])) : $elm$html$Html$text('');
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$inputIcdView = F5(
	function (depIdx, anstemp, sharedState, model, mSectionCategory) {
		var stmtId = function () {
			if (_Utils_eq($author$project$App$Data$Statement$Bearer, depIdx)) {
				return sharedState.ia.h8.g.e.as;
			} else {
				var dep = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2(
						$elm$core$Dict$get,
						$author$project$App$Data$Statement$depIndexToInt(depIdx),
						sharedState.ia.h8.fh));
				return dep.e.as;
			}
		}();
		var ansICD = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Answer$emptyModel,
			A2(
				$elm$core$Dict$get,
				anstemp.as,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Dict$empty,
					A2(
						$elm$core$Dict$get,
						$author$project$App$Data$Statement$depIndexToInt(depIdx),
						model.aZ))));
		var attrs = _List_fromArray(
			[
				$elm$html$Html$Attributes$type_('text'),
				$elm$html$Html$Attributes$value(ansICD.am),
				$elm$html$Html$Attributes$class('chip-input'),
				$elm$html$Html$Attributes$placeholder('Especificar CID'),
				$elm$html$Html$Events$onInput(
				A5($author$project$App$Page$Doctor$PageFinalEvaluation$helpInputAnswer, $author$project$App$Page$Doctor$PageFinalEvaluation$InputCIDField, depIdx, anstemp.as, stmtId, $author$project$App$Data$Answer$FieldDescription)),
				$elm$html$Html$Events$onFocus(
				$author$project$App$Page$Doctor$PageFinalEvaluation$CIDSetAutocomplete(
					A3($author$project$App$Page$Doctor$PageFinalEvaluation$AcQuestionIndex, anstemp.as, depIdx, mSectionCategory)))
			]);
		var acView = function () {
			var _v1 = model.f8.gt;
			var atId = _v1.a;
			var mIdx = _v1.b;
			return (_Utils_eq(atId, anstemp.as) && _Utils_eq(depIdx, mIdx)) ? A2(
				$elm$html$Html$map,
				$author$project$App$Page$Doctor$PageFinalEvaluation$CIDAutocompleteMsg(anstemp),
				A5($author$project$App$Components$Autocomplete$viewMenu, $author$project$App$Page$Doctor$PageFinalEvaluation$acICDCatFilter, $author$project$App$Page$Doctor$PageFinalEvaluation$acICDCatId, $author$project$App$Page$Doctor$PageFinalEvaluation$acICDCatDesc, model.fa, model.f8.fb)) : $elm$html$Html$text('');
		}();
		var acAttrs = function () {
			var _v0 = model.f8.gt;
			var atId = _v0.a;
			var mIdx = _v0.b;
			return (_Utils_eq(atId, anstemp.as) && _Utils_eq(depIdx, mIdx)) ? A2(
				$elm$core$List$append,
				attrs,
				A2(
					$elm$core$List$map,
					function (attr) {
						return A2(
							$elm$html$Html$Attributes$map,
							$author$project$App$Page$Doctor$PageFinalEvaluation$CIDAutocompleteMsg(anstemp),
							attr);
					},
					$author$project$App$Components$Autocomplete$attributes(model.f8.fb))) : attrs;
		}();
		return _List_fromArray(
			[
				A2($elm$html$Html$input, acAttrs, _List_Nil),
				acView
			]);
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$CIDRemove = F2(
	function (a, b) {
		return {$: 19, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewICD = F4(
	function (ansTemp, catCode, mIdx, icd) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('chip'),
					$elm$html$Html$Attributes$title(icd.am)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(icd.a0)
						])),
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons md-18 close closebtn'),
							$elm$html$Html$Events$onClick(
							A2($author$project$App$Page$Doctor$PageFinalEvaluation$CIDRemove, mIdx, icd.as))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('cancel')
						]))
				]));
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewICDs = F3(
	function (icds, ansTemp, mIdx) {
		return A3(
			$elm$core$Dict$foldr,
			F3(
				function (catCode, icd, result) {
					return A2(
						$elm$core$List$append,
						result,
						_List_fromArray(
							[
								A4($author$project$App$Page$Doctor$PageFinalEvaluation$viewICD, ansTemp, catCode, mIdx, icd)
							]));
				}),
			_List_Nil,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Dict$empty,
				A2($elm$core$Dict$get, ansTemp.as, icds)));
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewBMI = F4(
	function (sharedState, model, depIdx, stmt) {
		var weightTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 10) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.jM);
		var personQuestion = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestion$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (q) {
						return $author$project$App$Data$Id$uuidIsValid(q.hG.as);
					},
					A2(
						$elm$core$List$map,
						function (_v0) {
							var s = _v0.b;
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (q) {
											return _Utils_eq(q.hG.as, weightTemplate.bh);
										},
										s.eI)));
						},
						$elm$core$Dict$toList(model.cA)))));
		var heightTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 20) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.jM);
		var bmiTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 1000) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.gH);
		var doctorQuestion = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestion$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (q) {
						return $author$project$App$Data$Id$uuidIsValid(q.hG.as);
					},
					A2(
						$elm$core$List$map,
						function (s) {
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (q) {
											return _Utils_eq(q.hG.as, bmiTemplate.bh);
										},
										s.eI)));
						},
						model.P.gH))));
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no DPS')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatCompanyBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						A2(
							$elm$core$List$cons,
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Doctor$PageFinalEvaluation$viewICDs, stmt.fx, weightTemplate, depIdx)),
							A5(
								$author$project$App$Page$Doctor$PageFinalEvaluation$inputIcdView,
								depIdx,
								weightTemplate,
								sharedState,
								model,
								$elm$core$Maybe$Just(1))))
					])),
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no Questionário')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatPersonBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						A2(
							$elm$core$List$cons,
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Doctor$PageFinalEvaluation$viewICDs, stmt.fx, heightTemplate, depIdx)),
							A5($author$project$App$Page$Doctor$PageFinalEvaluation$inputIcdView, depIdx, heightTemplate, sharedState, model, $elm$core$Maybe$Nothing)))
					])),
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no Atendimento')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatDoctorBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						A2(
							$elm$core$List$cons,
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Doctor$PageFinalEvaluation$viewICDs, stmt.fx, bmiTemplate, depIdx)),
							A5($author$project$App$Page$Doctor$PageFinalEvaluation$inputIcdView, depIdx, bmiTemplate, sharedState, model, $elm$core$Maybe$Nothing)))
					]))
			]);
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$maskDecimal = F2(
	function (precision, value) {
		return A3($elm$core$String$replace, '.', ',', value);
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$maskHeight = function (cm) {
	var len = $elm$core$String$length(cm);
	var subM = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cm)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cm) : cm);
	var h = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(cm));
	var m = (h >= 100) ? A3($elm$core$String$slice, 0, -2, cm) : '0';
	return (h > 0) ? (m + (',' + subM)) : '';
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$maskPressure = function (str) {
	return function (p) {
		return (p > 0) ? $elm$core$String$fromInt(p) : '';
	}(
		function (p) {
			return (p > 999) ? (p / 10) : (p / 1.0);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(str))) | 0);
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$maskWeight = function (wStr) {
	return function (w) {
		return (w > 0) ? $elm$core$String$fromInt(w) : '';
	}(
		function (w) {
			return w / 1000;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(wStr))) | 0);
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$setupMask = F2(
	function (template, answer) {
		var _v0 = template.jj;
		if (_v0.$ === 1) {
			return answer.T;
		} else {
			switch (_v0.a.$) {
				case 3:
					var precision = _v0.a.a;
					return A2($author$project$App$Page$Doctor$PageFinalEvaluation$maskDecimal, precision, answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 0:
					var _v1 = _v0.a;
					return $author$project$App$Page$Doctor$PageFinalEvaluation$maskWeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 1:
					var _v2 = _v0.a;
					return $author$project$App$Page$Doctor$PageFinalEvaluation$maskHeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				default:
					var _v3 = _v0.a;
					return $author$project$App$Page$Doctor$PageFinalEvaluation$maskPressure(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
			}
		}
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewQuestion = F5(
	function (depIdx, question, sharedState, model, doctorQuestion) {
		var stmt = function () {
			if (!depIdx.$) {
				return sharedState.ia.h8.g;
			} else {
				var idx = depIdx.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, sharedState.ia.h8.fh));
			}
		}();
		var showCids = !A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		return A2(
			$elm$core$List$map,
			function (t) {
				var answer = A3($author$project$App$Page$Doctor$PageFinalEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
				var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
				return (show || (t.ay >= 1000)) ? A2(
					$elm$html$Html$tr,
					_List_Nil,
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(question.hG.bP)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(t.jh)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($author$project$App$Page$Doctor$PageFinalEvaluation$setupMask, t, answer))
									]))
							]),
						showCids ? _List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$type_('text'),
												$elm$html$Html$Attributes$value(answer.jx),
												$elm$html$Html$Events$onInput(
												A2(
													$elm$core$Basics$composeR,
													$author$project$App$Data$Answer$FieldObservation,
													A2($author$project$App$Page$Doctor$PageFinalEvaluation$InputAnswer, depIdx, answer)))
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_Utils_ap(
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('chips chips-initial input-field')
												]),
											A3($author$project$App$Page$Doctor$PageFinalEvaluation$viewICDs, stmt.fx, t, depIdx))
										]),
									A5($author$project$App$Page$Doctor$PageFinalEvaluation$inputIcdView, depIdx, t, sharedState, model, $elm$core$Maybe$Nothing)))
							]) : _List_Nil)) : A2($elm$html$Html$span, _List_Nil, _List_Nil);
			},
			A2(
				$elm$core$List$filter,
				function (t) {
					var answer = A3($author$project$App$Page$Doctor$PageFinalEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
					var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
					return show;
				},
				question.bY));
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewEditStatementSectionWithHeader = F5(
	function (depIdx, sharedState, model, fullQuestionSection, doctorQuestionSection) {
		var showCids = !A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var sectionName = function () {
			if (sharedState.ia.h8.b4.iR === 'Extramed') {
				var _v0 = fullQuestionSection.hX.a0;
				switch (_v0) {
					case 2:
						return 'Questionário';
					case 1:
						return 'Declaração';
					case 3:
						return 'Atendimento';
					default:
						return 'Desconhecido';
				}
			} else {
				return A2($elm$core$String$left, 50, fullQuestionSection.hX.hl) + '...';
			}
		}();
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '90%')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$colgroup,
					_List_Nil,
					_List_fromArray(
						[
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil)
						])),
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(sectionName)
											])),
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$colspan(2)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Resposta')
											]))
									]),
								showCids ? _List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Obs')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('CIDs')
											]))
									]) : _List_Nil))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, q) {
									return A5(
										$author$project$App$Page$Doctor$PageFinalEvaluation$viewQuestion,
										depIdx,
										q,
										sharedState,
										model,
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$FullQuestion$emptyModel,
											$elm$core$List$head(
												A2($elm$core$List$drop, i, doctorQuestionSection.eI))));
								}),
							A2(
								$elm$core$List$sortBy,
								function (sec) {
									return sec.hG.bB;
								},
								A2(
									$elm$core$List$filter,
									function (q) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (t) {
													var answer = A3($author$project$App$Page$Doctor$PageFinalEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
													var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
													return show;
												},
												q.bY)) > 0;
									},
									fullQuestionSection.eI)))))
				]));
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewEditStatementSectionWithoutHeader = F5(
	function (depIdx, sharedState, model, fullQuestionSection, doctorQuestionSection) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$tbody,
				_List_Nil,
				$elm$core$List$concat(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, q) {
								return A5(
									$author$project$App$Page$Doctor$PageFinalEvaluation$viewQuestion,
									depIdx,
									q,
									sharedState,
									model,
									A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$FullQuestion$emptyModel,
										$elm$core$List$head(
											A2($elm$core$List$drop, i, doctorQuestionSection.eI))));
							}),
						A2(
							$elm$core$List$sortBy,
							function (sec) {
								return sec.hG.bB;
							},
							A2(
								$elm$core$List$filter,
								function (q) {
									return $elm$core$List$length(
										A2(
											$elm$core$List$filter,
											function (t) {
												var answer = A3($author$project$App$Page$Doctor$PageFinalEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
												var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
												return show;
											},
											q.bY)) > 0;
								},
								fullQuestionSection.eI)))))
			]);
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewPersonDetails = F3(
	function (sharedState, depIdx, editStmt) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(editStmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$fullPersonName(editStmt.dw))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$formatDocument(editStmt.dw.i1))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Tools$FormHelpers$dateFormat(editStmt.dw.al))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									function (str) {
										return str + ' anos';
									}(
										$elm$core$String$fromInt(
											A2(
												$author$project$App$Data$Person$ageOnDate,
												$author$project$App$Service$ApiWebSocket$getTimestamp(sharedState._),
												editStmt.dw))))
								])),
							sharedState.ia.h8.cJ.gX ? A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									function () {
										if (depIdx.$ === 1) {
											var i = depIdx.a;
											return 'Dependente ' + $elm$core$String$fromInt(i + 1);
										} else {
											return _Utils_eq(editStmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
										}
									}())
								])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
						]))
				]));
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewPersonAnswers = F4(
	function (sharedState, model, depIdx, stmt) {
		var showPerson = (sharedState.ia.h8.b4.iR === 'Extramed') || (sharedState.ia.h8.b4.iR === 'Medsyn');
		var showDoctorQuestions = (sharedState.ia.h8.b4.iR === 'Extramed') || ((sharedState.ia.h8.b4.iR === 'Medsyn') || (sharedState.ia.h8.b4.iR === 'Centauro-ON'));
		var showBMI = sharedState.ia.h8.b4.iR === 'Extramed';
		var doctorSection = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestionSection$emptyModel,
			$elm$core$List$head(
				$elm$core$List$reverse(sharedState.ia.h8.gH)));
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$ul,
							_List_Nil,
							_List_fromArray(
								[
									A3($author$project$App$Page$Doctor$PageFinalEvaluation$viewPersonDetails, sharedState, depIdx, stmt)
								]))
						])),
					showBMI ? A2(
					$elm$html$Html$table,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$colgroup,
							_List_Nil,
							_List_fromArray(
								[
									A2($elm$html$Html$col, _List_Nil, _List_Nil),
									A2($elm$html$Html$col, _List_Nil, _List_Nil),
									A2($elm$html$Html$col, _List_Nil, _List_Nil),
									A2($elm$html$Html$col, _List_Nil, _List_Nil)
								])),
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Dados Antropométricos')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Valor')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('CIDs')
												]))
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							A4($author$project$App$Page$Doctor$PageFinalEvaluation$viewBMI, sharedState, model, depIdx, stmt))
						])) : $elm$html$Html$text('')
				]),
			_Utils_ap(
				showBMI ? A2(
					$elm$core$List$cons,
					A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '90%')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$colgroup,
								_List_Nil,
								_List_fromArray(
									[
										A2($elm$html$Html$col, _List_Nil, _List_Nil),
										A2($elm$html$Html$col, _List_Nil, _List_Nil),
										A2($elm$html$Html$col, _List_Nil, _List_Nil),
										A2($elm$html$Html$col, _List_Nil, _List_Nil),
										A2($elm$html$Html$col, _List_Nil, _List_Nil)
									])),
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Declaração')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Obs')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('CIDs')
													]))
											]))
									]))
							])),
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, s) {
									return A5(
										$author$project$App$Page$Doctor$PageFinalEvaluation$viewEditStatementSectionWithoutHeader,
										depIdx,
										sharedState,
										model,
										s,
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$FullQuestionSection$emptyModel,
											$elm$core$List$head(
												A2($elm$core$List$drop, i, sharedState.ia.h8.bs))));
								}),
							A2(
								$elm$core$List$filter,
								function (s) {
									return $elm$core$List$length(
										A2(
											$elm$core$List$filter,
											function (q) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (t) {
															var answer = A3($author$project$App$Page$Doctor$PageFinalEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
															var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
															return show;
														},
														q.bY)) > 0;
											},
											A2(
												$elm$core$List$sortBy,
												function (sec) {
													return sec.hG.bB;
												},
												s.eI))) > 0;
								},
								sharedState.ia.h8.bs)))) : A2(
					$elm$core$List$indexedMap,
					F2(
						function (i, s) {
							return A5(
								$author$project$App$Page$Doctor$PageFinalEvaluation$viewEditStatementSectionWithHeader,
								depIdx,
								sharedState,
								model,
								s,
								A2(
									$elm$core$Maybe$withDefault,
									$author$project$App$Data$FullQuestionSection$emptyModel,
									$elm$core$List$head(
										A2($elm$core$List$drop, i, sharedState.ia.h8.bs))));
						}),
					A2(
						$elm$core$List$filter,
						function (s) {
							return $elm$core$List$length(
								A2(
									$elm$core$List$filter,
									function (q) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (t) {
													var answer = A3($author$project$App$Page$Doctor$PageFinalEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
													var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
													return show;
												},
												q.bY)) > 0;
									},
									A2(
										$elm$core$List$sortBy,
										function (sec) {
											return sec.hG.bB;
										},
										s.eI))) > 0;
						},
						sharedState.ia.h8.bs)),
				_Utils_ap(
					showBMI ? A2(
						$elm$core$List$cons,
						A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '90%')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$colgroup,
									_List_Nil,
									_List_fromArray(
										[
											A2($elm$html$Html$col, _List_Nil, _List_Nil),
											A2($elm$html$Html$col, _List_Nil, _List_Nil),
											A2($elm$html$Html$col, _List_Nil, _List_Nil),
											A2($elm$html$Html$col, _List_Nil, _List_Nil),
											A2($elm$html$Html$col, _List_Nil, _List_Nil)
										])),
									A2(
									$elm$html$Html$thead,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Questionário')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Obs')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('CIDs')
														]))
												]))
										]))
								])),
						$elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								F2(
									function (i, s) {
										return A5(
											$author$project$App$Page$Doctor$PageFinalEvaluation$viewEditStatementSectionWithoutHeader,
											depIdx,
											sharedState,
											model,
											s,
											A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$FullQuestionSection$emptyModel,
												$elm$core$List$head(
													A2($elm$core$List$drop, i, sharedState.ia.h8.jM))));
									}),
								A2(
									$elm$core$List$filter,
									function (s) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (q) {
													return $elm$core$List$length(
														A2(
															$elm$core$List$filter,
															function (t) {
																var answer = A3($author$project$App$Page$Doctor$PageFinalEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
																var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
																return show;
															},
															q.bY)) > 0;
												},
												A2(
													$elm$core$List$sortBy,
													function (sec) {
														return sec.hG.bB;
													},
													s.eI))) > 0;
									},
									sharedState.ia.h8.jM)))) : (showPerson ? A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, s) {
								return A5(
									$author$project$App$Page$Doctor$PageFinalEvaluation$viewEditStatementSectionWithHeader,
									depIdx,
									sharedState,
									model,
									s,
									A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$FullQuestionSection$emptyModel,
										$elm$core$List$head(
											A2($elm$core$List$drop, i, sharedState.ia.h8.jM))));
							}),
						A2(
							$elm$core$List$filter,
							function (s) {
								return $elm$core$List$length(
									A2(
										$elm$core$List$filter,
										function (q) {
											return $elm$core$List$length(
												A2(
													$elm$core$List$filter,
													function (t) {
														var answer = A3($author$project$App$Page$Doctor$PageFinalEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
														var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
														return show;
													},
													q.bY)) > 0;
										},
										A2(
											$elm$core$List$sortBy,
											function (sec) {
												return sec.hG.bB;
											},
											s.eI))) > 0;
							},
							sharedState.ia.h8.jM)) : _List_Nil),
					function () {
						if (showDoctorQuestions) {
							return showBMI ? A2(
								$elm$core$List$cons,
								A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'width', '90%')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$colgroup,
											_List_Nil,
											_List_fromArray(
												[
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil)
												])),
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$tr,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Atendimento')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Obs')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('CIDs')
																]))
														]))
												]))
										])),
								$elm$core$List$concat(
									A2(
										$elm$core$List$map,
										function (s) {
											return A5($author$project$App$Page$Doctor$PageFinalEvaluation$viewEditStatementSectionWithoutHeader, depIdx, sharedState, model, s, s);
										},
										A2(
											$elm$core$List$filter,
											function (s) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (q) {
															return ($elm$core$List$length(
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var answer = A3($author$project$App$Page$Doctor$PageFinalEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
																		var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																		return show;
																	},
																	q.bY)) > 0) && (!A2(
																$elm$core$List$any,
																function (t) {
																	return (t.ay === 10) || (t.ay === 20);
																},
																q.bY));
														},
														A2(
															$elm$core$List$sortBy,
															function (sec) {
																return sec.hG.bB;
															},
															s.eI))) > 0;
											},
											sharedState.ia.h8.gH)))) : A2(
								$elm$core$List$map,
								function (s) {
									return A5($author$project$App$Page$Doctor$PageFinalEvaluation$viewEditStatementSectionWithHeader, depIdx, sharedState, model, s, s);
								},
								A2(
									$elm$core$List$filter,
									function (s) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (q) {
													return $elm$core$List$length(
														A2(
															$elm$core$List$filter,
															function (t) {
																var answer = A3($author$project$App$Page$Doctor$PageFinalEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
																var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																return show;
															},
															q.bY)) > 0;
												},
												A2(
													$elm$core$List$sortBy,
													function (sec) {
														return sec.hG.bB;
													},
													s.eI))) > 0;
									},
									sharedState.ia.h8.gH));
						} else {
							var question = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(doctorSection.eI));
							var template = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$AnswerTemplate$emptyModel,
								$elm$core$List$head(question.bY));
							var answer = A3($author$project$App$Page$Doctor$PageFinalEvaluation$getAnswerForTemplateId, depIdx, model, template.as);
							return (answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0))) ? _List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Evoluções médicas:')
										])),
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(answer.T),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeR,
												$author$project$App$Data$Answer$FieldValue,
												A2($author$project$App$Page$Doctor$PageFinalEvaluation$InputAnswer, depIdx, answer)))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Atestado Médico:')
										])),
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(answer.jx),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeR,
												$author$project$App$Data$Answer$FieldObservation,
												A2($author$project$App$Page$Doctor$PageFinalEvaluation$InputAnswer, depIdx, answer)))
										]),
									_List_Nil)
								]) : _List_Nil;
						}
					}())));
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewQuestions = F2(
	function (model, sharedState) {
		var depsPersonAnswers = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$second,
				$elm$core$Dict$toList(
					A2(
						$elm$core$Dict$map,
						F2(
							function (idx, dep) {
								return A4(
									$author$project$App$Page$Doctor$PageFinalEvaluation$viewPersonAnswers,
									sharedState,
									model,
									$author$project$App$Data$Statement$depIndexFromInt(idx),
									dep);
							}),
						sharedState.ia.h8.fh))));
		var bearerPersonAnswers = (!(sharedState.ia.h8.g.e.j_ === 2)) ? A4($author$project$App$Page$Doctor$PageFinalEvaluation$viewPersonAnswers, sharedState, model, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g) : _List_Nil;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('questions')
				]),
			_Utils_ap(
				bearerPersonAnswers,
				_Utils_ap(
					depsPersonAnswers,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Conclusões finais do atendimento:')
								])),
							A2(
							$elm$html$Html$textarea,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(model.P.g.e.jx),
									$elm$html$Html$Events$onInput(
									A3($author$project$App$Page$Doctor$PageFinalEvaluation$helpInputStmt, $author$project$App$Page$Doctor$PageFinalEvaluation$InputStmtField, $author$project$App$Data$Statement$Bearer, $author$project$App$Data$Statement$FieldObservation))
								]),
							_List_Nil)
						]))));
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$SelectDetailsTab = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$TabCPT = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$TabDocument = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$TabMedicalDocument = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$TabResult = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewDocumentTabNumered = F4(
	function (currentTab, idx, doc, documentTabs) {
		var index = $elm$core$List$length(documentTabs) + 1;
		return A2(
			$elm$core$List$append,
			documentTabs,
			function () {
				var _v0 = doc.dr;
				switch (_v0) {
					case 'video/webm':
						return _List_fromArray(
							[
								$elm$html$Html$text('')
							]);
					case 'video/mp4':
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Doctor$PageFinalEvaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Doctor$PageFinalEvaluation$SelectDetailsTab(
											$author$project$App$Page$Doctor$PageFinalEvaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										'Video' + $elm$core$String$fromInt(index))
									]))
							]);
					default:
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Doctor$PageFinalEvaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Doctor$PageFinalEvaluation$SelectDetailsTab(
											$author$project$App$Page$Doctor$PageFinalEvaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											var _v1 = doc.b7;
											switch (_v1) {
												case 1:
													return 'Parecer Antigo' + $elm$core$String$fromInt(index);
												case 3:
													return 'Atestado' + $elm$core$String$fromInt(index);
												case 4:
													return 'Receita' + $elm$core$String$fromInt(index);
												case 5:
													return 'CPT' + $elm$core$String$fromInt(index);
												default:
													return 'Anexo';
											}
										}())
									]))
							]);
				}
			}());
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewResultTab = F5(
	function (previewTitle, typeHnsg, currentTab, idx, d) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Doctor$PageFinalEvaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Doctor$PageFinalEvaluation$SelectDetailsTab(
							$author$project$App$Page$Doctor$PageFinalEvaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(previewTitle)
					])),
				typeHnsg ? A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Doctor$PageFinalEvaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Doctor$PageFinalEvaluation$SelectDetailsTab(
							$author$project$App$Page$Doctor$PageFinalEvaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Pré-Visualizar Atestado')
					])) : A2($elm$html$Html$span, _List_Nil, _List_Nil)
			]);
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewQuestionsTabs = F5(
	function (fullStmt, viewCPT, typeHnsg, currentTab, docs) {
		var reportDocs = A2(
			$elm$core$List$sortWith,
			F2(
				function (a, b) {
					var _v10 = A2(
						$elm$core$Basics$compare,
						$elm$time$Time$posixToMillis(a.b.iW),
						$elm$time$Time$posixToMillis(b.b.iW));
					switch (_v10) {
						case 0:
							return 2;
						case 1:
							return 1;
						default:
							return 0;
					}
				}),
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v9, doc) {
							return doc.b7 === 1;
						}),
					docs)));
		var previewTitle = typeHnsg ? 'Pré-Visualizar Prontuário' : 'Pré-Visualizar Parecer';
		var resultTab = A2(
			$elm$core$Maybe$withDefault,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'tab-selected',
									_Utils_eq(
										currentTab,
										$author$project$App$Page$Doctor$PageFinalEvaluation$TabResult($author$project$App$Data$Statement$Bearer)))
								])),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Doctor$PageFinalEvaluation$SelectDetailsTab(
								$author$project$App$Page$Doctor$PageFinalEvaluation$TabResult($author$project$App$Data$Statement$Bearer)))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(previewTitle)
						])),
					typeHnsg ? A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'tab-selected',
									_Utils_eq(
										currentTab,
										$author$project$App$Page$Doctor$PageFinalEvaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
								])),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Doctor$PageFinalEvaluation$SelectDetailsTab(
								$author$project$App$Page$Doctor$PageFinalEvaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Pré-Visualizar Atestado')
						])) : A2($elm$html$Html$span, _List_Nil, _List_Nil)
				]),
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$second,
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$map,
							A3($author$project$App$Page$Doctor$PageFinalEvaluation$viewResultTab, previewTitle, typeHnsg, currentTab),
							$elm$core$Dict$fromList(
								A2($elm$core$List$take, 1, reportDocs)))))));
		var oldResultsTab = A3(
			$elm$core$Dict$foldl,
			$author$project$App$Page$Doctor$PageFinalEvaluation$viewDocumentTabNumered(currentTab),
			_List_Nil,
			$elm$core$Dict$fromList(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(reportDocs))));
		var docsTabs = A3(
			$elm$core$Dict$foldl,
			F3(
				function (i, d, list) {
					var attachName = function () {
						var _v8 = d.dr;
						if (_v8 === 'video/mp4') {
							return 'Video';
						} else {
							return (d.b7 === 4) ? 'Receita' : ('Anexo ' + $elm$core$String$fromInt(i));
						}
					}();
					return A2(
						$elm$core$List$append,
						list,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Doctor$PageFinalEvaluation$TabDocument(i)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Doctor$PageFinalEvaluation$SelectDetailsTab(
											$author$project$App$Page$Doctor$PageFinalEvaluation$TabDocument(i))),
										$elm$html$Html$Attributes$href('#')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(attachName)
									]))
							]));
				}),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'tab-selected',
									_Utils_eq(currentTab, $author$project$App$Page$Doctor$PageFinalEvaluation$TabCIDs))
								])),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Doctor$PageFinalEvaluation$SelectDetailsTab($author$project$App$Page$Doctor$PageFinalEvaluation$TabCIDs))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Dados do Atendimento')
						]))
				]),
			A2(
				$elm$core$Dict$filter,
				F2(
					function (_v7, d) {
						return d.b7 !== 3;
					}),
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v6, d) {
							return d.b7 !== 5;
						}),
					A2(
						$elm$core$Dict$filter,
						F2(
							function (_v5, d) {
								return d.b7 !== 1;
							}),
						$elm$core$Dict$fromList(
							_Utils_ap(
								A2(
									$elm$core$List$take,
									1,
									$elm$core$List$reverse(
										$elm$core$Dict$toList(
											A2(
												$elm$core$Dict$filter,
												F2(
													function (_v3, doc) {
														return doc.dr === 'video/mp4';
													}),
												docs)))),
								$elm$core$Dict$toList(
									A2(
										$elm$core$Dict$filter,
										F2(
											function (_v4, doc) {
												return (doc.dr !== 'video/webm') && (doc.dr !== 'video/mp4');
											}),
										docs))))))));
		var cptDocs = A2(
			$elm$core$List$sortWith,
			F2(
				function (a, b) {
					var _v2 = A2(
						$elm$core$Basics$compare,
						$elm$time$Time$posixToMillis(a.b.iW),
						$elm$time$Time$posixToMillis(b.b.iW));
					switch (_v2) {
						case 0:
							return 2;
						case 1:
							return 1;
						default:
							return 0;
					}
				}),
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v1, doc) {
							return doc.b7 === 5;
						}),
					docs)));
		var cptDocumentsTab = A3(
			$elm$core$Dict$foldl,
			$author$project$App$Page$Doctor$PageFinalEvaluation$viewDocumentTabNumered(currentTab),
			_List_Nil,
			$elm$core$Dict$fromList(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(cptDocs))));
		var bearerCpt = (fullStmt.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Doctor$PageFinalEvaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Doctor$PageFinalEvaluation$SelectDetailsTab(
							$author$project$App$Page$Doctor$PageFinalEvaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Pré-visualizar CPT Titular')
					]))
			]);
		var cptTabs = viewCPT ? A2(
			$elm$core$List$append,
			bearerCpt,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var idx = _v0.a;
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('#'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'tab-selected',
										_Utils_eq(
											currentTab,
											$author$project$App$Page$Doctor$PageFinalEvaluation$TabCPT(
												$author$project$App$Data$Statement$Dependent(idx))))
									])),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Doctor$PageFinalEvaluation$SelectDetailsTab(
									$author$project$App$Page$Doctor$PageFinalEvaluation$TabCPT(
										$author$project$App$Data$Statement$Dependent(idx))))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								' Pré-visualizar CPT Dep ' + $elm$core$String$fromInt(idx + 1))
							]));
				},
				$elm$core$Dict$toList(fullStmt.fh))) : _List_Nil;
		var allTabs = _Utils_ap(
			docsTabs,
			_Utils_ap(
				resultTab,
				_Utils_ap(
					oldResultsTab,
					_Utils_ap(cptTabs, cptDocumentsTab))));
		return A2($elm$html$Html$div, _List_Nil, allTabs);
	});
var $author$project$App$Page$Doctor$PageFinalEvaluation$viewResultContent = function (fullStmt) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$App$Legacy$Doctor$ResultLayout$Common$viewResultLayout(fullStmt),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'justify-content', 'center'),
						A2($elm$html$Html$Attributes$style, 'margin-bottom', '2em')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageFinalEvaluation$AddReport),
								$elm$html$Html$Attributes$value('Adicionar Parecer')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Adicionar Parecer')
							]))
					]))
			]));
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$CancelWarning = {$: 8};
var $author$project$App$Page$Doctor$PageFinalEvaluation$ConfirmWarning = {$: 7};
var $author$project$App$Page$Doctor$PageFinalEvaluation$warningDialog = function (model) {
	if (model.h0) {
		var header = _List_Nil;
		var footer = _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'flex-direction', 'row-reverse'),
						A2($elm$html$Html$Attributes$style, 'right', '0px')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('cancel-button'),
										$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageFinalEvaluation$CancelWarning)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Cancelar')
									])),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageFinalEvaluation$ConfirmWarning)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Confirmar')
									]))
							])),
						$author$project$App$Page$Doctor$PageFinalEvaluation$viewLoadingOrError(model)
					]))
			]);
		var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
		var body = _List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('É de prioridade a implantação do cid em todos os itens da declaração de saúde.'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						$elm$html$Html$text('Tem certeza que o(a) doutor(a) quer deixar em branco?')
					]))
			]);
		var completeConfig = _Utils_update(
			config,
			{
				e5: $elm$core$Maybe$Just(body),
				ft: $elm$core$Maybe$Just(footer),
				gY: $elm$core$Maybe$Just(header),
				f1: 'Confirmação'
			});
		return $author$project$App$Components$Dialog$view(
			$elm$core$Maybe$Just(completeConfig));
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$App$Page$Doctor$PageFinalEvaluation$view = F3(
	function (now, sharedState, model) {
		var viewCPT = sharedState.ia.h8.b4.iR === 'Extramed';
		var typeHnsg = A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var maybePdfResult = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var maybePdfDocument = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var hasMedicalReport = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var hasMedicalDocument = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var canBeSigned = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (_v3) {
					var stmtIdx = _v3.a;
					var stmt = _v3.b;
					var idx = $author$project$App$Data$Statement$depIndexFromInt(stmtIdx);
					return $elm$core$List$length(
						A2(
							$elm$core$List$filter,
							function (s) {
								return $elm$core$List$length(
									A2(
										$elm$core$List$filter,
										function (q) {
											return $elm$core$List$length(
												A2(
													$elm$core$List$filter,
													function (t) {
														var hasIcd = A2($elm$core$Dict$member, t.as, stmt.fx);
														var answer = A3($author$project$App$Page$Doctor$PageFinalEvaluation$getAnswerForTemplateId, idx, model, t.as);
														var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
														return show && (!hasIcd);
													},
													q.bY)) > 0;
										},
										A2(
											$elm$core$List$sortBy,
											function (sec) {
												return sec.hG.bB;
											},
											s.eI))) > 0;
							},
							sharedState.ia.h8.bs)) > 0;
				},
				$elm$core$Dict$toList(sharedState.ia.h8.fh)))) && (!function () {
			var idx = $author$project$App$Data$Statement$Bearer;
			return $elm$core$List$length(
				A2(
					$elm$core$List$filter,
					function (s) {
						return $elm$core$List$length(
							A2(
								$elm$core$List$filter,
								function (q) {
									return $elm$core$List$length(
										A2(
											$elm$core$List$filter,
											function (t) {
												var hasIcd = A2($elm$core$Dict$member, t.as, sharedState.ia.h8.g.fx);
												var answer = A3($author$project$App$Page$Doctor$PageFinalEvaluation$getAnswerForTemplateId, idx, model, t.as);
												var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
												return show && (!hasIcd);
											},
											q.bY)) > 0;
								},
								A2(
									$elm$core$List$sortBy,
									function (sec) {
										return sec.hG.bB;
									},
									s.eI))) > 0;
					},
					sharedState.ia.h8.bs));
		}());
		var signButton = canBeSigned ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageFinalEvaluation$SignDocument)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Assinar')
				])) : A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageFinalEvaluation$ShowWarning)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Assinar')
				]));
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$header,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('tabs')
								]),
							_List_fromArray(
								[
									A5($author$project$App$Page$Doctor$PageFinalEvaluation$viewQuestionsTabs, sharedState.ia.h8, viewCPT, typeHnsg, model.b5, sharedState.ia.h8.an),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											(typeHnsg && ($elm$core$String$length(model.ho.e$) > 0)) ? A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageFinalEvaluation$OpenNexodata)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Receituário')
												])) : A2($elm$html$Html$span, _List_Nil, _List_Nil),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('cancel-button'),
													$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageFinalEvaluation$BackToPending)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Retornar')
												])),
											(hasMedicalReport && (hasMedicalDocument || (!typeHnsg))) ? A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick(
													$author$project$App$Page$Doctor$PageFinalEvaluation$AproveStatement(sharedState.ia.h8.g.e.as))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Finalizar')
												])) : signButton
										]))
								])),
							function () {
							var _v0 = model.b5;
							switch (_v0.$) {
								case 0:
									return A2($author$project$App$Page$Doctor$PageFinalEvaluation$viewQuestions, model, sharedState);
								case 1:
									var docIdx = _v0.a;
									return $author$project$App$Page$Doctor$PageFinalEvaluation$viewDoc(
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$Document$emptyModel,
											A2($elm$core$Dict$get, docIdx, model.an)));
								case 2:
									var mDepIdx = _v0.a;
									if (!maybePdfResult.$) {
										var pdfResult = maybePdfResult.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Doctor$PageFinalEvaluation$viewResultContent(sharedState.ia.h8);
									}
								case 3:
									var mDepIdx = _v0.a;
									if (!maybePdfDocument.$) {
										var pdfResult = maybePdfDocument.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Doctor$PageFinalEvaluation$viewMedicalDocumentContent(model.P);
									}
								case 5:
									var videoRepo = {f3: 'video-repo-enterprise-covid.ingress.medsyn.com.br'};
									var videoUrl = A2($author$project$App$Data$VideoRepository$statementVideoUrl, videoRepo, sharedState.ia.h8.g.e.as);
									return A2(
										$elm$html$Html$video,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('video-preview'),
												$elm$html$Html$Attributes$controls(true)
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$source,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$src(videoUrl),
														$elm$html$Html$Attributes$type_('video/mp4')
													]),
												_List_Nil),
												$elm$html$Html$text('Seu browser não suporte exibição de vídeo HTML5')
											]));
								case 4:
									var depIdx = _v0.a;
									var fullStmt = sharedState.ia.h8;
									return A3($author$project$App$Page$Doctor$PageFinalEvaluation$viewCPTContent, depIdx, fullStmt, now);
								default:
									return $elm$html$Html$text('');
							}
						}()
						])),
					A2($author$project$App$Page$Doctor$PageFinalEvaluation$viewDialog, sharedState, model),
					$author$project$App$Page$Doctor$PageFinalEvaluation$warningDialog(model)
				]),
			A2($author$project$App$Page$Doctor$PageFinalEvaluation$viewICDSection, sharedState, model));
	});
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $author$project$App$Data$Jitsi$view = function (options) {
	return A3(
		$elm$html$Html$node,
		'jitsi-custom-element',
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('room-jitsi-open-mode'),
				A2($elm$html$Html$Attributes$attribute, 'domain', 'jitsi.medsyn.com.br'),
				A2($elm$html$Html$Attributes$attribute, 'room', options.eO),
				A2($elm$html$Html$Attributes$attribute, 'displayName', options.i$),
				A2($elm$html$Html$Attributes$attribute, 'jwt', options.jg)
			]),
		_List_Nil);
};
var $author$project$App$Page$Doctor$PageInterview$SwitchDetails = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$viewButtonDetails = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$html$Html$text(''),
		A2(
			$elm$core$Maybe$map,
			function (_v0) {
				return A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('close-button', model.aA),
									_Utils_Tuple2('open-button', !model.aA)
								])),
							$elm$html$Html$Attributes$type_('button'),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Doctor$PageInterview$SwitchDetails(!model.aA))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									function () {
										var _v1 = model.aA;
										if (_v1) {
											return '/assets/images/close.svg';
										} else {
											return '/assets/images/alert.svg';
										}
									}())
								]),
							_List_Nil)
						]));
			},
			model.bU));
};
var $author$project$App$Page$Doctor$PageInterview$AdvanceScript = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$DoctorChange = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$FinishScript = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$ReturnPersonToLine = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$RevertScript = function (a) {
	return {$: 4, a: a};
};
var $elm$html$Html$Attributes$action = function (uri) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'action',
		_VirtualDom_noJavaScriptUri(uri));
};
var $author$project$App$Page$Doctor$Interview$PageConfirmId$view = F2(
	function (st, model) {
		var fullStmt = st.ia.h8;
		var sex = function () {
			var _v0 = fullStmt.g.dw.dJ;
			switch (_v0) {
				case 1:
					return 'o Sr.';
				case 2:
					return 'a Sra.';
				default:
					return 'o(a) Sr.(a)';
			}
		}();
		var depsOnlyWarning = (fullStmt.g.e.j_ === 2) ? A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Esse atendimento é exclusivo para a inclusão de novos dependentes.')
				])) : $elm$html$Html$text('');
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Confirmação de Dados Pessoais')
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Uma vez que este é um ato pessoal, ' + (sex + ' poderia me confirmar:'))
							])),
						A2(
						$elm$html$Html$ul,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$li,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Nome Completo: '),
										A2(
										$elm$html$Html$b,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Person$fullPersonName(fullStmt.g.dw))
											]))
									])),
								A2(
								$elm$html$Html$li,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Data de Nascimento: '),
										A2(
										$elm$html$Html$b,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Tools$FormHelpers$dateFormat(fullStmt.g.dw.al))
											]))
									])),
								A2(
								$elm$html$Html$li,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Número do CPF: '),
										A2(
										$elm$html$Html$b,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Person$formatDocument(fullStmt.g.dw.i1))
											]))
									]))
							]))
					])),
				depsOnlyWarning
			]);
	});
var $author$project$App$Data$Statement$FieldDoctorHeight = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Data$Statement$FieldDoctorWeight = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Doctor$Interview$PageConfirmMetrics$InputStmtField = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmMetrics$helpInputStmt = F4(
	function (msg, depIdx, field, str) {
		return A2(
			msg,
			depIdx,
			field(str));
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmMetrics$viewMetricTable = F2(
	function (editStmt, depIdx) {
		var stmt = editStmt.e;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('confirm-metrics')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Person$fullPersonName(editStmt.dw))
											])),
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												function () {
													if (!depIdx.$) {
														return 'Titular';
													} else {
														return 'Dependente';
													}
												}())
											]))
									]))
							])),
						A2(
						$elm$html$Html$tbody,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Peso DPS:')
											])),
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Statement$formatWeight(stmt.iT))
											]))
									])),
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Altura DPS:')
											])),
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Statement$formatHeight(stmt.iS))
											]))
									])),
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('IMC DPS:')
											])),
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Statement$formatCompanyBMI(stmt))
											]))
									])),
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Peso Medsyn:')
											])),
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Statement$formatWeight(stmt.jN))
											]))
									])),
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Altura Medsyn:')
											])),
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Statement$formatHeight(stmt.jL))
											]))
									])),
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('IMC Medsyn:')
											])),
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Statement$formatPersonBMI(stmt))
											]))
									])),
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Peso confirmado:')
											])),
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('tel'),
														$elm$html$Html$Attributes$placeholder('Digite o peso'),
														$elm$html$Html$Attributes$value(
														$author$project$App$Data$Statement$formatWeight(stmt.ap)),
														$elm$html$Html$Events$onInput(
														A3($author$project$App$Page$Doctor$Interview$PageConfirmMetrics$helpInputStmt, $author$project$App$Page$Doctor$Interview$PageConfirmMetrics$InputStmtField, depIdx, $author$project$App$Data$Statement$FieldDoctorWeight)),
														$author$project$App$Tools$FormHelpers$onBackspace(
														A2(
															$author$project$App$Page$Doctor$Interview$PageConfirmMetrics$InputStmtField,
															depIdx,
															A2($author$project$App$Data$Statement$backspaceWeight, $author$project$App$Data$Statement$FieldDoctorWeight, stmt.ap)))
													]),
												_List_Nil)
											]))
									])),
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Altura confirmada:')
											])),
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('tel'),
														$elm$html$Html$Attributes$placeholder('Digite a altura'),
														$elm$html$Html$Attributes$value(
														$author$project$App$Data$Statement$formatHeight(stmt.ao)),
														$elm$html$Html$Events$onInput(
														A3($author$project$App$Page$Doctor$Interview$PageConfirmMetrics$helpInputStmt, $author$project$App$Page$Doctor$Interview$PageConfirmMetrics$InputStmtField, depIdx, $author$project$App$Data$Statement$FieldDoctorHeight)),
														$author$project$App$Tools$FormHelpers$onBackspace(
														A2(
															$author$project$App$Page$Doctor$Interview$PageConfirmMetrics$InputStmtField,
															depIdx,
															A2($author$project$App$Data$Statement$backspaceHeight, $author$project$App$Data$Statement$FieldDoctorHeight, stmt.ao)))
													]),
												_List_Nil)
											]))
									])),
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('IMC confirmado:')
											])),
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Statement$formatDoctorBMI(stmt))
											]))
									]))
							]))
					]))
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmMetrics$view = F2(
	function (st, model) {
		var fullStmt = model.a5;
		var name = fullStmt.g.dw.hl;
		var sex = function () {
			var _v0 = fullStmt.g.dw.dJ;
			switch (_v0) {
				case 1:
					return 'O Sr.';
				case 2:
					return 'A Sra.';
				default:
					return 'O(a) Sr.(a)';
			}
		}();
		var bearerMetrics = (fullStmt.g.e.j_ === 2) ? _List_Nil : A2($author$project$App$Page$Doctor$Interview$PageConfirmMetrics$viewMetricTable, fullStmt.g, $author$project$App$Data$Statement$Bearer);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Confirmação de Dados Antropométricos')
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(sex + (' ' + (name + ' poderia me confirmar:')))
								]))
						]),
					_Utils_ap(
						bearerMetrics,
						A3(
							$elm$core$Dict$foldr,
							F3(
								function (k, v, resultHtml) {
									return _Utils_ap(
										A2(
											$author$project$App$Page$Doctor$Interview$PageConfirmMetrics$viewMetricTable,
											v,
											$author$project$App$Data$Statement$Dependent(k)),
										resultHtml);
								}),
							_List_Nil,
							fullStmt.fh))))
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$filterSections = F2(
	function (answers, sections) {
		return A2(
			$elm$core$List$filter,
			function (section) {
				return !$elm$core$List$isEmpty(
					A2(
						$elm$core$List$filter,
						function (question) {
							return !$elm$core$List$isEmpty(
								A2(
									$elm$core$List$filter,
									function (template) {
										return (A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$Answer$emptyModel,
											A2($elm$core$Dict$get, template.as, answers)).d3 && (template.ay !== 9)) || (A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$Answer$emptyModel,
											A2($elm$core$Dict$get, template.as, answers)).dF && (template.ay !== 9));
									},
									question.bY));
						},
						section.eI));
			},
			sections);
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$InputAnswer = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$maskDecimal = F2(
	function (precision, value) {
		return A3($elm$core$String$replace, '.', ',', value);
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$maskHeight = function (cm) {
	var len = $elm$core$String$length(cm);
	var subM = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cm)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cm) : cm);
	var h = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(cm));
	var m = (h >= 100) ? A3($elm$core$String$slice, 0, -2, cm) : '0';
	return (h > 0) ? (m + (',' + subM)) : '';
};
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$maskPressure = function (str) {
	return function (p) {
		return (p > 0) ? $elm$core$String$fromInt(p) : '';
	}(
		function (p) {
			return (p > 999) ? (p / 10) : (p / 1.0);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(str))) | 0);
};
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$maskWeight = function (wStr) {
	return function (w) {
		return (w > 0) ? $elm$core$String$fromInt(w) : '';
	}(
		function (w) {
			return w / 1000;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(wStr))) | 0);
};
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$storeDecimal = F2(
	function (precision, value) {
		var _v0 = $elm$regex$Regex$fromString('[^\\d]');
		if (!_v0.$) {
			var regex = _v0.a;
			var decimalPoints = precision * 10;
			var cleared = A3(
				$elm$regex$Regex$replace,
				regex,
				function (_v1) {
					return '';
				},
				value);
			var floatValue = A3(
				$elm$core$Basics$clamp,
				0,
				1000000000,
				A2(
					$elm$core$Maybe$withDefault,
					0,
					A2(
						$elm$core$Maybe$map,
						function (v) {
							return v / decimalPoints;
						},
						$elm$core$String$toFloat(cleared))));
			var intValue = A3($elm$core$Basics$clamp, 0, 1000000, floatValue | 0);
			var decimalValue = $elm$core$Basics$round((floatValue - intValue) * decimalPoints);
			var decimalValueStr = function (s) {
				return (_Utils_cmp(
					$elm$core$String$length(s),
					precision) > 0) ? A2(
					$elm$core$String$dropRight,
					$elm$core$String$length(s) - precision,
					s) : s;
			}(
				$elm$core$String$fromInt(decimalValue));
			return ((intValue > 0) || (decimalValue > 0)) ? ($elm$core$String$fromInt(intValue) + ('.' + decimalValueStr)) : '';
		} else {
			return '';
		}
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$storeHeight = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return function (h) {
			return (h > 0) ? $elm$core$String$fromInt(h) : '';
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A3(
						$elm$regex$Regex$replace,
						regex,
						function (_v1) {
							return '';
						},
						str))));
	} else {
		return '';
	}
};
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$storePressure = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return function (p) {
			return (p > 0) ? $elm$core$String$fromInt(p) : '';
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A3(
						$elm$regex$Regex$replace,
						regex,
						function (_v1) {
							return '';
						},
						str))));
	} else {
		return '';
	}
};
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$storeWeight = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return function (w) {
			return (w > 0) ? $elm$core$String$fromInt(w) : '';
		}(
			1000 * A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A3(
						$elm$regex$Regex$replace,
						regex,
						function (_v1) {
							return '';
						},
						str))));
	} else {
		return '';
	}
};
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$setupMask = F5(
	function (depIdx, question, template, answer, attrs) {
		var _v0 = template.jj;
		if (_v0.$ === 1) {
			return attrs;
		} else {
			switch (_v0.a.$) {
				case 3:
					var precision = _v0.a.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							A2($author$project$App$Page$Doctor$Interview$PageConfirmQuestions$maskDecimal, precision, answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Doctor$Interview$PageConfirmQuestions$storeDecimal(precision),
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A2($author$project$App$Page$Doctor$Interview$PageConfirmQuestions$InputAnswer, depIdx, answer))))
						]);
				case 0:
					var _v1 = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('number'),
							$elm$html$Html$Attributes$value(
							$author$project$App$Page$Doctor$Interview$PageConfirmQuestions$maskWeight(answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Doctor$Interview$PageConfirmQuestions$storeWeight,
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A2($author$project$App$Page$Doctor$Interview$PageConfirmQuestions$InputAnswer, depIdx, answer))))
						]);
				case 1:
					var _v2 = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							$author$project$App$Page$Doctor$Interview$PageConfirmQuestions$maskHeight(answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Doctor$Interview$PageConfirmQuestions$storeHeight,
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A2($author$project$App$Page$Doctor$Interview$PageConfirmQuestions$InputAnswer, depIdx, answer))))
						]);
				default:
					var _v3 = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							$author$project$App$Page$Doctor$Interview$PageConfirmQuestions$maskPressure(answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Doctor$Interview$PageConfirmQuestions$storePressure,
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A2($author$project$App$Page$Doctor$Interview$PageConfirmQuestions$InputAnswer, depIdx, answer))))
						]);
			}
		}
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$viewAnswerTemplateTypeStringForDPS = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh + ('. Resposta: ' + (answer.T + '  Obs: '))),
						A2(
						$elm$html$Html$input,
						A5(
							$author$project$App$Page$Doctor$Interview$PageConfirmQuestions$setupMask,
							depIdx,
							fullQuestion.hG,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('text'),
									$elm$html$Html$Attributes$value(answer.jx),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldObservation,
										A2($author$project$App$Page$Doctor$Interview$PageConfirmQuestions$InputAnswer, depIdx, answer)))
								])),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$viewAnswerTemplateForDPS = F5(
	function (depIdx, stmt, fullQuestion, template, answer) {
		return A4($author$project$App$Page$Doctor$Interview$PageConfirmQuestions$viewAnswerTemplateTypeStringForDPS, depIdx, fullQuestion, template, answer);
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$viewQuestionAnswer = F4(
	function (depIdx, stmt, answers, fullQuestion) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(fullQuestion.hG.bP)
					])),
				A2(
				$elm$html$Html$ul,
				_List_Nil,
				$elm$core$List$concat(
					A2(
						$elm$core$List$map,
						function (t) {
							return A5(
								$author$project$App$Page$Doctor$Interview$PageConfirmQuestions$viewAnswerTemplateForDPS,
								$author$project$App$Data$Statement$depIndexFromInt(depIdx),
								stmt,
								fullQuestion,
								t,
								A2(
									$elm$core$Maybe$withDefault,
									$author$project$App$Data$Answer$emptyModel,
									A2($elm$core$Dict$get, t.as, answers)));
						},
						A2(
							$elm$core$List$filter,
							function (t) {
								var ans = A2(
									$elm$core$Maybe$withDefault,
									$author$project$App$Data$Answer$emptyModel,
									A2($elm$core$Dict$get, t.as, answers));
								return ans.dF || (ans.T !== '');
							},
							fullQuestion.bY))))
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$viewSectionAnswers = F4(
	function (idx, stmt, answers, section) {
		return $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				A3($author$project$App$Page$Doctor$Interview$PageConfirmQuestions$viewQuestionAnswer, idx, stmt, answers),
				A2(
					$elm$core$List$filter,
					function (question) {
						return !$elm$core$List$isEmpty(
							A2(
								$elm$core$List$filter,
								function (template) {
									return (A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$Answer$emptyModel,
										A2($elm$core$Dict$get, template.as, answers)).d3 && ((template.ay > 9) && (template.ay < 9))) || (A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$Answer$emptyModel,
										A2($elm$core$Dict$get, template.as, answers)).dF && (template.ay !== 9));
								},
								question.bY));
					},
					section.eI)));
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$viewAnswers = F4(
	function (depIdx, stmt, sections, answers) {
		return $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (s) {
					return A4($author$project$App$Page$Doctor$Interview$PageConfirmQuestions$viewSectionAnswers, depIdx, stmt, answers, s);
				},
				A2($author$project$App$Page$Doctor$Interview$PageConfirmQuestions$filterSections, answers, sections)));
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$viewDPSAndPersonAnswers = F2(
	function (st, model) {
		var stmts = A2(
			$elm$core$List$any,
			function (_v0) {
				var i = _v0.a;
				var stmt = _v0.b;
				return stmt.e.j_ === 2;
			},
			$elm$core$Dict$toList(model.fX)) ? A2(
			$elm$core$Dict$filter,
			F2(
				function (i, stmt) {
					return i >= 0;
				}),
			model.fX) : model.fX;
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (idx, stmt, result) {
					return A2(
						$elm$core$List$append,
						result,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h3,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Data$Person$fullPersonName(stmt.dw))
									])),
								A2(
								$elm$html$Html$article,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$style, 'margin-left', '20px')
									]),
								_Utils_ap(
									_Utils_ap(
										_List_fromArray(
											[
												A2($elm$html$Html$br, _List_Nil, _List_Nil),
												A2(
												$elm$html$Html$h3,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('DPS')
													]))
											]),
										A4($author$project$App$Page$Doctor$Interview$PageConfirmQuestions$viewAnswers, idx, stmt, st.ia.h8.bs, stmt.gw)),
									_Utils_ap(
										_List_fromArray(
											[
												A2($elm$html$Html$br, _List_Nil, _List_Nil),
												A2(
												$elm$html$Html$h2,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Questionário')
													]))
											]),
										A4($author$project$App$Page$Doctor$Interview$PageConfirmQuestions$viewAnswers, idx, stmt, st.ia.h8.jM, stmt.aZ))))
							]));
				}),
			_List_Nil,
			stmts);
	});
var $author$project$App$Page$Doctor$Interview$PageConfirmQuestions$view = F2(
	function (st, model) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Confirmação do Questionário')
								]))
						]),
					A2($author$project$App$Page$Doctor$Interview$PageConfirmQuestions$viewDPSAndPersonAnswers, st, model)))
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$getAnswerForTemplate = F2(
	function (stmt, template) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Answer$emptyModel,
			A2($elm$core$Dict$get, template.as, stmt.aZ));
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$hasErrorOnQuestion = F2(
	function (qId, model) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (err) {
					return $elm$core$Dict$size(err) > 0;
				},
				A2($elm$core$Dict$get, qId, model.fp)));
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputAnswer = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$maskDecimal = F2(
	function (precision, value) {
		return A3($elm$core$String$replace, '.', ',', value);
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$maskHeight = function (cm) {
	var len = $elm$core$String$length(cm);
	var subM = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cm)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cm) : cm);
	var h = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(cm));
	var m = (h >= 100) ? A3($elm$core$String$slice, 0, -2, cm) : '0';
	return (h > 0) ? (m + (',' + subM)) : '';
};
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$maskPressure = function (str) {
	return function (p) {
		return (p > 0) ? $elm$core$String$fromInt(p) : '';
	}(
		function (p) {
			return (p > 999) ? (p / 10) : (p / 1.0);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(str))) | 0);
};
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$maskWeight = function (wStr) {
	return function (w) {
		return (w > 0) ? $elm$core$String$fromInt(w) : '';
	}(
		function (w) {
			return w / 1000;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(wStr))) | 0);
};
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$storeDecimal = F2(
	function (precision, value) {
		var _v0 = $elm$regex$Regex$fromString('[^\\d]');
		if (!_v0.$) {
			var regex = _v0.a;
			var decimalPoints = precision * 10;
			var cleared = A3(
				$elm$regex$Regex$replace,
				regex,
				function (_v1) {
					return '';
				},
				value);
			var floatValue = A3(
				$elm$core$Basics$clamp,
				0,
				1000000000,
				A2(
					$elm$core$Maybe$withDefault,
					0,
					A2(
						$elm$core$Maybe$map,
						function (v) {
							return v / decimalPoints;
						},
						$elm$core$String$toFloat(cleared))));
			var intValue = A3($elm$core$Basics$clamp, 0, 1000000, floatValue | 0);
			var decimalValue = $elm$core$Basics$round((floatValue - intValue) * decimalPoints);
			var decimalValueStr = function (s) {
				return (_Utils_cmp(
					$elm$core$String$length(s),
					precision) > 0) ? A2(
					$elm$core$String$dropRight,
					$elm$core$String$length(s) - precision,
					s) : s;
			}(
				$elm$core$String$fromInt(decimalValue));
			return ((intValue > 0) || (decimalValue > 0)) ? ($elm$core$String$fromInt(intValue) + ('.' + decimalValueStr)) : '';
		} else {
			return '';
		}
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$storeHeight = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return function (h) {
			return (h > 0) ? $elm$core$String$fromInt(h) : '';
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A3(
						$elm$regex$Regex$replace,
						regex,
						function (_v1) {
							return '';
						},
						str))));
	} else {
		return '';
	}
};
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$storePressure = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return function (p) {
			return (p > 0) ? $elm$core$String$fromInt(p) : '';
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A3(
						$elm$regex$Regex$replace,
						regex,
						function (_v1) {
							return '';
						},
						str))));
	} else {
		return '';
	}
};
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$storeWeight = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return function (w) {
			return (w > 0) ? $elm$core$String$fromInt(w) : '';
		}(
			1000 * A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A3(
						$elm$regex$Regex$replace,
						regex,
						function (_v1) {
							return '';
						},
						str))));
	} else {
		return '';
	}
};
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$setupMask = F5(
	function (depIdx, question, template, answer, attrs) {
		var _v0 = template.jj;
		if (_v0.$ === 1) {
			return attrs;
		} else {
			switch (_v0.a.$) {
				case 3:
					var precision = _v0.a.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$maskDecimal, precision, answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Doctor$Interview$PageEvalDeclared$storeDecimal(precision),
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputAnswer, depIdx, answer))))
						]);
				case 0:
					var _v1 = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('number'),
							$elm$html$Html$Attributes$value(
							$author$project$App$Page$Doctor$Interview$PageEvalDeclared$maskWeight(answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Doctor$Interview$PageEvalDeclared$storeWeight,
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputAnswer, depIdx, answer))))
						]);
				case 1:
					var _v2 = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							$author$project$App$Page$Doctor$Interview$PageEvalDeclared$maskHeight(answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Doctor$Interview$PageEvalDeclared$storeHeight,
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputAnswer, depIdx, answer))))
						]);
				default:
					var _v3 = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							$author$project$App$Page$Doctor$Interview$PageEvalDeclared$maskPressure(answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Doctor$Interview$PageEvalDeclared$storePressure,
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputAnswer, depIdx, answer))))
						]);
			}
		}
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeDate = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						A5(
							$author$project$App$Page$Doctor$Interview$PageEvalDeclared$setupMask,
							depIdx,
							fullQuestion.hG,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('date'),
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputAnswer, depIdx, answer)))
								])),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeNumeric = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						A5(
							$author$project$App$Page$Doctor$Interview$PageEvalDeclared$setupMask,
							depIdx,
							fullQuestion.hG,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('number'),
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputAnswer, depIdx, answer)))
								])),
						_List_Nil),
						$elm$html$Html$text(
						A2($elm$core$Maybe$withDefault, '', template.iv))
					]))
			]);
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputSingleChoice = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$onRadioChange = F2(
	function (depIdx, q) {
		return $elm$html$Html$Events$onInput(
			A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputSingleChoice, depIdx, q));
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeOption = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$for(template.as)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(template.as),
								$elm$html$Html$Attributes$name(fullQuestion.hG.as),
								$elm$html$Html$Attributes$type_('radio'),
								$elm$html$Html$Attributes$checked(answer.dF),
								A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$onRadioChange, depIdx, fullQuestion),
								$elm$html$Html$Attributes$value(template.as)
							]),
						_List_Nil),
						$elm$html$Html$text(template.jh)
					]))
			]);
	});
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeOptionCheck = F3(
	function (depIdx, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$for(template.as)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(template.as),
								$elm$html$Html$Attributes$type_('checkbox'),
								$elm$html$Html$Attributes$checked(answer.dF),
								$elm$html$Html$Events$onCheck(
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldSelectedBool,
									A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputAnswer, depIdx, answer))),
								$elm$html$Html$Attributes$value(template.as)
							]),
						_List_Nil),
						$elm$html$Html$text(template.jh)
					]))
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeOptionWithComplement = F4(
	function (depIdx, fullQuestion, template, answer) {
		var placeholderText = answer.dF ? 'Sua resposta aqui' : '';
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$for(template.as)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(template.as),
								$elm$html$Html$Attributes$name(fullQuestion.hG.as),
								$elm$html$Html$Attributes$type_('radio'),
								$elm$html$Html$Attributes$checked(answer.dF),
								A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$onRadioChange, depIdx, fullQuestion),
								$elm$html$Html$Attributes$value(template.as)
							]),
						_List_Nil),
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$placeholder(placeholderText),
								$elm$html$Html$Attributes$type_('text'),
								$elm$html$Html$Events$onInput(
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputAnswer, depIdx, answer))),
								$elm$html$Html$Attributes$value(answer.T)
							]),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeString = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						A5(
							$author$project$App$Page$Doctor$Interview$PageEvalDeclared$setupMask,
							depIdx,
							fullQuestion.hG,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('text'),
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputAnswer, depIdx, answer)))
								])),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Data$Answer$FieldTableRow = F3(
	function (a, b, c) {
		return {$: 6, a: a, b: b, c: c};
	});
var $author$project$App$Data$Answer$FieldTableRowRemove = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeTableRowDesktop = F3(
	function (depIdx, template, answer) {
		var theadContent = $elm$core$List$reverse(
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$th,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'role', 'columnheader')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Ações')
							]))
					]),
				$elm$core$List$reverse(
					A2(
						$elm$core$List$map,
						function (l) {
							return A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'role', 'columnheader')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(l)
									]));
						},
						A2($elm$core$String$split, ',', template.jh)))));
		var tableCell = F3(
			function (idx, header, v) {
				return A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'role', 'cell')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$placeholder(header),
									$elm$html$Html$Attributes$type_('text'),
									$elm$html$Html$Attributes$value(v),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										A2($author$project$App$Data$Answer$FieldTableRow, idx, header),
										A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputAnswer, depIdx, answer)))
								]),
							_List_Nil)
						]));
			});
		var tableRow = function (_v1) {
			var idx = _v1.a;
			var row = _v1.b;
			return A2(
				$elm$html$Html$tr,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'row')
					]),
				$elm$core$List$reverse(
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'role', 'cell')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick(
												A3(
													$author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputAnswer,
													depIdx,
													answer,
													$author$project$App$Data$Answer$FieldTableRowRemove(idx)))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Remover')
											]))
									]))
							]),
						$elm$core$List$reverse(
							A2(
								$elm$core$List$map,
								function (header) {
									return A3(
										tableCell,
										idx,
										header,
										A2(
											$elm$core$Maybe$withDefault,
											'',
											A2($elm$core$Dict$get, header, row)));
								},
								A2($elm$core$String$split, ',', template.jh))))));
		};
		var tbodyContent = function () {
			var _v0 = answer.G;
			if (_v0.$ === 1) {
				return _List_Nil;
			} else {
				var tableRows = _v0.a;
				return A2(
					$elm$core$List$map,
					tableRow,
					$elm$core$Dict$toList(tableRows));
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$thead,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'rowgroup')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tr,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'role', 'row')
							]),
						theadContent)
					])),
				A2(
				$elm$html$Html$tbody,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'rowgroup')
					]),
				tbodyContent)
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeTextBox = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$textarea,
						A5(
							$author$project$App$Page$Doctor$Interview$PageEvalDeclared$setupMask,
							depIdx,
							fullQuestion.hG,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputAnswer, depIdx, answer))),
									$elm$html$Html$Attributes$class('text-box')
								])),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplate = F5(
	function (depIdx, stmt, fullQuestion, template, answer) {
		var _v0 = template.j5;
		switch (_v0) {
			case 0:
				return _List_Nil;
			case 2:
				return A4($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeString, depIdx, fullQuestion, template, answer);
			case 3:
				return A4($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeNumeric, depIdx, fullQuestion, template, answer);
			case 5:
				return A4($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeDate, depIdx, fullQuestion, template, answer);
			case 1:
				return A4($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeOption, depIdx, fullQuestion, template, answer);
			case 6:
				return A3($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeOptionCheck, depIdx, template, answer);
			case 4:
				return A4($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeOptionWithComplement, depIdx, fullQuestion, template, answer);
			case 7:
				return A3($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeTableRowDesktop, depIdx, template, answer);
			default:
				return A4($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeTextBox, depIdx, fullQuestion, template, answer);
		}
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewErrors = F2(
	function (questionId, model) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Maybe$withDefault(_List_Nil),
				A2(
					$elm$core$Maybe$map,
					$elm$core$List$head,
					A2(
						$elm$core$Maybe$map,
						$elm$core$List$map(
							function (_v0) {
								var _v1 = _v0.b;
								var err = _v1.a;
								var idx = _v1.b;
								return _List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('material-icons')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('error_outline')
											])),
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(err)
											]))
									]);
							}),
						A2(
							$elm$core$Maybe$map,
							$elm$core$Dict$toList,
							A2($elm$core$Dict$get, questionId, model.fp))))));
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewQuestionCommon = F3(
	function (stmts, model, fullQuestion) {
		var templatesView = A3(
			$elm$core$Dict$foldl,
			F3(
				function (depId, stmt, result) {
					var depIdx = $author$project$App$Data$Statement$depIndexFromInt(depId);
					var errorIdx = $author$project$App$Data$Statement$depIndexToString(depIdx) + ('_' + fullQuestion.hG.as);
					var errorIdProp = _List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id(
									$author$project$App$Data$Statement$depIndexToString(depIdx) + ('_' + fullQuestion.hG.as)),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'error',
											A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$hasErrorOnQuestion, errorIdx, model))
										]))
								]),
							_List_Nil)
						]);
					var name = _List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'error',
											A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$hasErrorOnQuestion, errorIdx, model))
										])),
									A2($elm$html$Html$Attributes$style, 'margin-top', '8px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$fullPersonName(stmt.dw))
								]))
						]);
					return A2(
						$elm$core$List$append,
						result,
						A2(
							$elm$core$List$append,
							A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewErrors, errorIdx, model),
							_Utils_ap(
								errorIdProp,
								_Utils_ap(
									name,
									$elm$core$List$concat(
										A2(
											$elm$core$List$map,
											function (t) {
												return A5(
													$author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplate,
													depIdx,
													stmt,
													fullQuestion,
													t,
													A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$getAnswerForTemplate, stmt, t));
											},
											fullQuestion.bY))))));
				}),
			_List_Nil,
			stmts);
		return _List_fromArray(
			[
				A2($elm$html$Html$br, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$article,
				_List_Nil,
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(fullQuestion.hG.bP)
								]))
						]),
					_Utils_ap(
						templatesView,
						A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewErrors, fullQuestion.hG.as, model))))
			]);
	});
var $author$project$App$Data$Answer$FieldTableRowAdd = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeTableRowMobile = F3(
	function (depIdx, template, answer) {
		var tableCell = F3(
			function (idx, header, v) {
				return A2(
					$elm$html$Html$tr,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'role', 'row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$th,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'role', 'columnheader')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(header)
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'role', 'cell')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder(header),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$value(v),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeR,
												A2($author$project$App$Data$Answer$FieldTableRow, idx, header),
												A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputAnswer, depIdx, answer)))
										]),
									_List_Nil)
								]))
						]));
			});
		var tableRowInternal = function (_v1) {
			var idx = _v1.a;
			var row = _v1.b;
			return A2(
				$elm$core$List$map,
				function (header) {
					return A3(
						tableCell,
						idx,
						header,
						A2(
							$elm$core$Maybe$withDefault,
							'',
							A2($elm$core$Dict$get, header, row)));
				},
				A2($elm$core$String$split, ',', template.jh));
		};
		var tableRow = function (r) {
			var _v0 = tableRowInternal(r);
			if (_v0.b) {
				var head = _v0.a;
				var body = _v0.b;
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mobile_table'),
								A2($elm$html$Html$Attributes$attribute, 'role', 'table')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'role', 'rowgroup')
									]),
								_List_fromArray(
									[head])),
								A2(
								$elm$html$Html$tbody,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'role', 'rowgroup')
									]),
								body)
							]))
					]);
			} else {
				return _List_Nil;
			}
		};
		var tbodyContent = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$map(tableRow),
				A2($elm$core$Maybe$map, $elm$core$Dict$toList, answer.G)));
		return $elm$core$List$concat(tbodyContent);
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewTableDesktop = F4(
	function (question, template, answer, content) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('desktop_table'),
						A2($elm$html$Html$Attributes$attribute, 'role', 'table')
					]),
				content)
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewTableMobile = F4(
	function (question, template, answer, content) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mobile_table'),
						A2($elm$html$Html$Attributes$attribute, 'role', 'table')
					]),
				content)
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewQuestionTable = F3(
	function (stmts, model, fullQuestion) {
		var template = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$AnswerTemplate$emptyModel,
			$elm$core$List$head(fullQuestion.bY));
		var templates = A3(
			$elm$core$Dict$foldr,
			F3(
				function (depId, stmt, result) {
					var questionTitle = model.eU ? ($author$project$App$Data$Person$fullPersonName(stmt.dw) + (' - ' + fullQuestion.hG.bP)) : fullQuestion.hG.bP;
					var depIdx = $author$project$App$Data$Statement$depIndexFromInt(depId);
					var errorIdx = $author$project$App$Data$Statement$depIndexToString(depIdx) + ('_' + fullQuestion.hG.as);
					var errorIdProp = _List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id(
									$author$project$App$Data$Statement$depIndexToString(depIdx) + ('_' + fullQuestion.hG.as)),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'error',
											A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$hasErrorOnQuestion, errorIdx, model))
										]))
								]),
							_List_Nil)
						]);
					var answer = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$Answer$emptyModel,
						A2($elm$core$Dict$get, template.as, stmt.aZ));
					var templatesDesktop = A2(
						$elm$core$List$append,
						result,
						A4(
							$author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewTableDesktop,
							fullQuestion.hG,
							template,
							answer,
							$elm$core$List$concat(
								A2(
									$elm$core$List$map,
									function (t) {
										return A3(
											$author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeTableRowDesktop,
											depIdx,
											t,
											A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$getAnswerForTemplate, stmt, t));
									},
									fullQuestion.bY))));
					var templatesMobile = A4(
						$author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewTableMobile,
						fullQuestion.hG,
						template,
						answer,
						$elm$core$List$concat(
							A2(
								$elm$core$List$map,
								function (t) {
									return A3(
										$author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewAnswerTemplateTypeTableRowMobile,
										depIdx,
										t,
										A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$getAnswerForTemplate, stmt, t));
								},
								fullQuestion.bY)));
					return _Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'error',
												A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$hasErrorOnQuestion, errorIdx, model))
											])),
										A2($elm$html$Html$Attributes$style, 'margin-top', '8px')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(questionTitle)
									]))
							]),
						_Utils_ap(
							errorIdProp,
							_Utils_ap(
								templatesDesktop,
								_Utils_ap(
									templatesMobile,
									_Utils_ap(
										_List_fromArray(
											[
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('add_button'),
														$elm$html$Html$Events$onClick(
														A3(
															$author$project$App$Page$Doctor$Interview$PageEvalDeclared$InputAnswer,
															depIdx,
															answer,
															$author$project$App$Data$Answer$FieldTableRowAdd(
																A2($elm$core$String$split, ',', template.jh))))
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('adicionar outro')
													]))
											]),
										A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewErrors, errorIdx, model))))));
				}),
			_List_Nil,
			stmts);
		return _List_fromArray(
			[
				A2($elm$html$Html$a, _List_Nil, _List_Nil),
				A2($elm$html$Html$article, _List_Nil, templates)
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewSectionQuestion = F3(
	function (stmts, model, fullQuestion) {
		var _v0 = fullQuestion.hG.j5;
		switch (_v0) {
			case 0:
				return _List_Nil;
			case 1:
				return A3($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewQuestionCommon, stmts, model, fullQuestion);
			case 2:
				return A3($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewQuestionCommon, stmts, model, fullQuestion);
			case 3:
				return A3($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewQuestionCommon, stmts, model, fullQuestion);
			case 4:
				return A3($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewQuestionTable, stmts, model, fullQuestion);
			default:
				return A3($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewQuestionCommon, stmts, model, fullQuestion);
		}
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewSection = F3(
	function (fullSection, st, model) {
		var stmts = A2(
			$elm$core$List$any,
			function (_v0) {
				var i = _v0.a;
				var stmt = _v0.b;
				return stmt.e.j_ === 2;
			},
			$elm$core$Dict$toList(model.fX)) ? A2(
			$elm$core$Dict$filter,
			F2(
				function (i, stmt) {
					return i >= 0;
				}),
			model.fX) : model.fX;
		return A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$article,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm$core$String$fromInt(fullSection.hX.bB) + ('. ' + fullSection.hX.hl))
							]))
					])),
			$elm$core$List$concat(
				A2(
					$elm$core$List$map,
					function (q) {
						return A3($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewSectionQuestion, stmts, model, q);
					},
					A2(
						$elm$core$List$filter,
						function (q) {
							return (q.hG.ay !== 1000) || (st.ia.h8.b4.iR !== 'Extramed');
						},
						A2(
							$elm$core$List$sortBy,
							function (q) {
								return q.hG.bB;
							},
							fullSection.eI)))));
	});
var $author$project$App$Page$Doctor$Interview$PageEvalDeclared$view = F2(
	function (st, model) {
		var stmt = st.ia.h8;
		var sex = function () {
			var _v0 = stmt.g.dw.dJ;
			switch (_v0) {
				case 1:
					return 'o Sr.';
				case 2:
					return 'a Sra.';
				default:
					return 'o(a) Sr.(a)';
			}
		}();
		var currentProponent = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestionSection$emptyModel,
			A2(
				$elm$core$Maybe$map,
				function (s) {
					return s;
				},
				A2(
					$elm$core$Dict$get,
					stmt.g.e.bv,
					$elm$core$Dict$fromList(
						A2(
							$elm$core$List$map,
							function (v) {
								return _Utils_Tuple2(v.hX.bB, v);
							},
							stmt.jM)))));
		var current = A2($elm$core$Dict$get, stmt.g.e.bv, model.cA);
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				function (fullSection) {
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('doctor-questions')
								]),
							_Utils_ap(
								$elm$core$List$concat(
									st.ia.h8.cJ.ht ? _List_Nil : _List_fromArray(
										[
											_List_fromArray(
											[
												A2(
												$elm$html$Html$h2,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Confirmação de Respostas do Questionário')
													])),
												A2($elm$html$Html$br, _List_Nil, _List_Nil),
												A2(
												$elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Após análise de sua DPS e do Questionário do nosso Sistema gostaria que ' + (sex + ' confirmasse as seguintes respostas:'))
													]))
											]),
											_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Caso dúvida na definição de alguma patologia, procurar especificar ou orientar ' + ('o cliente no diagnóstico definitivo mais adequado e confirmar com o mesmo o ' + 'diagnóstico da(s) patologia(s).'))
													])),
												A2($elm$html$Html$br, _List_Nil, _List_Nil)
											])
										])),
								A3($author$project$App$Page$Doctor$Interview$PageEvalDeclared$viewSection, fullSection, st, model)))
						]);
				},
				current));
	});
var $author$project$App$Page$Doctor$Interview$PageFinishCall$view = F2(
	function (st, model) {
		var isDPS = (st.ia.h8.b4.iR === 'Extramed') || (st.ia.h8.b4.iR === 'Centauro-ON');
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				A2(
					$elm$core$List$cons,
					A2(
						$elm$html$Html$h2,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Saudações de Término do Contato')
							])),
					isDPS ? _List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Posso lhe auxiliar em mais alguma duvida referente a Declaração de Saúde?')
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Tenha um ótimo dia')
								]))
						]) : _List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Clique em finalizar para terminar a consulta.')
								]))
						])))
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageInformChanged$InputAnswerObservation = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Page$Doctor$Interview$PageInformChanged$maskDecimal = F2(
	function (precision, value) {
		return A3($elm$core$String$replace, '.', ',', value);
	});
var $author$project$App$Page$Doctor$Interview$PageInformChanged$maskHeight = function (cm) {
	var len = $elm$core$String$length(cm);
	var subM = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cm)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cm) : cm);
	var h = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(cm));
	var m = (h >= 100) ? A3($elm$core$String$slice, 0, -2, cm) : '0';
	return (h > 0) ? (m + (',' + subM)) : '';
};
var $author$project$App$Page$Doctor$Interview$PageInformChanged$maskPressure = function (str) {
	return function (p) {
		return (p > 0) ? $elm$core$String$fromInt(p) : '';
	}(
		function (p) {
			return (p > 999) ? (p / 10) : (p / 1.0);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(str))) | 0);
};
var $author$project$App$Page$Doctor$Interview$PageInformChanged$maskWeight = function (wStr) {
	return function (w) {
		return (w > 0) ? $elm$core$String$fromInt(w) : '';
	}(
		function (w) {
			return w / 1000;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(wStr))) | 0);
};
var $author$project$App$Page$Doctor$Interview$PageInformChanged$setupMask = F2(
	function (template, answer) {
		var _v0 = template.jj;
		if (_v0.$ === 1) {
			return answer.T;
		} else {
			switch (_v0.a.$) {
				case 3:
					var precision = _v0.a.a;
					return A2($author$project$App$Page$Doctor$Interview$PageInformChanged$maskDecimal, precision, answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 0:
					var _v1 = _v0.a;
					return $author$project$App$Page$Doctor$Interview$PageInformChanged$maskWeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 1:
					var _v2 = _v0.a;
					return $author$project$App$Page$Doctor$Interview$PageInformChanged$maskHeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				default:
					var _v3 = _v0.a;
					return $author$project$App$Page$Doctor$Interview$PageInformChanged$maskPressure(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
			}
		}
	});
var $author$project$App$Page$Doctor$Interview$PageInformChanged$viewAnswers = F3(
	function (title, questions, answers) {
		var answerList = A3(
			$elm$core$List$foldr,
			F2(
				function (q, acc) {
					return _Utils_ap(
						A2(
							$elm$core$List$map,
							function (t) {
								return A2(
									$elm$core$Maybe$withDefault,
									$author$project$App$Data$Answer$emptyModel,
									A2($elm$core$Dict$get, t.as, answers));
							},
							A2(
								$elm$core$List$filter,
								function (t) {
									var answer = A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$Answer$emptyModel,
										A2($elm$core$Dict$get, t.as, answers));
									return answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((t.ay !== 10) && (t.ay !== 20)));
								},
								q.bY)),
						acc);
				}),
			_List_Nil,
			questions);
		return ($elm$core$List$length(answerList) > 0) ? A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('doctor-questions-item'),
							A2($elm$html$Html$Attributes$style, 'padding-bottom', '4px'),
							A2($elm$html$Html$Attributes$style, 'padding-left', '32px')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h3,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(title)
								]))
						]))
				]),
			A3(
				$elm$core$List$foldr,
				F2(
					function (q, acc) {
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (t) {
									var ans = A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$Answer$emptyModel,
										A2($elm$core$Dict$get, t.as, answers));
									return A2(
										$elm$html$Html$li,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('doctor-questions-item'),
												A2($elm$html$Html$Attributes$style, 'padding-left', '64px')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text(q.hG.bP + (' -- ' + t.jh))
													])),
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														A2($elm$html$Html$Attributes$style, 'margin', '4px')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text(
																'Resposta: ' + A2($author$project$App$Page$Doctor$Interview$PageInformChanged$setupMask, t, ans))
															]))
													])),
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														A2($elm$html$Html$Attributes$style, 'margin', '4px')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Observações:')
															])),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$type_('text'),
																$elm$html$Html$Attributes$value(ans.jx),
																$elm$html$Html$Attributes$placeholder('Digitar observações se aplicável'),
																$elm$html$Html$Events$onInput(
																$author$project$App$Page$Doctor$Interview$PageInformChanged$InputAnswerObservation(ans))
															]),
														_List_Nil)
													]))
											]));
								},
								A2(
									$elm$core$List$filter,
									function (t) {
										var answer = A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$Answer$emptyModel,
											A2($elm$core$Dict$get, t.as, answers));
										return answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((t.ay !== 10) && (t.ay !== 20)));
									},
									q.bY)),
							acc);
					}),
				_List_Nil,
				questions)) : _List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('doctor-questions-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h3,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(title)
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Nenhuma resposta')
							]))
					]))
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageInformChanged$answeredQuestionsByPerson = F2(
	function (stmt, st) {
		return A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Person$fullPersonName(stmt.dw))
							]))
					])),
			_Utils_ap(
				A3(
					$author$project$App$Page$Doctor$Interview$PageInformChanged$viewAnswers,
					'Respostas DPS:',
					$elm$core$List$concat(
						A2(
							$elm$core$List$map,
							function (s) {
								return s.eI;
							},
							st.ia.h8.bs)),
					stmt.gw),
				_Utils_ap(
					A3(
						$author$project$App$Page$Doctor$Interview$PageInformChanged$viewAnswers,
						'Respostas Formulário:',
						$elm$core$List$concat(
							A2(
								$elm$core$List$map,
								function (s) {
									return s.eI;
								},
								st.ia.h8.jM)),
						stmt.aZ),
					A3(
						$author$project$App$Page$Doctor$Interview$PageInformChanged$viewAnswers,
						'Respostas Entrevista:',
						$elm$core$List$concat(
							A2(
								$elm$core$List$map,
								function (s) {
									return s.eI;
								},
								st.ia.h8.gH)),
						stmt.gE))));
	});
var $author$project$App$Page$Doctor$Interview$PageInformChanged$view = F2(
	function (st, model) {
		var answers = A3(
			$elm$core$Dict$foldl,
			F3(
				function (_v0, stmt, result) {
					return A2(
						$elm$core$List$append,
						result,
						A2($author$project$App$Page$Doctor$Interview$PageInformChanged$answeredQuestionsByPerson, stmt, st));
				}),
			_List_Nil,
			model.fX);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Dados Não conferem com o DPS e/ou Protocolo Medsyn')
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Houve uma inconsistência na sua avaliação e, por este motivo, estarei adicionando estas informações a sua Declaração de Saúde ' + 'e o departamento técnico da Operadora/Administradora entrará em contato para continuidade de seu processo de contratação')
							])),
						A2($elm$html$Html$ul, _List_Nil, answers)
					]))
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageInformNoChange$view = F2(
	function (st, model) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Dados conferem com DPS e Protocolo Medsyn')
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Muito obrigado(a) pela sua atenção! Neste momento daremos sequência na Análise do Processo de contratação de seu Plano de Saúde perante a Operadora/Administradora ')
							]))
					]))
			]);
	});
var $author$project$App$Page$Doctor$Interview$PageLoading$view = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Carregando Atendimento')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Aguarde enquanto carregamos todos os dados do atendimento...')
						])),
					A2($elm$html$Html$p, _List_Nil, _List_Nil)
				]))
		]);
};
var $author$project$App$Page$Doctor$Interview$PageOrientation$view = F2(
	function (st, model) {
		var interviewType = (st.ia.h8.b4.iR === 'Centauro-ON') ? 'Seguro de Vida.' : 'Plano de Saúde.';
		var dynamicText = (st.ia.h8.b4.iR === 'Centauro-ON') ? ('Este é um procedimento padrão solicitado no momento da contratação do seu seguro de vida por parte da seguradora ' + st.ia.h8.b4.iR) : ((st.ia.h8.b4.iR === 'Extramed') ? ('Este é um procedimento padrão solicitado no momento da contratação do seu plano de saúde por parte da operadora ' + (st.ia.h8.b4.iR + ('/' + st.ia.h8.cJ.hl))) : 'Este é um procedimento padrão solicitado no momento da contratação da empresa ');
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('doctor-questions')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Orientações do Procedimento')
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Antes de iniciarmos, peço ao Sr.(a) que para evitarmos prejuízos técnicos durante ' + 'o procedimento desta orientação verificar:')
							])),
						A2(
						$elm$html$Html$ul,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$li,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Posicionamento da câmera')
									])),
								A2(
								$elm$html$Html$li,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Iluminação')
									])),
								A2(
								$elm$html$Html$li,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Fone de ouvido')
									])),
								A2(
								$elm$html$Html$li,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Local silencioso')
									])),
								A2(
								$elm$html$Html$li,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Local com bom sinal de internet')
									]))
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(dynamicText + (', portanto este contato está sendo gravado. Mas não se preocupe que tomamos todas as ' + ('providências para lhe garantir todo o sigilo e segurança necessários, lembrando ' + ('que essas informações serão utilizadas apenas para o processo de contratação de seu ' + interviewType))))
							])),
						A2($elm$html$Html$br, _List_Nil, _List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Doctor$Interview$PagePresentation$view = F2(
	function (st, model) {
		var token = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Token$emptyModel,
			$author$project$App$Service$ApplicationResources$getAuthToken(st._));
		var hour = A2(
			$elm$time$Time$toHour,
			st.f6,
			$author$project$App$Service$ApiWebSocket$getTimestamp(st._));
		var greetings = (hour < 12) ? 'Bom dia!' : ((hour < 19) ? 'Boa tarde!' : 'Boa noite!');
		var fullStmt = st.ia.h8;
		var sex = function () {
			var _v1 = fullStmt.g.dw.dJ;
			switch (_v1) {
				case 1:
					return 'o Sr.';
				case 2:
					return 'a Sra.';
				default:
					return 'o(a) Sr.(a)';
			}
		}();
		var documentTypeText = (_Utils_eq(
			st.ia.h8.cJ.hw,
			$elm$core$Maybe$Just(false)) && (st.ia.h8.cJ.gX && (st.ia.h8.cJ.hR && (!st.ia.h8.cJ.ht)))) ? ' da sua declaração de saúde (DPS)' : ' da sua declaração de saúde e atividade (DPSA)';
		var depsText = ($elm$core$Dict$size(fullStmt.fh) > 0) ? (', bem como os seus dependentes: ' + A2(
			$elm$core$String$join,
			',',
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (_v0, dep, list) {
						return A2(
							$elm$core$List$append,
							list,
							_List_fromArray(
								[dep.dw.hl]));
					}),
				_List_Nil,
				fullStmt.fh))) : '.';
		var greetingsText = ($elm$core$List$length(st.ia.h8.cJ.eg) > 0) ? A2(
			$elm$core$List$map,
			function (t) {
				return A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(t)
						]));
			},
			st.ia.h8.cJ.eg) : _List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						'Sou ' + ($author$project$App$Data$Person$fullPersonName(token.dw) + (' e faço este contato com ' + (sex + (' por este sistema para orientações a respeito' + (documentTypeText + depsText))))))
					]))
			]);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Boas Vindas e Apresentação')
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(greetings)
								]))
						]),
					greetingsText))
			]);
	});
var $author$project$App$Page$Doctor$PageInterview$viewDoctorPage = F2(
	function (st, model) {
		var revertButton = (st.ia.h8.g.e.aw !== 13) ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cancel-button'),
					$elm$html$Html$Attributes$type_('button'),
					$elm$html$Html$Attributes$disabled(!(!st.ia.iP)),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Doctor$PageInterview$RevertScript(st.ia.h8))
				]),
			_List_fromArray(
				[
					(!(!st.ia.iP)) ? $elm$html$Html$text('Aguarde...') : $elm$html$Html$text('Anterior')
				])) : $elm$html$Html$text('');
		var finishButton = model.di ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$type_('button'),
					$elm$html$Html$Attributes$disabled(!(!st.ia.iP)),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Doctor$PageInterview$FinishScript(st.ia.h8))
				]),
			_List_fromArray(
				[
					(!(!st.ia.iP)) ? $elm$html$Html$text('Aguarde...') : $elm$html$Html$text('Finalizar')
				])) : (model.dh ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$type_('button'),
					$elm$html$Html$Attributes$disabled(!(!st.ia.iP)),
					A2($elm$html$Html$Attributes$style, 'background-color', 'grey'),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Doctor$PageInterview$FinishScript(st.ia.h8))
				]),
			_List_fromArray(
				[
					(!(!st.ia.iP)) ? $elm$html$Html$text('Aguarde...') : $elm$html$Html$text('Ative a gravação')
				])) : A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$type_('button'),
					$elm$html$Html$Attributes$disabled(!(!st.ia.iP)),
					A2($elm$html$Html$Attributes$style, 'background-color', 'grey')
				]),
			_List_fromArray(
				[
					(!(!st.ia.iP)) ? $elm$html$Html$text('Aguarde...') : $elm$html$Html$text('Ative a gravação')
				])));
		var lastButtons = _List_fromArray(
			[
				A2($elm$html$Html$br, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('action-buttons')
					]),
				_List_fromArray(
					[revertButton, finishButton]))
			]);
		var doNotAdvance = function () {
			var _v1 = model.aQ;
			if (_v1.$ === 4) {
				var evalModel = _v1.a;
				return !_Utils_eq(evalModel.fp, $elm$core$Dict$empty);
			} else {
				return false;
			}
		}();
		var changedButton = function () {
			if ((st.ia.h8.g.e.aw === 18) && st.ia.h8.cJ.gX) {
				var t = st.ia.h8.g.e.b8 ? $elm$html$Html$Attributes$type_('cancel-button') : $elm$html$Html$Attributes$type_('button');
				return A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							t,
							$elm$html$Html$Attributes$disabled(!(!st.ia.iP)),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Doctor$PageInterview$DoctorChange(st.ia.h8))
						]),
					_List_fromArray(
						[
							(!(!st.ia.iP)) ? $elm$html$Html$text('Aguarde...') : (st.ia.h8.g.e.b8 ? $elm$html$Html$text('Mudar para Não Alterado') : $elm$html$Html$text('Mudar para Alterado'))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		var cancelButton = A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cancel-button'),
					$elm$html$Html$Attributes$type_('button'),
					$elm$html$Html$Attributes$disabled(!(!st.ia.iP)),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Doctor$PageInterview$ReturnPersonToLine(st.ia.h8.g.dw.as))
				]),
			_List_fromArray(
				[
					(!(!st.ia.iP)) ? $elm$html$Html$text('Aguarde...') : $elm$html$Html$text('Cancelar Atendimento')
				]));
		var advanceButton = (model.di || (st.ia.h8.g.e.aw === 13)) ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$type_('button'),
					$elm$html$Html$Attributes$disabled((!(!st.ia.iP)) || doNotAdvance),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Doctor$PageInterview$AdvanceScript(st.ia.h8))
				]),
			_List_fromArray(
				[
					(!(!st.ia.iP)) ? $elm$html$Html$text('Aguarde...') : $elm$html$Html$text('Próximo')
				])) : (model.dh ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$type_('button'),
					$elm$html$Html$Attributes$disabled(!(!st.ia.iP)),
					A2($elm$html$Html$Attributes$style, 'background-color', 'grey'),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Doctor$PageInterview$AdvanceScript(st.ia.h8))
				]),
			_List_fromArray(
				[
					(!(!st.ia.iP)) ? $elm$html$Html$text('Aguarde...') : $elm$html$Html$text('Ative a gravação')
				])) : A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$type_('button'),
					$elm$html$Html$Attributes$disabled(!(!st.ia.iP)),
					A2($elm$html$Html$Attributes$style, 'background-color', 'grey')
				]),
			_List_fromArray(
				[
					(!(!st.ia.iP)) ? $elm$html$Html$text('Aguarde...') : $elm$html$Html$text('Ative a gravação')
				])));
		var actionButtons = _List_fromArray(
			[
				A2($elm$html$Html$br, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('action-buttons')
					]),
				_List_fromArray(
					[cancelButton, changedButton, revertButton, advanceButton]))
			]);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-attending', true),
							_Utils_Tuple2('form-attending-hidden', model.aA)
						])),
					$elm$html$Html$Attributes$action('')
				]),
			function () {
				var _v0 = model.aQ;
				switch (_v0.$) {
					case 0:
						var pageModel = _v0.a;
						return A2(
							$elm$core$List$map,
							$elm$html$Html$map($author$project$App$Page$Doctor$PageInterview$LoadingMsg),
							$author$project$App$Page$Doctor$Interview$PageLoading$view(pageModel));
					case 1:
						var pageModel = _v0.a;
						return function (v) {
							return _Utils_ap(v, actionButtons);
						}(
							A2(
								$elm$core$List$map,
								$elm$html$Html$map($author$project$App$Page$Doctor$PageInterview$PresentationMsg),
								A2($author$project$App$Page$Doctor$Interview$PagePresentation$view, st, pageModel)));
					case 2:
						var pageModel = _v0.a;
						return function (v) {
							return _Utils_ap(v, actionButtons);
						}(
							A2(
								$elm$core$List$map,
								$elm$html$Html$map($author$project$App$Page$Doctor$PageInterview$OrientationMsg),
								A2($author$project$App$Page$Doctor$Interview$PageOrientation$view, st, pageModel)));
					case 3:
						var pageModel = _v0.a;
						return function (v) {
							return _Utils_ap(v, actionButtons);
						}(
							A2(
								$elm$core$List$map,
								$elm$html$Html$map($author$project$App$Page$Doctor$PageInterview$ConfirmIdMsg),
								A2($author$project$App$Page$Doctor$Interview$PageConfirmId$view, st, pageModel)));
					case 4:
						var pageModel = _v0.a;
						return function (v) {
							return _Utils_ap(v, actionButtons);
						}(
							A2(
								$elm$core$List$map,
								$elm$html$Html$map($author$project$App$Page$Doctor$PageInterview$ConfirmMetricsMsg),
								A2($author$project$App$Page$Doctor$Interview$PageConfirmMetrics$view, st, pageModel)));
					case 5:
						var pageModel = _v0.a;
						return function (v) {
							return _Utils_ap(v, actionButtons);
						}(
							A2(
								$elm$core$List$map,
								$elm$html$Html$map($author$project$App$Page$Doctor$PageInterview$ConfirmQuestionsMsg),
								A2($author$project$App$Page$Doctor$Interview$PageConfirmQuestions$view, st, pageModel)));
					case 6:
						var pageModel = _v0.a;
						return function (v) {
							return _Utils_ap(v, actionButtons);
						}(
							A2(
								$elm$core$List$map,
								$elm$html$Html$map($author$project$App$Page$Doctor$PageInterview$EvalDeclaredMsg),
								A2($author$project$App$Page$Doctor$Interview$PageEvalDeclared$view, st, pageModel)));
					case 7:
						var pageModel = _v0.a;
						return function (v) {
							return _Utils_ap(v, actionButtons);
						}(
							A2(
								$elm$core$List$map,
								$elm$html$Html$map($author$project$App$Page$Doctor$PageInterview$InformNoChangeMsg),
								A2($author$project$App$Page$Doctor$Interview$PageInformNoChange$view, st, pageModel)));
					case 8:
						var pageModel = _v0.a;
						return function (v) {
							return _Utils_ap(v, actionButtons);
						}(
							A2(
								$elm$core$List$map,
								$elm$html$Html$map($author$project$App$Page$Doctor$PageInterview$InformChangedMsg),
								A2($author$project$App$Page$Doctor$Interview$PageInformChanged$view, st, pageModel)));
					default:
						var pageModel = _v0.a;
						return function (v) {
							return _Utils_ap(v, lastButtons);
						}(
							A2(
								$elm$core$List$map,
								$elm$html$Html$map($author$project$App$Page$Doctor$PageInterview$FinishCallMsg),
								A2($author$project$App$Page$Doctor$Interview$PageFinishCall$view, st, pageModel)));
				}
			}());
	});
var $author$project$App$Page$Doctor$PageInterview$viewPersonDetails = F2(
	function (st, model) {
		return A2(
			$elm$html$Html$article,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('user-detail', true),
							_Utils_Tuple2('detail-attending', model.aA),
							_Utils_Tuple2('user-details-hidden', !model.aA)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$withDefault,
					$elm$html$Html$text(''),
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							$author$project$App$Components$ViewFullStatement$view(st),
							$elm$html$Html$map($author$project$App$Page$Doctor$PageInterview$ViewFullStatementMsg)),
						model.bU))
				]));
	});
var $author$project$App$Page$Doctor$PageInterview$StartRecording = function (a) {
	return {$: 20, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$StopRecording = function (a) {
	return {$: 21, a: a};
};
var $author$project$App$Page$Doctor$PageInterview$viewRecordingButton = F2(
	function (model, st) {
		return _Utils_eq(
			st.f4.dA,
			$elm$core$Maybe$Just(true)) ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('conference'),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Doctor$PageInterview$StopRecording(st.ia.h8))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Parar gravação')
				])) : A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('conference'),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Doctor$PageInterview$StartRecording(st.ia.h8))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Iniciar gravação')
				]));
	});
var $author$project$App$Page$Doctor$PageInterview$viewRecording = F2(
	function (model, st) {
		var startRecordingButton = A2($author$project$App$Page$Doctor$PageInterview$viewRecordingButton, model, st);
		var proponentView = st.f4.d7.jR ? A2(
			$elm$html$Html$i,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('material-icons')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('play_circle_filled')
				])) : A2(
			$elm$html$Html$i,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('material-icons')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('pause_circle_outline')
				]));
		var doctorRecView = st.f4.d7.jQ ? A2(
			$elm$html$Html$i,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('material-icons')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('play_circle_filled')
				])) : A2(
			$elm$html$Html$i,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('material-icons')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('pause_circle_outline')
				]));
		var originalView = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('recording')
				]),
			_List_fromArray(
				[doctorRecView, proponentView, startRecordingButton]));
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	});
var $author$project$App$Page$Doctor$PageInterview$view = F2(
	function (st, model) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('doctor-full-screen')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								$author$project$App$Data$Jitsi$view(
								{i$: 'Doctor', jg: '', eO: model.eV}),
								A2($author$project$App$Page$Doctor$PageInterview$viewPersonDetails, st, model),
								A2($author$project$App$Page$Doctor$PageInterview$viewDoctorPage, st, model),
								$author$project$App$Page$Doctor$PageInterview$viewButtonDetails(model),
								A2($author$project$App$Page$Doctor$PageInterview$viewRecording, model, st)
							]))
					]))
			]);
	});
var $author$project$App$Page$Doctor$PageListFinished$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Doctor$PageListFinished$Evaluate = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Doctor$PageListFinished$HideInterview = {$: 4};
var $author$project$App$Page$Doctor$PageListFinished$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var _v0 = function () {
			var _v1 = _Utils_Tuple2(
				stmt.aw,
				_Utils_eq(
					model.aU,
					$elm$core$Maybe$Just(stmt.as)));
			if (!_v1.b) {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Doctor$PageListFinished$Evaluate(stmt.as))
						]),
					_List_Nil);
			} else {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageListFinished$HideInterview)
						]),
					_List_Nil);
			}
		}();
		var onClickMsg = _v0.a;
		var buttons = _v0.b;
		var _v2 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v2.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v2.a.$) {
				case 3:
					var person = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_Utils_ap(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('user-item'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'user-selected',
											_Utils_eq(
												model.aU,
												$elm$core$Maybe$Just(stmt.as)))
										]))
								]),
							onClickMsg),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status:')
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$StatementStatus$statusToText(stmt.aw))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_Utils_ap(
									buttons,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$App$Data$Person$fullPersonName(person))
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
												])),
											(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente titular')
												])) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente dependente')
												])))) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$elm$core$String$fromInt(stmt.bo) + ' dependentes')
												]))
										])))
							]));
				case 2:
					var err = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v3 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v4 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Doctor$PageListFinished$viewStatements = F2(
	function (model, stmtTemplate) {
		var _v0 = model.bk;
		if (!_v0.b) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Nenhum Atendimento realizado')
						]))
				]);
		} else {
			var statements = _v0;
			return A2(
				$elm$core$List$map,
				A2($author$project$App$Page$Doctor$PageListFinished$viewUserListRow, model, stmtTemplate),
				statements);
		}
	});
var $author$project$App$Page$Doctor$PageListFinished$view = F2(
	function (sharedState, model) {
		var viewDetails = function () {
			var _v0 = model.av;
			if (!_v0.$) {
				var viewFullStmt = _v0.a;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Doctor$PageListFinished$ViewFullStmtMsg,
							A2(
								$author$project$App$Components$ViewFullStatement$view,
								sharedState,
								_Utils_update(
									viewFullStmt,
									{a5: sharedState.ia.h8})))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Atividades Realizadas')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('action-buttons')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$map,
										$author$project$App$Page$Doctor$PageListFinished$FilterMsg,
										$author$project$App$Components$Filter$view(model.b))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								A2($author$project$App$Page$Doctor$PageListFinished$viewStatements, model, sharedState.ia.h8.cJ))
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Doctor$PageListFinished$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					])),
				viewDetails
			]);
	});
var $author$project$App$Page$Doctor$PageListPending$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Doctor$PageListPending$Evaluate = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Doctor$PageListPending$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var _v0 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v0.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v0.a.$) {
				case 3:
					var person = _v0.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item'),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Doctor$PageListPending$Evaluate(stmt.as))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status:')
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$StatementStatus$statusToText(stmt.aw))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Person$fullPersonName(person))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
											])),
										(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Somente titular')
											])) : A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Somente dependente')
											])))) : A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$elm$core$String$fromInt(stmt.bo) + ' dependentes')
											]))
									]))
							]));
				case 2:
					var err = _v0.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v1 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v2 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Doctor$PageListPending$viewStatements = F2(
	function (model, stmtTemplate) {
		var _v0 = model.bk;
		if (!_v0.b) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Nenhum Atendimento pendente')
						]))
				]);
		} else {
			var statements = _v0;
			return A2(
				$elm$core$List$map,
				A2($author$project$App$Page$Doctor$PageListPending$viewUserListRow, model, stmtTemplate),
				statements);
		}
	});
var $author$project$App$Page$Doctor$PageListPending$view = F2(
	function (sharedState, model) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Atividades Pendentes')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('action-buttons')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$map,
										$author$project$App$Page$Doctor$PageListPending$FilterMsg,
										$author$project$App$Components$Filter$view(model.b))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								A2($author$project$App$Page$Doctor$PageListPending$viewStatements, model, sharedState.ia.h8.cJ))
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Doctor$PageListPending$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					]))
			]);
	});
var $author$project$App$Page$Doctor$PageListReevaluation$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Doctor$PageListReevaluation$Evaluate = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Doctor$PageListReevaluation$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var _v0 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v0.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v0.a.$) {
				case 3:
					var person = _v0.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item'),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Doctor$PageListReevaluation$Evaluate(stmt.as))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status:')
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$StatementStatus$statusToText(stmt.aw))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Person$fullPersonName(person))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
											])),
										(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Somente titular')
											])) : A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Somente dependente')
											])))) : A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$elm$core$String$fromInt(stmt.bo) + ' dependentes')
											]))
									]))
							]));
				case 2:
					var err = _v0.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v1 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v2 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Doctor$PageListReevaluation$viewStatements = F2(
	function (model, stmtTemplate) {
		var _v0 = model.bk;
		if (!_v0.b) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Nenhuma reavaliação pendente')
						]))
				]);
		} else {
			var statements = _v0;
			return A2(
				$elm$core$List$map,
				A2($author$project$App$Page$Doctor$PageListReevaluation$viewUserListRow, model, stmtTemplate),
				statements);
		}
	});
var $author$project$App$Page$Doctor$PageListReevaluation$view = F2(
	function (sharedState, model) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Reavaliações Pendentes')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('action-buttons')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$map,
										$author$project$App$Page$Doctor$PageListReevaluation$FilterMsg,
										$author$project$App$Components$Filter$view(model.b))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								A2($author$project$App$Page$Doctor$PageListReevaluation$viewStatements, model, sharedState.ia.h8.cJ))
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Doctor$PageListReevaluation$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					]))
			]);
	});
var $author$project$App$Page$Doctor$PageLoading$view = _List_fromArray(
	[
		A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('waiting')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$header,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Carregando...')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('main')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$ul,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('users-list')
							]),
						_List_Nil)
					]))
			]))
	]);
var $author$project$App$Page$Doctor$PageReevaluation$ApprovalWarning = {$: 5};
var $author$project$App$Page$Doctor$PageReevaluation$BackToPending = {$: 1};
var $author$project$App$Page$Doctor$PageReevaluation$ShowWarning = {$: 12};
var $author$project$App$Page$Doctor$PageReevaluation$SignDocument = {$: 8};
var $author$project$App$Page$Doctor$PageReevaluation$CancelApprovalWarning = {$: 7};
var $author$project$App$Page$Doctor$PageReevaluation$ConfirmApprovalWarning = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Doctor$PageReevaluation$viewError = function (err) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('error-message')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$App$Service$ApiWebSocket$errorMessage(err))
			]));
};
var $author$project$App$Page$Doctor$PageReevaluation$viewLoading = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('loading')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('Carregando...')
		]));
var $author$project$App$Page$Doctor$PageReevaluation$viewLoadingOrError = function (model) {
	var _v0 = model.e$;
	switch (_v0.$) {
		case 1:
			return $author$project$App$Page$Doctor$PageReevaluation$viewLoading;
		case 2:
			var err = _v0.a;
			return $author$project$App$Page$Doctor$PageReevaluation$viewError(err);
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$App$Page$Doctor$PageReevaluation$approvalWarningDialog = function (model) {
	if (model.dK) {
		var header = _List_Nil;
		var footer = _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'flex-direction', 'row-reverse'),
						A2($elm$html$Html$Attributes$style, 'right', '0px')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('cancel-button'),
										$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageReevaluation$CancelApprovalWarning)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Cancelar')
									])),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Doctor$PageReevaluation$ConfirmApprovalWarning(model.P.g.e.as))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Confirmar')
									]))
							])),
						$author$project$App$Page$Doctor$PageReevaluation$viewLoadingOrError(model)
					]))
			]);
		var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
		var body = _List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Deseja retornar o atendimento na situação atual?')
					]))
			]);
		var completeConfig = _Utils_update(
			config,
			{
				e5: $elm$core$Maybe$Just(body),
				ft: $elm$core$Maybe$Just(footer),
				gY: $elm$core$Maybe$Just(header),
				f1: 'Confirmação'
			});
		return $author$project$App$Components$Dialog$view(
			$elm$core$Maybe$Just(completeConfig));
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$App$Page$Doctor$PageReevaluation$AddCpt = {$: 24};
var $author$project$App$Page$Doctor$PageReevaluation$viewDocumentContent = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Page$Doctor$PageReevaluation$viewCPTContent = F3(
	function (mDepIdx, fullStmt, now) {
		var cptDoc = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Document$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					function (_v1) {
						var i = _v1.a;
						var d = _v1.b;
						return d;
					},
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var d = _v0.b;
							return _Utils_eq(d.jJ, fullStmt.g.e.as) && (d.b7 === 5);
						},
						$elm$core$Dict$toList(fullStmt.an)))));
		return $author$project$App$Data$Id$uuidIsValid(cptDoc.as) ? $author$project$App$Page$Doctor$PageReevaluation$viewDocumentContent(cptDoc) : A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A3($author$project$App$Legacy$Doctor$ResultLayout$Common$viewCPTLayout, mDepIdx, fullStmt, now),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'display', 'flex'),
							A2($elm$html$Html$Attributes$style, 'justify-content', 'center'),
							A2($elm$html$Html$Attributes$style, 'margin-bottom', '2em')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('button'),
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageReevaluation$AddCpt),
									$elm$html$Html$Attributes$value('Adicionar CPT')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Adicionar CPT')
								]))
						]))
				]));
	});
var $author$project$App$Page$Doctor$PageReevaluation$CancelSignDocument = {$: 9};
var $author$project$App$Page$Doctor$PageReevaluation$ConfirmSignDocument = {$: 16};
var $author$project$App$Page$Doctor$PageReevaluation$InputSignToken = function (a) {
	return {$: 13, a: a};
};
var $author$project$App$Page$Doctor$PageReevaluation$KeyDownSignToken = function (a) {
	return {$: 14, a: a};
};
var $author$project$App$Page$Doctor$PageReevaluation$onKeyDown = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'keydown',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$keyCode));
};
var $author$project$App$Page$Doctor$PageReevaluation$viewDialog = F2(
	function (sharedState, model) {
		if (model.h$) {
			var typeHnsg = A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
			var previewTitle = typeHnsg ? 'Prontuário' : 'Parecer';
			var header = _List_Nil;
			var documentToSign = function () {
				var haveMedicalReport = $elm$core$List$length(
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$filter,
							F2(
								function (_v0, d) {
									return d.b7 === 1;
								}),
							model.an))) > 0;
				return haveMedicalReport ? 'Atestado médico' : previewTitle;
			}();
			var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
			var btnSignText = model.h3 ? 'Enviando...' : 'Enviar';
			var footer = _List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'flex-direction', 'row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageReevaluation$CancelSignDocument),
											$elm$html$Html$Attributes$disabled(model.h3)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Cancelar')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageReevaluation$ConfirmSignDocument),
											$elm$html$Html$Attributes$disabled(model.h3)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(btnSignText)
										]))
								])),
							$author$project$App$Page$Doctor$PageReevaluation$viewLoadingOrError(model)
						]))
				]);
			var body = _List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(model.h2),
							$elm$html$Html$Events$onInput($author$project$App$Page$Doctor$PageReevaluation$InputSignToken),
							$author$project$App$Page$Doctor$PageReevaluation$onKeyDown($author$project$App$Page$Doctor$PageReevaluation$KeyDownSignToken),
							$elm$html$Html$Attributes$disabled(model.h3)
						]),
					_List_Nil)
				]);
			var completeConfig = _Utils_update(
				config,
				{
					e5: $elm$core$Maybe$Just(body),
					ft: $elm$core$Maybe$Just(footer),
					gY: $elm$core$Maybe$Just(header),
					f1: 'Token de assinatura para ' + documentToSign
				});
			return $author$project$App$Components$Dialog$view(
				$elm$core$Maybe$Just(completeConfig));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$App$Page$Doctor$PageReevaluation$AddDPS = {$: 28};
var $author$project$App$Page$Doctor$PageReevaluation$AddReport = {$: 26};
var $author$project$App$Page$Doctor$PageReevaluation$viewDoc = function (doc) {
	return (doc.dr === 'video/mp4') ? A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pdfviewer')
					]),
				(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
					[
						$elm$html$Html$text('Nenhum PDF carregado')
					]) : _List_fromArray(
					[
						$author$project$App$Data$Document$view(doc),
						$elm$html$Html$text('                    ')
					]))
			])) : ((!doc.b7) ? A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pdfviewer')
					]),
				(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
					[
						$elm$html$Html$text('Nenhum PDF carregado')
					]) : _List_fromArray(
					[
						$author$project$App$Data$Document$view(doc),
						$elm$html$Html$text('                    ')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'justify-content', 'center'),
						A2($elm$html$Html$Attributes$style, 'margin-bottom', '2em')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageReevaluation$AddDPS),
								$elm$html$Html$Attributes$value('Adicionar DPS')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Adicionar DPS')
							]))
					]))
			])) : ((doc.b7 === 5) ? A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pdfviewer')
					]),
				(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
					[
						$elm$html$Html$text('Nenhum PDF carregado')
					]) : _List_fromArray(
					[
						$author$project$App$Data$Document$view(doc),
						$elm$html$Html$text('                    ')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'justify-content', 'center'),
						A2($elm$html$Html$Attributes$style, 'margin-bottom', '2em')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageReevaluation$AddCpt),
								$elm$html$Html$Attributes$value('Adicionar CPT')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Adicionar CPT')
							]))
					]))
			])) : ((doc.b7 === 1) ? A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pdfviewer')
					]),
				(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
					[
						$elm$html$Html$text('Nenhum PDF carregado')
					]) : _List_fromArray(
					[
						$author$project$App$Data$Document$view(doc),
						$elm$html$Html$text('                    ')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'justify-content', 'center'),
						A2($elm$html$Html$Attributes$style, 'margin-bottom', '2em')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageReevaluation$AddReport),
								$elm$html$Html$Attributes$value('Adicionar Parecer')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Adicionar Parecer')
							]))
					]))
			])) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			])))));
};
var $author$project$App$Page$Doctor$PageReevaluation$viewPersonICD = F4(
	function (now, mDepIdx, stmt, sharedState) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(stmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					A3(
						$elm$core$Dict$foldr,
						F3(
							function (cat, icd, html) {
								return A2(
									$elm$core$List$append,
									html,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('chip'),
													$elm$html$Html$Attributes$title(icd.am)
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(cat)
														]))
												]))
										]));
							}),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Data$Person$fullPersonName(stmt.dw))
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(
											A2($author$project$App$Data$Person$ageOnDate, now, stmt.dw)) + ' anos')
									])),
								sharedState.ia.h8.cJ.gX ? A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											if (mDepIdx.$ === 1) {
												var i = mDepIdx.a;
												return 'Dependente ' + $elm$core$String$fromInt(i + 1);
											} else {
												return _Utils_eq(stmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
											}
										}())
									])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
							]),
						A3(
							$elm$core$Dict$foldr,
							F3(
								function (_v0, qCatDict, catDict) {
									return A3(
										$elm$core$Dict$foldl,
										F3(
											function (cat, icd, catDictInner) {
												return A3($elm$core$Dict$insert, cat, icd, catDictInner);
											}),
										catDict,
										qCatDict);
								}),
							$elm$core$Dict$empty,
							stmt.fx)))
				]));
	});
var $author$project$App$Page$Doctor$PageReevaluation$viewICDSection = F2(
	function (sharedState, model) {
		var chatView = $author$project$App$Components$Chatbox$view;
		var bearerPersonIcd = (sharedState.ia.h8.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A4($author$project$App$Page$Doctor$PageReevaluation$viewPersonICD, model.ey, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g, sharedState)
			]);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tabs ')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Reavaliação')
									])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('material-icons')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('print')
													]))
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Doctor$PageReevaluation$ChatBoxMsg,
						chatView(model.aM)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('person-icds')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_Nil,
								A3(
									$elm$core$Dict$foldl,
									F3(
										function (i, dep, html) {
											return A2(
												$elm$core$List$append,
												html,
												_List_fromArray(
													[
														A4(
														$author$project$App$Page$Doctor$PageReevaluation$viewPersonICD,
														model.ey,
														$author$project$App$Data$Statement$Dependent(i),
														dep,
														sharedState)
													]));
										}),
									bearerPersonIcd,
									sharedState.ia.h8.fh))
							]))
					]))
			]);
	});
var $author$project$App$Page$Doctor$PageReevaluation$viewMedicalDocumentContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewMedicalDocumentLayout(fullStmt);
};
var $author$project$App$Page$Doctor$PageReevaluation$InputStmtField = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageReevaluation$helpInputStmt = F4(
	function (msg, depIdx, field, str) {
		return A2(
			msg,
			depIdx,
			field(str));
	});
var $author$project$App$Page$Doctor$PageReevaluation$InputAnswer = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$App$Page$Doctor$PageReevaluation$CIDSetAutocomplete = function (a) {
	return {$: 20, a: a};
};
var $author$project$App$Page$Doctor$PageReevaluation$helpInputAnswer = F6(
	function (msg, depIdx, qId, stmtId, field, str) {
		return A4(
			msg,
			depIdx,
			qId,
			stmtId,
			field(str));
	});
var $author$project$App$Page$Doctor$PageReevaluation$inputIcdView = F5(
	function (depIdx, anstemp, sharedState, model, mSectionCategory) {
		var stmtId = function () {
			if (_Utils_eq($author$project$App$Data$Statement$Bearer, depIdx)) {
				return sharedState.ia.h8.g.e.as;
			} else {
				var dep = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2(
						$elm$core$Dict$get,
						$author$project$App$Data$Statement$depIndexToInt(depIdx),
						sharedState.ia.h8.fh));
				return dep.e.as;
			}
		}();
		var ansICD = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Answer$emptyModel,
			A2(
				$elm$core$Dict$get,
				anstemp.as,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Dict$empty,
					A2(
						$elm$core$Dict$get,
						$author$project$App$Data$Statement$depIndexToInt(depIdx),
						model.aZ))));
		var attrs = _List_fromArray(
			[
				$elm$html$Html$Attributes$type_('text'),
				$elm$html$Html$Attributes$value(ansICD.am),
				$elm$html$Html$Attributes$class('chip-input'),
				$elm$html$Html$Attributes$placeholder('Especificar CID'),
				$elm$html$Html$Events$onInput(
				A5($author$project$App$Page$Doctor$PageReevaluation$helpInputAnswer, $author$project$App$Page$Doctor$PageReevaluation$InputCIDField, depIdx, anstemp.as, stmtId, $author$project$App$Data$Answer$FieldDescription)),
				$elm$html$Html$Events$onFocus(
				$author$project$App$Page$Doctor$PageReevaluation$CIDSetAutocomplete(
					A3($author$project$App$Page$Doctor$PageReevaluation$AcQuestionIndex, anstemp.as, depIdx, mSectionCategory)))
			]);
		var acView = function () {
			var _v1 = model.f8.gt;
			var atId = _v1.a;
			var mIdx = _v1.b;
			return (_Utils_eq(atId, anstemp.as) && _Utils_eq(depIdx, mIdx)) ? A2(
				$elm$html$Html$map,
				$author$project$App$Page$Doctor$PageReevaluation$CIDAutocompleteMsg(anstemp),
				A5($author$project$App$Components$Autocomplete$viewMenu, $author$project$App$Page$Doctor$PageReevaluation$acICDCatFilter, $author$project$App$Page$Doctor$PageReevaluation$acICDCatId, $author$project$App$Page$Doctor$PageReevaluation$acICDCatDesc, model.fa, model.f8.fb)) : $elm$html$Html$text('');
		}();
		var acAttrs = function () {
			var _v0 = model.f8.gt;
			var atId = _v0.a;
			var mIdx = _v0.b;
			return (_Utils_eq(atId, anstemp.as) && _Utils_eq(depIdx, mIdx)) ? A2(
				$elm$core$List$append,
				attrs,
				A2(
					$elm$core$List$map,
					function (attr) {
						return A2(
							$elm$html$Html$Attributes$map,
							$author$project$App$Page$Doctor$PageReevaluation$CIDAutocompleteMsg(anstemp),
							attr);
					},
					$author$project$App$Components$Autocomplete$attributes(model.f8.fb))) : attrs;
		}();
		return _List_fromArray(
			[
				A2($elm$html$Html$input, acAttrs, _List_Nil),
				acView
			]);
	});
var $author$project$App$Page$Doctor$PageReevaluation$CIDRemove = F2(
	function (a, b) {
		return {$: 23, a: a, b: b};
	});
var $author$project$App$Page$Doctor$PageReevaluation$viewICD = F4(
	function (ansTemp, catCode, mIdx, icd) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('chip'),
					$elm$html$Html$Attributes$title(icd.am)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(icd.a0)
						])),
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons md-18 close closebtn'),
							$elm$html$Html$Events$onClick(
							A2($author$project$App$Page$Doctor$PageReevaluation$CIDRemove, mIdx, icd.as))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('cancel')
						]))
				]));
	});
var $author$project$App$Page$Doctor$PageReevaluation$viewICDs = F3(
	function (icds, ansTemp, mIdx) {
		return A3(
			$elm$core$Dict$foldr,
			F3(
				function (catCode, icd, result) {
					return A2(
						$elm$core$List$append,
						result,
						_List_fromArray(
							[
								A4($author$project$App$Page$Doctor$PageReevaluation$viewICD, ansTemp, catCode, mIdx, icd)
							]));
				}),
			_List_Nil,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Dict$empty,
				A2($elm$core$Dict$get, ansTemp.as, icds)));
	});
var $author$project$App$Page$Doctor$PageReevaluation$viewBMI = F4(
	function (sharedState, model, depIdx, stmt) {
		var weightTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 10) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.jM);
		var personQuestion = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestion$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (q) {
						return $author$project$App$Data$Id$uuidIsValid(q.hG.as);
					},
					A2(
						$elm$core$List$map,
						function (_v0) {
							var s = _v0.b;
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (q) {
											return _Utils_eq(q.hG.as, weightTemplate.bh);
										},
										s.eI)));
						},
						$elm$core$Dict$toList(model.cA)))));
		var heightTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 20) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.jM);
		var bmiTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 1000) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.gH);
		var doctorQuestion = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestion$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (q) {
						return $author$project$App$Data$Id$uuidIsValid(q.hG.as);
					},
					A2(
						$elm$core$List$map,
						function (s) {
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (q) {
											return _Utils_eq(q.hG.as, bmiTemplate.bh);
										},
										s.eI)));
						},
						model.P.gH))));
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no DPS')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatCompanyBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						A2(
							$elm$core$List$cons,
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Doctor$PageReevaluation$viewICDs, stmt.fx, weightTemplate, depIdx)),
							A5(
								$author$project$App$Page$Doctor$PageReevaluation$inputIcdView,
								depIdx,
								weightTemplate,
								sharedState,
								model,
								$elm$core$Maybe$Just(1))))
					])),
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no Questionário')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatPersonBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						A2(
							$elm$core$List$cons,
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Doctor$PageReevaluation$viewICDs, stmt.fx, heightTemplate, depIdx)),
							A5($author$project$App$Page$Doctor$PageReevaluation$inputIcdView, depIdx, heightTemplate, sharedState, model, $elm$core$Maybe$Nothing)))
					])),
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no Atendimento')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatDoctorBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						A2(
							$elm$core$List$cons,
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Doctor$PageReevaluation$viewICDs, stmt.fx, bmiTemplate, depIdx)),
							A5($author$project$App$Page$Doctor$PageReevaluation$inputIcdView, depIdx, bmiTemplate, sharedState, model, $elm$core$Maybe$Nothing)))
					]))
			]);
	});
var $author$project$App$Page$Doctor$PageReevaluation$maskDecimal = F2(
	function (precision, value) {
		return A3($elm$core$String$replace, '.', ',', value);
	});
var $author$project$App$Page$Doctor$PageReevaluation$maskHeight = function (cm) {
	var len = $elm$core$String$length(cm);
	var subM = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cm)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cm) : cm);
	var h = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(cm));
	var m = (h >= 100) ? A3($elm$core$String$slice, 0, -2, cm) : '0';
	return (h > 0) ? (m + (',' + subM)) : '';
};
var $author$project$App$Page$Doctor$PageReevaluation$maskPressure = function (str) {
	return function (p) {
		return (p > 0) ? $elm$core$String$fromInt(p) : '';
	}(
		function (p) {
			return (p > 999) ? (p / 10) : (p / 1.0);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(str))) | 0);
};
var $author$project$App$Page$Doctor$PageReevaluation$maskWeight = function (wStr) {
	return function (w) {
		return (w > 0) ? $elm$core$String$fromInt(w) : '';
	}(
		function (w) {
			return w / 1000;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(wStr))) | 0);
};
var $author$project$App$Page$Doctor$PageReevaluation$setupMask = F2(
	function (template, answer) {
		var _v0 = template.jj;
		if (_v0.$ === 1) {
			return answer.T;
		} else {
			switch (_v0.a.$) {
				case 3:
					var precision = _v0.a.a;
					return A2($author$project$App$Page$Doctor$PageReevaluation$maskDecimal, precision, answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 0:
					var _v1 = _v0.a;
					return $author$project$App$Page$Doctor$PageReevaluation$maskWeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 1:
					var _v2 = _v0.a;
					return $author$project$App$Page$Doctor$PageReevaluation$maskHeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				default:
					var _v3 = _v0.a;
					return $author$project$App$Page$Doctor$PageReevaluation$maskPressure(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
			}
		}
	});
var $author$project$App$Page$Doctor$PageReevaluation$viewQuestion = F5(
	function (depIdx, question, sharedState, model, doctorQuestion) {
		var stmt = function () {
			if (!depIdx.$) {
				return sharedState.ia.h8.g;
			} else {
				var idx = depIdx.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, sharedState.ia.h8.fh));
			}
		}();
		var showCids = !A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		return A2(
			$elm$core$List$map,
			function (t) {
				var answer = A3($author$project$App$Page$Doctor$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
				var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
				return (show || (t.ay >= 1000)) ? A2(
					$elm$html$Html$tr,
					_List_Nil,
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(question.hG.bP)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(t.jh)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($author$project$App$Page$Doctor$PageReevaluation$setupMask, t, answer))
									]))
							]),
						showCids ? _List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$type_('text'),
												$elm$html$Html$Attributes$value(answer.jx),
												$elm$html$Html$Events$onInput(
												A2(
													$elm$core$Basics$composeR,
													$author$project$App$Data$Answer$FieldObservation,
													A2($author$project$App$Page$Doctor$PageReevaluation$InputAnswer, depIdx, answer)))
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_Utils_ap(
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('chips chips-initial input-field')
												]),
											A3($author$project$App$Page$Doctor$PageReevaluation$viewICDs, stmt.fx, t, depIdx))
										]),
									A5($author$project$App$Page$Doctor$PageReevaluation$inputIcdView, depIdx, t, sharedState, model, $elm$core$Maybe$Nothing)))
							]) : _List_Nil)) : A2($elm$html$Html$span, _List_Nil, _List_Nil);
			},
			A2(
				$elm$core$List$filter,
				function (t) {
					var answer = A3($author$project$App$Page$Doctor$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
					var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
					return show;
				},
				question.bY));
	});
var $author$project$App$Page$Doctor$PageReevaluation$viewEditStatementSectionWithHeader = F5(
	function (depIdx, sharedState, model, fullQuestionSection, doctorQuestionSection) {
		var showCids = !A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var sectionName = function () {
			if (sharedState.ia.h8.b4.iR === 'Extramed') {
				var _v0 = fullQuestionSection.hX.a0;
				switch (_v0) {
					case 2:
						return 'Questionário';
					case 1:
						return 'Declaração';
					case 3:
						return 'Atendimento';
					default:
						return 'Desconhecido';
				}
			} else {
				return A2($elm$core$String$left, 50, fullQuestionSection.hX.hl) + '...';
			}
		}();
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '90%')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$colgroup,
					_List_Nil,
					_List_fromArray(
						[
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil)
						])),
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(sectionName)
											])),
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$colspan(2)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Resposta')
											]))
									]),
								showCids ? _List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Obs')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('CIDs')
											]))
									]) : _List_Nil))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, q) {
									return A5(
										$author$project$App$Page$Doctor$PageReevaluation$viewQuestion,
										depIdx,
										q,
										sharedState,
										model,
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$FullQuestion$emptyModel,
											$elm$core$List$head(
												A2($elm$core$List$drop, i, doctorQuestionSection.eI))));
								}),
							A2(
								$elm$core$List$sortBy,
								function (sec) {
									return sec.hG.bB;
								},
								A2(
									$elm$core$List$filter,
									function (q) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (t) {
													var answer = A3($author$project$App$Page$Doctor$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
													var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
													return show;
												},
												q.bY)) > 0;
									},
									fullQuestionSection.eI)))))
				]));
	});
var $author$project$App$Page$Doctor$PageReevaluation$viewEditStatementSectionWithoutHeader = F5(
	function (depIdx, sharedState, model, fullQuestionSection, doctorQuestionSection) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$tbody,
				_List_Nil,
				$elm$core$List$concat(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, q) {
								return A5(
									$author$project$App$Page$Doctor$PageReevaluation$viewQuestion,
									depIdx,
									q,
									sharedState,
									model,
									A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$FullQuestion$emptyModel,
										$elm$core$List$head(
											A2($elm$core$List$drop, i, doctorQuestionSection.eI))));
							}),
						A2(
							$elm$core$List$sortBy,
							function (sec) {
								return sec.hG.bB;
							},
							A2(
								$elm$core$List$filter,
								function (q) {
									return $elm$core$List$length(
										A2(
											$elm$core$List$filter,
											function (t) {
												var answer = A3($author$project$App$Page$Doctor$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
												var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
												return show;
											},
											q.bY)) > 0;
								},
								fullQuestionSection.eI)))))
			]);
	});
var $author$project$App$Page$Doctor$PageReevaluation$viewPersonDetails = F3(
	function (sharedState, depIdx, editStmt) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(editStmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$fullPersonName(editStmt.dw))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$formatDocument(editStmt.dw.i1))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Tools$FormHelpers$dateFormat(editStmt.dw.al))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									function (str) {
										return str + ' anos';
									}(
										$elm$core$String$fromInt(
											A2(
												$author$project$App$Data$Person$ageOnDate,
												$author$project$App$Service$ApiWebSocket$getTimestamp(sharedState._),
												editStmt.dw))))
								])),
							sharedState.ia.h8.cJ.gX ? A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									function () {
										if (depIdx.$ === 1) {
											var i = depIdx.a;
											return 'Dependente ' + $elm$core$String$fromInt(i + 1);
										} else {
											return _Utils_eq(editStmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
										}
									}())
								])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
						]))
				]));
	});
var $author$project$App$Page$Doctor$PageReevaluation$viewPersonAnswers = F4(
	function (sharedState, model, depIdx, stmt) {
		var showPerson = (sharedState.ia.h8.b4.iR === 'Extramed') || (sharedState.ia.h8.b4.iR === 'Medsyn');
		var showDoctorQuestions = (sharedState.ia.h8.b4.iR === 'Extramed') || ((sharedState.ia.h8.b4.iR === 'Medsyn') || (sharedState.ia.h8.b4.iR === 'Centauro-ON'));
		var showBMI = sharedState.ia.h8.b4.iR === 'Extramed';
		var doctorSection = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestionSection$emptyModel,
			$elm$core$List$head(
				$elm$core$List$reverse(sharedState.ia.h8.gH)));
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$ul,
							_List_Nil,
							_List_fromArray(
								[
									A3($author$project$App$Page$Doctor$PageReevaluation$viewPersonDetails, sharedState, depIdx, stmt)
								]))
						])),
					showBMI ? A2(
					$elm$html$Html$table,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$colgroup,
							_List_Nil,
							_List_fromArray(
								[
									A2($elm$html$Html$col, _List_Nil, _List_Nil),
									A2($elm$html$Html$col, _List_Nil, _List_Nil),
									A2($elm$html$Html$col, _List_Nil, _List_Nil),
									A2($elm$html$Html$col, _List_Nil, _List_Nil)
								])),
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Dados Antropométricos')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Valor')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('CIDs')
												]))
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							A4($author$project$App$Page$Doctor$PageReevaluation$viewBMI, sharedState, model, depIdx, stmt))
						])) : $elm$html$Html$text('')
				]),
			_Utils_ap(
				showBMI ? A2(
					$elm$core$List$cons,
					A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '90%')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$colgroup,
								_List_Nil,
								_List_fromArray(
									[
										A2($elm$html$Html$col, _List_Nil, _List_Nil),
										A2($elm$html$Html$col, _List_Nil, _List_Nil),
										A2($elm$html$Html$col, _List_Nil, _List_Nil),
										A2($elm$html$Html$col, _List_Nil, _List_Nil),
										A2($elm$html$Html$col, _List_Nil, _List_Nil)
									])),
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Declaração')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Obs')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('CIDs')
													]))
											]))
									]))
							])),
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, s) {
									return A5(
										$author$project$App$Page$Doctor$PageReevaluation$viewEditStatementSectionWithoutHeader,
										depIdx,
										sharedState,
										model,
										s,
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$FullQuestionSection$emptyModel,
											$elm$core$List$head(
												A2($elm$core$List$drop, i, sharedState.ia.h8.bs))));
								}),
							A2(
								$elm$core$List$filter,
								function (s) {
									return $elm$core$List$length(
										A2(
											$elm$core$List$filter,
											function (q) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (t) {
															var answer = A3($author$project$App$Page$Doctor$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
															var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
															return show;
														},
														q.bY)) > 0;
											},
											A2(
												$elm$core$List$sortBy,
												function (sec) {
													return sec.hG.bB;
												},
												s.eI))) > 0;
								},
								sharedState.ia.h8.bs)))) : A2(
					$elm$core$List$indexedMap,
					F2(
						function (i, s) {
							return A5(
								$author$project$App$Page$Doctor$PageReevaluation$viewEditStatementSectionWithHeader,
								depIdx,
								sharedState,
								model,
								s,
								A2(
									$elm$core$Maybe$withDefault,
									$author$project$App$Data$FullQuestionSection$emptyModel,
									$elm$core$List$head(
										A2($elm$core$List$drop, i, sharedState.ia.h8.bs))));
						}),
					A2(
						$elm$core$List$filter,
						function (s) {
							return $elm$core$List$length(
								A2(
									$elm$core$List$filter,
									function (q) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (t) {
													var answer = A3($author$project$App$Page$Doctor$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
													var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
													return show;
												},
												q.bY)) > 0;
									},
									A2(
										$elm$core$List$sortBy,
										function (sec) {
											return sec.hG.bB;
										},
										s.eI))) > 0;
						},
						sharedState.ia.h8.bs)),
				_Utils_ap(
					showBMI ? A2(
						$elm$core$List$cons,
						A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '90%')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$colgroup,
									_List_Nil,
									_List_fromArray(
										[
											A2($elm$html$Html$col, _List_Nil, _List_Nil),
											A2($elm$html$Html$col, _List_Nil, _List_Nil),
											A2($elm$html$Html$col, _List_Nil, _List_Nil),
											A2($elm$html$Html$col, _List_Nil, _List_Nil),
											A2($elm$html$Html$col, _List_Nil, _List_Nil)
										])),
									A2(
									$elm$html$Html$thead,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Questionário')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Obs')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('CIDs')
														]))
												]))
										]))
								])),
						$elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								F2(
									function (i, s) {
										return A5(
											$author$project$App$Page$Doctor$PageReevaluation$viewEditStatementSectionWithoutHeader,
											depIdx,
											sharedState,
											model,
											s,
											A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$FullQuestionSection$emptyModel,
												$elm$core$List$head(
													A2($elm$core$List$drop, i, sharedState.ia.h8.jM))));
									}),
								A2(
									$elm$core$List$filter,
									function (s) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (q) {
													return $elm$core$List$length(
														A2(
															$elm$core$List$filter,
															function (t) {
																var answer = A3($author$project$App$Page$Doctor$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
																return show;
															},
															q.bY)) > 0;
												},
												A2(
													$elm$core$List$sortBy,
													function (sec) {
														return sec.hG.bB;
													},
													s.eI))) > 0;
									},
									sharedState.ia.h8.jM)))) : (showPerson ? A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, s) {
								return A5(
									$author$project$App$Page$Doctor$PageReevaluation$viewEditStatementSectionWithHeader,
									depIdx,
									sharedState,
									model,
									s,
									A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$FullQuestionSection$emptyModel,
										$elm$core$List$head(
											A2($elm$core$List$drop, i, sharedState.ia.h8.jM))));
							}),
						A2(
							$elm$core$List$filter,
							function (s) {
								return $elm$core$List$length(
									A2(
										$elm$core$List$filter,
										function (q) {
											return $elm$core$List$length(
												A2(
													$elm$core$List$filter,
													function (t) {
														var answer = A3($author$project$App$Page$Doctor$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
														var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
														return show;
													},
													q.bY)) > 0;
										},
										A2(
											$elm$core$List$sortBy,
											function (sec) {
												return sec.hG.bB;
											},
											s.eI))) > 0;
							},
							sharedState.ia.h8.jM)) : _List_Nil),
					function () {
						if (showDoctorQuestions) {
							return showBMI ? A2(
								$elm$core$List$cons,
								A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'width', '90%')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$colgroup,
											_List_Nil,
											_List_fromArray(
												[
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil)
												])),
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$tr,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Atendimento')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Obs')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('CIDs')
																]))
														]))
												]))
										])),
								$elm$core$List$concat(
									A2(
										$elm$core$List$map,
										function (s) {
											return A5($author$project$App$Page$Doctor$PageReevaluation$viewEditStatementSectionWithoutHeader, depIdx, sharedState, model, s, s);
										},
										A2(
											$elm$core$List$filter,
											function (s) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (q) {
															return ($elm$core$List$length(
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var answer = A3($author$project$App$Page$Doctor$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																		var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																		return show;
																	},
																	q.bY)) > 0) && (!A2(
																$elm$core$List$any,
																function (t) {
																	return (t.ay === 10) || (t.ay === 20);
																},
																q.bY));
														},
														A2(
															$elm$core$List$sortBy,
															function (sec) {
																return sec.hG.bB;
															},
															s.eI))) > 0;
											},
											sharedState.ia.h8.gH)))) : A2(
								$elm$core$List$map,
								function (s) {
									return A5($author$project$App$Page$Doctor$PageReevaluation$viewEditStatementSectionWithHeader, depIdx, sharedState, model, s, s);
								},
								A2(
									$elm$core$List$filter,
									function (s) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (q) {
													return $elm$core$List$length(
														A2(
															$elm$core$List$filter,
															function (t) {
																var answer = A3($author$project$App$Page$Doctor$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																return show;
															},
															q.bY)) > 0;
												},
												A2(
													$elm$core$List$sortBy,
													function (sec) {
														return sec.hG.bB;
													},
													s.eI))) > 0;
									},
									sharedState.ia.h8.gH));
						} else {
							var question = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(doctorSection.eI));
							var template = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$AnswerTemplate$emptyModel,
								$elm$core$List$head(question.bY));
							var answer = A3($author$project$App$Page$Doctor$PageReevaluation$getAnswerForTemplateId, depIdx, model, template.as);
							return (answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0))) ? _List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Evoluções médicas:')
										])),
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(answer.T),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeR,
												$author$project$App$Data$Answer$FieldValue,
												A2($author$project$App$Page$Doctor$PageReevaluation$InputAnswer, depIdx, answer)))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Atestado Médico:')
										])),
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(answer.jx),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeR,
												$author$project$App$Data$Answer$FieldObservation,
												A2($author$project$App$Page$Doctor$PageReevaluation$InputAnswer, depIdx, answer)))
										]),
									_List_Nil)
								]) : _List_Nil;
						}
					}())));
	});
var $author$project$App$Page$Doctor$PageReevaluation$viewQuestions = F2(
	function (model, sharedState) {
		var depsPersonAnswers = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$second,
				$elm$core$Dict$toList(
					A2(
						$elm$core$Dict$map,
						F2(
							function (idx, dep) {
								return A4(
									$author$project$App$Page$Doctor$PageReevaluation$viewPersonAnswers,
									sharedState,
									model,
									$author$project$App$Data$Statement$depIndexFromInt(idx),
									dep);
							}),
						sharedState.ia.h8.fh))));
		var bearerPersonAnswers = (!(sharedState.ia.h8.g.e.j_ === 2)) ? A4($author$project$App$Page$Doctor$PageReevaluation$viewPersonAnswers, sharedState, model, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g) : _List_Nil;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('questions')
				]),
			_Utils_ap(
				bearerPersonAnswers,
				_Utils_ap(
					depsPersonAnswers,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Conclusões finais do atendimento:')
								])),
							A2(
							$elm$html$Html$textarea,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(model.P.g.e.jx),
									$elm$html$Html$Events$onInput(
									A3($author$project$App$Page$Doctor$PageReevaluation$helpInputStmt, $author$project$App$Page$Doctor$PageReevaluation$InputStmtField, $author$project$App$Data$Statement$Bearer, $author$project$App$Data$Statement$FieldObservation))
								]),
							_List_Nil)
						]))));
	});
var $author$project$App$Page$Doctor$PageReevaluation$SelectDetailsTab = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Doctor$PageReevaluation$TabCPT = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Doctor$PageReevaluation$TabDocument = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Doctor$PageReevaluation$viewDocumentTabNumered = F4(
	function (currentTab, idx, doc, documentTabs) {
		var index = $elm$core$List$length(documentTabs) + 1;
		return A2(
			$elm$core$List$append,
			documentTabs,
			function () {
				var _v0 = doc.dr;
				switch (_v0) {
					case 'video/webm':
						return _List_fromArray(
							[
								$elm$html$Html$text('')
							]);
					case 'video/mp4':
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Doctor$PageReevaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Doctor$PageReevaluation$SelectDetailsTab(
											$author$project$App$Page$Doctor$PageReevaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										'Video' + $elm$core$String$fromInt(index))
									]))
							]);
					default:
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Doctor$PageReevaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Doctor$PageReevaluation$SelectDetailsTab(
											$author$project$App$Page$Doctor$PageReevaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											var _v1 = doc.b7;
											switch (_v1) {
												case 1:
													return 'Parecer Antigo' + $elm$core$String$fromInt(index);
												case 3:
													return 'Atestado' + $elm$core$String$fromInt(index);
												case 4:
													return 'Receita' + $elm$core$String$fromInt(index);
												default:
													return 'Anexo';
											}
										}())
									]))
							]);
				}
			}());
	});
var $author$project$App$Page$Doctor$PageReevaluation$TabMedicalDocument = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Doctor$PageReevaluation$viewResultTab = F5(
	function (previewTitle, typeHnsg, currentTab, idx, d) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Doctor$PageReevaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Doctor$PageReevaluation$SelectDetailsTab(
							$author$project$App$Page$Doctor$PageReevaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(previewTitle)
					])),
				typeHnsg ? A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Doctor$PageReevaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Doctor$PageReevaluation$SelectDetailsTab(
							$author$project$App$Page$Doctor$PageReevaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Pré-Visualizar Atestado')
					])) : A2($elm$html$Html$span, _List_Nil, _List_Nil)
			]);
	});
var $author$project$App$Page$Doctor$PageReevaluation$viewQuestionsTabs = F5(
	function (fullStmt, viewCPT, typeHnsg, currentTab, docs) {
		var reportDocs = A2(
			$elm$core$List$sortWith,
			F2(
				function (a, b) {
					var _v8 = A2(
						$elm$core$Basics$compare,
						$elm$time$Time$posixToMillis(a.b.iW),
						$elm$time$Time$posixToMillis(b.b.iW));
					switch (_v8) {
						case 0:
							return 2;
						case 1:
							return 1;
						default:
							return 0;
					}
				}),
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v7, doc) {
							return doc.b7 === 1;
						}),
					docs)));
		var previewTitle = typeHnsg ? 'Pré-Visualizar Prontuário' : 'Pré-Visualizar Parecer';
		var resultTab = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$second,
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$map,
							A3($author$project$App$Page$Doctor$PageReevaluation$viewResultTab, previewTitle, typeHnsg, currentTab),
							$elm$core$Dict$fromList(
								A2($elm$core$List$take, 1, reportDocs)))))));
		var oldResultsTab = A3(
			$elm$core$Dict$foldl,
			$author$project$App$Page$Doctor$PageReevaluation$viewDocumentTabNumered(currentTab),
			_List_Nil,
			$elm$core$Dict$fromList(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(reportDocs))));
		var docsTabs = A3(
			$elm$core$Dict$foldl,
			F3(
				function (i, d, list) {
					var attachName = function () {
						var _v6 = d.dr;
						if (_v6 === 'video/mp4') {
							return 'Video';
						} else {
							return (d.b7 === 4) ? 'Receita' : ('Anexo ' + $elm$core$String$fromInt(i));
						}
					}();
					return A2(
						$elm$core$List$append,
						list,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Doctor$PageReevaluation$TabDocument(i)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Doctor$PageReevaluation$SelectDetailsTab(
											$author$project$App$Page$Doctor$PageReevaluation$TabDocument(i))),
										$elm$html$Html$Attributes$href('#')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(attachName)
									]))
							]));
				}),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'tab-selected',
									_Utils_eq(currentTab, $author$project$App$Page$Doctor$PageReevaluation$TabCIDs))
								])),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Doctor$PageReevaluation$SelectDetailsTab($author$project$App$Page$Doctor$PageReevaluation$TabCIDs))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Dados do Atendimento')
						]))
				]),
			A2(
				$elm$core$Dict$filter,
				F2(
					function (_v5, d) {
						return d.b7 !== 5;
					}),
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v4, d) {
							return d.b7 !== 3;
						}),
					A2(
						$elm$core$Dict$filter,
						F2(
							function (_v3, d) {
								return d.b7 !== 1;
							}),
						$elm$core$Dict$fromList(
							_Utils_ap(
								A2(
									$elm$core$List$take,
									1,
									$elm$core$List$reverse(
										$elm$core$Dict$toList(
											A2(
												$elm$core$Dict$filter,
												F2(
													function (_v1, doc) {
														return doc.dr === 'video/mp4';
													}),
												docs)))),
								$elm$core$Dict$toList(
									A2(
										$elm$core$Dict$filter,
										F2(
											function (_v2, doc) {
												return (doc.dr !== 'video/webm') && (doc.dr !== 'video/mp4');
											}),
										docs))))))));
		var bearerCpt = (fullStmt.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Doctor$PageReevaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Doctor$PageReevaluation$SelectDetailsTab(
							$author$project$App$Page$Doctor$PageReevaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('CPT Titular')
					]))
			]);
		var cptTabs = viewCPT ? A2(
			$elm$core$List$append,
			bearerCpt,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var idx = _v0.a;
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('#'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'tab-selected',
										_Utils_eq(
											currentTab,
											$author$project$App$Page$Doctor$PageReevaluation$TabCPT(
												$author$project$App$Data$Statement$Dependent(idx))))
									])),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Doctor$PageReevaluation$SelectDetailsTab(
									$author$project$App$Page$Doctor$PageReevaluation$TabCPT(
										$author$project$App$Data$Statement$Dependent(idx))))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'CPT Dep ' + $elm$core$String$fromInt(idx + 1))
							]));
				},
				$elm$core$Dict$toList(fullStmt.fh))) : _List_Nil;
		var allTabs = _Utils_ap(
			docsTabs,
			_Utils_ap(
				resultTab,
				_Utils_ap(oldResultsTab, cptTabs)));
		return A2($elm$html$Html$div, _List_Nil, allTabs);
	});
var $author$project$App$Page$Doctor$PageReevaluation$viewResultContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewResultLayout(fullStmt);
};
var $author$project$App$Page$Doctor$PageReevaluation$CancelWarning = {$: 11};
var $author$project$App$Page$Doctor$PageReevaluation$ConfirmWarning = {$: 10};
var $author$project$App$Page$Doctor$PageReevaluation$warningDialog = function (model) {
	if (model.h0) {
		var header = _List_Nil;
		var footer = _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'flex-direction', 'row-reverse'),
						A2($elm$html$Html$Attributes$style, 'right', '0px')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('cancel-button'),
										$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageReevaluation$CancelWarning)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Cancelar')
									])),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageReevaluation$ConfirmWarning)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Confirmar')
									]))
							])),
						$author$project$App$Page$Doctor$PageReevaluation$viewLoadingOrError(model)
					]))
			]);
		var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
		var body = _List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('É de prioridade a implantação do cid em todos os itens da declaração de saúde.'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						$elm$html$Html$text('Tem certeza que o(a) doutor(a) quer deixar em branco?')
					]))
			]);
		var completeConfig = _Utils_update(
			config,
			{
				e5: $elm$core$Maybe$Just(body),
				ft: $elm$core$Maybe$Just(footer),
				gY: $elm$core$Maybe$Just(header),
				f1: 'Confirmação'
			});
		return $author$project$App$Components$Dialog$view(
			$elm$core$Maybe$Just(completeConfig));
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$App$Page$Doctor$PageReevaluation$view = F3(
	function (now, sharedState, model) {
		var viewCPT = sharedState.ia.h8.b4.iR === 'Extramed';
		var typeHnsg = A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var maybePdfResult = $elm$core$List$head(
			$elm$core$List$reverse(
				A2(
					$elm$core$List$filter,
					function (d) {
						return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
					},
					$elm$core$Dict$values(model.an))));
		var maybePdfDocument = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var hasMedicalReport = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 1;
		var hasMedicalDocument = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var canBeSigned = (!$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (_v3) {
					var stmtIdx = _v3.a;
					var stmt = _v3.b;
					var idx = $author$project$App$Data$Statement$depIndexFromInt(stmtIdx);
					return $elm$core$List$length(
						A2(
							$elm$core$List$filter,
							function (s) {
								return $elm$core$List$length(
									A2(
										$elm$core$List$filter,
										function (q) {
											return $elm$core$List$length(
												A2(
													$elm$core$List$filter,
													function (t) {
														var hasIcd = A2($elm$core$Dict$member, t.as, stmt.fx);
														var answer = A3($author$project$App$Page$Doctor$PageReevaluation$getAnswerForTemplateId, idx, model, t.as);
														var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
														return show && (!hasIcd);
													},
													q.bY)) > 0;
										},
										A2(
											$elm$core$List$sortBy,
											function (sec) {
												return sec.hG.bB;
											},
											s.eI))) > 0;
							},
							sharedState.ia.h8.bs)) > 0;
				},
				$elm$core$Dict$toList(sharedState.ia.h8.fh)))) && (!function () {
			var idx = $author$project$App$Data$Statement$Bearer;
			return $elm$core$List$length(
				A2(
					$elm$core$List$filter,
					function (s) {
						return $elm$core$List$length(
							A2(
								$elm$core$List$filter,
								function (q) {
									return $elm$core$List$length(
										A2(
											$elm$core$List$filter,
											function (t) {
												var hasIcd = A2($elm$core$Dict$member, t.as, sharedState.ia.h8.g.fx);
												var answer = A3($author$project$App$Page$Doctor$PageReevaluation$getAnswerForTemplateId, idx, model, t.as);
												var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
												return show && (!hasIcd);
											},
											q.bY)) > 0;
								},
								A2(
									$elm$core$List$sortBy,
									function (sec) {
										return sec.hG.bB;
									},
									s.eI))) > 0;
					},
					sharedState.ia.h8.bs));
		}());
		var signButton = canBeSigned ? A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageReevaluation$SignDocument)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Assinar')
				])) : A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageReevaluation$ShowWarning)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Assinar')
				]));
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$header,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('tabs')
								]),
							_List_fromArray(
								[
									A5($author$project$App$Page$Doctor$PageReevaluation$viewQuestionsTabs, sharedState.ia.h8, viewCPT, typeHnsg, model.b5, sharedState.ia.h8.an),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('cancel-button'),
													$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageReevaluation$BackToPending)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Retornar')
												])),
											signButton,
											(hasMedicalReport && (hasMedicalDocument || (!typeHnsg))) ? A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageReevaluation$ApprovalWarning)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Finalizar')
												])) : $elm$html$Html$text('')
										]))
								])),
							function () {
							var _v0 = model.b5;
							switch (_v0.$) {
								case 0:
									return A2($author$project$App$Page$Doctor$PageReevaluation$viewQuestions, model, sharedState);
								case 1:
									var docIdx = _v0.a;
									return $author$project$App$Page$Doctor$PageReevaluation$viewDoc(
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$Document$emptyModel,
											A2($elm$core$Dict$get, docIdx, model.an)));
								case 2:
									var mDepIdx = _v0.a;
									if (!maybePdfResult.$) {
										var pdfResult = maybePdfResult.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Doctor$PageReevaluation$viewResultContent(sharedState.ia.h8);
									}
								case 3:
									var mDepIdx = _v0.a;
									if (!maybePdfDocument.$) {
										var pdfResult = maybePdfDocument.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Doctor$PageReevaluation$viewMedicalDocumentContent(model.P);
									}
								case 5:
									var videoRepo = {f3: 'video-repo-enterprise-covid.ingress.medsyn.com.br'};
									var videoUrl = A2($author$project$App$Data$VideoRepository$statementVideoUrl, videoRepo, sharedState.ia.h8.g.e.as);
									return A2(
										$elm$html$Html$video,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('video-preview'),
												$elm$html$Html$Attributes$controls(true)
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$source,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$src(videoUrl),
														$elm$html$Html$Attributes$type_('video/mp4')
													]),
												_List_Nil),
												$elm$html$Html$text('Seu browser não suporte exibição de vídeo HTML5')
											]));
								case 4:
									var depIdx = _v0.a;
									var fullStmt = sharedState.ia.h8;
									return A3($author$project$App$Page$Doctor$PageReevaluation$viewCPTContent, depIdx, fullStmt, now);
								default:
									return $elm$html$Html$text('');
							}
						}()
						])),
					A2($author$project$App$Page$Doctor$PageReevaluation$viewDialog, sharedState, model),
					$author$project$App$Page$Doctor$PageReevaluation$warningDialog(model),
					$author$project$App$Page$Doctor$PageReevaluation$approvalWarningDialog(model)
				]),
			A2($author$project$App$Page$Doctor$PageReevaluation$viewICDSection, sharedState, model));
	});
var $author$project$App$Page$Doctor$PageReview$ReturnToLine = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Doctor$PageReview$StartInterview = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Doctor$PageReview$viewButtons = function (stmt) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('action-buttons')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('cancel-button'),
						$elm$html$Html$Attributes$type_('button'),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Doctor$PageReview$ReturnToLine(stmt.Y))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Recusar')
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('button'),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Doctor$PageReview$StartInterview(stmt.as))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Prosseguir')
					]))
			]));
};
var $author$project$App$Page$Doctor$PageReview$viewLoaded = F2(
	function (st, model) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('waiting')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Analisando Informações')
									])),
								$author$project$App$Page$Doctor$PageReview$viewButtons(st.ia.h8.g.e)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$map,
								$author$project$App$Page$Doctor$PageReview$ViewFullStatementMsg,
								A2($author$project$App$Components$ViewFullStatement$view, st, model.bm))
							]))
					]))
			]);
	});
var $author$project$App$Page$Doctor$PageReview$view = F2(
	function (st, model) {
		var _v0 = model.p;
		if (!_v0) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('waiting')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$header,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Carregando...')
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('main')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$ul,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('users-list')
										]),
									_List_Nil)
								]))
						]))
				]);
		} else {
			return A2($author$project$App$Page$Doctor$PageReview$viewLoaded, st, model);
		}
	});
var $author$project$App$Page$Doctor$PageWaitingLine$DisableSound = {$: 10};
var $author$project$App$Page$Doctor$PageWaitingLine$EnableSound = {$: 8};
var $author$project$App$Components$Sound$isEnabled = function (model) {
	if (!model.$) {
		return false;
	} else {
		return true;
	}
};
var $author$project$App$Data$Person$fullName = function (p) {
	return p.hl + (' ' + p.ii);
};
var $author$project$App$Page$Doctor$PageWaitingLine$viewDoctorOnLine = function (doctor) {
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('user-item')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(
						$author$project$App$Data$Person$photoUrl(doctor))
					]),
				_List_Nil),
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$App$Data$Person$fullName(doctor))
					]))
			]));
};
var $author$project$App$Page$Doctor$PageWaitingLine$viewDoctors = F3(
	function (model, st, doctorId) {
		return A2(
			$elm$html$Html$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$header,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Médicos Online')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$ul,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('doctor-list')
								]),
							A3(
								$elm$core$List$foldl,
								F2(
									function (doctor, html) {
										return A2(
											$elm$core$List$append,
											html,
											_List_fromArray(
												[
													$author$project$App$Page$Doctor$PageWaitingLine$viewDoctorOnLine(doctor)
												]));
									}),
								_List_Nil,
								model.c5))
						]))
				]));
	});
var $author$project$App$Page$Doctor$PageWaitingLine$HoldPerson = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Data$Person$fromWaiting = function (waiting) {
	return {al: waiting.al, D: waiting.D, i1: waiting.i1, as: waiting.as, cp: waiting.cp, hl: waiting.hl, dy: waiting.dy, cD: false, dJ: waiting.dJ, B: waiting.B, ii: waiting.ii, f2: waiting.f2};
};
var $author$project$App$Data$Person$waitingTime = F2(
	function (start, now) {
		var startMillis = $elm$time$Time$posixToMillis(start);
		var nowMillis = $elm$time$Time$posixToMillis(now);
		var diff = $elm$time$Time$millisToPosix(nowMillis - startMillis);
		var hours = $elm$core$String$fromInt(
			A2($elm$time$Time$toHour, $elm$time$Time$utc, diff));
		var min = $elm$core$String$fromInt(
			A2($elm$time$Time$toMinute, $elm$time$Time$utc, diff));
		var sec = $elm$core$String$fromInt(
			A2($elm$time$Time$toSecond, $elm$time$Time$utc, diff));
		return (!nowMillis) ? '' : (hours + ('h' + (min + ('m' + (sec + 's ')))));
	});
var $author$project$App$Page$Doctor$PageWaitingLine$viewWaitingUserRow = F2(
	function (st, wt) {
		var scheduledTime = _Utils_eq(
			wt.h8.jU,
			$elm$time$Time$millisToPosix(0)) ? $elm$html$Html$text('') : A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(
					'Agendado p/ ' + (A3(
						$elm$core$String$slice,
						8,
						10,
						$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(wt.h8.jU)) + ('-' + (A3(
						$elm$core$String$slice,
						5,
						7,
						$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(wt.h8.jU)) + (',' + (A3(
						$elm$core$String$slice,
						11,
						16,
						$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(wt.h8.jU)) + 'h'))))))
				]));
		var person = $author$project$App$Data$Person$fromWaiting(wt.dw);
		var now = $author$project$App$Service$ApiWebSocket$getTimestamp(st._);
		var status = (wt.dw.aw === 11) ? A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('can-be-taken')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					'Na fila a ' + A2($author$project$App$Data$Person$waitingTime, wt.dw.bW, now))
				])) : A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cannot-be-taken')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$author$project$App$Data$StatementStatus$statusToText(wt.dw.aw))
				]));
		var evt = (wt.dw.aw === 11) ? $elm$html$Html$Events$onClick(
			$author$project$App$Page$Doctor$PageWaitingLine$HoldPerson(wt.dw)) : A2($elm$html$Html$Attributes$attribute, 'onclick', '');
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item'),
					evt
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(person))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$fullPersonName(wt.dw))
								])),
							status,
							scheduledTime,
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(wt.b4.iR)
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(wt.cJ.hl)
								]))
						]))
				]));
	});
var $author$project$App$Page$Doctor$PageWaitingLine$viewWaitingUserRowDoctor = F3(
	function (model, lst, wt) {
		var person = $author$project$App$Data$Person$fromWaiting(wt.dw);
		var mDoctor = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (doc) {
					return _Utils_eq(
						wt.dw.cs,
						$elm$core$Maybe$Just(doc.as));
				},
				model.c5));
		var doctorFullName = function () {
			if (!mDoctor.$) {
				var doctor = mDoctor.a;
				return doctor.hl + (' ' + doctor.ii);
			} else {
				return '(carregando...)';
			}
		}();
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(person))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$fullPersonName(wt.dw))
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('cannot-be-taken')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sendo atendido por ' + doctorFullName)
								]))
						]))
				]));
	});
var $author$project$App$Page$Doctor$PageWaitingLine$viewWaitingLine = F2(
	function (st, model) {
		var notInInterview = A2(
			$elm$core$List$filter,
			function (wt) {
				return (_Utils_cmp(
					$author$project$App$Data$StatementStatus$statusToInt(wt.dw.aw),
					$author$project$App$Data$StatementStatus$statusToInt(4)) > 0) && (_Utils_cmp(
					$author$project$App$Data$StatementStatus$statusToInt(wt.dw.aw),
					$author$project$App$Data$StatementStatus$statusToInt(12)) < 0);
			},
			model.e1);
		var waitingLineHtml = A2(
			$elm$core$List$map,
			$author$project$App$Page$Doctor$PageWaitingLine$viewWaitingUserRow(st),
			notInInterview);
		var inInterview = A2(
			$elm$core$List$filter,
			function (wt) {
				return $author$project$App$Data$StatementStatus$statusInInterview(wt.dw.aw);
			},
			model.e1);
		var attendingHtml = A2(
			$elm$core$List$map,
			A2($author$project$App$Page$Doctor$PageWaitingLine$viewWaitingUserRowDoctor, model, st),
			inInterview);
		return (($elm$core$List$length(notInInterview) > 0) || ($elm$core$List$length(inInterview) > 0)) ? $elm$core$List$concat(
			_List_fromArray(
				[waitingLineHtml, attendingHtml])) : _List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Fila vazia')
					]))
			]);
	});
var $author$project$App$Page$Doctor$PageWaitingLine$view = F2(
	function (st, model) {
		var soundInit = $author$project$App$Components$Sound$isEnabled(model.N) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('action-buttons')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageWaitingLine$DisableSound),
							$elm$html$Html$Attributes$title('Desabilitar Notificações Sonoras')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('notifications_active')
								]))
						]))
				])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('action-buttons')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$PageWaitingLine$EnableSound),
							$elm$html$Html$Attributes$class('cancel-button'),
							$elm$html$Html$Attributes$title('Habilitar Notificações Sonoras')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('notifications_off')
								]))
						]))
				]));
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('waiting')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Aguardando Atendimento')
									])),
								soundInit
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								A2($author$project$App$Page$Doctor$PageWaitingLine$viewWaitingLine, st, model))
							]))
					])),
				A3($author$project$App$Page$Doctor$PageWaitingLine$viewDoctors, model, st, model.fl)
			]);
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$BackToFinished = {$: 1};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewDocumentContent = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewCPTContent = F3(
	function (mDepIdx, fullStmt, now) {
		var cptDoc = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Document$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					function (_v1) {
						var i = _v1.a;
						var d = _v1.b;
						return d;
					},
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var d = _v0.b;
							return _Utils_eq(d.jJ, fullStmt.g.e.as) && (d.b7 === 5);
						},
						$elm$core$Dict$toList(fullStmt.an)))));
		return $author$project$App$Data$Id$uuidIsValid(cptDoc.as) ? $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewDocumentContent(cptDoc) : A3($author$project$App$Legacy$Doctor$ResultLayout$Common$viewCPTLayout, mDepIdx, fullStmt, now);
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewDoc = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewPersonICD = F4(
	function (now, mDepIdx, stmt, sharedState) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(stmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					A3(
						$elm$core$Dict$foldr,
						F3(
							function (cat, icd, html) {
								return A2(
									$elm$core$List$append,
									html,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('chip'),
													$elm$html$Html$Attributes$title(icd.am)
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(cat)
														]))
												]))
										]));
							}),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Data$Person$fullPersonName(stmt.dw))
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(
											A2($author$project$App$Data$Person$ageOnDate, now, stmt.dw)) + ' anos')
									])),
								sharedState.ia.h8.cJ.gX ? A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											if (mDepIdx.$ === 1) {
												var i = mDepIdx.a;
												return 'Dependente ' + $elm$core$String$fromInt(i + 1);
											} else {
												return _Utils_eq(stmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
											}
										}())
									])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
							]),
						A3(
							$elm$core$Dict$foldr,
							F3(
								function (_v0, qCatDict, catDict) {
									return A3(
										$elm$core$Dict$foldl,
										F3(
											function (cat, icd, catDictInner) {
												return A3($elm$core$Dict$insert, cat, icd, catDictInner);
											}),
										catDict,
										qCatDict);
								}),
							$elm$core$Dict$empty,
							stmt.fx)))
				]));
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewICDSection = F2(
	function (sharedState, model) {
		var bearerPersonIcd = (sharedState.ia.h8.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A4($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewPersonICD, model.ey, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g, sharedState)
			]);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tabs ')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Dados por Pessoa')
									])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('material-icons')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('print')
													]))
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('person-icds')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_Nil,
								A3(
									$elm$core$Dict$foldl,
									F3(
										function (i, dep, html) {
											return A2(
												$elm$core$List$append,
												html,
												_List_fromArray(
													[
														A4(
														$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewPersonICD,
														model.ey,
														$author$project$App$Data$Statement$Dependent(i),
														dep,
														sharedState)
													]));
										}),
									bearerPersonIcd,
									sharedState.ia.h8.fh))
							]))
					]))
			]);
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewMedicalDocumentContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewMedicalDocumentLayout(fullStmt);
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId = F3(
	function (depIdx, model, tId) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Answer$emptyModel,
			A2(
				$elm$core$Dict$get,
				tId,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Dict$empty,
					A2(
						$elm$core$Dict$get,
						$author$project$App$Data$Statement$depIndexToInt(depIdx),
						model.aZ))));
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewICD = F4(
	function (ansTemp, catCode, mIdx, icd) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('chip'),
					$elm$html$Html$Attributes$title(icd.am)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(icd.a0)
						]))
				]));
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewICDs = F3(
	function (icds, ansTemp, mIdx) {
		return A3(
			$elm$core$Dict$foldr,
			F3(
				function (catCode, icd, result) {
					return A2(
						$elm$core$List$append,
						result,
						_List_fromArray(
							[
								A4($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewICD, ansTemp, catCode, mIdx, icd)
							]));
				}),
			_List_Nil,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Dict$empty,
				A2($elm$core$Dict$get, ansTemp.as, icds)));
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewBMI = F4(
	function (sharedState, model, depIdx, stmt) {
		var weightTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 10) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.jM);
		var personQuestion = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestion$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (q) {
						return $author$project$App$Data$Id$uuidIsValid(q.hG.as);
					},
					A2(
						$elm$core$List$map,
						function (_v0) {
							var s = _v0.b;
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (q) {
											return _Utils_eq(q.hG.as, weightTemplate.bh);
										},
										s.eI)));
						},
						$elm$core$Dict$toList(model.cA)))));
		var heightTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 20) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.jM);
		var bmiTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 1000) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.gH);
		var doctorQuestion = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestion$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (q) {
						return $author$project$App$Data$Id$uuidIsValid(q.hG.as);
					},
					A2(
						$elm$core$List$map,
						function (s) {
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (q) {
											return _Utils_eq(q.hG.as, bmiTemplate.bh);
										},
										s.eI)));
						},
						model.P.gH))));
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no DPS')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatCompanyBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewICDs, stmt.fx, weightTemplate, depIdx))
							]))
					])),
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no Questionário')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatPersonBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewICDs, stmt.fx, heightTemplate, depIdx))
							]))
					])),
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no Atendimento')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatDoctorBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewICDs, stmt.fx, bmiTemplate, depIdx))
							]))
					]))
			]);
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$maskDecimal = F2(
	function (precision, value) {
		return A3($elm$core$String$replace, '.', ',', value);
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$maskHeight = function (cm) {
	var len = $elm$core$String$length(cm);
	var subM = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cm)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cm) : cm);
	var h = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(cm));
	var m = (h >= 100) ? A3($elm$core$String$slice, 0, -2, cm) : '0';
	return (h > 0) ? (m + (',' + subM)) : '';
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$maskPressure = function (str) {
	return function (p) {
		return (p > 0) ? $elm$core$String$fromInt(p) : '';
	}(
		function (p) {
			return (p > 999) ? (p / 10) : (p / 1.0);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(str))) | 0);
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$maskWeight = function (wStr) {
	return function (w) {
		return (w > 0) ? $elm$core$String$fromInt(w) : '';
	}(
		function (w) {
			return w / 1000;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(wStr))) | 0);
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$setupMask = F2(
	function (template, answer) {
		var _v0 = template.jj;
		if (_v0.$ === 1) {
			return answer.T;
		} else {
			switch (_v0.a.$) {
				case 3:
					var precision = _v0.a.a;
					return A2($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$maskDecimal, precision, answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 0:
					var _v1 = _v0.a;
					return $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$maskWeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 1:
					var _v2 = _v0.a;
					return $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$maskHeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				default:
					var _v3 = _v0.a;
					return $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$maskPressure(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
			}
		}
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewQuestion = F5(
	function (depIdx, question, sharedState, model, doctorQuestion) {
		var stmt = function () {
			if (!depIdx.$) {
				return sharedState.ia.h8.g;
			} else {
				var idx = depIdx.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, sharedState.ia.h8.fh));
			}
		}();
		var showCids = !A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		return A2(
			$elm$core$List$map,
			function (t) {
				var answer = A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
				var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
				return (show || (t.ay >= 1000)) ? A2(
					$elm$html$Html$tr,
					_List_Nil,
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(question.hG.bP)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(t.jh)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$setupMask, t, answer))
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(answer.jx)
									]))
							]),
						showCids ? _List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('chips chips-initial input-field')
											]),
										A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewICDs, stmt.fx, t, depIdx))
									]))
							]) : _List_Nil)) : A2($elm$html$Html$span, _List_Nil, _List_Nil);
			},
			A2(
				$elm$core$List$filter,
				function (t) {
					var answer = A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
					var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
					return show;
				},
				question.bY));
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithHeader = F5(
	function (depIdx, sharedState, model, fullQuestionSection, doctorQuestionSection) {
		var showCids = !A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var sectionName = function () {
			if (sharedState.ia.h8.b4.iR === 'Extramed') {
				var _v0 = fullQuestionSection.hX.a0;
				switch (_v0) {
					case 2:
						return 'Questionário';
					case 1:
						return 'Declaração';
					case 3:
						return 'Atendimento';
					default:
						return 'Desconhecido';
				}
			} else {
				return A2($elm$core$String$left, 50, fullQuestionSection.hX.hl) + '...';
			}
		}();
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '90%')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$colgroup,
					_List_Nil,
					_List_fromArray(
						[
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil)
						])),
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(sectionName)
											])),
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$colspan(2)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Resposta')
											]))
									]),
								showCids ? _List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Obs')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('CIDs')
											]))
									]) : _List_Nil))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, q) {
									return A5(
										$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewQuestion,
										depIdx,
										q,
										sharedState,
										model,
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$FullQuestion$emptyModel,
											$elm$core$List$head(
												A2($elm$core$List$drop, i, doctorQuestionSection.eI))));
								}),
							A2(
								$elm$core$List$sortBy,
								function (sec) {
									return sec.hG.bB;
								},
								A2(
									$elm$core$List$filter,
									function (q) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (t) {
													var answer = A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
													var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
													return show;
												},
												q.bY)) > 0;
									},
									fullQuestionSection.eI)))))
				]));
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithoutHeader = F5(
	function (depIdx, sharedState, model, fullQuestionSection, doctorQuestionSection) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$tbody,
				_List_Nil,
				$elm$core$List$concat(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, q) {
								return A5(
									$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewQuestion,
									depIdx,
									q,
									sharedState,
									model,
									A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$FullQuestion$emptyModel,
										$elm$core$List$head(
											A2($elm$core$List$drop, i, doctorQuestionSection.eI))));
							}),
						A2(
							$elm$core$List$sortBy,
							function (sec) {
								return sec.hG.bB;
							},
							A2(
								$elm$core$List$filter,
								function (q) {
									return $elm$core$List$length(
										A2(
											$elm$core$List$filter,
											function (t) {
												var answer = A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
												var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
												return show;
											},
											q.bY)) > 0;
								},
								fullQuestionSection.eI)))))
			]);
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewPersonDetails = F3(
	function (sharedState, depIdx, editStmt) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(editStmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$fullPersonName(editStmt.dw))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$formatDocument(editStmt.dw.i1))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Tools$FormHelpers$dateFormat(editStmt.dw.al))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									function (str) {
										return str + ' anos';
									}(
										$elm$core$String$fromInt(
											A2(
												$author$project$App$Data$Person$ageOnDate,
												$author$project$App$Service$ApiWebSocket$getTimestamp(sharedState._),
												editStmt.dw))))
								])),
							sharedState.ia.h8.cJ.gX ? A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									function () {
										if (depIdx.$ === 1) {
											var i = depIdx.a;
											return 'Dependente ' + $elm$core$String$fromInt(i + 1);
										} else {
											return _Utils_eq(editStmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
										}
									}())
								])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
						]))
				]));
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewPersonAnswers = F4(
	function (sharedState, model, depIdx, stmt) {
		var showPerson = sharedState.ia.h8.b4.iR === 'Extramed';
		var showDoctorQuestions = (sharedState.ia.h8.b4.iR === 'Extramed') || (sharedState.ia.h8.b4.iR === 'Centauro-ON');
		var showBMI = sharedState.ia.h8.b4.iR === 'Extramed';
		var doctorSection = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestionSection$emptyModel,
			$elm$core$List$head(
				$elm$core$List$reverse(sharedState.ia.h8.gH)));
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$ul,
							_List_Nil,
							_List_fromArray(
								[
									A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewPersonDetails, sharedState, depIdx, stmt)
								]))
						])),
					showBMI ? A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '100%')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Dados Antropométricos')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Valor')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('CIDs')
												]))
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							A4($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewBMI, sharedState, model, depIdx, stmt))
						])) : $elm$html$Html$text('')
				]),
			_Utils_ap(
				showBMI ? A2(
					$elm$core$List$cons,
					A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '100%')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$colgroup,
								_List_Nil,
								_List_fromArray(
									[
										A2($elm$html$Html$col, _List_Nil, _List_Nil),
										A2($elm$html$Html$col, _List_Nil, _List_Nil),
										A2($elm$html$Html$col, _List_Nil, _List_Nil),
										A2($elm$html$Html$col, _List_Nil, _List_Nil),
										A2($elm$html$Html$col, _List_Nil, _List_Nil)
									])),
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$colspan(5)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Declaração')
													]))
											]))
									]))
							])),
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, s) {
									return A5(
										$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithoutHeader,
										depIdx,
										sharedState,
										model,
										s,
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$FullQuestionSection$emptyModel,
											$elm$core$List$head(
												A2($elm$core$List$drop, i, sharedState.ia.h8.bs))));
								}),
							A2(
								$elm$core$List$filter,
								function (s) {
									return $elm$core$List$length(
										A2(
											$elm$core$List$filter,
											function (q) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (t) {
															var answer = A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
															var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
															return show;
														},
														q.bY)) > 0;
											},
											A2(
												$elm$core$List$sortBy,
												function (sec) {
													return sec.hG.bB;
												},
												s.eI))) > 0;
								},
								sharedState.ia.h8.bs)))) : A2(
					$elm$core$List$indexedMap,
					F2(
						function (i, s) {
							return A5(
								$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithHeader,
								depIdx,
								sharedState,
								model,
								s,
								A2(
									$elm$core$Maybe$withDefault,
									$author$project$App$Data$FullQuestionSection$emptyModel,
									$elm$core$List$head(
										A2($elm$core$List$drop, i, sharedState.ia.h8.bs))));
						}),
					A2(
						$elm$core$List$filter,
						function (s) {
							return $elm$core$List$length(
								A2(
									$elm$core$List$filter,
									function (q) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (t) {
													var answer = A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
													var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
													return show;
												},
												q.bY)) > 0;
									},
									A2(
										$elm$core$List$sortBy,
										function (sec) {
											return sec.hG.bB;
										},
										s.eI))) > 0;
						},
						sharedState.ia.h8.bs)),
				_Utils_ap(
					showBMI ? A2(
						$elm$core$List$cons,
						A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '100%')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$colgroup,
									_List_Nil,
									_List_fromArray(
										[
											A2($elm$html$Html$col, _List_Nil, _List_Nil),
											A2($elm$html$Html$col, _List_Nil, _List_Nil),
											A2($elm$html$Html$col, _List_Nil, _List_Nil),
											A2($elm$html$Html$col, _List_Nil, _List_Nil),
											A2($elm$html$Html$col, _List_Nil, _List_Nil)
										])),
									A2(
									$elm$html$Html$thead,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$colspan(5)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Questionário')
														]))
												]))
										]))
								])),
						$elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								F2(
									function (i, s) {
										return A5(
											$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithoutHeader,
											depIdx,
											sharedState,
											model,
											s,
											A2(
												$elm$core$Maybe$withDefault,
												$author$project$App$Data$FullQuestionSection$emptyModel,
												$elm$core$List$head(
													A2($elm$core$List$drop, i, sharedState.ia.h8.jM))));
									}),
								A2(
									$elm$core$List$filter,
									function (s) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (q) {
													return $elm$core$List$length(
														A2(
															$elm$core$List$filter,
															function (t) {
																var answer = A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
																var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
																return show;
															},
															q.bY)) > 0;
												},
												A2(
													$elm$core$List$sortBy,
													function (sec) {
														return sec.hG.bB;
													},
													s.eI))) > 0;
									},
									sharedState.ia.h8.jM)))) : (showPerson ? A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, s) {
								return A5(
									$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithHeader,
									depIdx,
									sharedState,
									model,
									s,
									A2(
										$elm$core$Maybe$withDefault,
										$author$project$App$Data$FullQuestionSection$emptyModel,
										$elm$core$List$head(
											A2($elm$core$List$drop, i, sharedState.ia.h8.jM))));
							}),
						A2(
							$elm$core$List$filter,
							function (s) {
								return $elm$core$List$length(
									A2(
										$elm$core$List$filter,
										function (q) {
											return $elm$core$List$length(
												A2(
													$elm$core$List$filter,
													function (t) {
														var answer = A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
														var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
														return show;
													},
													q.bY)) > 0;
										},
										A2(
											$elm$core$List$sortBy,
											function (sec) {
												return sec.hG.bB;
											},
											s.eI))) > 0;
							},
							sharedState.ia.h8.jM)) : _List_Nil),
					function () {
						if (showDoctorQuestions) {
							return showBMI ? A2(
								$elm$core$List$cons,
								A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'width', '100%')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$colgroup,
											_List_Nil,
											_List_fromArray(
												[
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil)
												])),
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$tr,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$th,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$colspan(5)
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Atendimento')
																]))
														]))
												]))
										])),
								$elm$core$List$concat(
									A2(
										$elm$core$List$map,
										function (s) {
											return A5($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithoutHeader, depIdx, sharedState, model, s, s);
										},
										A2(
											$elm$core$List$filter,
											function (s) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (q) {
															return ($elm$core$List$length(
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var answer = A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
																		var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																		return show;
																	},
																	q.bY)) > 0) && (!A2(
																$elm$core$List$any,
																function (t) {
																	return (t.ay === 10) || (t.ay === 20);
																},
																q.bY));
														},
														A2(
															$elm$core$List$sortBy,
															function (sec) {
																return sec.hG.bB;
															},
															s.eI))) > 0;
											},
											sharedState.ia.h8.gH)))) : A2(
								$elm$core$List$map,
								function (s) {
									return A5($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewEditStatementSectionWithHeader, depIdx, sharedState, model, s, s);
								},
								A2(
									$elm$core$List$filter,
									function (s) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (q) {
													return $elm$core$List$length(
														A2(
															$elm$core$List$filter,
															function (t) {
																var answer = A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, t.as);
																var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																return show;
															},
															q.bY)) > 0;
												},
												A2(
													$elm$core$List$sortBy,
													function (sec) {
														return sec.hG.bB;
													},
													s.eI))) > 0;
									},
									sharedState.ia.h8.gH));
						} else {
							var question = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(doctorSection.eI));
							var template = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$AnswerTemplate$emptyModel,
								$elm$core$List$head(question.bY));
							var answer = A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$getAnswerForTemplateId, depIdx, model, template.as);
							return (answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0))) ? _List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Evoluções médicas:')
										])),
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(answer.T)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Atestado Médico:')
										])),
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(answer.jx)
										]),
									_List_Nil)
								]) : _List_Nil;
						}
					}())));
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewQuestions = F2(
	function (model, sharedState) {
		var depsPersonAnswers = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$second,
				$elm$core$Dict$toList(
					A2(
						$elm$core$Dict$map,
						F2(
							function (idx, dep) {
								return A4(
									$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewPersonAnswers,
									sharedState,
									model,
									$author$project$App$Data$Statement$depIndexFromInt(idx),
									dep);
							}),
						sharedState.ia.h8.fh))));
		var bearerPersonAnswers = (!(sharedState.ia.h8.g.e.j_ === 2)) ? A4($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewPersonAnswers, sharedState, model, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g) : _List_Nil;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('questions')
				]),
			_Utils_ap(
				bearerPersonAnswers,
				_Utils_ap(
					depsPersonAnswers,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Conclusões finais do atendimento:')
								])),
							A2(
							$elm$html$Html$textarea,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(model.P.g.e.jx)
								]),
							_List_Nil)
						]))));
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$SelectDetailsTab = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabCPT = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabDocument = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabMedicalDocument = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabResult = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewQuestionsTabs = F5(
	function (fullStmt, viewCPT, typeHnsg, currentTab, docs) {
		var previewTitle = typeHnsg ? 'Pré-Visualizar Prontuário' : 'Pré-Visualizar Parecer';
		var docsTabs = A3(
			$elm$core$Dict$foldl,
			F3(
				function (i, d, list) {
					var attachName = function () {
						var _v6 = d.dr;
						if (_v6 === 'video/mp4') {
							return 'Video';
						} else {
							return (d.b7 === 4) ? 'Receita' : ('Anexo ' + $elm$core$String$fromInt(i));
						}
					}();
					return A2(
						$elm$core$List$append,
						list,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabDocument(i)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$SelectDetailsTab(
											$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabDocument(i))),
										$elm$html$Html$Attributes$href('#')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(attachName)
									]))
							]));
				}),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'tab-selected',
									_Utils_eq(currentTab, $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabCIDs))
								])),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$SelectDetailsTab($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabCIDs))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Dados do Atendimento')
						]))
				]),
			A2(
				$elm$core$Dict$filter,
				F2(
					function (_v5, d) {
						return d.b7 !== 5;
					}),
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v4, d) {
							return d.b7 !== 3;
						}),
					A2(
						$elm$core$Dict$filter,
						F2(
							function (_v3, d) {
								return d.b7 !== 1;
							}),
						$elm$core$Dict$fromList(
							_Utils_ap(
								A2(
									$elm$core$List$take,
									1,
									$elm$core$List$reverse(
										$elm$core$Dict$toList(
											A2(
												$elm$core$Dict$filter,
												F2(
													function (_v1, doc) {
														return doc.dr === 'video/mp4';
													}),
												docs)))),
								$elm$core$Dict$toList(
									A2(
										$elm$core$Dict$filter,
										F2(
											function (_v2, doc) {
												return (doc.dr !== 'video/webm') && (doc.dr !== 'video/mp4');
											}),
										docs))))))));
		var bearerCpt = (fullStmt.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$SelectDetailsTab(
							$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('CPT Titular')
					]))
			]);
		var cptTabs = viewCPT ? A2(
			$elm$core$List$append,
			bearerCpt,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var idx = _v0.a;
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('#'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'tab-selected',
										_Utils_eq(
											currentTab,
											$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabCPT(
												$author$project$App$Data$Statement$Dependent(idx))))
									])),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$SelectDetailsTab(
									$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabCPT(
										$author$project$App$Data$Statement$Dependent(idx))))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'CPT Dep ' + $elm$core$String$fromInt(idx + 1))
							]));
				},
				$elm$core$Dict$toList(fullStmt.fh))) : _List_Nil;
		var allTabs = _Utils_ap(
			docsTabs,
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('#'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'tab-selected',
										_Utils_eq(
											currentTab,
											$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabResult($author$project$App$Data$Statement$Bearer)))
									])),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$SelectDetailsTab(
									$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabResult($author$project$App$Data$Statement$Bearer)))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(previewTitle)
							])),
						typeHnsg ? A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('#'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'tab-selected',
										_Utils_eq(
											currentTab,
											$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
									])),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$SelectDetailsTab(
									$author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Pré-Visualizar Atestado')
							])) : A2($elm$html$Html$span, _List_Nil, _List_Nil)
					]),
				cptTabs));
		return A2($elm$html$Html$div, _List_Nil, allTabs);
	});
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewResultContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewResultLayout(fullStmt);
};
var $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$view = F3(
	function (now, sharedState, model) {
		var viewCPT = sharedState.ia.h8.b4.iR === 'Extramed';
		var typeHnsg = A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var maybePdfResult = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var maybePdfDocument = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var hasMedicalReport = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var hasMedicalDocument = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$header,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('tabs')
								]),
							_List_fromArray(
								[
									A5($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewQuestionsTabs, sharedState.ia.h8, viewCPT, typeHnsg, model.b5, sharedState.ia.h8.an),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('cancel-button'),
													$elm$html$Html$Events$onClick($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$BackToFinished)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Retornar')
												]))
										]))
								])),
							function () {
							var _v0 = model.b5;
							switch (_v0.$) {
								case 0:
									return A2($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewQuestions, model, sharedState);
								case 1:
									var docIdx = _v0.a;
									return $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewDoc(
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$Document$emptyModel,
											A2($elm$core$Dict$get, docIdx, model.an)));
								case 2:
									var mDepIdx = _v0.a;
									if (!maybePdfResult.$) {
										var pdfResult = maybePdfResult.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewResultContent(sharedState.ia.h8);
									}
								case 3:
									var mDepIdx = _v0.a;
									if (!maybePdfDocument.$) {
										var pdfResult = maybePdfDocument.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewMedicalDocumentContent(model.P);
									}
								case 5:
									var videoRepo = {f3: 'video-repo-enterprise-covid.ingress.medsyn.com.br'};
									var videoUrl = A2($author$project$App$Data$VideoRepository$statementVideoUrl, videoRepo, sharedState.ia.h8.g.e.as);
									return A2(
										$elm$html$Html$video,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('video-preview'),
												$elm$html$Html$Attributes$controls(true)
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$source,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$src(videoUrl),
														$elm$html$Html$Attributes$type_('video/mp4')
													]),
												_List_Nil),
												$elm$html$Html$text('Seu browser não suporte exibição de vídeo HTML5')
											]));
								case 4:
									var depIdx = _v0.a;
									var fullStmt = sharedState.ia.h8;
									return A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewCPTContent, depIdx, fullStmt, now);
								default:
									return $elm$html$Html$text('');
							}
						}()
						]))
				]),
			A2($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$viewICDSection, sharedState, model));
	});
var $author$project$App$Page$PageDoctor$view = F3(
	function (now, sharedState, model) {
		var _v0 = model.aQ;
		switch (_v0.$) {
			case 0:
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageDoctor$LoadingMsg),
					$author$project$App$Page$Doctor$PageLoading$view);
			case 1:
				var pageModel = _v0.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageDoctor$WaitingLineMsg),
					A2($author$project$App$Page$Doctor$PageWaitingLine$view, sharedState, pageModel));
			case 2:
				var pageModel = _v0.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageDoctor$ReviewMsg),
					A2($author$project$App$Page$Doctor$PageReview$view, sharedState, pageModel));
			case 3:
				var pageModel = _v0.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageDoctor$InterviewMsg),
					A2($author$project$App$Page$Doctor$PageInterview$view, sharedState, pageModel));
			case 4:
				var pageModel = _v0.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageDoctor$FinalEvaluationMsg),
					A3($author$project$App$Page$Doctor$PageFinalEvaluation$view, now, sharedState, pageModel));
			case 5:
				var pageModel = _v0.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageDoctor$ViewEvaluationMsg),
					A3($author$project$App$Page$Doctor$ViewEvaluation$PageViewEvaluation$view, now, sharedState, pageModel));
			case 6:
				var pageModel = _v0.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageDoctor$ListPendingMsg),
					A2($author$project$App$Page$Doctor$PageListPending$view, sharedState, pageModel));
			case 7:
				var pageModel = _v0.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageDoctor$ListFinishedMsg),
					A2($author$project$App$Page$Doctor$PageListFinished$view, sharedState, pageModel));
			case 10:
				var pageModel = _v0.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageDoctor$DoctorSalesMsg),
					$author$project$App$Page$Doctor$PageDoctorSales$view(pageModel));
			case 8:
				var pageModel = _v0.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageDoctor$ListReevaluationMsg),
					A2($author$project$App$Page$Doctor$PageListReevaluation$view, sharedState, pageModel));
			default:
				var pageModel = _v0.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageDoctor$ReevaluationMsg),
					A3($author$project$App$Page$Doctor$PageReevaluation$view, now, sharedState, pageModel));
		}
	});
var $author$project$App$Data$DigitalAccount$FieldACity = function (a) {
	return {$: 17, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldANeighborhood = function (a) {
	return {$: 16, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldANumber = function (a) {
	return {$: 14, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldAPostCode = function (a) {
	return {$: 19, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldAState = function (a) {
	return {$: 18, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldAStreet = function (a) {
	return {$: 13, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldBAAccountNumber = function (a) {
	return {$: 21, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldBAAccountType = function (a) {
	return {$: 23, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldBAAgencyNumber = function (a) {
	return {$: 22, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldBABankNumber = function (a) {
	return {$: 20, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldBirthDate = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldCnae = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldCommercialName = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldDocument = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Data$User$FieldEmail = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldLRBirthdate = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldLRDocument = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldLRMotherName = function (a) {
	return {$: 12, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldLRName = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldLRSex = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldLRSurname = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldLicense = function (a) {
	return {$: 24, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldLicenseState = function (a) {
	return {$: 25, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldMemedCity = function (a) {
	return {$: 26, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldMemedSpecialty = function (a) {
	return {$: 27, a: a};
};
var $author$project$App$Data$DigitalAccount$FieldOfficialName = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Data$User$FieldPassword = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Data$User$FieldPasswordCheck = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$PageDoctorSignUp$GoToLogin = {$: 0};
var $author$project$App$Page$PageDoctorSignUp$SignUpDoctor = {$: 5};
var $author$project$App$Page$PageDoctorSignUp$TakeSelfieDoctor = {$: 6};
var $author$project$App$Page$PageDoctorSignUp$UpdateFormMode = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$PageDoctorSignUp$UpdateJunoField = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$PageDoctorSignUp$UpdatePhoneField = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$PageDoctorSignUp$UpdateUserField = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$PageDoctorSignUp$VideoMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Mask$cnpj = function (c) {
	return A2($author$project$Mask$number, '##.###.###/####-##', c);
};
var $author$project$App$Data$Contact$isInvalidFieldMobile = function (mobile) {
	return $elm$core$String$length(mobile) < 12;
};
var $author$project$App$Data$Doctor$isInvalidFieldSelect = function (str) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(str)) <= 0;
};
var $author$project$App$Page$PageDoctorSignUp$loadingView = function (status) {
	switch (status.$) {
		case 0:
			return $elm$html$Html$text('');
		case 1:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-message')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Existem campos inválidos')
					]));
		case 2:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('loading')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Enviando dados...')
					]));
		default:
			var error = status.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-message')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(error)
					]));
	}
};
var $author$project$App$Page$PageDoctorSignUp$maskStringHelper = F3(
	function (pattern, value, result) {
		maskStringHelper:
		while (true) {
			var valueUncons = $elm$core$String$uncons(value);
			if (!valueUncons.$) {
				var _v1 = valueUncons.a;
				var charValue = _v1.a;
				var rest = _v1.b;
				var _v2 = $elm$core$String$uncons(pattern);
				if (!_v2.$) {
					var _v3 = _v2.a;
					var a = _v3.a;
					var b = _v3.b;
					if (a === '#') {
						var $temp$pattern = b,
							$temp$value = rest,
							$temp$result = _Utils_ap(
							result,
							$elm$core$String$fromChar(charValue));
						pattern = $temp$pattern;
						value = $temp$value;
						result = $temp$result;
						continue maskStringHelper;
					} else {
						var $temp$pattern = b,
							$temp$value = value,
							$temp$result = _Utils_ap(
							result,
							$elm$core$String$fromChar(a));
						pattern = $temp$pattern;
						value = $temp$value;
						result = $temp$result;
						continue maskStringHelper;
					}
				} else {
					return result;
				}
			} else {
				return result;
			}
		}
	});
var $author$project$App$Page$PageDoctorSignUp$number = F2(
	function (pattern, value) {
		return function (v) {
			return A3($author$project$App$Page$PageDoctorSignUp$maskStringHelper, pattern, v, '');
		}(
			A2($elm$core$String$filter, $elm$core$Char$isDigit, value));
	});
var $author$project$App$Page$PageDoctorSignUp$maskCenae = function (c) {
	return A2($author$project$App$Page$PageDoctorSignUp$number, '####-#/##', c);
};
var $author$project$App$Tools$FormHelpers$selectEnableBillingAccountRegistration = F3(
	function (attrs, enable, msg) {
		return A4(
			$author$project$App$Tools$FormHelpers$select,
			attrs,
			$elm$core$String$fromInt(enable),
			msg,
			_List_fromArray(
				[
					_Utils_Tuple2('0', 'Não'),
					_Utils_Tuple2('1', 'Sim')
				]));
	});
var $author$project$App$Page$PageDoctorSignUp$showClassError = F2(
	function (status, isInvalid) {
		return $elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'error-message',
					_Utils_eq(status, $author$project$App$Page$PageDoctorSignUp$InvalidField) && isInvalid)
				]));
	});
var $author$project$App$Tools$FormHelpers$stringDateInputWithAttrs = F3(
	function (attrs, date, msg) {
		return $elm$core$String$isEmpty(date) ? A2(
			$elm$html$Html$input,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('date'),
						$elm$html$Html$Events$onInput(msg)
					]),
				attrs),
			_List_Nil) : A2(
			$elm$html$Html$input,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('date'),
						$elm$html$Html$Events$onInput(msg),
						$elm$html$Html$Attributes$value(date)
					]),
				attrs),
			_List_Nil);
	});
var $elm$html$Html$canvas = _VirtualDom_node('canvas');
var $author$project$App$Components$Video$getVideoDevices = function (device) {
	return A2(
		$elm$core$List$filter,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.hb;
			},
			$elm$core$Basics$eq('videoinput')),
		device.a9);
};
var $author$project$App$Components$Video$otherCamera = F2(
	function (current, device) {
		return $author$project$App$Components$Video$isDeviceMobile(device) ? ((current === 'user') ? 'environment' : 'user') : A2(
			$elm$core$Maybe$withDefault,
			current,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.c1;
				},
				_Utils_eq(
					$elm$core$Maybe$Just(current),
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.c1;
						},
						$elm$core$List$head(device.a9))) ? $elm$core$List$head(
					$elm$core$List$reverse(device.a9)) : $elm$core$List$head(device.a9)));
	});
var $author$project$App$Components$Video$viewLocalVideo = A2(
	$elm$html$Html$video,
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$attribute, 'autoplay', ''),
			A2($elm$html$Html$Attributes$attribute, 'muted', ''),
			A2($elm$html$Html$Attributes$attribute, 'playsinline', ''),
			A2($elm$html$Html$Attributes$attribute, 'webkit-playsinline', ''),
			$elm$html$Html$Attributes$id('localVideo'),
			$elm$html$Html$Attributes$class('local-conference')
		]),
	_List_Nil);
var $author$project$App$Components$Video$getMediaStatus = function (model) {
	var _v0 = model.p;
	if (_v0.$ === 2) {
		var mRemote = _v0.b;
		if (!mRemote.$) {
			var remote = mRemote.a;
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				A2($elm$core$Dict$get, remote, model.dq));
		} else {
			return 0;
		}
	} else {
		return 0;
	}
};
var $author$project$App$Components$Video$viewMessage = function (model) {
	var setMsg = function (msg) {
		return A2(
			$elm$html$Html$span,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(msg)
				]));
	};
	if (model.jl !== '') {
		return setMsg(model.jl);
	} else {
		var _v0 = $author$project$App$Components$Video$getMediaStatus(model);
		switch (_v0) {
			case 0:
				return setMsg('');
			case 1:
				return setMsg('Detectado video remoto...');
			case 2:
				return setMsg('Validando a conexão...');
			case 3:
				return setMsg('');
			case 4:
				return setMsg('');
			case 5:
				return setMsg('Desconectado. Tentando reconectar...');
			case 6:
				return setMsg('Falha na conexão');
			default:
				return setMsg('Conexão fechada');
		}
	}
};
var $author$project$App$Components$Video$viewRemoteVideo = function (device) {
	return A2(
		$elm$html$Html$video,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'autoplay', ''),
				A2($elm$html$Html$Attributes$attribute, 'playsinline', ''),
				A2($elm$html$Html$Attributes$attribute, 'webkit-playsinline', ''),
				$elm$html$Html$Attributes$id(device)
			]),
		_List_Nil);
};
var $author$project$App$Components$Video$runningVideo = F4(
	function (_v0, device, remote, model) {
		var requestStop = _v0.fQ;
		var changeCamera = _v0.e8;
		var takeSelfie = _v0.f_;
		var discardSelfie = _v0.fj;
		var acceptSelfie = _v0.e2;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id('remoteVideos'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('selfie', model.aT)
						])),
					$elm$html$Html$Attributes$class('conference')
				]),
			_List_fromArray(
				[
					(model.bM && (!model.aT)) ? A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('cancel-button', true),
									_Utils_Tuple2('cancel-conference', true)
								])),
							$elm$html$Html$Attributes$type_('button'),
							$elm$html$Html$Events$onClick(requestStop)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Ok')
						])) : $elm$html$Html$text(''),
					((($elm$core$List$length(
					$author$project$App$Components$Video$getVideoDevices(model.bt)) > 1) && ($elm$core$String$length(device) > 0)) && (!model.aT)) ? A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('switch-video'),
							$elm$html$Html$Attributes$type_('button'),
							$elm$html$Html$Events$onClick(
							A2(
								changeCamera,
								A2($author$project$App$Components$Video$otherCamera, device, model.bt),
								remote))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons md-18')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('switch_camera')
								]))
						])) : $elm$html$Html$text(''),
					model.aT ? A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('video-selfie-limits')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$canvas,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('video-selfie-snap')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('video-selfie-face')
								]),
							_List_Nil),
							(model.bj === '') ? A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick(requestStop)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Cancelar')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick(takeSelfie)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Tirar foto')
										]))
								])) : A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Events$onClick(discardSelfie)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Descartar')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick(acceptSelfie)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Utilizar')
										]))
								]))
						])) : $elm$html$Html$text(''),
					$author$project$App$Components$Video$viewMessage(model),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('conference-error-message')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(model.fo)
						])),
					$author$project$App$Components$Video$viewLocalVideo,
					function () {
					if (!remote.$) {
						var remoteV = remote.a;
						return $author$project$App$Components$Video$viewRemoteVideo(remoteV);
					} else {
						return $elm$html$Html$text('');
					}
				}()
				]));
	});
var $author$project$App$Components$Video$viewVideoError = F2(
	function (errorTitle, viewErrorContent) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id('remoteVideos'),
					$elm$html$Html$Attributes$class('conference-error')
				]),
			_List_fromArray(
				[
					A2($elm$html$Html$span, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('conference-error-message')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(errorTitle)
						])),
					A2($elm$html$Html$div, _List_Nil, viewErrorContent)
				]));
	});
var $author$project$App$Components$Video$viewVideoBusy = function (requestStop) {
	return A2(
		$author$project$App$Components$Video$viewVideoError,
		'Ocorreu um erro',
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Não foi possível iniciar sua câmera. ' + 'Pode ser que não esteja habilitada ou está indisponível para este sistema.')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Por favor, tente novamente ou entre em contato com o suporte.')
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(requestStop)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Retornar')
					]))
			]));
};
var $author$project$App$Components$Video$viewVideoDenied = function (requestStop) {
	return A2(
		$author$project$App$Components$Video$viewVideoError,
		'Câmera bloqueada',
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Sua câmera está bloqueada.')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('É necessário que a câmera seja liberada')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Após liberar a câmera, tente novamente')
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(requestStop)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Retornar')
					]))
			]));
};
var $author$project$App$Components$Video$viewVideoErrorGeneric = $author$project$App$Components$Video$viewVideoBusy;
var $author$project$App$Components$Video$viewVideoNotSupported = function (device) {
	var _v0 = ((device.dO === 'iOS') || A2($elm$core$String$contains, 'Mac', device.dO)) ? _Utils_Tuple2('Safari', '11') : _Utils_Tuple2('Google Chrome', '23');
	var browserSupported = _v0.a;
	var versionSupported = _v0.b;
	return A2(
		$author$project$App$Components$Video$viewVideoError,
		'Navegador não suportado',
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('O navegador atual não suporta a realização de video-chamada')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Recomendamos o uso do navegador ' + (browserSupported + (' na versão ' + (versionSupported + ' ou superior'))))
					]))
			]));
};
var $author$project$App$Components$Video$videoFailed = F3(
	function (requestStop, errorName, maybeDevice) {
		return A2(
			$elm$core$List$member,
			errorName,
			_List_fromArray(
				['NotAllowedError', 'PermissionDeniedError'])) ? $author$project$App$Components$Video$viewVideoDenied(requestStop) : (A2(
			$elm$core$List$member,
			errorName,
			_List_fromArray(
				['NotReadableError', 'TrackStartError'])) ? $author$project$App$Components$Video$viewVideoBusy(requestStop) : ((errorName === 'NotSupported') ? $author$project$App$Components$Video$viewVideoNotSupported(
			A2($elm$core$Maybe$withDefault, $author$project$App$Components$Video$emptyDevice, maybeDevice)) : $author$project$App$Components$Video$viewVideoErrorGeneric(requestStop)));
	});
var $author$project$App$Components$Video$viewWithMappedMsgs = function (model) {
	var mappings = {e2: $author$project$App$Components$Video$AcceptSelfie, e8: $author$project$App$Components$Video$SwitchCamera, fj: $author$project$App$Components$Video$DiscardSelfie, fQ: $author$project$App$Components$Video$RequestStop, f_: $author$project$App$Components$Video$TakeSelfie};
	var _v0 = model.p;
	switch (_v0.$) {
		case 0:
			return $elm$html$Html$text('');
		case 1:
			return A4($author$project$App$Components$Video$runningVideo, mappings, '', $elm$core$Maybe$Nothing, model);
		case 2:
			var device = _v0.a;
			var remote = _v0.b;
			return A4($author$project$App$Components$Video$runningVideo, mappings, device, remote, model);
		case 3:
			var device = _v0.a;
			var remote = _v0.b;
			return A4($author$project$App$Components$Video$runningVideo, mappings, device, remote, model);
		case 4:
			var errorName = _v0.a;
			var maybeDevice = _v0.b;
			return A3($author$project$App$Components$Video$videoFailed, $author$project$App$Components$Video$RequestStop, errorName, maybeDevice);
		case 5:
			return A4($author$project$App$Components$Video$runningVideo, mappings, '', $elm$core$Maybe$Nothing, model);
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$Mask$zipCode = function (zip) {
	return A2($author$project$Mask$number, '#####-###', zip);
};
var $author$project$App$Page$PageDoctorSignUp$view = F2(
	function (st, _v0) {
		var doctor = _v0.bu;
		var user = _v0.bS;
		var status = _v0.aw;
		var phone = _v0.dx;
		var digitalAccount = _v0.O;
		var memedCities = _v0.es;
		var memedSpecialties = _v0.eu;
		var billingAccount = _v0.cW;
		var mSpecialties = ($elm$core$List$length(memedSpecialties) > 0) ? _Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2('', 'Selecione')
				]),
			memedSpecialties) : _List_fromArray(
			[
				_Utils_Tuple2('', 'Carregando')
			]);
		var mCities = ($elm$core$List$length(memedCities) > 0) ? _Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2('', 'Selecione')
				]),
			memedCities) : _List_fromArray(
			[
				_Utils_Tuple2('', 'Carregando')
			]);
		var _v1 = function () {
			var _v2 = doctor.dy;
			if (!_v2.$) {
				var photo = _v2.a;
				return _Utils_Tuple2(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('addphoto'),
									$elm$html$Html$Attributes$src(photo),
									$elm$html$Html$Events$onClick($author$project$App$Page$PageDoctorSignUp$TakeSelfieDoctor)
								]),
							_List_Nil)
						]),
					A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$App$Page$PageDoctorSignUp$SignUpDoctor)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Criar')
							])));
			} else {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('addphoto material-icons md-36 md-light'),
									$elm$html$Html$Events$onClick($author$project$App$Page$PageDoctorSignUp$TakeSelfieDoctor)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('add_a_photo')
								]))
						]),
					A2(
						$elm$html$Html$button,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Aguardando foto...')
							])));
			}
		}();
		var photoContent = _v1.a;
		var createButton = _v1.b;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('signup authentication selfie')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$section,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('logo'),
												$elm$html$Html$Attributes$src('/assets/images/logo_color.svg')
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Bem vindo à '),
										A2(
										$elm$html$Html$b,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Medsyn!')
											])),
										A2($elm$html$Html$br, _List_Nil, _List_Nil),
										$elm$html$Html$text('\n                    Atualmente o acesso ao Sistema é exclusivo para Médicos cadastrados.\n                    Caso tenha interesse, pode criar sua conta. Para isso entre com os dados\n                    abaixo e crie um senha de acesso. Avaliaremos sua requisição e entraremos\n                    em contato para finalizar o cadastro.\n                ')
									])),
								A2(
								$elm$html$Html$form,
								_List_Nil,
								_Utils_ap(
									_List_fromArray(
										[
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Cadastrar cnpj (Apenas se a sua empresa pedir)'),
													A3(
													$author$project$App$Tools$FormHelpers$selectEnableBillingAccountRegistration,
													_List_Nil,
													billingAccount ? 1 : 0,
													$author$project$App$Page$PageDoctorSignUp$UpdateFormMode)
												])),
											A2($elm$html$Html$br, _List_Nil, _List_Nil),
											A2($elm$html$Html$span, _List_Nil, photoContent),
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('CRM'),
													A2(
													$elm$html$Html$div,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$placeholder('Número'),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$name('license'),
																	$elm$html$Html$Events$onInput(
																	A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldLicense)),
																	$elm$html$Html$Attributes$value(digitalAccount.dm),
																	A2($elm$html$Html$Attributes$attribute, 'inputmode', 'numeric'),
																	A2(
																	$author$project$App$Page$PageDoctorSignUp$showClassError,
																	status,
																	$author$project$App$Data$DigitalAccount$isInvalidFieldLicense(digitalAccount.dm))
																]),
															_List_Nil)
														]))
												])),
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Estado'),
													A3(
													$author$project$App$Tools$FormHelpers$selectStates,
													_List_fromArray(
														[
															A2(
															$author$project$App$Page$PageDoctorSignUp$showClassError,
															status,
															$author$project$App$Data$DigitalAccount$isInvalidFieldString(digitalAccount.dn))
														]),
													digitalAccount.dn,
													A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldLicenseState))
												])),
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Cidade'),
													A4(
													$author$project$App$Tools$FormHelpers$select,
													_List_fromArray(
														[
															A2(
															$author$project$App$Page$PageDoctorSignUp$showClassError,
															status,
															$author$project$App$Data$Doctor$isInvalidFieldSelect(
																$elm$core$String$fromInt(digitalAccount.et)))
														]),
													$elm$core$String$fromInt(digitalAccount.et),
													A2(
														$elm$core$Basics$composeL,
														A2(
															$elm$core$Basics$composeL,
															A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldMemedCity),
															$elm$core$Maybe$withDefault(0)),
														$elm$core$String$toInt),
													mCities)
												])),
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Especialidade'),
													A4(
													$author$project$App$Tools$FormHelpers$select,
													_List_fromArray(
														[
															A2(
															$author$project$App$Page$PageDoctorSignUp$showClassError,
															status,
															$author$project$App$Data$Doctor$isInvalidFieldSelect(
																$elm$core$String$fromInt(digitalAccount.ev)))
														]),
													$elm$core$String$fromInt(digitalAccount.ev),
													A2(
														$elm$core$Basics$composeL,
														A2(
															$elm$core$Basics$composeL,
															A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldMemedSpecialty),
															$elm$core$Maybe$withDefault(0)),
														$elm$core$String$toInt),
													mSpecialties)
												])),
											A2($elm$html$Html$br, _List_Nil, _List_Nil)
										]),
									_Utils_ap(
										billingAccount ? _List_fromArray(
											[
												A2(
												$elm$html$Html$h2,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Dados empresariais')
													])),
												A2($elm$html$Html$br, _List_Nil, _List_Nil),
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('CNPJ'),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$placeholder(''),
																$elm$html$Html$Attributes$type_('text'),
																$elm$html$Html$Attributes$name('document'),
																$elm$html$Html$Attributes$value(
																$author$project$Mask$cnpj(digitalAccount.b4.i1)),
																$elm$html$Html$Events$onInput(
																A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldDocument)),
																A2(
																$author$project$App$Page$PageDoctorSignUp$showClassError,
																status,
																$author$project$App$Data$DigitalAccount$isInvalidFieldDocument(digitalAccount.b4.i1))
															]),
														_List_Nil)
													])),
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Razão Social'),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$placeholder(''),
																$elm$html$Html$Attributes$type_('text'),
																$elm$html$Html$Attributes$name('name'),
																$elm$html$Html$Attributes$value(digitalAccount.b4.hs),
																$elm$html$Html$Events$onInput(
																A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldOfficialName)),
																A2(
																$author$project$App$Page$PageDoctorSignUp$showClassError,
																status,
																$author$project$App$Data$DigitalAccount$isInvalidFieldString(digitalAccount.b4.hs))
															]),
														_List_Nil)
													])),
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Nome Fantasia'),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$placeholder(''),
																$elm$html$Html$Attributes$type_('text'),
																$elm$html$Html$Attributes$name('name'),
																$elm$html$Html$Attributes$value(digitalAccount.b4.iR),
																$elm$html$Html$Events$onInput(
																A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldCommercialName)),
																A2(
																$author$project$App$Page$PageDoctorSignUp$showClassError,
																status,
																$author$project$App$Data$DigitalAccount$isInvalidFieldString(digitalAccount.b4.iR))
															]),
														_List_Nil)
													])),
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Telefone'),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$placeholder(''),
																$elm$html$Html$Attributes$type_('tel'),
																$elm$html$Html$Attributes$name('phone'),
																$elm$html$Html$Attributes$value(
																$author$project$App$Data$Contact$formatMobile(phone.T)),
																A2(
																$author$project$App$Page$PageDoctorSignUp$showClassError,
																status,
																$author$project$App$Data$Contact$isInvalidFieldMobile(phone.T)),
																$elm$html$Html$Events$onInput(
																A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdatePhoneField, $author$project$App$Data$Contact$FieldValue))
															]),
														_List_Nil)
													])),
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Data de início de situação cadastral'),
														A3(
														$author$project$App$Tools$FormHelpers$stringDateInputWithAttrs,
														_List_fromArray(
															[
																A2(
																$author$project$App$Page$PageDoctorSignUp$showClassError,
																status,
																$author$project$App$Data$DigitalAccount$isInvalidFieldString(digitalAccount.b4.al))
															]),
														digitalAccount.b4.al,
														A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldBirthDate))
													])),
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Cnae'),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$placeholder(''),
																$elm$html$Html$Attributes$type_('text'),
																$elm$html$Html$Attributes$name('name'),
																$elm$html$Html$Attributes$value(
																$author$project$App$Page$PageDoctorSignUp$maskCenae(digitalAccount.b4.b2)),
																$elm$html$Html$Events$onInput(
																A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldCnae)),
																A2(
																$author$project$App$Page$PageDoctorSignUp$showClassError,
																status,
																$author$project$App$Data$DigitalAccount$isInvalidFieldCnae(digitalAccount.b4.b2))
															]),
														_List_Nil)
													])),
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Rua'),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$placeholder(''),
																$elm$html$Html$Attributes$type_('text'),
																$elm$html$Html$Attributes$name('address'),
																$elm$html$Html$Attributes$value(digitalAccount.b4.cS),
																$elm$html$Html$Events$onInput(
																A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldAStreet)),
																A2(
																$author$project$App$Page$PageDoctorSignUp$showClassError,
																status,
																$author$project$App$Data$DigitalAccount$isInvalidFieldString(digitalAccount.b4.cS))
															]),
														_List_Nil)
													])),
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Numero'),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$placeholder(''),
																$elm$html$Html$Attributes$type_('text'),
																$elm$html$Html$Attributes$name('number'),
																$elm$html$Html$Attributes$value(digitalAccount.b4.bb),
																$elm$html$Html$Events$onInput(
																A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldANumber)),
																A2(
																$author$project$App$Page$PageDoctorSignUp$showClassError,
																status,
																$author$project$App$Data$DigitalAccount$isInvalidFieldAddressNumber(digitalAccount.b4.bb))
															]),
														_List_Nil)
													])),
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Bairro'),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$placeholder(''),
																$elm$html$Html$Attributes$type_('text'),
																$elm$html$Html$Attributes$name('district'),
																$elm$html$Html$Attributes$value(digitalAccount.b4.ef),
																$elm$html$Html$Events$onInput(
																A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldANeighborhood))
															]),
														_List_Nil)
													])),
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Cidade'),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$placeholder(''),
																$elm$html$Html$Attributes$type_('text'),
																$elm$html$Html$Attributes$name('city'),
																$elm$html$Html$Attributes$value(digitalAccount.b4.bp),
																$elm$html$Html$Events$onInput(
																A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldACity)),
																A2(
																$author$project$App$Page$PageDoctorSignUp$showClassError,
																status,
																$author$project$App$Data$DigitalAccount$isInvalidFieldString(digitalAccount.b4.bp))
															]),
														_List_Nil)
													])),
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Estado'),
														A3(
														$author$project$App$Tools$FormHelpers$selectStates,
														_List_fromArray(
															[
																A2(
																$author$project$App$Page$PageDoctorSignUp$showClassError,
																status,
																$author$project$App$Data$DigitalAccount$isInvalidFieldString(digitalAccount.b4.p))
															]),
														digitalAccount.b4.p,
														A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldAState))
													])),
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('CEP'),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$placeholder(''),
																$elm$html$Html$Attributes$type_('text'),
																$elm$html$Html$Attributes$name('postalcode'),
																$elm$html$Html$Attributes$value(
																$author$project$Mask$zipCode(digitalAccount.b4.cw)),
																$elm$html$Html$Events$onInput(
																A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldAPostCode)),
																A2(
																$author$project$App$Page$PageDoctorSignUp$showClassError,
																status,
																$author$project$App$Data$DigitalAccount$isInvalidFieldString(digitalAccount.b4.cw))
															]),
														_List_Nil)
													])),
												A2($elm$html$Html$br, _List_Nil, _List_Nil)
											]) : _List_Nil,
										_Utils_ap(
											_List_fromArray(
												[
													A2(
													$elm$html$Html$h2,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Dados Pessoais')
														])),
													A2($elm$html$Html$br, _List_Nil, _List_Nil),
													A2(
													$elm$html$Html$label,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('CPF'),
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$placeholder(''),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$name('document'),
																	$elm$html$Html$Attributes$value(
																	$author$project$Mask$cpf(digitalAccount.dw.i1)),
																	$elm$html$Html$Events$onInput(
																	A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldLRDocument)),
																	A2(
																	$author$project$App$Page$PageDoctorSignUp$showClassError,
																	status,
																	$author$project$App$Data$DigitalAccount$isInvalidFieldDocument(digitalAccount.dw.i1))
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$label,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Nome'),
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$placeholder(''),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$name('name'),
																	$elm$html$Html$Attributes$value(digitalAccount.dw.hl),
																	$elm$html$Html$Events$onInput(
																	A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldLRName)),
																	A2(
																	$author$project$App$Page$PageDoctorSignUp$showClassError,
																	status,
																	$author$project$App$Data$DigitalAccount$isInvalidFieldString(digitalAccount.dw.hl))
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$label,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Sobrenome'),
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$placeholder(''),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$name('name'),
																	$elm$html$Html$Attributes$value(digitalAccount.dw.ii),
																	$elm$html$Html$Events$onInput(
																	A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldLRSurname)),
																	A2(
																	$author$project$App$Page$PageDoctorSignUp$showClassError,
																	status,
																	$author$project$App$Data$DigitalAccount$isInvalidFieldString(digitalAccount.dw.ii))
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$label,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Sexo'),
															A4(
															$author$project$App$Tools$FormHelpers$select,
															_List_fromArray(
																[
																	A2(
																	$author$project$App$Page$PageDoctorSignUp$showClassError,
																	status,
																	$author$project$App$Data$DigitalAccount$isInvalidFieldString(digitalAccount.dw.dJ))
																]),
															digitalAccount.dw.dJ,
															A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldLRSex),
															_List_fromArray(
																[
																	_Utils_Tuple2('', 'Selecione'),
																	_Utils_Tuple2('M', 'Masculino'),
																	_Utils_Tuple2('F', 'Feminino')
																]))
														])),
													A2(
													$elm$html$Html$label,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Nome da mãe'),
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$placeholder(''),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$name('motherName'),
																	$elm$html$Html$Attributes$value(digitalAccount.dw.cp),
																	$elm$html$Html$Events$onInput(
																	A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldLRMotherName)),
																	A2(
																	$author$project$App$Page$PageDoctorSignUp$showClassError,
																	status,
																	$author$project$App$Data$DigitalAccount$isInvalidFieldString(digitalAccount.dw.cp))
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$label,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Data de nascimento'),
															A3(
															$author$project$App$Tools$FormHelpers$stringDateInputWithAttrs,
															_List_fromArray(
																[
																	A2(
																	$author$project$App$Page$PageDoctorSignUp$showClassError,
																	status,
																	$author$project$App$Data$DigitalAccount$isInvalidFieldString(digitalAccount.dw.al))
																]),
															digitalAccount.dw.al,
															A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldLRBirthdate))
														])),
													A2($elm$html$Html$br, _List_Nil, _List_Nil)
												]),
											_Utils_ap(
												billingAccount ? _List_fromArray(
													[
														A2(
														$elm$html$Html$h2,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Conta Bancária')
															])),
														A2($elm$html$Html$br, _List_Nil, _List_Nil),
														A2(
														$elm$html$Html$label,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Número do Banco'),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$placeholder(''),
																		$elm$html$Html$Attributes$type_('text'),
																		$elm$html$Html$Attributes$name('bank_number'),
																		$elm$html$Html$Attributes$value(digitalAccount.ak.d4),
																		$elm$html$Html$Events$onInput(
																		A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldBABankNumber))
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$label,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Agência'),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$placeholder(''),
																		$elm$html$Html$Attributes$type_('text'),
																		$elm$html$Html$Attributes$name('agency_number'),
																		$elm$html$Html$Attributes$value(digitalAccount.ak.d1),
																		$elm$html$Html$Events$onInput(
																		A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldBAAgencyNumber))
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$label,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Conta'),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$placeholder(''),
																		$elm$html$Html$Attributes$type_('text'),
																		$elm$html$Html$Attributes$name('account_number'),
																		$elm$html$Html$Attributes$value(digitalAccount.ak.d_),
																		$elm$html$Html$Events$onInput(
																		A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldBAAccountNumber))
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$label,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Tipo da Conta'),
																A4(
																$author$project$App$Tools$FormHelpers$select,
																_List_Nil,
																digitalAccount.ak.d$,
																A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateJunoField, $author$project$App$Data$DigitalAccount$FieldBAAccountType),
																_List_fromArray(
																	[
																		_Utils_Tuple2('', 'Selecione'),
																		_Utils_Tuple2('CHECKING', 'Conta Corrente'),
																		_Utils_Tuple2('SAVINGS', 'Poupança')
																	]))
															])),
														A2($elm$html$Html$br, _List_Nil, _List_Nil)
													]) : _List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$h2,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Conta')
															])),
														A2($elm$html$Html$br, _List_Nil, _List_Nil),
														A2(
														$elm$html$Html$label,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('e-mail'),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$placeholder(''),
																		$elm$html$Html$Attributes$type_('email'),
																		$elm$html$Html$Attributes$name('email'),
																		$elm$html$Html$Attributes$value(user.c7),
																		A2(
																		$author$project$App$Page$PageDoctorSignUp$showClassError,
																		status,
																		$author$project$App$Data$User$isInvalidFieldEmail(user.c7)),
																		$elm$html$Html$Events$onInput(
																		A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateUserField, $author$project$App$Data$User$FieldEmail))
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$label,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Senha'),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$placeholder(''),
																		$elm$html$Html$Attributes$type_('password'),
																		$elm$html$Html$Attributes$name('password'),
																		$elm$html$Html$Attributes$value(user.bf),
																		A2(
																		$author$project$App$Page$PageDoctorSignUp$showClassError,
																		status,
																		$author$project$App$Data$User$isInvalidFieldPassword(user.bf)),
																		$elm$html$Html$Events$onInput(
																		A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateUserField, $author$project$App$Data$User$FieldPassword))
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$label,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Confirmar senha'),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$placeholder(''),
																		$elm$html$Html$Attributes$type_('password'),
																		$elm$html$Html$Attributes$name('passworkCheck'),
																		$elm$html$Html$Attributes$value(user.dv),
																		A2(
																		$author$project$App$Page$PageDoctorSignUp$showClassError,
																		status,
																		A2($author$project$App$Data$User$isInvalidFieldPasswordCheck, user.bf, user.dv)),
																		$elm$html$Html$Events$onInput(
																		A2($elm$core$Basics$composeL, $author$project$App$Page$PageDoctorSignUp$UpdateUserField, $author$project$App$Data$User$FieldPasswordCheck))
																	]),
																_List_Nil)
															]))
													])))))),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('actions')
									]),
								_List_fromArray(
									[
										createButton,
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('cancel-button'),
												$elm$html$Html$Events$onClick($author$project$App$Page$PageDoctorSignUp$GoToLogin)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Cancelar')
											]))
									])),
								$author$project$App$Page$PageDoctorSignUp$loadingView(status)
							]))
					])),
				A2(
				$elm$html$Html$map,
				$author$project$App$Page$PageDoctorSignUp$VideoMsg,
				$author$project$App$Components$Video$viewWithMappedMsgs(st.f4))
			]);
	});
var $author$project$App$Page$PageLoading$view = _List_fromArray(
	[
		A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('authentication')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$a,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('logo'),
										$elm$html$Html$Attributes$src('/assets/images/logo_color.svg')
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Verificando suas credenciais atuais, aguarde um momento...')
							]))
					]))
			]))
	]);
var $author$project$App$Page$PageLogin$InputEmail = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$PageLogin$InputPassword = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$PageLogin$RecoverPasswordForm = {$: 5};
var $author$project$App$Page$PageLogin$SignIn = {$: 2};
var $author$project$App$Page$PageLogin$SignUpDoctor = {$: 3};
var $author$project$App$Page$PageLogin$SignUpUser = {$: 4};
var $author$project$App$Page$PageLogin$viewError = function (err) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('error-message')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$App$Service$ApiWebSocket$errorMessage(err))
			]));
};
var $author$project$App$Page$PageLogin$viewLoading = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('loading')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('Carregando...')
		]));
var $author$project$App$Page$PageLogin$viewLoadingOrError = function (model) {
	var _v0 = model.e$;
	switch (_v0.$) {
		case 1:
			return $author$project$App$Page$PageLogin$viewLoading;
		case 2:
			var err = _v0.a;
			return $author$project$App$Page$PageLogin$viewError(err);
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$App$Page$PageLogin$view = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('authentication')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('logo'),
											$elm$html$Html$Attributes$src('/assets/images/logo_color.svg')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Bem vindo à Medsyn. Por favor utilize suas credenciais para ' + 'acessar o Sistema.')
								])),
							A2(
							$elm$html$Html$form,
							_List_fromArray(
								[
									$elm$html$Html$Events$onSubmit($author$project$App$Page$PageLogin$SignIn)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Usuário'),
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$placeholder('e-mail'),
															$elm$html$Html$Attributes$type_('email'),
															$elm$html$Html$Attributes$name('email'),
															$elm$html$Html$Events$onInput($author$project$App$Page$PageLogin$InputEmail)
														]),
													_List_Nil)
												])),
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Senha'),
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$placeholder(''),
															$elm$html$Html$Attributes$type_('password'),
															$elm$html$Html$Attributes$name('password'),
															$elm$html$Html$Events$onInput($author$project$App$Page$PageLogin$InputPassword)
														]),
													_List_Nil)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('actions')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick($author$project$App$Page$PageLogin$SignIn)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Entrar')
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$type_('button'),
													$elm$html$Html$Attributes$class('secondary'),
													$elm$html$Html$Events$onClick($author$project$App$Page$PageLogin$SignUpDoctor)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Novo Médico')
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$type_('button'),
													$elm$html$Html$Attributes$class('secondary'),
													$elm$html$Html$Events$onClick($author$project$App$Page$PageLogin$SignUpUser)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Novo Usuário')
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$type_('button'),
													$elm$html$Html$Attributes$class('secondary'),
													$elm$html$Html$Events$onClick($author$project$App$Page$PageLogin$RecoverPasswordForm)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Recuperar Senha')
												]))
										])),
									$author$project$App$Page$PageLogin$viewLoadingOrError(model)
								]))
						])),
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('jumpinout'),
							$elm$html$Html$Attributes$src('/assets/images/talking_illustration.svg')
						]),
					_List_Nil)
				]))
		]);
};
var $author$project$App$Page$PageProponent$VideoMsg = function (a) {
	return {$: 15, a: a};
};
var $author$project$App$Page$Proponent$PageConfirmInterview$Advance = {$: 4};
var $author$project$App$Page$Proponent$PageConfirmInterview$FinishTakingPhotos = {$: 10};
var $author$project$App$Page$Proponent$PageConfirmInterview$Revert = {$: 5};
var $author$project$App$Page$Proponent$PageConfirmInterview$StopTestVideo = {$: 1};
var $author$project$App$Page$Proponent$PageConfirmInterview$TestVideo = {$: 0};
var $author$project$App$Page$Proponent$PageConfirmInterview$ToggleWarning = {$: 9};
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $author$project$App$Page$Proponent$PageConfirmInterview$TakeSelfie = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Proponent$PageConfirmInterview$viewSelfieText = function (imgState) {
	switch (imgState) {
		case 0:
			return 'Tirar foto de ';
		case 1:
			return 'Enviando foto de ';
		default:
			return 'Tirar outra foto de ';
	}
};
var $author$project$App$Page$Proponent$PageConfirmInterview$viewTakePhotos = F2(
	function (st, model) {
		var hasDeps = A2(
			$elm$core$List$map,
			function (_v0) {
				var i = _v0.a;
				var div = _v0.b;
				return div;
			},
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$map,
					F2(
						function (i, dep) {
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('circle_buttons')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$src(
												$author$project$App$Data$Person$photoUrl(dep.dw)),
												$elm$html$Html$Events$onClick(
												$author$project$App$Page$Proponent$PageConfirmInterview$TakeSelfie(
													$author$project$App$Data$Statement$depIndexFromInt(i)))
											]),
										_List_Nil),
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												_Utils_ap(
													$author$project$App$Page$Proponent$PageConfirmInterview$viewSelfieText(model.bA),
													dep.dw.hl))
											]))
									]));
						}),
					A2(
						$elm$core$Dict$filter,
						F2(
							function (i, dep) {
								return !dep.e.j_;
							}),
						st.ia.h8.fh))));
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('circle_buttons')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									$author$project$App$Data$Person$photoUrl(st.ia.h8.g.dw)),
									$elm$html$Html$Events$onClick(
									$author$project$App$Page$Proponent$PageConfirmInterview$TakeSelfie($author$project$App$Data$Statement$Bearer))
								]),
							_List_Nil),
							A2(
							$elm$html$Html$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									_Utils_ap(
										$author$project$App$Page$Proponent$PageConfirmInterview$viewSelfieText(model.bA),
										st.ia.h8.g.dw.hl))
								]))
						]))
				]),
			hasDeps);
	});
var $author$project$App$Page$Proponent$PageConfirmInterview$view = F2(
	function (st, model) {
		var warningComponent = function () {
			if (!_Utils_eq(model.de, $elm$core$Maybe$Nothing)) {
				var message = function () {
					var _v5 = model.de;
					if (!_v5.$) {
						var t = _v5.a;
						return t;
					} else {
						return '';
					}
				}();
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('jitsi_warning_message'),
							A2($elm$html$Html$Attributes$style, 'position', 'absolute')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(message)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('support-close'),
									$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PageConfirmInterview$ToggleWarning)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('X')
								]))
						]));
			} else {
				if (model.dL) {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('jitsi_warning_message'),
								A2($elm$html$Html$Attributes$style, 'position', 'absolute')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Nesta tela faremos apenas um teste da sua câmera e microfone. Após o teste retorne para tirar as fotos necessárias ou avance para a fila.'),
										A2($elm$html$Html$br, _List_Nil, _List_Nil),
										$elm$html$Html$text('Caso tenha alguma dificuldade entre em contato com o suporte no canto inferior direito.')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('support-close'),
										$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PageConfirmInterview$ToggleWarning)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('X')
									]))
							]));
				} else {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
								A2($elm$html$Html$Attributes$style, 'width', 'auto'),
								A2($elm$html$Html$Attributes$style, 'right', '12%'),
								A2($elm$html$Html$Attributes$style, 'left', 'auto'),
								A2($elm$html$Html$Attributes$style, 'background-color', '#2ccfd6'),
								A2($elm$html$Html$Attributes$style, 'border-radius', '30px'),
								A2($elm$html$Html$Attributes$style, 'padding', '15px'),
								$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PageConfirmInterview$ToggleWarning)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$style, 'color', 'white')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Mostrar'),
										A2($elm$html$Html$br, _List_Nil, _List_Nil),
										$elm$html$Html$text('Mensagem')
									]))
							]));
				}
			}
		}();
		var takePhotos = A2($author$project$App$Page$Proponent$PageConfirmInterview$viewTakePhotos, st, model);
		var _v0 = _Utils_Tuple2(true, 'Voltar');
		var canReturn = _v0.a;
		var returnButtonText = _v0.b;
		var _v1 = function () {
			var _v2 = _Utils_Tuple2(
				model.bO,
				$author$project$App$Page$Proponent$PageConfirmInterview$hasAllSelfies(st.ia.h8));
			if (!_v2.a.$) {
				if (_v2.a.a === 'ok') {
					if (_v2.b) {
						return _Utils_Tuple3(true, 'Entrar na fila', 'Entrar na fila');
					} else {
						return _Utils_Tuple3(false, 'Tire fotos com o botão acima', 'Retorne para tirar as fotos');
					}
				} else {
					var str = _v2.a.a;
					switch (str) {
						case 'NoVideo':
							return _Utils_Tuple3(false, 'Câmera não encontrada', 'Câmera não encontrada');
						case 'NoAudio':
							return _Utils_Tuple3(false, 'Microfone não encontrado', 'Microfone não encontrado');
						case 'NotTested':
							return _Utils_Tuple3(false, 'Não testado', 'Não testado');
						case 'NotSupported':
							return _Utils_Tuple3(false, 'Navegador Incompatível', 'Navegador Incompatível');
						default:
							return _Utils_Tuple3(false, 'Erro', 'Erro');
					}
				}
			} else {
				var _v4 = _v2.a;
				return _Utils_Tuple3(false, 'Não testado', 'Não testado');
			}
		}();
		var canAdvance = _v1.a;
		var advanceText = _v1.b;
		var advanceTextInJitsi = _v1.c;
		return model.dk ? _Utils_ap(
			_List_fromArray(
				[
					$author$project$App$Data$Jitsi$view(
					{i$: st.ia.h8.g.dw.B, jg: '', eO: st.ia.h8.g.e.as}),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$Attributes$style,
							'display',
							canReturn ? 'initial' : 'none'),
							$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PageConfirmInterview$StopTestVideo),
							$elm$html$Html$Attributes$class('jitsi_test_button left_jitsi_button'),
							A2($elm$html$Html$Attributes$style, 'position', 'absolute')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(returnButtonText)
						])),
					warningComponent
				]),
			canAdvance ? _List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$disabled(!canAdvance),
							$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PageConfirmInterview$Advance),
							$elm$html$Html$Attributes$class('jitsi_test_button right_jitsi_button'),
							A2($elm$html$Html$Attributes$style, 'position', 'absolute')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(advanceTextInJitsi)
						]))
				]) : _List_Nil) : (model.dj ? _List_fromArray(
			[
				A2(
				$elm$html$Html$article,
				_List_Nil,
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Foto')
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Será necessário tirar uma foto de cada pessoa que será atendida.')
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Isso nos ajuda a garantir que você será identificado pelo médico e que ' + 'teremos sua foto no nosso cadastro para futuros atendimentos.')
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('As fotos dos dependentes menores de idade são opcionais. Avance sem elas caso não seja possível tirar.')
								]))
						]),
					takePhotos)),
				A2(
				$elm$html$Html$footer,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$nav,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$disabled(!canReturn),
										$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PageConfirmInterview$Revert),
										$elm$html$Html$Attributes$class('left-extreme')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(returnButtonText)
									])),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$disabled(
										!$author$project$App$Page$Proponent$PageConfirmInterview$hasAllSelfies(st.ia.h8)),
										$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PageConfirmInterview$FinishTakingPhotos),
										$elm$html$Html$Attributes$class('right-extreme')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Avançar')
									]))
							]))
					]))
			]) : _List_fromArray(
			[
				A2(
				$elm$html$Html$article,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Agora vamos realizar a teleorientação com um dos médicos associados.')
							])),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						A2(
						$elm$html$Html$h2,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Antes de começar, precisamos ter certeza de que você tem ciência de ' + 'algumas informações importantes:')
							])),
						A2(
						$elm$html$Html$ul,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$li,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('O atendimento poderá ser gravado, mediante a sua autorização.')
									])),
								A2(
								$elm$html$Html$li,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Você deve estar utilizando uma conexão com a internet de qualidade, de preferência WiFi.')
									])),
								A2(
								$elm$html$Html$li,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Recomendamos o uso de fones de ouvido para evitar microfonia.')
									])),
								A2(
								$elm$html$Html$li,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Caso caia a conexão, recarregue o link que o médico estará na sala.')
									]))
							]))
					])),
				A2(
				$elm$html$Html$article,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Teste de Vídeo ')
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Será necessário liberar acesso à sua câmera e microfone. ' + 'Você pode clicar no ícone abaixo para efetuar o teste.')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('circle_buttons')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src('/assets/images/icon_video.png'),
										$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PageConfirmInterview$TestVideo)
									]),
								_List_Nil),
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Testar vídeo')
									]))
							]))
					])),
				A2(
				$elm$html$Html$footer,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$nav,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$disabled(!canReturn),
										$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PageConfirmInterview$Revert),
										$elm$html$Html$Attributes$class('left-extreme')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(returnButtonText)
									])),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$disabled(!canAdvance),
										$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PageConfirmInterview$Advance),
										$elm$html$Html$Attributes$class('right-extreme')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(advanceText)
									]))
							]))
					]))
			]));
	});
var $author$project$App$Page$Proponent$PageDoctorReview$view = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$article,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Médico Revisando os dados')
						])),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					$author$project$App$Data$Jitsi$view(
					{i$: model.P.g.dw.B, jg: '', eO: model.P.g.e.as})
				]))
		]);
};
var $author$project$App$Page$Proponent$PageEnteringWaitingLine$view = F2(
	function (st, model) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$article,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Entrando na fila')
							])),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Estamos notificando os doutores. Permaneça nesta tela até entrar na fila')
							]))
					]))
			]);
	});
var $author$project$App$Page$Proponent$PageError$view = function (model) {
	var msgText = function () {
		switch (model.a.$) {
			case 5:
				var _v1 = model;
				var msg = _v1.a.a;
				return 'Expirado em ' + msg;
			case 2:
				var _v2 = model;
				var msg = _v2.a.a;
				return msg;
			default:
				return '';
		}
	}();
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$article,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Erro ao carregar seu atendimento!')
						])),
					A2($elm$html$Html$p, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(msgText)
						]))
				]))
		]);
};
var $author$project$App$Page$Proponent$PageFinishedProposition$view = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$article,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Atendimento Finalizado.')
						])),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Seu atendimento foi finalizado pelo médico. ' + (model.a5.b4.iR + ' deve entrar em contato em breve. Obrigado!'))
						]))
				]))
		]);
};
var $author$project$App$Page$Proponent$PageInterview$view = function (model) {
	return _List_fromArray(
		[
			$author$project$App$Data$Jitsi$view(
			{i$: model.P.g.dw.B, jg: '', eO: model.P.g.e.as})
		]);
};
var $author$project$App$Page$Proponent$PageIntro$Accept = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Proponent$PageIntro$AcceptPolicy = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Proponent$PageIntro$Advance = {$: 2};
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $author$project$App$Page$Proponent$PageIntro$view = F2(
	function (st, model) {
		var termsDocument = A2($elm$core$Maybe$withDefault, $author$project$App$Data$Document$emptyModel, model.dQ);
		var fullStmt = st.ia.h8;
		var person = fullStmt.g.dw;
		var sexGreetings = (person.dJ === 1) ? A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(
					'Prezado ' + ($author$project$App$Data$Person$fullPersonName(person) + ','))
				])) : A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(
					'Prezada ' + ($author$project$App$Data$Person$fullPersonName(person) + ','))
				]));
		var greetings = A2(
			$elm$core$List$cons,
			sexGreetings,
			A2(
				$elm$core$List$map,
				function (t) {
					return A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(t)
							]));
				},
				fullStmt.cJ.el));
		var termsHref = _Utils_eq(model.dQ, $elm$core$Maybe$Nothing) ? fullStmt.cJ.iz : termsDocument.iU;
		var errorMsg = function () {
			var _v0 = model.c8;
			if (!_v0.$) {
				var t = _v0.a;
				return A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('error'),
							$elm$html$Html$Attributes$class('error-intro')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(t)
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$article,
				_List_Nil,
				_Utils_ap(
					greetings,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$type_('checkbox'),
												$elm$html$Html$Events$onCheck($author$project$App$Page$Proponent$PageIntro$Accept),
												$elm$html$Html$Attributes$checked(model.cR)
											]),
										_List_Nil),
										$elm$html$Html$text('Li e aceito os '),
										A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$href(termsHref),
												$elm$html$Html$Attributes$target('_blank')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Termos de Uso')
											]))
									]),
								($elm$core$String$length(fullStmt.cJ.iy) > 0) ? _List_fromArray(
									[
										A2($elm$html$Html$br, _List_Nil, _List_Nil),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$type_('checkbox'),
												$elm$html$Html$Events$onCheck($author$project$App$Page$Proponent$PageIntro$AcceptPolicy),
												$elm$html$Html$Attributes$checked(model.cQ)
											]),
										_List_Nil),
										$elm$html$Html$text('Li e aceito a '),
										A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$href(fullStmt.cJ.iy),
												$elm$html$Html$Attributes$target('_blank')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Política de Privacidade')
											]))
									]) : _List_Nil)),
							errorMsg
						]))),
				A2(
				$elm$html$Html$footer,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$nav,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$disabled(true),
										A2($elm$html$Html$Attributes$style, 'visibility', 'hidden')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Voltar')
									])),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PageIntro$Advance),
										$elm$html$Html$Attributes$class('right-extreme')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Próxima')
									]))
							]))
					]))
			]);
	});
var $author$project$App$Page$Proponent$PageLoading$view = _List_fromArray(
	[
		A2(
		$elm$html$Html$article,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Aguarde, carregando o seu atendimento...')
					]))
			]))
	]);
var $author$project$App$Page$Proponent$PageNotActivated$view = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$article,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Atendimento não ativado.')
						])),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Seu atendimento não foi ativado ainda. Aguarde a ativação e entre novamente. Obrigado!')
						]))
				]))
		]);
};
var $author$project$App$Data$DigitalAccount$FieldAComplement = function (a) {
	return {$: 15, a: a};
};
var $author$project$App$Page$Proponent$PagePayment$UpdateAddressField = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$Proponent$PagePayment$UpdateField = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$App$Page$Proponent$PagePayment$UpdateFieldEmail = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$Proponent$PagePayment$UpdateFieldExpirationDate = function (a) {
	return {$: 3, a: a};
};
var $author$project$Mask$cardNumber = $author$project$Mask$number('#### #### #### ####');
var $author$project$Mask$cardValidShort = $author$project$Mask$number('##/##');
var $author$project$App$Page$Proponent$PagePayment$comparePaymentIndex = F2(
	function (a, b) {
		return A2($elm$core$Basics$compare, a.b.b, b.b.b);
	});
var $author$project$App$Page$Proponent$PagePayment$dictKeyToFieldName = function (k) {
	switch (k) {
		case 'publicToken':
			return 'PUBLIC_TOKEN';
		case 'cNumber':
			return 'Número do cartão';
		case 'hName':
			return 'Nome do titular';
		case 'secCode':
			return 'Código de segurança';
		case 'expMonth':
			return 'Mês de expiração';
		case 'expYear':
			return 'Ano de expiração';
		default:
			return '';
	}
};
var $author$project$App$Page$Proponent$PagePayment$renderField = function (t) {
	var tempV = t.b.a;
	var v = function () {
		var _v0 = t.a;
		if (_v0 === 'cNumber') {
			return $author$project$Mask$cardNumber(tempV);
		} else {
			return tempV;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'text-align', 'center')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('text'),
						$elm$html$Html$Attributes$placeholder(
						$author$project$App$Page$Proponent$PagePayment$dictKeyToFieldName(t.a) + '*'),
						$elm$html$Html$Attributes$value(v),
						$elm$html$Html$Events$onInput(
						$author$project$App$Page$Proponent$PagePayment$UpdateField(t.a)),
						A2($elm$html$Html$Attributes$style, 'margin', 'auto'),
						A2($elm$html$Html$Attributes$style, 'width', 'inherit'),
						A2($elm$html$Html$Attributes$style, 'display', 'block')
					]),
				_List_Nil),
				A2($elm$html$Html$br, _List_Nil, _List_Nil)
			]));
};
var $author$project$App$Page$Proponent$PagePayment$InvalidField = 1;
var $author$project$App$Page$Proponent$PagePayment$showClassError = F2(
	function (status, isInvalid) {
		return $elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2('error-message', (status === 1) && isInvalid)
				]));
	});
var $author$project$App$Page$Proponent$PagePayment$Revert = {$: 11};
var $author$project$App$Page$Proponent$PagePayment$ValidateCard = {$: 4};
var $author$project$App$Data$Statement$formatPrice = function (v) {
	var cent = $elm$core$String$fromInt(v);
	var integer = (v >= 100) ? A3($elm$core$String$slice, 0, -2, cent) : '0';
	var len = $elm$core$String$length(cent);
	var onlyCents = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cent)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cent) : cent);
	return (v > 0) ? (integer + (',' + onlyCents)) : '0,00';
};
var $author$project$App$Page$Proponent$PagePayment$viewFooter = function (st) {
	var _v0 = st.ia.iP;
	switch (_v0) {
		case 1:
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$footer,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$nav,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$disabled(true)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Voltar')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$disabled(true)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Processando...')
										]))
								]))
						]))
				]);
		case 2:
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$footer,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$nav,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$disabled(true)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Processando...')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$disabled(true),
											$elm$html$Html$Attributes$class('right-extreme')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Próxima')
										]))
								]))
						]))
				]);
		default:
			var statement = st.ia.h8;
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$footer,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$nav,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PagePayment$Revert)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Voltar')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PagePayment$ValidateCard)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											'Pagar ' + ('R$ ' + $author$project$App$Data$Statement$formatPrice(
												A2($elm$core$Maybe$withDefault, 0, statement.cJ.gr))))
										]))
								]))
						]))
				]);
	}
};
var $author$project$App$Page$Proponent$PagePayment$view = F2(
	function (st, model) {
		var statement = st.ia.h8;
		var inputList = A2(
			$elm$core$List$map,
			$author$project$App$Page$Proponent$PagePayment$renderField,
			A2(
				$elm$core$List$sortWith,
				$author$project$App$Page$Proponent$PagePayment$comparePaymentIndex,
				$elm$core$Dict$toList(
					A2(
						$elm$core$Dict$filter,
						F2(
							function (k, _v1) {
								return k !== 'publicToken';
							}),
						model.hv.du))));
		var ft = $author$project$App$Page$Proponent$PagePayment$viewFooter(st);
		var errorMsg = function () {
			var _v0 = model.c8;
			if (!_v0.$) {
				var t = _v0.a;
				return A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('error'),
							$elm$html$Html$Attributes$class('error-intro')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(t)
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$article,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('page-payment ')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-medsyn')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('inner-container')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('form-wrapper')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$h2,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Dados de pagamento:')
														])),
													A2(
													$elm$html$Html$h3,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Cartão de crédito')
														])),
													A2(
													$elm$html$Html$label,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$placeholder('Nome do Titular*'),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$name('name'),
																	$elm$html$Html$Attributes$value(
																	A2(
																		$elm$core$Maybe$withDefault,
																		_Utils_Tuple2('', 0),
																		A2($elm$core$Dict$get, 'hName', model.hv.du)).a),
																	$elm$html$Html$Events$onInput(
																	$author$project$App$Page$Proponent$PagePayment$UpdateField('hName')),
																	A2(
																	$author$project$App$Page$Proponent$PagePayment$showClassError,
																	model.aw,
																	$author$project$App$Data$DigitalAccount$isInvalidFieldString(model.cS.hA))
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$label,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$placeholder('Número do cartão*'),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$name('cNumber'),
																	$elm$html$Html$Attributes$value(
																	$author$project$Mask$cardNumber(
																		A2(
																			$elm$core$Maybe$withDefault,
																			_Utils_Tuple2('', 0),
																			A2($elm$core$Dict$get, 'cNumber', model.hv.du)).a)),
																	$elm$html$Html$Events$onInput(
																	$author$project$App$Page$Proponent$PagePayment$UpdateField('cNumber')),
																	A2(
																	$author$project$App$Page$Proponent$PagePayment$showClassError,
																	model.aw,
																	$author$project$App$Data$DigitalAccount$isInvalidFieldString(model.cS.hA))
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('label-flex')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$placeholder('Validade (MM/AA)*'),
																			$elm$html$Html$Attributes$type_('text'),
																			$elm$html$Html$Attributes$name('expire'),
																			$elm$html$Html$Attributes$value(
																			$author$project$Mask$cardValidShort(
																				_Utils_ap(
																					A2(
																						$elm$core$Maybe$withDefault,
																						_Utils_Tuple2('', 0),
																						A2($elm$core$Dict$get, 'expMonth', model.hv.du)).a,
																					A2(
																						$elm$core$String$dropLeft,
																						2,
																						A2(
																							$elm$core$Maybe$withDefault,
																							_Utils_Tuple2('', 0),
																							A2($elm$core$Dict$get, 'expYear', model.hv.du)).a)))),
																			$elm$html$Html$Events$onInput($author$project$App$Page$Proponent$PagePayment$UpdateFieldExpirationDate)
																		]),
																	_List_Nil)
																])),
															A2(
															$elm$html$Html$label,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$placeholder('Cód. de segurança*'),
																			$elm$html$Html$Attributes$type_('text'),
																			$elm$html$Html$Attributes$name('code'),
																			$elm$html$Html$Attributes$value(
																			A2(
																				$elm$core$Maybe$withDefault,
																				_Utils_Tuple2('', 0),
																				A2($elm$core$Dict$get, 'secCode', model.hv.du)).a),
																			$elm$html$Html$Events$onInput(
																			$author$project$App$Page$Proponent$PagePayment$UpdateField('secCode'))
																		]),
																	_List_Nil)
																]))
														])),
													A2($elm$html$Html$br, _List_Nil, _List_Nil),
													A2(
													$elm$html$Html$h3,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Endereço')
														])),
													A2(
													$elm$html$Html$label,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$placeholder('CEP*'),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$name('postCode'),
																	$elm$html$Html$Attributes$value(
																	$author$project$Mask$zipCode(model.cS.hA)),
																	$elm$html$Html$Events$onInput(
																	A2($elm$core$Basics$composeL, $author$project$App$Page$Proponent$PagePayment$UpdateAddressField, $author$project$App$Data$DigitalAccount$FieldAPostCode)),
																	A2(
																	$author$project$App$Page$Proponent$PagePayment$showClassError,
																	model.aw,
																	$author$project$App$Data$DigitalAccount$isInvalidFieldString(model.cS.hA))
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$label,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$placeholder('Rua*'),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$name('street'),
																	$elm$html$Html$Attributes$value(model.cS.ic),
																	$elm$html$Html$Events$onInput(
																	A2($elm$core$Basics$composeL, $author$project$App$Page$Proponent$PagePayment$UpdateAddressField, $author$project$App$Data$DigitalAccount$FieldAStreet)),
																	A2(
																	$author$project$App$Page$Proponent$PagePayment$showClassError,
																	model.aw,
																	$author$project$App$Data$DigitalAccount$isInvalidFieldString(model.cS.ic))
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('label-flex')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('min-label')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$placeholder('N.º*'),
																			$elm$html$Html$Attributes$type_('text'),
																			$elm$html$Html$Attributes$name('number'),
																			$elm$html$Html$Attributes$value(model.cS.bb),
																			$elm$html$Html$Events$onInput(
																			A2($elm$core$Basics$composeL, $author$project$App$Page$Proponent$PagePayment$UpdateAddressField, $author$project$App$Data$DigitalAccount$FieldANumber)),
																			A2(
																			$author$project$App$Page$Proponent$PagePayment$showClassError,
																			model.aw,
																			$author$project$App$Data$DigitalAccount$isInvalidFieldAddressNumber(model.cS.bb))
																		]),
																	_List_Nil)
																])),
															A2(
															$elm$html$Html$label,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$placeholder('Complemento'),
																			$elm$html$Html$Attributes$type_('text'),
																			$elm$html$Html$Attributes$name('complement'),
																			$elm$html$Html$Attributes$value(model.cS.gy),
																			$elm$html$Html$Events$onInput(
																			A2($elm$core$Basics$composeL, $author$project$App$Page$Proponent$PagePayment$UpdateAddressField, $author$project$App$Data$DigitalAccount$FieldAComplement))
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$label,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$placeholder('Cidade*'),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$name('city'),
																	$elm$html$Html$Attributes$value(model.cS.bp),
																	$elm$html$Html$Events$onInput(
																	A2($elm$core$Basics$composeL, $author$project$App$Page$Proponent$PagePayment$UpdateAddressField, $author$project$App$Data$DigitalAccount$FieldACity)),
																	A2(
																	$author$project$App$Page$Proponent$PagePayment$showClassError,
																	model.aw,
																	$author$project$App$Data$DigitalAccount$isInvalidFieldString(model.cS.bp))
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('label-flex')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$placeholder('Bairro*'),
																			$elm$html$Html$Attributes$type_('text'),
																			$elm$html$Html$Attributes$name('neighborhood'),
																			$elm$html$Html$Attributes$value(model.cS.hn),
																			$elm$html$Html$Events$onInput(
																			A2($elm$core$Basics$composeL, $author$project$App$Page$Proponent$PagePayment$UpdateAddressField, $author$project$App$Data$DigitalAccount$FieldANeighborhood))
																		]),
																	_List_Nil)
																])),
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('min-label')
																]),
															_List_fromArray(
																[
																	A3(
																	$author$project$App$Tools$FormHelpers$selectStates,
																	_List_fromArray(
																		[
																			A2(
																			$author$project$App$Page$Proponent$PagePayment$showClassError,
																			model.aw,
																			$author$project$App$Data$DigitalAccount$isInvalidFieldString(model.cS.p)),
																			$elm$html$Html$Attributes$class('select-css'),
																			($elm$core$String$length(model.cS.p) > 0) ? $elm$html$Html$Attributes$class('') : $elm$html$Html$Attributes$class('not-checked')
																		]),
																	model.cS.p,
																	A2($elm$core$Basics$composeL, $author$project$App$Page$Proponent$PagePayment$UpdateAddressField, $author$project$App$Data$DigitalAccount$FieldAState))
																]))
														])),
													A2(
													$elm$html$Html$label,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$style, 'margin-left', '8px')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$placeholder('Seu e-mail*'),
																	$elm$html$Html$Attributes$type_('email'),
																	$elm$html$Html$Attributes$name('email'),
																	$elm$html$Html$Attributes$value(model.c7),
																	A2(
																	$author$project$App$Page$Proponent$PagePayment$showClassError,
																	model.aw,
																	$author$project$App$Data$User$isInvalidFieldEmail(model.c7)),
																	$elm$html$Html$Events$onInput($author$project$App$Page$Proponent$PagePayment$UpdateFieldEmail)
																]),
															_List_Nil)
														]))
												]))
										]))
								])),
							errorMsg
						]))
				]),
			ft);
	});
var $author$project$App$Page$Proponent$PageQuestions$Advance = {$: 2};
var $author$project$App$Page$Proponent$PageQuestions$Revert = {$: 3};
var $author$project$App$Page$Proponent$PageQuestions$viewFooter = F3(
	function (st, fullSection, stmts) {
		var requiredQuestionsAnswered = !A2(
			$elm$core$List$any,
			function (b) {
				return b;
			},
			A2(
				$elm$core$List$map,
				function (_v2) {
					var idx = _v2.a;
					var stmt = _v2.b;
					return A2(
						$elm$core$List$any,
						function (q) {
							return $elm$core$List$length(
								A2(
									$elm$core$List$filter,
									function (t) {
										var requiredAnswer = (t.ay === 10) || ((t.ay === 20) || ((t.ay === 30) || (t.ay === 40)));
										var answer = A2($author$project$App$Page$Proponent$PageQuestions$getAnswerForTemplate, stmt, t);
										return (!$elm$core$String$length(answer.T)) && ((!answer.dF) && requiredAnswer);
									},
									q.bY)) > 0;
						},
						fullSection.eI);
				},
				$elm$core$Dict$toList(stmts)));
		var isNotCentauro = !(st.ia.h8.b4.iR === 'Centauro-ON');
		var allQuestionsAnswered = A2(
			$elm$core$List$any,
			function (b) {
				return b;
			},
			A2(
				$elm$core$List$map,
				function (_v1) {
					var idx = _v1.a;
					var stmt = _v1.b;
					return !A2(
						$elm$core$List$any,
						function (b) {
							return !b;
						},
						A2(
							$elm$core$List$map,
							function (q) {
								return $elm$core$List$length(
									A2(
										$elm$core$List$filter,
										function (t) {
											var answer = A2($author$project$App$Page$Proponent$PageQuestions$getAnswerForTemplate, stmt, t);
											return ($elm$core$String$length(answer.T) > 0) || (answer.dF || (q.hG.j5 === 4));
										},
										q.bY)) > 0;
							},
							fullSection.eI));
				},
				$elm$core$Dict$toList(stmts)));
		var _v0 = st.ia.iP;
		switch (_v0) {
			case 1:
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$footer,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$nav,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$disabled(true)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Voltar')
											])),
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$disabled(true)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Processando...')
											]))
									]))
							]))
					]);
			case 2:
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$footer,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$nav,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$disabled(true)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Processando...')
											])),
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$disabled(true),
												$elm$html$Html$Attributes$class('right-extreme')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Próxima')
											]))
									]))
							]))
					]);
			default:
				return ((requiredQuestionsAnswered && allQuestionsAnswered) || isNotCentauro) ? _List_fromArray(
					[
						A2(
						$elm$html$Html$footer,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$nav,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PageQuestions$Revert)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Voltar')
											])),
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PageQuestions$Advance),
												$elm$html$Html$Attributes$class('right-extreme')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Próxima')
											]))
									]))
							]))
					]) : _List_fromArray(
					[
						A2(
						$elm$html$Html$footer,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$nav,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick($author$project$App$Page$Proponent$PageQuestions$Revert)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Voltar')
											])),
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$disabled(true),
												$elm$html$Html$Attributes$class('right-extreme')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Perguntas não respondidas')
											]))
									]))
							]))
					]);
		}
	});
var $author$project$App$Page$Proponent$PageQuestions$hasErrorOnQuestion = F2(
	function (qId, model) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (err) {
					return $elm$core$Dict$size(err) > 0;
				},
				A2($elm$core$Dict$get, qId, model.fp)));
	});
var $author$project$App$Page$Proponent$PageQuestions$InputAnswer = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$App$Page$Proponent$PageQuestions$maskDecimal = F2(
	function (precision, value) {
		return A3($elm$core$String$replace, '.', ',', value);
	});
var $author$project$App$Page$Proponent$PageQuestions$maskHeight = function (cm) {
	var len = $elm$core$String$length(cm);
	var subM = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cm)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cm) : cm);
	var h = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(cm));
	var m = (h >= 100) ? A3($elm$core$String$slice, 0, -2, cm) : '0';
	return (h > 0) ? (m + (',' + subM)) : '';
};
var $author$project$App$Page$Proponent$PageQuestions$maskPressure = function (str) {
	return function (p) {
		return (p > 0) ? $elm$core$String$fromInt(p) : '';
	}(
		function (p) {
			return (p > 999) ? (p / 10) : (p / 1.0);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(str))) | 0);
};
var $author$project$App$Page$Proponent$PageQuestions$maskWeight = function (wStr) {
	return function (w) {
		return (w > 0) ? $elm$core$String$fromInt(w) : '';
	}(
		function (w) {
			return w / 1000;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(wStr))) | 0);
};
var $author$project$App$Page$Proponent$PageQuestions$storeDecimal = F2(
	function (precision, value) {
		var _v0 = $elm$regex$Regex$fromString('[^\\d]');
		if (!_v0.$) {
			var regex = _v0.a;
			var decimalPoints = precision * 10;
			var cleared = A3(
				$elm$regex$Regex$replace,
				regex,
				function (_v1) {
					return '';
				},
				value);
			var floatValue = A3(
				$elm$core$Basics$clamp,
				0,
				1000000000,
				A2(
					$elm$core$Maybe$withDefault,
					0,
					A2(
						$elm$core$Maybe$map,
						function (v) {
							return v / decimalPoints;
						},
						$elm$core$String$toFloat(cleared))));
			var intValue = A3($elm$core$Basics$clamp, 0, 1000000, floatValue | 0);
			var decimalValue = $elm$core$Basics$round((floatValue - intValue) * decimalPoints);
			var decimalValueStr = function (s) {
				return (_Utils_cmp(
					$elm$core$String$length(s),
					precision) > 0) ? A2(
					$elm$core$String$dropRight,
					$elm$core$String$length(s) - precision,
					s) : s;
			}(
				$elm$core$String$fromInt(decimalValue));
			return ((intValue > 0) || (decimalValue > 0)) ? ($elm$core$String$fromInt(intValue) + ('.' + decimalValueStr)) : '';
		} else {
			return '';
		}
	});
var $author$project$App$Page$Proponent$PageQuestions$storeHeight = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return function (h) {
			return (h > 0) ? $elm$core$String$fromInt(h) : '';
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A3(
						$elm$regex$Regex$replace,
						regex,
						function (_v1) {
							return '';
						},
						str))));
	} else {
		return '';
	}
};
var $author$project$App$Page$Proponent$PageQuestions$storePressure = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return function (p) {
			return (p > 0) ? $elm$core$String$fromInt(p) : '';
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A3(
						$elm$regex$Regex$replace,
						regex,
						function (_v1) {
							return '';
						},
						str))));
	} else {
		return '';
	}
};
var $author$project$App$Page$Proponent$PageQuestions$storeWeight = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return function (w) {
			return (w > 0) ? $elm$core$String$fromInt(w) : '';
		}(
			1000 * A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A3(
						$elm$regex$Regex$replace,
						regex,
						function (_v1) {
							return '';
						},
						str))));
	} else {
		return '';
	}
};
var $author$project$App$Page$Proponent$PageQuestions$setupMask = F5(
	function (depIdx, question, template, answer, attrs) {
		var _v0 = template.jj;
		if (_v0.$ === 1) {
			return attrs;
		} else {
			switch (_v0.a.$) {
				case 3:
					var precision = _v0.a.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							A2($author$project$App$Page$Proponent$PageQuestions$maskDecimal, precision, answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Proponent$PageQuestions$storeDecimal(precision),
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A2($author$project$App$Page$Proponent$PageQuestions$InputAnswer, depIdx, answer))))
						]);
				case 0:
					var _v1 = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('number'),
							$elm$html$Html$Attributes$value(
							$author$project$App$Page$Proponent$PageQuestions$maskWeight(answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Proponent$PageQuestions$storeWeight,
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A2($author$project$App$Page$Proponent$PageQuestions$InputAnswer, depIdx, answer))))
						]);
				case 1:
					var _v2 = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							$author$project$App$Page$Proponent$PageQuestions$maskHeight(answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Proponent$PageQuestions$storeHeight,
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A2($author$project$App$Page$Proponent$PageQuestions$InputAnswer, depIdx, answer))))
						]);
				default:
					var _v3 = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							$author$project$App$Page$Proponent$PageQuestions$maskPressure(answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Proponent$PageQuestions$storePressure,
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A2($author$project$App$Page$Proponent$PageQuestions$InputAnswer, depIdx, answer))))
						]);
			}
		}
	});
var $author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeDate = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						A5(
							$author$project$App$Page$Proponent$PageQuestions$setupMask,
							depIdx,
							fullQuestion.hG,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('date'),
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A2($author$project$App$Page$Proponent$PageQuestions$InputAnswer, depIdx, answer)))
								])),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeNumeric = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						A5(
							$author$project$App$Page$Proponent$PageQuestions$setupMask,
							depIdx,
							fullQuestion.hG,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('number'),
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A2($author$project$App$Page$Proponent$PageQuestions$InputAnswer, depIdx, answer)))
								])),
						_List_Nil),
						$elm$html$Html$text(
						A2($elm$core$Maybe$withDefault, '', template.iv))
					]))
			]);
	});
var $author$project$App$Page$Proponent$PageQuestions$InputSingleChoice = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$App$Page$Proponent$PageQuestions$onRadioChange = F2(
	function (depIdx, q) {
		return $elm$html$Html$Events$onInput(
			A2($author$project$App$Page$Proponent$PageQuestions$InputSingleChoice, depIdx, q));
	});
var $author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeOption = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$for(
						_Utils_ap(
							template.as,
							$elm$core$String$fromInt(
								function () {
									if (!depIdx.$) {
										return -1;
									} else {
										var idx = depIdx.a;
										return idx;
									}
								}())))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(
								_Utils_ap(
									template.as,
									$elm$core$String$fromInt(
										function () {
											if (!depIdx.$) {
												return -1;
											} else {
												var idx = depIdx.a;
												return idx;
											}
										}()))),
								$elm$html$Html$Attributes$name(
								_Utils_ap(
									template.as,
									$elm$core$String$fromInt(
										function () {
											if (!depIdx.$) {
												return -1;
											} else {
												var idx = depIdx.a;
												return idx;
											}
										}()))),
								$elm$html$Html$Attributes$type_('radio'),
								$elm$html$Html$Attributes$checked(answer.dF),
								A2($author$project$App$Page$Proponent$PageQuestions$onRadioChange, depIdx, fullQuestion),
								$elm$html$Html$Attributes$value(template.as)
							]),
						_List_Nil),
						$elm$html$Html$text(template.jh)
					]))
			]);
	});
var $author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeOptionCheck = F3(
	function (depIdx, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$for(
						_Utils_ap(
							template.as,
							$elm$core$String$fromInt(
								function () {
									if (!depIdx.$) {
										return -1;
									} else {
										var idx = depIdx.a;
										return idx;
									}
								}())))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(
								_Utils_ap(
									template.as,
									$elm$core$String$fromInt(
										function () {
											if (!depIdx.$) {
												return -1;
											} else {
												var idx = depIdx.a;
												return idx;
											}
										}()))),
								$elm$html$Html$Attributes$type_('checkbox'),
								$elm$html$Html$Attributes$checked(answer.dF),
								$elm$html$Html$Events$onCheck(
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldSelectedBool,
									A2($author$project$App$Page$Proponent$PageQuestions$InputAnswer, depIdx, answer))),
								$elm$html$Html$Attributes$value(template.as)
							]),
						_List_Nil),
						$elm$html$Html$text(template.jh)
					]))
			]);
	});
var $author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeOptionWithComplement = F4(
	function (depIdx, fullQuestion, template, answer) {
		var placeholderText = answer.dF ? 'Sua resposta aqui' : '';
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$for(
						_Utils_ap(
							template.as,
							$elm$core$String$fromInt(
								function () {
									if (!depIdx.$) {
										return -1;
									} else {
										var idx = depIdx.a;
										return idx;
									}
								}())))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(
								_Utils_ap(
									template.as,
									$elm$core$String$fromInt(
										function () {
											if (!depIdx.$) {
												return -1;
											} else {
												var idx = depIdx.a;
												return idx;
											}
										}()))),
								$elm$html$Html$Attributes$name(
								_Utils_ap(
									template.as,
									$elm$core$String$fromInt(
										function () {
											if (!depIdx.$) {
												return -1;
											} else {
												var idx = depIdx.a;
												return idx;
											}
										}()))),
								$elm$html$Html$Attributes$type_('radio'),
								$elm$html$Html$Attributes$checked(answer.dF),
								A2($author$project$App$Page$Proponent$PageQuestions$onRadioChange, depIdx, fullQuestion),
								$elm$html$Html$Attributes$value(template.as)
							]),
						_List_Nil),
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$placeholder(placeholderText),
								$elm$html$Html$Attributes$type_('text'),
								$elm$html$Html$Events$onInput(
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A2($author$project$App$Page$Proponent$PageQuestions$InputAnswer, depIdx, answer))),
								$elm$html$Html$Attributes$value(answer.T)
							]),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeString = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						A5(
							$author$project$App$Page$Proponent$PageQuestions$setupMask,
							depIdx,
							fullQuestion.hG,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('text'),
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A2($author$project$App$Page$Proponent$PageQuestions$InputAnswer, depIdx, answer)))
								])),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Proponent$PageQuestions$formatDate = function (date) {
	var dateReplaced = A3(
		$elm$core$String$replace,
		'/',
		'',
		A3($elm$core$String$replace, '-', '', date));
	var day = A3($elm$core$String$slice, 0, 2, dateReplaced);
	var month = A3($elm$core$String$slice, 2, 4, dateReplaced);
	var year = A3($elm$core$String$slice, 4, 8, dateReplaced);
	return (year !== '') ? (day + ('/' + (month + ('/' + year)))) : ((month !== '') ? (day + ('/' + month)) : day);
};
var $author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeTableRowDesktop = F3(
	function (depIdx, template, answer) {
		var theadContent = $elm$core$List$reverse(
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$th,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'role', 'columnheader')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Ações')
							]))
					]),
				$elm$core$List$reverse(
					A2(
						$elm$core$List$map,
						function (l) {
							return A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'role', 'columnheader')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(l)
									]));
						},
						A2($elm$core$String$split, ',', template.jh)))));
		var tableCell = F3(
			function (idx, header, v) {
				return (header === 'Nascimento') ? A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'role', 'cell')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$placeholder(header),
									$elm$html$Html$Attributes$type_('text'),
									$elm$html$Html$Attributes$value(
									$author$project$App$Page$Proponent$PageQuestions$formatDate(v)),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										A2($author$project$App$Data$Answer$FieldTableRow, idx, header),
										A2($author$project$App$Page$Proponent$PageQuestions$InputAnswer, depIdx, answer)))
								]),
							_List_Nil)
						])) : A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'role', 'cell')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$placeholder(header),
									$elm$html$Html$Attributes$type_('text'),
									$elm$html$Html$Attributes$value(v),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										A2($author$project$App$Data$Answer$FieldTableRow, idx, header),
										A2($author$project$App$Page$Proponent$PageQuestions$InputAnswer, depIdx, answer)))
								]),
							_List_Nil)
						]));
			});
		var tableRow = function (_v1) {
			var idx = _v1.a;
			var row = _v1.b;
			return A2(
				$elm$html$Html$tr,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'row')
					]),
				$elm$core$List$reverse(
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'role', 'cell')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick(
												A3(
													$author$project$App$Page$Proponent$PageQuestions$InputAnswer,
													depIdx,
													answer,
													$author$project$App$Data$Answer$FieldTableRowRemove(idx)))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Remover')
											]))
									]))
							]),
						$elm$core$List$reverse(
							A2(
								$elm$core$List$map,
								function (header) {
									return A3(
										tableCell,
										idx,
										header,
										A2(
											$elm$core$Maybe$withDefault,
											'',
											A2($elm$core$Dict$get, header, row)));
								},
								A2($elm$core$String$split, ',', template.jh))))));
		};
		var tbodyContent = function () {
			var _v0 = answer.G;
			if (_v0.$ === 1) {
				return _List_Nil;
			} else {
				var tableRows = _v0.a;
				return A2(
					$elm$core$List$map,
					tableRow,
					$elm$core$Dict$toList(tableRows));
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$thead,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'rowgroup')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tr,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'role', 'row')
							]),
						theadContent)
					])),
				A2(
				$elm$html$Html$tbody,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'rowgroup')
					]),
				tbodyContent)
			]);
	});
var $author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeTextBox = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'flex-direction', 'column'),
						A2($elm$html$Html$Attributes$style, 'padding-top', '20px')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$textarea,
						A5(
							$author$project$App$Page$Proponent$PageQuestions$setupMask,
							depIdx,
							fullQuestion.hG,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A2($author$project$App$Page$Proponent$PageQuestions$InputAnswer, depIdx, answer))),
									$elm$html$Html$Attributes$class('text-box'),
									A2($elm$html$Html$Attributes$style, 'min-width', '400px')
								])),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplate = F5(
	function (depIdx, stmt, fullQuestion, template, answer) {
		var _v0 = template.j5;
		switch (_v0) {
			case 0:
				return _List_Nil;
			case 2:
				return A4($author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeString, depIdx, fullQuestion, template, answer);
			case 3:
				return A4($author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeNumeric, depIdx, fullQuestion, template, answer);
			case 5:
				return A4($author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeDate, depIdx, fullQuestion, template, answer);
			case 1:
				return A4($author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeOption, depIdx, fullQuestion, template, answer);
			case 6:
				return A3($author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeOptionCheck, depIdx, template, answer);
			case 4:
				return A4($author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeOptionWithComplement, depIdx, fullQuestion, template, answer);
			case 7:
				return A3($author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeTableRowDesktop, depIdx, template, answer);
			default:
				return A4($author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeTextBox, depIdx, fullQuestion, template, answer);
		}
	});
var $author$project$App$Page$Proponent$PageQuestions$viewErrors = F2(
	function (questionId, model) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Maybe$withDefault(_List_Nil),
				A2(
					$elm$core$Maybe$map,
					$elm$core$List$head,
					A2(
						$elm$core$Maybe$map,
						$elm$core$List$map(
							function (_v0) {
								var err = _v0.b;
								return _List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('material-icons')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('error_outline')
											])),
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(err)
											]))
									]);
							}),
						A2(
							$elm$core$Maybe$map,
							$elm$core$Dict$toList,
							A2($elm$core$Dict$get, questionId, model.fp))))));
	});
var $author$project$App$Page$Proponent$PageQuestions$viewToggleWithComplement = F5(
	function (depIdx, fullQuestion, yesTemplate, noTemplate, stmt) {
		var yesAnswer = A2($author$project$App$Page$Proponent$PageQuestions$getAnswerForTemplate, stmt, yesTemplate);
		var otherTemplate = yesAnswer.dF ? noTemplate : yesTemplate;
		var noAnswer = A2($author$project$App$Page$Proponent$PageQuestions$getAnswerForTemplate, stmt, noTemplate);
		var otherAnswer = yesAnswer.dF ? noAnswer : yesAnswer;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('toggle-div')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('toggle-div'),
								A2($elm$html$Html$Attributes$style, 'flex-direction', 'row')
							]),
						_List_fromArray(
							[
								yesAnswer.dF ? A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$style, 'padding', '4px'),
										A2($elm$html$Html$Attributes$style, 'color', '#CECECE')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Não')
									])) : A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$style, 'padding', '4px')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Não')
									])),
								A2(
								$elm$html$Html$label,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('toggle')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$name(
												$elm$core$String$fromInt(fullQuestion.hG.bB) + ('.' + $elm$core$String$fromInt(
													function () {
														if (!depIdx.$) {
															return -1;
														} else {
															var idx = depIdx.a;
															return idx;
														}
													}()))),
												$elm$html$Html$Attributes$type_('checkbox'),
												$elm$html$Html$Events$onClick(
												A3($author$project$App$Page$Proponent$PageQuestions$InputSingleChoice, depIdx, fullQuestion, otherTemplate.as)),
												$elm$html$Html$Attributes$checked(yesAnswer.dF)
											]),
										_List_Nil),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('toggle-slider')
											]),
										_List_Nil)
									])),
								yesAnswer.dF ? A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$style, 'padding', '4px')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Sim')
									])) : A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$style, 'padding', '4px'),
										A2($elm$html$Html$Attributes$style, 'color', '#CECECE')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Sim')
									]))
							])),
						yesAnswer.dF ? A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value(yesAnswer.T),
								$elm$html$Html$Events$onInput(
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A2($author$project$App$Page$Proponent$PageQuestions$InputAnswer, depIdx, yesAnswer))),
								$elm$html$Html$Attributes$placeholder('Sim, ')
							]),
						_List_Nil) : $elm$html$Html$text('')
					]))
			]);
	});
var $author$project$App$Page$Proponent$PageQuestions$viewQuestionCommon = F4(
	function (stmts, model, st, fullQuestion) {
		var questionTitle = fullQuestion.hG.bP;
		var isToggleWithComplement = ($elm$core$List$length(fullQuestion.bY) === 2) && (A2(
			$elm$core$List$any,
			function (t) {
				return t.j5 === 4;
			},
			fullQuestion.bY) && A2(
			$elm$core$List$any,
			function (t) {
				return t.j5 === 1;
			},
			fullQuestion.bY));
		var errorIdxs = A3(
			$elm$core$Dict$foldr,
			F3(
				function (depIdx, stmt, result) {
					return A2(
						$elm$core$List$append,
						result,
						_List_fromArray(
							[
								$author$project$App$Data$Statement$depIndexToString(
								$author$project$App$Data$Statement$depIndexFromInt(depIdx)) + ('_' + fullQuestion.hG.as)
							]));
				}),
			_List_Nil,
			model.fX);
		var hasErrorOnQuestionErrorIdx = A3(
			$elm$core$List$foldr,
			F2(
				function (e, b) {
					return b || A2($author$project$App$Page$Proponent$PageQuestions$hasErrorOnQuestion, e, model);
				}),
			false,
			errorIdxs);
		var idErrorIdx = A2(
			$elm$core$List$map,
			function (e) {
				return $elm$html$Html$Attributes$id(e);
			},
			errorIdxs);
		var viewErrorsErrorIdx = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (e) {
					return A2($author$project$App$Page$Proponent$PageQuestions$viewErrors, e, model);
				},
				errorIdxs));
		var disableFlex = A2(
			$elm$core$List$any,
			function (t) {
				return (t.ay === 10) || (t.ay === 20);
			},
			fullQuestion.bY) || (st.ia.h8.b4.iR === 'Centauro-ON');
		var templates = function () {
			if (isToggleWithComplement && st.ia.h8.cJ.gX) {
				var yesTemplate = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$AnswerTemplate$emptyModel,
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (t) {
								return t.j5 === 4;
							},
							fullQuestion.bY)));
				var noTemplate = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$AnswerTemplate$emptyModel,
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (t) {
								return t.j5 === 1;
							},
							fullQuestion.bY)));
				return A3(
					$elm$core$Dict$foldl,
					F3(
						function (depIdx, stmt, result) {
							return A2(
								$elm$core$List$append,
								result,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_Nil,
										A2(
											$elm$core$List$append,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$style, 'width', '50%')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$h3,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text(stmt.dw.B)
																]))
														]))
												]),
											A5(
												$author$project$App$Page$Proponent$PageQuestions$viewToggleWithComplement,
												$author$project$App$Data$Statement$depIndexFromInt(depIdx),
												fullQuestion,
												yesTemplate,
												noTemplate,
												stmt)))
									]));
						}),
					_List_Nil,
					stmts);
			} else {
				return A3(
					$elm$core$Dict$foldl,
					F3(
						function (depIdx, stmt, result) {
							var showName = st.ia.h8.cJ.gX ? A2(
								$elm$html$Html$h3,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(stmt.dw.B)
									])) : $elm$html$Html$text('');
							var displayMode = disableFlex ? A2($elm$html$Html$Attributes$style, 'display', 'block') : A2($elm$html$Html$Attributes$style, 'display', 'flex');
							return A2(
								$elm$core$List$append,
								result,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[displayMode]),
										A2(
											$elm$core$List$append,
											_List_fromArray(
												[showName]),
											$elm$core$List$concat(
												A2(
													$elm$core$List$map,
													function (t) {
														return A5(
															$author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplate,
															$author$project$App$Data$Statement$depIndexFromInt(depIdx),
															stmt,
															fullQuestion,
															t,
															A2($author$project$App$Page$Proponent$PageQuestions$getAnswerForTemplate, stmt, t));
													},
													fullQuestion.bY))))
									]));
						}),
					_List_Nil,
					stmts);
			}
		}();
		return _List_fromArray(
			[
				A2($elm$html$Html$a, idErrorIdx, _List_Nil),
				A2(
				$elm$html$Html$article,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('error', hasErrorOnQuestionErrorIdx)
							]))
					]),
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(questionTitle)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('response-container')
								]),
							templates)
						]),
					viewErrorsErrorIdx))
			]);
	});
var $author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeQuestionTable = F3(
	function (depIdx, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('add_button'),
						$elm$html$Html$Events$onClick(
						A3(
							$author$project$App$Page$Proponent$PageQuestions$InputAnswer,
							depIdx,
							answer,
							$author$project$App$Data$Answer$FieldTableRowAdd(
								A2($elm$core$String$split, ',', template.jh))))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('adicionar outro')
					]))
			]);
	});
var $author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeTableRowMobile = F3(
	function (depIdx, template, answer) {
		var tableCell = F3(
			function (idx, header, v) {
				return A2(
					$elm$html$Html$tr,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'role', 'row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$th,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'role', 'columnheader')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(header)
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'role', 'cell')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder(header),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$value(v),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeR,
												A2($author$project$App$Data$Answer$FieldTableRow, idx, header),
												A2($author$project$App$Page$Proponent$PageQuestions$InputAnswer, depIdx, answer)))
										]),
									_List_Nil)
								]))
						]));
			});
		var tableRowInternal = function (_v1) {
			var idx = _v1.a;
			var row = _v1.b;
			return A2(
				$elm$core$List$map,
				function (header) {
					return A3(
						tableCell,
						idx,
						header,
						A2(
							$elm$core$Maybe$withDefault,
							'',
							A2($elm$core$Dict$get, header, row)));
				},
				A2($elm$core$String$split, ',', template.jh));
		};
		var tableRow = function (r) {
			var _v0 = tableRowInternal(r);
			if (_v0.b) {
				var head = _v0.a;
				var body = _v0.b;
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mobile_table'),
								A2($elm$html$Html$Attributes$attribute, 'role', 'table')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'role', 'rowgroup')
									]),
								_List_fromArray(
									[head])),
								A2(
								$elm$html$Html$tbody,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'role', 'rowgroup')
									]),
								body)
							]))
					]);
			} else {
				return _List_Nil;
			}
		};
		var tbodyContent = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$map(tableRow),
				A2($elm$core$Maybe$map, $elm$core$Dict$toList, answer.G)));
		return $elm$core$List$concat(tbodyContent);
	});
var $author$project$App$Page$Proponent$PageQuestions$viewTableDesktop = F4(
	function (question, template, answer, content) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('desktop_table'),
						A2($elm$html$Html$Attributes$attribute, 'role', 'table')
					]),
				content)
			]);
	});
var $author$project$App$Page$Proponent$PageQuestions$viewQuestionTable = F4(
	function (stmts, model, st, fullQuestion) {
		var templatesMobile = A3(
			$elm$core$Dict$foldr,
			F3(
				function (depIdx, stmt, result) {
					return A2(
						$elm$core$List$append,
						result,
						$elm$core$List$concat(
							A2(
								$elm$core$List$map,
								function (t) {
									return A3(
										$author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeTableRowMobile,
										$author$project$App$Data$Statement$depIndexFromInt(depIdx),
										t,
										A2($author$project$App$Page$Proponent$PageQuestions$getAnswerForTemplate, stmt, t));
								},
								fullQuestion.bY)));
				}),
			_List_Nil,
			model.fX);
		var template = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$AnswerTemplate$emptyModel,
			$elm$core$List$head(fullQuestion.bY));
		var templatesDesktop = A3(
			$elm$core$Dict$foldr,
			F3(
				function (depIdx, stmt, result) {
					var answer = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$Answer$emptyModel,
						A2($elm$core$Dict$get, template.as, stmt.aZ));
					return A2(
						$elm$core$List$append,
						result,
						A4(
							$author$project$App$Page$Proponent$PageQuestions$viewTableDesktop,
							fullQuestion.hG,
							template,
							answer,
							$elm$core$List$concat(
								A2(
									$elm$core$List$map,
									function (t) {
										return A3(
											$author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeTableRowDesktop,
											$author$project$App$Data$Statement$depIndexFromInt(depIdx),
											t,
											A2($author$project$App$Page$Proponent$PageQuestions$getAnswerForTemplate, stmt, t));
									},
									fullQuestion.bY))));
				}),
			_List_Nil,
			model.fX);
		var questionTitle = model.eU ? fullQuestion.hG.bP : fullQuestion.hG.bP;
		var questionTables = A3(
			$elm$core$Dict$foldr,
			F3(
				function (depIdx, stmt, result) {
					var answer = A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$Answer$emptyModel,
						A2($elm$core$Dict$get, template.as, stmt.aZ));
					return A2(
						$elm$core$List$append,
						result,
						A3(
							$author$project$App$Page$Proponent$PageQuestions$viewAnswerTemplateTypeQuestionTable,
							$author$project$App$Data$Statement$depIndexFromInt(depIdx),
							template,
							answer));
				}),
			_List_Nil,
			model.fX);
		var errorIdxs = A3(
			$elm$core$Dict$foldr,
			F3(
				function (depIdx, stmt, result) {
					return A2(
						$elm$core$List$append,
						result,
						_List_fromArray(
							[
								$author$project$App$Data$Statement$depIndexToString(
								$author$project$App$Data$Statement$depIndexFromInt(depIdx)) + ('_' + fullQuestion.hG.as)
							]));
				}),
			_List_Nil,
			model.fX);
		var hasErrorOnQuestionErrorIdx = A3(
			$elm$core$List$foldr,
			F2(
				function (e, b) {
					return b || A2($author$project$App$Page$Proponent$PageQuestions$hasErrorOnQuestion, e, model);
				}),
			false,
			errorIdxs);
		var idErrorIdx = A2(
			$elm$core$List$map,
			function (e) {
				return $elm$html$Html$Attributes$id(e);
			},
			errorIdxs);
		var viewErrorsErrorIdx = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (e) {
					return A2($author$project$App$Page$Proponent$PageQuestions$viewErrors, e, model);
				},
				errorIdxs));
		return _List_fromArray(
			[
				A2($elm$html$Html$a, idErrorIdx, _List_Nil),
				A2(
				$elm$html$Html$article,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('error', hasErrorOnQuestionErrorIdx)
							]))
					]),
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(questionTitle)
								]))
						]),
					_Utils_ap(
						templatesDesktop,
						_Utils_ap(
							templatesMobile,
							_Utils_ap(questionTables, viewErrorsErrorIdx)))))
			]);
	});
var $author$project$App$Page$Proponent$PageQuestions$viewSectionQuestion = F4(
	function (stmts, model, st, fullQuestion) {
		var _v0 = fullQuestion.hG.j5;
		switch (_v0) {
			case 0:
				return _List_Nil;
			case 1:
				return A4($author$project$App$Page$Proponent$PageQuestions$viewQuestionCommon, stmts, model, st, fullQuestion);
			case 2:
				return A4($author$project$App$Page$Proponent$PageQuestions$viewQuestionCommon, stmts, model, st, fullQuestion);
			case 3:
				return A4($author$project$App$Page$Proponent$PageQuestions$viewQuestionCommon, stmts, model, st, fullQuestion);
			case 4:
				return A4($author$project$App$Page$Proponent$PageQuestions$viewQuestionTable, stmts, model, st, fullQuestion);
			default:
				return A4($author$project$App$Page$Proponent$PageQuestions$viewQuestionCommon, stmts, model, st, fullQuestion);
		}
	});
var $author$project$App$Page$Proponent$PageQuestions$viewSection = F3(
	function (fullSection, st, model) {
		var stmts = A2(
			$elm$core$List$any,
			function (_v0) {
				var i = _v0.a;
				var stmt = _v0.b;
				return stmt.e.j_ === 2;
			},
			$elm$core$Dict$toList(model.fX)) ? A2(
			$elm$core$Dict$filter,
			F2(
				function (i, stmt) {
					return i >= 0;
				}),
			model.fX) : model.fX;
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$article,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm$core$String$fromInt(fullSection.hX.bB) + ('. ' + (fullSection.hX.hl + ':')))
								]))
						]))
				]),
			_Utils_ap(
				$elm$core$List$concat(
					A2(
						$elm$core$List$map,
						A3($author$project$App$Page$Proponent$PageQuestions$viewSectionQuestion, stmts, model, st),
						A2(
							$elm$core$List$sortBy,
							function (s) {
								return s.hG.bB;
							},
							fullSection.eI))),
				A3($author$project$App$Page$Proponent$PageQuestions$viewFooter, st, fullSection, stmts)));
	});
var $author$project$App$Page$Proponent$PageQuestions$view = F2(
	function (st, model) {
		var current = A2($elm$core$Dict$get, st.ia.h8.g.e.bG, model.cA);
		if (!current.$) {
			var fullSection = current.a;
			return A3($author$project$App$Page$Proponent$PageQuestions$viewSection, fullSection, st, model);
		} else {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$article,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Carregando o questionário...')
								]))
						]))
				]);
		}
	});
var $author$project$App$Page$Proponent$PageScreening$view = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$article,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Triagem Automática')
						])),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$h2,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Aguarde enquanto fazemos o processamento do questionário.')
						]))
				]))
		]);
};
var $elm$core$String$toUpper = _String_toUpper;
var $author$project$App$Page$Proponent$PageWaitingInLine$viewDoctorsOnline = F2(
	function (model, fullStmt) {
		var waitingLineText = A2(
			$elm$core$List$map,
			function (t) {
				return A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(t)
						]));
			},
			fullStmt.cJ.e0);
		var doctorList = A2(
			$elm$core$List$map,
			function (doctor) {
				return A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-item')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									$author$project$App$Data$Person$photoUrl(doctor))
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									A2($elm$core$Basics$composeR, $author$project$App$Data$Person$fullName, $elm$core$String$toUpper)(doctor))
								]))
						]));
			},
			model.gJ);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$article,
				_List_Nil,
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Por favor aguarde o seu horário de atendimento. ')
								])),
							A2($elm$html$Html$br, _List_Nil, _List_Nil),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									'No momento temos ' + ($elm$core$String$fromInt(
										$elm$core$List$length(model.gJ)) + ' médico(s) online.'))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									A2($elm$html$Html$ul, _List_Nil, doctorList)
								]))
						]),
					_Utils_ap(
						waitingLineText,
						_Utils_ap(
							(!_Utils_eq(model.de, $elm$core$Maybe$Nothing)) ? _List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											A2($elm$core$Maybe$withDefault, '', model.de))
										]))
								]) : _List_Nil,
							_List_fromArray(
								[
									$author$project$App$Data$Jitsi$view(
									{i$: model.P.g.dw.B, jg: '', eO: model.P.g.e.as})
								])))))
			]);
	});
var $author$project$App$Page$Proponent$PageWaitingInLine$viewNoDoctorOnline = F2(
	function (model, fullStmt) {
		var waitingLineText = A2(
			$elm$core$List$map,
			function (t) {
				return A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(t)
						]));
			},
			fullStmt.cJ.ex);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$article,
				_List_Nil,
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Por favor aguarde o seu horário de atendimento')
								]))
						]),
					_Utils_ap(
						waitingLineText,
						(!_Utils_eq(model.de, $elm$core$Maybe$Nothing)) ? _List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($elm$core$Maybe$withDefault, '', model.de))
									]))
							]) : _Utils_ap(
							_List_Nil,
							_List_fromArray(
								[
									$author$project$App$Data$Jitsi$view(
									{i$: model.P.g.dw.B, jg: '', eO: model.P.g.e.as})
								])))))
			]);
	});
var $author$project$App$Page$Proponent$PageWaitingInLine$viewOutOfOffice = F2(
	function (model, fullStmt) {
		var waitingLineText = A2(
			$elm$core$List$map,
			function (t) {
				return A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(t)
						]));
			},
			fullStmt.cJ.eB);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$article,
				_List_Nil,
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Por favor aguarde o seu horário de atendimento')
								]))
						]),
					_Utils_ap(
						waitingLineText,
						(!_Utils_eq(model.de, $elm$core$Maybe$Nothing)) ? _List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($elm$core$Maybe$withDefault, '', model.de))
									]))
							]) : _List_Nil)))
			]);
	});
var $author$project$App$Page$Proponent$PageWaitingInLine$view = F2(
	function (st, model) {
		var now = $author$project$App$Service$ApiWebSocket$getTimestamp(st._);
		var isWorkingTime = true;
		var fullStmt = st.ia.h8;
		var _v0 = _Utils_Tuple2(
			isWorkingTime,
			$elm$core$List$length(model.gJ));
		_v0$2:
		while (true) {
			if (_v0.a) {
				if (!_v0.b) {
					return A2($author$project$App$Page$Proponent$PageWaitingInLine$viewNoDoctorOnline, model, fullStmt);
				} else {
					break _v0$2;
				}
			} else {
				if (!_v0.b) {
					return A2($author$project$App$Page$Proponent$PageWaitingInLine$viewOutOfOffice, model, fullStmt);
				} else {
					break _v0$2;
				}
			}
		}
		return A2($author$project$App$Page$Proponent$PageWaitingInLine$viewDoctorsOnline, model, fullStmt);
	});
var $author$project$App$Page$Proponent$PageWaitingResults$view = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$article,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Atendimento realizado com sucesso.')
						])),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Seu atendimento foi realizado com sucesso.')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Agora o médico que lhe atendeu  fará a análise final e encaminhará o resultado para a ' + (model.a5.b4.iR + '. Em breve você será contactado.'))
						]))
				]))
		]);
};
var $author$project$App$Page$Proponent$PageWaitingRoom$view = _List_fromArray(
	[
		A2(
		$elm$html$Html$article,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Aguarde enquanto criamos a sala de vídeo conferência...')
					]))
			]))
	]);
var $author$project$App$Page$PageProponent$view = F2(
	function (st, model) {
		var pageContent = function () {
			var _v0 = model.aQ;
			switch (_v0.$) {
				case 0:
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$App$Page$PageProponent$LoadingMsg),
						$author$project$App$Page$Proponent$PageLoading$view);
				case 2:
					var eModel = _v0.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$App$Page$PageProponent$ErrorLoadingMsg),
						$author$project$App$Page$Proponent$PageError$view(eModel));
				case 3:
					var iModel = _v0.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$App$Page$PageProponent$IntroMsg),
						A2($author$project$App$Page$Proponent$PageIntro$view, st, iModel));
				case 1:
					var iModel = _v0.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$App$Page$PageProponent$IntroMsg),
						A2($author$project$App$Page$Proponent$PageIntro$view, st, iModel));
				case 4:
					var iModel = _v0.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$App$Page$PageProponent$PaymentMsg),
						A2($author$project$App$Page$Proponent$PagePayment$view, st, iModel));
				case 5:
					return $author$project$App$Page$Proponent$PageWaitingRoom$view;
				case 6:
					var qModel = _v0.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$App$Page$PageProponent$QuestionsMsg),
						A2($author$project$App$Page$Proponent$PageQuestions$view, st, qModel));
				case 8:
					var cModel = _v0.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$App$Page$PageProponent$ConfirmMsg),
						A2($author$project$App$Page$Proponent$PageConfirmInterview$view, st, cModel));
				case 7:
					var sModel = _v0.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$App$Page$PageProponent$ScreeningMsg),
						$author$project$App$Page$Proponent$PageScreening$view(sModel));
				case 9:
					var wModel = _v0.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$App$Page$PageProponent$EnteringWaitingLineMsg),
						A2($author$project$App$Page$Proponent$PageEnteringWaitingLine$view, st, wModel));
				case 10:
					var wModel = _v0.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$App$Page$PageProponent$WaitingInLineMsg),
						A2($author$project$App$Page$Proponent$PageWaitingInLine$view, st, wModel));
				case 11:
					var dModel = _v0.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$App$Page$PageProponent$DoctorReviewMsg),
						$author$project$App$Page$Proponent$PageDoctorReview$view(dModel));
				case 12:
					var iModel = _v0.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$App$Page$PageProponent$InterviewMsg),
						$author$project$App$Page$Proponent$PageInterview$view(iModel));
				case 13:
					var rModel = _v0.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$App$Page$PageProponent$WaitingResultsMsg),
						$author$project$App$Page$Proponent$PageWaitingResults$view(rModel));
				case 14:
					var fModel = _v0.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$App$Page$PageProponent$FinishedPropositionMsg),
						$author$project$App$Page$Proponent$PageFinishedProposition$view(fModel));
				default:
					var fModel = _v0.a;
					return A2(
						$elm$core$List$map,
						$elm$html$Html$map($author$project$App$Page$PageProponent$NotActivatedMsg),
						$author$project$App$Page$Proponent$PageNotActivated$view(fModel));
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$header,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('logo')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$h1,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'margin-right', '20px'),
								A2($elm$html$Html$Attributes$style, 'margin-left', '20px')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(st.ia.h8.cJ.io)
							])),
						A2(
						$elm$html$Html$h2,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(st.ia.h8.cJ.$9)
							]))
					])),
				A2($elm$html$Html$section, _List_Nil, pageContent),
				A2(
				$elm$html$Html$map,
				$author$project$App$Page$PageProponent$VideoMsg,
				$author$project$App$Components$Video$viewWithMappedMsgs(st.f4))
			]);
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$CancelReturnToCompany = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$ConfirmReturnToCompany = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputObservationField = F3(
	function (a, b, c) {
		return {$: 10, a: a, b: b, c: c};
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$dialogFromModel = F2(
	function (model, st) {
		var header = _List_Nil;
		var footer = function (filter) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('cancel-button'),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Reviewer$EditStatement$StatementEdit$CancelReturnToCompany(model))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Cancelar')
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$ConfirmReturnToCompany, model.as, filter))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Devolver')
						]))
				]);
		};
		var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
		var body = function (filter) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$textarea,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$cols(50),
							$elm$html$Html$Attributes$rows(5),
							$elm$html$Html$Attributes$value(model.h8.g.e.jx),
							$elm$html$Html$Events$onInput(
							function (str) {
								return A3(
									$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputObservationField,
									model.as,
									filter,
									$author$project$App$Data$Statement$FieldObservation(str));
							})
						]),
					_List_Nil)
				]);
		};
		var completeConfig = function (filter) {
			return _Utils_update(
				config,
				{
					e5: $elm$core$Maybe$Just(
						body(filter)),
					ft: $elm$core$Maybe$Just(
						footer(filter)),
					gY: $elm$core$Maybe$Just(header),
					f1: 'Motivo da Devolução'
				});
		};
		return model.dC ? $author$project$App$Components$Dialog$view(
			$elm$core$Maybe$Just(
				completeConfig(model.b))) : $elm$html$Html$text('');
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$CloseNotification = {$: 32};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$notificationFromModel = function (model) {
	var notificationMessage = function () {
		var _v0 = model.F;
		if (!_v0.$) {
			var notificationModel = _v0.a;
			return notificationModel.jl;
		} else {
			return '';
		}
	}();
	var header = _List_Nil;
	var footer = _List_fromArray(
		[
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$EditStatement$StatementEdit$CloseNotification)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Ok')
				]))
		]);
	var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
	var body = _List_fromArray(
		[
			$elm$html$Html$text(notificationMessage)
		]);
	var completeConfig = _Utils_update(
		config,
		{
			e5: $elm$core$Maybe$Just(body),
			ft: $elm$core$Maybe$Just(footer),
			gY: $elm$core$Maybe$Just(header),
			f1: 'Resultado da Operação'
		});
	return (!_Utils_eq(model.F, $elm$core$Maybe$Nothing)) ? $author$project$App$Components$Dialog$view(
		$elm$core$Maybe$Just(completeConfig)) : $elm$html$Html$text('');
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$Error = 2;
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewErrors = F2(
	function (st, model) {
		var _v0 = A2($author$project$App$Service$ApplicationResources$getNewFullStatementForCompanyResponse, model.gx, st._);
		if (_v0.$ === 1) {
			var _v1 = _v0.a;
			var specificError = _v1.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$App$Service$SpecificResources$errorToMessage(specificError))
					]));
		} else {
			return (!$author$project$App$Page$Reviewer$EditStatement$StatementEdit$errorsDictIsEmpty(model.fp)) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('')
					])) : ((model.cH === 1) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Cadastrando atendimento...')
					])) : ((model.cH === 2) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Ocorreu um erro ao tentar cadastrar o atendimento.')
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Tente novamente em alguns instantes.')
							]))
					])) : $elm$html$Html$text('')));
		}
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$GoToIncomplete = {$: 2};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$Save = {$: 12};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$ToggleEditStatement = {$: 31};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$AddDependent = {$: 20};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$PostPoneStatement = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$ReturnToCompany = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$ReturnToConfirmInterview = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$ReturnToDoctor = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$ReturnToWaitingForRoom = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$UpdateNewDueDate = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$documentIsValid = F2(
	function (document, api) {
		var _v0 = A2($author$project$App$Service$ApplicationResources$getPersonByDocumentError, document, api);
		_v0$2:
		while (true) {
			if (!_v0.$) {
				switch (_v0.a.a.$) {
					case 4:
						var _v1 = _v0.a;
						return false;
					case 2:
						var _v2 = _v0.a;
						return true;
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return $author$project$App$Data$Person$isDocumentValid(document);
	});
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$AddDocument = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$ChooseVideo = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$DeleteDocument = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$viewDocumentContent = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer'),
				A2($elm$html$Html$Attributes$style, 'line-height', 'normal')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						(doc.dr === 'video/mp4') ? A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Reviewer$EditStatement$DocumentView$ChooseVideo(doc)),
								$elm$html$Html$Attributes$value('Escolher Vídeo')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Escolher Vídeo')
							])) : $elm$html$Html$text(''),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Reviewer$EditStatement$DocumentView$DeleteDocument(doc.as)),
								$elm$html$Html$Attributes$value('Apagar Documento')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Apagar Documento')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								$elm$html$Html$Events$onClick(
								A2($author$project$App$Page$Reviewer$EditStatement$DocumentView$AddDocument, doc.jJ, doc)),
								$elm$html$Html$Attributes$value('Adicionar Documento')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Adicionar Documento')
							]))
					]))
			]));
};
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$viewSelected = F2(
	function (_v0, sharedState) {
		var fullStatement = _v0.a5;
		var now = _v0.ey;
		var currentTab = _v0.b5;
		var doctor = _v0.bu;
		if (!currentTab.$) {
			var idx = currentTab.a;
			return _List_fromArray(
				[
					$author$project$App$Page$Reviewer$EditStatement$DocumentView$viewDocumentContent(
					A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$Document$emptyModel,
						A2($elm$core$Dict$get, idx, fullStatement.an)))
				]);
		} else {
			return _List_Nil;
		}
	});
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$ChangeToTab = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$viewDocumentTab = F3(
	function (currentTab, idx, doc) {
		var _v0 = doc.dr;
		switch (_v0) {
			case 'video/webm':
				return $elm$html$Html$text('');
			case 'video/mp4':
				return A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'tab-selected',
									_Utils_eq(
										currentTab,
										$author$project$App$Page$Reviewer$EditStatement$DocumentView$TabDocument(idx)))
								])),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Reviewer$EditStatement$DocumentView$ChangeToTab(
								$author$project$App$Page$Reviewer$EditStatement$DocumentView$TabDocument(idx)))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Video')
						]));
			default:
				return A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'tab-selected',
									_Utils_eq(
										currentTab,
										$author$project$App$Page$Reviewer$EditStatement$DocumentView$TabDocument(idx)))
								])),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Reviewer$EditStatement$DocumentView$ChangeToTab(
								$author$project$App$Page$Reviewer$EditStatement$DocumentView$TabDocument(idx)))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							function () {
								var _v1 = doc.b7;
								switch (_v1) {
									case 1:
										return 'Parecer';
									case 3:
										return 'Atestado';
									case 4:
										return 'Receita';
									default:
										return 'Anexo';
								}
							}())
						]));
		}
	});
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$viewDocumentTabNumered = F4(
	function (currentTab, idx, doc, documentTabs) {
		var index = $elm$core$List$length(documentTabs) + 1;
		return A2(
			$elm$core$List$append,
			documentTabs,
			function () {
				var _v0 = doc.dr;
				switch (_v0) {
					case 'video/webm':
						return _List_fromArray(
							[
								$elm$html$Html$text('')
							]);
					case 'video/mp4':
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Reviewer$EditStatement$DocumentView$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Reviewer$EditStatement$DocumentView$ChangeToTab(
											$author$project$App$Page$Reviewer$EditStatement$DocumentView$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										'Video' + $elm$core$String$fromInt(index))
									]))
							]);
					default:
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Reviewer$EditStatement$DocumentView$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Reviewer$EditStatement$DocumentView$ChangeToTab(
											$author$project$App$Page$Reviewer$EditStatement$DocumentView$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											var _v1 = doc.b7;
											switch (_v1) {
												case 1:
													return 'Parecer Antigo' + $elm$core$String$fromInt(index);
												case 3:
													return 'Atestado' + $elm$core$String$fromInt(index);
												case 4:
													return 'Receita' + $elm$core$String$fromInt(index);
												default:
													return 'Anexo';
											}
										}())
									]))
							]);
				}
			}());
	});
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$viewResultTab = F5(
	function (previewTitle, typeHnsg, currentTab, idx, d) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Reviewer$EditStatement$DocumentView$TabDocument(idx)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Reviewer$EditStatement$DocumentView$ChangeToTab(
							$author$project$App$Page$Reviewer$EditStatement$DocumentView$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(previewTitle)
					])),
				typeHnsg ? A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Reviewer$EditStatement$DocumentView$TabDocument(idx)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Reviewer$EditStatement$DocumentView$ChangeToTab(
							$author$project$App$Page$Reviewer$EditStatement$DocumentView$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Pré-Visualizar Atestado')
					])) : A2($elm$html$Html$span, _List_Nil, _List_Nil)
			]);
	});
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$viewDocumentTabs = F2(
	function (currentTab, docs) {
		var reportDocs = A2(
			$elm$core$List$sortWith,
			F2(
				function (a, b) {
					var _v4 = A2(
						$elm$core$Basics$compare,
						$elm$time$Time$posixToMillis(a.b.iW),
						$elm$time$Time$posixToMillis(b.b.iW));
					switch (_v4) {
						case 0:
							return 2;
						case 1:
							return 1;
						default:
							return 0;
					}
				}),
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v3, doc) {
							return doc.b7 === 1;
						}),
					docs)));
		var previewTitle = 'Parecer';
		var resultTab = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$second,
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$map,
							A3($author$project$App$Page$Reviewer$EditStatement$DocumentView$viewResultTab, previewTitle, false, currentTab),
							$elm$core$Dict$fromList(
								A2($elm$core$List$take, 1, reportDocs)))))));
		var oldResultsTab = A3(
			$elm$core$Dict$foldl,
			$author$project$App$Page$Reviewer$EditStatement$DocumentView$viewDocumentTabNumered(currentTab),
			_List_Nil,
			$elm$core$Dict$fromList(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(reportDocs))));
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$second,
				$elm$core$Dict$toList(
					A2(
						$elm$core$Dict$map,
						$author$project$App$Page$Reviewer$EditStatement$DocumentView$viewDocumentTab(currentTab),
						$elm$core$Dict$fromList(
							A2(
								$elm$core$List$sortWith,
								F2(
									function (a, b) {
										var _v1 = A2(
											$elm$core$Basics$compare,
											$elm$time$Time$posixToMillis(a.b.f2),
											$elm$time$Time$posixToMillis(b.b.f2));
										switch (_v1) {
											case 0:
												return 2;
											case 1:
												return 1;
											default:
												return 0;
										}
									}),
								$elm$core$Dict$toList(
									A2(
										$elm$core$Dict$filter,
										F2(
											function (_v0, doc) {
												return doc.dr === 'video/mp4';
											}),
										docs))))))),
			_Utils_ap(
				oldResultsTab,
				_Utils_ap(
					resultTab,
					A2(
						$elm$core$List$map,
						$elm$core$Tuple$second,
						$elm$core$Dict$toList(
							A2(
								$elm$core$Dict$map,
								$author$project$App$Page$Reviewer$EditStatement$DocumentView$viewDocumentTab(currentTab),
								A2(
									$elm$core$Dict$filter,
									F2(
										function (_v2, doc) {
											return (doc.dr !== 'video/webm') && ((doc.dr !== 'video/mp4') && (doc.b7 !== 1));
										}),
									docs)))))));
	});
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$viewTabs = F2(
	function (currentTab, fullStmt) {
		var commonView = A2($author$project$App$Page$Reviewer$EditStatement$DocumentView$viewDocumentTabs, currentTab, fullStmt.an);
		return commonView;
	});
var $author$project$App$Page$Reviewer$EditStatement$DocumentView$view = F2(
	function (sharedState, model) {
		return A2(
			$elm$html$Html$article,
			_List_Nil,
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$header,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('tabs')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							A2($author$project$App$Page$Reviewer$EditStatement$DocumentView$viewTabs, model.b5, model.a5))
						])),
				A2($author$project$App$Page$Reviewer$EditStatement$DocumentView$viewSelected, model, sharedState)));
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputContactField = F3(
	function (a, b, c) {
		return {$: 19, a: a, b: b, c: c};
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$errorsInField = F3(
	function (field, idx, errors) {
		return A2(
			$elm$core$List$member,
			field,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$elm$core$Dict$get,
					$author$project$App$Data$Statement$depIndexToInt(idx),
					errors)));
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewEditContacts = F3(
	function (mDepIdx, fullStmt, contacts) {
		var submitting = fullStmt.cH === 1;
		var showErrorMessageClass = function (field) {
			return _Utils_Tuple2(
				'error-message',
				A3($author$project$App$Page$Reviewer$EditStatement$StatementEdit$errorsInField, field, mDepIdx, fullStmt.fp));
		};
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('e-mail'),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('email'),
								$elm$html$Html$Attributes$disabled(submitting),
								$elm$html$Html$Attributes$placeholder('email@exemplo.com.br'),
								$elm$html$Html$Attributes$value(
								A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$getContactWithKind, 5, contacts).T),
								$elm$html$Html$Events$onInput(
								A2(
									$elm$core$Basics$composeL,
									A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputContactField, mDepIdx, 5),
									$author$project$App$Data$Contact$FieldValue)),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										showErrorMessageClass(10)
									]))
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Telefone celular'),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('tel'),
										$elm$html$Html$Attributes$disabled(submitting),
										$elm$html$Html$Attributes$placeholder('Celular'),
										$elm$html$Html$Attributes$value(
										$author$project$App$Data$Contact$formatMobile(
											A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$getContactWithKind, 1, contacts).T)),
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputContactField, mDepIdx, 1),
											$author$project$App$Data$Contact$FieldValue)),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												showErrorMessageClass(11)
											])),
										$author$project$App$Tools$FormHelpers$onBackspace(
										A3(
											$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputContactField,
											mDepIdx,
											1,
											$author$project$App$Data$Contact$FieldValue(
												A2(
													$elm$core$String$dropRight,
													1,
													A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$getContactWithKind, 1, contacts).T))))
									]),
								_List_Nil)
							]))
					]))
			]);
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$RemoveDependent = function (a) {
	return {$: 21, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputPersonField = F2(
	function (a, b) {
		return {$: 18, a: a, b: b};
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewEditCPFContent = F3(
	function (mDepIdx, person, api) {
		var isInvalid = !A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$documentIsValid, person.i1, api);
		return A2(
			$elm$html$Html$label,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('CPF'),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$placeholder('Digite o CPF do Cliente'),
									$elm$html$Html$Attributes$type_('text'),
									$elm$html$Html$Attributes$disabled(
									$author$project$App$Data$Id$uuidIsValid(person.as)),
									$elm$html$Html$Attributes$value(
									$author$project$App$Data$Person$formatDocument(person.i1)),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeL,
										$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputPersonField(mDepIdx),
										$author$project$App$Data$Person$FieldDocument)),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('error-message', isInvalid)
										])),
									$author$project$App$Tools$FormHelpers$onBackspace(
									A2(
										$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputPersonField,
										mDepIdx,
										$author$project$App$Data$Person$FieldDocument(
											A2($elm$core$String$dropRight, 1, person.i1))))
								]),
							_List_Nil),
							$elm$html$Html$text('')
						]))
				]));
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewEditPersonDocument = F4(
	function (mDepIdx, person, api, stmtTemplate) {
		var isDisabled = false;
		if (!mDepIdx.$) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$legend,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									stmtTemplate.gX ? 'Titular' : '')
								]))
						])),
					A3($author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewEditCPFContent, mDepIdx, person, api)
				]);
		} else {
			var depIdx = mDepIdx.a;
			return stmtTemplate.gX ? _List_fromArray(
				[
					A2(
					$elm$html$Html$legend,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									'Dependente ' + $elm$core$String$fromInt(depIdx + 1))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('action-buttons')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('action-buttons')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('cancel-button'),
													$elm$html$Html$Attributes$type_('button'),
													$elm$html$Html$Attributes$disabled(isDisabled),
													$elm$html$Html$Events$onClick(
													$author$project$App$Page$Reviewer$EditStatement$StatementEdit$RemoveDependent(depIdx))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Remover')
												]))
										]))
								]))
						])),
					A3($author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewEditCPFContent, mDepIdx, person, api)
				]) : _List_Nil;
		}
	});
var $author$project$App$Data$Statement$FieldScheduleDate = function (a) {
	return {$: 11, a: a};
};
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputStmtField = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$App$Data$Statement$ScheduleDate = 13;
var $author$project$App$Tools$FormHelpers$dateTimeInputWithAttrs = F3(
	function (attrs, date, msg) {
		return _Utils_eq(
			date,
			$elm$time$Time$millisToPosix(0)) ? A2(
			$elm$html$Html$input,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('datetime-local'),
						$elm$html$Html$Events$onInput(msg)
					]),
				attrs),
			_List_Nil) : A2(
			$elm$html$Html$input,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('datetime-local'),
						$elm$html$Html$Events$onInput(msg),
						$elm$html$Html$Attributes$value(
						A3(
							$elm$core$String$slice,
							0,
							16,
							$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(date)))
					]),
				attrs),
			_List_Nil);
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewRelationContent = F3(
	function (idx, editStmt, modelForm) {
		return _List_fromArray(
			[
				A4(
				$author$project$App$Tools$FormHelpers$select,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'error-message',
								A3($author$project$App$Page$Reviewer$EditStatement$StatementEdit$errorsInField, 12, idx, modelForm.fp))
							]))
					]),
				$author$project$App$Data$Statement$relationToString(
					A2($elm$core$Maybe$withDefault, 0, editStmt.e.be)),
				A2(
					$elm$core$Basics$composeL,
					$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputStmtField(idx),
					$author$project$App$Data$Statement$FieldRelation),
				A2(
					$elm$core$List$map,
					function (relation) {
						return _Utils_Tuple2(
							$author$project$App$Data$Statement$relationToString(relation),
							$author$project$App$Data$Statement$relationToText(relation));
					},
					_List_fromArray(
						[0, 1, 2, 3])))
			]);
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewEditPersonInformation = F4(
	function (mDepIdx, editStmt, modelForm, now) {
		var viewRelation = function () {
			if (!mDepIdx.$) {
				return _List_Nil;
			} else {
				return A3($author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewRelationContent, mDepIdx, editStmt, modelForm);
			}
		}();
		var submitting = modelForm.cH === 1;
		var stmt = editStmt.e;
		var stmtTemplate = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$StatementTemplate$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.as, stmt.eY);
					},
					modelForm.eZ)));
		var showErrorMessageClass = function (field) {
			return _Utils_Tuple2(
				'error-message',
				A3($author$project$App$Page$Reviewer$EditStatement$StatementEdit$errorsInField, field, mDepIdx, modelForm.fp));
		};
		var person = editStmt.dw;
		var emptyForm = {
			gx: $author$project$App$Data$Id$uuidNil,
			b5: $author$project$App$Components$ViewFullStatement$TabDocument(0),
			fp: $elm$core$Dict$empty,
			a5: $author$project$App$Data$FullStatement$emptyFullStatement,
			ep: false,
			cH: 0,
			eZ: _List_Nil
		};
		return _Utils_ap(
			viewRelation,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'name', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Nome Completo'),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder('Primeiro Nome'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$disabled(submitting),
											$elm$html$Html$Attributes$value(person.hl),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputPersonField(mDepIdx),
												$author$project$App$Data$Person$FieldName)),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													showErrorMessageClass(4)
												]))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder('Todos os sobrenomes'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$disabled(submitting),
											$elm$html$Html$Attributes$value(person.ii),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputPersonField(mDepIdx),
												$author$project$App$Data$Person$FieldSurname)),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													showErrorMessageClass(5)
												]))
										]),
									_List_Nil)
								]))
						]) : _List_Nil),
					A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'socialName', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Nome Social'),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder('Nome Social'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$disabled(submitting),
											$elm$html$Html$Attributes$value(person.B),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputPersonField(mDepIdx),
												$author$project$App$Data$Person$FieldSocialName)),
											$elm$html$Html$Attributes$classList(_List_Nil)
										]),
									_List_Nil)
								]))
						]) : _List_Nil),
					A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'birthdate', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Data Nascimento'),
							A3(
							$author$project$App$Tools$FormHelpers$dateInputWithAttrs,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											showErrorMessageClass(6)
										])),
									$elm$html$Html$Attributes$disabled(submitting)
								]),
							person.al,
							A2(
								$elm$core$Basics$composeL,
								$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputPersonField(mDepIdx),
								$author$project$App$Data$Person$FieldBirthDate))
						]) : _List_Nil),
					A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'sex', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Sexo'),
							A3(
							$author$project$App$Tools$FormHelpers$selectSex,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											showErrorMessageClass(7)
										])),
									$elm$html$Html$Attributes$disabled(submitting)
								]),
							person.dJ,
							A2(
								$elm$core$Basics$composeL,
								$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputPersonField(mDepIdx),
								$author$project$App$Data$Person$FieldSex))
						]) : _List_Nil),
					(((editStmt.e.j_ === 2) && (!_Utils_eq(mDepIdx, $author$project$App$Data$Statement$Bearer))) && (A2($author$project$App$Data$Person$ageOnDate, now, editStmt.dw) < 18)) ? $elm$html$Html$text('') : A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'birthdate', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Horário de atendimento'),
							A3(
							$author$project$App$Tools$FormHelpers$dateTimeInputWithAttrs,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											showErrorMessageClass(13)
										])),
									$elm$html$Html$Attributes$disabled(submitting)
								]),
							stmt.jU,
							A2(
								$elm$core$Basics$composeL,
								$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputStmtField(mDepIdx),
								$author$project$App$Data$Statement$FieldScheduleDate))
						]) : _List_Nil),
					((editStmt.e.j_ === 2) && _Utils_eq(mDepIdx, $author$project$App$Data$Statement$Bearer)) ? $elm$html$Html$text('') : A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'weight-height', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Peso e Altura'),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder('Peso'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$disabled(submitting),
											$elm$html$Html$Attributes$value(
											$author$project$App$Data$Statement$formatWeight(stmt.iT)),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputStmtField(mDepIdx),
												$author$project$App$Data$Statement$FieldCompanyWeight)),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													showErrorMessageClass(8)
												])),
											$author$project$App$Tools$FormHelpers$onBackspace(
											A2(
												$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputStmtField,
												mDepIdx,
												A2($author$project$App$Data$Statement$backspaceWeight, $author$project$App$Data$Statement$FieldCompanyWeight, stmt.iT)))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder('Altura'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$disabled(submitting),
											$elm$html$Html$Attributes$value(
											$author$project$App$Data$Statement$formatHeight(stmt.iS)),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputStmtField(mDepIdx),
												$author$project$App$Data$Statement$FieldCompanyHeight)),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													showErrorMessageClass(9)
												])),
											$author$project$App$Tools$FormHelpers$onBackspace(
											A2(
												$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputStmtField,
												mDepIdx,
												A2($author$project$App$Data$Statement$backspaceHeight, $author$project$App$Data$Statement$FieldCompanyHeight, stmt.iS)))
										]),
									_List_Nil)
								]))
						]) : _List_Nil)
				]));
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewEditDependent = F6(
	function (now, idx, modelForm, dep, fullStmt, api) {
		var ridById = $author$project$App$Data$Person$ridById(dep.dw.as);
		var ridByDoc = $author$project$App$Data$Person$ridByDoc(dep.dw.i1);
		var personLoaded = true;
		var invalidDoc = A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$documentIsValid, dep.dw.i1, api) ? false : true;
		var docComplete = $author$project$App$Data$Person$isDocumentValid(dep.dw.i1);
		var currentTemplate = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$StatementTemplate$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.as, fullStmt.g.e.eY);
					},
					modelForm.eZ)));
		return A2(
			$elm$html$Html$fieldset,
			_List_Nil,
			$elm$core$List$concat(
				_List_fromArray(
					[
						A4(
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewEditPersonDocument,
						$author$project$App$Data$Statement$Dependent(idx),
						dep.dw,
						api,
						currentTemplate),
						docComplete ? ((personLoaded && (!invalidDoc)) ? $elm$core$List$concat(
						_List_fromArray(
							[
								A4(
								$author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewEditPersonInformation,
								$author$project$App$Data$Statement$Dependent(idx),
								dep,
								modelForm,
								now),
								(A2($author$project$App$Data$Person$ageOnDate, now, dep.dw) < 18) ? _List_Nil : A3(
								$author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewEditContacts,
								$author$project$App$Data$Statement$Dependent(idx),
								modelForm,
								dep.ea)
							])) : (invalidDoc ? _List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('CPF inválido!')
								]))
						]) : _List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Validando CPF...')
								]))
						]))) : _List_fromArray(
						[
							$elm$html$Html$text('')
						])
					])));
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewTemplateContent = F2(
	function (fullStmt, modelForm) {
		var templates = A2(
			$elm$core$List$filter,
			function (t) {
				return t.hl !== 'Entrevista';
			},
			modelForm.eZ);
		var stmtTemplate = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$StatementTemplate$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.as, fullStmt.g.e.eY);
					},
					modelForm.eZ)));
		var stmtModel = modelForm.a5.g.e;
		var showModelOfInterviewText = A2($elm$core$List$member, 'interview-model:visible', stmtTemplate.eM);
		var dueDate = $author$project$App$Data$Id$uuidIsValid(stmtModel.as) ? A2(
			$elm$html$Html$label,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Prazo Final: '),
					A3(
					$author$project$App$Tools$FormHelpers$dateInputWithAttrs,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'disabled', '')
						]),
					stmtModel.gL,
					A2(
						$elm$core$Basics$composeL,
						$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputStmtField($author$project$App$Data$Statement$Bearer),
						$author$project$App$Data$Statement$FieldDueDate))
				])) : $elm$html$Html$text('');
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$legend,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Cadastro')
							]))
					])),
				dueDate,
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Modelo de Atendimento'),
						A5(
						$author$project$App$Tools$FormHelpers$selectFromCollection,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(
								(modelForm.cH === 1) || ($author$project$App$Data$Id$uuidIsValid(stmtModel.as) && $author$project$App$Data$Statement$isEditable(stmtModel))),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'error-message',
										A3($author$project$App$Page$Reviewer$EditStatement$StatementEdit$errorsInField, 2, $author$project$App$Data$Statement$Bearer, modelForm.fp))
									]))
							]),
						stmtModel.eY,
						templates,
						function (t) {
							return _Utils_Tuple2(t.as, t.hl);
						},
						A2(
							$elm$core$Basics$composeR,
							$author$project$App$Data$Statement$FieldTemplateId,
							$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputStmtField($author$project$App$Data$Statement$Bearer)))
					])),
				showModelOfInterviewText ? A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Categoria do Atendimento'),
						A4(
						$author$project$App$Tools$FormHelpers$select,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(modelForm.cH === 1)
							]),
						$author$project$App$Data$Statement$shouldInterviewToString(stmtModel.j_),
						A2(
							$elm$core$Basics$composeR,
							$author$project$App$Data$Statement$FieldShouldInterview,
							$author$project$App$Page$Reviewer$EditStatement$StatementEdit$InputStmtField($author$project$App$Data$Statement$Bearer)),
						_List_fromArray(
							[
								_Utils_Tuple2(
								$author$project$App$Data$Statement$shouldInterviewToString(1),
								$author$project$App$Data$Statement$shouldInterviewToText(1)),
								_Utils_Tuple2(
								$author$project$App$Data$Statement$shouldInterviewToString(2),
								$author$project$App$Data$Statement$shouldInterviewToText(2))
							]))
					])) : A2($elm$html$Html$label, _List_Nil, _List_Nil)
			]);
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewForm = F3(
	function (st, modelForm, model) {
		var viewDocumentDetails = function () {
			var _v1 = model.ac;
			if (_v1.$ === 1) {
				var viewDocumentModel = _v1.b;
				return A2(
					$elm$html$Html$map,
					$author$project$App$Page$Reviewer$EditStatement$StatementEdit$DocumentViewMsg,
					A2($author$project$App$Page$Reviewer$EditStatement$DocumentView$view, st, viewDocumentModel));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		var now = $author$project$App$Service$ApiWebSocket$getTimestamp(st._);
		var personEditContent = F2(
			function (mDepIdx, editStmt) {
				return A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$documentIsValid, editStmt.dw.i1, st._) ? _Utils_ap(
					A4($author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewEditPersonInformation, mDepIdx, editStmt, modelForm, now),
					((A2($author$project$App$Data$Person$ageOnDate, now, editStmt.dw) >= 18) && (!(!$elm$time$Time$posixToMillis(editStmt.dw.al)))) ? A3($author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewEditContacts, mDepIdx, modelForm, editStmt.ea) : _List_Nil) : _List_fromArray(
					[
						$elm$html$Html$text('')
					]);
			});
		var fullStmt = modelForm.a5;
		var statementModel = fullStmt.g.e;
		var depsContent = A2(
			$elm$core$List$map,
			function (_v0) {
				var idx = _v0.a;
				var dep = _v0.b;
				return A6($author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewEditDependent, modelForm.ey, idx, modelForm, dep, modelForm.a5, st._);
			},
			$elm$core$Dict$toList(modelForm.a5.fh));
		var currentTemplate = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$StatementTemplate$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.as, modelForm.a5.g.e.eY);
					},
					modelForm.eZ)));
		var depButtonContent = (!currentTemplate.gX) ? $elm$html$Html$text('') : (A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$documentIsValid, fullStmt.g.dw.i1, st._) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('action-buttons')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('button'),
							$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$EditStatement$StatementEdit$AddDependent)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Incluir dependente')
						]))
				])) : $elm$html$Html$text(''));
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_Nil,
				model.y ? _List_fromArray(
					[
						A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Carregando...')
											]))
									]))
							]))
					]) : ((!model.a6) ? _List_fromArray(
					[
						A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + A3(
													$elm$core$String$slice,
													0,
													10,
													$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(fullStmt.g.e.gL)))
											]))
									])),
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Novo vencimento:')
									])),
								A3($author$project$App$Tools$FormHelpers$dateInputWithAttrs, _List_Nil, model.cq, $author$project$App$Page$Reviewer$EditStatement$StatementEdit$UpdateNewDueDate),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('button'),
										A2($elm$html$Html$Attributes$style, 'margin', '0'),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Reviewer$EditStatement$StatementEdit$PostPoneStatement(
											_Utils_update(
												statementModel,
												{gL: model.cq}))),
										$elm$html$Html$Attributes$value('Alterar Vencimento')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Alterar Vencimento')
									]))
							])),
						(_Utils_cmp(
						$author$project$App$Data$StatementStatus$statusToInt(statementModel.aw),
						$author$project$App$Data$StatementStatus$statusToInt(24)) > -1) ? A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Remover parecer e enviar para o diretor clínico da Medsyn:')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('action-buttons')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('')
											]))
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('button'),
										A2($elm$html$Html$Attributes$style, 'margin', '0'),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Reviewer$EditStatement$StatementEdit$ReturnToDoctor(statementModel)),
										$elm$html$Html$Attributes$value('Retornar para o Diretor Clínico')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Retornar para o Diretor Clínico')
									]))
							])) : $elm$html$Html$text(''),
						(_Utils_cmp(
						$author$project$App$Data$StatementStatus$statusToInt(statementModel.aw),
						$author$project$App$Data$StatementStatus$statusToInt(8)) > -1) ? A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Retornar para a fila:')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('action-buttons')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('')
											]))
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('button'),
										A2($elm$html$Html$Attributes$style, 'margin', '0'),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Reviewer$EditStatement$StatementEdit$ReturnToConfirmInterview(statementModel)),
										$elm$html$Html$Attributes$value('Retornar para a fila')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Retornar para a fila')
									]))
							])) : $elm$html$Html$text(''),
						(_Utils_cmp(
						$author$project$App$Data$StatementStatus$statusToInt(statementModel.aw),
						$author$project$App$Data$StatementStatus$statusToInt(4)) > 0) ? A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Gerar as respostas novamente:')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('action-buttons')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('')
											]))
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('button'),
										A2($elm$html$Html$Attributes$style, 'margin', '0'),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Reviewer$EditStatement$StatementEdit$ReturnToWaitingForRoom(statementModel)),
										$elm$html$Html$Attributes$value('Gerar as respostas novamente')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Gerar as respostas novamente')
									]))
							])) : $elm$html$Html$text(''),
						A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Cancelar :')
											]))
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('button'),
										A2($elm$html$Html$Attributes$style, 'margin', '0'),
										$elm$html$Html$Events$onClick(
										A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$ReturnToCompany, fullStmt.g.e.as, model.b)),
										$elm$html$Html$Attributes$value('Cancelar Atendimento')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Cancelar Atendimento')
									]))
							])),
						A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Alternar vídeo :')
											]))
									])),
								viewDocumentDetails
							])),
						A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Editar informações:')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('action-buttons')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('')
											]))
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('button'),
										A2($elm$html$Html$Attributes$style, 'margin', '0'),
										$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$EditStatement$StatementEdit$ToggleEditStatement),
										$elm$html$Html$Attributes$value('Editar')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Editar')
									]))
							]))
					]) : _List_fromArray(
					[
						A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_Utils_ap(
							A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewTemplateContent, fullStmt, modelForm),
							_Utils_ap(
								A4($author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewEditPersonDocument, $author$project$App$Data$Statement$Bearer, fullStmt.g.dw, st._, st.ia.h8.cJ),
								_Utils_ap(
									A2(personEditContent, $author$project$App$Data$Statement$Bearer, fullStmt.g),
									_Utils_ap(
										depsContent,
										_List_fromArray(
											[depButtonContent]))))))
					])))
			]);
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewStatementForm = F4(
	function (filter, modelForm, model, st) {
		return A2(
			$elm$html$Html$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$header,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Editando Cadastro')
								])),
							model.a6 ? A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('action-buttons')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$EditStatement$StatementEdit$ToggleEditStatement)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Cancelar')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$EditStatement$StatementEdit$Save)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Salvar')
										]))
								])) : A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('action-buttons')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$EditStatement$StatementEdit$GoToIncomplete)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Voltar')
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('authentication'),
							A2($elm$html$Html$Attributes$style, 'overflow', 'inherit')
						]),
					$author$project$App$Data$Id$uuidIsValid(model.h8.g.e.as) ? A3($author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewForm, st, modelForm, model) : _List_Nil)
				]));
	});
var $author$project$App$Page$Reviewer$EditStatement$StatementEdit$view = F2(
	function (st, model) {
		var viewDetails = function () {
			var _v1 = model.gD;
			if (_v1.$ === 1) {
				var viewFullStmt = _v1.b;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Reviewer$EditStatement$StatementEdit$ViewFullStmtMsg,
							A2($author$project$App$Components$ViewFullStatement$view, st, viewFullStmt))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		var stmtId = model.as;
		var notificationView = $author$project$App$Page$Reviewer$EditStatement$StatementEdit$notificationFromModel(model);
		var modelForm = $author$project$App$Page$Reviewer$EditStatement$StatementEdit$formFromModel(model);
		var fullStmt = model.h8;
		var dialogView = A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$dialogFromModel, model, st);
		var _v0 = _Utils_Tuple2('Revisar Atendimento', 'Atualizar');
		var headerTitle = _v0.a;
		var actionText = _v0.b;
		return _List_fromArray(
			[
				A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewErrors, st, model),
				A4($author$project$App$Page$Reviewer$EditStatement$StatementEdit$viewStatementForm, model.b, modelForm, model, st),
				viewDetails,
				dialogView,
				notificationView
			]);
	});
var $author$project$App$Page$Reviewer$PageListApproved$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Reviewer$PageListApproved$viewDoctorOnLine = function (doctor) {
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('user-item')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(
						$author$project$App$Data$Person$photoUrl(doctor))
					]),
				_List_Nil),
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$App$Data$Person$fullName(doctor))
					]))
			]));
};
var $author$project$App$Page$Reviewer$PageListApproved$viewDoctors = function (doctorsOnline) {
	return A2(
		$elm$html$Html$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$header,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Médicos Online')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$ul,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('doctor-list')
							]),
						A3(
							$elm$core$List$foldl,
							F2(
								function (doctor, html) {
									return A2(
										$elm$core$List$append,
										html,
										_List_fromArray(
											[
												$author$project$App$Page$Reviewer$PageListApproved$viewDoctorOnLine(doctor)
											]));
								}),
							_List_Nil,
							doctorsOnline))
					]))
			]));
};
var $author$project$App$Page$Reviewer$PageListApproved$GoToEditStatement = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Reviewer$PageListApproved$HideDetails = {$: 6};
var $author$project$App$Page$Reviewer$PageListApproved$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var selectedStmtId = function () {
			var _v5 = model.gD;
			if (_v5.$ === 1) {
				var stmtId = _v5.a;
				return $elm$core$Maybe$Just(stmtId);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var editButton = A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list-buttons'),
					$elm$html$Html$Attributes$title('Editar'),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Reviewer$PageListApproved$GoToEditStatement(stmt.as))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'font-size', '16px'),
							A2($elm$html$Html$Attributes$style, 'float', 'left')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Editar')
						])),
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('edit')
						]))
				]));
		var _v0 = function () {
			var _v1 = model.gD;
			if (_v1.$ === 1) {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$PageListApproved$HideDetails)
						]),
					_List_fromArray(
						[editButton]));
			} else {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Reviewer$PageListApproved$ViewDetails(stmt.as))
						]),
					_List_fromArray(
						[editButton]));
			}
		}();
		var onClickMsg = _v0.a;
		var buttons = _v0.b;
		var _v2 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v2.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v2.a.$) {
				case 3:
					var person = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_Utils_ap(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('user-item'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'user-selected',
											_Utils_eq(
												selectedStmtId,
												$elm$core$Maybe$Just(stmt.as)))
										]))
								]),
							onClickMsg),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_Utils_ap(
									buttons,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Status:')
												])),
											(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente titular')
												])) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente dependente')
												])))) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$elm$core$String$fromInt(stmt.bo) + ' dependentes')
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
												]))
										]))),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Person$fullPersonName(person))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Última atualização: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.f2))
											]))
									]))
							]));
				case 2:
					var err = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v3 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v4 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Reviewer$PageListApproved$view = F2(
	function (st, model) {
		var viewStatements = ($elm$core$List$length(model.bk) > 0) ? A2(
			$elm$core$List$map,
			A2($author$project$App$Page$Reviewer$PageListApproved$viewUserListRow, model, st.ia.h8.cJ),
			model.bk) : _List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Nenhum item encontrado.')
					]))
			]);
		var viewDetails = function () {
			var _v0 = model.gD;
			if (_v0.$ === 1) {
				var viewFullStmt = _v0.b;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Reviewer$PageListApproved$ViewFullStmtMsg,
							A2($author$project$App$Components$ViewFullStatement$view, st, viewFullStmt))
						]));
			} else {
				return $author$project$App$Page$Reviewer$PageListApproved$viewDoctors(model.b9);
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Aprovados')
									])),
								A2(
								$elm$html$Html$map,
								$author$project$App$Page$Reviewer$PageListApproved$FilterMsg,
								$author$project$App$Components$Filter$view(model.b))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								viewStatements)
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Reviewer$PageListApproved$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					])),
				viewDetails
			]);
	});
var $author$project$App$Page$Reviewer$PageListForReview$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Reviewer$PageListForReview$nextIdFromPending = function (model) {
	if ($elm$core$List$length(model.bk) > 0) {
		var firstStmt = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Statement$emptyModel,
			$elm$core$List$head(model.bk));
		return firstStmt.as;
	} else {
		return $author$project$App$Data$Id$uuidNil;
	}
};
var $author$project$App$Page$Reviewer$PageListForReview$viewDoctorOnLine = function (doctor) {
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('user-item')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(
						$author$project$App$Data$Person$photoUrl(doctor))
					]),
				_List_Nil),
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$App$Data$Person$fullName(doctor))
					]))
			]));
};
var $author$project$App$Page$Reviewer$PageListForReview$viewDoctors = function (doctorsOnline) {
	return A2(
		$elm$html$Html$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$header,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Médicos Online')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$ul,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('doctor-list')
							]),
						A3(
							$elm$core$List$foldl,
							F2(
								function (doctor, html) {
									return A2(
										$elm$core$List$append,
										html,
										_List_fromArray(
											[
												$author$project$App$Page$Reviewer$PageListForReview$viewDoctorOnLine(doctor)
											]));
								}),
							_List_Nil,
							doctorsOnline))
					]))
			]));
};
var $author$project$App$Page$Reviewer$PageListForReview$ReviewInterview = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Reviewer$PageListForReview$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var reviewButton = A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list-buttons'),
					$elm$html$Html$Attributes$title('Enviar para Revisão'),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Reviewer$PageListForReview$ReviewInterview(stmt.as))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('done')
						]))
				]));
		var _v0 = function () {
			var _v1 = _Utils_Tuple2(
				stmt.aw,
				_Utils_eq(
					model.aU,
					$elm$core$Maybe$Just(stmt.as)));
			_v1$0:
			while (true) {
				if (!_v1.b) {
					if (!_v1.a) {
						break _v1$0;
					} else {
						return _Utils_Tuple2(
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$App$Page$Reviewer$PageListForReview$Review(stmt.as))
								]),
							_List_Nil);
					}
				} else {
					if (!_v1.a) {
						break _v1$0;
					} else {
						return _Utils_Tuple2(
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$App$Page$Reviewer$PageListForReview$Review(stmt.as))
								]),
							_List_Nil);
					}
				}
			}
			var _v2 = _v1.a;
			return _Utils_Tuple2(
				_List_Nil,
				_List_fromArray(
					[reviewButton]));
		}();
		var onClickMsg = _v0.a;
		var buttons = _v0.b;
		var _v3 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v3.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v3.a.$) {
				case 3:
					var person = _v3.a.a;
					return A2(
						$elm$html$Html$li,
						_Utils_ap(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('user-item'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'user-selected',
											_Utils_eq(
												model.aU,
												$elm$core$Maybe$Just(stmt.as)))
										]))
								]),
							onClickMsg),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status:')
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$StatementStatus$statusToText(stmt.aw))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_Utils_ap(
									buttons,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$App$Data$Person$fullPersonName(person))
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
												])),
											(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente titular')
												])) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente dependente')
												])))) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$elm$core$String$fromInt(stmt.bo) + ' dependentes')
												]))
										])))
							]));
				case 2:
					var err = _v3.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v4 = _v3.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v5 = _v3.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Reviewer$PageListForReview$view = F2(
	function (st, model) {
		var viewStatements = ($elm$core$List$length(model.bk) > 0) ? A2(
			$elm$core$List$map,
			A2($author$project$App$Page$Reviewer$PageListForReview$viewUserListRow, model, st.ia.h8.cJ),
			model.bk) : _List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Nenhum item encontrado.')
					]))
			]);
		var viewDetails = function () {
			var _v0 = model.av;
			if (!_v0.$) {
				var viewFullStmt = _v0.a;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Reviewer$PageListForReview$ViewFullStmtMsg,
							A2($author$project$App$Components$ViewFullStatement$view, st, viewFullStmt))
						]));
			} else {
				return $author$project$App$Page$Reviewer$PageListForReview$viewDoctors(model.b9);
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Revisão')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('action-buttons')
									]),
								_List_fromArray(
									[
										(!$elm$core$List$isEmpty(model.bk)) ? A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$type_('button'),
												$elm$html$Html$Events$onClick(
												$author$project$App$Page$Reviewer$PageListForReview$Review(
													$author$project$App$Page$Reviewer$PageListForReview$nextIdFromPending(model)))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Revisar Próxima')
											])) : $elm$html$Html$text('')
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								viewStatements)
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Reviewer$PageListForReview$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					])),
				viewDetails
			]);
	});
var $author$project$App$Page$Reviewer$PageListIncomplete$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Reviewer$PageListIncomplete$CancelReturnToCompany = function (a) {
	return {$: 8, a: a};
};
var $author$project$App$Page$Reviewer$PageListIncomplete$ConfirmReturnToCompany = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$App$Page$Reviewer$PageListIncomplete$InputObservationField = F3(
	function (a, b, c) {
		return {$: 9, a: a, b: b, c: c};
	});
var $author$project$App$Page$Reviewer$PageListIncomplete$dialogFromModel = F2(
	function (model, st) {
		var header = _List_Nil;
		var footer = function (filter) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('cancel-button'),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Reviewer$PageListIncomplete$CancelReturnToCompany(model))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Cancelar')
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							A2($author$project$App$Page$Reviewer$PageListIncomplete$ConfirmReturnToCompany, model.h8, filter))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Devolver')
						]))
				]);
		};
		var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
		var body = function (filter) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$textarea,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$cols(50),
							$elm$html$Html$Attributes$rows(5),
							$elm$html$Html$Attributes$value(model.h8.jx),
							$elm$html$Html$Events$onInput(
							function (str) {
								return A3(
									$author$project$App$Page$Reviewer$PageListIncomplete$InputObservationField,
									model.h8.as,
									filter,
									$author$project$App$Data$Statement$FieldObservation(str));
							})
						]),
					_List_Nil)
				]);
		};
		var completeConfig = function (filter) {
			return _Utils_update(
				config,
				{
					e5: $elm$core$Maybe$Just(
						body(filter)),
					ft: $elm$core$Maybe$Just(
						footer(filter)),
					gY: $elm$core$Maybe$Just(header),
					f1: 'Motivo da Devolução'
				});
		};
		return model.dC ? $author$project$App$Components$Dialog$view(
			$elm$core$Maybe$Just(
				completeConfig(model.b))) : $elm$html$Html$text('');
	});
var $author$project$App$Page$Reviewer$PageListIncomplete$viewDoctorOnLine = function (doctor) {
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('user-item')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(
						$author$project$App$Data$Person$photoUrl(doctor))
					]),
				_List_Nil),
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$App$Data$Person$fullName(doctor))
					]))
			]));
};
var $author$project$App$Page$Reviewer$PageListIncomplete$viewDoctors = function (doctorsOnline) {
	return A2(
		$elm$html$Html$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$header,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Médicos Online')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$ul,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('doctor-list')
							]),
						A3(
							$elm$core$List$foldl,
							F2(
								function (doctor, html) {
									return A2(
										$elm$core$List$append,
										html,
										_List_fromArray(
											[
												$author$project$App$Page$Reviewer$PageListIncomplete$viewDoctorOnLine(doctor)
											]));
								}),
							_List_Nil,
							doctorsOnline))
					]))
			]));
};
var $author$project$App$Page$Reviewer$PageListIncomplete$GoToEditStatement = function (a) {
	return {$: 13, a: a};
};
var $author$project$App$Page$Reviewer$PageListIncomplete$HideDetails = {$: 6};
var $author$project$App$Page$Reviewer$PageListIncomplete$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var selectedStmtId = function () {
			var _v4 = model.gD;
			if (_v4.$ === 1) {
				var stmtId = _v4.a;
				return $elm$core$Maybe$Just(stmtId);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var onClickMsg = function (stmtId) {
			var _v3 = model.gD;
			if (_v3.$ === 1) {
				return $author$project$App$Page$Reviewer$PageListIncomplete$HideDetails;
			} else {
				return $author$project$App$Page$Reviewer$PageListIncomplete$ViewDetails(stmtId);
			}
		};
		var editButton = A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list-buttons'),
					$elm$html$Html$Attributes$title('Editar'),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Reviewer$PageListIncomplete$GoToEditStatement(stmt.as))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'font-size', '16px'),
							A2($elm$html$Html$Attributes$style, 'float', 'left')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Editar')
						])),
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('edit')
						]))
				]));
		var buttons = _List_fromArray(
			[editButton]);
		var _v0 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v0.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v0.a.$) {
				case 3:
					var person = _v0.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'user-selected',
										_Utils_eq(
											selectedStmtId,
											$elm$core$Maybe$Just(stmt.as)))
									])),
								$elm$html$Html$Events$onClick(
								onClickMsg(stmt.as))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status:')
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$StatementStatus$statusToText(stmt.aw))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_Utils_ap(
									buttons,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$App$Data$Person$fullPersonName(person))
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
												])),
											(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente titular')
												])) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente dependente')
												])))) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$elm$core$String$fromInt(stmt.bo) + ' dependentes')
												]))
										])))
							]));
				case 2:
					var err = _v0.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v1 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v2 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Reviewer$PageListIncomplete$view = F2(
	function (st, model) {
		var viewStatements = ($elm$core$List$length(model.bk) > 0) ? A2(
			$elm$core$List$map,
			A2($author$project$App$Page$Reviewer$PageListIncomplete$viewUserListRow, model, st.ia.h8.cJ),
			model.bk) : _List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Nenhum item encontrado.')
					]))
			]);
		var viewDetails = function () {
			var _v0 = model.gD;
			if (_v0.$ === 1) {
				var viewFullStmt = _v0.b;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Reviewer$PageListIncomplete$ViewFullStmtMsg,
							A2($author$project$App$Components$ViewFullStatement$view, st, viewFullStmt))
						]));
			} else {
				return $author$project$App$Page$Reviewer$PageListIncomplete$viewDoctors(model.b9);
			}
		}();
		var dialogView = A2($author$project$App$Page$Reviewer$PageListIncomplete$dialogFromModel, model, st);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Incompletos')
									])),
								A2(
								$elm$html$Html$map,
								$author$project$App$Page$Reviewer$PageListIncomplete$FilterMsg,
								$author$project$App$Components$Filter$view(model.b))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								viewStatements)
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Reviewer$PageListIncomplete$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					])),
				viewDetails,
				dialogView
			]);
	});
var $author$project$App$Page$Reviewer$PageListReevaluation$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Reviewer$PageListReevaluation$viewDoctorOnLine = function (doctor) {
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('user-item')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(
						$author$project$App$Data$Person$photoUrl(doctor))
					]),
				_List_Nil),
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$App$Data$Person$fullName(doctor))
					]))
			]));
};
var $author$project$App$Page$Reviewer$PageListReevaluation$viewDoctors = function (doctorsOnline) {
	return A2(
		$elm$html$Html$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$header,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Médicos Online')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$ul,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('doctor-list')
							]),
						A3(
							$elm$core$List$foldl,
							F2(
								function (doctor, html) {
									return A2(
										$elm$core$List$append,
										html,
										_List_fromArray(
											[
												$author$project$App$Page$Reviewer$PageListReevaluation$viewDoctorOnLine(doctor)
											]));
								}),
							_List_Nil,
							doctorsOnline))
					]))
			]));
};
var $author$project$App$Page$Reviewer$PageListReevaluation$Evaluate = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Reviewer$PageListReevaluation$HideInterview = {$: 5};
var $author$project$App$Page$Reviewer$PageListReevaluation$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var _v0 = function () {
			var _v1 = _Utils_Tuple2(
				stmt.aw,
				_Utils_eq(
					model.aU,
					$elm$core$Maybe$Just(stmt.as)));
			if (!_v1.b) {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Reviewer$PageListReevaluation$Evaluate(stmt.as))
						]),
					_List_Nil);
			} else {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$PageListReevaluation$HideInterview)
						]),
					_List_Nil);
			}
		}();
		var onClickMsg = _v0.a;
		var buttons = _v0.b;
		var _v2 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v2.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v2.a.$) {
				case 3:
					var person = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_Utils_ap(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('user-item'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'user-selected',
											_Utils_eq(
												model.aU,
												$elm$core$Maybe$Just(stmt.as)))
										]))
								]),
							onClickMsg),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status:')
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$StatementStatus$statusToText(stmt.aw))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_Utils_ap(
									buttons,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$App$Data$Person$fullPersonName(person))
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
												])),
											(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente titular')
												])) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente dependente')
												])))) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$elm$core$String$fromInt(stmt.bo) + ' dependentes')
												]))
										])))
							]));
				case 2:
					var err = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v3 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v4 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Reviewer$PageListReevaluation$viewStatements = F2(
	function (model, stmtTemplate) {
		var _v0 = model.bk;
		if (!_v0.b) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Nenhum atendimento em reavaliação')
						]))
				]);
		} else {
			var statements = _v0;
			return A2(
				$elm$core$List$map,
				A2($author$project$App$Page$Reviewer$PageListReevaluation$viewUserListRow, model, stmtTemplate),
				statements);
		}
	});
var $author$project$App$Page$Reviewer$PageListReevaluation$view = F2(
	function (sharedState, model) {
		var viewDetails = function () {
			var _v0 = model.av;
			if (!_v0.$) {
				var viewFullStmt = _v0.a;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Reviewer$PageListReevaluation$ViewFullStmtMsg,
							A2(
								$author$project$App$Components$ViewFullStatement$view,
								sharedState,
								_Utils_update(
									viewFullStmt,
									{a5: sharedState.ia.h8})))
						]));
			} else {
				return $author$project$App$Page$Reviewer$PageListReevaluation$viewDoctors(model.b9);
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Em Reavaliação')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('action-buttons')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$map,
										$author$project$App$Page$Reviewer$PageListReevaluation$FilterMsg,
										$author$project$App$Components$Filter$view(model.b))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								A2($author$project$App$Page$Reviewer$PageListReevaluation$viewStatements, model, sharedState.ia.h8.cJ))
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Reviewer$PageListReevaluation$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					])),
				viewDetails
			]);
	});
var $author$project$App$Page$Reviewer$PageReevaluation$BackToFinished = {$: 1};
var $author$project$App$Page$Reviewer$PageReevaluation$FinishReevaluation = {$: 4};
var $author$project$App$Page$Reviewer$PageReevaluation$SendToReview = {$: 3};
var $author$project$App$Page$Reviewer$PageReevaluation$ToggleDialog = {$: 2};
var $author$project$App$Page$Reviewer$PageReevaluation$UpdateReviewMessage = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Reviewer$PageReevaluation$dialogFromModel = function (model) {
	var header = _List_Nil;
	var footer = _List_fromArray(
		[
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cancel-button'),
					$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$PageReevaluation$ToggleDialog)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Cancelar')
				])),
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$PageReevaluation$SendToReview)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Devolver')
				]))
		]);
	var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
	var body = _List_fromArray(
		[
			A2(
			$elm$html$Html$textarea,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$cols(50),
					$elm$html$Html$Attributes$rows(5),
					$elm$html$Html$Attributes$value(model.eN),
					$elm$html$Html$Events$onInput($author$project$App$Page$Reviewer$PageReevaluation$UpdateReviewMessage)
				]),
			_List_Nil)
		]);
	var completeConfig = _Utils_update(
		config,
		{
			e5: $elm$core$Maybe$Just(body),
			ft: $elm$core$Maybe$Just(footer),
			gY: $elm$core$Maybe$Just(header),
			f1: 'Motivo da Reavaliação'
		});
	return model.dC ? $author$project$App$Components$Dialog$view(
		$elm$core$Maybe$Just(completeConfig)) : $elm$html$Html$text('');
};
var $author$project$App$Page$Reviewer$PageReevaluation$viewDocumentContent = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Page$Reviewer$PageReevaluation$viewCPTContent = F3(
	function (mDepIdx, fullStmt, now) {
		var cptDoc = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Document$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					function (_v1) {
						var i = _v1.a;
						var d = _v1.b;
						return d;
					},
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var d = _v0.b;
							return _Utils_eq(d.jJ, fullStmt.g.e.as) && (d.b7 === 5);
						},
						$elm$core$Dict$toList(fullStmt.an)))));
		return $author$project$App$Data$Id$uuidIsValid(cptDoc.as) ? $author$project$App$Page$Reviewer$PageReevaluation$viewDocumentContent(cptDoc) : A3($author$project$App$Legacy$Doctor$ResultLayout$Common$viewCPTLayout, mDepIdx, fullStmt, now);
	});
var $author$project$App$Page$Reviewer$PageReevaluation$viewDoc = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Page$Reviewer$PageReevaluation$viewPersonICD = F4(
	function (now, mDepIdx, stmt, sharedState) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(stmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					A3(
						$elm$core$Dict$foldr,
						F3(
							function (cat, icd, html) {
								return A2(
									$elm$core$List$append,
									html,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('chip'),
													$elm$html$Html$Attributes$title(icd.am)
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(cat)
														]))
												]))
										]));
							}),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Data$Person$fullPersonName(stmt.dw))
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(
											A2($author$project$App$Data$Person$ageOnDate, now, stmt.dw)) + ' anos')
									])),
								sharedState.ia.h8.cJ.gX ? A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											if (mDepIdx.$ === 1) {
												var i = mDepIdx.a;
												return 'Dependente ' + $elm$core$String$fromInt(i + 1);
											} else {
												return _Utils_eq(stmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
											}
										}())
									])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
							]),
						A3(
							$elm$core$Dict$foldr,
							F3(
								function (_v0, qCatDict, catDict) {
									return A3(
										$elm$core$Dict$foldl,
										F3(
											function (cat, icd, catDictInner) {
												return A3($elm$core$Dict$insert, cat, icd, catDictInner);
											}),
										catDict,
										qCatDict);
								}),
							$elm$core$Dict$empty,
							stmt.fx)))
				]));
	});
var $author$project$App$Page$Reviewer$PageReevaluation$viewICDSection = F2(
	function (sharedState, model) {
		var chatView = $author$project$App$Components$Chatbox$view;
		var bearerPersonIcd = (sharedState.ia.h8.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A4($author$project$App$Page$Reviewer$PageReevaluation$viewPersonICD, model.ey, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g, sharedState)
			]);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tabs ')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Dados por Pessoa')
									])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('material-icons')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('print')
													]))
											]))
									]))
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Reviewer$PageReevaluation$ChatBoxMsg,
						chatView(model.aM)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('person-icds')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_Nil,
								A3(
									$elm$core$Dict$foldl,
									F3(
										function (i, dep, html) {
											return A2(
												$elm$core$List$append,
												html,
												_List_fromArray(
													[
														A4(
														$author$project$App$Page$Reviewer$PageReevaluation$viewPersonICD,
														model.ey,
														$author$project$App$Data$Statement$Dependent(i),
														dep,
														sharedState)
													]));
										}),
									bearerPersonIcd,
									sharedState.ia.h8.fh))
							]))
					]))
			]);
	});
var $author$project$App$Page$Reviewer$PageReevaluation$viewMedicalDocumentContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewMedicalDocumentLayout(fullStmt);
};
var $author$project$App$Page$Reviewer$PageReevaluation$getAnswerForTemplateId = F3(
	function (depIdx, model, tId) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Answer$emptyModel,
			A2(
				$elm$core$Dict$get,
				tId,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Dict$empty,
					A2(
						$elm$core$Dict$get,
						$author$project$App$Data$Statement$depIndexToInt(depIdx),
						model.aZ))));
	});
var $author$project$App$Page$Reviewer$PageReevaluation$viewICD = F4(
	function (ansTemp, catCode, mIdx, icd) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('chip'),
					$elm$html$Html$Attributes$title(icd.am)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(icd.a0)
						]))
				]));
	});
var $author$project$App$Page$Reviewer$PageReevaluation$viewICDs = F3(
	function (icds, ansTemp, mIdx) {
		return A3(
			$elm$core$Dict$foldr,
			F3(
				function (catCode, icd, result) {
					return A2(
						$elm$core$List$append,
						result,
						_List_fromArray(
							[
								A4($author$project$App$Page$Reviewer$PageReevaluation$viewICD, ansTemp, catCode, mIdx, icd)
							]));
				}),
			_List_Nil,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Dict$empty,
				A2($elm$core$Dict$get, ansTemp.as, icds)));
	});
var $author$project$App$Page$Reviewer$PageReevaluation$viewBMI = F4(
	function (sharedState, model, depIdx, stmt) {
		var weightTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 10) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.jM);
		var personQuestion = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestion$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (q) {
						return $author$project$App$Data$Id$uuidIsValid(q.hG.as);
					},
					A2(
						$elm$core$List$map,
						function (_v0) {
							var s = _v0.b;
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (q) {
											return _Utils_eq(q.hG.as, weightTemplate.bh);
										},
										s.eI)));
						},
						$elm$core$Dict$toList(model.cA)))));
		var heightTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 20) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.jM);
		var bmiTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 1000) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.gH);
		var doctorQuestion = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestion$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (q) {
						return $author$project$App$Data$Id$uuidIsValid(q.hG.as);
					},
					A2(
						$elm$core$List$map,
						function (s) {
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (q) {
											return _Utils_eq(q.hG.as, bmiTemplate.bh);
										},
										s.eI)));
						},
						model.P.gH))));
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no DPS')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatCompanyBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Reviewer$PageReevaluation$viewICDs, stmt.fx, weightTemplate, depIdx))
							]))
					])),
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no Questionário')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatPersonBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Reviewer$PageReevaluation$viewICDs, stmt.fx, heightTemplate, depIdx))
							]))
					])),
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no Atendimento')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatDoctorBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Reviewer$PageReevaluation$viewICDs, stmt.fx, bmiTemplate, depIdx))
							]))
					]))
			]);
	});
var $author$project$App$Page$Reviewer$PageReevaluation$maskDecimal = F2(
	function (precision, value) {
		return A3($elm$core$String$replace, '.', ',', value);
	});
var $author$project$App$Page$Reviewer$PageReevaluation$maskHeight = function (cm) {
	var len = $elm$core$String$length(cm);
	var subM = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cm)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cm) : cm);
	var h = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(cm));
	var m = (h >= 100) ? A3($elm$core$String$slice, 0, -2, cm) : '0';
	return (h > 0) ? (m + (',' + subM)) : '';
};
var $author$project$App$Page$Reviewer$PageReevaluation$maskPressure = function (str) {
	return function (p) {
		return (p > 0) ? $elm$core$String$fromInt(p) : '';
	}(
		function (p) {
			return (p > 999) ? (p / 10) : (p / 1.0);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(str))) | 0);
};
var $author$project$App$Page$Reviewer$PageReevaluation$maskWeight = function (wStr) {
	return function (w) {
		return (w > 0) ? $elm$core$String$fromInt(w) : '';
	}(
		function (w) {
			return w / 1000;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(wStr))) | 0);
};
var $author$project$App$Page$Reviewer$PageReevaluation$setupMask = F2(
	function (template, answer) {
		var _v0 = template.jj;
		if (_v0.$ === 1) {
			return answer.T;
		} else {
			switch (_v0.a.$) {
				case 3:
					var precision = _v0.a.a;
					return A2($author$project$App$Page$Reviewer$PageReevaluation$maskDecimal, precision, answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 0:
					var _v1 = _v0.a;
					return $author$project$App$Page$Reviewer$PageReevaluation$maskWeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 1:
					var _v2 = _v0.a;
					return $author$project$App$Page$Reviewer$PageReevaluation$maskHeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				default:
					var _v3 = _v0.a;
					return $author$project$App$Page$Reviewer$PageReevaluation$maskPressure(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
			}
		}
	});
var $author$project$App$Page$Reviewer$PageReevaluation$viewQuestion = F5(
	function (depIdx, question, sharedState, model, doctorQuestion) {
		var stmt = function () {
			if (!depIdx.$) {
				return sharedState.ia.h8.g;
			} else {
				var idx = depIdx.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, sharedState.ia.h8.fh));
			}
		}();
		var showCids = !A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		return A2(
			$elm$core$List$map,
			function (t) {
				var answer = A3($author$project$App$Page$Reviewer$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
				var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
				return (show || (t.ay >= 1000)) ? A2(
					$elm$html$Html$tr,
					_List_Nil,
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(question.hG.bP)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(t.jh)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($author$project$App$Page$Reviewer$PageReevaluation$setupMask, t, answer))
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(answer.jx)
									]))
							]),
						showCids ? _List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('chips chips-initial input-field')
											]),
										A3($author$project$App$Page$Reviewer$PageReevaluation$viewICDs, stmt.fx, t, depIdx))
									]))
							]) : _List_Nil)) : A2($elm$html$Html$span, _List_Nil, _List_Nil);
			},
			A2(
				$elm$core$List$filter,
				function (t) {
					var answer = A3($author$project$App$Page$Reviewer$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
					var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
					return show;
				},
				question.bY));
	});
var $author$project$App$Page$Reviewer$PageReevaluation$viewEditStatementSectionWithHeader = F5(
	function (depIdx, sharedState, model, fullQuestionSection, doctorQuestionSection) {
		var showCids = !A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var sectionName = function () {
			if (sharedState.ia.h8.b4.iR === 'Extramed') {
				var _v0 = fullQuestionSection.hX.a0;
				switch (_v0) {
					case 2:
						return 'Questionário';
					case 1:
						return 'Declaração';
					case 3:
						return 'Atendimento';
					default:
						return 'Desconhecido';
				}
			} else {
				return A2($elm$core$String$left, 50, fullQuestionSection.hX.hl) + '...';
			}
		}();
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '90%')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$colgroup,
					_List_Nil,
					_List_fromArray(
						[
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil)
						])),
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(sectionName)
											])),
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$colspan(2)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Resposta')
											]))
									]),
								showCids ? _List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Obs')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('CIDs')
											]))
									]) : _List_Nil))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, q) {
									return A5(
										$author$project$App$Page$Reviewer$PageReevaluation$viewQuestion,
										depIdx,
										q,
										sharedState,
										model,
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$FullQuestion$emptyModel,
											$elm$core$List$head(
												A2($elm$core$List$drop, i, doctorQuestionSection.eI))));
								}),
							A2(
								$elm$core$List$sortBy,
								function (sec) {
									return sec.hG.bB;
								},
								A2(
									$elm$core$List$filter,
									function (q) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (t) {
													var answer = A3($author$project$App$Page$Reviewer$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
													var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
													return show;
												},
												q.bY)) > 0;
									},
									fullQuestionSection.eI)))))
				]));
	});
var $author$project$App$Page$Reviewer$PageReevaluation$viewEditStatementSectionWithoutHeader = F5(
	function (depIdx, sharedState, model, fullQuestionSection, doctorQuestionSection) {
		return $elm$core$List$concat(
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (i, q) {
						return A5(
							$author$project$App$Page$Reviewer$PageReevaluation$viewQuestion,
							depIdx,
							q,
							sharedState,
							model,
							A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2($elm$core$List$drop, i, doctorQuestionSection.eI))));
					}),
				A2(
					$elm$core$List$sortBy,
					function (sec) {
						return sec.hG.bB;
					},
					A2(
						$elm$core$List$filter,
						function (q) {
							return $elm$core$List$length(
								A2(
									$elm$core$List$filter,
									function (t) {
										var answer = A3($author$project$App$Page$Reviewer$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
										var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
										return show;
									},
									q.bY)) > 0;
						},
						fullQuestionSection.eI))));
	});
var $author$project$App$Page$Reviewer$PageReevaluation$viewPersonDetails = F3(
	function (sharedState, depIdx, editStmt) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(editStmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$fullPersonName(editStmt.dw))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$formatDocument(editStmt.dw.i1))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Tools$FormHelpers$dateFormat(editStmt.dw.al))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									function (str) {
										return str + ' anos';
									}(
										$elm$core$String$fromInt(
											A2(
												$author$project$App$Data$Person$ageOnDate,
												$author$project$App$Service$ApiWebSocket$getTimestamp(sharedState._),
												editStmt.dw))))
								])),
							sharedState.ia.h8.cJ.gX ? A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									function () {
										if (depIdx.$ === 1) {
											var i = depIdx.a;
											return 'Dependente ' + $elm$core$String$fromInt(i + 1);
										} else {
											return _Utils_eq(editStmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
										}
									}())
								])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
						]))
				]));
	});
var $author$project$App$Page$Reviewer$PageReevaluation$viewPersonAnswers = F4(
	function (sharedState, model, depIdx, stmt) {
		var showPerson = sharedState.ia.h8.b4.iR === 'Extramed';
		var showDoctorQuestions = (sharedState.ia.h8.b4.iR === 'Extramed') || (sharedState.ia.h8.b4.iR === 'Centauro-ON');
		var showBMI = sharedState.ia.h8.b4.iR === 'Extramed';
		var doctorSection = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestionSection$emptyModel,
			$elm$core$List$head(
				$elm$core$List$reverse(sharedState.ia.h8.gH)));
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$ul,
							_List_Nil,
							_List_fromArray(
								[
									A3($author$project$App$Page$Reviewer$PageReevaluation$viewPersonDetails, sharedState, depIdx, stmt)
								]))
						])),
					showBMI ? A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '100%')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Dados Antropométricos')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Valor')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('CIDs')
												]))
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							A4($author$project$App$Page$Reviewer$PageReevaluation$viewBMI, sharedState, model, depIdx, stmt))
						])) : $elm$html$Html$text('')
				]),
			_Utils_ap(
				showBMI ? _List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '100%')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Declaração')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Marcado')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Resposta')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Observação Médica')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('CID')
													]))
											]))
									])),
								A2(
								$elm$html$Html$tbody,
								_List_Nil,
								$elm$core$List$concat(
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (i, s) {
												return A5(
													$author$project$App$Page$Reviewer$PageReevaluation$viewEditStatementSectionWithoutHeader,
													depIdx,
													sharedState,
													model,
													s,
													A2(
														$elm$core$Maybe$withDefault,
														$author$project$App$Data$FullQuestionSection$emptyModel,
														$elm$core$List$head(
															A2($elm$core$List$drop, i, sharedState.ia.h8.bs))));
											}),
										A2(
											$elm$core$List$filter,
											function (s) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (q) {
															return $elm$core$List$length(
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var answer = A3($author$project$App$Page$Reviewer$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																		var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
																		return show;
																	},
																	q.bY)) > 0;
														},
														A2(
															$elm$core$List$sortBy,
															function (sec) {
																return sec.hG.bB;
															},
															s.eI))) > 0;
											},
											sharedState.ia.h8.bs))))
							]))
					]) : _List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '100%')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Declaração')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Marcado')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Resposta')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Observação Médica')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('CID')
													]))
											]))
									])),
								A2(
								$elm$html$Html$tbody,
								_List_Nil,
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, s) {
											return A5(
												$author$project$App$Page$Reviewer$PageReevaluation$viewEditStatementSectionWithHeader,
												depIdx,
												sharedState,
												model,
												s,
												A2(
													$elm$core$Maybe$withDefault,
													$author$project$App$Data$FullQuestionSection$emptyModel,
													$elm$core$List$head(
														A2($elm$core$List$drop, i, sharedState.ia.h8.bs))));
										}),
									A2(
										$elm$core$List$filter,
										function (s) {
											return $elm$core$List$length(
												A2(
													$elm$core$List$filter,
													function (q) {
														return $elm$core$List$length(
															A2(
																$elm$core$List$filter,
																function (t) {
																	var answer = A3($author$project$App$Page$Reviewer$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																	var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
																	return show;
																},
																q.bY)) > 0;
													},
													A2(
														$elm$core$List$sortBy,
														function (sec) {
															return sec.hG.bB;
														},
														s.eI))) > 0;
										},
										sharedState.ia.h8.bs)))
							]))
					]),
				_Utils_ap(
					_List_fromArray(
						[
							showBMI ? A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '100%')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$thead,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Questionário')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Marcado')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Resposta')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Observação Médica')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('CID')
														]))
												]))
										])),
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											F2(
												function (i, s) {
													return A5(
														$author$project$App$Page$Reviewer$PageReevaluation$viewEditStatementSectionWithoutHeader,
														depIdx,
														sharedState,
														model,
														s,
														A2(
															$elm$core$Maybe$withDefault,
															$author$project$App$Data$FullQuestionSection$emptyModel,
															$elm$core$List$head(
																A2($elm$core$List$drop, i, sharedState.ia.h8.jM))));
												}),
											A2(
												$elm$core$List$filter,
												function (s) {
													return $elm$core$List$length(
														A2(
															$elm$core$List$filter,
															function (q) {
																return $elm$core$List$length(
																	A2(
																		$elm$core$List$filter,
																		function (t) {
																			var answer = A3($author$project$App$Page$Reviewer$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																			var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
																			return show;
																		},
																		q.bY)) > 0;
															},
															A2(
																$elm$core$List$sortBy,
																function (sec) {
																	return sec.hG.bB;
																},
																s.eI))) > 0;
												},
												sharedState.ia.h8.jM))))
								])) : (showPerson ? A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '100%')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$thead,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Questionário')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Marcado')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Resposta')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Observação Médica')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('CID')
														]))
												]))
										])),
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (i, s) {
												return A5(
													$author$project$App$Page$Reviewer$PageReevaluation$viewEditStatementSectionWithHeader,
													depIdx,
													sharedState,
													model,
													s,
													A2(
														$elm$core$Maybe$withDefault,
														$author$project$App$Data$FullQuestionSection$emptyModel,
														$elm$core$List$head(
															A2($elm$core$List$drop, i, sharedState.ia.h8.jM))));
											}),
										A2(
											$elm$core$List$filter,
											function (s) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (q) {
															return $elm$core$List$length(
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var answer = A3($author$project$App$Page$Reviewer$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																		var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																		return show;
																	},
																	q.bY)) > 0;
														},
														A2(
															$elm$core$List$sortBy,
															function (sec) {
																return sec.hG.bB;
															},
															s.eI))) > 0;
											},
											sharedState.ia.h8.jM)))
								])) : $elm$html$Html$text(''))
						]),
					function () {
						if (showDoctorQuestions) {
							return showBMI ? A2(
								$elm$core$List$cons,
								A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'width', '100%')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$colgroup,
											_List_Nil,
											_List_fromArray(
												[
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil)
												])),
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$tr,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$th,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$colspan(5)
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Atendimento')
																]))
														]))
												]))
										])),
								$elm$core$List$concat(
									A2(
										$elm$core$List$map,
										function (s) {
											return A5($author$project$App$Page$Reviewer$PageReevaluation$viewEditStatementSectionWithoutHeader, depIdx, sharedState, model, s, s);
										},
										A2(
											$elm$core$List$filter,
											function (s) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (q) {
															return ($elm$core$List$length(
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var answer = A3($author$project$App$Page$Reviewer$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																		var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																		return show;
																	},
																	q.bY)) > 0) && (!A2(
																$elm$core$List$any,
																function (t) {
																	return (t.ay === 10) || (t.ay === 20);
																},
																q.bY));
														},
														A2(
															$elm$core$List$sortBy,
															function (sec) {
																return sec.hG.bB;
															},
															s.eI))) > 0;
											},
											sharedState.ia.h8.gH)))) : A2(
								$elm$core$List$map,
								function (s) {
									return A5($author$project$App$Page$Reviewer$PageReevaluation$viewEditStatementSectionWithHeader, depIdx, sharedState, model, s, s);
								},
								A2(
									$elm$core$List$filter,
									function (s) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (q) {
													return $elm$core$List$length(
														A2(
															$elm$core$List$filter,
															function (t) {
																var answer = A3($author$project$App$Page$Reviewer$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																return show;
															},
															q.bY)) > 0;
												},
												A2(
													$elm$core$List$sortBy,
													function (sec) {
														return sec.hG.bB;
													},
													s.eI))) > 0;
									},
									sharedState.ia.h8.gH));
						} else {
							var question = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(doctorSection.eI));
							var template = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$AnswerTemplate$emptyModel,
								$elm$core$List$head(question.bY));
							var answer = A3($author$project$App$Page$Reviewer$PageReevaluation$getAnswerForTemplateId, depIdx, model, template.as);
							return (answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0))) ? _List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Evoluções médicas:')
										])),
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(answer.T)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Atestado Médico:')
										])),
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(answer.jx)
										]),
									_List_Nil)
								]) : _List_Nil;
						}
					}())));
	});
var $author$project$App$Page$Reviewer$PageReevaluation$viewQuestions = F2(
	function (model, sharedState) {
		var depsPersonAnswers = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$second,
				$elm$core$Dict$toList(
					A2(
						$elm$core$Dict$map,
						F2(
							function (idx, dep) {
								return A4(
									$author$project$App$Page$Reviewer$PageReevaluation$viewPersonAnswers,
									sharedState,
									model,
									$author$project$App$Data$Statement$depIndexFromInt(idx),
									dep);
							}),
						sharedState.ia.h8.fh))));
		var bearerPersonAnswers = (!(sharedState.ia.h8.g.e.j_ === 2)) ? A4($author$project$App$Page$Reviewer$PageReevaluation$viewPersonAnswers, sharedState, model, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g) : _List_Nil;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('questions')
				]),
			_Utils_ap(
				bearerPersonAnswers,
				_Utils_ap(
					depsPersonAnswers,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Conclusões finais do atendimento:')
								])),
							A2(
							$elm$html$Html$textarea,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(model.P.g.e.jx)
								]),
							_List_Nil)
						]))));
	});
var $author$project$App$Page$Reviewer$PageReevaluation$SelectDetailsTab = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Reviewer$PageReevaluation$TabCPT = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Reviewer$PageReevaluation$TabDocument = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Reviewer$PageReevaluation$viewDocumentTabNumered = F4(
	function (currentTab, idx, doc, documentTabs) {
		var index = $elm$core$List$length(documentTabs) + 1;
		return A2(
			$elm$core$List$append,
			documentTabs,
			function () {
				var _v0 = doc.dr;
				switch (_v0) {
					case 'video/webm':
						return _List_fromArray(
							[
								$elm$html$Html$text('')
							]);
					case 'video/mp4':
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Reviewer$PageReevaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Reviewer$PageReevaluation$SelectDetailsTab(
											$author$project$App$Page$Reviewer$PageReevaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										'Video' + $elm$core$String$fromInt(index))
									]))
							]);
					default:
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Reviewer$PageReevaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Reviewer$PageReevaluation$SelectDetailsTab(
											$author$project$App$Page$Reviewer$PageReevaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											var _v1 = doc.b7;
											switch (_v1) {
												case 1:
													return 'Parecer Antigo' + $elm$core$String$fromInt(index);
												case 3:
													return 'Atestado' + $elm$core$String$fromInt(index);
												case 4:
													return 'Receita' + $elm$core$String$fromInt(index);
												default:
													return 'Anexo';
											}
										}())
									]))
							]);
				}
			}());
	});
var $author$project$App$Page$Reviewer$PageReevaluation$TabMedicalDocument = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Reviewer$PageReevaluation$viewResultTab = F5(
	function (previewTitle, typeHnsg, currentTab, idx, d) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Reviewer$PageReevaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Reviewer$PageReevaluation$SelectDetailsTab(
							$author$project$App$Page$Reviewer$PageReevaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(previewTitle)
					])),
				typeHnsg ? A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Reviewer$PageReevaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Reviewer$PageReevaluation$SelectDetailsTab(
							$author$project$App$Page$Reviewer$PageReevaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Pré-Visualizar Atestado')
					])) : A2($elm$html$Html$span, _List_Nil, _List_Nil)
			]);
	});
var $author$project$App$Page$Reviewer$PageReevaluation$viewQuestionsTabs = F5(
	function (fullStmt, viewCPT, typeHnsg, currentTab, docs) {
		var reportDocs = A2(
			$elm$core$List$sortWith,
			F2(
				function (a, b) {
					var _v8 = A2(
						$elm$core$Basics$compare,
						$elm$time$Time$posixToMillis(a.b.iW),
						$elm$time$Time$posixToMillis(b.b.iW));
					switch (_v8) {
						case 0:
							return 2;
						case 1:
							return 1;
						default:
							return 0;
					}
				}),
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v7, doc) {
							return doc.b7 === 1;
						}),
					docs)));
		var previewTitle = typeHnsg ? 'Pré-Visualizar Prontuário' : 'Pré-Visualizar Parecer';
		var resultTab = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$second,
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$map,
							A3($author$project$App$Page$Reviewer$PageReevaluation$viewResultTab, previewTitle, typeHnsg, currentTab),
							$elm$core$Dict$fromList(
								A2($elm$core$List$take, 1, reportDocs)))))));
		var oldResultsTab = A3(
			$elm$core$Dict$foldl,
			$author$project$App$Page$Reviewer$PageReevaluation$viewDocumentTabNumered(currentTab),
			_List_Nil,
			$elm$core$Dict$fromList(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(reportDocs))));
		var docsTabs = A3(
			$elm$core$Dict$foldl,
			F3(
				function (i, d, list) {
					var attachName = function () {
						var _v6 = d.dr;
						if (_v6 === 'video/mp4') {
							return 'Video';
						} else {
							return 'Anexo ' + $elm$core$String$fromInt(i);
						}
					}();
					return A2(
						$elm$core$List$append,
						list,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Reviewer$PageReevaluation$TabDocument(i)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Reviewer$PageReevaluation$SelectDetailsTab(
											$author$project$App$Page$Reviewer$PageReevaluation$TabDocument(i))),
										$elm$html$Html$Attributes$href('#')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(attachName)
									]))
							]));
				}),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'tab-selected',
									_Utils_eq(currentTab, $author$project$App$Page$Reviewer$PageReevaluation$TabCIDs))
								])),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Reviewer$PageReevaluation$SelectDetailsTab($author$project$App$Page$Reviewer$PageReevaluation$TabCIDs))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Dados do Atendimento')
						]))
				]),
			A2(
				$elm$core$Dict$filter,
				F2(
					function (_v5, d) {
						return d.b7 !== 5;
					}),
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v4, d) {
							return d.b7 !== 3;
						}),
					A2(
						$elm$core$Dict$filter,
						F2(
							function (_v3, d) {
								return d.b7 !== 1;
							}),
						$elm$core$Dict$fromList(
							_Utils_ap(
								A2(
									$elm$core$List$take,
									1,
									$elm$core$List$reverse(
										$elm$core$Dict$toList(
											A2(
												$elm$core$Dict$filter,
												F2(
													function (_v1, doc) {
														return doc.dr === 'video/mp4';
													}),
												docs)))),
								$elm$core$Dict$toList(
									A2(
										$elm$core$Dict$filter,
										F2(
											function (_v2, doc) {
												return (doc.dr !== 'video/webm') && (doc.dr !== 'video/mp4');
											}),
										docs))))))));
		var bearerCpt = (fullStmt.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Reviewer$PageReevaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Reviewer$PageReevaluation$SelectDetailsTab(
							$author$project$App$Page$Reviewer$PageReevaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('CPT Titular')
					]))
			]);
		var cptTabs = viewCPT ? A2(
			$elm$core$List$append,
			bearerCpt,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var idx = _v0.a;
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('#'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'tab-selected',
										_Utils_eq(
											currentTab,
											$author$project$App$Page$Reviewer$PageReevaluation$TabCPT(
												$author$project$App$Data$Statement$Dependent(idx))))
									])),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Reviewer$PageReevaluation$SelectDetailsTab(
									$author$project$App$Page$Reviewer$PageReevaluation$TabCPT(
										$author$project$App$Data$Statement$Dependent(idx))))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'CPT Dep ' + $elm$core$String$fromInt(idx + 1))
							]));
				},
				$elm$core$Dict$toList(fullStmt.fh))) : _List_Nil;
		var allTabs = _Utils_ap(
			docsTabs,
			_Utils_ap(
				resultTab,
				_Utils_ap(oldResultsTab, cptTabs)));
		return A2($elm$html$Html$div, _List_Nil, allTabs);
	});
var $author$project$App$Page$Reviewer$PageReevaluation$viewResultContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewResultLayout(fullStmt);
};
var $author$project$App$Page$Reviewer$PageReevaluation$view = F3(
	function (now, sharedState, model) {
		var viewCPT = sharedState.ia.h8.b4.iR === 'Extramed';
		var typeHnsg = A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var maybePdfResult = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var maybePdfDocument = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var hasMedicalReport = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var hasMedicalDocument = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var dialogView = $author$project$App$Page$Reviewer$PageReevaluation$dialogFromModel(model);
		var confirmButton = (model.P.g.e.aw === 31) ? _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$PageReevaluation$SendToReview)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Retornar para médico')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$PageReevaluation$FinishReevaluation)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Finalizar')
							]))
					]))
			]) : _List_Nil;
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$header,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('tabs')
								]),
							_Utils_ap(
								_List_fromArray(
									[
										A5($author$project$App$Page$Reviewer$PageReevaluation$viewQuestionsTabs, sharedState.ia.h8, viewCPT, typeHnsg, model.b5, sharedState.ia.h8.an),
										A2(
										$elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('cancel-button'),
														$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$PageReevaluation$BackToFinished)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Retornar')
													]))
											]))
									]),
								confirmButton)),
							function () {
							var _v0 = model.b5;
							switch (_v0.$) {
								case 0:
									return A2($author$project$App$Page$Reviewer$PageReevaluation$viewQuestions, model, sharedState);
								case 1:
									var docIdx = _v0.a;
									return $author$project$App$Page$Reviewer$PageReevaluation$viewDoc(
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$Document$emptyModel,
											A2($elm$core$Dict$get, docIdx, model.an)));
								case 2:
									var mDepIdx = _v0.a;
									if (!maybePdfResult.$) {
										var pdfResult = maybePdfResult.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Reviewer$PageReevaluation$viewResultContent(sharedState.ia.h8);
									}
								case 3:
									var mDepIdx = _v0.a;
									if (!maybePdfDocument.$) {
										var pdfResult = maybePdfDocument.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Reviewer$PageReevaluation$viewMedicalDocumentContent(model.P);
									}
								case 5:
									var videoRepo = {f3: 'video-repo-enterprise-covid.ingress.medsyn.com.br'};
									var videoUrl = A2($author$project$App$Data$VideoRepository$statementVideoUrl, videoRepo, sharedState.ia.h8.g.e.as);
									return A2(
										$elm$html$Html$video,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('video-preview'),
												$elm$html$Html$Attributes$controls(true)
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$source,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$src(videoUrl),
														$elm$html$Html$Attributes$type_('video/mp4')
													]),
												_List_Nil),
												$elm$html$Html$text('Seu browser não suporte exibição de vídeo HTML5')
											]));
								case 4:
									var depIdx = _v0.a;
									var fullStmt = sharedState.ia.h8;
									return A3($author$project$App$Page$Reviewer$PageReevaluation$viewCPTContent, depIdx, fullStmt, now);
								default:
									return $elm$html$Html$text('');
							}
						}()
						]))
				]),
			_Utils_ap(
				A2($author$project$App$Page$Reviewer$PageReevaluation$viewICDSection, sharedState, model),
				_List_fromArray(
					[dialogView])));
	});
var $author$project$App$Page$Reviewer$PageReview$CancelReturnToCompany = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Reviewer$PageReview$ConfirmReturnToCompany = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$App$Page$Reviewer$PageReview$InputObservationField = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $author$project$App$Page$Reviewer$PageReview$dialogFromModel = F2(
	function (model, st) {
		var header = _List_Nil;
		var footer = function (filter) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('cancel-button'),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Reviewer$PageReview$CancelReturnToCompany(model))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Cancelar')
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							A2($author$project$App$Page$Reviewer$PageReview$ConfirmReturnToCompany, model.as, filter))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Devolver')
						]))
				]);
		};
		var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
		var body = function (filter) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$textarea,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$cols(50),
							$elm$html$Html$Attributes$rows(5),
							$elm$html$Html$Attributes$value(model.h8.g.e.jx),
							$elm$html$Html$Events$onInput(
							function (str) {
								return A3(
									$author$project$App$Page$Reviewer$PageReview$InputObservationField,
									model.as,
									filter,
									$author$project$App$Data$Statement$FieldObservation(str));
							})
						]),
					_List_Nil)
				]);
		};
		var completeConfig = function (filter) {
			return _Utils_update(
				config,
				{
					e5: $elm$core$Maybe$Just(
						body(filter)),
					ft: $elm$core$Maybe$Just(
						footer(filter)),
					gY: $elm$core$Maybe$Just(header),
					f1: 'Motivo da Devolução'
				});
		};
		return model.dC ? $author$project$App$Components$Dialog$view(
			$elm$core$Maybe$Just(
				completeConfig(model.b))) : $elm$html$Html$text('');
	});
var $author$project$App$Page$Reviewer$PageReview$Error = 2;
var $author$project$App$Page$Reviewer$PageReview$Yes = 1;
var $author$project$App$Page$Reviewer$PageReview$errorsDictIsEmpty = function (errors) {
	return !$elm$core$Dict$size(
		A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, fields) {
					return $elm$core$List$length(fields) > 0;
				}),
			errors));
};
var $author$project$App$Page$Reviewer$PageReview$viewErrors = F2(
	function (st, model) {
		var _v0 = A2($author$project$App$Service$ApplicationResources$getNewFullStatementForCompanyResponse, model.gx, st._);
		if (_v0.$ === 1) {
			var _v1 = _v0.a;
			var specificError = _v1.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$App$Service$SpecificResources$errorToMessage(specificError))
					]));
		} else {
			return (!$author$project$App$Page$Reviewer$PageReview$errorsDictIsEmpty(model.fp)) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('')
					])) : ((model.cH === 1) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Cadastrando atendimento...')
					])) : ((model.cH === 2) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Ocorreu um erro ao tentar cadastrar o atendimento.')
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Tente novamente em alguns instantes.')
							]))
					])) : $elm$html$Html$text('')));
		}
	});
var $author$project$App$Page$Reviewer$PageReview$GoToPending = {$: 2};
var $author$project$App$Page$Reviewer$PageReview$ReturnToCompany = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$App$Page$Reviewer$PageReview$Save = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$Reviewer$PageReview$fullQuestionSorter = F2(
	function (a, b) {
		return A2($elm$core$Basics$compare, a.hG.bB, b.hG.bB);
	});
var $author$project$App$Page$Reviewer$PageReview$getTemplateQuestions = F2(
	function (templateId, st) {
		var sections = st.ia.h8.bs;
		var questions = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					function (s) {
						return s.eI;
					},
					A2(
						$elm$core$List$filter,
						function (s) {
							return _Utils_eq(s.hX.eY, templateId);
						},
						sections))));
		return questions;
	});
var $author$project$App$Page$Reviewer$PageReview$getAnswerForTemplate = F2(
	function (stmt, template) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Answer$emptyModel,
			A2($elm$core$Dict$get, template.as, stmt.gw));
	});
var $author$project$App$Page$Reviewer$PageReview$InputAnswerField = F5(
	function (a, b, c, d, e) {
		return {$: 7, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$App$Page$Reviewer$PageReview$maskDecimal = F2(
	function (precision, value) {
		return A3($elm$core$String$replace, '.', ',', value);
	});
var $author$project$App$Page$Reviewer$PageReview$maskHeight = function (cm) {
	var len = $elm$core$String$length(cm);
	var subM = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cm)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cm) : cm);
	var h = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(cm));
	var m = (h >= 100) ? A3($elm$core$String$slice, 0, -2, cm) : '0';
	return (h > 0) ? (m + (',' + subM)) : '';
};
var $author$project$App$Page$Reviewer$PageReview$maskPressure = function (str) {
	return function (p) {
		return (p > 0) ? $elm$core$String$fromInt(p) : '';
	}(
		function (p) {
			return (p > 999) ? (p / 10) : (p / 1.0);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(str))) | 0);
};
var $author$project$App$Page$Reviewer$PageReview$maskWeight = function (wStr) {
	return function (w) {
		return (w > 0) ? $elm$core$String$fromInt(w) : '';
	}(
		function (w) {
			return w / 1000;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(wStr))) | 0);
};
var $author$project$App$Page$Reviewer$PageReview$storeDecimal = F2(
	function (precision, value) {
		var _v0 = $elm$regex$Regex$fromString('[^\\d]');
		if (!_v0.$) {
			var regex = _v0.a;
			var decimalPoints = precision * 10;
			var cleared = A3(
				$elm$regex$Regex$replace,
				regex,
				function (_v1) {
					return '';
				},
				value);
			var floatValue = A3(
				$elm$core$Basics$clamp,
				0,
				1000000000,
				A2(
					$elm$core$Maybe$withDefault,
					0,
					A2(
						$elm$core$Maybe$map,
						function (v) {
							return v / decimalPoints;
						},
						$elm$core$String$toFloat(cleared))));
			var intValue = A3($elm$core$Basics$clamp, 0, 1000000, floatValue | 0);
			var decimalValue = $elm$core$Basics$round((floatValue - intValue) * decimalPoints);
			var decimalValueStr = function (s) {
				return (_Utils_cmp(
					$elm$core$String$length(s),
					precision) > 0) ? A2(
					$elm$core$String$dropRight,
					$elm$core$String$length(s) - precision,
					s) : s;
			}(
				$elm$core$String$fromInt(decimalValue));
			return ((intValue > 0) || (decimalValue > 0)) ? ($elm$core$String$fromInt(intValue) + ('.' + decimalValueStr)) : '';
		} else {
			return '';
		}
	});
var $author$project$App$Page$Reviewer$PageReview$storeHeight = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return function (h) {
			return (h > 0) ? $elm$core$String$fromInt(h) : '';
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A3(
						$elm$regex$Regex$replace,
						regex,
						function (_v1) {
							return '';
						},
						str))));
	} else {
		return '';
	}
};
var $author$project$App$Page$Reviewer$PageReview$storePressure = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return function (p) {
			return (p > 0) ? $elm$core$String$fromInt(p) : '';
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A3(
						$elm$regex$Regex$replace,
						regex,
						function (_v1) {
							return '';
						},
						str))));
	} else {
		return '';
	}
};
var $author$project$App$Page$Reviewer$PageReview$storeWeight = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return function (w) {
			return (w > 0) ? $elm$core$String$fromInt(w) : '';
		}(
			1000 * A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A3(
						$elm$regex$Regex$replace,
						regex,
						function (_v1) {
							return '';
						},
						str))));
	} else {
		return '';
	}
};
var $author$project$App$Page$Reviewer$PageReview$setupMask = F5(
	function (depIdx, fullQuestion, template, answer, attrs) {
		var _v0 = template.jj;
		if (_v0.$ === 1) {
			return attrs;
		} else {
			switch (_v0.a.$) {
				case 3:
					var precision = _v0.a.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							A2($author$project$App$Page$Reviewer$PageReview$maskDecimal, precision, answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Reviewer$PageReview$storeDecimal(precision),
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A4($author$project$App$Page$Reviewer$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer))))
						]);
				case 0:
					var _v1 = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('number'),
							$elm$html$Html$Attributes$value(
							$author$project$App$Page$Reviewer$PageReview$maskWeight(answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Reviewer$PageReview$storeWeight,
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A4($author$project$App$Page$Reviewer$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer))))
						]);
				case 1:
					var _v2 = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							$author$project$App$Page$Reviewer$PageReview$maskHeight(answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Reviewer$PageReview$storeHeight,
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A4($author$project$App$Page$Reviewer$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer))))
						]);
				default:
					var _v3 = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							$author$project$App$Page$Reviewer$PageReview$maskPressure(answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Reviewer$PageReview$storePressure,
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A4($author$project$App$Page$Reviewer$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer))))
						]);
			}
		}
	});
var $author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeDate = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('review-answer')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						A5(
							$author$project$App$Page$Reviewer$PageReview$setupMask,
							depIdx,
							fullQuestion,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('date'),
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A4($author$project$App$Page$Reviewer$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer)))
								])),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeNumeric = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('review-answer')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						A5(
							$author$project$App$Page$Reviewer$PageReview$setupMask,
							depIdx,
							fullQuestion,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('number'),
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A4($author$project$App$Page$Reviewer$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer)))
								])),
						_List_Nil),
						$elm$html$Html$text(
						A2($elm$core$Maybe$withDefault, '', template.iv))
					]))
			]);
	});
var $author$project$App$Page$Reviewer$PageReview$InputSingleChoice = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var $author$project$App$Page$Reviewer$PageReview$onRadioChange = F2(
	function (depIdx, q) {
		return $elm$html$Html$Events$onInput(
			A2($author$project$App$Page$Reviewer$PageReview$InputSingleChoice, depIdx, q));
	});
var $author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeOption = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('review-answer'),
						$elm$html$Html$Attributes$for(
						_Utils_ap(
							template.as,
							$elm$core$String$fromInt(
								A2($elm$core$Maybe$withDefault, -1, depIdx))))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(
								_Utils_ap(
									template.as,
									$elm$core$String$fromInt(
										A2($elm$core$Maybe$withDefault, -1, depIdx)))),
								$elm$html$Html$Attributes$name(
								_Utils_ap(
									template.as,
									$elm$core$String$fromInt(
										A2($elm$core$Maybe$withDefault, -1, depIdx)))),
								$elm$html$Html$Attributes$type_('radio'),
								$elm$html$Html$Attributes$checked(answer.dF),
								A2($author$project$App$Page$Reviewer$PageReview$onRadioChange, depIdx, fullQuestion),
								$elm$html$Html$Attributes$value(template.as)
							]),
						_List_Nil),
						$elm$html$Html$text(template.jh)
					]))
			]);
	});
var $author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeOptionCheck = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$for(
						_Utils_ap(
							template.as,
							$elm$core$String$fromInt(
								A2($elm$core$Maybe$withDefault, -1, depIdx))))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(
								_Utils_ap(
									template.as,
									$elm$core$String$fromInt(
										A2($elm$core$Maybe$withDefault, -1, depIdx)))),
								$elm$html$Html$Attributes$type_('checkbox'),
								$elm$html$Html$Attributes$checked(answer.dF),
								$elm$html$Html$Events$onCheck(
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldSelectedBool,
									A4($author$project$App$Page$Reviewer$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer))),
								$elm$html$Html$Attributes$value(template.as)
							]),
						_List_Nil),
						$elm$html$Html$text(template.jh)
					]))
			]);
	});
var $author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeOptionWithComplement = F4(
	function (depIdx, fullQuestion, template, answer) {
		var placeholderText = answer.dF ? 'Sua resposta aqui' : '';
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('review-answer'),
						$elm$html$Html$Attributes$for(
						_Utils_ap(
							template.as,
							$elm$core$String$fromInt(
								A2($elm$core$Maybe$withDefault, -1, depIdx))))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(
								_Utils_ap(
									template.as,
									$elm$core$String$fromInt(
										A2($elm$core$Maybe$withDefault, -1, depIdx)))),
								$elm$html$Html$Attributes$name(
								_Utils_ap(
									template.as,
									$elm$core$String$fromInt(
										A2($elm$core$Maybe$withDefault, -1, depIdx)))),
								$elm$html$Html$Attributes$type_('radio'),
								$elm$html$Html$Attributes$checked(answer.dF),
								A2($author$project$App$Page$Reviewer$PageReview$onRadioChange, depIdx, fullQuestion),
								$elm$html$Html$Attributes$value(template.as)
							]),
						_List_Nil),
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$placeholder(placeholderText),
								$elm$html$Html$Attributes$type_('text'),
								$elm$html$Html$Events$onInput(
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A4($author$project$App$Page$Reviewer$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer))),
								$elm$html$Html$Attributes$value(answer.T)
							]),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeString = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('review-answer')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						A5(
							$author$project$App$Page$Reviewer$PageReview$setupMask,
							depIdx,
							fullQuestion,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('text'),
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A4($author$project$App$Page$Reviewer$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer)))
								])),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeTableRowDesktop = F4(
	function (depIdx, fullQuestion, template, answer) {
		var theadContent = $elm$core$List$reverse(
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$th,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'role', 'columnheader')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Ações')
							]))
					]),
				$elm$core$List$reverse(
					A2(
						$elm$core$List$map,
						function (l) {
							return A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'role', 'columnheader')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(l)
									]));
						},
						A2($elm$core$String$split, ',', template.jh)))));
		var tableCell = F3(
			function (idx, header, v) {
				return A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'role', 'cell')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$placeholder(header),
									$elm$html$Html$Attributes$type_('text'),
									$elm$html$Html$Attributes$value(v),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										A2($author$project$App$Data$Answer$FieldTableRow, idx, header),
										A4($author$project$App$Page$Reviewer$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer)))
								]),
							_List_Nil)
						]));
			});
		var tableRow = function (_v1) {
			var idx = _v1.a;
			var row = _v1.b;
			return A2(
				$elm$html$Html$tr,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'row')
					]),
				$elm$core$List$reverse(
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'role', 'cell')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick(
												A5(
													$author$project$App$Page$Reviewer$PageReview$InputAnswerField,
													depIdx,
													template.as,
													answer.aV,
													answer,
													$author$project$App$Data$Answer$FieldTableRowRemove(idx)))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Remover')
											]))
									]))
							]),
						$elm$core$List$reverse(
							A2(
								$elm$core$List$map,
								function (header) {
									return A3(
										tableCell,
										idx,
										header,
										A2(
											$elm$core$Maybe$withDefault,
											'',
											A2($elm$core$Dict$get, header, row)));
								},
								A2($elm$core$String$split, ',', template.jh))))));
		};
		var tbodyContent = function () {
			var _v0 = answer.G;
			if (_v0.$ === 1) {
				return _List_Nil;
			} else {
				var tableRows = _v0.a;
				return A2(
					$elm$core$List$map,
					tableRow,
					$elm$core$Dict$toList(tableRows));
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$thead,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'rowgroup')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tr,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'role', 'row')
							]),
						theadContent)
					])),
				A2(
				$elm$html$Html$tbody,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'rowgroup')
					]),
				tbodyContent)
			]);
	});
var $author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeTextBox = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'flex-direction', 'column'),
						A2($elm$html$Html$Attributes$style, 'padding-top', '20px')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$textarea,
						A5(
							$author$project$App$Page$Reviewer$PageReview$setupMask,
							depIdx,
							fullQuestion,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A4($author$project$App$Page$Reviewer$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer))),
									$elm$html$Html$Attributes$class('text-box'),
									A2($elm$html$Html$Attributes$style, 'min-width', '400px')
								])),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Reviewer$PageReview$viewAnswerTemplate = F5(
	function (depIdx, stmt, fullQuestion, template, answer) {
		var _v0 = template.j5;
		switch (_v0) {
			case 0:
				return _List_Nil;
			case 2:
				return A4($author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeString, depIdx, fullQuestion, template, answer);
			case 3:
				return A4($author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeNumeric, depIdx, fullQuestion, template, answer);
			case 5:
				return A4($author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeDate, depIdx, fullQuestion, template, answer);
			case 1:
				return A4($author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeOption, depIdx, fullQuestion, template, answer);
			case 6:
				return A4($author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeOptionCheck, depIdx, fullQuestion, template, answer);
			case 4:
				return A4($author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeOptionWithComplement, depIdx, fullQuestion, template, answer);
			case 7:
				return A4($author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeTableRowDesktop, depIdx, fullQuestion, template, answer);
			default:
				return A4($author$project$App$Page$Reviewer$PageReview$viewAnswerTemplateTypeTextBox, depIdx, fullQuestion, template, answer);
		}
	});
var $author$project$App$Page$Reviewer$PageReview$viewToggleWithComplement = F5(
	function (depIdx, fullQuestion, yesTemplate, noTemplate, stmt) {
		var yesAnswer = A2($author$project$App$Page$Reviewer$PageReview$getAnswerForTemplate, stmt, yesTemplate);
		var otherTemplate = yesAnswer.dF ? noTemplate : yesTemplate;
		var noAnswer = A2($author$project$App$Page$Reviewer$PageReview$getAnswerForTemplate, stmt, noTemplate);
		var otherAnswer = yesAnswer.dF ? noAnswer : yesAnswer;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('toggle')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$name(
								$elm$core$String$fromInt(fullQuestion.hG.bB) + ('.' + $elm$core$String$fromInt(
									A2($elm$core$Maybe$withDefault, -1, depIdx)))),
								$elm$html$Html$Attributes$type_('checkbox'),
								$elm$html$Html$Events$onClick(
								A3($author$project$App$Page$Reviewer$PageReview$InputSingleChoice, depIdx, fullQuestion, otherTemplate.as)),
								$elm$html$Html$Attributes$checked(yesAnswer.dF)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('toggle-slider')
							]),
						_List_Nil)
					])),
				A2($elm$html$Html$br, _List_Nil, _List_Nil),
				yesAnswer.dF ? A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(yesAnswer.T),
						$elm$html$Html$Events$onInput(
						A2(
							$elm$core$Basics$composeR,
							$author$project$App$Data$Answer$FieldValue,
							A4($author$project$App$Page$Reviewer$PageReview$InputAnswerField, depIdx, yesTemplate.as, yesAnswer.aV, yesAnswer)))
					]),
				_List_Nil) : $elm$html$Html$text('')
			]);
	});
var $author$project$App$Page$Reviewer$PageReview$viewQuestionCommon = F3(
	function (depIdx, stmt, fullQuestion) {
		var isToggleWithComplement = ($elm$core$List$length(fullQuestion.bY) === 2) && (A2(
			$elm$core$List$any,
			function (t) {
				return t.j5 === 4;
			},
			fullQuestion.bY) && A2(
			$elm$core$List$any,
			function (t) {
				return t.j5 === 1;
			},
			fullQuestion.bY));
		var templates = function () {
			if (isToggleWithComplement) {
				var yesTemplate = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$AnswerTemplate$emptyModel,
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (t) {
								return t.j5 === 4;
							},
							fullQuestion.bY)));
				var noTemplate = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$AnswerTemplate$emptyModel,
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (t) {
								return t.j5 === 1;
							},
							fullQuestion.bY)));
				return A5($author$project$App$Page$Reviewer$PageReview$viewToggleWithComplement, depIdx, fullQuestion, yesTemplate, noTemplate, stmt);
			} else {
				return $elm$core$List$concat(
					A2(
						$elm$core$List$map,
						function (t) {
							return A5(
								$author$project$App$Page$Reviewer$PageReview$viewAnswerTemplate,
								depIdx,
								stmt,
								fullQuestion,
								t,
								A2($author$project$App$Page$Reviewer$PageReview$getAnswerForTemplate, stmt, t));
						},
						fullQuestion.bY));
			}
		}();
		return A2(
			$elm$html$Html$td,
			_List_Nil,
			_Utils_ap(_List_Nil, templates));
	});
var $author$project$App$Page$Reviewer$PageReview$viewAnswer = F5(
	function (mAnswer, fullQuestion, stmt, stmtId, depIdx) {
		if (!mAnswer.$) {
			var answer = mAnswer.a;
			var _v1 = fullQuestion.hG.j5;
			switch (_v1) {
				case 0:
					return A2($elm$html$Html$td, _List_Nil, _List_Nil);
				case 1:
					return A3($author$project$App$Page$Reviewer$PageReview$viewQuestionCommon, depIdx, stmt, fullQuestion);
				case 2:
					return A3($author$project$App$Page$Reviewer$PageReview$viewQuestionCommon, depIdx, stmt, fullQuestion);
				case 3:
					return A3($author$project$App$Page$Reviewer$PageReview$viewQuestionCommon, depIdx, stmt, fullQuestion);
				case 4:
					return A2($elm$html$Html$td, _List_Nil, _List_Nil);
				default:
					return A3($author$project$App$Page$Reviewer$PageReview$viewQuestionCommon, depIdx, stmt, fullQuestion);
			}
		} else {
			return A2($elm$html$Html$td, _List_Nil, _List_Nil);
		}
	});
var $author$project$App$Page$Reviewer$PageReview$viewQuestion = F3(
	function (fullQuestion, stmt, deps) {
		var templates = fullQuestion.bY;
		var answers = A2(
			$elm$core$List$map,
			function (t) {
				return A2($elm$core$Dict$get, t.as, stmt.gw);
			},
			templates);
		var bearerAnswers = (stmt.e.j_ === 2) ? $elm$html$Html$text('') : A5(
			$author$project$App$Page$Reviewer$PageReview$viewAnswer,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Maybe$Nothing,
				$elm$core$List$head(answers)),
			fullQuestion,
			stmt,
			stmt.e.as,
			$elm$core$Maybe$Nothing);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_Utils_ap(
				$elm$core$List$concat(
					_List_fromArray(
						[
							_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(fullQuestion.hG.bB) + ('. ' + fullQuestion.hG.bP))
									])),
								bearerAnswers
							])
						])),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var v = _v0.b;
						return v;
					},
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$map,
							F2(
								function (i, dep) {
									var depAnswer = A2(
										$elm$core$List$map,
										function (t) {
											return A2($elm$core$Dict$get, t.as, dep.gw);
										},
										templates);
									return A5(
										$author$project$App$Page$Reviewer$PageReview$viewAnswer,
										A2(
											$elm$core$Maybe$withDefault,
											$elm$core$Maybe$Nothing,
											$elm$core$List$head(depAnswer)),
										fullQuestion,
										dep,
										dep.e.as,
										$elm$core$Maybe$Just(i));
								}),
							deps)))));
	});
var $author$project$App$Page$Reviewer$PageReview$viewQuestions = F2(
	function (fullStmt, st) {
		var stmt = fullStmt.g;
		var questions = A2($author$project$App$Page$Reviewer$PageReview$getTemplateQuestions, fullStmt.g.e.eY, st);
		var bearerHeader = ((stmt.e.j_ === 2) || (!st.ia.h8.cJ.gX)) ? $elm$html$Html$text('') : A2(
			$elm$html$Html$th,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Titular'),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					$elm$html$Html$text(fullStmt.g.dw.hl)
				]));
		return A2(
			$elm$html$Html$table,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$colgroup,
					_List_Nil,
					$elm$core$List$concat(
						_List_fromArray(
							[
								_List_fromArray(
								[
									A2($elm$html$Html$col, _List_Nil, _List_Nil),
									A2($elm$html$Html$col, _List_Nil, _List_Nil)
								]),
								A2(
								$elm$core$List$map,
								function (_v2) {
									var v = _v2.b;
									return v;
								},
								$elm$core$Dict$toList(
									A2(
										$elm$core$Dict$map,
										F2(
											function (_v0, _v1) {
												return A2($elm$html$Html$col, _List_Nil, _List_Nil);
											}),
										fullStmt.fh)))
							]))),
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							$elm$core$List$concat(
								_List_fromArray(
									[
										_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Perguntas')
												])),
											bearerHeader
										]),
										A2(
										$elm$core$List$map,
										function (_v3) {
											var v = _v3.b;
											return v;
										},
										$elm$core$Dict$toList(
											A2(
												$elm$core$Dict$map,
												F2(
													function (i, dep) {
														return A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	'Dep ' + $elm$core$String$fromInt(i + 1)),
																	A2($elm$html$Html$br, _List_Nil, _List_Nil),
																	$elm$html$Html$text(dep.dw.hl)
																]));
													}),
												fullStmt.fh)))
									])))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (q) {
							return A3($author$project$App$Page$Reviewer$PageReview$viewQuestion, q, stmt, fullStmt.fh);
						},
						A2($elm$core$List$sortWith, $author$project$App$Page$Reviewer$PageReview$fullQuestionSorter, questions)))
				]));
	});
var $author$project$App$Page$Reviewer$PageReview$viewStatementQuestions = F3(
	function (filter, model, st) {
		return A2(
			$elm$html$Html$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$header,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Revisando Cadastro')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('action-buttons')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick(
											A2($author$project$App$Page$Reviewer$PageReview$ReturnToCompany, model.as, filter))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Devolver')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$PageReview$GoToPending)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Cancelar')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick(
											$author$project$App$Page$Reviewer$PageReview$Save(model.h8.g.e.as))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Aprovar')
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('questions review')
						]),
					_List_fromArray(
						[
							A2($author$project$App$Page$Reviewer$PageReview$viewQuestions, model.h8, st)
						]))
				]));
	});
var $author$project$App$Page$Reviewer$PageReview$view = F2(
	function (st, model) {
		var viewDetails = function () {
			var _v1 = model.gD;
			if (_v1.$ === 1) {
				var viewFullStmt = _v1.b;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Reviewer$PageReview$ViewFullStmtMsg,
							A2($author$project$App$Components$ViewFullStatement$view, st, viewFullStmt))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		var dialogView = A2($author$project$App$Page$Reviewer$PageReview$dialogFromModel, model, st);
		var _v0 = _Utils_Tuple2('Revisar Atendimento', 'Atualizar');
		var headerTitle = _v0.a;
		var actionText = _v0.b;
		return _List_fromArray(
			[
				A2($author$project$App$Page$Reviewer$PageReview$viewErrors, st, model),
				A3($author$project$App$Page$Reviewer$PageReview$viewStatementQuestions, model.b, model, st),
				viewDetails,
				dialogView
			]);
	});
var $author$project$App$Page$Reviewer$PageWaitingLine$DisableSound = {$: 10};
var $author$project$App$Page$Reviewer$PageWaitingLine$EnableSound = {$: 8};
var $author$project$App$Page$Reviewer$PageWaitingLine$viewDoctorOnLine = function (doctor) {
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('user-item')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(
						$author$project$App$Data$Person$photoUrl(doctor))
					]),
				_List_Nil),
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$App$Data$Person$fullName(doctor))
					]))
			]));
};
var $author$project$App$Page$Reviewer$PageWaitingLine$viewDoctors = F3(
	function (model, st, personId) {
		return A2(
			$elm$html$Html$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$header,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Médicos Online')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$ul,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('doctor-list')
								]),
							A3(
								$elm$core$List$foldl,
								F2(
									function (doctor, html) {
										return A2(
											$elm$core$List$append,
											html,
											_List_fromArray(
												[
													$author$project$App$Page$Reviewer$PageWaitingLine$viewDoctorOnLine(doctor)
												]));
									}),
								_List_Nil,
								model.c5))
						]))
				]));
	});
var $author$project$App$Page$Reviewer$PageWaitingLine$HoldPerson = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Reviewer$PageWaitingLine$viewWaitingUserRow = F2(
	function (st, wt) {
		var scheduledTime = _Utils_eq(
			wt.h8.jU,
			$elm$time$Time$millisToPosix(0)) ? $elm$html$Html$text('') : A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(
					'Agendado p/ ' + (A3(
						$elm$core$String$slice,
						8,
						10,
						$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(wt.h8.jU)) + ('-' + (A3(
						$elm$core$String$slice,
						5,
						7,
						$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(wt.h8.jU)) + (',' + (A3(
						$elm$core$String$slice,
						11,
						16,
						$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(wt.h8.jU)) + 'h'))))))
				]));
		var person = $author$project$App$Data$Person$fromWaiting(wt.dw);
		var now = $author$project$App$Service$ApiWebSocket$getTimestamp(st._);
		var status = (wt.dw.aw === 11) ? A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('can-be-taken')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					'Na fila a ' + A2($author$project$App$Data$Person$waitingTime, wt.dw.bW, now))
				])) : A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cannot-be-taken')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$author$project$App$Data$StatementStatus$statusToText(wt.dw.aw))
				]));
		var evt = (wt.dw.aw === 11) ? $elm$html$Html$Events$onClick(
			$author$project$App$Page$Reviewer$PageWaitingLine$HoldPerson(wt.dw)) : A2($elm$html$Html$Attributes$attribute, 'onclick', '');
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item'),
					evt
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(person))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$fullPersonName(wt.dw))
								])),
							status,
							scheduledTime,
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(wt.b4.iR)
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(wt.cJ.hl)
								]))
						]))
				]));
	});
var $author$project$App$Page$Reviewer$PageWaitingLine$viewWaitingUserRowDoctor = F3(
	function (model, lst, wt) {
		var person = $author$project$App$Data$Person$fromWaiting(wt.dw);
		var mDoctor = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (doc) {
					return _Utils_eq(
						wt.dw.cs,
						$elm$core$Maybe$Just(doc.as));
				},
				model.c5));
		var doctorFullName = function () {
			if (!mDoctor.$) {
				var doctor = mDoctor.a;
				return doctor.hl + (' ' + doctor.ii);
			} else {
				return '(carregando...)';
			}
		}();
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(person))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$fullPersonName(wt.dw))
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('cannot-be-taken')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sendo atendido por ' + doctorFullName)
								]))
						]))
				]));
	});
var $author$project$App$Page$Reviewer$PageWaitingLine$viewWaitingLine = F2(
	function (st, model) {
		var notInInterview = A2(
			$elm$core$List$filter,
			function (wt) {
				return (_Utils_cmp(
					$author$project$App$Data$StatementStatus$statusToInt(wt.dw.aw),
					$author$project$App$Data$StatementStatus$statusToInt(4)) > 0) && (_Utils_cmp(
					$author$project$App$Data$StatementStatus$statusToInt(wt.dw.aw),
					$author$project$App$Data$StatementStatus$statusToInt(12)) < 0);
			},
			model.e1);
		var waitingLineHtml = A2(
			$elm$core$List$map,
			$author$project$App$Page$Reviewer$PageWaitingLine$viewWaitingUserRow(st),
			notInInterview);
		var inInterview = A2(
			$elm$core$List$filter,
			function (wt) {
				return $author$project$App$Data$StatementStatus$statusInInterview(wt.dw.aw);
			},
			model.e1);
		var attendingHtml = A2(
			$elm$core$List$map,
			A2($author$project$App$Page$Reviewer$PageWaitingLine$viewWaitingUserRowDoctor, model, st),
			inInterview);
		return (($elm$core$List$length(notInInterview) > 0) || ($elm$core$List$length(inInterview) > 0)) ? $elm$core$List$concat(
			_List_fromArray(
				[waitingLineHtml, attendingHtml])) : _List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Fila vazia')
					]))
			]);
	});
var $author$project$App$Page$Reviewer$PageWaitingLine$view = F2(
	function (st, model) {
		var soundInit = $author$project$App$Components$Sound$isEnabled(model.N) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('action-buttons')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$PageWaitingLine$DisableSound),
							$elm$html$Html$Attributes$title('Desabilitar Notificações Sonoras')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('notifications_active')
								]))
						]))
				])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('action-buttons')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$App$Page$Reviewer$PageWaitingLine$EnableSound),
							$elm$html$Html$Attributes$class('cancel-button'),
							$elm$html$Html$Attributes$title('Habilitar Notificações Sonoras')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('notifications_off')
								]))
						]))
				]));
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('waiting')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Aguardando Atendimento')
									])),
								soundInit
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								A2($author$project$App$Page$Reviewer$PageWaitingLine$viewWaitingLine, st, model))
							]))
					])),
				A3($author$project$App$Page$Reviewer$PageWaitingLine$viewDoctors, model, st, model.Y)
			]);
	});
var $author$project$App$Page$Reviewer$PageWaitingProponentDetails$ReturnToLine = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Reviewer$PageWaitingProponentDetails$viewButtons = function (stmt) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('action-buttons')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('cancel-button'),
						$elm$html$Html$Attributes$type_('button'),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Reviewer$PageWaitingProponentDetails$ReturnToLine(stmt.Y))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Voltar')
					]))
			]));
};
var $author$project$App$Page$Reviewer$PageWaitingProponentDetails$viewLoaded = F2(
	function (st, model) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('waiting')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Analisando Informações')
									])),
								$author$project$App$Page$Reviewer$PageWaitingProponentDetails$viewButtons(st.ia.h8.g.e)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$map,
								$author$project$App$Page$Reviewer$PageWaitingProponentDetails$ViewFullStatementMsg,
								A2($author$project$App$Components$ViewFullStatement$view, st, model.bm))
							]))
					]))
			]);
	});
var $author$project$App$Page$Reviewer$PageWaitingProponentDetails$view = F2(
	function (st, model) {
		var _v0 = model.p;
		if (!_v0) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('waiting')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$header,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Carregando...')
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('main')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$ul,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('users-list')
										]),
									_List_Nil)
								]))
						]))
				]);
		} else {
			return A2($author$project$App$Page$Reviewer$PageWaitingProponentDetails$viewLoaded, st, model);
		}
	});
var $author$project$App$Page$PageReviewer$view = F3(
	function (now, st, model) {
		switch (model.$) {
			case 0:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageReviewer$ListIncompleteMsg),
					A2($author$project$App$Page$Reviewer$PageListIncomplete$view, st, pageModel));
			case 1:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageReviewer$ListForReviewMsg),
					A2($author$project$App$Page$Reviewer$PageListForReview$view, st, pageModel));
			case 2:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageReviewer$ListApprovedMsg),
					A2($author$project$App$Page$Reviewer$PageListApproved$view, st, pageModel));
			case 3:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageReviewer$ReviewInterviewMsg),
					A2($author$project$App$Page$Reviewer$PageReview$view, st, pageModel));
			case 4:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageReviewer$EditStatementMsg),
					A2($author$project$App$Page$Reviewer$EditStatement$StatementEdit$view, st, pageModel));
			case 6:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageReviewer$ListReevaluationMsg),
					A2($author$project$App$Page$Reviewer$PageListReevaluation$view, st, pageModel));
			case 5:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageReviewer$ReevaluationMsg),
					A3($author$project$App$Page$Reviewer$PageReevaluation$view, now, st, pageModel));
			case 7:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageReviewer$WaitingLineMsg),
					A2($author$project$App$Page$Reviewer$PageWaitingLine$view, st, pageModel));
			default:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageReviewer$WaitingProponentDetailsMsg),
					A2($author$project$App$Page$Reviewer$PageWaitingProponentDetails$view, st, pageModel));
		}
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$CancelReturnToCompany = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$ConfirmReturnToCompany = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$InputObservationField = F3(
	function (a, b, c) {
		return {$: 10, a: a, b: b, c: c};
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$dialogFromModel = F2(
	function (model, st) {
		var header = _List_Nil;
		var footer = function (filter) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('cancel-button'),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Support$EditStatement$StatementEdit$CancelReturnToCompany(model))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Cancelar')
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							A2($author$project$App$Page$Support$EditStatement$StatementEdit$ConfirmReturnToCompany, model.as, filter))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Devolver')
						]))
				]);
		};
		var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
		var body = function (filter) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$textarea,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$cols(50),
							$elm$html$Html$Attributes$rows(5),
							$elm$html$Html$Attributes$value(model.h8.g.e.jx),
							$elm$html$Html$Events$onInput(
							function (str) {
								return A3(
									$author$project$App$Page$Support$EditStatement$StatementEdit$InputObservationField,
									model.as,
									filter,
									$author$project$App$Data$Statement$FieldObservation(str));
							})
						]),
					_List_Nil)
				]);
		};
		var completeConfig = function (filter) {
			return _Utils_update(
				config,
				{
					e5: $elm$core$Maybe$Just(
						body(filter)),
					ft: $elm$core$Maybe$Just(
						footer(filter)),
					gY: $elm$core$Maybe$Just(header),
					f1: 'Motivo da Devolução'
				});
		};
		return model.dC ? $author$project$App$Components$Dialog$view(
			$elm$core$Maybe$Just(
				completeConfig(model.b))) : $elm$html$Html$text('');
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$CloseNotification = {$: 31};
var $author$project$App$Page$Support$EditStatement$StatementEdit$notificationFromModel = function (model) {
	var notificationMessage = function () {
		var _v0 = model.F;
		if (!_v0.$) {
			var notificationModel = _v0.a;
			return notificationModel.jl;
		} else {
			return '';
		}
	}();
	var header = _List_Nil;
	var footer = _List_fromArray(
		[
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$App$Page$Support$EditStatement$StatementEdit$CloseNotification)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Ok')
				]))
		]);
	var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
	var body = _List_fromArray(
		[
			$elm$html$Html$text(notificationMessage)
		]);
	var completeConfig = _Utils_update(
		config,
		{
			e5: $elm$core$Maybe$Just(body),
			ft: $elm$core$Maybe$Just(footer),
			gY: $elm$core$Maybe$Just(header),
			f1: 'Resultado da Operação'
		});
	return (!_Utils_eq(model.F, $elm$core$Maybe$Nothing)) ? $author$project$App$Components$Dialog$view(
		$elm$core$Maybe$Just(completeConfig)) : $elm$html$Html$text('');
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$Error = 2;
var $author$project$App$Page$Support$EditStatement$StatementEdit$viewErrors = F2(
	function (st, model) {
		var _v0 = A2($author$project$App$Service$ApplicationResources$getNewFullStatementForCompanyResponse, model.gx, st._);
		if (_v0.$ === 1) {
			var _v1 = _v0.a;
			var specificError = _v1.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$App$Service$SpecificResources$errorToMessage(specificError))
					]));
		} else {
			return (!$author$project$App$Page$Support$EditStatement$StatementEdit$errorsDictIsEmpty(model.fp)) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('')
					])) : ((model.cH === 1) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Cadastrando atendimento...')
					])) : ((model.cH === 2) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Ocorreu um erro ao tentar cadastrar o atendimento.')
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Tente novamente em alguns instantes.')
							]))
					])) : $elm$html$Html$text('')));
		}
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$GoToIncomplete = {$: 2};
var $author$project$App$Page$Support$EditStatement$StatementEdit$Save = {$: 12};
var $author$project$App$Page$Support$EditStatement$StatementEdit$ToggleEditStatement = {$: 30};
var $author$project$App$Page$Support$EditStatement$StatementEdit$AddDependent = {$: 20};
var $author$project$App$Page$Support$EditStatement$StatementEdit$PostPoneStatement = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$ReturnToCompany = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$ReturnToConfirmInterview = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$ReturnToDoctor = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$ReturnToWaitingForRoom = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$UpdateNewDueDate = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$documentIsValid = F2(
	function (document, api) {
		var _v0 = A2($author$project$App$Service$ApplicationResources$getPersonByDocumentError, document, api);
		_v0$2:
		while (true) {
			if (!_v0.$) {
				switch (_v0.a.a.$) {
					case 4:
						var _v1 = _v0.a;
						return false;
					case 2:
						var _v2 = _v0.a;
						return true;
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return $author$project$App$Data$Person$isDocumentValid(document);
	});
var $author$project$App$Page$Support$EditStatement$DocumentView$ChooseVideo = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Support$EditStatement$DocumentView$DeleteDocument = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$Support$EditStatement$DocumentView$viewDocumentContent = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer'),
				A2($elm$html$Html$Attributes$style, 'line-height', 'normal')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						(doc.dr === 'video/mp4') ? A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Support$EditStatement$DocumentView$ChooseVideo(doc)),
								$elm$html$Html$Attributes$value('Escolher Vídeo')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Escolher Vídeo')
							])) : $elm$html$Html$text(''),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Support$EditStatement$DocumentView$DeleteDocument(doc.as)),
								$elm$html$Html$Attributes$value('Apagar Documento')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Apagar Documento')
							]))
					]))
			]));
};
var $author$project$App$Page$Support$EditStatement$DocumentView$viewSelected = F2(
	function (_v0, sharedState) {
		var fullStatement = _v0.a5;
		var now = _v0.ey;
		var currentTab = _v0.b5;
		var doctor = _v0.bu;
		if (!currentTab.$) {
			var idx = currentTab.a;
			return _List_fromArray(
				[
					$author$project$App$Page$Support$EditStatement$DocumentView$viewDocumentContent(
					A2(
						$elm$core$Maybe$withDefault,
						$author$project$App$Data$Document$emptyModel,
						A2($elm$core$Dict$get, idx, fullStatement.an)))
				]);
		} else {
			return _List_Nil;
		}
	});
var $author$project$App$Page$Support$EditStatement$DocumentView$ChangeToTab = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Support$EditStatement$DocumentView$viewDocumentTab = F3(
	function (currentTab, idx, doc) {
		var _v0 = doc.dr;
		switch (_v0) {
			case 'video/webm':
				return $elm$html$Html$text('');
			case 'video/mp4':
				return A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'tab-selected',
									_Utils_eq(
										currentTab,
										$author$project$App$Page$Support$EditStatement$DocumentView$TabDocument(idx)))
								])),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Support$EditStatement$DocumentView$ChangeToTab(
								$author$project$App$Page$Support$EditStatement$DocumentView$TabDocument(idx)))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Video')
						]));
			default:
				return A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'tab-selected',
									_Utils_eq(
										currentTab,
										$author$project$App$Page$Support$EditStatement$DocumentView$TabDocument(idx)))
								])),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Support$EditStatement$DocumentView$ChangeToTab(
								$author$project$App$Page$Support$EditStatement$DocumentView$TabDocument(idx)))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							function () {
								var _v1 = doc.b7;
								switch (_v1) {
									case 1:
										return 'Parecer';
									case 3:
										return 'Atestado';
									case 4:
										return 'Receita';
									default:
										return 'Anexo';
								}
							}())
						]));
		}
	});
var $author$project$App$Page$Support$EditStatement$DocumentView$viewDocumentTabNumered = F4(
	function (currentTab, idx, doc, documentTabs) {
		var index = $elm$core$List$length(documentTabs) + 1;
		return A2(
			$elm$core$List$append,
			documentTabs,
			function () {
				var _v0 = doc.dr;
				switch (_v0) {
					case 'video/webm':
						return _List_fromArray(
							[
								$elm$html$Html$text('')
							]);
					case 'video/mp4':
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Support$EditStatement$DocumentView$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Support$EditStatement$DocumentView$ChangeToTab(
											$author$project$App$Page$Support$EditStatement$DocumentView$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										'Video' + $elm$core$String$fromInt(index))
									]))
							]);
					default:
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Support$EditStatement$DocumentView$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Support$EditStatement$DocumentView$ChangeToTab(
											$author$project$App$Page$Support$EditStatement$DocumentView$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											var _v1 = doc.b7;
											switch (_v1) {
												case 1:
													return 'Parecer Antigo' + $elm$core$String$fromInt(index);
												case 3:
													return 'Atestado' + $elm$core$String$fromInt(index);
												case 4:
													return 'Receita' + $elm$core$String$fromInt(index);
												default:
													return 'Anexo';
											}
										}())
									]))
							]);
				}
			}());
	});
var $author$project$App$Page$Support$EditStatement$DocumentView$viewResultTab = F5(
	function (previewTitle, typeHnsg, currentTab, idx, d) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Support$EditStatement$DocumentView$TabDocument(idx)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Support$EditStatement$DocumentView$ChangeToTab(
							$author$project$App$Page$Support$EditStatement$DocumentView$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(previewTitle)
					])),
				typeHnsg ? A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Support$EditStatement$DocumentView$TabDocument(idx)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Support$EditStatement$DocumentView$ChangeToTab(
							$author$project$App$Page$Support$EditStatement$DocumentView$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Pré-Visualizar Atestado')
					])) : A2($elm$html$Html$span, _List_Nil, _List_Nil)
			]);
	});
var $author$project$App$Page$Support$EditStatement$DocumentView$viewDocumentTabs = F2(
	function (currentTab, docs) {
		var reportDocs = A2(
			$elm$core$List$sortWith,
			F2(
				function (a, b) {
					var _v4 = A2(
						$elm$core$Basics$compare,
						$elm$time$Time$posixToMillis(a.b.iW),
						$elm$time$Time$posixToMillis(b.b.iW));
					switch (_v4) {
						case 0:
							return 2;
						case 1:
							return 1;
						default:
							return 0;
					}
				}),
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v3, doc) {
							return doc.b7 === 1;
						}),
					docs)));
		var previewTitle = 'Parecer';
		var resultTab = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$second,
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$map,
							A3($author$project$App$Page$Support$EditStatement$DocumentView$viewResultTab, previewTitle, false, currentTab),
							$elm$core$Dict$fromList(
								A2($elm$core$List$take, 1, reportDocs)))))));
		var oldResultsTab = A3(
			$elm$core$Dict$foldl,
			$author$project$App$Page$Support$EditStatement$DocumentView$viewDocumentTabNumered(currentTab),
			_List_Nil,
			$elm$core$Dict$fromList(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(reportDocs))));
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$second,
				$elm$core$Dict$toList(
					A2(
						$elm$core$Dict$map,
						$author$project$App$Page$Support$EditStatement$DocumentView$viewDocumentTab(currentTab),
						$elm$core$Dict$fromList(
							A2(
								$elm$core$List$sortWith,
								F2(
									function (a, b) {
										var _v1 = A2(
											$elm$core$Basics$compare,
											$elm$time$Time$posixToMillis(a.b.f2),
											$elm$time$Time$posixToMillis(b.b.f2));
										switch (_v1) {
											case 0:
												return 2;
											case 1:
												return 1;
											default:
												return 0;
										}
									}),
								$elm$core$Dict$toList(
									A2(
										$elm$core$Dict$filter,
										F2(
											function (_v0, doc) {
												return doc.dr === 'video/mp4';
											}),
										docs))))))),
			_Utils_ap(
				oldResultsTab,
				_Utils_ap(
					resultTab,
					A2(
						$elm$core$List$map,
						$elm$core$Tuple$second,
						$elm$core$Dict$toList(
							A2(
								$elm$core$Dict$map,
								$author$project$App$Page$Support$EditStatement$DocumentView$viewDocumentTab(currentTab),
								A2(
									$elm$core$Dict$filter,
									F2(
										function (_v2, doc) {
											return (doc.dr !== 'video/webm') && (doc.dr !== 'video/mp4');
										}),
									docs)))))));
	});
var $author$project$App$Page$Support$EditStatement$DocumentView$viewTabs = F2(
	function (currentTab, fullStmt) {
		var commonView = A2($author$project$App$Page$Support$EditStatement$DocumentView$viewDocumentTabs, currentTab, fullStmt.an);
		return commonView;
	});
var $author$project$App$Page$Support$EditStatement$DocumentView$view = F2(
	function (sharedState, model) {
		return A2(
			$elm$html$Html$article,
			_List_Nil,
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$header,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('tabs')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							A2($author$project$App$Page$Support$EditStatement$DocumentView$viewTabs, model.b5, model.a5))
						])),
				A2($author$project$App$Page$Support$EditStatement$DocumentView$viewSelected, model, sharedState)));
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$InputContactField = F3(
	function (a, b, c) {
		return {$: 19, a: a, b: b, c: c};
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$errorsInField = F3(
	function (field, idx, errors) {
		return A2(
			$elm$core$List$member,
			field,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$elm$core$Dict$get,
					$author$project$App$Data$Statement$depIndexToInt(idx),
					errors)));
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$viewEditContacts = F3(
	function (mDepIdx, fullStmt, contacts) {
		var submitting = fullStmt.cH === 1;
		var showErrorMessageClass = function (field) {
			return _Utils_Tuple2(
				'error-message',
				A3($author$project$App$Page$Support$EditStatement$StatementEdit$errorsInField, field, mDepIdx, fullStmt.fp));
		};
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('e-mail'),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('email'),
								$elm$html$Html$Attributes$disabled(submitting),
								$elm$html$Html$Attributes$placeholder('email@exemplo.com.br'),
								$elm$html$Html$Attributes$value(
								A2($author$project$App$Page$Support$EditStatement$StatementEdit$getContactWithKind, 5, contacts).T),
								$elm$html$Html$Events$onInput(
								A2(
									$elm$core$Basics$composeL,
									A2($author$project$App$Page$Support$EditStatement$StatementEdit$InputContactField, mDepIdx, 5),
									$author$project$App$Data$Contact$FieldValue)),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										showErrorMessageClass(10)
									]))
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Telefone celular'),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('tel'),
										$elm$html$Html$Attributes$disabled(submitting),
										$elm$html$Html$Attributes$placeholder('Celular'),
										$elm$html$Html$Attributes$value(
										$author$project$App$Data$Contact$formatMobile(
											A2($author$project$App$Page$Support$EditStatement$StatementEdit$getContactWithKind, 1, contacts).T)),
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($author$project$App$Page$Support$EditStatement$StatementEdit$InputContactField, mDepIdx, 1),
											$author$project$App$Data$Contact$FieldValue)),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												showErrorMessageClass(11)
											])),
										$author$project$App$Tools$FormHelpers$onBackspace(
										A3(
											$author$project$App$Page$Support$EditStatement$StatementEdit$InputContactField,
											mDepIdx,
											1,
											$author$project$App$Data$Contact$FieldValue(
												A2(
													$elm$core$String$dropRight,
													1,
													A2($author$project$App$Page$Support$EditStatement$StatementEdit$getContactWithKind, 1, contacts).T))))
									]),
								_List_Nil)
							]))
					]))
			]);
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$RemoveDependent = function (a) {
	return {$: 21, a: a};
};
var $author$project$App$Page$Support$EditStatement$StatementEdit$InputPersonField = F2(
	function (a, b) {
		return {$: 18, a: a, b: b};
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$viewEditCPFContent = F3(
	function (mDepIdx, person, api) {
		var isInvalid = !A2($author$project$App$Page$Support$EditStatement$StatementEdit$documentIsValid, person.i1, api);
		return A2(
			$elm$html$Html$label,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('CPF'),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$placeholder('Digite o CPF do Cliente'),
									$elm$html$Html$Attributes$type_('text'),
									$elm$html$Html$Attributes$disabled(
									$author$project$App$Data$Id$uuidIsValid(person.as)),
									$elm$html$Html$Attributes$value(
									$author$project$App$Data$Person$formatDocument(person.i1)),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeL,
										$author$project$App$Page$Support$EditStatement$StatementEdit$InputPersonField(mDepIdx),
										$author$project$App$Data$Person$FieldDocument)),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('error-message', isInvalid)
										])),
									$author$project$App$Tools$FormHelpers$onBackspace(
									A2(
										$author$project$App$Page$Support$EditStatement$StatementEdit$InputPersonField,
										mDepIdx,
										$author$project$App$Data$Person$FieldDocument(
											A2($elm$core$String$dropRight, 1, person.i1))))
								]),
							_List_Nil),
							$elm$html$Html$text('')
						]))
				]));
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$viewEditPersonDocument = F4(
	function (mDepIdx, person, api, stmtTemplate) {
		var isDisabled = false;
		if (!mDepIdx.$) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$legend,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									stmtTemplate.gX ? 'Titular' : '')
								]))
						])),
					A3($author$project$App$Page$Support$EditStatement$StatementEdit$viewEditCPFContent, mDepIdx, person, api)
				]);
		} else {
			var depIdx = mDepIdx.a;
			return stmtTemplate.gX ? _List_fromArray(
				[
					A2(
					$elm$html$Html$legend,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									'Dependente ' + $elm$core$String$fromInt(depIdx + 1))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('action-buttons')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('action-buttons')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('cancel-button'),
													$elm$html$Html$Attributes$type_('button'),
													$elm$html$Html$Attributes$disabled(isDisabled),
													$elm$html$Html$Events$onClick(
													$author$project$App$Page$Support$EditStatement$StatementEdit$RemoveDependent(depIdx))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Remover')
												]))
										]))
								]))
						])),
					A3($author$project$App$Page$Support$EditStatement$StatementEdit$viewEditCPFContent, mDepIdx, person, api)
				]) : _List_Nil;
		}
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$InputStmtField = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$viewRelationContent = F3(
	function (idx, editStmt, modelForm) {
		return _List_fromArray(
			[
				A4(
				$author$project$App$Tools$FormHelpers$select,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'error-message',
								A3($author$project$App$Page$Support$EditStatement$StatementEdit$errorsInField, 12, idx, modelForm.fp))
							]))
					]),
				$author$project$App$Data$Statement$relationToString(
					A2($elm$core$Maybe$withDefault, 0, editStmt.e.be)),
				A2(
					$elm$core$Basics$composeL,
					$author$project$App$Page$Support$EditStatement$StatementEdit$InputStmtField(idx),
					$author$project$App$Data$Statement$FieldRelation),
				A2(
					$elm$core$List$map,
					function (relation) {
						return _Utils_Tuple2(
							$author$project$App$Data$Statement$relationToString(relation),
							$author$project$App$Data$Statement$relationToText(relation));
					},
					_List_fromArray(
						[0, 1, 2, 3])))
			]);
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$viewEditPersonInformation = F4(
	function (mDepIdx, editStmt, modelForm, now) {
		var viewRelation = function () {
			if (!mDepIdx.$) {
				return _List_Nil;
			} else {
				return A3($author$project$App$Page$Support$EditStatement$StatementEdit$viewRelationContent, mDepIdx, editStmt, modelForm);
			}
		}();
		var submitting = modelForm.cH === 1;
		var stmt = editStmt.e;
		var stmtTemplate = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$StatementTemplate$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.as, stmt.eY);
					},
					modelForm.eZ)));
		var showErrorMessageClass = function (field) {
			return _Utils_Tuple2(
				'error-message',
				A3($author$project$App$Page$Support$EditStatement$StatementEdit$errorsInField, field, mDepIdx, modelForm.fp));
		};
		var person = editStmt.dw;
		var emptyForm = {
			gx: $author$project$App$Data$Id$uuidNil,
			b5: $author$project$App$Components$ViewFullStatement$TabDocument(0),
			fp: $elm$core$Dict$empty,
			a5: $author$project$App$Data$FullStatement$emptyFullStatement,
			ep: false,
			cH: 0,
			eZ: _List_Nil
		};
		return _Utils_ap(
			viewRelation,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'name', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Nome Completo'),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder('Primeiro Nome'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$disabled(submitting),
											$elm$html$Html$Attributes$value(person.hl),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												$author$project$App$Page$Support$EditStatement$StatementEdit$InputPersonField(mDepIdx),
												$author$project$App$Data$Person$FieldName)),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													showErrorMessageClass(4)
												]))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder('Todos os sobrenomes'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$disabled(submitting),
											$elm$html$Html$Attributes$value(person.ii),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												$author$project$App$Page$Support$EditStatement$StatementEdit$InputPersonField(mDepIdx),
												$author$project$App$Data$Person$FieldSurname)),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													showErrorMessageClass(5)
												]))
										]),
									_List_Nil)
								]))
						]) : _List_Nil),
					A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'socialName', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Nome Social'),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder('Nome Social'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$disabled(submitting),
											$elm$html$Html$Attributes$value(person.B),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												$author$project$App$Page$Support$EditStatement$StatementEdit$InputPersonField(mDepIdx),
												$author$project$App$Data$Person$FieldSocialName)),
											$elm$html$Html$Attributes$classList(_List_Nil)
										]),
									_List_Nil)
								]))
						]) : _List_Nil),
					A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'birthdate', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Data Nascimento'),
							A3(
							$author$project$App$Tools$FormHelpers$dateInputWithAttrs,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											showErrorMessageClass(6)
										])),
									$elm$html$Html$Attributes$disabled(submitting)
								]),
							person.al,
							A2(
								$elm$core$Basics$composeL,
								$author$project$App$Page$Support$EditStatement$StatementEdit$InputPersonField(mDepIdx),
								$author$project$App$Data$Person$FieldBirthDate))
						]) : _List_Nil),
					A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'sex', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Sexo'),
							A3(
							$author$project$App$Tools$FormHelpers$selectSex,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											showErrorMessageClass(7)
										])),
									$elm$html$Html$Attributes$disabled(submitting)
								]),
							person.dJ,
							A2(
								$elm$core$Basics$composeL,
								$author$project$App$Page$Support$EditStatement$StatementEdit$InputPersonField(mDepIdx),
								$author$project$App$Data$Person$FieldSex))
						]) : _List_Nil),
					(((editStmt.e.j_ === 2) && (!_Utils_eq(mDepIdx, $author$project$App$Data$Statement$Bearer))) && (A2($author$project$App$Data$Person$ageOnDate, now, editStmt.dw) < 18)) ? $elm$html$Html$text('') : A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'birthdate', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Horário de atendimento'),
							A3(
							$author$project$App$Tools$FormHelpers$dateTimeInputWithAttrs,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											showErrorMessageClass(13)
										])),
									$elm$html$Html$Attributes$disabled(submitting)
								]),
							stmt.jU,
							A2(
								$elm$core$Basics$composeL,
								$author$project$App$Page$Support$EditStatement$StatementEdit$InputStmtField(mDepIdx),
								$author$project$App$Data$Statement$FieldScheduleDate))
						]) : _List_Nil),
					((editStmt.e.j_ === 2) && _Utils_eq(mDepIdx, $author$project$App$Data$Statement$Bearer)) ? $elm$html$Html$text('') : A2(
					$elm$html$Html$label,
					_List_Nil,
					A2($elm$core$List$member, 'weight-height', stmtTemplate.eJ) ? _List_fromArray(
						[
							$elm$html$Html$text('Peso e Altura'),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder('Peso'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$disabled(submitting),
											$elm$html$Html$Attributes$value(
											$author$project$App$Data$Statement$formatWeight(stmt.iT)),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												$author$project$App$Page$Support$EditStatement$StatementEdit$InputStmtField(mDepIdx),
												$author$project$App$Data$Statement$FieldCompanyWeight)),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													showErrorMessageClass(8)
												])),
											$author$project$App$Tools$FormHelpers$onBackspace(
											A2(
												$author$project$App$Page$Support$EditStatement$StatementEdit$InputStmtField,
												mDepIdx,
												A2($author$project$App$Data$Statement$backspaceWeight, $author$project$App$Data$Statement$FieldCompanyWeight, stmt.iT)))
										]),
									_List_Nil),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder('Altura'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$disabled(submitting),
											$elm$html$Html$Attributes$value(
											$author$project$App$Data$Statement$formatHeight(stmt.iS)),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												$author$project$App$Page$Support$EditStatement$StatementEdit$InputStmtField(mDepIdx),
												$author$project$App$Data$Statement$FieldCompanyHeight)),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													showErrorMessageClass(9)
												])),
											$author$project$App$Tools$FormHelpers$onBackspace(
											A2(
												$author$project$App$Page$Support$EditStatement$StatementEdit$InputStmtField,
												mDepIdx,
												A2($author$project$App$Data$Statement$backspaceHeight, $author$project$App$Data$Statement$FieldCompanyHeight, stmt.iS)))
										]),
									_List_Nil)
								]))
						]) : _List_Nil)
				]));
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$viewEditDependent = F6(
	function (now, idx, modelForm, dep, fullStmt, api) {
		var ridById = $author$project$App$Data$Person$ridById(dep.dw.as);
		var ridByDoc = $author$project$App$Data$Person$ridByDoc(dep.dw.i1);
		var personLoaded = true;
		var invalidDoc = A2($author$project$App$Page$Support$EditStatement$StatementEdit$documentIsValid, dep.dw.i1, api) ? false : true;
		var docComplete = $author$project$App$Data$Person$isDocumentValid(dep.dw.i1);
		var currentTemplate = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$StatementTemplate$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.as, fullStmt.g.e.eY);
					},
					modelForm.eZ)));
		return A2(
			$elm$html$Html$fieldset,
			_List_Nil,
			$elm$core$List$concat(
				_List_fromArray(
					[
						A4(
						$author$project$App$Page$Support$EditStatement$StatementEdit$viewEditPersonDocument,
						$author$project$App$Data$Statement$Dependent(idx),
						dep.dw,
						api,
						currentTemplate),
						docComplete ? ((personLoaded && (!invalidDoc)) ? $elm$core$List$concat(
						_List_fromArray(
							[
								A4(
								$author$project$App$Page$Support$EditStatement$StatementEdit$viewEditPersonInformation,
								$author$project$App$Data$Statement$Dependent(idx),
								dep,
								modelForm,
								now),
								(A2($author$project$App$Data$Person$ageOnDate, now, dep.dw) < 18) ? _List_Nil : A3(
								$author$project$App$Page$Support$EditStatement$StatementEdit$viewEditContacts,
								$author$project$App$Data$Statement$Dependent(idx),
								modelForm,
								dep.ea)
							])) : (invalidDoc ? _List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('CPF inválido!')
								]))
						]) : _List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Validando CPF...')
								]))
						]))) : _List_fromArray(
						[
							$elm$html$Html$text('')
						])
					])));
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$viewTemplateContent = F2(
	function (fullStmt, modelForm) {
		var templates = A2(
			$elm$core$List$filter,
			function (t) {
				return t.hl !== 'Entrevista';
			},
			modelForm.eZ);
		var stmtTemplate = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$StatementTemplate$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.as, fullStmt.g.e.eY);
					},
					modelForm.eZ)));
		var stmtModel = modelForm.a5.g.e;
		var showModelOfInterviewText = A2($elm$core$List$member, 'interview-model:visible', stmtTemplate.eM);
		var dueDate = $author$project$App$Data$Id$uuidIsValid(stmtModel.as) ? A2(
			$elm$html$Html$label,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Prazo Final: '),
					A3(
					$author$project$App$Tools$FormHelpers$dateInputWithAttrs,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'disabled', '')
						]),
					stmtModel.gL,
					A2(
						$elm$core$Basics$composeL,
						$author$project$App$Page$Support$EditStatement$StatementEdit$InputStmtField($author$project$App$Data$Statement$Bearer),
						$author$project$App$Data$Statement$FieldDueDate))
				])) : $elm$html$Html$text('');
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$legend,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Cadastro')
							]))
					])),
				dueDate,
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Modelo de Atendimento'),
						A5(
						$author$project$App$Tools$FormHelpers$selectFromCollection,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(
								(modelForm.cH === 1) || ($author$project$App$Data$Id$uuidIsValid(stmtModel.as) && $author$project$App$Data$Statement$isEditable(stmtModel))),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'error-message',
										A3($author$project$App$Page$Support$EditStatement$StatementEdit$errorsInField, 2, $author$project$App$Data$Statement$Bearer, modelForm.fp))
									]))
							]),
						stmtModel.eY,
						templates,
						function (t) {
							return _Utils_Tuple2(t.as, t.hl);
						},
						A2(
							$elm$core$Basics$composeR,
							$author$project$App$Data$Statement$FieldTemplateId,
							$author$project$App$Page$Support$EditStatement$StatementEdit$InputStmtField($author$project$App$Data$Statement$Bearer)))
					])),
				showModelOfInterviewText ? A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Categoria do Atendimento'),
						A4(
						$author$project$App$Tools$FormHelpers$select,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(modelForm.cH === 1)
							]),
						$author$project$App$Data$Statement$shouldInterviewToString(stmtModel.j_),
						A2(
							$elm$core$Basics$composeR,
							$author$project$App$Data$Statement$FieldShouldInterview,
							$author$project$App$Page$Support$EditStatement$StatementEdit$InputStmtField($author$project$App$Data$Statement$Bearer)),
						_List_fromArray(
							[
								_Utils_Tuple2(
								$author$project$App$Data$Statement$shouldInterviewToString(1),
								$author$project$App$Data$Statement$shouldInterviewToText(1)),
								_Utils_Tuple2(
								$author$project$App$Data$Statement$shouldInterviewToString(2),
								$author$project$App$Data$Statement$shouldInterviewToText(2))
							]))
					])) : A2($elm$html$Html$label, _List_Nil, _List_Nil)
			]);
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$viewForm = F3(
	function (st, modelForm, model) {
		var viewDocumentDetails = function () {
			var _v1 = model.ac;
			if (_v1.$ === 1) {
				var viewDocumentModel = _v1.b;
				return A2(
					$elm$html$Html$map,
					$author$project$App$Page$Support$EditStatement$StatementEdit$DocumentViewMsg,
					A2($author$project$App$Page$Support$EditStatement$DocumentView$view, st, viewDocumentModel));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		var now = $author$project$App$Service$ApiWebSocket$getTimestamp(st._);
		var personEditContent = F2(
			function (mDepIdx, editStmt) {
				return A2($author$project$App$Page$Support$EditStatement$StatementEdit$documentIsValid, editStmt.dw.i1, st._) ? _Utils_ap(
					A4($author$project$App$Page$Support$EditStatement$StatementEdit$viewEditPersonInformation, mDepIdx, editStmt, modelForm, now),
					((A2($author$project$App$Data$Person$ageOnDate, now, editStmt.dw) >= 18) && (!(!$elm$time$Time$posixToMillis(editStmt.dw.al)))) ? A3($author$project$App$Page$Support$EditStatement$StatementEdit$viewEditContacts, mDepIdx, modelForm, editStmt.ea) : _List_Nil) : _List_fromArray(
					[
						$elm$html$Html$text('')
					]);
			});
		var fullStmt = modelForm.a5;
		var statementModel = fullStmt.g.e;
		var depsContent = A2(
			$elm$core$List$map,
			function (_v0) {
				var idx = _v0.a;
				var dep = _v0.b;
				return A6($author$project$App$Page$Support$EditStatement$StatementEdit$viewEditDependent, modelForm.ey, idx, modelForm, dep, modelForm.a5, st._);
			},
			$elm$core$Dict$toList(modelForm.a5.fh));
		var currentTemplate = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$StatementTemplate$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.as, modelForm.a5.g.e.eY);
					},
					modelForm.eZ)));
		var depButtonContent = (!currentTemplate.gX) ? $elm$html$Html$text('') : (A2($author$project$App$Page$Support$EditStatement$StatementEdit$documentIsValid, fullStmt.g.dw.i1, st._) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('action-buttons')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('button'),
							$elm$html$Html$Events$onClick($author$project$App$Page$Support$EditStatement$StatementEdit$AddDependent)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Incluir dependente')
						]))
				])) : $elm$html$Html$text(''));
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_Nil,
				model.y ? _List_fromArray(
					[
						A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Carregando...')
											]))
									]))
							]))
					]) : ((!model.a6) ? _List_fromArray(
					[
						A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + A3(
													$elm$core$String$slice,
													0,
													10,
													$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(fullStmt.g.e.gL)))
											]))
									])),
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Novo vencimento:')
									])),
								A3($author$project$App$Tools$FormHelpers$dateInputWithAttrs, _List_Nil, model.cq, $author$project$App$Page$Support$EditStatement$StatementEdit$UpdateNewDueDate),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('button'),
										A2($elm$html$Html$Attributes$style, 'margin', '0'),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Support$EditStatement$StatementEdit$PostPoneStatement(
											_Utils_update(
												statementModel,
												{gL: model.cq}))),
										$elm$html$Html$Attributes$value('Alterar Vencimento')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Alterar Vencimento')
									]))
							])),
						(_Utils_cmp(
						$author$project$App$Data$StatementStatus$statusToInt(statementModel.aw),
						$author$project$App$Data$StatementStatus$statusToInt(24)) > -1) ? A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Remover parecer e enviar para o diretor clínico da Medsyn:')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('action-buttons')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('')
											]))
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('button'),
										A2($elm$html$Html$Attributes$style, 'margin', '0'),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Support$EditStatement$StatementEdit$ReturnToDoctor(statementModel)),
										$elm$html$Html$Attributes$value('Retornar para o Diretor Clínico')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Retornar para o Diretor Clínico')
									]))
							])) : $elm$html$Html$text(''),
						(_Utils_cmp(
						$author$project$App$Data$StatementStatus$statusToInt(statementModel.aw),
						$author$project$App$Data$StatementStatus$statusToInt(8)) > -1) ? A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Retornar para a fila:')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('action-buttons')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('')
											]))
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('button'),
										A2($elm$html$Html$Attributes$style, 'margin', '0'),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Support$EditStatement$StatementEdit$ReturnToConfirmInterview(statementModel)),
										$elm$html$Html$Attributes$value('Retornar para a fila')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Retornar para a fila')
									]))
							])) : $elm$html$Html$text(''),
						(_Utils_cmp(
						$author$project$App$Data$StatementStatus$statusToInt(statementModel.aw),
						$author$project$App$Data$StatementStatus$statusToInt(4)) > 0) ? A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Gerar as respostas novamente:')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('action-buttons')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('')
											]))
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('button'),
										A2($elm$html$Html$Attributes$style, 'margin', '0'),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Support$EditStatement$StatementEdit$ReturnToWaitingForRoom(statementModel)),
										$elm$html$Html$Attributes$value('Gerar as respostas novamente')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Gerar as respostas novamente')
									]))
							])) : $elm$html$Html$text(''),
						A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Cancelar :')
											]))
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('button'),
										A2($elm$html$Html$Attributes$style, 'margin', '0'),
										$elm$html$Html$Events$onClick(
										A2($author$project$App$Page$Support$EditStatement$StatementEdit$ReturnToCompany, fullStmt.g.e.as, model.b)),
										$elm$html$Html$Attributes$value('Cancelar Atendimento')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Cancelar Atendimento')
									]))
							])),
						A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Alternar vídeo :')
											]))
									])),
								viewDocumentDetails
							])),
						A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$legend,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Editar informações:')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('action-buttons')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('')
											]))
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('button'),
										A2($elm$html$Html$Attributes$style, 'margin', '0'),
										$elm$html$Html$Events$onClick($author$project$App$Page$Support$EditStatement$StatementEdit$ToggleEditStatement),
										$elm$html$Html$Attributes$value('Editar')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Editar')
									]))
							]))
					]) : _List_fromArray(
					[
						A2(
						$elm$html$Html$fieldset,
						_List_Nil,
						_Utils_ap(
							A2($author$project$App$Page$Support$EditStatement$StatementEdit$viewTemplateContent, fullStmt, modelForm),
							_Utils_ap(
								A4($author$project$App$Page$Support$EditStatement$StatementEdit$viewEditPersonDocument, $author$project$App$Data$Statement$Bearer, fullStmt.g.dw, st._, st.ia.h8.cJ),
								_Utils_ap(
									A2(personEditContent, $author$project$App$Data$Statement$Bearer, fullStmt.g),
									_Utils_ap(
										depsContent,
										_List_fromArray(
											[depButtonContent]))))))
					])))
			]);
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$viewStatementForm = F4(
	function (filter, modelForm, model, st) {
		return A2(
			$elm$html$Html$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$header,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Editando Cadastro')
								])),
							model.a6 ? A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('action-buttons')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Support$EditStatement$StatementEdit$ToggleEditStatement)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Cancelar')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Support$EditStatement$StatementEdit$Save)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Salvar')
										]))
								])) : A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('action-buttons')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Support$EditStatement$StatementEdit$GoToIncomplete)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Voltar')
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('authentication'),
							A2($elm$html$Html$Attributes$style, 'overflow', 'inherit')
						]),
					$author$project$App$Data$Id$uuidIsValid(model.h8.g.e.as) ? A3($author$project$App$Page$Support$EditStatement$StatementEdit$viewForm, st, modelForm, model) : _List_Nil)
				]));
	});
var $author$project$App$Page$Support$EditStatement$StatementEdit$view = F2(
	function (st, model) {
		var viewDetails = function () {
			var _v1 = model.gD;
			if (_v1.$ === 1) {
				var viewFullStmt = _v1.b;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Support$EditStatement$StatementEdit$ViewFullStmtMsg,
							A2($author$project$App$Components$ViewFullStatement$view, st, viewFullStmt))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		var stmtId = model.as;
		var notificationView = $author$project$App$Page$Support$EditStatement$StatementEdit$notificationFromModel(model);
		var modelForm = $author$project$App$Page$Support$EditStatement$StatementEdit$formFromModel(model);
		var fullStmt = model.h8;
		var dialogView = A2($author$project$App$Page$Support$EditStatement$StatementEdit$dialogFromModel, model, st);
		var _v0 = _Utils_Tuple2('Revisar Atendimento', 'Atualizar');
		var headerTitle = _v0.a;
		var actionText = _v0.b;
		return _List_fromArray(
			[
				A2($author$project$App$Page$Support$EditStatement$StatementEdit$viewErrors, st, model),
				A4($author$project$App$Page$Support$EditStatement$StatementEdit$viewStatementForm, model.b, modelForm, model, st),
				viewDetails,
				dialogView,
				notificationView
			]);
	});
var $author$project$App$Page$Support$PageListApproved$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Support$PageListApproved$GoToEditStatement = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Support$PageListApproved$HideDetails = {$: 5};
var $author$project$App$Page$Support$PageListApproved$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var selectedStmtId = function () {
			var _v5 = model.gD;
			if (_v5.$ === 1) {
				var stmtId = _v5.a;
				return $elm$core$Maybe$Just(stmtId);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var editButton = A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list-buttons'),
					$elm$html$Html$Attributes$title('Editar'),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Support$PageListApproved$GoToEditStatement(stmt.as))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'font-size', '16px'),
							A2($elm$html$Html$Attributes$style, 'float', 'left')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Editar')
						])),
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('edit')
						]))
				]));
		var _v0 = function () {
			var _v1 = model.gD;
			if (_v1.$ === 1) {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$App$Page$Support$PageListApproved$HideDetails)
						]),
					_List_fromArray(
						[editButton]));
			} else {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Support$PageListApproved$ViewDetails(stmt.as))
						]),
					_List_fromArray(
						[editButton]));
			}
		}();
		var onClickMsg = _v0.a;
		var buttons = _v0.b;
		var _v2 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v2.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v2.a.$) {
				case 3:
					var person = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_Utils_ap(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('user-item'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'user-selected',
											_Utils_eq(
												selectedStmtId,
												$elm$core$Maybe$Just(stmt.as)))
										]))
								]),
							onClickMsg),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_Utils_ap(
									buttons,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Status:')
												])),
											(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente titular')
												])) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente dependente')
												])))) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$elm$core$String$fromInt(stmt.bo) + ' dependentes')
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
												]))
										]))),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$Person$fullPersonName(person))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Última atualização: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.f2))
											]))
									]))
							]));
				case 2:
					var err = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v3 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v4 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Support$PageListApproved$view = F2(
	function (st, model) {
		var viewStatements = ($elm$core$List$length(model.bk) > 0) ? A2(
			$elm$core$List$map,
			A2($author$project$App$Page$Support$PageListApproved$viewUserListRow, model, st.ia.h8.cJ),
			model.bk) : _List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Nenhum item encontrado.')
					]))
			]);
		var viewDetails = function () {
			var _v0 = model.gD;
			if (_v0.$ === 1) {
				var viewFullStmt = _v0.b;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Support$PageListApproved$ViewFullStmtMsg,
							A2($author$project$App$Components$ViewFullStatement$view, st, viewFullStmt))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Aprovados')
									])),
								A2(
								$elm$html$Html$map,
								$author$project$App$Page$Support$PageListApproved$FilterMsg,
								$author$project$App$Components$Filter$view(model.b))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								viewStatements)
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Support$PageListApproved$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					])),
				viewDetails
			]);
	});
var $author$project$App$Page$Support$PageListForReview$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Support$PageListForReview$nextIdFromPending = function (model) {
	if ($elm$core$List$length(model.bk) > 0) {
		var firstStmt = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Statement$emptyModel,
			$elm$core$List$head(model.bk));
		return firstStmt.as;
	} else {
		return $author$project$App$Data$Id$uuidNil;
	}
};
var $author$project$App$Page$Support$PageListForReview$ReviewInterview = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Support$PageListForReview$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var reviewButton = A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list-buttons'),
					$elm$html$Html$Attributes$title('Enviar para Revisão'),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Support$PageListForReview$ReviewInterview(stmt.as))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('done')
						]))
				]));
		var _v0 = function () {
			var _v1 = _Utils_Tuple2(
				stmt.aw,
				_Utils_eq(
					model.aU,
					$elm$core$Maybe$Just(stmt.as)));
			_v1$0:
			while (true) {
				if (!_v1.b) {
					if (!_v1.a) {
						break _v1$0;
					} else {
						return _Utils_Tuple2(
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$App$Page$Support$PageListForReview$Review(stmt.as))
								]),
							_List_Nil);
					}
				} else {
					if (!_v1.a) {
						break _v1$0;
					} else {
						return _Utils_Tuple2(
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$App$Page$Support$PageListForReview$Review(stmt.as))
								]),
							_List_Nil);
					}
				}
			}
			var _v2 = _v1.a;
			return _Utils_Tuple2(
				_List_Nil,
				_List_fromArray(
					[reviewButton]));
		}();
		var onClickMsg = _v0.a;
		var buttons = _v0.b;
		var _v3 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v3.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v3.a.$) {
				case 3:
					var person = _v3.a.a;
					return A2(
						$elm$html$Html$li,
						_Utils_ap(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('user-item'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'user-selected',
											_Utils_eq(
												model.aU,
												$elm$core$Maybe$Just(stmt.as)))
										]))
								]),
							onClickMsg),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status:')
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$StatementStatus$statusToText(stmt.aw))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_Utils_ap(
									buttons,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$App$Data$Person$fullPersonName(person))
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
												])),
											(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente titular')
												])) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente dependente')
												])))) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$elm$core$String$fromInt(stmt.bo) + ' dependentes')
												]))
										])))
							]));
				case 2:
					var err = _v3.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v4 = _v3.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v5 = _v3.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Support$PageListForReview$view = F2(
	function (st, model) {
		var viewStatements = ($elm$core$List$length(model.bk) > 0) ? A2(
			$elm$core$List$map,
			A2($author$project$App$Page$Support$PageListForReview$viewUserListRow, model, st.ia.h8.cJ),
			model.bk) : _List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Nenhum item encontrado.')
					]))
			]);
		var viewDetails = function () {
			var _v0 = model.av;
			if (!_v0.$) {
				var viewFullStmt = _v0.a;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Support$PageListForReview$ViewFullStmtMsg,
							A2($author$project$App$Components$ViewFullStatement$view, st, viewFullStmt))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Revisão')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('action-buttons')
									]),
								_List_fromArray(
									[
										(!$elm$core$List$isEmpty(model.bk)) ? A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$type_('button'),
												$elm$html$Html$Events$onClick(
												$author$project$App$Page$Support$PageListForReview$Review(
													$author$project$App$Page$Support$PageListForReview$nextIdFromPending(model)))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Revisar Próxima')
											])) : $elm$html$Html$text('')
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								viewStatements)
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Support$PageListForReview$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					])),
				viewDetails
			]);
	});
var $author$project$App$Page$Support$PageListIncomplete$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Support$PageListIncomplete$CancelReturnToCompany = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Support$PageListIncomplete$ConfirmReturnToCompany = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$App$Page$Support$PageListIncomplete$InputObservationField = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var $author$project$App$Page$Support$PageListIncomplete$dialogFromModel = F2(
	function (model, st) {
		var header = _List_Nil;
		var footer = function (filter) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('cancel-button'),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Support$PageListIncomplete$CancelReturnToCompany(model))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Cancelar')
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							A2($author$project$App$Page$Support$PageListIncomplete$ConfirmReturnToCompany, model.h8, filter))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Devolver')
						]))
				]);
		};
		var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
		var body = function (filter) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$textarea,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$cols(50),
							$elm$html$Html$Attributes$rows(5),
							$elm$html$Html$Attributes$value(model.h8.jx),
							$elm$html$Html$Events$onInput(
							function (str) {
								return A3(
									$author$project$App$Page$Support$PageListIncomplete$InputObservationField,
									model.h8.as,
									filter,
									$author$project$App$Data$Statement$FieldObservation(str));
							})
						]),
					_List_Nil)
				]);
		};
		var completeConfig = function (filter) {
			return _Utils_update(
				config,
				{
					e5: $elm$core$Maybe$Just(
						body(filter)),
					ft: $elm$core$Maybe$Just(
						footer(filter)),
					gY: $elm$core$Maybe$Just(header),
					f1: 'Motivo da Devolução'
				});
		};
		return model.dC ? $author$project$App$Components$Dialog$view(
			$elm$core$Maybe$Just(
				completeConfig(model.b))) : $elm$html$Html$text('');
	});
var $author$project$App$Page$Support$PageListIncomplete$GoToEditStatement = function (a) {
	return {$: 12, a: a};
};
var $author$project$App$Page$Support$PageListIncomplete$HideDetails = {$: 5};
var $author$project$App$Page$Support$PageListIncomplete$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var selectedStmtId = function () {
			var _v4 = model.gD;
			if (_v4.$ === 1) {
				var stmtId = _v4.a;
				return $elm$core$Maybe$Just(stmtId);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var onClickMsg = function (stmtId) {
			var _v3 = model.gD;
			if (_v3.$ === 1) {
				return $author$project$App$Page$Support$PageListIncomplete$HideDetails;
			} else {
				return $author$project$App$Page$Support$PageListIncomplete$ViewDetails(stmtId);
			}
		};
		var editButton = A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list-buttons'),
					$elm$html$Html$Attributes$title('Editar'),
					$elm$html$Html$Events$onClick(
					$author$project$App$Page$Support$PageListIncomplete$GoToEditStatement(stmt.as))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'font-size', '16px'),
							A2($elm$html$Html$Attributes$style, 'float', 'left')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Editar')
						])),
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('edit')
						]))
				]));
		var buttons = _List_fromArray(
			[editButton]);
		var _v0 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v0.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v0.a.$) {
				case 3:
					var person = _v0.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'user-selected',
										_Utils_eq(
											selectedStmtId,
											$elm$core$Maybe$Just(stmt.as)))
									])),
								$elm$html$Html$Events$onClick(
								onClickMsg(stmt.as))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status:')
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$StatementStatus$statusToText(stmt.aw))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_Utils_ap(
									buttons,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$App$Data$Person$fullPersonName(person))
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
												])),
											(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente titular')
												])) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente dependente')
												])))) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$elm$core$String$fromInt(stmt.bo) + ' dependentes')
												]))
										])))
							]));
				case 2:
					var err = _v0.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v1 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v2 = _v0.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Support$PageListIncomplete$view = F2(
	function (st, model) {
		var viewStatements = ($elm$core$List$length(model.bk) > 0) ? A2(
			$elm$core$List$map,
			A2($author$project$App$Page$Support$PageListIncomplete$viewUserListRow, model, st.ia.h8.cJ),
			model.bk) : _List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Nenhum item encontrado.')
					]))
			]);
		var viewDetails = function () {
			var _v0 = model.gD;
			if (_v0.$ === 1) {
				var viewFullStmt = _v0.b;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Support$PageListIncomplete$ViewFullStmtMsg,
							A2($author$project$App$Components$ViewFullStatement$view, st, viewFullStmt))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		var dialogView = A2($author$project$App$Page$Support$PageListIncomplete$dialogFromModel, model, st);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Incompletos')
									])),
								A2(
								$elm$html$Html$map,
								$author$project$App$Page$Support$PageListIncomplete$FilterMsg,
								$author$project$App$Components$Filter$view(model.b))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								viewStatements)
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Support$PageListIncomplete$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					])),
				viewDetails,
				dialogView
			]);
	});
var $author$project$App$Page$Support$PageListReevaluation$FilterMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$Support$PageListReevaluation$Evaluate = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Support$PageListReevaluation$HideInterview = {$: 4};
var $author$project$App$Page$Support$PageListReevaluation$viewUserListRow = F3(
	function (model, stmtTemplate, stmt) {
		var _v0 = function () {
			var _v1 = _Utils_Tuple2(
				stmt.aw,
				_Utils_eq(
					model.aU,
					$elm$core$Maybe$Just(stmt.as)));
			if (!_v1.b) {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Support$PageListReevaluation$Evaluate(stmt.as))
						]),
					_List_Nil);
			} else {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$App$Page$Support$PageListReevaluation$HideInterview)
						]),
					_List_Nil);
			}
		}();
		var onClickMsg = _v0.a;
		var buttons = _v0.b;
		var _v2 = A2($elm$core$Dict$get, stmt.Y, model.bF);
		if (_v2.$ === 1) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('user-item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Requisitando...')
							]))
					]));
		} else {
			switch (_v2.a.$) {
				case 3:
					var person = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_Utils_ap(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('user-item'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'user-selected',
											_Utils_eq(
												model.aU,
												$elm$core$Maybe$Just(stmt.as)))
										]))
								]),
							onClickMsg),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$App$Data$Person$photoUrl(person))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status:')
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$App$Data$StatementStatus$statusToText(stmt.aw))
											])),
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Vencimento: ' + $author$project$App$Tools$FormHelpers$dateFormat(stmt.gL))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('user-description')
									]),
								_Utils_ap(
									buttons,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$App$Data$Person$fullPersonName(person))
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													'CPF: ' + $author$project$App$Data$Person$formatDocument(person.i1))
												])),
											(!stmt.bo) ? ((!stmtTemplate.gX) ? A2($elm$html$Html$p, _List_Nil, _List_Nil) : (_Utils_eq(stmt.jK, $elm$core$Maybe$Nothing) ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente titular')
												])) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Somente dependente')
												])))) : A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$elm$core$String$fromInt(stmt.bo) + ' dependentes')
												]))
										])))
							]));
				case 2:
					var err = _v2.a.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Service$ApiWebSocket$errorMessage(err))
									]))
							]));
				case 1:
					var _v3 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
				default:
					var _v4 = _v2.a;
					return A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-item')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Carregando...')
									]))
							]));
			}
		}
	});
var $author$project$App$Page$Support$PageListReevaluation$viewStatements = F2(
	function (model, stmtTemplate) {
		var _v0 = model.bk;
		if (!_v0.b) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Nenhum atendimento em reavaliação')
						]))
				]);
		} else {
			var statements = _v0;
			return A2(
				$elm$core$List$map,
				A2($author$project$App$Page$Support$PageListReevaluation$viewUserListRow, model, stmtTemplate),
				statements);
		}
	});
var $author$project$App$Page$Support$PageListReevaluation$view = F2(
	function (sharedState, model) {
		var viewDetails = function () {
			var _v0 = model.av;
			if (!_v0.$) {
				var viewFullStmt = _v0.a;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Support$PageListReevaluation$ViewFullStmtMsg,
							A2(
								$author$project$App$Components$ViewFullStatement$view,
								sharedState,
								_Utils_update(
									viewFullStmt,
									{a5: sharedState.ia.h8})))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Em Reavaliação')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('action-buttons')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$map,
										$author$project$App$Page$Support$PageListReevaluation$FilterMsg,
										$author$project$App$Components$Filter$view(model.b))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('users-list')
									]),
								A2($author$project$App$Page$Support$PageListReevaluation$viewStatements, model, sharedState.ia.h8.cJ))
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Support$PageListReevaluation$FilterMsg,
						A2($author$project$App$Components$Filter$viewPagination, model.bk, model.b))
					])),
				viewDetails
			]);
	});
var $author$project$App$Page$Support$PageReevaluation$BackToFinished = {$: 1};
var $author$project$App$Page$Support$PageReevaluation$FinishReevaluation = {$: 4};
var $author$project$App$Page$Support$PageReevaluation$SendToReview = {$: 3};
var $author$project$App$Page$Support$PageReevaluation$ToggleDialog = {$: 2};
var $author$project$App$Page$Support$PageReevaluation$UpdateReviewMessage = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Page$Support$PageReevaluation$dialogFromModel = function (model) {
	var header = _List_Nil;
	var footer = _List_fromArray(
		[
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cancel-button'),
					$elm$html$Html$Events$onClick($author$project$App$Page$Support$PageReevaluation$ToggleDialog)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Cancelar')
				])),
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$App$Page$Support$PageReevaluation$SendToReview)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Devolver')
				]))
		]);
	var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
	var body = _List_fromArray(
		[
			A2(
			$elm$html$Html$textarea,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$cols(50),
					$elm$html$Html$Attributes$rows(5),
					$elm$html$Html$Attributes$value(model.eN),
					$elm$html$Html$Events$onInput($author$project$App$Page$Support$PageReevaluation$UpdateReviewMessage)
				]),
			_List_Nil)
		]);
	var completeConfig = _Utils_update(
		config,
		{
			e5: $elm$core$Maybe$Just(body),
			ft: $elm$core$Maybe$Just(footer),
			gY: $elm$core$Maybe$Just(header),
			f1: 'Motivo da Reavaliação'
		});
	return model.dC ? $author$project$App$Components$Dialog$view(
		$elm$core$Maybe$Just(completeConfig)) : $elm$html$Html$text('');
};
var $author$project$App$Page$Support$PageReevaluation$viewCPTContent = F3(
	function (mDepIdx, fullStmt, now) {
		return A3($author$project$App$Legacy$Doctor$ResultLayout$Common$viewCPTLayout, mDepIdx, fullStmt, now);
	});
var $author$project$App$Page$Support$PageReevaluation$viewDoc = function (doc) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pdfviewer')
			]),
		(!$elm$core$String$length(doc.iU)) ? _List_fromArray(
			[
				$elm$html$Html$text('Nenhum PDF carregado')
			]) : _List_fromArray(
			[
				$author$project$App$Data$Document$view(doc),
				$elm$html$Html$text('                    ')
			]));
};
var $author$project$App$Page$Support$PageReevaluation$viewPersonICD = F4(
	function (now, mDepIdx, stmt, sharedState) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(stmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					A3(
						$elm$core$Dict$foldr,
						F3(
							function (cat, icd, html) {
								return A2(
									$elm$core$List$append,
									html,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('chip'),
													$elm$html$Html$Attributes$title(icd.am)
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(cat)
														]))
												]))
										]));
							}),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Data$Person$fullPersonName(stmt.dw))
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(
											A2($author$project$App$Data$Person$ageOnDate, now, stmt.dw)) + ' anos')
									])),
								sharedState.ia.h8.cJ.gX ? A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											if (mDepIdx.$ === 1) {
												var i = mDepIdx.a;
												return 'Dependente ' + $elm$core$String$fromInt(i + 1);
											} else {
												return _Utils_eq(stmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
											}
										}())
									])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
							]),
						A3(
							$elm$core$Dict$foldr,
							F3(
								function (_v0, qCatDict, catDict) {
									return A3(
										$elm$core$Dict$foldl,
										F3(
											function (cat, icd, catDictInner) {
												return A3($elm$core$Dict$insert, cat, icd, catDictInner);
											}),
										catDict,
										qCatDict);
								}),
							$elm$core$Dict$empty,
							stmt.fx)))
				]));
	});
var $author$project$App$Page$Support$PageReevaluation$viewICDSection = F2(
	function (sharedState, model) {
		var chatView = $author$project$App$Components$Chatbox$view;
		var bearerPersonIcd = (sharedState.ia.h8.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A4($author$project$App$Page$Support$PageReevaluation$viewPersonICD, model.ey, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g, sharedState)
			]);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tabs ')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Dados por Pessoa')
									])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('material-icons')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('print')
													]))
											]))
									]))
							])),
						A2(
						$elm$html$Html$map,
						$author$project$App$Page$Support$PageReevaluation$ChatBoxMsg,
						chatView(model.aM)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('person-icds')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_Nil,
								A3(
									$elm$core$Dict$foldl,
									F3(
										function (i, dep, html) {
											return A2(
												$elm$core$List$append,
												html,
												_List_fromArray(
													[
														A4(
														$author$project$App$Page$Support$PageReevaluation$viewPersonICD,
														model.ey,
														$author$project$App$Data$Statement$Dependent(i),
														dep,
														sharedState)
													]));
										}),
									bearerPersonIcd,
									sharedState.ia.h8.fh))
							]))
					]))
			]);
	});
var $author$project$App$Page$Support$PageReevaluation$viewMedicalDocumentContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewMedicalDocumentLayout(fullStmt);
};
var $author$project$App$Page$Support$PageReevaluation$getAnswerForTemplateId = F3(
	function (depIdx, model, tId) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Answer$emptyModel,
			A2(
				$elm$core$Dict$get,
				tId,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Dict$empty,
					A2(
						$elm$core$Dict$get,
						$author$project$App$Data$Statement$depIndexToInt(depIdx),
						model.aZ))));
	});
var $author$project$App$Page$Support$PageReevaluation$viewICD = F4(
	function (ansTemp, catCode, mIdx, icd) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('chip'),
					$elm$html$Html$Attributes$title(icd.am)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(icd.a0)
						]))
				]));
	});
var $author$project$App$Page$Support$PageReevaluation$viewICDs = F3(
	function (icds, ansTemp, mIdx) {
		return A3(
			$elm$core$Dict$foldr,
			F3(
				function (catCode, icd, result) {
					return A2(
						$elm$core$List$append,
						result,
						_List_fromArray(
							[
								A4($author$project$App$Page$Support$PageReevaluation$viewICD, ansTemp, catCode, mIdx, icd)
							]));
				}),
			_List_Nil,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Dict$empty,
				A2($elm$core$Dict$get, ansTemp.as, icds)));
	});
var $author$project$App$Page$Support$PageReevaluation$viewBMI = F4(
	function (sharedState, model, depIdx, stmt) {
		var weightTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 10) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.jM);
		var personQuestion = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestion$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (q) {
						return $author$project$App$Data$Id$uuidIsValid(q.hG.as);
					},
					A2(
						$elm$core$List$map,
						function (_v0) {
							var s = _v0.b;
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (q) {
											return _Utils_eq(q.hG.as, weightTemplate.bh);
										},
										s.eI)));
						},
						$elm$core$Dict$toList(model.cA)))));
		var heightTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 20) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.jM);
		var bmiTemplate = A3(
			$elm$core$List$foldl,
			F2(
				function (s, result) {
					var template = A3(
						$elm$core$List$foldl,
						F2(
							function (q, re) {
								var tmplt = A3(
									$elm$core$List$foldl,
									F2(
										function (t, r) {
											return (t.ay === 1000) ? t : r;
										}),
									$author$project$App$Data$AnswerTemplate$emptyModel,
									q.bY);
								return $author$project$App$Data$Id$uuidIsValid(tmplt.as) ? tmplt : re;
							}),
						$author$project$App$Data$AnswerTemplate$emptyModel,
						s.eI);
					return $author$project$App$Data$Id$uuidIsValid(template.as) ? template : result;
				}),
			$author$project$App$Data$AnswerTemplate$emptyModel,
			model.P.gH);
		var doctorQuestion = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestion$emptyModel,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (q) {
						return $author$project$App$Data$Id$uuidIsValid(q.hG.as);
					},
					A2(
						$elm$core$List$map,
						function (s) {
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (q) {
											return _Utils_eq(q.hG.as, bmiTemplate.bh);
										},
										s.eI)));
						},
						model.P.gH))));
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no DPS')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatCompanyBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Support$PageReevaluation$viewICDs, stmt.fx, weightTemplate, depIdx))
							]))
					])),
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no Questionário')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatPersonBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Support$PageReevaluation$viewICDs, stmt.fx, heightTemplate, depIdx))
							]))
					])),
				A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('BMI no Atendimento')
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$App$Data$Statement$formatDoctorBMI(stmt.e))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('chips chips-initial input-field')
									]),
								A3($author$project$App$Page$Support$PageReevaluation$viewICDs, stmt.fx, bmiTemplate, depIdx))
							]))
					]))
			]);
	});
var $author$project$App$Page$Support$PageReevaluation$maskDecimal = F2(
	function (precision, value) {
		return A3($elm$core$String$replace, '.', ',', value);
	});
var $author$project$App$Page$Support$PageReevaluation$maskHeight = function (cm) {
	var len = $elm$core$String$length(cm);
	var subM = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cm)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cm) : cm);
	var h = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(cm));
	var m = (h >= 100) ? A3($elm$core$String$slice, 0, -2, cm) : '0';
	return (h > 0) ? (m + (',' + subM)) : '';
};
var $author$project$App$Page$Support$PageReevaluation$maskPressure = function (str) {
	return function (p) {
		return (p > 0) ? $elm$core$String$fromInt(p) : '';
	}(
		function (p) {
			return (p > 999) ? (p / 10) : (p / 1.0);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(str))) | 0);
};
var $author$project$App$Page$Support$PageReevaluation$maskWeight = function (wStr) {
	return function (w) {
		return (w > 0) ? $elm$core$String$fromInt(w) : '';
	}(
		function (w) {
			return w / 1000;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(wStr))) | 0);
};
var $author$project$App$Page$Support$PageReevaluation$setupMask = F2(
	function (template, answer) {
		var _v0 = template.jj;
		if (_v0.$ === 1) {
			return answer.T;
		} else {
			switch (_v0.a.$) {
				case 3:
					var precision = _v0.a.a;
					return A2($author$project$App$Page$Support$PageReevaluation$maskDecimal, precision, answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 0:
					var _v1 = _v0.a;
					return $author$project$App$Page$Support$PageReevaluation$maskWeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				case 1:
					var _v2 = _v0.a;
					return $author$project$App$Page$Support$PageReevaluation$maskHeight(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
				default:
					var _v3 = _v0.a;
					return $author$project$App$Page$Support$PageReevaluation$maskPressure(answer.T) + (' ' + A2($elm$core$Maybe$withDefault, '', template.iv));
			}
		}
	});
var $author$project$App$Page$Support$PageReevaluation$viewQuestion = F5(
	function (depIdx, question, sharedState, model, doctorQuestion) {
		var stmt = function () {
			if (!depIdx.$) {
				return sharedState.ia.h8.g;
			} else {
				var idx = depIdx.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$FullStatement$emptyEditStatement,
					A2($elm$core$Dict$get, idx, sharedState.ia.h8.fh));
			}
		}();
		var showCids = !A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		return A2(
			$elm$core$List$map,
			function (t) {
				var answer = A3($author$project$App$Page$Support$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
				var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
				return (show || (t.ay >= 1000)) ? A2(
					$elm$html$Html$tr,
					_List_Nil,
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(question.hG.bP)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(t.jh)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($author$project$App$Page$Support$PageReevaluation$setupMask, t, answer))
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(answer.jx)
									]))
							]),
						showCids ? _List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('chips chips-initial input-field')
											]),
										A3($author$project$App$Page$Support$PageReevaluation$viewICDs, stmt.fx, t, depIdx))
									]))
							]) : _List_Nil)) : A2($elm$html$Html$span, _List_Nil, _List_Nil);
			},
			A2(
				$elm$core$List$filter,
				function (t) {
					var answer = A3($author$project$App$Page$Support$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
					var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
					return show;
				},
				question.bY));
	});
var $author$project$App$Page$Support$PageReevaluation$viewEditStatementSectionWithHeader = F5(
	function (depIdx, sharedState, model, fullQuestionSection, doctorQuestionSection) {
		var showCids = !A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var sectionName = function () {
			if (sharedState.ia.h8.b4.iR === 'Extramed') {
				var _v0 = fullQuestionSection.hX.a0;
				switch (_v0) {
					case 2:
						return 'Questionário';
					case 1:
						return 'Declaração';
					case 3:
						return 'Atendimento';
					default:
						return 'Desconhecido';
				}
			} else {
				return A2($elm$core$String$left, 50, fullQuestionSection.hX.hl) + '...';
			}
		}();
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '90%')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$colgroup,
					_List_Nil,
					_List_fromArray(
						[
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil),
							A2($elm$html$Html$col, _List_Nil, _List_Nil)
						])),
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(sectionName)
											])),
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$colspan(2)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Resposta')
											]))
									]),
								showCids ? _List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Obs')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('CIDs')
											]))
									]) : _List_Nil))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, q) {
									return A5(
										$author$project$App$Page$Support$PageReevaluation$viewQuestion,
										depIdx,
										q,
										sharedState,
										model,
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$FullQuestion$emptyModel,
											$elm$core$List$head(
												A2($elm$core$List$drop, i, doctorQuestionSection.eI))));
								}),
							A2(
								$elm$core$List$sortBy,
								function (sec) {
									return sec.hG.bB;
								},
								A2(
									$elm$core$List$filter,
									function (q) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (t) {
													var answer = A3($author$project$App$Page$Support$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
													var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
													return show;
												},
												q.bY)) > 0;
									},
									fullQuestionSection.eI)))))
				]));
	});
var $author$project$App$Page$Support$PageReevaluation$viewEditStatementSectionWithoutHeader = F5(
	function (depIdx, sharedState, model, fullQuestionSection, doctorQuestionSection) {
		return $elm$core$List$concat(
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (i, q) {
						return A5(
							$author$project$App$Page$Support$PageReevaluation$viewQuestion,
							depIdx,
							q,
							sharedState,
							model,
							A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(
									A2($elm$core$List$drop, i, doctorQuestionSection.eI))));
					}),
				A2(
					$elm$core$List$sortBy,
					function (sec) {
						return sec.hG.bB;
					},
					A2(
						$elm$core$List$filter,
						function (q) {
							return $elm$core$List$length(
								A2(
									$elm$core$List$filter,
									function (t) {
										var answer = A3($author$project$App$Page$Support$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
										var show = answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0));
										return show;
									},
									q.bY)) > 0;
						},
						fullQuestionSection.eI))));
	});
var $author$project$App$Page$Support$PageReevaluation$viewPersonDetails = F3(
	function (sharedState, depIdx, editStmt) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('user-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$App$Data$Person$photoUrl(editStmt.dw))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-description')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$fullPersonName(editStmt.dw))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Data$Person$formatDocument(editStmt.dw.i1))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$App$Tools$FormHelpers$dateFormat(editStmt.dw.al))
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									function (str) {
										return str + ' anos';
									}(
										$elm$core$String$fromInt(
											A2(
												$author$project$App$Data$Person$ageOnDate,
												$author$project$App$Service$ApiWebSocket$getTimestamp(sharedState._),
												editStmt.dw))))
								])),
							sharedState.ia.h8.cJ.gX ? A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									function () {
										if (depIdx.$ === 1) {
											var i = depIdx.a;
											return 'Dependente ' + $elm$core$String$fromInt(i + 1);
										} else {
											return _Utils_eq(editStmt.e.jK, $elm$core$Maybe$Nothing) ? 'Titular' : 'Dependente';
										}
									}())
								])) : A2($elm$html$Html$p, _List_Nil, _List_Nil)
						]))
				]));
	});
var $author$project$App$Page$Support$PageReevaluation$viewPersonAnswers = F4(
	function (sharedState, model, depIdx, stmt) {
		var showPerson = sharedState.ia.h8.b4.iR === 'Extramed';
		var showDoctorQuestions = (sharedState.ia.h8.b4.iR === 'Extramed') || (sharedState.ia.h8.b4.iR === 'Centauro-ON');
		var showBMI = sharedState.ia.h8.b4.iR === 'Extramed';
		var doctorSection = A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$FullQuestionSection$emptyModel,
			$elm$core$List$head(
				$elm$core$List$reverse(sharedState.ia.h8.gH)));
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$ul,
							_List_Nil,
							_List_fromArray(
								[
									A3($author$project$App$Page$Support$PageReevaluation$viewPersonDetails, sharedState, depIdx, stmt)
								]))
						])),
					showBMI ? A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '100%')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Dados Antropométricos')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Valor')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('CIDs')
												]))
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							A4($author$project$App$Page$Support$PageReevaluation$viewBMI, sharedState, model, depIdx, stmt))
						])) : $elm$html$Html$text('')
				]),
			_Utils_ap(
				showBMI ? _List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '100%')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Declaração')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Marcado')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Resposta')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Observação Médica')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('CID')
													]))
											]))
									])),
								A2(
								$elm$html$Html$tbody,
								_List_Nil,
								$elm$core$List$concat(
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (i, s) {
												return A5(
													$author$project$App$Page$Support$PageReevaluation$viewEditStatementSectionWithoutHeader,
													depIdx,
													sharedState,
													model,
													s,
													A2(
														$elm$core$Maybe$withDefault,
														$author$project$App$Data$FullQuestionSection$emptyModel,
														$elm$core$List$head(
															A2($elm$core$List$drop, i, sharedState.ia.h8.bs))));
											}),
										A2(
											$elm$core$List$filter,
											function (s) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (q) {
															return $elm$core$List$length(
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var answer = A3($author$project$App$Page$Support$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																		var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
																		return show;
																	},
																	q.bY)) > 0;
														},
														A2(
															$elm$core$List$sortBy,
															function (sec) {
																return sec.hG.bB;
															},
															s.eI))) > 0;
											},
											sharedState.ia.h8.bs))))
							]))
					]) : _List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '100%')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Declaração')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Marcado')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Resposta')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Observação Médica')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('CID')
													]))
											]))
									])),
								A2(
								$elm$html$Html$tbody,
								_List_Nil,
								A2(
									$elm$core$List$indexedMap,
									F2(
										function (i, s) {
											return A5(
												$author$project$App$Page$Support$PageReevaluation$viewEditStatementSectionWithHeader,
												depIdx,
												sharedState,
												model,
												s,
												A2(
													$elm$core$Maybe$withDefault,
													$author$project$App$Data$FullQuestionSection$emptyModel,
													$elm$core$List$head(
														A2($elm$core$List$drop, i, sharedState.ia.h8.bs))));
										}),
									A2(
										$elm$core$List$filter,
										function (s) {
											return $elm$core$List$length(
												A2(
													$elm$core$List$filter,
													function (q) {
														return $elm$core$List$length(
															A2(
																$elm$core$List$filter,
																function (t) {
																	var answer = A3($author$project$App$Page$Support$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																	var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
																	return show;
																},
																q.bY)) > 0;
													},
													A2(
														$elm$core$List$sortBy,
														function (sec) {
															return sec.hG.bB;
														},
														s.eI))) > 0;
										},
										sharedState.ia.h8.bs)))
							]))
					]),
				_Utils_ap(
					_List_fromArray(
						[
							showBMI ? A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '100%')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$thead,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Questionário')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Marcado')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Resposta')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Observação Médica')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('CID')
														]))
												]))
										])),
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											F2(
												function (i, s) {
													return A5(
														$author$project$App$Page$Support$PageReevaluation$viewEditStatementSectionWithoutHeader,
														depIdx,
														sharedState,
														model,
														s,
														A2(
															$elm$core$Maybe$withDefault,
															$author$project$App$Data$FullQuestionSection$emptyModel,
															$elm$core$List$head(
																A2($elm$core$List$drop, i, sharedState.ia.h8.jM))));
												}),
											A2(
												$elm$core$List$filter,
												function (s) {
													return $elm$core$List$length(
														A2(
															$elm$core$List$filter,
															function (q) {
																return $elm$core$List$length(
																	A2(
																		$elm$core$List$filter,
																		function (t) {
																			var answer = A3($author$project$App$Page$Support$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																			var show = answer.d3 && (answer.dF && ((answer.ay !== 10) && (answer.ay !== 20)));
																			return show;
																		},
																		q.bY)) > 0;
															},
															A2(
																$elm$core$List$sortBy,
																function (sec) {
																	return sec.hG.bB;
																},
																s.eI))) > 0;
												},
												sharedState.ia.h8.jM))))
								])) : (showPerson ? A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '100%')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$thead,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Questionário')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Marcado')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Resposta')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Observação Médica')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('CID')
														]))
												]))
										])),
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (i, s) {
												return A5(
													$author$project$App$Page$Support$PageReevaluation$viewEditStatementSectionWithHeader,
													depIdx,
													sharedState,
													model,
													s,
													A2(
														$elm$core$Maybe$withDefault,
														$author$project$App$Data$FullQuestionSection$emptyModel,
														$elm$core$List$head(
															A2($elm$core$List$drop, i, sharedState.ia.h8.jM))));
											}),
										A2(
											$elm$core$List$filter,
											function (s) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (q) {
															return $elm$core$List$length(
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var answer = A3($author$project$App$Page$Support$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																		var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																		return show;
																	},
																	q.bY)) > 0;
														},
														A2(
															$elm$core$List$sortBy,
															function (sec) {
																return sec.hG.bB;
															},
															s.eI))) > 0;
											},
											sharedState.ia.h8.jM)))
								])) : $elm$html$Html$text(''))
						]),
					function () {
						if (showDoctorQuestions) {
							return showBMI ? A2(
								$elm$core$List$cons,
								A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'width', '100%')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$colgroup,
											_List_Nil,
											_List_fromArray(
												[
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil),
													A2($elm$html$Html$col, _List_Nil, _List_Nil)
												])),
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$tr,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$th,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$colspan(5)
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Atendimento')
																]))
														]))
												]))
										])),
								$elm$core$List$concat(
									A2(
										$elm$core$List$map,
										function (s) {
											return A5($author$project$App$Page$Support$PageReevaluation$viewEditStatementSectionWithoutHeader, depIdx, sharedState, model, s, s);
										},
										A2(
											$elm$core$List$filter,
											function (s) {
												return $elm$core$List$length(
													A2(
														$elm$core$List$filter,
														function (q) {
															return ($elm$core$List$length(
																A2(
																	$elm$core$List$filter,
																	function (t) {
																		var answer = A3($author$project$App$Page$Support$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																		var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																		return show;
																	},
																	q.bY)) > 0) && (!A2(
																$elm$core$List$any,
																function (t) {
																	return (t.ay === 10) || (t.ay === 20);
																},
																q.bY));
														},
														A2(
															$elm$core$List$sortBy,
															function (sec) {
																return sec.hG.bB;
															},
															s.eI))) > 0;
											},
											sharedState.ia.h8.gH)))) : A2(
								$elm$core$List$map,
								function (s) {
									return A5($author$project$App$Page$Support$PageReevaluation$viewEditStatementSectionWithHeader, depIdx, sharedState, model, s, s);
								},
								A2(
									$elm$core$List$filter,
									function (s) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (q) {
													return $elm$core$List$length(
														A2(
															$elm$core$List$filter,
															function (t) {
																var answer = A3($author$project$App$Page$Support$PageReevaluation$getAnswerForTemplateId, depIdx, model, t.as);
																var show = answer.d3 && ((answer.dF || ($elm$core$String$length(answer.T) > 0)) && ((answer.ay !== 10) && (answer.ay !== 20)));
																return show;
															},
															q.bY)) > 0;
												},
												A2(
													$elm$core$List$sortBy,
													function (sec) {
														return sec.hG.bB;
													},
													s.eI))) > 0;
									},
									sharedState.ia.h8.gH));
						} else {
							var question = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$FullQuestion$emptyModel,
								$elm$core$List$head(doctorSection.eI));
							var template = A2(
								$elm$core$Maybe$withDefault,
								$author$project$App$Data$AnswerTemplate$emptyModel,
								$elm$core$List$head(question.bY));
							var answer = A3($author$project$App$Page$Support$PageReevaluation$getAnswerForTemplateId, depIdx, model, template.as);
							return (answer.d3 && (answer.dF || ($elm$core$String$length(answer.T) > 0))) ? _List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Evoluções médicas:')
										])),
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(answer.T)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Atestado Médico:')
										])),
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(answer.jx)
										]),
									_List_Nil)
								]) : _List_Nil;
						}
					}())));
	});
var $author$project$App$Page$Support$PageReevaluation$viewQuestions = F2(
	function (model, sharedState) {
		var depsPersonAnswers = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$second,
				$elm$core$Dict$toList(
					A2(
						$elm$core$Dict$map,
						F2(
							function (idx, dep) {
								return A4(
									$author$project$App$Page$Support$PageReevaluation$viewPersonAnswers,
									sharedState,
									model,
									$author$project$App$Data$Statement$depIndexFromInt(idx),
									dep);
							}),
						sharedState.ia.h8.fh))));
		var bearerPersonAnswers = (!(sharedState.ia.h8.g.e.j_ === 2)) ? A4($author$project$App$Page$Support$PageReevaluation$viewPersonAnswers, sharedState, model, $author$project$App$Data$Statement$Bearer, sharedState.ia.h8.g) : _List_Nil;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('questions')
				]),
			_Utils_ap(
				bearerPersonAnswers,
				_Utils_ap(
					depsPersonAnswers,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Conclusões finais do atendimento:')
								])),
							A2(
							$elm$html$Html$textarea,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(model.P.g.e.jx)
								]),
							_List_Nil)
						]))));
	});
var $author$project$App$Page$Support$PageReevaluation$SelectDetailsTab = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Page$Support$PageReevaluation$TabCPT = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Support$PageReevaluation$TabDocument = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$Support$PageReevaluation$viewDocumentTabNumered = F4(
	function (currentTab, idx, doc, documentTabs) {
		var index = $elm$core$List$length(documentTabs) + 1;
		return A2(
			$elm$core$List$append,
			documentTabs,
			function () {
				var _v0 = doc.dr;
				switch (_v0) {
					case 'video/webm':
						return _List_fromArray(
							[
								$elm$html$Html$text('')
							]);
					case 'video/mp4':
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Support$PageReevaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Support$PageReevaluation$SelectDetailsTab(
											$author$project$App$Page$Support$PageReevaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										'Video' + $elm$core$String$fromInt(index))
									]))
							]);
					default:
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('#'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Support$PageReevaluation$TabDocument(idx)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Support$PageReevaluation$SelectDetailsTab(
											$author$project$App$Page$Support$PageReevaluation$TabDocument(idx)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											var _v1 = doc.b7;
											switch (_v1) {
												case 1:
													return 'Parecer Antigo' + $elm$core$String$fromInt(index);
												case 3:
													return 'Atestado' + $elm$core$String$fromInt(index);
												case 4:
													return 'Receita' + $elm$core$String$fromInt(index);
												default:
													return 'Anexo';
											}
										}())
									]))
							]);
				}
			}());
	});
var $author$project$App$Page$Support$PageReevaluation$TabMedicalDocument = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Page$Support$PageReevaluation$viewResultTab = F5(
	function (previewTitle, typeHnsg, currentTab, idx, d) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Support$PageReevaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Support$PageReevaluation$SelectDetailsTab(
							$author$project$App$Page$Support$PageReevaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(previewTitle)
					])),
				typeHnsg ? A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Support$PageReevaluation$TabMedicalDocument($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Support$PageReevaluation$SelectDetailsTab(
							$author$project$App$Page$Support$PageReevaluation$TabDocument(idx)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Pré-Visualizar Atestado')
					])) : A2($elm$html$Html$span, _List_Nil, _List_Nil)
			]);
	});
var $author$project$App$Page$Support$PageReevaluation$viewQuestionsTabs = F5(
	function (fullStmt, viewCPT, typeHnsg, currentTab, docs) {
		var reportDocs = A2(
			$elm$core$List$sortWith,
			F2(
				function (a, b) {
					var _v7 = A2(
						$elm$core$Basics$compare,
						$elm$time$Time$posixToMillis(a.b.iW),
						$elm$time$Time$posixToMillis(b.b.iW));
					switch (_v7) {
						case 0:
							return 2;
						case 1:
							return 1;
						default:
							return 0;
					}
				}),
			$elm$core$Dict$toList(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v6, doc) {
							return doc.b7 === 1;
						}),
					docs)));
		var previewTitle = typeHnsg ? 'Pré-Visualizar Prontuário' : 'Pré-Visualizar Parecer';
		var resultTab = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$second,
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$map,
							A3($author$project$App$Page$Support$PageReevaluation$viewResultTab, previewTitle, typeHnsg, currentTab),
							$elm$core$Dict$fromList(
								A2($elm$core$List$take, 1, reportDocs)))))));
		var oldResultsTab = A3(
			$elm$core$Dict$foldl,
			$author$project$App$Page$Support$PageReevaluation$viewDocumentTabNumered(currentTab),
			_List_Nil,
			$elm$core$Dict$fromList(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(reportDocs))));
		var docsTabs = A3(
			$elm$core$Dict$foldl,
			F3(
				function (i, d, list) {
					var attachName = function () {
						var _v5 = d.dr;
						if (_v5 === 'video/mp4') {
							return 'Video';
						} else {
							return 'Anexo ' + $elm$core$String$fromInt(i);
						}
					}();
					return A2(
						$elm$core$List$append,
						list,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'tab-selected',
												_Utils_eq(
													currentTab,
													$author$project$App$Page$Support$PageReevaluation$TabDocument(i)))
											])),
										$elm$html$Html$Events$onClick(
										$author$project$App$Page$Support$PageReevaluation$SelectDetailsTab(
											$author$project$App$Page$Support$PageReevaluation$TabDocument(i))),
										$elm$html$Html$Attributes$href('#')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(attachName)
									]))
							]));
				}),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'tab-selected',
									_Utils_eq(currentTab, $author$project$App$Page$Support$PageReevaluation$TabCIDs))
								])),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Support$PageReevaluation$SelectDetailsTab($author$project$App$Page$Support$PageReevaluation$TabCIDs))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Dados do Atendimento')
						]))
				]),
			A2(
				$elm$core$Dict$filter,
				F2(
					function (_v4, d) {
						return d.b7 !== 3;
					}),
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v3, d) {
							return d.b7 !== 1;
						}),
					$elm$core$Dict$fromList(
						_Utils_ap(
							A2(
								$elm$core$List$take,
								1,
								$elm$core$List$reverse(
									$elm$core$Dict$toList(
										A2(
											$elm$core$Dict$filter,
											F2(
												function (_v1, doc) {
													return doc.dr === 'video/mp4';
												}),
											docs)))),
							$elm$core$Dict$toList(
								A2(
									$elm$core$Dict$filter,
									F2(
										function (_v2, doc) {
											return (doc.dr !== 'video/webm') && (doc.dr !== 'video/mp4');
										}),
									docs)))))));
		var bearerCpt = (fullStmt.g.e.j_ === 2) ? _List_Nil : _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('#'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tab-selected',
								_Utils_eq(
									currentTab,
									$author$project$App$Page$Support$PageReevaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
							])),
						$elm$html$Html$Events$onClick(
						$author$project$App$Page$Support$PageReevaluation$SelectDetailsTab(
							$author$project$App$Page$Support$PageReevaluation$TabCPT($author$project$App$Data$Statement$Bearer)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('CPT Titular')
					]))
			]);
		var cptTabs = viewCPT ? A2(
			$elm$core$List$append,
			bearerCpt,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var idx = _v0.a;
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('#'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'tab-selected',
										_Utils_eq(
											currentTab,
											$author$project$App$Page$Support$PageReevaluation$TabCPT(
												$author$project$App$Data$Statement$Dependent(idx))))
									])),
								$elm$html$Html$Events$onClick(
								$author$project$App$Page$Support$PageReevaluation$SelectDetailsTab(
									$author$project$App$Page$Support$PageReevaluation$TabCPT(
										$author$project$App$Data$Statement$Dependent(idx))))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'CPT Dep ' + $elm$core$String$fromInt(idx + 1))
							]));
				},
				$elm$core$Dict$toList(fullStmt.fh))) : _List_Nil;
		var allTabs = _Utils_ap(
			docsTabs,
			_Utils_ap(
				resultTab,
				_Utils_ap(oldResultsTab, cptTabs)));
		return A2($elm$html$Html$div, _List_Nil, allTabs);
	});
var $author$project$App$Page$Support$PageReevaluation$viewResultContent = function (fullStmt) {
	return $author$project$App$Legacy$Doctor$ResultLayout$Common$viewResultLayout(fullStmt);
};
var $author$project$App$Page$Support$PageReevaluation$view = F3(
	function (now, sharedState, model) {
		var viewCPT = sharedState.ia.h8.b4.iR === 'Extramed';
		var typeHnsg = A2($elm$core$List$member, 'report:hnsg', sharedState.ia.h8.cJ.eM);
		var maybePdfResult = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var maybePdfDocument = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an)));
		var hasMedicalReport = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 1) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var hasMedicalDocument = $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (d) {
					return (d.b7 === 3) && _Utils_eq(d.jJ, sharedState.ia.h8.g.e.as);
				},
				$elm$core$Dict$values(model.an))) > 0;
		var dialogView = $author$project$App$Page$Support$PageReevaluation$dialogFromModel(model);
		var confirmButton = (model.P.g.e.aw === 31) ? _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$App$Page$Support$PageReevaluation$SendToReview)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Retornar para médico')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$App$Page$Support$PageReevaluation$FinishReevaluation)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Finalizar')
							]))
					]))
			]) : _List_Nil;
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$header,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('tabs')
								]),
							_Utils_ap(
								_List_fromArray(
									[
										A5($author$project$App$Page$Support$PageReevaluation$viewQuestionsTabs, sharedState.ia.h8, viewCPT, typeHnsg, model.b5, sharedState.ia.h8.an),
										A2(
										$elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('cancel-button'),
														$elm$html$Html$Events$onClick($author$project$App$Page$Support$PageReevaluation$BackToFinished)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Retornar')
													]))
											]))
									]),
								confirmButton)),
							function () {
							var _v0 = model.b5;
							switch (_v0.$) {
								case 0:
									return A2($author$project$App$Page$Support$PageReevaluation$viewQuestions, model, sharedState);
								case 1:
									var docIdx = _v0.a;
									return $author$project$App$Page$Support$PageReevaluation$viewDoc(
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$App$Data$Document$emptyModel,
											A2($elm$core$Dict$get, docIdx, model.an)));
								case 2:
									var mDepIdx = _v0.a;
									if (!maybePdfResult.$) {
										var pdfResult = maybePdfResult.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Support$PageReevaluation$viewResultContent(sharedState.ia.h8);
									}
								case 3:
									var mDepIdx = _v0.a;
									if (!maybePdfDocument.$) {
										var pdfResult = maybePdfDocument.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pdfviewer')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$embed,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$attribute, 'height', '100%'),
															$elm$html$Html$Attributes$src(pdfResult.af),
															$elm$html$Html$Attributes$type_('application/pdf'),
															A2($elm$html$Html$Attributes$attribute, 'width', '100%')
														]),
													_List_Nil),
													$elm$html$Html$text('                    ')
												]));
									} else {
										return $author$project$App$Page$Support$PageReevaluation$viewMedicalDocumentContent(model.P);
									}
								case 5:
									var videoRepo = {f3: 'video-repo-enterprise-covid.ingress.medsyn.com.br'};
									var videoUrl = A2($author$project$App$Data$VideoRepository$statementVideoUrl, videoRepo, sharedState.ia.h8.g.e.as);
									return A2(
										$elm$html$Html$video,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('video-preview'),
												$elm$html$Html$Attributes$controls(true)
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$source,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$src(videoUrl),
														$elm$html$Html$Attributes$type_('video/mp4')
													]),
												_List_Nil),
												$elm$html$Html$text('Seu browser não suporte exibição de vídeo HTML5')
											]));
								case 4:
									var depIdx = _v0.a;
									var fullStmt = sharedState.ia.h8;
									return A3($author$project$App$Page$Support$PageReevaluation$viewCPTContent, depIdx, fullStmt, now);
								default:
									return $elm$html$Html$text('');
							}
						}()
						]))
				]),
			_Utils_ap(
				A2($author$project$App$Page$Support$PageReevaluation$viewICDSection, sharedState, model),
				_List_fromArray(
					[dialogView])));
	});
var $author$project$App$Page$Support$PageReview$CancelReturnToCompany = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$Support$PageReview$ConfirmReturnToCompany = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$App$Page$Support$PageReview$InputObservationField = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $author$project$App$Page$Support$PageReview$dialogFromModel = F2(
	function (model, st) {
		var header = _List_Nil;
		var footer = function (filter) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('cancel-button'),
							$elm$html$Html$Events$onClick(
							$author$project$App$Page$Support$PageReview$CancelReturnToCompany(model))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Cancelar')
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							A2($author$project$App$Page$Support$PageReview$ConfirmReturnToCompany, model.as, filter))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Devolver')
						]))
				]);
		};
		var config = $author$project$App$Components$Dialog$emptyConfig('user-config');
		var body = function (filter) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$textarea,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$cols(50),
							$elm$html$Html$Attributes$rows(5),
							$elm$html$Html$Attributes$value(model.h8.g.e.jx),
							$elm$html$Html$Events$onInput(
							function (str) {
								return A3(
									$author$project$App$Page$Support$PageReview$InputObservationField,
									model.as,
									filter,
									$author$project$App$Data$Statement$FieldObservation(str));
							})
						]),
					_List_Nil)
				]);
		};
		var completeConfig = function (filter) {
			return _Utils_update(
				config,
				{
					e5: $elm$core$Maybe$Just(
						body(filter)),
					ft: $elm$core$Maybe$Just(
						footer(filter)),
					gY: $elm$core$Maybe$Just(header),
					f1: 'Motivo da Devolução'
				});
		};
		return model.dC ? $author$project$App$Components$Dialog$view(
			$elm$core$Maybe$Just(
				completeConfig(model.b))) : $elm$html$Html$text('');
	});
var $author$project$App$Page$Support$PageReview$Error = 2;
var $author$project$App$Page$Support$PageReview$Yes = 1;
var $author$project$App$Page$Support$PageReview$errorsDictIsEmpty = function (errors) {
	return !$elm$core$Dict$size(
		A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, fields) {
					return $elm$core$List$length(fields) > 0;
				}),
			errors));
};
var $author$project$App$Page$Support$PageReview$viewErrors = F2(
	function (st, model) {
		var _v0 = A2($author$project$App$Service$ApplicationResources$getNewFullStatementForCompanyResponse, model.gx, st._);
		if (_v0.$ === 1) {
			var _v1 = _v0.a;
			var specificError = _v1.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$App$Service$SpecificResources$errorToMessage(specificError))
					]));
		} else {
			return (!$author$project$App$Page$Support$PageReview$errorsDictIsEmpty(model.fp)) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('')
					])) : ((model.cH === 1) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Cadastrando atendimento...')
					])) : ((model.cH === 2) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('messages')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Ocorreu um erro ao tentar cadastrar o atendimento.')
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Tente novamente em alguns instantes.')
							]))
					])) : $elm$html$Html$text('')));
		}
	});
var $author$project$App$Page$Support$PageReview$GoToPending = {$: 2};
var $author$project$App$Page$Support$PageReview$ReturnToCompany = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$App$Page$Support$PageReview$Save = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Page$Support$PageReview$fullQuestionSorter = F2(
	function (a, b) {
		return A2($elm$core$Basics$compare, a.hG.bB, b.hG.bB);
	});
var $author$project$App$Page$Support$PageReview$getTemplateQuestions = F2(
	function (templateId, st) {
		var sections = st.ia.h8.bs;
		var questions = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					function (s) {
						return s.eI;
					},
					A2(
						$elm$core$List$filter,
						function (s) {
							return _Utils_eq(s.hX.eY, templateId);
						},
						sections))));
		return questions;
	});
var $author$project$App$Page$Support$PageReview$getAnswerForTemplate = F2(
	function (stmt, template) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Data$Answer$emptyModel,
			A2($elm$core$Dict$get, template.as, stmt.gw));
	});
var $author$project$App$Page$Support$PageReview$InputAnswerField = F5(
	function (a, b, c, d, e) {
		return {$: 7, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$App$Page$Support$PageReview$maskDecimal = F2(
	function (precision, value) {
		return A3($elm$core$String$replace, '.', ',', value);
	});
var $author$project$App$Page$Support$PageReview$maskHeight = function (cm) {
	var len = $elm$core$String$length(cm);
	var subM = (len === 1) ? ('0' + A2($elm$core$String$dropLeft, len - 2, cm)) : ((len > 2) ? A2($elm$core$String$dropLeft, len - 2, cm) : cm);
	var h = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(cm));
	var m = (h >= 100) ? A3($elm$core$String$slice, 0, -2, cm) : '0';
	return (h > 0) ? (m + (',' + subM)) : '';
};
var $author$project$App$Page$Support$PageReview$maskPressure = function (str) {
	return function (p) {
		return (p > 0) ? $elm$core$String$fromInt(p) : '';
	}(
		function (p) {
			return (p > 999) ? (p / 10) : (p / 1.0);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(str))) | 0);
};
var $author$project$App$Page$Support$PageReview$maskWeight = function (wStr) {
	return function (w) {
		return (w > 0) ? $elm$core$String$fromInt(w) : '';
	}(
		function (w) {
			return w / 1000;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(wStr))) | 0);
};
var $author$project$App$Page$Support$PageReview$storeDecimal = F2(
	function (precision, value) {
		var _v0 = $elm$regex$Regex$fromString('[^\\d]');
		if (!_v0.$) {
			var regex = _v0.a;
			var decimalPoints = precision * 10;
			var cleared = A3(
				$elm$regex$Regex$replace,
				regex,
				function (_v1) {
					return '';
				},
				value);
			var floatValue = A3(
				$elm$core$Basics$clamp,
				0,
				1000000000,
				A2(
					$elm$core$Maybe$withDefault,
					0,
					A2(
						$elm$core$Maybe$map,
						function (v) {
							return v / decimalPoints;
						},
						$elm$core$String$toFloat(cleared))));
			var intValue = A3($elm$core$Basics$clamp, 0, 1000000, floatValue | 0);
			var decimalValue = $elm$core$Basics$round((floatValue - intValue) * decimalPoints);
			var decimalValueStr = function (s) {
				return (_Utils_cmp(
					$elm$core$String$length(s),
					precision) > 0) ? A2(
					$elm$core$String$dropRight,
					$elm$core$String$length(s) - precision,
					s) : s;
			}(
				$elm$core$String$fromInt(decimalValue));
			return ((intValue > 0) || (decimalValue > 0)) ? ($elm$core$String$fromInt(intValue) + ('.' + decimalValueStr)) : '';
		} else {
			return '';
		}
	});
var $author$project$App$Page$Support$PageReview$storeHeight = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return function (h) {
			return (h > 0) ? $elm$core$String$fromInt(h) : '';
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A3(
						$elm$regex$Regex$replace,
						regex,
						function (_v1) {
							return '';
						},
						str))));
	} else {
		return '';
	}
};
var $author$project$App$Page$Support$PageReview$storePressure = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return function (p) {
			return (p > 0) ? $elm$core$String$fromInt(p) : '';
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A3(
						$elm$regex$Regex$replace,
						regex,
						function (_v1) {
							return '';
						},
						str))));
	} else {
		return '';
	}
};
var $author$project$App$Page$Support$PageReview$storeWeight = function (str) {
	var _v0 = $elm$regex$Regex$fromString('[^\\d]+');
	if (!_v0.$) {
		var regex = _v0.a;
		return function (w) {
			return (w > 0) ? $elm$core$String$fromInt(w) : '';
		}(
			1000 * A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A3(
						$elm$regex$Regex$replace,
						regex,
						function (_v1) {
							return '';
						},
						str))));
	} else {
		return '';
	}
};
var $author$project$App$Page$Support$PageReview$setupMask = F5(
	function (depIdx, fullQuestion, template, answer, attrs) {
		var _v0 = template.jj;
		if (_v0.$ === 1) {
			return attrs;
		} else {
			switch (_v0.a.$) {
				case 3:
					var precision = _v0.a.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							A2($author$project$App$Page$Support$PageReview$maskDecimal, precision, answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Support$PageReview$storeDecimal(precision),
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A4($author$project$App$Page$Support$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer))))
						]);
				case 0:
					var _v1 = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('number'),
							$elm$html$Html$Attributes$value(
							$author$project$App$Page$Support$PageReview$maskWeight(answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Support$PageReview$storeWeight,
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A4($author$project$App$Page$Support$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer))))
						]);
				case 1:
					var _v2 = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							$author$project$App$Page$Support$PageReview$maskHeight(answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Support$PageReview$storeHeight,
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A4($author$project$App$Page$Support$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer))))
						]);
				default:
					var _v3 = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							$author$project$App$Page$Support$PageReview$maskPressure(answer.T)),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$author$project$App$Page$Support$PageReview$storePressure,
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A4($author$project$App$Page$Support$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer))))
						]);
			}
		}
	});
var $author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeDate = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('review-answer')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						A5(
							$author$project$App$Page$Support$PageReview$setupMask,
							depIdx,
							fullQuestion,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('date'),
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A4($author$project$App$Page$Support$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer)))
								])),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeNumeric = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('review-answer')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						A5(
							$author$project$App$Page$Support$PageReview$setupMask,
							depIdx,
							fullQuestion,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('number'),
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A4($author$project$App$Page$Support$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer)))
								])),
						_List_Nil),
						$elm$html$Html$text(
						A2($elm$core$Maybe$withDefault, '', template.iv))
					]))
			]);
	});
var $author$project$App$Page$Support$PageReview$InputSingleChoice = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var $author$project$App$Page$Support$PageReview$onRadioChange = F2(
	function (depIdx, q) {
		return $elm$html$Html$Events$onInput(
			A2($author$project$App$Page$Support$PageReview$InputSingleChoice, depIdx, q));
	});
var $author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeOption = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('review-answer'),
						$elm$html$Html$Attributes$for(
						_Utils_ap(
							template.as,
							$elm$core$String$fromInt(
								A2($elm$core$Maybe$withDefault, -1, depIdx))))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(
								_Utils_ap(
									template.as,
									$elm$core$String$fromInt(
										A2($elm$core$Maybe$withDefault, -1, depIdx)))),
								$elm$html$Html$Attributes$name(
								_Utils_ap(
									template.as,
									$elm$core$String$fromInt(
										A2($elm$core$Maybe$withDefault, -1, depIdx)))),
								$elm$html$Html$Attributes$type_('radio'),
								$elm$html$Html$Attributes$checked(answer.dF),
								A2($author$project$App$Page$Support$PageReview$onRadioChange, depIdx, fullQuestion),
								$elm$html$Html$Attributes$value(template.as)
							]),
						_List_Nil),
						$elm$html$Html$text(template.jh)
					]))
			]);
	});
var $author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeOptionCheck = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$for(
						_Utils_ap(
							template.as,
							$elm$core$String$fromInt(
								A2($elm$core$Maybe$withDefault, -1, depIdx))))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(
								_Utils_ap(
									template.as,
									$elm$core$String$fromInt(
										A2($elm$core$Maybe$withDefault, -1, depIdx)))),
								$elm$html$Html$Attributes$type_('checkbox'),
								$elm$html$Html$Attributes$checked(answer.dF),
								$elm$html$Html$Events$onCheck(
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldSelectedBool,
									A4($author$project$App$Page$Support$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer))),
								$elm$html$Html$Attributes$value(template.as)
							]),
						_List_Nil),
						$elm$html$Html$text(template.jh)
					]))
			]);
	});
var $author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeOptionWithComplement = F4(
	function (depIdx, fullQuestion, template, answer) {
		var placeholderText = answer.dF ? 'Sua resposta aqui' : '';
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('review-answer'),
						$elm$html$Html$Attributes$for(
						_Utils_ap(
							template.as,
							$elm$core$String$fromInt(
								A2($elm$core$Maybe$withDefault, -1, depIdx))))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(
								_Utils_ap(
									template.as,
									$elm$core$String$fromInt(
										A2($elm$core$Maybe$withDefault, -1, depIdx)))),
								$elm$html$Html$Attributes$name(
								_Utils_ap(
									template.as,
									$elm$core$String$fromInt(
										A2($elm$core$Maybe$withDefault, -1, depIdx)))),
								$elm$html$Html$Attributes$type_('radio'),
								$elm$html$Html$Attributes$checked(answer.dF),
								A2($author$project$App$Page$Support$PageReview$onRadioChange, depIdx, fullQuestion),
								$elm$html$Html$Attributes$value(template.as)
							]),
						_List_Nil),
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$placeholder(placeholderText),
								$elm$html$Html$Attributes$type_('text'),
								$elm$html$Html$Events$onInput(
								A2(
									$elm$core$Basics$composeR,
									$author$project$App$Data$Answer$FieldValue,
									A4($author$project$App$Page$Support$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer))),
								$elm$html$Html$Attributes$value(answer.T)
							]),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeString = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('review-answer')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$input,
						A5(
							$author$project$App$Page$Support$PageReview$setupMask,
							depIdx,
							fullQuestion,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('text'),
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A4($author$project$App$Page$Support$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer)))
								])),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeTableRowDesktop = F4(
	function (depIdx, fullQuestion, template, answer) {
		var theadContent = $elm$core$List$reverse(
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$th,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'role', 'columnheader')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Ações')
							]))
					]),
				$elm$core$List$reverse(
					A2(
						$elm$core$List$map,
						function (l) {
							return A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'role', 'columnheader')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(l)
									]));
						},
						A2($elm$core$String$split, ',', template.jh)))));
		var tableCell = F3(
			function (idx, header, v) {
				return A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'role', 'cell')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$placeholder(header),
									$elm$html$Html$Attributes$type_('text'),
									$elm$html$Html$Attributes$value(v),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										A2($author$project$App$Data$Answer$FieldTableRow, idx, header),
										A4($author$project$App$Page$Support$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer)))
								]),
							_List_Nil)
						]));
			});
		var tableRow = function (_v1) {
			var idx = _v1.a;
			var row = _v1.b;
			return A2(
				$elm$html$Html$tr,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'row')
					]),
				$elm$core$List$reverse(
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'role', 'cell')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick(
												A5(
													$author$project$App$Page$Support$PageReview$InputAnswerField,
													depIdx,
													template.as,
													answer.aV,
													answer,
													$author$project$App$Data$Answer$FieldTableRowRemove(idx)))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Remover')
											]))
									]))
							]),
						$elm$core$List$reverse(
							A2(
								$elm$core$List$map,
								function (header) {
									return A3(
										tableCell,
										idx,
										header,
										A2(
											$elm$core$Maybe$withDefault,
											'',
											A2($elm$core$Dict$get, header, row)));
								},
								A2($elm$core$String$split, ',', template.jh))))));
		};
		var tbodyContent = function () {
			var _v0 = answer.G;
			if (_v0.$ === 1) {
				return _List_Nil;
			} else {
				var tableRows = _v0.a;
				return A2(
					$elm$core$List$map,
					tableRow,
					$elm$core$Dict$toList(tableRows));
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$thead,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'rowgroup')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tr,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'role', 'row')
							]),
						theadContent)
					])),
				A2(
				$elm$html$Html$tbody,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'rowgroup')
					]),
				tbodyContent)
			]);
	});
var $author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeTextBox = F4(
	function (depIdx, fullQuestion, template, answer) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'flex-direction', 'column'),
						A2($elm$html$Html$Attributes$style, 'padding-top', '20px')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(template.jh),
						A2(
						$elm$html$Html$textarea,
						A5(
							$author$project$App$Page$Support$PageReview$setupMask,
							depIdx,
							fullQuestion,
							template,
							answer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(answer.T),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeR,
										$author$project$App$Data$Answer$FieldValue,
										A4($author$project$App$Page$Support$PageReview$InputAnswerField, depIdx, template.as, answer.aV, answer))),
									$elm$html$Html$Attributes$class('text-box'),
									A2($elm$html$Html$Attributes$style, 'min-width', '400px')
								])),
						_List_Nil)
					]))
			]);
	});
var $author$project$App$Page$Support$PageReview$viewAnswerTemplate = F5(
	function (depIdx, stmt, fullQuestion, template, answer) {
		var _v0 = template.j5;
		switch (_v0) {
			case 0:
				return _List_Nil;
			case 2:
				return A4($author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeString, depIdx, fullQuestion, template, answer);
			case 3:
				return A4($author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeNumeric, depIdx, fullQuestion, template, answer);
			case 5:
				return A4($author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeDate, depIdx, fullQuestion, template, answer);
			case 1:
				return A4($author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeOption, depIdx, fullQuestion, template, answer);
			case 6:
				return A4($author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeOptionCheck, depIdx, fullQuestion, template, answer);
			case 4:
				return A4($author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeOptionWithComplement, depIdx, fullQuestion, template, answer);
			case 7:
				return A4($author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeTableRowDesktop, depIdx, fullQuestion, template, answer);
			default:
				return A4($author$project$App$Page$Support$PageReview$viewAnswerTemplateTypeTextBox, depIdx, fullQuestion, template, answer);
		}
	});
var $author$project$App$Page$Support$PageReview$viewToggleWithComplement = F5(
	function (depIdx, fullQuestion, yesTemplate, noTemplate, stmt) {
		var yesAnswer = A2($author$project$App$Page$Support$PageReview$getAnswerForTemplate, stmt, yesTemplate);
		var otherTemplate = yesAnswer.dF ? noTemplate : yesTemplate;
		var noAnswer = A2($author$project$App$Page$Support$PageReview$getAnswerForTemplate, stmt, noTemplate);
		var otherAnswer = yesAnswer.dF ? noAnswer : yesAnswer;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('toggle')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$name(
								$elm$core$String$fromInt(fullQuestion.hG.bB) + ('.' + $elm$core$String$fromInt(
									A2($elm$core$Maybe$withDefault, -1, depIdx)))),
								$elm$html$Html$Attributes$type_('checkbox'),
								$elm$html$Html$Events$onClick(
								A3($author$project$App$Page$Support$PageReview$InputSingleChoice, depIdx, fullQuestion, otherTemplate.as)),
								$elm$html$Html$Attributes$checked(yesAnswer.dF)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('toggle-slider')
							]),
						_List_Nil)
					])),
				A2($elm$html$Html$br, _List_Nil, _List_Nil),
				yesAnswer.dF ? A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(yesAnswer.T),
						$elm$html$Html$Events$onInput(
						A2(
							$elm$core$Basics$composeR,
							$author$project$App$Data$Answer$FieldValue,
							A4($author$project$App$Page$Support$PageReview$InputAnswerField, depIdx, yesTemplate.as, yesAnswer.aV, yesAnswer)))
					]),
				_List_Nil) : $elm$html$Html$text('')
			]);
	});
var $author$project$App$Page$Support$PageReview$viewQuestionCommon = F3(
	function (depIdx, stmt, fullQuestion) {
		var isToggleWithComplement = ($elm$core$List$length(fullQuestion.bY) === 2) && (A2(
			$elm$core$List$any,
			function (t) {
				return t.j5 === 4;
			},
			fullQuestion.bY) && A2(
			$elm$core$List$any,
			function (t) {
				return t.j5 === 1;
			},
			fullQuestion.bY));
		var templates = function () {
			if (isToggleWithComplement) {
				var yesTemplate = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$AnswerTemplate$emptyModel,
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (t) {
								return t.j5 === 4;
							},
							fullQuestion.bY)));
				var noTemplate = A2(
					$elm$core$Maybe$withDefault,
					$author$project$App$Data$AnswerTemplate$emptyModel,
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (t) {
								return t.j5 === 1;
							},
							fullQuestion.bY)));
				return A5($author$project$App$Page$Support$PageReview$viewToggleWithComplement, depIdx, fullQuestion, yesTemplate, noTemplate, stmt);
			} else {
				return $elm$core$List$concat(
					A2(
						$elm$core$List$map,
						function (t) {
							return A5(
								$author$project$App$Page$Support$PageReview$viewAnswerTemplate,
								depIdx,
								stmt,
								fullQuestion,
								t,
								A2($author$project$App$Page$Support$PageReview$getAnswerForTemplate, stmt, t));
						},
						fullQuestion.bY));
			}
		}();
		return A2(
			$elm$html$Html$td,
			_List_Nil,
			_Utils_ap(_List_Nil, templates));
	});
var $author$project$App$Page$Support$PageReview$viewAnswer = F5(
	function (mAnswer, fullQuestion, stmt, stmtId, depIdx) {
		if (!mAnswer.$) {
			var answer = mAnswer.a;
			var _v1 = fullQuestion.hG.j5;
			switch (_v1) {
				case 0:
					return A2($elm$html$Html$td, _List_Nil, _List_Nil);
				case 1:
					return A3($author$project$App$Page$Support$PageReview$viewQuestionCommon, depIdx, stmt, fullQuestion);
				case 2:
					return A3($author$project$App$Page$Support$PageReview$viewQuestionCommon, depIdx, stmt, fullQuestion);
				case 3:
					return A3($author$project$App$Page$Support$PageReview$viewQuestionCommon, depIdx, stmt, fullQuestion);
				case 4:
					return A2($elm$html$Html$td, _List_Nil, _List_Nil);
				default:
					return A3($author$project$App$Page$Support$PageReview$viewQuestionCommon, depIdx, stmt, fullQuestion);
			}
		} else {
			return A2($elm$html$Html$td, _List_Nil, _List_Nil);
		}
	});
var $author$project$App$Page$Support$PageReview$viewQuestion = F3(
	function (fullQuestion, stmt, deps) {
		var templates = fullQuestion.bY;
		var answers = A2(
			$elm$core$List$map,
			function (t) {
				return A2($elm$core$Dict$get, t.as, stmt.gw);
			},
			templates);
		var bearerAnswers = (stmt.e.j_ === 2) ? $elm$html$Html$text('') : A5(
			$author$project$App$Page$Support$PageReview$viewAnswer,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Maybe$Nothing,
				$elm$core$List$head(answers)),
			fullQuestion,
			stmt,
			stmt.e.as,
			$elm$core$Maybe$Nothing);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_Utils_ap(
				$elm$core$List$concat(
					_List_fromArray(
						[
							_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(fullQuestion.hG.bB) + ('. ' + fullQuestion.hG.bP))
									])),
								bearerAnswers
							])
						])),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var v = _v0.b;
						return v;
					},
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$map,
							F2(
								function (i, dep) {
									var depAnswer = A2(
										$elm$core$List$map,
										function (t) {
											return A2($elm$core$Dict$get, t.as, dep.gw);
										},
										templates);
									return A5(
										$author$project$App$Page$Support$PageReview$viewAnswer,
										A2(
											$elm$core$Maybe$withDefault,
											$elm$core$Maybe$Nothing,
											$elm$core$List$head(depAnswer)),
										fullQuestion,
										dep,
										dep.e.as,
										$elm$core$Maybe$Just(i));
								}),
							deps)))));
	});
var $author$project$App$Page$Support$PageReview$viewQuestions = F2(
	function (fullStmt, st) {
		var stmt = fullStmt.g;
		var questions = A2($author$project$App$Page$Support$PageReview$getTemplateQuestions, fullStmt.g.e.eY, st);
		var bearerHeader = ((stmt.e.j_ === 2) || (!st.ia.h8.cJ.gX)) ? $elm$html$Html$text('') : A2(
			$elm$html$Html$th,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Titular'),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					$elm$html$Html$text(fullStmt.g.dw.hl)
				]));
		return A2(
			$elm$html$Html$table,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$colgroup,
					_List_Nil,
					$elm$core$List$concat(
						_List_fromArray(
							[
								_List_fromArray(
								[
									A2($elm$html$Html$col, _List_Nil, _List_Nil),
									A2($elm$html$Html$col, _List_Nil, _List_Nil)
								]),
								A2(
								$elm$core$List$map,
								function (_v2) {
									var v = _v2.b;
									return v;
								},
								$elm$core$Dict$toList(
									A2(
										$elm$core$Dict$map,
										F2(
											function (_v0, _v1) {
												return A2($elm$html$Html$col, _List_Nil, _List_Nil);
											}),
										fullStmt.fh)))
							]))),
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							$elm$core$List$concat(
								_List_fromArray(
									[
										_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Perguntas')
												])),
											bearerHeader
										]),
										A2(
										$elm$core$List$map,
										function (_v3) {
											var v = _v3.b;
											return v;
										},
										$elm$core$Dict$toList(
											A2(
												$elm$core$Dict$map,
												F2(
													function (i, dep) {
														return A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	'Dep ' + $elm$core$String$fromInt(i + 1)),
																	A2($elm$html$Html$br, _List_Nil, _List_Nil),
																	$elm$html$Html$text(dep.dw.hl)
																]));
													}),
												fullStmt.fh)))
									])))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (q) {
							return A3($author$project$App$Page$Support$PageReview$viewQuestion, q, stmt, fullStmt.fh);
						},
						A2($elm$core$List$sortWith, $author$project$App$Page$Support$PageReview$fullQuestionSorter, questions)))
				]));
	});
var $author$project$App$Page$Support$PageReview$viewStatementQuestions = F3(
	function (filter, model, st) {
		return A2(
			$elm$html$Html$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$header,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Revisando Cadastro')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('action-buttons')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick(
											A2($author$project$App$Page$Support$PageReview$ReturnToCompany, model.as, filter))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Devolver')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick($author$project$App$Page$Support$PageReview$GoToPending)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Cancelar')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Events$onClick(
											$author$project$App$Page$Support$PageReview$Save(model.h8.g.e.as))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Aprovar')
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('questions review')
						]),
					_List_fromArray(
						[
							A2($author$project$App$Page$Support$PageReview$viewQuestions, model.h8, st)
						]))
				]));
	});
var $author$project$App$Page$Support$PageReview$view = F2(
	function (st, model) {
		var viewDetails = function () {
			var _v1 = model.gD;
			if (_v1.$ === 1) {
				var viewFullStmt = _v1.b;
				return A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$Page$Support$PageReview$ViewFullStmtMsg,
							A2($author$project$App$Components$ViewFullStatement$view, st, viewFullStmt))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		var dialogView = A2($author$project$App$Page$Support$PageReview$dialogFromModel, model, st);
		var _v0 = _Utils_Tuple2('Revisar Atendimento', 'Atualizar');
		var headerTitle = _v0.a;
		var actionText = _v0.b;
		return _List_fromArray(
			[
				A2($author$project$App$Page$Support$PageReview$viewErrors, st, model),
				A3($author$project$App$Page$Support$PageReview$viewStatementQuestions, model.b, model, st),
				viewDetails,
				dialogView
			]);
	});
var $author$project$App$Page$PageSupport$view = F3(
	function (now, st, model) {
		switch (model.$) {
			case 0:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageSupport$ListIncompleteMsg),
					A2($author$project$App$Page$Support$PageListIncomplete$view, st, pageModel));
			case 1:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageSupport$ListForReviewMsg),
					A2($author$project$App$Page$Support$PageListForReview$view, st, pageModel));
			case 2:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageSupport$ListApprovedMsg),
					A2($author$project$App$Page$Support$PageListApproved$view, st, pageModel));
			case 3:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageSupport$ReviewInterviewMsg),
					A2($author$project$App$Page$Support$PageReview$view, st, pageModel));
			case 4:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageSupport$EditStatementMsg),
					A2($author$project$App$Page$Support$EditStatement$StatementEdit$view, st, pageModel));
			case 6:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageSupport$ListReevaluationMsg),
					A2($author$project$App$Page$Support$PageListReevaluation$view, st, pageModel));
			default:
				var pageModel = model.a;
				return A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Page$PageSupport$ReevaluationMsg),
					A3($author$project$App$Page$Support$PageReevaluation$view, now, st, pageModel));
		}
	});
var $author$project$App$Page$PageUserRecoverPassword$GoToLogin = {$: 2};
var $author$project$App$Page$PageUserRecoverPassword$ResetPassword = {$: 1};
var $author$project$App$Page$PageUserRecoverPassword$UpdateUserField = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$PageUserRecoverPassword$loadingView = function (status) {
	switch (status.$) {
		case 0:
			return $elm$html$Html$text('');
		case 1:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-message')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Existem campos inválidos')
					]));
		case 2:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('loading')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Enviando dados...')
					]));
		case 4:
			var error = status.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-message')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(error)
					]));
		default:
			var message = status.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('success-message-with-color')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(message)
					]));
	}
};
var $author$project$App$Page$PageUserRecoverPassword$InvalidField = {$: 1};
var $author$project$App$Page$PageUserRecoverPassword$showClassError = F2(
	function (status, isInvalid) {
		return $elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'error-message',
					_Utils_eq(status, $author$project$App$Page$PageUserRecoverPassword$InvalidField) && isInvalid)
				]));
	});
var $author$project$App$Page$PageUserRecoverPassword$view = function (_v0) {
	var user = _v0.bS;
	var status = _v0.aw;
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('signup authentication selfie')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('logo'),
											$elm$html$Html$Attributes$src('/assets/images/logo_color.svg')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('\n                    Atualize a sua senha.\n                ')
								])),
							A2(
							$elm$html$Html$form,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Senha'),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$placeholder(''),
													$elm$html$Html$Attributes$type_('password'),
													$elm$html$Html$Attributes$name('password'),
													$elm$html$Html$Attributes$value(user.bf),
													A2(
													$author$project$App$Page$PageUserRecoverPassword$showClassError,
													status,
													$author$project$App$Data$User$isInvalidFieldPassword(user.bf)),
													$elm$html$Html$Events$onInput(
													A2($elm$core$Basics$composeL, $author$project$App$Page$PageUserRecoverPassword$UpdateUserField, $author$project$App$Data$User$FieldPassword))
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Confirmar senha'),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$placeholder(''),
													$elm$html$Html$Attributes$type_('password'),
													$elm$html$Html$Attributes$name('passworkCheck'),
													$elm$html$Html$Attributes$value(user.dv),
													A2(
													$author$project$App$Page$PageUserRecoverPassword$showClassError,
													status,
													A2($author$project$App$Data$User$isInvalidFieldPasswordCheck, user.bf, user.dv)),
													$elm$html$Html$Events$onInput(
													A2($elm$core$Basics$composeL, $author$project$App$Page$PageUserRecoverPassword$UpdateUserField, $author$project$App$Data$User$FieldPasswordCheck))
												]),
											_List_Nil)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('actions')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick($author$project$App$Page$PageUserRecoverPassword$ResetPassword)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Atualizar senha')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Events$onClick($author$project$App$Page$PageUserRecoverPassword$GoToLogin)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Cancelar')
										]))
								])),
							$author$project$App$Page$PageUserRecoverPassword$loadingView(status)
						]))
				]))
		]);
};
var $author$project$App$Page$PageUserRequestRecoverPassword$GoToLogin = {$: 2};
var $author$project$App$Page$PageUserRequestRecoverPassword$RequestChangePassword = {$: 1};
var $author$project$App$Page$PageUserRequestRecoverPassword$UpdateUserField = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$PageUserRequestRecoverPassword$loadingView = function (status) {
	switch (status.$) {
		case 0:
			return $elm$html$Html$text('');
		case 1:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-message')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Existem campos inválidos')
					]));
		case 2:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('loading')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Enviando dados...')
					]));
		case 3:
			var error = status.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-message')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(error)
					]));
		default:
			var message = status.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('success-message-with-color')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(message)
					]));
	}
};
var $author$project$App$Page$PageUserRequestRecoverPassword$InvalidField = {$: 1};
var $author$project$App$Page$PageUserRequestRecoverPassword$showClassError = F2(
	function (status, isInvalid) {
		return $elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'error-message',
					_Utils_eq(status, $author$project$App$Page$PageUserRequestRecoverPassword$InvalidField) && isInvalid)
				]));
	});
var $author$project$App$Page$PageUserRequestRecoverPassword$view = function (_v0) {
	var user = _v0.bS;
	var status = _v0.aw;
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('signup authentication selfie')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('logo'),
											$elm$html$Html$Attributes$src('/assets/images/logo_color.svg')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Recuperar a senha')
										])),
									A2($elm$html$Html$br, _List_Nil, _List_Nil),
									$elm$html$Html$text('\n                  Após clicar em \'recuperar conta\', enviaremos um e-mail para você trocar a senha e recuperar a conta.\n                ')
								])),
							A2(
							$elm$html$Html$form,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('e-mail'),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$placeholder(''),
													$elm$html$Html$Attributes$type_('email'),
													$elm$html$Html$Attributes$name('email'),
													$elm$html$Html$Attributes$value(user.c7),
													A2(
													$author$project$App$Page$PageUserRequestRecoverPassword$showClassError,
													status,
													$author$project$App$Data$User$isInvalidFieldEmail(user.c7)),
													$elm$html$Html$Events$onInput(
													A2($elm$core$Basics$composeL, $author$project$App$Page$PageUserRequestRecoverPassword$UpdateUserField, $author$project$App$Data$User$FieldEmail))
												]),
											_List_Nil)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('actions')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick($author$project$App$Page$PageUserRequestRecoverPassword$RequestChangePassword)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Recuperar conta')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Events$onClick($author$project$App$Page$PageUserRequestRecoverPassword$GoToLogin)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Cancelar')
										]))
								])),
							$author$project$App$Page$PageUserRequestRecoverPassword$loadingView(status)
						]))
				]))
		]);
};
var $author$project$App$Data$User$FieldCompanyID = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Page$PageUserSignUp$GoToLogin = {$: 4};
var $author$project$App$Page$PageUserSignUp$SignUpUser = {$: 3};
var $author$project$App$Page$PageUserSignUp$UpdatePersonField = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Page$PageUserSignUp$UpdatePhoneField = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Page$PageUserSignUp$UpdateUserField = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Page$PageUserSignUp$loadingView = function (status) {
	switch (status.$) {
		case 0:
			return $elm$html$Html$text('');
		case 1:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-message')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Existem campos inválidos')
					]));
		case 2:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('loading')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Enviando dados...')
					]));
		default:
			var error = status.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-message')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(error)
					]));
	}
};
var $author$project$App$Page$PageUserSignUp$InvalidField = {$: 1};
var $author$project$App$Page$PageUserSignUp$showClassError = F2(
	function (status, isInvalid) {
		return $elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'error-message',
					_Utils_eq(status, $author$project$App$Page$PageUserSignUp$InvalidField) && isInvalid)
				]));
	});
var $author$project$App$Page$PageUserSignUp$view = function (_v0) {
	var user = _v0.bS;
	var person = _v0.dw;
	var status = _v0.aw;
	var phone = _v0.dx;
	var companies = _v0.bq;
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('signup authentication selfie')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('logo'),
											$elm$html$Html$Attributes$src('/assets/images/logo_color.svg')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Bem vindo à '),
									A2(
									$elm$html$Html$b,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Medsyn!')
										])),
									A2($elm$html$Html$br, _List_Nil, _List_Nil),
									$elm$html$Html$text('\n                    O cadastro abaixo é indicado para usuários dos sistemas da Medsyn. Para ter acesso,\n                    complete os campos abaixo e envie, nós analisaremos e faremos a liberação assim que\n                    possível.\n                ')
								])),
							A2(
							$elm$html$Html$form,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Empresa'),
											A5(
											$author$project$App$Tools$FormHelpers$selectFromCollection,
											_List_fromArray(
												[
													A2(
													$author$project$App$Page$PageUserSignUp$showClassError,
													status,
													!_Utils_eq(user.gx, $elm$core$Maybe$Nothing))
												]),
											A2($elm$core$Maybe$withDefault, '', user.gx),
											companies,
											function (t) {
												return _Utils_Tuple2(t.as, t.iR);
											},
											A2($elm$core$Basics$composeL, $author$project$App$Page$PageUserSignUp$UpdateUserField, $author$project$App$Data$User$FieldCompanyID))
										])),
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('CPF'),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$placeholder(''),
													$elm$html$Html$Attributes$type_('text'),
													$elm$html$Html$Attributes$name('document'),
													$elm$html$Html$Attributes$value(
													$author$project$Mask$cpf(person.i1)),
													$elm$html$Html$Events$onInput(
													A2($elm$core$Basics$composeL, $author$project$App$Page$PageUserSignUp$UpdatePersonField, $author$project$App$Data$Person$FieldDocument)),
													A2(
													$author$project$App$Page$PageUserSignUp$showClassError,
													status,
													$author$project$App$Data$Person$isDocumentValid(person.i1))
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Nome'),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$placeholder(''),
													$elm$html$Html$Attributes$type_('text'),
													$elm$html$Html$Attributes$name('name'),
													$elm$html$Html$Attributes$value(person.hl),
													$elm$html$Html$Events$onInput(
													A2($elm$core$Basics$composeL, $author$project$App$Page$PageUserSignUp$UpdatePersonField, $author$project$App$Data$Person$FieldName)),
													A2(
													$author$project$App$Page$PageUserSignUp$showClassError,
													status,
													!$elm$core$String$isEmpty(person.hl))
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Sobrenome'),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$placeholder(''),
													$elm$html$Html$Attributes$type_('text'),
													$elm$html$Html$Attributes$name('name'),
													$elm$html$Html$Attributes$value(person.ii),
													$elm$html$Html$Events$onInput(
													A2($elm$core$Basics$composeL, $author$project$App$Page$PageUserSignUp$UpdatePersonField, $author$project$App$Data$Person$FieldSurname)),
													A2(
													$author$project$App$Page$PageUserSignUp$showClassError,
													status,
													!$elm$core$String$isEmpty(person.ii))
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Sexo'),
											A4(
											$author$project$App$Tools$FormHelpers$select,
											_List_fromArray(
												[
													A2(
													$author$project$App$Page$PageUserSignUp$showClassError,
													status,
													!$elm$core$String$isEmpty(
														$author$project$App$Data$Person$sexToString(person.dJ)))
												]),
											$author$project$App$Data$Person$sexToString(person.dJ),
											A2($elm$core$Basics$composeL, $author$project$App$Page$PageUserSignUp$UpdatePersonField, $author$project$App$Data$Person$FieldSex),
											_List_fromArray(
												[
													_Utils_Tuple2('', 'Selecione'),
													_Utils_Tuple2('M', 'Masculino'),
													_Utils_Tuple2('F', 'Feminino')
												]))
										])),
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Data de nascimento'),
											A3(
											$author$project$App$Tools$FormHelpers$dateInputWithAttrs,
											_List_fromArray(
												[
													A2(
													$author$project$App$Page$PageUserSignUp$showClassError,
													status,
													!(!$elm$time$Time$posixToMillis(person.al)))
												]),
											person.al,
											A2($elm$core$Basics$composeL, $author$project$App$Page$PageUserSignUp$UpdatePersonField, $author$project$App$Data$Person$FieldBirthDate))
										])),
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Telefone'),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$placeholder(''),
													$elm$html$Html$Attributes$type_('tel'),
													$elm$html$Html$Attributes$name('phone'),
													$elm$html$Html$Attributes$value(
													$author$project$App$Data$Contact$formatMobile(phone.T)),
													A2(
													$author$project$App$Page$PageUserSignUp$showClassError,
													status,
													$author$project$App$Data$Contact$isInvalidFieldMobile(phone.T)),
													$elm$html$Html$Events$onInput(
													A2($elm$core$Basics$composeL, $author$project$App$Page$PageUserSignUp$UpdatePhoneField, $author$project$App$Data$Contact$FieldValue))
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('e-mail'),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$placeholder(''),
													$elm$html$Html$Attributes$type_('email'),
													$elm$html$Html$Attributes$name('email'),
													$elm$html$Html$Attributes$value(user.c7),
													A2(
													$author$project$App$Page$PageUserSignUp$showClassError,
													status,
													$author$project$App$Data$User$isInvalidFieldEmail(user.c7)),
													$elm$html$Html$Events$onInput(
													A2($elm$core$Basics$composeL, $author$project$App$Page$PageUserSignUp$UpdateUserField, $author$project$App$Data$User$FieldEmail))
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Senha'),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$placeholder(''),
													$elm$html$Html$Attributes$type_('password'),
													$elm$html$Html$Attributes$name('password'),
													$elm$html$Html$Attributes$value(user.bf),
													A2(
													$author$project$App$Page$PageUserSignUp$showClassError,
													status,
													$author$project$App$Data$User$isInvalidFieldPassword(user.bf)),
													$elm$html$Html$Events$onInput(
													A2($elm$core$Basics$composeL, $author$project$App$Page$PageUserSignUp$UpdateUserField, $author$project$App$Data$User$FieldPassword))
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$label,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Confirmar senha'),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$placeholder(''),
													$elm$html$Html$Attributes$type_('password'),
													$elm$html$Html$Attributes$name('passworkCheck'),
													$elm$html$Html$Attributes$value(user.dv),
													A2(
													$author$project$App$Page$PageUserSignUp$showClassError,
													status,
													A2($author$project$App$Data$User$isInvalidFieldPasswordCheck, user.bf, user.dv)),
													$elm$html$Html$Events$onInput(
													A2($elm$core$Basics$composeL, $author$project$App$Page$PageUserSignUp$UpdateUserField, $author$project$App$Data$User$FieldPasswordCheck))
												]),
											_List_Nil)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('actions')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick($author$project$App$Page$PageUserSignUp$SignUpUser)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Criar')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cancel-button'),
											$elm$html$Html$Events$onClick($author$project$App$Page$PageUserSignUp$GoToLogin)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Cancelar')
										]))
								])),
							$author$project$App$Page$PageUserSignUp$loadingView(status)
						]))
				]))
		]);
};
var $author$project$App$Page$PageUserSignUpComplete$GoToLogin = 0;
var $author$project$App$Page$PageUserSignUpComplete$view = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('authentication')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('logo'),
											$elm$html$Html$Attributes$src('/assets/images/logo_color.svg')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Seu cadastro foi efetuado com sucesso. Faremos a avaliação e entraremos em contato ' + 'para liberar seu acesso.')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('actions')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(0)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Retornar')
										]))
								]))
						]))
				]))
		]);
};
var $author$project$App$SharedState$MenuCmd = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Page$Menu$profileMenus = function (profile) {
	var _v0 = profile.gu;
	switch (_v0) {
		case 0:
			return _List_fromArray(
				[
					$author$project$App$Page$Menu$DoctorMenu(0),
					$author$project$App$Page$Menu$DoctorMenu(1),
					$author$project$App$Page$Menu$DoctorMenu(3),
					$author$project$App$Page$Menu$DoctorMenu(2),
					$author$project$App$Page$Menu$DoctorMenu(4)
				]);
		case 1:
			return _List_fromArray(
				[
					$author$project$App$Page$Menu$CompanyMenu(0),
					$author$project$App$Page$Menu$CompanyMenu(2),
					$author$project$App$Page$Menu$CompanyMenu(1),
					$author$project$App$Page$Menu$CompanyMenu(3)
				]);
		case 2:
			return _List_fromArray(
				[
					$author$project$App$Page$Menu$ReviewMenu(0),
					$author$project$App$Page$Menu$ReviewMenu(1),
					$author$project$App$Page$Menu$ReviewMenu(2),
					$author$project$App$Page$Menu$ReviewMenu(3)
				]);
		case 5:
			return _List_fromArray(
				[
					$author$project$App$Page$Menu$AdminMenu(0),
					$author$project$App$Page$Menu$AdminMenu(1),
					$author$project$App$Page$Menu$AdminMenu(2),
					$author$project$App$Page$Menu$AdminMenu(3)
				]);
		default:
			return _List_Nil;
	}
};
var $author$project$App$Page$Menu$userMenus = function (profiles) {
	return $elm$core$List$concat(
		A2($elm$core$List$map, $author$project$App$Page$Menu$profileMenus, profiles));
};
var $author$project$App$Page$Menu$ChangeMenu = $elm$core$Basics$identity;
var $author$project$App$Page$Menu$viewMenu = F2(
	function (currentMenu, menu) {
		switch (menu.$) {
			case 1:
				switch (menu.a) {
					case 0:
						var _v1 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Fila de Atendimento'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/menus/waiting_icon.svg')
												]),
											_List_Nil)
										]))
								]));
					case 1:
						var _v2 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Atendimentos pendentes'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/menus/pending_icon.svg')
												]),
											_List_Nil)
										]))
								]));
					case 3:
						var _v3 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Atendimentos finalizados'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/menus/user_completed.svg')
												]),
											_List_Nil)
										]))
								]));
					case 2:
						var _v4 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Pendentes para reavaliação'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/menus/user_approved.svg')
												]),
											_List_Nil)
										]))
								]));
					default:
						var _v5 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Relatório'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/exportar-csv-ico-white.svg')
												]),
											_List_Nil)
										]))
								]));
				}
			case 2:
				switch (menu.a) {
					case 0:
						var _v6 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Atendimentos em Andamento'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/menus/waiting_icon.svg')
												]),
											_List_Nil)
										]))
								]));
					case 1:
						var _v7 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Atendimentos Cancelados'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/menus/user_cancelled.svg')
												]),
											_List_Nil)
										]))
								]));
					case 2:
						var _v8 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Atendimentos Concluídos'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/menus/user_approved.svg')
												]),
											_List_Nil)
										]))
								]));
					default:
						var _v9 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Pendentes para reavaliação'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/menus/pending_icon.svg')
												]),
											_List_Nil)
										]))
								]));
				}
			case 3:
				switch (menu.a) {
					case 0:
						var _v10 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Atendimentos para revisar'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/menus/waiting_icon.svg')
												]),
											_List_Nil)
										]))
								]));
					case 1:
						var _v11 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Atendimentos incompletos'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/menus/user_cancelled.svg')
												]),
											_List_Nil)
										]))
								]));
					case 2:
						var _v12 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Atendimentos finalizados'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/menus/user_approved.svg')
												]),
											_List_Nil)
										]))
								]));
					default:
						var _v13 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Pendentes para reavaliação'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/menus/pending_icon.svg')
												]),
											_List_Nil)
										]))
								]));
				}
			case 4:
				switch (menu.a) {
					case 0:
						var _v14 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Relatório'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/exportar-csv-ico-white.svg')
												]),
											_List_Nil)
										]))
								]));
					case 1:
						var _v15 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Contas Para Ativar'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/menus/user_approved.svg')
												]),
											_List_Nil)
										]))
								]));
					case 2:
						var _v16 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Atendimentos finalizados'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/menus/user_completed.svg')
												]),
											_List_Nil)
										]))
								]));
					default:
						var _v17 = menu.a;
						return A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'selected',
											_Utils_eq(menu, currentMenu))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#'),
											$elm$html$Html$Attributes$title('Pendentes para reavaliação'),
											$elm$html$Html$Events$onClick(menu)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/images/menus/pending_icon.svg')
												]),
											_List_Nil)
										]))
								]));
				}
			default:
				return $elm$html$Html$text('');
		}
	});
var $author$project$App$Page$Menu$view = function (model) {
	return A2(
		$elm$html$Html$nav,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_Nil,
				A2(
					$elm$core$List$map,
					$author$project$App$Page$Menu$viewMenu(model.ec),
					$author$project$App$Page$Menu$userMenus(model.fN)))
			]));
};
var $author$project$App$SharedState$viewMenus = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$html$Html$text(''),
		A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				$author$project$App$Page$Menu$view,
				$elm$html$Html$map($author$project$App$SharedState$MenuCmd)),
			model.ba));
};
var $author$project$App$SharedState$ChangeUserInput = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$SharedState$DoLogout = {$: 0};
var $author$project$App$Data$User$FieldPasswordOld = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$SharedState$SubmitChangePassword = {$: 2};
var $author$project$App$SharedState$ToggleChangeProfile = {$: 1};
var $author$project$App$SharedState$viewError = function (string) {
	var str = function () {
		switch (string) {
			case 'Access denied':
				return 'Senha antiga incorreta';
			case 'Senhas não coincidem':
				return 'Senhas não coincidem';
			default:
				return 'Erro desconhecido';
		}
	}();
	return A2(
		$elm$html$Html$label,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('error-message')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $author$project$App$SharedState$viewLoading = A2(
	$elm$html$Html$label,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('loading')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('Carregando...')
		]));
var $author$project$App$SharedState$viewSuccess = function (string) {
	return A2(
		$elm$html$Html$label,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('success-message')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(string)
			]));
};
var $author$project$App$SharedState$viewLoadingOrError = function (model) {
	var _v0 = model.b6;
	if (!_v0.$) {
		switch (_v0.a) {
			case 'Senha Modificada!':
				return $author$project$App$SharedState$viewSuccess('Senha Modificada!');
			case 'Carregando':
				return $author$project$App$SharedState$viewLoading;
			default:
				var string = _v0.a;
				return $author$project$App$SharedState$viewError(string);
		}
	} else {
		return $elm$html$Html$text('asd');
	}
};
var $author$project$App$SharedState$viewUserTopBar = function (model) {
	var token = A2(
		$elm$core$Maybe$withDefault,
		$author$project$App$Data$Token$emptyModel,
		$author$project$App$Service$ApplicationResources$getAuthToken(model._));
	var passwordChangeFooter = _List_fromArray(
		[
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cancel-button'),
					$elm$html$Html$Events$onClick($author$project$App$SharedState$ToggleChangeProfile)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Cancelar')
				])),
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$App$SharedState$SubmitChangePassword)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Confirmar')
				]))
		]);
	var passwordChangeDialogEmpty = $author$project$App$Components$Dialog$emptyConfig('user-config');
	var passwordChangeBody = _List_fromArray(
		[
			A2(
			$elm$html$Html$fieldset,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$legend,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Trocar senha')
						])),
					A2(
					$elm$html$Html$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Senha atual:'),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('password'),
									$elm$html$Html$Attributes$value(model.bS.eE),
									$elm$html$Html$Events$onInput(
									A2($elm$core$Basics$composeL, $author$project$App$SharedState$ChangeUserInput, $author$project$App$Data$User$FieldPasswordOld))
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Nova senha:'),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('password'),
									$elm$html$Html$Attributes$value(model.bS.bf),
									$elm$html$Html$Events$onInput(
									A2($elm$core$Basics$composeL, $author$project$App$SharedState$ChangeUserInput, $author$project$App$Data$User$FieldPassword))
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Confirmar nova senha:'),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('password'),
									$elm$html$Html$Attributes$value(model.bS.dv),
									$elm$html$Html$Events$onInput(
									A2($elm$core$Basics$composeL, $author$project$App$SharedState$ChangeUserInput, $author$project$App$Data$User$FieldPasswordCheck))
								]),
							_List_Nil)
						])),
					$author$project$App$SharedState$viewLoadingOrError(model)
				]))
		]);
	var passwordChangeDialog = model.cY ? $elm$core$Maybe$Just(
		_Utils_update(
			passwordChangeDialogEmpty,
			{
				e5: $elm$core$Maybe$Just(passwordChangeBody),
				e6: $elm$core$Maybe$Just(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('selfie')
						])),
				ft: $elm$core$Maybe$Just(passwordChangeFooter),
				f1: 'Editar perfil'
			})) : $elm$core$Maybe$Nothing;
	return A2(
		$elm$html$Html$header,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$author$project$App$Route$href(
						$author$project$App$Route$Public($author$project$App$Route$Loading))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src('/assets/images/logo_white.svg'),
								$elm$html$Html$Attributes$title('Home')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('userprofile')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$App$Data$Person$fullPersonName(token.dw))
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(token.fq)
									]))
							])),
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(
								$author$project$App$Data$Person$photoUrl(token.dw))
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('userprofile-details')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$li,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$a,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$href('#'),
														$elm$html$Html$Events$onClick($author$project$App$SharedState$ToggleChangeProfile)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Trocar senha')
													]))
											])),
										A2(
										$elm$html$Html$li,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$a,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$href('#'),
														$elm$html$Html$Events$onClick($author$project$App$SharedState$DoLogout)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Sair')
													]))
											]))
									]))
							])),
						$author$project$App$Components$Dialog$view(passwordChangeDialog)
					]))
			]));
};
var $author$project$App$Main$renderRoute = function (model) {
	var bodyWithMenu = function (content) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('tbody ')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$map,
						$author$project$App$Main$SharedStateMsg,
						$author$project$App$SharedState$viewUserTopBar(model.a)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('content')
							]),
						A2(
							$elm$core$List$cons,
							A2(
								$elm$html$Html$map,
								$author$project$App$Main$SharedStateMsg,
								$author$project$App$SharedState$viewMenus(model.a)),
							content))
					]))
			]);
	};
	var _v0 = model.aQ;
	switch (_v0.$) {
		case 0:
			return A2(
				$elm$core$List$map,
				$elm$html$Html$map($author$project$App$Main$LoadingMsg),
				$author$project$App$Page$PageLoading$view);
		case 1:
			var pModel = _v0.a;
			return A2(
				$elm$core$List$map,
				$elm$html$Html$map($author$project$App$Main$LoginMsg),
				$author$project$App$Page$PageLogin$view(pModel));
		case 2:
			var pModel = _v0.a;
			return A2(
				$elm$core$List$map,
				$elm$html$Html$map($author$project$App$Main$FinanceSalesMsg),
				$author$project$App$Page$Finances$PageFinanceSales$view(pModel));
		case 3:
			var pModel = _v0.a;
			return A2(
				$elm$core$List$map,
				$elm$html$Html$map($author$project$App$Main$ClientMsg),
				$author$project$App$Page$Client$PageClient$view(pModel));
		case 4:
			var pModel = _v0.a;
			return A2(
				$elm$core$List$map,
				$elm$html$Html$map($author$project$App$Main$DoctorResumeMsg),
				$author$project$App$Page$Finances$PageDoctorResume$view(pModel));
		case 5:
			var pModel = _v0.a;
			return A2(
				$elm$core$List$map,
				$elm$html$Html$map($author$project$App$Main$DoctorSignUpMsg),
				A2($author$project$App$Page$PageDoctorSignUp$view, model.a, pModel));
		case 8:
			var pModel = _v0.a;
			return A2(
				$elm$core$List$map,
				$elm$html$Html$map($author$project$App$Main$UserRequestRecoverPasswordMsg),
				$author$project$App$Page$PageUserRequestRecoverPassword$view(pModel));
		case 9:
			var pModel = _v0.a;
			return A2(
				$elm$core$List$map,
				$elm$html$Html$map($author$project$App$Main$UserRecoverPasswordMsg),
				$author$project$App$Page$PageUserRecoverPassword$view(pModel));
		case 6:
			var pModel = _v0.a;
			return A2(
				$elm$core$List$map,
				$elm$html$Html$map($author$project$App$Main$UserSignUpMsg),
				$author$project$App$Page$PageUserSignUp$view(pModel));
		case 7:
			var pModel = _v0.a;
			return A2(
				$elm$core$List$map,
				$elm$html$Html$map($author$project$App$Main$UserSignUpCompleteMsg),
				$author$project$App$Page$PageUserSignUpComplete$view(pModel));
		case 10:
			var pModel = _v0.a;
			return A2(
				$elm$core$List$map,
				$elm$html$Html$map($author$project$App$Main$ProponentMsg),
				A2($author$project$App$Page$PageProponent$view, model.a, pModel));
		case 11:
			var dModel = _v0.a;
			return bodyWithMenu(
				A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Main$DoctorMsg),
					A3($author$project$App$Page$PageDoctor$view, model.ey, model.a, dModel)));
		case 12:
			var cModel = _v0.a;
			return bodyWithMenu(
				A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Main$CompanyMsg),
					A3($author$project$App$Page$PageCompany$view, model.a, model.ey, cModel)));
		case 13:
			var cModel = _v0.a;
			return bodyWithMenu(
				A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Main$ReviewMsg),
					A3($author$project$App$Page$PageReviewer$view, model.ey, model.a, cModel)));
		case 14:
			var cModel = _v0.a;
			return bodyWithMenu(
				A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Main$SupportMsg),
					A3($author$project$App$Page$PageSupport$view, model.ey, model.a, cModel)));
		default:
			var cModel = _v0.a;
			return bodyWithMenu(
				A2(
					$elm$core$List$map,
					$elm$html$Html$map($author$project$App$Main$AdminMsg),
					A3($author$project$App$Page$PageAdmin$view, model.a, model.ey, cModel)));
	}
};
var $author$project$App$Main$ToggleSupportChat = {$: 5};
var $author$project$App$Main$viewSupport = function (m) {
	return m.eW ? _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('support-wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('support-chat'),
							$elm$html$Html$Attributes$class('support-block'),
							$elm$html$Html$Attributes$href('https://api.whatsapp.com/send?phone=5541999701929'),
							$elm$html$Html$Attributes$target('_blank')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Converse com o suporte (8:30~12:00, 13:30~18:00)')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('support-close'),
							$elm$html$Html$Events$onClick($author$project$App$Main$ToggleSupportChat)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('X')
						]))
				]))
		]) : _List_Nil;
};
var $author$project$App$Main$view = function (model) {
	return {
		e5: _Utils_ap(
			$author$project$App$Main$renderRoute(model),
			$author$project$App$Main$viewSupport(model)),
		f1: 'Medsyn'
	};
};
var $author$project$App$Main$main = $elm$browser$Browser$application(
	{jd: $author$project$App$Main$init, jF: $author$project$App$Main$UrlChanged, jG: $author$project$App$Main$LinkClicked, j2: $author$project$App$Main$subscriptions, j7: $author$project$App$Main$update, ka: $author$project$App$Main$view});
_Platform_export({'App':{'Main':{'init':$author$project$App$Main$main(
	$elm$json$Json$Decode$succeed(0))(0)}}});}(this));